import { createContext, useContext } from 'react';
import { useJobReport } from './hooks';

export const JobReportContext = createContext(
  {} as ReturnType<typeof useJobReport>['context'],
);

export const useJobReportContext = () => {
  return useContext(JobReportContext);
};
