import React, { useState } from 'react';
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react-thin';
import { useSchedulerContext } from '../../hooks';
import {
  defaultConfig,
  defaultDisabledConfig,
  defaultRenderConfig,
} from './config';
import { SchedulerContentContext } from './context';
import { useSchedulerContent } from './hooks';
import { SchedulerContentProps } from './types';

const SchedulerContent = ({
  resourceType,
  config: config_,
  getDefaultDuration,
}: SchedulerContentProps) => {
  const [config] = useState(config_);
  const { project } = useSchedulerContext();
  const {
    ref,
    context,
    onBeforeTaskEdit,
    onBeforeEventDelete,
    onAfterEventDrop,
    onVisibleDateRangeChange,
    onBeforeEventResize,
    onEventResizeEnd,
  } = useSchedulerContent(resourceType, getDefaultDuration);
  return (
    <SchedulerContentContext.Provider value={context}>
      {/* @ts-ignore */}
      <BryntumSchedulerPro
        {...defaultDisabledConfig}
        {...defaultRenderConfig}
        {...defaultConfig}
        {...config}
        ref={ref}
        project={project}
        onBeforeTaskEdit={onBeforeTaskEdit}
        onBeforeEventDelete={onBeforeEventDelete}
        onAfterEventDrop={onAfterEventDrop}
        onVisibleDateRangeChange={onVisibleDateRangeChange}
        onBeforeEventResize={onBeforeEventResize}
        onEventResizeEnd={onEventResizeEnd}
      />
    </SchedulerContentContext.Provider>
  );
};

export default SchedulerContent;
