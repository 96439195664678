import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useUser } from 'lib/hooks';
import { Notification } from 'lib/types';
import { platformClient } from '../../../../app/platform';

export const useNotifications = () => {
  const user = useUser();

  const queryClient = useQueryClient();
  const queryKey = ['notifications'];

  const { data = [] } = useQuery({
    queryFn: platformClient.getNotifications(),
    queryKey,
  });

  const read = useMutation({
    mutationFn: platformClient.readNotifications(),
    onMutate: async ({ ids }: { ids: string[] }) => {
      await queryClient.cancelQueries({ queryKey });
      const previousNotifications = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (notifications: Notification[]) =>
        notifications.map((notification) =>
          ids.includes(notification.id)
            ? {
                ...notification,
                isRead: true,
              }
            : notification,
        ),
      );
      return { previousNotifications };
    },
    onError: (_, __, context) => {
      if (context?.previousNotifications) {
        queryClient.setQueryData(queryKey, context.previousNotifications);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const readAll = useMutation({
    mutationFn: platformClient.readAllNotifications(),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey });
      const previousNotifications = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (notifications: Notification[]) =>
        notifications.map((notification) => ({
          ...notification,
          isRead: true,
        })),
      );
      return { previousNotifications };
    },
    onError: (_, __, context) => {
      if (context?.previousNotifications) {
        queryClient.setQueryData(queryKey, context.previousNotifications);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const handleMarkAsRead = (notification: Notification) => {
    return async () => {
      if (!user) return;
      if (notification.isRead) return;
      await read.mutateAsync({ ids: [notification.id] });
    };
  };

  const handleMarkAllAsRead = async () => {
    if (!user) return;
    if (!data) return;
    await readAll.mutateAsync({});
  };

  const notifications = data ?? [];
  const notificationsUnread = notifications.filter(
    (notification) => !notification.isRead,
  );

  return {
    notificationsUnread,
    notifications,
    markAsRead: handleMarkAsRead,
    markAllAsRead: handleMarkAllAsRead,
  };
};
