import { useMatch } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'sonner';
import { UserQueryData, UserQueryVariables } from './types';

export const useTrainingStaffMember = () => {
  const match = useMatch('/hr/training/staff/:username');

  const { data, refetch } = useQuery<UserQueryData, UserQueryVariables>(
    gql`
      query GetUser($username: String) {
        user(username: $username) {
          id
          name
          email
          externalId
          training {
            id
            qualification {
              id
              color
              logo {
                id
              }
              name
              description
              fields
              data
              parent {
                id
              }
            }
            certificates {
              id
              sourceObject {
                id
                name
                type
                size
                data
              }
            }
            user {
              id
              name
              id
              email
            }
            dateValid
            dateExpiry
            data
          }
          trainingCertificates {
            id
            sourceObject {
              id
              name
              type
              size
              data
            }
          }
        }
      }
    `,
    {
      skip: !match,
      variables: { username: match?.params.username as string },
    },
  );

  const [create] = useMutation(
    gql`
      mutation CreateUserTraining(
        $user: ID!
        $qualification: ID!
        $dateValid: DateTime
        $dateExpiry: DateTime
        $data: JSONScalarInput!
      ) {
        createTraining(
          user: $user
          qualification: $qualification
          dateValid: $dateValid
          dateExpiry: $dateExpiry
          data: $data
        ) {
          training {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (values: any) => {
    if (!data) return;

    const { data: createData, errors } = await create({
      variables: {
        user: data.user.id,
        qualification: values.qualification.id,
        dateValid: values.dateValid,
        dateExpiry: values.dateExpiry,
        data: values.data || {},
      },
    });

    if (createData) {
      await refetch();
      toast.success('Training record created');
    }

    if (errors) {
      toast.error('Failed to create training record');
    }
  };

  const context = {
    handlers: {
      onCreate: handleOnCreate,
    },
  };

  if (!data) {
    return {
      context: {
        ...context,
        user: null,
      },
    };
  }

  return {
    context: {
      ...context,
      user: {
        ...data.user,
        refetch,
      },
    },
  };
};
