import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useCommercialContractContext } from '../../../../context';

export const usePrelimsCreate = () => {
  const { contract } = useCommercialContractContext();

  const [open, setOpen] = useState(false);

  const [create] = useMutation(
    gql`
      mutation CreateContractPrelim($contractId: ID!, $factor: Float!) {
        createCommercialContractPrelim(
          commercialContractId: $contractId
          factor: $factor
        ) {
          commercialContractPrelim {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (values: any) => {
    if (!contract) {
      console.warn('Cannot create prelim without contract');
      return;
    }

    const { data, errors } = await create({
      variables: {
        contractId: contract.id,
        factor: 1 + values.factor / 100,
      },
    });

    if (data) {
      await contract.refetch();
      setOpen(false);
      toast.success('Prelim created');
    }

    if (errors) {
      toast.error('Failed to create prelim');
    }
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onCreate: handleOnCreate,
    },
  };
};
