import { evaluateExpression } from '@enterprise-platform-ui/expressions';

const evaluateProperty = <D extends Record<string, any> = any>(
  key: keyof D,
  data: D,
  context: any,
  extractValue = true,
) => {
  try {
    const expressionKey = `$.${String(key)}`;
    return expressionKey in data
      ? extractValue
        ? evaluateExpression(data[expressionKey], context)?.value
        : evaluateExpression(data[expressionKey], context)
      : data[key];
  } catch (error) {
    return undefined;
  }
};

export default evaluateProperty;
