import React from 'react';
import { Badge } from '@/components/ui/badge';
import { DateTime, Interval } from 'luxon';
import { useParentStore } from 'lib/hooks';
import { RosterDuty } from 'lib/types';
import { RosterShiftChipProps } from './types';

const getShift = (
  duty: RosterDuty,
  dateTimeStart: DateTime,
  dateTimeEnd: DateTime,
) => {
  const interval = Interval.fromDateTimes(dateTimeStart, dateTimeEnd);
  return interval
    .splitBy({ days: 1 })
    .filter((day) => day.start)
    .map((day) => (day.start as DateTime).weekday)
    .indexOf(DateTime.fromISO(duty.dateTimeStart).weekday);
};

const RosterShiftChip = ({ exceedance, offset = 0 }: RosterShiftChipProps) => {
  const useStore = useParentStore<any>();

  const { dateTimeStart, dateTimeEnd } = useStore((state) => ({
    dateTimeStart: state.dateTimeStart,
    dateTimeEnd: state.dateTimeEnd,
  }));

  const shift = getShift(exceedance.duty, dateTimeStart, dateTimeEnd);
  const shiftOffset = shift + 1 + offset;

  if (shiftOffset < 1) {
    return <>the previous shift</>;
  }

  return <Badge variant="outline">Shift {shiftOffset}</Badge>;
};

export default RosterShiftChip;
