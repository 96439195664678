import { Plus } from 'lucide-react';
import { CommandGroup, CommandItem } from '@/components/ui/command';
import { cn } from '@/lib/utils';
import { TableSelector } from 'lib/shared';
import { CommercialType } from 'lib/types';
import { useActivityTableContext } from '../../../../context';
import { useValueCreate } from './hooks';

const ValueCreate = () => {
  const { type } = useActivityTableContext();
  const { contracts, handlers } = useValueCreate();
  return (
    <div className="flex bg-background ring-1 ring-border">
      <div className="grid w-7/12 grid-cols-8">
        <div className="col-span-3 flex border-r">
          <div className="flex w-8 flex-shrink-0 items-center justify-center border-r">
            <Plus className="-mr-0.5 size-4" />
          </div>
          {(type === CommercialType.Prelim ||
            type === CommercialType.Labor ||
            type === CommercialType.Subcontractor) && (
            <TableSelector
              popoverClassName={cn({
                'border-labor': type === CommercialType.Labor,
                'border-subcontractor': type === CommercialType.Subcontractor,
              })}
              placeholder="Add new line"
              searchPlaceholder="Search resources"
              value={undefined}
              valueDisplay={undefined}
              onChange={handlers.onCreateLabor}
            >
              {({ disabled, onSelect }) =>
                contracts.map((contract) => (
                  <CommandGroup key={contract.id} heading={contract.name}>
                    {contract.rates.map((rate) => (
                      <CommandItem
                        key={rate.id}
                        value={rate.id}
                        keywords={[rate.name, rate.description]}
                        className="cursor-pointer"
                        disabled={disabled}
                        onSelect={onSelect}
                      >
                        <div className="flex w-full items-center justify-between">
                          <p className="text-[13px]">{rate.name}</p>
                        </div>
                      </CommandItem>
                    ))}
                  </CommandGroup>
                ))
              }
            </TableSelector>
          )}
          {type === CommercialType.Material && (
            <TableSelector
              placeholder="Add new line"
              popoverClassName="border-material/75"
              searchPlaceholder="Search material"
              value={undefined}
              valueDisplay={undefined}
              onChange={handlers.onCreateMaterial}
            >
              {({ onSelect }) => null}
            </TableSelector>
          )}
          {type === CommercialType.Equipment && (
            <TableSelector
              placeholder="Add new line"
              popoverClassName="border-equipment"
              searchPlaceholder="Search equipment"
              value={undefined}
              valueDisplay={undefined}
              onChange={handlers.onCreateMaterial}
            >
              {({ onSelect }) => null}
            </TableSelector>
          )}
        </div>
        <div className="col-span-1 border-r" />
        <div className="col-span-1 border-r" />
        <div className="col-span-1 border-r" />
        <div className="col-span-1 border-r" />
        <div className="col-span-1 border-r" />
      </div>
      <div className="grid w-5/12 grid-cols-6 ">
        <div className="col-span-1 border-r" />
        <div className="col-span-1 border-r" />
        <div className="col-span-1 border-r" />
        <div className="col-span-1 border-r" />
        <div className="col-span-2 border-r" />
      </div>
    </div>
  );
};

export default ValueCreate;
