import React from 'react';
import { Check } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { ScopeLabel } from 'lib/shared';
import { JobIssueStatus } from 'lib/types';
import { useJobIssueContext } from '../../context';

const IssueHeader = () => {
  const { issue } = useJobIssueContext();
  if (!issue) return null;
  return (
    <div className="relative rounded-lg border bg-background p-4 pb-5">
      <div className="mb-4">
        <h2 className="relative z-[2] mb-2 flex items-center gap-x-2 text-lg">
          <div
            className={cn('mr-1 mt-0.5 h-6 w-6 flex-none rounded-full p-1.5', {
              'bg-incoming/50': issue.status === JobIssueStatus.Closed,
              'bg-red-800/50': issue.status === JobIssueStatus.Open,
            })}
          >
            <div
              className={cn('h-3 w-3 rounded-full', {
                'bg-incoming': issue.status === JobIssueStatus.Closed,
                'bg-red-800': issue.status === JobIssueStatus.Open,
              })}
            />
          </div>
          <span
            className={cn({
              'line-through': issue.status === JobIssueStatus.Closed,
            })}
          >
            <ScopeLabel
              className={cn('text-lg', {
                'line-through': issue.status === JobIssueStatus.Closed,
              })}
              label={issue.name}
            />
          </span>
          {issue.status === JobIssueStatus.Closed && (
            <div className="ml-2 flex items-center gap-x-1 text-sm font-semibold text-green-800">
              <Check className="size-4" />
              Resolved
            </div>
          )}
        </h2>
        <p className="ml-9 text-sm dark:text-gray-400">
          An issue was reported by operatives during the attendance of a task.
        </p>
      </div>
      <div className="ml-9">
        {issue.items.map((item) => {
          return (
            <div key={item.id} className="flex items-center gap-x-2">
              <Badge className="rounded capitalize">
                {item.status.toLowerCase()}
              </Badge>
              <p className="text-sm font-semibold underline">
                {item.description}
              </p>
            </div>
          );
        })}
      </div>
      <div className="z-1 absolute bottom-0 left-[27px] top-[47px] w-0.5 bg-accent" />
    </div>
  );
};

export default IssueHeader;
