import React from 'react';
import { TextareaAutosize } from '@/components/ui/textarea';
import { EditableAutoSaveProvider } from 'lib/shared';
import { useHeaderDescription } from './hooks';

const HeaderDescription = () => {
  const { estimate, handlers } = useHeaderDescription();
  return (
    <div className="flex">
      <div className="mr-[1px] grow rounded-none text-sm text-gray-600 dark:text-gray-300">
        <EditableAutoSaveProvider
          value={estimate?.description}
          onChange={async (value) => {
            await handlers.onUpdate('description', value || '');
            handlers.onClose();
          }}
        >
          {({ readOnly, value, onValueChange }) => (
            <TextareaAutosize
              className="border-0 focus-visible:ring-0"
              placeholder="Add a description"
              readOnly={readOnly}
              value={value}
              onValueChange={onValueChange}
            />
          )}
        </EditableAutoSaveProvider>
      </div>
    </div>
  );
};

export default HeaderDescription;
