import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useLocationPickerContext } from '../../hooks';

const PickerConfirm = () => {
  const {
    placingMarker,
    selected,
    handleOnContinue,
    handleOnClear,
    handleOnSave,
  } = useLocationPickerContext();

  if (placingMarker) {
    return (
      <div className="absolute bottom-4 right-4 z-10 flex justify-center">
        <div className="flex items-center gap-x-16 rounded-lg border bg-background p-4">
          <div>
            <p className="text-sm font-medium">Confirm marker position</p>
            <p className="text-xs leading-5 text-gray-500 dark:text-gray-400">
              Double check the location before saving
            </p>
          </div>
          <div className="flex justify-between gap-x-2">
            <Button
              size="sm"
              variant="outline"
              onClick={() => {
                handleOnClear(true);
              }}
            >
              Cancel
            </Button>
            <Button size="sm" className="font-bold" onClick={handleOnContinue}>
              Continue
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (!selected) {
    return null;
  }

  return (
    <div className="absolute bottom-4 right-4 z-10 flex justify-center">
      <div className="flex items-center gap-x-16 rounded-lg border bg-background px-4 py-3">
        <div>
          <p className="text-sm font-semibold">Confirm selection</p>
          <p className="text-xs leading-5 text-gray-500 dark:text-gray-400">
            Double check the location before saving
          </p>
        </div>
        <div className="flex justify-between gap-x-2">
          <Button
            size="sm"
            variant="outline"
            onClick={() => {
              handleOnClear(true);
            }}
          >
            Cancel
          </Button>
          <Button size="sm" className="font-bold" onClick={handleOnSave}>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PickerConfirm;
