import { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'sonner';
import { useCommercialContractContext } from '../../context';
import {
  CommercialContractRateQueryData,
  CommercialContractRateQueryVariables,
} from './types';

export const useContractRate = (id: string) => {
  const { contract } = useCommercialContractContext();

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const { data, refetch } = useQuery<
    CommercialContractRateQueryData,
    CommercialContractRateQueryVariables
  >(
    gql`
      query GetContractRate($id: ID!) {
        commercialContractRate(commercialContractRateId: $id) {
          id
          name
          description
          unit
          unitDisplay
          unitValue
          unitValueTransition
          unitValueTransitionTime
          qualifications {
            id
            priority
            value: qualification {
              id
              color
              logo {
                id
              }
              name
              description
            }
          }
        }
      }
    `,
    {
      skip: !open,
      variables: { id },
      fetchPolicy: 'no-cache',
    },
  );

  const [update] = useMutation(
    gql`
      mutation UpdateContractRate(
        $id: ID!
        $name: String!
        $description: String!
        $unitValue: Float!
        $unit: String!
      ) {
        updateCommercialContractRate(
          commercialContractRateId: $id
          name: $name
          description: $description
          unitValue: $unitValue
          unit: $unit
        ) {
          commercialContractRate {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnUpdate = async (values: any) => {
    if (!contract) return;

    const { data, errors } = await update({
      variables: {
        id,
        name: values.name,
        description: values.description,
        unitValue: values.unitValue,
        unit: values.unit,
      },
    });

    if (data) {
      await refetch();
      await contract.refetch();
      setOpen(false);
      toast.success('Rate updated');
    }

    if (errors) {
      toast.error('Failed to update rate');
    }
  };

  const [delete_] = useMutation(
    gql`
      mutation DeleteContractRate($id: ID!) {
        deleteCommercialContractRate(commercialContractRateId: $id) {
          deleted
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnDelete = async () => {
    if (!contract) return;

    const { data, errors } = await delete_({ variables: { id } });

    if (data) {
      await contract.refetch();
      setOpen(false);
      toast.success('Rate deleted');
    }

    if (errors) {
      toast.error('Failed to delete rate');
    }
  };

  const context = {
    state: {
      edit,
      onEdit: () => setEdit(true),
    },
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onUpdate: handleOnUpdate,
      onDelete: handleOnDelete,
    },
  };

  if (!data) return { context: { ...context, rate: null } };

  return {
    context: {
      ...context,
      rate: {
        ...data?.commercialContractRate,
        refetch,
      },
    },
  };
};
