import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { CommercialCostLine } from 'lib/types';
import { useEstimateTableContext } from '../../../../context';

export const useCostMenu = (line: CommercialCostLine) => {
  const { updateRefetch } = useEstimateTableContext();

  const [delete_] = useMutation(
    gql`
      mutation DeleteCommercialCostLine($line: ID!) {
        deleteCommercialCostLine(line: $line) {
          deleted
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnDelete = async () => {
    const { data, errors } = await delete_({ variables: { line: line.id } });

    if (data) {
      await updateRefetch();
      toast.success('Cost line deleted');
    }

    if (errors) {
      toast.error('Error deleting cost line');
    }
  };

  return {
    handlers: {
      onDelete: handleOnDelete,
    },
  };
};
