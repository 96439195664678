import React from 'react';
import {
  ArrowRight,
  LayoutDashboard,
  PersonStandingIcon,
  UsersIcon,
} from 'lucide-react';
import { Outlet } from 'react-router-dom';
import { Badge } from '@/components/ui/badge';
import { Nav } from '@/components/ui/nav';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable';
import { cn } from '@/lib/utils';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useRouteMatch } from 'lib/hooks';
import { useInitialQuery } from './hooks';

const HumanResourcesRosters = ({ defaultLayout = [265, 440, 655] }) => {
  useInitialQuery();
  const [open, setOpen] = useLocalStorage('hr-rosters-open', true);

  const routeMatch = useRouteMatch(['/hr/rosters/teams/*', '/hr/rosters/*']);
  return (
    <ResizablePanelGroup
      direction="horizontal"
      className="grow items-stretch bg-background"
    >
      <ResizablePanel
        defaultSize={defaultLayout[0]}
        minSize={12}
        maxSize={15}
        collapsible
        collapsedSize={1}
        onCollapse={() => setOpen(false)}
        onExpand={() => setOpen(true)}
        className={cn(
          'min-w-[64px] bg-background-secondary transition-all',
          !open && 'duration-300 ease-in-out',
        )}
      >
        <div className="flex h-[52px] items-center border-b px-4">
          {open ? (
            <>
              <h1
                className={cn('text-xl font-semibold', {
                  'font-normal':
                    routeMatch?.pattern.path.startsWith('/hr/rosters'),
                })}
              >
                Rosters
              </h1>
              <ArrowRight className="ml-auto h-4 w-4 text-muted-foreground" />
            </>
          ) : (
            <Badge className="h-8 w-8 justify-center rounded-md p-0 text-white dark:bg-secondary">
              <PersonStandingIcon className="h-5 w-5" />
            </Badge>
          )}
        </div>
        <Nav
          collapsed={!open}
          links={[
            {
              title: 'Calendar',
              icon: LayoutDashboard,
              variant: routeMatch?.pattern.path.startsWith('/hr/rosters/*')
                ? 'selected'
                : 'unselected',
              to: `/hr/rosters`,
            },
            {
              title: 'Teams',
              icon: UsersIcon,
              variant: routeMatch?.pattern.path.startsWith(
                '/hr/rosters/teams/*',
              )
                ? 'selected'
                : 'unselected',
              to: `/hr/rosters/teams`,
            },
          ]}
        />
      </ResizablePanel>
      <ResizableHandle withHandle />
      <ResizablePanel
        defaultSize={defaultLayout[1]}
        minSize={30}
        className="flex flex-col"
      >
        <Outlet />
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};

export default HumanResourcesRosters;
