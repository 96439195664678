import React from 'react';
import { EventModel } from '@bryntum/scheduler-thin';
import { TimesheetDuty } from 'lib/types';

const EventRenderer = ({ eventRecord }: { eventRecord: EventModel }) => {
  const isActive = true;

  const timesheetDuty = eventRecord.getData(
    'timesheetDuty',
  ) as TimesheetDuty & { isNew: boolean; isHighlighted?: boolean };

  const getStateClassName = () => {
    if (isActive) return 'active';
    return 'other';
  };

  if (timesheetDuty.activity.jobTask) {
    const {
      activity: {
        jobTask: { job, name, number: taskNumber },
      },
    } = timesheetDuty;

    return (
      <div className={getStateClassName()}>
        <p className="text-xs font-semibold">{job.reference}</p>
        <p className="text-[11px] font-medium">
          {name} {taskNumber}
        </p>
      </div>
    );
  }

  return null;
};

EventRenderer.displayName = 'Event';

export default EventRenderer;
