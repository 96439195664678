import { User } from 'lib/types';

export type UsersQueryVariables = {
  isActive: boolean;
};

export type UsersQueryData = {
  users: User[];
};

export enum Mode {
  Chart = 'chart',
  Table = 'table',
}
