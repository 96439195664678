import { createContext, useContext } from 'react';
import { useAction } from './hooks';

export const ActionContext = createContext(
  {} as ReturnType<typeof useAction>['context'],
);

export const useActionContext = () => {
  return useContext(ActionContext);
};
