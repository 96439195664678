import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CompanyAccreditations from './pages/CompanyAccreditations';
import CompanyInsurance from './pages/CompanyInsurance';
import CompanyMemberships from './pages/CompanyMemberships';
import CompanyStaff from './pages/CompanyStaff';

const Company = () => {
  return (
    <Routes>
      <Route path="staff" element={<CompanyStaff />} />
      <Route path="accreditations" element={<CompanyAccreditations />} />
      <Route path="memberships" element={<CompanyMemberships />} />
      <Route path="insurance" element={<CompanyInsurance />} />
    </Routes>
  );
};

export default Company;
