import React from 'react';
import Empty from '../Empty';
import FormPage from './components/FormPage';
import { FormContextProvider } from './context';
import { useForm, useFormStore } from './hooks';
import { useValue } from './hooks';
import { FormRendererProps } from './types';
import { getHorizontalPadding } from './utils';

const FormRenderer = ({
  readOnly: readOnlyFlag = false,
  template,
  values,
  objects,
  meta = {
    isCompleted: false,
    isPristine: false,
    isLocked: false,
    contributors: [],
    signature: null,
  },
  reviewMode = false,
  disableMedia = false,
  fieldExtensions,
  renderField,
  fieldValueOnChange,
  fieldMetaOnChange,
  repeatingBranchOnCreate,
  repeatingBranchOnDelete,
  objectsOnCreate,
  objectOnUpload,
  objectOnUploadProgress,
  objectOnDelete,
  onComplete,
}: FormRendererProps) => {
  const nonProxyValues = JSON.parse(JSON.stringify(values));
  const { pageIndex, pages } = useForm(
    template,
    nonProxyValues,
    useFormStore((state) => state.collapsed),
  );
  const readOnly = readOnlyFlag || meta.isCompleted || meta.isLocked;
  return (
    <FormContextProvider
      value={{
        template,
        values,
        objects,
        meta,
        readOnly,
        reviewMode,
        disableMedia,
        fieldExtensions,
        renderField,
        fieldValueOnChange,
        fieldMetaOnChange,
        repeatingBranchOnCreate,
        repeatingBranchOnDelete,
        objectsOnCreate,
        objectOnUpload,
        objectOnUploadProgress,
        objectOnDelete,
      }}
    >
      {pages[pageIndex] ? (
        <FormPage
          page={pages[pageIndex]}
          isLast={pageIndex === pages.length - 1}
          onComplete={onComplete}
        />
      ) : (
        <div className="h-64 p-4">
          <Empty>Page not found</Empty>
        </div>
      )}
    </FormContextProvider>
  );
};

export { getHorizontalPadding, useValue };

FormRenderer.Page = FormPage;

export default FormRenderer;
