import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react-thin';
import { motion } from 'framer-motion';
import { DateTime, Duration } from 'luxon';
import { Scheduler } from 'lib/shared';
import { JobTimelineTargetAimStatus } from 'lib/types';
import useTraceUpdate from '../../../../hooks/useTraceUpdate';
import { useSchedulerContext } from '../../../../shared/Scheduler/hooks';
import { SchedulerAction } from '../../../../shared/Scheduler/types';
import { useClient } from '../../../Client/hooks';
import { useJobContext } from '../../context';
import { JobTaskContext, useJobTaskContext } from '../JobTask/context';
import { useJobTask } from '../JobTask/hooks';
import AssignmentCrosshair from './components/AssignmentCrosshair';
import AssignmentInfo from './components/AssignmentInfo';
import AssignmentLoader from './components/AssignmentLoader';
import AssignmentStaff from './components/AssignmentStaff';
import AssignmentSubcontractor from './components/AssignmentSubcontractor';
import { useProject } from './hooks';

const JobTaskAssignment = () => {
  console.warn('JobTaskAssignment');

  const { job } = useJobContext();
  const { context } = useJobTask();

  if (context.task?.deleted) {
    return (
      <JobTaskContext.Provider value={context}>
        <div className="p-4">
          <h5>Job Task Deleted</h5>
          <p>This job task has been deleted and is no longer available.</p>
        </div>
      </JobTaskContext.Provider>
    );
  }

  const ref = useRef<BryntumSchedulerPro>(null);

  if (job.partial || !context.task) return null;

  return (
    <JobTaskContext.Provider value={context}>
      <SchedulerContext ref={ref} />
    </JobTaskContext.Provider>
  );
};

const SchedulerContext = forwardRef<BryntumSchedulerPro, any>((_, ref) => {
  const client = useClient();

  const { job } = useJobContext();
  const { context } = useJobTask();

  const { ready, project } = useProject();

  if (job.partial || !context.task) return null;

  return (
    <Scheduler.Root
      ref={ref}
      project={project}
      onUpdateSuccess={async () => {
        await client.refetch();
        await job.refetch();
        await context.task.refetch();
      }}
    >
      <JobTaskAssignmentRenderer ready={ready} />
    </Scheduler.Root>
  );
});

const JobTaskAssignmentRenderer = ({ ready }: any) => {
  console.warn('JobTaskAssignmentRenderer', { ready });

  const { task } = useJobTaskContext();
  const { actions } = useSchedulerContext();

  useTraceUpdate(actions);

  const [duration, setDuration] = useState<Duration>(
    Duration.fromObject({ minutes: 60 }),
  );

  const getDefaultDuration = useCallback(() => duration, [duration]);

  useEffect(() => {
    console.warn('JobTaskAssignmentRenderer', { ready, task });

    if (!ready || !task) return;

    const nextUnmetTarget = [...task.aims]
      .sort((a, b) =>
        DateTime.fromISO(a.target.dateTime) >
        DateTime.fromISO(b.target.dateTime)
          ? 1
          : -1,
      )
      .find((aim) => aim.status === JobTimelineTargetAimStatus.Pending);

    if (nextUnmetTarget) {
      actions.request({
        type: SchedulerAction.SetControlDateTime,
        description: 'Jumped to next unmet target',
        dateTime: DateTime.fromISO(nextUnmetTarget.target.dateTime),
      });
    }
  }, [ready]);

  if (!task) return null;

  return (
    <div className="flex h-full min-h-0 gap-x-4 rounded bg-secondary p-4">
      <div className="flex h-full w-[350px] flex-shrink-0 flex-col overflow-hidden rounded-lg border bg-background">
        <AssignmentInfo />
        <div id="task-drag-container" className="p-4">
          {ready && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <AssignmentCrosshair
                active={false}
                value={duration}
                onValueChange={setDuration}
                min={Duration.fromObject({ minutes: 5 })}
                max={Duration.fromObject({ minutes: 180 })}
                data-id={task.id}
              />
            </motion.div>
          )}
        </div>
      </div>
      <div className="relative h-full grow overflow-hidden rounded-lg border bg-background">
        {!ready && <AssignmentLoader />}
        <motion.div
          className="flex h-full min-h-0 flex-col"
          initial={{ opacity: 0 }}
          animate={{ opacity: ready ? 1 : 0 }}
          transition={{ duration: ready ? 0.5 : 0 }}
        >
          <Scheduler.Controls />
          <div className="min-h-0 grow">
            <AssignmentStaff getDefaultDuration={getDefaultDuration} />
            <AssignmentSubcontractor getDefaultDuration={getDefaultDuration} />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default JobTaskAssignment;
