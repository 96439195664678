import { BryntumGanttProps } from '@bryntum/gantt-react-thin';

export const ganttConfig: BryntumGanttProps = {
  columns: [{ type: 'name', field: 'name', width: 250 }],
  viewPreset: 'weekAndDayLetter',
  timeRangesFeature: {
    showHeaderElements: false,
    showCurrentTimeLine: true,
  },
};
