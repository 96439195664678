import { PropsWithChildren } from 'react';
import { LucideIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { buttonVariants } from '@/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

type NavProps = PropsWithChildren<{
  collapsed?: boolean;
  links: {
    title: string;
    label?: string;
    icon: LucideIcon;
    color?: string;
    variant: 'default' | 'ghost' | 'outline' | 'selected' | 'unselected';
    to: string;
  }[];
}>;

export const Nav = ({ children, collapsed = false, links }: NavProps) => {
  return (
    <div className="group flex flex-col gap-4 py-4 data-[collapsed=true]:py-4">
      <nav
        className={cn(
          'grid gap-1 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-4',
          {
            'px-3.5': collapsed,
            'px-2': !collapsed,
          },
        )}
      >
        {links.map((link, index) =>
          collapsed ? (
            <Tooltip key={index}>
              <TooltipTrigger asChild>
                <Link
                  to={link.to}
                  className={cn(
                    buttonVariants({ variant: link.variant, size: 'icon' }),
                    link.variant === 'default' &&
                      'text-white hover:bg-primary hover:text-white dark:bg-muted',
                    'h-8',
                  )}
                >
                  <link.icon
                    className="h-4 w-4"
                    style={link.color ? { color: link.color } : undefined}
                  />
                </Link>
              </TooltipTrigger>
              <TooltipContent side="right">
                <div>
                  {link.title}
                  {link.label && (
                    <span
                      className={cn(
                        'ml-auto',
                        link.variant === 'default' &&
                          'text-background dark:text-white',
                      )}
                    >
                      {link.label}
                    </span>
                  )}
                </div>
              </TooltipContent>
            </Tooltip>
          ) : (
            <NavLink key={index} link={link} />
          ),
        )}
        {children}
      </nav>
    </div>
  );
};

export const NavLink = ({ link }: { link: NavProps['links'][0] }) => {
  return (
    <Link
      to={link.to}
      className={cn(
        buttonVariants({ variant: link.variant, size: 'sm' }),
        link.variant === 'default' &&
          'text-white hover:bg-primary hover:text-white dark:bg-muted',
        'justify-start pl-[15px]',
      )}
    >
      <link.icon
        className="mr-2 h-4 w-4"
        style={link.color ? { color: link.color } : undefined}
      />
      {link.title}
      {link.label && (
        <span
          className={cn(
            'ml-auto',
            link.variant === 'default' && 'text-background dark:text-white',
          )}
        >
          {link.label}
        </span>
      )}
    </Link>
  );
};
