import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { CommercialCostLine } from 'lib/types';
import { useCommercialActivityContext } from '../../../../../../context';
import { useActivityTableContext } from '../../../../context';

export const useCostMenu = (line: CommercialCostLine) => {
  const { store } = useCommercialActivityContext();
  const { updateRefetch } = useActivityTableContext();

  const [delete_] = useMutation(
    gql`
      mutation DeleteCommercialCostLine($line: ID!) {
        deleteCommercialCostLine(line: $line) {
          deleted
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnDelete = async () => {
    const { data, errors } = await delete_({ variables: { line: line.id } });

    if (data) {
      try {
        store.order.splice(store.order.indexOf(line.id), 1);
      } catch (error) {
        console.error('Error removing line from store', error);
      }
      await updateRefetch();
      toast.success('Cost line deleted');
    }

    if (errors) {
      toast.error('Error deleting cost line');
    }
  };

  return {
    handlers: {
      onDelete: handleOnDelete,
    },
  };
};
