import React from 'react';
import { PersonStanding } from 'lucide-react';
import {
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu';
import NavigationLeftListItem from '../NavigationLeftListItem';

const NavigationLeftHumanResources = () => {
  return (
    <NavigationMenuItem>
      <NavigationMenuTrigger>Human Resources</NavigationMenuTrigger>
      <NavigationMenuContent>
        <ul className="grid gap-3 p-4 md:w-[450px] lg:w-[550px] lg:grid-cols-[.75fr_1fr]">
          <li className="row-span-4">
            <NavigationMenuLink asChild>
              <div className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md">
                <PersonStanding className="h-6 w-6" />
                <div className="mb-2 mt-4 text-lg font-medium">
                  Human Resources
                </div>
                <p className="text-sm leading-tight text-muted-foreground">
                  Manage rosters and roster teams
                </p>
              </div>
            </NavigationMenuLink>
          </li>
          <NavigationLeftListItem to="/hr/rosters" title="Rosters">
            Manage rosters and roster teams
          </NavigationLeftListItem>
          <NavigationLeftListItem to="/hr/timesheets" title="Timesheets">
            Create and manage timesheets
          </NavigationLeftListItem>
          <NavigationLeftListItem to="/hr/leave" title="Leave">
            Review and approve leave
          </NavigationLeftListItem>
          <NavigationLeftListItem to="/hr/training" title="Training">
            Track and approve training
          </NavigationLeftListItem>
        </ul>
      </NavigationMenuContent>
    </NavigationMenuItem>
  );
};

export default NavigationLeftHumanResources;
