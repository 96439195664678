import React from 'react';
import { Circle } from 'lucide-react';
import Map, { Marker } from 'react-map-gl';
import { Button } from '@/components/ui/button';
import { DateTime } from 'luxon';
import { useMapStyle } from 'lib/hooks';
import { addCircleOnLoad } from '../../../../../../../ClientJob/pages/JobOverview/components/OverviewMap';
import { useSubcontractorContext } from '../../context';

const SubcontractorActivity = () => {
  const mapStyle = useMapStyle();
  const { subcontractor } = useSubcontractorContext();

  if (!subcontractor?.duties) return null;

  return (
    <div
      className="flex flex-col overflow-hidden"
      style={{ gridArea: 'activity' }}
    >
      <div className="flex flex-grow gap-x-4">
        {subcontractor.duties.slice(0, 3).map((duty) => {
          const {
            jobTask: task,
            jobTask: { job },
          } = duty.timesheetActivity;
          const point = job.site?.location?.precise?.geometry?.point;
          return (
            <div
              key={duty.id}
              className="relative h-full w-[33%] overflow-hidden rounded-lg border-2 bg-background-secondary"
            >
              <div className="absolute inset-0 top-0 z-[0] min-h-0">
                <Map
                  trackResize
                  style={{ height: '100%', width: '100%' }}
                  initialViewState={{
                    zoom: 15,
                    ...(job.location?.precise?.geometry?.point ||
                      job.site.location?.precise?.geometry?.point),
                  }}
                  mapboxAccessToken="pk.eyJ1IjoiZW5lcmd5ZW5hYmxlcyIsImEiOiJjbGpia2NqZ2IxcTcxM2pxeGxvNG5ud2lpIn0.gMXRc_xES-6AlQx1w8MuXw"
                  attributionControl={false}
                  mapStyle={mapStyle}
                  onLoad={(event) => {
                    if (point) addCircleOnLoad(event, point);
                  }}
                >
                  {point && (
                    <Marker {...point}>
                      <Circle className="size-3 fill-success stroke-success" />
                    </Marker>
                  )}
                </Map>
              </div>
              <div className="relative z-10 flex h-full flex-col justify-between bg-gradient-to-b from-background-secondary from-20% to-100% p-4">
                <div className="flex">
                  <div className="flex-grow">
                    <p className="font-mono text-sm font-semibold">
                      {job.reference}
                    </p>
                    <p className="text-sm">{task.name}</p>
                  </div>
                  <div>
                    <Button className="p-0" size="xs" variant="link">
                      View
                    </Button>
                  </div>
                </div>
                <div>
                  <p className="mb-1 font-mono text-xs font-semibold dark:text-gray-400">
                    {DateTime.fromISO(duty.dateTimeStart).toFormat(
                      'HH:mm dd/MM/yy',
                    )}
                  </p>
                  <p className="font-mono text-xs font-semibold dark:text-gray-400">
                    {DateTime.fromISO(duty.dateTimeEnd).toFormat(
                      'HH:mm dd/MM/yy',
                    )}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubcontractorActivity;
