import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { useStore } from '../../hooks';
import { useLocationPrecise } from './hooks';

const LocationPrecise = ({ border = false }) => {
  const apply = useStore((state) => state.apply);
  const {
    value,
    isActiveWorkspaceHandle,
    workspaceValue,
    handleOnEdit,
    handleOnSave,
    handleOnCancel,
    handleOnDelete,
  } = useLocationPrecise();

  let inner: React.ReactNode;

  if (isActiveWorkspaceHandle) {
    inner = (
      <div>
        <p className="mb-2 text-sm font-medium">Precise location</p>
        {workspaceValue ? (
          <Button
            fullWidth
            variant="contained"
            color="success"
            sx={{ height: 'auto', padding: 4, marginBottom: 2 }}
            onClick={handleOnSave}
          >
            Precise location selected. Click to save.
          </Button>
        ) : (
          <Button
            fullWidth
            variant="contained"
            color="success"
            sx={{ height: 'auto', padding: 4, marginBottom: 2 }}
            disabled={!workspaceValue}
            onClick={handleOnEdit}
          >
            Use the map to select a precise location for this location
          </Button>
        )}
        <Button
          fullWidth
          variant="outlined"
          color="secondary"
          onClick={handleOnCancel}
        >
          Cancel
        </Button>
      </div>
    );
  } else if (!value) {
    inner = (
      <Box>
        <p className="mb-2">Precise location</p>
        <Button
          fullWidth
          sx={{ height: 'auto', padding: 4 }}
          onClick={handleOnEdit}
        >
          Add precise location
        </Button>
      </Box>
    );
  } else {
    inner = (
      <div className="relative">
        <p className="mb-2">Precise location</p>
        <div className="mb-2">
          <table>
            <tbody>
              <tr>
                <td
                  style={{
                    paddingRight: 16,
                    opacity: 0.75,
                    fontWeight: value ? 600 : 400,
                  }}
                >
                  Longitude
                </td>
                <td>
                  {Math.abs(value.geometry.point.longitude) < 10 && <p>0</p>}
                  <p>{value.geometry.point.longitude.toFixed(3)}</p>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    paddingRight: 16,
                    opacity: 0.75,
                    fontWeight: value ? 600 : 400,
                  }}
                >
                  Latitude
                </td>
                <td>
                  {Math.abs(value.geometry.point.latitude) < 10 && <p>0</p>}
                  <p>{value.geometry.point.latitude.toFixed(3)}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Button
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            height: 24,
            fontSize: 14,
            opacity: 0.75,
            '&:hover': {
              opacity: 1,
            },
          }}
          onClick={handleOnEdit}
        >
          Edit
        </Button>
        <Button
          size="small"
          startIcon={
            <FontAwesomeIcon icon={faEye} style={{ width: 14, height: 14 }} />
          }
          onClick={() => {
            apply((state) => {
              state.map.viewState = {
                ...state.map.viewState,
                latitude: value.geometry.point.latitude,
                longitude: value.geometry.point.longitude,
              };
            });
          }}
        >
          View on map
        </Button>
        <Button
          size="small"
          startIcon={
            <FontAwesomeIcon icon={faTimes} style={{ width: 14, height: 14 }} />
          }
          confirmOptions={{ confirmText: 'Are you sure?' }}
          onClick={handleOnDelete}
        >
          Delete
        </Button>
      </div>
    );
  }

  return (
    <div
      {...(border
        ? {
            border: theme.border.thin,
            borderRadius: 1,
            padding: 1,
          }
        : {})}
    >
      {inner}
    </div>
  );
};

export default LocationPrecise;
