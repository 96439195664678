import React from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import parse, { Element } from 'html-react-parser';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Placeholder } from '@tiptap/extension-placeholder';
import { StarterKit } from '@tiptap/starter-kit';
import DOMPurify from 'dompurify';
import { DateTime } from 'luxon';
import { useMessageGroup, useUser } from 'lib/hooks';
import { PlatformMessage } from 'lib/types';
import { PlatformEvent, useEvents } from '../Events';
import { MessageGroupContext } from './context';
import { MessageGroupProps } from './types';

enum FeedItemType {
  Message = 'MESSAGE',
  Event = 'EVENT',
}

const DateTimeDisplay = ({
  className,
  timestamp,
}: {
  className?: string;
  timestamp: number;
}) => {
  const dateTime = DateTime.fromSeconds(timestamp);
  if (dateTime.hasSame(DateTime.now(), 'day')) {
    return <p className={className}>{dateTime.toFormat('t')}</p>;
  }
  return <p className={className}>{dateTime.toFormat('t')}</p>;
};

const MessageGroup = ({ children, scope, id }: MessageGroupProps) => {
  const user = useUser();
  const { messages, onSend } = useMessageGroup({
    scope,
    id,
    defaultMeta: {
      user: user
        ? {
            id: user.id,
            name: user.name,
            initials: user.initials,
            platformId: user.platformId,
          }
        : undefined,
    },
  });
  const [events] = useEvents(`${scope}::${id}`);
  const feed = [
    ...(messages ?? []).map((message) => ({
      ...message,
      type: FeedItemType.Message,
    })),
    ...(events ?? []).map((event) => ({
      ...event,
      type: FeedItemType.Event,
    })),
  ].sort((a, b) => a.timestamp - b.timestamp) as (
    | (PlatformMessage & { type: FeedItemType.Message })
    | (PlatformEvent & { type: FeedItemType.Event })
  )[];

  if (children) {
    return (
      <MessageGroupContext.Provider value={{}}>
        {children({ feed, onSend })}
      </MessageGroupContext.Provider>
    );
  }

  return (
    <MessageGroupContext.Provider value={{}}>
      <div className="flex h-full min-h-0 flex-col">
        <div className="flex min-h-0 grow flex-col gap-y-4">
          <ScrollArea className="h-full min-h-0">
            <div className="flex flex-col gap-y-4 p-4">
              {feed.map((item) => {
                if (item.type === FeedItemType.Message) {
                  return <Message key={item.id} message={item} />;
                }
                if (item.type === FeedItemType.Event) {
                  return <EventRenderer key={item.id} event={item} />;
                }
                return null;
              })}
            </div>
          </ScrollArea>
        </div>
        <MessageBox onSend={onSend} />
      </div>
    </MessageGroupContext.Provider>
  );
};

const Message = ({ message }: { message: PlatformMessage }) => {
  const user = useUser();
  const safeHtml = DOMPurify.sanitize(message.bodyHtml);

  const parsed = parse(safeHtml, {
    replace: (domNode) => {
      if (!(domNode instanceof Element)) return;
      return domNode;
    },
  });

  if (message.meta?.user?.id === user?.id) {
    return (
      <div className="flex justify-end">
        <div className="flex min-w-[20%] max-w-[40%] gap-x-2">
          <div className="w-full rounded-lg bg-background p-4 text-sm">
            <div className="mb-1 flex gap-x-2 text-xs">
              <p className="font-medium">You </p>
              <DateTimeDisplay className="" timestamp={message.timestamp} />
            </div>
            <div>{parsed as any}</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-start">
      <div className="flex gap-x-2">
        <Avatar>
          <AvatarFallback className="bg-background">
            {message.meta?.user?.initials?.at(0) ?? '?'}
          </AvatarFallback>
        </Avatar>
        <div className="rounded-lg bg-background p-4 text-sm">
          <div className="mb-1 text-xs">
            <span className="font-medium">
              {message.meta?.user?.name ?? 'Unknown'}{' '}
            </span>
            <span>{DateTime.fromSeconds(message.timestamp).toISO()}</span>
          </div>
          <div>{parsed as any}</div>
        </div>
      </div>
    </div>
  );
};

const MessageBox = ({ onSend }: { onSend: any }) => {
  const editor = useEditor({
    extensions: [StarterKit, Placeholder.configure({ placeholder: 'Aa' })],
    editorProps: {
      attributes: {
        class: 'px-4 py-4 outline-none text-sm',
      },
    },
  });
  return (
    <div className="flex items-center border-t bg-background">
      <EditorContent className="grow" editor={editor} />
      <Button
        size="xs"
        variant="outline"
        className="mr-3"
        onClick={() => {
          if (!editor) return;
          onSend(editor.getJSON(), editor.getHTML(), editor.getText());
          editor.commands.clearContent();
        }}
      >
        Send message
      </Button>
    </div>
  );
};

const EventRenderer = ({ event }: { event: PlatformEvent }) => {
  if (event.name === 'JOB_ISSUE_ITEM_RESOLVED') {
    return (
      <div className="flex justify-center">
        <div className="rounded-lg border border-success bg-background p-4 text-sm">
          <div className="mb-1 text-center text-xs">
            <DateTimeDisplay className="mb-1" timestamp={event.timestamp} />
            <p className="font-medium">Marked as resolved</p>
          </div>
        </div>
      </div>
    );
  }
  if (event.name === 'JOB_ISSUE_ITEM_BLOCKING') {
    return (
      <div className="flex justify-center">
        <div className="rounded-lg border border-destructive bg-background p-4 text-sm">
          <div className="mb-1 text-center text-xs">
            <DateTimeDisplay className="mb-1" timestamp={event.timestamp} />
            <p className="font-medium">Marked as blocking</p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex justify-center">
      <div className="rounded-lg bg-background p-4 text-sm">
        <div className="mb-1 text-center text-xs">
          <DateTimeDisplay className="mb-1" timestamp={event.timestamp} />
          <p className="font-medium">Marked as resolved</p>
        </div>
      </div>
    </div>
  );
};

export default MessageGroup;
