import { EditorContent, useEditor } from '@tiptap/react';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { StarterKit } from '@tiptap/starter-kit';
import { DateTime } from 'luxon';
import { UserLabel } from 'lib/shared';
import { FeedItemType } from '../../../../../../../../external/MessageGroup/types';
import { ActivityMessageProps } from './types';

const ActivityMessage = ({ feed, message }: ActivityMessageProps) => {
  const editor = useEditor({
    extensions: [StarterKit],
    content: message.bodyHtml,
    editable: false,
  });

  const matchPrevious = () => {
    const previousItem = feed[feed.indexOf(message) - 1];
    return (
      previousItem &&
      previousItem.type === FeedItemType.Message &&
      previousItem.meta.user.id === message.meta.user.id
    );
  };

  const matchNext = () => {
    const nextItem = feed[feed.indexOf(message) + 1];
    return (
      nextItem &&
      nextItem.type === FeedItemType.Message &&
      nextItem.meta.user.id === message.meta.user.id
    );
  };

  return (
    <div
      className={cn('z-2 relative flex justify-end gap-x-2', {
        'pt-8': !matchPrevious(),
      })}
    >
      <div
        className={cn(
          'min-w-[30%] max-w-[75%] bg-background px-4 pb-3 pt-2 text-sm',
          { 'mb-1': matchNext() },
        )}
        style={{
          borderTopLeftRadius: '1rem',
          borderTopRightRadius: matchPrevious() ? '0' : '1rem',
          borderBottomLeftRadius: '1rem',
          borderBottomRightRadius: matchNext() ? '0' : '1rem',
        }}
      >
        <div className="mb-1 flex items-center gap-x-4">
          <div className="grow">
            <UserLabel user={message.meta.user}>
              <Button
                className="h-auto justify-start p-0 text-xs font-semibold dark:text-gray-400"
                variant="link"
              >
                {message.meta.user.name}
              </Button>
            </UserLabel>
          </div>
          <p className="text-[11px] dark:text-gray-400">
            {DateTime.fromSeconds(message.timestamp).toLocaleString(
              DateTime.DATETIME_MED,
            )}
          </p>
        </div>
        <EditorContent editor={editor} />
      </div>
      <div>
        <Avatar className="size-8 text-xs">
          <AvatarFallback className="bg-background-secondary font-semibold dark:bg-muted">
            {message.meta.user.initials[0]}
          </AvatarFallback>
        </Avatar>
      </div>
    </div>
  );
};

export default ActivityMessage;
