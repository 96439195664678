import React from 'react';
import { Button } from '@/components/ui/button';
import { input } from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import {
  Sheet,
  SheetContentExtension,
  SheetTrigger,
} from '@/components/ui/sheet';
import { z } from 'zod';
import { useStaffMemberContext } from '../../context';
import { useMemberDriverInfo } from './hooks';

const MemberDriverInfo = () => {
  const { user } = useStaffMemberContext();
  const { sheet, handlers } = useMemberDriverInfo();
  return (
    <div>
      <div className="mb-4">
        <p className="mb-1 text-sm font-medium">Driver Info</p>
        <p className="text-sm text-gray-400 dark:text-gray-400">
          Set this individual's driver information such as their driver fob ID.
        </p>
      </div>
      <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
        <SheetTrigger asChild>
          <Button
            className="relative w-full justify-start text-start"
            variant="outline"
            size="sm"
          >
            {user?.driverKeyFobId ? (
              <p>
                <span className="dark:text-gray-400">Driver Fob ID: </span>
                <span className="font-mono font-semibold">
                  {user.driverKeyFobId}
                </span>
              </p>
            ) : (
              <em>Not set</em>
            )}
            <div className="absolute inset-y-0 right-3 flex items-center">
              <span className="text-[11px]">Click to edit</span>
            </div>
          </Button>
        </SheetTrigger>
        <SheetContentExtension right={500} className="p-4">
          <FormRenderer
            fields={[
              input({
                name: 'driverKeyFobId',
                label: 'Driver Fob ID',
                schema: z.string().min(1),
                defaultValue: user?.driverKeyFobId,
              }),
            ]}
            onSubmit={handlers.onUpdate}
          >
            {({ onSubmit }) => (
              <div>
                <Button className="w-full" size="sm" onClick={onSubmit}>
                  Set
                </Button>
              </div>
            )}
          </FormRenderer>
        </SheetContentExtension>
      </Sheet>
    </div>
  );
};

export default MemberDriverInfo;
