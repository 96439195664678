import { useCallback } from 'react';
import { useQuery as usePlatformQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { Job, Site } from 'lib/types';
import { PlatformClient } from '../../../../../../app/platform';
import { MiniMapPosition } from './components/MiniMap/types';
import { Data, LatestDriverUpdates } from './types';

export const useActivityMap = (
  jobs: Job[],
  miniMapPosition: MiniMapPosition,
) => {
  const driverUpdates = useDriverUpdates();

  const getSize = useCallback((dateTimeString: string) => {
    const now = DateTime.now();
    const dateTime = DateTime.fromISO(dateTimeString);
    const x = now.diff(dateTime).as('seconds');
    const y = 32 * Math.exp(-0.001 * x) + 24;
    return Math.max(16, Math.min(56, y));
  }, []);

  const groupBySite = useCallback((jobs: Job[]) => {
    return Object.values(
      jobs.reduce(
        (acc, job) => {
          return {
            ...acc,
            [job.site.id]: {
              site: job.site,
              jobs: [...(acc[job.site.id]?.jobs || []), job],
            },
          };
        },
        {} as Record<string, { site: Site; jobs: Job[] }>,
      ),
    );
  }, []);

  const getDriversAtCoords = useCallback(
    (latitude: number, longitude: number, radius: number) => {
      return Object.values(driverUpdates).filter(
        ({ latitude: lat, longitude: lon }) =>
          Math.sqrt(
            Math.pow(lat - latitude, 2) + Math.pow(lon - longitude, 2),
          ) <= radius,
      );
    },
    [driverUpdates],
  );

  return {
    context: {
      jobs,
      driverUpdates,
      getSize,
      groupBySite,
      getDriversAtCoords,
      miniMapPosition,
    },
  };
};

const useDriverUpdates = (): LatestDriverUpdates => {
  const { data } = usePlatformQuery<Data>({
    queryKey: ['datagraph', 'events'],
    queryFn: new PlatformClient(
      'https://api.enterprise-platform.com',
    ).searchEvents('tenant__mchugh__verizon_driver_updates_event', {
      size: 0,
      aggs: {
        drivers: {
          terms: { field: 'vehicle.esn', size: 10000 },
          aggs: {
            latest_event: {
              top_hits: {
                sort: [{ time: { order: 'desc' } }],
                _source: {
                  includes: [
                    'driver',
                    'vehicle',
                    'latitude',
                    'longitude',
                    'speed',
                    'direction',
                    'time',
                    'data',
                  ],
                },
                size: 1,
              },
            },
            latest_movements: {
              filter: {
                range: {
                  time: {
                    gte: 'now-15m',
                  },
                },
              },
              aggs: {
                recent_data: {
                  top_hits: {
                    sort: [{ time: { order: 'desc' } }],
                    _source: {
                      includes: ['latitude', 'longitude', 'time'],
                    },
                    size: 5,
                  },
                },
              },
            },
          },
        },
      },
    }),
    refetchInterval: 5000,
  });

  return !data
    ? {}
    : data.aggregations.drivers.buckets.reduce((acc, bucket) => {
        const latestEvent = bucket.latestEvent.hits.hits[0].source;
        const latestMovements = bucket.latestMovements.recentData.hits.hits.map(
          (hit) => hit.source,
        );
        return {
          ...acc,
          [bucket.key]: {
            ...latestEvent,
            movements: latestMovements,
          },
        };
      }, {} as LatestDriverUpdates);
};
