import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { Loader2 } from 'lucide-react';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { cn } from '@/lib/utils';
import { type VariantProps, cva } from 'class-variance-authority';
import { AsyncContext } from 'lib/shared';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        background: 'bg-background shadow hover:bg-background/90',
        default:
          'bg-primary text-primary-foreground shadow hover:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        outline:
          'border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        skeleton: 'bg-transparent',
        table: 'text-primary underline-offset-4 hover:underline p-0',
        selected:
          'border bg-muted hover:bg-accent hover:text-accent-foreground font-bold',
        unselected:
          'hover:bg-accent hover:text-accent-foreground border border-transparent',
      },
      size: {
        default: 'h-9 px-4 py-2',
        xs: 'h-6 px-2.5 py-1.5 text-xs',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-10 rounded-md px-8',
        icon: 'h-9 w-9 flex-shrink-0',
        'icon-sm': 'h-8 w-8 flex-shrink-0',
        'icon-xs': 'h-7 w-7 flex-shrink-0',
        table: 'p-0',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  disableAsync?: boolean;
  isLoading?: boolean;
  loading?: boolean;
  confirm?: string;
  confirmHelp?: string;
  confirmButton?: string;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void | Promise<void>;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      disableAsync = false,
      isLoading: explicitIsLoading = false,
      loading: explicitLoading = false,
      disabled: explicitDisabled = false,
      confirm,
      confirmHelp,
      confirmButton,
      variant,
      size,
      asChild = false,
      onClick,
      ...props
    },
    ref,
  ) => {
    const [asyncLoading, setAsyncLoading] = React.useState(false);
    const [event, setEvent] =
      React.useState<React.MouseEvent<HTMLButtonElement>>();

    const [open, setOpen] = React.useState(false);

    const handleOnClick = React.useCallback(
      async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (confirm) {
          setEvent(event);
          return;
        }

        if (asyncLoading) return;
        if (onClick) {
          const result = onClick(event);
          if (result instanceof Promise && !disableAsync) {
            setAsyncLoading(true);
            result.finally(() => setAsyncLoading(false));
          }
        }
      },
      [asyncLoading, onClick, setAsyncLoading, disableAsync, setEvent, confirm],
    );

    const loading = explicitIsLoading || explicitLoading || asyncLoading;

    const Comp = asChild ? Slot : 'button';
    const comp = (
      <Comp
        className={cn('z-0', buttonVariants({ variant, size, className }), {
          'border-gray-400':
            (variant && ['ghost', 'link'].includes(variant) && loading) ||
            (!variant && loading),
        })}
        ref={ref}
        disabled={explicitDisabled || loading}
        onClick={handleOnClick}
        {...props}
      >
        {loading ? <Loader2 className="h-4 w-4 animate-spin" /> : children}
      </Comp>
    );

    if (confirm) {
      return (
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogTrigger asChild>{comp}</DialogTrigger>
          <DialogContent overlay>
            <DialogHeader>
              <DialogTitle>{confirm}</DialogTitle>
              <DialogDescription>{confirmHelp}</DialogDescription>
            </DialogHeader>
            <AsyncContext
              handle={async () => {
                if (event) {
                  await onClick?.(event);
                  setOpen(false);
                }
              }}
            >
              {({ loading, handle }) => (
                <div className="flex justify-between">
                  {!loading ? (
                    <DialogClose asChild>
                      <Button size="sm" variant="outline">
                        Cancel
                      </Button>
                    </DialogClose>
                  ) : (
                    <div />
                  )}
                  <Button
                    size="sm"
                    variant="destructive"
                    loading={loading}
                    onClick={handle}
                  >
                    {confirmButton || 'Confirm'}
                  </Button>
                </div>
              )}
            </AsyncContext>
          </DialogContent>
        </Dialog>
      );
    }

    return comp;
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
