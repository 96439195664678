import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { platformObjectInput } from 'lib/utils';
import { useCompanyAccreditationsContext } from '../../context';
import {
  CreateCompanyAccreditationMutationData,
  CreateCompanyAccreditationMutationVariables,
} from './types';

export const useAccreditationsCreate = () => {
  const { refetch } = useCompanyAccreditationsContext();

  const [open, setOpen] = useState(false);

  const [create] = useMutation<
    CreateCompanyAccreditationMutationData,
    CreateCompanyAccreditationMutationVariables
  >(
    gql`
      mutation CreateCompanyAccreditation($data: CompanyAccreditationInput) {
        createCompanyAccreditation(companyAccreditationData: $data) {
          companyAccreditation {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (values: any) => {
    const { data, errors } = await create({
      variables: {
        data: {
          logo: platformObjectInput(values.logo),
          name: values.name,
          reference: values.reference,
          dateTimeExpiry: values.dateTimeExpiry,
          description: values.description,
          document: platformObjectInput(values.document),
        },
      },
    });

    if (data) {
      await refetch();
      setOpen(false);
      toast.success('Company accreditation created');
    }

    if (errors) {
      toast.error('Failed to create company accreditation');
    }
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onCreate: handleOnCreate,
    },
  };
};
