import { BryntumTimelineProps } from '@bryntum/schedulerpro-react-thin';
import { ViewPreset } from '@bryntum/scheduler-thin';
import { ColumnRenderer } from '../../../../sections/ClientJob/pages/JobTaskAssignment/components/AssignmentScheduler/components/SchedulerStaffMembers/renderers';

export const defaultViewPreset = new ViewPreset({
  id: 'default',
  name: 'Default',
  tickWidth: 64,
  tickHeight: 24,
  displayDateFormat: 'HH:mm',
  shiftIncrement: 1,
  shiftUnit: 'day',
  defaultSpan: 24,
  timeResolution: {
    unit: 'minute',
    increment: 5,
  },
  headers: [
    {
      unit: 'hour',
      dateFormat: 'HH:mm',
    },
  ],
  columnLinesFor: 0,
});

export const defaultConfig: Partial<BryntumTimelineProps> = {
  height: 64,
  barMargin: 12,
  emptyText: 'No activity',
  monitorResize: false,
  readOnly: true,
  resourceMargin: 32,
  rowHeight: 16,
  viewPreset: defaultViewPreset,
  timeRangesFeature: {
    showCurrentTimeLine: true,
  },
};

export const defaultDisabledConfig: Partial<BryntumTimelineProps> = {
  cellMenuFeature: false,
  createEventOnDblClick: false,
  dependenciesFeature: false,
  dependencyEditFeature: false,
  eventDragCreateFeature: false,
  eventMenuFeature: false,
  scheduleMenuFeature: false,
  splitFeature: false,
  timeAxisHeaderMenuFeature: false,
  zoomOnMouseWheel: false,
  zoomOnTimeAxisDoubleClick: false,
};
