import React, { useMemo } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useStaffMemberContext } from '../../context';
import {
  CreateCommercialResourceRateMutationData,
  CreateCommercialResourceRateMutationVariables,
} from './types';

export const useMemberResourceRates = () => {
  const { user } = useStaffMemberContext();

  const [open, setOpen] = React.useState(false);

  const [create] = useMutation<
    CreateCommercialResourceRateMutationData,
    CreateCommercialResourceRateMutationVariables
  >(
    gql`
      mutation CreateCommercialResourceRate(
        $userId: ID!
        $description: String!
        $base: Boolean!
        $unit: String!
        $unitCost: Float!
        $dateStart: DateTime!
        $dateEnd: DateTime!
      ) {
        createCommercialResourceRate(
          userId: $userId
          description: $description
          base: $base
          unit: $unit
          unitCost: $unitCost
          dateStart: $dateStart
          dateEnd: $dateEnd
        ) {
          commercialResourceRate {
            id
            description
            base
            unit
            unitDisplay
            unitCost
            dateStart
            dateEnd
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (values: any) => {
    if (!user) {
      return;
    }

    const { data, errors } = await create({
      variables: {
        userId: user.id,
        description: values.description,
        base: values.base,
        unit: values.unit,
        unitCost: values.unitCost,
        dateStart: values.dateStart,
        dateEnd: values.dateEnd,
      },
    });

    if (data) {
      await user.refetch();
      setOpen(false);
      toast.success('Resource rate created');
    }

    if (errors) {
      console.error(errors);
      toast.error('Resource rate creation failed');
    }
  };

  const rates = useMemo(() => user?.rates ?? [], [user]);

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    rates,
    handlers: {
      onCreate: handleOnCreate,
    },
  };
};
