import React, { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { gql, useMutation, useQuery } from '@apollo/client';
import { formatDistanceToNow } from 'date-fns';
import { DateTime } from 'luxon';
import { z } from 'zod';
import { Empty } from 'lib/shared';
import { TimesheetDuty } from 'lib/types';

const SubcontractorActivity = ({
  duty: dutyOuter,
}: {
  duty: TimesheetDuty;
}) => {
  const [open, setOpen] = useState(false);

  const [, setValue] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setValue((prevValue) => prevValue + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const { data } = useQuery(
    gql`
      query GetTimesheetDuty($timesheetDutyId: ID!) {
        timesheetDuty(timesheetDutyId: $timesheetDutyId) {
          id
          subcontractorReports {
            id
            status
            sourceObject {
              id
              type
              name
              size
            }
          }
        }
      }
    `,
    {
      skip: !open,
      fetchPolicy: 'no-cache',
      variables: { timesheetDutyId: dutyOuter.id, open },
    },
  );

  const [create] = useMutation(gql`
    mutation CreateSubcontractorReport(
      $timesheetDuty: ID!
      $platformObject: PlatformObjectInput!
    ) {
      createSubcontractorReport(
        timesheetDutyId: $timesheetDuty
        platformObject: $platformObject
      ) {
        subcontractorReport {
          id
        }
      }
    }
  `);

  const duty: TimesheetDuty = {
    ...dutyOuter,
    ...data?.timesheetDuty,
  };

  const reports = duty.subcontractorReports ?? [];

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button className="font-bold" size="sm" variant="outline">
          Subcontractor: {duty.subcontractor?.name}
        </Button>
      </SheetTrigger>
      <SheetContent className="flex w-[700px] max-w-[700px] flex-col gap-0 p-0 sm:max-w-[700px]">
        <SheetHeader className="p-4">
          <SheetTitle>Timesheet duty</SheetTitle>
          <SheetDescription>
            Manage the timesheet duty for this user.
          </SheetDescription>
        </SheetHeader>
        <ScrollArea className="grow">
          <div>
            <Separator />
            <div className="p-4">
              <h3 className="text-md mb-1 font-medium text-gray-600 dark:text-white/80">
                Schedule
              </h3>
              <p className="text-xs text-gray-400">
                This are the times given to the subcontractor.
              </p>
            </div>
            <Separator />
            <ul role="list" className="space-y-6 py-4 pl-3 pr-4">
              <DutyCreatedListItem duty={duty} />
              {duty.dateTimeStart && <DutyStartListItem duty={duty} />}
              {duty.dateTimeEnd && <DutyEndListItem duty={duty} />}
            </ul>
          </div>
          <div>
            <Separator />
            <div className="flex items-center justify-between p-4">
              <div>
                <h3 className="text-md mb-1 font-medium text-gray-600 dark:text-white/80">
                  Reports
                </h3>
                <p className="text-xs text-gray-400">
                  Here are any reports that have been uploaded for this duty.
                </p>
              </div>
              <div>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button size="sm" variant="outline">
                      Upload new report
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <FormRenderer
                      fields={[
                        {
                          type: 'file',
                          name: 'file',
                          label: 'Report',
                          schema: z.any(),
                          maxFiles: 1,
                        },
                      ]}
                      onSubmit={async (values) => {
                        await create({
                          variables: {
                            timesheetDuty: duty.id,
                            platformObject: {
                              id: values.file.id,
                              type: values.file.type,
                              name: values.file.name,
                              size: values.file.size,
                              data: values.file.data,
                            },
                          },
                        });
                      }}
                    />
                  </DialogContent>
                </Dialog>
              </div>
            </div>
            <Separator />
            {reports.length > 0 ? (
              <div>
                {reports.map((report) => (
                  <div key={report.id} className="p-4">
                    {report.id}
                  </div>
                ))}
              </div>
            ) : (
              <div className="h-32 p-4">
                <Empty>No reports uploaded for this duty</Empty>
              </div>
            )}
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

const DutyCreatedListItem = ({ duty }: { duty: TimesheetDuty }) => {
  return (
    <li className="relative flex gap-x-4">
      <div className="absolute -bottom-6 -left-[1px] top-0 flex w-6 justify-center">
        <div className="w-px bg-gray-200" />
      </div>
      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-background">
        <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
      </div>
      <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
        <span className="font-medium text-gray-900 dark:text-white/75">
          Timesheet duty created
        </span>
      </p>
      <time
        dateTime={duty.dateTimeCreated}
        className="flex-none py-0.5 text-xs leading-5 text-gray-500 dark:text-white/50"
      >
        {formatDistanceToNow(duty.dateTimeCreated, {
          addSuffix: true,
        })}
      </time>
    </li>
  );
};

const DutyStartListItem = ({ duty }: { duty: TimesheetDuty }) => {
  return (
    <li className="relative flex gap-x-4">
      <div className="absolute -bottom-6 -left-[1px] top-0 flex w-6 justify-center">
        <div className="w-px bg-gray-200" />
      </div>
      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-background">
        <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
      </div>
      <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
        <span className="font-medium text-gray-900 dark:text-white/75">
          Start <strong>{duty.dateTimeStartCondition.toLowerCase()}</strong>{' '}
          {DateTime.fromISO(duty.dateTimeStart).toLocaleString(
            DateTime.DATETIME_FULL,
          )}
        </span>
      </p>
      <time
        dateTime={duty.dateTimeStart}
        className="flex-none py-0.5 text-xs leading-5 text-gray-500 dark:text-white/50"
      >
        {formatDistanceToNow(duty.dateTimeStart, {
          addSuffix: true,
        })}
      </time>
    </li>
  );
};

const DutyEndListItem = ({ duty }: { duty: TimesheetDuty }) => {
  return (
    <li className="relative flex gap-x-4">
      <div className="absolute -left-[1px] top-0 flex h-6 w-6 justify-center">
        <div className="w-px bg-gray-200" />
      </div>
      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-background">
        <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
      </div>
      <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
        <span className="font-medium text-gray-900 dark:text-white/75">
          End <strong>{duty.dateTimeEndCondition.toLowerCase()}</strong>{' '}
          {DateTime.fromISO(duty.dateTimeEnd).toLocaleString(
            DateTime.DATETIME_FULL,
          )}
        </span>
      </p>
      <time
        dateTime={duty.dateTimeEnd}
        className="flex-none py-0.5 text-xs leading-5 text-gray-500 dark:text-white/50"
      >
        {formatDistanceToNow(duty.dateTimeEnd, {
          addSuffix: true,
        })}
      </time>
    </li>
  );
};

export default SubcontractorActivity;
