import { useClientAsset } from './hooks';

const ClientAsset = () => {
  const { asset } = useClientAsset();
  return (
    <div>
      Asset
      {asset?.reference}
    </div>
  );
};

export default ClientAsset;
