import React, { useState } from 'react';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { Circle } from 'lucide-react';
import { useLocation } from 'react-router';
import { badgeVariants } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Drawer, DrawerContent, DrawerTrigger } from '@/components/ui/drawer';
import { cn } from '@/lib/utils';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Link } from 'lib/shared';
import { Job } from 'lib/types';
import { useClient } from '../../../../hooks';
import JobsBoard from '../../../../pages/ClientJobs/components/JobsBoard';

const NavJobs = () => {
  const client = useClient();
  const { requiresAction, requiresReview, incoming, active } = client.jobs;
  const [open, setOpen] = useState(false);
  const attention = [...requiresAction, ...requiresReview];
  return (
    <div className="ml-[23px] flex flex-col gap-y-4 pb-4 pr-2">
      <NavJobsIncoming incoming={incoming} />
      <NavJobsActive active={active} />
      <NavJobsAttention attention={attention} />
      <Drawer open={open} onOpenChange={setOpen}>
        <DrawerTrigger asChild>
          <Button
            className={cn(
              badgeVariants({ variant: 'outline' }),
              'mr-2 mt-2 h-6 rounded-md',
            )}
            variant="outline"
            size="sm"
          >
            Open job board
          </Button>
        </DrawerTrigger>
        <DrawerContent className="h-1/2 min-h-0">
          <div className="flex h-full min-h-0 flex-col p-4">
            <JobsBoard
              className="rounded-lg"
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

const NavJobsIncoming = ({ incoming }: { incoming: Job[] }) => {
  const { pathname } = useLocation();
  const client = useClient();
  const [open, setOpen] = useLocalStorage(
    'client::nav::incoming-jobs-open',
    true,
  );
  return (
    <div className="border-l-2 border-dotted pl-2">
      <div>
        <Button
          size="xs"
          variant="ghost"
          className="flex w-full items-center gap-x-2 text-left"
          onClick={() => setOpen((prevOpen) => !prevOpen)}
        >
          <p className="grow text-xs font-bold dark:text-gray-400">Incoming</p>
          <p className="text-xs font-bold dark:text-gray-400">
            {incoming.length}
          </p>
          <ChevronRightIcon
            className={cn('h-4 w-4 transition-all', {
              'rotate-90 transform': open,
            })}
          />
        </Button>
      </div>
      {open ? (
        incoming.length > 0 ? (
          <div className="mt-2 flex flex-col pl-2">
            {incoming.slice(0, 5).map((job) => (
              <Link
                key={job.id}
                to={`/clients/${client.slug}/jobs/${job.reference}`}
              >
                <Button
                  size="xs"
                  variant="ghost"
                  className={cn(
                    'flex w-full items-center justify-start gap-x-2 text-left transition-none',
                    {
                      'bg-accent text-accent-foreground': pathname.includes(
                        job.reference,
                      ),
                    },
                  )}
                >
                  <Circle className="h-2 w-2 fill-incoming-foreground stroke-incoming-foreground" />
                  <p className="text-xs font-medium">{job.reference}</p>
                </Button>
              </Link>
            ))}
            {incoming.length > 5 && (
              <Link to={`/clients/${client.slug}/jobs`}>
                <Button
                  size="xs"
                  variant="link"
                  className="px-0 opacity-50 transition-opacity hover:opacity-100"
                >
                  +{incoming.length - 5} more
                </Button>
              </Link>
            )}
          </div>
        ) : (
          <div>
            <p className="ml-2.5 mt-2 text-xs text-gray-400 dark:text-white/50">
              No incoming jobs
            </p>
          </div>
        )
      ) : null}
    </div>
  );
};

const NavJobsActive = ({ active }: { active: Job[] }) => {
  const { pathname } = useLocation();
  const client = useClient();
  const [open, setOpen] = useLocalStorage(
    'client::nav::active-jobs-open',
    true,
  );
  return (
    <div className="border-l-2 border-dotted pl-2">
      <div>
        <Button
          size="xs"
          variant="ghost"
          className="flex w-full items-center gap-x-2 text-left"
          onClick={() => setOpen((prevOpen) => !prevOpen)}
        >
          <p className="grow text-xs font-bold dark:text-gray-400">Active</p>
          <p className="text-xs font-bold dark:text-gray-400">
            {active.length}
          </p>
          <ChevronRightIcon
            className={cn('h-4 w-4 transition-all', {
              'rotate-90 transform': open,
            })}
          />
        </Button>
      </div>
      {open ? (
        active.length > 0 ? (
          <div className="mt-2 flex flex-col gap-y-1 pl-2">
            {active.slice(0, 5).map((job) => (
              <Link
                key={job.id}
                to={`/clients/${client.slug}/jobs/${job.reference}`}
              >
                <Button
                  size="xs"
                  variant="ghost"
                  className={cn(
                    'flex w-full items-center justify-start gap-x-2 text-left transition-none',
                    {
                      'bg-accent text-accent-foreground': pathname.includes(
                        job.reference,
                      ),
                    },
                  )}
                >
                  <Circle className="h-2 w-2 fill-progress-foreground stroke-progress-foreground" />
                  <p className="text-xs font-medium">{job.reference}</p>
                </Button>
              </Link>
            ))}
            {active.length > 5 && (
              <Link to={`/clients/${client.slug}/jobs`}>
                <Button
                  size="xs"
                  variant="link"
                  className="px-0 opacity-50 transition-opacity hover:opacity-100"
                >
                  +{active.length - 5} more
                </Button>
              </Link>
            )}
          </div>
        ) : (
          <div>
            <p className="ml-2.5 mt-2 text-xs text-gray-400 dark:text-white/50">
              No active jobs
            </p>
          </div>
        )
      ) : null}
    </div>
  );
};

const NavJobsAttention = ({ attention }: { attention: Job[] }) => {
  const { pathname } = useLocation();
  const client = useClient();
  const [open, setOpen] = useLocalStorage(
    'client::nav::jobs-requiring-attention-open',
    true,
  );
  return (
    <div className="border-l-2 border-dotted pl-2">
      <div>
        <Button
          size="xs"
          variant="ghost"
          className="flex w-full items-center gap-x-2 text-left"
          onClick={() => setOpen((prevOpen) => !prevOpen)}
        >
          <p className="grow text-xs font-bold dark:text-gray-400">Attention</p>
          <p className="text-xs font-bold dark:text-gray-400">
            {attention.length}
          </p>
          <ChevronRightIcon
            className={cn('h-4 w-4 transition-all', {
              'rotate-90 transform': open,
            })}
          />
        </Button>
      </div>
      {open ? (
        attention.length > 0 ? (
          <div className="mt-2 flex flex-col gap-y-1 pl-2">
            {attention.slice(0, 5).map((job) => (
              <Link
                key={job.id}
                to={`/clients/${client.slug}/jobs/${job.reference}`}
              >
                <Button
                  size="xs"
                  variant="ghost"
                  className={cn(
                    'flex w-full items-center justify-start gap-x-2 text-left transition-none',
                    {
                      'bg-accent text-accent-foreground': pathname.includes(
                        job.reference,
                      ),
                    },
                  )}
                >
                  <div className="relative h-2 w-2">
                    <Circle className="absolute inset-0 h-2 w-2 fill-destructive stroke-destructive" />
                    <Circle className="absolute inset-0 h-2 w-2 animate-ping fill-destructive stroke-destructive" />
                  </div>
                  <p className="text-xs font-medium">{job.reference}</p>
                </Button>
              </Link>
            ))}
            {attention.length > 5 && (
              <Link to={`/clients/${client.slug}/jobs`}>
                <Button
                  size="xs"
                  variant="link"
                  className="px-0 opacity-50 transition-opacity hover:opacity-100"
                >
                  +{attention.length - 5} more
                </Button>
              </Link>
            )}
          </div>
        ) : (
          <div>
            <p className="ml-2.5 mt-2 text-xs text-gray-400 dark:text-white/50">
              No jobs require attention
            </p>
          </div>
        )
      ) : null}
    </div>
  );
};

export default NavJobs;
