import { useRef, useState } from 'react';
import { DragUpdate } from '@hello-pangea/dnd';
import { DebouncedFunc, throttle } from 'lodash';
import {
  GetIsItemCollapsed,
  GetShouldItemNest,
  GetShouldParentIncreaseDepth,
  Item,
  ItemType,
  Items,
  Pages,
} from './types';

export const getItemDepth = (
  item: Item,
  items: Items,
  getShouldParentIncreaseDepth: GetShouldParentIncreaseDepth,
): number => {
  let parentItem = items[item.parentId];
  if (!parentItem) return 0;
  if (parentItem.type === ItemType.PAGE) return 0;

  let depth = 0;
  while (parentItem.type !== ItemType.PAGE) {
    if (getShouldParentIncreaseDepth(parentItem)) depth += 1;
    parentItem = items[parentItem.parentId];
    if (!parentItem) return depth;
  }

  return depth;
};

export const getDraggedDOM = (draggableId: string) => {
  return document.querySelector(
    `[data-rfd-draggable-id='${draggableId}']`,
  ) as Element;
};

export const getNewItemDepth = (
  draggableId: string,
  items: Items,
  depthMargin: number,
  shouldParentIncreaseDepth: GetShouldParentIncreaseDepth,
) => {
  const draggedDOM = getDraggedDOM(draggableId);

  if (!draggedDOM || !(draggedDOM instanceof Element)) return -1;

  const matrixString = window.getComputedStyle(draggedDOM).transform;
  const matrix = new WebKitCSSMatrix(matrixString);

  return Math.floor(
    (getItemDepth(items[draggableId], items, shouldParentIncreaseDepth) *
      depthMargin +
      matrix.e +
      5) /
      depthMargin,
  );
};

type Params = {
  pages: Pages;
  items: Items;
  depthMargin?: number;
  getShouldItemNest?: GetShouldItemNest;
  getIsItemCollapsed?: GetIsItemCollapsed;
  getShouldParentIncreaseDepth?: GetShouldParentIncreaseDepth;
};

export const useStartTrackingMouse = ({
  pages,
  items,
  depthMargin = 40,
  getShouldItemNest = () => true,
  getIsItemCollapsed = () => false,
  getShouldParentIncreaseDepth = () => true,
}: Params) => {
  const [currentItemDepth, setCurrentItemDepth] = useState<number>();

  const dragEventRef = useRef<DragUpdate>();
  const functionRef = useRef<DebouncedFunc<() => void>>();

  const startTrackingMouse = () => {
    if (functionRef.current) {
      document.removeEventListener('mousemove', functionRef.current);
    }

    functionRef.current = throttle(() => {
      if (!dragEventRef.current) return;
      if (!dragEventRef.current?.destination) return;

      const { draggableId, destination } = dragEventRef.current;

      if (!destination.droppableId || !pages[destination.droppableId]) return;

      const depth = getNewItemDepth(
        draggableId,
        items,
        depthMargin,
        getShouldParentIncreaseDepth,
      );

      const otherBlocks = pages[destination.droppableId].items.filter(
        (block) => block.id !== draggableId,
      );

      const aboveItem = otherBlocks[destination.index - 1];
      const aboveItemDepth = aboveItem
        ? getItemDepth(aboveItem, items, getShouldParentIncreaseDepth)
        : 0;

      const belowItem = otherBlocks[destination.index];
      const belowItemDepth = belowItem
        ? getItemDepth(belowItem, items, getShouldParentIncreaseDepth)
        : 0;

      const shouldItemNest =
        aboveItem &&
        (getShouldItemNest?.(aboveItem) ?? true) &&
        !getIsItemCollapsed(aboveItem.id);

      const nextItemDepth = Math.max(
        belowItemDepth,
        Math.min(
          Math.max(0, depth ? depth : 0),
          aboveItemDepth + (shouldItemNest ? 1 : 0),
        ),
      );

      if (nextItemDepth !== undefined) {
        setCurrentItemDepth(nextItemDepth);
      }
    }, 100);

    document.addEventListener('mousemove', functionRef.current);
  };

  const stopTrackingMouse = () => {
    if (!functionRef.current) return;
    document.removeEventListener('mousemove', functionRef.current);
    functionRef.current.cancel();
  };

  return {
    currentItemDepth,
    dragEventRef,
    startTrackingMouse,
    stopTrackingMouse,
  };
};
