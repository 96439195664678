import { gql, useQuery } from '@apollo/client';
import { useClient } from '../../hooks';
import { GetAssetsQueryData, GetAssetsQueryVariables } from './types';

export const useClientAssets = () => {
  const client = useClient();

  const { data } = useQuery<GetAssetsQueryData, GetAssetsQueryVariables>(
    gql`
      query GetAssets($clientId: ID!) {
        assets(clientId: $clientId) {
          id
          reference
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: { clientId: client.id },
    },
  );

  return {
    assets: data?.assets || [],
  };
};
