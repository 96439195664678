import React from 'react';
import { AlertTriangle, Check } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Loader } from '@/components/ui/loading';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'sonner';
import {
  CommercialActivityStatus,
  CommercialContract,
  CommercialType,
} from 'lib/types';
import { useJobContext } from '../../../../context';
import { JobCommercialProvider } from '../../../JobCommercial';
import { CommercialActivityProvider } from '../../../JobCommercial/components/CommercialActivity';
import { ActivityTableProvider } from '../../../JobCommercial/components/CommercialActivity/components/ActivityTable';
import ValueHeader from '../../../JobCommercial/components/CommercialActivity/components/ActivityTable/components/TableValue/components/ValueHeader';
import ValueLine from '../../../JobCommercial/components/CommercialActivity/components/ActivityTable/components/TableValue/components/ValueLine';
import { useActivityTableContext } from '../../../JobCommercial/components/CommercialActivity/components/ActivityTable/context';
import {
  CreateJobCommercialActivityMutationData,
  CreateJobCommercialActivityMutationVariables,
} from '../../../JobCommercial/components/CommercialActivityCreate/types';
import { useStage } from '../../hooks';
import { STAGE_COMMERCIAL } from '../../stages';
import { InstantiationStageIssues } from '../InstantiationIssues';

const InstantiationCommercial = () => {
  const { job } = useJobContext();
  const stage = useStage(STAGE_COMMERCIAL);

  if (job.partial) return null;

  return (
    <div className="border-b p-4">
      <CommercialActivity />
      <InstantiationStageIssues
        disabled={!job.commercialActivity?.contract}
        stage={stage}
        types={[
          {
            value: 'information-missing',
            label: 'Missing information',
            color: 'red',
          },
        ]}
      />
    </div>
  );
};

const CommercialActivity = () => {
  const { job } = useJobContext();

  if (job.partial) return null;

  if (!job.commercialActivity) {
    return <CommercialActivityDoesNotExist />;
  }

  if (!job.commercialActivity.contract) {
    return <CommercialActivityNoContract />;
  }

  if (job.commercialActivity.status === CommercialActivityStatus.PreApproved) {
    return <CommercialActivityPreApproved />;
  }

  return <CommercialActivityValid />;
};

const CommercialActivityDoesNotExist = () => {
  const { job } = useJobContext();

  const [create] = useMutation<
    CreateJobCommercialActivityMutationData,
    CreateJobCommercialActivityMutationVariables
  >(
    gql`
      mutation CreateJobCommercialActivity($jobId: ID!) {
        createJobCommercialActivity(jobId: $jobId) {
          job {
            commercialActivity {
              id
            }
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async () => {
    if (job.partial) return;

    const { data, errors } = await create({ variables: { jobId: job.id } });

    if (data) {
      await job.refetch();
      toast.success('Commercial activity created');
    }

    if (errors) {
      toast.error('Failed to create commercial activity');
    }
  };

  if (job.partial) return null;

  return (
    <div className="mb-4 flex grow justify-between rounded-lg border border-warning/50 bg-secondary/50">
      <div className="grow px-3 py-2">
        <p>No commercial activity</p>
        <p className="text-sm font-medium dark:text-gray-400">
          A commercial activity is required before a job can be accepted
        </p>
      </div>
      <div className="flex items-center gap-x-4 pr-3">
        <Button size="sm" onClick={handleOnCreate}>
          Create commercial activity
        </Button>
        <AlertTriangle className="size-6 text-warning/75" />
      </div>
    </div>
  );
};

const CommercialActivityNoContract = () => {
  const { job } = useJobContext();

  if (job.partial) return null;

  return (
    <div className="mb-4 flex grow justify-between rounded-lg border border-warning/50 bg-secondary/50">
      <div className="grow px-3 py-2">
        <p>No commercial contract set</p>
        <p className="text-sm font-medium dark:text-gray-400">
          {job.client.name}
        </p>
      </div>
      <div className="flex items-center gap-x-4 pr-3">
        <ContractSelector />
        <AlertTriangle className="size-6 text-warning/75" />
      </div>
    </div>
  );
};

const CommercialActivityPreApproved = () => {
  const { job } = useJobContext();

  if (job.partial) return null;

  return (
    <div>
      <div className="mb-4 flex gap-x-2">
        <div className="flex rounded-lg border bg-secondary">
          <p className="border-r px-2 py-1.5 text-xs font-semibold uppercase text-gray-500 dark:text-gray-400">
            Contract
          </p>
          <p className="px-2 py-1 text-sm font-semibold">
            {job.commercialActivity.contract.name}
          </p>
        </div>
        <Badge className="rounded-lg" variant="outline">
          <Check className="mr-2 h-4 w-4" />
          Callout rate applied
        </Badge>
      </div>
      <div className="mb-4 grow rounded-lg border bg-secondary/50 p-2">
        <div className="overflow-hidden rounded-md border">
          <JobCommercialProvider>
            <CommercialActivityProvider
              loader={
                <Loader wrapperClassname="m-4 bg-transparent">
                  Loading activity...
                </Loader>
              }
            >
              <ActivityTableProvider
                type={CommercialType.Labor}
                color="#fff"
                loader={
                  <Loader wrapperClassname="m-4 bg-transparent">
                    Loading table...
                  </Loader>
                }
              >
                <CommercialActivityPreApprovedRenderer />
              </ActivityTableProvider>
            </CommercialActivityProvider>
          </JobCommercialProvider>
        </div>
      </div>
    </div>
  );
};

const CommercialActivityPreApprovedRenderer = () => {
  const { valueLines } = useActivityTableContext();
  return (
    <div>
      <ValueHeader estimate />
      {valueLines.map((line) => (
        <ValueLine key={line.id} estimate line={line} />
      ))}
    </div>
  );
};

const CommercialActivityValid = () => {
  const { job } = useJobContext();

  if (job.partial) return null;

  return (
    <div className="mb-4 grow rounded-lg border bg-secondary/50 px-3 py-2">
      <p>{job.commercialActivity.contract.name}</p>
      <p className="text-sm font-medium dark:text-gray-400">
        {job.client.name}
      </p>
    </div>
  );
};

const ContractSelector = () => {
  const { job } = useJobContext();

  const { data } = useQuery<{ commercialContracts: CommercialContract[] }>(
    gql`
      query GetCommercialContracts($clientId: ID!) {
        commercialContracts(clientId: $clientId) {
          id
          name
          description
          dateStart
          dateEnd
        }
      }
    `,
    {
      variables: job.partial
        ? undefined
        : {
            clientId: job.client.id,
          },
    },
  );

  const [update] = useMutation(
    gql`
      mutation UpdateJobCommercialContract($id: ID!, $contract: String!) {
        updateJob(
          jobId: $id
          field: "commercial_contract_id"
          value: $contract
        ) {
          job {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnValueChange = async (value: string) => {
    if (job.partial) return;

    const { data, errors } = await update({
      variables: {
        id: job.id,
        contract: value,
      },
    });

    if (data) {
      await job.refetch();
      toast.success('Contract updated');
    }

    if (errors) {
      toast.error('Failed to update contract');
    }
  };

  if (job.partial || !data) return null;

  return (
    <Select
      value={job.commercialActivity?.contract?.id}
      onValueChange={handleOnValueChange}
    >
      <SelectTrigger className="h-[34px] w-auto">
        <div className="flex h-[34px] items-center gap-x-1 pr-2 text-xs">
          <p className="text-gray-400 dark:text-white/50">Select contract: </p>
          <SelectValue placeholder="No contract selected" />
        </div>
      </SelectTrigger>
      <SelectContent>
        {data.commercialContracts.map((contract) => (
          <SelectItem key={contract.id} value={contract.id}>
            {contract.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default InstantiationCommercial;
