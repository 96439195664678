import { createContext, useContext } from 'react';
import { useCommercialEstimates } from './hooks';

export const CommercialEstimatesContext = createContext(
  {} as ReturnType<typeof useCommercialEstimates>['context'],
);

export const useCommercialEstimatesContext = () => {
  return useContext(CommercialEstimatesContext);
};
