import { gql, useMutation } from '@apollo/client';
import { useParentStore } from 'lib/hooks';
import { RosterExceedance } from 'lib/types';
import { RosterReviewState } from '../../state';
import {
  CreateRosterExceedanceMutationData,
  CreateRosterExceedanceMutationVariables,
  UpdateRosterExceedanceMutationData,
  UpdateRosterExceedanceMutationVariables,
} from './types';

export const useApproveExceedance = () => {
  const useStore = useParentStore<RosterReviewState>();

  const {
    onApproveRosterExceedance,
    onUpdateRosterExceedanceReview,
    onDeleteRosterExceedanceReview,
  } = useStore((state) => ({
    onApproveRosterExceedance: state.onApproveRosterExceedance,
    onUpdateRosterExceedanceReview: state.onUpdateRosterExceedanceReview,
    onDeleteRosterExceedanceReview: state.onDeleteRosterExceedanceReview,
  }));

  const [createRosterExceedance, { loading: isApproving }] = useMutation<
    CreateRosterExceedanceMutationData,
    CreateRosterExceedanceMutationVariables
  >(gql`
    mutation (
      $rosterDutyId: ID!
      $exceedanceType: String!
      $note: String!
      $files: [ObjectInput!]!
    ) {
      createRosterExceedanceReview(
        rosterDutyId: $rosterDutyId
        exceedanceType: $exceedanceType
        note: $note
        files: $files
      ) {
        rosterExceedanceReview {
          id
          duty {
            id
          }
          type
          note
          files {
            id
            name
            type
            size
          }
        }
      }
    }
  `);

  const handleApproveExceedance = async (
    rosterExceedance: RosterExceedance,
    values: Pick<CreateRosterExceedanceMutationVariables, 'note' | 'files'>,
  ) => {
    const { data } = await createRosterExceedance({
      variables: {
        rosterDutyId: rosterExceedance.duty.id,
        exceedanceType: rosterExceedance.offence,
        note: values.note,
        files: values.files,
      },
    });

    if (data) onApproveRosterExceedance(data);
  };

  const [updateRosterExceedance, { loading: isUpdating }] = useMutation<
    UpdateRosterExceedanceMutationData,
    UpdateRosterExceedanceMutationVariables
  >(gql`
    mutation ($id: ID!, $note: String!, $files: [ObjectInput!]!) {
      updateRosterExceedanceReview(
        rosterExceedanceReviewId: $id
        note: $note
        files: $files
      ) {
        rosterExceedanceReview {
          id
          duty {
            id
          }
          type
          note
          files {
            id
            name
            type
            size
          }
        }
      }
    }
  `);

  const handleUpdateExceedance = async (
    values: Pick<
      UpdateRosterExceedanceMutationVariables,
      'id' | 'note' | 'files'
    >,
  ) => {
    const { data } = await updateRosterExceedance({
      variables: {
        id: values.id,
        note: values.note,
        files: values.files,
      },
    });

    if (data) onUpdateRosterExceedanceReview(data);
  };

  const [deleteRosterExceedance, { loading: isDeleting }] = useMutation(gql`
    mutation ($id: ID!) {
      deleteRosterExceedanceReview(rosterExceedanceReviewId: $id) {
        id
      }
    }
  `);

  const handleDeleteExceedance = async (id: string) => {
    const { data } = await deleteRosterExceedance({
      variables: {
        id,
      },
    });

    if (data) onDeleteRosterExceedanceReview(data);
  };

  return {
    isApproving,
    isUpdating,
    isDeleting,
    approveExceedance: handleApproveExceedance,
    updateExceedance: handleUpdateExceedance,
    deleteExceedance: handleDeleteExceedance,
  };
};
