import React from 'react';
import { AssignmentResourceType } from '../../../../../../../ClientJob/pages/JobTaskAssignment/types';
import { useClientActivityContext } from '../../../../hooks';

const ColumnRenderer = ({
  record,
}: {
  record: any;
  row: { index: number };
}) => {
  if (record.type === AssignmentResourceType.User) {
    return (
      <div className="flex h-full flex-col gap-y-2 px-4">
        <div>
          <p className="text-sm font-medium">{record.name}</p>
          <p className="text-xs text-gray-400">Staff member</p>
        </div>
      </div>
    );
  }
  return null;
};

export default ColumnRenderer;
