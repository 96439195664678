import { CSSProperties, useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { EventModel } from '@bryntum/schedulerpro-thin';
import { useJobContext } from '../../../../sections/ClientJob/context';
import { useSchedulerContext } from '../../hooks';
import { SchedulerDuty, Update, UpdateStatus, UpdateType } from '../../types';
import { prepareSubcontractorDuty, prepareTimesheetDuty } from '../../utils';
import { APPLY_JOB_TASK_ASSIGNMENT_UPDATE } from './query';
import {
  ApplyJobTaskAssignmentUpdateData,
  ApplyJobTaskAssignmentUpdateVariables,
} from './types';

export const useControlsPending = () => {
  const { job } = useJobContext();
  const { project, updates } = useSchedulerContext();

  const [applyUpdate] = useMutation<
    ApplyJobTaskAssignmentUpdateData,
    ApplyJobTaskAssignmentUpdateVariables
  >(APPLY_JOB_TASK_ASSIGNMENT_UPDATE, { errorPolicy: 'all' });

  const [open, setOpen] = useState<boolean>(false);
  const handleOnOpenChange = useCallback((open: boolean) => {
    setOpen(open);
  }, []);

  const handleOnRevert = useCallback((update: Update) => {
    return () => {
      updates.remove(update.diff.id);
      const event = project.eventStore.getById(update.diff.id) as EventModel;
      switch (update.type) {
        case UpdateType.Create: {
          event.remove();
          break;
        }
        case UpdateType.Update:
        case UpdateType.UpdateTransfer: {
          if (update.duty.dateTimeStart) {
            event.startDate = update.duty.dateTimeStart.toJSDate();
          }
          if (update.duty.dateTimeEnd) {
            event.endDate = update.duty.dateTimeEnd.toJSDate();
          }
          if (update.duty.user) {
            event.resourceId = update.duty.user.id;
          }
          break;
        }
        case UpdateType.Delete: {
          break;
        }
      }
    };
  }, []);

  const handleConfirmUpdate = async (update: Update) => {
    if (job.partial) return;

    updates.mark(update.diff.id, UpdateStatus.Applying);

    const { data, errors } = await applyUpdate({
      variables: {
        jobTaskId: update.duty.activity.jobTask.id,
        update: {
          type: update.type,
          duty: {
            id: update.duty.id,
            dateTimeStart: update.duty.dateTimeStart,
            dateTimeEnd: update.duty.dateTimeEnd,
            user: update.duty.user?.id,
            subcontractor: update.duty.subcontractor?.id,
          },
          diff: {
            id: update.diff.id,
            dateTimeStart: update.diff.dateTimeStart,
            dateTimeEnd: update.diff.dateTimeEnd,
            user: update.diff.user?.id,
            subcontractor: update.diff.subcontractor?.id,
            interactions: update.diff.interactions,
          },
        },
      },
    });

    if (data) {
      updates.mark(update.diff.id, UpdateStatus.Applied);
      updates.callbacks.onSuccess?.(update);

      if (update.type === UpdateType.Delete) {
        project.eventStore.remove(update.duty.id);
      } else if (data.applyJobTaskAssignmentUpdate.timesheetDuty) {
        const event = project.eventStore.getById(
          update.duty.id,
        ) as SchedulerDuty;
        const duty = data.applyJobTaskAssignmentUpdate.timesheetDuty;
        event.timesheetDuty = prepareTimesheetDuty(duty, job);
      } else if (data.applyJobTaskAssignmentUpdate.subcontractorDuty) {
        const event = project.eventStore.getById(
          update.duty.id,
        ) as SchedulerDuty;
        const duty = data.applyJobTaskAssignmentUpdate.subcontractorDuty;
        event.subcontractorDuty = prepareSubcontractorDuty(duty, job);
      }
    }

    if (errors) {
      updates.mark(update.diff.id, UpdateStatus.Error, [...errors]);
      updates.callbacks.onError?.(update, errors);
    }
  };

  const handleOnConfirm = (update: Update) => {
    return () => handleConfirmUpdate(update);
  };

  const handleOnConfirmAll = () => {
    return Promise.all(updates.pending.map(handleConfirmUpdate));
  };

  const confirmAllDisabled = updates.pending.length === 0;

  return {
    open,
    confirmAllDisabled,
    onRevert: handleOnRevert,
    onConfirm: handleOnConfirm,
    onConfirmAll: handleOnConfirmAll,
    onOpenChange: handleOnOpenChange,
  };
};

export const usePendingStyle = (
  update: Update,
  default_: Pick<CSSProperties, 'backgroundColor' | 'borderColor'>,
) => {
  return useMemo(() => {
    switch (update.status) {
      case UpdateStatus.Applied:
        return {
          backgroundColor: 'rgba(0, 180, 75, 0.1)',
          borderColor: 'rgba(0, 180, 75, 0.75)',
        };
      case UpdateStatus.Error:
        return {
          backgroundColor: 'rgba(148, 34, 34, 0.1)',
          borderColor: 'rgba(148, 34, 34, 0.75)',
        };
      default:
        return default_;
    }
  }, [update.status, default_]);
};
