import React from 'react';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import UserDangerZone from '@/components/ui/user/components/UserDangerZone';
import UserResourceRates from '@/components/ui/user/components/UserResourceRates';
import { useUserSheet } from '@/components/ui/user/hooks';
import { PageLoader } from 'lib/shared';
import UserPhoto from '../../../../shared/UserPhoto';
import { UserSheetContext } from './context';

export type UserSheetProps = React.PropsWithChildren<{
  id: string;
}>;

export const UserSheet = ({ children, id }: UserSheetProps) => {
  const { sheet, loading, user } = useUserSheet(id);
  return (
    <UserSheetContext.Provider value={{ sheet, user: user! }}>
      <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
        <SheetTrigger asChild>{children}</SheetTrigger>
        <SheetContent className="w-[500px] min-w-[500px] p-0">
          {loading ? (
            <PageLoader>Loading user...</PageLoader>
          ) : (
            user && (
              <div>
                <div className="mb-4 flex flex-col gap-y-2 border-b p-4">
                  <UserPhoto
                    className="size-16"
                    user={user}
                    avatarClassName="size-16"
                  />
                  <h1 className="text-xl font-semibold">{user?.name}</h1>
                  <p className="text-sm text-gray-400 dark:text-gray-400">
                    {user.email}
                  </p>
                </div>
                <div className="mb-4 border-b px-4 pb-4">
                  <UserResourceRates />
                </div>
                <div className="px-4">
                  <UserDangerZone />
                </div>
              </div>
            )
          )}
        </SheetContent>
      </Sheet>
    </UserSheetContext.Provider>
  );
};
