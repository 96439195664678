import React from 'react';
import ReportSection from '../../../../../../../ReportSection';
import ReportText from '../../../../../../../ReportText';
import { FormFieldComponentProps } from '../../types';

const FieldComment = ({ item }: FormFieldComponentProps) => {
  return (
    <ReportSection title="Comment">
      <ReportText
        style={{
          fontSize: 12,
          padding: 12,
          lineHeight: '1.5',
          whiteSpace: 'pre-line',
        }}
      >
        {item.data.value}
      </ReportText>
    </ReportSection>
  );
};

export default FieldComment;
