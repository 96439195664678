import React, { Fragment } from 'react';
import { ArrowDown } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Loader } from '@/components/ui/loading';
import { cn } from '@/lib/utils';
import { useActionContext } from '../../context';
import { flatten } from '../../utils';
import { parse, renderNodeDescription } from '../ActionDryrun/utils';

const ActionPerformPending = () => {
  const { state } = useActionContext();
  if (!state.representation) return null;
  return (
    <>
      <div className="flex flex-col items-center gap-y-2 rounded-md border bg-secondary p-4 bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-background">
        <Badge variant="warning">Started</Badge>
        <ArrowDown className="h-4 w-4 text-gray-500 dark:text-gray-400" />
        {state.representation.nodes
          .flatMap(flatten)
          .filter((node) => !!node.label || !!node.description)
          .map((node, index, nodes) => {
            const status = state.nodeStatus[node.humanId];
            return (
              <Fragment key={node.id}>
                <div
                  className={cn(
                    'rounded border border-background bg-background/75 p-4 text-center',
                    { 'border-success bg-success/25': !!status },
                  )}
                >
                  <p className="dark-text-gray-400 mb-2 text-sm font-semibold">
                    {parse(node.label)}
                  </p>
                  {node.description && renderNodeDescription(node.description)}
                </div>
                {index !== nodes.length - 1 && (
                  <ArrowDown className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                )}
              </Fragment>
            );
          })}
        <ArrowDown className="h-4 w-4 text-gray-500 dark:text-gray-400" />
        <Badge variant="accent">Completed</Badge>
      </div>
      <Button loading size="sm" variant="outline" className="w-full">
        Running
      </Button>
    </>
  );
};

const ActionPerformSuccess = () => {
  return (
    <div>
      <p>Success</p>
    </div>
  );
};

const ActionPerformCleanup = () => {
  return (
    <div>
      <div className="flex h-[200px] w-full items-center justify-center rounded-md border border-dashed">
        <div>
          <Loader className="mx-auto mb-2 h-4 w-4 text-gray-400" />
          <p className="dark:text-white-400 text-xs text-gray-400">
            Cleaning up...
          </p>
        </div>
      </div>
    </div>
  );
};

const ActionPerformError = () => {
  return (
    <div>
      <p>Error</p>
    </div>
  );
};

const ActionPerform = {
  Pending: ActionPerformPending,
  Success: ActionPerformSuccess,
  Cleanup: ActionPerformCleanup,
  Error: ActionPerformError,
};

export default ActionPerform;
