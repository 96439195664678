import { useCallback, useEffect, useState } from 'react';
import { JobTaskForm } from 'lib/types';
import { useJobTaskContext } from '../../../../context';

const debugLength = 29;

export const useTaskForm = (form: JobTaskForm) => {
  const { task } = useJobTaskContext();

  const [open, setOpen] = useState(false);

  const handleOnApprove = useCallback(() => {
    if (task) {
      setOpen(false);
      task.refetch();
    }
  }, [task]);

  const handleOnEdit = useCallback(() => {
    if (task) {
      task.refetch();
    }
  }, [task]);

  useEffect(() => {
    console.debug(
      `[${form.template.name
        .slice(0, debugLength)
        .padEnd(debugLength)}]: Mounted`,
    );
    return () => {
      console.debug(
        `[${form.template.name
          .slice(0, debugLength)
          .padEnd(debugLength)}]: Unmounted`,
      );
    };
  }, []);

  useEffect(() => {
    if (open) {
      console.debug('\n');
      console.debug(
        `[${form.template.name
          .slice(0, debugLength)
          .padEnd(debugLength)}]: Opened`,
      );
    } else {
      console.debug(
        `[${form.template.name
          .slice(0, debugLength)
          .padEnd(debugLength)}]: Closed`,
      );
    }
  }, [open]);

  return {
    context: {
      form,
      state: {
        open,
        onOpenChange: setOpen,
        onClose: () => setOpen(false),
      },
      handlers: {
        onApprove: handleOnApprove,
        onEdit: handleOnEdit,
      },
    },
  };
};
