import React from 'react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { UpdateStatus, UpdateType } from '../../../../types';
import { usePendingStyle } from '../../hooks';
import PendingDateTime from '../PendingDateTime';
import PendingDuration from '../PendingDuration';
import PendingStaffMember from '../PendingStaffMember';
import PendingSubcontractor from '../PendingSubcontractor';
import { PendingUpdateProps } from './types';

const PendingUpdate = ({
  update,
  onHighlight,
  onRevert,
  onConfirm,
}: PendingUpdateProps) => {
  const style = usePendingStyle(update, {
    backgroundColor: 'rgba(255, 166, 0, 0.1)',
    borderColor: 'rgba(255, 166, 0, 0.75)',
  });
  return (
    <div className="rounded-md border p-4" style={style}>
      <div className="mb-4 flex items-center justify-between">
        <p className="text-sm font-semibold">
          {update.status ?? 'Pending update'}
        </p>
        <div className="flex gap-x-2">
          <Badge className="h-5" variant="warning">
            Update
          </Badge>
          {update.type === UpdateType.UpdateTransfer && (
            <Badge className="h-5" variant="warning">
              Transfer
            </Badge>
          )}
        </div>
      </div>
      {update.duty.user && update.diff.user && (
        <PendingStaffMember.Diff
          className="mb-4"
          duty={update.duty.user}
          diff={update.diff.user}
        />
      )}
      {update.duty.subcontractor && update.diff.subcontractor && (
        <PendingSubcontractor.Diff
          className="mb-4"
          duty={update.duty.subcontractor}
          diff={update.diff.subcontractor}
        />
      )}
      {update.duty.dateTimeStart && update.diff.dateTimeStart && (
        <PendingDateTime.Diff
          className="mb-4"
          title="Start"
          duty={update.duty.dateTimeStart}
          diff={update.diff.dateTimeStart}
        />
      )}
      {update.duty.dateTimeEnd && update.diff.dateTimeEnd && (
        <PendingDateTime.Diff
          className="mb-4"
          title="End"
          duty={update.duty.dateTimeEnd}
          diff={update.diff.dateTimeEnd}
        />
      )}
      {update.duty.dateTimeStart &&
        update.duty.dateTimeEnd &&
        update.diff.dateTimeStart &&
        update.diff.dateTimeEnd && (
          <PendingDuration.Diff
            className="mb-4"
            duty={update.duty.dateTimeEnd.diff(update.duty.dateTimeStart)}
            diff={update.diff.dateTimeEnd.diff(update.diff.dateTimeStart)}
          />
        )}
      <div className="flex justify-between">
        <div className="flex items-center gap-x-2">
          <Button size="sm" variant="outline" onClick={onHighlight}>
            Highlight
          </Button>
          {update.status === UpdateStatus.Error && (
            <Button size="sm" variant="outline" onClick={onConfirm}>
              Retry
            </Button>
          )}
          {!update.status && (
            <Button size="sm" variant="outline" onClick={onRevert}>
              Revert
            </Button>
          )}
        </div>
        <Button
          size="sm"
          variant="outline"
          disabled={!!update.status}
          isLoading={update.status === UpdateStatus.Applying}
          onClick={onConfirm}
        >
          {!update.status && 'Update'}
          {update.status === UpdateStatus.Applied && 'Updated timesheet duty'}
          {update.status === UpdateStatus.Error && 'Failed'}
        </Button>
      </div>
    </div>
  );
};

export default PendingUpdate;
