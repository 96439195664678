import React from 'react';
import { cn } from '@/lib/utils';
import { useLocationContext } from '../../context';

const LocationAddress = ({ border = false }) => {
  const { location, supplementaryLocation } = useLocationContext();
  const address = location?.address ?? supplementaryLocation?.address;

  const renderRow = (name: string, value: string | undefined) => {
    return (
      <tr>
        <td
          style={{
            paddingRight: 16,
            paddingBottom: 4,
            opacity: 0.75,
            fontWeight: value ? 600 : 400,
          }}
        >
          {name}
        </td>
        <td>{value}</td>
      </tr>
    );
  };

  return (
    <div
      className={cn({
        'padding-[1px] rounded-[1px] border': border,
      })}
    >
      <table>
        <tbody>
          {renderRow('Line one', address?.lineOne)}
          {renderRow('Line two', address?.lineTwo)}
          {renderRow('Town', address?.city)}
          {renderRow('Country', address?.region)}
          {renderRow('Postcode', address?.postcode)}
        </tbody>
      </table>
    </div>
  );
};

export default LocationAddress;
