import React from 'react';
import { TableRenderer, selectColumn } from '@/components/ui/table/table/Table';
import { DateTime } from 'luxon';
import { RosterDutyFieldProps } from './types';

const RosterDutyField = ({
  value,
  rosterDuties,
  onChange,
}: RosterDutyFieldProps) => {
  return (
    <TableRenderer
      simple
      small
      header={false}
      columns={[
        selectColumn(),
        {
          header: 'Date',
          cell: ({ row: { original } }) => (
            <>
              {DateTime.fromISO(original.dateTimeStart).toLocaleString(
                DateTime.DATE_MED,
              )}
            </>
          ),
        },
        {
          header: 'On duty',
          cell: ({ row: { original } }) => (
            <>
              {DateTime.fromISO(original.dateTimeStart).toLocaleString(
                DateTime.TIME_24_SIMPLE,
              )}
            </>
          ),
        },
        {
          header: 'Off duty',
          cell: ({ row: { original } }) => (
            <>
              {DateTime.fromISO(original.dateTimeEnd).toLocaleString(
                DateTime.TIME_24_SIMPLE,
              )}
            </>
          ),
        },
      ]}
      selected={value}
      onSelectedChange={onChange}
      data={rosterDuties}
    />
  );
};

export default RosterDutyField;
