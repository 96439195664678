import React from 'react';
import { Badge } from '@/components/ui/badge';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { DateTime, Interval } from 'luxon';
import { useParentStore } from 'lib/hooks';
import RosterDutyRow, { RosterDutyEmptyRow } from '../RosterDutyRow';
import { RosterDutyTableState } from './state';

const RosterDutyTable = () => {
  const useStore = useParentStore<RosterDutyTableState>();

  const { dateTimeStart, dateTimeEnd, rosterDuties, user } = useStore(
    (state) => ({
      dateTimeStart: state.dateTimeStart,
      dateTimeEnd: state.dateTimeEnd,
      rosterDuties: state.rosterDuties,
      user: state.user,
    }),
  );

  const shifts = Interval.fromDateTimes(dateTimeStart, dateTimeEnd).splitBy({
    days: 1,
  });

  return (
    <Table>
      <TableHeader className="h-[52px] whitespace-nowrap border-b">
        <TableRow>
          <TableHead className="pl-4">
            <Badge
              variant="outline"
              className="h-[36px] w-[100px] justify-center rounded-md text-center"
            >
              Shift #
            </Badge>
          </TableHead>
          <TableHead>Date</TableHead>
          <TableHead>Weekday</TableHead>
          <TableHead>Day/night</TableHead>
          <TableHead>Total commute (mins)</TableHead>
          <TableHead>On duty</TableHead>
          <TableHead>Off duty</TableHead>
          <TableHead>Duration [mins]</TableHead>
          <TableHead>Fatigue index</TableHead>
          <TableHead>Risk index</TableHead>
          <TableHead className="pr-4">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {shifts.map((day, index) => {
          if (!day.start || !user) return null;
          const isLast = index === shifts.length - 1;
          const rosterDuty = (rosterDuties ?? []).find((innerRosterDuty) => {
            if (!day.start) return false;
            return DateTime.fromISO(innerRosterDuty.dateTimeStart).hasSame(
              day.start,
              'day',
            );
          });
          return rosterDuty ? (
            <RosterDutyRow
              key={day.toISO()}
              index={index}
              isLast={isLast}
              day={day.start}
              rosterDuty={rosterDuty}
            />
          ) : (
            <RosterDutyEmptyRow
              key={day.toISO()}
              index={index}
              isLast={isLast}
              day={day.start}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

export default RosterDutyTable;
