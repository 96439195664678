import { useMutation } from '@tanstack/react-query';
import { platformClient } from '../../../../app/platform';
import { useClient } from '../../../../sections/Client/hooks';

const useCreateFormTemplate = (
  onCompleted: (data: any) => void,
  constructFormTemplateId: (name: string) => string,
) => {
  const create = useMutation({
    mutationKey: ['createFormTemplate'],
    mutationFn: platformClient.createFormTemplate(),
  });

  const handleOnCreate = async (values: { name: string; scope?: string }) => {
    const data = await create.mutateAsync({
      variables: {
        formTemplateId: constructFormTemplateId(values.name),
        formTemplateName: values.name,
        formTemplateScope: values.scope,
      },
    });

    if (data) onCompleted(data);
  };

  return { isCreating: create.isPending, handleOnCreate };
};

export const useCreateClientFormTemplate = (
  onCompleted: (data: any) => void,
) => {
  const client = useClient();
  return useCreateFormTemplate(
    onCompleted,
    (name) => `${client?.slug}::${name.toLowerCase().replace(/\s/g, '-')}`,
  );
};

export default useCreateFormTemplate;
