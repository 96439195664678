import React, { useEffect, useMemo, useState } from 'react';
import { Image as PdfImage, View } from '@react-pdf/renderer';
import parse, { DOMNode, Element, domToReact } from 'html-react-parser';
import DOMPurify from 'dompurify';
import { Image, ReportSvg } from 'lib/shared';
import { PlatformObjectAnnotation, PlatformObjectImageData } from 'lib/types';
import { useReportContext } from '../../context';
import ReportComponent from '../ReportComponent';
import { ReportImageProps } from './types';

const ReportImage = (props: ReportImageProps) => {
  return (
    <ReportComponent
      editComponent={EditComponent}
      viewComponent={ViewComponent}
      props={props}
    />
  );
};

const EditComponent = ({
  ignoreOnMount = false,
  object,
  alt,
  src: srcFull,
  srcCompressed,
  onError,
  ...props
}: ReportImageProps) => {
  const { registerImage } = useReportContext();

  const [fallback, setFallback] = useState(false);
  const src = useMemo(() => {
    if (fallback) {
      return srcFull;
    }
    return srcCompressed || srcFull;
  }, [fallback, srcFull, srcCompressed]);

  useEffect(() => {
    if (ignoreOnMount) return;
    registerImage({
      id: object.id,
      alt,
      src: srcFull,
      srcCompressed,
      name: object.name,
      data: object.data,
    });
  }, [object?.id, src, registerImage]);

  return (
    <Image
      {...props}
      alt={alt}
      name={object?.name}
      data={object?.data}
      sheet
      sources={{
        full: {
          uri: srcFull,
        },
        compressed: {
          uri: srcCompressed,
        },
      }}
      onError={(event) => {
        console.debug(
          'Failed to load compressed source. Falling back to full size.',
          event,
        );
        setFallback(true);
      }}
    />
  );
};

const ViewComponent = ({
  ignoreOnMount = false,
  style,
  src,
  srcCompressed,
  object,
}: ReportImageProps) => {
  const data = useMemo<PlatformObjectImageData>(() => {
    if (!object?.data) return { annotations: [] };
    try {
      return JSON.parse(object.data);
    } catch (error) {
      return { annotations: [] };
    }
  }, [object]);

  return (
    <View
      style={{
        position: 'relative',
        ...style,
        backgroundColor: 'rgba(255, 0, 0, 0.1)',
      }}
    >
      <PdfImage
        cache={false}
        src={undefined}
        style={{
          ...style,
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        }}
        source={{
          uri: ignoreOnMount ? src : srcCompressed,
          body: undefined,
          method: 'GET',
          headers: {
            Pragma: 'no-cache',
            'Cache-Control': 'no-cache',
            'Access-Control-Allow-Origin': '*',
          },
        }}
      />
      {data.annotations.length > 0 && (
        <View
          style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}
        >
          {data.annotations.map((annotation) => (
            <ViewComponentAnnotation
              key={annotation.id}
              width={style.width}
              height={style.height}
              annotation={annotation}
            />
          ))}
        </View>
      )}
    </View>
  );
};

type ViewComponentAnnotationProps = {
  style?: any;
  width: number;
  height: number;
  annotation: PlatformObjectAnnotation;
};

const ViewComponentAnnotation = ({
  style,
  width,
  height,
  annotation,
}: ViewComponentAnnotationProps) => {
  const { theme } = useReportContext();

  const replace = (node: DOMNode) => {
    if (!(node instanceof Element)) {
      return <></>;
    }
    if (node.name === 'svg') {
      const scale = annotation.width / width;
      const scaledWidth = annotation.width * scale;
      const scaledHeight = annotation.height * scale;
      const top = -((1 / scale) * annotation.height - height) / 2;

      return (
        <ReportSvg
          {...(node.attribs as { width: string; height: string })}
          viewBox={`0 0 ${scaledWidth} ${scaledHeight}`}
          style={{
            pointerEvents: 'none',
            position: 'absolute',
            left: 0,
            top,
            width: annotation.width,
            height: annotation.height,
          }}
        >
          {domToReact(node.children, { replace })}
        </ReportSvg>
      );
    }
    if (node.name === 'path') {
      return (
        <ReportSvg.Path
          {...node.attribs}
          d={node.attribs.d}
          fill={theme.colors.annotation}
          stroke={theme.colors.annotation}
        />
      );
    }
    if (node.name === 'rect') {
      return (
        <ReportSvg.Rect
          {...(node.attribs as {
            width: string;
            height: string;
          })}
          fill="none"
        />
      );
    }
    if (node.name === 'g') {
      return (
        <ReportSvg.G {...node.attribs}>
          {domToReact(node.children, { replace })}
        </ReportSvg.G>
      );
    }
    return node;
  };

  const safe = DOMPurify.sanitize(annotation.svg);
  const html = parse(safe, { replace });

  return (
    <View
      style={{
        ...style,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 30,
        backgroundColor: 'transparent',
      }}
    >
      {html}
    </View>
  );
};

export default ReportImage;
