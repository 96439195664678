import React, { Fragment } from 'react';
import { MousePointer2 } from 'lucide-react';
import { Marker } from 'react-map-gl';
import { cn } from '@/lib/utils';
import { useActivityMapContext } from '../../context';

const MapDrivers = () => {
  const { driverUpdates, getDriversAtCoords, getSize } =
    useActivityMapContext();
  const driversAtHeadOffice = getDriversAtCoords(
    51.43675904815511,
    0.07505702683621203,
    0.005,
  );
  return (
    <>
      {Object.entries(driverUpdates)
        .filter(
          ([_, update]) =>
            !driversAtHeadOffice
              .map((update) => update.vehicle.vin)
              .includes(update.vehicle.vin),
        )
        .map(([key, driverUpdate]) => (
          <Fragment key={key}>
            {driverUpdate.data.displayState && (
              <Marker
                longitude={driverUpdate.longitude}
                latitude={driverUpdate.latitude}
              >
                <div className="relative size-8">
                  <div
                    className={cn('absolute inset-0 rounded-full border', {
                      'border-destructive bg-destructive/25':
                        driverUpdate.data.displayState === 'Stop',
                      'border-success bg-success/50':
                        driverUpdate.data.displayState === 'Moving',
                    })}
                  />
                  <div
                    className={cn(
                      'absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full border-2',
                      {
                        'border-destructive bg-destructive/25':
                          driverUpdate.data.displayState === 'Stop',
                        'border-success/50':
                          driverUpdate.data.displayState === 'Moving',
                      },
                    )}
                    style={{
                      width: getSize(driverUpdate.time),
                      height: getSize(driverUpdate.time),
                    }}
                  />
                </div>
              </Marker>
            )}
            <Marker
              longitude={driverUpdate.longitude}
              latitude={driverUpdate.latitude}
              rotation={driverUpdate.direction + 45}
              rotationAlignment="map"
            >
              <MousePointer2 fill="white" className="h-4 w-4" />
            </Marker>
            {driverUpdate.driver && (
              <Marker
                offset={[0, -30]}
                longitude={driverUpdate.longitude}
                latitude={driverUpdate.latitude}
              >
                <span className="font-semibold dark:[text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">
                  {driverUpdate.driver.driverFirstName}{' '}
                  {driverUpdate.driver.driverLastName}
                </span>
              </Marker>
            )}
          </Fragment>
        ))}
    </>
  );
};

export default MapDrivers;
