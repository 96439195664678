import { TableRenderer } from '@/components/ui/table/table/Table';
import { useSubcontractorContext } from '../../../../context';
import LocationsDetail from '../LocationsDetail';

const LocationsTable = () => {
  const { subcontractor } = useSubcontractorContext();

  if (!subcontractor) return null;

  return (
    <TableRenderer
      header={false}
      headerClassName="bg-background-secondary"
      wrapperClassName="border-none"
      simple
      initialSorting={[{ id: 'name', desc: false }]}
      columns={[
        {
          id: 'name',
          accessorKey: 'name',
          header: 'Name',
          className: 'pl-4',
          cell: ({ row }) => <LocationsDetail location={row.original} />,
        },
      ]}
      data={subcontractor.locations}
    />
  );
};

export default LocationsTable;
