import { useCallback, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useYjs } from 'lib/hooks';
import { ReportCache } from 'lib/shared';
import { JobReportVersion } from 'lib/types';
import { useJobReportContext } from '../../context';
import {
  JobReportVersionMode,
  JobReportVersionQueryData,
  JobReportVersionQueryVariables,
} from './types';

export const useJobReportVersion = () => {
  const { version } = useJobReportContext();

  const [cache] = useState(new ReportCache());
  const [mode, setMode] = useState<JobReportVersionMode>(
    JobReportVersionMode.Live,
  );

  const { data, refetch } = useQuery<
    JobReportVersionQueryData,
    JobReportVersionQueryVariables
  >(
    gql`
      query GetJobReport($jobReportVersionId: ID!) {
        jobReportVersion(jobReportVersionId: $jobReportVersionId) {
          id
          number
          sourceObject {
            id
            name
          }
          statusInternal
          statusExternal
          externalId
        }
      }
    `,
    {
      skip: !version,
      variables: { jobReportVersionId: version?.id! },
    },
  );

  const [images, setImages] = useState<string[]>([]);
  const registerImage = useCallback(
    (id: string) => {
      setImages((prevImages) => {
        if (!prevImages.includes(id)) {
          return [...prevImages, id];
        }
        return prevImages;
      });
    },
    [setImages],
  );

  const [documents, setDocuments] = useState<string[]>([]);
  const registerDocument = useCallback(
    (id: string) => {
      setDocuments((prevDocuments) => {
        if (!prevDocuments.includes(id)) {
          return [...prevDocuments, id];
        }
        return prevDocuments;
      });
    },
    [setDocuments],
  );

  const { doc, store, isReady } = useYjs<{
    selected: string[];
    meta: { isCompleted: boolean; isApproved: boolean };
  }>(
    'jobs::job-report-version',
    version ? `${version.id}::editor` : undefined,
    { selected: [], meta: {} } as any,
    {},
  );

  if (!data?.jobReportVersion || !isReady) {
    return {
      context: {
        mode: {
          value: mode,
          set: setMode,
        },
        synced: {
          store: store as {
            selected: string[];
            meta: { isCompleted: boolean; isApproved: boolean };
          },
          doc,
        },
        state: {
          ready: false,
          images,
          documents,
        },
        cache,
        version: null as JobReportVersion | null,
        handlers: {
          registerImage,
          registerDocument,
        },
      },
    };
  }

  return {
    context: {
      mode: {
        value: mode,
        set: setMode,
      },
      synced: {
        store: store as {
          selected: string[];
          meta: { isCompleted: boolean; isApproved: boolean };
        },
        doc,
      },
      state: {
        ready: isReady,
        images,
        documents,
      },
      cache,
      version: {
        ...data.jobReportVersion,
        refetch,
      } as JobReportVersion | null,
      handlers: {
        registerImage,
        registerDocument,
      },
    },
  };
};
