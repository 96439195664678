import React from 'react';
import { Circle, DotIcon } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { useJobContext } from '../../../../context';

const InstantiationHeader = () => {
  const { job } = useJobContext();

  if (job.partial) return null;

  return (
    <div className="flex justify-between border-b p-4">
      <div className="flex items-center gap-x-2">
        <h1
          className="text-[24px] font-medium text-gray-700 dark:text-gray-200"
          style={{ fontFamily: 'Roboto Mono' }}
        >
          {job.reference}
        </h1>
        <Badge
          variant="outline"
          className="rounded-md text-gray-500 dark:text-gray-400"
          style={{ textTransform: 'capitalize' }}
        >
          {job.scope.split('::').at(-1)?.replace(/-/g, ' ')}
        </Badge>
        <Badge
          className="flex items-center gap-x-2 rounded-md text-gray-500 dark:text-gray-400"
          variant="outline"
        >
          {job.priority.responseNameShort}
          <DotIcon className="h-2 w-2" />
          {job.priority.completionNameShort}
        </Badge>
      </div>
      <div>
        <Badge
          className="gap-x-1 border-success/25 text-success/75"
          variant="outline"
        >
          <div className="relative h-2 w-2">
            <Circle className="absolute inset-0 h-2 w-2 fill-success/75 stroke-success/75" />
            <Circle className="absolute inset-0 h-2 w-2 animate-ping fill-success/75 stroke-success/75" />
          </div>
          Connected
        </Badge>
      </div>
    </div>
  );
};

export default InstantiationHeader;
