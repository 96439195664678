import React, { useCallback, useRef } from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { useSyncedStore } from '@syncedstore/react';
import { useClickAway } from 'react-use';
import { cn } from '@/lib/utils';
import chroma from 'chroma-js';
import { ItemType, flatten, getItemDepth } from 'external/Tree';
import { useFormTemplateContext } from '../../context';
import BlockButtons from '../BlockButtons';
import BlockConfigurator from '../BlockConfigurator';
import BlockControls from '../BlockControls';
import BlockInput from '../BlockInput';
import BlockLogic from '../BlockLogic';
import BlockSidecar from '../BlockSidecar';
import { useBlock } from './hooks';
import { BlockProps } from './types';

const colors = chroma
  .cubehelix()
  .start(200)
  .rotations(-0.35)
  .gamma(0.7)
  .lightness([0.3, 0.8])
  .scale()
  .correctLightness()
  .colors(5);

const Block = ({
  item,
  isLast,
  draggableStateSnapshot,
  dragHandleProps,
}: BlockProps) => {
  const { aboveItemDepth, depth, belowItemDepth, style, onFocus } = useBlock(
    item as any,
  );

  const {
    store,
    focus,
    collapsed,
    onBlur,
    getShouldParentIncreaseDepth,
    getLogicFor,
  } = useFormTemplateContext();

  const { items } = useSyncedStore(store);

  const focusedParent = items?.[items?.[focus || '']?.parentId];
  const focusedParentContext =
    focusedParent && focusedParent.type !== ItemType.PAGE
      ? flatten(
          focusedParent,
          items,
          () => true,
          () => true,
        ).map(({ id }) => id)
      : [];

  const focusedParentDepth = focusedParent
    ? getItemDepth(focusedParent, items, getShouldParentIncreaseDepth)
    : 0;

  const color = colors[depth % 5];

  const isCollapsed = collapsed.includes(item.id);
  const isFocused = focus === item.id;
  const border = `var(--border)`;

  const isItemBelowAdjacent = belowItemDepth === depth;

  const isItemAboveDeeper = aboveItemDepth > depth;
  const isItemBelowDeeper = belowItemDepth > depth;

  const logic = getLogicFor(item);
  const logicHasChildBlocks = (logic?.children ?? []).some(
    (childId) =>
      items[childId]?.type === ItemType.BLOCK ||
      items[childId]?.type === ItemType.CONTAINER,
  );

  const itemHasLogic = item.children.some(
    (childId) => items[childId]?.type === ItemType.LOGIC,
  );

  const getBorderBottom = useCallback(
    (component: 'input' | 'controls' | 'logic-selector' | 'logic-renderer') => {
      if (component === 'input') {
        if (isFocused) return border;
        if (isLast) return false;
        if (
          isItemBelowAdjacent &&
          (item.children.length === 0 || !logicHasChildBlocks)
        )
          return border;
        if (!isItemBelowDeeper) return false;
        if (item.children.length === 0) return border;
        return border;
      }

      if (component === 'controls') {
        if (
          item.type === ItemType.CONTAINER &&
          item.children.length > 0 &&
          !isCollapsed
        )
          return border;
        if (isFocused && (isItemBelowDeeper || isItemBelowAdjacent))
          return border;
        if (itemHasLogic) return border;
        if (!isItemBelowDeeper || isItemBelowAdjacent) return false;
        return false;
      }

      if (component === 'logic-selector') {
        return border;
      }

      if (component === 'logic-renderer') {
        if (!logicHasChildBlocks && isItemBelowAdjacent) return border;
        if (!logicHasChildBlocks) return false;
        return border;
      }
    },
    [
      isFocused,
      isCollapsed,
      isItemBelowAdjacent,
      isItemBelowDeeper,
      isLast,
      item.children.length,
      item.type,
      itemHasLogic,
      logicHasChildBlocks,
    ],
  );

  const ref = useRef(null);
  useClickAway(ref, () => onBlur());

  return (
    <div className="relative">
      <AccordionPrimitive.Item ref={isFocused ? ref : null} value={item.id}>
        <AccordionPrimitive.Trigger asChild>
          <div
            className={cn(
              'relative flex justify-between border-x bg-background',
              {
                'border-t': isItemAboveDeeper,
                'border-b': !!getBorderBottom('input'),
              },
            )}
            style={style}
          >
            <BlockButtons
              color={color}
              item={item}
              dragHandleProps={dragHandleProps}
            />
            <BlockInput item={item} onFocus={onFocus(item)} />
            <BlockConfigurator item={item} />
            {!draggableStateSnapshot.isDragging && (
              <BlockSidecar item={item as any} />
            )}
          </div>
        </AccordionPrimitive.Trigger>
        <AccordionPrimitive.Content className="overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
          <div>
            <BlockControls
              item={item as any}
              style={style}
              getBorderBottom={getBorderBottom}
            />
          </div>
        </AccordionPrimitive.Content>
      </AccordionPrimitive.Item>
      <BlockLogic
        item={item as any}
        border={border}
        getBorderBottom={getBorderBottom}
      />
      {depth > 0 && !draggableStateSnapshot.isDragging && (
        <>
          {aboveItemDepth < depth && (
            <div className="absolute right-[100%] top-0 z-[10] h-[8px] w-[40px] bg-secondary" />
          )}
          <div className="absolute right-[calc(100%+16px)] top-0 z-[5] flex h-full gap-x-[37px] bg-secondary">
            {Array.from({ length: depth })
              .map((_, index) => index)
              .map((index) => {
                return (
                  <div key={index} className="relative h-full">
                    <div
                      className="h-full w-[3px] transition-all"
                      style={{
                        backgroundColor: chroma(colors[index % 5])
                          .alpha(
                            focusedParentContext.includes(item.id) &&
                              index === focusedParentDepth
                              ? 1
                              : 0.25,
                          )
                          .hex(),
                      }}
                    />
                    {belowItemDepth < index + 1 && (
                      <div className="absolute bottom-0 left-0 z-[5] h-[8px] w-full bg-secondary" />
                    )}
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default Block;
