import React, { cloneElement } from 'react';
import { ConditionalProps } from './types';

const Conditional = ({
  children,
  wrapper,
  default: default_,
  wrap,
}: ConditionalProps) => {
  if (wrap) {
    return cloneElement(wrapper, { children });
  }

  if (default_) {
    return cloneElement(default_, { children });
  }

  return <>{children}</>;
};

export default Conditional;
