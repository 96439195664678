import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import Logo from '../../theme/logo';
import TopbarBreadcrumb from './components/TopbarBreadcrumb';
import TopbarNavigationLeft from './components/TopbarNavigationLeft';
import TopbarNavigationRight from './components/TopbarNavigationRight';

const LayoutTopbar = () => {
  return (
    <>
      <div className="flex border-b bg-background py-2 pl-3 pr-4">
        <div className="flex grow">
          <Link to="/">
            <Button
              variant="ghost"
              className="mr-1 h-[40px] gap-2 fill-primary px-2 py-1 dark:fill-white"
              style={{
                borderTopLeftRadius: '50% 70%',
                borderTopRightRadius: '50% 70%',
                borderBottomLeftRadius: '60% 100%',
                borderBottomRightRadius: '60% 100%',
              }}
            >
              <Logo />
            </Button>
          </Link>
          <TopbarNavigationLeft />
        </div>
        <TopbarNavigationRight />
      </div>
      <TopbarBreadcrumb />
    </>
  );
};

export default LayoutTopbar;
