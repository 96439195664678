import { createContext, useContext } from 'react';
import { useSubcontractorRequirements } from './hooks';

export const SubcontractorRequirementsContext = createContext(
  {} as ReturnType<typeof useSubcontractorRequirements>['context'],
);

export const useSubcontractorsRequirementsContext = () => {
  return useContext(SubcontractorRequirementsContext);
};
