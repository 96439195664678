import { useRef, useState } from 'react';
import { useCommercialEstimateContext } from '../../../../context';

export const useHeaderDescription = () => {
  const { estimate, handlers } = useCommercialEstimateContext();

  const ref = useRef<HTMLTextAreaElement>(null);

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(estimate?.description);

  return {
    ref,
    state: {
      open,
      value,
    },
    estimate,
    handlers: {
      ...handlers,
      onValueChange: setValue,
      onOpen: () => setOpen(true),
      onClose: () => setOpen(false),
    },
  };
};
