import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useClient } from '../../../../../../hooks';
import { useCommercialContractContext } from '../../context';
import {
  CopyCommercialContractMutationData,
  CopyCommercialContractMutationVariables,
} from './types';

export const useContractCopy = () => {
  const { contract } = useCommercialContractContext();

  const navigate = useNavigate();
  const client = useClient();

  const [open, setOpen] = useState(false);

  const [copy] = useMutation<
    CopyCommercialContractMutationData,
    CopyCommercialContractMutationVariables
  >(
    gql`
      mutation CopyCommercialContract(
        $id: ID!
        $dateStart: DateTime!
        $dateEnd: DateTime!
      ) {
        copyCommercialContract(
          commercialContractId: $id
          dateStart: $dateStart
          dateEnd: $dateEnd
        ) {
          commercialContract {
            id
            slug
            dateStart
            dateEnd
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCopy = async (values: any) => {
    if (!contract) {
      console.error('No contract found');
      return;
    }

    const { data: copyData, errors } = await copy({
      variables: {
        id: contract.id,
        dateStart: values.dateStart,
        dateEnd: values.dateEnd,
      },
    });

    if (copyData) {
      const { slug, dateStart, dateEnd } =
        copyData.copyCommercialContract.commercialContract;
      navigate(client.paths.commercial.contract(slug, dateStart, dateEnd));
      toast.success('Contract copied');
    }

    if (errors) {
      const message = 'Error copying contract';
      console.error(message, errors);
      toast.error(message);
    }
  };

  return {
    dialog: {
      open,
      onOpenChange: setOpen,
    },
    onCopy: handleOnCopy,
  };
};
