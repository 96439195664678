import React from 'react';
import { CheckIcon } from '@radix-ui/react-icons';
import { Progress } from '@/components/ui/progress';
import AppLoginLayout from '../AppLoginLayout';
import { useOnMount } from './hooks';

const AppLoginRedirect = () => {
  const { done, progress } = useOnMount();
  return (
    <AppLoginLayout>
      <div className="flex h-full w-full flex-col bg-background bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-secondary">
        <div className="container mt-[180px] h-full max-w-4xl text-center">
          <div className="mb-8 flex justify-center text-center">
            <div className="flex flex-col gap-y-2">
              <h1 className="text-2xl font-bold">Logging you in...</h1>
              <p className="text-sm text-gray-400">
                Using your Office 365 account
              </p>
              {!done ? (
                <Progress value={progress} />
              ) : (
                <CheckIcon className="mx-auto h-8 w-8 text-green-500 animate-in" />
              )}
            </div>
          </div>
        </div>
      </div>
    </AppLoginLayout>
  );
};

export default AppLoginRedirect;
