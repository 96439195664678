import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Client from '../Client';
import ClientActivity from '../Client/pages/ClientActivity';
import ClientAsset from '../Client/pages/ClientAsset';
import ClientAssets from '../Client/pages/ClientAssets';
import ClientCommercial from '../Client/pages/ClientCommercial';
import ClientCommercialContract from '../Client/pages/ClientCommercial/components/CommercialContract';
import ClientCommercialContracts from '../Client/pages/ClientCommercial/components/CommercialContracts';
import ClientJobs from '../Client/pages/ClientJobs';
import ClientMaintenance from '../Client/pages/ClientMaintenance';
import ClientMaintenanceSeries from '../Client/pages/ClientMaintenance/components/MaintenanceSeries';
import ClientOverview from '../Client/pages/ClientOverview';
import ClientProjects from '../Client/pages/ClientProjects';
import ClientSettings from '../Client/pages/ClientSettings';
import SettingsDelivery from '../Client/pages/ClientSettings/components/SettingsDelivery';
import SettingsForm from '../Client/pages/ClientSettings/components/SettingsForm';
import SettingsForms from '../Client/pages/ClientSettings/components/SettingsForms';
import SettingsGeneral from '../Client/pages/ClientSettings/components/SettingsGeneral';
import SettingsSupplyChain from '../Client/pages/ClientSettings/components/SettingsSupplyChain';
import ClientSite from '../Client/pages/ClientSite';
import ClientSites from '../Client/pages/ClientSites';
import ClientTodos from '../Client/pages/ClientTodos';
import ClientJob from '../ClientJob';
import ClientsOverview from './pages/ClientsOverview';

const Clients = () => {
  return (
    <Routes>
      <Route index element={<ClientsOverview />} />
      <Route element={<Client />}>
        <Route path="/:slug" element={<ClientOverview />} />
        <Route path="/:slug/activity" element={<ClientActivity />} />
        <Route path="/:slug/todos/*" element={<ClientTodos />} />
        <Route path="/:slug/projects/*" element={<ClientProjects />} />
        <Route path="/:slug/assets/*">
          <Route index element={<ClientAssets />} />
          <Route path=":reference" element={<ClientAsset />} />
        </Route>
        <Route path="/:slug/sites/*">
          <Route index element={<ClientSites />} />
          <Route path=":reference" element={<ClientSite />} />
        </Route>
        <Route path="/:slug/commercial">
          <Route index element={<ClientCommercial />} />
          <Route path="contracts" element={<ClientCommercialContracts />} />
          <Route
            path="contracts/:contractSlug/:dateStart/:dateEnd"
            element={<ClientCommercialContract />}
          />
        </Route>
        <Route path="/:slug/maintenance" element={<ClientMaintenance />} />
        <Route
          path="/:slug/maintenance/series/:jobSeriesReference"
          element={<ClientMaintenanceSeries />}
        />
        <Route path="/:slug/jobs" element={<ClientJobs />} />
        <Route path="/:slug/jobs/:reference/*" element={<ClientJob />} />
        <Route path="/:slug/settings" element={<ClientSettings />}>
          <Route index element={<SettingsGeneral />} />
          <Route path="forms" element={<SettingsForms />} />
          <Route path="forms/:formTemplateId" element={<SettingsForm />} />
          <Route path="delivery" element={<SettingsDelivery />} />
          <Route path="supply-chain/*" element={<SettingsSupplyChain />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Clients;
