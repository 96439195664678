import React from 'react';
import { Button } from '@/components/ui/button';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';

export const SheetForm = ({
  open,
  trigger,
  title,
  description,
  submit,
  fields,
  onSubmit,
  onOpenChange,
}: any) => {
  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetTrigger asChild>
        <Button size="sm" variant="outline">
          {trigger}
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>{title}</SheetTitle>
          <SheetDescription>{description}</SheetDescription>
        </SheetHeader>
        <div className="grid gap-4 py-4">
          <FormRenderer fields={fields} onSubmit={onSubmit}>
            {({ onSubmit }) => (
              <SheetFooter className="pt-2">
                <Button size="sm" className="w-full" onClick={onSubmit}>
                  {submit}
                </Button>
              </SheetFooter>
            )}
          </FormRenderer>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default SheetForm;
