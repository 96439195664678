import React from 'react';
import Map, { Marker } from 'react-map-gl';
import { useParams } from 'react-router';
import { Button } from '@/components/ui/button';
import { useTheme } from '@/components/ui/theme';
import { cn } from '@/lib/utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import LocationPicker from '../../../../shared/LocationPicker';
import { useClient } from '../../hooks';
import { ClientSiteQueryData, ClientSiteQueryVariables } from './types';

const ClientSite = () => {
  const client = useClient();
  const params = useParams();

  const { data } = useQuery<ClientSiteQueryData, ClientSiteQueryVariables>(
    gql`
      query GetSite($clientId: ID!, $siteReference: ID!) {
        site(clientId: $clientId, siteReference: $siteReference) {
          id
          reference
          location {
            id
            address {
              lineOne
              lineTwo
              city
              postcode
              country
              formatted
            }
            precise {
              id
              description
              geometry {
                id
                point {
                  latitude
                  longitude
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      skip: !params.reference,
      variables: {
        clientId: client.id,
        siteReference: params.reference as string,
      },
    },
  );

  const [updateSiteLocation] = useMutation(gql`
    mutation UpdateSiteLocation(
      $siteId: ID!
      $siteLocationEstimate: LocationEstimateInput!
    ) {
      updateSiteLocation(
        siteId: $siteId
        siteLocationEstimate: $siteLocationEstimate
      ) {
        site {
          id
        }
      }
    }
  `);

  const { theme } = useTheme();
  const mapStyle =
    theme === 'dark'
      ? 'mapbox://styles/energyenables/clrcv8giv009s01pedl6ecmy5'
      : 'mapbox://styles/energyenables/clqzso8ec009y01pd8b4tatmd';

  if (!data) {
    return (
      <div>
        <div className="flex h-[52px] flex-shrink-0 items-center border-b px-4 py-2">
          <h1 className="text-xl font-bold">{params.reference}</h1>
        </div>
      </div>
    );
  }

  const { site } = data;
  return (
    <div className="flex h-full flex-col">
      <div className="flex h-[52px] flex-shrink-0 items-center border-b px-4 py-2">
        <h1 className="text-xl font-bold">{site.reference}</h1>
      </div>
      <div className="flex grow">
        <div className="basis-1/3 p-4">
          <div>
            <p>
              <span>{site.location?.address?.lineOne}</span>{' '}
              <span>{site.location?.address?.lineTwo}</span>
            </p>
            <p>{site.location?.address?.city}</p>
            <p>{site.location?.address?.postcode}</p>
            <p>{site.location?.address?.country}</p>
          </div>
          <LocationPicker
            value={site.location}
            onChange={async (value) => {
              if (value) {
                await updateSiteLocation({
                  variables: {
                    siteId: site.id,
                    siteLocationEstimate: value,
                  },
                });
              }
            }}
          >
            <Button variant="outline">Select a location</Button>
          </LocationPicker>
        </div>
        <div className="h-full basis-2/3">
          <Map
            style={{ height: '100%', width: '100%' }}
            initialViewState={{
              zoom: 16,
              pitch: 45,
              latitude: 51.5074,
              longitude: 0.1278,
              ...(site.location?.precise?.geometry?.point ?? {}),
            }}
            mapboxAccessToken="pk.eyJ1IjoiZW5lcmd5ZW5hYmxlcyIsImEiOiJjbGpia2NqZ2IxcTcxM2pxeGxvNG5ud2lpIn0.gMXRc_xES-6AlQx1w8MuXw"
            attributionControl={false}
            mapStyle={mapStyle}
          >
            <Marker
              {...(site.location?.precise?.geometry?.point ?? {
                latitude: 51.5074,
                longitude: 0.1278,
              })}
            >
              <div
                className={cn(
                  'relative rounded-full border-2 border-incoming-foreground/50 bg-incoming-foreground/25 p-0 transition-[padding]',
                )}
              >
                <div className="rounded-full bg-incoming-foreground/25 p-3">
                  <div className="relative h-2 w-2 rounded-full bg-incoming-foreground">
                    <span
                      className={cn(
                        'absolute left-1/2 top-1/2 -translate-y-1/2',
                      )}
                    ></span>
                  </div>
                </div>
                <div className="absolute left-full top-0 w-[300px] pl-3">
                  <p className="text-sm font-semibold">
                    {site.location?.address?.lineTwo}
                  </p>
                  <p className="text-xs font-medium leading-5 text-gray-500 dark:text-gray-400">
                    {site.location?.address?.formatted}
                  </p>
                </div>
              </div>
            </Marker>
          </Map>
        </div>
      </div>
    </div>
  );
};

export default ClientSite;
