import React from 'react';
import { useReadOnlyFormTemplate } from 'lib/hooks';
import {
  shouldRenderBase,
  usePages,
} from '../../../../../../../../shared/FormRenderer/parse';
import { TaskFormCompletionDisplay } from '../../../TaskFormCompletion';
import { useFormCompletion } from '../../../TaskFormCompletion/hooks';
import { TaskFormCompletionProps } from './types';

const TaskFormCompletion = ({
  values,
  form,
  small = false,
}: TaskFormCompletionProps) => {
  const { formTemplate } = useReadOnlyFormTemplate(form.template.id);

  const { pages } = usePages(
    formTemplate?.data
      ? (JSON.parse(formTemplate.data) as any)
      : { pageOrder: [], items: {} },
    values,
    [],
    shouldRenderBase,
  );

  const { isCompleted, completionPercentage } = useFormCompletion(
    pages,
    values,
  );

  return (
    <TaskFormCompletionDisplay
      isCompleted={isCompleted}
      completionPercentage={completionPercentage}
      small={small}
    />
  );
};

export default TaskFormCompletion;
