import { DataType } from 'external/FormTemplateEditor';

const parseForClient = (obj: any): any => {
  if (!obj) return obj;

  if (obj.dataType === DataType.OBJECT) {
    return Object.entries(obj.value).reduce(
      (ret, [key, value]) => ({
        ...ret,
        [key]: parseForClient(value),
      }),
      {},
    );
  }

  if (obj.dataType === DataType.ARRAY) {
    return obj.value.map(parseForClient);
  }

  return obj.value;
};

export default parseForClient;
