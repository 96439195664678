import React, { forwardRef } from 'react';
import { ChevronRightIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useActionContext } from '../../context';

const ActionButton = forwardRef<HTMLButtonElement>((_, ref) => {
  const { action, small, state, dialog, button } = useActionContext();

  if (!action) return null;

  if (small) {
    return (
      <Button
        disableAsync
        disabled={state.disabled || !action.isValid}
        variant="outline"
        size="sm"
        className={cn('flex items-center gap-x-2 pl-2.5', button?.className)}
        onClick={dialog.onOpen}
      >
        <div
          className={cn('h-4 w-4 flex-none rounded-full bg-green-400/10 p-1', {
            'text-green-400': !state.disabled && action.isValid,
          })}
        >
          <div className="h-2 w-2 rounded-full bg-current" />
        </div>
        <h2 className="min-w-0 grow font-semibold leading-6">
          <a className="flex gap-x-2">
            <span className="truncate text-xs leading-5 text-gray-400">
              Workflow
            </span>
            <span className="text-sm text-gray-400">/</span>
            <span className="whitespace-nowrap text-xs leading-5">
              {action.label}
            </span>
          </a>
        </h2>
      </Button>
    );
  }

  return (
    <Button
      ref={ref}
      disableAsync
      disabled={state.disabled || !action.isValid}
      variant="ghost"
      className="block h-auto w-full min-w-0 rounded-none p-4 text-left"
      onClick={dialog.onOpen}
    >
      <div className="relative flex gap-x-2 pr-4">
        <div
          className={cn(
            'mt-0.5 h-4 w-4 flex-none rounded-full bg-green-400/10 p-1',
            { 'text-green-400': !state.disabled && action.isValid },
          )}
        >
          <div className="h-2 w-2 rounded-full bg-current" />
        </div>
        <div className="min-w-0 flex-auto grow">
          <div className="flex items-center gap-x-3">
            <h2 className="min-w-0 grow font-semibold leading-6">
              <a className="flex gap-x-2">
                <span className="truncate text-xs leading-5 text-gray-400">
                  Workflow
                </span>
                <span className="text-sm text-gray-400">/</span>
                <span className="whitespace-nowrap text-sm">
                  {action.label}
                </span>
              </a>
            </h2>
          </div>
          {action.isValid ? (
            <p className="mt-1 whitespace-normal text-xs leading-5 text-gray-400">
              {action.description}
            </p>
          ) : (
            <p className="mt-1 whitespace-normal text-xs leading-5">
              {action.validatorDescription}
            </p>
          )}
        </div>
        <ChevronRightIcon
          className="absolute inset-y-0 right-0 top-1/2 h-5 w-5 -translate-y-1/2  text-gray-400"
          aria-hidden="true"
        />
      </div>
    </Button>
  );
});

export default ActionButton;
