import React, { useMemo } from 'react';
import { FormControl, FormItem } from '@/components/ui/form';
import { CustomFieldRenderData } from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormLabel } from '@/components/ui/form/form-label/FormLabel';
import { Input } from '@/components/ui/input';
import { TrainingQualification } from 'lib/types';
import { getExpressions } from 'lib/utils';

const FieldData = ({ form }: CustomFieldRenderData<any>) => {
  const qualification = form.watch('qualification') as
    | TrainingQualification
    | undefined;

  const fields = useMemo(() => {
    if (!qualification) return [];

    const ret = [];

    for (const field of qualification.fields) {
      const expressions = getExpressions(field.value);

      for (const expression of expressions) {
        if (expression.includes('$.training.data.')) {
          const name = expression.replace('$.training.data.', '');
          ret.push(name);
        }
      }
    }

    return ret;
  }, [qualification]);

  if (fields.length === 0) {
    return null;
  }

  return (
    <FormItem className="mt-4">
      <FormLabel>Fields</FormLabel>
      <FormControl>
        <div className="flex flex-col gap-y-4 rounded-md border bg-background-secondary p-4">
          {fields.map((field) => {
            return (
              <div key={field} className="flex items-center gap-x-4">
                <p className="w-1/2 font-mono text-sm">{field}:</p>
                <Input
                  className="w-1/2 text-sm"
                  value={form.getValues().data?.[field] || ''}
                  onChange={(event) => {
                    form.setValue('data', {
                      ...form.getValues().data,
                      [field]: event.target.value,
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      </FormControl>
    </FormItem>
  );
};

export default FieldData;
