import { gql, useMutation } from '@apollo/client';
import { useCommercialActivityContext } from '../../../../../../context';
import { useActivityTableContext } from '../../../../context';

export const useValueCreate = () => {
  const { activity, contracts, store } = useCommercialActivityContext();
  const { type, updateRefetch } = useActivityTableContext();

  const [create] = useMutation(
    gql`
      mutation CreateCommercialValueLine(
        $activityId: ID!
        $type: String!
        $rate: ID
        $description: String
      ) {
        createCommercialValueLine(
          activityId: $activityId
          type: $type
          rate: $rate
          description: $description
        ) {
          commercialValueLine {
            id
            rate {
              id
            }
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (values: any) => {
    const { data, errors } = await create({
      variables: {
        activityId: activity.id,
        type,
        ...values,
      },
    });

    if (data) {
      const { id } = data.createCommercialValueLine.commercialValueLine;
      store.order.push(id);
      await updateRefetch();
    }

    if (errors) {
    }
  };

  const handleOnCreateLabor = async (rate: string) => {
    await handleOnCreate({ rate });
  };

  const handleOnCreateMaterial = async (item: string, mode: string) => {
    if (mode === 'create') {
      await handleOnCreate({ description: item });
      return;
    }

    await handleOnCreate({ item });
  };

  return {
    contracts,
    handlers: {
      onCreateLabor: handleOnCreateLabor,
      onCreateMaterial: handleOnCreateMaterial,
    },
  };
};
