import React, { useState } from 'react';
import { LightningBoltIcon } from '@radix-ui/react-icons';
import { Badge } from '@/components/ui/badge';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { ItemType } from 'external/Tree';
import { DATA_TYPE_MAP, DataType } from '../../types';
import BlockButton from '../BlockButton';
import BlockConfiguratorChoiceSets from '../BlockConfiguratorChoiceSets';
import BlockToggle from '../BlockToggle';
import ChoiceSet from '../ChoiceSet';
import { useBlockConfigurator } from './hooks';

const BlockConfigurator = ({ item }: any) => {
  const { info, choiceSet, handleDataTypeOnClick } = useBlockConfigurator(item);
  const [open, setOpen] = useState(false);

  if (item.type === ItemType.CONTAINER) {
    return (
      <div className="w-[300px] border-l bg-background">
        <BlockToggle
          item={item}
          toggle="isRepeated"
          className="font-base h-full w-full justify-start"
        >
          Repeat this section
        </BlockToggle>
      </div>
    );
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <BlockButton
          size="default"
          className="font-base h-auto w-[300px] justify-start gap-x-2 border-l bg-background py-0"
        >
          {info?.icon}
          {choiceSet ? (
            choiceSet.dynamic ? (
              <>
                <LightningBoltIcon className="size-4 flex-shrink-0" />
                <p className="line-clamp-1 overflow-ellipsis whitespace-nowrap font-mono">
                  {choiceSet.source}
                </p>
              </>
            ) : (
              <ChoiceSet choiceSet={choiceSet} />
            )
          ) : (
            info?.label ?? 'No type label'
          )}
        </BlockButton>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        sideOffset={0}
        alignOffset={0}
        className="w-auto max-w-none border-0 p-0 sm:max-w-none"
        style={{
          transformOrigin: 'var(--radix-popover-content-transform-origin)',
        }}
      >
        <div className="z-[5] flex overflow-hidden rounded border border-r-0 border-t-0 bg-background-secondary text-left ring-2">
          <div className="min-w-[300px] max-w-[400px]">
            <BlockConfiguratorChoiceSets item={item} />
          </div>
          <div className="w-[300px] border-l">
            <div className="flex h-[37px] items-center justify-between border-b pl-4">
              <p className="text-sm font-medium">Data types</p>
            </div>
            {Object.entries(DATA_TYPE_MAP)
              // fixme: is this filter needed?
              // .filter(([, { isCustom = false }]) => !isCustom)
              .map(([key, dataType]) => {
                return (
                  <BlockButton
                    key={key}
                    className="h-8 w-full justify-start gap-x-2 text-left"
                    onClick={() => {
                      handleDataTypeOnClick(key as DataType);
                      setOpen(false);
                    }}
                  >
                    {dataType.icon}
                    {dataType.label}
                  </BlockButton>
                );
              })}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default BlockConfigurator;
