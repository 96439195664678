import React, { useContext } from 'react';
import { GetShouldParentIncreaseDepth, Item, Items } from 'external/Tree';
import { LogicData } from 'external/types';
import { ChoiceSet } from './types';

type Store = {
  pageOrder: string[];
  items: Items;
  choiceSets: Record<string, ChoiceSet>;
  fieldLabels: Record<string, { label: string }>;
};

type FormTemplateContextType = {
  store: Store;
  focus: string | null;
  logic: Record<string, string>;
  collapsed: string[];
  onFocus: (item: any) => () => void;
  onBlur: () => void;
  onSelectLogic: (item: Item) => () => void;
  onCollapse: (item: Item, override?: boolean) => () => void;
  getShouldParentIncreaseDepth: GetShouldParentIncreaseDepth;
  getLogicFor: (item: Item) => Item<LogicData>;
};

const FormTemplateContext = React.createContext<FormTemplateContextType>({
  store: {
    pageOrder: [],
    items: {},
    choiceSets: {},
    fieldLabels: {},
  },
  focus: null,
  logic: {},
  collapsed: [],
  onBlur: () => {},
  onFocus: () => () => {},
  onSelectLogic: () => () => {},
  onCollapse: () => () => {},
  getShouldParentIncreaseDepth: () => true,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getLogicFor: () => {},
});

export const FormTemplateContextProvider = FormTemplateContext.Provider;

export const useFormTemplateContext = () => useContext(FormTemplateContext);
