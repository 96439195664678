import React from 'react';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { DeleteIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { CardTitle } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { useFormContext } from '../../context';
import { useFormStore } from '../../hooks';
import { getHorizontalPadding } from '../../utils';
import { FormContainerRepeatingBranchProps } from './types';

const FormContainerRepeatingBranch = ({
  item,
}: FormContainerRepeatingBranchProps) => {
  const { readOnly, repeatingBranchOnDelete } = useFormContext();
  const collapsed = useFormStore((state) => state.collapsed);
  const onCollapse = useFormStore((state) => state.onCollapse);
  const isCollapsed = collapsed.includes(item.id);
  return (
    <div className="sticky top-0 z-10 border-b bg-background-secondary py-3 pr-4">
      <div
        className="flex items-center gap-x-3"
        style={{ paddingLeft: getHorizontalPadding(item.depth) }}
      >
        <Button
          variant="outline"
          size="icon-xs"
          onClick={() => onCollapse(item.id)}
        >
          <ChevronRightIcon
            className={cn('h-4 w-4 transition-all', {
              'rotate-90': !isCollapsed,
            })}
          />
        </Button>
        <CardTitle className="grow">
          {item.data.value} {item.data.branchIndex + 1}
        </CardTitle>
        {!readOnly && (
          <Button
            variant="outline"
            size="icon-xs"
            confirm="Are you sure?"
            onClick={() => repeatingBranchOnDelete(item)}
          >
            <DeleteIcon className="h-4 w-4" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default FormContainerRepeatingBranch;
