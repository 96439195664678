import React from 'react';
import { ReportMode } from 'lib/shared';
import { useReportContext } from '../../../../context';
import ReportImage from '../../../ReportImage';
import ReportText from '../../../ReportText';
import ReportView from '../../../ReportView';

const BlockAppendix = (_: any) => {
  const { mode, theme, images } = useReportContext();

  const imagesDistinct = images.filter(
    (image, index, self) =>
      index === self.findIndex(({ id }) => id === image.id),
  );

  // If there are no registered objects, then
  // we don't need to render the appendix.
  if (images.length === 0) return null;

  return (
    <ReportView style={mode === ReportMode.Edit ? { padding: 32 } : undefined}>
      <ReportView style={{ marginBottom: 16 }}>
        <ReportText
          style={{
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: 1.2,
            whiteSpace: 'nowrap',
          }}
        >
          Appendix
        </ReportText>
      </ReportView>
      <ReportView
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 16,
        }}
      >
        {imagesDistinct.map((image) => {
          return (
            <ReportView
              key={image.id}
              style={{
                aspectRatio: 1,
              }}
            >
              <ReportImage
                ignoreOnMount
                alt={`Uploaded image (${image.id})`}
                src={image.srcCompressed}
                srcCompressed={image.srcCompressed}
                object={image}
                style={{
                  width: (595.28 - 108) / 3,
                  height: 200,
                  objectFit: 'cover',
                  borderRadius: 4,
                }}
              />
              <ReportText
                style={{
                  marginTop: 8,
                  fontSize: 12,
                  color: theme.colors.subtitle,
                }}
              >
                Photo {imagesDistinct.map(({ id }) => id).indexOf(image.id) + 1}
              </ReportText>
            </ReportView>
          );
        })}
      </ReportView>
    </ReportView>
  );
};

export default BlockAppendix;
