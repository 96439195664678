import React from 'react';
import { useLocationPickerContext } from '../../../../../../../../shared/LocationPicker/hooks';
import { resultToAddress } from '../../../../../../../../shared/LocationPicker/utils';

const LocationPreview = () => {
  const { selected } = useLocationPickerContext();

  if (!selected) return null;

  const address = resultToAddress(selected);

  return (
    <div className="absolute left-4 top-4 z-[50] min-w-[400px] rounded-md border bg-secondary p-4 text-sm">
      <div className="mb-2 flex justify-between gap-x-4">
        <div>
          <p className="mb-1 font-bold">{selected.data.displayName?.text}</p>
          <p>New address</p>
        </div>
      </div>
      <table className="pointer-events-auto">
        <tbody>
          <tr>
            <td className="pr-2 text-gray-500 dark:text-gray-400">Line one</td>
            <td>{address?.lineOne}</td>
          </tr>
          <tr>
            <td className="pr-2 text-gray-500 dark:text-gray-400">Line two</td>
            <td>{address?.lineTwo}</td>
          </tr>
          <tr>
            <td className="pr-2 text-gray-500 dark:text-gray-400">City/Town</td>
            <td>{address?.city}</td>
          </tr>
          <tr>
            <td className="pr-2 text-gray-500 dark:text-gray-400">Postcode</td>
            <td>{address?.postcode}</td>
          </tr>
          <tr>
            <td className="pr-2 text-gray-500 dark:text-gray-400">Country</td>
            <td>{address?.country}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LocationPreview;
