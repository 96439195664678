import React, { useEffect } from 'react';
import { useDebounce } from 'react-use';
import { Loader } from '@/components/ui/loading';
import { cn } from '@/lib/utils';
import { EditableAutoSaveProviderProps } from './types';

const EditableAutoSaveProvider = ({
  children,
  className,
  value: initialValue,
  onChange,
}: EditableAutoSaveProviderProps) => {
  const [internalValue, setInternalValue] = React.useState(
    initialValue ? initialValue : undefined,
  );

  const [ready, setReady] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [saved, setSaved] = React.useState(false);

  useEffect(() => {
    setInternalValue(initialValue ? initialValue : undefined);
  }, [initialValue]);

  useDebounce(
    async () => {
      if (!ready) return;

      setLoading(true);
      await onChange(internalValue || '');
      setLoading(false);

      setSaved(true);
      setTimeout(() => {
        setSaved(false);
      }, 1000);
    },
    1000,
    [internalValue],
  );

  const handleOnChange = (value: string) => {
    setReady(true);
    setInternalValue(value);
  };

  return (
    <div className={cn('relative', className)}>
      <div className="pointer-events-none absolute right-2 top-2">
        {loading && <Loader className="size-3" />}
        {saved && (
          <p className="text-[10px] leading-[10px] text-green-500/75">Saved</p>
        )}
      </div>
      {children({
        readOnly: loading,
        value: internalValue,
        onValueChange: handleOnChange,
      })}
    </div>
  );
};

export default EditableAutoSaveProvider;
