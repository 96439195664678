import React from 'react';
import { Button } from '@/components/ui/button';
import { useCommercialContractContext } from '../../context';
import AccountingUpdate from './components/AccountingUpdate';

const ContractAccounting = () => {
  const { contract } = useCommercialContractContext();

  if (!contract) return null;

  if (!contract.accounting) {
    return (
      <div>
        <div className="mb-4">
          <p className="mb-1 text-sm font-bold">Accounting</p>
          <p className="text-xs text-gray-400">
            View the accounting information for this contract.
          </p>
        </div>
        <div className="rounded-md border p-4">
          <Button></Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col">
      <div className="mb-4 flex items-end justify-between">
        <div>
          <p className="mb-1 text-sm font-bold">Accounting</p>
          <p className="text-xs text-gray-400">
            View the accounting information for this contract.
          </p>
        </div>
        <AccountingUpdate />
      </div>
      <div className="grow rounded-lg border bg-secondary p-2">
        <div className="rounded-md bg-background-secondary p-3">
          <div className="mb-4 grid grid-cols-12">
            <div className="col-span-3">
              <p className="mb-0.5 text-xs font-semibold dark:text-gray-400">
                Customer
              </p>
              <p className="text-sm">{contract.accounting.customer}</p>
            </div>
            <div className="col-span-3">
              <p className="mb-0.5 text-xs font-semibold dark:text-gray-400">
                Search Code
              </p>
              <p className="text-sm">{contract.accounting.searchCode}</p>
            </div>
            <div className="col-span-3">
              <p className="mb-0.5 text-xs font-semibold dark:text-gray-400">
                Nominal Cost Centre
              </p>
              <p className="text-sm">{contract.accounting.nominalCostCentre}</p>
            </div>
            <div className="col-span-3">
              <p className="mb-0.5 text-xs font-semibold dark:text-gray-400">
                Auth PO
              </p>
              <p className="text-sm">{contract.accounting.authPo}</p>
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div className="col-span-3">
              <p className="mb-0.5 text-xs font-semibold dark:text-gray-400">
                Approved Value
              </p>
              <p className="text-sm">{contract.accounting.approvedValue}</p>
            </div>
            <div className="col-span-3">
              <p className="mb-0.5 text-xs font-semibold dark:text-gray-400">
                Contract Manager
              </p>
              <p className="text-sm">{contract.accounting.contractManager}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractAccounting;
