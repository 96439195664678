import React from 'react';
import Map from 'react-map-gl';
import { Button } from '@/components/ui/button';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { Loader } from '@/components/ui/loading';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { cn } from '@/lib/utils';
import { z } from 'zod';
import { useMapStyle, usePlatformObject } from 'lib/hooks';
import { useClient } from '../../../../../../hooks';

const SettingsHeader = () => {
  const client = useClient();
  return (
    <div className="relative flex border-b">
      <div className="relative z-[2] flex basis-2/3">
        <div className="pb-4 pl-2 pt-2">
          <SettingsHeaderLogo />
          <div className="mt-1 pl-2">
            <p className="text-sm text-gray-600 dark:text-white/75">
              {client.description}
            </p>
          </div>
        </div>
      </div>
      <div className="absolute inset-0">
        <SettingsHeaderMap />
      </div>
    </div>
  );
};

const SettingsHeaderLogo = () => {
  const client = useClient();
  const { isLoading, object } = usePlatformObject(client.logo?.id);
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button
          variant={client.logo?.id ? 'ghost' : 'outline'}
          className={cn('h-[56px]', {
            'ml-2': !client.logo?.id,
            'p-2': client.logo?.id,
          })}
        >
          {client.logo?.id ? (
            <div className="w-[128px]">
              {isLoading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                <img
                  className="h-[40px]"
                  src={object?.getUrl}
                  alt="Client logo"
                />
              )}
            </div>
          ) : (
            <div>
              <p>Click to add image</p>
            </div>
          )}
        </Button>
      </SheetTrigger>
      <SheetContent className="flex w-[700px] max-w-[700px] flex-col gap-0 gap-y-4 sm:max-w-[700px]">
        <FormRenderer
          fields={[
            {
              type: 'file',
              name: 'file',
              label: 'File',
              schema: z.any(),
            },
          ]}
          onSubmit={async (values) => {
            await client.update('logo', {
              id: values.file.id,
              type: values.file.type,
              name: values.file.name,
              size: values.file.size,
              data: values.file.data,
            });
          }}
        />
      </SheetContent>
    </Sheet>
  );
};

const SettingsHeaderMap = () => {
  const mapStyle = useMapStyle();
  return (
    <>
      <div className="pointer-events-none absolute inset-0 z-[1] from-background from-50% to-75% bg-gradient-[45deg]" />
      <Map
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          zIndex: 0,
        }}
        initialViewState={{
          zoom: 10.5,
          latitude: 51.4574,
          longitude: 0.1278,
        }}
        mapboxAccessToken="pk.eyJ1IjoiZW5lcmd5ZW5hYmxlcyIsImEiOiJjbGpia2NqZ2IxcTcxM2pxeGxvNG5ud2lpIn0.gMXRc_xES-6AlQx1w8MuXw"
        attributionControl={false}
        mapStyle={mapStyle}
        logoPosition="bottom-right"
      />
    </>
  );
};

export default SettingsHeader;
