import { Check } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import chroma from 'chroma-js';
import { DateTime } from 'luxon';
import { ScopeLabel } from 'lib/shared';
import TodoUrgency from '../../../../../../external/Todos/components/TodoUrgency';
import { useTodos } from '../../../../../../external/Todos/hooks';
import { useJobIssueContext } from '../../context';

const IssueManagement = () => {
  const { issue } = useJobIssueContext();

  const { todos } = useTodos({
    scope: issue ? `jobs::job-issue::${issue.id}` : undefined,
    queryKey: ['issue', issue!.id],
  });

  return (
    <div className="col-span-3 flex flex-col gap-y-4 px-8 py-4">
      {todos.map((todo) => {
        const color =
          todo.status === 'OPEN'
            ? chroma.mix('green', 'red', todo.urgency)
            : chroma('green');
        return (
          <div
            key={todo.id}
            className={cn('rounded-lg border bg-background p-4 pt-3', {
              'pb-3': todo.status === 'COMPLETED',
            })}
            style={{ borderColor: color.alpha(todo.urgency + 0.5).css() }}
          >
            <div className="flex flex-col gap-y-2">
              <div className="text-sm">
                <ScopeLabel label={todo.data.card.title} />
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {todo.data.card.description}
                </p>
              </div>
              {todo.status === 'OPEN' && (
                <>
                  <TodoUrgency urgency={todo.urgency} />
                  {todo.urgency < 0.33 && (
                    <p className="text-xs font-bold text-green-700">
                      Not urgent
                    </p>
                  )}
                  {todo.urgency > 0.66 && (
                    <p className="text-xs font-bold text-red-600">Urgent</p>
                  )}
                  <p className="text-xs font-semibold dark:text-gray-400">
                    {DateTime.fromSeconds(todo.deadline).toLocaleString(
                      DateTime.DATETIME_MED,
                    )}
                  </p>
                  <Button className="mt-2" size="sm" variant="secondary">
                    Escalate todo
                  </Button>
                </>
              )}
              {todo.status === 'COMPLETED' && (
                <p className="flex gap-x-1 text-xs font-bold text-green-700">
                  <Check className="size-4" />
                  Completed
                </p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default IssueManagement;
