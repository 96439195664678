import { createContext, useContext } from 'react';
import { useActivityTable } from './hooks';

export const ActivityTableContext = createContext(
  {} as ReturnType<typeof useActivityTable>['context'],
);

export const useActivityTableContext = () => {
  return useContext(ActivityTableContext);
};
