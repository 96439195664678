import { forwardRef } from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { Label } from '@/components/ui/label';
import { cn } from '@/lib/utils';
import { useFormField } from '../form-field/FormField';

/**
 * Renders form field label.
 */
export const FormLabel = forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
  const { error, formItemId } = useFormField();

  return (
    <Label
      ref={ref}
      className={cn(error && 'text-destructive', className)}
      htmlFor={formItemId}
      {...props}
    />
  );
});
