import React, { PropsWithChildren, useCallback, useState } from 'react';
import { PlatformTodo } from 'lib/types';
import { useTodos } from '../Todos/hooks';
import { TodosContext } from './context';

const TodosProvider = ({ children }: PropsWithChildren) => {
  const {
    todos = [],
    groups = [],
    refetchTodos,
  } = useTodos({
    status: 'OPEN',
    sort: { urgency: 'desc' },
    pagination: { page_size: 1000 },
  });

  const [selected, setSelected] = useState<null | PlatformTodo>(null);

  const handleSelectTodo = useCallback((todo: PlatformTodo | null) => {
    setSelected(todo);
  }, []);

  const handleClearSelectedTodo = useCallback(() => {
    setSelected(null);
  }, []);

  return (
    <TodosContext.Provider
      value={{
        todos,
        groups,
        selected,
        refetchTodos,
        selectTodo: handleSelectTodo,
        clearSelectedTodo: handleClearSelectedTodo,
      }}
    >
      {children}
    </TodosContext.Provider>
  );
};

export default TodosProvider;
