import React from 'react';
import { DotIcon } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import chroma from 'chroma-js';
import { getJobTaskStatusColor } from 'lib/utils';
import JobLink from '../../../../components/JobLink';
import { useJobContext } from '../../../../context';
import { useJobTaskContext } from '../../context';
import TaskAims from '../TaskAims';

const TaskInfo = () => {
  const { state } = useJobContext();
  const { task } = useJobTaskContext();

  if (!task) return null;

  const color = getJobTaskStatusColor(task);

  return (
    <div
      className="rounded-tl-sm p-4"
      style={{ borderLeft: `4px solid ${chroma(color).alpha(0.5).hex()}` }}
    >
      <div className="mb-2 flex">
        <div className="flex grow items-center gap-x-2">
          <h3 className="text-lg font-semibold">{task.name}</h3>
          <DotIcon className="h-2 w-2" />
          <Badge
            className="capitalize"
            variant="outline"
            style={{ borderColor: color, color }}
          >
            {task.status.toLowerCase()}
          </Badge>
          <DotIcon className="h-2 w-2" />
          <p className="text-sm font-medium">Task #{task.number}</p>
          <DotIcon className="h-2 w-2" />
          <p className="text-sm font-medium">Created by workflow</p>
        </div>
        {!state.readOnly && (
          <div className="flex items-center gap-x-2">
            <JobLink to={`tasks/${task.number}/assign`}>
              <Button variant="outline" size="sm" className="h-7">
                Assign task
              </Button>
            </JobLink>
          </div>
        )}
      </div>
      <div className="mb-3">
        <p className="text-sm font-medium text-gray-400">{task.description}</p>
      </div>
      <div>
        <TaskAims task={task} />
      </div>
    </div>
  );
};

export default TaskInfo;
