import { createContext, useContext } from 'react';
import { useJobIssue } from './hooks';

export const JobIssueContext = createContext(
  {} as ReturnType<typeof useJobIssue>['context'],
);

export const useJobIssueContext = () => {
  return useContext(JobIssueContext);
};
