import React, { useMemo, useState } from 'react';
import { RowSelectionState } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import {
  Sheet,
  SheetContent,
  SheetContentExtension,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { TableRenderer, selectColumn } from '@/components/ui/table/table/Table';
import { pluralize } from '@/lib/utils';
import { gql, useQuery } from '@apollo/client';
import { User } from 'lib/types';
import { UserSelectorProps } from './types';

const UserSelector = ({
  children,
  title,
  description,
  exclude = [],
  extension = false,
  multiple = true,
  onSelect,
}: UserSelectorProps) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<RowSelectionState>({});
  const selectedIds = Object.entries(selected)
    .filter(([_, isSelected]) => isSelected)
    .map(([id]) => id);

  const { data } = useQuery<{ users: User[] }>(
    gql`
      query GetUsers {
        users {
          id
          name
          email
          externalId
        }
      }
    `,
    { fetchPolicy: 'no-cache' },
  );

  const dataMemo = useMemo(
    () =>
      data
        ? data.users
            .filter((user) => user.email !== 'AnonymousUser')
            .filter(({ id }) => !exclude.map(({ id }) => id).includes(id))
        : [],
    [data, exclude],
  );

  const inner = (
    <>
      <SheetHeader>
        <SheetTitle>{title}</SheetTitle>
        <SheetDescription>{description}</SheetDescription>
      </SheetHeader>
      <div className="min-h-0 grow">
        <TableRenderer
          small
          filter="name"
          globalFilterFn="auto"
          columns={[
            ...(multiple ? [selectColumn()] : []),
            {
              id: 'name',
              accessorKey: 'name',
              header: 'Name',
              ...(multiple
                ? {}
                : {
                    cell: ({ row }) => {
                      return (
                        <Button
                          size="table"
                          variant="table"
                          onClick={() => {
                            onSelect?.([row.original]);
                            setOpen(false);
                          }}
                        >
                          {row.original.name}
                        </Button>
                      );
                    },
                  }),
            },
          ]}
          data={dataMemo}
          topRight={() => null}
          selected={selected}
          onSelectedChange={setSelected}
        />
      </div>
      {multiple && (
        <Button
          className="w-full"
          variant="outline"
          disabled={selectedIds.length === 0}
          onClick={async () => {
            onSelect?.(
              data?.users.filter(({ id }) => selectedIds.includes(id)) ?? [],
            );
            setOpen(false);
          }}
        >
          {selectedIds.length === 0 ? (
            'Select user(s)'
          ) : (
            <>
              Add {selectedIds.length} {pluralize(selectedIds.length, 'user')}{' '}
              to group
            </>
          )}
        </Button>
      )}
    </>
  );

  return (
    <Sheet
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (open) {
          setSelected({});
        }
      }}
    >
      <SheetTrigger asChild>{children}</SheetTrigger>
      {extension ? (
        <SheetContentExtension
          className="flex w-[500px] max-w-[500px] flex-col gap-y-0 p-4 sm:max-w-[500px]"
          right={500}
        >
          {inner}
        </SheetContentExtension>
      ) : (
        <SheetContent className="flex w-[500px] max-w-[500px] flex-col gap-y-0 p-4 sm:max-w-[500px]">
          {inner}
        </SheetContent>
      )}
    </Sheet>
  );
};

export default UserSelector;
