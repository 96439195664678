import React from 'react';
import {
  custom,
  date,
} from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { DateTime } from 'luxon';
import { z } from 'zod';
import { TrainingCertificate } from 'lib/types';
import { MemberTrainingFormContext } from './context';
import FieldCertificates from './fields/FieldCertificates';
import FieldData from './fields/FieldData';
import FieldQualification from './fields/FieldQualification';
import { useMemberTrainingForm } from './hooks';
import { MemberTrainingFormProps } from './types';

const MemberTrainingForm = ({
  children,
  training,
  onSubmit,
}: MemberTrainingFormProps) => {
  const { context } = useMemberTrainingForm(training);
  return (
    <MemberTrainingFormContext.Provider value={context}>
      <FormRenderer
        fields={[
          custom({
            name: 'qualification',
            defaultValue: training?.qualification,
            schema: z.any(),
            render: FieldQualification,
          }),
          custom({
            name: 'data',
            defaultValue: training?.data,
            schema: z.any(),
            render: FieldData,
          }),
          date({
            name: 'dateValid',
            label: 'Date valid',
            timescape: true,
            schema: z.any(),
            // @ts-ignore
            defaultValue: training?.dateValid
              ? DateTime.fromISO(training.dateValid)
              : undefined,
          }),
          date({
            name: 'dateExpiry',
            label: 'Date expiry',
            timescape: true,
            schema: z.any(),
            // @ts-ignore
            defaultValue: training?.dateExpiry
              ? DateTime.fromISO(training.dateExpiry)
              : undefined,
          }),
          custom({
            name: 'certificates',
            defaultValue: (training?.certificates ?? []).map(
              (certificate: TrainingCertificate) => ({
                id: certificate.id,
                isNew: false,
                sourceObject: {
                  id: certificate.sourceObject.id,
                  type: certificate.sourceObject.type,
                  size: certificate.sourceObject.size,
                  name: certificate.sourceObject.name,
                  data: certificate.sourceObject.data,
                },
              }),
            ),
            schema: z.any(),
            render: FieldCertificates,
          }),
        ]}
        onSubmit={onSubmit}
      >
        {children}
      </FormRenderer>
    </MemberTrainingFormContext.Provider>
  );
};

export default MemberTrainingForm;
