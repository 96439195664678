import { CommercialType } from 'lib/types';
import { useQuoteRendererContext } from '../../../../context';

export const useBodyLines = (type: CommercialType) => {
  const { estimate, order } = useQuoteRendererContext();

  if (!estimate) {
    return {
      lines: [],
    };
  }

  const lines = [
    ...estimate.valueLines.filter((line) => line.type === type),
  ].sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));

  return {
    lines,
  };
};
