import React from 'react';
import { Settings2 } from 'lucide-react';
import {
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu';
import NavigationLeftListItem from '../NavigationLeftListItem';

const NavigationLeftSupplyChain = () => {
  return (
    <NavigationMenuItem>
      <NavigationMenuTrigger>Supply Chain</NavigationMenuTrigger>
      <NavigationMenuContent>
        <ul className="grid gap-3 p-4 md:w-[450px] lg:w-[550px] lg:grid-cols-[.75fr_1fr]">
          <li className="row-span-4">
            <NavigationMenuLink asChild>
              <div className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md">
                <Settings2 className="h-6 w-6" />
                <div className="mb-2 mt-4 text-lg font-medium">
                  Supply Chain
                </div>
                <p className="text-sm leading-tight text-muted-foreground">
                  Manage subcontractors and suppliers
                </p>
              </div>
            </NavigationMenuLink>
          </li>
          <NavigationLeftListItem
            to="/supply-chain/subcontractors"
            title="Subcontractors"
          >
            Manage and approve subcontractors
          </NavigationLeftListItem>
          <NavigationLeftListItem
            to="/supply-chain/suppliers"
            title="Suppliers"
          >
            Manage and approve suppliers
          </NavigationLeftListItem>
          <NavigationLeftListItem to="/supply-chain/plant" title="Plant">
            Manage internal and hired plant
          </NavigationLeftListItem>
          <NavigationLeftListItem
            to="/supply-chain/waste-management"
            title="Waste Management"
          >
            Waste management and recycling
          </NavigationLeftListItem>
        </ul>
      </NavigationMenuContent>
    </NavigationMenuItem>
  );
};

export default NavigationLeftSupplyChain;
