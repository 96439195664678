import React from 'react';
import { ItemType, RenderPageFooterProps } from '../../../Tree';
import { useCreateItem } from '../../hooks';
import { DataType } from '../../types';
import BlockButton from '../BlockButton';
import { usePageFooter } from './hooks';

const PageFooter = ({ page }: RenderPageFooterProps) => {
  const { handleDeletePageOnClick } = usePageFooter(page);
  const createItem = useCreateItem({
    item: { id: page.children[page.children.length - 1] },
    parentId: page.id,
  });
  return (
    <div className="mb-8 flex justify-between overflow-hidden rounded-b-lg border bg-background">
      <div>
        <BlockButton
          onClick={createItem(ItemType.BLOCK, {
            dataType: DataType.CHAR,
            isRequired: true,
          })}
        >
          Question
        </BlockButton>
        <BlockButton
          onClick={createItem(ItemType.CONTAINER, { isRepeated: false })}
        >
          Section
        </BlockButton>
      </div>
      <div>
        <BlockButton onClick={handleDeletePageOnClick}>Delete page</BlockButton>
      </div>
    </div>
  );
};

export default PageFooter;
