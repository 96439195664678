import { createContext, useContext } from 'react';
import { CommercialContract } from 'lib/types';

export const CommercialContractContext = createContext(
  {} as {
    contract: CommercialContract | undefined;
  },
);

export const useCommercialContractContext = () => {
  return useContext(CommercialContractContext);
};
