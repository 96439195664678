import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  date,
  file,
  image,
  input,
  textarea,
} from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { z } from 'zod';
import { useAccreditationsCreate } from './hooks';

const AccreditationsCreate = () => {
  const { sheet, handlers } = useAccreditationsCreate();
  return (
    <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
      <SheetTrigger asChild>
        <Button className="pl-2" size="sm" variant="outline">
          <Plus className="mr-1 size-4" />
          Create new accreditation
        </Button>
      </SheetTrigger>
      <SheetContent className="flex h-full min-h-0 flex-col p-0">
        <SheetHeader className="p-4">
          <SheetTitle>Create Company Accreditation</SheetTitle>
          <SheetDescription>
            Create a new company accreditation
          </SheetDescription>
        </SheetHeader>
        <ScrollArea className="min-h-0 flex-grow pb-4">
          <FormRenderer
            className="mx-4"
            fields={[
              image({
                name: 'logo',
                label: 'Logo',
                schema: z.any(),
                description: 'The logo of the accreditation',
              }),
              input({
                name: 'reference',
                label: 'Reference',
                schema: z.string().min(1),
                description: 'The reference of the membership',
              }),
              date({
                name: 'expiryDate',
                label: 'Expiry Date',
                schema: z.date(),
                timescape: true,
                description: 'The date the membership expires',
              }),
              textarea({
                name: 'description',
                label: 'Description',
                schema: z.string().min(1),
                description: 'A description of the membership',
              }),
              file({
                name: 'document',
                label: 'Document',
                schema: z.any(),
                description: 'The document of the accreditation',
                right: 384,
              }),
            ]}
            onSubmit={handlers.onCreate}
          >
            {({ onSubmit }) => (
              <Button className="w-full" size="sm" onClick={onSubmit}>
                Create
              </Button>
            )}
          </FormRenderer>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

export default AccreditationsCreate;
