import {
  UserGroupQueryData,
  UserGroupQueryVariables,
} from '@/components/ui/user-group/types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { User } from 'lib/types';

export const useUserGroup = (id: string) => {
  const { data, refetch } = useQuery<
    UserGroupQueryData,
    UserGroupQueryVariables
  >(
    gql`
      query GetUserGroup($id: ID!) {
        userGroup(userGroupId: $id) {
          id
          platformId
          name
          users {
            id
            name
            externalId
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      variables: { id },
    },
  );

  const [add] = useMutation(gql`
    mutation AddUserToGroup($userGroupId: ID!, $userId: ID!) {
      addUserToUserGroup(userGroupId: $userGroupId, userId: $userId) {
        userGroup {
          id
          users {
            id
          }
        }
      }
    }
  `);

  const handleOnAdd = async (users: User[]) => {
    await Promise.all(
      users.map((user) =>
        add({
          variables: {
            userGroupId: id,
            userId: user.id,
          },
        }),
      ),
    );
    await refetch();
  };

  const [remove] = useMutation(gql`
    mutation RemoveUserFromGroup($userGroupId: ID!, $userId: ID!) {
      removeUserFromUserGroup(userGroupId: $userGroupId, userId: $userId) {
        userGroup {
          id
          users {
            id
          }
        }
      }
    }
  `);

  const handleOnRemove = async (user: User) => {
    await remove({
      variables: {
        userGroupId: id,
        userId: user.id,
      },
    });
    await refetch();
  };

  return {
    context: {
      group: data?.userGroup
        ? {
            ...data.userGroup,
            refetch,
          }
        : undefined,
      users: data?.userGroup?.users || [],
      handlers: {
        onAdd: handleOnAdd,
        onRemove: handleOnRemove,
      },
    },
  };
};
