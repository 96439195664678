import React, { useCallback, useEffect, useState } from 'react';
import * as Y from 'yjs';
import { useYjs } from 'lib/hooks';
import { useFormCompletion } from '../../sections/ClientJob/pages/JobTask/components/TaskFormCompletion/hooks';
import FormRenderer from '../FormRenderer';
import { shouldRenderBase, usePages } from '../FormRenderer/parse';
import { useForm } from './hooks';
import { FormProps } from './types';

export const FormComponent = ({
  children,
  store,
  doc,
  disableFooter,
  disableHeader,
  disableMedia,
  id,
  name,
  template,
  reviewMode,
  setDefaults,
  render,
  renderField,
  onSubmit,
}: FormProps) => {
  /*
   * Form is a wrapper around FormRenderer
   * that provides a collaborative state for the form.
   * This is useful for forms that need to be submitted
   * to the platform, and need to be able to be edited
   * by multiple users at the same time across devices.
   */

  const {
    values,
    objects,
    meta,
    handleFieldValueOnChange,
    handleFieldMetaOnChange,
    handleRepeatingBranchOnCreate,
    handleRepeatingBranchOnDelete,
    handleObjectsOnCreate,
    handleObjectOnUpload,
    handleObjectOnUploadProgress,
    handleObjectOnDelete,
    handleGetSubmission,
    handleOnComplete,
    handleOnEdit,
    handleOnSubmit,
  } = useForm(id, store, setDefaults, onSubmit);

  const { pages } = usePages(template, values, [], shouldRenderBase);
  const { completionPercentage } = useFormCompletion(pages, values);

  const [undoManager, setUndoManager] = useState<Y.UndoManager>();
  useEffect(() => {
    if (doc) {
      const newUndoManager = new Y.UndoManager([
        doc.getMap('values'),
        doc.getMap('objects'),
      ]);

      setUndoManager(newUndoManager);
    }
  }, [doc]);

  const handleOnUndo = useCallback(() => {
    if (undoManager) {
      undoManager.undo();
    }
  }, [undoManager]);

  const handleOnRedo = useCallback(() => {
    if (undoManager) {
      undoManager.redo();
    }
  }, [undoManager]);

  const form = (
    <FormRenderer
      disableFooter={disableFooter}
      disableHeader={disableHeader}
      disableMedia={disableMedia}
      name={name}
      template={template}
      values={values}
      objects={objects}
      meta={meta}
      reviewMode={reviewMode}
      renderField={renderField}
      fieldValueOnChange={handleFieldValueOnChange}
      fieldMetaOnChange={handleFieldMetaOnChange}
      repeatingBranchOnCreate={handleRepeatingBranchOnCreate}
      repeatingBranchOnDelete={handleRepeatingBranchOnDelete}
      objectsOnCreate={handleObjectsOnCreate}
      objectOnUpload={handleObjectOnUpload}
      objectOnUploadProgress={handleObjectOnUploadProgress}
      objectOnDelete={handleObjectOnDelete}
      onComplete={handleOnComplete}
      onUndo={handleOnUndo}
      onRedo={handleOnRedo}
    />
  );

  const props = {
    meta,
    isCompleted: meta.isCompleted ?? false,
    isApproved: meta.isApproved ?? false,
    isLocked: meta.isLocked ?? false,
    completionPercentage,
    values,
    fieldValueOnChange: handleFieldValueOnChange,
    getSubmission: handleGetSubmission,
    onComplete: handleOnComplete,
    onEdit: handleOnEdit,
    onSubmit: handleOnSubmit,
  };

  return render ? (
    render({ ...props, form })
  ) : (
    <>
      {form}
      {children?.({ ...props, form: null })}
    </>
  );
};

const Form = ({
  children,
  scope,
  id,
  loader = null,
  name,
  ...props
}: Omit<FormProps, 'store' | 'doc'> & { scope: string; loader?: any }) => {
  useEffect(() => {
    console.debug(`[${(name ?? '').slice(0, 29).padEnd(29)}]: Form mount`);
    return () => {
      console.debug(`[${(name ?? '').slice(0, 29).padEnd(29)}]: Form unmount`);
    };
  }, [name]);

  const { isReady, store, doc } = useYjs(
    scope,
    id,
    { values: {}, objects: {}, meta: {}, choiceSets: {}, fieldLabels: {} },
    { debugName: name },
  );
  return !isReady || !store || !doc.current ? (
    loader
  ) : (
    <FormComponent
      {...props}
      name={name}
      store={store}
      doc={doc.current}
      id={id}
    >
      {children}
    </FormComponent>
  );
};

export default Form;
