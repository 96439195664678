import React, { RefObject, useMemo } from 'react';
import { BryntumGantt } from '@bryntum/gantt-react-thin';
import {
  BryntumSchedulerPro,
  BryntumTimeline,
} from '@bryntum/schedulerpro-react-thin';
import ButtonTabs from '@/components/ui/button-tabs';
import { EventModelConfig } from '@bryntum/scheduler-thin';
import { EventModel, ProjectModel } from '@bryntum/schedulerpro-thin';
import { useLocalStorage } from '@uidotdev/usehooks';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useCallbackRef } from 'lib/hooks';
import {
  PreparedTimesheetDuty,
  getAssignments,
  getResources,
  prepareDuties,
} from '../../../../../ClientJob/pages/JobTaskAssignment/hooks';
import { AssignmentResourceType } from '../../../../../ClientJob/pages/JobTaskAssignment/types';
import { useClient } from '../../../../hooks';
import { useClientActivityContext } from '../../hooks';
import { useConfig } from './config';
import { ActivityScheduleContext } from './context';
import { ActivityScheduleProps } from './types';

enum TimeRangeMode {
  Overview = 'overview',
  Gantt = 'gantt',
}

const ActivitySchedule = ({ timesheetDuties }: ActivityScheduleProps) => {
  const { interval, onIntervalChange } = useClientActivityContext();

  const client = useClient();
  const config = useConfig();

  const schedulerRef = useCallbackRef() as RefObject<BryntumSchedulerPro>;
  const project = useMemo(() => new ProjectModel(), []);

  const [mode, setMode] = useLocalStorage<TimeRangeMode>(
    `clients::client::${client.slug}::activitySchedule::mode`,
    TimeRangeMode.Overview,
  );

  useDeepCompareEffect(() => {
    const preparedTimesheetDuties = prepareDuties(timesheetDuties);

    const resources = getResources(
      [`clients::client::${client.slug}::operatives`],
      client.userGroups.results,
      [],
    );

    project.inlineData = {
      resourcesData: resources,
      eventsData: getEvents(preparedTimesheetDuties),
      assignmentsData: getAssignments(preparedTimesheetDuties),
    };
  }, [client, timesheetDuties]);

  return (
    <ActivityScheduleContext.Provider value={{ schedulerRef }}>
      <div className="flex h-full flex-col gap-y-4 p-4">
        <div className="flex justify-between">
          <div>
            <ButtonTabs
              className="bg-background"
              tabs={[
                {
                  label: 'Overview',
                  value: TimeRangeMode.Overview,
                },
                {
                  label: 'Gantt',
                  value: TimeRangeMode.Gantt,
                },
              ]}
              value={mode}
              onValueChange={setMode}
            />
          </div>
          <div className="flex items-center gap-x-2"></div>
        </div>
        {mode === TimeRangeMode.Overview && (
          <>
            <div className="min-h-0 overflow-hidden rounded-lg border">
              <div className="no-border-bottom">
                {interval?.start && interval?.end && (
                  <BryntumTimeline
                    project={project}
                    autoHeight
                    monitorResize={false}
                    minHeight={0}
                    height="auto"
                    rowHeight={60}
                    barMargin={12}
                    readOnly
                    enableEventAnimations
                    emptyText="No activity"
                    startDate={interval.start.toJSDate()}
                    endDate={interval.end.toJSDate()}
                    onEventClick={async ({ eventRecord }) => {
                      if (schedulerRef.current) {
                        const event =
                          schedulerRef.current.instance.eventStore.getById(
                            eventRecord.id,
                          );
                        if (event) {
                          await schedulerRef.current.instance.scrollEventIntoView(
                            event as EventModel,
                            { block: 'start' },
                          );
                        }
                      }
                    }}
                  />
                )}
              </div>
            </div>
            <div className="grow overflow-hidden rounded-lg border">
              <BryntumSchedulerPro
                {...config}
                ref={schedulerRef}
                project={project}
                emptyText="No activity"
                infiniteScroll={false}
                startDate={interval?.start?.toJSDate()}
                endDate={interval?.end?.toJSDate()}
              />
            </div>
          </>
        )}
        {mode === TimeRangeMode.Gantt && (
          <div className="min-h-0 grow overflow-hidden rounded-lg border">
            {/* @ts-ignore */}
            <BryntumGantt project={project} />
          </div>
        )}
      </div>
    </ActivityScheduleContext.Provider>
  );
};

export const getEvents = (preparedDuties: PreparedTimesheetDuty[]) => {
  return preparedDuties.map(
    (duty) =>
      ({
        id: duty.id,
        type: duty.user
          ? AssignmentResourceType.User
          : AssignmentResourceType.Subcontractor,
        name: !duty.isActive
          ? `#${duty.jobTask?.number}`
          : `${duty.job?.reference} #${duty.jobTask?.number}`,
        startDate: duty.dateTimeStart ? duty.dateTimeStart.toJSDate() : null,
        endDate: duty.dateTimeEnd ? duty.dateTimeEnd.toJSDate() : null,
        timesheetDuty: duty,
        showInTimeline: true,
        readOnly: true,
        isActive: true,
      }) as Partial<EventModelConfig>,
  );
};

export default ActivitySchedule;
