import React from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import SettingsHeader from './components/SettingsHeader';
import SettingsUserGroups from './components/SettingsUserGroups';

const SettingsGeneral = () => {
  return (
    <div className="min-h- flex h-full flex-col">
      <div className="flex h-[52px] flex-shrink-0 items-center justify-between border-b px-4 py-2">
        <h1 className="text-xl font-bold">General</h1>
      </div>
      <SettingsHeader />
      <ScrollArea className="min-h-0 grow">
        <SettingsUserGroups />
        <Separator />
      </ScrollArea>
    </div>
  );
};

export default SettingsGeneral;
