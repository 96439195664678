import React, { useMemo, useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  RendererField,
  duration,
  select,
  time,
} from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import chroma from 'chroma-js';
import { z } from 'zod';
import { AttentionMapping, WorkloadMapping } from '../../types';
import { RosterTeamSettingsProps } from './types';

export const workloadMapping: WorkloadMapping = {
  '1': {
    label: 'Extremely undemanding',
    value: '1',
    color: chroma('blue').alpha(0.5).hex(),
  },
  '2': {
    label: 'Moderately undemanding',
    value: '2',
    color: chroma('blue').alpha(0.25).hex(),
  },
  '3': {
    label: 'Moderately demanding',
    value: '3',
    color: chroma('red').alpha(0.25).hex(),
  },
  '4': {
    label: 'Extremely demanding',
    value: '4',
    color: chroma('red').alpha(0.5).hex(),
  },
};

export const attentionMapping: AttentionMapping = {
  '1': {
    label: 'Rarely or nearly none of the time',
    value: '1',
    color: chroma('blue').alpha(0.5).hex(),
  },
  '2': {
    label: 'Some of the time',
    value: '2',
    color: chroma('blue').alpha(0.25).hex(),
  },
  '3': {
    label: 'Most of the time',
    value: '3',
    color: chroma('red').alpha(0.25).hex(),
  },
  '4': {
    label: 'All or nearly all the time',
    value: '4',
    color: chroma('red').alpha(0.5).hex(),
  },
};

const RosterTeamSettings = ({
  className,
  isLoading = false,
  rosterTeam,
  onSubmit,
}: RosterTeamSettingsProps) => {
  const [open, setOpen] = useState(false);
  const fields = useMemo<RendererField[]>(
    () => [
      select({
        name: 'jobWorkload',
        label: 'Attention',
        description:
          'Estimate the physical workload and attention required to complete the tasks on a shift.',
        options: Object.values(workloadMapping).map((mapping) => ({
          label: mapping.label,
          value: mapping.value,
        })),
        schema: z.string().optional(),
        defaultValue: rosterTeam.defaults.jobWorkload,
      }),
      select({
        name: 'jobAttention',
        label: 'Attention',
        description:
          'Estimate the physical workload and attention required to complete the tasks on a shift.',
        options: Object.values(attentionMapping).map((mapping) => ({
          label: mapping.label,
          value: mapping.value,
        })),
        schema: z.string().optional(),
        defaultValue: rosterTeam.defaults.jobAttention,
      }),
      duration({
        name: 'breakFrequency',
        label: 'Break frequency',
        description: 'How frequently are breaks taken? (minutes)',
        defaultValue: rosterTeam.defaults.breakFrequency,
        schema: z.coerce.number().optional(),
      }),
      duration({
        name: 'longestContinuousWorkBeforeBreak',
        label: 'Longest continuous work before break',
        description: 'What is the longest period of continuous work? (minutes)',
        defaultValue: rosterTeam.defaults.longestContinuousWorkBeforeBreak,
        schema: z.coerce.number().optional(),
      }),
      duration({
        name: 'breakAverageLength',
        label: 'Average break length',
        description: 'What is the average length of each break? (minutes)',
        defaultValue: rosterTeam.defaults.breakAverageLength,
        schema: z.coerce.number().optional(),
      }),
      duration({
        name: 'breakLengthAfterContinuousWork',
        label: 'Typical break length after continuous work',
        description:
          'How long is the typical break after the longest period of continuous work? (minutes)',
        defaultValue: rosterTeam.defaults.breakLengthAfterContinuousWork,
        schema: z.coerce.number().optional(),
      }),
      time({
        name: 'timeStartDay',
        label: 'Normal day start time',
        description:
          'When does the typical day shift start? (e.g. 9:00 AM, 12:00 PM)',
        defaultValue: rosterTeam.defaults.timeStartDay,
        schema: z.string().optional(),
      }),
      time({
        name: 'timeStartNight',
        label: 'Normal night start time',
        description:
          'When does the typical night shift start? (e.g. 9:00 PM, 12:00 AM)',
        defaultValue: rosterTeam.defaults.timeStartNight,
        schema: z.string().optional(),
      }),
      duration({
        name: 'shiftDuration',
        label: 'Normal shift duration (minutes)',
        description:
          'How long is the typical shift? (e.g. 480 minutes, 8 hours)',
        defaultValue: rosterTeam.defaults.shiftDuration,
        schema: z.coerce.number().optional(),
      }),
      duration({
        name: 'commuteTime',
        label: 'Typical commute time (minutes)',
        description:
          'What is the typical commute time to and from the workplace? (minutes)',
        defaultValue: rosterTeam.defaults.commuteTime,
        schema: z.coerce.number().optional(),
      }),
    ],
    [
      rosterTeam.defaults.breakFrequency,
      rosterTeam.defaults.longestContinuousWorkBeforeBreak,
      rosterTeam.defaults.breakAverageLength,
      rosterTeam.defaults.breakLengthAfterContinuousWork,
      rosterTeam.defaults.timeStartDay,
      rosterTeam.defaults.timeStartNight,
      rosterTeam.defaults.shiftDuration,
      rosterTeam.defaults.jobAttention,
      rosterTeam.defaults.jobWorkload,
      rosterTeam.defaults.commuteTime,
    ],
  );
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button className={className} variant="outline" size="sm">
          Edit defaults
        </Button>
      </SheetTrigger>
      <SheetContent className="w-[500px] max-w-[500px] sm:max-w-[500px] p-0">
        <ScrollArea className="h-full">
          <div className="p-4">
            <FormRenderer fields={fields} onSubmit={onSubmit}>
              {({ onSubmit }) => (
                <div>
                  <Button
                    isLoading={isLoading}
                    className="w-full"
                    variant="outline"
                    onClick={async (event) => {
                      event.preventDefault();
                      await onSubmit();
                      setOpen(false);
                    }}
                  >
                    Save defaults
                  </Button>
                </div>
              )}
            </FormRenderer>
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

export default RosterTeamSettings;
