import React, { useMemo, useState } from 'react';
import { Button } from '@/components/ui/button';
import { input } from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import {
  FormRenderer,
  FormRendererSubmitHandler,
} from '@/components/ui/form/form-renderer/FormRenderer';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import UserGroups from '@/components/ui/user-groups';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { z } from 'zod';
import { useClient } from '../../../../../../hooks';

const SettingsUserGroups = () => {
  const client = useClient();
  const [open, setOpen] = useState(false);

  const fields = useMemo(
    () => [
      input({
        label: 'Name',
        name: 'name',
        schema: z.string(),
      }),
    ],
    [],
  );

  const [create] = useMutation(gql`
    mutation CreateUserGroup($clientId: ID!, $userGroupName: String!) {
      addUserGroupToClient(clientId: $clientId, userGroupName: $userGroupName) {
        client {
          id
        }
      }
    }
  `);

  const handleOnSubmit: FormRendererSubmitHandler<typeof fields> = async (
    values,
  ) => {
    await create({
      variables: {
        clientId: client.id,
        userGroupName: values.name,
      },
    });
    toast.success('User group created');
    setOpen(false);
  };

  return (
    <div className="p-4">
      <div className="mb-4 flex justify-between">
        <div className="mb-4">
          <h3 className="mb-1">User groups</h3>
          <p className="text-xs dark:text-white/75">
            User groups allow you to group users together to manage their
            permissions.
          </p>
        </div>
        <Sheet open={open} onOpenChange={setOpen}>
          <SheetTrigger asChild>
            <Button variant="outline" size="sm">
              Create user group
            </Button>
          </SheetTrigger>
          <SheetContent>
            <FormRenderer fields={fields} onSubmit={handleOnSubmit}>
              {({ onSubmit }) => (
                <Button className="w-full" onClick={onSubmit}>
                  Create user group
                </Button>
              )}
            </FormRenderer>
          </SheetContent>
        </Sheet>
      </div>
      <UserGroups ids={client.userGroupIds} />
    </div>
  );
};

export default SettingsUserGroups;
