import React from 'react';
import chroma from 'chroma-js';
import ReportSection from '../../../../../../../ReportSection';
import ReportText from '../../../../../../../ReportText';
import ReportView from '../../../../../../../ReportView';
import { FormFieldComponentProps } from '../../types';

const FieldBoolean = ({ item, value, media }: FormFieldComponentProps) => {
  const color = chroma(value?.value ? '#41a412' : '#fd0808');
  const backgroundColor = color.alpha(0.2).css();
  return (
    <ReportSection title={item.data.value} subtitle="" media={media}>
      <ReportView
        style={{
          textAlign: 'center',
          borderRadius: 4,
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 4,
          paddingBottom: 4,
          margin: 8,
          color: color.css(),
          backgroundColor,
        }}
      >
        <ReportText style={{ fontSize: 12, fontWeight: 'bold' }}>
          {value?.value ? 'Yes' : 'No'}
        </ReportText>
      </ReportView>
    </ReportSection>
  );
};

export default FieldBoolean;
