import React, { HTMLProps, useMemo, useState } from 'react';
import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { RedoIcon, UndoIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Loader } from '@/components/ui/loading';
import { ScrollArea } from '@/components/ui/scroll-area';
import FormTemplateEditor, { State } from 'external/FormTemplateEditor';
import { toast } from 'sonner';
import * as Y from 'yjs';
import { useYjs } from 'lib/hooks';
import { useFormTemplate } from './hooks';

const Editable = ({
  value,
  onChange,
  ...rest
}: Omit<HTMLProps<HTMLParagraphElement>, 'onChange'> & {
  value: string;
  onChange: any;
}) => {
  const [tempValue, setTempValue] = useState<string>();

  if (tempValue) {
    return <div></div>;
  }

  if (!value) {
    return (
      <div
        onClick={() => {
          setTempValue('No description. Click to edit..');
        }}
      >
        <p className="text-sm" {...rest}>
          No description. Click to edit...
        </p>
      </div>
    );
  }

  return (
    <div
      className="text-sm"
      onClick={() => {
        setTempValue(value);
      }}
    >
      <p {...rest}>{value}</p>
    </div>
  );
};

const SettingsFormEditor = ({
  children,
  id,
}: {
  children?: React.FC<{
    editor: React.ReactNode;
    store: State;
    undoManagers: Record<string, Y.UndoManager>;
  }>;
  id: string;
}) => {
  const shape = useMemo(
    () => ({
      pageOrder: [],
      items: {},
      choiceSets: {},
      fieldLabels: {},
    }),
    [],
  );

  const config = useMemo(
    () => ({
      undoKeys: {
        main: (inner: Y.Doc) =>
          new Y.UndoManager([
            inner.getArray('pageOrder'),
            inner.getMap('items'),
            inner.getMap('fieldLabels'),
          ]),
        choiceSets: (inner: Y.Doc) =>
          new Y.UndoManager(inner.getMap('choiceSets')),
      },
    }),
    [],
  );

  const { doc, isReady, store, undoManagers } = useYjs(
    'form-templates',
    id,
    shape,
    config,
  );

  if (!store) return null;
  if (!isReady)
    return (
      <div className="flex gap-x-2 p-4">
        <Loader>Syncing form...</Loader>
      </div>
    );

  const editor = (
    <FormTemplateEditor
      id={id}
      doc={doc.current as Y.Doc}
      store={store as State}
      undoManagers={undoManagers}
    />
  );

  if (children) return children({ editor, store, undoManagers });

  return (
    <FormTemplateEditor
      id={id}
      doc={doc.current as Y.Doc}
      store={store as State}
      undoManagers={undoManagers}
    />
  );
};

const SettingsForm = () => {
  const {
    isLoading,
    formTemplate,
    isPublishing,
    handleDescriptionOnChange,
    handleOnPublish,
  } = useFormTemplate();

  if (isLoading) {
    return (
      <div className="flex gap-x-2 p-4">
        <Loader>Syncing form...</Loader>
      </div>
    );
  }

  return formTemplate ? (
    <div className="flex h-full flex-col">
      <div>
        <div className="flex h-[52px] flex-shrink-0 items-center justify-between border-b px-4">
          <h1 className="text-xl font-bold">{formTemplate.name}</h1>
        </div>
      </div>
      <SettingsFormEditor id={formTemplate.id}>
        {({ editor, store, undoManagers }) => (
          <>
            <div className="flex justify-between border-b px-4 py-2">
              <div className="flex items-center gap-x-2">
                <Link to=".." relative="path">
                  <Button size="sm" value="outline" variant="ghost">
                    <ArrowLeftIcon className="mr-1 h-4 w-4" />
                    Back
                  </Button>
                </Link>
                <Editable
                  value={formTemplate?.description}
                  onChange={handleDescriptionOnChange}
                />
              </div>
              <div className="flex items-center gap-x-2 pr-[102px]">
                <Button
                  size="sm"
                  variant="outline"
                  isLoading={isPublishing}
                  onClick={async () => {
                    try {
                      await handleOnPublish(store);
                      toast.success('Form published successfully');
                    } catch (error: any) {
                      toast.error(error?.message || 'An error occurred');
                    }
                  }}
                >
                  Publish
                </Button>
                <div className="flex gap-x-1">
                  <Button
                    size="icon"
                    variant="outline"
                    onClick={() => {
                      undoManagers.main.undo();
                    }}
                  >
                    <UndoIcon className="h-4 w-4" />
                  </Button>
                  <Button
                    size="icon"
                    variant="outline"
                    onClick={() => {
                      undoManagers.main.redo();
                    }}
                  >
                    <RedoIcon className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>
            <ScrollArea className="min-h-0 grow bg-secondary px-4">
              <div className="py-4">{editor}</div>
            </ScrollArea>
          </>
        )}
      </SettingsFormEditor>
    </div>
  ) : null;
};

export default SettingsForm;
