import { gql, useQuery } from '@apollo/client';
import { useMatch } from 'lib/hooks';
import { JobIssue } from 'lib/types';
import { useJobContext } from '../../context';
import { JobIssueQueryData, JobIssueQueryVariables } from './types';

export const useJobIssue = () => {
  const { job } = useJobContext();

  const match = useMatch(
    '/clients/:clientSlug/jobs/:jobReference/issues/:jobIssueNumber/*',
  );

  const { data, refetch } = useQuery<JobIssueQueryData, JobIssueQueryVariables>(
    gql`
      query ($jobId: ID!, $jobIssueNumber: Int!) {
        job(jobId: $jobId) {
          id
          issue(jobIssueNumber: $jobIssueNumber) {
            id
            name
            number
            description
            slug
            status
            actionSetId
            dateTimeCreated
            links(model: "jobtask") {
              id
              source {
                ... on JobTask {
                  id
                  name
                  number
                  forms {
                    id
                    template {
                      id
                    }
                    latestSubmission {
                      id
                      data
                      template
                    }
                  }
                }
              }
            }
            jobTask {
              id
              number
              name
              description
              timesheetActivity {
                id
                users {
                  id
                  name
                  initials
                  email
                }
              }
            }
            items {
              id
              type
              description
              status
              resolutions {
                id
                dateTimeCreated
              }
            }
            users {
              id
              name
              initials
              email
            }
            closeOutData
          }
        }
      }
    `,
    {
      skip: job.partial,
      variables: job.partial
        ? undefined
        : {
            jobId: job.id,
            jobIssueNumber: parseInt(
              match?.params?.jobIssueNumber as string,
              10,
            ),
          },
    },
  );

  if (!data) {
    return {
      context: {
        issue: null as JobIssue | null,
      },
    };
  }

  return {
    context: {
      issue: {
        ...data.job.issue,
        refetch,
      } as JobIssue | null,
    },
  };
};
