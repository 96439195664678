import { createContext, useContext } from 'react';
import * as Y from 'yjs';
import { useCommercialActivity } from './hooks';

export const CommercialActivityContext = createContext(
  {} as ReturnType<typeof useCommercialActivity>['context'] & {
    store: any;
    doc: Y.Doc;
  },
);

export const useCommercialActivityContext = () => {
  return useContext(CommercialActivityContext);
};
