import { ChevronRight, GripVertical } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { CommandItem } from '@/components/ui/command';
import { cn } from '@/lib/utils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DebouncedNumberInput, TableSelector } from 'lib/shared';
import { CommercialType } from 'lib/types';
import { useCommercialEstimateContext } from '../../../../../../context';
import { useEstimateTableContext } from '../../../../context';
import CostMenu from '../CostMenu';
import LineDetail from './components/LineDetail';
import { CostLineContext } from './context';
import { useCostLine } from './hooks';
import { CostLineProps } from './types';

const CostLine = ({ line }: CostLineProps) => {
  const { expanded } = useCommercialEstimateContext();
  const { type } = useEstimateTableContext();
  const {
    context,
    context: { resources, handlers },
  } = useCostLine(line);

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: line.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <CostLineContext.Provider value={context}>
      <div
        className={cn('relative z-0 grid grid-cols-8 ring-1', {
          'z-10': isDragging,
          'ring-border': !isDragging,
        })}
        ref={setNodeRef}
        style={style}
        {...attributes}
      >
        <div className="col-span-3 flex border-r bg-background">
          <div className="w-8 flex-shrink-0 border-r">
            <Button
              className="size-8 flex-shrink-0 rounded-none p-0"
              variant="ghost"
              onClick={() => expanded.toggle(line.id)}
            >
              <ChevronRight
                className={cn(
                  'size-4',
                  expanded.is(line.id) && 'rotate-90 transform',
                )}
              />
            </Button>
          </div>
          {(type === CommercialType.Prelim ||
            type === CommercialType.Labor ||
            type === CommercialType.Subcontractor) && (
            <TableSelector
              className="text-sm"
              placeholder="Select rate"
              searchPlaceholder="Search resources"
              value={line.rate?.id}
              valueDisplay={line.rate?.user.name}
              onChange={async () => {}}
            >
              {({ onSelect }) =>
                resources.map((resource) => (
                  <CommandItem
                    key={resource.id}
                    value={resource.id}
                    keywords={[resource.user.name]}
                    className="cursor-pointer"
                    onSelect={onSelect}
                  >
                    <div className="flex w-full items-center justify-between">
                      <p className="text-[13px]">{resource.user.name}</p>
                    </div>
                  </CommandItem>
                ))
              }
            </TableSelector>
          )}
          {type === CommercialType.Material && (
            <TableSelector
              className="text-sm"
              popoverClassName="border-material/75"
              placeholder="Select material"
              searchPlaceholder="Search material"
              value={line.description}
              valueDisplay={line.description}
              searchDefault={line.description}
              onChange={async () => {}}
            >
              {({ onSelect }) => null}
            </TableSelector>
          )}
        </div>
        <div className="col-span-1 flex items-center border-r">
          <p className="px-2 text-sm">{line.unitDisplay}</p>
        </div>
        <div className="col-span-1 border-r bg-background">
          <DebouncedNumberInput
            className="h-full rounded-none border-none px-2 hover:bg-secondary focus:bg-secondary"
            min={0}
            value={line.estimatedQuantity?.toFixed(2)}
            onChange={handlers.estimatedQuantityOnChange}
          />
        </div>
        <div className="col-span-1 border-r bg-background">
          <DebouncedNumberInput
            className="h-full rounded-none border-none px-2 hover:bg-secondary focus:bg-secondary"
            min={0}
            value={line.estimatedUnitCost?.toFixed(2)}
            onChange={handlers.estimatedCostOnChange}
          />
        </div>
        <div className="col-span-1 flex">
          <div className="flex grow items-center border-r bg-background px-2 text-sm">
            {line.estimatedTotalCost?.toFixed(2)}
          </div>
          <CostMenu line={line} />
        </div>
        <div className="col-span-1" />
      </div>
      {expanded.is(line.id) && <LineDetail />}
    </CostLineContext.Provider>
  );
};

export default CostLine;
