import React, { useRef } from 'react';
import { MapRef } from 'react-map-gl';
import { DateTime } from 'luxon';
import { UserPhoto } from 'lib/shared';
import TimelineRenderer from '../../../../../../shared/TimelineRenderer';
import { useClient } from '../../../../hooks';
import ActivityMap from '../../../ClientActivity/components/ActivityMap';
import { MiniMapPosition } from '../../../ClientActivity/components/ActivityMap/components/MiniMap/types';
import { useOverviewDelivery } from './hooks';

const OverviewDelivery = () => {
  const client = useClient();
  const { operatives } = useOverviewDelivery();

  const { context } = TimelineRenderer.use({
    scale: 2,
    dateTimeStart: DateTime.now().minus({ days: 5 }),
    dateTimeEnd: DateTime.now().plus({ days: 5 }),
    jobs: client.jobs.all,
  });

  return (
    <TimelineRenderer.Provider value={context}>
      <div className="flex min-h-0 flex-grow flex-col gap-y-4 p-4">
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-3">
            <div className="flex flex-col gap-y-2">
              {operatives.map((operative) => (
                <div
                  key={operative.id}
                  className="flex gap-x-3 rounded-md bg-secondary p-2 pr-3 shadow-sm"
                >
                  <div>
                    <UserPhoto user={operative} />
                  </div>
                  <div className="flex flex-grow items-center">
                    <div>
                      <p className="text-xs font-bold">{operative.name}</p>
                      <p className="text-xs text-green-600">Available</p>
                    </div>
                  </div>
                  <div>
                    <div className="h-full w-[3px] rounded-full bg-green-600" />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-9 rounded-md bg-secondary">
            <Map />
          </div>
        </div>
        <div className="min-h-0 flex-grow overflow-hidden rounded-md border-2 bg-secondary">
          <TimelineRenderer
            asChild
            scale={2}
            dateTimeStart={DateTime.now().minus({ days: 5 })}
            dateTimeEnd={DateTime.now().plus({ days: 5 })}
            jobs={client.jobs.all}
          />
        </div>
      </div>
    </TimelineRenderer.Provider>
  );
};

const Map = () => {
  const { jobs } = TimelineRenderer.useContext();
  const mapRef = useRef<MapRef>(null);
  return (
    <ActivityMap
      jobs={jobs}
      mapRef={mapRef}
      className="p-2"
      miniMapPosition={MiniMapPosition.TopRight}
      miniMapClassName="h-[30%]"
    />
  );
};

export default OverviewDelivery;
