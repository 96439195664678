import { useCallback } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useLocalStorage } from '@uidotdev/usehooks';
import { toast } from 'sonner';
import { CommercialType } from 'lib/types';
import { useClient } from '../../../../../Client/hooks';
import { useJobCommercialContext } from '../../context';
import {
  CommercialEstimateQueryQueryData,
  CommercialEstimateQueryVariables,
} from './types';

export const useCommercialEstimate = (id: string) => {
  const client = useClient();

  const { activity } = useJobCommercialContext();

  const [expanded, setExpanded] = useLocalStorage(
    `commercial::commercial-estimate::${id}::expanded`,
    [] as string[],
  );

  const handleIsExpanded = useCallback(
    (id: string) => {
      return expanded.includes(id);
    },
    [expanded],
  );

  const handleToggleExpanded = useCallback(
    (id: string) => {
      setExpanded((prev) =>
        prev.includes(id)
          ? prev.filter((inner) => inner !== id)
          : [...prev, id],
      );
    },
    [setExpanded],
  );

  const [sections, setSections] = useLocalStorage(
    `commercial::commercial-estimate::${id}::sections`,
    [] as CommercialType[],
  );

  const handleAddSection = useCallback(
    (section: CommercialType) => {
      setSections((prev) => [...new Set([...(prev ?? []), section])]);
    },
    [setSections],
  );

  const handleRemoveSection = useCallback(
    (section: CommercialType) => {
      setSections((prev) => prev.filter((inner) => inner !== section));
    },
    [setSections],
  );

  const handleIsSectionOpen = useCallback(
    (section: CommercialType) => {
      return sections.includes(section);
    },
    [sections],
  );

  const { data, refetch } = useQuery<
    CommercialEstimateQueryQueryData,
    CommercialEstimateQueryVariables
  >(
    gql`
      query GetCommercialEstimate($clientId: ID!, $id: ID!) {
        commercialContracts(clientId: $clientId) {
          id
          name
          rates {
            id
            name
            description
            unit
            unitDisplay
            unitValue
          }
        }
        commercialResourceRates {
          id
          unitCost
          dateStart
          dateEnd
          user {
            id
            name
          }
        }
        commercialEstimate(commercialEstimateId: $id) {
          id
          number
          name
          description
          status
          statusDisplay
          contractPrelimFactor
          contractPrelimFactorDescription
          totalEstimatedValue
          totalEstimatedCost
          totalEstimatedCostWithPrelims
          totalEstimatedMargin
          totalEstimatedMarginWithPrelims
          totalEstimatedMarginRatio
          totalEstimatedMarginRatioWithPrelims
          totalQuoteValuePrelim: totalEstimatedValue(type: "PRELIM")
          totalQuoteValueLabor: totalEstimatedValue(type: "LABOR")
          totalQuoteValueLabor: totalEstimatedValue(type: "LABOR")
          totalQuoteValueMaterial: totalEstimatedValue(type: "MATERIAL")
          totalQuoteValueEquipment: totalEstimatedValue(type: "EQUIPMENT")
          totalQuoteValueSubcontractor: totalEstimatedValue(
            type: "SUBCONTRACTOR"
          )
          valueLines {
            id
            type
            unit
            unitDisplay
            rate {
              id
              description
            }
            description
            descriptionEstimate
            estimatedQuantity
            estimatedUnitValueWithUplift
            estimatedTotalValue
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      variables: { clientId: client.id, id },
    },
  );

  const [update] = useMutation(
    gql`
      mutation UpdateCommercialEstimate(
        $id: ID!
        $field: String!
        $value: String!
      ) {
        updateCommercialEstimate(
          commercialEstimateId: $id
          field: $field
          value: $value
        ) {
          commercialEstimate {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnUpdate = useCallback(
    async (field: string, value: string) => {
      const { data, errors } = await update({
        variables: {
          id,
          field,
          value,
        },
      });

      if (data) {
        // await refetch();
        toast.success('Estimate updated successfully');
      }

      if (errors) {
        toast.error('Failed to update estimate');
      }
    },
    [id, update, refetch],
  );

  if (!data?.commercialEstimate) {
    return {
      context: {
        activity: activity!,
        estimate: undefined,
        contracts: [],
        resources: [],
        expanded: {
          value: expanded,
          is: handleIsExpanded,
          set: setExpanded,
          toggle: handleToggleExpanded,
        },
        sections: {
          value: sections,
          isOpen: handleIsSectionOpen,
          add: handleAddSection,
          remove: handleRemoveSection,
        },
        handlers: {
          onUpdate: handleOnUpdate,
        },
      },
    };
  }

  return {
    context: {
      activity: activity!,
      estimate: {
        ...data.commercialEstimate,
        refetch,
      },
      contracts: data?.commercialContracts || [],
      resources: data?.commercialResourceRates || [],
      expanded: {
        value: expanded,
        is: handleIsExpanded,
        set: setExpanded,
        toggle: handleToggleExpanded,
      },
      sections: {
        value: sections,
        isOpen: handleIsSectionOpen,
        add: handleAddSection,
        remove: handleRemoveSection,
      },
      handlers: {
        onUpdate: handleOnUpdate,
      },
    },
  };
};
