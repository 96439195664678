import { createContext, useContext } from 'react';
import { useClientTodos } from './hooks';

export const ClientTodosContext = createContext(
  {} as ReturnType<typeof useClientTodos>['context'],
);

export const useClientTodosContext = () => {
  return useContext(ClientTodosContext);
};
