import React from 'react';
import { Button } from '@/components/ui/button';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { z } from 'zod';
import { useContractsCreate } from './hooks';

const ContractsCreate = () => {
  const { onCreate } = useContractsCreate();
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" size="sm">
          Create
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Create Contract</SheetTitle>
          <SheetDescription>Create a new contract</SheetDescription>
        </SheetHeader>
        <div>
          <FormRenderer
            fields={[
              {
                type: 'input',
                name: 'name',
                label: 'Name',
                schema: z.string(),
              },
              {
                type: 'input',
                name: 'description',
                label: 'Description',
                schema: z.string(),
              },
              {
                type: 'date',
                name: 'dateStart',
                label: 'Start',
                schema: z.any(),
              },
              {
                type: 'date',
                name: 'dateEnd',
                label: 'End',
                schema: z.any(),
              },
            ]}
            onSubmit={onCreate}
          />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default ContractsCreate;
