import React from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { cn } from '@/lib/utils';
import { ItemType } from 'external/Tree';
import { useFormTemplateContext } from '../../context';
import BlockConditionRenderer from './components/BlockConditionRenderer';
import BlockLogicCondition from './components/BlockLogicCondition';
import { BlockLogicProps } from './types';

const BlockLogic = ({ item, border, getBorderBottom }: BlockLogicProps) => {
  const { collapsed, store, getLogicFor } = useFormTemplateContext();
  const { items } = useSyncedStore(store);

  const isCollapsed = collapsed.includes(item.id);

  const logic = getLogicFor(item);
  const logicHasChildBlocks = (logic?.children ?? []).some(
    (childId) =>
      items[childId]?.type === ItemType.BLOCK ||
      items[childId]?.type === ItemType.CONTAINER,
  );

  if (
    !isCollapsed &&
    item.type === ItemType.BLOCK &&
    item.children.length > 0
  ) {
    return (
      <div>
        <div
          className={cn('flex border-x bg-background', {
            'border-b': !!getBorderBottom('logic-selector'),
          })}
        >
          {item.children.map((childId) => (
            <BlockLogicCondition key={childId} parent={item} itemId={childId} />
          ))}
        </div>
        <div
          className={cn('border-x bg-background', {
            'border-b': !!getBorderBottom('logic-renderer'),
            'rounded-bl': !logicHasChildBlocks || isCollapsed,
          })}
        >
          <BlockConditionRenderer item={item} />
        </div>
      </div>
    );
  }

  return null;
};

export default BlockLogic;
