import { createContext, useContext } from 'react';
import { useCostLine } from './hooks';

export const CostLineContext = createContext(
  {} as ReturnType<typeof useCostLine>['context'],
);

export const useCostLineContext = () => {
  return useContext(CostLineContext);
};
