import {
  OperationVariables,
  SubscriptionHookOptions,
  useSubscription,
} from '@apollo/client';
import { DocumentNode } from 'graphql/index';
import { platformSubscriptionClient } from '../app/platform';

const usePlatformSubscription = <
  TData,
  TVariables extends OperationVariables = any,
>(
  query: DocumentNode,
  options: SubscriptionHookOptions<TData, TVariables> = {},
) => {
  return useSubscription<TData, TVariables>(query, {
    ...options,
    client: platformSubscriptionClient,
  });
};

export default usePlatformSubscription;
