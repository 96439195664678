import React, { PropsWithChildren, useMemo, useState } from 'react';
import { CogIcon } from 'lucide-react';
import { useFormContext } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import {
  RendererField,
  duration,
  select,
} from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { useFormField } from '@/components/ui/form/form-field/FormField';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import chroma from 'chroma-js';
import { z } from 'zod';
import { RosterDuty, RosterTeam } from 'lib/types';

type Mapping = Record<
  string,
  {
    label: string;
    value: string;
    color: string;
  }
>;

export const workloadMapping: Mapping = {
  '1': {
    label: 'Extremely undemanding',
    value: '1',
    color: chroma('blue').alpha(0.5).hex(),
  },
  '2': {
    label: 'Moderately undemanding',
    value: '2',
    color: chroma('blue').alpha(0.25).hex(),
  },
  '3': {
    label: 'Moderately demanding',
    value: '3',
    color: chroma('red').alpha(0.25).hex(),
  },
  '4': {
    label: 'Extremely demanding',
    value: '4',
    color: chroma('red').alpha(0.5).hex(),
  },
};

export const attentionMapping: Mapping = {
  '1': {
    label: 'Rarely or nearly none of the time',
    value: '1',
    color: chroma('blue').alpha(0.5).hex(),
  },
  '2': {
    label: 'Some of the time',
    value: '2',
    color: chroma('blue').alpha(0.25).hex(),
  },
  '3': {
    label: 'Most of the time',
    value: '3',
    color: chroma('red').alpha(0.25).hex(),
  },
  '4': {
    label: 'All or nearly all the time',
    value: '4',
    color: chroma('red').alpha(0.5).hex(),
  },
};

const Label = ({
  children,
  teamDefault,
}: PropsWithChildren<{ teamDefault: any }>) => {
  const { watch, setValue } = useFormContext();
  const { name } = useFormField();
  const value = watch(name);
  return (
    <div>
      {children}
      {value === teamDefault ? (
        <div className="flex h-[32px] items-center justify-between">
          <p className="text-xs text-gray-400">Currently set to team default</p>
        </div>
      ) : (
        <div className="flex items-center justify-between">
          <p className="text-xs text-gray-400">Changed from team default</p>
          <Button
            size="sm"
            variant="outline"
            onClick={() => {
              setValue(name, teamDefault);
            }}
          >
            Restore to {teamDefault}
          </Button>
        </div>
      )}
    </div>
  );
};

const RosterDutySettings = ({
  team,
  duty,
  onReset,
  onSubmit: handleOnSubmit,
}: {
  team: RosterTeam;
  duty: RosterDuty;
  onReset: () => Promise<void>;
  onSubmit: (values: any) => Promise<void>;
}) => {
  const [open, setOpen] = useState(false);
  const fields = useMemo<RendererField[]>(
    () => [
      select({
        name: 'jobWorkload',
        label: <Label teamDefault={team.defaults.jobWorkload}>Workflow</Label>,
        description:
          'Estimate the physical workload and attention required to complete the tasks on a shift.',
        options: Object.values(workloadMapping).map((mapping) => ({
          label: mapping.label,
          value: mapping.value,
        })),
        schema: z.string().optional(),
        defaultValue: duty.settings.jobWorkload,
      }),
      select({
        name: 'jobAttention',
        label: (
          <Label teamDefault={team.defaults.jobAttention}>Attention</Label>
        ),
        description:
          'Estimate the physical workload and attention required to complete the tasks on a shift.',
        options: Object.values(attentionMapping).map((mapping) => ({
          label: mapping.label,
          value: mapping.value,
        })),
        schema: z.string().optional(),
        defaultValue: duty.settings.jobAttention,
      }),
      duration({
        name: 'breakFrequency',
        label: (
          <Label teamDefault={team.defaults.breakFrequency}>
            Break frequency
          </Label>
        ),
        description: 'How frequently are breaks taken? (minutes)',
        defaultValue: duty.settings.breakFrequency,
        schema: z.coerce.number().optional(),
      }),
      duration({
        name: 'breakAverageLength',
        label: (
          <Label teamDefault={team.defaults.breakAverageLength}>
            Average break length
          </Label>
        ),
        description: 'What is the average length of each break? (minutes)',
        defaultValue: duty.settings.breakAverageLength,
        schema: z.coerce.number().optional(),
      }),
      duration({
        name: 'longestContinuousWorkBeforeBreak',
        label: (
          <Label teamDefault={duty.settings.longestContinuousWorkBeforeBreak}>
            Longest continuous work before break
          </Label>
        ),
        description: 'What is the longest period of continuous work? (minutes)',
        defaultValue: duty.settings.longestContinuousWorkBeforeBreak,
        schema: z.coerce.number().optional(),
      }),
      duration({
        name: 'breakLengthAfterContinuousWork',
        label: (
          <Label teamDefault={duty.settings.breakLengthAfterContinuousWork}>
            Typical break length after continuous work
          </Label>
        ),
        description:
          'How long is the typical break after the longest period of continuous work? (minutes)',
        defaultValue: duty.settings.breakLengthAfterContinuousWork,
        schema: z.coerce.number().optional(),
      }),
    ],
    [
      duty.settings.jobWorkload,
      duty.settings.jobAttention,
      duty.settings.breakFrequency,
      duty.settings.breakAverageLength,
      duty.settings.longestContinuousWorkBeforeBreak,
      duty.settings.breakLengthAfterContinuousWork,
      team.defaults.jobWorkload,
      team.defaults.jobAttention,
      team.defaults.breakFrequency,
      team.defaults.breakAverageLength,
    ],
  );
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant="outline" size="icon">
          <CogIcon className="h-4 w-4" />
        </Button>
      </SheetTrigger>
      <SheetContent className="w-[500px] max-w-[500px] p-0 sm:max-w-[500px]">
        <ScrollArea className="h-full">
          <div className="p-4">
            <FormRenderer fields={fields} onSubmit={handleOnSubmit}>
              {({ onSubmit }) => (
                <div>
                  <Button
                    className="w-full"
                    variant="outline"
                    onClick={async (event) => {
                      event.preventDefault();
                      await onSubmit();
                      setOpen(false);
                    }}
                  >
                    Save duty settings
                  </Button>
                </div>
              )}
            </FormRenderer>
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

export default RosterDutySettings;
