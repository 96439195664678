import React, { useCallback, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { ScopeContext } from './context';
import { ScopeDataQueryData, ScopeDataQueryVariables } from './types';

export const ScopeProvider = ({ children }: React.PropsWithChildren) => {
  const [registeredScopes, setRegisteredScopes] = useState<string[]>([]);
  const [scopeData, setScopeData] = useState<Record<string, any>>({});

  const registerScopes = useCallback(
    (scopes: string[]) => {
      setRegisteredScopes((prevRegisteredScopes) => [
        ...new Set([...prevRegisteredScopes, ...scopes]),
      ]);
    },
    [setRegisteredScopes],
  );

  const { loading: isLoading } = useQuery<
    ScopeDataQueryData,
    ScopeDataQueryVariables
  >(
    gql`
      query GetScopeData($scopes: [String!]!) {
        scopeData(scopes: $scopes) {
          scope
          orderKey
          data
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      skip: !registeredScopes.length,
      variables: { scopes: Array.from(new Set(registeredScopes)) },
      onCompleted: (data) => {
        setScopeData(
          data.scopeData.reduce(
            (ret, scopeResponse) => ({
              ...ret,
              [scopeResponse.scope]: {
                ...scopeResponse.data,
                orderKey: scopeResponse.orderKey,
              },
            }),
            {},
          ),
        );
      },
    },
  );

  return (
    <ScopeContext.Provider
      value={{
        isLoading,
        scopeData,
        registerScopes,
      }}
    >
      {children}
    </ScopeContext.Provider>
  );
};

export default ScopeProvider;
