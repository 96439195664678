import React from 'react';
import { Button } from '@/components/ui/button';
import { TableRenderer } from '@/components/ui/table/table/Table';
import { Link } from 'lib/shared';
import { useClient } from '../../hooks';
import AssetsCreate from './components/AssetsCreate';
import { useClientAssets } from './hooks';

const ClientAssets = () => {
  const { paths } = useClient();
  const { assets } = useClientAssets();
  return (
    <div className="h-full min-h-0">
      <div className="flex h-[52px] flex-shrink-0 items-center border-b px-4 py-2">
        <h1 className="text-xl font-bold">Assets</h1>
      </div>
      <div className="min-h-0 grow">
        <div className="px-4">
          <TableRenderer
            filter="reference"
            columns={[
              {
                accessorKey: 'reference',
                header: 'Reference',
                cell: ({ row, renderValue }) => (
                  <Link to={paths.assets.asset(row.original)}>
                    <Button size="sm" variant="link" className="h-4 p-0">
                      {renderValue() as string}
                    </Button>
                  </Link>
                ),
              },
            ]}
            data={assets}
            topRight={({ columnsSelector }) => (
              <div className="ml-auto space-x-2">
                <AssetsCreate />
                {columnsSelector}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientAssets;
