import React, { useState } from 'react';
import { ChevronDown, ChevronLeft, ChevronRight, DotIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Loader } from '@/components/ui/loading';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { gql, useQuery } from '@apollo/client';
import { DateTime, Interval } from 'luxon';
import { getWeekNumber } from 'lib/utils';
import { DayClipper } from '../../pages/HumanResourcesRostersCalendar';

const VerticalCalendar = ({
  small,
  active = false,
  interval,
  intervalBuffer = [2, 49],
  intervalOnChange = () => {},
  variables,
  deps,
}: {
  small?: boolean;
  active?: boolean;
  interval: Interval;
  intervalBuffer?: [number, number];
  intervalOnChange?: (interval: Interval) => void;
  variables: any;
  deps: any[];
}) => {
  const [range, setRange] = useState<Interval | null>(() => {
    if (!interval.start || !interval.end) return null;
    if (small) return interval;
    const dateStartInitial = DateTime.fromObject({
      month: interval.start.month,
      year: interval.start.year,
    });
    const [startBuffer, endBuffer] = intervalBuffer;
    const dateStart = dateStartInitial
      .startOf('week')
      .minus({ days: startBuffer });
    const dateEnd = dateStart.plus({ days: endBuffer });
    return Interval.fromDateTimes(dateStart, dateEnd);
  });

  const { data } = useQuery(
    gql`
      query GetCalendarData(
        $dateTimeStart: DateTime!
        $dateTimeEnd: DateTime!
        $team: ID
        $user: ID
      ) {
        rosterDutyCountPerDay(
          rangeDateTimeStart: $dateTimeStart
          rangeDateTimeEnd: $dateTimeEnd
          teamId: $team
          userId: $user
          status: 0
        )

        rosterDutySubmittedPerDay: rosterDutyCountPerDay(
          rangeDateTimeStart: $dateTimeStart
          rangeDateTimeEnd: $dateTimeEnd
          teamId: $team
          userId: $user
          status: 1
        )

        rosterDutyApprovedPerDay: rosterDutyCountPerDay(
          rangeDateTimeStart: $dateTimeStart
          rangeDateTimeEnd: $dateTimeEnd
          teamId: $team
          userId: $user
          status: 2
        )
      }
    `,
    {
      skip: !range?.start || !range?.end,
      fetchPolicy: 'no-cache',
      variables: {
        ...variables,
        dateTimeStart: range?.start as DateTime,
        dateTimeEnd: range?.end as DateTime,
        deps,
      },
    },
  );

  const {
    rosterDutyCountPerDay,
    rosterDutySubmittedPerDay,
    rosterDutyApprovedPerDay,
  } = data ?? {
    rosterDutyCountPerDay: {},
    rosterDutySubmittedPerDay: {},
    rosterDutyApprovedPerDay: {},
  };

  return !range?.start || !range?.end ? null : (
    <div className={cn({ 'opacity-50': !active })}>
      {!small && (
        <div className={cn({ 'px-4': !small })}>
          <Button
            size="sm"
            className="mb-2 w-full"
            variant="outline"
            disabled={!active || range.engulfs(interval)}
            onClick={() => {
              if (!interval.start || !interval.end) return null;
              const dateStartInitial = DateTime.fromObject({
                month: interval.start.month,
                year: interval.start.year,
              });
              const dateStart = dateStartInitial
                .startOf('week')
                .minus({ days: 2 });
              const dateEnd = dateStart.plus({ days: 42 });
              setRange(Interval.fromDateTimes(dateStart, dateEnd));
            }}
          >
            Present
          </Button>
          <Button
            size="sm"
            className="w-full"
            variant="outline"
            disabled={!active}
            onClick={() => {
              setRange((prevRange) =>
                Interval.fromDateTimes(
                  prevRange.start.minus({ days: 7 }),
                  prevRange.end.minus({ days: 7 }),
                ),
              );
            }}
          >
            Up
          </Button>
        </div>
      )}
      <div
        className={cn({
          'px-4 pt-2': !small,
        })}
      >
        <table className="w-full table-fixed">
          <thead>
            <tr>
              <th className="w-[26px] text-xs text-gray-400">Wk</th>
              {Interval.fromDateTimes(
                range.start,
                range.start.plus({ weeks: 1 }),
              )
                .splitBy({ days: 1 })
                .map((day, dayIndex, days) => {
                  return day.start ? (
                    <th
                      key={day.start.toISO()}
                      className={cn('text-sm text-gray-600', {
                        'pr-1': dayIndex === days.length - 1,
                      })}
                    >
                      {day.start.toFormat('EEE')}
                    </th>
                  ) : null;
                })}
            </tr>
          </thead>
          <tbody>
            {range.splitBy({ week: 1 }).map((week) => {
              const isWeekSelected = interval.equals(week);
              return (
                <tr
                  key={week.toISO()}
                  className={cn('fill-background hover:fill-muted', {
                    'fill-muted': !small && isWeekSelected,
                    group: active,
                  })}
                  onClick={() => {
                    if (active) {
                      intervalOnChange(week);
                    }
                  }}
                >
                  <td
                    className={cn(
                      'w-[26px] rounded-l-md pl-1 text-center text-xs text-gray-400',
                      {
                        'bg-muted': !small && isWeekSelected,
                        'cursor-pointer group-hover:bg-muted': active,
                      },
                    )}
                  >
                    {getWeekNumber(week.start, week.start.startOf('year')) + 1}
                  </td>
                  {week.splitBy({ days: 1 }).map((day, dayIndex) => {
                    if (!day.start) return null;
                    const isFirstOfWeek = dayIndex === 0;
                    const isLastOfWeek = dayIndex === 6;
                    const countKey = day.start.toFormat('yyyy-MM-dd') as string;
                    const hasDuties = rosterDutyCountPerDay[countKey] > 0;
                    const hasSubmitted =
                      rosterDutySubmittedPerDay[countKey] > 0;
                    const hasApproved = rosterDutyApprovedPerDay[countKey] > 0;
                    return (
                      <td
                        key={day.toISO()}
                        className={cn(
                          'relative border-dashed text-center last:rounded-r-md',
                          {
                            'bg-muted': !small && isWeekSelected,
                            'cursor-pointer group-hover:bg-muted': active,
                            'pr-1': isLastOfWeek,
                            'py-2': small,
                            'py-4': !small,
                          },
                        )}
                      >
                        <span
                          className={cn('relative z-[2]', {
                            'text-xs': small,
                          })}
                        >
                          {day.start.day}
                        </span>
                        {active && (
                          <>
                            {!hasDuties && !hasSubmitted && !hasApproved && (
                              <DayClipper />
                            )}
                            {hasDuties && (hasSubmitted || hasApproved) && (
                              <div className="absolute -bottom-1 left-1/2 -translate-x-[50%]">
                                <DotIcon className="text-gray-400" />
                              </div>
                            )}
                            {hasDuties && !hasSubmitted && !hasApproved && (
                              <div
                                className={cn(
                                  'absolute bottom-1/4 left-0 right-0 top-1/4 opacity-25',
                                  {
                                    'rounded-l': isFirstOfWeek,
                                    'rounded-r': isLastOfWeek,
                                  },
                                )}
                                style={{ backgroundColor: 'gray' }}
                              />
                            )}
                            {hasApproved && !hasSubmitted && (
                              <div
                                className={cn(
                                  'absolute bottom-1/4 left-0 right-0 top-1/4 opacity-25',
                                  {
                                    'rounded-l': isFirstOfWeek,
                                    'rounded-r': isLastOfWeek,
                                  },
                                )}
                                style={{ backgroundColor: 'green' }}
                              />
                            )}
                            {hasApproved && hasSubmitted && (
                              <>
                                <div
                                  className={cn(
                                    'absolute bottom-1/2 left-0 right-0 top-1/4 opacity-25',
                                    {
                                      'rounded-tl': isFirstOfWeek,
                                      'rounded-tr': isLastOfWeek,
                                    },
                                  )}
                                  style={{ backgroundColor: 'green' }}
                                />
                                <div
                                  className={cn(
                                    'absolute bottom-1/4 left-0 right-0 top-1/2 opacity-25',
                                    {
                                      'rounded-bl': isFirstOfWeek,
                                      'rounded-br': isLastOfWeek,
                                    },
                                  )}
                                  style={{ backgroundColor: 'red' }}
                                />
                              </>
                            )}
                            {!hasApproved && hasSubmitted && (
                              <div
                                className={cn(
                                  'absolute bottom-1/4 left-0 right-0 top-1/4 opacity-25',
                                  {
                                    'rounded-l': isFirstOfWeek,
                                    'rounded-r': isLastOfWeek,
                                  },
                                )}
                                style={{ backgroundColor: 'red' }}
                              />
                            )}
                          </>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {!small && (
        <div className="px-4">
          <Button
            size="sm"
            className="w-full"
            variant="outline"
            disabled={!active}
            onClick={() => {
              setRange((prevRange) =>
                Interval.fromDateTimes(
                  prevRange.start.plus({ days: 7 }),
                  prevRange.end.plus({ days: 7 }),
                ),
              );
            }}
          >
            Down
          </Button>
        </div>
      )}
    </div>
  );
};

export const HorizontalCalendar = ({
  small = true,
  interval,
  variables,
  deps,
}: {
  small?: boolean;
  interval: Interval;
  variables: any;
  deps: any[];
}) => {
  const range = interval;
  const { data } = useQuery(
    gql`
      query GetCalendarData(
        $dateTimeStart: DateTime!
        $dateTimeEnd: DateTime!
        $team: ID
        $user: ID
      ) {
        rosterDutyCountPerDay(
          rangeDateTimeStart: $dateTimeStart
          rangeDateTimeEnd: $dateTimeEnd
          teamId: $team
          userId: $user
          status: 0
        )

        rosterDutySubmittedPerDay: rosterDutyCountPerDay(
          rangeDateTimeStart: $dateTimeStart
          rangeDateTimeEnd: $dateTimeEnd
          teamId: $team
          userId: $user
          status: 1
        )

        rosterDutyApprovedPerDay: rosterDutyCountPerDay(
          rangeDateTimeStart: $dateTimeStart
          rangeDateTimeEnd: $dateTimeEnd
          teamId: $team
          userId: $user
          status: 2
        )
      }
    `,
    {
      skip: !range?.start || !range?.end,
      fetchPolicy: 'no-cache',
      variables: {
        ...variables,
        dateTimeStart: range?.start as DateTime,
        dateTimeEnd: range?.end as DateTime,
        deps,
      },
    },
  );

  const {
    rosterDutyCountPerDay,
    rosterDutySubmittedPerDay,
    rosterDutyApprovedPerDay,
  } = data ?? {
    rosterDutyCountPerDay: {},
    rosterDutySubmittedPerDay: {},
    rosterDutyApprovedPerDay: {},
  };

  return !range?.start || !range?.end ? null : (
    <div
      className={cn({
        'px-4 pt-2': !small,
      })}
    >
      <table className="w-full table-fixed">
        <thead>
          <tr>
            <th className="w-[26px] text-xs text-gray-400">Wk</th>
            {Interval.fromDateTimes(range.start, range.start.plus({ weeks: 1 }))
              .splitBy({ days: 1 })
              .map((day, dayIndex, days) => {
                return day.start ? (
                  <th
                    key={day.start.toISO()}
                    className={cn('text-sm text-gray-600', {
                      'pr-1': dayIndex === days.length - 1,
                    })}
                  >
                    {day.start.toFormat('EEE')}
                  </th>
                ) : null;
              })}
          </tr>
        </thead>
        <tbody>
          {range.splitBy({ week: 1 }).map((week) => {
            const isWeekSelected = interval.equals(week);
            return (
              <tr
                key={week.toISO()}
                className="group/week group/week-selected overflow-hidden"
              >
                <td
                  className={cn(
                    'w-[26px] rounded-l-md pl-1 text-center text-xs text-gray-400',
                    { 'bg-muted': !small && isWeekSelected },
                  )}
                >
                  {getWeekNumber(week.start, week.start.startOf('year')) + 1}
                </td>
                {week.splitBy({ days: 1 }).map((day, dayIndex) => {
                  if (!day.start) return null;
                  const isFirstOfWeek = dayIndex === 0;
                  const isLastOfWeek = dayIndex === 6;
                  const countKey = day.start.toFormat('yyyy-MM-dd') as string;
                  const hasDuties = rosterDutyCountPerDay[countKey] > 0;
                  const hasSubmitted = rosterDutySubmittedPerDay[countKey] > 0;
                  const hasApproved = rosterDutyApprovedPerDay[countKey] > 0;
                  return (
                    <td
                      key={day.toISO()}
                      className={cn(
                        'relative border-dashed text-center last:rounded-r-md',
                        {
                          'bg-muted': !small && isWeekSelected,
                          'pr-1': isLastOfWeek,
                          'py-4': !small,
                          'py-2': small,
                        },
                      )}
                    >
                      <span
                        className={cn('relative z-[2]', {
                          'text-xs': small,
                        })}
                      >
                        {day.start.day}
                      </span>
                      {!hasDuties && !hasSubmitted && !hasApproved && (
                        <DayClipper />
                      )}
                      {hasDuties && (hasSubmitted || hasApproved) && (
                        <div className="absolute -bottom-1 left-1/2 -translate-x-[50%]">
                          <DotIcon className="text-gray-400" />
                        </div>
                      )}
                      {hasDuties && !hasSubmitted && !hasApproved && (
                        <div
                          className={cn(
                            'absolute bottom-1/4 left-0 right-0 top-1/4 opacity-25',
                            {
                              'rounded-l': isFirstOfWeek,
                              'rounded-r': isLastOfWeek,
                            },
                          )}
                          style={{ backgroundColor: 'gray' }}
                        />
                      )}
                      {hasApproved && !hasSubmitted && (
                        <div
                          className={cn(
                            'absolute bottom-1/4 left-0 right-0 top-1/4 opacity-25',
                            {
                              'rounded-l': isFirstOfWeek,
                              'rounded-r': isLastOfWeek,
                            },
                          )}
                          style={{ backgroundColor: 'green' }}
                        />
                      )}
                      {hasApproved && hasSubmitted && (
                        <>
                          <div
                            className={cn(
                              'absolute bottom-1/2 left-0 right-0 top-1/4 opacity-25',
                              {
                                'rounded-tl': isFirstOfWeek,
                                'rounded-tr': isLastOfWeek,
                              },
                            )}
                            style={{ backgroundColor: 'green' }}
                          />
                          <div
                            className={cn(
                              'absolute bottom-1/4 left-0 right-0 top-1/2 opacity-25',
                              {
                                'rounded-bl': isFirstOfWeek,
                                'rounded-br': isLastOfWeek,
                              },
                            )}
                            style={{ backgroundColor: 'red' }}
                          />
                        </>
                      )}
                      {!hasApproved && hasSubmitted && (
                        <div
                          className={cn(
                            'absolute bottom-1/4 left-0 right-0 top-1/4 opacity-25',
                            {
                              'rounded-l': isFirstOfWeek,
                              'rounded-r': isLastOfWeek,
                            },
                          )}
                          style={{ backgroundColor: 'red' }}
                        />
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export const VerticalCalendarSelector = ({
  isLoading = false,
  interval,
  intervalBuffer = [2, 49],
  intervalOnChange = () => {},
  variables,
  deps,
}: {
  isLoading?: boolean;
  interval: Interval;
  intervalBuffer?: [number, number];
  intervalOnChange?: (interval: Interval) => void;
  variables: any;
  deps: any[];
}) => {
  return (
    <div className="flex items-center gap-x-2">
      <Button
        variant="outline"
        size="icon"
        className="h-[50px]"
        onClick={() => {
          intervalOnChange(
            Interval.fromDateTimes(
              interval.start.minus({ days: 7 }),
              interval.end.minus({ days: 7 }),
            ),
          );
        }}
      >
        <ChevronLeft className="h-4 w-4" />
      </Button>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            disabled={isLoading}
            variant="outline"
            className="h-auto grow justify-between text-left text-xs"
          >
            <div>
              <p className="font-semibold">
                Week{' '}
                {getWeekNumber(interval.start, interval.start.startOf('year')) +
                  1}
              </p>
              <p>Starting {interval.start.toFormat('dd MMMM yyyy')}</p>
            </div>
            {isLoading ? (
              <Loader className="h-4 w-4" />
            ) : (
              <ChevronDown className="h-4 w-4" />
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-80 px-0 py-4">
          <VerticalCalendar
            active
            interval={interval}
            intervalBuffer={intervalBuffer}
            intervalOnChange={intervalOnChange}
            variables={variables}
            deps={deps}
          />
        </PopoverContent>
      </Popover>
      <Button
        variant="outline"
        size="icon"
        className="h-[50px]"
        onClick={() => {
          intervalOnChange(
            Interval.fromDateTimes(
              interval.start.plus({ days: 7 }),
              interval.end.plus({ days: 7 }),
            ),
          );
        }}
      >
        <ChevronRight className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default VerticalCalendar;
