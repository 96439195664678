import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { usePlatformObject } from 'lib/hooks';
import { useMemberTrainingFormContext } from '../../context';
import { MemberTrainingFormMode } from '../../types';
import { TrainingQualificationsQueryData } from './types';

export const useFieldQualification = (formField: any) => {
  const { mode } = useMemberTrainingFormContext();

  const [open, setOpen] = useState(false);

  const { object } = usePlatformObject(formField.value?.logo?.id);

  const { data } = useQuery<TrainingQualificationsQueryData>(
    gql`
      query GetTrainingQualifications {
        trainingQualifications {
          id
          name
          description
          color
          logo {
            id
          }
          fields
          data
        }
      }
    `,
    {
      skip: mode !== MemberTrainingFormMode.Create,
      fetchPolicy: 'cache-and-network',
    },
  );

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
      onClose: () => setOpen(false),
    },
    object,
    data,
  };
};
