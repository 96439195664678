import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useEstimateTableContext } from '../../../../context';
import CostCreate from '../CostCreate';
import CostLine from '../CostLine';

const CostBody = () => {
  const { costLines } = useEstimateTableContext();

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  return (
    <>
      <div className="relative">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          // onDragStart={dragEventHandlers.onDragStart}
          // onDragCancel={dragEventHandlers.onDragCancel}
          // onDragEnd={dragEventHandlers.onDragEnd}
        >
          <SortableContext
            items={costLines}
            strategy={verticalListSortingStrategy}
          >
            {costLines.map((line) => (
              <CostLine key={line.id} line={line} />
            ))}
          </SortableContext>
        </DndContext>
      </div>
      <CostCreate />
    </>
  );
};

export default CostBody;
