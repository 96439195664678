import { cn } from '@/lib/utils';
import { useCommercialEstimateContext } from '../../../../../../context';
import { useEstimateTableContext } from '../../../../context';

const CostFooter = () => {
  const { estimate } = useCommercialEstimateContext();
  const {
    totalEstimatedCost,
    totalEstimatedCostWithPrelims,
    totalEstimatedMargin,
    totalEstimatedMarginWithPrelims,
  } = useEstimateTableContext();
  return (
    <>
      {estimate?.contractPrelimFactor !== null && (
        <div className="grid grid-cols-8 border-b">
          <div className="col-span-5 border-r" />
          <div className="col-span-1 flex items-center border-r px-2 text-xs font-semibold">
            Subtotal
          </div>
          <div className="col-span-1 flex items-center px-2">
            {totalEstimatedCost.toFixed(2)}
          </div>
          <div
            className={cn('col-span-1 flex h-8 items-center px-2 font-bold', {
              'bg-red-500/25 text-red-500': totalEstimatedMargin < 0,
              'bg-green-700/25 text-green-700': totalEstimatedMargin > 0,
            })}
          >
            {totalEstimatedMargin.toFixed(2)}
          </div>
        </div>
      )}
      <div className="grid grid-cols-8">
        <div className="col-span-5 border-r" />
        <div className="col-span-1 flex items-center border-r px-2 text-xs font-semibold">
          Total{estimate?.contractPrelimFactor !== null && ' with prelims'}
        </div>
        <div className="col-span-1 flex items-center border-r bg-secondary px-2">
          {totalEstimatedCostWithPrelims.toFixed(2)}
        </div>
        <div
          className={cn('col-span-1 flex h-8 items-center px-2 font-bold', {
            'bg-red-500/25 text-red-500': totalEstimatedMarginWithPrelims < 0,
            'bg-green-700/25 text-green-700':
              totalEstimatedMarginWithPrelims > 0,
          })}
        >
          {totalEstimatedMarginWithPrelims.toFixed(2)}
        </div>
      </div>
    </>
  );
};

export default CostFooter;
