import React, { useEffect } from 'react';
import { useDebounce } from 'react-use';
import { Loader } from '@/components/ui/loading';
import { TextareaAutosize } from '@/components/ui/textarea';
import { EditableAutoSaveProps } from './types';

const EditableAutoSave = ({
  value: initialValue,
  onChange,
}: EditableAutoSaveProps) => {
  const [internalValue, setInternalValue] = React.useState(
    initialValue ? initialValue : undefined,
  );

  const [ready, setReady] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [saved, setSaved] = React.useState(false);

  useEffect(() => {
    setInternalValue(initialValue ? initialValue : undefined);
  }, [initialValue]);

  useDebounce(
    async () => {
      if (!ready) return;

      setLoading(true);
      await onChange(internalValue || '');
      setLoading(false);

      setSaved(true);
      setTimeout(() => {
        setSaved(false);
      }, 1000);
    },
    1000,
    [internalValue],
  );

  const handleOnChange = (value: string) => {
    setReady(true);
    setInternalValue(value);
  };

  return (
    <div className="relative">
      <div className="pointer-events-none absolute right-2 top-2">
        {loading && <Loader className="size-3" />}
        {saved && (
          <p className="text-[10px] leading-[10px] text-green-500/75">Saved</p>
        )}
      </div>
      <TextareaAutosize
        className="w-full"
        readOnly={loading}
        value={internalValue}
        onValueChange={handleOnChange}
      />
    </div>
  );
};

export default EditableAutoSave;
