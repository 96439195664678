import { gql, useQuery } from '@apollo/client';
import * as Y from 'yjs';
import { useMatch } from 'lib/hooks';
import { JobReport } from 'lib/types';
import { useJobContext } from '../../context';
import { JobReportQueryData, JobReportQueryVariables } from './types';

export const useJobReport = () => {
  const { job } = useJobContext();

  const match = useMatch(
    '/clients/:clientSlug/jobs/:jobReference/reports/:jobReportNumber/*',
  );

  const { data, refetch } = useQuery<
    JobReportQueryData,
    JobReportQueryVariables
  >(
    gql`
      query GetJobReport($jobId: ID!, $jobReportNumber: Int!) {
        job(jobId: $jobId) {
          id
          report(jobReportNumber: $jobReportNumber) {
            id
            slug
            name
            number
            status
            statusDisplay
            selected
            actionSetId
            versions {
              id
              number
            }
            latestVersion {
              id
              number
            }
          }
        }
      }
    `,
    {
      skip: job.partial || !match?.params.jobReportNumber,
      variables: job.partial
        ? undefined
        : {
            jobId: job.id,
            jobReportNumber: parseInt(
              match?.params.jobReportNumber as string,
              10,
            ),
          },
    },
  );

  if (!data?.job?.report) {
    return {
      context: {
        doc: undefined as Y.Doc | undefined,
        store: undefined as
          | {
              selected: string[];
              meta: { isCompleted: boolean; isApproved: boolean };
            }
          | undefined,
        report: null as JobReport | null,
        version: undefined,
      },
    };
  }

  return {
    context: {
      doc: undefined as Y.Doc | undefined,
      store: undefined as
        | {
            selected: string[];
            meta: { isCompleted: boolean; isApproved: boolean };
          }
        | undefined,
      report: {
        ...data.job.report,
        refetch,
      } as JobReport | null,
      version: data.job.report.latestVersion
        ? { id: data.job.report.latestVersion.id }
        : undefined,
    },
  };
};
