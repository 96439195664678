import { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@/components/ui/theme';
import { useQuery } from '@apollo/client';
import {
  EventModelConfig,
  ResourceTimeRangeModelConfig,
  TimeSpanConfig,
} from '@bryntum/scheduler-thin';
import {
  AssignmentModelConfig,
  ProjectModel,
  ResourceModelConfig,
} from '@bryntum/schedulerpro-thin';
import chroma from 'chroma-js';
import { DateTime } from 'luxon';
import {
  Job,
  JobTask,
  Subcontractor,
  TimesheetDuty,
  TimesheetEvent,
  User,
  UserGroup,
} from 'lib/types';
import { useJobContext } from '../../context';
import { useJobTaskContext } from '../JobTask/context';
import { INLINE_DATA_QUERY } from './query';
import {
  AssignmentResourceType,
  InlineDataQueryData,
  InlineDataQueryVariables,
} from './types';

export type Update = {
  type: UpdateType;
  duty: PreparedTimesheetDuty;
  diff: UpdateDiff;
  status?: UpdateStatus;
  errors?: any[];
};

export enum UpdateType {
  Create = 'CREATE',
  Update = 'UPDATE',
  UpdateTransfer = 'UPDATE_TRANSFER',
  Delete = 'DELETE',
}

export enum UpdateStatus {
  Applying = 'APPLYING',
  Applied = 'APPLIED',
  Error = 'ERROR',
}

export type UpdateDiff = {
  id: string;
  dateTimeStart?: DateTime;
  dateTimeEnd?: DateTime;
  user?: User | null;
  userPrevious?: User | null;
};

export type PreparedTimesheetEvent = Omit<
  TimesheetEvent,
  'dateTimeStart' | 'dateTimeEnd'
> & {
  dateTimeStart: DateTime;
  dateTimeEnd: DateTime | null;
};

export type PreparedTimesheetDuty = Omit<
  TimesheetDuty,
  'dateTimeCreated' | 'dateTimeStart' | 'dateTimeEnd' | 'events'
> & {
  job: Job;
  jobTask: JobTask;
  isActive: boolean;
  dateTimeCreated: DateTime;
  dateTimeStart: DateTime | null;
  dateTimeEnd: DateTime | null;
  events: PreparedTimesheetEvent[];
};

export const useProject = () => {
  const theme = useTheme();

  const project = useMemo(() => new ProjectModel(), []);
  useProjectInlineData(project, () => setReady(true));

  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(false);
    setTimeout(() => {
      setReady(true);
    }, 1000);
  }, [theme]);

  return {
    ready,
    project,
  };
};

export const useProjectInlineData = (
  project: ProjectModel,
  onCompleted: () => void,
) => {
  const { job } = useJobContext();
  const { task } = useJobTaskContext();

  useQuery<InlineDataQueryData, InlineDataQueryVariables>(INLINE_DATA_QUERY, {
    skip: job.partial || !task,
    fetchPolicy: 'no-cache',
    variables:
      job.partial || !task
        ? undefined
        : {
            clientId: job.client.id,
            jobId: job.id,
            jobTaskId: task.id,
          },
    onCompleted: (data) => {
      if (job.partial) return;

      const { timesheetDuties: duties } = data;
      const timeRanges = getTimeRanges(job);
      const preparedDuties = prepareDuties(duties, job);
      const resources = getResources(
        [`clients::client::${job.client.slug}::operatives`],
        data.client.userGroups,
        data.subcontractors,
      );
      const resourceTimeRanges = getResourceTimeRanges(preparedDuties);
      const events = getEvents(preparedDuties);
      const assignments = getAssignments(preparedDuties);
      project.inlineData = {
        jobId: job.id,
        timeRangesData: timeRanges,
        resourceTimeRangesData: resourceTimeRanges,
        eventsData: events,
        assignmentsData: assignments,
        resourcesData: resources,
        dependenciesData: [],
      };
      onCompleted();
    },
  });
};

export const getResources = (
  scopes: string[],
  userGroups: UserGroup[],
  subcontractors: Subcontractor[],
) => {
  return [
    ...userGroups.flatMap((group) =>
      group.users.map(
        (user) =>
          ({
            id: user.id,
            name: user.name,
            email: user.email,
            initials: user.initials,
            type: AssignmentResourceType.User,
            user,
          }) as Partial<ResourceModelConfig>,
      ),
    ),
    ...subcontractors.map(
      (subcontractor) =>
        ({
          id: subcontractor.id,
          name: subcontractor.name,
          type: AssignmentResourceType.Subcontractor,
          subcontractor,
        }) as Partial<ResourceModelConfig>,
    ),
  ].filter(
    (resource, index, self) =>
      index === self.findIndex((inner) => inner.id === resource.id),
  );
};

export const prepareDuties = (duties: TimesheetDuty[], job?: Job) => {
  return duties.map((duty) => prepareDuty(duty, job));
};

export const prepareDuty = (duty: TimesheetDuty, job?: Job) => {
  return {
    ...duty,
    job: duty.activity.jobTask?.job,
    jobTask: duty.activity.jobTask,
    // Check if the duty is active by comparing the job id
    // of the activity with the job id of the current job.
    // Note, the new timesheet module's generality means that
    // we don't have to render exclusively jobs on the scheduler.
    isActive: duty.activity.jobTask?.job?.id === job?.id,
    dateTimeCreated: DateTime.fromISO(duty.dateTimeCreated),
    dateTimeStart: duty.dateTimeStart
      ? DateTime.fromISO(duty.dateTimeStart)
      : null,
    dateTimeEnd: duty.dateTimeEnd ? DateTime.fromISO(duty.dateTimeEnd) : null,
    events: duty.events.map(
      (event) =>
        ({
          ...event,
          dateTimeStart: DateTime.fromISO(event.dateTimeStart),
          dateTimeEnd: event.dateTimeEnd
            ? DateTime.fromISO(event.dateTimeEnd)
            : null,
        }) as PreparedTimesheetEvent,
    ),
  } as PreparedTimesheetDuty;
};

export const getResourceTimeRanges = (
  preparedDuties: PreparedTimesheetDuty[],
) => {
  return preparedDuties.flatMap((duty) =>
    duty.events.map(
      (event) =>
        ({
          id: event.id,
          startDate: event.dateTimeStart
            ? event.dateTimeStart.startOf('minute').toJSDate()
            : null,
          startDateType: event.dateTimeStartType,
          endDate: event.dateTimeEnd
            ? event.dateTimeEnd.startOf('minute').toJSDate()
            : null,
          endDateType: event.dateTimeEndType,
          timesheetDuty: duty,
          timesheetEvent: event,
          resourceId: duty.user?.id ?? duty.subcontractor?.id,
          name: 'Timesheet Event',
          type: duty.user
            ? AssignmentResourceType.User
            : AssignmentResourceType.Subcontractor,
          timeRangeColor: chroma.random().hex(),
        }) as Partial<ResourceTimeRangeModelConfig>,
    ),
  );
};

const getTimeRanges = (job: Job) => {
  const ret: Partial<TimeSpanConfig>[] = [
    {
      id: 'job-timeline',
      name: 'Issued',
      startDate: job.timeline.dateTimeStart,
    },
    {
      id: 'job-timeline-range',
      name: '',
      startDate: DateTime.fromISO(job.timeline.dateTimeStart)
        .minus({ year: 1 })
        .toJSDate(),
      endDate: job.timeline.dateTimeStart,
    },
  ];
  for (const target of job.timeline.targets) {
    ret.push({
      id: `job-timeline-${target.id}`,
      cls: 'capitalize',
      name: target.type.replace(/_/g, ' ').toLowerCase(),
      startDate: target.dateTime,
    });
  }
  return ret;
};

export const getEvents = (preparedDuties: PreparedTimesheetDuty[]) => {
  return preparedDuties
    .filter((duty) => !!duty.jobTask)
    .map(
      (duty) =>
        ({
          id: duty.id,
          type: duty.user
            ? AssignmentResourceType.User
            : AssignmentResourceType.Subcontractor,
          name: !duty.isActive
            ? `#${duty.jobTask?.number}`
            : `${duty.job?.reference} #${duty.jobTask?.number}`,
          startDate: duty.dateTimeStart ? duty.dateTimeStart.toJSDate() : null,
          endDate: duty.dateTimeEnd ? duty.dateTimeEnd.toJSDate() : null,
          timesheetDuty: duty,
          showInTimeline: duty.isActive,
          readOnly: !duty.isActive,
          isActive: duty.isActive,
        }) as Partial<EventModelConfig>,
    );
};

export const getAssignments = (preparedDuties: PreparedTimesheetDuty[]) => {
  return preparedDuties.flatMap(
    (duty) =>
      ({
        id: duty.id,
        type: duty.user
          ? AssignmentResourceType.User
          : AssignmentResourceType.Subcontractor,
        eventId: duty.id,
        resourceId: duty.user?.id ?? duty.subcontractor?.id,
      }) as Partial<AssignmentModelConfig>,
  );
};
