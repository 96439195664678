import { Client } from './clients';
import { Nullable } from './core';
import { Location } from './locations';

export type Site = {
  id: string;
  client: Client;
  reference: string;
  name: string;
  location?: Location;
  isVerified: boolean;
  contacts: SiteContact[];
};

export type SiteContact = {
  id: string;
  name: string;
  email: string;
  phone: string;
};
