import React from 'react';
import { ChevronRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { CommandGroup, CommandItem } from '@/components/ui/command';
import { cn } from '@/lib/utils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DebouncedNumberInput, TableSelector } from 'lib/shared';
import { CommercialType } from 'lib/types';
import CommercialUnitField from '../../../../../../../CommercialUnitField';
import { useCommercialEstimateContext } from '../../../../../../context';
import { useEstimateTableContext } from '../../../../context';
import LineDetail from './components/LineDetail';
import LineMenu from './components/LineMenu';
import { ValueLineContext } from './context';
import { useValueLine } from './hooks';
import { ValueLineProps } from './types';

const ValueLine = ({ line }: ValueLineProps) => {
  const { activity, expanded } = useCommercialEstimateContext();
  const { type } = useEstimateTableContext();
  const {
    context,
    context: { handlers },
  } = useValueLine(line);

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: line.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ValueLineContext.Provider value={context}>
      <div
        className={cn('relative bg-background ring-1', {
          'z-10': isDragging,
          'ring-border': !isDragging,
        })}
        ref={setNodeRef}
        style={style}
        {...attributes}
      >
        <div className="grid grid-cols-8">
          <div className="col-span-3 flex border-r">
            <div className="w-8 flex-shrink-0 border-r">
              <Button
                className="size-8 flex-shrink-0 rounded-none p-0"
                variant="ghost"
                onClick={() => expanded.toggle(line.id)}
              >
                <ChevronRight
                  className={cn(
                    'size-4',
                    expanded.is(line.id) && 'rotate-90 transform',
                  )}
                />
              </Button>
            </div>
            {(type === CommercialType.Prelim ||
              type === CommercialType.Labor ||
              type === CommercialType.Subcontractor) && (
              <TableSelector
                placeholder="Select rate"
                searchPlaceholder="Search resources"
                className="text-sm"
                popoverClassName={cn({
                  'border-labor': type === CommercialType.Labor,
                  'border-subcontractor/75':
                    type === CommercialType.Subcontractor,
                })}
                value={line.rate?.id as string}
                valueDisplay={line.rate?.name as string}
                onChange={handlers.rateOnChange}
              >
                {({ onSelect }) =>
                  activity.contract.rates.map((rate) => (
                    <CommandItem
                      key={rate.id}
                      value={rate.id}
                      keywords={[rate.name, rate.description]}
                      className="cursor-pointer"
                      onSelect={onSelect}
                    >
                      <div className="flex w-full items-center justify-between">
                        <p className="text-[13px]">{rate.name}</p>
                      </div>
                    </CommandItem>
                  ))
                }
              </TableSelector>
            )}
            {type === CommercialType.Material && (
              <TableSelector
                placeholder="Select rate"
                searchPlaceholder="Search material"
                className="text-sm"
                popoverClassName="border-material/75"
                value={line.description}
                valueDisplay={line.description}
                searchDefault={line.description}
                onChange={handlers.descriptionOnChange}
              >
                {({ onSelect }) => null}
              </TableSelector>
            )}
          </div>
          <div className="col-span-1 flex items-center border-r">
            <CommercialUnitField
              readOnly={!!line.rate}
              value={line.unit}
              valueDisplay={line.unitDisplay}
              onValueChange={handlers.unitOnChange}
            />
          </div>
          <div className="col-span-1 border-r">
            <DebouncedNumberInput
              className="h-full rounded-none border-none px-2 hover:bg-secondary focus:bg-secondary"
              min={0}
              value={line.estimatedQuantity}
              onChange={handlers.estimatedQuantityOnChange}
            />
          </div>
          <div className="col-span-1 border-r">
            <DebouncedNumberInput
              className="h-full rounded-none border-none px-2 hover:bg-secondary focus:bg-secondary"
              min={0}
              value={line.estimatedUnitValue?.toFixed(2)}
              onChange={handlers.estimatedValueOnChange}
            />
          </div>
          <div className="col-span-1 border-r">
            <DebouncedNumberInput
              className="h-full rounded-none border-none px-2 hover:bg-secondary focus:bg-secondary"
              min={0}
              value={((line.estimatedUnitValueUplift ?? 1) - 1) * 100}
              onChange={handlers.estimatedValueUpliftOnChange}
            />
          </div>
          <div className="col-span-1 flex">
            <div className="flex grow items-center border-r px-2 text-sm">
              {line.estimatedTotalValue?.toFixed(2)}
            </div>
            <LineMenu />
          </div>
        </div>
        {expanded.is(line.id) && <LineDetail />}
      </div>
    </ValueLineContext.Provider>
  );
};

export default ValueLine;
