import React, { CSSProperties, useState } from 'react';
import {
  ControllerRenderProps,
  FieldValues,
  Path,
  UseFormReturn,
} from 'react-hook-form';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { ColorPicker } from '@/components/ui/color-picker';
import { Combobox } from '@/components/ui/combobox';
import { DatePicker } from '@/components/ui/date-picker';
import { DateTimePicker } from '@/components/ui/datetime-picker';
import { MediaInput } from '@/components/ui/form/form-media/FormMedia';
import { useFormRendererContext } from '@/components/ui/form/form-renderer/FormRenderer';
import { Image } from '@/components/ui/image';
import { Input } from '@/components/ui/input';
import { Loader } from '@/components/ui/loading';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { TimePicker } from '@/components/ui/time-picker';
import { cn } from '@/lib/utils';
import { ZodBoolean, ZodTypeAny, z } from 'zod';
import { usePlatformObject } from 'lib/hooks';
import { useMedia } from '../../../../../shared/Media/hooks';
import { useRegisterObjectsOnMount } from '../../../../../shared/MediaProvider';
import { FormControl } from '../form-control/FormControl';
import { FormDescription } from '../form-description/FormDescription';
import { FormField } from '../form-field/FormField';
import { FormItem } from '../form-item/FormItem';
import { FormLabel } from '../form-label/FormLabel';
import { FormMessage } from '../form-message/FormMessage';

export interface FieldBaseProps<
  TType extends string,
  TName extends string,
  TSchema extends ZodTypeAny,
> {
  type: TType;
  name: TName;
  schema: TSchema;
  className?: string;
  style?: CSSProperties;
}

export interface InputFieldProps<
  TName extends string,
  TSchema extends ZodTypeAny,
> extends FieldBaseProps<'input', TName, TSchema> {
  label: React.ReactNode;
  placeholder?: string;
  defaultValue?: string | number;
  description?: string;
  disabled?: boolean;
  prefix?: string;
}

export interface BooleanFieldProps<
  TName extends string,
  TSchema extends ZodBoolean,
> extends FieldBaseProps<'input', TName, TSchema> {
  defaultValue?: boolean;
}

export interface SelectFieldOption<TValue extends string | number> {
  label: React.ReactNode;
  value: TValue;
  keywords?: string[];
}

export interface SelectFieldProps<
  TName extends string,
  TSchema extends ZodTypeAny,
> extends FieldBaseProps<'select', TName, TSchema> {
  label: React.ReactNode;
  placeholder?: string;
  defaultValue?: z.infer<TSchema>;
  description?: string;
  combobox?: boolean;
  options: SelectFieldOption<z.infer<TSchema>>[];
}

/**
 * A helper function that casts custom render field data into a type given in a generic.
 */
export const customFieldRenderData = <TFields extends RendererField[]>(
  data: CustomFieldRenderData<FieldValues>,
) =>
  data as unknown as CustomFieldRenderData<RendererFieldsFormValues<TFields>>;

export interface CustomFieldRenderData<TFields extends FieldValues> {
  form: UseFormReturn<TFields, unknown, undefined>;
  formField: ControllerRenderProps<TFields, Path<TFields>>;
}

export interface CustomFieldProps<
  TName extends string,
  TSchema extends ZodTypeAny,
> extends FieldBaseProps<'custom', TName, TSchema> {
  defaultValue: z.infer<TSchema>;
  /**
   * Renders custom field.
   */
  render: (data: CustomFieldRenderData<FieldValues>) => React.ReactNode;
}

/**
 * Input form field configuration factory.
 *
 * @example
 * import { useMemo } from "react";
 * import {
 *  input,
 *  FormRendererSubmitHandler
 * } from "@/ui/components/form/form-field-renderer/FormFieldRenderer"
 *
 * const MyForm = () => {
 *  const formFields = useMemo(() => [
 *    input({
 *      label: "Email",
 *      name: "email",
 *      placeholder: "example@gmail.com",
 *      schema: z.string().email()
 *    })
 *  ], [])
 *
 *  const handleSubmit: FormRendererSubmitHandler<typeof formFields> = (values) => {
 *    console.log(values); // { email: string }
 *  }
 *
 *  return <FormRenderer fields={formFields} onSubmit={handleSubmit} />
 * }
 */
export const input = <TName extends string, TSchema extends ZodTypeAny>(
  props: Omit<InputFieldProps<TName, TSchema>, 'type'>,
) => {
  return {
    ...props,
    type: 'input',
  } as const;
};

export const number = <TName extends string, TSchema extends ZodTypeAny>(
  props: Omit<InputFieldProps<TName, TSchema>, 'type'>,
) => {
  return {
    ...props,
    type: 'number',
  } as const;
};

export const boolean = <TName extends string, TSchema extends ZodBoolean>(
  props: Omit<BooleanFieldProps<TName, TSchema>, 'type'>,
) => {
  return {
    ...props,
    type: 'boolean',
  } as const;
};

export const time = <TName extends string, TSchema extends ZodTypeAny>(
  props: Omit<InputFieldProps<TName, TSchema>, 'type'>,
) => {
  return {
    ...props,
    type: 'time',
  } as const;
};

export const date = <TName extends string, TSchema extends ZodTypeAny>(
  props: Omit<InputFieldProps<TName, TSchema>, 'type'> & {
    timescape?: boolean;
  },
) => {
  return {
    ...props,
    type: 'date',
  } as const;
};

export const datetime = <TName extends string, TSchema extends ZodTypeAny>(
  props: Omit<InputFieldProps<TName, TSchema>, 'type'>,
) => {
  return {
    ...props,
    type: 'datetime',
  } as const;
};

export const duration = <TName extends string, TSchema extends ZodTypeAny>(
  props: Omit<InputFieldProps<TName, TSchema>, 'type'>,
) => {
  return {
    ...props,
    type: 'duration',
  } as const;
};

export const textarea = <TName extends string, TSchema extends ZodTypeAny>(
  props: Omit<InputFieldProps<TName, TSchema>, 'type'>,
) => {
  return {
    ...props,
    type: 'textarea',
  } as const;
};

export const color = <TName extends string, TSchema extends ZodTypeAny>(
  props: Omit<InputFieldProps<TName, TSchema>, 'type'>,
) => {
  return {
    ...props,
    type: 'color',
  } as const;
};

/**
 * Select form field configuration factory.
 *
 * @example
 * import { useMemo } from "react";
 * import {
 *  select,
 *  FormRendererSubmitHandler
 * } from "@/ui/components/form/form-field-renderer/FormFieldRenderer"
 *
 * const MyForm = () => {
 *  const formFields = useMemo(() => [
 *    select({
 *      label: "Priority",
 *      name: "priority",
 *      placeholder: "Select priority",
 *      schema: z.enum(["low", "medium", "high"]),
 *      options: [
 *        { label: "Low", value: "low" }.
 *        { label: "Medium", value: "medium" }.
 *        { label: "High", value: "high" }
 *      ]
 *    })
 *  ], [])
 *
 *  const handleSubmit: FormRendererSubmitHandler<typeof formFields> = (values) => {
 *    console.log(values); // { priority: "low" | "medium" | "high" }
 *  }
 *
 *  return <FormRenderer fields={formFields} onSubmit={handleSubmit} />
 * }
 */
export const select = <TName extends string, TSchema extends z.ZodString>(
  props: Omit<SelectFieldProps<TName, TSchema>, 'type'>,
) => {
  return {
    ...props,
    type: 'select',
  } as const;
};

export const checkboxes = <
  TName extends string,
  TSchema extends z.ZodArray<string>,
>(
  props: Omit<SelectFieldProps<TName, TSchema>, 'type'>,
) => {
  return {
    ...props,
    type: 'checkboxes',
  } as const;
};

export const file = <TName extends string, TSchema extends z.ZodAny>(
  props: Omit<InputFieldProps<TName, TSchema>, 'type'> & { right?: number },
) => {
  return {
    ...props,
    type: 'file',
  } as const;
};

export const image = <TName extends string, TSchema extends z.ZodAny>(
  props: Omit<InputFieldProps<TName, TSchema>, 'type'>,
) => {
  return {
    ...props,
    type: 'image',
  } as const;
};

export const files = <TName extends string, TSchema extends z.ZodAny>(
  props: Omit<InputFieldProps<TName, TSchema>, 'type'> & {
    maxFiles?: number;
    right?: number;
  },
) => {
  return {
    ...props,
    type: 'files',
  } as const;
};

/**
 * Custom form field configuration factory.
 *
 * @example
 * import { useMemo } from "react";
 * import { FormControl } from "@/ui/components/form/form-control/FormControl"
 * import {
 *  custom,
 *  FormRendererSubmitHandler
 * } from "@/ui/components/form/form-field-renderer/FormFieldRenderer"
 * import { FormItem } from "@/ui/components/form/form-item/FormItem"
 * import { FormLabel } from "@/ui/components/form/form-label/FormLabel"
 * import { FormMessage } from "@/ui/components/form/form-message/FormMessage"
 * import { Input } from "@/ui/components/input/Input"
 *
 * const MyForm = () => {
 *  const formFields = useMemo(() => [
 *    custom({
 *      name: "phoneNumber",
 *      defaultValue: "",
 *      schema: z.string().min(9).max(9),
 *      render: (data) => {
 *        const { form, formField } = customFieldRenderData<typeof formFields>(data);
 *
 *        return (
 *          <FormItem>
 *            <FormLabel>My custom phone number field</FormLabel>
 *            <FormControl>
 *              <Input
 *                {...formField}
 *                disabled={formField.disabled || form.formState.isSubmitting}
 *              />
 *            </FormControl>
 *
 *            <FormMessage />
 *          </FormItem>
 *        )
 *      }
 *    })
 *  ], [])
 *
 *  const handleSubmit: FormRendererSubmitHandler<typeof formFields> = (values) => {
 *    console.log(values); // { phoneNumber: string }
 *  }
 *
 *  return <FormRenderer fields={formFields} onSubmit={handleSubmit} />
 * }
 */
export const custom = <TName extends string, TSchema extends ZodTypeAny>(
  props: Omit<CustomFieldProps<TName, TSchema>, 'type'>,
) => {
  return {
    ...props,
    type: 'custom',
  } as const;
};

/**
 * The combination of available form fields.
 */
export type RendererField =
  | ReturnType<typeof input>
  | ReturnType<typeof number>
  | ReturnType<typeof boolean>
  | ReturnType<typeof textarea>
  | ReturnType<typeof color>
  | ReturnType<typeof time>
  | ReturnType<typeof date>
  | ReturnType<typeof datetime>
  | ReturnType<typeof select>
  | ReturnType<typeof duration>
  | ReturnType<typeof file>
  | ReturnType<typeof files>
  | ReturnType<typeof image>
  | ReturnType<typeof custom>;

/**
 * Converts renderer field array into an object that uses field names as keys and field schemas as values.
 */
export type RendererFieldsFormSchema<TFields extends RendererField[]> = {
  [K in TFields[number]['name']]: Extract<
    TFields[number],
    { name: K }
  >['schema'];
};

/**
 * Converts renderer field array into an object that uses field names as keys and inferred field schemas as values.
 */
export type RendererFieldsFormValues<TFields extends RendererField[]> = {
  [K in TFields[number]['name']]: z.infer<
    Extract<TFields[number], { name: K }>['schema']
  >;
};

export interface FormFieldRendererProps<TFields extends RendererField[]> {
  form: UseFormReturn<RendererFieldsFormValues<TFields>, unknown, undefined>;
  field: TFields[number];
}

/**
 * Renders form field based on form field configuration.
 */
export const FormFieldRenderer = <TFields extends RendererField[]>({
  form,
  field,
}: FormFieldRendererProps<TFields>) => {
  return (
    <FormField
      control={form.control}
      name={field.name as Path<RendererFieldsFormValues<TFields>>}
      render={({ field: formField }) => {
        switch (field.type) {
          case 'input':
            return (
              <FormItem style={field.style} className={field.className}>
                <FormLabel>{field.label}</FormLabel>
                <FormControl>
                  {field.prefix ? (
                    <div className="flex">
                      <Badge
                        className="whitespace-nowrap rounded-l-md rounded-r-none font-mono text-gray-600 dark:text-white/75"
                        variant="outline"
                      >
                        <span className="-mb-0.5">{field.prefix}</span>
                      </Badge>
                      <Input
                        className="rounded-l-none border-l-0 font-mono"
                        placeholder={field.placeholder}
                        {...formField}
                        disabled={
                          formField.disabled || form.formState.isSubmitting
                        }
                        value={
                          (formField.value
                            ? formField.value.replace(field.prefix, '')
                            : formField.value) as string | undefined
                        }
                        onChange={(event) => {
                          formField.onChange(field.prefix + event.target.value);
                        }}
                      />
                    </div>
                  ) : (
                    <Input
                      placeholder={field.placeholder}
                      {...formField}
                      disabled={
                        formField.disabled || form.formState.isSubmitting
                      }
                      value={formField.value as string | undefined}
                    />
                  )}
                </FormControl>
                {field.description && (
                  <FormDescription>{field.description}</FormDescription>
                )}
                <FormMessage />
              </FormItem>
            );
          case 'number':
            return (
              <FormItem style={field.style} className={field.className}>
                <FormLabel>{field.label}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={field.placeholder}
                    {...formField}
                    type="number"
                    disabled={formField.disabled || form.formState.isSubmitting}
                    value={formField.value as string | undefined}
                    onChange={(value) => {
                      try {
                        formField.onChange(parseFloat(value.target.value));
                      } catch (error) {
                        console.error(error);
                        formField.onChange(value.target.value);
                      }
                    }}
                  />
                </FormControl>

                {field.description && (
                  <FormDescription>{field.description}</FormDescription>
                )}
                <FormMessage />
              </FormItem>
            );
          case 'boolean':
            return (
              <FormItem style={field.style} className={field.className}>
                <div className="flex items-center gap-x-2 space-y-0 py-2">
                  <FormControl className="mt-0">
                    <Checkbox
                      className="border border-input"
                      placeholder={field.placeholder}
                      {...formField}
                      disabled={
                        formField.disabled || form.formState.isSubmitting
                      }
                      value={formField.value as string | undefined}
                      checked={formField.value as boolean | undefined}
                      onCheckedChange={(value) => {
                        formField.onChange(value);
                      }}
                    />
                  </FormControl>
                  <FormLabel>{field.label}</FormLabel>
                </div>
                {field.description && (
                  <FormDescription>{field.description}</FormDescription>
                )}
                <FormMessage />
              </FormItem>
            );
          case 'textarea':
            return (
              <FormItem style={field.style} className={field.className}>
                <FormLabel>{field.label}</FormLabel>
                <FormControl>
                  <Textarea
                    className="min-h-[120px] rounded-lg"
                    placeholder={field.placeholder ?? 'Aa'}
                    {...formField}
                    disabled={formField.disabled || form.formState.isSubmitting}
                    value={formField.value as string | undefined}
                    defaultValue={field.defaultValue}
                  />
                </FormControl>

                {field.description && (
                  <FormDescription>{field.description}</FormDescription>
                )}
                <FormMessage />
              </FormItem>
            );
          case 'color':
            return (
              <FormItem style={field.style} className={field.className}>
                <FormLabel>{field.label}</FormLabel>
                <FormControl>
                  <ColorPicker
                    value={formField.value ?? field.defaultValue}
                    onValueChange={(value) => {
                      formField.onChange(value);
                    }}
                  />
                </FormControl>
                {field.description && (
                  <FormDescription>{field.description}</FormDescription>
                )}
                <FormMessage />
              </FormItem>
            );
          case 'time':
            return (
              <FormItem style={field.style} className={field.className}>
                <FormLabel>{field.label}</FormLabel>
                <FormControl>
                  <TimePicker
                    placeholder={field.placeholder}
                    {...formField}
                    date={
                      formField.value
                        ? new Date(formField.value)
                        : field.defaultValue
                          ? new Date(field.defaultValue)
                          : undefined
                    }
                    setDate={(date) => {
                      formField.onChange(date?.toISOString());
                    }}
                  />
                </FormControl>
                {field.description && (
                  <FormDescription>{field.description}</FormDescription>
                )}
                <FormMessage />
              </FormItem>
            );
          case 'date':
            return (
              <FormItem style={field.style} className={field.className}>
                <FormLabel>{field.label}</FormLabel>
                <FormControl>
                  <div>
                    <DatePicker
                      optional={field.schema.isOptional()}
                      timescape={field.timescape}
                      value={formField.value}
                      onValueChange={formField.onChange}
                    />
                  </div>
                </FormControl>
                {field.description && (
                  <FormDescription>{field.description}</FormDescription>
                )}
                <FormMessage />
              </FormItem>
            );
          case 'datetime':
            return (
              <FormItem style={field.style} className={field.className}>
                <FormLabel>{field.label}</FormLabel>
                <FormControl>
                  <DateTimePicker
                    value={formField.value}
                    onValueChange={formField.onChange}
                  />
                </FormControl>
                {field.description && (
                  <FormDescription>{field.description}</FormDescription>
                )}
                <FormMessage />
              </FormItem>
            );
          case 'duration':
            return (
              <FormItem style={field.style} className={field.className}>
                <FormLabel>{field.label}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={field.placeholder}
                    {...formField}
                    type="number"
                    disabled={formField.disabled || form.formState.isSubmitting}
                    value={formField.value as string | undefined}
                    defaultValue={field.defaultValue}
                  />
                </FormControl>

                {field.description && (
                  <FormDescription>{field.description}</FormDescription>
                )}
                <FormMessage />
              </FormItem>
            );
          case 'select':
            if (field.combobox) {
              return (
                <FormItem style={field.style} className={field.className}>
                  <FormLabel>{field.label}</FormLabel>
                  <FormControl>
                    <div>
                      <Combobox
                        className="w-full"
                        options={field.options}
                        disabled={
                          formField.disabled || form.formState.isSubmitting
                        }
                        value={formField.value as string | undefined}
                        onValueChange={formField.onChange}
                      />
                    </div>
                  </FormControl>
                  {field.description && (
                    <FormDescription>{field.description}</FormDescription>
                  )}
                  <FormMessage />
                </FormItem>
              );
            }
            return (
              <FormItem style={field.style} className={field.className}>
                <FormLabel>{field.label}</FormLabel>
                <Select
                  onValueChange={formField.onChange}
                  value={formField.value as string | undefined}
                  disabled={formField.disabled || form.formState.isSubmitting}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue
                        placeholder={field.placeholder || 'Select value'}
                      />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {field.options.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {field.description && (
                  <FormDescription>{field.description}</FormDescription>
                )}
                <FormMessage />
              </FormItem>
            );
          case 'checkboxes':
            return (
              <FormItem style={field.style} className={field.className}>
                <FormLabel>{field.label}</FormLabel>
                <FormControl>
                  <div className="flex flex-col gap-y-2">
                    {field.options.map((option) => (
                      <div key={option.value}>{option.label}</div>
                    ))}
                  </div>
                </FormControl>

                {field.description && (
                  <FormDescription>{field.description}</FormDescription>
                )}
                <FormMessage />
              </FormItem>
            );
          case 'file':
            return (
              <FormItem style={field.style} className={field.className}>
                <FormLabel>{field.label}</FormLabel>
                <FormControl>
                  <MediaInput
                    asControl
                    value={formField.value ? [formField.value] : []}
                    maxFiles={1}
                    right={field.right}
                    onChange={(value) => {
                      formField.onChange(value[0]);
                    }}
                  />
                </FormControl>
                {field.description && (
                  <FormDescription>{field.description}</FormDescription>
                )}
                <FormMessage />
              </FormItem>
            );
          case 'files':
            return (
              <FormItem style={field.style} className={field.className}>
                <FormLabel>{field.label}</FormLabel>
                <FormControl>
                  <MediaInput
                    asControl
                    value={formField.value}
                    right={field.right}
                    onChange={(value) => {
                      formField.onChange(value);
                    }}
                  />
                </FormControl>
                {field.description && (
                  <FormDescription>{field.description}</FormDescription>
                )}
                <FormMessage />
              </FormItem>
            );
          case 'image':
            return (
              <ImageFieldRenderer
                form={form}
                field={field}
                formField={formField}
              />
            );
          case 'custom':
            return (
              <>
                {field.render({
                  form: form as unknown as UseFormReturn<
                    FieldValues,
                    unknown,
                    undefined
                  >,
                  formField: formField as unknown as ControllerRenderProps,
                })}
              </>
            );
        }
      }}
    />
  );
};

const ImageFieldRenderer = ({ form, field, formField }: any) => {
  const [progress, setProgress] = useState<Record<string, number>>({});
  const { enable, disable } = useFormRendererContext();

  useRegisterObjectsOnMount(formField.value ? [formField.value] : []);

  const { dropzone } = useMedia({
    onCreated: (objects) => {
      disable();

      const object = objects[0];
      formField.onChange({
        id: object.id,
        type: object.type,
        name: object.name,
        size: object.size,
        data: object.data,
      });

      setProgress((prevProgress) =>
        objects.reduce((acc, object) => {
          acc[object.id] = 0;
          return acc;
        }, prevProgress),
      );
    },
    onUploadProgress: (id, progress) => {
      setProgress((prevProgress) => ({
        ...prevProgress,
        [id]: progress,
      }));
    },
    onUploaded: (id) => {
      setProgress((prevProgress) => ({
        ...prevProgress,
        [id]: 100,
      }));
    },
    onCompleted: () => {
      enable();
    },
  });

  const { object, isLoading } = usePlatformObject(formField.value?.id);

  return (
    <FormItem style={field.style} className={field.className}>
      <FormLabel>{field.label}</FormLabel>
      <FormControl>
        <div
          className={cn('flex items-center gap-x-4 rounded-md border p-2', {
            'border-dashed': !formField.value,
          })}
        >
          {formField.value && (
            <div>
              {isLoading ? (
                <div className="ml-4">
                  <Loader />
                </div>
              ) : (
                <Image
                  className="ml-2 h-[40px]"
                  src={object?.getUrl}
                  alt="Client logo"
                />
              )}
            </div>
          )}
          <div className="flex w-full justify-end gap-x-2">
            {formField.value ? (
              <>
                <div className="flex flex-col gap-y-2">
                  <Button
                    size="xs"
                    variant="secondary"
                    onClick={(event) => {
                      event.preventDefault();
                      dropzone.open();
                    }}
                  >
                    Change
                  </Button>
                  <Button
                    size="xs"
                    variant="outline"
                    className="border-destructive text-destructive"
                    confirm="Are you sure?"
                    onClick={(event) => {
                      event.preventDefault();
                      form.resetField(formField.name);
                    }}
                  >
                    Remove
                  </Button>
                </div>
              </>
            ) : (
              <Button
                size="xs"
                variant="ghost"
                className="h-14 flex-grow"
                {...dropzone.getRootProps()}
                onClick={(event) => {
                  event.preventDefault();
                  dropzone.open();
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      </FormControl>
      {field.description && (
        <FormDescription>{field.description}</FormDescription>
      )}
      <FormMessage />
    </FormItem>
  );
};
