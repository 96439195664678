import React from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Report, ReportMode } from 'lib/shared';
import { JobTask } from 'lib/types';
import { footer } from '../..';
import { useJobContext } from '../../../../../../context';
import { useJobReportContext } from '../../../../context';
import template from '../../../../template.json';
import { useJobReportVersionContext } from '../../context';
import blocks from '../VersionRenderer/blocks';

const VersionLive = () => {
  const { job } = useJobContext();
  const { report } = useJobReportContext();
  const { cache, state, handlers } = useJobReportVersionContext();

  if (job.partial || !report) {
    return <div>Loading...</div>;
  }

  return (
    <ScrollArea className="h-full min-h-0">
      <div
        className="mx-auto py-4"
        style={{ width: 'calc(var(--scale-factor) * 595.280029px)' }}
      >
        <div className="rounded-lg bg-background">
          <Report.Root
            mode={ReportMode.Edit}
            cache={cache}
            template={template}
            blocks={blocks}
            footer={footer}
            context={{
              selected: report.selected,
              job: {
                ...job,
                status: job.status.map(({ label }) => label).join(', '),
              },
              jobReport: report,
              title: report.name,
              tasks: (job.tasks ?? []) as JobTask[],
            }}
            images={state.images}
            documents={state.documents}
            registerImage={handlers.registerImage}
            registerDocument={handlers.registerDocument}
          />
        </div>
      </div>
    </ScrollArea>
  );
};

export default VersionLive;
