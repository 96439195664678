import * as Y from 'yjs';
import { useYjs } from 'lib/hooks';
import { NotesStore, UseNotesResult } from './types';

export const useNotes = (
  namespace: string,
  id: string | undefined,
): UseNotesResult => {
  const { isReady, doc, store } = useYjs(namespace, id, { notes: {} }, {});

  if (isReady) {
    return {
      isReady: true,
      store: store as NotesStore,
      doc: doc.current as Y.Doc,
    };
  }

  return {
    isReady: false,
    store: undefined,
    doc: undefined,
  };
};
