import React from 'react';
import { ReportBlockComponentProps, ReportElement } from '../../../../types';
import ReportView from '../../../ReportView';

const BlockContainer = ({
  data,
  context,
  getBlockComponent,
}: ReportBlockComponentProps<{ elements: ReportElement[] }>) => {
  const getHorizontalPercentage = (value: number | 'auto' = 'auto') => {
    if (value === 'auto') return value;
    return `${(value / 12) * 100}%`;
  };

  const getVerticalPercentage = (value: number | 'auto' = 'auto') => {
    if (value === 'auto') return value;
    return `${(value / 100) * 100}%`;
  };

  const getStyle = (element: any) => {
    const { position = 'relative', x, y, w, h } = element;
    const style: React.CSSProperties = {
      position,
      width: getHorizontalPercentage(w),
      height: getVerticalPercentage(h),
    };

    if (position === 'relative') {
      return style;
    }

    if (position === 'absolute') {
      return {
        ...style,
        left: getHorizontalPercentage(x),
        top: getVerticalPercentage(y),
      };
    }

    return style;
  };

  return (
    <ReportView break={data.break} style={data?.style}>
      {data.elements.map((element, index) => (
        <ReportView key={index} style={getStyle(element)}>
          {getBlockComponent(element.type)({
            ...element,
            context,
            getBlockComponent,
          })}
        </ReportView>
      ))}
    </ReportView>
  );
};

export default BlockContainer;
