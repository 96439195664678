import { Edit } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { DateTime } from 'luxon';
import { z } from 'zod';
import { useContractUpdate } from './hooks';

const DetailsUpdate = () => {
  const { contract, sheet, handlers } = useContractUpdate();
  if (!contract) return null;
  return (
    <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
      <SheetTrigger asChild>
        <Button variant="ghost" size="icon-sm">
          <Edit className="size-4" />
        </Button>
      </SheetTrigger>
      <SheetContent>
        <FormRenderer
          fields={[
            {
              type: 'input',
              name: 'name',
              label: 'Name',
              schema: z.string().min(2),
              defaultValue: contract.name,
            },
            {
              name: 'description',
              label: 'Description',
              type: 'textarea',
              schema: z.string(),
              defaultValue: contract.description,
            },
            {
              type: 'date',
              name: 'dateStart',
              label: 'Start',
              schema: z.any(),
              defaultValue: DateTime.fromISO(contract.dateStart) as any,
            },
            {
              type: 'date',
              name: 'dateEnd',
              label: 'End',
              schema: z.any(),
              defaultValue: DateTime.fromISO(contract.dateEnd) as any,
            },
          ]}
          onSubmit={handlers.onSubmit}
        >
          {({ form, onSubmit }) => (
            <Button
              size="sm"
              variant="secondary"
              className="w-full"
              disabled={!form.formState.isValid}
              onClick={onSubmit}
            >
              Update
            </Button>
          )}
        </FormRenderer>
      </SheetContent>
    </Sheet>
  );
};

export default DetailsUpdate;
