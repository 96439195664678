import React, { PropsWithChildren } from 'react';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { usePlatformObject } from 'lib/hooks';
import { useActionContext } from '../../context';
import { ActionStatus } from '../../types';
import ActionButton from '../ActionButton';
import ActionTooltip from '../ActionTooltip';

const ActionPreview = ({ children }: PropsWithChildren) => {
  const { action, state, dialog, preview } = useActionContext();
  const { object } = usePlatformObject(preview?.id);
  if (!action || !preview) return null;
  return (
    <Dialog open={dialog.open}>
      <ActionTooltip>
        <span>
          <DialogTrigger asChild>
            <ActionButton />
          </DialogTrigger>
        </span>
      </ActionTooltip>
      <DialogContent
        overlay
        hideClose
        className="flex h-full w-full max-w-none flex-col px-4 pb-4 pt-3.5"
        onCloseAutoFocus={(event) => event.preventDefault()}
      >
        <Button
          className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
          disabled={state.status === ActionStatus.PerformPending}
          onClick={dialog.onClose}
        >
          <Cross2Icon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
        <DialogHeader>
          <DialogTitle className="text-md">{action.label}</DialogTitle>
          <DialogDescription className="text-xs">
            {action.description}
          </DialogDescription>
        </DialogHeader>
        <div className="flex min-h-0 w-full grow gap-x-4">
          <div className="grow">
            {object && <iframe className="h-full w-full" src={object.getUrl} />}
          </div>
          <div className="flex basis-1/4 flex-col gap-y-4">{children}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ActionPreview;
