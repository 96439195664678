import React from 'react';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { DateTime, Interval } from 'luxon';
import { getTimelineTargetColor } from '../../../../sections/ClientJob/components/JobTimeline';
import { useTimelineRendererContext } from '../../context';
import { useTimeline } from './hooks';

const Timeline = () => {
  const {
    now,
    scale,
    interval,
    refs,
    jobs,
    getHorizontalPosition,
    getHorizontalWidth,
  } = useTimelineRendererContext();
  const { width } = useTimeline();
  return (
    <ScrollArea
      viewportRef={refs.cell}
      className="h-full min-h-0 min-w-0 flex-grow"
    >
      <ScrollBar orientation="horizontal" />
      <div className="relative overflow-hidden bg-background" style={{ width }}>
        <div className="absolute inset-0">
          <div
            ref={refs.now}
            className="absolute inset-y-0 z-[1] w-[2px] bg-destructive shadow-sm shadow-destructive"
            style={{ left: getHorizontalPosition(now) }}
          />
          {interval
            .splitBy({ hours: Math.pow(scale, 2) })
            .map((interval: Interval<true>) => (
              <div
                key={interval.start.toISO()}
                className="absolute inset-y-0 z-[0] pt-2"
                style={{ left: getHorizontalPosition(interval.start) }}
              >
                <p className="ml-1 text-sm font-semibold dark:text-gray-400">
                  {interval.start.toFormat('HH')}
                </p>
                <div className="absolute inset-y-0 w-[1px] bg-border" />
              </div>
            ))}
        </div>
        <div className="pt-8">
          <div className="relative">
            {jobs.map((job) => {
              const { dateTimeStart: start, targets } = job.timeline;
              const dateTimeStart = DateTime.fromISO(start);
              const dateTimeFirstTarget =
                targets.length > 0
                  ? DateTime.fromISO(targets[0].dateTime)
                  : null;

              const startWidth = getHorizontalWidth(
                dateTimeStart,
                dateTimeFirstTarget ?? dateTimeStart,
              );

              return (
                <div key={job.id} className="relative h-12 w-full">
                  <div
                    className="absolute top-2 flex h-8 items-center overflow-hidden rounded-l-md bg-incoming-foreground/25 px-2"
                    style={{
                      left: getHorizontalPosition(DateTime.fromISO(start)),
                      width: startWidth,
                    }}
                  >
                    <div>
                      <p className="whitespace-nowrap text-xs font-medium capitalize">
                        {dateTimeStart.hasSame(now, 'day')
                          ? dateTimeStart.toLocaleString(DateTime.TIME_SIMPLE)
                          : dateTimeStart.toLocaleString(
                              DateTime.DATETIME_MED_WITH_WEEKDAY,
                            )}
                      </p>
                      <p className="whitespace-nowrap text-[10px] font-medium capitalize leading-[12px] dark:text-gray-200">
                        Issued
                      </p>
                    </div>
                  </div>
                  {targets.map((target, index, targets) => {
                    const isLast = index === targets.length - 1;

                    const inner = DateTime.fromISO(target.dateTime);
                    const innerLeft = getHorizontalPosition(inner);

                    const nextTarget = targets[index + 1];
                    const innerWidth = isLast
                      ? '5%'
                      : getHorizontalWidth(
                          inner,
                          nextTarget
                            ? DateTime.fromISO(nextTarget.dateTime)
                            : inner,
                        );

                    const color = getTimelineTargetColor(target.type);
                    const typeDisplay = target.type
                      .replace(/_/g, ' ')
                      .toLowerCase();

                    return (
                      <div
                        key={target.id}
                        className={cn(
                          'absolute top-2 flex h-8 items-center pl-2',
                          { 'rounded-r-md': isLast },
                        )}
                        style={{
                          left: innerLeft,
                          width: innerWidth,
                          backgroundColor: color.alpha(0.5).css(),
                          backgroundImage: isLast
                            ? 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5) 100%)'
                            : undefined,
                        }}
                      >
                        <div>
                          <p className="whitespace-nowrap text-xs font-medium capitalize">
                            {inner.hasSame(now, 'day')
                              ? inner.toLocaleString(DateTime.TIME_SIMPLE)
                              : inner.toLocaleString(
                                  DateTime.DATETIME_MED_WITH_WEEKDAY,
                                )}
                          </p>
                          <p className="whitespace-nowrap text-[10px] font-medium capitalize leading-[12px] dark:text-gray-200">
                            {typeDisplay}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ScrollArea>
  );
};

export default Timeline;
