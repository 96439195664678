import React, { memo } from 'react';
import { Handle, Position } from '@xyflow/react';
import { motion } from 'framer-motion';
import { Conditional } from 'lib/shared';
import JobLink from '../../../../../../components/JobLink';
import { useOverviewFlow } from '../../index';
import { NodeBaseProps } from './types';

const NodeBase = memo(
  ({
    type,
    id,
    to,
    children,
    target = true,
    source = true,
    borderColor = 'transparent',
    onClick,
  }: NodeBaseProps) => {
    const { selectableTypes, selected, onSelect } = useOverviewFlow();

    const isSelectable = selectableTypes?.includes(type) ?? false;
    const isSelected = selected?.includes(id) ?? false;

    const actualBorderColor =
      isSelectable && selected
        ? selected.includes(id)
          ? 'green'
          : 'transparent'
        : borderColor;

    const handleOnClick = () => {
      if (isSelectable && selected && onSelect) {
        onSelect(id);
      }
    };

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.25 }}
      >
        <div>
          <button
            className="nodrag relative min-w-[250px] cursor-pointer rounded-lg border-2 text-start transition hover:ring-2"
            style={{ borderColor: actualBorderColor }}
            onClick={isSelectable ? handleOnClick : onClick}
          >
            {target && (
              <Handle
                type="target"
                position={Position.Left}
                style={{ opacity: 0 }}
              />
            )}
            {source && (
              <Handle
                type="source"
                position={Position.Right}
                style={{ opacity: 0 }}
              />
            )}
            <Conditional
              wrap={
                typeof to !== 'undefined' && typeof selected === 'undefined'
              }
              wrapper={<JobLink to={to as string} />}
            >
              {children}
            </Conditional>
            {isSelectable && (
              <>
                <motion.div
                  animate={{ opacity: isSelected ? 1 : 0 }}
                  transition={{ duration: 500 }}
                >
                  <div className="absolute left-0 top-full flex w-full justify-between px-1 pt-1">
                    <p className="text-sm font-bold text-primary-foreground">
                      Selected
                    </p>
                    <p className="text-sm font-bold">Click to deselect</p>
                  </div>
                </motion.div>
                <motion.div
                  animate={{ opacity: isSelected ? 0 : 1 }}
                  transition={{ duration: 500 }}
                >
                  <div className="absolute left-0 top-full flex w-full justify-between px-1 pt-1">
                    <p className="text-sm font-bold">Click to select</p>
                  </div>
                </motion.div>
              </>
            )}
          </button>
        </div>
      </motion.div>
    );
  },
);

export default NodeBase;
