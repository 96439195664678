import React, { Fragment } from 'react';
import { useTopbarBreadcrumb } from './hooks';

const TopbarBreadcrumb = () => {
  const { segments } = useTopbarBreadcrumb();
  return (
    <div className="flex h-[40px] flex-shrink-0 items-center border-b bg-gradient-to-r from-background-secondary to-background pl-[20px] pr-4">
      <p className="text-sm text-gray-400">Home</p>
      <p className="px-3 text-xs text-gray-400">/</p>
      {segments.length === 0 ? (
        <p className="text-sm text-gray-400">Welcome</p>
      ) : (
        segments.map((segment, index) => (
          <Fragment key={`${index}-${segment}`}>
            <p className="text-sm capitalize text-gray-400">{segment}</p>
            {index !== segments.length - 1 && (
              <p className="px-3 text-xs text-gray-400">/</p>
            )}
          </Fragment>
        ))
      )}
    </div>
  );
};

export default TopbarBreadcrumb;
