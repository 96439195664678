import { ChoiceSet, DataType } from './FormTemplateEditor';

export enum FormItemType {
  BLOCK = 'BLOCK',
  CONTAINER = 'CONTAINER',
  CONTAINER_REPEATING_BRANCH = 'CONTAINER_REPEATING_BRANCH',
  CONTAINER_REPEATING_BRANCH_STUB = 'CONTAINER_REPEATING_BRANCH_STUB',
  LOGIC = 'LOGIC',
}

export type PageData = {
  name: string;
};

export type BlockData = {
  type: FormItemType.BLOCK;
  dataType: DataType;
  value: any;
  choiceSetId?: ChoiceSet['id'];
  isMultipleSelection?: boolean;
  isRequired: boolean;
  isPrivate?: boolean;
  helpText?: { value: string };
};

export type ContainerData = {
  type: FormItemType.CONTAINER;
  dataType?: never;
  isRepeated: boolean;
  isPrivate?: boolean;
  value: any;
};

export type ContainerRepeatingBranchData = Omit<ContainerData, 'type'> & {
  type: FormItemType.CONTAINER_REPEATING_BRANCH;
  branchId: string;
  branchIndex: number;
};

export type LogicData = {
  type: FormItemType.LOGIC;
  conditionId: string;
  expression: string;
  constants?: Record<string, any>;
};

export type FormItem =
  | (Item<BlockData, FormItemType> & { type: FormItemType.BLOCK })
  | (Item<ContainerData, FormItemType> & { type: FormItemType.CONTAINER })
  | (Item<ContainerRepeatingBranchData, FormItemType> & {
      type: FormItemType.CONTAINER_REPEATING_BRANCH;
    })
  | (Item<LogicData, FormItemType> & { type: FormItemType.LOGIC });
export type FormItems = Record<FormItem['id'], FormItem>;

export type FormPage = Page<FormItem>;
export type FormPages = Record<FormPage['id'], FormPage>;

export type Page<ItemType> = {
  id: string;
  items: ItemType[];
  children: Item['id'][];
  data?: any;
};

export type Item<Data = any, Type = any> = {
  id: string;
  path: string;
  depth: number;
  type: Type;
  parentId: string;
  data: Data;
  children: Item['id'][];
};

export type Items<Data = any> = Record<Item['id'], Item<Data>>;
