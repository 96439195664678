import React from 'react';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { UnderConstruction } from 'lib/shared';

const TaskFormEditor = () => {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button
          className="h-6"
          variant="outline"
          style={{ lineHeight: '24px' }}
        >
          <p className="text-xs" style={{ lineHeight: '16px' }}>
            Add form
          </p>
        </Button>
      </SheetTrigger>
      <SheetContent className="flex h-full w-[500px] max-w-[500px] flex-col p-0 sm:max-w-[500px]">
        <UnderConstruction className="pb-4" />
      </SheetContent>
    </Sheet>
  );
};

export default TaskFormEditor;
