import { Edge, Node, Position } from '@xyflow/react';
import { Direction, Elements } from './types';

export const getPosition = (x: number, y: number, direction: Direction) => {
  switch (direction) {
    case 'LR':
      return { x: y, y: x };
    case 'RL':
      return { x: -y, y: -x };
    case 'BT':
      return { x: -x, y: -y };
    default:
      return { x, y };
  }
};

export const compareElements = (xs: Elements, ys: Elements) => {
  return (
    compareNodes(xs.nodeMap, ys.nodeMap) && compareEdges(xs.edgeMap, ys.edgeMap)
  );
};

const compareNodes = (xs: Map<string, Node>, ys: Map<string, Node>) => {
  // the number of nodes changed, so we already know that the nodes are not equal
  if (xs.size !== ys.size) return false;

  for (const [id, x] of xs.entries()) {
    const y = ys.get(id);

    // the node doesn't exist in the next state so it just got added
    if (!y) return false;
    // We don't want to force a layout change while a user might be resizing a
    // node, so we only compare the dimensions if the node is not currently
    // being resized.
    //
    // We early return here instead of using a `continue` because there's no
    // scenario where we'd want nodes to start moving around *while* a user is
    // trying to resize a node or move it around.
    if (x.resizing || x.dragging) return true;
    if (
      x.measured?.width !== y.measured?.width ||
      x.measured?.height !== y.measured?.height
    )
      return false;
  }

  return true;
};

const compareEdges = (xs: Map<string, Edge>, ys: Map<string, Edge>) => {
  // the number of edges changed, so we already know that the edges are not equal
  if (xs.size !== ys.size) return false;

  for (const [id, x] of xs.entries()) {
    const y = ys.get(id);

    // the edge doesn't exist in the next state so it just got added
    if (!y) return false;
    if (x.source !== y.source || x.target !== y.target) return false;
    if (x?.sourceHandle !== y?.sourceHandle) return false;
    if (x?.targetHandle !== y?.targetHandle) return false;
  }

  return true;
};

export const getSourceHandlePosition = (direction: Direction) => {
  switch (direction) {
    case 'TB':
      return Position.Bottom;
    case 'BT':
      return Position.Top;
    case 'LR':
      return Position.Right;
    case 'RL':
      return Position.Left;
  }
};

export const getTargetHandlePosition = (direction: Direction) => {
  switch (direction) {
    case 'TB':
      return Position.Top;
    case 'BT':
      return Position.Bottom;
    case 'LR':
      return Position.Left;
    case 'RL':
      return Position.Right;
  }
};
