import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HumanResourcesLeave from './pages/HumanResourcesLeave';
import HumanResourcesRoster from './pages/HumanResourcesRoster';
import HumanResourcesRosterReview from './pages/HumanResourcesRosterReview';
import HumanResourcesRosterTeam from './pages/HumanResourcesRosterTeam';
import HumanResourcesTeams from './pages/HumanResourcesRosterTeams';
import HumanResourcesRosters from './pages/HumanResourcesRosters';
import HumanResourcesRostersCalendar from './pages/HumanResourcesRostersCalendar';
import HumanResourcesTimesheet from './pages/HumanResourcesTimesheet';
import HumanResourcesTimesheets from './pages/HumanResourcesTimesheets';
import HumanResourcesTraining from './pages/HumanResourcesTraining';
import TrainingEvent from './pages/HumanResourcesTraining/components/TrainingEvent';
import TrainingEvents from './pages/HumanResourcesTraining/components/TrainingEvents';
import TrainingOverview from './pages/HumanResourcesTraining/components/TrainingOverview';
import TrainingQualification from './pages/HumanResourcesTraining/components/TrainingQualification';
import QualificationCreator from './pages/HumanResourcesTraining/components/TrainingQualification/components/QualificationCreator';
import TrainingQualifications from './pages/HumanResourcesTraining/components/TrainingQualifications';
import QualificationOverview from './pages/HumanResourcesTraining/components/TrainingQualifications/components/QualificationOverview';
import TrainingStaff from './pages/HumanResourcesTraining/components/TrainingStaff';
import StaffOverview from './pages/HumanResourcesTraining/components/TrainingStaff/components/StaffOverview';
import TrainingStaffMember from './pages/HumanResourcesTraining/components/TrainingStaffMember';

const HumanResources = () => {
  return (
    <Routes>
      <Route path="rosters" element={<HumanResourcesRosters />}>
        <Route index element={<HumanResourcesRostersCalendar />} />
        <Route path=":team/:range" element={<HumanResourcesRoster />} />
        <Route
          path="reviews/:team/:range"
          element={<HumanResourcesRosterReview />}
        />
        <Route path="teams" element={<HumanResourcesTeams />}>
          <Route path=":id" element={<HumanResourcesRosterTeam />} />
        </Route>
      </Route>
      <Route path="timesheets">
        <Route index element={<HumanResourcesTimesheets />} />
        <Route path=":id" element={<HumanResourcesTimesheet />} />
      </Route>
      <Route path="leave" element={<HumanResourcesLeave />} />
      <Route path="training" element={<HumanResourcesTraining />}>
        <Route index element={<TrainingOverview />} />
        <Route path="staff" element={<TrainingStaff />}>
          <Route index element={<StaffOverview />} />
          <Route path=":username" element={<TrainingStaffMember />} />
        </Route>
        <Route path="qualifications/*" element={<TrainingQualifications />}>
          <Route index element={<QualificationOverview />} />
          <Route path="create" element={<QualificationCreator />} />
          <Route path=":id" element={<TrainingQualification />} />
        </Route>
        <Route path="events" element={<TrainingEvents />} />
        <Route path="events/:id" element={<TrainingEvent />} />
      </Route>
    </Routes>
  );
};

export default HumanResources;
