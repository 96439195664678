import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useTrainingQualificationContext } from '../../../../context';

export const useChildrenCreator = () => {
  const { qualification } = useTrainingQualificationContext();

  const [open, setOpen] = useState(false);

  const [create] = useMutation(
    gql`
      mutation CreateTrainingQualificationChild(
        $data: TrainingQualificationInput!
      ) {
        createTrainingQualification(trainingQualificationData: $data) {
          trainingQualification {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (values: any) => {
    if (!qualification) return;

    const { data, errors } = await create({
      variables: {
        data: {
          color: qualification.color,
          logo: qualification.logo
            ? {
                id: qualification.logo?.id,
                type: qualification.logo?.type,
                name: qualification.logo?.name,
                size: qualification.logo?.size,
              }
            : null,
          name: values.name,
          description: values.description,
          data: values.data || {},
          fields: values.fields || [],
          parentId: qualification?.id,
          parentIndex: 0,
        },
      },
    });

    if (data) {
      await qualification.refetch();
      setOpen(false);
      toast.success('Qualification created successfully');
    }

    if (errors) {
      toast.error('Failed to create qualification');
    }
  };

  return {
    state: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onCreate: handleOnCreate,
    },
  };
};
