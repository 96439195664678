import { useCallback, useState } from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { getYjsDoc } from '@syncedstore/core';
import { Item, ItemType, TreeChangeEvent, flatten } from 'external/Tree';
import { BlockData, LogicData } from 'external/types';
import { createSectionState, generateId } from 'lib/utils';
import { useFormTemplateContext } from './context';
import { Nullable, State } from './types';

export const useStore = createSectionState({
  collapsed: [] as string[],
  choiceSetId: null as Nullable<string>,
  choiceSetEditorIsOpen: false,
});

export const useFormTemplateEditorLocalState = () => {
  // Local state: logic determines the current logical branch
  // for item that has logic. Focus is set to the id of the
  // currently focused item and collapsed keeps track of the
  // sections and fields with logic that are collapsed.
  const [logic, setLogic] = useState<Record<string, string>>({});
  const [focus, setFocus] = useState<string | null>(null);
  const [collapsed, setCollapsed] = useState<string[]>([]);

  const handleOnFocus = useCallback((item: any) => {
    return () => setFocus(item.id);
  }, []);

  const handleOnBlur = useCallback(() => {
    setFocus(null);
  }, []);

  const handleOnSelectLogic = useCallback((item: Item) => {
    return () => {
      setLogic((prevLogic) => ({
        ...prevLogic,
        [item.parentId]: item.id,
      }));
    };
  }, []);

  const handleOnCollapse = useCallback((item: Item, override?: boolean) => {
    return () => {
      setCollapsed((prevCollapsed) =>
        (
          typeof override === 'undefined'
            ? prevCollapsed.includes(item.id)
            : !override
        )
          ? prevCollapsed.filter((itemId) => itemId !== item.id)
          : [...prevCollapsed, item.id],
      );
    };
  }, []);

  return {
    logic,
    focus,
    collapsed,
    handleOnFocus,
    handleOnBlur,
    handleOnSelectLogic,
    handleOnCollapse,
  };
};

export const useSyncedTransact = <Store>(store: Store) => {
  return useCallback(
    (callback: () => void) => {
      getYjsDoc(store).transact(callback);
    },
    [store],
  );
};

export const useChoiceSetEditor = (store: State) => {
  const { choiceSets } = useSyncedStore(store);

  const { choiceSetId, isOpen, apply } = useStore((state) => ({
    choiceSetId: state.choiceSetId,
    isOpen: state.choiceSetEditorIsOpen,
    apply: state.apply,
  }));

  const choiceSet = choiceSetId ? choiceSets?.[choiceSetId] ?? null : null;

  const handleOnSave = () => {
    apply((state) => {
      state.choiceSetId = null;
      state.choiceSetEditorIsOpen = false;
    });
  };

  const handleOnClose = () => {
    apply((state) => {
      state.choiceSetId = null;
      state.choiceSetEditorIsOpen = false;
    });
  };

  return {
    choiceSet,
    isOpen,
    onSave: handleOnSave,
    onClose: handleOnClose,
  };
};

export const useFormTemplateEditor = (store: State) => {
  const { pageOrder, items } = useSyncedStore(store);
  const transact = useSyncedTransact(store);

  const {
    logic,
    focus,
    collapsed,
    handleOnFocus,
    handleOnBlur,
    handleOnSelectLogic,
    handleOnCollapse,
  } = useFormTemplateEditorLocalState();

  const handleOnChange = useCallback(
    ({ oldParentItem, newParentItem, item, children }: TreeChangeEvent) => {
      transact(() => {
        if (newParentItem.id === oldParentItem.id) {
          items[newParentItem.id].children = children;
          return;
        }

        items[item.id].parentId = newParentItem.id;
        items[newParentItem.id].children = children;
        items[oldParentItem.id].children = items[
          oldParentItem.id
        ].children.filter((id) => id !== item.id);
      });
    },
    [items, transact],
  );

  const getLogicFor = useCallback(
    (item: Item<BlockData>) => {
      const selectedLogic = items?.[logic?.[item.id]] as Item<LogicData>;
      const firstLogic = items[item.children[0]] as Item<LogicData>;
      return selectedLogic || firstLogic;
    },
    [items, logic],
  );

  const isLogicActive = useCallback(
    (logicItem: Item, parentItem: Item) => {
      return logicItem.id === getLogicFor(parentItem).id;
    },
    [getLogicFor],
  );

  const getShouldItemNest = useCallback((aboveItem: Item) => {
    return aboveItem.type === ItemType.BLOCK
      ? aboveItem.children.length > 0
      : aboveItem.type === ItemType.CONTAINER;
  }, []);

  const getShouldItemRender = useCallback(
    (item: Item, parentItem: Item) => {
      if (!item?.parentId) return false;
      if (collapsed.includes(item.parentId)) return false;

      if (item.type === ItemType.BLOCK || item.type === ItemType.CONTAINER) {
        return true;
      }

      if (
        parentItem.type === ItemType.PAGE ||
        parentItem.type === ItemType.CONTAINER
      ) {
        return true;
      }

      return isLogicActive(item, parentItem);
    },
    [collapsed, isLogicActive],
  );

  const getShouldIncludeSelf = useCallback((item: Item) => {
    return item.type !== ItemType.LOGIC;
  }, []);

  const getShouldParentIncreaseDepth = useCallback((parentItem: Item) => {
    return (
      parentItem.type === ItemType.LOGIC ||
      parentItem.type === ItemType.CONTAINER
    );
  }, []);

  const transformParentItem = useCallback(
    (parentItem: Item) => {
      return parentItem.type === ItemType.BLOCK
        ? getLogicFor(parentItem)
        : parentItem;
    },
    [getLogicFor],
  );

  const handleAddPageOnClick = useCallback(() => {
    const id = generateId();
    transact(() => {
      pageOrder.push(id);
      items[id] = {
        id,
        type: ItemType.PAGE,
        parentId: '',
        children: [],
        items: [],
        data: { name: 'New page' },
      };
    });
  }, [items, pageOrder, transact]);

  const choiceSetEditorProps = useChoiceSetEditor(store);

  return {
    logic,
    focus,
    collapsed,
    handleOnFocus,
    handleOnBlur,
    handleOnSelectLogic,
    handleOnCollapse,
    handleOnChange,
    getLogicFor,
    isLogicActive,
    getShouldItemNest,
    getShouldItemRender,
    getShouldIncludeSelf,
    getShouldParentIncreaseDepth,
    transformParentItem,
    handleAddPageOnClick,
    choiceSetEditorProps,
  };
};

type UseCreateItemParams =
  | {
      item?: any;
      page?: never;
      parentId?: string;
    }
  | {
      item?: never;
      page?: any;
      parentId?: string;
    };

export const useCreateItem = ({
  item,
  page,
  parentId = undefined,
}: UseCreateItemParams) => {
  const { store, onFocus } = useFormTemplateContext();
  const { items } = useSyncedStore(store);
  const transact = useSyncedTransact(store);

  const newParentId = parentId || (item ? item.parentId : page.id);

  return useCallback(
    (itemType: ItemType, data: any = {}) => {
      return () => {
        const id = generateId();
        const newItem = {
          id,
          type: itemType,
          parentId: newParentId,
          data,
          children: [],
        };

        transact(() => {
          items[newItem.id] = newItem;
          items[newParentId].children.push(newItem.id);
        });

        setTimeout(() => onFocus(newItem)(), 100);
      };
    },
    [items, newParentId, onFocus, transact],
  );
};

export const useDeleteItem = (item: Item<BlockData | LogicData>) => {
  const { store } = useFormTemplateContext();
  const { items } = useSyncedStore(store);
  const transact = useSyncedTransact(store);

  return useCallback(() => {
    const index = items[item.parentId].children.indexOf(item.id);
    transact(() => {
      items[item.parentId].children.splice(index, 1);
      for (const itemToRemove of flatten(
        item,
        items,
        () => true,
        () => true,
      )) {
        delete items[itemToRemove.id];
      }
    });
  }, [item, items, transact]);
};
