import { pdf } from '@react-pdf/renderer';
import { Buffer } from 'buffer';
import PDFMerger from 'pdf-merger-js';
import { platformClient } from '../../../../app/platform';

export const render = async (
  report: any,
  extra: { preamble: any; sourceObject: { id: string } }[] = [],
) => {
  const instance = pdf(report);
  console.debug('Created PDF instance.');

  let blob: Blob;
  try {
    blob = await instance.toBlob();
  } catch (error) {
    console.error('Failed to create PDF instance.', error);
    return;
  }
  const merger = new PDFMerger();
  await merger.add(await blob.arrayBuffer());

  for (const item of extra) {
    const platformObject = await platformClient.getObject(
      item.sourceObject.id,
    )();

    if (!platformObject?.getUrl) continue;

    const platformObjectResponse = await fetch(platformObject.getUrl);
    const platformObjectBuffer = await platformObjectResponse.arrayBuffer();

    const preamble = pdf(item.preamble);

    const preambleBlob = await preamble.toBlob();
    const preambleBuffer = new Buffer(await preambleBlob.arrayBuffer());

    await merger.add(preambleBuffer);
    await merger.add(platformObjectBuffer);
  }

  const mergedBuffer = await merger.saveAsBuffer();
  const mergedBlob = new Blob([mergedBuffer], { type: 'application/pdf' });
  console.debug('Merged PDFs.');

  return mergedBlob;
};
