import React from 'react';
import { ContainerData, FormItemType, Item } from 'external/types';
import { ReportHexagon, ReportText, ReportView } from 'lib/shared';
import { useReportContext } from '../../../../../../context';
import ReportStack from '../../../../../ReportStack';
import { useReportBlockFormContext } from '../../context';
import FormField from '../FormField';
import { FormBlockProps } from './types';

const FormBlock = ({ item, path }: FormBlockProps) => {
  const { theme } = useReportContext();
  const { template, submission, shouldRender } = useReportBlockFormContext();

  if (item.type === FormItemType.LOGIC) {
    const childItems = item.children
      .filter((childId) => shouldRender(`${path}::${childId}`))
      .map((childId) => template.items[childId] as Item);
    return childItems.length > 0 ? (
      <>
        {childItems.map((childItem) => (
          <FormBlock
            key={childItem.id}
            item={childItem}
            path={`${path}::${childItem.id}`}
          />
        ))}
      </>
    ) : null;
  }

  if (item.type === FormItemType.CONTAINER) {
    if ((item.data as ContainerData).isRepeated) {
      const branchIds =
        (submission.values[path]?.value as undefined | string[]) || [];

      if (branchIds.length === 0) {
        return (
          <ReportView style={{ paddingLeft: 32, paddingRight: 32 }}>
            <ReportView
              wrap={false}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                textAlign: 'center',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: theme.colors.border,
                borderRadius: 8,
                padding: 24,
              }}
            >
              <ReportView style={{ marginBottom: 4, paddingTop: 4 }}>
                <ReportHexagon />
              </ReportView>
              <ReportText
                style={{
                  marginBottom: 4,
                  textAlign: 'center',
                  fontSize: 13,
                  fontWeight: 600,
                  color: theme.colors.subtitle,
                }}
              >
                {item.data.value}
              </ReportText>
              <ReportText
                style={{
                  textAlign: 'center',
                  fontSize: 11,
                  color: theme.colors.subtitle,
                }}
              >
                None reported
              </ReportText>
            </ReportView>
          </ReportView>
        );
      }
      return branchIds.length ? (
        <ReportStack>
          {branchIds.map((branchId, branchIndex) => (
            <ReportView key={branchId}>
              <ReportText
                style={{
                  fontSize: 13,
                  fontWeight: 600,
                  paddingLeft: 32,
                  paddingRight: 32,
                  marginBottom: 10,
                  lineHeight: '2',
                }}
              >
                {item.data.value} {branchIndex + 1}
              </ReportText>
              {item.children.length > 0 && (
                <ReportStack>
                  {item.children.map((childId) => {
                    const item = template.items[childId];
                    return (item.data as any)?.isPrivate ? null : (
                      <FormBlock
                        key={childId}
                        item={template.items[childId] as Item}
                        path={`${path}::${branchId}::${childId}`}
                      />
                    );
                  })}
                </ReportStack>
              )}
            </ReportView>
          ))}
        </ReportStack>
      ) : null;
    }

    return (
      <ReportView>
        <ReportView
          style={{ paddingLeft: 32, paddingRight: 32, marginBottom: 4 }}
        >
          <ReportText
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              lineHeight: '2',
              whiteSpace: 'nowrap',
            }}
          >
            {item.data.value}
          </ReportText>
        </ReportView>
        {item.children.filter((childId) => shouldRender(`${path}::${childId}`))
          .length > 0 && (
          <ReportStack>
            {item.children
              .filter((childId) => shouldRender(`${path}::${childId}`))
              .map((childId) => (
                <FormBlock
                  key={childId}
                  item={template.items[childId] as Item}
                  path={`${path}::${childId}`}
                />
              ))}
          </ReportStack>
        )}
      </ReportView>
    );
  }

  return (
    <>
      <FormField item={item as Item} path={path} />
      {item.children.map((childId, childIndex) => (
        <FormBlock
          key={childId}
          item={template.items[childId] as Item}
          path={`${path}::${childId}`}
          outerIndex={childIndex}
        />
      ))}
    </>
  );
};

export default FormBlock;
