import React, { ReactElement, useEffect, useState } from 'react';
import { ScrollMode, SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { BlobProvider, Document, Page, pdf } from '@react-pdf/renderer';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from '@/components/ui/dialog';
import { gql, useMutation } from '@apollo/client';
import ShortUniqueId from 'short-unique-id';
import { toast } from 'sonner';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useUploadFile } from 'lib/hooks';
import {
  AsyncContext,
  PageLoader,
  ReportCache,
  ReportContext,
  ReportMode,
  registerFonts,
} from 'lib/shared';
import { CommercialEstimateStatus } from 'lib/types';
import EstimateActions from '../EstimateActions';
import RendererBody from './components/RendererBody';
import RendererHeader from './components/RendererHeader';
import { EstimateQuoteContext, QuoteRendererContext } from './context';
import { useEstimateQuote, useQuoteRenderer } from './hooks';

const EstimateQuote = () => {
  const uploadFile = useUploadFile();

  const { context } = useEstimateQuote();
  const { context: quoteRenderContext } = useQuoteRenderer();

  const [loading, setLoading] = useState(false);
  const [pdfComponent, setPdf] = useState<ReactElement>();

  const [loadingFonts, setLoadingFonts] = useState(true);
  useEffect(() => {
    registerFonts();
    setLoadingFonts(false);
  }, []);

  useDeepCompareEffect(() => {
    setLoading(true);
    setPdf(
      <ReportContext.Provider
        value={{
          mode: ReportMode.PDF,
          cache: new ReportCache(),
          theme: quoteRenderContext.theme,
          images: [],
          documents: [],
          registerImage: () => {},
          registerDocument: () => {},
        }}
      >
        <Document>
          <QuoteRendererContext.Provider value={quoteRenderContext}>
            <Page size="A4" style={{ fontFamily: 'Inter' }}>
              <RendererHeader />
              <RendererBody />
            </Page>
          </QuoteRendererContext.Provider>
        </Document>
      </ReportContext.Provider>,
    );
  }, [quoteRenderContext]);

  const [approve] = useMutation(
    gql`
      mutation ApproveCommercialEstimate(
        $id: ID!
        $sourceObject: PlatformObjectInput!
        $sourceContext: JSONScalarInput!
      ) {
        createJobQuote(
          commercialEstimateId: $id
          sourceObject: $sourceObject
          sourceContext: $sourceContext
        ) {
          jobQuote {
            id
          }
          jobQuoteVersion {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleApproveEstimate = async () => {
    if (!context.estimate) {
      console.error('Estimate not found');
      return;
    }

    const instance = pdf(pdfComponent);
    const blob = await instance.toBlob();

    // todo: look into this
    // @ts-ignore
    const { randomUUID } = new ShortUniqueId({ length: 10 });
    const platformObject = await uploadFile(
      new File([blob], `${context.estimate.id}-${randomUUID()}.pdf`, {
        type: 'application/pdf',
      }),
    );

    if (!platformObject) {
      console.warn('Failed to upload PDF');
      toast.error('Failed to upload PDF');
      return;
    }

    const { data, errors } = await approve({
      variables: {
        id: context.estimate.id,
        sourceContext: { estimate: context.estimate },
        sourceObject: {
          id: platformObject.id,
          type: platformObject.type,
          size: platformObject.size,
          name: platformObject.name,
          data: platformObject.data,
        },
      },
    });

    if (data) {
      await Promise.all([
        context.activity.refetch(),
        context.estimate.refetch(),
      ]);
      toast.success('Estimate approved');
    }

    if (errors) {
      toast.error('Failed to approve estimate');
    }
  };

  return (
    <EstimateQuoteContext.Provider value={context}>
      <div className="flex h-full min-h-0 flex-col overflow-hidden border-l bg-background">
        <div className="flex flex-shrink-0 justify-between border-b px-4 pt-4">
          <div className="flex h-8 items-center justify-between">
            <p className="text-sm font-bold">Quote preview</p>
            <Badge
              variant="incoming"
              className="ml-4 h-5 rounded-md text-[11px]"
            >
              Awaited
            </Badge>
          </div>
        </div>
        <div className="relative -mr-1 min-h-0 grow py-4 pl-3">
          {(loading || loadingFonts) && (
            <div className="absolute inset-0 z-10 bg-background">
              <PageLoader className="p-4">Loading quote...</PageLoader>
            </div>
          )}
          {pdfComponent && (
            <BlobProvider document={pdfComponent}>
              {({ url }) => {
                return url ? (
                  <div className="job-report-pdf relative h-full min-h-0">
                    <Viewer
                      scrollMode={ScrollMode.Vertical}
                      fileUrl={url}
                      defaultScale={SpecialZoomLevel.PageWidth}
                      onDocumentLoad={() => {
                        setLoading(false);
                      }}
                    />
                  </div>
                ) : null;
              }}
            </BlobProvider>
          )}
        </div>
        <div className="flex h-[45px] flex-shrink-0 items-center justify-between border-t px-4">
          <div />
          <div>
            {context.estimate.status === CommercialEstimateStatus.Draft && (
              <AsyncContext handle={handleApproveEstimate}>
                {({ handle, loading }) => (
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button size="sm" variant="secondary">
                        Approve estimate and save quote
                      </Button>
                    </DialogTrigger>
                    <DialogContent overlay>
                      <div>
                        <p className="mb-2 text-sm font-semibold">
                          Are you sure you want to approve the estimate?
                        </p>
                        <p className="mb-4 text-xs">
                          To approve the estimate press the button below.
                        </p>
                        <DialogClose asChild>
                          <Button
                            className="mb-4 w-full"
                            loading={loading}
                            onClick={handle}
                          >
                            Approve estimate
                          </Button>
                        </DialogClose>
                        <p className="text-xs text-gray-400 dark:text-gray-400">
                          Upon approval the estimate will be rendered as a PDF
                          and stored ready to be sent to the client or other
                          parties. Please ensure all information is correct
                          before proceeding.
                        </p>
                      </div>
                    </DialogContent>
                  </Dialog>
                )}
              </AsyncContext>
            )}
            {context.estimate.status === CommercialEstimateStatus.Submitted && (
              <Button size="sm" variant="secondary" disabled>
                Submitted
              </Button>
            )}
            {context.estimate.status === CommercialEstimateStatus.Approved && (
              <div className="flex gap-x-2">
                <Button size="sm" variant="secondary" disabled>
                  Approved
                </Button>
                <EstimateActions />
              </div>
            )}
            {context.estimate.status === CommercialEstimateStatus.Rejected && (
              <Button size="sm" variant="secondary" disabled>
                Rejected
              </Button>
            )}
          </div>
        </div>
      </div>
    </EstimateQuoteContext.Provider>
  );
};

export default EstimateQuote;
