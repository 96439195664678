import React, { Fragment, memo, useCallback, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { DotIcon } from 'lucide-react';
import { useNavigate } from 'react-router';
import { Button } from '@/components/ui/button';
import { MultipleSelector } from '@/components/ui/multi-select';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import { cn } from '@/lib/utils';
import { gql, useQuery } from '@apollo/client';
import deepEqual from 'fast-deep-equal';
import { DateTime, Interval } from 'luxon';
import { RosterTeam } from 'lib/types';
import { getWeekNumber } from 'lib/utils';
import { useInitialQuery, useInterval } from './hooks';
import { useStore } from './state';

export type TeamsQueryVariables = {
  dateTimeStart: DateTime;
  dateTimeEnd: DateTime;
};

export type TeamsQueryData = {
  rosterTeams: RosterTeam[];
};

type TeamsProps = {
  mode: 'creator' | 'approver';
  range: Interval;
  onClick: (rosterTeam: RosterTeam) => () => void;
};

const useGetTeams = (range: Interval) => {
  if (!range.start || !range.end) return { rosterTeams: [], isLoading: true };

  const { data, loading: isLoading } = useQuery<
    TeamsQueryData,
    TeamsQueryVariables
  >(
    gql`
      query GetRosterTeams($dateTimeStart: DateTime!, $dateTimeEnd: DateTime!) {
        rosterTeams {
          id
          name
          users {
            id
          }
          requiresReviewInDateRange(
            dateTimeStart: $dateTimeStart
            dateTimeEnd: $dateTimeEnd
          ) {
            id
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      variables: {
        dateTimeStart: range.start,
        dateTimeEnd: range.end,
      },
    },
  );

  const staticValues = { isLoading };

  return data
    ? { ...staticValues, rosterTeams: data.rosterTeams }
    : { ...staticValues, rosterTeams: [] };
};

const Teams = ({ mode, range, onClick }: TeamsProps) => {
  const { rosterTeams, isLoading } = useGetTeams(range);

  if (isLoading) {
    return <div></div>;
  }

  return (
    <div>
      {rosterTeams.map((rosterTeam) => {
        const { requiresReviewInDateRange: rosterDuties } = rosterTeam;
        let secondary: React.ReactNode;
        if (mode === 'approver' && rosterDuties.length > 0) {
          secondary = (
            <p className="mt-1 text-xs text-destructive">
              {rosterDuties.length} dut
              {rosterDuties.length === 1 ? 'y' : 'ies'} require
              {rosterDuties.length === 1 ? 's' : ''} review
            </p>
          );
        }
        return (
          <Button
            key={rosterTeam.id}
            className="block h-auto w-full rounded-none border-b p-4 text-left last:border-b-0"
            variant="ghost"
            onClick={onClick(rosterTeam)}
          >
            <p className="font-bold">{rosterTeam.name}</p>
            {secondary}
          </Button>
        );
      })}
    </div>
  );
};

export const DayClipper = ({
  className,
  isLastOfWeek = false,
}: {
  className?: string;
  isLastOfWeek?: boolean;
}) => {
  const classNameBase = cn('absolute z-[5]', className);
  return (
    <>
      <svg
        width={8}
        height={8}
        className={cn(classNameBase, 'right-[100%] top-1/4')}
        viewBox="0 0 400 400"
        preserveAspectRatio="none"
      >
        <path d="M200 0   A 200 200 0  0 1 400 200 v-200" />
      </svg>
      <svg
        width={8}
        height={8}
        className={cn(classNameBase, 'bottom-1/4 right-[100%]')}
        viewBox="0 0 400 400"
        preserveAspectRatio="none"
      >
        <path d="M400 200 A 200 200 90 0 1 200 400 h200" />
      </svg>

      {!isLastOfWeek && (
        <>
          <svg
            width={8}
            height={8}
            style={{ transform: 'rotate(180deg)' }}
            className={cn(classNameBase, 'absolute bottom-1/4 left-[100%]')}
            viewBox="0 0 400 400"
            preserveAspectRatio="none"
          >
            <path d="M200 0   A 200 200 0  0 1 400 200 v-200" />
          </svg>
          <svg
            width={8}
            height={8}
            style={{ transform: 'rotate(180deg)' }}
            className={cn(classNameBase, 'absolute left-[100%] top-1/4')}
            viewBox="0 0 400 400"
            preserveAspectRatio="none"
          >
            <path d="M400 200 A 200 200 90 0 1 200 400 h200" />
          </svg>
        </>
      )}
    </>
  );
};

const DayLegend = () => {
  return (
    <div className="flex gap-x-4">
      <div className="flex items-center gap-x-2">
        <div
          className="h-4 w-4 rounded opacity-25"
          style={{ backgroundColor: 'gray' }}
        />
        <p className="text-xs font-medium text-gray-600">Un-submitted duties</p>
      </div>
      <div className="flex items-center gap-x-2">
        <div
          className="h-4 w-4 rounded opacity-25"
          style={{ backgroundColor: 'red' }}
        />
        <p className="text-xs font-medium text-gray-600">Awaiting review</p>
      </div>
      <div className="flex items-center gap-x-2">
        <div
          className="h-4 w-4 rounded opacity-25"
          style={{ backgroundColor: 'green' }}
        />
        <p className="text-xs font-medium text-gray-600">Approved</p>
      </div>
    </div>
  );
};

export const Day = memo(
  ({
    month,
    day,
    dayIndex,
    counts: {
      rosterDutyCountPerDay,
      rosterDutySubmittedPerDay,
      rosterDutyApprovedPerDay,
    },
  }: {
    month: Interval;
    day: Interval;
    dayIndex: number;
    counts: {
      rosterDutyCountPerDay: Record<string, number>;
      rosterDutySubmittedPerDay: Record<string, number>;
      rosterDutyApprovedPerDay: Record<string, number>;
    };
  }) => {
    if (!month.start || !day.start) return null;
    const isFirstOfWeek = dayIndex === 0;
    const isLastOfWeek = dayIndex === 6;
    const countKey = day.start.toFormat('yyyy-MM-dd') as string;
    const hasDuties = rosterDutyCountPerDay[countKey] > 0;
    const hasSubmitted = rosterDutySubmittedPerDay[countKey] > 0;
    const hasApproved = rosterDutyApprovedPerDay[countKey] > 0;
    return month.start.month === day.start.month ? (
      <td
        key={day.toISO()}
        className={cn('relative py-4 text-center group-hover:bg-muted', {
          'rounded-r-md': isLastOfWeek,
        })}
      >
        <span className="relative z-[2]">{day.start.day}</span>
        {!hasDuties && !hasSubmitted && !hasApproved && (
          <DayClipper isLastOfWeek={isLastOfWeek} />
        )}
        {hasDuties && (hasSubmitted || hasApproved) && (
          <div className="absolute -bottom-1 left-1/2 -translate-x-[50%]">
            <DotIcon className="text-gray-400" />
          </div>
        )}
        {hasDuties && !hasSubmitted && !hasApproved && (
          <div
            className={cn(
              'absolute bottom-1/4 left-0 right-0 top-1/4 opacity-25',
              {
                'rounded-l': isFirstOfWeek,
                'rounded-r': isLastOfWeek,
              },
            )}
            style={{ backgroundColor: 'gray' }}
          />
        )}
        {hasApproved && !hasSubmitted && (
          <div
            className={cn(
              'absolute bottom-1/4 left-0 right-0 top-1/4 opacity-25',
              {
                'rounded-l': isFirstOfWeek,
                'rounded-r': isLastOfWeek,
              },
            )}
            style={{ backgroundColor: 'green' }}
          />
        )}
        {hasApproved && hasSubmitted && (
          <>
            <div
              className={cn(
                'absolute bottom-1/2 left-0 right-0 top-1/4 opacity-25',
                {
                  'rounded-tl': isFirstOfWeek,
                  'rounded-tr': isLastOfWeek,
                },
              )}
              style={{ backgroundColor: 'green' }}
            />
            <div
              className={cn(
                'absolute bottom-1/4 left-0 right-0 top-1/2 opacity-25',
                {
                  'rounded-bl': isFirstOfWeek,
                  'rounded-br': isLastOfWeek,
                },
              )}
              style={{ backgroundColor: 'red' }}
            />
          </>
        )}
        {!hasApproved && hasSubmitted && (
          <div
            className={cn(
              'absolute bottom-1/4 left-0 right-0 top-1/4 opacity-25',
              {
                'rounded-l': isFirstOfWeek,
                'rounded-r': isLastOfWeek,
              },
            )}
            style={{ backgroundColor: 'red' }}
          />
        )}
      </td>
    ) : (
      <td key={day.toISO()} className="relative py-4 text-center text-gray-400">
        {day.start.day}
        {!hasSubmitted && !hasApproved && (
          <DayClipper isLastOfWeek={isLastOfWeek} />
        )}
      </td>
    );
  },
);

export const Week = memo(
  ({
    month,
    weeks,
    week,
    weekIndex,
    dateTimeStart,
    counts: {
      rosterDutyCountPerDay,
      rosterDutySubmittedPerDay,
      rosterDutyApprovedPerDay,
    },
    handleRangeOnClick,
  }: {
    month: Interval;
    weeks: Interval[];
    week: Interval;
    weekIndex: number;
    dateTimeStart: DateTime;
    counts: {
      rosterDutyCountPerDay: Record<string, number>;
      rosterDutySubmittedPerDay: Record<string, number>;
      rosterDutyApprovedPerDay: Record<string, number>;
    };
    handleRangeOnClick: (range: Interval) => () => void;
  }) => {
    if (!week.start) return null;
    const weekKeys = week
      .splitBy({ days: 1 })
      .map((day) => day.start?.toFormat('yyyy-MM-dd'))
      .filter((date) => date) as string[];
    const weekHasDuties = weekKeys.some(
      (date) => rosterDutyCountPerDay[date] > 0,
    );
    const weekHasSubmitted = weekKeys.some(
      (date) => rosterDutySubmittedPerDay[date] > 0,
    );
    const weekHasApproved = weekKeys.some(
      (date) => rosterDutyApprovedPerDay[date] > 0,
    );
    return month.overlaps(week) ? (
      <tr
        className="group/week cursor-pointer rounded-lg fill-background hover:bg-muted hover:fill-muted"
        onClick={handleRangeOnClick(week)}
      >
        <td
          className={cn(
            'w-[26px] rounded-l-md text-center text-xs text-gray-400',
            {
              'font-bold': weekHasDuties || weekHasSubmitted || weekHasApproved,
              'text-green-400': weekHasApproved,
              'text-red-400': weekHasSubmitted,
            },
          )}
        >
          {getWeekNumber(week.start, dateTimeStart) + 1}
        </td>
        {week.splitBy({ days: 1 }).map((day, dayIndex) => {
          return (
            <Day
              key={day.toISO()}
              month={month}
              day={day}
              dayIndex={dayIndex}
              counts={{
                rosterDutyCountPerDay,
                rosterDutySubmittedPerDay,
                rosterDutyApprovedPerDay,
              }}
            />
          );
        })}
      </tr>
    ) : (
      <>
        {weekIndex === weeks.length - 1 && (
          <tr>
            <td className="h-[26px]" />
          </tr>
        )}
      </>
    );
  },
);

export const Month = memo(
  ({
    small = false,
    month,
    dateTimeStart,
    rosterDutyCountPerDay,
    rosterDutySubmittedPerDay,
    rosterDutyApprovedPerDay,
    handleRangeOnClick,
  }: {
    small?: boolean;
    month: Interval;
    dateTimeStart: DateTime;
    rosterDutyCountPerDay: Record<string, number>;
    rosterDutySubmittedPerDay: Record<string, number>;
    rosterDutyApprovedPerDay: Record<string, number>;
    handleRangeOnClick: (range: Interval) => () => void;
  }) => {
    if (!month.start || !month.end) return null;
    const dateStartInitial = DateTime.fromObject({
      month: month.start.month,
      year: month.start.year,
    });
    const dateStart = dateStartInitial.startOf('week').minus({ days: 2 });
    const dateEnd = dateStart.plus({ days: 42 });
    const interval = Interval.fromDateTimes(dateStart, dateEnd);
    return (
      <Fragment key={month.toISO()}>
        <div className="sticky top-0 z-[10] bg-background/75 pt-5 backdrop-blur-sm">
          <div
            className={cn(
              'sticky top-0 flex items-center justify-between py-2',
              {
                'pl-0 pr-2.5': !small,
                'pl-1 pr-1.5': small,
              },
            )}
          >
            <p className="text-sm font-bold">
              {month.start.toFormat('MMMM yyyy')}
            </p>
            <p className="text-xs font-medium text-gray-400">
              Week{' '}
              {getWeekNumber(month.start.startOf('week'), dateTimeStart) + 1}
              {' - week '}
              {getWeekNumber(month.end.startOf('week'), dateTimeStart) + 1}
            </p>
          </div>
          <table className="w-full table-fixed">
            <thead>
              <tr>
                <th className="w-[26px] text-xs text-gray-400">Wk</th>
                {Interval.fromDateTimes(dateStart, dateStart.plus({ weeks: 1 }))
                  .splitBy({ days: 1 })
                  .map((day) => {
                    return day.start ? (
                      <th
                        key={day.start.toISO()}
                        className={cn('text-gray-600', { 'text-sm': small })}
                      >
                        {day.start.toFormat('EEE')}
                      </th>
                    ) : null;
                  })}
              </tr>
            </thead>
          </table>
        </div>
        <table className="w-full table-fixed">
          <tbody>
            {interval.splitBy({ weeks: 1 }).map((week, weekIndex, weeks) => {
              return (
                <Week
                  key={week.toISO()}
                  month={month}
                  weeks={weeks}
                  week={week}
                  weekIndex={weekIndex}
                  dateTimeStart={dateTimeStart}
                  counts={{
                    rosterDutyCountPerDay,
                    rosterDutySubmittedPerDay,
                    rosterDutyApprovedPerDay,
                  }}
                  handleRangeOnClick={handleRangeOnClick}
                />
              );
            })}
          </tbody>
        </table>
      </Fragment>
    );
  },
);

const Year = memo(
  ({
    interval,
    dateTimeStart,
    counts: {
      rosterDutyCountPerDay,
      rosterDutySubmittedPerDay,
      rosterDutyApprovedPerDay,
    },
    handleRangeOnClick,
  }: {
    interval: Interval;
    dateTimeStart: DateTime;
    counts: {
      rosterDutyCountPerDay: Record<string, number>;
      rosterDutySubmittedPerDay: Record<string, number>;
      rosterDutyApprovedPerDay: Record<string, number>;
    };
    handleRangeOnClick: (range: Interval) => () => void;
  }) => {
    return (
      <ScrollArea>
        <div className="grid grid-cols-4 gap-[1px] bg-border">
          {interval.splitBy({ month: 1 }).map((month) => {
            return (
              <div key={month.toISO()} className="bg-background pl-5 pr-6">
                <Month
                  month={month}
                  dateTimeStart={dateTimeStart}
                  rosterDutyCountPerDay={rosterDutyCountPerDay}
                  rosterDutySubmittedPerDay={rosterDutySubmittedPerDay}
                  rosterDutyApprovedPerDay={rosterDutyApprovedPerDay}
                  handleRangeOnClick={handleRangeOnClick}
                />
              </div>
            );
          })}
        </div>
      </ScrollArea>
    );
  },
  deepEqual,
);

const HumanResourcesRostersCalendar = () => {
  const navigate = useNavigate();
  const {
    rosterTeams,
    selectedRosterTeamIds,
    dateTimeStart,
    rosterDutyCountPerDay,
    rosterDutySubmittedPerDay,
    rosterDutyApprovedPerDay,
    setStartTimeStart,
    selectRosterTeams,
    decrementStartTimeStart,
    incrementStartTimeStart,
  } = useStore((state) => ({
    rosterTeams: state.rosterTeams,
    selectedRosterTeamIds: state.selectedRosterTeamIds,
    dateTimeStart: state.dateTimeStart,
    rosterDutyCountPerDay: state.rosterDutyCountPerDay,
    rosterDutySubmittedPerDay: state.rosterDutySubmittedPerDay,
    rosterDutyApprovedPerDay: state.rosterDutyApprovedPerDay,
    setStartTimeStart: state.setStartTimeStart,
    selectRosterTeams: state.selectRosterTeams,
    decrementStartTimeStart: state.decrementStartTimeStart,
    incrementStartTimeStart: state.incrementStartTimeStart,
  }));

  const { refetch } = useInitialQuery();

  const [range, setRange] = useState<Interval>();
  const interval = useInterval();

  const handleDrawerOnClose = () => {
    setRange(undefined);
  };

  const handleRangeOnClick = useCallback((next: Interval) => {
    return () => setRange(next);
  }, []);

  const handleCreateRosterOnClick = (rosterTeam: RosterTeam) => {
    return () => {
      if (!range?.start || !range?.end) return;
      navigate(
        `/hr/rosters/${rosterTeam.id}/${encodeURIComponent(
          `${range.start.toMillis()}::${range.end.toMillis()}`,
        )}`,
      );
    };
  };

  const handleReviewRosterOnClick = (rosterTeam: RosterTeam) => {
    return () => {
      if (!range?.start || !range?.end) return;
      navigate(
        `/hr/rosters/reviews/${rosterTeam.id}/${encodeURIComponent(
          `${range.start.toMillis()}::${range.end.toMillis()}`,
        )}`,
      );
    };
  };

  return (
    <>
      <div className="flex h-[52px] w-full flex-shrink-0 items-center gap-x-4 whitespace-nowrap border-b px-4">
        <h1 className="text-xl font-bold">
          Calendar {dateTimeStart.toFormat('yyyy')}
        </h1>
        <Separator orientation="vertical" />
        <div className="grow">
          <MultipleSelector
            hidePlaceholderWhenSelected
            placeholder="Select teams..."
            options={rosterTeams.map((rosterTeam) => ({
              label: rosterTeam.name,
              value: rosterTeam.id,
            }))}
            value={rosterTeams
              .filter((rosterTeam) =>
                selectedRosterTeamIds.includes(rosterTeam.id),
              )
              .map((rosterTeam) => ({
                label: rosterTeam.name,
                value: rosterTeam.id,
              }))}
            onChange={(teams) => {
              const teamIds = teams.map((team) => team.value);
              selectRosterTeams(teamIds);
              refetch({ teams: teamIds });
            }}
          />
        </div>
        <Separator orientation="vertical" />
        <DayLegend />
        <Separator orientation="vertical" />
        <div className="flex gap-x-2">
          <Button
            variant="outline"
            size="icon"
            onClick={decrementStartTimeStart}
          >
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Select
            value={dateTimeStart.toFormat('yyyy')}
            onValueChange={(year) => {
              setStartTimeStart(
                DateTime.fromObject({ year: parseInt(year, 10), month: 4 }),
              );
            }}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select a year" />
            </SelectTrigger>
            <SelectContent className="w-[180px]">
              <SelectGroup>
                {Interval.fromDateTimes(
                  dateTimeStart.minus({ years: 2 }),
                  dateTimeStart.plus({ years: 3 }),
                )
                  .splitBy({ year: 1 })
                  .map((year) =>
                    year.start ? (
                      <SelectItem
                        key={year.start.toFormat('yyyy')}
                        value={year.start.toFormat('yyyy')}
                      >
                        {year.start.toFormat('yyyy')}
                      </SelectItem>
                    ) : null,
                  )}
              </SelectGroup>
            </SelectContent>
          </Select>
          <Button
            variant="outline"
            size="icon"
            onClick={incrementStartTimeStart}
          >
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
      <Year
        interval={interval}
        dateTimeStart={dateTimeStart}
        counts={{
          rosterDutyCountPerDay,
          rosterDutySubmittedPerDay,
          rosterDutyApprovedPerDay,
        }}
        handleRangeOnClick={handleRangeOnClick}
      />
      <Sheet
        open={!!range}
        onOpenChange={(open) => {
          if (!open) {
            handleDrawerOnClose();
          }
        }}
      >
        <SheetContent className="p-0">
          {range?.start && (
            <div>
              <div className="p-4">
                <p>{dateTimeStart.toFormat('MMMM yyyy')}</p>
                <p className="text-sm text-gray-600">
                  Week {getWeekNumber(range.start, dateTimeStart) + 1}
                </p>
              </div>
              <Separator />
              <div>
                <div className="p-4">
                  <p>Create a roster</p>
                  <p className="text-xs text-gray-400">Select a team</p>
                </div>
                <Separator />
                <Teams
                  mode="creator"
                  range={range}
                  onClick={handleCreateRosterOnClick}
                />
              </div>
              <Separator />
              <div>
                <div className="p-4">
                  <p className="mb-1">Approve a roster</p>
                  <p className="text-xs text-gray-400">Select a team</p>
                </div>
                <Separator />
                <Teams
                  mode="approver"
                  range={range}
                  onClick={handleReviewRosterOnClick}
                />
              </div>
            </div>
          )}
        </SheetContent>
      </Sheet>
    </>
  );
};

export default HumanResourcesRostersCalendar;
