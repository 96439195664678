import produce from 'immer';
import { create } from 'zustand';
import { RosterTeam, User } from 'lib/types';
import { RemoveUserFromRosterTeamMutationData } from '../HumanResourcesRosterTeam/components/RosterTeamSettings/types';
import {
  RosterTeamQueryData,
  UpdateRosterTeamMutationData,
} from '../HumanResourcesRosterTeam/types';
import { InitialQueryData } from './types';

export type RosterTeamsValues = {
  rosterTeams: RosterTeam[];
  rosterTeam: RosterTeam | null;
};

export type Role = keyof Pick<RosterTeam, 'users' | 'approvers' | 'creators'>;

export type RosterTeamsActions = {
  initialQueryOnCompleted: (data: InitialQueryData) => void;
  rosterTeamQueryOnCompleted: (data: RosterTeamQueryData) => void;
  updateRosterTeamOnCompleted: (data: UpdateRosterTeamMutationData) => void;
  setRosterTeamUsers: (role: Role, users: User[]) => void;
};

export type RosterTeamsState = RosterTeamsValues & RosterTeamsActions;

export const initialValues: RosterTeamsValues = {
  rosterTeams: [],
  rosterTeam: null,
};

export const useStore = create<RosterTeamsState>()((set) => ({
  ...initialValues,

  clearRosterTeam: () =>
    set(
      produce<RosterTeamsState>((state) => {
        state.rosterTeam = null;
      }),
    ),

  initialQueryOnCompleted: ({ rosterTeams }) =>
    set(
      produce<RosterTeamsState>((state) => {
        state.rosterTeams = rosterTeams;
      }),
    ),

  rosterTeamQueryOnCompleted: ({ rosterTeam }) =>
    set(
      produce<RosterTeamsState>((state) => {
        state.rosterTeam = rosterTeam;
      }),
    ),

  updateRosterTeamOnCompleted: ({ updateRosterTeam: { rosterTeam } }) =>
    set(
      produce<RosterTeamsState>((state) => {
        state.rosterTeam = { ...state.rosterTeam, ...rosterTeam };
      }),
    ),

  setRosterTeamUsers: (
    role: keyof Pick<RosterTeam, 'users' | 'approvers' | 'creators'>,
    users,
  ) => {
    set(
      produce<RosterTeamsState>((state) => {
        if (state.rosterTeam) {
          state.rosterTeam[role] = users;
        }
      }),
    );
  },
}));
