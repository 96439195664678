import { GraphQLError } from 'graphql';

export enum AuthErrors {
  'AUTH_USER_NOT_CONFIGURED' = 'AUTH_USER_NOT_CONFIGURED',
}

export const errorOfTypeThrown =
  (type: string) => (errors: readonly GraphQLError[] | undefined) => {
    if (!errors) return false;
    return errors.some((error) => error.extensions.type === type);
  };

export const userNotConfigured = errorOfTypeThrown(
  AuthErrors.AUTH_USER_NOT_CONFIGURED,
);
