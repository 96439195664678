import React from 'react';
import { CheckIcon, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import ButtonTabs from '@/components/ui/button-tabs';
import { TableRenderer } from '@/components/ui/table/table/Table';
import { useCompanyStaffContext } from '../../context';
import StaffMember from '../StaffMember';
import StaffMemberCreate from '../StaffMemberCreate';
import { TableMode } from './types';

const StaffTable = () => {
  const { users, tableMode: mode, refetch } = useCompanyStaffContext();
  return (
    <TableRenderer
      filter="name"
      initialSorting={[{ id: 'name', desc: false }]}
      columns={[
        {
          accessorKey: 'name',
          header: 'Name',
          cell: ({ row }) => (
            <StaffMember id={row.original.id}>
              <Button variant="table" size="table">
                {row.original.name}
              </Button>
            </StaffMember>
          ),
        },
        {
          accessorKey: 'email',
          header: 'Email',
        },
        {
          accessorKey: 'jobTitle',
          header: 'Job Title',
          cell: ({ row }) => {
            return (
              <div>
                {row.original.jobTitle ?? (
                  <em className="dark:text-gray-400">Not set</em>
                )}
              </div>
            );
          },
        },
        {
          header: 'Synced',
          cell: ({ row }) => {
            return (
              <div>
                {row.original.platformId ? (
                  <div className="flex items-center gap-x-1">
                    <CheckIcon className="h-4 w-4" />
                    <span>Synced</span>
                  </div>
                ) : (
                  <div className="flex items-center gap-x-1 font-semibold text-destructive">
                    <X className="h-4 w-4" />
                    <span>Not synced</span>
                  </div>
                )}
              </div>
            );
          },
        },
        {
          accessorKey: 'driverKeyFobId',
          header: 'Driver Fob Key',
          cell: ({ row }) => {
            return (
              <div>
                {row.original.driverKeyFobId ?? (
                  <em className="dark:text-gray-400">Not set</em>
                )}
              </div>
            );
          },
        },
      ]}
      data={users}
      topRight={({ columnsSelector }) => (
        <div className="ml-auto flex gap-x-2">
          <ButtonTabs
            className="h-8 bg-background"
            buttonClassName="h-8"
            tabs={[
              {
                label: 'Active',
                value: TableMode.Active,
              },
              {
                label: 'Archived',
                value: TableMode.Archived,
              },
            ]}
            value={mode.value}
            onValueChange={mode.set}
          />
          <StaffMemberCreate
            onCreated={async () => {
              await refetch();
            }}
          />
          {columnsSelector}
        </div>
      )}
    />
  );
};

export default StaffTable;
