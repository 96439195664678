import React from 'react';
import { TableRenderer } from '@/components/ui/table/table/Table';
import PriorityEditor from './components/PriorityEditor';
import { useDeliveryPriorities } from './hooks';

const DeliveryPriorities = () => {
  const { priorities, refetch } = useDeliveryPriorities();
  return (
    <div className="px-4 pt-4">
      <div>
        <h3 className="mb-1">Priorities</h3>
        <p className="text-xs dark:text-white/75">
          Priorities define job timelines and response times.
        </p>
      </div>
      <TableRenderer
        filter="reference"
        filterPlaceholder="Filter priorities..."
        columns={[
          {
            id: 'reference',
            accessorKey: 'reference',
            header: 'Reference',
            cell: ({ row }) => (
              <div className="flex items-center gap-x-2">
                <div
                  className="h-4 w-4 rounded-full"
                  style={{ backgroundColor: row.original.color }}
                />
                <p>{row.original.reference}</p>
              </div>
            ),
          },
          {
            accessorKey: 'responseName',
            header: 'Response name',
          },
          {
            accessorKey: 'responseDuration',
            header: 'Response duration',
          },
          {
            accessorKey: 'completionName',
            header: 'Completion name',
          },
          {
            accessorKey: 'completionDuration',
            header: 'Completion duration',
          },
          {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
              return (
                <div className="flex justify-end">
                  <PriorityEditor
                    id={row.original.id}
                    onSuccess={() => {
                      refetch();
                    }}
                  />
                </div>
              );
            },
          },
        ]}
        data={priorities}
        topRight={({ columnsSelector }) => (
          <div className="ml-auto space-x-2">
            <PriorityEditor
              id={undefined}
              onSuccess={async () => {
                await refetch();
              }}
            />
            {columnsSelector}
          </div>
        )}
      />
    </div>
  );
};

export default DeliveryPriorities;
