import RequirementsCreate from './components/RequirementsCreate';
import RequirementsTable from './components/RequirementsTable';
import { SubcontractorRequirementsContext } from './context';
import { useSubcontractorRequirements } from './hooks';

const SubcontractorRequirements = () => {
  const { context } = useSubcontractorRequirements();
  return (
    <SubcontractorRequirementsContext.Provider value={context}>
      <div>
        <RequirementsCreate />
        <RequirementsTable />
      </div>
    </SubcontractorRequirementsContext.Provider>
  );
};

export default SubcontractorRequirements;
