import React from 'react';
import { Droppable } from '@hello-pangea/dnd';
import { useTreeContext } from '../../context';
import TreeItem from '../TreeItem';
import DroppablePlaceholder from '../TreePlaceholder';
import { TreePageProps } from './types';

const TreePage = ({
  page,
  isCollapsed = false,
  renderHeader,
  renderFooter,
  renderPlaceholder,
  renderItem,
  renderItemPlaceholder,
}: TreePageProps) => {
  const { placeholderPosition, currentItemDepth } = useTreeContext();

  const placeholder = renderPlaceholder?.({ page });
  const footer = renderFooter?.({ page });

  return (
    <div>
      {renderHeader?.({ page, isCollapsed })}
      {!isCollapsed && (
        <>
          <Droppable droppableId={page.id}>
            {(
              { innerRef, placeholder: droppablePlaceholder, droppableProps },
              droppableSnapshot,
            ) => (
              <div
                style={{ position: 'relative' }}
                ref={innerRef}
                {...droppableProps}
              >
                {page.items.length > 0 ? (
                  <div>
                    {page.items.map((item, index) => (
                      <TreeItem
                        key={item.id}
                        item={item}
                        index={index}
                        isLast={index === page.items.length - 1}
                        renderItem={renderItem}
                      />
                    ))}
                    {droppablePlaceholder}
                  </div>
                ) : (
                  placeholder
                )}
                {droppableSnapshot.isDraggingOver && (
                  <DroppablePlaceholder
                    placeholderPosition={placeholderPosition}
                    currentItemDepth={currentItemDepth}
                    depthMargin={40}
                    renderPlaceholder={renderItemPlaceholder}
                  />
                )}
              </div>
            )}
          </Droppable>
          {footer}
        </>
      )}
    </div>
  );
};

export default TreePage;
