import React from 'react';
import {
  Box,
  ClipboardCheck,
  CogIcon,
  Factory,
  HeartPulse,
  Landmark,
  LayoutDashboard,
  ListTodo,
  Table2,
  WrenchIcon,
} from 'lucide-react';
import { Link, PathMatch } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Nav, NavLink } from '@/components/ui/nav';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useClient } from '../../hooks';
import NavJobs from './components/NavJobs';
import NavMaintenance from './components/NavMaintenance';

const ClientNav = ({ routeMatch }: { routeMatch: PathMatch | null }) => {
  const client = useClient();
  return (
    <div className="flex min-h-0 grow flex-col">
      <ScrollArea className="grow border-b" silent>
        <Nav
          links={[
            {
              to: client.paths.root,
              title: 'Overview',
              icon: LayoutDashboard,
              variant:
                routeMatch?.pattern.path === '/clients/:clientSlug'
                  ? 'selected'
                  : 'unselected',
            },
            {
              to: client.paths.todos,
              title: 'Todos',
              icon: ListTodo,
              variant:
                routeMatch?.pattern.path === '/clients/:clientSlug/todos'
                  ? 'selected'
                  : 'unselected',
            },
            {
              to: client.paths.activity,
              title: 'Activity',
              icon: HeartPulse,
              variant:
                routeMatch?.pattern.path === '/clients/:clientSlug/activity'
                  ? 'selected'
                  : 'unselected',
            },
            {
              to: client.paths.commercial.root,
              title: 'Commercial',
              icon: Landmark,
              variant: routeMatch?.pattern.path.startsWith(
                '/clients/:clientSlug/commercial',
              )
                ? 'selected'
                : 'unselected',
            },

            {
              to: client.paths.assets.root,
              title: 'Assets',
              icon: Box,
              variant: routeMatch?.pattern.path.startsWith(
                '/clients/:clientSlug/assets',
              )
                ? 'selected'
                : 'unselected',
            },
            {
              to: client.paths.sites.root,
              title: 'Sites',
              icon: Factory,
              variant:
                routeMatch?.pattern.path === '/clients/:clientSlug/sites'
                  ? 'selected'
                  : 'unselected',
            },
            {
              to: client.paths.projects.root,
              title: 'Projects',
              icon: Table2,
              variant: routeMatch?.pattern.path.startsWith(
                '/clients/:clientSlug/projects',
              )
                ? 'selected'
                : 'unselected',
            },
          ]}
        >
          <NavLink
            link={{
              to: client.paths.maintenance.root,
              title: 'Maintenance',
              icon: ClipboardCheck,
              variant: routeMatch?.pattern.path.startsWith(
                '/clients/:clientSlug/maintenance',
              )
                ? 'selected'
                : 'unselected',
            }}
          />
          <NavMaintenance />
          <NavLink
            link={{
              to: client.paths.jobs.root,
              title: 'Reactive',
              icon: WrenchIcon,
              variant: routeMatch?.pattern.path.startsWith(
                '/clients/:clientSlug/jobs',
              )
                ? 'selected'
                : 'unselected',
            }}
          />
          <NavJobs />
        </Nav>
      </ScrollArea>
      <div className="mb-8 p-4">
        <div className="flex items-center justify-between rounded-md bg-secondary p-4">
          <p className="text-xs font-medium">{client.name}</p>
          <Link to={client.paths.settings.root}>
            <Button size="xs" variant="outline">
              <CogIcon className="mr-1 h-3 w-3" />
              Settings
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ClientNav;
