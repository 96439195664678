import React from 'react';
import { JobTaskFormScope } from 'lib/types';
import FormStaffMember from './components/FormStaffMember';
import FormSubcontractor from './components/FormSubcontractor';
import { TaskFormContext } from './context';
import { useTaskForm } from './hooks';
import { TaskFormProps } from './types';

const TaskForm = ({ form }: TaskFormProps) => {
  const { context } = useTaskForm(form);
  return (
    <TaskFormContext.Provider value={context}>
      {form.scope === JobTaskFormScope.Staff && <FormStaffMember />}
      {form.scope === JobTaskFormScope.Subcontractor && <FormSubcontractor />}
    </TaskFormContext.Provider>
  );
};

export default TaskForm;
