import { NumericFormat } from 'react-number-format';
import { Input } from '@/components/ui/input';
import { useDebouncedNumberInput } from './hooks';
import { DebouncedNumberInputProps } from './types';

const DebouncedNumberInput = ({
  value,
  onChange,
  ...rest
}: DebouncedNumberInputProps) => {
  const { input } = useDebouncedNumberInput(value, onChange);
  return (
    <NumericFormat
      {...rest}
      type="number"
      value={input.value}
      onChange={input.onChange}
      decimalScale={2}
      fixedDecimalScale
      customInput={Input}
    />
  );
};

export default DebouncedNumberInput;
