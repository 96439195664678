import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { DateTime } from 'luxon';
import { useUser } from 'lib/hooks';
import { User } from 'lib/types';
import { generateId } from 'lib/utils';
import { NoteHistoryItemAction } from '../../types';
import Note from '../Note';
import { NoteCreateProps } from './types';

const NoteCreate = ({ scope, store }: NoteCreateProps) => {
  const user = useUser() as User;
  const [open, setOpen] = useState(false);
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button
          className="h-6"
          variant="outline"
          style={{ lineHeight: '24px' }}
        >
          <p className="text-xs" style={{ lineHeight: '16px' }}>
            Create note
          </p>
        </Button>
      </SheetTrigger>
      <SheetContent className="w-[500px] max-w-[500px] sm:max-w-[500px]">
        <SheetHeader className="mb-8">
          <SheetTitle>Create note</SheetTitle>
          <SheetDescription>
            Create a new note to keep track of important information
          </SheetDescription>
        </SheetHeader>
        <Note
          scope={scope}
          onSave={(note) => {
            store.notes[note.id] = {
              ...note,
              history: [
                {
                  id: generateId(),
                  action: NoteHistoryItemAction.Create,
                  dateTime: DateTime.now().toISO() as string,
                  snapshot: JSON.stringify({
                    id: note.id,
                    scope: note.scope,
                    content: note.content,
                    deleted: note.deleted,
                    objects: note.objects,
                  }),
                  user: {
                    id: user.id,
                    name: user.name,
                    email: user.email,
                  },
                },
              ],
            };
            setOpen(false);
          }}
        />
      </SheetContent>
    </Sheet>
  );
};

export default NoteCreate;
