import {
  DropResult,
  OnDragStartResponder,
  OnDragUpdateResponder,
  ResponderProvided,
} from '@hello-pangea/dnd';
import { usePlaceholder } from './placeholder';
import { getDraggedDOM, getItemDepth, useStartTrackingMouse } from './tracking';
import {
  GetIsItemCollapsed,
  GetShouldItemNest,
  GetShouldParentIncreaseDepth,
  Item,
  Items,
  Page,
  Pages,
  TreeOnChange,
  TreeProps,
} from './types';

type Params<T = any> = {
  pages: Pages<T>;
  items: Items<T>;
  getShouldParentIncreaseDepth: GetShouldParentIncreaseDepth<T>;
  getShouldItemNest?: GetShouldItemNest<T>;
  getIsItemCollapsed?: GetIsItemCollapsed<T>;
  transformParentItem?: ParentItemTransformer<T>;
  onDragStartHook: TreeProps['onDragStart'];
  onDragUpdateHook: TreeProps['onDragUpdate'];
  onDragEndHook: TreeProps['onDragEnd'];
  onChange: TreeOnChange<T>;
};

type Required<T = any> = {
  pages: Pages<T>;
  items: Items<T>;
  currentItemDepth: number | undefined;
  stopTrackingMouse: () => void;
  transformParentItem?: ParentItemTransformer<T>;
  getShouldParentIncreaseDepth: GetShouldParentIncreaseDepth<T>;
  onDragEndHook: TreeProps['onDragEnd'];
  onChange: TreeOnChange<T>;
};

export type ParentItemTransformer<T = any> = (item: Item<T>) => Item<T>;

export const orderBy = (toOrder: Item['id'][], basedOn: Item['id'][]) => {
  return [...toOrder].sort((a, b) => basedOn.indexOf(a) - basedOn.indexOf(b));
};

export const handleOnDragEnd = <T = any>(
  result: DropResult,
  provided: ResponderProvided,
  {
    pages,
    items,
    currentItemDepth,
    stopTrackingMouse,
    getShouldParentIncreaseDepth,
    transformParentItem,
    onDragEndHook,
    onChange,
  }: Required<T>,
) => {
  stopTrackingMouse();

  const { source, destination } = result;
  if (!destination) return;

  const oldPage = pages[source.droppableId];
  const newPage = pages[destination.droppableId];

  const item = oldPage.items[source.index];

  const oldParentItem = items[item.parentId];
  let newParentItem: Page<T> | Item<T> = newPage;

  if (currentItemDepth && currentItemDepth > 0) {
    const potentialParentItems = newPage.items
      .slice(
        0,
        // If the dragged item has moved down the tree, i.e. its
        // index has increased, we need to add one to the slice
        // index as we also need to remove the item that moved
        // up the tree in place of the dragged item.
        destination.index + (destination.index > source.index ? 1 : 0),
      )
      .filter((innerItem) => innerItem.id !== item.id)
      .filter(
        (innerItem) =>
          getItemDepth(innerItem, items, getShouldParentIncreaseDepth) ===
          currentItemDepth - 1,
      );
    [newParentItem] = [...potentialParentItems].reverse();

    newParentItem = transformParentItem
      ? transformParentItem(newParentItem)
      : newParentItem;
  }

  const oldPageChildren = [...oldPage.items.map(({ id }) => id)];
  const newPageChildren = [...newPage.items.map(({ id }) => id)];

  if (newPageChildren.includes(item.id)) {
    newPageChildren.splice(source.index, 1);
  }

  newPageChildren.splice(destination.index, 0, oldPageChildren[source.index]);

  const newParentItemChildren = newParentItem.children.includes(item.id)
    ? newParentItem.children
    : [...newParentItem.children, item.id];

  const children = orderBy(newParentItemChildren, newPageChildren);

  onDragEndHook?.(result, provided);
  onChange({
    oldParentItem,
    newParentItem,
    children,
    item,
  });
};

export const useListeners = ({
  pages,
  items,
  getShouldItemNest,
  getIsItemCollapsed,
  getShouldParentIncreaseDepth,
  transformParentItem,
  onDragStartHook,
  onDragUpdateHook,
  onDragEndHook,
  onChange,
}: Params) => {
  const {
    currentItemDepth,
    dragEventRef,
    startTrackingMouse,
    stopTrackingMouse,
  } = useStartTrackingMouse({
    pages,
    items,
    getShouldItemNest,
    getIsItemCollapsed,
    getShouldParentIncreaseDepth,
  });

  const { placeholderPosition, setPlaceholderPosition } = usePlaceholder();

  const handleOnDragStart: OnDragStartResponder = (event, provided) => {
    dragEventRef.current = {
      ...event,
      combine: null,
      destination: event.source,
    };

    startTrackingMouse();

    const draggedDOM = getDraggedDOM(event.draggableId);

    if (draggedDOM && draggedDOM.parentNode) {
      setPlaceholderPosition(
        draggedDOM,
        [...draggedDOM.parentNode.children],
        event.source.index,
      );
    }

    onDragStartHook?.(event, provided);
  };

  const handleOnDragUpdate: OnDragUpdateResponder = (event, provided) => {
    dragEventRef.current = event;

    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDOM(event.draggableId);

    if (!draggedDOM || !draggedDOM.parentNode) {
      return;
    }
    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[event.source.index];
    childrenArray.splice(event.source.index, 1);

    const updatedArray = [
      ...childrenArray.slice(0, event.destination.index),
      movedItem,
      ...childrenArray.slice(event.destination.index),
    ];

    setPlaceholderPosition(draggedDOM, updatedArray, event.destination.index);
    onDragUpdateHook?.(event, provided);
  };

  return {
    currentItemDepth,
    placeholderPosition,
    onDragStart: handleOnDragStart,
    onDragUpdate: handleOnDragUpdate,
    onDragEnd: (event: DropResult, provided: ResponderProvided) =>
      handleOnDragEnd(event, provided, {
        pages,
        items,
        currentItemDepth,
        getShouldParentIncreaseDepth,
        transformParentItem,
        stopTrackingMouse,
        onDragEndHook,
        onChange,
      }),
  };
};
