import { Button } from '@/components/ui/button';
import { useContactsDelete } from './hooks';
import { ContactsDeleteProps } from './types';

const ContactsDelete = ({ contact }: ContactsDeleteProps) => {
  const { handlers } = useContactsDelete(contact);
  return (
    <Button
      confirm="Are you sure?"
      confirmHelp="This will delete the contact."
      size="sm"
      variant="destructive"
      onClick={handlers.onDelete}
    >
      Delete
    </Button>
  );
};

export default ContactsDelete;
