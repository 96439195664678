import { useMemo } from 'react';
import { useLocation, useMatch } from 'react-router';

export const useTopbarBreadcrumb = () => {
  const location = useLocation();

  const rosterMatch = useMatch('/hr/*');

  const segments = useMemo(() => {
    return location.pathname
      .split('/')
      .filter((segment) => Boolean(segment))
      .map((segment) => {
        if (segment === 'hr') {
          return 'Human Resources';
        }
        return segment;
      })
      .map((segment) => segment.replace(/-/g, ' '));
  }, [rosterMatch, location.pathname]);

  return {
    segments,
  };
};
