import { useSyncedStore } from '@syncedstore/react';
import { CONDITIONS } from 'external/FormTemplateEditor';
import { BlockData, Item, LogicData } from 'external/types';
import parse, { BinaryExpression, Identifier } from 'jsep';
import { useFormTemplateContext } from '../../../../context';

export const useBlockLogicCondition = (
  parent: Item<BlockData>,
  itemId: string,
) => {
  const { getLogicFor, onSelectLogic, store } = useFormTemplateContext();
  const { choiceSets, items } = useSyncedStore(store);

  const item = items[itemId] as Item<LogicData>;
  const itemParent = items[item.parentId] as Item<BlockData>;

  const logic = getLogicFor(itemParent);
  const isSelected = itemId === logic?.id;

  const condition =
    CONDITIONS[itemParent.data.dataType]?.find(
      ({ id }) => id === item.data.conditionId,
    ) || CONDITIONS[itemParent.data.dataType]?.[0];

  let body: (Identifier | BinaryExpression)[];
  try {
    body = parse(condition.summary).body as (Identifier | BinaryExpression)[];
  } catch (error) {
    body = [];
  }

  return {
    item,
    body,
    condition,
    isSelected,
    choiceSets,
    onSelectLogic,
  };
};
