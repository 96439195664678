import React from 'react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { UserPhoto } from 'lib/shared';
import { useStaffMemberContext } from '../../context';
import UserSelector from './components/UserSelector';
import { useMemberLineManager } from './hooks';

const MemberLineManager = () => {
  const { user } = useStaffMemberContext();
  const { handlers } = useMemberLineManager();

  if (!user) return null;

  return (
    <div>
      <div className="mb-4">
        <p className="mb-1 text-sm font-medium">Line Manager</p>
        <p className="text-sm text-gray-400 dark:text-gray-400">
          Set this individual's line manager
        </p>
      </div>
      <UserSelector
        title="Select line manager"
        description="Chose the line manager for this user"
        extension
        multiple={false}
        exclude={[user]}
        onSelect={handlers.onSelect}
      >
        <Button
          className={cn(
            'relative h-auto w-full justify-start gap-x-2 py-2 text-start',
            { 'pl-2': !!user.lineManager },
          )}
          variant="outline"
          size="sm"
        >
          {user.lineManager ? (
            <>
              <UserPhoto user={user.lineManager} />
              <div>
                <p className="text-xs font-semibold">{user.lineManager.name}</p>
                <p className="text-xs font-normal text-gray-600 dark:text-gray-400">
                  {user.lineManager.email}
                </p>
              </div>
              <div className="absolute inset-y-0 right-3 flex items-center">
                <span className="text-[11px]">Click to edit</span>
              </div>
            </>
          ) : (
            <div>
              <p>Not set</p>
              <div className="absolute inset-y-0 right-3 flex items-center">
                <span className="text-[11px]">Click to set</span>
              </div>
            </div>
          )}
        </Button>
      </UserSelector>
    </div>
  );
};

export default MemberLineManager;
