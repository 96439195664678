import React from 'react';
import { BryntumTimeline } from '@bryntum/schedulerpro-react-thin';
import { useSchedulerContext } from '../../hooks';
import { defaultConfig, defaultDisabledConfig } from './config';
import { useSchedulerTimeline } from './hooks';
import { SchedulerTimelineProps } from './types';

const SchedulerTimeline = ({ config }: SchedulerTimelineProps) => {
  const { project } = useSchedulerContext();
  const { onEventClick } = useSchedulerTimeline();
  return (
    <BryntumTimeline
      {...defaultDisabledConfig}
      {...defaultConfig}
      {...config}
      project={project}
      onEventClick={onEventClick}
    />
  );
};

export default SchedulerTimeline;
