import { DataType } from 'external/FormTemplateEditor';
import { Item } from 'external/types';

export const formFieldHasValue = (item: Item, value: any) => {
  if (item.type === 'CONTAINER_REPEATING_BRANCH') {
    return Array.isArray(value?.value) && value.value.length > 0;
  }

  if (item.data.dataType === DataType.BOOLEAN) {
    return typeof value !== 'undefined';
  }

  if (!value) return false;
  if (!value.value) return false;
  return !(Array.isArray(value.value) && !value.value.length);
};
