import { createContext, useContext } from 'react';
import { useSubcontractor } from './hooks';

export const SubcontractorContext = createContext(
  {} as ReturnType<typeof useSubcontractor>['context'],
);

export const useSubcontractorContext = () => {
  return useContext(SubcontractorContext);
};
