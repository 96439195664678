import React from 'react';
import { BryntumSchedulerProProps } from '@bryntum/schedulerpro-react-thin';
import { ViewPreset } from '@bryntum/scheduler-thin';
import ContentColumn from './components/ContentColumn';
import ContentEvent from './components/ContentEvent';
import ContentEventTooltip from './components/ContentEventTooltip';

export const defaultViewPreset = new ViewPreset({
  id: 'default',
  name: 'Default',
  tickWidth: 64,
  tickHeight: 24,
  displayDateFormat: 'HH:mm',
  shiftIncrement: 1,
  shiftUnit: 'day',
  defaultSpan: 24,
  timeResolution: {
    unit: 'minute',
    increment: 5,
  },
  headers: [
    {
      unit: 'day',
      dateFormat: 'ddd DD MMM',
    },
    {
      unit: 'hour',
      dateFormat: 'HH:mm',
    },
  ],
  columnLinesFor: 1,
});

export const defaultRenderConfig: Partial<BryntumSchedulerProProps> = {
  eventRenderer: ({ eventRecord, assignmentRecord }) => (
    <ContentEvent key={assignmentRecord.id} eventRecord={eventRecord} />
  ),
  eventTooltipFeature: {
    allowOver: true,
    align: 't-b',
    template: (props: any) => <ContentEventTooltip {...props} />,
  },
};

export const defaultConfig: Partial<BryntumSchedulerProProps> = {
  columns: [
    {
      text: 'Name',
      field: 'name',
      width: 200,
      renderer: ContentColumn,
    },
  ],
  height: '100%',
  resourceMargin: 24,
  rowHeight: 100,
  viewPreset: defaultViewPreset,
  timeRangesFeature: { showCurrentTimeLine: true, showHeaderElements: false },
  infiniteScroll: true,
};

export const defaultDisabledConfig: Partial<BryntumSchedulerProProps> = {
  cellMenuFeature: false,
  cellTooltipFeature: false,
  createEventOnDblClick: false,
  dependenciesFeature: false,
  dependencyEditFeature: false,
  eventDragCreateFeature: false,
  eventMenuFeature: false,
  percentBarFeature: false,
  scheduleMenuFeature: false,
  scheduleTooltipFeature: false,
  creationTooltip: { disabled: true },
  splitFeature: false,
  timeAxisHeaderMenuFeature: false,
};
