import { useCallback, useMemo, useState } from 'react';
import { DataType } from 'external/FormTemplateEditor';
import { BlockData, Item } from 'external/types';
import { z } from 'zod';
import { create } from 'zustand';
import { shouldRenderBase, usePages } from './parse';
import { FormState } from './types';
import { getDefaults } from './utils';

type FormStore = {
  collapsed: string[];
  onCollapse: (itemId: string) => void;
};

export const useFormStore = create<FormStore>()((set) => ({
  collapsed: [] as string[],
  onCollapse: (itemId: string) => {
    set((state) => ({
      collapsed: (state.collapsed = state.collapsed.includes(itemId)
        ? state.collapsed.filter((id) => id !== itemId)
        : [...state.collapsed, itemId]),
    }));
  },
}));

export const useFormCompletion = (pages: any[], values: any) => {
  const hasValue = useCallback(
    (item: Item) => {
      const value = values?.[item.path];

      if (item.data.dataType === DataType.BOOLEAN) {
        return typeof value !== 'undefined';
      }

      if (item.type === 'CONTAINER' && item.data.isRepeated) {
        return Array.isArray(value) && value.length > 0;
      }

      if (!value) return false;
      if (!value.value) return false;
      return !(Array.isArray(value.value) && !value.value.length);
    },
    [values],
  );

  const requiredItems = useMemo(
    () =>
      pages
        .flatMap((innerPage) => innerPage.items)
        .filter(
          (item) => (item.data as BlockData).dataType !== DataType.COMMENT,
        )
        .filter((item) => (item.data as BlockData).isRequired),
    [pages],
  );

  const requiredItemsWithValues = useMemo(
    () => requiredItems.filter(hasValue),
    [hasValue, requiredItems],
  );

  const requiredItemsWithoutValues = useMemo(
    () => requiredItems.filter((item) => !hasValue(item)),
    [hasValue, requiredItems],
  );

  const isCompleted = requiredItemsWithoutValues.length === 0;
  const completionPercentage = Math.round(
    (requiredItemsWithValues.length / requiredItems.length) * 100,
  );

  return { isCompleted, completionPercentage };
};

export const useForm = (
  template: any,
  values: FormState['values'],
  collapsed: string[],
) => {
  const [pageIndex, setPageIndex] = useState(0);
  const { pages } = usePages(template, values, collapsed, shouldRenderBase);
  const page = useMemo(() => pages[pageIndex], [pages, pageIndex]);

  const handleOnPrev = useCallback(() => {
    setPageIndex((prevPage) => Math.max(0, prevPage - 1));
  }, []);

  const handleOnNext = useCallback(() => {
    setPageIndex((prevPage) => Math.min(prevPage + 1, pages.length - 1));
  }, [pages.length]);

  return {
    pages,
    pageIndex,
    page,
    isPrevDisabled: pageIndex === 0,
    isNextDisabled: pageIndex === pages.length - 1,
    onPrev: handleOnPrev,
    onNext: handleOnNext,
  };
};

export const useValue = <Value>(
  proxy: { dataType: DataType; value: Partial<Value> },
  schema: z.ZodType<Value>,
): [Required<NonNullable<Value>>, boolean] => {
  if (!proxy?.value) {
    return [getDefaults(schema) as Required<NonNullable<Value>>, false];
  }

  try {
    const value = schema.parse(proxy.value) as Required<NonNullable<Value>>;
    return [value, true];
  } catch (error) {
    console.warn(error, proxy.value);
    return [getDefaults(schema) as Required<NonNullable<Value>>, false];
  }
};
