import {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react-thin';
import { EventModel } from '@bryntum/schedulerpro-thin';
import { DateTime, Duration } from 'luxon';
import { JobTask, Nullable, User } from 'lib/types';
import { generateId } from 'lib/utils';
import { useJobContext } from '../../../../sections/ClientJob/context';
import { useSchedulerActionObserver, useSchedulerContext } from '../../hooks';
import {
  PreparedTimesheetDuty,
  SchedulerAction,
  SchedulerDuty,
  SchedulerResourceType,
  Update,
  UpdateType,
} from '../../types';
import { SchedulerContentContext } from './context';
import { Drag } from './drag';
import {
  OnAfterEventDrop,
  OnBeforeEventDelete,
  OnBeforeResize,
  OnBeforeTaskEdit,
  OnEventDelete,
  OnEventResizeEnd,
  OnVisibleDateChangeRangeChange,
} from './types';

export const useSchedulerContent = (
  resourceType: SchedulerResourceType,
  getDefaultDuration: () => Duration,
) => {
  const { ref, project, actions, updates } = useSchedulerContext();

  const [origin] = useState(generateId);

  useSchedulerContentDrag(ref, getDefaultDuration, updates.handle);

  useSchedulerActionObserver(async (request) => {
    if (request.origin === origin) return;
    if (ref.current) {
      const instance = ref.current.instance;
      switch (request.type) {
        case SchedulerAction.SetControlDateTime:
          await instance.scrollToDate(request.dateTime.toJSDate(), {
            block: 'start',
          });
          console.debug(`Control date time set to ${request.dateTime}`);
          break;
        case SchedulerAction.ShiftTimeAxis:
          instance.shift(request.delta, request.unit);
          console.debug(
            `Time axis shifted by ${request.delta} ${request.unit}`,
          );
          break;
        case SchedulerAction.HighlightEvent:
          const event = instance.eventStore.getById(
            request.id,
          ) as SchedulerDuty;
          if (event) {
            await instance.scrollEventIntoView(event, {
              block: 'start',
            });
            event.isHighlighted = true;
            setTimeout(() => {
              event.isHighlighted = false;
            }, 1000);
          }
          break;
        case SchedulerAction.ScrollToDateTime:
          await instance.scrollToDate(
            request.dateTime.toJSDate(),
            request.options,
          );
          console.debug(`Scrolled to ${request.dateTime}`);
          break;
      }
    }
  });

  const handleOnVisibleDateRangeChange =
    useCallback<OnVisibleDateChangeRangeChange>(({ new: { startDate } }) => {
      console.debug('Visible date range changed', startDate);
      actions.request({
        type: SchedulerAction.SetControlDateTime,
        description: 'Set control date time',
        dateTime: DateTime.fromJSDate(startDate),
        origin,
      });
    }, []);

  const handleOnBeforeTaskEdit = useCallback<OnBeforeTaskEdit>(async () => {
    return false;
  }, []);

  const handleOnAfterEventDropUser = useCallback(
    (event: SchedulerDuty) => {
      if (!event.timesheetDuty) return;

      const userPrevious = event.timesheetDuty.user;
      const user =
        event.resource.type === SchedulerResourceType.User
          ? (event.resource.getData('user') as User)
          : null;

      const dateTimeStart = DateTime.fromJSDate(event.startDate as Date);
      const dateTimeEnd = DateTime.fromJSDate(event.endDate as Date);

      const update: Update = {
        type:
          user?.id === userPrevious?.id
            ? UpdateType.Update
            : UpdateType.UpdateTransfer,
        duty: event.timesheetDuty,
        diff: {
          id: event.timesheetDuty.id,
          dateTimeStart,
          dateTimeEnd,
          userPrevious: event.timesheetDuty.user,
          user,
        },
      };

      updates.handle(event.timesheetDuty, update);
    },
    [updates],
  );

  const handleOnAfterEventDropSubcontractor = useCallback(() => {}, []);

  const handleOnAfterEventDrop = useCallback<OnAfterEventDrop>(
    ({ eventRecords }) => {
      for (const event of eventRecords) {
        switch (event.resource.type) {
          case SchedulerResourceType.User:
            handleOnAfterEventDropUser(event);
            break;
          case SchedulerResourceType.Subcontractor:
            handleOnAfterEventDropSubcontractor();
            break;
          default:
            throw new Error('Invalid resource type');
        }
      }
    },
    [handleOnAfterEventDropUser],
  );

  const handleOnBeforeEventDelete =
    useCallback<OnBeforeEventDelete>(async () => {
      return false;
    }, []);

  const handleOnEventDelete = useCallback<OnEventDelete>(
    ({ eventRecord }) => {
      if (!eventRecord.timesheetDuty) return;

      if (eventRecord.timesheetDuty.isNew) {
        updates.remove(eventRecord.timesheetDuty.id);
        eventRecord.remove();
        console.debug('New duty removed');
        return;
      }

      const update: Update = {
        type: UpdateType.Delete,
        duty: eventRecord.timesheetDuty,
        diff: { id: eventRecord.timesheetDuty.id },
      };

      updates.handle(eventRecord.timesheetDuty, update);

      if (eventRecord.timesheetDuty.dateTimeStart) {
        eventRecord.startDate =
          eventRecord.timesheetDuty.dateTimeStart.toJSDate();
      }

      if (eventRecord.timesheetDuty.dateTimeEnd) {
        eventRecord.endDate = eventRecord.timesheetDuty.dateTimeEnd.toJSDate();
      }

      if (eventRecord.timesheetDuty.user) {
        eventRecord.resourceId = eventRecord.timesheetDuty.user.id;
      }

      eventRecord.isDeleted = true;
    },
    [updates],
  );

  const handleOnEventRevert = useCallback(
    ({ eventRecord }: { eventRecord: SchedulerDuty }) => {
      const update = updates.get(eventRecord.id);
      updates.remove(update.diff.id);

      const event = project.eventStore.getById(update.diff.id) as EventModel;
      switch (update.type) {
        case UpdateType.Create: {
          event.remove();
          break;
        }
        case UpdateType.Update:
        case UpdateType.UpdateTransfer: {
          if (update.duty.dateTimeStart) {
            event.startDate = update.duty.dateTimeStart.toJSDate();
          }
          if (update.duty.dateTimeEnd) {
            event.endDate = update.duty.dateTimeEnd.toJSDate();
          }
          if (update.duty.user) {
            event.resourceId = update.duty.user.id;
          }
          break;
        }
        case UpdateType.Delete: {
          break;
        }
      }
    },
    [],
  );

  const handleOnBeforeEventResize = useCallback<OnBeforeResize>(
    ({ eventRecord }) => {
      return (updates.get(eventRecord.id).diff.interactions ?? []).length <= 1;
    },
    [updates],
  );

  const handleOnEventResizeEnd = useCallback<OnEventResizeEnd>(
    ({ changed, eventRecord }) => {
      if (!changed || !eventRecord.timesheetDuty) return;

      if ((updates.get(eventRecord.id).diff.interactions ?? []).length > 1) {
        console.warn('This should be an unreachable state.');
        return;
      }

      const userPrevious = eventRecord.timesheetDuty.user;
      const user =
        eventRecord.resource.type === SchedulerResourceType.User
          ? (eventRecord.resource.getData('user') as User)
          : null;

      const dateTimeStart = DateTime.fromJSDate(eventRecord.startDate as Date);
      const dateTimeEnd = DateTime.fromJSDate(eventRecord.endDate as Date);

      const update: Update = {
        type:
          user?.id === userPrevious?.id
            ? UpdateType.Update
            : UpdateType.UpdateTransfer,
        duty: eventRecord.timesheetDuty,
        diff: {
          id: eventRecord.timesheetDuty.id,
          dateTimeStart,
          dateTimeEnd,
          user,
        },
      };

      updates.handle(eventRecord.timesheetDuty, update);

      updates.set(update.diff.id, (prev) => {
        if (prev.diff.interactions) {
          return {
            ...prev,
            diff: {
              ...prev.diff,
              interactions: prev.diff.interactions[0]
                ? [
                    {
                      ...prev.diff.interactions[0],
                      duration: dateTimeEnd.diff(dateTimeStart).toMillis(),
                    },
                  ]
                : [],
            },
          };
        }

        return prev;
      });
    },
    [updates],
  );

  return {
    ref,
    onBeforeTaskEdit: handleOnBeforeTaskEdit,
    onAfterEventDrop: handleOnAfterEventDrop,
    onBeforeEventDelete: handleOnBeforeEventDelete,
    onVisibleDateRangeChange: handleOnVisibleDateRangeChange,
    onBeforeEventResize: handleOnBeforeEventResize,
    onEventResizeEnd: handleOnEventResizeEnd,
    context: {
      resourceType,
      onEventDelete: handleOnEventDelete,
      onEventRevert: handleOnEventRevert,
    },
  };
};

export const useSchedulerContentDrag = (
  schedulerRef: RefObject<BryntumSchedulerPro>,
  getDefaultDuration: () => Duration,
  onSuccess: (duty: PreparedTimesheetDuty, update: Update) => void,
) => {
  const { job } = useJobContext();

  const [isDragging, setIsDragging] = useState(false);
  const [active, setActive] = useState<Nullable<JobTask>>(null);

  const [ready, setReady] = useState(false);
  const drag = useRef<Drag>();

  useEffect(() => {
    if (job.partial || !schedulerRef.current) return;
    if (ready) return;

    const element = document.getElementById('task-drag-container');
    if (!element) return;

    drag.current = new Drag({
      getDefaultDuration,
      schedule: schedulerRef.current.instance,
      constrain: false,
      outerElement: element,
      setIsDragging,
      setActive,
      getJobTaskById: (id: string) => {
        const task = job.tasks.find((inner) => inner.id === id) as JobTask;
        return { ...task, job };
      },
      onSuccess,
    });
    console.debug('Drag instance constructed');
    setReady(true);
  }, [getDefaultDuration, schedulerRef, ready, job]);

  useEffect(() => {
    if (drag.current) {
      drag.current.getDefaultDuration = getDefaultDuration;
    }
  }, [getDefaultDuration]);

  useEffect(() => {
    return () => {
      if (drag.current) {
        drag.current.destroy();
        console.debug('Drag instance destroyed');
      }
    };
  }, []);

  return { isDragging, active };
};

export const useSchedulerContentContext = () => {
  return useContext(SchedulerContentContext);
};
