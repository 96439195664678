import React from 'react';
import { ThemeToggle } from '@/components/ui/theme';
import pkg from '../../../../../package.json';
import Logo from '../../../../theme/logo';
import { AppLoginLayoutProps } from './types';

const AppLoginLayout = ({ children, extra }: AppLoginLayoutProps) => {
  return (
    <div className="relative h-full animate-in">
      <div className="absolute left-4 top-4">
        <div className="w-8 fill-success dark:fill-white">
          <Logo fill="" />
        </div>
      </div>
      <div className="absolute right-4 top-4 z-10">
        <ThemeToggle />
      </div>
      {children}
      <div className="fixed bottom-4 left-4">
        <p className="text-xs text-gray-400">Version {pkg.version}</p>
      </div>
    </div>
  );
};

export default AppLoginLayout;
