import React, { useEffect, useRef, useState } from 'react';
import {
  HocuspocusProviderWebsocket,
  WebSocketStatus,
} from '@hocuspocus/provider';
import { toast } from 'sonner';
import { CollaborationContext } from './context';

const host = import.meta.env.PUBLIC_DOCUMENTS_API_HOST;

const CollaborationProvider = ({ children }: React.PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);
  const [websocketProvider, setWebsocketProvider] =
    useState<HocuspocusProviderWebsocket>();

  const retries = useRef(0);

  useEffect(() => {
    toast.info('Opening websocket connection...');

    const provider = new HocuspocusProviderWebsocket({
      url: host + 'multiplex',
      onOpen: () => {
        console.debug('Opened websocket');
        setIsOpen(true);
      },
      onClose: () => {
        console.debug('Closed websocket');
        setIsOpen(false);
      },
      onConnect: () => {
        retries.current = 0;
        // toast.success('Connected to websocket');
      },
      onDisconnect: () => {
        // toast.warning('Disconnected from websocket');
        console.debug('Disconnected from websocket, reconnecting...');
        if (retries.current < 5) {
          retries.current += 1;
          setTimeout(async () => {
            if (provider.status === WebSocketStatus.Disconnected) {
              console.debug(`Reconnecting... (attempt ${retries.current})`);
              await provider.connect();
            }
          }, 1000);
        }
      },
    });

    setWebsocketProvider(provider);
  }, []);

  if (!websocketProvider) return null;

  return (
    <CollaborationContext.Provider
      value={{
        websocketProviderIsOpen: isOpen,
        websocketProvider,
      }}
    >
      {children}
    </CollaborationContext.Provider>
  );
};

export default CollaborationProvider;
