import { createContext } from 'react';
import { PlatformEvent } from '../Events';
import { ListenerMatcher } from './types';

export const Context = createContext({
  register: (() => {}) as (
    id: string,
    event: ListenerMatcher,
    callback: (event: PlatformEvent) => void,
  ) => void,
  remove: (() => {}) as (id: string) => void,
});
