import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { SubcontractorDuty } from 'lib/types';
import { platformObjectInput } from 'lib/utils';
import { useJobTaskContext } from '../../../../../../../../context';
import {
  CreateSubcontractorReportMutationData,
  CreateSubcontractorReportMutationVariables,
} from './types';

export const useSubcontractorReportCreate = (duty: SubcontractorDuty) => {
  const { task } = useJobTaskContext();

  const [open, setOpen] = useState(false);

  const [create] = useMutation<
    CreateSubcontractorReportMutationData,
    CreateSubcontractorReportMutationVariables
  >(
    gql`
      mutation CreateSubcontractorReport(
        $dutyId: ID!
        $sourceObject: PlatformObjectInput!
      ) {
        createSubcontractorReport(
          subcontractorDutyId: $dutyId
          sourceObject: $sourceObject
        ) {
          subcontractorReport {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (values: any) => {
    if (!task) return;

    const { data, errors } = await create({
      variables: {
        dutyId: duty.id,
        sourceObject: platformObjectInput(values.sourceObject)!,
      },
    });

    if (data) {
      await task.refetch();
      setOpen(false);
      toast.success('Subcontractor report created');
    }

    if (errors) {
      console.error(errors);
      toast.error('Failed to create subcontractor report');
    }
  };

  return {
    state: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onCreate: handleOnCreate,
    },
  };
};
