import { useFormTemplates as usePlatformFormTemplates } from 'lib/hooks';
import { useClient } from '../../../../hooks';

export const useFormTemplates = () => {
  const client = useClient();
  const { formTemplates, isLoading } = usePlatformFormTemplates(client.slug);
  return {
    isLoading,
    isCreating: false,
    formTemplates,
    handleOnSubmit: () => {},
  };
};
