import React, { PropsWithChildren, useCallback, useState } from 'react';
import { gql } from '@apollo/client';
import usePlatformSubscription from '../../hooks/usePlatformSubscription';
import { PlatformEvent } from '../Events';
import { Context } from './context';
import { EventsSubscriptionData, Listener, ListenerMatcher } from './types';

const EventsProvider = ({ children }: PropsWithChildren) => {
  const [listeners, setListeners] = useState<Listener[]>([]);

  const handleRegister = useCallback(
    (
      id: string,
      event: ListenerMatcher,
      callback: (event: PlatformEvent) => void,
    ) => {
      setListeners((prevListeners) =>
        prevListeners.map((inner) => inner.id).includes(id)
          ? prevListeners
          : [...prevListeners, { id, event, callback }],
      );
    },
    [],
  );

  const handleRemove = useCallback((id: string) => {
    setListeners((prevListeners) =>
      prevListeners.filter((listener) => listener.id !== id),
    );
  }, []);

  usePlatformSubscription<EventsSubscriptionData>(
    gql(`
      subscription ListenForEvents {
        subscribe(scope: "events") {
          scope
          data
        }
      }  
    `),
    {
      onData: ({ data: { data } }) => {
        if (!data?.subscribe) return;

        let event: PlatformEvent;
        try {
          event = JSON.parse(data.subscribe.data);
        } catch (error) {
          console.warn('Failed to parse event data', error);
          return;
        }

        for (const listener of listeners) {
          if (event.name.search(listener.event.name) !== -1) {
            listener.callback(event);
          }
        }
      },
    },
  );

  return (
    <Context.Provider
      value={{
        register: handleRegister,
        remove: handleRemove,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default EventsProvider;
