import { gql, useQuery } from '@apollo/client';
import { useClient } from '../../../../hooks';
import {
  CommercialContractsQueryData,
  CommercialContractsQueryVariables,
} from './types';

export const useCommercialContracts = () => {
  const client = useClient();

  const { data } = useQuery<
    CommercialContractsQueryData,
    CommercialContractsQueryVariables
  >(
    gql`
      query GetCommercialContracts($clientId: ID!) {
        commercialContracts(clientId: $clientId) {
          id
          name
          slug
          description
          dateStart
          dateEnd
          rates {
            id
          }
        }
      }
    `,
    {
      variables: { clientId: client.id },
      fetchPolicy: 'no-cache',
    },
  );

  return {
    contracts: data?.commercialContracts ?? [],
  };
};
