import {
  CommercialContract,
  CommercialEstimate,
  CommercialResourceRate,
} from 'lib/types';

export type CommercialEstimateProps = {
  id: string;
};

export type CommercialEstimateQueryVariables = {
  clientId: string;
  id: string;
};

export type CommercialEstimateQueryQueryData = {
  commercialContracts: CommercialContract[];
  commercialResourceRates: CommercialResourceRate[];
  commercialEstimate: CommercialEstimate;
};

export enum ViewMode {
  Split = 'split',
  TwoColumn = 'two-column',
}
