import React from 'react';
import parse, { DOMNode, Element, domToReact } from 'html-react-parser';
import DOMPurify from 'dompurify';
import { DateTime } from 'luxon';
import { z } from 'zod';
import {
  ReportMode,
  ReportSvg,
  ReportText,
  ReportView,
  useValue,
} from 'lib/shared';
import { useReportContext } from '../../../../../../../../context';
import { FormFieldComponentProps } from '../../types';

const FieldSignature = ({ item, value }: FormFieldComponentProps) => {
  const { mode, theme } = useReportContext();

  const [{ name, dateTime: dateTimeString, svg }] = useValue(
    value,
    z.object({
      name: z.string(),
      dateTime: z.string().datetime({ offset: true }),
      svg: z.string(),
    }),
  );

  const dateTime = dateTimeString ? DateTime.fromISO(dateTimeString) : null;

  const replace = (node: DOMNode) => {
    if (!(node instanceof Element)) {
      return <></>;
    }
    if (node.name === 'svg') {
      return (
        <ReportSvg
          {...(node.attribs as { width: string; height: string })}
          style={{ width: 200, height: 100 }}
        >
          {domToReact(node.children, { replace })}
        </ReportSvg>
      );
    }
    if (node.name === 'path') {
      return (
        <ReportSvg.Path
          {...node.attribs}
          d={node.attribs.d}
          fill={theme.colors.signature}
          stroke={theme.colors.signature}
        />
      );
    }
    if (node.name === 'rect') {
      return (
        <ReportSvg.Rect
          {...(node.attribs as {
            width: string;
            height: string;
          })}
          fill={theme.colors.signatureBackground}
        />
      );
    }
    if (node.name === 'g') {
      return (
        <ReportSvg.G {...node.attribs}>
          {domToReact(node.children, { replace })}
        </ReportSvg.G>
      );
    }
    return node;
  };

  const safe = DOMPurify.sanitize(svg);
  const html = parse(safe, { replace });

  return (
    <ReportView style={{ paddingLeft: 32, paddingRight: 32 }} wrap={false}>
      <ReportView style={{ paddingBottom: 8 }}>
        <ReportText style={{ fontSize: 14, fontWeight: 600 }}>
          {item.data.value}
        </ReportText>
      </ReportView>
      <ReportView
        style={{
          display: 'flex',
          flexDirection: 'row',
          borderTopWidth: 1,
          borderTopStyle: 'solid',
          borderTopColor: theme.colors.border,
        }}
      >
        <ReportView
          style={{
            flexBasis: '33%',
            paddingTop: 12,
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: theme.colors.border,
          }}
        >
          <ReportText
            style={{
              fontSize: 11,
              fontWeight: 600,
              marginBottom: 4,
              color: theme.colors.subtitle,
            }}
          >
            Name
          </ReportText>
          <ReportText
            style={{ fontWeight: 600, fontSize: 12, lineHeight: '1.5' }}
          >
            {name}
          </ReportText>
        </ReportView>
        <ReportView
          style={{
            flexBasis: '33%',
            padding: 12,
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: theme.colors.border,
          }}
        >
          <ReportText
            style={{
              fontSize: 11,
              fontWeight: 600,
              marginBottom: 4,
              color: theme.colors.subtitle,
            }}
          >
            Date
          </ReportText>
          <ReportText style={{ fontSize: 12, fontWeight: 600 }}>
            {dateTime?.toLocaleString(DateTime.DATE_FULL)}
          </ReportText>
        </ReportView>
        <ReportView style={{ flexBasis: '33%', padding: 12 }}>
          <ReportText
            style={{
              fontSize: 11,
              fontWeight: 600,
              color: theme.colors.subtitle,
            }}
          >
            Signature
          </ReportText>
          <ReportView>{html}</ReportView>
        </ReportView>
      </ReportView>
    </ReportView>
  );
};

export default FieldSignature;
