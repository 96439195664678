import React from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { z } from 'zod';
import { useContractCopy } from './hooks';

const ContractCopy = () => {
  const { dialog, onCopy } = useContractCopy();
  return (
    <Dialog open={dialog.open} onOpenChange={dialog.onOpenChange}>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm">
          Copy to new
        </Button>
      </DialogTrigger>
      <DialogContent overlay>
        <DialogHeader>
          <DialogTitle>Copy Contract</DialogTitle>
          <DialogDescription>
            Copy this contract to another period
          </DialogDescription>
        </DialogHeader>
        <FormRenderer
          fields={[
            {
              type: 'date',
              name: 'dateStart',
              label: 'Start',
              schema: z.any(),
            },
            {
              type: 'date',
              name: 'dateEnd',
              label: 'End',
              schema: z.any(),
            },
          ]}
          onSubmit={onCopy}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ContractCopy;
