import { createContext, useContext } from 'react';
import { useActions } from './hooks';

export const ActionsContext = createContext(
  {} as ReturnType<typeof useActions>['context'],
);

export const useActionsContext = () => {
  return useContext(ActionsContext);
};
