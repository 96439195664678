import { gql, useQuery } from '@apollo/client';
import { toast } from 'sonner';
import { SubcontractorsQueryData } from './types';

export const useSupplyChainSubcontractors = () => {
  const { data, refetch } = useQuery<SubcontractorsQueryData>(
    gql`
      query GetSubcontractors {
        subcontractors {
          id
          name
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      onError: () => {
        toast.error('Failed to fetch subcontractors');
      },
    },
  );

  return {
    context: {
      subcontractors: data?.subcontractors || [],
      refetch,
    },
  };
};
