import { useState } from 'react';

export const useEstimatePrelims = () => {
  const [open, setOpen] = useState(false);

  return {
    state: {
      open,
      onToggle: () => setOpen((prev) => !prev),
    },
  };
};
