import produce from 'immer';
import { create } from 'zustand';
import { InitialQueryData } from './types';

export type RostersValues = {
  canAccessRosterTeams: boolean;
  canAccessRosterReviews: boolean;
};

export type RostersActions = {
  initialQueryOnCompleted: (data: InitialQueryData) => void;
};

export type RostersState = RostersValues & RostersActions;

export const initialValues: RostersValues = {
  canAccessRosterTeams: false,
  canAccessRosterReviews: false,
};

export const useStore = create<RostersState>()((set) => ({
  ...initialValues,

  initialQueryOnCompleted: ({
    user: { canAccessRosterTeams, canAccessRosterReviews },
  }) =>
    set(
      produce<RostersState>((state) => {
        state.canAccessRosterTeams = true;
        state.canAccessRosterReviews = true;
      }),
    ),
}));
