import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Download } from 'lucide-react';
import { Button, ButtonProps } from '@/components/ui/button';
import { platformClient } from '../../app/platform';

const DownloadButton = ({
  id,
  ...props
}: { id: string | undefined } & Omit<
  ButtonProps,
  'isLoading' | 'disabled' | 'onClick' | 'children'
>) => {
  const { data, isLoading } = useQuery({
    enabled: !!id,
    queryKey: ['downloads', id],
    queryFn: platformClient.getObject(id as string),
  });
  return (
    <Button
      {...props}
      isLoading={isLoading}
      disabled={isLoading}
      onClick={() => {
        if (!data?.getUrl) return;
        const a = document.createElement('a');
        a.href = data.getUrl;
        a.download = data.name;
        a.target = '_blank';
        a.click();
      }}
    >
      <Download className="mr-2 size-4" />
      Download
    </Button>
  );
};

export default DownloadButton;
