import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import {
  CommercialContractRateQualification,
  TrainingQualification,
} from 'lib/types';
import { useCommercialContractContext } from '../../../../context';
import { useContractRateContext } from '../../context';
import {
  CreateRateQualificationMutationData,
  CreateRateQualificationMutationVariables,
  DeleteRateQualificationMutationData,
  DeleteRateQualificationMutationVariables,
  UpdateRateQualificationMutationData,
  UpdateRateQualificationMutationVariables,
} from './types';

export const useRateQualifications = () => {
  const { contract } = useCommercialContractContext();
  const { rate } = useContractRateContext();

  const [create] = useMutation<
    CreateRateQualificationMutationData,
    CreateRateQualificationMutationVariables
  >(
    gql`
      mutation CreateCommercialContractRateQualification(
        $rate: ID!
        $qualification: ID!
        $priority: Int!
      ) {
        createCommercialContractRateQualification(
          rateId: $rate
          qualificationId: $qualification
          priority: $priority
        ) {
          commercialContractRateQualification {
            id
            priority
            qualification {
              id
              color
              logo {
                id
              }
              name
              description
            }
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = (qualification: TrainingQualification) => {
    return async () => {
      if (!rate || !contract) return;

      const { data, errors } = await create({
        variables: {
          rate: rate.id,
          qualification: qualification.id,
          priority: 100,
        },
      });

      if (data) {
        await rate.refetch();
        await contract.refetch();
        toast.success('Qualification added');
      }

      if (errors) {
        toast.error('Failed to add qualification');
      }
    };
  };

  const [update] = useMutation<
    UpdateRateQualificationMutationData,
    UpdateRateQualificationMutationVariables
  >(gql`
    mutation UpdateCommercialContractRateQualification(
      $id: ID!
      $priority: Int!
    ) {
      updateCommercialContractRateQualification(
        commercialContractRateQualificationId: $id
        priority: $priority
      ) {
        commercialContractRateQualification {
          id
        }
      }
    }
  `);

  const handleOnUpdate = async () => {};

  const [delete_] = useMutation<
    DeleteRateQualificationMutationData,
    DeleteRateQualificationMutationVariables
  >(gql`
    mutation DeleteCommercialContractRateQualification($id: ID!) {
      deleteCommercialContractRateQualification(
        commercialContractRateQualificationId: $id
      ) {
        deleted
      }
    }
  `);

  const handleOnDelete = (
    qualification: CommercialContractRateQualification,
  ) => {
    return async () => {
      if (!rate || !contract) return;

      const { data, errors } = await delete_({
        variables: { id: qualification.id },
      });

      if (data) {
        await rate.refetch();
        await contract.refetch();
        toast.success('Qualification deleted');
      }

      if (errors) {
        toast.error('Failed to delete qualification');
      }
    };
  };

  return {
    context: {
      handlers: {
        onCreate: handleOnCreate,
        onUpdate: handleOnUpdate,
        onDelete: handleOnDelete,
      },
    },
  };
};
