import React from 'react';
import chroma from 'chroma-js';
import { TodoUrgencyProps } from './types';

const TodoUrgency = ({ urgency }: TodoUrgencyProps) => {
  const color = chroma.mix('green', 'red', urgency);

  const percentLow = Math.max(0, Math.min(1 / 3, urgency)) / (1 / 3);
  const percentMedium =
    (Math.max(1 / 3, Math.min(2 / 3, urgency)) - 1 / 3) / (1 / 3);
  const percentHigh = (Math.max(2 / 3, Math.min(1, urgency)) - 2 / 3) / (1 / 3);

  return (
    <div className="grid h-2 w-full grid-cols-3 gap-x-[2px] overflow-hidden rounded bg-secondary">
      <div className="relative col-span-1 h-full">
        <div
          className="absolute inset-y-0 rounded-l"
          style={{
            width: `${percentLow * 100}%`,
            backgroundColor: color.hex(),
          }}
        />
      </div>
      <div className="relative col-span-1 h-full">
        <div
          className="absolute inset-y-0"
          style={{
            width: `${percentMedium * 100}%`,
            backgroundColor: color.hex(),
          }}
        />
      </div>
      <div className="relative col-span-1 h-full">
        <div
          className="absolute inset-y-0 rounded-r"
          style={{
            width: `${percentHigh * 100}%`,
            backgroundColor: color.hex(),
          }}
        />
      </div>
    </div>
  );
};

export default TodoUrgency;
