import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FormItemType } from 'external/types';
import { Signature } from 'lib/shared';
import { useFormContext } from '../../context';
import FormContainer from '../FormContainer';
import FormContainerRepeatingBranch from '../FormContainerRepeatingBranch';
import FormContainerRepeatingStub from '../FormContainerRepeatingStub';
import FormField from '../FormField';
import { FormPageProps } from './types';

const FormPageRouter = ({
  item,
  index,
  items,
}: {
  item: any;
  index: number;
  items: any[];
}) => {
  const isLast = index === items.length - 1;
  switch (item.type) {
    case FormItemType.BLOCK:
      return <FormField key={item.path} item={item} isLast={isLast} />;
    case FormItemType.CONTAINER:
      return <FormContainer key={item.path} item={item} />;
    case FormItemType.CONTAINER_REPEATING_BRANCH:
      return <FormContainerRepeatingBranch key={item.path} item={item} />;
    case FormItemType.CONTAINER_REPEATING_BRANCH_STUB:
      return <FormContainerRepeatingStub key={item.path} item={item} />;
    default:
      return null;
  }
};

const FormPage = ({ page, isLast = false }: FormPageProps) => {
  const { meta } = useFormContext();
  return (
    <ErrorBoundary
      fallback={<div className="p-4">Error loading page {page.id}</div>}
      onError={() => {
        console.error('Error loading page', { page });
      }}
    >
      <div className="flex flex-col">
        {page.items.map((item, index, items) => (
          <FormPageRouter
            key={item.path}
            item={item}
            index={index}
            items={items}
          />
        ))}
        {isLast && meta.signature && (
          <div className="border-t p-4">
            <p className="text-sm font-semibold">Signed</p>
            <Signature signature={meta.signature} />
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default FormPage;
