import React, { useEffect, useMemo, useState } from 'react';
import { X } from 'lucide-react';
import Map, { useMap } from 'react-map-gl';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useMapStyle } from 'lib/hooks';
import { useActivityMapContext } from '../../context';
import MiniMapOffice from './components/MiniMapOffice';
import MiniMapYard from './components/MiniMapYard';
import { MiniMapPosition } from './types';

const MiniMap = ({ className }: { className?: string }) => {
  const { miniMapPosition } = useActivityMapContext();

  const { main, mini } = useMap();
  const mapStyle = useMapStyle();

  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (!main || !mini || !open) return;

    mini.setCenter(main.getCenter());

    const handle = () => {
      mini.setCenter(main.getCenter());
    };

    main.on('move', handle);

    return () => {
      main.off('move', handle);
    };
  }, [main, mini, open]);

  const positionClassName = useMemo(() => {
    if (miniMapPosition === MiniMapPosition.BottomLeft) {
      return 'bottom-4 left-4';
    }

    if (miniMapPosition === MiniMapPosition.TopRight) {
      return 'top-4 right-4';
    }

    throw new Error(`Invalid mini map position: ${miniMapPosition}`);
  }, [miniMapPosition]);

  if (!open) {
    return (
      <div className={cn('absolute z-10', positionClassName)}>
        <Button className="p-0" variant="link" onClick={() => setOpen(true)}>
          <div className="h-12 w-12 rounded-full border border-accent-foreground bg-accent-foreground/25" />
        </Button>
      </div>
    );
  }

  return (
    <div
      className={cn(
        'absolute z-10 h-1/5 w-1/5 rounded-lg bg-background p-1 shadow-lg ring-2',
        positionClassName,
        className,
      )}
    >
      <div className="absolute right-2 top-2 z-10">
        <Button
          className="size-6 rounded-lg p-0"
          variant="ghost"
          onClick={() => setOpen(false)}
        >
          <X className="h-4 w-4" />
        </Button>
      </div>
      <div className="h-full w-full overflow-hidden rounded-md">
        <Map
          id="mini"
          style={{
            width: '100%',
            height: '100%',
          }}
          initialViewState={{
            zoom: 5,
            latitude: 51.4574,
            longitude: 0.1278,
          }}
          mapboxAccessToken="pk.eyJ1IjoiZW5lcmd5ZW5hYmxlcyIsImEiOiJjbGpia2NqZ2IxcTcxM2pxeGxvNG5ud2lpIn0.gMXRc_xES-6AlQx1w8MuXw"
          attributionControl={false}
          mapStyle={mapStyle}
        >
          <MiniMapOffice
            name="Head Office"
            drivers={[]}
            longitude={0.07505702683621203}
            latitude={51.43675904815511}
            anchor="left"
          />
          <MiniMapOffice
            name="Basingstoke Office"
            drivers={[]}
            longitude={-1.0890495885635525}
            latitude={51.27167748819066}
            anchor="right"
          />
          <MiniMapYard
            name="Swanley Yard"
            drivers={[]}
            longitude={0.1626716644235529}
            latitude={51.39023030725496}
            anchor="left"
          />
        </Map>
      </div>
    </div>
  );
};

export default MiniMap;
