import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useCommercialActivityContext } from '../../../CommercialActivity/context';
import { useCommercialEstimatesContext } from '../../context';

export const useEstimatesCopy = () => {
  const { activity } = useCommercialActivityContext();
  const { state } = useCommercialEstimatesContext();

  const [open, setOpen] = useState(false);

  const [copy] = useMutation(
    gql`
      mutation CopyEstimates($id: ID!, $name: String) {
        copyCommercialEstimate(
          commercialEstimateId: $id
          commercialEstimateName: $name
        ) {
          commercialEstimate {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnSubmit = async (values: any) => {
    if (!state.selected) {
      console.error('No estimate selected');
      return;
    }

    const { data, errors } = await copy({
      variables: {
        id: state.selected,
        name: values.name,
      },
    });

    if (data) {
      await activity.refetch();
      setOpen(false);
      toast.success('Estimate copied');
    }

    if (errors) {
      console.error(errors);
      toast.error('Error copying estimate');
    }
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onSubmit: handleOnSubmit,
    },
  };
};
