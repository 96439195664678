import React from 'react';
import { Button } from '@/components/ui/button';
import { TableRenderer } from '@/components/ui/table/table/Table';
import { DateTime } from 'luxon';
import { PdfSheet } from 'lib/shared';
import MembershipsCreate from './components/MembershipsCreate';
import MembershipsEditor from './components/MembershipsEditor';
import { CompanyMembershipsContext } from './context';
import { useCompanyMemberships } from './hooks';

const CompanyMemberships = () => {
  const { context } = useCompanyMemberships();
  return (
    <CompanyMembershipsContext.Provider value={context}>
      <div className="flex min-h-0 grow flex-col">
        <div className="flex h-[52px] flex-shrink-0 items-center justify-between border-b px-4 py-2">
          <h1 className="text-xl font-bold">Memberships</h1>
        </div>
        <div className="min-h-0 grow px-4">
          <TableRenderer
            filter="name"
            initialSorting={[{ id: 'name', desc: false }]}
            columns={[
              {
                id: 'name',
                header: 'Name',
                accessorKey: 'name',
                cell: ({ row }) => {
                  return <MembershipsEditor membership={row.original} />;
                },
              },
              {
                id: 'description',
                header: 'Description',
                accessorKey: 'description',
                cell: ({ row }) => {
                  if (!row.original.description) {
                    return <p className="italic dark:text-gray-400">Not set</p>;
                  }
                  return (
                    <p className="line-clamp-1 text-xs">
                      {row.original.description}
                    </p>
                  );
                },
              },
              {
                id: 'dateTimeExpiry',
                header: 'Expiry Date',
                accessorKey: 'dateTimeExpiry',
                cell: ({ row }) => {
                  if (!row.original.dateTimeExpiry) {
                    return <p className="italic dark:text-gray-400">Not set</p>;
                  }
                  return (
                    <p className="line-clamp-1">
                      {DateTime.fromISO(
                        row.original.dateTimeExpiry,
                      ).toLocaleString(DateTime.DATE_MED)}
                    </p>
                  );
                },
              },
              {
                id: 'document',
                header: 'Document',
                accessorKey: 'document',
                cell: ({ row }) => {
                  if (!row.original.document) {
                    return <p className="italic dark:text-gray-400">None</p>;
                  }
                  return (
                    <PdfSheet objectId={row.original.document.id}>
                      <Button size="table" variant="table">
                        View
                      </Button>
                    </PdfSheet>
                  );
                },
              },
            ]}
            data={context.memberships}
            topRight={({ columnsSelector }) => (
              <div className="ml-auto flex gap-x-2">
                <MembershipsCreate />
                {columnsSelector}
              </div>
            )}
          />
        </div>
      </div>
    </CompanyMembershipsContext.Provider>
  );
};

export default CompanyMemberships;
