import { createContext, useContext } from 'react';
import { useJobReportVersion } from './hooks';

export const JobReportVersionContext = createContext(
  {} as ReturnType<typeof useJobReportVersion>['context'],
);

export const useJobReportVersionContext = () => {
  return useContext(JobReportVersionContext);
};
