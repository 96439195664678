import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useTrainingQualificationsContext } from '../../../TrainingQualifications/context';

export const useQualificationCreator = () => {
  const navigate = useNavigate();
  const { refetch } = useTrainingQualificationsContext();

  const [create] = useMutation(
    gql`
      mutation CreateTrainingQualification($data: TrainingQualificationInput!) {
        createTrainingQualification(trainingQualificationData: $data) {
          trainingQualification {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (values: any) => {
    const { data, errors } = await create({
      variables: {
        data: {
          ...values,
          data: values.data || {},
          fields: values.fields || [],
        },
      },
    });

    if (data) {
      await refetch();
      const qualification =
        data.createTrainingQualification.trainingQualification;
      navigate(`/hr/training/qualifications/${qualification.id}`);
      toast.success('Qualification created successfully');
    }

    if (errors) {
      toast.error('Failed to create qualification');
    }
  };

  const handleOnClose = () => {
    navigate('/hr/training/qualifications');
  };

  return {
    handlers: {
      onCreate: handleOnCreate,
      onClose: handleOnClose,
    },
  };
};
