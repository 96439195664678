import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Badge } from '@/components/ui/badge';
import chroma from 'chroma-js';
import { Job } from 'lib/types';
import TableReference from './components/TableReference';

export const columns: ColumnDef<Job>[] = [
  {
    id: 'reference',
    header: 'Reference',
    accessorKey: 'reference',
    sortingFn: 'alphanumeric',
    meta: { span: 1, button: true },
    cell: TableReference,
  },
  {
    id: 'description',
    header: 'Description',
    accessorKey: 'description',
    meta: { span: 4 },
    cell: ({ row }) => {
      return (
        <p className="line-clamp-1 text-xs dark:text-gray-300">
          {row.original.description}
        </p>
      );
    },
  },
  {
    id: 'tags',
    header: 'Tags',
    accessorKey: 'tags',
    meta: { span: 2 },
    cell: ({ row }) => {
      return (
        <div>
          {row.original.tags.map((tag) => (
            <Badge
              key={tag.id}
              className="h-6 rounded-sm px-2 text-[11px] font-semibold"
              variant="outline"
              style={{
                color: chroma(tag.color).css(),
                backgroundColor: chroma(tag.color).alpha(0.1).css(),
                borderColor: chroma(tag.color).css(),
              }}
            >
              {tag.label}
            </Badge>
          ))}
        </div>
      );
    },
  },
  {
    id: 'rest',
    header: '',
    meta: { span: 5 },
  },
];
