import React from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { AsyncContext } from 'lib/shared';
import { usePrelimsConvertToExplicit } from './hooks';

const PrelimsConvertToExplicit = () => {
  const { dialog, handlers } = usePrelimsConvertToExplicit();
  return (
    <Dialog open={dialog.open} onOpenChange={dialog.onOpenChange}>
      <DialogTrigger asChild>
        <Button className="h-8 p-0 text-xs" variant="link">
          Convert to explicit prelim
        </Button>
      </DialogTrigger>
      <DialogContent overlay>
        <DialogHeader>
          <DialogTitle>Convert to explicit prelim</DialogTitle>
          <DialogDescription>
            This will remove the contract prelim factor from all cost lines in
            this estimate and enable you to explicitly estimate prelims for this
            job.
          </DialogDescription>
          <p className="text-sm text-red-500">
            Caution: any manually spread prelims will be cleared, this action
            cannot be reversed.
          </p>
        </DialogHeader>
        <AsyncContext handle={handlers.onConvert}>
          {({ loading, handle }) => (
            <div className="flex justify-between">
              {loading ? (
                <div />
              ) : (
                <DialogClose asChild>
                  <Button size="sm" variant="outline">
                    Cancel
                  </Button>
                </DialogClose>
              )}
              <Button size="sm" variant="default" onClick={handle}>
                Convert to explicit prelim
              </Button>
            </div>
          )}
        </AsyncContext>
      </DialogContent>
    </Dialog>
  );
};

export default PrelimsConvertToExplicit;
