import { EventModel } from '@bryntum/schedulerpro-thin';
import { Duration } from 'luxon';
import { generateId } from 'lib/utils';
import { useSchedulerContext } from '../../../../hooks';
import { Update, UpdateDiffInteraction } from '../../../../types';

export const usePendingInteractions = (id: string) => {
  const { updates, project } = useSchedulerContext();

  const handleOnCreate = () => {
    updates.set(id, (prev) => ({
      ...prev,
      diff: {
        ...prev.diff,
        interactions: [
          ...(prev.diff.interactions || []),
          {
            id: generateId(),
            rate: undefined,
            duration: undefined,
          },
        ],
      },
    }));
  };

  const handleOnUpdate = <Field extends keyof UpdateDiffInteraction>(
    interactionId: string,
    field: Field,
    value: UpdateDiffInteraction[Field],
  ) => {
    updates.set(id, (prev) =>
      handlePrepareUpdate(
        prev,
        (prev.diff.interactions || []).map((interaction) =>
          interaction.id === interactionId
            ? { ...interaction, [field]: value }
            : interaction,
        ),
      ),
    );
  };

  const handleOnDelete = (interactionId: string) => {
    return () => {
      updates.set(id, (prev) =>
        handlePrepareUpdate(
          prev,
          (prev.diff.interactions || []).filter(
            (interaction) => interaction.id !== interactionId,
          ),
        ),
      );
    };
  };

  const handlePrepareUpdate = (
    prev: Update,
    interactions: UpdateDiffInteraction[],
  ) => {
    const duration = interactions.reduce((acc, ret) => {
      return acc.plus(Duration.fromMillis(ret.duration ?? 0));
    }, Duration.fromMillis(0));

    const dateTimeEnd = prev.diff.dateTimeStart
      ? prev.diff.dateTimeStart.plus(duration)
      : null;

    if (dateTimeEnd) {
      if (dateTimeEnd) {
        (project.eventStore.getById(prev.duty.id) as EventModel).endDate =
          dateTimeEnd?.toJSDate();

        return {
          ...prev,
          duty: {
            ...prev.duty,
            duration: duration.toMillis(),
            dateTimeEnd,
          },
          diff: {
            ...prev.diff,
            dateTimeEnd,
            interactions,
          },
        };
      }
    }

    return prev;
  };

  return {
    handlers: {
      onCreate: handleOnCreate,
      onUpdate: handleOnUpdate,
      onDelete: handleOnDelete,
    },
  };
};
