import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { DateTime } from 'luxon';
import { PdfViewer } from 'lib/shared';
import SubcontractorReportActions from '../SubcontractorReportActions';
import { SubcontractorReportContext } from './context';
import { useSubcontractorReport } from './hooks';
import { SubcontractorReportProps } from './types';

const SubcontractorReport = ({ active, onClear }: SubcontractorReportProps) => {
  const { context } = useSubcontractorReport(active.report);

  if (!active.duty || !active.report) {
    return null;
  }

  return (
    <SubcontractorReportContext.Provider value={context}>
      <div className="grid h-[61px] w-full flex-shrink-0 grid-cols-12 items-center gap-x-2 border-b px-4">
        <div className="col-span-2">
          <Button
            className=" pl-2 pr-3"
            variant="secondary"
            size="sm"
            onClick={onClear}
          >
            <ArrowLeft className="mr-2 size-4" />
            Back
          </Button>
        </div>
        <h1 className="col-span-8 flex justify-center text-xl font-bold">
          {active.duty.subcontractor.name}:{' '}
          {DateTime.fromISO(active.duty.dateTimeStart).toLocaleString(
            DateTime.DATE_MED,
          )}{' '}
          Duty
        </h1>
        <div className="col-span-2 flex justify-end">
          <SubcontractorReportActions />
        </div>
      </div>
      <div className="col-span-4 flex h-full min-h-0 flex-col">
        <div className="min-h-0 grow">
          <PdfViewer
            flashbangWarning={false}
            objectId={active.report.sourceObject.id}
          />
        </div>
      </div>
    </SubcontractorReportContext.Provider>
  );
};

export default SubcontractorReport;
