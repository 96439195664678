import React from 'react';
import { Actions } from 'external';
import { useUser } from 'lib/hooks';
import { useClient } from '../../../../../Client/hooks';
import { useJobContext } from '../../../../context';

const OverviewActions = () => {
  const user = useUser();
  const client = useClient();

  const { job } = useJobContext();

  if (job.partial || !job.actionSetId) return null;

  return (
    <Actions
      queryKey={[job.id, 'overview']}
      actionSetId={job.actionSetId}
      scope={`jobs::job::${job.id}`}
      context={{
        event: {
          data: {
            user,
            client,
            instantiate: false,
            job: {
              id: job.id,
              reference: job.reference,
              canCreateTask: job.canCreateTask,
              requiresStartConfirmation: true,
            },
          },
        },
      }}
    />
  );
};

export default OverviewActions;
