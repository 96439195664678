import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useCommercialContractContext } from '../../../../context';
import { useContractRateContext } from '../../context';
import {
  UpdateCommercialContractRateMutationData,
  UpdateCommercialContractRateMutationVariables,
} from './types';

export const useRateUnitValue = () => {
  const { contract } = useCommercialContractContext();
  const { rate } = useContractRateContext();

  const [open, setOpen] = useState(false);

  const [update] = useMutation<
    UpdateCommercialContractRateMutationData,
    UpdateCommercialContractRateMutationVariables
  >(
    gql`
      mutation UpdateContractRate($id: ID!, $value: JSONScalarInput!) {
        updateCommercialContractRate(
          commercialContractRateId: $id
          field: "unit_value"
          value: $value
        ) {
          commercialContractRate {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnSubmit = async (values: any) => {
    if (!rate || !contract) return;

    const { data, errors } = await update({
      variables: {
        id: rate.id,
        value: values.unitValue,
      },
    });

    if (data) {
      await rate.refetch();
      await contract.refetch();
      setOpen(false);
      toast.success('Unit value updated');
    }

    if (errors) {
      console.error(errors);
      toast.error('Failed to update unit value');
    }
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onSubmit: handleOnSubmit,
    },
  };
};
