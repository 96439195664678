import { FC } from 'react';
import { PlatformMessage } from 'lib/types';
import { PlatformEvent } from '../Events';

export type MessageGroupProps = {
  children?: FC<{
    feed: FeedItem[];
    onSend: (
      value: any,
      valueHtml: string,
      valueString: string,
      meta?: any,
    ) => Promise<void>;
  }>;
  scope: string;
  id: string;
};

export enum FeedItemType {
  Message = 'MESSAGE',
  Event = 'EVENT',
}

export type FeedMessage = PlatformMessage & { type: FeedItemType.Message };
export type FeedEvent = PlatformEvent & { type: FeedItemType.Event };
export type FeedItem = FeedMessage | FeedEvent;
