import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type Apply<Values> = (getNewState: (state: Values) => Values | void) => void;

export type BaseValues = Record<string, any>;

export type BaseActions<Values> = {
  apply: Apply<Values>;
  reset: () => void;
};

const createSectionState = <
  Values extends BaseValues,
  Actions extends BaseActions<Values> = BaseActions<Values>,
>(
  initialValues: Values,
  actions: Omit<BaseActions<Actions>, 'apply' | 'reset'> = {},
) => {
  return create<Values & Actions>()(
    immer(
      (set) =>
        ({
          ...actions,
          ...initialValues,
          reset: () => set(() => initialValues as Values & Actions),
          // For now, we use `as any` to turn off the type checker
          // when applying the `getNewState` method. Confusingly,
          // the state also contains the actions, this makes typing
          // a little tricky as we don't really want consumers of
          // this store adding new actions dynamically.
          apply: (getNewState) => set(getNewState as any),
        }) as Values & Actions,
    ),
  );
};

export type SectionState<Values extends BaseValues> = ReturnType<
  typeof createSectionState<Values>
>;

export default createSectionState;
