import React from 'react';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { TextareaAutosize } from '@/components/ui/textarea';
import { useJobContext } from '../../../../context';
import { useStage } from '../../hooks';
import { STAGE_DESCRIPTION } from '../../stages';
import { InstantiationStageIssues } from '../InstantiationIssues';

const InstantiationDescription = () => {
  const { job } = useJobContext();
  const stage = useStage(STAGE_DESCRIPTION);

  if (job.partial) return null;

  return (
    <AccordionItem value={STAGE_DESCRIPTION} asChild>
      <div className="border-b p-4 pb-5">
        <AccordionTrigger className="mb-4 p-0 text-start">
          <div className="flex gap-x-4">
            <div className="flex rounded-lg border bg-secondary">
              <p className="border-r px-2 py-1.5 text-xs font-semibold uppercase text-gray-500 dark:text-gray-400">
                Category
              </p>
              <p className="px-2 py-1 text-sm font-semibold">
                {job.category?.name}
                {job.category?.name !== job.category?.code && (
                  <span className="ml-1 text-gray-500 dark:text-gray-400">
                    {' '}
                    [{job.category?.code || <em>Not set</em>}]
                  </span>
                )}
              </p>
            </div>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="rounded-lg border bg-secondary p-2">
            <TextareaAutosize
              className="bg-background p-2 text-[13px]"
              value={job.description}
            />
            {/*<div className="mt-2">*/}
            {/*  <Button variant="outline" size="xs">*/}
            {/*    Rewrite*/}
            {/*  </Button>*/}
            {/*</div>*/}
          </div>
        </AccordionContent>
        <InstantiationStageIssues
          stage={stage}
          types={[
            {
              value: 'information-missing',
              label: 'Missing information',
              color: 'red',
            },
          ]}
        />
      </div>
    </AccordionItem>
  );
};

export default InstantiationDescription;
