import { Dispatch, SetStateAction, createContext } from 'react';
import useCallbackRef from '../../hooks/useCallbackRef';

export const Context = createContext({
  windowed: false,
  headerRef: null as ReturnType<typeof useCallbackRef> | null,
  path: '/',
  patterns: [] as readonly string[],
  setPath: (() => {}) as Dispatch<SetStateAction<string>>,
  close: (() => {}) as () => void,
  registerPatterns: (() => {}) as (patterns: string[]) => void,
});
