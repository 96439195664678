import { useSubcontractorContext } from '../../context';
import LocationsCreate from './components/LocationsCreate';
import LocationsTable from './components/LocationsTable';

const SubcontractorLocations = () => {
  const { subcontractor } = useSubcontractorContext();

  if (!subcontractor?.locations) return null;

  return (
    <div
      className="overflow-hidden rounded-lg border"
      style={{ gridArea: 'locations' }}
    >
      <div className="flex border-b bg-background-secondary p-4">
        <h3 className="flex-grow font-semibold">Locations</h3>
        <LocationsCreate />
      </div>
      <LocationsTable />
    </div>
  );
};

export default SubcontractorLocations;
