import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import {
  RendererField,
  input,
} from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { toast } from 'sonner';
import { z } from 'zod';
import { SheetForm } from 'lib/shared';
import { FormTemplate, User } from 'lib/types';
import { platformClient } from '../../../../../../app/platform';
import { useClient } from '../../../../hooks';
import { useFormTemplates } from './hooks';

const fields: RendererField[] = [
  input({
    label: 'Name',
    name: 'name',
    placeholder: 'Template name',
    schema: z.string().min(2),
  }),
];

const CreateFormTemplate = ({
  onCreated,
}: {
  onCreated: (user: User) => void;
}) => {
  const [open, setOpen] = useState(false);

  const client = useClient();
  const queryClient = useQueryClient();
  const queryKey = ['formTemplates', client.slug];

  const create = useMutation({
    mutationFn: platformClient.createFormTemplate(),
    onMutate: async (formTemplate) => {
      await queryClient.cancelQueries({ queryKey });
      const previousFormTemplates = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (old: any) => [...old, formTemplate]);
      return { previousFormTemplates };
    },
    onError: (error, _, context) => {
      toast.error(error?.message || 'An error occurred.');
      if (context?.previousFormTemplates) {
        queryClient.setQueryData(queryKey, context.previousFormTemplates);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const handleOnCreate = async (values: { name: string }) => {
    try {
      await create.mutateAsync({
        name: values.name,
      });
      setOpen(false);
      toast.success('Form template created.');
    } catch (error: any) {
      toast.error(error?.message || 'An error occurred.');
    }
  };

  return (
    <SheetForm
      open={open}
      trigger="Create form template"
      title="Create form template"
      description="Create a form template."
      submit="Create form template"
      fields={fields}
      onSubmit={handleOnCreate}
      onOpenChange={setOpen}
    />
  );
};

const columns: ColumnDef<FormTemplate>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row: { original } }) => (
      <div className="flex items-center gap-2">
        <Link to={original.id.split('::').at(-1) as string}>
          <p className="font-semibold">{original.name}</p>
        </Link>
      </div>
    ),
  },
];

const SettingsForms = () => {
  const { formTemplates, handleOnSubmit } = useFormTemplates();
  const table = useReactTable({
    data: formTemplates,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <div>
      <div className="flex h-[52px] flex-shrink-0 items-center justify-between border-b px-4 py-2">
        <h1 className="text-xl font-bold">Forms</h1>
        <CreateFormTemplate onCreated={() => {}} />
      </div>
      <div className="px-4 pt-4">
        <div className="mb-4">
          <h3 className="mb-1">Templates</h3>
          <p className="text-xs dark:text-white/75">
            Form templates allow you to create reusable forms
          </p>
        </div>
        <div className="grow rounded-md border">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default SettingsForms;
