import { ScrollArea } from '@/components/ui/scroll-area';
import MemberCertificates from './components/MemberCertificates';
import MemberDetails from './components/MemberDetails';
import MemberTraining from './components/MemberTraining';
import { TrainingStaffMemberContext } from './context';
import { useTrainingStaffMember } from './hooks';

const TrainingStaffMember = () => {
  const { context } = useTrainingStaffMember();
  return (
    <TrainingStaffMemberContext.Provider value={context}>
      <div className="h-full min-h-0 bg-secondary bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-background">
        <ScrollArea className="h-full min-h-0">
          <div className="container flex max-w-screen-lg flex-col gap-y-4 pt-8">
            <MemberDetails />
            <MemberTraining />
            <MemberCertificates />
          </div>
        </ScrollArea>
      </div>
    </TrainingStaffMemberContext.Provider>
  );
};

export default TrainingStaffMember;
