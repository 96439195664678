import React from 'react';
import { evaluateProperty } from 'lib/utils';
import { ReportBlockComponentProps } from '../../../../types';
import ReportText from '../../../ReportText';
import ReportView from '../../../ReportView';

const BlockTitle = ({ data, context }: ReportBlockComponentProps) => {
  return (
    <ReportView>
      <ReportText
        style={{
          marginTop: -2,
          fontWeight: 'bold',
          fontSize: 20,
        }}
      >
        {evaluateProperty('title', data, context)}
      </ReportText>
      <ReportText
        style={{
          opacity: 0.75,
          fontSize: 14,
        }}
      >
        {evaluateProperty('subtitle', data, context)}
      </ReportText>
    </ReportView>
  );
};

export default BlockTitle;
