import React from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { usePlatformObject } from 'lib/hooks';
import { useJobReportVersionContext } from '../../context';
import { useVersionRendererContext } from '../VersionRenderer/context';

const VersionPdf = () => {
  const { version } = useJobReportVersionContext();
  const { toolbar } = useVersionRendererContext();
  const { object } = usePlatformObject(version?.sourceObject?.id);
  return (
    <div
      className="relative mx-auto mt-4 h-[calc(100%-16px)] min-h-0"
      style={{ width: 'calc(var(--scale-factor) * 595.280029px + 14px)' }}
    >
      {object?.getUrl && (
        <div className="job-report-pdf -mr-[14px] h-full min-h-0">
          <Viewer
            fileUrl={object.getUrl}
            plugins={[toolbar]}
            defaultScale={1}
          />
        </div>
      )}
    </div>
  );
};

export default VersionPdf;
