import React from 'react';
import { Loader } from '@/components/ui/loading';

const AssignmentLoader = () => {
  return (
    <div className="absolute inset-0 z-10 h-full w-full bg-background p-4">
      <div className="flex h-full w-full items-center justify-center rounded-md border border-dashed">
        <div>
          <Loader className="dark:text-white-400 mx-auto mb-2 h-4 w-4 text-gray-400" />
          <p className="dark:text-white-400 text-sm text-gray-400">
            Loading scheduler
          </p>
        </div>
      </div>
    </div>
  );
};

export default AssignmentLoader;
