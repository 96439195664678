import { DateTime } from 'luxon';

const getWeekNumber = (dateTime: DateTime, dateTimeStart?: DateTime) => {
  const startOfYear = (dateTimeStart?.plus({ year: 1 }) ?? DateTime.local())
    .startOf('year')
    .minus({ year: 1 })
    .plus({ months: 3 })
    .startOf('month')
    .set({ weekday: 6 });

  return Math.floor(dateTime.diff(startOfYear, ['weeks']).weeks);
};

export default getWeekNumber;
