import React, { useCallback, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { ArrowRightIcon } from '@radix-ui/react-icons';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { SlateReactPresentation } from 'slate-react-presentation';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
} from '@/components/ui/carousel';
import { Loader } from '@/components/ui/loading';
import { ScrollArea } from '@/components/ui/scroll-area';
import { gql } from '@apollo/client';
import { request } from 'graphql-request';
import { DateTime } from 'luxon';
import AppLoginLayout from './components/AppLoginLayout';

const test = {
  data: {
    stories: [
      {
        id: '507',
        title: 'Celebrating 2500 Followers on Linkedin!',
        body: {
          mobile: [
            {
              h: 2,
              i: '8fda9f30-0fe6-4aba-9b62-0d51693d58ff',
              w: 4,
              x: 0,
              y: 0,
              type: 'text',
              elementId: '10cfd6d7-c230-4116-b196-d5276a43ac1a',
            },
          ],
          desktop: [
            {
              h: 18,
              i: '37aca33e-6cf2-4de4-b145-3242a9302b14',
              w: 12,
              x: 0,
              y: 0,
              type: 'text',
              elementId: '10cfd6d7-c230-4116-b196-d5276a43ac1a',
            },
          ],
          elements: {
            '10cfd6d7-c230-4116-b196-d5276a43ac1a': {
              id: '10cfd6d7-c230-4116-b196-d5276a43ac1a',
              type: 'text',
              value: [
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: "We're thrilled to announce this remarkable milestone! It's been an incredible journey, and we’re truly grateful to each and every one of you for being a part of our growing community.",
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'A heartfelt THANK YOU to our 2500 Friends and Followers on LinkedIn. Your interest, continued support, and engagement mean a lot to us. We are committed to delivering valuable content with our goal to provide you with informative and engaging updates. We would like to express our sincere gratitude to all those who read, like, share, and comment on our posts.',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: "We're excited about welcoming new followers and further expanding our LinkedIn family as we work towards our next milestone!",
                    },
                  ],
                },
              ],
            },
          },
        },
        dateFirstPublished: '22-1-2024',
        slug: 'celebrating-2500-followers-on-linkedin',
        category: {
          id: 5,
          name: 'Other',
        },
        primaryImage: {
          id: '852',
          desktopThumbnail:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/1cd9c213-8d94-45c9-91ea-d2cebdc28c9e-thumbnail-400x225-70.jpg',
          desktopDisplay:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/1cd9c213-8d94-45c9-91ea-d2cebdc28c9e-thumbnail-650x650-70.jpg',
        },
      },
      {
        id: '506',
        title: 'Working to the highest standards!',
        body: {
          mobile: [
            {
              h: 2,
              i: 'bcbdb8dd-a594-4e5a-bb54-c25158660781',
              w: 4,
              x: 0,
              y: 0,
              type: 'text',
              elementId: '2ad5f364-3310-45aa-9d66-6881a4d8b6d7',
            },
          ],
          desktop: [
            {
              h: 18,
              i: 'aba991a1-9472-4b9e-a071-bb3ec3cb5d55',
              w: 12,
              x: 0,
              y: 0,
              type: 'text',
              elementId: '2ad5f364-3310-45aa-9d66-6881a4d8b6d7',
            },
          ],
          elements: {
            '2ad5f364-3310-45aa-9d66-6881a4d8b6d7': {
              id: '2ad5f364-3310-45aa-9d66-6881a4d8b6d7',
              type: 'text',
              value: [
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'Dedicated & committed to working at the highest standards, B&M McHugh are delighted to announce we have once again acquired the following accreditations for 2024:',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: ' ✅ SSIP ',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: ' ✅ Social Value',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: ' ✅ Gold Constructionline ',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: ' ✅ Builders Profile',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: '',
                    },
                  ],
                },
              ],
            },
          },
        },
        dateFirstPublished: '11-1-2024',
        slug: 'working-to-the-highest-standards',
        category: {
          id: 1,
          name: 'Recent Awards',
        },
        primaryImage: {
          id: '851',
          desktopThumbnail:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/03cbd46a-b1be-459e-bf12-d95ca6ea16ee-thumbnail-400x225-70.jpg',
          desktopDisplay:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/03cbd46a-b1be-459e-bf12-d95ca6ea16ee-thumbnail-650x650-70.jpg',
        },
      },
      {
        id: '505',
        title: 'Constructionline Gold!',
        body: {
          mobile: [
            {
              h: 2,
              i: '45ebb3be-448a-47f7-95f6-949caa470367',
              w: 4,
              x: 0,
              y: 0,
              type: 'text',
              elementId: 'd3f79bc9-fdbc-452d-9aad-ae5568cf0d3a',
            },
          ],
          desktop: [
            {
              h: 14,
              i: '4ccaadd5-9da1-4511-9020-78da7dc3d2fb',
              w: 12,
              x: 0,
              y: 0,
              type: 'text',
              elementId: 'd3f79bc9-fdbc-452d-9aad-ae5568cf0d3a',
            },
          ],
          elements: {
            'd3f79bc9-fdbc-452d-9aad-ae5568cf0d3a': {
              id: 'd3f79bc9-fdbc-452d-9aad-ae5568cf0d3a',
              type: 'text',
              value: [
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'Delighted to announce our reaccreditation of Constructionline Gold!',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'We believe it illustrates to clients our commitment to increased scrutiny around our company governance, risk management, equal opportunity & diversity matters and our financial resilience. Along with a higher performance in environmental management, Building Information Modelling (BIM), and corporate & social responsibility.',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'Building a better future.',
                    },
                  ],
                },
              ],
            },
          },
        },
        dateFirstPublished: '11-1-2024',
        slug: 'constructionline-gold',
        category: {
          id: 1,
          name: 'Recent Awards',
        },
        primaryImage: {
          id: '850',
          desktopThumbnail:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/f43d93d0-5bdd-499d-af9d-ad0b4beef8da-thumbnail-400x225-70.jpg',
          desktopDisplay:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/f43d93d0-5bdd-499d-af9d-ad0b4beef8da-thumbnail-650x650-70.jpg',
        },
      },
      {
        id: '504',
        title: 'Washroom Refurbishments are Us!',
        body: {
          mobile: [
            {
              h: 2,
              i: 'db06a773-c609-420c-824a-c6620e8d4dc8',
              w: 4,
              x: 0,
              y: 0,
              type: 'text',
              elementId: '14cb25ae-1335-4521-863a-9ebcb018ac4d',
            },
          ],
          desktop: [
            {
              h: 25,
              i: '75f42723-076a-4e52-9194-3aabe4f565b5',
              w: 12,
              x: 0,
              y: 0,
              type: 'text',
              elementId: '14cb25ae-1335-4521-863a-9ebcb018ac4d',
            },
          ],
          elements: {
            '14cb25ae-1335-4521-863a-9ebcb018ac4d': {
              id: '14cb25ae-1335-4521-863a-9ebcb018ac4d',
              type: 'text',
              value: [
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'Completion photos from the North Core toilets refurbishment project at Reading Civic Centre. ',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'The commercial washroom area is often mistakenly overlooked but can give customers and clients a very strong impression of your company and its values.',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'However, good commercial toilet designs don’t just impact visitors; they can also play a significant role in influencing staff behaviours. We have seen firsthand evidence that our commercial washroom refurbishments have increased staff commitment and performance. It helps to make your staff feel valued and this ultimately reflects on the quality of the work they produce.',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'From a small office toilet design to a full washroom fit out, even a small office toilet refurbishment makes a massive difference to overall company performance and hence customer perceptions.',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: '',
                    },
                  ],
                },
              ],
            },
          },
        },
        dateFirstPublished: '3-1-2024',
        slug: 'washroom-refurbishments-are-us',
        category: null,
        primaryImage: {
          id: '846',
          desktopThumbnail:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/2a0871fe-da19-48ed-91f2-9befd9697ba1-thumbnail-400x225-70.jpg',
          desktopDisplay:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/2a0871fe-da19-48ed-91f2-9befd9697ba1-thumbnail-650x650-70.jpg',
        },
      },
      {
        id: '503',
        title: 'Happy New Year!',
        body: {
          mobile: [
            {
              h: 2,
              i: 'f3d8e38a-a027-49b4-86bd-852e5208fb7b',
              w: 4,
              x: 0,
              y: 0,
              type: 'text',
              elementId: 'ce36817b-2657-4863-9cd5-92cafd3e1a9a',
            },
          ],
          desktop: [
            {
              h: 13,
              i: '9bc69429-7d12-48c5-8712-4d6d8e770674',
              w: 12,
              x: 0,
              y: 0,
              type: 'text',
              elementId: 'ce36817b-2657-4863-9cd5-92cafd3e1a9a',
            },
          ],
          elements: {
            'ce36817b-2657-4863-9cd5-92cafd3e1a9a': {
              id: 'ce36817b-2657-4863-9cd5-92cafd3e1a9a',
              type: 'text',
              value: [
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'Happy New Year from all of us at B&M McHugh!',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: "As we welcome 2024, we'd like to extend our deepest gratitude to our clients, partners, suppliers, friends, and followers. Your support and trust mean the world to us. We once again look forward to a year of growth and opportunities.",
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: "Here's to a prosperous New Year!",
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: '',
                    },
                  ],
                },
              ],
            },
          },
        },
        dateFirstPublished: '2-1-2024',
        slug: 'happy-new-year',
        category: {
          id: 4,
          name: 'CSR',
        },
        primaryImage: {
          id: '845',
          desktopThumbnail:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/9ce0abbf-0349-40bb-a389-c194ffe80fa7-thumbnail-400x225-70.jpg',
          desktopDisplay:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/9ce0abbf-0349-40bb-a389-c194ffe80fa7-thumbnail-650x650-70.jpg',
        },
      },
      {
        id: '502',
        title:
          'Harmony and Honey: Christmas Contribution to the Mayoral Christmas Carol Service.',
        body: {
          mobile: [
            {
              h: 2,
              i: '14ae2b03-c7e6-45aa-9e3f-7266a4ef03de',
              w: 4,
              x: 0,
              y: 0,
              type: 'text',
              elementId: '2d544746-e947-458a-99ee-d6e8a4b23c53',
            },
            {
              h: 10,
              i: 'e3b4021e-73de-4911-9209-f1a22c48c87b',
              w: 4,
              x: 0,
              y: 100,
              type: 'image',
              deleted: true,
              elementId: 'bfb16bc2-79a1-45b2-95cd-8cd23672e5e0',
              resizeHandles: ['se'],
            },
          ],
          desktop: [
            {
              h: 19,
              i: 'a9d0d47e-0853-4f9b-99da-c15ceafa1547',
              w: 12,
              x: 0,
              y: 0,
              type: 'text',
              moved: false,
              static: false,
              elementId: '2d544746-e947-458a-99ee-d6e8a4b23c53',
            },
            {
              h: 20,
              i: '215b2c8a-974b-40f9-a32d-5ac3349b6c0a',
              w: 12,
              x: 0,
              y: 19,
              type: 'image',
              moved: false,
              static: false,
              deleted: true,
              elementId: 'bfb16bc2-79a1-45b2-95cd-8cd23672e5e0',
              resizeHandles: ['se'],
            },
          ],
          elements: {
            '2d544746-e947-458a-99ee-d6e8a4b23c53': {
              id: '2d544746-e947-458a-99ee-d6e8a4b23c53',
              type: 'text',
              value: [
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: "This holiday season, we are thrilled to have sponsored the Christmas tree at the Bexley Civic Centre's Chamber, transforming it into a radiant focal point of festive joy and community spirit. ",
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'The Centre will be a hub of holiday activities, each designed to spread seasonal cheer among the community. A highlight of these events was the Mayoral Christmas Carol service, which took place on December 2nd. This beloved gathering, especially aimed at our elderly residents, attracted over 200 participants, infusing the Chamber with warmth and a sense of togetherness. Alongside the splendid Christmas tree, we were pleased to contribute jars of our signature honey, locally produced from our own hives, adding a sweet touch to the celebration.',
                    },
                  ],
                },
              ],
            },
            'bfb16bc2-79a1-45b2-95cd-8cd23672e5e0': {
              id: 'bfb16bc2-79a1-45b2-95cd-8cd23672e5e0',
              type: 'image',
              value: {
                id: '844',
                raw: 'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/9c7acdd3-13d5-45a3-b4c8-edd355facfc6-thumbnail-650x650-70.jpg',
                preview:
                  'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/9c7acdd3-13d5-45a3-b4c8-edd355facfc6-thumbnail-650x350-70.jpg',
                thumbnail:
                  'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/9c7acdd3-13d5-45a3-b4c8-edd355facfc6-thumbnail-400x225-70.jpg',
                unsplashUrls: {},
                desktopDisplay:
                  'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/9c7acdd3-13d5-45a3-b4c8-edd355facfc6-thumbnail-650x650-70.jpg',
                nativeRawWidth: 960,
                nativeRawHeight: 1280,
                desktopThumbnail:
                  'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/9c7acdd3-13d5-45a3-b4c8-edd355facfc6-thumbnail-400x225-70.jpg',
              },
            },
          },
        },
        dateFirstPublished: '15-12-2023',
        slug: 'harmony-and-honey-christmas-contribution-to-the-mayoral-christmas-carol-service',
        category: {
          id: 4,
          name: 'CSR',
        },
        primaryImage: {
          id: '842',
          desktopThumbnail:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/e3eb3605-206a-4877-b71b-35fd2d6501a7-thumbnail-400x225-70.jpg',
          desktopDisplay:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/e3eb3605-206a-4877-b71b-35fd2d6501a7-thumbnail-650x650-70.jpg',
        },
      },
      {
        id: '501',
        title: 'Volunteering in the Community!',
        body: {
          mobile: [
            {
              h: 2,
              i: '03991ff3-980c-4471-bf7e-217ec94a5b29',
              w: 4,
              x: 0,
              y: 0,
              type: 'text',
              elementId: 'd602f681-86d8-4814-8945-11ef7af2e455',
            },
          ],
          desktop: [
            {
              h: 28,
              i: 'eec5ee66-cc90-4e07-908e-0426bfbf4c9a',
              w: 12,
              x: 0,
              y: 0,
              type: 'text',
              elementId: 'd602f681-86d8-4814-8945-11ef7af2e455',
            },
          ],
          elements: {
            'd602f681-86d8-4814-8945-11ef7af2e455': {
              id: 'd602f681-86d8-4814-8945-11ef7af2e455',
              type: 'text',
              value: [
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: '400,000 children are at risk of missing their next meal. 1.5 million adults in London struggle to afford to eat every day.',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'Meanwhile the food industry generates 3 million tonnes of good, edible surplus food each year. The Felix Project believes in a vision of London where no-one goes hungry and good food is never wasted.',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'Help The Felix Project fill empty plates this winter.',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: '£25 will provide 25 meals.',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: '£42.55 will feed a family of four for a month.',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: '£75 will provide 218 plates of nutritious food.',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'On Tuesday our team decided to play a positive, responsible, active part in helping the local community. We were part of a team at The Felix Project Deptford who helped produce over 3000 meals to be distributed to the local community.',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: '',
                    },
                  ],
                },
              ],
            },
          },
        },
        dateFirstPublished: '13-12-2023',
        slug: 'volunteering-in-the-community',
        category: {
          id: 4,
          name: 'CSR',
        },
        primaryImage: {
          id: '837',
          desktopThumbnail:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/1c840735-7b72-4d27-85aa-120b2932306f-thumbnail-400x225-70.jpg',
          desktopDisplay:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/1c840735-7b72-4d27-85aa-120b2932306f-thumbnail-650x650-70.jpg',
        },
      },
      {
        id: '499',
        title: 'Christmas Jumper Day for Save the Children!',
        body: {
          mobile: [
            {
              h: 2,
              i: '2da58292-fead-42b5-8d36-ed45c127eefb',
              w: 4,
              x: 0,
              y: 0,
              type: 'text',
              elementId: 'ca4f0470-6191-445f-a9cf-fc410686a7ca',
            },
          ],
          desktop: [
            {
              h: 7,
              i: '50098e38-f89e-4b01-8514-f624a7ca70b8',
              w: 12,
              x: 0,
              y: 0,
              type: 'text',
              elementId: 'ca4f0470-6191-445f-a9cf-fc410686a7ca',
            },
          ],
          elements: {
            'ca4f0470-6191-445f-a9cf-fc410686a7ca': {
              id: 'ca4f0470-6191-445f-a9cf-fc410686a7ca',
              type: 'text',
              value: [
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: "Don't you just love Christmas Jumper Day? 🎄 An amazing £280 raised for Save the Children. A massive thank-you to everyone who got involved!",
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: '#santaiscoming #christmascheer #charitysupport',
                    },
                  ],
                },
              ],
            },
          },
        },
        dateFirstPublished: '8-12-2023',
        slug: 'christmas-jumper-day-for-save-the-children',
        category: {
          id: 4,
          name: 'CSR',
        },
        primaryImage: {
          id: '830',
          desktopThumbnail:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/e8bd8d67-4fc2-4b50-95bd-54deda03fa59-thumbnail-400x225-70.jpg',
          desktopDisplay:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/e8bd8d67-4fc2-4b50-95bd-54deda03fa59-thumbnail-650x650-70.jpg',
        },
      },
      {
        id: '498',
        title: 'Washroom & Toilet Refurbishment specialists.',
        body: {
          mobile: [
            {
              h: 2,
              i: 'be5e83a4-4ed8-4666-94b6-7a191dc1c934',
              w: 4,
              x: 0,
              y: 0,
              type: 'text',
              elementId: '9fb0e487-ab4a-4fdf-98c5-be854d7f65b2',
            },
          ],
          desktop: [
            {
              h: 17,
              i: '46832f86-1e1e-4a7e-8bba-1183ae5de0ec',
              w: 12,
              x: 0,
              y: 0,
              type: 'text',
              elementId: '9fb0e487-ab4a-4fdf-98c5-be854d7f65b2',
            },
          ],
          elements: {
            '9fb0e487-ab4a-4fdf-98c5-be854d7f65b2': {
              id: '9fb0e487-ab4a-4fdf-98c5-be854d7f65b2',
              type: 'text',
              value: [
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'B&M McHugh offer a range of washroom refurbishment services for commercial settings such as school toilets, leisure centres, offices and healthcare facilities. Every commercial washroom refurbishment project is assigned a dedicated project manager, meaning you have one point of contact and they take full accountability for your project.',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'You’ll also have a dedicated multi-skilled fitting team, meaning your work is completed by the same team throughout and ensuring you can rely on the consistent quality of work. Our priority is to provide a turn-key service, allowing you to confirm your designs and then relax and leave us to complete the work, on time and on budget.',
                    },
                  ],
                },
              ],
            },
          },
        },
        dateFirstPublished: '22-11-2023',
        slug: 'washroom-toilet-refurbishment-specialists',
        category: {
          id: 3,
          name: 'Completed Works',
        },
        primaryImage: {
          id: '825',
          desktopThumbnail:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/c27ef1bf-85a9-4ba2-8ef7-fd6f2ff33b92-thumbnail-400x225-70.jpg',
          desktopDisplay:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/c27ef1bf-85a9-4ba2-8ef7-fd6f2ff33b92-thumbnail-650x650-70.jpg',
        },
      },
      {
        id: '497',
        title: 'Expert advice & construction of Retaining Walls.',
        body: {
          mobile: [
            {
              h: 2,
              i: '3de992c0-c4c2-4cf4-9d33-95385be6487e',
              w: 4,
              x: 0,
              y: 0,
              type: 'text',
              elementId: '2e1dca91-e05b-47c5-8f73-aa5d410b1799',
            },
          ],
          desktop: [
            {
              h: 21,
              i: 'c37df79e-b492-4927-bfaf-8e15dbe467ca',
              w: 12,
              x: 0,
              y: 0,
              type: 'text',
              elementId: '2e1dca91-e05b-47c5-8f73-aa5d410b1799',
            },
          ],
          elements: {
            '2e1dca91-e05b-47c5-8f73-aa5d410b1799': {
              id: '2e1dca91-e05b-47c5-8f73-aa5d410b1799',
              type: 'text',
              value: [
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'If you are looking for a contractor to help you build or repair a concrete retaining wall, B&M McHugh can help you. We are a construction company that specialises in building and restoring retaining walls. Our retaining wall specialist team work on projects across the south of England including London, Oxfordshire, Bedfordshire, Sussex, Surrey and Kent and everywhere in between. ',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'The retaining wall contractor team at B&M McHugh has worked on countless projects over the years and we are proud to have built an outstanding reputation in the field.',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'When it comes to retaining walls, we work in a variety of sectors including Rail, Water, Aviation, Defence, Energy, Infrastructure & Public Realm and Buildings on numerous projects ranging in size. If you would like to discuss a new retaining wall project, please call us on 020 8859 7706.',
                    },
                  ],
                },
              ],
            },
          },
        },
        dateFirstPublished: '22-11-2023',
        slug: 'expert-advice-construction-of-retaining-walls',
        category: null,
        primaryImage: {
          id: '822',
          desktopThumbnail:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/383bcc3d-657c-4af9-aa38-367e4ca798d6-thumbnail-400x225-70.jpg',
          desktopDisplay:
            'https://cdn.hydro-two.co.uk/media/__sized__/native_images/mchugh/383bcc3d-657c-4af9-aa38-367e4ca798d6-thumbnail-650x650-70.jpg',
        },
      },
    ],
  },
};

const queryClient = new QueryClient();

const StoryBody = ({ story }: { story: any }) => {
  const textElements = story.body.desktop
    .filter((element: { type: string }) => element.type === 'text')
    .flatMap((element: { elementId: string | number }) => {
      return story.body.elements[element.elementId].value;
    });

  const renderElement = useCallback(
    ({ attributes, children, element }: any) => {
      switch (element.type) {
        case 'h1':
          return <h1 {...attributes}>{children}</h1>;

        // ...

        default:
          return <p {...attributes}>{children}</p>;
      }
    },
    [],
  );

  const renderLeaf = useCallback(({ attributes, children, leaf }: any) => {
    if (leaf.bold) {
      children = <b>{children}</b>;
    }

    // ...

    return <span {...attributes}>{children}</span>;
  }, []);

  return (
    <SlateReactPresentation
      value={textElements}
      renderElement={renderElement}
      renderLeaf={renderLeaf}
    />
  );
};

const AppLoginRenderer = () => {
  const { instance } = useMsal();

  const { data } = useQuery({
    queryKey: ['news'],
    queryFn: async () => {
      const { stories } = await request<{ stories: any[] }>(
        'https://api.hydro-two.co.uk/graphql/',
        gql(`
          query GetStories {
            stories: publishedStoriesPublic(
              customerId: "1"
              page: 1
              size: 10
            ) {
              id
              title
              body
              dateFirstPublished
              slug
              category
              primaryImage {
                id
                desktopThumbnail
                desktopDisplay
              }
            }
          }
        `),
      );
      return stories;
    },
  });

  const [pressed, setPressed] = useState(false);

  return (
    <AppLoginLayout>
      <div className="flex h-full">
        <div className="relative flex h-full basis-1/2 flex-col justify-center text-center">
          <div>
            <h1 className="mb-2 text-xl font-semibold">Login to McHugh</h1>
            <p className="mb-8 text-sm text-gray-600 dark:text-white/75">
              Continue to log in or create a new account if you don't have one
              yet.
            </p>
            <Button
              className="gap-x-2 rounded-[2px] text-white"
              style={{ backgroundColor: '#0067B8' }}
              onClick={() => {
                instance.loginRedirect({
                  prompt: 'select_account',
                  scopes: [import.meta.env.PUBLIC_AZURE_SCOPE],
                  redirectUri: import.meta.env.PUBLIC_AZURE_REDIRECT_URL,
                  authority: import.meta.env.PUBLIC_AZURE_AZURE_AUTHORITY,
                });
              }}
              onMouseDown={() => {
                setPressed(true);
                setTimeout(() => {
                  setPressed(false);
                }, 1000);
              }}
            >
              {pressed ? (
                <Loader className="h-4 w-4 animate-spin text-white" />
              ) : (
                <svg
                  className="group-active:hidden"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_870_2464)">
                    <path d="M8.1 0H0.5V7.6H8.1V0Z" fill="#F25022" />
                    <path
                      d="M16.5004 0H8.90039V7.6H16.5004V0Z"
                      fill="#7FBA00"
                    />
                    <path
                      d="M8.1 8.40039H0.5V16.0004H8.1V8.40039Z"
                      fill="#00A4EF"
                    />
                    <path
                      d="M16.5004 8.40039H8.90039V16.0004H16.5004V8.40039Z"
                      fill="#FFB900"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_870_2464">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              )}
              Login with Office 365
            </Button>
          </div>
          <div className="absolute inset-x-0 bottom-4">
            <p className="text-xs">
              By continuing, you agree to our{' '}
              <span className="text-green-700">Terms of Service</span> and{' '}
              <span className="text-green-700">Privacy Policy</span>.
            </p>
          </div>
        </div>
        <div className="relative h-full basis-1/2">
          <div className="pointer-events-none absolute inset-0 bg-gradient-to-r from-[rgba(57,168,230,0.1)] to-[rgba(12,108,14,0.1)]" />
          <svg
            className="absolute right-0 top-32"
            width="292"
            height="675"
            viewBox="0 0 292 675"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.05" clipPath="url(#clip0_835_5797)">
              <mask
                id="mask0_835_5797"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="-169"
                y="-175"
                width="1023"
                height="1023"
              >
                <path
                  d="M-168.75 -174.319H853.335V847.766H-168.75V-174.319Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_835_5797)">
                <path
                  d="M342.821 45.3527C269.824 45.3527 214.651 73.6001 178.858 129.309C140.686 188.702 134.986 261.321 134.986 299.312C134.986 372.714 158.997 456.131 199.215 522.454C240.528 590.579 292.872 629.65 342.828 629.65C392.784 629.65 445.128 590.579 486.441 522.454C526.66 456.131 550.672 372.718 550.672 299.312C550.672 222.952 530.419 45.3446 342.831 45.3446M342.831 674.99C275.675 674.99 210.9 629.167 160.45 545.962C116.115 472.854 89.6484 380.65 89.6484 299.312C89.6484 256.34 96.2828 173.923 140.719 104.789C184.777 36.2368 254.671 0.00488281 342.836 0.00488281C433.007 0.00488281 503.579 36.294 546.916 104.953C589.644 172.634 596.023 255.782 596.023 299.322C596.023 380.66 569.554 472.872 525.221 545.972C474.763 629.18 409.991 675 342.84 675"
                  fill="#0C6C0E"
                />
                <path
                  d="M550.059 151.091C535.37 116.393 515.052 87.9356 489.67 66.5087L483.709 61.4758L481.942 69.074C472.042 111.622 440.321 183.006 339.116 183.006C244.51 183.006 209.812 124.441 197.363 75.3088L195.384 67.4959L189.484 72.9866C167.384 93.553 149.269 119.798 135.638 150.993L132.618 157.908L140.16 157.676C141.663 157.63 143.19 157.607 144.739 157.607C191.778 157.607 226.917 175.483 249.176 210.742C271.816 246.602 275.197 291.523 275.197 315.107C275.197 354.339 263.879 398.567 244.145 436.449C223.427 476.221 196.442 503.531 168.16 513.354L162.835 515.202L165.536 520.15C184.241 554.403 205.742 583.2 229.447 605.741L237.4 613.305L237.51 602.33C237.953 557.806 249.952 507.607 269.605 468.048C290.287 426.415 316.718 402.538 342.121 402.538C367.533 402.538 393.967 426.415 414.645 468.048C434.294 507.605 446.289 557.804 446.732 602.33L446.842 613.305L454.796 605.741C478.5 583.2 500.002 554.403 518.706 520.15L521.407 515.202L516.082 513.354C487.8 503.531 460.815 476.221 440.097 436.449C420.364 398.568 409.045 354.34 409.045 315.107C409.045 291.884 412.565 247.448 436.138 210.773C458.814 175.494 493.592 157.605 539.502 157.605C541.93 157.605 543.761 157.64 545.436 157.718L553.014 158.075L550.059 151.091Z"
                  fill="#0C6C0E"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_835_5797">
                <rect width="675" height="675" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div className="relative h-full overflow-hidden p-32 bg-dotted-spacing-4 bg-dotted-gray-300 dark:bg-dotted-secondary">
            <div>
              <h2 className="mb-4 text-4xl font-semibold leading-[48px] text-gray-700 dark:text-white">
                Infrastructure Renewal and <br />
                Maintenance Contractors
              </h2>
              <p className="text-gray-500 dark:text-white/75">
                We deliver engineering solutions through cost effective,
                customer focused solutions
                <br /> for minor and major projects.
              </p>
            </div>
            <div className="absolute -right-32 bottom-32 left-[120px] overflow-hidden">
              <h3 className="mb-4 ml-2 text-lg font-medium text-gray-600 dark:text-white/75">
                Latest news from B. & M. McHugh
              </h3>
              <Carousel className="w-full">
                <CarouselContent className="-ml-1">
                  {test.data.stories.map((story) => {
                    return (
                      <CarouselItem
                        key={story.id}
                        className="p-0 pl-1 md:basis-1/2 lg:basis-1/3"
                      >
                        <div className="p-2">
                          <Card className="overflow-hidden p-0">
                            <CardContent className="p-0">
                              <div className="h-[200px] w-full">
                                <img
                                  className="h-full w-full rounded object-cover"
                                  alt=""
                                  src={story.primaryImage.desktopDisplay}
                                />
                              </div>
                              <ScrollArea>
                                <div className="pointer-events-none absolute inset-0 z-[5] bg-gradient-to-b from-transparent from-50% to-background" />
                                <div className="relative h-[150px] p-4">
                                  <p className="mb-2 text-xs font-semibold uppercase text-gray-500 dark:text-white/50">
                                    News /{' '}
                                    {DateTime.fromFormat(
                                      story.dateFirstPublished,
                                      'd-M-yyyy',
                                    ).toFormat('DD')}
                                  </p>
                                  <h4 className="mb-2 text-sm font-semibold text-gray-700 dark:text-white">
                                    {story.title}
                                  </h4>
                                  <div className="text-xs leading-6 text-gray-600 dark:text-white/75">
                                    <StoryBody story={story} />
                                  </div>
                                </div>
                              </ScrollArea>
                              <div className="ml-2 pb-2">
                                <Button size="xs" variant="link">
                                  Read more
                                  <ArrowRightIcon className="ml-1 h-4 w-4" />
                                </Button>
                              </div>
                            </CardContent>
                          </Card>
                        </div>
                      </CarouselItem>
                    );
                  })}
                </CarouselContent>
                <CarouselNext className="right-36" />
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </AppLoginLayout>
  );
};

const AppLogin = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppLoginRenderer />
    </QueryClientProvider>
  );
};

export default AppLogin;
