import GroupHeader from '@/components/ui/user-group/components/GroupHeader';
import GroupTable from '@/components/ui/user-group/components/GroupTable';
import { UserGroupProps } from '@/components/ui/user-group/types';
import { UserGroupContext } from './context';
import { useUserGroup } from './hooks';

const UserGroup = ({ id }: UserGroupProps) => {
  const { context } = useUserGroup(id);
  return (
    <UserGroupContext.Provider value={context}>
      <GroupHeader />
      <GroupTable />
    </UserGroupContext.Provider>
  );
};

export default UserGroup;
