import React from 'react';
import { Badge } from '@/components/ui/badge';
import chroma from 'chroma-js';
import { StatusBadgeProps } from './types';

export const StatusBadge = ({ status, colors }: StatusBadgeProps) => {
  const color = chroma(colors[status]);
  return (
    <Badge
      className="rounded-md font-semibold capitalize"
      style={{
        color: color.css(),
        border: `1px solid ${color.alpha(0.5).css()}`,
        backgroundColor: color.alpha(0.1).css(),
      }}
    >
      {status.replace(/_/g, ' ').toLowerCase()}
    </Badge>
  );
};

export default StatusBadge;
