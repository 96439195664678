import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  Background,
  Controls,
  MarkerType,
  ReactFlow,
  ReactFlowProvider,
  SmoothStepEdge,
} from '@xyflow/react';
import { EdgeSmoothStepProps } from 'react-flow-renderer';
import { Job, Nullable } from 'lib/types';
import { useJobContext } from '../../../../context';
import { useGraph } from './hooks';
import NodeJob from './nodes/NodeJob';
import NodeJobIssue from './nodes/NodeJobIssue';
import NodeJobReport from './nodes/NodeJobReport';
import NodeJobTask from './nodes/NodeJobTask';
import NodeJobVariation from './nodes/NodeJobVariation';
import { OverviewFlowProps } from './types';

export const defaultEdgeOptions = {
  type: 'smoothstep',
  markerEnd: { type: MarkerType.ArrowClosed },
  pathOptions: { offset: 0 },
};

export const nodeTypes = {
  job: NodeJob,
  task: NodeJobTask,
  issue: NodeJobIssue,
  variation: NodeJobVariation,
  report: NodeJobReport,
};

const proOptions = { account: 'paid-pro', hideAttribution: true };

const OverviewGraph = ({
  defaultZoom = 0.1,
  flowProps,
}: {
  defaultZoom?: number;
  flowProps?: Partial<OverviewFlowProps>;
}) => {
  const { job } = useJobContext();

  if (job.partial) return null;

  return (
    <div className="flex h-full flex-col">
      {/* @ts-ignore */}
      <OverviewFlow
        key={JSON.stringify(job.graph)}
        job={job}
        defaultZoom={defaultZoom}
        selected={flowProps?.selected}
        selectableTypes={flowProps?.selectableTypes}
        onSelect={flowProps?.onSelect}
      />
    </div>
  );
};

export const OverviewFlow = ({
  job,
  selectableTypes,
  defaultZoom,
  highlight,
  showControls = true,
  showMeta = false,
  selected,
  onSelect,
}: OverviewFlowProps) => {
  const props = selected
    ? { selectableTypes, selected, defaultZoom, showMeta, onSelect }
    : {};

  return (
    <ReactFlowProvider>
      <OverviewFlowComponent
        job={job}
        highlight={highlight}
        showControls={showControls}
        {...props}
      />
    </ReactFlowProvider>
  );
};

const OverviewFlowComponent = ({
  job,
  selectableTypes,
  showControls = true,
  showMeta = false,
  selected,
  highlight,
  onSelect,
}: OverviewFlowProps) => {
  const { nodes, edges, onNodesChange, onEdgesChange } = useGraph(
    job,
    highlight,
  );

  return (
    <OverviewFlowProvider
      value={{
        job,
        selectableTypes,
        selected,
        showMeta,
        onSelect,
      }}
    >
      <ReactFlow
        proOptions={proOptions}
        defaultEdgeOptions={defaultEdgeOptions}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodesConnectable={false}
        nodesDraggable={false}
        zoomOnDoubleClick={false}
        minZoom={0}
      >
        <Background />
        {showControls && <Controls />}
      </ReactFlow>
    </OverviewFlowProvider>
  );
};

const EdgeRenderer = ({ ...props }: EdgeSmoothStepProps) => {
  const [opacity, setOpacity] = useState(0);
  useEffect(() => {
    const timeout = setTimeout(() => setOpacity(1), 250);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <SmoothStepEdge
      {...props}
      style={{ ...props.style, opacity, transition: 'opacity 0.25s' }}
    />
  );
};

export const edgeTypes = {
  smoothstep: EdgeRenderer,
};

const OverviewFlowContext = createContext({
  job: null as Nullable<Job>,
  selectableTypes: undefined as string[] | undefined,
  selected: undefined as string[] | undefined,
  showMeta: false,
  onSelect: undefined as ((id: string) => void) | undefined,
});

const OverviewFlowProvider = OverviewFlowContext.Provider;

export const useOverviewFlow = () => useContext(OverviewFlowContext);

export * from './hooks';

export default OverviewGraph;
