import React, { PropsWithChildren, useState } from 'react';
import { Button } from '@/components/ui/button';
import { useTheme } from '@/components/ui/theme';
import { cn } from '@/lib/utils';

const FlashbangWarning = ({ children }: PropsWithChildren) => {
  const { theme } = useTheme();
  const [open, setOpen] = useState(true);

  if (theme === 'dark') {
    return (
      <>
        <div
          className={cn(
            'pointer-events-none absolute inset-0 z-[10] rounded-lg bg-background/75',
            { 'bg-background/0': !open },
          )}
          style={{ transition: 'all 2s' }}
        >
          <div className="sticky inset-x-0 top-0 px-2">
            <div className="flex items-center justify-between">
              <div
                className={cn('flex items-center pl-4 opacity-100', {
                  'opacity-0': !open,
                })}
                style={{ transition: 'all 2s' }}
              >
                <p className="text-xs font-semibold">Brightness protector on</p>
              </div>
              <Button
                size="sm"
                variant="link"
                className={cn('pointer-events-auto mr-2', {
                  'text-secondary': !open,
                })}
                onClick={() => setOpen((prevOpen) => !prevOpen)}
              >
                {open ? 'Remove' : 'Enable'} brightness protector
              </Button>
            </div>
          </div>
        </div>
        {children}
      </>
    );
  }

  return <>{children}</>;
};

export default FlashbangWarning;
