import { useContext } from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { boxed } from '@syncedstore/core';
import { DateTime } from 'luxon';
import { useUser } from 'lib/hooks';
import { User } from 'lib/types';
import { generateId } from 'lib/utils';
import { InstantiationContext } from './context';
import {
  InstantiationHistoryItemAction,
  InstantiationRendererProps,
  InstantiationStage,
  InstantiationStageIssue,
} from './types';

export const useInstantiationContext = () => {
  return useContext(InstantiationContext);
};

export const useStage = (id: string) => {
  const { store } = useInstantiationContext();
  const { stages = {} } = useSyncedStore(store);
  return (
    stages[id] ??
    ({
      id,
      isConfirmed: false,
      issues: [],
    } as InstantiationStage)
  );
};

export const useIssues = () => {
  const { store } = useInstantiationContext();
  const { stages = {} } = useSyncedStore(store);
  return Object.values(stages).flatMap((stage) => stage?.issues ?? []);
};

export const useConfirmed = (count: number) => {
  const { store } = useInstantiationContext();
  const { stages = {} } = useSyncedStore(store);
  return (
    Object.values(stages).filter((stage) => !!stage?.isConfirmed).length ===
    count
  );
};

export const useSerializedState = () => {
  const { store } = useInstantiationContext();
  const state = useSyncedStore(store);
  return JSON.stringify(state);
};

export const useInstantiation = ({
  store,
  doc,
}: InstantiationRendererProps) => {
  const user = useUser() as User;
  const userPresence = user
    ? {
        id: user.id,
        name: user.name,
        email: user.email,
      }
    : undefined;

  const getStage = (id: string) => {
    return store.stages[id];
  };

  const getStageOrThrow = (id: string) => {
    const stage = getStage(id);

    if (!stage) {
      throw new Error(`Stage with id ${id} not found`);
    }

    return stage;
  };

  const getStageIssueIndex = (stage: InstantiationStage, issueId: string) => {
    const index = stage.issues.findIndex((issue) => issue.id === issueId);

    if (index === -1) {
      throw new Error(`Issue with id ${issueId} not found`);
    }

    return index;
  };

  const createHistoryItem = (action: InstantiationHistoryItemAction) => {
    if (!userPresence) {
      throw new Error('User not found');
    }
    return boxed({
      id: generateId(),
      action,
      dateTime: DateTime.now().toISO() as string,
      snapshot: JSON.stringify({
        version: store.history.length,
        stages: store.stages,
      }),
      user: userPresence,
    });
  };

  const handleConfirmStage = (id: string) => {
    const stage = store.stages[id];

    if (!stage) {
      doc.transact(() => {
        store.stages[id] = {
          id,
          isConfirmed: true,
          issues: [],
        };
        store.history.push(
          createHistoryItem(InstantiationHistoryItemAction.ConfirmStage),
        );
      });
      return;
    }

    doc.transact(() => {
      stage.isConfirmed = true;
      store.history.push(
        createHistoryItem(InstantiationHistoryItemAction.ConfirmStage),
      );
    });
  };

  const handleResetStage = (id: string) => {
    const stage = getStageOrThrow(id);
    doc.transact(() => {
      stage.isConfirmed = false;
      store.history.push(
        createHistoryItem(InstantiationHistoryItemAction.ResetStage),
      );
    });
  };

  const handleCreateIssue = (stageId: string) => {
    const stage = getStage(stageId);

    const stageIssue = {
      id: generateId(),
      type: undefined,
      note: '',
    };

    if (!stage) {
      doc.transact(() => {
        store.stages[stageId] = {
          id: stageId,
          isConfirmed: false,
          issues: [],
        };
        (store.stages[stageId] as InstantiationStage).issues.push(stageIssue);
        store.history.push(
          createHistoryItem(InstantiationHistoryItemAction.CreateIssue),
        );
      });
      return;
    }

    if (!('issues' in stage)) {
      doc.transact(() => {
        // @ts-ignore
        (stage as Omit<InstantiationStage, 'issues'>).issues = [stageIssue];
        store.history.push(
          createHistoryItem(InstantiationHistoryItemAction.CreateIssue),
        );
      });
      return;
    }

    doc.transact(() => {
      stage.issues.push(stageIssue);
      store.history.push(
        createHistoryItem(InstantiationHistoryItemAction.CreateIssue),
      );
    });
  };

  const handleRequestIssueUpdate = (stageId: string, issueId: string) => {
    const stage = getStageOrThrow(stageId);
    const issueIndex = getStageIssueIndex(stage, issueId);

    if (
      stage.issues[issueIndex].editor &&
      stage.issues[issueIndex].editor?.id !== userPresence?.id
    ) {
      return false;
    }

    doc.transact(() => {
      stage.issues[issueIndex].editor = userPresence;
      stage.issues[issueIndex].draft = {
        type: stage.issues[issueIndex].type,
        note: stage.issues[issueIndex].note,
      };
    });
    return true;
  };

  const handleUpdateIssue = (
    stageId: string,
    issueId: string,
    updater: (issue: InstantiationStageIssue) => void,
  ) => {
    const stage = getStageOrThrow(stageId);
    const issueIndex = getStageIssueIndex(stage, issueId);
    doc.transact(() => {
      updater(stage.issues[issueIndex]);
      delete stage.issues[issueIndex].editor;
      delete stage.issues[issueIndex].draft;
      store.history.push(
        createHistoryItem(InstantiationHistoryItemAction.UpdateIssue),
      );
    });
  };

  const handleCancelIssueUpdate = (stageId: string, issueId: string) => {
    const stage = getStageOrThrow(stageId);
    const issueIndex = getStageIssueIndex(stage, issueId);
    doc.transact(() => {
      delete stage.issues[issueIndex].editor;
      delete stage.issues[issueIndex].draft;
    });
  };

  const handleDeleteIssue = (stageId: string, issueId: string) => {
    const stage = getStageOrThrow(stageId);
    const issueIndex = getStageIssueIndex(stage, issueId);
    doc.transact(() => {
      stage.issues.splice(issueIndex, 1);
      store.history.push(
        createHistoryItem(InstantiationHistoryItemAction.DeleteIssue),
      );
    });
  };

  return {
    context: {
      store,
      doc,
      confirmStage: handleConfirmStage,
      resetStage: handleResetStage,
      createIssue: handleCreateIssue,
      requestIssueUpdate: handleRequestIssueUpdate,
      cancelIssueUpdate: handleCancelIssueUpdate,
      updateIssue: handleUpdateIssue,
      deleteIssue: handleDeleteIssue,
    },
  };
};
