import React, { Fragment, useEffect, useState } from 'react';
import { ClockIcon, Hexagon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { gql, useMutation } from '@apollo/client';
import { formatDistanceToNow } from 'date-fns';
import { DateTime } from 'luxon';
import { toast } from 'sonner';
import { AsyncContext } from 'lib/shared';
import { TimesheetDuty, TimesheetEvent } from 'lib/types';
import { useJobContext } from '../../../../../../../../context';
import { useJobTaskContext } from '../../../../../../context';

type DeleteTimesheetEventMutationVariables = {
  timesheetEventId: string;
};

type DeleteTimesheetEventMutationData = {
  deleteTimesheetEvent: {
    isDeleted: boolean;
  };
};

type UnsetTimesheetEventMutationVariables = {
  timesheetEventId: string;
};

type UnsetTimesheetEventMutationData = {
  unsetTimesheetEvent: {
    isUnset: boolean;
  };
};

const StaffMemberActivity = ({ duty }: { duty: TimesheetDuty }) => {
  const { task } = useJobTaskContext();

  const [, setValue] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setValue((prevValue) => prevValue + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const [updateEvent] = useMutation(gql`
    mutation UpdateTimesheetDuty(
      $timesheetEventId: ID!
      $input: UpdateTimesheetEventInput!
    ) {
      updateTimesheetEvent(
        timesheetEventId: $timesheetEventId
        timesheetEventInput: $input
      ) {
        timesheetEvent {
          id
        }
      }
    }
  `);

  if (!task) return null;

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button className="font-bold" size="sm" variant="outline">
          Staff: {duty.user?.name}
        </Button>
      </SheetTrigger>
      <SheetContent className="flex w-[700px] max-w-[700px] flex-col gap-0 p-0 sm:max-w-[700px]">
        <SheetHeader className="p-4">
          <SheetTitle>Timesheet duty</SheetTitle>
          <SheetDescription>
            Manage the timesheet duty for this user.
          </SheetDescription>
        </SheetHeader>
        <ScrollArea className="grow">
          <div>
            <Separator />
            <div className="p-4">
              <h3 className="text-md mb-1 font-medium text-gray-600 dark:text-white/80">
                Schedule
              </h3>
              <p className="text-xs text-gray-400">
                This is the schedule for this user.
              </p>
            </div>
            <Separator />
            <ul role="list" className="space-y-6 py-4 pl-3 pr-4">
              <DutyCreatedListItem duty={duty} />
              {duty.dateTimeStart && <DutyStartListItem duty={duty} />}
              {duty.dateTimeEnd && <DutyEndListItem duty={duty} />}
            </ul>
          </div>
          <Separator />
          <div>
            <div className="p-4">
              <h3 className="text-md mb-1 font-medium text-gray-600 dark:text-white/80">
                Events
              </h3>
              <p className="text-xs text-gray-400">
                This is the actual activity for this user.
              </p>
            </div>
            <Separator />
            <div>
              {duty.events.length > 0 ? (
                <ul className="space-y-6 py-4 pl-3 pr-4">
                  {duty.events.map((event, index, events) => {
                    const isLast = index === events.length - 1;
                    return (
                      <Fragment key={event.id}>
                        <EventStartListItem
                          event={event}
                          isLast={isLast && !event.dateTimeEnd}
                        />
                        <EventEndListItem event={event} isLast={isLast} />
                        {events.length > 1 && index < events.length - 1 && (
                          <div className="pl-1">
                            <Separator />
                          </div>
                        )}
                      </Fragment>
                    );
                  })}
                </ul>
              ) : (
                <div className="p-4">
                  <div className="flex h-32 w-full items-center justify-center rounded-md border border-dashed">
                    <div>
                      <Hexagon className="dark:text-white-400 mx-auto mb-2 h-4 w-4 text-gray-400" />
                      <p className="dark:text-white-400 text-xs text-gray-400">
                        No activity yet
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

const DutyCreatedListItem = ({ duty }: { duty: TimesheetDuty }) => {
  return (
    <li className="relative flex gap-x-4">
      <div className="absolute -bottom-6 -left-[1px] top-0 flex w-6 justify-center">
        <div className="w-px bg-gray-200" />
      </div>
      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-background">
        <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
      </div>
      <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
        <span className="font-medium text-gray-900 dark:text-white/75">
          Timesheet duty created
        </span>
      </p>
      <time
        dateTime={duty.dateTimeCreated}
        className="flex-none py-0.5 text-xs leading-5 text-gray-500 dark:text-white/50"
      >
        {formatDistanceToNow(duty.dateTimeCreated, {
          addSuffix: true,
        })}
      </time>
    </li>
  );
};

const DutyStartListItem = ({ duty }: { duty: TimesheetDuty }) => {
  return (
    <li className="relative flex gap-x-4">
      <div className="absolute -bottom-6 -left-[1px] top-0 flex w-6 justify-center">
        <div className="w-px bg-gray-200" />
      </div>
      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-background">
        <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
      </div>
      <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
        <span className="font-medium text-gray-900 dark:text-white/75">
          Start <strong>{duty.dateTimeStartCondition.toLowerCase()}</strong>{' '}
          {DateTime.fromISO(duty.dateTimeStart).toLocaleString(
            DateTime.DATETIME_FULL,
          )}
        </span>
      </p>
      <time
        dateTime={duty.dateTimeStart}
        className="flex-none py-0.5 text-xs leading-5 text-gray-500 dark:text-white/50"
      >
        {formatDistanceToNow(duty.dateTimeStart, {
          addSuffix: true,
        })}
      </time>
    </li>
  );
};

const DutyEndListItem = ({ duty }: { duty: TimesheetDuty }) => {
  return (
    <li className="relative flex gap-x-4">
      <div className="absolute -left-[1px] top-0 flex h-6 w-6 justify-center">
        <div className="w-px bg-gray-200" />
      </div>
      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-background">
        <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
      </div>
      <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
        <span className="font-medium text-gray-900 dark:text-white/75">
          End <strong>{duty.dateTimeEndCondition.toLowerCase()}</strong>{' '}
          {DateTime.fromISO(duty.dateTimeEnd).toLocaleString(
            DateTime.DATETIME_FULL,
          )}
        </span>
      </p>
      <time
        dateTime={duty.dateTimeEnd}
        className="flex-none py-0.5 text-xs leading-5 text-gray-500 dark:text-white/50"
      >
        {formatDistanceToNow(duty.dateTimeEnd, {
          addSuffix: true,
        })}
      </time>
    </li>
  );
};

const EventStartListItem = ({
  event,
  isLast = false,
}: {
  event: TimesheetEvent;
  isLast: boolean;
}) => {
  const { job } = useJobContext();
  const { task } = useJobTaskContext();

  const [deleteEvent] = useMutation<
    DeleteTimesheetEventMutationData,
    DeleteTimesheetEventMutationVariables
  >(gql`
    mutation DeleteTimesheetEvent($timesheetEventId: ID!) {
      deleteTimesheetEvent(timesheetEventId: $timesheetEventId) {
        isDeleted
      }
    }
  `);

  const handleOnDelete = async () => {
    if (!task) return;

    const { data, errors } = await deleteEvent({
      variables: { timesheetEventId: event.id },
      errorPolicy: 'all',
    });

    if (data && data.deleteTimesheetEvent.isDeleted) {
      await job.refetch();
      await task.refetch();
      toast.success('Event deleted successfully');
    }

    if (errors) {
      toast.error('An error occurred while deleting the event');
      console.error(errors);
    }
  };

  if (!event.dateTimeStart || !event.dateTimeStartType) return null;

  const dateTime = DateTime.fromISO(event.dateTimeStart);
  return (
    <li className="relative flex items-center gap-x-4">
      <div className="absolute -bottom-6 -left-[1px] top-0 flex w-6 justify-center">
        <div className="w-px bg-gray-200" />
      </div>
      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-background">
        <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
      </div>
      <div className="flex grow items-center">
        <div className="grow">
          <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
            <span className="font-medium text-gray-900 dark:text-white">
              <span className="capitalize">
                {event.dateTimeStartType.toLowerCase()}
              </span>{' '}
              at {dateTime.toLocaleString(DateTime.DATETIME_FULL)}
            </span>
          </p>
        </div>
        {isLast && (
          <Dialog>
            <DialogTrigger asChild>
              <Button size="xs" variant="outline">
                Delete
              </Button>
            </DialogTrigger>
            <DialogContent>
              <div>
                <p className="mb-2 text-sm font-semibold">
                  Are you sure you want to delete this event?
                </p>
                <p className="mb-2 text-xs">
                  The operative reported their start time as:
                </p>
                <Card className="mb-2">
                  <CardContent className="whitespace-nowrap p-2">
                    <p className="mb-1 text-sm font-semibold">
                      {' '}
                      {dateTime.toLocaleString(DateTime.TIME_24_SIMPLE)}
                    </p>
                    <p className="text-xs text-gray-600 dark:text-gray-400">
                      {dateTime.toLocaleString(DateTime.DATE_HUGE)}
                    </p>
                  </CardContent>
                </Card>
                <p className="text-xs text-gray-400 dark:text-gray-400">
                  Please make sure to only delete this event if the operative
                  has created in error as this action removes history of this
                  interaction.
                </p>
              </div>
              <AsyncContext handle={handleOnDelete}>
                {({ loading, handle }) => (
                  <div className="flex justify-between">
                    <DialogClose asChild>
                      <Button size="sm" variant="outline" disabled={loading}>
                        Cancel
                      </Button>
                    </DialogClose>
                    <Button
                      size="sm"
                      variant="destructive"
                      isLoading={loading}
                      onClick={handle}
                    >
                      Delete event
                    </Button>
                  </div>
                )}
              </AsyncContext>
            </DialogContent>
          </Dialog>
        )}
      </div>
      <time
        dateTime={event.dateTimeStart}
        className="flex-none py-0.5 text-xs leading-5 text-gray-500"
      >
        {formatDistanceToNow(event.dateTimeStart, {
          addSuffix: true,
        })}
      </time>
    </li>
  );
};

const EventEndListItem = ({
  event,
  isLast = false,
}: {
  event: TimesheetEvent;
  isLast: boolean;
}) => {
  const { job } = useJobContext();
  const { task } = useJobTaskContext();

  const [unsetEvent] = useMutation<
    UnsetTimesheetEventMutationData,
    UnsetTimesheetEventMutationVariables
  >(gql`
    mutation UnsetTimesheetEvent($timesheetEventId: ID!) {
      unsetTimesheetEvent(timesheetEventId: $timesheetEventId) {
        isUnset
      }
    }
  `);

  const handleOnUnset = async () => {
    if (!task) return;

    const { data, errors } = await unsetEvent({
      variables: { timesheetEventId: event.id },
      errorPolicy: 'all',
    });

    if (data && data.unsetTimesheetEvent.isUnset) {
      await job.refetch();
      await task.refetch();
      toast.success('Event end time unset successfully');
    }

    if (errors) {
      toast.error('An error occurred while unsetting the event end time');
      console.error(errors);
    }
  };

  if (!event.dateTimeEnd || !event.dateTimeEndType) {
    return (
      <li className="relative">
        <div className="flex gap-x-4">
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-background">
            <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
          </div>
          <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
            No end time recorded.
          </p>
        </div>
      </li>
    );
  }

  const dateTime = DateTime.fromISO(event.dateTimeEnd);
  return (
    <li className="relative">
      <div className="flex gap-x-4">
        <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-background">
          <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
        </div>
        <div className="flex grow items-center">
          <div className="grow">
            <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
              <span className="font-medium text-gray-900 dark:text-white">
                <span className="capitalize">
                  {event.dateTimeEndType.toLowerCase()}
                </span>{' '}
                at{' '}
                {DateTime.fromISO(event.dateTimeEnd).toLocaleString(
                  DateTime.DATETIME_FULL,
                )}
              </span>
            </p>
          </div>
          {isLast && (
            <Dialog>
              <DialogTrigger asChild>
                <Button size="xs" variant="outline">
                  Unset
                </Button>
              </DialogTrigger>
              <DialogContent>
                <div>
                  <p className="mb-2 text-sm font-semibold">
                    Are you sure you want to unset the end time for this event?
                  </p>
                  <p className="mb-2 text-xs">
                    The operative reported their end time as:
                  </p>
                  <Card className="mb-2">
                    <CardContent className="whitespace-nowrap p-2">
                      <p className="mb-1 text-sm font-semibold">
                        {dateTime.toLocaleString(DateTime.TIME_24_SIMPLE)}
                      </p>
                      <p className="text-xs text-gray-600 dark:text-gray-400">
                        {dateTime.toLocaleString(DateTime.DATE_HUGE)}
                      </p>
                    </CardContent>
                  </Card>
                  <p className="text-xs text-gray-400 dark:text-gray-400">
                    If you unset this event, the operative will be deemed as
                    still on site. If you intend to delete the event, please
                    also delete the start time.
                  </p>
                </div>
                <AsyncContext handle={handleOnUnset}>
                  {({ loading, handle }) => (
                    <div className="flex justify-between">
                      <DialogClose asChild disabled={loading}>
                        <Button size="sm" variant="outline">
                          Cancel
                        </Button>
                      </DialogClose>
                      <Button
                        size="sm"
                        variant="destructive"
                        isLoading={loading}
                        onClick={handle}
                      >
                        Unset end time
                      </Button>
                    </div>
                  )}
                </AsyncContext>
              </DialogContent>
            </Dialog>
          )}
        </div>
        <time
          dateTime={event.dateTimeEnd}
          className="flex-none py-0.5 text-xs leading-5 text-gray-500"
        >
          {formatDistanceToNow(event.dateTimeEnd, {
            addSuffix: true,
          })}
        </time>
      </div>
      {event?.dateTimeStart && event?.dateTimeEnd && (
        <div className="mt-4 inline-block rounded-md bg-muted p-4">
          <p className="flex items-center gap-2 text-sm font-semibold">
            <ClockIcon className="h-4 w-4" />
            Operative spent{' '}
            {DateTime.fromISO(event.dateTimeEnd)
              .diff(DateTime.fromISO(event.dateTimeStart))
              .shiftTo('hours', 'minutes')
              .mapUnits((unit) => Math.ceil(unit))
              .toHuman()}{' '}
            on site.
          </p>
        </div>
      )}
    </li>
  );
};

export default StaffMemberActivity;
