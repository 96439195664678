import React from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { LockIcon } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { TextareaAutosize } from '@/components/ui/textarea';
import { ItemType } from 'external/Tree';
import { BlockData } from '../../../types';
import { useFormTemplateContext } from '../../context';
import { BlockInputProps } from './types';

const BlockInput = ({ item, onFocus }: BlockInputProps) => {
  const { store } = useFormTemplateContext();
  const { items } = useSyncedStore(store);

  const getPlaceholder = () => {
    if (item.type === ItemType.CONTAINER) return 'Give this section a label...';
    if (item.data.dataType === 'COMMENT')
      return 'Give this comment some text...';
    return 'Give this question a label...';
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value: newValue } = event.target;
    (items[item.id].data as BlockData).value = newValue;
  };

  return (
    <div className="relative flex grow">
      {item.data.isRequired && <p className="absolute left-0 top-[6px]">*</p>}
      <div className="grow">
        <TextareaAutosize
          className="mr-[1px] min-h-[38px] resize-none rounded-none border-transparent shadow-none hover:bg-accent"
          placeholder={getPlaceholder()}
          value={items[item.id].data.value}
          onFocus={onFocus}
          onChange={handleOnChange}
        />
        {item.data.helpText && (
          <div>
            <Input
              className="h-6 rounded-none border-none text-xs italic shadow-none hover:bg-accent dark:text-gray-300"
              placeholder="Provide help text..."
              value={items[item.id].data.helpText.value}
              onFocus={onFocus}
              onChange={(event) => {
                items[item.id].data.helpText.value = event.target.value;
              }}
            />
          </div>
        )}
      </div>
      {item.data.isPrivate && (
        <div className="mx-2 flex items-center gap-x-1">
          <LockIcon className="h-4 w-4 text-gray-400 dark:text-gray-600" />
          <p className="text-xs font-bold text-gray-400 dark:text-gray-600">
            Private
          </p>
        </div>
      )}
    </div>
  );
};

export default BlockInput;
