import { evaluateExpression } from '@enterprise-platform-ui/expressions';

export const evaluate = (expression: string, context: any) => {
  const expressionPositions = getExpressionPositions(expression);

  let result: string = expression;

  for (const [start, end] of expressionPositions) {
    const inner = expression.slice(start, end);
    const value = evaluateExpression(inner, context);
    if (value) {
      result = result.replace(
        `$(${expression.slice(start, end)})`,
        value.value,
      );
    }
  }

  return result;
};

export const getExpressions = (expression: string) => {
  const expressionPositions = getExpressionPositions(expression);

  const expressions: string[] = [];

  for (const [start, end] of expressionPositions) {
    expressions.push(expression.slice(start, end));
  }

  return expressions;
};

export const getExpressionPositions = (
  expression: string,
): [number, number][] => {
  const expressionPositions: [number, number][] = [];

  let cursor = 0;

  while (cursor < expression.length) {
    if (`${expression[cursor]}${expression[cursor + 1]}` === '$(') {
      cursor += 2;
      expressionPositions.push([cursor, -1]);

      let bracketCount = 1;
      while (bracketCount > 0) {
        if (cursor >= expression.length) {
          console.error('Unmatched brackets');
          break;
        }

        if (expression[cursor] === '(') {
          bracketCount++;
        } else if (expression[cursor] === ')') {
          bracketCount--;
        }

        cursor++;
      }
      expressionPositions[expressionPositions.length - 1][1] = cursor - 1;
    } else {
      cursor++;
    }
  }

  return expressionPositions;
};
