import { PropsWithChildren } from 'react';
import { ChevronRight, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { toast } from 'sonner';
import { CommercialType, commercialTypeDisplayMap } from 'lib/types';
import { useCommercialActivityContext } from '../../context';
import TableCost from './components/TableCost';
import TableValue from './components/TableValue';
import { ActivityTableContext } from './context';
import { useActivityTable, useActivityTableRenderer } from './hooks';
import { ActivityTableProps } from './types';

const ActivityTable = ({
  children,
  standalone = false,
  type,
  color,
}: ActivityTableProps) => {
  return (
    <ActivityTableProvider standalone={standalone} type={type} color={color}>
      <ActivityTableRenderer standalone={standalone}>
        {children}
      </ActivityTableRenderer>
    </ActivityTableProvider>
  );
};

export const ActivityTableProvider = ({
  children,
  loader = null,
  type,
}: PropsWithChildren<ActivityTableProps>) => {
  const { ready, context } = useActivityTable(type);
  if (!ready) return <>{loader}</>;
  return (
    <ActivityTableContext.Provider value={context}>
      {children}
    </ActivityTableContext.Provider>
  );
};

const ActivityTableRenderer = ({
  children,
  standalone = false,
}: Pick<ActivityTableProps, 'children' | 'standalone'>) => {
  const { sections } = useCommercialActivityContext();
  const {
    type,
    state,
    totalEstimatedValue,
    totalEstimatedCost,
    totalAppliedValue,
    totalActualCost,
  } = useActivityTableRenderer();

  if (!standalone && state.empty && !sections.isOpen(type)) {
    return null;
  }

  return (
    <Card
      className={cn('relative overflow-hidden p-0', {
        'border-labor/50': type === CommercialType.Labor,
        'border-material/50': type === CommercialType.Material,
        'border-equipment/50': type === CommercialType.Equipment,
        'border-subcontractor/50': type === CommercialType.Subcontractor,
      })}
    >
      <div className="relative z-[3]">
        <div
          className={cn(
            'flex h-8 items-center bg-gradient-to-r to-background to-20%',
            {
              'bg-background': type === CommercialType.Prelim,
              'from-labor/50': type === CommercialType.Labor,
              'from-material/50': type === CommercialType.Material,
              'from-equipment/50': type === CommercialType.Equipment,
              'from-subcontractor/50': type === CommercialType.Subcontractor,
            },
          )}
        >
          <div className="grid w-7/12 grid-cols-8 border-r text-sm">
            <div className="col-span-3 flex items-center">
              <Button
                className="size-8 flex-shrink-0 rounded-none p-0"
                variant="ghost"
                onClick={state.onToggle}
              >
                <ChevronRight
                  className={cn('size-4', state.open && 'rotate-90 transform')}
                />
              </Button>
              {children || (
                <p className="pl-2 font-bold">
                  <span className="capitalize">
                    {commercialTypeDisplayMap[type]}
                  </span>{' '}
                  value estimate
                </p>
              )}
            </div>
            {!state.open && (
              <>
                <div className="col-span-1 flex items-center">
                  Estimated cost
                </div>
                <div className="col-span-1 flex items-center">
                  £{totalEstimatedCost.toFixed(2)}
                </div>
                <div className="col-span-1 flex items-center">
                  Estimated value
                </div>
                <div className="col-span-1 flex items-center">
                  £{totalEstimatedValue.toFixed(2)}
                </div>
              </>
            )}
          </div>
          <div className="grid w-5/12 grid-cols-7 text-sm">
            <div className="col-span-3 flex items-center">
              <p className="pl-2 font-bold">
                <span className="capitalize">
                  {commercialTypeDisplayMap[type]}
                </span>{' '}
                applied value
              </p>
            </div>
            {!state.open && (
              <>
                <div className="col-span-1 flex items-center">Actual cost</div>
                <div className="col-span-1 flex items-center">
                  £{totalActualCost.toFixed(2)}
                </div>
                <div className="col-span-1 flex items-center">
                  Applied value
                </div>
                <div className="col-span-1 flex items-center">
                  £{totalAppliedValue.toFixed(2)}
                </div>
              </>
            )}
          </div>
        </div>
        {state.open && (
          <>
            <TableValue />
            <div className="flex">
              <div className="grid w-7/12 grid-cols-8 text-sm">
                <div className="col-span-4 flex h-8 items-center pl-8">
                  <p className="pl-2 font-bold">
                    <span className="capitalize">
                      {commercialTypeDisplayMap[type]}
                    </span>{' '}
                    estimated cost
                  </p>
                </div>
              </div>
              <div className="grid w-5/12 grid-cols-8 text-sm">
                <div className="col-span-3 flex h-8 items-center">
                  <p className="pl-2 font-bold">
                    <span className="capitalize">
                      {commercialTypeDisplayMap[type]}
                    </span>{' '}
                    actual cost
                  </p>
                </div>
              </div>
            </div>
            <TableCost />
          </>
        )}
        {state.empty && (
          <div className="absolute inset-y-0 right-0">
            <Button
              className="size-8 rounded-none p-0"
              variant="ghost"
              onClick={() => {
                sections.remove(type);
                toast.success(`Removed ${commercialTypeDisplayMap[type]}`);
              }}
            >
              <X className="size-4" />
            </Button>
          </div>
        )}
      </div>
      <div className="absolute inset-0 z-[2] flex">
        <div className="grid h-full w-7/12 grid-cols-8">
          <div className="col-span-7" />
          <div className="col-span-1 bg-secondary ring-1 ring-border" />
        </div>
        <div className="mr-[1px] grid h-full w-5/12 grid-cols-6">
          <div className="col-span-3" />
          <div className="col-span-1 ml-[1px] bg-secondary ring-1 ring-border" />
        </div>
      </div>
    </Card>
  );
};

export default ActivityTable;
