import { Route, Routes } from 'react-router-dom';
import { UnderConstruction } from 'lib/shared';
import SupplyChainOverview from './pages/SupplyChainOverview';
import SupplyChainSubcontractors from './pages/SupplyChainSubcontractors';
import SupplyChainSuppliers from './pages/SupplyChainSuppliers';

const SupplyChain = () => {
  return (
    <Routes>
      <Route index element={<SupplyChainOverview />} />
      <Route path="subcontractors/*" element={<SupplyChainSubcontractors />} />
      <Route path="suppliers" element={<SupplyChainSuppliers />} />
      <Route path="plant" element={<UnderConstruction />} />
      <Route path="waste-management" element={<UnderConstruction />} />
    </Routes>
  );
};

export default SupplyChain;
