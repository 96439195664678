import { useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { useRealtime } from 'lib/hooks';
import { PlatformTodo, PlatformTodoGroup } from 'lib/types';
import { platformClient } from '../../app/platform';

export const useTodos = ({
  scope,
  status,
  pagination,
  sort,
  queryKey = ['todos'],
  queryFn,
  groupKey,
}: {
  scope?: string;
  status?: string;
  pagination?: any;
  sort?: any;
  queryKey?: string[];
  queryFn?: any;
  groupKey?: string;
} = {}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const queryClient = useQueryClient();

  const { data, isLoading, refetch } = useQuery<{ results: PlatformTodo[] }>({
    queryKey,
    queryFn:
      queryFn ??
      platformClient.getTodos({
        scope,
        status,
        pagination,
        sort,
      }),
    enabled: !!scope,
  });

  useRealtime('todos', (data) => {
    queryClient.setQueryData(
      queryKey,
      (oldTodos: { results: PlatformTodo[] }) => {
        const existingTodo = oldTodos.results.find(
          (todo) => todo.id === data.id,
        );
        if (existingTodo) {
          return produce(oldTodos, (draft) => {
            Object.assign(
              draft.results.find(({ id }) => id === data.id) as PlatformTodo,
              data,
            );
          });
        } else {
          queryClient.refetchQueries({ queryKey });
        }
      },
    );
  });

  const todos = useMemo(() => data?.results ?? [], [data?.results]);

  const groups: PlatformTodoGroup[] = useMemo(
    () =>
      groupKey
        ? todos
            .filter((todo) => todo.status === status)
            .reduce((acc, todo) => {
              const existingGroup = acc.find(() =>
                todo.scopes.includes(groupKey),
              );
              if (existingGroup) {
                existingGroup.todos.push(todo);
              } else {
                const scope = [...todo.scopes]
                  .sort((a, b) => a.length - b.length)
                  .find((scope) => scope.startsWith(groupKey));
                if (scope) {
                  acc.push({ scope, todos: [todo] });
                }
              }
              return acc;
            }, [] as PlatformTodoGroup[])
        : [],
    [todos, status, groupKey],
  );

  const handleSearchTermOnChange = (nextSearchTerm: string) => {
    setSearchTerm(nextSearchTerm);
  };

  const openTodos = todos.filter((todo) => todo.status === 'OPEN') ?? [];
  return {
    isLoadingTodos: isLoading,
    groups,
    todos,
    openTodos,
    refetchTodos: refetch,
    searchTerm,
    searchTermOnChange: handleSearchTermOnChange,
  };
};
