import { gql, useMutation } from '@apollo/client';
import { useLocationContext } from '../../context';
import { useStore, useWorkspaceHandle } from '../../hooks';

export const useLocationPrecise = () => {
  const { location, handlers } = useLocationContext();

  const { workspace, apply } = useStore((state) => ({
    workspace: state.workspace,
    apply: state.apply,
  }));

  const { isActiveWorkspaceHandle, generateWorkspaceHandle } =
    useWorkspaceHandle();

  const handleOnEdit = () => {
    generateWorkspaceHandle();
  };

  const [update] = useMutation(
    gql`
      mutation UpdateLocationPrecise(
        $locationId: ID
        $locationPrecisePoint: LocationGeometryPointInput!
      ) {
        updateLocationPrecise(
          locationId: $locationId
          locationPrecisePoint: $locationPrecisePoint
        ) {
          locationIsNew
          location {
            id
            precise {
              id
              geometry {
                id
                point {
                  latitude
                  longitude
                }
              }
            }
          }
          locationPreciseIsNew
          locationPrecise {
            id
            geometry {
              id
              point {
                latitude
                longitude
              }
            }
          }
        }
      }
    `,
  );

  const handleOnSave = async () => {
    if (!workspace.geometry) return;

    const { data } = await update({
      variables: {
        locationId: location?.id,
        locationPrecisePoint: {
          latitude: workspace.geometry?.point.latitude,
          longitude: workspace.geometry?.point.longitude,
        },
      },
    });

    if (data) {
      const { locationIsNew } = data.updateLocationPrecise;

      apply((state) => {
        state.workspace = {};
        state.workspaceHandle = undefined;
      });

      if (locationIsNew) {
        handlers.locationOnCreate?.(data.updateLocationPrecise.location);
      } else {
        handlers.locationOnUpdate?.({
          ...location!,
          precise: {
            ...location!.precise!,
            geometry: {
              ...location!.precise!.geometry,
              ...data.updateLocationPrecise.locationPrecise.geometry,
            },
          },
        });
      }
    }
  };

  const handleOnCancel = () => {
    apply((state) => {
      state.workspace = {};
      state.workspaceHandle = undefined;
    });
  };

  const [delete_] = useMutation(
    gql`
      mutation DeleteLocationPrecise($locationId: ID!) {
        deleteLocationPrecise(locationId: $locationId) {
          location {
            id
          }
        }
      }
    `,
  );

  const handleOnDelete = async () => {
    const { data } = await delete_({
      variables: {
        locationId: location?.id,
      },
    });

    if (data) {
      apply((state) => {
        state.workspace = {};
        state.workspaceHandle = undefined;
      });

      handlers.locationOnUpdate?.({
        ...location!,
        precise: undefined,
      });
    }
  };

  return {
    value: location?.precise,
    isActiveWorkspaceHandle,
    workspaceValue: workspace.geometry,
    handleOnEdit,
    handleOnSave,
    handleOnCancel,
    handleOnDelete,
  };
};
