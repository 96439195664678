import React, { useRef } from 'react';
import {
  BryntumGantt,
  BryntumGanttProjectModel,
} from '@bryntum/gantt-react-thin';
import { TaskModelConfig } from '@bryntum/gantt-thin';
import { DateTime } from 'luxon';
import { Job, TimesheetDuty } from 'lib/types';
import { useJobContext } from '../../context';
import { ganttConfig } from './config';

const JobGantt = () => {
  const { job } = useJobContext();

  const gantt = useRef<BryntumGantt>(null);
  const project = useRef<BryntumGanttProjectModel>(null);

  if (job.partial) return null;

  const { reference, dateTimeCreated, graph } = job;

  const processTasks = (tasks: any) => {
    return tasks.map((task: any) => {
      return {
        id: task.id,
        name: task.data.name,
        expanded: true,
        rollup: true,
        children: task.data.timesheetActivity.duties.map(
          (duty: TimesheetDuty) =>
            ({
              id: duty.id,
              name: duty.user?.name,
              readOnly: true,
              startDate: DateTime.fromISO(duty.dateTimeStart).toJSDate(),
              endDate: DateTime.fromISO(duty.dateTimeEnd).toJSDate(),
              manuallyScheduled: true,
            }) as Partial<TaskModelConfig>,
        ),
      } as Partial<TaskModelConfig>;
    });
  };

  const tasks: Partial<TaskModelConfig>[] = [
    {
      id: 'job',
      name: reference,
      expanded: true,
      startDate: dateTimeCreated,
      children: [
        {
          id: 1,
          name: 'Delivery',
          expanded: true,
          children: [
            ...processTasks(graph.nodes.filter((node) => node.type === 'task')),
          ],
        },
      ],
    },
  ];

  return (
    <div className="h-full w-full min-w-0">
      <BryntumGanttProjectModel
        ref={project}
        tasks={tasks}
        readOnly
        dependencies={graph.edges.map((edge) => ({
          id: `${edge.source}-${edge.target}`,
          fromEvent: edge.source,
          toEvent: edge.target,
        }))}
      />
      <BryntumGantt {...ganttConfig} ref={gantt} project={project} />
    </div>
  );
};

export default JobGantt;
