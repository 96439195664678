import React, { useEffect, useState } from 'react';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  FilterFnOption,
  OnChangeFn,
  Row,
  RowSelectionState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ArrowUpIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { cn } from '@/lib/utils';

export const selectColumn = (): ColumnDef<any> => ({
  id: 'select',
  header: ({ table }) => (
    <Checkbox
      checked={
        table.getIsAllPageRowsSelected() ||
        (table.getIsSomePageRowsSelected() && 'indeterminate')
      }
      onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
      aria-label="Select all"
    />
  ),
  cell: ({ row }) => (
    <Checkbox
      checked={row.getIsSelected()}
      onCheckedChange={(value) => row.toggleSelected(!!value)}
      aria-label="Select row"
    />
  ),
  enableSorting: false,
  enableHiding: false,
});

export function TableRenderer<Data extends Record<string, any> = any>({
  simple = false,
  small = false,
  header = true,
  filter,
  filterClassName,
  filterPlaceholder = 'Filter...',
  filterFns,
  globalFilterFn = 'auto',
  columns: providedColumns,
  data = [],
  topRight,
  selected,
  onSelectedChange,
  renderRow,
  padHeader = false,
  initialSorting = [],
  wrapperClassName,
  headerClassName,
}: {
  simple?: boolean;
  small?: boolean;
  header?: boolean;
  filter?: keyof Data;
  filterClassName?: string;
  filterPlaceholder?: string;
  filterFns?: Record<string, FilterFn<Data>>;
  columns: (ColumnDef<Data> & { className?: string })[];
  data: Data[];
  globalFilterFn?: FilterFnOption<Data>;
  topRight?: React.FC<{ columnsSelector: React.ReactNode }>;
  selected?: RowSelectionState;
  onSelectedChange?: OnChangeFn<RowSelectionState>;
  renderRow?: (row: Row<Data>) => React.ReactNode;
  padHeader?: boolean;
  initialSorting?: SortingState;
  wrapperClassName?: string;
  headerClassName?: string;
}) {
  const [columns] = useState<ColumnDef<Data>[]>(providedColumns);
  const [sorting, setSorting] = useState<SortingState>(initialSorting);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: onSelectedChange,
    getRowId: (row) => row.id,
    filterFns,
    globalFilterFn,
    state: {
      sorting,
      globalFilter,
      columnFilters,
      columnVisibility,
      rowSelection: selected ?? {},
    },
  });

  useEffect(() => {
    table.setPageSize(25);
  }, []);

  const columnsSelector = simple ? null : (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="ml-auto" size="sm">
          Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {table
          .getAllColumns()
          .filter((column) => column.getCanHide())
          .map((column) => {
            return (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) =>
                  column.toggleVisibility(Boolean(value))
                }
              >
                {column.id}
              </DropdownMenuCheckboxItem>
            );
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  );

  return (
    <div className="flex h-full w-full flex-col">
      {header && (
        <div className="flex items-center py-4">
          {filter && (
            <Input
              placeholder={filterPlaceholder}
              value={globalFilter}
              onChange={(event) => {
                setGlobalFilter(event.target.value);
              }}
              className={cn('h-8', filterClassName, {
                'w-full': small,
                'max-w-sm': !small,
              })}
            />
          )}
          {topRight ? topRight({ columnsSelector }) : columnsSelector}
        </div>
      )}
      <div className={cn(wrapperClassName, 'min-h-0 grow rounded-md border')}>
        <ScrollArea className="h-full min-h-0 w-full">
          <Table className="h-full min-h-0 w-full">
            <TableHeader className={cn(headerClassName)}>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {padHeader && <TableCell className="w-0" />}
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id}
                        className={cn(
                          { 'w-0': header.id === 'select' },
                          // @ts-ignore
                          header.column.columnDef.className,
                        )}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            className={
                              header.column.getCanSort()
                                ? 'flex cursor-pointer select-none items-center gap-x-2'
                                : ''
                            }
                            onClick={header.column.getToggleSortingHandler()}
                            title={
                              header.column.getCanSort()
                                ? header.column.getNextSortingOrder() === 'asc'
                                  ? 'Sort ascending'
                                  : header.column.getNextSortingOrder() ===
                                      'desc'
                                    ? 'Sort descending'
                                    : 'Clear sort'
                                : undefined
                            }
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {header.column.getIsSorted() && (
                              <ArrowUpIcon
                                className={cn('h-4 w-4 transition-all', {
                                  'rotate-180':
                                    header.column.getIsSorted() === 'desc',
                                })}
                              />
                            )}
                          </div>
                        )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows.length ? (
                table.getRowModel().rows.map((row) =>
                  renderRow ? (
                    renderRow(row)
                  ) : (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && 'selected'}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          // @ts-ignore
                          className={cell.column.columnDef.className}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ),
                )
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ScrollArea>
      </div>
      {!simple && (
        <div className="flex items-center justify-end space-x-2 py-4">
          {selected && (
            <div className="text-xs text-muted-foreground">
              {table.getFilteredSelectedRowModel().rows.length} of{' '}
              {table.getFilteredRowModel().rows.length} row(s) selected.
            </div>
          )}
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
}
