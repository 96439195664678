import React, { useState } from 'react';
import { BryntumSchedulerProProps } from '@bryntum/schedulerpro-react-thin';
import { ViewPreset } from '@bryntum/scheduler-thin';
import {
  ColumnRenderer,
  EventRenderer,
  EventTooltipRenderer,
} from './renderers';

export const useConfig = () => {
  return useState<BryntumSchedulerProProps>({
    height: '100%',
    viewPreset: new ViewPreset({
      id: 'myPreset',
      name: 'My view preset',
      tickWidth: 96,
      tickHeight: 24,
      displayDateFormat: 'HH:mm',
      shiftIncrement: 1,
      shiftUnit: 'day',
      defaultSpan: 24,
      timeResolution: {
        unit: 'minute',
        increment: 5,
      },
      headers: [
        {
          unit: 'hour',
          dateFormat: 'HH:mm',
        },
      ],
      columnLinesFor: 0,
    }),
    eventDragCreateFeature: false,
    cellMenuFeature: false,
    scheduleMenuFeature: false,
    eventMenuFeature: false,
    timeAxisHeaderMenuFeature: false,
    createEventOnDblClick: false,
    dependenciesFeature: false,
    dependencyEditFeature: false,
    percentBarFeature: false,
    resourceTimeRangesFeature: {
      enableMouseEvents: true,
    },
    cellEditFeature: false,
    splitFeature: false,
    zoomOnMouseWheel: false,
    zoomOnTimeAxisDoubleClick: false,
    rowHeight: 100,
    resourceMargin: 24,
    columns: [
      {
        text: 'Name',
        field: 'name',
        width: 160,
        renderer: ({ record, row }: any) => (
          <ColumnRenderer key={record.id} record={record} row={row} />
        ),
      },
    ],
    eventRenderer: ({ eventRecord, assignmentRecord }) => (
      <EventRenderer key={assignmentRecord.id} eventRecord={eventRecord} />
    ),
    eventTooltipFeature: {
      allowOver: true,
      align: 't-b',
      template: (props: any) => <EventTooltipRenderer {...props} />,
    },
    scheduleTooltipFeature: false,
    cellTooltipFeature: false,
  })[0];
};
