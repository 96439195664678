import React from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { CheckCheck, CheckSquare, Square } from 'lucide-react';
import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/lib/utils';
import { useFormTemplateContext } from '../../context';
import BlockButton from '../BlockButton';
import { BlockToggleProps } from './types';

const BlockToggle = ({
  children,
  className,
  item,
  toggle,
  ...props
}: BlockToggleProps) => {
  const { store } = useFormTemplateContext();
  const { items } = useSyncedStore(store);

  const handleOnClick = () => {
    (items[item.id].data as any)[toggle] = !items[item.id].data[toggle];
  };

  return (
    <BlockButton
      {...props}
      className={cn('gap-x-2', className)}
      onClick={handleOnClick}
    >
      {items[item.id].data[toggle] ? (
        <CheckSquare className="size-4" />
      ) : (
        <Square className="size-4" />
      )}
      {children}
    </BlockButton>
  );
};

export default BlockToggle;
