import { Button } from '@/components/ui/button';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { z } from 'zod';
import { useEstimatesCopy } from './hooks';

const EstimatesCopy = () => {
  const { sheet, handlers } = useEstimatesCopy();
  return (
    <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
      <SheetTrigger asChild>
        <Button className="h-7" variant="outline" size="sm">
          Copy to new
        </Button>
      </SheetTrigger>
      <SheetContent overlay>
        <SheetHeader>
          <SheetTitle></SheetTitle>
          <SheetDescription></SheetDescription>
        </SheetHeader>
        <FormRenderer
          fields={[
            {
              type: 'input',
              label: 'Name',
              name: 'name',
              schema: z.string(),
            },
          ]}
          onSubmit={handlers.onSubmit}
        >
          {({ onSubmit }) => (
            <div>
              <Button onClick={onSubmit}>Copy</Button>
            </div>
          )}
        </FormRenderer>
      </SheetContent>
    </Sheet>
  );
};

export default EstimatesCopy;
