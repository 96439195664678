import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { AsyncContext } from 'lib/shared';
import { useEstimatesDelete } from './hooks';

const EstimatesDelete = () => {
  const { dialog, handlers } = useEstimatesDelete();
  return (
    <Dialog open={dialog.open} onOpenChange={dialog.onOpenChange}>
      <DialogTrigger asChild>
        <Button className="h-7" variant="destructive" size="sm">
          Delete estimate
        </Button>
      </DialogTrigger>
      <DialogContent overlay>
        <DialogHeader>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogDescription>
            Please confirm that you want to delete this estimate. This action
            will also delete all associated value and cost lines.
          </DialogDescription>
        </DialogHeader>
        <AsyncContext handle={handlers.onDelete}>
          {({ loading, handle }) => (
            <div className="flex justify-between">
              {!loading ? (
                <Button size="sm" variant="outline" onClick={handlers.onCancel}>
                  Cancel
                </Button>
              ) : (
                <div />
              )}
              <Button size="sm" variant="destructive" onClick={handle}>
                Delete estimate
              </Button>
            </div>
          )}
        </AsyncContext>
      </DialogContent>
    </Dialog>
  );
};

export default EstimatesDelete;
