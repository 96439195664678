import { KeyboardEvent, useState } from 'react';
import { ValueBase } from './types';

export const useTableSelector = (
  onChange: (value: ValueBase['id'], mode: string) => Promise<void>,
  onOpen: (() => void) | undefined,
  searchDefault: string | undefined,
) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(searchDefault || '');
  const [loading, setLoading] = useState(false);

  const handlePopoverOnOpenChange = (nextOpen: boolean) => {
    setOpen(nextOpen);
  };

  const handlePopoverOnOpen = async () => {
    setOpen(true);
    onOpen?.();
  };

  const handlePopoverOnClose = () => {
    setOpen(false);
  };

  const handleSearchOnValueChange = (value: string) => {
    setSearch(value);
  };

  const handleOnChange = async (id: string, mode: string) => {
    setLoading(true);
    await onChange(id, mode);
    setLoading(false);
    setOpen(false);
  };

  const handleCommandOnSelect = (id: string) => {
    return handleOnChange(id, 'select');
  };

  const handleCommandOnKeyDown = async (
    event: KeyboardEvent<HTMLInputElement>,
  ) => {
    switch (event.key) {
      case 'Enter':
        break;
      case 'Escape':
        if (!loading) {
          setOpen(false);
        }
        break;
      default:
        break;
    }
  };

  return {
    state: {
      loading,
    },
    popover: {
      open,
      onOpen: handlePopoverOnOpen,
      onClose: handlePopoverOnClose,
      onOpenChange: handlePopoverOnOpenChange,
    },
    command: {
      onSelect: handleCommandOnSelect,
      onKeyDown: handleCommandOnKeyDown,
    },
    search: {
      value: search,
      onValueChange: handleSearchOnValueChange,
    },
  };
};
