import { createContext, useContext } from 'react';
import { useRateQualifications } from './hooks';

export const RateQualificationsContext = createContext(
  {} as ReturnType<typeof useRateQualifications>['context'],
);

export const useRateQualificationsContext = () => {
  return useContext(RateQualificationsContext);
};
