import { Plus } from 'lucide-react';
import { CommandItem } from '@/components/ui/command';
import { cn } from '@/lib/utils';
import { TableSelector } from 'lib/shared';
import { CommercialType } from 'lib/types';
import { useActivityTableContext } from '../../../../context';
import { useCostCreate } from './hooks';

const CostCreate = () => {
  const { type } = useActivityTableContext();
  const { resources, onCreate } = useCostCreate();
  return (
    <div className="flex">
      <div className="grid w-7/12 grid-cols-8 border-b">
        <div className="col-span-3 flex border-r">
          <div className="flex w-8 flex-shrink-0 items-center justify-center border-r">
            <Plus className="-mr-0.5 size-4" />
          </div>
          {(type === CommercialType.Prelim ||
            type === CommercialType.Labor ||
            type === CommercialType.Subcontractor) && (
            <TableSelector
              placeholder="Add new line"
              popoverClassName={cn({
                'border-labor': type === CommercialType.Labor,
                'border-subcontractor': type === CommercialType.Subcontractor,
              })}
              value={undefined}
              valueDisplay={undefined}
              searchPlaceholder="Search resources"
              onChange={onCreate}
            >
              {({ disabled, onSelect }) =>
                resources.map((resource) => (
                  <CommandItem
                    key={resource.id}
                    value={resource.id}
                    keywords={[resource.user.name]}
                    className="cursor-pointer"
                    disabled={disabled}
                    onSelect={onSelect}
                  >
                    <div className="flex w-full items-center justify-between">
                      <p className="text-[13px]">{resource.user.name}</p>
                    </div>
                  </CommandItem>
                ))
              }
            </TableSelector>
          )}
          {type === CommercialType.Material && (
            <TableSelector
              className="text-sm"
              popoverClassName="border-material/75"
              placeholder="Add new line"
              searchPlaceholder="Search materials"
              value={undefined}
              valueDisplay={undefined}
              onChange={async () => {}}
            >
              {({ onSelect }) => null}
            </TableSelector>
          )}
          {type === CommercialType.Equipment && (
            <TableSelector
              placeholder="Add new line"
              popoverClassName="border-equipment"
              searchPlaceholder="Search equipment"
              value={undefined}
              valueDisplay={undefined}
              onChange={async () => {}}
            >
              {({ onSelect }) => null}
            </TableSelector>
          )}
        </div>
        <div className="col-span-1 border-r" />
        <div className="col-span-1 border-r" />
        <div className="col-span-1 border-r" />
        <div className="col-span-1" />
        <div className="col-span-1" />
      </div>
      <div className="grid w-5/12 grid-cols-6 border-b">
        <div className="col-span-1 border-r" />
        <div className="col-span-1 border-r" />
        <div className="col-span-1" />
        <div className="col-span-2" />
      </div>
    </div>
  );
};

export default CostCreate;
