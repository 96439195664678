import { gql, useQuery } from '@apollo/client';
import { useStore } from './state';

export const useInitialQuery = () => {
  return useQuery(
    gql`
      query {
        rosterTeams {
          id
          name
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      onCompleted: useStore((state) => state.initialQueryOnCompleted),
    },
  );
};
