import { createContext, useContext } from 'react';
import { useCompanyStaff } from './hooks';

export const CompanyStaffContext = createContext(
  {} as ReturnType<typeof useCompanyStaff>['context'],
);

export const useCompanyStaffContext = () => {
  return useContext(CompanyStaffContext);
};
