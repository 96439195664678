import React, { useMemo } from 'react';
import { AlertTriangle } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import { buttonVariants } from '@/components/ui/button';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Action, Actions } from 'external';
import { toast } from 'sonner';
import { z } from 'zod';
import { CommercialContract } from 'lib/types';
import { useClient } from '../../../Client/hooks';
import JobLink from '../../components/JobLink';
import { useJobContext } from '../../context';

const JobSettings = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const client = useClient();

  const { job } = useJobContext();

  const items = useMemo(
    () => [
      {
        title: 'General',
        href: 'settings',
      },
      {
        title: 'Commercial',
        href: 'settings/-/commercial',
      },
      {
        title: 'Danger Zone',
        href: 'settings/danger',
      },
    ],
    [],
  );

  if (job.partial) return null;

  return (
    <Actions
      queryKey={[job.id, 'settings']}
      actionSetId={job.actionSetId!}
      scope={`jobs::job::${job.id}`}
      context={{
        event: {
          data: {
            delete: true,
            job: {
              id: job.id,
              reference: job.reference,
            },
          },
        },
      }}
    >
      <div className="mt-8 grid grid-cols-12">
        <div />
        <div className="col-span-10">
          <div className="space-y-0.5">
            <h2 className="text-2xl font-bold">Settings</h2>
            <p className="text-sm text-muted-foreground">
              Manage your account settings and set e-mail preferences.
            </p>
          </div>
          <Separator className="my-4" />
        </div>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-1" />
        <nav className="col-span-2 flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1">
          {items.map((item) => (
            <JobLink
              key={item.href}
              to={item.href as string}
              className={cn(
                buttonVariants({ variant: 'ghost' }),
                pathname.endsWith(item.href)
                  ? 'bg-muted hover:bg-muted'
                  : 'hover:bg-transparent hover:underline',
                'justify-start',
              )}
            >
              {item.title}
            </JobLink>
          ))}
        </nav>
        <div className="container col-span-6 flex max-w-screen-lg flex-col gap-y-8">
          <Routes>
            <Route
              index
              element={
                <FormRenderer
                  fields={[
                    {
                      name: 'reference',
                      label: 'Reference',
                      type: 'input',
                      disabled: true,
                      defaultValue: job.reference,
                      description:
                        'Client provided reference. This cannot be changed.',
                      schema: z.string(),
                    },
                    {
                      name: 'description',
                      label: 'Description',
                      description: 'Client provided description.',
                      type: 'textarea',
                      disabled: true,
                      defaultValue: job.description,
                      schema: z.string(),
                    },
                  ]}
                >
                  {({}) => null}
                </FormRenderer>
              }
            />
            <Route
              path="-/commercial"
              element={
                <div className="flex flex-col gap-y-4">
                  <div>
                    <ContractSelector />
                  </div>
                </div>
              }
            />
            <Route
              path="danger"
              element={
                <div className="flex flex-col gap-y-4">
                  <div className="rounded-lg bg-destructive p-4">
                    <p className="mb-2 flex gap-x-2">
                      <AlertTriangle className="" />
                      Warning
                    </p>
                    <p className="text-sm">
                      The settings in this section are permanent and cannot be
                      undone. Please proceed with caution.
                    </p>
                  </div>
                  <div>
                    <Label>Delete </Label>
                    <Action
                      id="delete"
                      small
                      onSuccess={async () => {
                        await client.jobs.refetch();
                        navigate(`/clients/${job.client.slug}/jobs`);
                      }}
                    />
                  </div>
                </div>
              }
            />
          </Routes>
        </div>
      </div>
    </Actions>
  );
};

const ContractSelector = () => {
  const { job } = useJobContext();

  const { data } = useQuery<{ commercialContracts: CommercialContract[] }>(
    gql`
      query GetCommercialContracts($clientId: ID!) {
        commercialContracts(clientId: $clientId) {
          id
          name
          description
          dateStart
          dateEnd
        }
      }
    `,
    {
      variables: job.partial
        ? undefined
        : {
            clientId: job.client.id,
          },
    },
  );

  const [update] = useMutation(
    gql`
      mutation UpdateJobCommercialContract($id: ID!, $contract: String!) {
        updateJob(
          jobId: $id
          field: "commercial_contract_id"
          value: $contract
        ) {
          job {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnValueChange = async (value: string) => {
    if (job.partial) return;

    const { data, errors } = await update({
      variables: {
        id: job.id,
        contract: value,
      },
    });

    if (data) {
      await job.refetch();
      toast.success('Contract updated');
    }

    if (errors) {
      toast.error('Failed to update contract');
    }
  };

  if (job.partial || !data) return null;

  return (
    <Select
      value={job.commercialActivity?.contract?.id}
      onValueChange={handleOnValueChange}
    >
      <SelectTrigger className="h-[34px] w-auto">
        <div className="flex h-[34px] items-center gap-x-1 pr-2 text-xs">
          <p className="text-gray-400 dark:text-white/50">Select contract: </p>
          <SelectValue placeholder="No contract selected" />
        </div>
      </SelectTrigger>
      <SelectContent>
        {data.commercialContracts.map((contract) => (
          <SelectItem key={contract.id} value={contract.id}>
            {contract.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default JobSettings;
