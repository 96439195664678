import React from 'react';
import { cn } from '@/lib/utils';
import { useLocationPickerContext } from '../../hooks';

const PickerEmpty = () => {
  const { estimation } = useLocationPickerContext();
  return (
    <div
      className={cn('absolute inset-0 z-10 opacity-100 transition-all', {
        'pointer-events-none opacity-0': estimation.results.length > 0,
      })}
    >
      <div className="flex h-full w-full items-center justify-center rounded-md border border-dashed bg-background">
        <div>
          <p className="dark:text-white-400 text-xs text-gray-400">
            No results found
          </p>
        </div>
      </div>
    </div>
  );
};

export default PickerEmpty;
