import { DataType } from 'external/FormTemplateEditor';
import { DateTime } from 'luxon';
import { Client } from './clients';
import { User } from './users';


export type Event = {
  id: string;
  name: string;
  nameDisplay?: string;
  context: string;
  instance: string;
  dateTime: DateTime;
  data: EventSynchronousData;
  client: Client;
  user: User;
  synchronous: boolean;
  synchronousData?: EventSynchronousData[];
};

export type EventSynchronousData = {
  result: {
    status: string;
    nodes: Record<
      string,
      {
        id: string;
        description: string;
      }
    >;
    resultOrder: string[];
    results: Record<string, { dataType: DataType; value: any }>;
    return: { dataType: DataType; value: any };
  };
};
