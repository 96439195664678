import React from 'react';
import { ShieldCheck, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Image } from '@/components/ui/image';
import { usePlatformObject } from 'lib/hooks';
import { Empty } from 'lib/shared';
import { CommercialContractRateQualification } from 'lib/types';
import { useContractRateContext } from '../../context';
import QualificationsAdd from './components/QualificationsAdd';
import QualificationsAddNested from './components/QualificationsAddNested';
import {
  RateQualificationsContext,
  useRateQualificationsContext,
} from './context';
import { useRateQualifications } from './hooks';

const RateQualifications = () => {
  const { rate } = useContractRateContext();
  const { context } = useRateQualifications();

  if (!rate) {
    return null;
  }

  return (
    <RateQualificationsContext.Provider value={context}>
      {rate.qualifications.length > 0 ? (
        <div className="flex flex-col gap-y-4">
          {rate.qualifications.map((qualification) => (
            <QualificationsListItem
              key={qualification.id}
              qualification={qualification}
            />
          ))}
        </div>
      ) : (
        <Empty className="h-20">No qualifications set</Empty>
      )}
      <div className="mt-4">
        <QualificationsAdd />
      </div>
    </RateQualificationsContext.Provider>
  );
};

const QualificationsListItem = ({
  qualification,
}: {
  qualification: CommercialContractRateQualification;
}) => {
  const { handlers } = useRateQualificationsContext();
  const { object } = usePlatformObject(qualification.value.logo.id);
  return (
    <div className="w-full rounded-lg border bg-background-secondary px-4 pb-2 pt-4">
      <div className="mb-2 flex items-center justify-between">
        <div className="flex items-center gap-x-2">
          <Image className="size-6" src={object?.getUrl} />
          <p className="font-semibold">{qualification.value.name}</p>
        </div>
      </div>
      <div className="mb-2">
        <p className="line-clamp-2 text-xs leading-relaxed dark:text-gray-400">
          {qualification.value.description}
        </p>
      </div>
      <div className="flex items-center justify-between">
        <QualificationsAddNested qualification={qualification.value} />
        <Button
          className="p-0"
          variant="link"
          size="sm"
          confirm="Are you sure you want to remove this qualification?"
          onClick={handlers.onDelete(qualification)}
        >
          <X className="mr-1 h-4 w-4" />
          Remove
        </Button>
      </div>
    </div>
  );
};

export default RateQualifications;
