import { useCallback, useEffect } from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { BlockData, ContainerRepeatingBranchData, Item } from 'external/types';
import { useUser } from 'lib/hooks';
import { PlatformObject } from 'lib/types';
import { generateId } from 'lib/utils';

export const useForm = (
  id: any,
  store: {
    values: Record<string, any>;
    objects: Record<string, Omit<PlatformObject, 'sources'>[]>;
    meta: any;
  },
  setDefaults: any,
  onSubmit?: any,
) => {
  const user = useUser();

  const state = useSyncedStore(store);
  const { values, objects, meta } = state;
  useEffect(() => {
    setDefaults?.(state);
  }, [setDefaults, state]);

  const handleGetSubmission = useCallback(() => {
    return {
      id,
      submission: JSON.parse(JSON.stringify({ values, objects, meta })),
    };
  }, [id, objects, values]);

  const addCollaborator = useCallback(() => {
    try {
      const userData = {
        id: user?.id,
        name: user?.name,
        email: user?.email,
        initials: user?.initials,
      };

      if (!('collaborators' in meta)) {
        meta.collaborators = [userData];
      } else {
        if (
          !meta.collaborators
            .map(({ id: inner }: any) => inner)
            .includes(userData.id)
        ) {
          meta.collaborators.push(userData);
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }, [meta, user?.email, user?.id, user?.initials, user?.name]);

  const handleFieldValueOnChange = useCallback(
    (item: Item<BlockData>, value: any) => {
      values[item.path] = value;
      addCollaborator();
    },
    [addCollaborator, values],
  );

  const handleFieldMetaOnChange = useCallback(
    (item: Item<BlockData>, value: any) => {
      meta[item.path] = value;
    },
    [meta],
  );

  const handleRepeatingBranchOnCreate = useCallback(
    (item: Item<ContainerRepeatingBranchData>) => {
      addCollaborator();

      if (!(item.path in values)) {
        values[item.path] = { dataType: 'ARRAY', value: [generateId()] };
      } else {
        values[item.path].value.push(generateId());
      }
    },
    [addCollaborator, values],
  );

  const handleRepeatingBranchOnDelete = useCallback(
    (item: Item<ContainerRepeatingBranchData>) => {
      addCollaborator();

      const parentPath = item.path.split('::').slice(0, -1).join('::');
      const index = values[parentPath].value.indexOf(item.data.branchId);
      values[parentPath].value.splice(index, 1);
    },
    [addCollaborator, values],
  );

  const handleObjectsOnCreate = useCallback(
    (item: Item<BlockData>, newObjects: PlatformObject[]) => {
      addCollaborator();

      const serializedObjects = newObjects.map(
        (object) =>
          ({
            id: object.id,
            type: object.type,
            size: object.size,
            name: object.name,
            status: 'UPLOADING' as const,
            data: object.data,
          }) as PlatformObject,
      );

      if (!(item.path in objects)) {
        objects[item.path] = serializedObjects;
        return;
      }

      objects[item.path].push(...serializedObjects);
    },
    [addCollaborator, objects],
  );

  const handleObjectOnUploadProgress = useCallback(
    (
      item: Item<BlockData>,
      objectId: PlatformObject['id'],
      progressPercentage: number,
    ) => {
      const index = objects[item.path]
        .map(({ id: innerObjectId }) => innerObjectId)
        .indexOf(objectId);
      objects[item.path][index].progressPercentage = progressPercentage;
    },
    [],
  );

  const handleObjectOnUpload = useCallback(
    (item: Item<BlockData>, newObjectId: PlatformObject['id']) => {
      const index = objects[item.path]
        .map(({ id: objectId }) => objectId)
        .indexOf(newObjectId);
      objects[item.path][index].status = 'UPLOADED';
    },
    [objects],
  );

  const handleObjectOnDelete = useCallback(
    (
      item: Item<BlockData>,
      object: PlatformObject | Omit<PlatformObject, 'sources'>,
    ) => {
      addCollaborator();

      if (object.isNew || object.isPending) return;
      const index = objects[item.path]
        .map(({ id: objectId }) => objectId)
        .indexOf(object.id);
      objects[item.path].splice(index, 1);
    },
    [addCollaborator, objects],
  );

  const handleOnComplete = useCallback(() => {
    meta.isCompleted = true;
  }, [meta]);

  const handleOnEdit = useCallback(() => {
    meta.isCompleted = false;
    meta.isApproved = false;
  }, [meta]);

  const handleOnSubmit = useCallback(() => {
    onSubmit?.(values);
  }, [onSubmit, values]);

  return {
    handleFieldValueOnChange,
    handleFieldMetaOnChange,
    handleRepeatingBranchOnCreate,
    handleRepeatingBranchOnDelete,
    handleObjectsOnCreate,
    handleObjectOnUploadProgress,
    handleObjectOnUpload,
    handleObjectOnDelete,
    handleOnComplete,
    handleOnEdit,
    handleGetSubmission,
    handleOnSubmit,
    meta,
    objects,
    values,
  };
};
