import React from 'react';
import { DropzoneState } from 'react-dropzone';
import { ChoiceSet, DataType } from 'external/FormTemplateEditor';
import { Item } from 'external/types';
import { PlatformObject, PlatformObjectData } from 'lib/types';

export type Template = {
  items: Record<string, Item>;
  choiceSets: Record<string, ChoiceSet>;
  fieldLabels: Record<string, any>;
};

export type ValueMap = {
  [DataType.ARRAY]: any;
  [DataType.BOOLEAN]: boolean;
  [DataType.CHOICE]: any;
  [DataType.CHOICE_SELECTION]: any;
  [DataType.CHOICE_SET]: any;
  [DataType.CHAR]: string;
  [DataType.DATETIME]: string;
  [DataType.DURATION]: string;
  [DataType.MAPPING]: any;
  [DataType.MEDIA]: any;
  [DataType.NUMBER]: number;
  [DataType.OBJECT]: any;
  [DataType.UNDEFINED]: undefined;
};

export type Value<D extends DataType = DataType> = {
  dataType: D;
  value: any;
};

export type RenderFormFieldProps = {
  readOnly: boolean;
  item: Item;
  value: Value;
  label: React.ReactNode;
  field: React.ReactNode;
  media: React.ReactNode;
  inner: React.ReactNode;
  isLast: boolean;
  dropzone: DropzoneState;
  onChange: FormFieldValueOnChange;
  metaOnChange: FormFieldMetaOnChange;
};

export type RenderFormField = React.FC<RenderFormFieldProps>;

export type FormFieldValueOnChange = (
  item: Item<BlockData>,
  value: { value: any; dataType: DataType },
) => void;

export type FormFieldMetaOnChange = (item: Item<BlockData>, value: any) => void;

export type FormRendererProps = {
  name?: string;
  template: Template;
  values: Record<string, any>;
  objects: Record<string, Omit<PlatformObject, 'sources'>[]>;
  meta: {
    isCompleted: boolean;
    isPristine: boolean;
    isLocked: boolean;
    contributors: any[];
    signature: null | {
      dateTime: string;
      height: number;
      width: number;
      name: string;
      svg: string;
    };
  };
  readOnly?: boolean;
  reviewMode?: boolean;
  disableHeader?: boolean;
  disableFooter?: boolean;
  disableMedia?: boolean;
  disableCompletion?: boolean;
  fieldExtensions?: Record<string, any>;
  renderField: RenderFormField;
  fieldValueOnChange: FormFieldValueOnChange;
  fieldMetaOnChange: FormFieldMetaOnChange;
  repeatingBranchOnCreate: (item: Item<ContainerRepeatingBranchData>) => void;
  repeatingBranchOnDelete: (item: Item<ContainerRepeatingBranchData>) => void;
  objectsOnCreate: (item: Item<BlockData>, objects: PlatformObject[]) => void;
  objectOnUpload: (
    item: Item<BlockData>,
    objectId: PlatformObject['id'],
  ) => void;
  objectOnUploadProgress: (
    item: Item<BlockData>,
    objectId: PlatformObject['id'],
    progressPercentage: number,
  ) => void;
  objectOnDelete: (
    item: Item<BlockData>,
    object: PlatformObject | Omit<PlatformObject, 'sources'>,
  ) => void;
  onComplete: () => void;
  onUndo: () => void;
  onRedo: () => void;
};

export type FormContextType = Pick<
  FormRendererProps,
  | 'values'
  | 'objects'
  | 'meta'
  | 'readOnly'
  | 'template'
  | 'reviewMode'
  | 'disableMedia'
  | 'renderField'
  | 'fieldExtensions'
  | 'fieldValueOnChange'
  | 'fieldMetaOnChange'
  | 'repeatingBranchOnCreate'
  | 'repeatingBranchOnDelete'
  | 'objectsOnCreate'
  | 'objectOnUpload'
  | 'objectOnUploadProgress'
  | 'objectOnDelete'
>;

export type FormState = {
  values: Record<string, any>;
  objects: Record<string, Omit<PlatformObject, 'sources'>[]>;
};

export type ObjectsQueryVariables = {
  objectIds: string[];
};

export type ObjectsQueryData = {
  objects: PlatformObjectData[];
};
