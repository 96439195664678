import { TableCell, TableRow } from '@/components/ui/table';
import { TimesheetLineProps } from './types';

const TimesheetLine = ({ row }: TimesheetLineProps) => {
  return (
    <TableRow>
      <TableCell>Test</TableCell>
    </TableRow>
  );
};

export default TimesheetLine;
