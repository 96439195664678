import React from 'react';
import { useReportContext } from '../../context';
import ReportText from '../ReportText';
import ReportView from '../ReportView';
import { ReportSectionProps } from './types';

const ReportSection = ({
  break: break_ = false,
  title,
  subtitle,
  children,
  media,
}: ReportSectionProps) => {
  const { theme } = useReportContext();
  return (
    <ReportView
      style={{ paddingLeft: 32, paddingRight: 32 }}
      wrap={false}
      break={break_}
      bookmark={{ title }}
    >
      <ReportView
        style={{
          borderRadius: 8,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme.colors.border,
        }}
      >
        <ReportView
          style={{
            padding: 12,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: theme.colors.border,
          }}
        >
          <ReportText
            style={{
              color: theme.colors.title,
              fontSize: 12,
              fontWeight: 600,
              lineHeight: '1.25',
            }}
          >
            {title}
          </ReportText>
          {subtitle && (
            <ReportText
              style={{
                marginTop: 4,
                lineHeight: '1.25',
                fontSize: 10,
                color: theme.colors.subtitle,
              }}
            >
              {subtitle}
            </ReportText>
          )}
        </ReportView>
        {children}
        {media}
      </ReportView>
    </ReportView>
  );
};

export default ReportSection;
