import { createContext, useContext } from 'react';
import { useTrainingStaffMember } from './hooks';

export const TrainingStaffMemberContext = createContext(
  {} as ReturnType<typeof useTrainingStaffMember>['context'],
);

export const useTrainingStaffMemberContext = () => {
  return useContext(TrainingStaffMemberContext);
};
