import React, { useEffect } from 'react';
import parse, { Element } from 'html-react-parser';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import DOMPurify from 'dompurify';
import { Conditional, Link, UserLabel } from 'lib/shared';
import { User } from 'lib/types';
import { useScopeContext } from '../ScopeProvider/context';
import {
  ScopeLabelButtonLinkProps,
  ScopeLabelProps,
  ScopeLabelRendererProps,
} from './types';

const map: Record<string, string> = {
  subcontractor: 'subcontractors::subcontractor',
  user: 'users::user',
  job: 'jobs::job',
  'job-task': 'jobs::job-task',
  'job-task-form': 'jobs::job-task-form',
  'job-issue': 'jobs::job-issue',
  'job-issue-item': 'jobs::job-issue-item',
  'job-report': 'jobs::job-report',
  'job-variation': 'jobs::job-variation',
};

const ScopeLabel = ({ className, label, link }: ScopeLabelProps) => {
  const safeHtml = DOMPurify.sanitize(label, {
    ALLOWED_TAGS: [
      'job',
      'job-task',
      'job-task-form',
      'job-issue',
      'job-issue-item',
      'job-report',
      'job-variation',
      'subcontractor',
      'user',
    ],
    ALLOW_DATA_ATTR: true,
  });

  const parsed = parse(safeHtml, {
    replace: (domNode) => {
      if (!(domNode instanceof Element)) return domNode;
      return (
        <ScopeLabelRenderer
          className={className}
          node={domNode}
          id={domNode.attribs['data-id']}
          link={link}
        />
      );
    },
  });

  return <>{parsed as React.JSX.Element}</>;
};

const ScopeLabelRenderer = ({
  className,
  node,
  id,
  link = true,
  small = true,
}: ScopeLabelRendererProps) => {
  const { scopeData, registerScopes } = useScopeContext();

  const scopeBase = map[node.name];
  const scope = `${scopeBase}::${id}`;

  useEffect(() => {
    registerScopes([scope]);
  }, []);

  const data = scopeData[scope];

  if (node.name === 'subcontractor') {
    return (
      <>
        subcontractor{' '}
        <Conditional
          wrapper={<ScopeLabelButtonLink className={className} to={data?.to} />}
          default={<span className={className} />}
          wrap={link}
        >
          {data?.label}
        </Conditional>
      </>
    );
  }

  if (node.name === 'user') {
    return <UserLabel small={small} user={{ id } as User} />;
  }

  if (node.name === 'subcontractor') {
    return (
      <Conditional
        wrapper={<ScopeLabelButtonLink className={className} to={data?.to} />}
        default={<span className={className} />}
        wrap={link}
      >
        Subcontractor [{data?.label}]
      </Conditional>
    );
  }

  if (node.name === 'job') {
    return (
      <Conditional
        wrapper={<ScopeLabelButtonLink className={className} to={data?.to} />}
        default={<span className={className} />}
        wrap={link}
      >
        Job [{data?.label}]
      </Conditional>
    );
  }

  if (node.name === 'job-task') {
    return (
      <Conditional
        wrapper={<ScopeLabelButtonLink className={className} to={data?.to} />}
        default={<span className={className} />}
        wrap={link}
      >
        Task [{data?.label}]
      </Conditional>
    );
  }

  if (node.name === 'job-task-form') {
    return (
      <Conditional
        wrapper={<ScopeLabelButtonLink className={className} to={data?.to} />}
        default={<span className={className} />}
        wrap={link}
      >
        Form [{data?.label}]
      </Conditional>
    );
  }

  if (node.name === 'job-issue') {
    return (
      <Conditional
        wrapper={<ScopeLabelButtonLink className={className} to={data?.to} />}
        default={<span className={className} />}
        wrap={link}
      >
        Issue [{data?.label}]
      </Conditional>
    );
  }

  if (node.name === 'job-issue-item') {
    return (
      <Conditional
        wrapper={<ScopeLabelButtonLink className={className} to={data?.to} />}
        default={<span className={className} />}
        wrap={link}
      >
        item [{data?.label}]
      </Conditional>
    );
  }

  if (node.name === 'job-report') {
    return (
      <Conditional
        wrapper={<ScopeLabelButtonLink className={className} to={data?.to} />}
        default={<span className={className} />}
        wrap={link}
      >
        Report [{data?.label}]
      </Conditional>
    );
  }

  if (node.name === 'job-variation') {
    return (
      <Conditional
        wrapper={<ScopeLabelButtonLink className={className} to={data?.to} />}
        default={<span className={className} />}
        wrap={link}
      >
        Variation [{data?.label}]
      </Conditional>
    );
  }

  return <>{node}</>;
};

const ScopeLabelButtonLink = ({
  children,
  className,
  to,
}: ScopeLabelButtonLinkProps) => {
  return (
    <Link to={to}>
      <Button
        variant="link"
        size="sm"
        className={cn('h-6 p-0 font-semibold', className)}
      >
        {children}
      </Button>
    </Link>
  );
};

export default ScopeLabel;
