import React, { useCallback } from 'react';
import { Container } from 'lucide-react';
import { Marker, useMap } from 'react-map-gl';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Anchor } from 'mapbox-gl';

const MiniMapYard = ({
  name,
  drivers,
  longitude,
  latitude,
  anchor,
}: {
  name: string;
  drivers: any[];
  longitude: number;
  latitude: number;
  anchor: Anchor;
}) => {
  const { main } = useMap();

  const handleOnClick = useCallback(() => {
    if (!main) return;

    main.flyTo({
      center: [longitude, latitude],
      zoom: 12,
      animate: true,
    });
  }, [main, longitude, latitude]);

  return (
    <>
      <Marker longitude={longitude} latitude={latitude}>
        <div className="relative size-3">
          <Container className="absolute inset-0 size-3" />
        </div>
      </Marker>
      <Marker longitude={longitude} latitude={latitude}>
        <div className="size-6 rounded-full border-2 border-accent-foreground bg-accent-foreground/25" />
      </Marker>
      <Marker
        offset={[anchor === 'left' ? 20 : -20, 0]}
        anchor={anchor}
        longitude={longitude}
        latitude={latitude}
      >
        <Button
          className={cn('p-0', {
            'text-left': anchor === 'left',
            'text-right': anchor === 'right',
          })}
          variant="link"
          onClick={handleOnClick}
        >
          <p
            className="text-[11px] font-semibold"
            style={{
              textShadow:
                '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
            }}
          >
            {name}
            <p className="-mt-0.5 text-[11px]">({drivers.length} present)</p>
          </p>
        </Button>
      </Marker>
    </>
  );
};

export default MiniMapYard;
