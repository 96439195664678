import React, { RefObject } from 'react';
import Map, { MapProvider, MapRef } from 'react-map-gl';
import { useTheme } from '@/components/ui/theme';
import { cn } from '@/lib/utils';
import { Job } from 'lib/types';
import MapDrivers from './components/MapDrivers';
import MapJobs from './components/MapJobs';
import MapLocations from './components/MapLocations';
import MiniMap from './components/MiniMap';
import { MiniMapPosition } from './components/MiniMap/types';
import { ActivityMapContext } from './context';
import { useActivityMap } from './hooks';

const ActivityMap = ({
  jobs,
  mapRef,
  className,
  miniMapPosition = MiniMapPosition.BottomLeft,
  miniMapClassName,
}: {
  jobs: Job[];
  mapRef: RefObject<MapRef>;
  className?: string;
  miniMapPosition?: MiniMapPosition;
  miniMapClassName?: string;
}) => {
  const { theme } = useTheme();
  const { context } = useActivityMap(jobs, miniMapPosition);

  const mapStyle =
    theme === 'dark'
      ? 'mapbox://styles/energyenables/clrcv8giv009s01pedl6ecmy5'
      : 'mapbox://styles/energyenables/clv8gyvj800q701pkcqxh4spq';

  return (
    <ActivityMapContext.Provider value={context}>
      <MapProvider>
        <div className={cn('h-full p-4', className)}>
          <div className="relative h-full min-h-0 overflow-hidden rounded-lg border">
            <MiniMap className={miniMapClassName} />
            <Map
              id="main"
              key={theme}
              ref={mapRef}
              style={{
                width: '100%',
                height: '100%',
              }}
              initialViewState={{
                zoom: 12,
                latitude: 51.4574,
                longitude: 0.1278,
              }}
              mapboxAccessToken="pk.eyJ1IjoiZW5lcmd5ZW5hYmxlcyIsImEiOiJjbGpia2NqZ2IxcTcxM2pxeGxvNG5ud2lpIn0.gMXRc_xES-6AlQx1w8MuXw"
              attributionControl={false}
              mapStyle={mapStyle}
              logoPosition="bottom-right"
            >
              <MapJobs />
              <MapLocations />
              <MapDrivers />
            </Map>
          </div>
        </div>
      </MapProvider>
    </ActivityMapContext.Provider>
  );
};

export default ActivityMap;
