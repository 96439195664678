export type Document = {
  id: string;
  name: string;
  isNew: boolean;
};

export interface NewDocument extends Document {
  isNew: true;
  file: File;
}

export interface PreparedDocument extends Document {
  file: {
    postData: {
      url: string;
      fields: Record<string, string>;
    };
  };
}
