import { ChangeEvent, useCallback } from 'react';
import { toast } from 'sonner';
import { useCommercialEstimateContext } from '../../../../context';

export const usePrelimsContractFactor = () => {
  const { handlers } = useCommercialEstimateContext();

  const handleOnChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(event.target.value);
      await handlers.onUpdate(
        'contract_prelim_factor',
        (1 + value / 100).toFixed(2),
      );
      toast.success('Contract prelim factor updated');
    },
    [handlers.onUpdate],
  );

  return {
    handlers: {
      onChange: handleOnChange,
    },
  };
};
