import { useState } from 'react';

const useTrackLoading = () => {
  const [loading, setLoading] = useState(false);

  const handleTrackLoading = async <T = any>(wrap: Promise<T>): Promise<T> => {
    setLoading(true);
    const ret = await wrap;
    setLoading(false);
    return ret;
  };

  return {
    loading,
    trackLoading: handleTrackLoading,
  };
};

export default useTrackLoading;
