const createTokenString = (method: string, value: string) => {
  const token = {
    tenant_id: 'mchugh',
    method,
    value,
  };
  return Buffer.from(JSON.stringify(token)).toString('base64');
};

export default createTokenString;
