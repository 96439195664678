import { Result, ResultType, Viewport } from './types';

export const mapPlaceToResult = (place: any): Result => {
  return {
    id: place.id,
    type: ResultType.Place,
    location: {
      lng: place.location.longitude,
      lat: place.location.latitude,
    },
    viewport: {
      ne: {
        lng: place.viewport.high.longitude,
        lat: place.viewport.high.latitude,
      },
      sw: {
        lng: place.viewport.low.longitude,
        lat: place.viewport.low.latitude,
      },
    },
    data: place,
  };
};

export const mapPointToResult = (point: any): Result => {
  return {
    id: point.placeId,
    type: ResultType.Point,
    location: {
      lng: point.geometry.location.lng,
      lat: point.geometry.location.lat,
    },
    viewport: {
      ne: {
        lng: point.geometry.viewport.northeast.lng,
        lat: point.geometry.viewport.northeast.lat,
      },
      sw: {
        lng: point.geometry.viewport.southwest.lng,
        lat: point.geometry.viewport.southwest.lat,
      },
    },
    data: point,
  };
};

export const getBounds = (results: Result[]): Viewport => {
  if (results.length === 0) {
    return {
      ne: { lng: -Infinity, lat: -Infinity },
      sw: { lng: Infinity, lat: Infinity },
    };
  }

  if (results.length === 1) {
    return results[0].viewport;
  }

  return results.reduce(
    (acc, { viewport }) => {
      return {
        ne: {
          lng: Math.max(acc.ne.lng, viewport.ne.lng),
          lat: Math.max(acc.ne.lat, viewport.ne.lat),
        },
        sw: {
          lng: Math.min(acc.sw.lng, viewport.sw.lng),
          lat: Math.min(acc.sw.lat, viewport.sw.lat),
        },
      };
    },
    {
      ne: { lng: -Infinity, lat: -Infinity },
      sw: { lng: Infinity, lat: Infinity },
    } as Viewport,
  );
};

export const findComponent = (term: string, component: any) => {
  try {
    return component.find((inner: any) => inner.types.includes(term))?.longText;
  } catch {
    return null;
  }
};

export const resultToAddress = (result: Result) => {
  return {
    lineOne:
      findComponent('street_number', result?.data.addressComponents) ||
      findComponent('route', result?.data.addressComponents) ||
      findComponent('premise', result?.data.addressComponents),
    lineTwo: findComponent('route', result?.data.addressComponents),
    city: findComponent('postal_town', result?.data.addressComponents),
    country: findComponent('country', result?.data.addressComponents),
    postcode: findComponent('postal_code', result?.data.addressComponents),
  };
};
