import { useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useUploadFiles } from 'lib/hooks';
import { PlatformObject } from 'lib/types';
import { useMediaContext } from '../MediaProvider';

export const useMedia = ({
  onCreated,
  onUploadProgress,
  onUploaded,
  onCompleted,
  ...rest
}: {
  onCreated: (objects: PlatformObject[]) => void;
  onUploadProgress: (id: string, progress: number) => void;
  onUploaded: (id: string) => void;
  onCompleted?: (objects: PlatformObject[]) => void;
} & DropzoneOptions) => {
  const uploadFiles = useUploadFiles({
    onCreated,
    onUploadProgress,
    onUploaded,
    onCompleted,
  });
  const { registerObjectSources } = useMediaContext();

  const handleFiles = async (acceptedFiles: File[]) => {
    const objects = (await uploadFiles(acceptedFiles)).map((object, index) => {
      const acceptedFile = acceptedFiles[index];
      return {
        id: object.id,
        type: object.type,
        name: acceptedFile.name,
        getUrl: object.getUrl,
        size: acceptedFile.size,
        data: {},
        sources: {
          full: { uri: URL.createObjectURL(acceptedFile) },
          thumbnail: { uri: URL.createObjectURL(acceptedFile) },
          compressed: { uri: URL.createObjectURL(acceptedFile) },
        },
      };
    });
    registerObjectSources(
      objects.map((object) => ({
        id: object.id,
        sources: object.sources,
      })),
    );
  };

  const dropzone = useDropzone({
    ...rest,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    onDrop: handleFiles,
  });

  return {
    dropzone,
    handleFiles,
  };
};

export const useMediaWithProgress = ({
  onCreated,
  onCompleted,
  ...rest
}: {
  onCreated: (objects: PlatformObject[]) => void;
  onCompleted?: (objects: PlatformObject[]) => void;
} & DropzoneOptions) => {
  const [progress, setProgress] = useState<Record<string, number>>({});
  const { dropzone } = useMedia({
    ...rest,
    onCreated,
    onUploadProgress: (id, progress) => {
      setProgress((prevProgress) => ({
        ...prevProgress,
        [id]: progress,
      }));
    },
    onUploaded: (id) => {
      setProgress((prevProgress) => ({
        ...prevProgress,
        [id]: 100,
      }));
    },
    onCompleted,
  });
  return { dropzone, progress };
};
