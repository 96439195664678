import { Training, TrainingQualification } from './training';
import { User } from './users';

export type CommercialActivity = {
  id: string;
  order: string[];
  status: CommercialActivityStatus;
  statusDisplay: string;
  contractPrelimFactor: number | null;
  contractPrelimFactorDescription: string | null;
  contract: CommercialContract;
  estimates: CommercialEstimate[];
  valueLines: CommercialValueLine[];
  costLines: CommercialCostLine[];
  totalEstimatedValue: number;
  totalEstimatedCost: number;
  totalEstimatedCostWithPrelims: number;
  totalEstimatedMargin: number;
  totalEstimatedMarginWithPrelims: number;
  totalEstimatedMarginRatio: number;
  totalEstimatedMarginRatioWithPrelims: number;
  totalAppliedValue: number;
  totalActualCost: number;
  totalActualCostWithPrelims: number;
  totalActualMargin: number;
  totalActualMarginWithPrelims: number;
  totalActualMarginRatio: number;
  totalActualMarginRatioWithPrelims: number;
  totalMarginDifference: number;
  totalMarginDifferenceWithPrelims: number;
  totalQuoteValueLabor: number;
  totalQuoteValueMaterial: number;
  totalQuoteValueEquipment: number;
  totalQuoteValueSubcontractor: number;
  staffTraining: Training[];
  refetch: () => Promise<any>;
};

export enum CommercialActivityStatus {
  PreApproved = 'PRE_APPROVED',
  Estimating = 'ESTIMATING',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export type CommercialEstimate = {
  id: string;
  number: number;
  name: string;
  description?: string;
  status: CommercialEstimateStatus;
  statusDisplay: string;
  contractPrelimFactor: number | null;
  contractPrelimFactorDescription: string | null;
  activity: CommercialActivity;
  valueLines: CommercialValueLine[];
  costLines: CommercialCostLine[];
  totalEstimatedValue: number;
  totalEstimatedCost: number;
  totalEstimatedCostWithPrelims: number;
  totalEstimatedMargin: number;
  totalEstimatedMarginWithPrelims: number;
  totalEstimatedMarginRatio: number;
  totalEstimatedMarginRatioWithPrelims: number;
  totalQuoteValuePrelim: number;
  totalQuoteValueLabor: number;
  totalQuoteValueMaterial: number;
  totalQuoteValueEquipment: number;
  totalQuoteValueSubcontractor: number;
  refetch: () => Promise<any>;
};

export enum CommercialEstimateStatus {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export type CommercialContract = {
  id: string;
  name: string;
  slug: string;
  description: string;
  dateStart: string;
  dateEnd: string;
  activePrelim: CommercialContractPrelim | null;
  prelims: CommercialContractPrelim[];
  rates: CommercialContractRate[];
  accounting?: CommercialContractAccounting;
  refetch: () => Promise<any>;
};

export type CommercialContractAccounting = {
  id: string;
  contract: CommercialContract;
  customer: string;
  searchCode: string;
  nominalCostCentre: string;
  authPo: string;
  approvedValue: string;
  contractManager: string;
};

export type CommercialContractPrelim = {
  id: string;
  contract: CommercialContract;
  factor: number;
  dateTimeCreated: string;
};

export type CommercialContractRate = {
  id: string;
  contract: CommercialContract;
  name: string;
  description: string;
  unit: string;
  unitDisplay: string;
  unitValue: number;
  unitValueTransition?: number;
  unitValueTransitionTime?: string;
  unitValueTransitionMask?: string;
  qualifications: CommercialContractRateQualification[];
  refetch: () => Promise<any>;
};

export type CommercialContractRateQualification = {
  id: string;
  priority: number;
  rate: CommercialContractRate;
  value: TrainingQualification;
};

export type CommercialResourceRate = {
  id: string;
  description: string;
  base: boolean;
  unitCost: number;
  unit: string;
  unitDisplay: string;
  dateStart: string;
  dateEnd: string;
  user: User;
};

export type CommercialValueLine = {
  id: string;
  type: CommercialType;
  rate: CommercialContractRate | null;
  description: string | null;
  descriptionEstimate: string | null;
  descriptionInternal: string | null;
  unit: CommercialUnit;
  unitDisplay: string;
  estimatedQuantity: number | null;
  estimatedUnitValue: number | null;
  estimatedUnitValueUplift: number | null;
  estimatedUnitValueWithUplift: number | null;
  estimatedTotalValue: number | null;
  appliedQuantity: number | null;
  appliedUnitValue: number | null;
  appliedUnitValueUplift: number | null;
  appliedUnitValueWithUplift: number | null;
  appliedTotalValue: number | null;
  deltaTotalValue: number | null;
  isCallout: boolean;
  isLocked: boolean;
};

export type CommercialCostLine = {
  id: string;
  type: CommercialType;
  rate: CommercialResourceRate | null;
  description: string | null;
  descriptionInternal: string | null;
  unit: CommercialUnit | null;
  unitDisplay: string;
  estimatedQuantity: number | null;
  estimatedUnitCost: number | null;
  estimatedTotalCost: number | null;
  plannedQuantity: number | null;
  plannedUnitCost: number | null;
  plannedTotalCost: number | null;
  actualQuantity: number | null;
  actualUnitCost: number | null;
  actualTotalCost: number | null;
};

export enum CommercialType {
  Prelim = 'PRELIM',
  Labor = 'LABOR',
  Material = 'MATERIAL',
  Equipment = 'EQUIPMENT',
  Subcontractor = 'SUBCONTRACTOR',
}

export enum CommercialUnit {
  Hour = 'HOUR',
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR',
  Unit = 'UNIT',
}

export const commercialTypeDisplayMap: Record<CommercialType, string> = {
  [CommercialType.Prelim]: 'Prelims',
  [CommercialType.Labor]: 'Labour',
  [CommercialType.Equipment]: 'Equipment',
  [CommercialType.Material]: 'Materials',
  [CommercialType.Subcontractor]: 'Subcontractors',
} as const;
