import { useMatch } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { useClient } from '../../../../hooks';
import {
  CommercialContractQueryData,
  CommercialContractQueryVariables,
} from './types';

export const useCommercialContract = () => {
  const client = useClient();

  const match = useMatch(
    '/clients/:clientSlug/commercial/contracts/:contractSlug/:dateStart/:dateEnd',
  );

  const { data, refetch } = useQuery<
    CommercialContractQueryData,
    CommercialContractQueryVariables
  >(
    gql`
      query GetCommercialContract(
        $clientId: ID!
        $slug: ID!
        $dateStart: DateTime!
        $dateEnd: DateTime!
      ) {
        commercialContract(
          clientId: $clientId
          slug: $slug
          dateStart: $dateStart
          dateEnd: $dateEnd
        ) {
          id
          name
          description
          dateStart
          dateEnd
          prelims {
            id
            factor
            dateTimeCreated
          }
          activePrelim {
            id
          }
          rates {
            id
            name
            description
            unit
            unitDisplay
            unitValue
            unitValueTransition
            unitValueTransitionTime
            unitValueTransitionMask
            qualifications {
              id
              priority
              value: qualification {
                id
                name
                description
              }
            }
          }
          accounting {
            id
            customer
            searchCode
            nominalCostCentre
            authPo
            approvedValue
            contractManager
          }
        }
      }
    `,
    {
      skip: !match,
      variables: {
        clientId: client.id,
        slug: match?.params.contractSlug as string,
        dateStart: match?.params.dateStart as string,
        dateEnd: match?.params.dateEnd as string,
      },
    },
  );

  if (data) {
    return {
      contract: {
        ...data.commercialContract,
        refetch,
      },
    };
  }

  return {
    contract: undefined,
  };
};
