import React from 'react';
import ButtonTabs from '@/components/ui/button-tabs';
import { useSchedulerContext } from '../../../../hooks';
import { SchedulerResourceType } from '../../../../types';

const ControlsResourceType = () => {
  const { resourceType } = useSchedulerContext();
  return (
    <ButtonTabs
      tabs={[
        {
          label: 'Staff',
          value: SchedulerResourceType.User,
        },
        {
          label: 'Subcontractors',
          value: SchedulerResourceType.Subcontractor,
        },
      ]}
      value={resourceType.value}
      onValueChange={resourceType.set}
    />
  );
};

export default ControlsResourceType;
