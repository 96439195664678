import { ScrollArea } from '@/components/ui/scroll-area';
import { useSubcontractorContext } from '../../context';
import PolicyCreate from './PolicyCreate';
import PolicyOther from './PolicyOther';
import PolicyRequirement from './PolicyRequirement';

const SubcontractorPolicies = () => {
  const { requirements, subcontractor } = useSubcontractorContext();
  if (!subcontractor?.policies) return null;
  return (
    <div
      className="flex min-h-0 flex-col overflow-hidden rounded-lg border"
      style={{ gridArea: 'policies' }}
    >
      <div className="flex border-b bg-background-secondary p-4">
        <h3 className="flex-grow font-semibold">Policies</h3>
        <PolicyCreate />
      </div>
      <ScrollArea>
        <div className="border-b px-4 py-2">Requirements</div>
        <div>
          {requirements.map((requirement) => (
            <PolicyRequirement key={requirement.id} requirement={requirement} />
          ))}
        </div>
        <div className="border-b px-4 py-2">Other</div>
        <div>
          {subcontractor.policies
            .filter((policy) => !policy.requirement)
            .map((policy) => (
              <PolicyOther key={policy.id} policy={policy} />
            ))}
        </div>
      </ScrollArea>
    </div>
  );
};

export default SubcontractorPolicies;
