import { gql, useQuery } from '@apollo/client';
import { TrainingQualification } from 'lib/types';
import { TrainingQualificationQueryData } from './types';

export const useQualificationsAddNested = (
  qualification: TrainingQualification,
) => {
  const { data, loading } = useQuery<TrainingQualificationQueryData>(
    gql`
      query GetTrainingQualification($id: ID!) {
        trainingQualification(trainingQualificationId: $id) {
          id
          children {
            id
            name
            description
            color
            logo {
              id
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      variables: { id: qualification.id },
    },
  );

  return {
    loading,
    qualifications: data?.trainingQualification?.children ?? [],
  };
};
