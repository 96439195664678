import { useMutation, useQuery } from '@tanstack/react-query';
import { gql, useApolloClient } from '@apollo/client';
import { evaluateExpression } from '@enterprise-platform-ui/expressions';
import chroma from 'chroma-js';
import { parseForClient, renderForPlatform } from 'lib/utils';
import { platformClient } from '../../../../app/platform';
import { Choice, State } from '../../../../external/FormTemplateEditor';
import { useClient } from '../../../../sections/Client/hooks';

export const useReadOnlyFormTemplate = (formTemplateId: string | undefined) => {
  const { data, isLoading } = useQuery({
    queryKey: ['formTemplate', formTemplateId],
    queryFn: platformClient.getFormTemplate(formTemplateId as string),
    enabled: !!formTemplateId,
    networkMode: 'always',
  });
  return {
    isLoading,
    formTemplate: data,
  };
};

export const useReadOnlyClientFormTemplate = (
  formTemplateSlug: string | undefined,
) => {
  const client = useClient();
  const formTemplateId = formTemplateSlug
    ? `${client?.slug}::${formTemplateSlug}`
    : undefined;
  return useReadOnlyFormTemplate(formTemplateId);
};

const useFormTemplate = (formTemplateId: string | undefined) => {
  const client = useApolloClient();

  const { data: formTemplate, isLoading } = useQuery({
    queryKey: ['formTemplate', formTemplateId],
    queryFn: platformClient.getFormTemplate(formTemplateId as string),
    enabled: !!formTemplateId,
  });

  const update = useMutation({
    mutationKey: ['updateFormTemplate', formTemplateId],
    mutationFn: platformClient.updateFormTemplate(),
  });

  const publish = useMutation({
    mutationKey: ['publishFormTemplate', formTemplateId],
    mutationFn: platformClient.publishFormTemplate(),
  });

  const handleDescriptionOnChange = async (value: string) => {
    if (!formTemplateId) return;
    return update.mutateAsync({
      id: formTemplateId,
      field: 'description',
      value,
    });
  };

  const handleOnPublish = async (store: State) => {
    const concreteStore: State = JSON.parse(JSON.stringify(store));

    const activeChoiceSetIds: string[] = Object.values(store.items)
      .map((item) => item.data.choiceSetId)
      .filter(Boolean);

    for (const choiceSet of Object.values(store.choiceSets).filter(
      (choiceSet) =>
        choiceSet.dynamic && activeChoiceSetIds.includes(choiceSet.id),
    )) {
      const { data } = await client.query({
        query: gql(choiceSet.sourceConfig.query),
        variables: choiceSet.sourceConfig.variables,
        fetchPolicy: 'no-cache',
      });

      const values: any[] = parseForClient(
        evaluateExpression(
          choiceSet.sourceConfig.mapping,
          renderForPlatform(data),
        ),
      );

      const colors = chroma
        .cubehelix()
        .start(200)
        .rotations(-0.35)
        .gamma(0.7)
        .lightness([0.3, 0.8])
        .scale()
        .correctLightness()
        .colors(values.length);

      const choices: Choice[] = values.map((value, index) => ({
        id: value.id,
        value: value.label,
        color: colors[index],
      }));

      concreteStore.choiceSets[choiceSet.id] = {
        id: choiceSet.id,
        choices,
      };
    }

    await publish.mutateAsync({
      id: formTemplateId,
      data: JSON.stringify(concreteStore),
    });
  };

  return {
    formTemplate,
    isLoading,
    isUpdating: update.isPending,
    isPublishing: publish.isPending,
    handleDescriptionOnChange,
    handleOnPublish,
  };
};

export const useClientFormTemplate = (formTemplateSlug: string | undefined) => {
  const client = useClient();
  const formTemplateId = formTemplateSlug
    ? `${client?.slug}::${formTemplateSlug}`
    : undefined;
  return useFormTemplate(formTemplateId);
};

export default useFormTemplate;
