import React from 'react';
import { Outlet } from 'react-router-dom';
import pkg from '../../../package.json';
import LayoutTopbar from '../LayoutTopbar';

const Layout = () => {
  return (
    <div className="flex h-full flex-col">
      <LayoutTopbar />
      <Outlet />
      <div className="fixed bottom-4 left-4 flex w-[255px] justify-between">
        <p className="text-xs text-gray-400">
          Version {pkg.version}{' '}
          <span className="text-gray-400/50">
            &copy; {new Date().getFullYear()} B. & M. McHugh
          </span>
        </p>
      </div>
    </div>
  );
};

export default Layout;
