import { useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { Job } from 'lib/types';
import { useClient } from '../../../../hooks';
import { ClientQueryData, ClientQueryVariables } from './types';

export const useOverviewDelivery = () => {
  const client = useClient();

  const [date, setDate] = useState(DateTime.now());

  const { data } = useQuery<ClientQueryData, ClientQueryVariables>(
    gql`
      query GetClientDelivery($id: ID!) {
        client(clientId: $id) {
          id
          operatives: userGroup(userGroupSlug: "operatives") {
            id
            users {
              id
              name
              email
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      variables: { id: client.id },
    },
  );

  const jobsByDay = useMemo<JobByDay[]>(() => {
    const jobsByDay: JobByDay[] = [];

    if (client.jobs.all) {
      client.jobs.all.forEach((job) => {
        const date = job.dateTimeCreated;
        const day = jobsByDay.find((day) => day.date === date);

        if (day) {
          day.jobs.push(job);
        } else {
          jobsByDay.push({
            date,
            jobs: [job],
          });
        }
      });
    }

    return jobsByDay.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
  }, [client.jobs.all]);

  const getJobsForDate = (date: DateTime) => {
    return client.jobs.all.filter((job) => {
      const dateTimeCreated = DateTime.fromISO(job.dateTimeCreated);
      return dateTimeCreated.hasSame(date, 'day');
    });
  };

  return {
    date,
    operatives: data?.client.operatives.users ?? [],
    jobsByDay,
    getJobsForDate,
  };
};

type JobByDay = {
  date: string;
  jobs: Job[];
};
