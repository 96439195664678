import * as React from 'react';
import { Loader2, LucideProps } from 'lucide-react';
import { cn } from '@/lib/utils';

export type LoaderProps = {
  className?: string;
  wrapperClassname?: string;
};

const Loader = React.forwardRef<any, LucideProps & LoaderProps>(
  ({ className, wrapperClassname, children, ...props }, ref) => {
    if (children) {
      return (
        <div
          className={cn('flex items-center bg-background/50', wrapperClassname)}
        >
          <Loader2
            ref={ref}
            className={cn('h-4 w-4 animate-spin', className)}
            {...props}
          />
          <span className="ml-2 text-xs font-semibold">{children}</span>
        </div>
      );
    }

    return (
      <Loader2
        ref={ref}
        className={cn('h-4 w-4 animate-spin', className)}
        {...props}
      />
    );
  },
);
Loader.displayName = 'Loader';

export { Loader };
