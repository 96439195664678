import {
  Dispatch,
  RefObject,
  SetStateAction,
  createContext,
  useContext,
} from 'react';
import { MapRef } from 'react-map-gl';
import { Location } from 'lib/types';
import { LocationMapMarker } from './components/LocationMap/types';

export const Context = createContext({
  mapRef: undefined as RefObject<MapRef> | undefined,
  mapMarkers: [] as LocationMapMarker[],
  setMapMarkers: (() => {}) as Dispatch<SetStateAction<LocationMapMarker[]>>,
  location: undefined as Location | undefined,
  supplementaryLocation: undefined as Location | undefined,
  handlers: {
    locationOnCreate: (() => {}) as ((location: Location) => void) | undefined,
    locationOnUpdate: (() => {}) as ((location: Location) => void) | undefined,
  },
});

export const Provider = Context.Provider;

export const useLocationContext = () => useContext(Context);
