import React from 'react';
import { Search } from 'lucide-react';
import { useNavigate } from 'react-router';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
} from '@/components/ui/select';
import { cn } from '@/lib/utils';
import { ScopeLabel } from 'lib/shared';
import { useClient } from '../../../../../Client/hooks';
import { useJobContext } from '../../../../context';
import { useJobIssueContext } from '../../context';

const IssueToolbar = () => {
  const navigate = useNavigate();

  const client = useClient();

  const { job, state } = useJobContext();
  const { issue } = useJobIssueContext();

  if (job.partial || !issue?.jobTask) return null;

  return (
    <div className="relative grid h-[52px] flex-shrink-0 grid-cols-12 items-center border-b">
      {!state.small && (
        <div className="col-span-3 px-4">
          <Select
            value={issue.id}
            onValueChange={(value) => {
              const nextIssue = job.issues.find(({ id }) => id === value);
              if (nextIssue) {
                navigate(
                  `${client.paths.jobs.job(job).root}/issues/${nextIssue.number}`,
                );
              }
            }}
          >
            <SelectTrigger className="h-8 pl-4">
              <p className="text-sm">
                <span className="font-semibold">#{issue.number} - </span>
                <ScopeLabel
                  className="text-sm lowercase"
                  label={issue.name}
                  link={false}
                />
              </p>
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Other issues</SelectLabel>
                {job.issues
                  .filter(({ id }) => id !== issue.id)
                  .reverse()
                  .map((inner) => (
                    <SelectItem key={inner.id} value={inner.id}>
                      <p>
                        <ScopeLabel
                          className="text-sm lowercase"
                          label={inner.name}
                          link={false}
                        />
                      </p>
                    </SelectItem>
                  ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      )}
      <div
        className={cn('relative flex overflow-hidden rounded-md bg-secondary', {
          'col-span-6': !state.small,
          'col-span-12 mx-4': state.small,
        })}
      >
        <Popover>
          <PopoverTrigger asChild>
            <Button
              size="sm"
              variant="secondary"
              className="w-full gap-x-2 rounded-none dark:text-gray-400"
            >
              <Search className="h-4 w-4" />
              Search, bookmarks, and more...
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="popover-content-width-same-as-its-trigger p-0"
            side="top"
            align="start"
          >
            <Command>
              <CommandInput placeholder="Type a command or search..." />
              <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup heading="Suggestions">
                  <CommandItem>Calendar</CommandItem>
                  <CommandItem>Search Emoji</CommandItem>
                  <CommandItem>Calculator</CommandItem>
                </CommandGroup>
                <CommandSeparator />
                <CommandGroup heading="Settings">
                  <CommandItem>Profile</CommandItem>
                  <CommandItem>Billing</CommandItem>
                  <CommandItem>Settings</CommandItem>
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default IssueToolbar;
