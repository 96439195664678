import React, { useMemo } from 'react';
import { InfoIcon } from 'lucide-react';
import { DataType } from 'external/FormTemplateEditor';
import { getHorizontalPadding } from 'lib/shared';
import { useFormContext } from '../../context';
import FormFieldBoolean from './components/FormFieldBoolean';
import FormFieldChar from './components/FormFieldChar';
import FormFieldChoice from './components/FormFieldChoice';
import FormFieldDatetime from './components/FormFieldDatetime';
import FormFieldDuration from './components/FormFieldDuration';
import FormFieldFile from './components/FormFieldFile';
import FormFieldLabel from './components/FormFieldLabel';
import FormFieldMedia, {
  FormFieldMediaStandalone,
  useFormFieldMedia,
} from './components/FormFieldMedia';
import FormFieldNumber from './components/FormFieldNumber';
import FormFieldSignature from './components/FormFieldSignature';
import FormFieldUser from './components/FormFieldUser';
import FormFieldWarning from './components/FormFieldWarning';
import { FormFieldComponentProps, FormFieldProps } from './types';

const FormFieldRouter = (props: FormFieldComponentProps) => {
  switch (props.item.data.dataType) {
    case DataType.BOOLEAN:
      return <FormFieldBoolean {...props} />;
    case DataType.CHAR:
      return <FormFieldChar {...props} />;
    case DataType.CHOICE_SET:
      return <FormFieldChoice {...props} />;
    case DataType.DATETIME:
      return <FormFieldDatetime {...props} />;
    case DataType.DURATION:
      return <FormFieldDuration {...props} />;
    case DataType.NUMBER:
      return <FormFieldNumber {...props} />;
    case DataType.MEDIA:
      return <FormFieldMediaStandalone {...props} />;
    case DataType.FILE:
      return <FormFieldFile {...props} />;
    case DataType.SIGNATURE:
      return <FormFieldSignature {...props} />;
    case DataType.USER:
      return <FormFieldUser {...props} />;
    default:
      return null;
  }
};

const FormField = ({ item, isLast = false }: FormFieldProps) => {
  const {
    values,
    disableMedia,
    readOnly = false,
    renderField,
    fieldValueOnChange,
    fieldMetaOnChange,
  } = useFormContext();

  const value = useMemo(
    () => values?.[item.path],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item.path, values?.[item.path]],
  );

  const label = useMemo(
    () => <FormFieldLabel item={item} value={value} />,
    [item, value],
  );

  const field = useMemo(
    () => (
      <FormFieldRouter
        readOnly={readOnly}
        item={item}
        isLast={isLast}
        value={value}
        onChange={fieldValueOnChange}
      />
    ),
    [fieldValueOnChange, item, readOnly, value, isLast],
  );

  const mediaProps = useFormFieldMedia({
    readOnly,
    item,
    onChange: fieldValueOnChange,
  });

  const media = useMemo(() => <FormFieldMedia {...mediaProps} />, [mediaProps]);
  const inner = useMemo(
    () => (
      <>
        {label}
        {item.data.dataType !== 'COMMENT' && (
          <>
            {field}
            {(!disableMedia || item.data.dataType === DataType.MEDIA) && media}
          </>
        )}
      </>
    ),
    [disableMedia, field, item.data.dataType, label, media],
  );

  if (item.data.dataType === 'COMMENT') {
    return (
      <div
        className="border-b p-4"
        style={{ paddingLeft: getHorizontalPadding(item.depth) }}
      >
        <div className="mb-2 flex items-center gap-2">
          <InfoIcon className="light:text-muted h-4 w-4" />
          <p className="light:text-muted text-xs">Comment</p>
        </div>
        <em className="light:text-muted text-sm font-medium">
          {item.data.value || ''}
        </em>
      </div>
    );
  }

  if (item.data.dataType === DataType.WARNING) {
    return (
      <div
        className="border-b p-4"
        style={{ paddingLeft: getHorizontalPadding(item.depth) }}
      >
        <FormFieldWarning value={item.data.value} />
      </div>
    );
  }

  return (
    renderField?.({
      readOnly,
      item,
      value,
      label,
      field,
      media,
      inner,
      isLast,
      dropzone: mediaProps.dropzone,
      onChange: fieldValueOnChange,
      metaOnChange: fieldMetaOnChange,
    }) ?? inner
  );
};

export default FormField;
