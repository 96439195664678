import React from 'react';
import { EditableAutoSave } from 'lib/shared';
import { useCostLineContext } from '../../context';

const LineDetail = () => {
  return (
    <div className="cursor-auto border-y bg-background-secondary">
      <DetailDescriptionInternal />
    </div>
  );
};

const DetailDescriptionInternal = () => {
  const { line, handlers } = useCostLineContext();
  return (
    <div className="grid grid-cols-12 p-4">
      <div className="col-span-2">
        <p className="text-sm font-medium">Notes</p>
        <p className="text-xs dark:text-gray-400">Internal only</p>
      </div>
      <div className="col-span-10">
        <EditableAutoSave
          value={line.descriptionInternal}
          onChange={handlers.descriptionInternalOnChange}
        />
      </div>
    </div>
  );
};

export default LineDetail;
