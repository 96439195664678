import React, { HTMLAttributes } from 'react';
import ReactPDF from '@react-pdf/renderer';
import ReportSvg from '../../components/ReportSvg';

const ReportHexagon = (
  props: ReactPDF.SVGProps | HTMLAttributes<SVGElement>,
) => {
  return (
    <ReportSvg {...props} style={{ height: 24, width: 24 }}>
      <ReportSvg.Path
        fill="none"
        stroke="#A19898"
        d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"
      />
    </ReportSvg>
  );
};

export default ReportHexagon;
