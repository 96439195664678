import React from 'react';
import { Button } from '@/components/ui/button';
import { TableRenderer } from '@/components/ui/table/table/Table';
import { DateTime } from 'luxon';
import { PdfSheet } from 'lib/shared';
import InsurancesCreate from './components/InsurancesCreate';
import InsurancesEditor from './components/InsurancesEditor';
import { CompanyInsurancesContext } from './context';
import { useCompanyInsurances } from './hooks';

const CompanyInsurances = () => {
  const { context } = useCompanyInsurances();
  return (
    <CompanyInsurancesContext.Provider value={context}>
      <div className="flex min-h-0 grow flex-col">
        <div className="flex h-[52px] flex-shrink-0 items-center justify-between border-b px-4 py-2">
          <h1 className="text-xl font-bold">Insurance</h1>
        </div>
        <div className="min-h-0 grow px-4">
          <TableRenderer
            filter="coverName"
            initialSorting={[{ id: 'issuer', desc: false }]}
            columns={[
              {
                id: 'coverName',
                header: 'Cover Name',
                accessorKey: 'coverName',
                cell: ({ row }) => {
                  return <InsurancesEditor insurance={row.original} />;
                },
              },
              {
                id: 'issuer',
                header: 'Issuer',
                accessorKey: 'issuer',
                cell: ({ row }) => {
                  if (!row.original.issuer) {
                    return <p className="italic dark:text-gray-400">Not set</p>;
                  }
                  return <p>{row.original.issuer}</p>;
                },
              },
              {
                id: 'policyNumber',
                header: 'Policy Number',
                accessorKey: 'policyNumber',
                cell: ({ row }) => {
                  if (!row.original.policyNumber) {
                    return <p className="italic dark:text-gray-400">Not set</p>;
                  }
                  return <p>{row.original.policyNumber}</p>;
                },
              },
              {
                id: 'dateTimeCoverStart',
                header: 'Cover Start',
                accessorKey: 'dateTimeCoverStart',
                cell: ({ row }) => {
                  return (
                    <p>
                      {row.original.dateTimeCoverStart
                        ? DateTime.fromISO(
                            row.original.dateTimeCoverStart,
                          ).toFormat('dd/MM/yyyy')
                        : 'Not set'}
                    </p>
                  );
                },
              },
              {
                id: 'dateTimeCoverEnd',
                header: 'Cover End',
                accessorKey: 'dateTimeCoverEnd',
                cell: ({ row }) => {
                  return (
                    <p>
                      {row.original.dateTimeCoverEnd
                        ? DateTime.fromISO(
                            row.original.dateTimeCoverEnd,
                          ).toFormat('dd/MM/yyyy')
                        : 'Not set'}
                    </p>
                  );
                },
              },
              {
                id: 'indemnityLimit',
                header: 'Indemnity Limit',
                accessorKey: 'indemnityLimit',
                cell: ({ row }) => {
                  const formatted = new Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currency: 'GBP',
                  }).format(row.original.indemnityLimit);
                  return <p>{formatted}</p>;
                },
              },
              {
                id: 'notes',
                header: 'Notes',
                accessorKey: 'notes',
                cell: ({ row }) => {
                  if (!row.original.notes) {
                    return <p className="italic dark:text-gray-400">Not set</p>;
                  }
                  return (
                    <p className="line-clamp-1 text-xs">{row.original.notes}</p>
                  );
                },
              },
              {
                id: 'document',
                header: 'Document',
                accessorKey: 'expiryDate',
                cell: ({ row }) => {
                  if (!row.original.document) {
                    return <p className="italic dark:text-gray-400">None</p>;
                  }
                  return (
                    <PdfSheet objectId={row.original.document.id}>
                      <Button size="table" variant="table">
                        View
                      </Button>
                    </PdfSheet>
                  );
                },
              },
            ]}
            data={context.insurances}
            topRight={({ columnsSelector }) => (
              <div className="ml-auto flex gap-x-2">
                <InsurancesCreate />
                {columnsSelector}
              </div>
            )}
          />
        </div>
      </div>
    </CompanyInsurancesContext.Provider>
  );
};

export default CompanyInsurances;
