import React from 'react';
import { ArrowRight, Check, Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Image } from '@/components/ui/image';
import { Loader } from '@/components/ui/loading';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Sheet,
  SheetContentExtension,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { usePlatformObject } from 'lib/hooks';
import { Empty } from 'lib/shared';
import { TrainingQualification } from 'lib/types';
import { useContractRateContext } from '../../../../context';
import { useRateQualificationsContext } from '../../context';
import { useQualificationsAddNested } from './hooks';
import { QualificationsAddNestedProps } from './types';

const QualificationsAddNested = ({
  qualification,
}: QualificationsAddNestedProps) => {
  const { qualifications, loading } = useQualificationsAddNested(qualification);
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button className="p-0" variant="link" size="sm">
          <Plus className="mr-1 size-4" />
          Add nested
        </Button>
      </SheetTrigger>
      <SheetContentExtension
        right={500}
        className="flex h-full min-h-0 flex-col p-0"
      >
        <SheetHeader className="p-4 pb-0">
          <SheetTitle>Add qualification</SheetTitle>
          <SheetDescription>
            Add a qualification to this rate to further define the conditions
            under which this rate applies.
          </SheetDescription>
        </SheetHeader>
        <ScrollArea className="min-h-0 flex-grow">
          <div className="mx-4 flex flex-col gap-y-4 pb-4">
            {loading ? (
              <div>
                <Loader />
              </div>
            ) : qualifications.length > 0 ? (
              qualifications.map((qualification) => (
                <QualificationsListItem
                  key={qualification.id}
                  qualification={qualification}
                />
              ))
            ) : (
              <Empty className="h-24">No nested qualifications</Empty>
            )}
          </div>
        </ScrollArea>
      </SheetContentExtension>
    </Sheet>
  );
};

const QualificationsListItem = ({
  qualification,
}: {
  qualification: TrainingQualification;
}) => {
  const { rate } = useContractRateContext();
  const { handlers } = useRateQualificationsContext();

  const { object } = usePlatformObject(qualification.logo?.id);

  const added = rate?.qualifications.some(
    (inner) => inner.value.id === qualification.id,
  );

  const header = (
    <>
      <div className="mb-3 flex items-center gap-x-2">
        <Image className="h-6" src={object?.getUrl} />
      </div>
      <div className="mb-2">
        <p className="mb-1 text-sm font-semibold">{qualification.name}</p>
        <p className="line-clamp-2 text-xs leading-relaxed dark:text-gray-400">
          {qualification.description}
        </p>
      </div>
    </>
  );

  if (added) {
    return (
      <div className="w-full rounded-lg border bg-background-secondary p-4 opacity-50">
        {header}
        <div className="flex justify-end">
          <Button variant="link" size="sm">
            <Check className="mr-1 h-4 w-4" />
            Added
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full rounded-lg border bg-background-secondary p-4">
      {header}
      <div className="flex justify-end">
        <Button
          size="sm"
          variant="link"
          onClick={handlers.onCreate(qualification)}
        >
          Add to contract
          <ArrowRight className="ml-1 h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

export default QualificationsAddNested;
