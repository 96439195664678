import React, { useEffect } from 'react';
import { useScopeContext } from '../../../../shared/ScopeProvider/context';
import TodoCard from '../TodoCard';
import { TodoCardGroupProps } from './types';

const Scope = ({ scope }: { scope: string }) => {
  const {
    isLoading,
    scopeData: globalScopeData,
    registerScopes,
  } = useScopeContext();

  useEffect(() => {
    registerScopes([scope]);
  }, [registerScopes, scope]);

  if (isLoading) {
    return null;
  }

  const scopeData = globalScopeData[scope];
  if (!scopeData) return null;

  return <div>{scopeData.label}</div>;
};

const TodoCardGroup = ({
  group,
  selected,
  todoOnClick,
  todoOnClose,
}: TodoCardGroupProps) => {
  return (
    <div className="border-b">
      <div className="px-4 py-2">
        <Scope scope={group.scope} />
      </div>
      <div className="px-4 pb-4">
        {group.todos.map((todo) => (
          <TodoCard
            key={todo.id}
            todo={todo}
            selected={selected}
            onClick={() => todoOnClick(todo)}
            onClose={() => todoOnClose()}
          />
        ))}
      </div>
    </div>
  );
};

export default TodoCardGroup;
