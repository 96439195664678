import CostBody from './components/CostBody';
import CostFooter from './components/CostFooter';
import CostHeader from './components/CostHeader';

const TableCost = () => {
  return (
    <>
      <CostHeader />
      <CostBody />
      <CostFooter />
    </>
  );
};

export default TableCost;
