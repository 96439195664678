import React, { useState } from 'react';
import { ArrowRight, ChevronDown, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { FormControl, FormItem } from '@/components/ui/form';
import { CustomFieldRenderData } from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormLabel } from '@/components/ui/form/form-label/FormLabel';
import { Image } from '@/components/ui/image';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Sheet,
  SheetContentExtension,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { gql, useLazyQuery } from '@apollo/client';
import { usePlatformObject } from 'lib/hooks';
import { PageLoader } from 'lib/shared';
import { TrainingQualification } from 'lib/types';
import { useMemberTrainingFormContext } from '../../context';
import { MemberTrainingFormMode } from '../../types';
import { useFieldQualification } from './hooks';

const FieldQualification = ({
  form,
  formField,
}: CustomFieldRenderData<any>) => {
  const { mode } = useMemberTrainingFormContext();
  const { sheet, data, object } = useFieldQualification(formField);
  return (
    <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
      <FormItem>
        <FormLabel>Qualification</FormLabel>
        <FormControl>
          <div>
            {formField.value && (
              <div className="mb-4">
                <div className="rounded-md border bg-background-secondary p-4">
                  <div className="mb-2 flex items-center gap-x-2">
                    <Image className="h-6" src={object?.getUrl} />
                  </div>
                  <div>
                    <p className="mb-1 text-sm font-semibold">
                      {formField.value.name}
                    </p>
                    <p className="line-clamp-2 text-xs dark:text-gray-400">
                      {formField.value.description}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {mode === MemberTrainingFormMode.Create && (
              <SheetTrigger asChild>
                <Button
                  className="w-full"
                  variant="outline"
                  size="sm"
                  type="button"
                >
                  {formField.value ? 'Change' : 'Select'} qualification
                </Button>
              </SheetTrigger>
            )}
          </div>
        </FormControl>
      </FormItem>
      {mode === MemberTrainingFormMode.Create && (
        <SheetContentExtension
          right={384}
          className="flex h-full min-h-0 flex-col"
        >
          <SheetHeader className="p-4">
            <SheetTitle>Add qualification</SheetTitle>
            <SheetDescription>
              Select the qualification you wish to add to this user.
            </SheetDescription>
          </SheetHeader>
          <ScrollArea className="min-h-0 flex-grow">
            {data ? (
              <div className="flex flex-col gap-y-4 px-4 pb-4">
                {data.trainingQualifications.map((qualification) => (
                  <FieldQualificationListItem
                    key={qualification.id}
                    qualification={qualification}
                    formField={formField}
                    form={form}
                    onClick={() => {
                      sheet.onClose();
                    }}
                  />
                ))}
              </div>
            ) : (
              <PageLoader>Loading qualifications</PageLoader>
            )}
          </ScrollArea>
        </SheetContentExtension>
      )}
    </Sheet>
  );
};

const FieldQualificationListItem = ({
  qualification,
  formField,
  form,
  onClick,
}: {
  qualification: TrainingQualification;
  formField: any;
  form: any;
  onClick: () => void;
}) => {
  const { object } = usePlatformObject(qualification.logo?.id);

  const [open, setOpen] = useState(false);

  const [get, { data }] = useLazyQuery<{
    trainingQualification: TrainingQualification;
  }>(
    gql`
      query GetTrainingQualification($id: ID!) {
        trainingQualification(trainingQualificationId: $id) {
          id
          children {
            id
            name
            data
            description
            fields
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      variables: { id: qualification.id },
    },
  );

  return (
    <div className="block h-auto w-full whitespace-normal rounded-lg border bg-background-secondary p-4 pb-2 text-start">
      <div className="mb-2 flex items-center gap-x-2">
        <Image className="h-6" src={object?.getUrl} />
      </div>
      <div className="mb-2">
        <p className="mb-1 text-sm font-semibold">{qualification.name}</p>
        <p className="line-clamp-2 text-xs dark:text-gray-400">
          {qualification.description}
        </p>
      </div>
      <div>
        <div className="flex items-center justify-between">
          <Button
            className="gap-x-1 p-0"
            size="sm"
            variant="link"
            onClick={async () => {
              await get();
              setOpen(true);
            }}
          >
            <ChevronDown className="size-4" />
            View nested
          </Button>
          {open ? (
            <Button
              className="gap-x-1"
              size="xs"
              variant="link"
              onClick={() => {
                setOpen(false);
              }}
            >
              <X className="size-4" />
              Close
            </Button>
          ) : (
            <Button
              className="gap-x-1"
              size="xs"
              variant="outline"
              onClick={() => {
                formField.onChange(qualification);
                form.resetField('data');
                onClick();
              }}
            >
              Select
              <ArrowRight className="size-4" />
            </Button>
          )}
        </div>
        {open && data?.trainingQualification ? (
          <div className="my-2 flex flex-col gap-y-2 rounded bg-secondary p-2">
            {(data?.trainingQualification?.children ?? []).length > 0 ? (
              data.trainingQualification.children.map((child) => (
                <div
                  key={child.id}
                  className="flex items-center justify-between"
                >
                  <p className="text-xs font-semibold">{child.name}</p>
                  <Button
                    className="gap-x-1"
                    size="xs"
                    variant="outline"
                    onClick={() => {
                      formField.onChange(child);
                      form.resetField('data');
                      onClick();
                    }}
                  >
                    Select
                    <ArrowRight className="size-4" />
                  </Button>
                </div>
              ))
            ) : (
              <div>
                <p className="text-xs font-semibold">
                  No nested qualifications
                </p>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FieldQualification;
