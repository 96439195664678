import { gql } from '@apollo/client';

export const GET_COMMERCIAL_ACTIVITY_QUERY = gql`
  query GetCommercialActivity($id: ID!) {
    commercialActivity(commercialActivityId: $id) {
      id
      order
      status
      statusDisplay
      contractPrelimFactor
      contractPrelimFactorDescription
      contract {
        id
        activePrelim {
          id
          factor
          dateTimeCreated
        }
        rates {
          id
          name
          description
          unit
          unitDisplay
          unitValue
        }
      }
      estimates {
        id
        number
        name
        description
        status
        statusDisplay
        totalEstimatedValue
        totalEstimatedCost
        totalEstimatedMargin
        totalEstimatedMarginRatio
      }
      totalEstimatedValue
      totalEstimatedCost
      totalEstimatedMargin
      totalEstimatedMarginWithPrelims
      totalAppliedValue
      totalActualCost
      totalActualMargin
      totalActualMarginWithPrelims
      totalMarginDifference
      totalMarginDifferenceWithPrelims
      totalQuoteValueLabor: totalEstimatedValue(type: "LABOR")
      totalQuoteValueMaterial: totalEstimatedValue(type: "MATERIAL")
      totalQuoteValueEquipment: totalEstimatedValue(type: "EQUIPMENT")
      totalQuoteValueSubcontractor: totalEstimatedValue(type: "SUBCONTRACTOR")
      valueLines {
        id
        type
        unit
        unitDisplay
        rate {
          id
          description
        }
        description
        descriptionEstimate
        estimatedQuantity
        estimatedUnitValueWithUplift
        estimatedTotalValue
      }
      costLines {
        id
      }
    }
  }
`;
