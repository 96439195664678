import React from 'react';
import { LayoutDashboard, Plus } from 'lucide-react';
import { Outlet } from 'react-router-dom';
import { Image } from '@/components/ui/image';
import { Nav } from '@/components/ui/nav';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable';
import { ScrollArea } from '@/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { usePlatformObject, useRouteMatch } from 'lib/hooks';
import { Link } from 'lib/shared';
import { TrainingQualification } from 'lib/types';
import { TrainingQualificationsContext } from './context';
import { useTrainingQualifications } from './hooks';

const TrainingQualifications = () => {
  const {
    context,
    context: { qualifications },
  } = useTrainingQualifications();
  const routeMatch = useRouteMatch([
    '/hr/training/qualifications',
    '/hr/training/qualifications/create',
  ]);
  const innerMatch = useRouteMatch(['/hr/training/qualifications/:id']);
  return (
    <TrainingQualificationsContext.Provider value={context}>
      <ResizablePanelGroup
        direction="horizontal"
        className="grow items-stretch bg-background"
      >
        <ResizablePanel
          className="flex flex-col"
          defaultSize={20}
          minSize={12}
          maxSize={20}
        >
          <div className="flex h-[52px] flex-shrink-0 items-center justify-between border-b px-4 py-2">
            <h1 className="text-xl font-bold">Qualifications</h1>
          </div>
          <div className="border-b">
            <Nav
              links={[
                {
                  title: 'Overview',
                  icon: LayoutDashboard,
                  variant:
                    routeMatch?.pattern.path === '/hr/training/qualifications'
                      ? 'selected'
                      : 'unselected',
                  to: `/hr/training/qualifications`,
                },
                {
                  title: 'Create new qualification',
                  icon: Plus,
                  variant:
                    routeMatch?.pattern.path ===
                    '/hr/training/qualifications/create'
                      ? 'selected'
                      : 'unselected',
                  to: `/hr/training/qualifications/create`,
                },
              ]}
            />
          </div>
          <div className="min-h-0 grow">
            <ScrollArea className="h-full">
              <div className="flex flex-col gap-y-2 p-2">
                {qualifications.map((qualification) => {
                  const selected = innerMatch?.params.id === qualification.id;
                  return (
                    <QualificationsListItem
                      key={qualification.id}
                      qualification={qualification}
                      selected={selected}
                    />
                  );
                })}
              </div>
            </ScrollArea>
          </div>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel>
          <Outlet />
        </ResizablePanel>
      </ResizablePanelGroup>
    </TrainingQualificationsContext.Provider>
  );
};

const QualificationsListItem = ({
  qualification,
  selected = false,
}: {
  qualification: TrainingQualification;
  selected?: boolean;
}) => {
  const { object } = usePlatformObject(qualification.logo?.id);
  return (
    <Link
      key={qualification.id}
      to={`/hr/training/qualifications/${qualification.id}`}
    >
      <div
        className={cn('w-full rounded-lg border p-4', {
          'bg-background-secondary': !selected,
          'bg-secondary': selected,
        })}
      >
        <div className="mb-2 flex items-center gap-x-2">
          <Image className="h-6" src={object?.getUrl} />
        </div>
        <div>
          <p className="mb-1 text-sm font-semibold">{qualification.name}</p>
          <p className="line-clamp-2 text-xs dark:text-gray-400">
            {qualification.description}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default TrainingQualifications;
