import * as React from 'react';
import { CalendarIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { TimescapePicker } from '@/components/ui/timescape-picker';
import { cn } from '@/lib/utils';
import { DateTime } from 'luxon';

export const DatePicker = ({
  className,
  optional = false,
  timescape = false,
  value,
  onValueChange,
}: {
  className?: string;
  optional?: boolean;
  timescape?: boolean;
  value: DateTime | null | undefined;
  onValueChange: (value: DateTime | undefined) => void;
}) => {
  if (timescape) {
    return (
      <div className="flex">
        <TimescapePicker
          watch={!optional}
          disabled={optional && !value}
          className={cn({ 'rounded-r-none': optional })}
          format={[['days', 'months', 'years'], []]}
          value={value ? value.toJSDate() : undefined}
          onChange={(nextDate) => {
            if (!nextDate) return;
            onValueChange(DateTime.fromJSDate(nextDate));
          }}
        />
        {optional && (
          <div className="flex size-10 items-center justify-center rounded-r-md border border-l-0">
            <Checkbox
              checked={!!value}
              onCheckedChange={(checked) => {
                onValueChange(checked ? DateTime.now() : undefined);
              }}
            />
          </div>
        )}
      </div>
    );
  }
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn(
            'h-9 w-full justify-start gap-x-1 pl-3 text-left text-xs font-semibold',
            !value && 'text-muted-foreground',
            className,
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {value ? value.toFormat('dd LLLL yyyy') : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="center"
        className="flex w-auto flex-col space-y-2 p-2"
      >
        <Select
          onValueChange={(value) =>
            onValueChange(
              DateTime.now()
                .startOf('day')
                .plus({ days: Number(value) }),
            )
          }
        >
          <SelectTrigger>
            <SelectValue placeholder="Select" />
          </SelectTrigger>
          <SelectContent position="popper">
            <SelectItem value="0">Today</SelectItem>
            <SelectItem value="1">Tomorrow</SelectItem>
            <SelectItem value="3">In 3 days</SelectItem>
            <SelectItem value="7">In a week</SelectItem>
          </SelectContent>
        </Select>
        <div className="rounded-md border">
          <Calendar
            mode="single"
            selected={value?.toJSDate()}
            defaultMonth={value?.toJSDate()}
            onSelect={(value) => {
              return value
                ? onValueChange(DateTime.fromJSDate(value))
                : undefined;
            }}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};
