import React from 'react';
import { DateTime } from 'luxon';
import { TimelineRenderer } from 'lib/shared';
import { useClient } from '../../../../hooks';

const JobsTimeline = () => {
  const client = useClient();
  return (
    <TimelineRenderer
      scale={2}
      dateTimeStart={DateTime.now().minus({ days: 5 })}
      dateTimeEnd={DateTime.now().plus({ days: 5 })}
      jobs={client.jobs.all}
    />
  );
};

export default JobsTimeline;
