import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { platformObjectInput } from 'lib/utils';
import { useCompanyMembershipsContext } from '../../context';
import {
  CreateCompanyMembershipMutationData,
  CreateCompanyMembershipMutationVariables,
} from './types';

export const useMembershipsCreate = () => {
  const { refetch } = useCompanyMembershipsContext();

  const [open, setOpen] = useState(false);

  const [create] = useMutation<
    CreateCompanyMembershipMutationData,
    CreateCompanyMembershipMutationVariables
  >(
    gql`
      mutation CreateCompanyMembership($data: CompanyMembershipData!) {
        createCompanyMembership(companyMembershipData: $data) {
          companyMembership {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (values: any) => {
    const { data, errors } = await create({
      variables: {
        data: {
          logo: platformObjectInput(values.logo),
          name: values.name,
          description: values.description,
          dateTimeExpiry: values.dateTimeExpiry,
          document: platformObjectInput(values.document),
        },
      },
    });

    if (data) {
      await refetch();
      setOpen(false);
      toast.success('Company membership created');
    }

    if (errors) {
      toast.error('Failed to create company membership');
    }
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onCreate: handleOnCreate,
    },
  };
};
