import React from 'react';
import { JobTimelineRenderer } from '../../../../components/JobTimeline';
import { useJobContext } from '../../../../context';
import { useStage } from '../../hooks';
import { STAGE_TIMELINE } from '../../stages';
import { InstantiationStageIssues } from '../InstantiationIssues';

const InstantiationTimeline = () => {
  const { job } = useJobContext();
  const stage = useStage(STAGE_TIMELINE);

  if (job.partial) return null;

  return (
    <div className="border-b pb-5 pt-4">
      <JobTimelineRenderer job={job} />
      <div className="mt-4 px-4">
        <InstantiationStageIssues
          stage={stage}
          types={[
            {
              value: 'information-missing',
              label: 'Missing information',
              color: 'red',
            },
          ]}
        />
      </div>
    </div>
  );
};

export default InstantiationTimeline;
