import React from 'react';
import { Dot } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { input } from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import {
  Sheet,
  SheetContentExtension,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { z } from 'zod';
import { UserPhoto } from 'lib/shared';
import { useStaffMemberContext } from '../../context';
import { useMemberDetails } from './hooks';

const MemberDetails = () => {
  const { user } = useStaffMemberContext();
  const { sheet, handlers } = useMemberDetails();

  if (!user) return null;

  return (
    <div className="mb-4 flex flex-col gap-y-2 border-b p-4">
      <UserPhoto className="size-16" user={user} avatarClassName="size-16" />
      <div className="flex items-center gap-x-2">
        <h1 className="text-xl font-semibold">{user.name}</h1>
        <Dot className="size-1" />
        <span className="text-sm text-gray-400 dark:text-gray-400">
          {user.jobTitle || 'Job title not set'}
        </span>
        <Dot className="size-1" />
        <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
          <SheetTrigger asChild>
            <Button className="p-0" size="xs" variant="link">
              Edit
            </Button>
          </SheetTrigger>
          <SheetContentExtension right={500} className="p-4">
            <SheetHeader>
              <SheetTitle>Update details</SheetTitle>
              <SheetDescription>
                Update the details of this user
              </SheetDescription>
            </SheetHeader>
            <FormRenderer
              fields={[
                input({
                  name: 'name',
                  label: 'Name',
                  schema: z.string().min(1),
                  defaultValue: user.name,
                }),
                input({
                  name: 'email',
                  label: 'Email',
                  schema: z.string().min(1),
                  defaultValue: user.email,
                  disabled: true,
                }),
                input({
                  name: 'jobTitle',
                  label: 'Job Title',
                  schema: z.string().min(1),
                  defaultValue: user.jobTitle,
                }),
              ]}
              onSubmit={handlers.onSubmit}
            >
              {({ onSubmit }) => (
                <div>
                  <Button onClick={onSubmit}>Save</Button>
                </div>
              )}
            </FormRenderer>
          </SheetContentExtension>
        </Sheet>
      </div>
      <p className="text-sm text-gray-400 dark:text-gray-400">{user.email}</p>
    </div>
  );
};

export default MemberDetails;
