import React from 'react';
import { AlertTriangle } from 'lucide-react';

const FormFieldComment = ({ value }: { value: string }) => {
  return (
    <div className="flex items-center gap-x-2 rounded border border-warning bg-warning/10 p-4">
      <AlertTriangle className="size-4 text-warning" />
      <p className="text-sm font-semibold text-warning">{value || ''}</p>
    </div>
  );
};

export default FormFieldComment;
