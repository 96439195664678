import React from 'react';
import { Dot } from 'lucide-react';
import { cn } from '@/lib/utils';
import { DateTime } from 'luxon';
import { TimesheetDuty } from 'lib/types';
import { useSchedulerContext } from '../../../../hooks';
import { UpdateStatus, UpdateType } from '../../../../types';
import { ContentEventProps } from './types';

const ContentEvent = ({ eventRecord }: ContentEventProps) => {
  const update = useSchedulerContext().updates.get(eventRecord.id);

  const isSuccess = update && update.status === UpdateStatus.Applied;
  const isCreated = update && update.type === UpdateType.Create;
  const isDeleted = update && update.type === UpdateType.Delete;
  const isUpdated =
    update &&
    (update.type === UpdateType.Update ||
      update.type === UpdateType.UpdateTransfer);
  const isActive = eventRecord.getData('isActive') as boolean;

  const timesheetDuty = eventRecord.getData(
    'timesheetDuty',
  ) as TimesheetDuty & { isNew: boolean; isHighlighted?: boolean };

  const getStateClassName = () => {
    if (isSuccess) return 'active';
    if (isDeleted) return 'deleted';
    if (isUpdated) return 'updated';
    if (isCreated) return 'created';
    if (isActive) return 'active';
    return 'other';
  };

  if (timesheetDuty.activity.jobTask) {
    const {
      activity: {
        jobTask: { job, name, number: taskNumber },
      },
    } = timesheetDuty;

    const dateTimeStart = DateTime.fromJSDate(eventRecord.startDate as Date);
    const dateTimeEnd = DateTime.fromJSDate(eventRecord.endDate as Date);

    return (
      <div
        className={cn(
          getStateClassName(),
          'flex flex-col gap-y-1 text-accent-foreground',
        )}
      >
        <div className="flex items-center gap-x-1">
          <p className="text-xs font-medium">{job.reference}</p>
          <Dot className="size-3" />
          <p className="text-xs font-semibold">
            {(dateTimeStart as unknown as DateTime).toLocaleString(
              DateTime.TIME_24_SIMPLE,
            )}
            {' - '}
            {(dateTimeEnd as unknown as DateTime).toLocaleString(
              DateTime.TIME_24_SIMPLE,
            )}
          </p>
        </div>
        <div>
          <p className="text-xs font-medium">{name}</p>
        </div>
      </div>
    );
  }

  return null;
};

export default ContentEvent;
