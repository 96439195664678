import { BryntumSchedulerProProps } from '@bryntum/schedulerpro-react-thin';
import { ViewPreset } from '@bryntum/scheduler-thin';

export const defaultViewPreset = new ViewPreset({
  id: 'default',
  name: 'Default',
  tickWidth: 64,
  tickHeight: 24,
  displayDateFormat: 'HH:mm',
  shiftIncrement: 1,
  shiftUnit: 'day',
  defaultSpan: 24,
  timeResolution: {
    unit: 'hour',
    increment: 1,
  },
  headers: [
    {
      unit: 'day',
      dateFormat: 'ddd DD MMM',
    },
    {
      unit: 'hour',
      dateFormat: 'HH:mm',
    },
  ],
  columnLinesFor: 1,
});

export const defaultConfig: Partial<BryntumSchedulerProProps> = {
  viewPreset: defaultViewPreset,
};
