import { useMatch } from 'react-router-dom';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Loader } from '@/components/ui/loading';
import UserPhoto from '../../../../../../../../shared/UserPhoto';
import { useTrainingStaffMemberContext } from '../../context';

const MemberDetails = () => {
  const { user } = useTrainingStaffMemberContext();
  const match = useMatch('/hr/training/staff/:username');

  if (!user) {
    const name = match?.params?.username?.replace('.', ' ');
    return (
      <div>
        <div className="flex items-center gap-x-4 rounded-lg border bg-background p-4">
          <Avatar className="size-16">
            <AvatarFallback>
              <Loader />
            </AvatarFallback>
          </Avatar>
          <div>
            <h1 className="text-xl font-semibold capitalize">
              {name || 'Loading'}
            </h1>
            <p className="text-sm dark:text-gray-400">Loading user...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center gap-x-4 rounded-lg border bg-background p-4">
        <UserPhoto
          user={user}
          className="size-16"
          avatarClassName="size-16"
          avatarFallbackClassName="text-base"
        />
        <div>
          <h1 className="text-xl font-semibold">{user.name}</h1>
          <p className="text-sm dark:text-gray-400">{user.email}</p>
        </div>
      </div>
    </div>
  );
};

export default MemberDetails;
