import React from 'react';
import { PlusIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useFormContext } from '../../context';
import { getHorizontalPadding } from '../../utils';
import { FormContainerRepeatingStubProps } from './types';

const FormContainerRepeatingStub = ({
  item,
}: FormContainerRepeatingStubProps) => {
  const { readOnly, repeatingBranchOnCreate } = useFormContext();
  return readOnly ? null : (
    <div
      className="bg:primary border-b p-4"
      style={{ paddingLeft: getHorizontalPadding(item.depth) }}
    >
      <div className="flex items-center gap-x-2">
        <Button
          variant="outline"
          size="icon"
          onClick={() => repeatingBranchOnCreate(item)}
        >
          <PlusIcon className="h-4 w-4" />
        </Button>
        <p className="text-sm font-bold">Add {item.data.value}</p>
      </div>
    </div>
  );
};

export default FormContainerRepeatingStub;
