import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { CompanyInsurancesQueryData } from './types';

export const useCompanyInsurances = () => {
  const { data, refetch } = useQuery<CompanyInsurancesQueryData>(gql`
    query GetCompanyInsurances {
      companyInsurances {
        id
        notes
        coverName
        issuer
        policyNumber
        dateTimeCoverStart
        dateTimeCoverEnd
        indemnityLimit
        document {
          id
          name
          type
          size
          data
        }
      }
    }
  `);

  const insurances = useMemo(() => data?.companyInsurances || [], [data]);

  return {
    context: {
      insurances,
      refetch,
    },
  };
};
