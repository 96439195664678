import React from 'react';
import { ReportBlockComponent } from 'lib/shared';
import BlockHeader from './BlockHeader';
import BlockJob from './BlockJob';
import BlockTask from './BlockJobTask';

export default {
  header: (props) => <BlockHeader {...props} />,
  job: (props) => <BlockJob {...props} />,
  task: (props) => <BlockTask {...props} />,
} as Record<string, ReportBlockComponent>;
