import { WindowManager } from 'lib/shared';
import SeriesCommercial from '../SeriesCommercial';
import SeriesJob from '../SeriesJob';
import SeriesOverview from '../SeriesOverview';

const SeriesWindowManager = () => {
  return <WindowManager.Router routes={routes} />;
};

const routes = [
  {
    path: '/',
    component: <SeriesOverview />,
  },
  {
    path: '/commercial',
    component: <SeriesCommercial />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/*',
    component: <SeriesJob />,
  },
];

export default SeriesWindowManager;
