import { useActivityTableContext } from '../../../../context';

const ValueFooter = () => {
  const { totalEstimatedValue, totalAppliedValue } = useActivityTableContext();
  return (
    <div className="flex">
      <div className="w-7/12">
        <div className="grid grid-cols-8">
          <div className="col-span-6 border-r" />
          <div className="col-span-1 flex items-center border-b border-r px-2 text-xs font-semibold">
            Total
          </div>
          <div className="col-span-1 flex h-8 items-center border-b px-2 font-semibold">
            {totalEstimatedValue.toFixed(2)}
          </div>
        </div>
      </div>
      <div className="w-5/12">
        <div className="grid grid-cols-6">
          <div className="col-span-2 border-r" />
          <div className="col-span-1 flex items-center border-b px-2 text-xs font-semibold">
            Total
          </div>
          <div className="col-span-1 flex h-8 items-center border-b border-r px-2 font-semibold">
            {totalAppliedValue.toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValueFooter;
