import { createContext, useContext } from 'react';
import { useMaintenanceSeries } from './hooks';

export const MaintenanceSeriesContext = createContext(
  {} as ReturnType<typeof useMaintenanceSeries>['context'],
);

export const useMaintenanceSeriesContext = () => {
  return useContext(MaintenanceSeriesContext);
};
