import React, { useMemo } from 'react';
import { flexRender } from '@tanstack/react-table';
import { ArrowUpIcon, DotIcon, Hexagon } from 'lucide-react';
import { useNavigate } from 'react-router';
import { Button } from '@/components/ui/button';
import { ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import chroma from 'chroma-js';
import { DateTime } from 'luxon';
import { ScopeLabel, WindowManager } from 'lib/shared';
import TodoUrgency from '../../../../../external/Todos/components/TodoUrgency';
import ClientJob from '../../../../ClientJob';
import { useTodosWorkspace } from './hooks';

const TodosWorkspace = () => {
  const navigate = useNavigate();
  const { table, selected, select, clearSelected } = useTodosWorkspace();
  const window = selected?.data?.window;
  const dependencies = useMemo(() => [selected?.id], [selected?.id]);
  return (
    <ResizablePanelGroup
      direction="horizontal"
      className="grow items-stretch bg-background"
    >
      <ResizablePanel defaultSize={20} minSize={20}>
        <div className="flex h-full min-h-0 w-full flex-col">
          <div className="border-b">
            {table.getHeaderGroups().map((headerGroup) => (
              <div key={headerGroup.id} className="grid grid-cols-3">
                {headerGroup.headers.map((header) => {
                  const { span } = (header.column.columnDef.meta as any) || {
                    meta: 1,
                  };
                  if (header.column.getCanSort()) {
                    return (
                      <Button
                        key={header.id}
                        variant="ghost"
                        className={cn(
                          'flex justify-start gap-x-2 rounded-none bg-background-secondary px-4 py-2 text-xs font-semibold ring-1 ring-border',
                          `col-span-${span}`,
                        )}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                        {header.column.getIsSorted() && (
                          <ArrowUpIcon
                            className={cn('h-4 w-4 transition-all', {
                              'rotate-180':
                                header.column.getIsSorted() === 'desc',
                            })}
                          />
                        )}
                      </Button>
                    );
                  }
                  return (
                    <div
                      key={header.id}
                      className={cn(
                        'relative bg-background-secondary px-4 py-2 text-sm font-semibold ring-1 ring-border',
                        `col-span-${span}`,
                      )}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <ScrollArea className="min-h-0 grow">
            <div>
              {table.getRowModel().rows?.length
                ? table.getRowModel().rows.map((row) => {
                    const isSelected = selected?.id === row.original.id;
                    const color = chroma.mix(
                      'green',
                      'red',
                      row.original.urgency,
                    );
                    return (
                      <Button
                        key={row.id}
                        variant="ghost"
                        className={cn(
                          'group relative grid h-[42px] w-full grid-cols-3 rounded-none border-0 p-0',
                        )}
                        data-state={row.getIsSelected() && 'selected'}
                        onClick={() => select(row.original)}
                      >
                        {row.getVisibleCells().map((cell) => {
                          const { span, button } = (cell.column.columnDef
                            .meta as any) || {
                            meta: 1,
                            button: false,
                          };
                          return (
                            <div
                              key={cell.id}
                              className={cn(
                                'flex h-full items-center group-hover:bg-accent/25',
                                `col-span-${span}`,
                                { 'px-2 py-0': button, 'px-4 py-2': !button },
                              )}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </div>
                          );
                        })}
                      </Button>
                    );
                  })
                : null}
            </div>
          </ScrollArea>
          <div className="h-[41px] flex-shrink-0 border-t bg-background-secondary px-4"></div>
        </div>
      </ResizablePanel>
      <Separator orientation="vertical" />
      <ResizablePanel defaultSize={80}>
        {selected && window ? (
          <WindowManager
            initialPath={window.initialPath}
            dependencies={dependencies}
            onClose={() => {}}
          >
            <div className="flex h-full flex-col">
              <div className="flex h-[37px] w-full flex-shrink-0 items-center border-b bg-background-secondary px-4">
                <div className="flex w-full grow items-center gap-2">
                  <p className="text-sm leading-6">
                    <ScopeLabel label={selected.data.card.description} />
                  </p>
                  <DotIcon className="h-2 w-2 text-muted-foreground" />
                  <p className="text-sm">
                    {DateTime.fromSeconds(selected.deadline).toLocaleString(
                      DateTime.DATETIME_FULL,
                    )}
                  </p>
                </div>
                <div className="flex content-between items-center gap-2">
                  <div className="w-32">
                    <TodoUrgency urgency={selected.urgency} />
                  </div>
                  <Button
                    size="xs"
                    variant="outline"
                    onClick={() => {
                      navigate('');
                      clearSelected();
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
              <ClientJob key={selected.id} id={window.props.id} small />
            </div>
          </WindowManager>
        ) : (
          <div className="h-full w-full p-4">
            <div className="flex h-full w-full items-center rounded border border-dashed text-center">
              <div className="grow">
                <Hexagon className="dark:text-white-400 mx-auto mb-2 h-4 w-4 text-gray-400" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900 dark:text-white/50">
                  Nothing selected
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Select a todo from the list to begin.
                </p>
              </div>
            </div>
          </div>
        )}
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};

export default TodosWorkspace;
