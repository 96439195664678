import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { SubcontractorContact } from 'lib/types';
import { useSubcontractorContext } from '../../../../context';
import {
  DeleteSubcontractorContactMutationData,
  DeleteSubcontractorContactMutationVariables,
} from './types';

export const useContactsDelete = (contact: SubcontractorContact) => {
  const { subcontractor } = useSubcontractorContext();

  const [delete_] = useMutation<
    DeleteSubcontractorContactMutationData,
    DeleteSubcontractorContactMutationVariables
  >(
    gql`
      mutation DeleteSubcontractorContact($id: ID!) {
        deleteSubcontractorContact(subcontractorContactId: $id) {
          deleted
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnDelete = async () => {
    if (!subcontractor) return;

    const { data, errors } = await delete_({
      variables: {
        id: contact.id,
      },
    });

    if (data) {
      await subcontractor.refetch();
      toast.success('Contact deleted');
    }

    if (errors) {
      console.error(errors);
      toast.error('Failed to delete contact');
    }
  };

  return {
    handlers: {
      onDelete: handleOnDelete,
    },
  };
};
