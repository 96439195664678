import { Job, JobIssue, JobReport } from './jobs';
import { PlatformObject } from './platform';
import { User, UserGroup } from './users';

export type Client = {
  id: string;
  name: string;
  description: string;
  slug: string;
  userGroups: UserGroup[];
  userGroupIds: string[];
  usersReactive: User[];
  logo?: PlatformObject;
  logoDark?: PlatformObject;
  accentColor?: string;
  accentColorDark?: string;
  openJobs: Job[];
  openIssues: JobIssue[];
  openReports: JobReport[];
  operatives: UserGroup;
};
