import React from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { Button } from '@/components/ui/button';
import { gql, useQuery } from '@apollo/client';
import { useClient } from '../../../../../../sections/Client/hooks';
import { useSyncedTransact } from '../../../../hooks';
import { useChoiceSetEditorContext } from '../../context';
import {
  CommercialContractQueryData,
  CommercialContractQueryVariables,
  CommercialContractsQueryData,
  CommercialContractsQueryVariables,
} from './types';

const DynamicCommercialContractRates = () => {
  const { choiceSet } = useChoiceSetEditorContext();

  if (!choiceSet) return null;

  if (!choiceSet.sourceConfig?.contract) {
    return <DynamicCommercialContractSelector />;
  }

  return <DynamicCommercialContract />;
};

const DynamicCommercialContract = () => {
  const { store, choiceSet } = useChoiceSetEditorContext();

  const { choiceSets } = useSyncedStore(store);
  const transact = useSyncedTransact(store);

  const { data } = useQuery<
    CommercialContractQueryData,
    CommercialContractQueryVariables
  >(gql(choiceSet!.sourceConfig.query as string), {
    fetchPolicy: 'cache-and-network',
    variables: choiceSet!.sourceConfig.variables,
  });

  const handleOnBack = () => {
    if (!choiceSet) return;

    transact(() => {
      choiceSets[choiceSet.id].sourceConfig = undefined;
    });
  };

  if (!choiceSet) return null;

  return (
    <div>
      DynamicCommercialContractRates
      {choiceSet.sourceConfig.contract}
      {data && (
        <div>
          {data.commercialContract.rates.map((rate) => (
            <div key={rate.id}>{rate.id}</div>
          ))}
        </div>
      )}
      <Button onClick={handleOnBack}>Back</Button>
    </div>
  );
};

const DynamicCommercialContractSelector = () => {
  const { id } = useClient();
  const { store, choiceSet } = useChoiceSetEditorContext();

  const { choiceSets } = useSyncedStore(store);
  const transact = useSyncedTransact(store);

  const { data } = useQuery<
    CommercialContractsQueryData,
    CommercialContractsQueryVariables
  >(
    gql`
      query GetCommercialContracts($clientId: ID!) {
        commercialContracts(clientId: $clientId) {
          id
          name
        }
      }
    `,
    { fetchPolicy: 'cache-and-network', variables: { clientId: id } },
  );

  const handleOnSelect = (contract: any) => {
    return () => {
      transact(() => {
        choiceSets[choiceSet!.id].sourceConfig = {
          contract: contract.id,
          query: `
            query GetCommercialContract($id: ID!) {
              commercialContract(commercialContractId: $id) {
                id
                rates {
                  id
                  label: name
                  value: id
                }
              }
            }
          `,
          variables: { id: contract.id },
          mapping: '$.commercialContract.rates',
        };
      });
    };
  };

  if (!data) return null;

  return (
    <div>
      Select contract
      {data.commercialContracts.map((contract) => (
        <div>
          {contract.name}
          <Button onClick={handleOnSelect(contract)}>Select</Button>
        </div>
      ))}
    </div>
  );
};

const DynamicCommercialContractRatesCard = () => {
  const { handlers } = useChoiceSetEditorContext();
  return (
    <div>
      <Button
        onClick={handlers.onSelectDynamicSource(
          'commercial::contract-rate::list',
        )}
      >
        Select
      </Button>
    </div>
  );
};

DynamicCommercialContractRates.Card = DynamicCommercialContractRatesCard;
export default DynamicCommercialContractRates;
