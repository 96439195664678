import React, { Fragment } from 'react';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { DateTime } from 'luxon';
import { Empty, PageLoader } from 'lib/shared';
import EventBody from './components/EventBody';
import { useEventsRenderer } from './hooks';
import { EventsProps } from './types';

const Events = ({
  scope,
  asStack = true,
  standalone = false,
  excludedNames = [],
}: EventsProps) => {
  const { events, eventsByDate, eventsByDateFormat, isLoading } =
    useEventsRenderer(scope, excludedNames);
  const body = (
    <div>
      {Object.entries(eventsByDate).map(([date, eventsOnDate]) => (
        <Fragment key={date}>
          <div className="sticky top-0 z-[1] whitespace-nowrap border-none bg-background-secondary p-0">
            <div className="border-y px-4 py-2">
              <p className="text-xs font-medium text-gray-500 dark:text-gray-400">
                {DateTime.fromFormat(date, eventsByDateFormat).toLocaleString(
                  DateTime.DATE_FULL,
                )}
              </p>
            </div>
          </div>
          {eventsOnDate.map((event, index, events) => (
            <EventBody
              key={event.id}
              event={event}
              last={index === events.length - 1}
            />
          ))}
        </Fragment>
      ))}
    </div>
  );

  if (standalone) return body;

  if (!asStack) {
    if (events.length === 0) {
      return <Empty className="p-4">No events</Empty>;
    }
    return <ScrollArea>{body}</ScrollArea>;
  }

  return (
    <div className="flex h-full min-h-0 min-w-0 flex-col rounded-lg border bg-background">
      <div className="flex items-center justify-between p-4">
        <h3 className="font-semibold">Events</h3>
        <Button className="h-6 p-0" size="sm" variant="link">
          Latest first
        </Button>
      </div>
      {events.length > 0 ? (
        <div className="min-h-0 grow">
          <ScrollArea className="relative z-10 h-full min-h-0">
            {body}
          </ScrollArea>
        </div>
      ) : isLoading ? (
        <PageLoader className="p-4">Loading events...</PageLoader>
      ) : (
        <Empty className="p-4">No events</Empty>
      )}
    </div>
  );
};

export * from './hooks';

export default Events;
