import React from 'react';
import { Button } from '@/components/ui/button';
import {
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import { useChoiceSetEditorContext } from '../../context';
import DynamicCommercialContractRates from '../../dynamic/DynamicCommercialContractRates';

const EditorDynamic = () => {
  const { handlers } = useChoiceSetEditorContext();
  return (
    <SheetContent className="flex min-h-0 flex-col gap-y-0 p-0">
      <SheetHeader className="p-4">
        <SheetTitle>Edit choice set</SheetTitle>
        <SheetDescription>Add, edit or remove choices</SheetDescription>
      </SheetHeader>
      <div>
        <EditorDynamicRenderer />
        <Button onClick={handlers.onClearDynamicSource}>Reset</Button>
      </div>
    </SheetContent>
  );
};

const EditorDynamicRenderer = () => {
  const { choiceSet } = useChoiceSetEditorContext();

  if (!choiceSet?.source) {
    return (
      <div className="flex flex-col gap-y-4">
        <DynamicCommercialContractRates.Card />
      </div>
    );
  }

  if (choiceSet.source === 'commercial::contract-rate::list') {
    return <DynamicCommercialContractRates />;
  }

  return null;
};

export default EditorDynamic;
