import { useState } from 'react';
import { Job } from 'lib/types';

export const useMaintenanceSeries = () => {
  const [active, setActive] = useState<Job>();
  return {
    context: {
      active: {
        value: active,
        set: setActive,
      },
    },
  };
};
