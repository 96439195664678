import React from 'react';
import { DateTime } from 'luxon';
import { useCommercialContractContext } from '../../context';
import DetailsUpdate from './components/DetailsUpdate';

const ContractDetails = () => {
  const { contract } = useCommercialContractContext();
  if (!contract) return null;
  return (
    <div>
      <div className="mb-4 flex items-end justify-between">
        <div>
          <p className="mb-1 text-sm font-bold">Details</p>
          <p className="text-xs text-gray-400">
            View the accounting information for this contract.
          </p>
        </div>
        <DetailsUpdate />
      </div>
      <div className="rounded-lg border bg-secondary p-2">
        <div className="rounded-md bg-background-secondary p-3">
          <div className="mb-4 flex gap-x-8">
            <div>
              <p className="mb-0.5 text-xs font-semibold dark:text-gray-400">
                Name
              </p>
              <p className="text-sm">{contract.name}</p>
            </div>
            <div>
              <p className="mb-0.5 text-xs font-semibold dark:text-gray-400">
                Start
              </p>
              <p className="text-sm">
                {DateTime.fromISO(contract.dateStart).toLocaleString(
                  DateTime.DATE_SHORT,
                )}
              </p>
            </div>
            <div>
              <p className="mb-0.5 text-xs font-semibold dark:text-gray-400">
                End
              </p>
              <p className="text-sm">
                {DateTime.fromISO(contract.dateEnd).toLocaleString(
                  DateTime.DATE_SHORT,
                )}
              </p>
            </div>
          </div>
          <div>
            <p className="mb-0.5 text-xs font-semibold dark:text-gray-400">
              Description
            </p>
            <p className="text-sm">{contract.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractDetails;
