import { gql, useQuery } from '@apollo/client';
import { useMatch } from 'lib/hooks';
import { useClient } from '../../hooks';
import { ClientAssetQueryData, ClientAssetQueryVariables } from './types';

export const useClientAsset = () => {
  const client = useClient();
  const match = useMatch('/clients/:clientSlug/assets/:reference');

  const { data, refetch } = useQuery<
    ClientAssetQueryData,
    ClientAssetQueryVariables
  >(
    gql`
      query GetAsset($clientId: ID!, $reference: String!) {
        asset(clientId: $clientId, assetReference: $reference) {
          id
          reference
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      skip: !match?.params?.reference,
      variables: {
        clientId: client.id,
        reference: match?.params?.reference!,
      },
    },
  );

  if (!data?.asset) {
    return {
      asset: null,
    };
  }

  data.asset.refetch = refetch;
  return {
    asset: data.asset,
  };
};
