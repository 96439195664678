import React from 'react';
import { DateTime } from 'luxon';
import { PendingDateTimeDiffProps, PendingDateTimeProps } from './types';

const PendingDateTime = ({ className, title, value }: PendingDateTimeProps) => {
  return (
    <div className={className}>
      <div className="mb-1">
        <p className="text-xs font-semibold uppercase tracking-wide text-gray-400 dark:text-gray-300">
          {title}
        </p>
      </div>
      <div className="flex items-center gap-x-2">
        <p className="font-mono font-medium">
          {value.toLocaleString(DateTime.TIME_24_SIMPLE)}
        </p>
        <p className="text-sm">{value.toLocaleString(DateTime.DATE_MED)}</p>
      </div>
    </div>
  );
};

const PendingDateTimeDiff = ({
  className,
  title,
  duty,
  diff,
}: PendingDateTimeDiffProps) => {
  const isSame = duty.equals(diff);
  return (
    <div className={className}>
      <div className="mb-1 flex items-center justify-between">
        <p className="text-xs font-semibold uppercase tracking-wide text-gray-400 dark:text-gray-300">
          {title}
        </p>
        {isSame ? (
          <p className="text-sm dark:text-gray-400">(unchanged)</p>
        ) : (
          <p className="text-sm dark:text-gray-400">
            ({diff.diff(duty).rescale().toHuman().replace(/-/g, '')}{' '}
            {diff > duty ? <>later</> : <>earlier</>})
          </p>
        )}
      </div>
      <div className="flex justify-between">
        {isSame ? (
          '-'
        ) : (
          <div className="flex items-center gap-x-2 line-through dark:text-gray-400">
            <p className="font-mono font-medium">
              {duty.toLocaleString(DateTime.TIME_24_SIMPLE)}
            </p>
            <p className="text-sm">{duty.toLocaleString(DateTime.DATE_MED)}</p>
          </div>
        )}
        <div className="flex items-center gap-x-2">
          <p className="font-mono font-medium">
            {diff.toLocaleString(DateTime.TIME_24_SIMPLE)}
          </p>
          <p className="text-sm">{diff.toLocaleString(DateTime.DATE_MED)}</p>
        </div>
      </div>
    </div>
  );
};

PendingDateTime.Diff = PendingDateTimeDiff;

export default PendingDateTime;
