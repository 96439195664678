import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocalStorage } from '@uidotdev/usehooks';
import { PlatformTodo } from 'lib/types';
import { platformClient } from '../../../../app/platform';
import { useTodoContext } from '../../../../external/TodosProvider/hooks';
import { useScopeContext } from '../../../../shared/ScopeProvider/context';
import { useClient } from '../../hooks';
import { ViewMode } from './types';

export const useClientTodos = () => {
  const client = useClient();

  const { registerScopes } = useScopeContext();
  const { clearSelectedTodo } = useTodoContext();

  const [mode, setMode] = useLocalStorage(
    'client-todos-view-mode',
    ViewMode.Table,
  );

  const { data, refetch } = useQuery<{ results: PlatformTodo[] }>({
    queryKey: ['platform', 'todos'],
    queryFn: platformClient.getTodos({
      scope: client.scope,
      status: 'OPEN',
      sort: { urgency: 'desc' },
      pagination: { page_size: 1000 },
    }),
  });

  useEffect(() => {
    registerScopes(data?.results.flatMap((todo) => todo.scopes) ?? []);
  }, [data]);

  const handleOnValueChange = (value: ViewMode) => {
    clearSelectedTodo();
    setMode(value);
  };

  return {
    context: {
      mode: {
        value: mode,
        set: setMode,
      },
      todos: data?.results ?? [],
      refetch,
    },
    handlers: {
      onValueChange: handleOnValueChange,
    },
  };
};
