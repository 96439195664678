import React from 'react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { UpdateStatus } from '../../../../types';
import { usePendingStyle } from '../../hooks';
import PendingDateTime from '../PendingDateTime';
import PendingDuration from '../PendingDuration';
import PendingInteractions from '../PendingInteractions';
import PendingStaffMember from '../PendingStaffMember';
import PendingSubcontractor from '../PendingSubcontractor';
import { PendingCreateProps } from './types';

const PendingCreate = ({
  update,
  onHighlight,
  onRevert,
  onConfirm,
}: PendingCreateProps) => {
  const style = usePendingStyle(update, {
    backgroundColor: 'rgba(4, 204, 255, 0.1)',
    borderColor: 'rgba(4, 204, 255, 0.75)',
  });
  return (
    <div className="rounded-md border p-4" style={style}>
      <div className="mb-4 flex items-center justify-between">
        <p className="text-sm font-semibold">
          {update.status ?? 'Pending create'}
        </p>
        <div className="flex gap-x-2">
          <Badge className="h-5" variant="incoming">
            New timesheet duty
          </Badge>
        </div>
      </div>
      <div>
        {update.diff.user && (
          <>
            <PendingStaffMember className="mb-4" value={update.diff.user} />
            <PendingInteractions
              className="mb-4"
              id={update.diff.id}
              value={update.diff.interactions}
            />
          </>
        )}
        {update.diff.subcontractor && (
          <PendingSubcontractor
            className="mb-4"
            value={update.diff.subcontractor}
          />
        )}
        {update.diff.dateTimeStart && (
          <PendingDateTime
            className="mb-4"
            title="Start"
            value={update.diff.dateTimeStart}
          />
        )}
        {update.diff.dateTimeEnd && (
          <PendingDateTime
            className="mb-4"
            title="End"
            value={update.diff.dateTimeEnd}
          />
        )}
        {update.diff.dateTimeStart && update.diff.dateTimeEnd && (
          <PendingDuration
            className="mb-4"
            title="Duration"
            value={update.diff.dateTimeEnd.diff(update.diff.dateTimeStart)}
          />
        )}
      </div>
      <div className="flex justify-between">
        <div className="flex items-center gap-x-2">
          <Button size="sm" variant="outline" onClick={onHighlight}>
            Highlight
          </Button>
          {!update.status && (
            <Button size="sm" variant="outline" onClick={onRevert}>
              Revert
            </Button>
          )}
          {update.status === UpdateStatus.Error && (
            <Button size="sm" variant="outline" onClick={onConfirm}>
              Retry
            </Button>
          )}
        </div>
        <Button
          size="sm"
          variant="outline"
          disabled={!!update.status}
          isLoading={update.status === UpdateStatus.Applying}
          onClick={onConfirm}
        >
          {!update.status && 'Create'}
          {update.status === UpdateStatus.Applied && 'Created timesheet duty'}
          {update.status === UpdateStatus.Error && 'Failed'}
        </Button>
      </div>
    </div>
  );
};

export default PendingCreate;
