import { RefObject } from 'react';
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react-thin';
import { DragHelper, DragHelperConfig } from '@bryntum/core-thin';
import { ResourceModel, SchedulerPro } from '@bryntum/schedulerpro-thin';
import { Duration } from 'luxon';
import {
  Client,
  Job,
  JobTask,
  Nullable,
  Subcontractor,
  TimesheetDuty,
  User,
} from 'lib/types';
import { DutyResource } from './config';

export enum AssignmentSchedulerMode {
  StaffMembers = 'STAFF_MEMBERS',
  Subcontractors = 'SUBCONTRACTORS',
}

export enum AssignmentResourceType {
  'User' = 'USER',
  'Subcontractor' = 'SUBCONTRACTOR',
}

export interface IDrag extends DragHelper {
  scrollManager: any;
  grid: any;
  schedule: SchedulerPro;
}

export type DragProps = Partial<DragHelperConfig> & {
  defaultDuration: Duration;
  schedule: SchedulerPro;
  getJobTaskById: (id: string) => JobTask;
  setIsDragging: (isDragging: boolean) => void;
  setActive: (active: Nullable<JobTask>) => void;
};

export type DragStartEvent = {
  context: {
    grabbed: HTMLElement;
    task: JobTask;
  };
};

export type DragEvent = {
  context: {
    target: HTMLElement;
    resource: DutyResource;
  };
};

export type DragEndEvent = {
  context: {
    newX: number;
    valid: boolean;
    target: HTMLElement;
    resource: ResourceModel &
      (
        | {
            type: AssignmentResourceType.User;
            user: User;
          }
        | {
            type: AssignmentResourceType.Subcontractor;
            user: Subcontractor;
          }
      );
    task: JobTask;
  };
};

export type InlineDataQueryVariables = {
  clientId: Client['id'];
  jobId: Job['id'];
  jobTaskId: JobTask['id'];
};

export type InlineDataQueryData = {
  client: Client;
  job: Job;
  users: User[];
  subcontractors: Subcontractor[];
  timesheetDuties: TimesheetDuty[];
};

export type SchedulerRefs = {
  staffMembers: RefObject<BryntumSchedulerPro>;
  subcontractors: RefObject<BryntumSchedulerPro>;
};
