import { createContext, useContext } from 'react';
import { useStaffMember } from './hooks';

export const StaffMemberContext = createContext(
  {} as ReturnType<typeof useStaffMember>['context'],
);

export const useStaffMemberContext = () => {
  return useContext(StaffMemberContext);
};
