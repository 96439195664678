import React from 'react';
import { Clipboard } from 'lucide-react';
import ReactJson from 'react-json-view';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Sheet,
  SheetContent,
  SheetContentExtension,
  SheetTrigger,
} from '@/components/ui/sheet';
import { useUser } from 'lib/hooks';
import { FormTemplate } from 'lib/types';
import { getPages } from '../../../../../../../../../../shared/FormRenderer/parse';
import JobTaskForm from '../../../../../../../../components/JobTaskForm';
import { useTaskFormContext } from '../../context';

const FormStaffMember = () => {
  const user = useUser();
  const { form, state, handlers } = useTaskFormContext();
  return (
    <Sheet open={state.open} onOpenChange={state.onOpenChange}>
      <SheetTrigger asChild>
        <Button variant="outline" size="sm" className="mr-4 font-bold">
          <Clipboard className="mr-2 h-4 w-4" />
          {form.template.name}
        </Button>
      </SheetTrigger>
      <SheetContent className="w-[700px] max-w-[700px] p-0 sm:max-w-[700px]">
        <JobTaskForm
          form={form}
          onApprove={handlers.onApprove}
          onEdit={handlers.onEdit}
          debug={
            user?.email === 'jamie.williams@mchughltd.co.uk'
              ? ({ formTemplate, getSubmission }) => (
                  <Sheet>
                    <SheetTrigger asChild>
                      <Button className="mx-2" variant="outline" size="sm">
                        Debug
                      </Button>
                    </SheetTrigger>
                    <SheetContentExtension className="w-[1000px] min-w-[1000px] p-4">
                      <ScrollArea className="h-full min-h-0">
                        <ReactJson
                          src={transformSubmission(
                            formTemplate,
                            getSubmission(),
                          )}
                          theme="twilight"
                        />
                      </ScrollArea>
                    </SheetContentExtension>
                  </Sheet>
                )
              : undefined
          }
        />
      </SheetContent>
    </Sheet>
  );
};

const transformSubmission = (formTemplate: FormTemplate, submission: any) => {
  const template = JSON.parse(formTemplate.data);
  const values = submission.submission.values;

  const { pages } = getPages(template, values, [], () => true);

  const pass = (path: string) => {
    const itemId = path.split('::').pop();
    const item = template.items[itemId as string];

    if (!item) {
      return {
        path,
        item: itemId,
        error: 'not found',
      };
    }

    if (item.type === 'PAGE') {
      return item.children.reduce((acc: any, childId: string) => {
        const childPath = `${path}::${childId}`;
        const childName = template.items[childId].data.value.toLowerCase();
        const childLabel = template.fieldLabels[childId]?.label;
        return {
          ...acc,
          [childLabel || childName || childId]: pass(childPath),
        };
      }, {});
    }

    if (item.type === 'CONTAINER' && !item.data.isRepeated) {
      return item.children.reduce((acc: any, childId: string) => {
        const childPath = `${path}::${childId}`;
        const childName = template.items[childId].data.value.toLowerCase();
        const childLabel = template.fieldLabels[childId]?.label;
        return {
          ...acc,
          [childLabel || childName || childId]: pass(childPath),
        };
      }, {});
    }

    if (item.type === 'CONTAINER' && item.data.isRepeated) {
      if (!values[path]) {
        return [];
      }

      return values[path].value.map((branchId: string) => {
        const branchPath = `${path}::${branchId}`;
        return item.children.reduce((acc: any, childId: string) => {
          const childPath = `${branchPath}::${childId}`;
          const childName = template.items[childId].data.value.toLowerCase();
          const childLabel = template.fieldLabels[childId]?.label;
          return {
            ...acc,
            [childLabel || childName || childId]: pass(childPath),
          };
        }, {});
      });
    }

    return values[path];
  };

  return pages.reduce((acc: any, page: any) => {
    return { ...acc, [page.data.name.toLowerCase()]: pass(`root::${page.id}`) };
  }, {});
};

export default FormStaffMember;
