import React, { useMemo } from 'react';
import { TrashIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  MediaInputDocument,
  MediaInputDropzone,
  MediaInputImage,
} from '@/components/ui/form/form-media/FormMedia';
import { Progress } from '@/components/ui/progress';
import { Separator } from '@/components/ui/separator';
import { filesize } from 'filesize';
import { Empty } from 'lib/shared';
import { PlatformObject, PlatformObjectImageData } from 'lib/types';
import { useFormFieldMedia } from './hooks';
import { FormFieldMediaProps } from './types';

const FormFieldMedia = ({
  readOnly,
  value: valueOrProxy = [],
  dropzone,
  onDelete,
}: ReturnType<typeof useFormFieldMedia>) => {
  const value = JSON.parse(JSON.stringify(valueOrProxy));
  return (
    <div className="mt-4 flex flex-col gap-y-4">
      {[...value].reverse().map((upload) => (
        <FormFieldMediaUpload
          key={upload.id}
          readOnly={readOnly}
          upload={upload}
          onDelete={() => onDelete(upload)}
        />
      ))}
      {!readOnly && (
        <Button size="sm" variant="outline" onClick={dropzone.open}>
          Add media
        </Button>
      )}
    </div>
  );
};

export const FormFieldMediaStandalone = (props: FormFieldMediaProps) => {
  const { readOnly } = props;
  const { value, dropzone, onDelete } = useFormFieldMedia(props);

  if (readOnly && value.length === 0) {
    return <Empty className="h-24">No media provided</Empty>;
  }

  return (
    <div>
      {!readOnly && <MediaInputDropzone dropzone={dropzone} />}
      <div className="mt-4 flex flex-col gap-y-4">
        {[...value].reverse().map((upload) => (
          <FormFieldMediaUpload
            key={upload.id}
            readOnly={readOnly}
            upload={upload}
            onDelete={() => onDelete(upload)}
          />
        ))}
      </div>
    </div>
  );
};

const FormFieldMediaUpload = ({
  readOnly,
  upload,
  onDelete,
}: {
  readOnly: boolean;
  upload: Omit<PlatformObject, 'sources'>;
  onDelete: () => void;
}) => {
  const data = useMemo<PlatformObjectImageData>(() => {
    try {
      return JSON.parse(upload.data);
    } catch (error) {
      return { annotations: [] };
    }
  }, [upload.data]);
  const uploadProgress = upload?.progressPercentage ?? 0;
  const uploadSize = upload.size || data.size;
  return (
    <div key={upload.id} className="rounded-lg border">
      <div className="flex items-center gap-x-4 p-4">
        {upload.type === 'IMAGE' && <MediaInputImage upload={upload} />}
        {upload.type !== 'IMAGE' && <MediaInputDocument upload={upload} />}
        <div className="grow">
          <p className="text-sm font-semibold">{upload.name}</p>
          <div className="mt-2 flex gap-x-2">
            <p className="line-clamp-1 text-xs uppercase">
              {upload.name.split('.').at(-1)}
            </p>
            {!!uploadProgress && uploadProgress !== 100 ? (
              <>
                <div className="py-1">
                  <Separator orientation="vertical" />
                </div>
                <p className="text-xs text-primary">{uploadProgress}%</p>
              </>
            ) : (
              <>
                {!!uploadSize && (
                  <>
                    <div className="py-1">
                      <Separator orientation="vertical" />
                    </div>
                    <p className="text-xs">{filesize(uploadSize)}</p>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {!readOnly && (
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            confirm="Are you absolutely sure?"
            confirmHelp="This will remove the file from the form submission."
            onClick={onDelete}
          >
            <TrashIcon className="h-4 w-4" />
          </Button>
        )}
      </div>
      {!!uploadProgress && uploadProgress !== 100 && (
        <div className="px-4 pb-4">
          <Progress className="h-1 rounded-full" value={uploadProgress} />
        </div>
      )}
    </div>
  );
};

export { useFormFieldMedia };
export default FormFieldMedia;
