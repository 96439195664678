import React, { Fragment } from 'react';
import { Hammer } from 'lucide-react';
import { Marker } from 'react-map-gl';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { JobBoardCard } from '../../../../../ClientJobs/components/JobsBoard';
import { useActivityMapContext } from '../../context';

const MapJobs = () => {
  const { jobs, groupBySite } = useActivityMapContext();
  return (
    <>
      {groupBySite(jobs).map(({ site, jobs }) => {
        if (!site.location?.precise) return null;

        if (jobs.length === 1) {
          const [job] = jobs;
          return (
            <Fragment key={site.id}>
              <Marker
                longitude={site.location.precise.geometry.point.longitude}
                latitude={site.location.precise.geometry.point.latitude}
              >
                <div className="h-12 w-12 rounded-full border border-incoming-foreground/25 bg-incoming-foreground/10" />
              </Marker>
              <Marker
                longitude={site.location.precise.geometry.point.longitude}
                latitude={site.location.precise.geometry.point.latitude}
              >
                <div className="relative size-4">
                  <Hammer
                    fill="white"
                    className="absolute inset-0 size-4 fill-incoming-foreground stroke-incoming-foreground"
                  />
                </div>
              </Marker>
              <Marker
                offset={[0, 20]}
                longitude={site.location.precise.geometry.point.longitude}
                latitude={site.location.precise.geometry.point.latitude}
              >
                <p>{job.reference}</p>
              </Marker>
            </Fragment>
          );
        }

        return (
          <Fragment key={site.id}>
            <Marker
              longitude={site.location.precise.geometry.point.longitude}
              latitude={site.location.precise.geometry.point.latitude}
            >
              <div className="h-12 w-12 rounded-full border border-incoming-foreground/25 bg-incoming-foreground/10" />
            </Marker>
            <Marker
              longitude={site.location.precise.geometry.point.longitude}
              latitude={site.location.precise.geometry.point.latitude}
            >
              <div className="relative size-4">
                <Hammer
                  fill="white"
                  className="absolute inset-0 size-4 fill-incoming-foreground stroke-incoming-foreground"
                />
              </div>
            </Marker>
            <Marker
              offset={[0, 20]}
              longitude={site.location.precise.geometry.point.longitude}
              latitude={site.location.precise.geometry.point.latitude}
            >
              <Sheet>
                <SheetTrigger asChild>
                  <Button className="p-0" variant="link">
                    <p className="font-semibold dark:[text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">
                      {jobs.length} Jobs
                    </p>
                  </Button>
                </SheetTrigger>
                <SheetContent className="p-0" overlay={false}>
                  <ScrollArea className="h-full">
                    <div className="flex flex-col gap-y-4 p-4">
                      {jobs.map((job) => {
                        return (
                          <div key={job.id}>
                            <JobBoardCard job={job} />
                          </div>
                        );
                      })}
                    </div>
                  </ScrollArea>
                </SheetContent>
              </Sheet>
            </Marker>
          </Fragment>
        );
      })}
    </>
  );
};

export default MapJobs;
