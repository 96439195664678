import { useCallback, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { JobCategory } from 'lib/types';

export const useCategoryEditor = (
  category: JobCategory | undefined,
  onSuccess: () => void,
) => {
  const [open, setOpen] = useState(false);

  const handleSheetOnOpenChange = useCallback(
    async (nextOpen: boolean) => {
      setOpen(nextOpen);
      if (nextOpen && category) {
        await category.refetch();
      }
    },
    [category],
  );

  const [create, { loading: creating }] = useMutation(gql`
    mutation CreateJobPriority($clientId: ID!, $name: String!, $code: String!) {
      createJobCategory(clientId: $clientId, name: $name, code: $code) {
        jobCategory {
          id
        }
      }
    }
  `);

  const [update, { loading: updating }] = useMutation(gql`
    mutation UpdateJobPriority($id: ID!, $name: String!, $code: String!) {
      updateJobCategory(jobCategoryId: $id, name: $name, code: $code) {
        jobCategory {
          id
        }
      }
    }
  `);

  return {
    sheet: {
      open,
      onOpenChange: handleSheetOnOpenChange,
    },
    state: {
      creating,
      updating,
    },
    handlers: {
      create,
      update,
    },
  };
};
