import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { Location } from 'lib/types';
import LocationPicker from '../../../../../../shared/LocationPicker';
import PickerClose from '../../../../../../shared/LocationPicker/components/PickerClose';
import PickerConfirm from '../../../../../../shared/LocationPicker/components/PickerConfirm';
import PickerMap from '../../../../../../shared/LocationPicker/components/PickerMap';
import PickerSearch from '../../../../../../shared/LocationPicker/components/PickerSearch';
import { useLocationPickerContext } from '../../../../../../shared/LocationPicker/hooks';
import { useJobContext } from '../../../../context';
import LocationPreview from '../../../JobInstantiation/components/InstantiationLocation/components/LocationPreview';
import {
  UpdateSiteLocationMutationData,
  UpdateSiteLocationMutationVariables,
} from '../../../JobInstantiation/components/InstantiationLocation/types';

const LocationSheet = () => {
  const { job } = useJobContext();

  const [open, setOpen] = useState(false);

  const [updateSiteLocation] = useMutation<
    UpdateSiteLocationMutationData,
    UpdateSiteLocationMutationVariables
  >(
    gql`
      mutation UpdateSiteLocation(
        $siteId: ID!
        $siteLocationEstimate: LocationEstimateInput!
      ) {
        updateSiteLocation(
          siteId: $siteId
          siteLocationEstimate: $siteLocationEstimate
        ) {
          site {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnChange = async (value: Location | undefined) => {
    if (job.partial) return;

    if (!value) {
      console.warn('Location is undefined');
      return;
    }

    const { data, errors } = await updateSiteLocation({
      variables: {
        siteId: job.site.id,
        siteLocationEstimate: value,
      },
    });

    if (data) {
      await job.refetch();
      setOpen(false);
      toast.success('Site location updated');
    }

    if (errors) {
      toast.error('Failed to update site location');
    }
  };

  if (job.partial) return null;

  return (
    <LocationPicker value={job.site.location} onChange={handleOnChange}>
      <LocationSheetRenderer open={open} setOpen={setOpen} />
    </LocationPicker>
  );
};

const LocationSheetRenderer = ({ open, setOpen }: any) => {
  const { job } = useJobContext();
  const { selected, handleOpen, handleReset } = useLocationPickerContext();

  if (job.partial) return null;

  return (
    <>
      <Button
        size="xs"
        variant="outline"
        onClick={async () => {
          setOpen(true);
          await handleOpen(job.site.location);
        }}
      >
        Edit site
      </Button>
      {open && (
        <div className="fixed inset-0 z-[100] grid grid-cols-12 bg-secondary">
          <div className="col-span-3">
            <PickerSearch />
          </div>
          <div className="relative col-span-9 p-1">
            {selected && <LocationPreview />}
            <PickerMap />
            <PickerConfirm />
            <div className="absolute right-3 top-3">
              <PickerClose
                onClose={() => {
                  handleReset();
                  setOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LocationSheet;
