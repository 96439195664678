import { Fragment } from 'react';
import { ReportBlockProps } from './types';

const ReportBlock = ({
  block,
  component,
  context,
  getBlockComponent,
}: ReportBlockProps) => {
  return (
    <>
      {component.elements.map((element, index) => (
        <Fragment key={`${block.id}-${index}`}>
          {getBlockComponent(element.type)({
            ...element,
            context,
            getBlockComponent,
          })}
        </Fragment>
      ))}
    </>
  );
};

export default ReportBlock;
