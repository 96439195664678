import React from 'react';
import { DataType } from 'external/FormTemplateEditor';
import { DateTime } from 'luxon';
import { FormFieldComponentProps } from '../../types';

const FormFieldDatetime = ({
  readOnly,
  item,
  value,
  onChange,
}: FormFieldComponentProps) => {
  const handleOnChange = (nextValue: DateTime | null) => {
    if (!nextValue) return;
    onChange(item, { dataType: DataType.DATETIME, value: nextValue.toISO() });
  };

  if (readOnly) {
    return (
      <div>
        <p className="font-semibold">
          {DateTime.fromISO(value?.value).toLocaleString(
            DateTime.DATETIME_FULL,
          )}
        </p>
      </div>
    );
  }

  return null;
};

export default FormFieldDatetime;
