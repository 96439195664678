import {
  Background,
  Handle,
  NodeProps,
  Position,
  ReactFlow,
  ReactFlowProvider,
} from '@xyflow/react';
import { UserPhoto } from 'lib/shared';
import { User } from 'lib/types';
import { useStaffChart } from './hooks';

const NodeUser = ({ data }: NodeProps) => {
  const user = data.user as User;
  return (
    <div className="w-[200px] rounded-lg bg-background pb-2 pt-3 shadow-sm">
      <Handle className="opacity-0" type="target" position={Position.Top} />
      <div className="flex flex-col items-center text-center">
        <UserPhoto user={user} />
        <p className="mt-1 text-sm font-semibold">{user.name}</p>
        <p className="mb-1 text-xs text-neutral-500">{user.jobTitle}</p>
      </div>
      <Handle className="opacity-0" type="source" position={Position.Bottom} />
    </div>
  );
};

const nodeTypes = {
  user: NodeUser,
};

const proOptions = { account: 'paid-pro', hideAttribution: true };

const StaffChart = () => {
  return (
    <ReactFlowProvider>
      <StaffChartRenderer />
    </ReactFlowProvider>
  );
};

const StaffChartRenderer = () => {
  const { graph } = useStaffChart();
  return (
    <div className="h-full w-full bg-background pb-12 pt-4">
      <div className="h-full w-full rounded-lg border bg-secondary">
        <ReactFlow
          minZoom={0.1}
          maxZoom={5}
          proOptions={proOptions}
          nodes={graph.nodes}
          edges={graph.edges}
          nodeTypes={nodeTypes}
          onNodesChange={graph.onNodesChange}
          onEdgesChange={graph.onEdgesChange}
        >
          <Background />
        </ReactFlow>
      </div>
    </div>
  );
};

export default StaffChart;
