import React, { useEffect } from 'react';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { formatDistanceToNow } from 'date-fns';
import { DateTime } from 'luxon';
import { useScopeContext } from '../../../../shared/ScopeProvider/context';
import TodoUrgency from '../TodoUrgency';
import { TodoCardProps } from './types';

const Scope = ({ scope }: { scope: string }) => {
  const {
    isLoading,
    scopeData: globalScopeData,
    registerScopes,
  } = useScopeContext();

  useEffect(() => {
    registerScopes([scope]);
  }, [registerScopes, scope]);

  if (isLoading) {
    return null;
  }

  const scopeData = globalScopeData[scope];
  if (!scopeData) return null;

  return <div>{scopeData.label}</div>;
};

const TodoCard = ({
  inGroup = false,
  todo,
  selected,
  onClick,
}: TodoCardProps) => {
  if (!todo.data.card) return null;
  const { scope, title, description } = todo.data.card;
  const isSelected = todo.id === selected?.id;
  const dateTime = DateTime.fromSeconds(todo.deadline).toJSDate();
  return (
    <button
      className={cn(
        'flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent',
        isSelected && 'border-primary',
      )}
      onClick={onClick}
    >
      <div className="flex w-full flex-col gap-2">
        <div className="flex items-center">
          <div className="flex items-center gap-2">
            <div className="font-semibold">{title}</div>
          </div>
          <div
            className={cn(
              'ml-auto text-xs',
              isSelected ? 'text-foreground' : 'text-muted-foreground',
            )}
          >
            {formatDistanceToNow(dateTime, {
              addSuffix: true,
            })}
          </div>
        </div>
        {!inGroup ? (
          <div className="font-semibold">
            <Scope scope={scope} />
          </div>
        ) : (
          <div />
        )}
        <div className="text-xs font-medium">{description}</div>
        <div className="flex items-center gap-2">
          <TodoUrgency todo={todo} urgency={todo.urgency} />
        </div>
      </div>
    </button>
  );
};

export default TodoCard;
