import React, { Fragment } from 'react';
import { DotIcon, EyeIcon } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { DateTime } from 'luxon';
import { getJobTaskStatusColor } from 'lib/utils';
import { useSchedulerContext } from '../../../../../../shared/Scheduler/hooks';
import { SchedulerAction } from '../../../../../../shared/Scheduler/types';
import { useJobTaskContext } from '../../../JobTask/context';

const AssignmentInfo = () => {
  const { task } = useJobTaskContext();
  const { actions } = useSchedulerContext();
  const color = getJobTaskStatusColor(task);
  return (
    <>
      <div className="border-b p-4 pl-5">
        <div className="mb-2 flex grow items-center gap-2">
          <h3 className="text-lg font-semibold">{task.name}</h3>
          <DotIcon className="h-2 w-2" />
          <Badge
            className="capitalize"
            variant="outline"
            style={{ borderColor: color, color }}
          >
            {task.status.toLowerCase()}
          </Badge>
        </div>
        <p className="dark:text-white-600 line-clamp-4 text-xs leading-[21px]">
          {task.description}
        </p>
      </div>
      <div className="border-b">
        <div className="px-4 pt-4">
          <p>Targets</p>
        </div>
        <div className="flex flex-col gap-y-4 py-4">
          {task.aims.map((aim) => (
            <Fragment key={aim.id}>
              <div className="px-4">
                <div className="flex items-center">
                  <p className="grow  text-xs font-semibold">
                    {aim.target.typeDisplay}
                  </p>
                  <Button
                    size="icon-sm"
                    variant="outline"
                    onClick={actions.factory({
                      type: SchedulerAction.ScrollToDateTime,
                      description: 'Set control date time',
                      dateTime: DateTime.fromISO(aim.target.dateTime),
                      options: { block: 'center' },
                    })}
                  >
                    <EyeIcon className="size-4" />
                  </Button>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
      <div className="flex min-h-0 grow flex-col">
        <div className="px-4 pt-4">
          <p>Assignments</p>
        </div>
        <ScrollArea className="min-h-0 grow">
          <div className="flex flex-col gap-y-4 py-4">
            {task.timesheetActivity.duties.map((duty) => (
              <Fragment key={duty.id}>
                <div className="px-4">
                  <div className="flex items-center">
                    <p className="grow  text-xs font-semibold">
                      {duty.user?.name}
                    </p>
                    <Button
                      size="icon-sm"
                      variant="outline"
                      onClick={actions.factory({
                        type: SchedulerAction.HighlightEvent,
                        description: 'Highlighted assignment',
                        id: duty.id,
                      })}
                    >
                      <EyeIcon className="size-4" />
                    </Button>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        </ScrollArea>
      </div>
    </>
  );
};

export default AssignmentInfo;
