import { useEffect, useRef } from 'react';
import deepEqual from 'fast-deep-equal';

const useTraceUpdate = (props: any) => {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (!deepEqual(prev.current[k], v) || prev.current[k] !== v) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', Object.keys(changedProps));
    }
    prev.current = props;
  });
};

export default useTraceUpdate;
