import React, { PropsWithChildren } from 'react';
import { AlertTriangle, Hexagon } from 'lucide-react';
import { Loader } from '@/components/ui/loading';
import { cn } from '@/lib/utils';
import ActionsCard from './components/ActionsCard';
import ActionsList from './components/ActionsList';
import { ActionsContext, useActionsContext } from './context';
import { useActions } from './hooks';
import { ActionsProps, ActionsRendererProps } from './types';

const Actions = ({
  children,
  className,
  hideHeader = false,
  ...props
}: PropsWithChildren<ActionsProps> & {
  className?: string;
  hideHeader?: boolean;
}) => {
  const { context } = useActions(props);

  if (children) {
    return (
      <ActionsContext.Provider value={context}>
        {children}
      </ActionsContext.Provider>
    );
  }

  return (
    <ActionsContext.Provider value={context}>
      <ActionsRenderer className={className} hideHeader={hideHeader} />
    </ActionsContext.Provider>
  );
};

const ActionsRenderer = ({
  className,
  hideHeader = false,
}: ActionsRendererProps) => {
  const { actionSet, error } = useActionsContext();

  if (error) {
    return (
      <ActionsCard className={className} hideHeader={hideHeader}>
        <div className={cn('grow p-4', className)}>
          <div className="flex h-full w-full items-center justify-center rounded-md border border-dashed">
            <div className="p-4 text-center">
              <AlertTriangle className="dark:text-white-400 mx-auto mb-2 h-4 w-4 text-gray-400" />
              <p className="dark:text-white-400 text-xs text-gray-400">
                Failed to load actions
              </p>
              {error.message && (
                <p className="dark:text-white-400 mt-2 text-xs text-gray-400">
                  {error.message}
                </p>
              )}
            </div>
          </div>
        </div>
      </ActionsCard>
    );
  }

  if (!actionSet) {
    return (
      <ActionsCard className={className} hideHeader={hideHeader}>
        <div className={cn('grow p-4', className)}>
          <div className="flex h-full min-h-20 w-full items-center justify-center rounded-md border border-dashed">
            <div>
              <Loader className="mx-auto mb-2 h-4 w-4 text-gray-400" />
              <p className="dark:text-white-400 text-xs text-gray-400">
                Loading actions
              </p>
            </div>
          </div>
        </div>
      </ActionsCard>
    );
  }

  if (!actionSet?.actions) {
    return null;
  }

  if (actionSet.actions.length === 0) {
    return (
      <ActionsCard className={className} hideHeader={hideHeader}>
        <div className={cn('grow p-4', className)}>
          <div className="flex h-full min-h-20 w-full items-center justify-center rounded-md border border-dashed">
            <div>
              <Hexagon className="dark:text-white-400 mx-auto mb-2 h-4 w-4 text-gray-400" />
              <p className="dark:text-white-400 text-xs text-gray-400">
                No actions
              </p>
            </div>
          </div>
        </div>
      </ActionsCard>
    );
  }

  return (
    <ActionsCard className={className} hideHeader={hideHeader}>
      <ActionsList />
    </ActionsCard>
  );
};

export default Actions;
