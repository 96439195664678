import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { platformClient } from '../../app/platform';
import { useScopeContext } from '../../shared/ScopeProvider/context';

export type PlatformEvent = {
  id: string;
  name: string;
  scopes: string[];
  scope: string;
  data: any;
  timestamp: number;
  orderKey?: number;
  children?: PlatformEvent[];
};

export const useEvents = (
  scope: string | undefined,
  excludeNames?: string[],
): [
  PlatformEvent[],
  {
    isLoading: boolean;
    refetchEvents: any;
  },
] => {
  const {
    data: { events } = { events: [] },
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['events', scope],
    queryFn: platformClient.getEvents(scope, excludeNames),
    enabled: !!scope,
  });
  return [events, { isLoading, refetchEvents: refetch }];
};

export const useEventsRenderer = (
  scope: string | undefined,
  excludedNames: string[] | undefined,
) => {
  const [events = [], { isLoading }] = useEvents(scope, excludedNames);
  const { registerScopes } = useScopeContext();

  useEffect(() => {
    for (const event of events) {
      registerScopes(event.scopes);
    }
  }, [events, registerScopes]);

  // Get all events where data.parent is null,
  // and for each populate a children array with
  // all events where data.parent === event.id.
  // If the parent is not in the list of events,
  // render the child as a top-level event.
  const nestedEvents = events
    .filter(
      (event) =>
        !(
          event.data.parent &&
          events.map(({ id }) => id).includes(event.data.parent)
        ),
    )
    .map((event) => ({
      ...event,
      children: events.filter(
        (childEvent) => childEvent.data.parent === event.id,
      ),
    }));

  const eventsByDateFormat = 'dd-MM-yyyy';
  const eventsByDate = [...nestedEvents]
    .reverse()
    .sort(
      (a, b) =>
        b.timestamp - a.timestamp || (b?.orderKey ?? 0) - (a?.orderKey ?? 0),
    )
    .reduce(
      (ret, event) => {
        const date = DateTime.fromSeconds(event.timestamp).toFormat(
          eventsByDateFormat,
        );
        return {
          ...ret,
          [date]: [...(ret?.[date] ?? []), event],
        };
      },
      {} as Record<string, PlatformEvent[]>,
    );

  return { events, eventsByDate, eventsByDateFormat, isLoading };
};
