import { Plus } from 'lucide-react';
import { CommandItem } from '@/components/ui/command';
import { cn } from '@/lib/utils';
import { TableSelector } from 'lib/shared';
import { CommercialType } from 'lib/types';
import { useEstimateTableContext } from '../../../../context';
import { useCostCreate } from './hooks';

const CostCreate = () => {
  const { type } = useEstimateTableContext();
  const { resources, handlers } = useCostCreate();
  return (
    <div className="grid grid-cols-8 border-b">
      <div className="col-span-3 flex border-r">
        <div className="flex w-8 flex-shrink-0 items-center justify-center border-r">
          <Plus className="-mr-0.5 size-4" />
        </div>
        {(type === CommercialType.Prelim ||
          type === CommercialType.Labor ||
          type === CommercialType.Subcontractor) && (
          <TableSelector
            placeholder="Add new line"
            popoverClassName={cn({
              'border-labor': type === CommercialType.Labor,
              'border-subcontractor': type === CommercialType.Subcontractor,
            })}
            value={undefined}
            valueDisplay={undefined}
            searchPlaceholder="Search resources"
            onChange={handlers.onCreateLabor}
          >
            {({ onSelect }) =>
              resources.map((resource) => (
                <CommandItem
                  key={resource.id}
                  value={resource.id}
                  keywords={[resource.user.name]}
                  className="cursor-pointer"
                  onSelect={onSelect}
                >
                  <div className="flex w-full items-center justify-between">
                    <p className="text-[13px]">{resource.user.name}</p>
                  </div>
                </CommandItem>
              ))
            }
          </TableSelector>
        )}
        {type === CommercialType.Material && (
          <TableSelector
            className="text-sm"
            popoverClassName="border-material/75"
            placeholder="Add new line"
            searchPlaceholder="Search materials"
            value={undefined}
            valueDisplay={undefined}
            onChange={handlers.onCreateMaterial}
          >
            {({ onSelect }) => null}
          </TableSelector>
        )}
        {type === CommercialType.Equipment && (
          <TableSelector
            placeholder="Add new line"
            popoverClassName="border-equipment"
            searchPlaceholder="Search equipment"
            value={undefined}
            valueDisplay={undefined}
            onChange={async () => {}}
          >
            {({ onSelect }) => null}
          </TableSelector>
        )}
      </div>
      <div className="col-span-1 border-r" />
      <div className="col-span-1 border-r" />
      <div className="col-span-1 border-r" />
      <div className="col-span-1" />
      <div className="col-span-1" />
    </div>
  );
};

export default CostCreate;
