import React from 'react';
import { ChevronDown, Plus, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { FormControl, FormItem } from '@/components/ui/form';
import { custom } from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormLabel } from '@/components/ui/form/form-label/FormLabel';
import { FormMessage } from '@/components/ui/form/form-message/FormMessage';
import { Input } from '@/components/ui/input';
import { z } from 'zod';
import { Empty } from 'lib/shared';
import { TrainingQualification } from 'lib/types';
import { generateId } from 'lib/utils';

export const qualificationFields = (
  qualification: TrainingQualification | undefined,
) =>
  custom({
    name: 'fields',
    defaultValue: qualification?.fields as any,
    schema: z.any(),
    render: ({ formField }) => (
      <FormItem style={{ gridArea: 'fields' }}>
        <FormLabel>Fields</FormLabel>
        <FormControl>
          <div>
            <div className="mb-4 flex flex-col gap-y-2">
              {(formField.value ?? []).length === 0 && (
                <Empty className="h-16">No fields set</Empty>
              )}
              {(formField.value ?? []).map((field: any) => (
                <div key={field.id} className="flex items-center gap-x-2">
                  <div className="w-2/5">
                    <Input
                      className="h-8 font-mono text-sm"
                      value={field.label}
                      onChange={(event) => {
                        formField.onChange(
                          formField.value.map((item: any) => {
                            if (item.id === field.id) {
                              return {
                                ...item,
                                label: event.target.value,
                              };
                            }
                            return item;
                          }),
                        );
                      }}
                    />
                  </div>
                  <p>:</p>
                  <div className="flex w-3/5 gap-x-2">
                    <div className="flex grow">
                      <Input
                        className="h-8 rounded-r-none font-mono text-sm"
                        value={field.value}
                        onChange={(event) => {
                          formField.onChange(
                            formField.value.map((item: any) => {
                              if (item.id === field.id) {
                                return {
                                  ...item,
                                  value: event.target.value,
                                };
                              }
                              return item;
                            }),
                          );
                        }}
                      />
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            className="rounded-l-none border-l-0"
                            variant="outline"
                            size="icon-sm"
                          >
                            <ChevronDown className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-56" align="end">
                          <DropdownMenuLabel>Examples</DropdownMenuLabel>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem
                            onSelect={() => {
                              formField.onChange(
                                formField.value.map((item: any) => {
                                  if (item.id === field.id) {
                                    return {
                                      ...item,
                                      value: '$(training.user.name)',
                                    };
                                  }
                                  return item;
                                }),
                              );
                            }}
                          >
                            Staff member's name
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onSelect={() => {
                              formField.onChange(
                                formField.value.map((item: any) => {
                                  if (item.id === field.id) {
                                    return {
                                      ...item,
                                      value: '$(training.date_valid)',
                                    };
                                  }
                                  return item;
                                }),
                              );
                            }}
                          >
                            Date staff training valid
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                    <Button
                      variant="destructive"
                      size="icon-sm"
                      onClick={(event) => {
                        event.preventDefault();
                        formField.onChange(
                          formField.value.filter(
                            (item: any) => item.id !== field.id,
                          ),
                        );
                      }}
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-xs dark:text-gray-400">
                  Fields allow dynamic values to appear when qualification cards
                  are displayed in reports
                </p>
              </div>
              <Button
                className="pl-1.5"
                variant="outline"
                size="xs"
                onClick={(event) => {
                  event.preventDefault();
                  formField.onChange([
                    ...(formField.value ?? []),
                    { id: generateId(), label: '', value: '' },
                  ]);
                }}
              >
                <Plus className="mr-1 h-4 w-4" />
                Add new field
              </Button>
            </div>
          </div>
        </FormControl>
        <FormMessage />
      </FormItem>
    ),
  });
