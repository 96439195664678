import React, { useCallback } from 'react';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { DataType } from 'external/FormTemplateEditor';
import { z } from 'zod';
import { useValue } from '../../../../hooks';
import { FormFieldComponentProps } from '../../types';

const FormFieldChar = ({
  readOnly,
  item,
  value,
  onChange,
}: FormFieldComponentProps) => {
  const [char, isValid] = useValue(value, z.string().default(''));
  const text = isValid ? char : '';

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(item, { dataType: DataType.CHAR, value: event.target.value });
    },
    [item, onChange],
  );

  if (item.data.isMultiline) {
    if (readOnly) {
      return (
        <Textarea
          id={item.id}
          disabled
          className="min-h-[120px]"
          value={text}
          required={item.data.isRequired}
          placeholder="Not set"
          onChange={handleOnChange}
        />
      );
    }

    return (
      <Textarea
        id={item.id}
        className="min-h-[120px]"
        value={text}
        required={item.data.isRequired}
        placeholder="Aa"
        onChange={handleOnChange}
      />
    );
  }

  if (readOnly) {
    return (
      <Input
        id={item.id}
        disabled
        value={text}
        required={item.data.isRequired}
        placeholder="Not set"
        onChange={handleOnChange}
      />
    );
  }

  return (
    <Input
      id={item.id}
      value={text}
      required={item.data.isRequired}
      placeholder="Aa"
      onChange={handleOnChange}
    />
  );
};

export default React.memo(FormFieldChar);
