import { createContext, useContext } from 'react';
import { useJobCommercial } from './hooks';

export const JobCommercialContext = createContext(
  {} as ReturnType<typeof useJobCommercial>['context'],
);

export const useJobCommercialContext = () => {
  return useContext(JobCommercialContext);
};
