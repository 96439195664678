import React, { Fragment, PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Map, { Marker, Popup } from 'react-map-gl';
import { SizeMe } from 'react-sizeme';
import { useTheme } from '@/components/ui/theme';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import { useLocationContext } from '../../context';
import { useLocationMap } from './hooks';

const LocationMap = ({
  children,
  hideMarkerPopups = false,
  mapProps = {},
}: PropsWithChildren<any>) => {
  const { mapRef, mapMarkers } = useLocationContext();
  const { viewState, handleOnMove, handleOnClick } = useLocationMap();
  const { theme } = useTheme();
  const mapStyle =
    theme === 'dark'
      ? 'mapbox://styles/energyenables/clrcv8giv009s01pedl6ecmy5'
      : 'mapbox://styles/energyenables/clqzso8ec009y01pd8b4tatmd';
  return (
    <SizeMe
      monitorHeight
      monitorWidth
      refreshMode="debounce"
      resizeDetectorStrategy="object"
    >
      {({ size }) => (
        <div className="h-full">
          {size?.height && size?.width ? (
            <Map
              {...viewState}
              ref={mapRef}
              style={{
                width: size.width,
                height: size.height,
              }}
              initialViewState={{
                zoom: 18,
                pitch: 45,
                latitude: 51.5074,
                longitude: 0.1278,
              }}
              mapboxAccessToken="pk.eyJ1IjoiZW5lcmd5ZW5hYmxlcyIsImEiOiJjbGpia2NqZ2IxcTcxM2pxeGxvNG5ud2lpIn0.gMXRc_xES-6AlQx1w8MuXw"
              attributionControl={false}
              mapStyle={mapStyle}
              onMove={handleOnMove}
              onClick={handleOnClick}
              {...mapProps}
            >
              {mapMarkers.map((marker) => (
                <Fragment key={marker.id}>
                  <Marker {...marker.point}>
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      size="3x"
                      style={{ transform: 'translateY(-50%)' }}
                    />
                  </Marker>
                  {!hideMarkerPopups && (
                    <Popup
                      {...marker.point}
                      anchor={marker.anchor}
                      closeButton={false}
                      closeOnMove={false}
                      closeOnClick={false}
                      focusAfterOpen={false}
                      maxWidth="none"
                    >
                      {marker.popup}
                    </Popup>
                  )}
                </Fragment>
              ))}
              {children}
            </Map>
          ) : null}
        </div>
      )}
    </SizeMe>
  );
};

export default LocationMap;
