import React from 'react';
import { GripVertical } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { CommandGroup, CommandItem } from '@/components/ui/command';
import { cn } from '@/lib/utils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DebouncedNumberInput, TableSelector } from 'lib/shared';
import { CommercialType } from 'lib/types';
import CommercialUnitField from '../../../../../../../CommercialUnitField';
import { useActivityTableContext } from '../../../../context';
import ValueMenu from '../ValueMenu';
import { useTableValue } from './hooks';
import { ValueLineProps } from './types';

const ValueLine = ({ estimate = false, line }: ValueLineProps) => {
  const { type } = useActivityTableContext();
  const { contracts, handlers } = useTableValue(line);

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: line.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className={cn('relative flex bg-background', {
        'z-10': isDragging,
        'ring-border': !estimate && !isDragging,
        'ring-1': !estimate,
      })}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div
        className={cn('grid grid-cols-8', {
          'w-7/12': !estimate,
          'w-full': estimate,
        })}
      >
        <div className="col-span-3 flex border-r">
          {!estimate && (
            <div className="w-8 flex-shrink-0 border-r">
              <Button
                ref={setActivatorNodeRef}
                variant="ghost"
                className="size-8 cursor-grab rounded-none p-0"
                {...listeners}
              >
                <GripVertical className="size-4" />
              </Button>
            </div>
          )}
          {(type === CommercialType.Prelim ||
            type === CommercialType.Labor ||
            type === CommercialType.Subcontractor) && (
            <TableSelector
              placeholder="Select rate"
              searchPlaceholder="Search resources"
              className="text-sm"
              popoverClassName={cn({
                'border-labor': type === CommercialType.Labor,
                'border-subcontractor/75':
                  type === CommercialType.Subcontractor,
              })}
              value={line.rate?.id as string}
              valueDisplay={line.rate?.name as string}
              valueTag={
                line.isCallout ? (
                  <Badge className="h-4 rounded">Callout</Badge>
                ) : undefined
              }
              onChange={handlers.rateOnChange}
            >
              {({ disabled, onSelect }) =>
                contracts.map((contract) => (
                  <CommandGroup key={contract.id} heading={contract.name}>
                    {contract.rates.map((rate) => (
                      <CommandItem
                        key={rate.id}
                        value={rate.id}
                        active={rate.id === line.rate?.id}
                        keywords={[rate.name, rate.description]}
                        className="cursor-pointer"
                        disabled={disabled}
                        onSelect={onSelect}
                      >
                        <div className="flex w-full items-center justify-between">
                          <p className="text-[13px]">{rate.name}</p>
                        </div>
                      </CommandItem>
                    ))}
                  </CommandGroup>
                ))
              }
            </TableSelector>
          )}
          {type === CommercialType.Material && (
            <TableSelector
              placeholder="Select rate"
              searchPlaceholder="Search material"
              className="text-sm"
              popoverClassName="border-material/75"
              value={line.description}
              valueDisplay={line.description}
              searchDefault={line.description}
              onChange={handlers.descriptionOnChange}
            >
              {({ onSelect }) => null}
            </TableSelector>
          )}
        </div>
        <div className="col-span-1 flex items-center border-r">
          <CommercialUnitField
            readOnly={!!line.rate}
            value={line.unit}
            valueDisplay={line.unitDisplay}
            onValueChange={handlers.unitOnChange}
          />
        </div>
        <div className="col-span-1 border-r">
          <DebouncedNumberInput
            className="h-full rounded-none border-none px-2 hover:bg-secondary focus:bg-secondary"
            min={0}
            value={line.estimatedQuantity}
            onChange={handlers.estimatedQuantityOnChange}
          />
        </div>
        <div className="col-span-1 border-r">
          <DebouncedNumberInput
            className="h-full rounded-none border-none px-2 hover:bg-secondary focus:bg-secondary"
            min={0}
            value={line.estimatedUnitValue?.toFixed(2)}
            onChange={handlers.estimatedValueOnChange}
          />
        </div>
        <div className="col-span-1 border-r">
          <DebouncedNumberInput
            className="h-full rounded-none border-none px-2 hover:bg-secondary focus:bg-secondary"
            min={0}
            value={((line.estimatedUnitValueUplift ?? 1) - 1) * 100}
            onChange={handlers.estimatedValueUpliftOnChange}
          />
        </div>
        <div
          className={cn('col-span-1 flex items-center px-2 text-sm', {
            'border-r': !estimate,
          })}
        >
          {line.estimatedTotalValue?.toFixed(2)}
        </div>
      </div>
      {!estimate && (
        <div className="grid w-5/12 grid-cols-6">
          <div className="col-span-1 border-r">
            <DebouncedNumberInput
              className="h-full rounded-none border-none px-2 hover:bg-secondary focus:bg-secondary"
              min={0}
              value={line.appliedQuantity}
              onChange={() => {}}
            />
          </div>
          <div className="col-span-1 border-r">
            <DebouncedNumberInput
              className="h-full rounded-none border-none px-2 hover:bg-secondary focus:bg-secondary"
              min={0}
              value={line.appliedUnitValue}
              onChange={() => {}}
            />
          </div>
          <div className="col-span-1 border-r">
            <DebouncedNumberInput
              className="h-full rounded-none border-none px-2 hover:bg-secondary focus:bg-secondary"
              min={0}
              value={((line.appliedUnitValueUplift ?? 1) - 1) * 100}
              onChange={() => {}}
            />
          </div>
          <div className="col-span-1 flex items-center border-r px-2 text-sm">
            {line.appliedTotalValue?.toFixed(2)}
          </div>
          <div className="col-span-2">
            <div className="flex">
              <div className="flex grow items-center border-r px-2 text-sm">
                Matched
              </div>
              <ValueMenu line={line} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ValueLine;
