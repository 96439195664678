import { useMatch } from 'react-router';
import { useClientFormTemplate } from 'lib/hooks';

export const useFormTemplate = () => {
  const {
    formTemplate,
    isLoading,
    isUpdating,
    isPublishing,
    handleDescriptionOnChange,
    handleOnPublish,
  } = useClientFormTemplate(
    useMatch('/clients/:clientSlug/settings/forms/:formTemplateSlug')?.params
      .formTemplateSlug,
  );
  return {
    isLoading,
    isUpdating,
    isPublishing,
    formTemplate,
    handleDescriptionOnChange,
    handleOnPublish,
  };
};
