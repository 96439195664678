import React from 'react';
import { ViewState } from 'react-map-gl';
import { Location } from 'lib/types';

export enum LocationMode {
  View = 'VIEW',
  Edit = 'EDIT',
}

export type LocationProps = React.PropsWithChildren<{
  location: Location | undefined;
  supplementaryLocation?: Location | undefined;
  locationOnCreate?: (location: Location) => void;
  locationOnUpdate?: (location: Location) => void;
  defaultViewState?: Partial<ViewState>;
}>;

export enum GeometryType {
  Point = 'POINT',
}

export type LocationStore = {
  mode: LocationMode.View;
  workspace: {
    geometry?: {
      type: GeometryType.Point;
      point: {
        latitude: number;
        longitude: number;
      };
    };
  };
  workspaceHandle: string | undefined;
  mapRef: React.RefObject<any> | undefined;
  map: { viewState: Partial<ViewState> | undefined };
  mapEnabled: boolean;
};

export type LocationWorkspace = {
  geometry?: {
    type: GeometryType.Point;
    point: {
      latitude: number;
      longitude: number;
    };
  };
};
