import React, { useEffect, useState } from 'react';
import { ArrowLeft, Menu } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';
import { Report, ReportMode } from 'lib/shared';
import { useJobContext } from '../../../../context';
import { useJobReportContext } from '../../context';
import template from '../../template.json';
import { footer } from '../ReportVersion';
import blocks from '../ReportVersion/components/VersionRenderer/blocks';
import { useJobReportVersionContext } from '../ReportVersion/context';

const ReportContents = () => {
  const { job } = useJobContext();
  const { report } = useJobReportContext();
  const { cache } = useJobReportVersionContext();

  const [open, setOpen] = useState(true);
  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setRenderKey((prev) => prev + 1);
    }, 500);
  }, []);

  if (job.partial || !report) return null;

  return (
    <div
      className={cn('flex h-full min-h-0 flex-col pl-4 pt-2', {
        'pointer-events-auto': open,
        'pointer-events-none': !open,
      })}
    >
      <div className="flex items-center">
        <Button
          size="sm"
          className="pointer-events-auto gap-x-2 px-2"
          variant="ghost"
          onClick={() => {
            setOpen((prevOpen) => !prevOpen);
          }}
        >
          {open ? (
            <ArrowLeft className="h-4 w-4" />
          ) : (
            <Menu className="h-4 w-4" />
          )}
          <p className="text-sm">Contents</p>
        </Button>
      </div>
      <motion.div
        key="contents"
        className="min-h-0 grow"
        transition={{ type: 'tween', duration: 0.2 }}
        initial={{ opacity: 0 }}
        animate={open ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }}
      >
        <ScrollArea className="h-full min-h-0" silent>
          <div className="relative text-ellipsis py-2">
            <Report.Root
              mode={ReportMode.Contents}
              key={renderKey.toString()}
              cache={cache}
              template={template}
              blocks={blocks}
              footer={footer}
              context={{
                selected: report.selected,
                job: {
                  ...job,
                  status: job.status.map(({ label }) => label).join(', '),
                },
                jobReport: report,
                title: report.name,
                tasks: job.tasks,
              }}
            />
          </div>
        </ScrollArea>
      </motion.div>
    </div>
  );
};

export default ReportContents;
