import { gql, useQuery } from '@apollo/client';
import { TrainingQualificationsQueryData } from './types';

export const useTrainingQualifications = () => {
  const { data, refetch } = useQuery<TrainingQualificationsQueryData>(gql`
    query GetTrainingQualifications {
      trainingQualifications {
        id
        name
        description
        color
        logo {
          id
        }
      }
    }
  `);

  return {
    context: {
      qualifications: data?.trainingQualifications || [],
      refetch,
    },
  };
};
