import React from 'react';
import { input } from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { z } from 'zod';
import { SheetForm } from 'lib/shared';
import { useStaffMemberCreate } from './hooks';
import { StaffMemberCreateProps } from './types';

const StaffMemberCreate = ({ onCreated }: StaffMemberCreateProps) => {
  const { state, handlers } = useStaffMemberCreate(onCreated);
  return (
    <SheetForm
      open={state.open}
      trigger="Create user"
      title="Create user"
      description="Create a new user to give them access to the platform or mobile app."
      submit="Create user"
      fields={[
        input({
          label: 'Name',
          name: 'name',
          placeholder: 'John Doe',
          schema: z.string().min(2),
        }),
        input({
          label: 'Email',
          name: 'email',
          placeholder: 'example@gmail.com',
          schema: z.string().email().endsWith('@mchughltd.co.uk'),
        }),
        input({
          label: 'Job Title',
          name: 'jobTitle',
          placeholder: 'Engineer',
          schema: z.string().optional(),
        }),
        input({
          label: 'Driver Fob ID',
          name: 'driverKeyFobId',
          placeholder: 'Verizon Driver ID',
          schema: z.string().optional(),
        }),
      ]}
      onSubmit={handlers.onCreate}
      onOpenChange={state.onOpenChange}
    />
  );
};

export default StaffMemberCreate;
