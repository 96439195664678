import { useState } from 'react';
import { SubcontractorDuty, SubcontractorReport } from 'lib/types';

export const useFormSubcontractor = () => {
  const [active, setActive] = useState({
    duty: undefined,
    report: undefined,
  } as
    | { duty: undefined; report: undefined }
    | { duty: SubcontractorDuty; report: SubcontractorReport });

  const handleOnSelect = (
    duty: SubcontractorDuty,
    report: SubcontractorReport,
  ) => {
    setActive({ duty, report });
  };

  const handleOnClear = () => {
    setActive({ duty: undefined, report: undefined });
  };

  return {
    active,
    onSelect: handleOnSelect,
    onClear: handleOnClear,
  };
};
