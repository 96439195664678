import React from 'react';
import { LightningBoltIcon } from '@radix-ui/react-icons';
import { Badge } from '@/components/ui/badge';
import { parseColor } from '../../utils';
import { ChoiceProps, ChoiceSetProps } from './types';

const ChoiceSet = ({
  choiceSet,
  placeholder = 'Not selected',
}: ChoiceSetProps) => {
  if (choiceSet.dynamic) {
    return (
      <div className="flex min-w-0 gap-x-2 overflow-hidden">
        <Badge variant="outline" className="h-[22px] rounded pl-1.5 pr-2">
          <LightningBoltIcon className="mr-1 size-3" />
          {choiceSet.source}
        </Badge>
      </div>
    );
  }

  return (
    <div className="flex min-w-0 gap-x-2 overflow-hidden">
      {choiceSet.choices.length > 0 ? (
        choiceSet.choices.map((choice) => (
          <Choice key={choice.id} choice={choice} />
        ))
      ) : (
        <Badge variant="outline" className="rounded">
          {placeholder}
        </Badge>
      )}
    </div>
  );
};

const Choice = ({ choice }: ChoiceProps) => {
  const color = parseColor(choice.color);
  return (
    <div className="rounded bg-white">
      <Badge
        className="rounded"
        style={{
          backgroundColor: color.alpha(0.1).css(),
          borderColor: color.css(),
          color: color.css(),
        }}
      >
        {choice.value}
      </Badge>
    </div>
  );
};

ChoiceSet.Choice = Choice;
export default ChoiceSet;
