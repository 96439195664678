import { Training } from 'lib/types';
import { MemberTrainingFormMode } from './types';

export const useMemberTrainingForm = (training?: Training) => {
  return {
    context: {
      mode: training
        ? MemberTrainingFormMode.Update
        : MemberTrainingFormMode.Create,
    },
  };
};
