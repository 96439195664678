import React from 'react';
import { ChevronRightIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { RenderPageHeaderProps } from 'external/Tree';
import { usePageHeader } from './hooks';

const PageHeader = ({ page, isCollapsed }: RenderPageHeaderProps) => {
  const { pageOrder, handlePageNameOnChange, handleCollapsePageOnClick } =
    usePageHeader(page);
  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center">
          <Button
            size="icon"
            variant="ghost"
            style={{ width: 28, height: 28, margin: '3px' }}
            onClick={handleCollapsePageOnClick}
          >
            <ChevronRightIcon
              className={cn('h-4 w-4 transition-all', {
                'rotate-90 transform': !isCollapsed,
              })}
            />
          </Button>
          <input
            className="border-none bg-transparent text-lg font-bold text-gray-800 focus:ring-0 dark:text-gray-200"
            value={page.data?.name}
            onChange={(event) => {
              handlePageNameOnChange(event.target.value);
            }}
          />
        </div>
        <div className="flex flex-col justify-end pr-2">
          <p className="text-xs text-gray-500 dark:text-gray-400">
            Page {pageOrder.indexOf(page.id) + 1}
          </p>
        </div>
      </div>
      {!isCollapsed && (
        <div className="relative z-[10] flex justify-between rounded-t border bg-background">
          <div className="grow p-2">
            <p className="text-sm text-gray-600 dark:text-gray-400">Question</p>
          </div>
          <div className="w-[300px] border-l px-3 py-2">
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Response type
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageHeader;
