import * as React from 'react';
import { DatePicker } from '@/components/ui/date-picker';
import { TimePicker } from '@/components/ui/time-picker';
import { DateTime } from 'luxon';

export function DateTimePicker({
  value,
  onValueChange,
}: {
  value?: DateTime;
  onValueChange: (value: DateTime) => void;
}) {
  return (
    <div>
      <DatePicker value={value} onValueChange={onValueChange} />
      <TimePicker
        date={value?.toJSDate()}
        setDate={(value) => {
          if (value) {
            onValueChange(DateTime.fromJSDate(value));
          }
        }}
      />
    </div>
  );
}
