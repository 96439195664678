import SubcontractorActivity from './components/SubcontractorActivity';
import SubcontractorContacts from './components/SubcontractorContacts';
import SubcontractorDetails from './components/SubcontractorDetails';
import SubcontractorLocations from './components/SubcontractorLocations';
import SubcontractorPolicies from './components/SubcontractorPolicies';
import { SubcontractorContext } from './context';
import { useSubcontractor } from './hooks';

const Subcontractor = () => {
  const { context } = useSubcontractor();
  return (
    <SubcontractorContext.Provider value={context}>
      <div className="flex h-full min-h-0 flex-col">
        <SubcontractorDetails />
        <div
          className="min-h-0 flex-grow gap-4 p-4"
          style={{
            display: 'grid',
            gridAutoColumns: '1fr',
            gridAutoRows: '1fr',
            gridTemplateAreas: `
              "activity  policies"
              "locations policies" 
              "contacts  policies"
            `,
          }}
        >
          <SubcontractorActivity />
          <SubcontractorLocations />
          <SubcontractorContacts />
          <SubcontractorPolicies />
        </div>
      </div>
    </SubcontractorContext.Provider>
  );
};

export default Subcontractor;
