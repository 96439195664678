import { formatDistanceToNow } from 'date-fns/formatDistanceToNow';
import { DateTime } from 'luxon';
import { UserLabel } from 'lib/shared';
import { Event } from '../../../../../../external';
import { useJobIssueContext } from '../../context';

const IssueSource = () => {
  const { issue } = useJobIssueContext();
  if (!issue) return null;
  return (
    <div className="col-span-3 px-8 py-4">
      <div className="mb-8 mt-2">
        <p className="mb-2 text-xs font-medium dark:text-gray-400">
          Reported by
        </p>
        <div className="flex flex-col gap-y-4">
          {issue.jobTask?.timesheetActivity.users.map((user) => (
            <UserLabel key={user.id} user={user} />
          ))}
        </div>
      </div>
      <div>
        <Event scope={`jobs::job-issue::${issue.id}`} name="JOB_ISSUE_CLOSED">
          {({ event }) => (
            <>
              <p className="mb-2 text-xs font-medium dark:text-gray-400">
                Resolved{' '}
                {formatDistanceToNow(
                  DateTime.fromSeconds(event.timestamp).toJSDate(),
                  {
                    addSuffix: true,
                  },
                )}{' '}
                by
              </p>
              <div className="flex flex-col gap-y-4">
                <UserLabel user={event.data.user} />
                <p className="pl-4 text-xs font-semibold dark:text-gray-400"></p>
              </div>
            </>
          )}
        </Event>
      </div>
    </div>
  );
};

export default IssueSource;
