import React from 'react';
import { Calendar, luxonLocalizer } from 'react-big-calendar';
import { Button } from '@/components/ui/button';
import { input } from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { DateTime } from 'luxon';
import { z } from 'zod';
import { Link } from 'lib/shared';
import { useTrainingEvents } from './hooks';

const localizer = luxonLocalizer(DateTime);

const Toolbar = (props: any) => {
  console.log(props);
  return <div>Test</div>;
};

const components = {
  toolbar: Toolbar,
};

const TrainingEvents = () => {
  const { events, handlers } = useTrainingEvents();
  return (
    <div className="flex h-full min-h-0 flex-col">
      <div className="flex h-[52px] flex-shrink-0 items-center justify-between border-b px-4 py-2">
        <h1 className="text-xl font-bold">Events</h1>
      </div>
      <div className="min-h-0 grow p-4">
        <Calendar
          className="rounded"
          localizer={localizer}
          events={[]}
          components={components}
        />
      </div>
      {/*<Sheet>*/}
      {/*  <SheetTrigger asChild>*/}
      {/*    <Button>Create</Button>*/}
      {/*  </SheetTrigger>*/}
      {/*  <SheetContent>*/}
      {/*    <SheetHeader>*/}
      {/*      <SheetTitle>Create Event</SheetTitle>*/}
      {/*      <SheetDescription>*/}
      {/*        Enter the name of the event you want to create.*/}
      {/*      </SheetDescription>*/}
      {/*    </SheetHeader>*/}
      {/*    <FormRenderer*/}
      {/*      fields={[*/}
      {/*        input({*/}
      {/*          label: 'Name',*/}
      {/*          name: 'name',*/}
      {/*          schema: z.string().min(1),*/}
      {/*        }),*/}
      {/*      ]}*/}
      {/*      onSubmit={handlers.onCreate}*/}
      {/*    >*/}
      {/*      {({ onSubmit }) => (*/}
      {/*        <div>*/}
      {/*          <Button onClick={onSubmit}>Create</Button>*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*    </FormRenderer>*/}
      {/*  </SheetContent>*/}
      {/*</Sheet>*/}
    </div>
  );
};

export default TrainingEvents;
