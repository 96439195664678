import produce from 'immer';
import { DateTime } from 'luxon';
import { StateCreator, create } from 'zustand';
import { RosterDuty, RosterReview, RosterTeam, User } from 'lib/types';
import { RosterValidationResults } from '../HumanResourcesRoster/components/RosterDutyRow/types';
import {
  RosterDutyTableState,
  createRosterDutyTableSlice,
} from '../HumanResourcesRoster/components/RosterDutyTable/state';
import { RosterDutiesQueryData } from '../HumanResourcesRoster/types';
import { ApproveRosterMutationData } from './components/RosterApprove/types';
import { CreateRosterExceedanceMutationData } from './components/RosterReviewExceedances/types';
import { RosterReviewQueryData } from './types';

export type RosterReviewValues = {
  dateTimeStart: DateTime;
  dateTimeEnd: DateTime;

  user: User | null;

  stage: number;

  rosterTeam: RosterTeam | null;
  rosterReview: RosterReview | null;
  rosterDuties: RosterDuty[];
  rosterDutiesInWeek: RosterDuty[];
  rosterDutiesInMonth: RosterDuty[];
  rosterValidationResults: RosterValidationResults;
};

export type RosterReviewActions = {
  apply: (getNewState: (state: RosterReviewState) => RosterReviewState) => void;

  rosterReviewQueryOnCompleted: (
    dateTimeStart: DateTime,
    dateTimeEnd: DateTime,
    data: RosterReviewQueryData,
  ) => void;
  userOnChange: (user: User, data: RosterDutiesQueryData) => void;
  timeIntervalOnChange: (
    dateTimeStart: DateTime,
    dateTimeEnd: DateTime,
    data: RosterDutiesQueryData,
  ) => void;
  onApproveRoster: (data: ApproveRosterMutationData) => void;
  onApproveRosterExceedance: (data: CreateRosterExceedanceMutationData) => void;

  onCreateRosterExceedanceReview: (data: any) => void;
  onUpdateRosterExceedanceReview: (data: any) => void;
  onDeleteRosterExceedanceReview: (data: any) => void;

  reset: () => void;
};

export type RosterReviewState = RosterReviewValues & RosterReviewActions;

const initialValues: RosterReviewValues = {
  dateTimeStart: DateTime.local(),
  dateTimeEnd: DateTime.local(),

  user: null,

  stage: 1,

  rosterTeam: null,
  rosterReview: null,
  rosterDuties: [],
  rosterDutiesInWeek: [],
  rosterDutiesInMonth: [],
  rosterValidationResults: {
    fatigueIndices: {},
    riskIndices: {},
    exceedances: [],
  },
};

export const createRosterReviewSlice: StateCreator<RosterReviewState> = (
  set,
) => ({
  ...initialValues,

  reset: () => set(() => initialValues),

  apply: (getNewState) => set(getNewState),

  rosterReviewQueryOnCompleted: (
    dateTimeStart,
    dateTimeEnd,
    { rosterTeam, rosterDutiesInWeek, rosterDutiesInMonth },
  ) =>
    set(
      produce<RosterReviewState>((state) => {
        state.dateTimeStart = dateTimeStart;
        state.dateTimeEnd = dateTimeEnd;
        state.rosterTeam = rosterTeam;
        state.rosterDutiesInWeek = rosterDutiesInWeek;
        state.rosterDutiesInMonth = rosterDutiesInMonth;
      }),
    ),

  userOnChange: (
    user,
    {
      rosterDuties,
      rosterDutiesInMonth,
      rosterDutiesInWeek,
      rosterValidationResults,
    },
  ) =>
    set(
      produce<RosterReviewState>((state) => {
        state.user = user;
        state.rosterDuties = rosterDuties;
        state.rosterValidationResults = rosterValidationResults;
      }),
    ),

  timeIntervalOnChange: (
    dateTimeStart,
    dateTimeEnd,
    { rosterDuties, rosterValidationResults },
  ) =>
    set(
      produce<RosterReviewState>((state) => {
        state.dateTimeStart = dateTimeStart;
        state.dateTimeEnd = dateTimeEnd;
        state.rosterDuties = rosterDuties;
        state.rosterValidationResults = rosterValidationResults;
      }),
    ),

  onApproveRoster: ({ approveRoster: { rosterDuties } }) =>
    set(
      produce<RosterReviewState>((state) => {
        state.rosterDuties = state.rosterDuties
          .filter(
            ({ id, team }) =>
              team.id !== state.rosterTeam?.id ||
              rosterDuties
                .map((innerRosterDuty) => innerRosterDuty.id)
                .includes(id),
          )
          .map((rosterDuty) =>
            rosterDuties.find(({ id }) => id === rosterDuty.id)
              ? {
                  ...rosterDuty,
                  ...rosterDuties.find(({ id }) => id === rosterDuty.id),
                }
              : rosterDuty,
          );

        state.rosterDutiesInWeek = state.rosterDutiesInWeek
          .filter(
            ({ id, team }) =>
              team.id !== state.rosterTeam?.id ||
              rosterDuties
                .map((innerRosterDuty) => innerRosterDuty.id)
                .includes(id),
          )
          .map((rosterDuty) =>
            rosterDuties.find(({ id }) => id === rosterDuty.id)
              ? {
                  ...rosterDuty,
                  ...rosterDuties.find(({ id }) => id === rosterDuty.id),
                }
              : rosterDuty,
          );
      }),
    ),

  onApproveRosterExceedance: ({
    createRosterExceedanceReview: { rosterExceedanceReview },
  }) =>
    set(
      produce<RosterReviewState>((state) => {
        state.rosterValidationResults.exceedances =
          state.rosterValidationResults.exceedances.map((exceedance) =>
            exceedance.duty.id === rosterExceedanceReview.duty.id
              ? {
                  ...exceedance,
                  review: rosterExceedanceReview,
                }
              : exceedance,
          );
      }),
    ),

  onCreateRosterExceedanceReview: ({
    createRosterExceedanceReview: { rosterExceedanceReview },
  }) =>
    set(
      produce<RosterReviewState>((state) => {
        state.rosterValidationResults.exceedances =
          state.rosterValidationResults.exceedances.map((exceedance) =>
            exceedance.duty.id === rosterExceedanceReview.duty.id
              ? {
                  ...exceedance,
                  review: rosterExceedanceReview,
                }
              : exceedance,
          );
      }),
    ),

  onUpdateRosterExceedanceReview: ({
    updateRosterExceedanceReview: { rosterExceedanceReview },
  }) =>
    set(
      produce<RosterReviewState>((state) => {
        state.rosterValidationResults.exceedances =
          state.rosterValidationResults.exceedances.map((exceedance) =>
            exceedance.duty.id === rosterExceedanceReview.duty.id
              ? {
                  ...exceedance,
                  review: rosterExceedanceReview,
                }
              : exceedance,
          );
      }),
    ),

  onDeleteRosterExceedanceReview: ({ deleteRosterExceedanceReview: { id } }) =>
    set(
      produce<RosterReviewState>((state) => {
        state.rosterValidationResults.exceedances =
          state.rosterValidationResults.exceedances.map((exceedance) =>
            exceedance.duty.id === id
              ? {
                  ...exceedance,
                  review: null,
                }
              : exceedance,
          ) as any;
      }),
    ),
});

export const useStore = create<RosterReviewState & RosterDutyTableState>()(
  (...args) => ({
    ...createRosterReviewSlice(...args),
    ...createRosterDutyTableSlice(...args),
    ...{ stage: 1 },
  }),
);
