import React from 'react';
import { DebouncedNumberInput } from 'lib/shared';
import { useCommercialEstimateContext } from '../../../../context';
import { usePrelimsContractFactor } from './hooks';

const PrelimsContractFactor = () => {
  const { estimate } = useCommercialEstimateContext();
  const { handlers } = usePrelimsContractFactor();
  return (
    <DebouncedNumberInput
      className="h-full rounded-none border-none px-2 hover:bg-secondary focus:bg-secondary"
      min={0}
      value={
        estimate?.contractPrelimFactor
          ? ((estimate.contractPrelimFactor - 1) * 100).toFixed(2)
          : 0
      }
      onChange={handlers.onChange}
    />
  );
};

export default PrelimsContractFactor;
