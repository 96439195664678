import { useEffect, useMemo, useRef, useState } from 'react';
import { HocuspocusProvider } from '@hocuspocus/provider';
import syncedStore, { getYjsDoc } from '@syncedstore/core';
import {
  DocTypeDescription,
  MappedTypeDescription,
} from '@syncedstore/core/types/doc';
import { useWebsocketProvider } from 'external/CollaborationProvider/hooks';
import * as Y from 'yjs';
import { createTokenString } from 'lib/utils';
import { msalApplication } from '../app/hooks';

type ProvidedConfig = {
  undoKeys?: Record<string, (doc: Y.Doc) => Y.UndoManager>;
  debugName?: string;
};

type Config = {
  undoKeys?: Record<string, (doc: Y.Doc) => Y.UndoManager>;
  debugName: string;
};

const useYjs = <T extends DocTypeDescription>(
  namespace: string,
  id: string | undefined,
  shape: T,
  providedConfig: ProvidedConfig,
) => {
  const websocketProvider = useWebsocketProvider();

  const config = useMemo(() => {
    const ret = providedConfig;
    ret.debugName = ((ret.debugName ?? namespace) as string)
      .slice(0, 29)
      .padEnd(29);
    return ret as Config;
  }, [providedConfig, namespace]);

  const [storeShape] = useState(shape);
  const [isReady, setIsReady] = useState(false);

  const provider = useRef<HocuspocusProvider>();
  const doc = useRef<Y.Doc>();

  const [store, setStore] = useState<MappedTypeDescription<T>>();
  const [undoManagers, setUndoManagers] = useState<
    Record<string, Y.UndoManager>
  >({});

  const [token, setToken] = useState<string>();

  useEffect(() => {
    (async () => {
      const request = { scopes: [import.meta.env.PUBLIC_AZURE_SCOPE] };
      const { accessToken } = await msalApplication.acquireTokenSilent(request);
      setToken(accessToken);
    })();
  }, []);

  useEffect(() => {
    if (!id || !namespace || !token) return;

    const newStore = syncedStore(storeShape);
    const newDoc = getYjsDoc(newStore);
    console.debug(`[${config.debugName}]: Doc created`);

    setUndoManagers(
      Object.entries(config.undoKeys ?? []).reduce(
        (ret, [key, constructor]) => ({
          ...ret,
          [key]: constructor(newDoc),
        }),
        {},
      ),
    );

    doc.current = newDoc;
    setStore(newStore);

    provider.current = new HocuspocusProvider({
      websocketProvider,
      name: `mchugh::${namespace}::${id}`,
      document: doc.current,
      token: `Bearer ${createTokenString('azure', token)}`,
      onSynced: () => {
        setIsReady(true);
        console.debug(`[${config.debugName}]: Synced`);
      },
    });

    return () => {
      setIsReady(false);
      if (doc.current) {
        doc.current.destroy();
        doc.current = undefined;
        console.debug(`[${config.debugName}]: Doc destroyed`);
      }
      if (store) {
        setStore(undefined);
        console.debug(`[${config.debugName}]: Store cleared`);
      }
      if (provider.current) {
        provider.current.disconnect();
        provider.current = undefined;
        console.debug(`[${config.debugName}]: Disconnected`);
      }
    };
  }, [id, namespace, storeShape, config.undoKeys, token]);

  return { isReady, doc, store, undoManagers };
};

export default useYjs;
