import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { z } from 'zod';
import { RatesCreateProps } from './types';

const RatesCreate = ({ className, onCreate }: RatesCreateProps) => {
  const [open, setOpen] = useState(false);
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button className={className} variant="outline" size="sm">
          Create rate
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader className="mb-4">
          <SheetTitle>Create rate</SheetTitle>
          <SheetDescription>Add a new rate to the contract</SheetDescription>
        </SheetHeader>
        <FormRenderer
          fields={[
            {
              name: 'name',
              label: 'Name',
              type: 'input',
              schema: z.string().min(2),
            },
            {
              name: 'description',
              label: 'Description',
              type: 'textarea',
              schema: z.string().min(4),
            },
            {
              name: 'unitValue',
              label: 'Unit Value',
              type: 'number',
              schema: z.number().gt(0),
            },
            {
              name: 'unit',
              label: 'Unit',
              type: 'select',
              schema: z.string(),
              options: [
                {
                  label: 'Hour',
                  value: 'HOUR',
                },
                {
                  label: 'Day',
                  value: 'DAY',
                },
                {
                  label: 'Week',
                  value: 'WEEK',
                },
                {
                  label: 'Month',
                  value: 'MONTH',
                },
                {
                  label: 'Year',
                  value: 'YEAR',
                },
                {
                  label: 'Unit',
                  value: 'UNIT',
                },
              ],
            },
          ]}
          onSubmit={async (values) => {
            await onCreate(values);
            setOpen(false);
          }}
        >
          {({ onSubmit }) => (
            <div className="pt-4">
              <Button
                className="w-full"
                size="sm"
                variant="outline"
                onClick={onSubmit}
              >
                Create
              </Button>
            </div>
          )}
        </FormRenderer>
      </SheetContent>
    </Sheet>
  );
};

export default RatesCreate;
