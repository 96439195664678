import React from 'react';
import ButtonTabs from '@/components/ui/button-tabs';
import StaffChart from './components/StaffChart';
import StaffTable from './components/StaffTable';
import { CompanyStaffContext } from './context';
import { useCompanyStaff } from './hooks';
import { Mode } from './types';

const CompanyStaff = () => {
  const { mode, context } = useCompanyStaff();
  return (
    <CompanyStaffContext.Provider value={context}>
      <div className="flex min-h-0 grow flex-col">
        <div className="flex h-[52px] flex-shrink-0 items-center justify-between border-b px-4 py-2">
          <h1 className="text-xl font-bold">Staff</h1>
          <ButtonTabs
            className="h-8 bg-background"
            buttonClassName="h-8"
            tabs={[
              {
                label: 'Chart',
                value: Mode.Chart,
              },
              {
                label: 'Table',
                value: Mode.Table,
              },
            ]}
            value={mode.value}
            onValueChange={mode.set}
          />
        </div>
        <div className="min-h-0 grow px-4">
          {mode.value === Mode.Chart && <StaffChart />}
          {mode.value === Mode.Table && <StaffTable />}
        </div>
      </div>
    </CompanyStaffContext.Provider>
  );
};

export default CompanyStaff;
