import React from 'react';
import { ArrowDown, ArrowUp, Search } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useJobReportContext } from '../../context';
import ReportActions from '../ReportActions';
import { useVersionRendererContext } from '../ReportVersion/components/VersionRenderer/context';
import { useJobReportVersionContext } from '../ReportVersion/context';
import { JobReportVersionMode } from '../ReportVersion/types';

const ReportToolbar = () => {
  const { report } = useJobReportContext();
  const { version, mode } = useJobReportVersionContext();
  const { Toolbar } = useVersionRendererContext();
  return (
    <div className="relative flex h-[52px] flex-shrink-0 items-center justify-center border-b">
      <div className="absolute left-4 flex gap-x-2">
        <Select>
          <SelectTrigger className="h-8 w-[180px] pl-4">
            <p>
              <span>{report?.name}</span>
              <span className="ml-2 text-sm font-medium dark:text-gray-400">
                v{version?.number}
              </span>
            </p>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Previous versions</SelectLabel>
              {[...(report?.versions ?? [])].reverse().map((version) => (
                <SelectItem key={version.id} value={version.id}>
                  Version {version.number}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
        <Badge className="rounded-md" variant="secondary">
          {report?.statusDisplay}
        </Badge>
      </div>
      <div
        className="relative flex overflow-hidden rounded-md bg-secondary"
        style={{ width: 'calc(var(--scale-factor)* 595.280029px)' }}
      >
        <Select
          value={mode.value}
          onValueChange={(value) => mode.set(value as JobReportVersionMode)}
        >
          <SelectTrigger className="absolute inset-y-0 left-0 z-10 h-8 w-auto gap-x-2 rounded-none border-0 pl-3 outline-none focus:ring-0">
            <SelectValue className="mr-2" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value={JobReportVersionMode.Live}>Live</SelectItem>
              <SelectItem value={JobReportVersionMode.PDF}>PDF</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              size="sm"
              variant="secondary"
              className="w-full gap-x-2 rounded-none dark:text-gray-400"
            >
              <Search className="h-4 w-4" />
              Search, bookmarks, and more...
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="popover-content-width-same-as-its-trigger p-0"
            side="top"
            align="start"
          >
            <Command>
              <CommandInput placeholder="Type a command or search..." />
              <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup heading="Suggestions">
                  <CommandItem>Calendar</CommandItem>
                  <CommandItem>Search Emoji</CommandItem>
                  <CommandItem>Calculator</CommandItem>
                </CommandGroup>
                <CommandSeparator />
                <CommandGroup heading="Settings">
                  <CommandItem>Profile</CommandItem>
                  <CommandItem>Billing</CommandItem>
                  <CommandItem>Settings</CommandItem>
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        {mode.value === JobReportVersionMode.PDF && toolbar && (
          <div className="absolute inset-y-0 right-0 z-10 flex">
            <Toolbar.GoToPreviousPage>
              {({ isDisabled, onClick }) => (
                <Button
                  className="rounded-none"
                  size="icon-sm"
                  variant="ghost"
                  disabled={isDisabled}
                  onClick={onClick}
                >
                  <ArrowUp className="size-4" />
                </Button>
              )}
            </Toolbar.GoToPreviousPage>
            <Toolbar.GoToNextPage>
              {({ isDisabled, onClick }) => (
                <Button
                  className="rounded-none"
                  size="icon-sm"
                  variant="ghost"
                  disabled={isDisabled}
                  onClick={onClick}
                >
                  <ArrowDown className="size-4" />
                </Button>
              )}
            </Toolbar.GoToNextPage>
          </div>
        )}
      </div>
      <div className="absolute right-0">
        <ReportActions />
      </div>
    </div>
  );
};

export default ReportToolbar;
