import React from 'react';
import { TableRenderer } from '@/components/ui/table/table/Table';
import { useTrainingQualificationContext } from '../../context';
import ChildrenCreator from './components/ChildrenCreator';
import ChildrenEditor from './components/ChildrenEditor';

const QualificationChildren = () => {
  const { qualification } = useTrainingQualificationContext();

  if (!qualification) return null;

  return (
    <div>
      <div>
        <h3 className="mb-1">Nested qualifications</h3>
        <p className="text-xs dark:text-white/75">
          With nested qualifications you can group qualifications together
        </p>
      </div>
      <TableRenderer
        filter="name"
        columns={[
          {
            id: 'name',
            header: 'Name',
            accessorKey: 'name',
            cell: ({ row }) => {
              return <ChildrenEditor child={row.original} />;
            },
          },
          {
            id: 'fields',
            header: 'Fields',
            cell: ({ row }) => {
              if (!row.original.fields || row.original.fields.length === 0) {
                return <p className="italic dark:text-gray-400">None set</p>;
              }
              return (
                <div className="flex items-center gap-x-2">
                  {row.original.fields.map((field) => (
                    <div
                      key={field.label}
                      className="flex items-center gap-x-2"
                    >
                      <p className="w-32 whitespace-nowrap font-mono text-sm font-semibold">
                        {field.label}
                      </p>
                    </div>
                  ))}
                </div>
              );
            },
          },
        ]}
        data={qualification.children}
        topRight={({ columnsSelector }) => (
          <div className="ml-auto flex gap-x-2">
            <ChildrenCreator />
            {columnsSelector}
          </div>
        )}
      />
    </div>
  );
};

export default QualificationChildren;
