import React from 'react';
import UserGroupRenderer from '@/components/ui/user-group';

const UserGroups = ({ ids }: { ids: string[] }) => {
  return (
    <div>
      {ids.map((id) => (
        <UserGroupRenderer key={id} id={id} />
      ))}
    </div>
  );
};

export default UserGroups;
