import React from 'react';
import { SizeMe } from 'react-sizeme';
import { Sheet, SheetTrigger } from '@/components/ui/sheet';
import { cn } from '@/lib/utils';
import ImageAnnotation from './components/ImageAnnotation';
import ImageRenderer from './components/ImageRenderer';
import ImageSheet from './components/ImageSheet';
import { useImage } from './hooks';
import { ImageProps } from './types';

const Image = ({
  className,
  sources,
  name,
  data: dataString,
  annotations: providedAnnotations,
  highlighted,
  opacity,
  sheet: enableSheet = false,
  nested = false,
  right,
  ...props
}: ImageProps) => {
  const { annotations, sheet, handlers } = useImage(
    providedAnnotations,
    highlighted,
    dataString,
  );

  const image = (
    <SizeMe monitorWidth monitorHeight>
      {({ size }) => (
        <div>
          <ImageRenderer
            {...props}
            className={cn(className)}
            src={sources?.full.uri}
            opacity={opacity}
          />
          {size &&
            annotations?.map((annotation, index) => (
              <ImageAnnotation
                key={annotation.id}
                className="transition-all"
                style={{
                  zIndex: 30 + index,
                  opacity: handlers.getOpacity(annotation.id),
                }}
                width={size.width!}
                height={size.height!}
                annotation={annotation}
              />
            ))}
        </div>
      )}
    </SizeMe>
  );

  if (enableSheet) {
    return (
      <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
        <SheetTrigger asChild>
          <button className="rounded hover:ring-2">{image}</button>
        </SheetTrigger>
        <ImageSheet
          name={name}
          sources={sources}
          nested={nested}
          right={right}
          annotations={annotations}
        />
      </Sheet>
    );
  }

  return image;
};

export default Image;
