import React from 'react';
import { Input } from '@/components/ui/input';
import { DataType } from 'external/FormTemplateEditor';
import { FormFieldComponentProps } from '../../types';

const FormFieldNumber = ({
  readOnly,
  item,
  value,
  onChange,
}: FormFieldComponentProps) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(item, { dataType: DataType.NUMBER, value: event.target.value });
  };

  return (
    <Input
      id={item.id}
      value={value?.value || ''}
      type="number"
      disabled={readOnly}
      required={item.data.isRequired}
      placeholder="Aa"
      onChange={readOnly ? undefined : handleOnChange}
    />
  );
};

export default FormFieldNumber;
