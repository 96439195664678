import React, { createContext, useRef } from 'react';
import { ViewState } from 'react-map-gl';
import createSectionState, {
  SectionState,
} from '../../utils/createSectionState';
import { LocationMode, LocationStore, LocationWorkspace } from './types';

export const StoreContext = createContext<
  SectionState<LocationStore> | undefined
>(undefined);

export const StoreProvider = ({
  children,
  defaultViewState,
}: React.PropsWithChildren<{ defaultViewState?: Partial<ViewState> }>) => {
  const storeRef = useRef<SectionState<LocationStore>>();

  if (!storeRef.current) {
    storeRef.current = createSectionState({
      mode: LocationMode.View as any,

      // The workspace contains any values for
      // from interactions with a map or another component.
      workspace: {} as LocationWorkspace,
      workspaceHandle: undefined as string | undefined,

      // The map is an optional component that if placed
      // within the location context is able to display
      // information and register map interactions.
      mapRef: undefined as React.RefObject<any> | undefined,
      map: { viewState: defaultViewState },
      mapEnabled: false as boolean,
    });
  }

  return (
    <StoreContext.Provider value={storeRef.current}>
      {children}
    </StoreContext.Provider>
  );
};
