import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useWindowManager } from '../../hooks';
import { WindowManagerRouterProps } from './types';
import { getRouteMatch } from './utils';

const WindowManagerRouter = ({
  children,
  routes: providedRoutes,
}: WindowManagerRouterProps) => {
  const [routes] = useState(providedRoutes);
  const { path, registerPatterns } = useWindowManager();

  useEffect(() => {
    registerPatterns(routes.map((route) => route.path));
  }, [registerPatterns, routes]);

  const match = useMemo(
    () =>
      getRouteMatch(
        path,
        routes.map((route) => route.path),
      ),
    [path, routes],
  );

  if (!match) return null;

  const route = (routes.find((route) => route.path === match.pattern.path)
    ?.component ?? null) as ReactElement | null;

  return <>{children({ match, route })}</>;
};

export default WindowManagerRouter;
