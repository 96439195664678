import { useCallback, useState } from 'react';

const useCallbackRef = <T>() => {
  const [current, setCurrent] = useState<T>();

  const callbackRef = useCallback((node: T) => {
    if (node !== null) setCurrent(node);
  }, []);

  return { current, callbackRef };
};

export default useCallbackRef;
