import { CommercialResourceRate } from './commercial';
import { Training, TrainingCertificate } from './training';

export type User = {
  id: string;
  name: string;
  email: string;
  initials: string;
  username: string;
  platformId?: string;
  externalId?: string;
  driverKeyFobId?: number;
  isConfigured: boolean;
  rates: CommercialResourceRate[];
  training: Training[];
  trainingCertificates: TrainingCertificate[];
  lineManager?: User;
  jobTitle?: string;
  refetch: () => Promise<any>;
};

export type Driver = User & {
  latitude: number;
  longitude: number;
};

export type UserGroup = {
  id: string;
  platformId: string;
  reference: string;
  name: string;
  scopes: string[];
  groups: UserGroup[];
  users: User[];
};
