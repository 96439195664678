import { Report } from 'lib/shared';
import { CommercialType } from 'lib/types';
import BodyHeader from './components/BodyHeader';
import BodyLines from './components/BodyLines';
import BodySummary from './components/BodySummary';

const RendererBody = () => {
  return (
    <Report.View
      style={{ paddingHorizontal: 32, paddingBottom: 12 }}
      wrap={false}
    >
      <BodyHeader />
      <Report.View>
        <BodyLines type={CommercialType.Prelim} />
        <BodyLines type={CommercialType.Labor} />
        <BodyLines type={CommercialType.Material} />
        <BodyLines type={CommercialType.Equipment} />
        <BodyLines type={CommercialType.Subcontractor} />
      </Report.View>
      <BodySummary />
    </Report.View>
  );
};

export default RendererBody;
