import React from 'react';
import { FormContextType } from './types';

const FormContext = React.createContext<FormContextType>({
  template: { items: {}, choiceSets: {}, fieldLabels: {} },
  values: {},
  objects: {},
  meta: {
    isCompleted: false,
    isPristine: true,
    isLocked: false,
    contributors: [],
  },
  readOnly: false,
  reviewMode: false,
  renderField: () => null,
  fieldValueOnChange: () => {},
  fieldMetaOnChange: () => {},
  repeatingBranchOnCreate: () => {},
  repeatingBranchOnDelete: () => {},
  objectsOnCreate: () => {},
  objectOnUploadProgress: () => {},
  objectOnUpload: () => {},
  objectOnDelete: () => {},
});

export const FormContextProvider = FormContext.Provider;

export const useFormContext = () => React.useContext(FormContext);
