import React from 'react';
import { Badge, badgeVariants } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Drawer, DrawerContent, DrawerTrigger } from '@/components/ui/drawer';
import { cn } from '@/lib/utils';
import chroma from 'chroma-js';
import { formatDistanceToNow } from 'date-fns';
import { DateTime } from 'luxon';
import { Job } from 'lib/types';
import { useJobContext } from '../../context';
import JobGantt from '../JobGantt';

export const getTimelineTargetColor = (type: string) => {
  switch (type) {
    case 'INCOMING':
      return chroma.hsl(194, 1, 0.5);
    case 'FIRST_RESPONSE':
      return chroma('#ffa600');
    case 'PERMANENT_SOLUTION':
      return chroma('#106c12');
    default:
      return chroma('');
  }
};

export const JobTimelineRenderer = ({ job }: { job: Job }) => {
  const { timeline } = job;
  const dateTimeStart = DateTime.fromISO(timeline.dateTimeStart);
  const incomingColor = getTimelineTargetColor('INCOMING');
  return (
    <div className="relative flex gap-x-4 px-4">
      <div className="absolute inset-x-0 top-[14px] h-[1px] bg-secondary" />
      <div className="basis-1/3">
        <div className="relative z-10 pb-3">
          <div className="inline bg-background">
            <Badge
              className="rounded px-2.5 shadow-none"
              style={{
                backgroundColor: incomingColor.alpha(0.1).css(),
                borderColor: incomingColor.alpha(0.25).css(),
                color: incomingColor.css(),
              }}
            >
              Received
            </Badge>
          </div>
        </div>
        <Card>
          <CardContent className="whitespace-nowrap px-2.5 py-2">
            <p className="mb-1 text-sm font-semibold">Incoming</p>
            <p className="text-xs text-gray-600 dark:text-gray-400">
              {dateTimeStart.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
            </p>
          </CardContent>
        </Card>
      </div>
      {timeline.targets.map((target) => {
        const dateTime = DateTime.fromISO(target.dateTime);
        const color = getTimelineTargetColor(target.type);
        return (
          <div key={target.id} className="basis-1/3">
            <div className="relative z-10 pb-3">
              <div className="inline bg-background">
                <Badge
                  className="rounded px-2.5 shadow-none"
                  style={{
                    backgroundColor: color.alpha(0.1).css(),
                    borderColor: color.alpha(0.25).css(),
                    color: color.css(),
                  }}
                >
                  {DateTime.now() > dateTime
                    ? `${formatDistanceToNow(dateTime.toJSDate())} ago`
                    : `In ${formatDistanceToNow(dateTime.toJSDate())}`}
                </Badge>
              </div>
            </div>
            <Card>
              <CardContent className="whitespace-nowrap px-2.5 py-2">
                <p className="mb-1 text-sm font-semibold">
                  {target.typeDisplay}
                </p>
                <p className="text-xs text-gray-600 dark:text-gray-400">
                  {dateTime.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
                </p>
              </CardContent>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

export const JobTimelineBadge = () => {
  const { job } = useJobContext();
  if (job.partial) return null;
  return (
    <Drawer>
      <DrawerTrigger asChild>
        <Button
          className={cn(badgeVariants({ variant: 'outline' }), 'h-6')}
          variant="outline"
          size="sm"
        >
          Timeline
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <div className="my-4">
          <JobTimelineRenderer job={job} />
        </div>
        <div className="h-[400px] border-t">
          <JobGantt />
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export const JobTimeline = ({ className }: { className?: string }) => {
  const { job } = useJobContext();
  if (job.partial) return null;
  return (
    <div
      className={cn(
        'rounded-lg border border-l-4 bg-background',
        // 'bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-secondary h-full',
        className,
      )}
      style={{ borderLeftColor: 'rgb(0, 195, 255)' }}
    >
      <div className="flex items-center justify-between px-4 pt-4">
        <div className="mb-6">
          <h3 className="mb-1 font-medium">Timeline</h3>
          <p className="text-xs dark:text-white/75">
            The timeline below shows the progress of the job.
          </p>
        </div>
      </div>
      <JobTimelineRenderer job={job} />
    </div>
  );
};

export default JobTimeline;
