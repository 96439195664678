import { BlockData, Item } from 'external/types';
import { formFieldHasValue } from './utils';

export const useFormCompletion = (
  pages: any[],
  values: Record<string, any>,
) => {
  if (!pages) return { isCompleted: false, completionPercentage: 0 };

  const prepareValue = (inner: any) => {
    try {
      return JSON.parse(JSON.stringify(inner));
    } catch (error) {
      return inner;
    }
  };

  const hasValue = (item: Item) =>
    formFieldHasValue(item, prepareValue(values?.[item.path]));

  const requiredItems = pages
    .flatMap((innerPage) => innerPage.items)
    .filter((item) => item.type !== 'CONTAINER_REPEATING_BRANCH')
    .filter((item) => item.type !== 'CONTAINER_REPEATING_BRANCH_STUB')
    .filter((item) => (item.data as BlockData).dataType !== 'COMMENT')
    .filter((item) => (item.data as BlockData).dataType !== 'WARNING')
    .filter((item) => (item.data as BlockData).isRequired);

  const requiredItemsWithValues = requiredItems.filter(hasValue);
  const requiredItemsWithoutValues = requiredItems.filter(
    (item) => !hasValue(item),
  );

  const isCompleted = requiredItemsWithoutValues.length === 0;
  const completionPercentage = Math.round(
    (requiredItemsWithValues.length / requiredItems.length) * 100,
  );

  return { isCompleted, completionPercentage };
};
