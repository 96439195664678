import React from 'react';
import {
  AlertTriangleIcon,
  ClipboardCheck,
  LayoutListIcon,
  NewspaperIcon,
} from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Link, PageLoader } from 'lib/shared';
import { useTodoContext } from '../../../../../../external/TodosProvider/hooks';
import { useNavigationLeftClients } from './hooks';

const NavigationLeftClients = () => {
  const { clients, loading, error } = useNavigationLeftClients();
  const { todos } = useTodoContext();
  return (
    <NavigationMenuItem>
      <NavigationMenuTrigger>Clients</NavigationMenuTrigger>
      <NavigationMenuContent>
        <Command>
          <div className="flex items-center gap-x-2 p-2">
            <div className="w-[240px] grow rounded-md border">
              <CommandInput
                disabled={loading || !!error}
                placeholder="Search clients..."
                wrapperClassname="border-0 h-8"
              />
            </div>
            <Select defaultValue="recent" disabled>
              <SelectTrigger className="h-[34px] w-[180px]">
                <div className="flex h-[34px] w-[180px] items-center gap-x-1 text-xs">
                  <p className="text-gray-400 dark:text-white/50">Sort by:</p>
                  <SelectValue placeholder="Sort by" />
                </div>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="recent">Recent</SelectItem>
                <SelectItem value="name">Name</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <CommandList className="px-2 pb-2">
            {!loading && <CommandEmpty>No clients found.</CommandEmpty>}
            {(() => {
              if (loading)
                return (
                  <div className="h-24">
                    <PageLoader className="bg-transparent">
                      Loading clients...
                    </PageLoader>
                  </div>
                );
              if (error)
                return (
                  <CommandItem className="p-0">
                    <div className="flex items-center gap-x-2 p-2">
                      <AlertTriangleIcon className="h-6 w-6 text-yellow-500" />
                      <p className="text-sm text-yellow-500">{error.message}</p>
                    </div>
                  </CommandItem>
                );
              return clients.map((client) => (
                <CommandItem
                  asChild
                  key={client.id}
                  value={client.name}
                  keywords={[client.name]}
                  className="mr-[1px] cursor-pointer p-0 last:mb-0.5"
                >
                  <NavigationMenuLink asChild>
                    <Link to={`/clients/${client.slug}`}>
                      <div className="flex gap-x-4 p-2">
                        <Badge
                          className="h-11 w-11 justify-center rounded-md text-white"
                          style={{ backgroundColor: client.accentColor }}
                        >
                          <p className="text-lg">{client.name.at(0)}</p>
                        </Badge>
                        <div>
                          <p className="mb-1 mt-[1px] font-medium">
                            {client.name}
                          </p>
                          <div className="flex divide-x divide-white/25 [&_p]:-mb-0.5">
                            <div className="flex items-center gap-x-1 pr-2 text-xs text-gray-400">
                              <LayoutListIcon className="h-4 w-4" />
                              <p className="whitespace-nowrap">
                                {client.openJobs.length} open job
                                {client.openJobs.length === 1 ? '' : 's'}
                              </p>
                            </div>
                            <div className="flex items-center gap-x-1 px-2 text-xs text-gray-400">
                              <AlertTriangleIcon className="h-4 w-4" />
                              <p className="whitespace-nowrap">
                                {client.openIssues.length} open issue
                                {client.openIssues.length === 1 ? '' : 's'}
                              </p>
                            </div>
                            <div className="flex items-center gap-x-1 px-2 text-xs text-gray-400">
                              <NewspaperIcon className="h-4 w-4" />
                              <p className="whitespace-nowrap">
                                {client.openReports.length} pending report
                                {client.openReports.length === 1 ? '' : 's'}
                              </p>
                            </div>
                            <div className="flex items-center gap-x-1 pl-2 text-xs text-gray-400">
                              <ClipboardCheck className="h-4 w-4" />
                              <p className="whitespace-nowrap">
                                {todos.length} todo
                                {todos.length === 1 ? '' : 's'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </NavigationMenuLink>
                </CommandItem>
              ));
            })()}
          </CommandList>
        </Command>
      </NavigationMenuContent>
    </NavigationMenuItem>
  );
};

export default NavigationLeftClients;
