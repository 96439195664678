import React, { HTMLAttributes } from 'react';
import ReactPDF, { Text } from '@react-pdf/renderer';
import { ReportMode } from 'lib/shared';
import { useReportContext } from '../../context';
import ReportComponent from '../ReportComponent';

const ReportText = (
  props: ReactPDF.TextProps | HTMLAttributes<HTMLParagraphElement>,
) => {
  const { mode } = useReportContext();

  if (mode === ReportMode.Contents || mode === ReportMode.Meta) {
    return null;
  }

  return (
    <ReportComponent editComponent="p" viewComponent={Text} props={props} />
  );
};

export default ReportText;
