import React, { Fragment } from 'react';
import { evaluateExpression } from '@enterprise-platform-ui/expressions';
import { ExpressionType } from '@enterprise-platform-ui/expressions/src/datatypes';
import { DataType } from 'external/FormTemplateEditor';
import { ReportBlockComponentProps, ReportElement } from 'lib/shared';
import { evaluateProperty } from 'lib/utils';

export const serializeExpressionType = (
  expressionType: ExpressionType,
): any => {
  if (!expressionType) {
    return undefined;
  }

  switch (expressionType.dataType) {
    case DataType.OBJECT:
      return {
        value: Object.entries(expressionType.value).reduce(
          (ret, [key, value]) => ({
            ...ret,
            [key]: serializeExpressionType(value as ExpressionType),
          }),
          {},
        ),
        dataType: DataType.OBJECT,
      };

    case DataType.ARRAY:
      return {
        value: expressionType.value.map(serializeExpressionType),
        dataType: DataType.ARRAY,
      };

    default:
      return {
        value: expressionType.value,
        dataType: expressionType.dataType,
      };
  }
};

const BlockMap = ({
  data,
  context,
  getBlockComponent,
  after,
}: ReportBlockComponentProps<{
  each: any[];
  eachKey: string;
  eachIndexKey?: string;
  eachIf?: string;
  eachBreak?: boolean;
  eachBreakFirst?: boolean;
  eachStyle: React.CSSProperties;
  elements: ReportElement[];
}> & { after?: React.ReactNode }) => {
  const each = evaluateProperty('each', data, context) as any[];
  return (
    <>
      {(each ?? []).map((item, index) => {
        const eachValue = item ? serializeExpressionType(item) : undefined;
        const innerContext = {
          ...context,
          value: {
            ...context.value,
            [data.eachIndexKey ?? 'eachIndex']: {
              dataType: DataType.NUMBER,
              value: index,
            },
            [data.eachKey]: eachValue,
          },
        };

        if (data.eachIf) {
          if (!evaluateExpression(data.eachIf, innerContext)?.value) {
            return null;
          }
        }

        return (
          <Fragment key={item.value.id}>
            {data.elements.map((element, elementIndex) => {
              return (
                <Fragment key={elementIndex}>
                  {getBlockComponent(element.type)({
                    ...element,
                    context: innerContext,
                    getBlockComponent,
                  })}
                </Fragment>
              );
            })}
          </Fragment>
        );
      })}
      {after}
    </>
  );
};

export default BlockMap;
