import React from 'react';
import { Report, ReportBlockComponentProps, ReportMode } from 'lib/shared';
import { CommercialType, commercialTypeDisplayMap } from 'lib/types';
import JobTraining from '../JobTraining';
import { useJobCommercial } from './hooks';

const JobCommercial = ({
  context,
}: Pick<ReportBlockComponentProps, 'context'>) => {
  const { theme } = Report.useContext();
  const { ready, linesByType, totalsByType, total, training } =
    useJobCommercial(context);
  if (!ready) return null;
  return (
    <Report.View break>
      <Report.Stack>
        <Report.Section
          title="Job Cost"
          subtitle="Estimated costing information for works"
        >
          <Report.View style={{ paddingBottom: 6 }}>
            {linesByType.map(([type, lines], index, types) => {
              const isLast = index === types.length - 1;
              return (
                <Report.View
                  key={type}
                  style={{
                    ...(isLast
                      ? {}
                      : {
                          paddingBottom: 4,
                          marginBottom: 4,
                          borderBottomWidth: 1,
                          borderBottomStyle: 'solid',
                          borderBottomColor: theme.colors.border,
                        }),
                  }}
                >
                  <Report.View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 6,
                      lineHeight: '2.0',
                      color: theme.colors.subtitle,
                    }}
                  >
                    <Report.Text style={{ width: '50%', fontSize: 12 }}>
                      {commercialTypeDisplayMap[type as CommercialType]}
                    </Report.Text>
                    <Report.Text style={{ width: '12.5%', fontSize: 12 }}>
                      Unit
                    </Report.Text>
                    <Report.Text style={{ width: '12.5%', fontSize: 12 }}>
                      Quantity
                    </Report.Text>
                    <Report.Text style={{ width: '12.5%', fontSize: 12 }}>
                      Unit cost
                    </Report.Text>
                    <Report.Text style={{ width: '12.5%', fontSize: 12 }}>
                      Total cost
                    </Report.Text>
                  </Report.View>
                  <Report.View>
                    {lines.map((line) => {
                      return (
                        <Report.View
                          key={line.id}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingLeft: 12,
                            paddingRight: 12,
                            fontSize: 12,
                            fontWeight: 600,
                            color: theme.colors.title,
                            lineHeight: '2.0',
                          }}
                        >
                          <Report.Text style={{ width: '50%' }}>
                            {line.rate?.name || line.description}
                            {line.isCallout && ' (Callout)'}
                          </Report.Text>
                          <Report.Text style={{ width: '12.5%' }}>
                            {line.unitDisplay}
                          </Report.Text>
                          <Report.Text style={{ width: '12.5%' }}>
                            {line.estimatedQuantity?.toFixed(2)}
                          </Report.Text>
                          <Report.Text style={{ width: '12.5%' }}>
                            {line.estimatedUnitValue?.toFixed(2)}
                          </Report.Text>
                          <Report.Text style={{ width: '12.5%' }}>
                            {line.estimatedTotalValue?.toFixed(2)}
                          </Report.Text>
                        </Report.View>
                      );
                    })}
                  </Report.View>
                  <Report.View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      paddingLeft: 12,
                      paddingRight: 12,
                      lineHeight: '2.0',
                      fontWeight: 600,
                    }}
                  >
                    <Report.Text style={{ width: '62.5%' }} />
                    <Report.Text
                      style={{
                        width: '25%',
                        fontSize: 12,
                        color: theme.colors.subtitle,
                      }}
                    >
                      {commercialTypeDisplayMap[type as CommercialType]} Total
                    </Report.Text>
                    <Report.Text
                      style={{
                        width: '12.5%',
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      {totalsByType[type as CommercialType].toFixed(2)}
                    </Report.Text>
                  </Report.View>
                </Report.View>
              );
            })}
          </Report.View>
          <Report.View
            style={{
              borderTopWidth: 1,
              borderTopStyle: 'solid',
              borderTopColor: theme.colors.border,
              display: 'flex',
              flexDirection: 'row',
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 4,
              paddingBottom: 4,
              lineHeight: '1.5',
              alignItems: 'center',
            }}
          >
            <Report.Text
              style={{
                width: '75%',
                fontSize: 11,
                color: theme.colors.subtitle,
              }}
            >
              Please note the above costs are estimates only and may vary
            </Report.Text>
            <Report.Text
              style={{
                width: '12.5%',
                fontSize: 12,
                fontWeight: 600,
                color: theme.colors.subtitle,
              }}
            >
              Total
            </Report.Text>
            <Report.Text
              style={{ width: '12.5%', fontSize: 12, fontWeight: 600 }}
            >
              {total.toFixed(2)}
            </Report.Text>
          </Report.View>
        </Report.Section>
        {training.length > 0 && (
          <Report.View>
            <Report.Section
              title="Staff Training"
              subtitle="Training required for works"
            >
              <Report.Stack style={{ padding: 12 }}>
                {training.map((training) => (
                  <JobTraining key={training.id} training={training} />
                ))}
              </Report.Stack>
            </Report.Section>
          </Report.View>
        )}
      </Report.Stack>
    </Report.View>
  );
};

export default JobCommercial;
