import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Empty } from 'lib/shared';
import { useCommercialActivityCreate } from './hooks';

const CommercialActivityCreate = () => {
  const { dialog, onCreate } = useCommercialActivityCreate();
  return (
    <div className="h-full p-4">
      <Empty asChild>
        <div className="flex flex-col items-center gap-y-2">
          <p className="text-sm">No commercial activity found for this job.</p>
          <p className="dark:text-white-400 text-xs text-gray-400">
            Create a new commercial activity to get started.
          </p>
          <Dialog open={dialog.open} onOpenChange={dialog.onOpenChange}>
            <DialogTrigger asChild>
              <Button className="mt-1" variant="outline">
                Create Activity
              </Button>
            </DialogTrigger>
            <DialogContent overlay>
              <DialogHeader>
                <DialogTitle>Create Commercial Activity</DialogTitle>
                <DialogDescription>
                  Create a new commercial activity for this job
                </DialogDescription>
              </DialogHeader>
              <Button onClick={onCreate}>Create</Button>
            </DialogContent>
          </Dialog>
        </div>
      </Empty>
    </div>
  );
};

export default CommercialActivityCreate;
