import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useCallbackRef } from 'lib/hooks';
import useTraceUpdate from '../../hooks/useTraceUpdate';
import WindowManagerClose from './components/WindowManagerClose';
import WindowManagerHeader from './components/WindowManagerHeader';
import WindowManagerRouter from './components/WindowManagerRouter';
import { Context } from './context';
import { WindowManagerProps } from './types';

const WindowManager = ({
  children,
  dependencies = [],
  initialPath = '/',
  onClose = () => {},
}: WindowManagerProps) => {
  const navigate = useNavigate();
  const [, setParams] = useSearchParams();

  const [key, setKey] = useState(0);
  const [path, setPath] = useState(initialPath);
  const [patterns, setPatterns] = useState<string[]>([]);

  const [ready, setReady] = useState(false);

  useEffect(() => {
    setPath(initialPath);
    setKey((prevKey) => prevKey + 1);

    setParams((prevParams) => {
      const params = new URLSearchParams(prevParams);
      params.set('window', initialPath);
      return params.toString();
    });
  }, dependencies);

  // const location = useLocation();
  // useEffect(() => {
  //   setPath(location.search.replace('?window=', ''));
  //   setKey((prevKey) => prevKey + 1);
  //
  //   console.log('WindowManager location useEffect');
  // }, [location.search]);

  useTraceUpdate({
    children,
    dependencies,
    initialPath,
    onClose,
    key,
    path,
    patterns,
    ready,
  });

  const handleRegisterPatterns = useCallback((newPatterns: string[]) => {
    setPatterns((prevPatterns) => [
      ...new Set([...prevPatterns, ...newPatterns]),
    ]);
  }, []);

  const handleOnClose = useCallback(() => {
    navigate('');
    onClose();
  }, [navigate, onClose]);

  const headerRef = useCallbackRef();

  return (
    <Fragment key={key}>
      <Context.Provider
        value={{
          windowed: true,
          headerRef,
          path,
          patterns,
          setPath,
          close: handleOnClose,
          registerPatterns: handleRegisterPatterns,
        }}
      >
        <div className="flex h-full flex-col">
          <div ref={headerRef.callbackRef as any} />
          {children}
        </div>
      </Context.Provider>
    </Fragment>
  );
};

WindowManager.Close = WindowManagerClose;
WindowManager.Header = WindowManagerHeader;
WindowManager.Router = WindowManagerRouter;

export default WindowManager;

const a = {
  rate: '20.00',
  quotes: [],
  varied: 'N',
  est_vat: '0.00',
  on_hold: 'N',
  plant_id: null,
  est_other: '0.00',
  reference: null,
  room_desc: null,
  site_code: 'C021',
  site_desc: 'Civic Offices, Watling Street',
  created_on: '2024-05-14 15:18:57',
  est_labour: '75.00',
  plant_code: null,
  plant_desc: null,
  category_id: 49,
  issued_date: '2024-05-14 15:19:00',
  parent_code: 'HLP005174',
  priority_id: 2,
  room_number: null,
  target_date: '2024-05-16 00:00:00',
  target_time: '15:17',
  est_comments: null,
  est_material: '0.00',
  fin_accounts: [
    {
      fin_account: {
        inspection_code: '',
        fin_account_code: 'RF61051-621270',
        fin_account_desc:
          'C021  Civic Offices 2 Watling Street    - Building Mtce ( B&M)',
        fin_net_allocation: '75.00',
      },
    },
  ],
  site_details: {
    site_code: 'C021',
    site_desc: 'Civic Offices, Watling Street',
    site_contact: {
      id: 19,
      email_addr: 'Fmclient.unit@bexley.gov.uk',
      phone_main: '0203 045 3336',
      contact_name: 'FM Client Unit',
      organisation: 'Bexley Council',
    },
    additional_data: {
      user_sel1: null,
      user_sel2: null,
      user_text1: null,
      user_text2: null,
      user_text3: null,
      user_text4: null,
      user_text5: null,
      user_text6: null,
      user_check1: 'N',
      user_check2: 'N',
      user_check3: 'N',
      user_check4: 'N',
      user_contact1_email_addr: null,
      user_contact1_phone_main: null,
      user_contact2_email_addr: null,
      user_contact2_phone_main: null,
      user_contact3_email_addr: null,
      user_contact3_phone_main: null,
      user_contact4_email_addr: null,
      user_contact4_phone_main: null,
      user_contact5_email_addr: null,
      user_contact5_phone_main: null,
      user_contact1_contact_name: null,
      user_contact1_organisation: null,
      user_contact2_contact_name: null,
      user_contact2_organisation: null,
      user_contact3_contact_name: null,
      user_contact3_organisation: null,
      user_contact4_contact_name: null,
      user_contact4_organisation: null,
      user_contact5_contact_name: null,
      user_contact5_organisation: null,
    },
    site_address_postcode: 'DA6 7AT',
  },
  auth_comments: null,
  building_code: 'C021/01',
  building_desc: 'Civic Offices - Main Building',
  category_desc: 'Alarm Systems',
  category_name: '03',
  est_net_total: '75.00',
  priority_name: '2',
  supplier_name: 'B&M McHugh Ltd',
  tax_code_code: '20%',
  tax_code_desc: 'Standard',
  instruction_id: 10918,
  parent_type_id: 1,
  user_sel1_code: null,
  user_sel1_desc: null,
  additional_data: [],
  authoriser_name: null,
  est_gross_total: '75.00',
  mtc_contract_id: 50,
  trade_code_code: null,
  trade_code_desc: null,
  appointment_date: null,
  appointment_time: null,
  instruction_code: 'MTC002162',
  instruction_desc:
    'We have a fault on the Fire Alarm Panel I think its said lost device and sounder light is flashing.',
  instruction_type: 'instruction',
  created_user_name: 'sarah.wise',
  est_permit_ref_no: null,
  mtc_contract_code: 'CONT00000026',
  authoriser_username: null,
  est_permit_required: 'N',
  instruction_type_id: 5,
  on_hold_description: null,
  print_template_code: 'DEFAULT',
  supplier_account_ref: '621270',
  target_response_date: '2024-05-15 00:00:00',
  target_response_time: '15:17',
  instruction_bgt_lines: [],
  instruction_type_name: 'TC Order',
  supplier_organisation: 'B&M McHugh Ltd',
  instruction_status_code: 'QTESENT',
  instruction_status_desc: 'Quote Sent',
  print_template_description: 'Default Print Template',
  mtc_contract_instruction_id: 54,
  helpcall_standard_problem_id: null,
  instruction_status_type_code: 'PRI',
  instruction_status_type_desc: 'Printed',
  mtc_contract_instruction_code: 'CINT00000030',
  helpcall_standard_problem_code: null,
};
