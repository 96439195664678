import React from 'react';
import {
  CalendarCheck,
  LayoutDashboard,
  ShieldCheck,
  Users,
} from 'lucide-react';
import { Outlet } from 'react-router-dom';
import { Nav } from '@/components/ui/nav';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable';
import { cn } from '@/lib/utils';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useRouteMatch } from 'lib/hooks';

const HumanResourcesTraining = () => {
  const [open, setOpen] = useLocalStorage('hr-training-open', true);
  const routeMatch = useRouteMatch([
    '/hr/training/qualifications/*',
    '/hr/training/events/*',
    '/hr/training/staff/*',
    '/hr/training/*',
  ]);
  return (
    <ResizablePanelGroup
      direction="horizontal"
      className="grow items-stretch bg-background"
    >
      <ResizablePanel
        defaultSize={14}
        minSize={14}
        maxSize={14}
        collapsible
        collapsedSize={1}
        onCollapse={() => setOpen(false)}
        onExpand={() => setOpen(true)}
        className={cn(
          'min-w-[64px] bg-background-secondary transition-all',
          !open && 'duration-300 ease-in-out',
        )}
      >
        <div className="flex h-[52px] items-center border-b px-4">
          <h1
            className={cn('text-xl font-semibold', {
              'font-normal': routeMatch?.pattern.path.startsWith('/hr/rosters'),
            })}
          >
            Training
          </h1>
        </div>
        <Nav
          collapsed={!open}
          links={[
            {
              title: 'Dashboard',
              icon: LayoutDashboard,
              variant: routeMatch?.pattern.path.startsWith('/hr/training/*')
                ? 'selected'
                : 'unselected',
              to: `/hr/training`,
            },
            {
              title: 'Qualifications',
              icon: ShieldCheck,
              variant: routeMatch?.pattern.path.startsWith(
                '/hr/training/qualifications/*',
              )
                ? 'selected'
                : 'unselected',
              to: `/hr/training/qualifications`,
            },
            {
              title: 'Events',
              icon: CalendarCheck,
              variant: routeMatch?.pattern.path.startsWith(
                '/hr/training/events/*',
              )
                ? 'selected'
                : 'unselected',
              to: `/hr/training/events`,
            },
            {
              title: 'Staff',
              icon: Users,
              variant: routeMatch?.pattern.path.startsWith(
                '/hr/training/staff/*',
              )
                ? 'selected'
                : 'unselected',
              to: `/hr/training/staff`,
            },
          ]}
        />
      </ResizablePanel>
      <ResizableHandle withHandle />
      <ResizablePanel>
        <Outlet />
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};

export default HumanResourcesTraining;
