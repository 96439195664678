import React from 'react';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable';
import { WindowManager } from 'lib/shared';
import SeriesNavigation from './components/SeriesNavigation';
import SeriesWindowManager from './components/SeriesWindowManager';
import { MaintenanceSeriesContext } from './context';
import { useMaintenanceSeries } from './hooks';

const MaintenanceSeries = () => {
  const { context } = useMaintenanceSeries();
  return (
    <MaintenanceSeriesContext.Provider value={context}>
      <WindowManager onClose={() => {}}>
        <ResizablePanelGroup
          autoSaveId="client"
          direction="horizontal"
          className="grow items-stretch bg-background"
        >
          <ResizablePanel
            className="relative flex flex-col"
            collapsible={false}
            minSize={12}
            maxSize={15}
          >
            <SeriesNavigation />
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel minSize={30} className="flex flex-col">
            <SeriesWindowManager />
          </ResizablePanel>
        </ResizablePanelGroup>
      </WindowManager>
    </MaintenanceSeriesContext.Provider>
  );
};

export default MaintenanceSeries;
