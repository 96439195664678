import React from 'react';
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import { BellIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  NavigationMenuContent,
  NavigationMenuItem,
} from '@/components/ui/navigation-menu';
import { ScrollArea } from '@/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { formatDistanceToNow } from 'date-fns/formatDistanceToNow';
import { DateTime } from 'luxon';
import { useNotifications } from '../../hooks';

const MenuNotifications = () => {
  const { notifications, notificationsUnread, markAsRead, markAllAsRead } =
    useNotifications();
  return (
    <NavigationMenuItem>
      <NavigationMenuPrimitive.Trigger
        asChild
        onPointerMove={(event) => event.preventDefault()}
        onPointerLeave={(event) => event.preventDefault()}
      >
        <Button className="relative" variant="outline" size="icon">
          <BellIcon className="w-5 text-gray-700 dark:text-primary" />
          {notificationsUnread.length > 0 && (
            <div className="absolute bottom-[-2px] bg-background px-[4px]">
              <div
                className="h-[2px] max-w-4 rounded-lg bg-warning"
                style={{ width: notificationsUnread.length * 4 }}
              />
            </div>
          )}
        </Button>
      </NavigationMenuPrimitive.Trigger>
      <NavigationMenuContent>
        <div className="w-[500px]">
          <div className="flex p-4 font-normal">
            <div className="flex grow flex-col space-y-2">
              <p className="text-sm font-medium leading-none">Notifications</p>
              {notificationsUnread.length > 0 ? (
                <p className="text-xs leading-none text-muted-foreground">
                  {notificationsUnread.length} new notification
                  {notificationsUnread.length > 1 && 's'}
                </p>
              ) : (
                <p className="text-xs leading-none text-muted-foreground">
                  No new notifications
                </p>
              )}
            </div>
            <div>
              <Button
                disabled={notificationsUnread.length === 0}
                variant="outline"
                size="sm"
                onClick={markAllAsRead}
              >
                Mark all as read
              </Button>
            </div>
          </div>
          <ScrollArea className="h-[400px]">
            {notifications.map((notification) => (
              <button
                key={notification.id}
                className={cn(
                  'flex w-full flex-col items-start gap-2 px-4 py-3 text-left text-sm transition-all hover:bg-accent',
                )}
                onClick={markAsRead(notification)}
              >
                <div className="flex w-full flex-col gap-1">
                  <div className="flex items-center">
                    <div className="flex items-center gap-2">
                      <div className="font-semibold">{notification.title}</div>
                      {!notification.isRead && (
                        <span className="flex h-2 w-2 rounded-full bg-blue-600" />
                      )}
                    </div>
                    <div className={cn('ml-auto text-xs')}>
                      {formatDistanceToNow(
                        DateTime.fromSeconds(notification.timestamp).toJSDate(),
                        {
                          addSuffix: true,
                        },
                      )}
                    </div>
                  </div>
                </div>
                <div className="line-clamp-2 text-xs text-muted-foreground">
                  {notification.body.substring(0, 300)}
                </div>
              </button>
            ))}
          </ScrollArea>
          <div className="p-4">
            <Button className="w-full" size="sm" variant="outline">
              View all notifications
            </Button>
          </div>
        </div>
      </NavigationMenuContent>
    </NavigationMenuItem>
  );
};

export default MenuNotifications;
