import { CommercialType } from 'lib/types';
import { useEstimateTableContext } from '../../../../context';

const ValueHeader = () => {
  const { type } = useEstimateTableContext();
  return (
    <div className="grid grid-cols-8 border-y bg-background text-xs font-semibold">
      <div className="col-span-3 flex items-center border-r">
        <div className="w-8 flex-shrink-0" />
        {type === CommercialType.Prelim && (
          <span className="px-2">Description</span>
        )}
        {(type === CommercialType.Labor ||
          type === CommercialType.Subcontractor) && (
          <span className="px-2">Contract rate</span>
        )}
        {type === CommercialType.Material && (
          <span className="px-2">Item / Description</span>
        )}
        {type === CommercialType.Equipment && (
          <span className="px-2">Asset / Description</span>
        )}
      </div>
      <div className="col-span-1 border-r p-2">Unit</div>
      <div className="col-span-1 border-r p-2">Quantity</div>
      <div className="col-span-1 border-r p-2">Unit value (£)</div>
      <div className="col-span-1 border-r p-2">Uplift (%)</div>
      <div className="col-span-1 p-2">Total value (£)</div>
    </div>
  );
};

export default ValueHeader;
