import { createContext, useContext } from 'react';
import { useEstimateQuote, useQuoteRenderer } from './hooks';

export const QuoteRendererContext = createContext(
  {} as ReturnType<typeof useQuoteRenderer>['context'],
);

export const useQuoteRendererContext = () => {
  return useContext(QuoteRendererContext);
};

export const EstimateQuoteContext = createContext(
  {} as ReturnType<typeof useEstimateQuote>['context'],
);

export const useEstimateQuoteContext = () => {
  return useContext(EstimateQuoteContext);
};
