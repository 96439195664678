import React from 'react';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { TableRenderer } from '@/components/ui/table/table/Table';
import QualificationCard from './components/QualificationCard';
import QualificationChildren from './components/QualificationChildren';
import QualificationEditor from './components/QualificationEditor';
import { TrainingQualificationContext } from './context';
import { useTrainingQualification } from './hooks';

const TrainingQualification = () => {
  const { context } = useTrainingQualification();

  if (!context.qualification) return null;

  if (context.state.open) {
    return (
      <QualificationEditor
        qualification={context.qualification}
        onClose={context.state.onClose}
        onSubmit={context.handlers.onUpdate}
        onDelete={context.handlers.onDelete}
      />
    );
  }

  return (
    <TrainingQualificationContext.Provider value={context}>
      <div className="h-full min-h-0 bg-secondary bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-background">
        <ScrollArea className="h-full min-h-0">
          <div className="container flex w-[900px] flex-col gap-y-4 py-8">
            <QualificationCard
              extra={
                <div className="absolute left-full top-0 pl-4">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={context.state.onOpen}
                  >
                    Edit
                  </Button>
                </div>
              }
            />
            <div className="rounded-lg border bg-background px-4 pt-4">
              <QualificationChildren />
            </div>
            <div className="rounded-lg border bg-background px-4 pt-4">
              <div>
                <h3 className="mb-1">Qualified staff</h3>
                <p className="text-xs dark:text-white/75">
                  User groups allow you to group users together to manage their
                  permissions.
                </p>
              </div>
              <TableRenderer
                filter="reference"
                columns={[
                  {
                    id: 'name',
                    accessorKey: 'name',
                    header: 'Name',
                  },
                ]}
                data={[]}
              />
            </div>
            <div className="rounded-lg border bg-background px-4 pt-4">
              <div>
                <h3 className="mb-1">Training events</h3>
                <p className="text-xs dark:text-white/75">
                  User groups allow you to group users together to manage their
                  permissions.
                </p>
              </div>
              <TableRenderer
                filter="reference"
                columns={[
                  {
                    id: 'name',
                    accessorKey: 'name',
                    header: 'Name',
                  },
                ]}
                data={[]}
              />
            </div>
          </div>
        </ScrollArea>
      </div>
    </TrainingQualificationContext.Provider>
  );
};

export default TrainingQualification;
