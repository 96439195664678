import { useMatch } from 'react-router';
import {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  useQuery,
} from '@apollo/client';
import { DocumentNode } from 'graphql/index';
import produce from 'immer';
import { Mutate, StoreApi, UseBoundStore } from 'zustand';
import { BaseActions } from './createSectionState';

export const createUse =
  <Values>(
    useStore: UseBoundStore<
      Mutate<StoreApi<Values & BaseActions<Values>>, any>
    >,
  ) =>
  <TData, TVariables extends OperationVariables, L extends keyof Values>(
    key: L,
    query: DocumentNode,
    options?: {
      queryOptions?:
        | QueryHookOptions<TData, TVariables>
        | ((
            variables: Partial<TVariables>,
          ) => QueryHookOptions<TData, TVariables>);
      matchPattern?: string;
      matchVariables?: string[];
    },
  ) => {
    return (
      staticVariables: Partial<TVariables> = {} as any,
      onCompleted?: (data: TData) => void,
      optionalVariables: Partial<TVariables> = {} as any,
    ): [Values[L], QueryResult] => {
      const value = useStore((state) => (state as Values)[key]);
      const apply = useStore((state) => state.apply);
      const match = useMatch(options?.matchPattern || '');

      const variablesFromMatch = options?.matchVariables
        ? options?.matchVariables.reduce(
            (ret, variable) => ({
              ...ret,
              [variable]: match?.params?.[variable],
            }),
            {} as Record<string, any>,
          )
        : undefined;

      const variables = {
        ...variablesFromMatch,
        ...(staticVariables as TVariables),
      };

      const result = useQuery<TData, TVariables>(query, {
        ...(typeof options?.queryOptions === 'function'
          ? options?.queryOptions(variables)
          : options?.queryOptions),
        fetchPolicy: 'no-cache',
        skip: Object.values(variables).some((variable) => !variable),
        variables: { ...variables, ...optionalVariables },
        onCompleted: (data) => {
          onCompleted?.(data);
          apply(
            produce((state) => {
              (state as Values)[key] = (data as any)[key];
            }),
          );
        },
      });

      // todo: investigate this type error [priority: low]
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return [value, result];
    };
  };

export default createUse;
