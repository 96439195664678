'use client';

import * as React from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';

export function Combobox({
  value,
  className,
  defaultFilter,
  disabled = false,
  options = [],
  onValueChange,
}: {
  value?: string;
  className?: string;
  defaultFilter?: string;
  disabled?: boolean;
  options: {
    value: string;
    label: string;
    keywords?: string[];
  }[];
  onValueChange?: (value: string) => void;
}) {
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = React.useState(defaultFilter || '');

  return (
    <Popover open={!disabled && open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn('min-w-[200px] justify-between', className)}
        >
          {value
            ? options.find((option) => option.value === value)?.label
            : 'Select scope...'}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="popover-content-width-same-as-its-trigger w-[200px] p-0">
        <Command loop disablePointerSelection={false}>
          <CommandInput
            placeholder="Search scopes..."
            disabled={disabled}
            value={filter}
            onValueChange={setFilter}
          />
          <CommandEmpty>No scope found.</CommandEmpty>
          <CommandList>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option.value}
                  value={option.value}
                  keywords={option.keywords}
                  onSelect={(currentValue) => {
                    onValueChange?.(currentValue === value ? '' : currentValue);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      value === option.value ? 'opacity-100' : 'opacity-0',
                    )}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
