import React from 'react';
import { useActionContext } from '../../context';
import { ActionStatus } from '../../types';
import ActionDryrun from '../ActionDryrun';
import ActionForm from '../ActionForm';
import ActionPerform from '../ActionPerform';

const ActionBody = () => {
  const {
    state: { status },
  } = useActionContext();

  if (status === ActionStatus.Idle) {
    return null;
  }

  if (status === ActionStatus.Form) {
    return <ActionForm />;
  }

  if (status === ActionStatus.DryrunPending) {
    return <ActionDryrun.Pending />;
  }

  if (status === ActionStatus.DryrunSucceeded) {
    return <ActionDryrun.Success />;
  }

  if (status === ActionStatus.DryrunFailed) {
    return <ActionDryrun.Error />;
  }

  if (status === ActionStatus.PerformPending) {
    return <ActionPerform.Pending />;
  }

  if (status === ActionStatus.PerformSucceeded) {
    return <ActionPerform.Success />;
  }

  if (status === ActionStatus.PerformCleanup) {
    return <ActionPerform.Cleanup />;
  }

  if (status === ActionStatus.PerformFailed) {
    return <ActionPerform.Error />;
  }

  return null;
};

export default ActionBody;
