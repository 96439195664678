import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

export const useTime = () => {
  const [time, setTime] = useState(DateTime.now());
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(DateTime.now());
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return time;
};
