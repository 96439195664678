import { useSyncedStore } from '@syncedstore/react';
import { Page, flatten } from 'external/Tree';
import { useFormTemplateContext } from '../../context';

export const usePageFooter = (page: Page) => {
  const { store } = useFormTemplateContext();
  const { items, pageOrder } = useSyncedStore(store);

  const handleDeletePageOnClick = () => {
    pageOrder.splice(pageOrder.indexOf(page.id), 1);
    const itemsToRemove = flatten(
      page,
      items,
      () => true,
      () => true,
    );

    for (const itemToRemove of itemsToRemove) {
      delete items[itemToRemove.id];
    }
  };

  return { handleDeletePageOnClick };
};
