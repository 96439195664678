import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { CompanyMembership } from 'lib/types';
import { platformObjectInput } from 'lib/utils';
import { useCompanyMembershipsContext } from '../../context';
import {
  DeleteCompanyMembershipMutationData,
  DeleteCompanyMembershipMutationVariables,
  UpdateCompanyMembershipMutationData,
  UpdateCompanyMembershipMutationVariables,
} from './types';

export const useMembershipsEditor = (membership: CompanyMembership) => {
  const { refetch } = useCompanyMembershipsContext();

  const [open, setOpen] = useState(false);

  const [update] = useMutation<
    UpdateCompanyMembershipMutationData,
    UpdateCompanyMembershipMutationVariables
  >(
    gql`
      mutation CreateCompanyMembership(
        $id: ID!
        $data: CompanyMembershipData!
      ) {
        updateCompanyMembership(
          companyMembershipId: $id
          companyMembershipData: $data
        ) {
          companyMembership {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnUpdate = async (values: any) => {
    const { data, errors } = await update({
      variables: {
        id: membership.id,
        data: {
          logo: platformObjectInput(values.logo),
          name: values.name,
          description: values.description,
          dateTimeExpiry: values.dateTimeExpiry,
          document: platformObjectInput(values.document),
        },
      },
    });

    if (data) {
      await refetch();
      setOpen(false);
      toast.success('Company membership created');
    }

    if (errors) {
      toast.error('Failed to create company membership');
    }
  };

  const [delete_] = useMutation<
    DeleteCompanyMembershipMutationData,
    DeleteCompanyMembershipMutationVariables
  >(
    gql`
      mutation DeleteCompanyMembership($id: ID!) {
        deleteCompanyMembership(companyMembershipId: $id) {
          deleted
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnDelete = async () => {
    const { data, errors } = await delete_({
      variables: {
        id: membership.id,
      },
    });

    if (data) {
      await refetch();
      setOpen(false);
      toast.success('Company membership deleted');
    }

    if (errors) {
      toast.error('Failed to delete company membership');
    }
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onUpdate: handleOnUpdate,
      onDelete: handleOnDelete,
    },
  };
};
