import React, { useState } from 'react';
import { TooltipPortal } from '@radix-ui/react-tooltip';
import { TrashIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { toast } from 'sonner';
import { User } from 'lib/types';
import { useUserGroupContext } from '../../../../context';

const TableRemove = ({
  open: controlledOpen,
  setOpen: controlledSetOpen,
  user,
}: {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  user: User;
}) => {
  const { handlers } = useUserGroupContext();

  const [uncontrolledOpen, uncontrolledSetOpen] = useState(false);
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = controlledSetOpen ?? uncontrolledSetOpen;
  const isControlled = controlledOpen !== undefined;

  const trigger = isControlled ? null : (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button variant="outline" size="icon" onClick={() => setOpen(true)}>
          <TrashIcon className="h-4 w-4 text-gray-400 " />
        </Button>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent>Remove from team</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <div>{trigger}</div>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Remove from group</DialogTitle>
          <DialogDescription>
            Are you sure you want to remove this user from the group?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" size="sm">
              Cancel
            </Button>
          </DialogClose>
          <Button
            size="sm"
            variant="destructive"
            onClick={async () => {
              await handlers.onRemove(user);
              setOpen(false);
              toast.success('User removed from group');
            }}
          >
            Remove
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default TableRemove;
