import React from 'react';
import { Building } from 'lucide-react';
import {
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu';
import NavigationLeftListItem from '../NavigationLeftListItem';

const NavigationLeftCompany = () => {
  return (
    <NavigationMenuItem>
      <NavigationMenuTrigger>Company</NavigationMenuTrigger>
      <NavigationMenuContent>
        <ul className="grid gap-3 p-4 md:w-[450px] lg:w-[550px] lg:grid-cols-[.75fr_1fr]">
          <li className="row-span-4">
            <NavigationMenuLink asChild>
              <div className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md">
                <Building className="h-6 w-6" />
                <div className="mb-2 mt-4 text-lg font-medium">Company</div>
                <p className="text-sm leading-tight text-muted-foreground">
                  Manage company settings and users
                </p>
              </div>
            </NavigationMenuLink>
          </li>
          <NavigationLeftListItem to="/company/staff" title="Staff">
            Manage organisational structure
          </NavigationLeftListItem>
          <NavigationLeftListItem
            to="/company/accreditations"
            title="Accreditations"
          >
            Manage accreditations
          </NavigationLeftListItem>
          <NavigationLeftListItem to="/company/memberships" title="Memberships">
            Manage memberships
          </NavigationLeftListItem>
          <NavigationLeftListItem to="/company/insurance" title="Insurance">
            Manage insurance
          </NavigationLeftListItem>
        </ul>
      </NavigationMenuContent>
    </NavigationMenuItem>
  );
};

export default NavigationLeftCompany;
