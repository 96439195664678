import React, { useCallback } from 'react';
import { ChevronRightIcon, GripVerticalIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import chroma from 'chroma-js';
import { ItemType } from 'external/Tree';
import { useFormTemplateContext } from '../../context';
import { BlockButtonsProps } from './types';

const BlockButtons = ({ color, item, dragHandleProps }: BlockButtonsProps) => {
  const { collapsed, onCollapse } = useFormTemplateContext();
  const isCollapsed = collapsed.includes(item.id);

  const renderCollapseButton = useCallback(() => {
    return (
      <Button
        size="icon"
        className="rounded-full"
        style={{
          width: 28,
          height: 28,
          margin: '3px',
          backgroundColor: chroma(color).alpha(0.25).css(),
        }}
        onClick={onCollapse(item)}
      >
        <ChevronRightIcon
          className={cn('h-4 w-4 transition-all', {
            'rotate-90 transform': !isCollapsed,
          })}
          style={{ stroke: color }}
        />
      </Button>
    );
  }, [color, isCollapsed, item, onCollapse]);

  const renderDragHandle = useCallback(() => {
    return (
      <span {...dragHandleProps}>
        <Button
          size="icon"
          variant="ghost"
          className="cursor-grab"
          style={{ width: 28, height: 28, margin: '3px' }}
        >
          <GripVerticalIcon className="h-5 w-5" />
        </Button>
      </span>
    );
  }, [dragHandleProps]);

  if (item.type === ItemType.CONTAINER) {
    return (
      <div className="flex items-center px-1">
        {item.children.length > 0 && renderCollapseButton()}
        {(isCollapsed || item.children.length === 0) && renderDragHandle()}
      </div>
    );
  }

  return (
    <div className="flex items-center px-1">
      {item.children.length > 0 && renderCollapseButton()}
      {((item.type === ItemType.BLOCK &&
        (item.children.length === 0 || isCollapsed)) ||
        (item.type === ItemType.CONTAINER && isCollapsed)) &&
        renderDragHandle()}
    </div>
  );
};

export default BlockButtons;
