import { createContext, useContext } from 'react';
import { useMemberTrainingForm } from './hooks';

export const MemberTrainingFormContext = createContext(
  {} as ReturnType<typeof useMemberTrainingForm>['context'],
);

export const useMemberTrainingFormContext = () => {
  return useContext(MemberTrainingFormContext);
};
