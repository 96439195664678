import React from 'react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { DateTime } from 'luxon';
import { useJobTaskContext } from '../../../../../../../../context';
import SubcontractorReportCreate from '../SubcontractorReportCreate';
import { SubcontractorReportListProps } from './types';

const SubcontractorReportList = ({
  onSelect,
}: SubcontractorReportListProps) => {
  const { task } = useJobTaskContext();

  if (!task) {
    return null;
  }

  return (
    <div>
      <div>
        {task.timesheetActivity.subcontractorDuties.map((duty) => (
          <div key={duty.id} className="border-b p-4">
            <div className="mb-4">
              <h3 className="font-semibold">{duty.subcontractor.name}</h3>
              <div className="flex items-center gap-x-4">
                <p className="text-sm">
                  Start:{' '}
                  {DateTime.fromISO(duty.dateTimeStart).toLocaleString(
                    DateTime.DATETIME_MED,
                  )}
                </p>
                <p className="text-sm">
                  End:{' '}
                  {DateTime.fromISO(duty.dateTimeEnd).toLocaleString(
                    DateTime.DATETIME_MED,
                  )}
                </p>
                <SubcontractorReportCreate duty={duty} />
              </div>
            </div>
            <div>
              {duty.reports.map((report) => (
                <div key={report.id} className="flex items-center gap-x-4">
                  <h5 className="grow text-sm font-medium">
                    {report.sourceObject.name}
                  </h5>
                  <Badge>{report.statusDisplay}</Badge>
                  <Button
                    variant="link"
                    size="sm"
                    onClick={() => onSelect(duty, report)}
                  >
                    View
                  </Button>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubcontractorReportList;
