import * as React from 'react';
import ReactTextareaAutosize, {
  TextareaAutosizeProps as BaseTextareaAutosizeProps,
} from 'react-textarea-autosize';
import { cn } from '@/lib/utils';

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  onValueChange?: (value: string) => void;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, onValueChange, ...props }, ref) => {
    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onValueChange) {
        onValueChange(event.target.value);
      }
    };

    return (
      <textarea
        className={cn(
          'flex min-h-[60px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
        ref={ref}
        onChange={handleOnChange}
        {...props}
      />
    );
  },
);
Textarea.displayName = 'Textarea';

export interface TextareaAutosizeProps extends TextareaProps {
  style?: BaseTextareaAutosizeProps['style'];
  minRows?: number;
  maxRows?: number;
  onValueChange?: (value: string) => void;
}

const TextareaAutosize = React.forwardRef<
  HTMLTextAreaElement,
  TextareaAutosizeProps
>(({ className, minRows, maxRows, onValueChange, ...props }, ref) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onValueChange) {
      onValueChange(event.target.value);
    }
  };

  return (
    <ReactTextareaAutosize
      className={cn(
        'flex min-h-[60px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      ref={ref}
      onChange={handleOnChange}
      {...props}
    />
  );
});
TextareaAutosize.displayName = 'TextareaAutosize';

export { Textarea, TextareaAutosize };
