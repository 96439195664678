import React, { PropsWithChildren } from 'react';
import { Accordion } from '@/components/ui/accordion';
import { ScrollArea } from '@/components/ui/scroll-area';
import { STAGE_DESCRIPTION, STAGE_LOCATION } from '../../stages';

const InstantiationBody = ({ children }: PropsWithChildren) => {
  return (
    <ScrollArea>
      <div className="pb-[65px]">
        <Accordion
          type="multiple"
          defaultValue={[STAGE_DESCRIPTION, STAGE_LOCATION]}
        >
          {children}
        </Accordion>
      </div>
    </ScrollArea>
  );
};

export default InstantiationBody;
