import { CommercialType } from 'lib/types';
import { useActivityTableContext } from '../../../../context';

const TableCostHeader = () => {
  const { type } = useActivityTableContext();
  return (
    <div className="flex bg-background-secondary text-xs font-semibold">
      <div className="grid w-7/12 grid-cols-8 border-y">
        <div className="col-span-3 flex items-center border-r">
          <div className="w-8 flex-shrink-0" />
          {(type === CommercialType.Prelim ||
            type === CommercialType.Labor ||
            type === CommercialType.Subcontractor) && (
            <span className="px-2">Resource rate</span>
          )}
          {type === CommercialType.Material && (
            <span className="px-2">Item / Description</span>
          )}
          {type === CommercialType.Equipment && (
            <span className="px-2">Asset / Description</span>
          )}
        </div>
        <div className="col-span-1 border-r p-2">Unit</div>
        <div className="col-span-1 border-r p-2">Quantity</div>
        <div className="col-span-1 border-r p-2">Unit cost (£)</div>
        <div className="col-span-1 p-2">Total cost (£)</div>
        <div className="col-span-1 p-2" />
      </div>
      <div className="grid w-5/12 grid-cols-6 border-y">
        <div className="col-span-1 p-2">Quantity</div>
        <div className="col-span-1 p-2">Unit cost (£)</div>
        <div className="col-span-1 p-2">Total cost (£)</div>
        <div className="col-span-1 p-2" />
        <div className="col-span-2 p-2">Status</div>
      </div>
    </div>
  );
};

export default TableCostHeader;
