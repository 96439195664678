import { DataType } from 'external/FormTemplateEditor';
import { Duration } from 'luxon';
import { ReportBlockComponentProps } from '../../../../types';
import { evaluateProperty } from '../../../../utils';
import BlockText from '../BlockText';
import { BlockDateTimeProps } from './types';


const BlockDuration = ({
  data,
  context,
  ...props
}: ReportBlockComponentProps<BlockDateTimeProps>) => {
  const milliseconds =
    (evaluateProperty('duration', data, context) ?? 0) * 1000;
  const duration = Duration.fromMillis(
    Math.round(milliseconds / 60000) * 60000,
  );
  return (
    <BlockText
      {...props}
      type="text"
      data={{
        ...data,
        mode: 'row',
        '$.text': '$.value',
        '$.label': '$.label',
      }}
      context={{
        label: { dataType: DataType.CHAR, value: data.label },
        value: {
          dataType: DataType.CHAR,
          value: duration.shiftTo('hours', 'minutes').toHuman(),
        },
      }}
    />
  );
};

export default BlockDuration;
