import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useCommercialContractContext } from '../../../../context';

export const useContractUpdate = () => {
  const { contract } = useCommercialContractContext();

  const [open, setOpen] = useState(false);

  const [update] = useMutation(
    gql`
      mutation UpdateCommercialContract(
        $id: ID!
        $name: String!
        $description: String!
        $dateStart: DateTime!
        $dateEnd: DateTime!
      ) {
        updateCommercialContract(
          commercialContractId: $id
          name: $name
          description: $description
          dateStart: $dateStart
          dateEnd: $dateEnd
        ) {
          commercialContract {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnSubmit = async (values: any) => {
    if (!contract) {
      console.warn('Cannot update non-existent contract');
      return;
    }

    const { data, errors } = await update({
      variables: {
        id: contract.id,
        name: values.name,
        description: values.description,
        dateStart: values.dateStart,
        dateEnd: values.dateEnd,
      },
    });

    if (data) {
      await contract.refetch();
      setOpen(false);
      toast.success('Contract updated');
    }

    if (errors) {
      toast.error('Failed to update contract');
    }
  };

  return {
    contract,
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onSubmit: handleOnSubmit,
    },
  };
};
