import React from 'react';
import { Button } from '@/components/ui/button';
import { TableRenderer } from '@/components/ui/table/table/Table';
import { PdfSheet } from 'lib/shared';
import { useTrainingStaffMemberContext } from '../../context';

const MemberCertificates = () => {
  const { user } = useTrainingStaffMemberContext();

  if (!user) {
    return null;
  }

  return (
    <div className="mb-8 rounded-lg border bg-background px-4 pt-4">
      <div>
        <h3 className="mb-1">Certificates</h3>
        <p className="text-xs dark:text-white/75">
          Training certificates for this user.
        </p>
      </div>
      <div className="flex flex-col gap-y-4">
        <TableRenderer
          // @ts-ignore
          filter="qualification.name"
          columns={[
            {
              id: 'name',
              accessorKey: 'qualification.name',
              header: 'Name',
              cell: ({ row }) => {
                return (
                  <PdfSheet objectId={row.original.sourceObject.id}>
                    <Button size="table" variant="table">
                      {row.original.sourceObject.name}
                    </Button>
                  </PdfSheet>
                );
              },
            },
          ]}
          data={user.trainingCertificates}
          topRight={({ columnsSelector }) => (
            <div className="ml-auto flex gap-x-2">{columnsSelector}</div>
          )}
        />
      </div>
    </div>
  );
};

export default MemberCertificates;
