import React from 'react';
import {
  Tabs as TabsBase,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs';
import { TabsProps } from './types';

const PaperStackTabs = ({ tabs }: TabsProps) => {
  return (
    <div className="h-full rounded-lg border bg-background">
      <TabsBase defaultValue="0" className="flex h-full flex-col">
        <div className="relative">
          <div className="px-4 py-3">
            <TabsList className="mt-0 h-8">
              {tabs.map((tab, index) => (
                <TabsTrigger
                  key={index.toString()}
                  className="group"
                  value={index.toString()}
                >
                  {tab.name}
                </TabsTrigger>
              ))}
            </TabsList>
          </div>
          <div className="absolute inset-y-0 right-0 ml-auto p-4 pt-[14px]">
            {tabs.map((tab, index) => (
              <TabsContent
                asChild
                key={index.toString()}
                value={index.toString()}
              >
                <span>{tab.header}</span>
              </TabsContent>
            ))}
          </div>
        </div>
        {tabs.map((tab, index) => (
          <TabsContent
            key={index.toString()}
            className="mt-0 min-h-0 grow"
            value={index.toString()}
          >
            {tab.render({})}
          </TabsContent>
        ))}
      </TabsBase>
    </div>
  );
};

export default PaperStackTabs;
