import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import parse, { Element, domToReact } from 'html-react-parser';
import {
  FileCheck2Icon,
  FileIcon,
  FileX2Icon,
  TrashIcon,
  UploadIcon,
} from 'lucide-react';
import { DropzoneState } from 'react-dropzone';
import { SizeMe } from 'react-sizeme';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { FormControl } from '@/components/ui/form/form-control/FormControl';
import { FormItem } from '@/components/ui/form/form-item/FormItem';
import { FormLabel } from '@/components/ui/form/form-label/FormLabel';
import { useFormRendererContext } from '@/components/ui/form/form-renderer/FormRenderer';
import { Image } from '@/components/ui/image';
import { Progress } from '@/components/ui/progress';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { cn } from '@/lib/utils';
import DOMPurify from 'dompurify';
import { filesize } from 'filesize';
import {
  PlatformObject,
  PlatformObjectAnnotation,
  PlatformObjectImageData,
  PlatformObjectSources,
} from 'lib/types';
import { useMedia } from '../../../../../shared/Media/hooks';
import {
  useObjectSource,
  useRegisterObjectsOnMount,
} from '../../../../../shared/MediaProvider';
import PdfViewer from '../../../../../shared/PdfViewer';

export const MediaInputImage = ({
  nested = true,
  right = 700,
  upload,
}: {
  nested?: boolean;
  right?: number;
  upload: Omit<PlatformObject, 'sources'>;
}) => {
  const [open, setOpen] = useState(false);
  const sources = useObjectSource(upload);

  const data = useMemo<PlatformObjectImageData>(() => {
    try {
      return JSON.parse(upload.data);
    } catch (error) {
      return { annotations: [] };
    }
  }, [upload.data]);

  const [highlighted, setHighlighted] = useState<string>();

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <button
          className="relative h-12 w-12"
          type="button"
          onClick={() => {
            setOpen(true);
          }}
        >
          <AnnotatedImage
            className="h-12 w-12 rounded-md border object-cover"
            sources={sources}
            annotations={data.annotations}
          />
        </button>
      </SheetTrigger>
      <SheetContent
        className="p-0"
        overlay={!nested}
        style={nested ? { right, zIndex: 49 } : undefined}
      >
        <div className="justify-content-between flex items-center justify-between border-b p-4">
          <p className="text-sm font-bold">{upload.name}</p>
          <div className="mr-8"></div>
        </div>
        <div className="relative min-h-0 p-4">
          <AnnotatedImage
            className="w-full rounded-lg"
            sources={sources}
            annotations={data.annotations}
            highlighted={highlighted}
          />
        </div>
        {(data?.annotations?.length ?? 0) > 0 && (
          <div className="flex flex-col gap-y-2 px-4">
            {data.annotations.map((annotation, index) => {
              return (
                <div
                  key={annotation.id}
                  className="group flex items-center gap-x-2"
                  onMouseEnter={() => setHighlighted(annotation.id)}
                  onMouseLeave={() => setHighlighted(undefined)}
                >
                  <div className="overflow-hidden rounded-lg group-hover:ring-2">
                    <AnnotatedImage
                      className="h-12 w-12 cursor-pointer rounded-lg opacity-10 "
                      annotations={[annotation]}
                      sources={sources}
                      opacity={0.5}
                    />
                  </div>
                  <p className="text-sm">Annotation {index + 1}</p>
                </div>
              );
            })}
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
};

const AnnotatedImage = ({
  className,
  sources,
  annotations = [],
  highlighted,
  opacity,
}: {
  className?: string;
  sources?: PlatformObjectSources;
  annotations?: PlatformObjectAnnotation[];
  highlighted?: string;
  opacity?: number;
}) => {
  const getOpacity = useCallback(
    (id: string) => {
      if (!highlighted) return 1;
      if (highlighted === id) return 1;
      return 0.5;
    },
    [highlighted],
  );

  return (
    <SizeMe monitorWidth monitorHeight>
      {({ size }) => (
        <div>
          <Image
            className={cn(className)}
            src={sources?.full.uri}
            opacity={opacity}
          />
          {size &&
            annotations?.map((annotation, index) => (
              <Annotation
                key={annotation.id}
                className="transition-all"
                style={{
                  zIndex: 100 + index,
                  opacity: getOpacity(annotation.id),
                }}
                width={size.width!}
                height={size.height!}
                annotation={annotation}
              />
            ))}
        </div>
      )}
    </SizeMe>
  );
};

const Annotation = ({
  className,
  style,
  width,
  height,
  annotation,
}: {
  className?: string;
  style?: CSSProperties;
  width: number;
  height: number;
  annotation: PlatformObjectAnnotation;
}) => {
  const safe = DOMPurify.sanitize(annotation.svg);
  const html = parse(safe, {
    replace: (node) => {
      if (!(node instanceof Element)) return;
      if (node.name === 'svg') {
        const scale = annotation.width / width;
        const scaledWidth = annotation.width * scale;
        const scaledHeight = annotation.height * scale;
        const top = -((1 / scale) * annotation.height - height) / 2;
        return (
          <svg
            {...node.attribs}
            width={undefined}
            height={undefined}
            fill="red"
            viewBox={`0 0 ${scaledWidth} ${scaledHeight}`}
            style={{
              pointerEvents: 'none',
              position: 'absolute',
              left: 0,
              top,
              width: annotation.width,
              height: annotation.height,
            }}
          >
            {domToReact(node.children)}
          </svg>
        );
      }
      if (node.name === 'path') {
        return <path {...node.attribs} />;
      }
      return node;
    },
  });

  return (
    <div
      className={cn(
        'pointer-events-none absolute inset-0 overflow-hidden',
        className,
      )}
      style={style}
    >
      {html}
    </div>
  );
};

export const MediaInputDocument = ({
  nested = true,
  right = 700,
  upload,
}: {
  nested?: boolean;
  right?: number;
  upload: Omit<PlatformObject, 'sources'>;
}) => {
  const [open, setOpen] = useState(false);
  const sources = useObjectSource(upload);
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <button
          type="button"
          onClick={() => {
            setOpen(true);
          }}
        >
          <Avatar className="h-12 w-12 rounded-lg">
            <AvatarImage
              src={sources?.thumbnail?.uri}
              className="object-cover"
            />
            <AvatarFallback className="rounded-lg text-gray-400">
              <FileIcon />
            </AvatarFallback>
          </Avatar>
        </button>
      </SheetTrigger>
      <SheetContent
        className="flex w-[700px] max-w-[700px] flex-col gap-0 gap-y-4 p-0 sm:max-w-[700px]"
        overlay={!nested}
        style={nested ? { right, zIndex: 49 } : undefined}
      >
        <div className="h-full w-full">
          <PdfViewer objectId={upload?.id} />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export const MediaInputDropzone = ({
  dropzone,
}: {
  dropzone: DropzoneState;
}) => {
  return (
    <button
      className={cn('block w-full rounded-lg border border-dashed p-8', {
        'border-primary': dropzone.isDragActive,
      })}
      {...dropzone.getRootProps()}
      onClick={(event) => {
        event.preventDefault();
        dropzone.open();
      }}
    >
      <input {...dropzone.getInputProps()} />
      <div className="flex flex-col items-center gap-y-2 text-center">
        {!dropzone.isDragActive && (
          <>
            <div className="content-center">
              <UploadIcon className="h-6 w-6 text-gray-400" />
            </div>
            <p className="text-sm font-semibold">
              Drop your image / document here, or{' '}
              <span className="text-primary">click to browse</span>
            </p>
            <p className="text-grey-400 text-xs">
              Files supported: PNG, JPG, PDF, PPF
            </p>
          </>
        )}
        {dropzone.isDragAccept && (
          <>
            <div>
              <FileCheck2Icon className="h-6 w-6 text-gray-400" />
            </div>
            <p className="text-sm font-semibold">Drop to upload</p>
            <p className="text-grey-400 text-xs">Selected files supported</p>
          </>
        )}
        {dropzone.isDragReject && (
          <>
            <div>
              <FileX2Icon className="h-6 w-6 text-gray-400" />
            </div>
            <p className="text-sm font-semibold">Cannot drop</p>
            <p className="text-grey-400 text-xs">
              At least one selected file is not supported
            </p>
          </>
        )}
      </div>
    </button>
  );
};

export const MediaInputValue = ({
  className,
  value = [],
  right = 700,
  progress = {},
  onChange,
}: {
  className?: string;
  value: PlatformObject[];
  right?: number;
  progress: Record<string, number>;
  onChange: (value: any[]) => void;
}) => {
  return (
    <ScrollArea>
      <div className={cn('mt-4 flex flex-col gap-y-4', className)}>
        {[...value].reverse().map((upload) => {
          const uploadProgress = progress[upload.id];
          return (
            <div key={upload.id} className="rounded-lg border">
              <div className="flex items-center gap-x-4 p-4">
                {upload.type === 'IMAGE' && (
                  <MediaInputImage upload={upload} right={right} />
                )}
                {upload.type !== 'IMAGE' && (
                  <MediaInputDocument upload={upload} right={right} />
                )}
                <div className="grow">
                  <p className="text-sm font-semibold">{upload.name}</p>
                  <div className="mt-2 flex gap-x-2">
                    <p className="text-xs uppercase">
                      {upload.name.split('.').at(-1)}
                    </p>
                    <div className="py-1">
                      <Separator orientation="vertical" />
                    </div>
                    {!!uploadProgress && uploadProgress !== 100 ? (
                      <p className="text-xs text-primary">{uploadProgress}%</p>
                    ) : (
                      <p className="text-xs">{filesize(upload.size ?? 0)}</p>
                    )}
                  </div>
                </div>
                <div>
                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <Button variant="outline" className="h-8 w-8 p-0">
                        <TrashIcon className="h-4 w-4" />
                      </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>
                          Are you absolutely sure?
                        </AlertDialogTitle>
                        <AlertDialogDescription>
                          This action cannot be undone. This will permanently
                          delete your account and remove your data from our
                          servers.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction
                          onClick={() => {
                            onChange(
                              value.filter(({ id }) => id !== upload.id),
                            );
                          }}
                        >
                          Continue
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </div>
              </div>
              {!!uploadProgress && uploadProgress !== 100 && (
                <div className="px-4 pb-4">
                  <Progress
                    className="h-1 rounded-full"
                    value={uploadProgress}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </ScrollArea>
  );
};

export const MediaInput = ({
  asControl = false,
  value = [],
  maxFiles,
  right = 700,
  onChange,
}: {
  asControl?: boolean;
  value: PlatformObject[];
  upload?: File[];
  maxFiles?: number;
  right?: number;
  onChange: (value: any[]) => void;
}) => {
  const disabled = maxFiles && value.length >= maxFiles;
  useRegisterObjectsOnMount(value);
  const [progress, setProgress] = useState<Record<string, number>>({});
  const { enable, disable } = useFormRendererContext();
  const { dropzone } = useMedia({
    onCreated: (objects) => {
      disable();
      onChange([...value, ...objects]);
      setProgress((prevProgress) =>
        objects.reduce((acc, object) => {
          acc[object.id] = 0;
          return acc;
        }, prevProgress),
      );
    },
    onUploadProgress: (id, progress) => {
      setProgress((prevProgress) => ({
        ...prevProgress,
        [id]: progress,
      }));
    },
    onUploaded: (id) => {
      setProgress((prevProgress) => ({
        ...prevProgress,
        [id]: 100,
      }));
    },
    onCompleted: () => {
      enable();
    },
  });

  const control = (
    <div>
      {!disabled && value.length < (maxFiles ?? Infinity) && (
        <MediaInputDropzone dropzone={dropzone} />
      )}
      {value.length > 0 && (
        <MediaInputValue
          className={cn({ 'mt-0': disabled })}
          value={value}
          right={right}
          progress={progress}
          onChange={onChange}
        />
      )}
    </div>
  );

  if (asControl) {
    return <>{control}</>;
  }

  return (
    <FormItem className="flex grow flex-col gap-y-2">
      <FormLabel>Attached file{maxFiles === 1 ? '' : 's'}</FormLabel>
      <FormControl>{control}</FormControl>
    </FormItem>
  );
};
