import { useCallback, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { JobPriority } from 'lib/types';

export const usePriorityEditor = (
  id: string | undefined,
  onSuccess: () => void,
) => {
  const [open, setOpen] = useState(false);

  const { data, loading, refetch } = useQuery<{ jobPriority: JobPriority }>(
    gql`
      query GetJobPriority($id: ID!) {
        jobPriority(jobPriorityId: $id) {
          id
          color
          reference
          responseName
          responseNameShort
          responseDuration
          completionName
          completionNameShort
          completionDuration
          durationSpace
        }
      }
    `,
    {
      skip: !id,
      fetchPolicy: 'no-cache',
      variables: { id },
    },
  );

  const handleSheetOnOpenChange = useCallback(
    (nextOpen: boolean) => {
      setOpen(nextOpen);
      if (nextOpen && id) {
        refetch();
      }
    },
    [id, refetch],
  );

  const [create, { loading: creating }] = useMutation(gql`
    mutation CreateJobPriority($clientId: ID!, $data: JobPriorityInput!) {
      createJobPriority(clientId: $clientId, jobPriorityData: $data) {
        jobPriority {
          id
        }
      }
    }
  `);

  const [update, { loading: updating }] = useMutation(gql`
    mutation UpdateJobPriority($id: ID!, $data: JobPriorityInput!) {
      updateJobPriority(jobPriorityId: $id, jobPriorityData: $data) {
        jobPriority {
          id
        }
      }
    }
  `);

  return {
    priority: data?.jobPriority
      ? {
          ...data.jobPriority,
          refetch,
        }
      : undefined,
    sheet: {
      open,
      onOpenChange: handleSheetOnOpenChange,
    },
    state: {
      loading,
      creating,
      updating,
    },
    handlers: {
      create,
      update,
    },
  };
};
