import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { RendererField } from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRendererSubmitHandler } from '@/components/ui/form/form-renderer/FormRenderer';
import { gql, useMutation } from '@apollo/client';
import { z } from 'zod';
import { useClient } from '../../../../hooks';
import { CreateSiteMutationData, CreateSiteMutationVariables } from './types';

export const useSitesCreate = () => {
  const navigate = useNavigate();

  const client = useClient();
  const fields = useMemo<RendererField[]>(
    () => [
      {
        type: 'input' as const,
        name: 'reference',
        label: 'Reference',
        schema: z.string(),
      },
    ],
    [],
  );

  const [create] = useMutation<
    CreateSiteMutationData,
    CreateSiteMutationVariables
  >(gql`
    mutation CreateSite($clientId: ID!, $siteReference: String!) {
      createSite(clientId: $clientId, siteReference: $siteReference) {
        site {
          id
          reference
        }
      }
    }
  `);

  const handleOnSubmit: FormRendererSubmitHandler<typeof fields> = async (
    values,
  ) => {
    const { data } = await create({
      variables: {
        clientId: client.id,
        siteReference: values.reference,
      },
    });

    if (data) {
      navigate(client.paths.sites.site(data.createSite.site));
    }
  };

  return {
    fields,
    onSubmit: handleOnSubmit,
  };
};
