import React from 'react';
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Loader } from '@/components/ui/loading';
import {
  NavigationMenuContent,
  NavigationMenuItem,
} from '@/components/ui/navigation-menu';
import { useUser } from 'lib/hooks';
import UserPhoto from '../../../../../../shared/UserPhoto';

const MenuProfile = () => {
  const user = useUser();
  return (
    <NavigationMenuItem className="h-9">
      <NavigationMenuPrimitive.Trigger
        asChild
        onPointerMove={(event) => event.preventDefault()}
        onPointerLeave={(event) => event.preventDefault()}
      >
        <Button
          variant="ghost"
          size="icon"
          className="items-center rounded-full p-0"
        >
          <UserPhoto className="size-8" user={user}>
            <Avatar className="h-9 w-9">
              <AvatarFallback className="rounded-md bg-primary/25 font-bold text-primary dark:text-primary">
                {user ? user?.name?.[0] : <Loader />}
              </AvatarFallback>
            </Avatar>
          </UserPhoto>
        </Button>
      </NavigationMenuPrimitive.Trigger>
      <NavigationMenuContent>
        <div className="w-[500px]">
          <div className="flex items-center gap-x-4 p-4 font-normal">
            <UserPhoto user={user} className="size-12">
              <Avatar className="h-12 w-12">
                <AvatarFallback className="rounded-md bg-primary/25 font-bold text-primary dark:text-primary">
                  {user?.name?.[0]}
                </AvatarFallback>
              </Avatar>
            </UserPhoto>
            <div className="flex flex-col space-y-2">
              <p className="text-md font-medium leading-none">{user?.name}</p>
              <p className="text-xs leading-none text-muted-foreground">
                {user?.email}
              </p>
            </div>
          </div>
        </div>
      </NavigationMenuContent>
    </NavigationMenuItem>
  );
};

export default MenuProfile;
