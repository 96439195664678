import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { RendererField } from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRendererSubmitHandler } from '@/components/ui/form/form-renderer/FormRenderer';
import { gql, useMutation } from '@apollo/client';
import { z } from 'zod';
import { useClient } from '../../../../hooks';
import { CreateAssetMutationData, CreateAssetMutationVariables } from './types';

export const useAssetsCreate = () => {
  const navigate = useNavigate();

  const client = useClient();
  const fields = useMemo<RendererField[]>(
    () => [
      {
        type: 'input' as const,
        name: 'reference',
        label: 'Reference',
        schema: z.string(),
      },
    ],
    [],
  );

  const [create] = useMutation<
    CreateAssetMutationData,
    CreateAssetMutationVariables
  >(gql`
    mutation CreateAsset($clientId: ID!, $reference: String!) {
      createAsset(clientId: $clientId, assetReference: $reference) {
        asset {
          id
          reference
        }
      }
    }
  `);

  const handleOnSubmit: FormRendererSubmitHandler<typeof fields> = async (
    values,
  ) => {
    const { data } = await create({
      variables: {
        clientId: client.id,
        reference: values.reference,
      },
    });

    if (data) {
      navigate(client.paths.assets.asset(data.createAsset.asset));
    }
  };

  return {
    fields,
    onSubmit: handleOnSubmit,
  };
};
