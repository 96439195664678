import React from 'react';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { TableRenderer } from '@/components/ui/table/table/Table';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'lib/shared';
import { useClient } from '../../hooks';
import SitesCreate from './components/SitesCreate';
import { ClientSitesQueryData, ClientSitesQueryVariables } from './types';

const ClientSites = () => {
  const { id, paths } = useClient();
  const { data: { sites } = { sites: [] } } = useQuery<
    ClientSitesQueryData,
    ClientSitesQueryVariables
  >(
    gql`
      query GetSites($clientId: ID!) {
        sites(clientId: $clientId) {
          id
          reference
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      variables: { clientId: id },
    },
  );

  return (
    <div className="flex h-full min-h-0 flex-col">
      <div className="flex h-[52px] flex-shrink-0 items-center border-b px-4 py-2">
        <h1 className="text-xl font-bold">Sites</h1>
      </div>
      <div className="min-h-0 flex-grow">
        <ScrollArea className="h-full min-h-0 px-4">
          <TableRenderer
            filter="reference"
            columns={[
              {
                accessorKey: 'reference',
                header: 'Reference',
                cell: ({ row, renderValue }) => (
                  <Link to={paths.sites.site(row.original)}>
                    <Button size="sm" variant="link" className="h-4 p-0">
                      {renderValue() as string}
                    </Button>
                  </Link>
                ),
              },
            ]}
            data={sites}
            topRight={({ columnsSelector }) => (
              <div className="ml-auto space-x-2">
                <SitesCreate />
                {columnsSelector}
              </div>
            )}
          />
        </ScrollArea>
      </div>
    </div>
  );
};

export default ClientSites;
