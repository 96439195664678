import { gql, useMutation } from '@apollo/client';
import produce from 'immer';
import { RosterDuty } from 'lib/types';
import { RosterReviewState, useStore } from '../../state';
import {
  ApproveRosterMutationData,
  ApproveRosterMutationVariables,
} from './types';

export const useApproveRoster = () => {
  const { rosterTeam, apply } = useStore((state) => ({
    rosterTeam: state.rosterTeam,
    rosterDuties: state.rosterDuties,
    apply: state.apply,
  }));

  const [approveRoster, { loading: isApproving }] = useMutation<
    ApproveRosterMutationData,
    ApproveRosterMutationVariables
  >(gql`
    mutation ($rosterDutyIds: [ID!]!, $rosterTeamId: ID!) {
      approveRoster(
        rosterDutyIds: $rosterDutyIds
        rosterTeamId: $rosterTeamId
      ) {
        rosterDuties {
          id
          status
          team {
            id
          }
          user {
            id
            name
            initials
          }
        }
        deletedIds
      }
    }
  `);

  const handleOnSubmit = async (duties: string[]) => {
    if (!rosterTeam) return;

    const { data } = await approveRoster({
      variables: {
        rosterTeamId: rosterTeam.id,
        rosterDutyIds: duties,
      },
    });

    const handleUpdateRosterDuties = (
      state: RosterReviewState,
      initialRosterDuties: RosterDuty[],
      updatedRosterDuties: RosterDuty[],
      deletedIds: string[],
    ) => {
      return initialRosterDuties
        .filter((rosterDuty) => !deletedIds.includes(rosterDuty.id))
        .map((rosterDuty) =>
          updatedRosterDuties.find(({ id }) => id === rosterDuty.id)
            ? {
                ...rosterDuty,
                ...updatedRosterDuties.find(({ id }) => id === rosterDuty.id),
              }
            : rosterDuty,
        );
    };

    if (data) {
      const {
        approveRoster: { rosterDuties: updatedRosterDuties, deletedIds },
      } = data;

      apply(
        produce((state) => {
          // Apply updates to the roster duties in the table
          // view, visually updating the shift chips.
          state.rosterDuties = handleUpdateRosterDuties(
            state,
            state.rosterDuties,
            updatedRosterDuties,
            deletedIds,
          );
        }),
      );
    }
  };

  return { onSubmit: handleOnSubmit, isApproving };
};
