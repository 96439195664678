import { useQuery } from '@apollo/client';
import { useMatch } from 'lib/hooks';
import { useJobContext } from '../../context';
import { GET_JOB_TASK_QUERY } from './query';
import { JobTaskQueryData, JobTaskQueryVariables } from './types';

export const useJobTask = () => {
  const { job } = useJobContext();

  const match = useMatch(
    '/clients/:clientSlug/jobs/:jobReference/tasks/:jobTaskNumber/*',
  );

  const { data, refetch } = useQuery<JobTaskQueryData, JobTaskQueryVariables>(
    GET_JOB_TASK_QUERY,
    {
      skip: job.partial,
      variables: job.partial
        ? undefined
        : {
            jobId: job.id,
            jobTaskNumber: parseInt(match?.params?.jobTaskNumber as string, 10),
          },
    },
  );

  if (!data?.job.task) {
    return {
      context: {
        jobTask: null,
        task: null,
      },
    };
  }

  return {
    context: {
      jobTask: {
        ...data.job.task,
        refetch,
      },
      task: {
        ...data.job.task,
        refetch,
      },
    },
  };
};
