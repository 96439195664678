import React from 'react';
import TodoCard from '../TodoCard';
import TodoCardGroup from '../TodoCardGroup';
import { TodoCardListProps } from './types';

const TodoCardList = ({
  selected,
  groups,
  todoOnClick,
  todoOnClose,
}: TodoCardListProps) => {
  return (
    <div>
      {groups.map((group) => {
        return (
          <TodoCardGroup
            key={group.scope}
            group={group}
            selected={selected}
            todoOnClick={todoOnClick}
            todoOnClose={todoOnClose}
          />
        );
      })}
    </div>
  );
};

export default TodoCardList;
