import React from 'react';
import { useLocation } from 'react-router';
import { AuthErrors } from 'lib/errors';
import AppLoginLayout from '../AppLoginLayout';
import { AppLoginErrorMessageProps } from './types';

const AppLoginErrorMessage = ({ errorType }: AppLoginErrorMessageProps) => {
  if (errorType === AuthErrors.AUTH_USER_NOT_CONFIGURED) {
    return <p>This account has not been given access to mchugh.day.</p>;
  }

  return null;
};

const AppLoginError = ({}) => {
  const location = useLocation();
  const errorType: AuthErrors = location.state.error;

  return (
    <AppLoginLayout>
      <p>There was an issue</p>
      <AppLoginErrorMessage errorType={errorType} />
    </AppLoginLayout>
  );
};

export default AppLoginError;
