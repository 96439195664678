import React from 'react';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@xyflow/react/dist/style.css';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { ThemeProvider } from '@/components/ui/theme';
import AppInner from '../AppInner';
import AppLogin from '../AppLogin';
import AppLoginError from '../AppLogin/components/AppLoginError';
import AppLoginRedirect from '../AppLogin/components/AppLoginRedirect';
import AppLoginRequest from '../AppLogin/components/AppLoginRequest';
import { msalApplication } from '../hooks';

pdfjs.GlobalWorkerOptions.workerSrc =
  '//unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window['__@hello-pangea/dnd-disable-dev-warnings'] = true;

const App = () => {
  return (
    <MsalProvider instance={msalApplication}>
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>
    </MsalProvider>
  );
};

const RequireAuth = ({ children }: React.PropsWithChildren) => {
  return (
    <>
      <UnauthenticatedTemplate>
        <Navigate to="/login" />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
    </>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/login">
        <Route index element={<AppLogin />} />
        <Route path="error" element={<AppLoginError />} />
        <Route path="redirect" element={<AppLoginRedirect />} />
        <Route path="request" element={<AppLoginRequest />} />
      </Route>
      <Route
        path="/*"
        element={
          <RequireAuth>
            <AppInner />
          </RequireAuth>
        }
      />
    </Route>,
  ),
);

export default App;
