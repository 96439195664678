import { DateTime } from 'luxon';
import { PlatformObject } from './platform';
import { User, UserGroup } from './users';

export type RosterDuty = {
  id: string;
  commuteTime: number;
  dateTimeStart: string;
  dateTimeEnd: string;
  shiftDuration: number;
  shiftType: {
    label: string;
    value: string;
  };
  status: string;
  fatigueIndex: number;
  riskIndex: number;
  user: User;
  settings: any;
  isDeleted: boolean;
  isPristine: boolean;
  isSettingsPristine: boolean;
  team: RosterTeam;
};

export type RosterExceedanceReview = {
  id: string;
  type: string;
  duty: RosterDuty;
  note: string;
  files: PlatformObject[];
};

export type RosterExceedanceType = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';

export type RosterExceedance = {
  offence: RosterExceedanceType;
  offenceValueType: 'DurationField' | 'FloatField';
  offenceValue: string | number;
  duty: RosterDuty;
  review: RosterExceedanceReview;
};

export type RosterTeam = {
  id: string;
  name: string;
  userGroupIds: UserGroup['id'][];
  membersGroup: UserGroup;
  creatorsGroup: UserGroup;
  approversGroup: UserGroup;
  adminsGroup: UserGroup;
  defaults: any;
  requiresReviewInDateRange: RosterDuty[];
};

export type UnpreparedDateTimes<T, S extends keyof T, E extends keyof T> = Omit<
  T,
  S | E
> & {
  [P in S | E]: string;
};

export type RosterReview = {
  id: string;
  team: RosterTeam;
  creator: User;
  approver: User;
  rangeDateTimeStart: DateTime;
  rangeDateTimeEnd: DateTime;
  duties: RosterDuty[];
  meta: {
    approvedUsers: User['id'][];
  };
};

export type RosterStage = 'CREATE' | 'APPROVE' | 'LIVE';
