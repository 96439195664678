import { Button } from '@/components/ui/button';

const ContractDelete = () => {
  return (
    <Button size="sm" variant="destructive" disabled>
      Delete contract
    </Button>
  );
};

export default ContractDelete;
