import produce from 'immer';
import { DateTime } from 'luxon';
import { StateCreator } from 'zustand';
import { RosterDuty, RosterTeam, User } from 'lib/types';
import {
  CreateRosterDutyMutationData,
  DeleteRosterDutyMutationData,
  RosterValidationResults,
  UpdateRosterDutyMutationData,
} from '../RosterDutyRow/types';

export type RosterDutyTableValues = {
  dateTimeStart: DateTime;
  dateTimeEnd: DateTime;

  user: User | null;
  stage: number;

  rosterTeam: RosterTeam | null;
  rosterDuties: RosterDuty[];
  rosterDutiesInMonth: RosterDuty[];
  rosterValidationResults: RosterValidationResults;
};

export type RosterDutyTableActions = {
  onCreateRosterDuty: (data: CreateRosterDutyMutationData) => void;
  onUpdateRosterDuty: <F extends keyof RosterDuty = any>(
    rosterDuty: RosterDuty,
    field: F,
    fieldValue: RosterDuty[F],
  ) => void;
  onUpdateRosterCompleted: (data: UpdateRosterDutyMutationData) => void;
  onDeleteRosterDuty: (data: DeleteRosterDutyMutationData) => void;
  updateValidationResults: (validationResults: RosterValidationResults) => void;
};

export type RosterDutyTableState = RosterDutyTableValues &
  RosterDutyTableActions;

const initialValues: RosterDutyTableValues = {
  dateTimeStart: DateTime.local(),
  dateTimeEnd: DateTime.local(),

  user: null,
  stage: -1,

  rosterTeam: null,
  rosterDuties: [],
  rosterDutiesInMonth: [],
  rosterValidationResults: {
    fatigueIndices: {},
    riskIndices: {},
    exceedances: [],
  },
};

export const createRosterDutyTableSlice: StateCreator<RosterDutyTableState> = (
  set,
) => ({
  ...initialValues,

  onCreateRosterDuty: ({ createRosterDuty }) =>
    set(
      produce<RosterDutyTableState>((state) => {
        state.rosterDuties.push(createRosterDuty.rosterDuty);
        state.rosterDutiesInMonth.push(createRosterDuty.rosterDuty);
        state.rosterValidationResults = createRosterDuty.validationResults;
      }),
    ),

  onUpdateRosterDuty: (rosterDuty, field, fieldValue) =>
    set(
      produce<RosterDutyTableState>((state) => {
        const toUpdate = state.rosterDuties.find(
          ({ id }) => id === rosterDuty.id,
        );
        if (!toUpdate) return state;
        if (field in toUpdate) toUpdate[field] = fieldValue;
      }),
    ),

  onUpdateRosterCompleted: ({
    updateRosterDuty: { rosterDuty: rosterDutyData, validationResults },
  }) =>
    set(
      produce<RosterDutyTableState>((state) => {
        state.rosterValidationResults = validationResults;
        state.rosterDuties = state.rosterDuties.map((rosterDuty) =>
          rosterDuty.id === rosterDutyData.id
            ? { ...rosterDuty, ...rosterDutyData }
            : rosterDuty,
        );
      }),
    ),

  onDeleteRosterDuty: ({ deleteRosterDuty }) =>
    set(
      produce<RosterDutyTableState>((state) => {
        if (deleteRosterDuty.isDeleted) {
          state.rosterDuties = state.rosterDuties.filter(
            ({ id }) => id !== deleteRosterDuty.deletedId,
          );
          state.rosterDutiesInMonth = state.rosterDutiesInMonth.filter(
            ({ id }) => id !== deleteRosterDuty.deletedId,
          );
        } else {
          state.rosterDuties = state.rosterDuties.map((rosterDuty) =>
            rosterDuty.id === deleteRosterDuty.rosterDuty?.id
              ? { ...rosterDuty, ...deleteRosterDuty.rosterDuty }
              : rosterDuty,
          );
        }
        state.rosterValidationResults = deleteRosterDuty.validationResults;
      }),
    ),

  updateValidationResults: (validationResults) =>
    set(
      produce<RosterDutyTableState>((state) => {
        state.rosterValidationResults = validationResults;
      }),
    ),
});
