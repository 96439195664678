import React from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useYjs } from 'lib/hooks';
import { PageLoader, WindowManager } from 'lib/shared';
import { CommercialType } from 'lib/types';
import { useJobContext } from '../../../../context';
import ClientJob from '../../../../index';
import { useCommercialEstimatesContext } from '../CommercialEstimates/context';
import CommercialSelector from '../CommercialSelector';
import EstimateFooter from './components/EstimateFooter';
import EstimateHeader from './components/EstimateHeader';
import EstimatePrelims from './components/EstimatePrelims';
import EstimateQuote from './components/EstimateQuote';
import EstimateTable from './components/EstimateTable';
import {
  CommercialEstimateContext,
  useCommercialEstimateContext,
} from './context';
import { useCommercialEstimate } from './hooks';
import { CommercialEstimateProps, ViewMode } from './types';

const CommercialEstimate = ({ id }: CommercialEstimateProps) => {
  const { job } = useJobContext();

  const { context } = useCommercialEstimate(id);
  const { doc, store } = useYjs(
    'commercial::commercial-estimate',
    `${id}::edit`,
    { order: [] } as { order: string[] },
    { debugName: `${job.reference} commercial estimate` },
  );

  if (!context.estimate || !store || !doc?.current) {
    return <PageLoader className="p-4">Loading estimate...</PageLoader>;
  }

  return (
    <CommercialEstimateContext.Provider
      value={{
        ...context,
        store,
        doc: doc.current,
      }}
    >
      <CommercialEstimateRenderer />
    </CommercialEstimateContext.Provider>
  );
};

const CommercialEstimateRenderer = () => {
  const { job } = useJobContext();
  const { mode } = useCommercialEstimatesContext();
  const { sections } = useCommercialEstimateContext();

  if (job.partial) return null;

  if (mode.value === ViewMode.Split) {
    return (
      <>
        <div className="grid min-h-0 grow grid-cols-12 bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-background">
          <div className="relative col-span-7 flex h-full min-h-0 flex-col">
            <ScrollArea className="min-h-0 grow px-4" silent>
              <div className="flex flex-col gap-y-4 py-4 pb-16">
                <EstimateHeader />
                <EstimatePrelims />
                <EstimateTable type={CommercialType.Labor} color="#ffa600" />
                <EstimateTable type={CommercialType.Material} color="#ffa600" />
                <EstimateTable
                  type={CommercialType.Equipment}
                  color="#ffa600"
                />
                <EstimateTable
                  type={CommercialType.Subcontractor}
                  color="#ffa600"
                />
                <CommercialSelector sections={sections} />
              </div>
            </ScrollArea>
            <EstimateFooter />
          </div>
          <div className="col-span-5 h-full min-h-0 pl-0">
            <EstimateQuote />
          </div>
        </div>
      </>
    );
  }

  if (mode.value === ViewMode.TwoColumn) {
    return (
      <>
        <div className="grid min-h-0 grow grid-cols-12">
          <div className="relative col-span-7 flex h-full min-h-0 flex-col bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-background">
            <ScrollArea className="min-h-0 grow">
              <div className="flex flex-col gap-y-4 px-4 py-4">
                <EstimateHeader />
                <EstimatePrelims />
                <EstimateTable type={CommercialType.Labor} color="#ffa600" />
                <EstimateTable type={CommercialType.Material} color="#ffa600" />
                <EstimateTable
                  type={CommercialType.Equipment}
                  color="#ffa600"
                />
                <EstimateTable
                  type={CommercialType.Subcontractor}
                  color="#ffa600"
                />
                <CommercialSelector sections={sections} />
              </div>
            </ScrollArea>
            <EstimateFooter />
          </div>
          <div className="col-span-5 flex min-h-0 flex-col border-l bg-background">
            <WindowManager
              initialPath={`/clients/${job.client.slug}/jobs/${job.reference}`}
            >
              <ClientJob id={job.id} small />
            </WindowManager>
          </div>
        </div>
      </>
    );
  }

  return null;
};

export default CommercialEstimate;
