import { useMatch } from 'react-router';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'sonner';
import { useStore } from '../HumanResourcesRosterTeams/state';
import {
  RosterTeamQueryData,
  RosterTeamQueryVariables,
  UpdateRosterTeamMutationData,
  UpdateRosterTeamMutationVariables,
} from './types';

export const useRosterTeam = () => {
  const match = useMatch('/hr/rosters/teams/:id');

  const {
    rosterTeam,
    rosterTeamQueryOnCompleted,
    updateRosterTeamOnCompleted,
  } = useStore((state) => ({
    rosterTeam: state.rosterTeam,
    rosterTeamQueryOnCompleted: state.rosterTeamQueryOnCompleted,
    updateRosterTeamOnCompleted: state.updateRosterTeamOnCompleted,
  }));

  const { loading: isLoading } = useQuery<
    RosterTeamQueryData,
    RosterTeamQueryVariables
  >(
    gql`
      query GetRosterTeam($rosterTeamId: ID!) {
        rosterTeam(rosterTeamId: $rosterTeamId) {
          id
          name
          userGroupIds
          defaults
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      variables: { rosterTeamId: match?.params?.id as string },
      onCompleted: rosterTeamQueryOnCompleted,
    },
  );

  const [updateTeamDefaults, { loading: isUpdating }] = useMutation<
    UpdateRosterTeamMutationData,
    UpdateRosterTeamMutationVariables
  >(gql`
    mutation UpdateRosterTeam(
      $rosterTeamId: ID!
      $field: String!
      $fieldValue: JSONScalarInput!
    ) {
      updateRosterTeam(
        rosterTeamId: $rosterTeamId
        field: $field
        fieldValue: $fieldValue
      ) {
        rosterTeam {
          id
          defaults
        }
      }
    }
  `);

  const handleOnSubmit = async (values: any) => {
    if (!rosterTeam) return;

    const { data } = await updateTeamDefaults({
      variables: {
        rosterTeamId: rosterTeam.id,
        field: 'defaults',
        fieldValue: values,
      },
    });

    if (data) {
      updateRosterTeamOnCompleted(data);
      toast.success('Successfully updated team defaults');
    }
  };

  return { onSubmit: handleOnSubmit, isLoading, isUpdating };
};
