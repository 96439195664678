import React from 'react';
import { useJobContext } from '../../../../../../context';

const LocationAddress = () => {
  const { job } = useJobContext();

  if (job.partial) return null;

  return (
    <table className="pointer-events-auto">
      <tbody>
        <tr>
          <td className="pr-2 text-gray-500 dark:text-gray-400">Line one</td>
          <td>{job.site.location?.address?.lineOne}</td>
        </tr>
        <tr>
          <td className="pr-2 text-gray-500 dark:text-gray-400">Line two</td>
          <td>{job.site.location?.address?.lineTwo}</td>
        </tr>
        <tr>
          <td className="pr-2 text-gray-500 dark:text-gray-400">City/Town</td>
          <td>{job.site.location?.address?.city}</td>
        </tr>
        <tr>
          <td className="pr-2 text-gray-500 dark:text-gray-400">Postcode</td>
          <td>{job.site.location?.address?.postcode}</td>
        </tr>
        <tr>
          <td className="pr-2 text-gray-500 dark:text-gray-400">Country</td>
          <td>{job.site.location?.address?.country}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default LocationAddress;
