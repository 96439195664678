import React, { useMemo } from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Badge } from '@/components/ui/badge';
import { DateTime, Interval } from 'luxon';
import { TimesheetDuty } from 'lib/types';
import { useJobTaskContext } from '../../context';
import StaffMemberActivity from './components/AssignmentRowStaffMember/components/StaffMemberActivity';
import SubcontractorActivity from './components/AssignmentRowSubcontractor/components/SubcontractorActivity';
import { getTimesheetDutyColor } from './utils';

export const useTable = () => {
  const { task } = useJobTaskContext();

  const columns = useMemo<ColumnDef<TimesheetDuty>[]>(
    () => [
      {
        header: 'Principal',
        cell: ({ row }) => {
          if (row.original.user) {
            return (
              <div>
                <StaffMemberActivity duty={row.original} />
              </div>
            );
          }
          if (row.original.subcontractor) {
            return (
              <div>
                <SubcontractorActivity duty={row.original} />
              </div>
            );
          }
        },
      },
      {
        header: 'Assigned',
        cell: ({ row }) => {
          const dateTime = DateTime.fromISO(row.original.dateTimeCreated);
          const dateTimeString = dateTime.toLocaleString(DateTime.DATETIME_MED);
          return <p className="whitespace-nowrap text-xs">{dateTimeString}</p>;
        },
      },
      {
        header: 'Planned start',
        cell: ({ row }) => {
          if (!row.original.dateTimeStart) {
            return (
              <p className="whitespace-nowrap text-xs font-semibold">
                Not defined
              </p>
            );
          }
          const dateTime = DateTime.fromISO(row.original.dateTimeStart);
          const dateTimeString = dateTime.toLocaleString(DateTime.DATETIME_MED);
          return (
            <p className="whitespace-nowrap text-xs font-semibold">
              {dateTimeString}
            </p>
          );
        },
      },
      {
        header: 'Planned end',
        cell: ({ row }) => {
          if (!row.original.dateTimeStart) {
            return (
              <p className="whitespace-nowrap text-xs font-semibold">
                Not defined
              </p>
            );
          }
          const dateTime = DateTime.fromISO(row.original.dateTimeEnd);
          const dateTimeString = dateTime.toLocaleString(DateTime.DATETIME_MED);
          return (
            <p className="whitespace-nowrap text-xs font-semibold">
              {dateTimeString}
            </p>
          );
        },
      },
      {
        header: 'Planned duration',
        cell: ({ row }) => {
          if (!row.original.dateTimeEnd) {
            return <p className="whitespace-nowrap text-xs font-semibold">-</p>;
          }
          const interval = Interval.fromDateTimes(
            DateTime.fromISO(row.original.dateTimeStart),
            DateTime.fromISO(row.original.dateTimeEnd),
          );
          const duration = interval.toDuration();
          const durationString = duration.as('minutes');
          return (
            <p className="whitespace-nowrap text-xs font-semibold">
              {durationString.toFixed(0)} mins
            </p>
          );
        },
      },
      {
        header: 'Status',
        cell: ({ row }) => (
          <Badge
            className="capitalize"
            variant="outline"
            style={{
              borderColor: getTimesheetDutyColor(row.original).alpha(0.5).hex(),
              color: getTimesheetDutyColor(row.original).hex(),
            }}
          >
            {row.original.status.toLowerCase()}
          </Badge>
        ),
      },
    ],
    [],
  );

  const table = useReactTable({
    data: task?.timesheetActivity.duties ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return { table, columns };
};
