import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import App from './app/App';
import { msalApplication } from './app/hooks';
import './globals.css';
import './index.css';
import './polyfill';

msalApplication.initialize().then(() => {
  const accounts = msalApplication.getAllAccounts();
  if (accounts.length > 0) {
    msalApplication.setActiveAccount(accounts[0]);
  }

  msalApplication.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalApplication.setActiveAccount(account);
    }
  });

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <App />,
  );
});
