import React from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { useYjs } from 'lib/hooks';
import { FormStateProps, TargetProps } from './types';

export const Target = ({ store, children }: TargetProps) => {
  const { values, objects, meta } = useSyncedStore(store);
  return children({ values, objects, meta, store });
};

const FormState = ({ id, scope, children, loader = null }: FormStateProps) => {
  const { store, isReady } = useYjs(
    scope,
    id,
    {
      values: {},
      objects: {},
      meta: {},
    },
    {},
  );

  if (!store || !isReady) return loader;

  return <Target store={store} children={children} />;
};

export default FormState;
