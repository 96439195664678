import { useLocation } from 'react-router';
import { matchPath } from 'react-router-dom';
import { useWindowManager } from '../shared/WindowManager/hooks';

const useRouteMatch = (patterns: readonly string[]) => {
  const { windowed, path } = useWindowManager();
  const { pathname } = useLocation();
  const currentPath = windowed ? path : pathname;
  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, currentPath);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }
  return null;
};

export default useRouteMatch;
