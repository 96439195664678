import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DateTime, Interval } from 'luxon';
import { TimelineRendererProps } from './types';

export const useTimelineRenderer = ({
  scale = 1,
  count = 25,
  jobs: jobsUnsorted,
  dateTimeStart,
  dateTimeEnd,
}: TimelineRendererProps) => {
  const gridRef = useRef<HTMLDivElement>(null);
  const cellRef = useRef<HTMLDivElement>(null);
  const nowRef = useRef<HTMLDivElement>(null);

  const [now, setNow] = useState(DateTime.now());
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(DateTime.now());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const jobs = useMemo(() => {
    const targets = jobsUnsorted
      .flatMap((job) =>
        job.timeline.targets.map((target) => ({ ...target, jobId: job.id })),
      )
      .map((target) => ({
        ...target,
        dateTime: DateTime.fromISO(target.dateTime),
      }));

    targets.sort((a, b) => {
      return (
        Math.abs(a.dateTime.diff(now).as('seconds')) -
        Math.abs(b.dateTime.diff(now).as('seconds'))
      );
    });

    const jobIds = targets
      .map((target) => target.jobId)
      .filter((v, i, a) => a.indexOf(v) === i);

    return jobIds
      .map((jobId) => {
        const job = jobsUnsorted.find((job) => job.id === jobId);
        if (!job) {
          throw new Error('Job not found');
        }
        return job;
      })
      .slice(0, count);
  }, [now, jobsUnsorted]);

  useEffect(() => {
    if (nowRef.current) {
      nowRef.current.scrollIntoView({
        behavior: 'instant',
        inline: 'center',
      });
    }
  }, []);

  useEffect(() => {
    const grid = gridRef.current;
    const cell = cellRef.current;
    if (grid && cell) {
      cell.addEventListener('scroll', () => {
        grid.scrollTop = cell.scrollTop;
      });
    }
    return () => {
      if (grid && cell) {
        cell.removeEventListener('scroll', () => {
          grid.scrollLeft = cell.scrollLeft;
        });
      }
    };
  }, []);

  const interval = useMemo(() => {
    const interval = Interval.fromDateTimes(dateTimeStart, dateTimeEnd);
    if (!interval.isValid) {
      throw new Error('Invalid interval');
    }
    return interval;
  }, [dateTimeStart, dateTimeEnd]);

  const getHorizontalPosition = useCallback(
    <Percentage extends boolean = true>(
      dateTime: DateTime,
      percentage: Percentage = true as Percentage,
    ) => {
      const diff = dateTime.diff(dateTimeStart);
      const ret = (diff.as('seconds') / interval.length('seconds')) * 100;
      return (percentage ? `${ret}%` : ret) as Percentage extends true
        ? string
        : number;
    },
    [dateTimeStart, interval],
  );

  const getHorizontalWidth = useCallback(
    <Percentage extends boolean = true>(
      dateTimeFrom: DateTime,
      dateTimeTo: DateTime,
      percentage: Percentage = true as Percentage,
    ) => {
      const positionFrom = getHorizontalPosition(dateTimeFrom, false);
      const positionTo = getHorizontalPosition(dateTimeTo, false);
      const ret = positionTo - positionFrom;
      return (percentage ? `${ret}%` : ret) as Percentage extends true
        ? string
        : number;
    },
    [getHorizontalPosition],
  );

  return {
    context: {
      now,
      jobs,
      scale,
      interval,
      refs: {
        grid: gridRef,
        cell: cellRef,
        now: nowRef,
      },
      getHorizontalPosition,
      getHorizontalWidth,
    },
  };
};
