import React from 'react';
import FormRenderer from '../FormRenderer';
import { useFormLocal } from './hooks';
import { FormLocalProps } from './types';

const FormLocal = ({
  readOnly = false,
  children,
  disableFooter,
  disableHeader,
  disableMedia,
  disableCompletion,
  template,
  reviewMode,
  fieldExtensions,
  renderField,
  onSubmit,
}: FormLocalProps) => {
  /*
   * FormLocal is a wrapper around FormRenderer
   * that provides a local state for the form.
   * This is useful for forms that need to be
   * submitted to the platform, but also need to
   * be able to be saved locally and resumed later.
   */

  const {
    isCompleted,
    completionPercentage,
    values,
    meta,
    objects,
    handleFieldValueOnChange,
    handleFieldMetaOnChange,
    handleRepeatingBranchOnCreate,
    handleRepeatingBranchOnDelete,
    handleObjectsOnCreate,
    handleObjectOnUpload,
    handleObjectOnUploadProgress,
    handleObjectOnDelete,
    handleOnSubmit,
    handleGetSubmission,
  } = useFormLocal(template, onSubmit);

  return (
    <>
      <FormRenderer
        readOnly={readOnly}
        disableFooter={disableFooter}
        disableHeader={disableHeader}
        disableMedia={disableMedia}
        disableCompletion={disableCompletion}
        template={template}
        values={values}
        objects={objects}
        meta={meta as any}
        reviewMode={reviewMode}
        fieldExtensions={fieldExtensions}
        renderField={renderField}
        fieldValueOnChange={handleFieldValueOnChange}
        fieldMetaOnChange={handleFieldMetaOnChange}
        repeatingBranchOnCreate={handleRepeatingBranchOnCreate}
        repeatingBranchOnDelete={handleRepeatingBranchOnDelete}
        objectsOnCreate={handleObjectsOnCreate}
        objectOnUpload={handleObjectOnUpload}
        objectOnUploadProgress={handleObjectOnUploadProgress}
        objectOnDelete={handleObjectOnDelete}
        onComplete={() => {}}
        onUndo={() => {}}
        onRedo={() => {}}
      />
      {children?.({
        form: null,
        isCompleted,
        isApproved: false,
        isLocked: false,
        values,
        meta,
        completionPercentage,
        fieldValueOnChange: handleFieldValueOnChange,
        getSubmission: handleGetSubmission,
        onEdit: () => {},
        onComplete: () => {},
        onSubmit: handleOnSubmit,
      })}
    </>
  );
};

export default FormLocal;
