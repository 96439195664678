import { useCallback, useState } from 'react';
import { PlatformObject } from 'lib/types';
import { DataType } from '../../../../../../external/FormTemplateEditor';
import { useMedia } from '../../../../../Media/hooks';
import { useRegisterObjectsOnMount } from '../../../../../MediaProvider';
import { useFormContext } from '../../../../context';
import { FormFieldMediaProps } from './types';

export const useFormFieldMedia = ({
  readOnly,
  item,
  onChange,
}: FormFieldMediaProps) => {
  const {
    objects,
    objectsOnCreate,
    objectOnUploadProgress,
    objectOnUpload,
    objectOnDelete,
  } = useFormContext();

  const itemObjects = objects?.[item.path];
  useRegisterObjectsOnMount(itemObjects);

  const handleObjectsOnCreated = useCallback(
    (newObjects: PlatformObject[]) => {
      objectsOnCreate(item, newObjects);
      if (item.data.dataType === DataType.MEDIA) {
        onChange(item, {
          dataType: DataType.MEDIA,
          value: [
            ...(itemObjects?.map(({ id }) => id) ?? []),
            ...newObjects.map(({ id }) => id),
          ],
        });
      }
    },
    [objectsOnCreate, item, onChange],
  );

  const handleOnUploadProgress = useCallback(
    (objectId: PlatformObject['id'], progressPercentage: number) => {
      objectOnUploadProgress(item, objectId, progressPercentage);
    },
    [objectOnUploadProgress, item],
  );

  const handleOnUploaded = useCallback(
    (objectId: PlatformObject['id']) => {
      objectOnUpload(item, objectId);
    },
    [objectOnUpload, item],
  );

  const handleOnDeleted = useCallback(
    (object: PlatformObject | Omit<PlatformObject, 'sources'>) => {
      objectOnDelete(item, object);
      if (item.data.dataType === DataType.MEDIA) {
        onChange(item, {
          dataType: DataType.MEDIA,
          value: itemObjects.filter(({ id }) => id !== object.id),
        });
      }
    },
    [objectOnDelete, item, onChange],
  );

  const [progress, setProgress] = useState<Record<string, number>>({});
  const { dropzone } = useMedia({
    onCreated: (objects) => {
      handleObjectsOnCreated(objects);
      setProgress((prevProgress) =>
        objects.reduce((acc, object) => {
          acc[object.id] = 0;
          return acc;
        }, prevProgress),
      );
    },
    onUploadProgress: (id, progress) => {
      handleOnUploadProgress(id, progress);
    },
    onUploaded: (id) => {
      handleOnUploaded(id);
      handleOnUploadProgress(id, 100);
      setProgress((prevProgress) => ({
        ...prevProgress,
        [id]: 100,
      }));
    },
  });

  const handleOnDelete = useCallback(
    (object: PlatformObject | Omit<PlatformObject, 'sources'>) => {
      handleOnDeleted(object);
    },
    [handleOnDeleted],
  );

  return {
    readOnly,
    value: itemObjects ?? [],
    progress,
    dropzone,
    setProgress,
    onDelete: handleOnDelete,
  };
};
