import React from 'react';
import {
  BookIcon,
  CogIcon,
  ForkliftIcon,
  LogOutIcon,
  UserIcon,
} from 'lucide-react';
import { useNavigate } from 'react-router';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import {
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import { Loader } from '@/components/ui/loading';
import { useUser } from 'lib/hooks';
import { useLogout } from '../../../../app/hooks';

const SearchStatic = ({ onClose }: { onClose?: () => void }) => {
  const user = useUser();
  const navigate = useNavigate();
  const logout = useLogout();
  return (
    <CommandList className="overflow-y-auto">
      <CommandEmpty>No results found.</CommandEmpty>
      <CommandGroup className="mb-1.5 mt-1" heading="Suggestions">
        <CommandItem
          className="cursor-pointer"
          onSelect={() => {
            navigate('/clients');
            onClose?.();
          }}
        >
          <ForkliftIcon className="mr-2 h-4 w-4" />
          <span>Clients</span>
        </CommandItem>
        <CommandItem
          className="cursor-pointer"
          onSelect={() => {
            navigate('/hr/rosters');
            onClose?.();
          }}
        >
          <UserIcon className="mr-2 h-4 w-4" />
          <span>Human resources</span>
        </CommandItem>
        <CommandItem
          className="cursor-pointer"
          onSelect={() => {
            navigate('/docs');
            onClose?.();
          }}
        >
          <BookIcon className="mr-2 h-4 w-4" />
          <span>Documentation</span>
        </CommandItem>
      </CommandGroup>
      <CommandSeparator />
      <CommandGroup className="mb-1.5 mt-2" heading="Settings">
        <CommandItem
          className="cursor-pointer"
          onSelect={() => {
            navigate('/profile');
            onClose?.();
          }}
        >
          <Avatar className="mr-2 h-5 w-5 rounded">
            <AvatarFallback className="rounded bg-primary/25 text-[10px] font-bold text-primary dark:text-primary">
              {user ? user?.name?.[0] : <Loader className="h-3 w-3" />}
            </AvatarFallback>
          </Avatar>
          <span>Profile</span>
        </CommandItem>
        <CommandItem
          className="cursor-pointer"
          onSelect={() => {
            navigate('/settings');
            onClose?.();
          }}
        >
          <CogIcon className="mr-2 h-4 w-4" />
          <span>Admin</span>
        </CommandItem>
        <CommandItem
          className="cursor-pointer"
          onSelect={() => {
            onClose?.();
            logout();
          }}
        >
          <LogOutIcon className="mr-2 h-4 w-4" />
          <span>Sign out</span>
        </CommandItem>
      </CommandGroup>
    </CommandList>
  );
};

export default SearchStatic;
