import React from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { Progress } from '@/components/ui/progress';
import { useYjs } from 'lib/hooks';
import { JobTaskForm } from 'lib/types';
import { useFormCompletion } from './hooks';
import { JobTaskFormCompletionProps } from './types';

export const TaskFormCompletionDisplay = ({
  isCompleted = false,
  completionPercentage = 0,
  small = false,
}) => {
  if (small) {
    return (
      <div className="flex items-center gap-2">
        <Progress className="w-[100px] border" value={completionPercentage} />
        <p className="text-xs font-semibold text-primary/75">
          {completionPercentage}%
        </p>
      </div>
    );
  }
  return (
    <div className="flex items-center gap-2">
      <Progress className="w-[100px]" value={completionPercentage} />
      <p className="text-sm font-semibold text-primary/75">
        {completionPercentage}% completed
      </p>
    </div>
  );
};

export const TaskFormCompletion = ({
  store,
  jobTaskForm,
}: JobTaskFormCompletionProps) => {
  const { values } = useSyncedStore(store);

  const { isCompleted, completionPercentage } = useFormCompletion(
    JSON.parse(jobTaskForm.template.data),
    values,
  );

  return (
    <TaskFormCompletionDisplay
      isCompleted={isCompleted}
      completionPercentage={completionPercentage}
    />
  );
};

const StateWrapper = ({
  jobTaskForm,
}: {
  auth: any;
  jobTaskForm: JobTaskForm;
}) => {
  const { store, isReady } = useYjs(
    'jobs::job-task-form',
    jobTaskForm.id,
    {
      values: {},
      objects: {},
      meta: {},
    },
    {},
  );

  if (!store || !isReady) return null;

  return <TaskFormCompletion store={store} jobTaskForm={jobTaskForm} />;
};

export default StateWrapper;
