import React from 'react';
import { PageLoader } from 'lib/shared';
import ReportVersion from './components/ReportVersion';
import { JobReportContext } from './context';
import { useJobReport } from './hooks';

const JobReport = () => {
  const { context } = useJobReport();

  if (!context.report) {
    return <PageLoader className="p-4">Loading report...</PageLoader>;
  }

  return (
    <JobReportContext.Provider value={context}>
      <ReportVersion />
    </JobReportContext.Provider>
  );
};

export default JobReport;
