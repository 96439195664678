import { ChangeEvent, useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { CommercialCostLine, CommercialUnit } from 'lib/types';
import { useCommercialActivityContext } from '../../../../../../context';
import { useActivityTableContext } from '../../../../context';

export const useTableCost = (line: CommercialCostLine) => {
  const { resources } = useCommercialActivityContext();
  const { updateRefetch } = useActivityTableContext();

  const [update] = useMutation(
    gql`
      mutation UpdateCommercialCostLine(
        $id: ID!
        $field: String!
        $value: String!
      ) {
        updateCommercialCostLine(line: $id, field: $field, value: $value) {
          commercialCostLine {
            id
            rate {
              id
            }
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnChange = useCallback(
    async (field: string, value: string) => {
      const { data, errors } = await update({
        variables: {
          id: line.id,
          field,
          value,
        },
      });

      if (data) {
        await updateRefetch();
      }

      if (errors) {
      }
    },
    [line.id, update, updateRefetch],
  );

  const handleRateOnChange = useCallback(
    async (value: string) => {
      await handleOnChange('rate', value);
    },
    [handleOnChange],
  );

  const handleUnitOnChange = useCallback(
    async (value: CommercialUnit) => {
      await handleOnChange('unit', value);
    },
    [handleOnChange],
  );

  const handleEstimatedQuantityOnChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      await handleOnChange('estimated_quantity', event.target.value);
    },
    [handleOnChange],
  );

  const handleEstimatedUnitCostOnChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      await handleOnChange('estimated_unit_cost', event.target.value);
    },
    [handleOnChange],
  );

  return {
    resources,
    handlers: {
      rateOnChange: handleRateOnChange,
      unitOnChange: handleUnitOnChange,
      estimatedQuantityOnChange: handleEstimatedQuantityOnChange,
      estimatedCostOnChange: handleEstimatedUnitCostOnChange,
    },
  };
};
