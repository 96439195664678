import { useEffect, useMemo, useState } from 'react';
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useLocation } from 'react-router';
import { useTodoContext } from '../../../../../external/TodosProvider/hooks';
import { useClientTodosContext } from '../context';
import { columns } from './table';

export const useTodosWorkspace = () => {
  const { selected, selectTodo, clearSelectedTodo } = useTodoContext();
  const { todos } = useClientTodosContext();

  const data = useMemo(() => todos, [todos]);

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'urgency',
      desc: true,
    },
  ]);

  const location = useLocation();
  useEffect(() => {
    if (!location.search.includes('?window=')) {
      clearSelectedTodo();
    }
  }, [location.search]);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row.id,
    enableSorting: true,
    enableMultiSort: false,
    state: { sorting },
    onSortingChange: setSorting,
  });

  return {
    table,
    selected,
    select: selectTodo,
    clearSelected: clearSelectedTodo,
  };
};
