import React from 'react';
import {
  CheckCircle,
  ChevronDownCircle,
  Circle,
  DotIcon,
  MailCheck,
  Scan,
  XCircle,
} from 'lucide-react';
import { Route, Routes } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { gql, useMutation, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { toast } from 'sonner';
import { CommercialEstimateStatus, JobQuoteStatus } from 'lib/types';
import { Action, Actions } from '../../../../external';
import { useWindowManager } from '../../../../shared/WindowManager/hooks';
import JobLink from '../../components/JobLink';
import { useJobContext } from '../../context';
import JobCommercial from '../JobCommercial';

const JobVariationInstantiation = () => {
  const { windowed, path } = useWindowManager();

  if (windowed) {
    if (path.includes('commercial')) {
      return <JobCommercial />;
    }

    return <JobVariationInstantiationRenderer />;
  }

  return (
    <Routes>
      <Route index element={<JobVariationInstantiationRenderer />} />
      <Route path="commercial/*" element={<JobCommercial />} />
    </Routes>
  );
};

const JobVariationInstantiationRenderer = () => {
  const { job } = useJobContext();

  const { data } = useQuery(
    gql`
      query GetJobGraph($id: ID!) {
        job(jobId: $id) {
          id
          graph
        }
      }
    `,
    {
      skip: job.partial,
      variables: job.partial ? undefined : { id: job.id },
    },
  );

  const [update, { loading: updating }] = useMutation(gql`
    mutation UpdateJobQuote(
      $id: ID!
      $field: String!
      $value: JSONScalarInput!
    ) {
      updateJobQuote(jobQuoteId: $id, field: $field, value: $value) {
        jobQuote {
          id
        }
      }
    }
  `);

  const handleOnMarkApproves = (quote: string) => {
    return async () => {
      if (job.partial) return;

      const { data, errors } = await update({
        variables: {
          id: quote,
          field: 'status',
          value: 'APPROVED',
        },
      });

      if (data) {
        await job.refetch();
        toast.success('Quote marked as approved');
      }

      if (errors) {
        toast.error('Failed to mark quote as approved');
      }
    };
  };

  const handleOnMarkRejects = (quote: string) => {
    return async () => {
      if (job.partial) return;

      const { data, errors } = await update({
        variables: {
          id: quote,
          field: 'status',
          value: 'REJECTED',
        },
      });

      if (data) {
        await job.refetch();
        toast.success('Quote marked as rejected');
      }

      if (errors) {
        toast.error('Failed to mark quote as rejected');
      }
    };
  };

  if (job.partial || !job.variation) return null;

  const submittedQuotes = job.quotes.filter(
    (quote) => quote.status === JobQuoteStatus.Submitted,
  );

  if (submittedQuotes.length === 0) {
    return (
      <div className="relative flex h-full min-h-0 w-full flex-col bg-background py-10 bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-secondary">
        <div className="container flex h-full min-h-0 max-w-[900px] flex-col rounded-lg border bg-background p-0">
          <div className="my-8 flex flex-col items-center gap-y-4 text-center">
            <h1 className="text-xl">Awaiting estimation</h1>
            <div className="relative size-8">
              <Circle className="absolute inset-0 size-8 fill-incoming/75 stroke-incoming/75" />
              <Circle className="absolute inset-0 size-8 animate-ping fill-incoming/75 stroke-incoming/75" />
            </div>
          </div>

          <div className="flex flex-col items-center gap-y-4 text-center">
            <h1 className="text-xl">Draft estimates</h1>
            <div className="flex gap-x-8">
              {job.variation.commercialActivity.estimates.map((estimate) => {
                const quote = job.quotes.find(
                  (quote) => quote.commercialEstimate.id === estimate.id,
                );
                return (
                  <div key={estimate.id} className="w-64 rounded border p-4">
                    <p className="">{estimate.name}</p>
                    <p>£{estimate.totalEstimatedValue.toFixed(2)}</p>
                    <p>{quote?.reference}</p>
                    <JobLink to={`commercial?estimate=${estimate.id}`}>
                      <Button>View</Button>
                    </JobLink>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ScrollArea>
      <div className="relative flex h-full min-h-0 w-full flex-col bg-background py-10 bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-secondary">
        <div className="container relative flex min-h-0 max-w-[900px] flex-col rounded-lg p-0">
          <div className="relative pb-8">
            <h1 className="relative text-xl">
              <div className="flex items-center gap-x-1">
                Variation created
                <DotIcon className="h-4 w-4" />
                <p className="text-xs font-semibold dark:text-gray-400">
                  {DateTime.fromISO(
                    job.variation.dateTimeCreated as unknown as string,
                  ).toLocaleString(DateTime.DATETIME_MED)}
                </p>
              </div>
              <div className="absolute right-full top-0 z-10 mr-3.5 rounded-full border-4 border-background bg-background">
                <ChevronDownCircle className="h-5 w-5 text-incoming" />
              </div>
            </h1>
            <div className="absolute bottom-0 right-full top-2 mr-[27px] w-0.5 bg-accent" />
          </div>

          <div className="relative flex flex-col gap-y-4 pb-8">
            <h1 className="text-xl">
              <div className="flex items-center gap-x-1">
                Quote{submittedQuotes.length > 1 && 's'} submitted
                <DotIcon className="h-4 w-4" />
                <p className="text-xs font-semibold dark:text-gray-400">
                  {DateTime.fromISO(
                    job.variation.dateTimeCreated as unknown as string,
                  ).toLocaleString(DateTime.DATETIME_MED)}
                </p>
              </div>
              <div className="absolute right-full top-0 z-10 mr-3.5 rounded-full border-4 border-background bg-background">
                <CheckCircle className="h-5 w-5 text-green-600" />
              </div>
            </h1>
            <div className="flex flex-col gap-y-4">
              {job.variation.commercialActivity.estimates.map((estimate) => {
                const quote = job.quotes.find(
                  (quote) => quote.commercialEstimate.id === estimate.id,
                );
                return (
                  <div
                    key={estimate.id}
                    className="rounded border bg-background px-4 py-3"
                  >
                    <div className="mb-1 flex items-center gap-x-1">
                      <p className="font-semibold text-green-600">
                        {quote?.reference}
                      </p>
                      <DotIcon className="mx-1 h-2 w-2" />
                      <p className="whitespace-nowrap">{estimate.name}</p>
                      <DotIcon className="mx-1 h-2 w-2" />
                      <div className="flex items-center gap-x-1">
                        <p className="text-sm">Value:</p>
                        <p className="font-semibold">
                          £{estimate.totalEstimatedValue.toFixed(2)}
                        </p>
                      </div>
                      <DotIcon className="mx-1 h-2 w-2" />
                      <JobLink to={`commercial?estimate=${estimate.id}`}>
                        <Button className="h-6 p-0" size="sm" variant="link">
                          View quote
                        </Button>
                      </JobLink>
                    </div>
                    <p className="line-clamp-2 text-sm dark:text-gray-400">
                      {estimate.description}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="absolute bottom-0 right-full top-2 mr-[27px] w-0.5 bg-accent" />
          </div>

          {job.variation.quoteResponses.length > 0 ? (
            <>
              <div className="relative flex flex-col gap-y-4 pb-8">
                <h1 className="text-xl">
                  <div className="flex items-center gap-x-1">
                    Detected client response
                    <DotIcon className="h-4 w-4" />
                    <p className="text-xs font-semibold dark:text-gray-400">
                      {DateTime.fromISO(
                        job.variation.dateTimeCreated as unknown as string,
                      ).toLocaleString(DateTime.DATETIME_MED)}
                    </p>
                  </div>
                  <div className="absolute right-full top-0 z-10 mr-3.5 rounded-full border-4 border-background bg-background">
                    <MailCheck className="h-5 w-5 text-warning" />
                  </div>
                </h1>
                <div>
                  {job.variation.quoteResponses.map((quoteResponse) => {
                    return (
                      <div
                        key={quoteResponse.id}
                        className="rounded border bg-background px-4 py-3"
                      >
                        <div className="mb-1 flex items-center gap-x-1">
                          <p className="font-semibold">
                            {quoteResponse?.jobReference}
                          </p>
                          <DotIcon className="mx-1 h-2 w-2" />
                          <p>{quoteResponse.jobVariationReference}</p>
                          <DotIcon className="mx-1 h-2 w-2" />
                          <p className="text-sm dark:text-gray-400">
                            Picked up from TechForge
                          </p>
                        </div>
                        <p className="text-sm dark:text-gray-400">
                          {quoteResponse.description}
                        </p>
                      </div>
                    );
                  })}
                </div>
                <div className="absolute bottom-0 right-full top-2 mr-[27px] w-0.5 bg-accent" />
              </div>

              {job.variation.actionSetId && (
                <Actions
                  actionSetId={job.variation.actionSetId}
                  scope={`jobs::job-variation::${job.variation.id}`}
                  context={{
                    event: {
                      data: {
                        instantiate: true,
                      },
                    },
                  }}
                  queryKey={[]}
                >
                  <div className="relative flex flex-col gap-y-4">
                    <h1 className="text-xl">
                      <div className="flex items-center gap-x-1">
                        Select and approve
                      </div>
                      <div className="absolute right-full top-0 z-10 mr-3.5 rounded-full border-4 border-background bg-background">
                        <Scan className="h-5 w-5" />
                      </div>
                    </h1>
                    <div className="rounded border bg-secondary">
                      <div className="p-2">
                        <p className="mb-1 text-sm">
                          Please confirm which quote is to be carried out:
                        </p>
                      </div>
                      <div className="divive-x flex flex-col">
                        {job.quotes.map((quote) => (
                          <div
                            key={quote.id}
                            className="flex items-center justify-between gap-x-2 border-b p-2"
                          >
                            <div>
                              <p className="font-semibold text-green-600">
                                {quote.reference}
                              </p>
                              <p className="text-xs dark:text-gray-400">
                                {quote.commercialEstimate.name}
                              </p>
                            </div>
                            <Action
                              id="instantiate"
                              small
                              disabled={
                                quote.status !== JobQuoteStatus.Approved
                              }
                              context={{ job_quote: { id: quote.id } }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Actions>
              )}
            </>
          ) : (
            <div className="flex flex-col gap-y-4">
              <h1 className="relative text-xl">
                <div className="flex items-center gap-x-1">
                  Awaiting client response
                </div>
                <div className="absolute right-full top-0 z-10 mr-[15px] rounded-full border-[5px] border-background bg-background">
                  <div className="relative size-4">
                    <Circle className="absolute inset-0 size-4 fill-warning/75 stroke-warning/75" />
                    <Circle className="absolute inset-0 size-4 animate-ping fill-warning/75 stroke-warning/75" />
                  </div>
                </div>
              </h1>
            </div>
          )}
        </div>
      </div>
    </ScrollArea>
  );
};

export default JobVariationInstantiation;
