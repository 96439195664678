import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  date,
  file,
  image,
  input,
  textarea,
} from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { z } from 'zod';
import { useMembershipsCreate } from './hooks';

const MembershipsCreate = () => {
  const { sheet, handlers } = useMembershipsCreate();
  return (
    <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
      <SheetTrigger asChild>
        <Button className="pl-2" size="sm" variant="outline">
          <Plus className="mr-1 size-4" />
          Create new membership
        </Button>
      </SheetTrigger>
      <SheetContent className="flex h-full min-h-0 flex-col p-0">
        <SheetHeader className="p-4">
          <SheetTitle>Create Company Membership</SheetTitle>
          <SheetDescription>Create a new company membership</SheetDescription>
        </SheetHeader>
        <ScrollArea className="min-h-0 flex-grow pb-4">
          <FormRenderer
            className="mx-4"
            fields={[
              image({
                name: 'logo',
                label: 'Logo',
                schema: z.any(),
                description: 'The logo of the membership',
              }),
              input({
                name: 'name',
                label: 'Name',
                schema: z.string().min(1),
              }),
              textarea({
                name: 'description',
                label: 'Description',
                schema: z.string().min(1),
              }),
              date({
                name: 'dateTimeExpiry',
                label: 'Expiry Date',
                schema: z.any(),
                timescape: true,
                description: 'The date the membership expires',
              }),
              file({
                name: 'document',
                label: 'Document',
                schema: z.any(),
                description: 'The document of the membership',
                right: 384,
              }),
            ]}
            onSubmit={handlers.onCreate}
          >
            {({ onSubmit }) => (
              <div className="pt-2">
                <Button className="w-full" size="sm" onClick={onSubmit}>
                  Create
                </Button>
              </div>
            )}
          </FormRenderer>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

export default MembershipsCreate;
