import React, { useEffect, useState } from 'react';
import { Report, ReportBlockComponentProps, ReportMode } from 'lib/shared';
import { Job } from 'lib/types';
import { parseForClient } from 'lib/utils';

const JobMap = ({ context }: Pick<ReportBlockComponentProps, 'context'>) => {
  const { mode, theme, cache } = Report.useContext();

  const mapToken =
    'pk.eyJ1IjoiZW5lcmd5ZW5hYmxlcyIsImEiOiJjbGpia2NqZ2IxcTcxM2pxeGxvNG5ud2lpIn0.gMXRc_xES-6AlQx1w8MuXw';
  const { job } = parseForClient(context) as { job: Job };
  const { site } = job;
  const address = site.location?.address;
  const point = site.location?.precise?.geometry?.point;

  if (!point || !address) return null;

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    (async () => {
      if (mode === ReportMode.Edit) {
        setIsLoading(true);
        const boundingBox = `[${point.longitude - 0.0005},${
          point.latitude - 0.0005
        },${point.longitude + 0.0005},${point.latitude + 0.0005}]`;
        const pin = `geojson(${encodeURIComponent(
          JSON.stringify({
            type: 'Point',
            coordinates: [point.longitude, point.latitude],
          }),
        )})`;
        const response = await fetch(
          `https://api.mapbox.com/styles/v1/energyenables/clqzso8ec009y01pd8b4tatmd/static/${pin}/${boundingBox}/561x160?access_token=${mapToken}`,
        );
        const blob = await response.blob();
        const dataUrl = URL.createObjectURL(blob);
        cache.set(`job::${job.id}::map`, dataUrl);
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Report.Section title="Job Site" subtitle="Client provided job description">
      <Report.View
        style={{
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: theme.colors.border,
        }}
      >
        {isLoading ? (
          <Report.View style={{ padding: 16 }}>
            <Report.Text style={{ fontSize: 14 }}>Loading map...</Report.Text>
          </Report.View>
        ) : (
          <Report.Image
            src={cache.get(`job::${job.id}::map`)}
            ignoreOnMount
            style={{ height: 160 }}
          />
        )}
      </Report.View>
      <Report.View
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Report.Text
          style={{
            fontSize: 13,
            fontWeight: 600,
            lineHeight: '1.25',
            color: theme.colors.title,
            textAlign: 'center',
            padding: 8,
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: theme.colors.border,
          }}
        >
          {site.reference}
        </Report.Text>
        <Report.Text
          style={{
            padding: 8,
            textAlign: 'center',
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '1.25',
            color: theme.colors.subtitle,
          }}
        >
          {address.formatted}
        </Report.Text>
      </Report.View>
    </Report.Section>
  );
};

export default JobMap;
