import React, { forwardRef, useImperativeHandle } from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { useSyncedStore } from '@syncedstore/react';
import { PlusIcon } from 'lucide-react';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from '@/components/ui/button';
import * as Y from 'yjs';
import { useUser } from 'lib/hooks';
import { platformClient } from '../../app/platform';
import snakeCaseToCamelCase from '../../utils/snakeCaseToCamelCase';
import Tree from '../Tree';
import Block from './components/Block';
import BlockPlaceholder from './components/BlockPlaceholder';
import ChoiceSetEditor from './components/ChoiceSetEditor';
import PageFooter from './components/PageFooter';
import PageHeader from './components/PageHeader';
import PagePlaceholder from './components/PagePlaceholder';
import { FormTemplateContextProvider } from './context';
import { useFormTemplateEditor, useSyncedTransact } from './hooks';
import { FormTemplateEditorRef, State } from './types';

const FormTemplateEditor = forwardRef<
  FormTemplateEditorRef,
  {
    id: string;
    store: State;
    doc: Y.Doc;
    undoManagers: Record<string, Y.UndoManager>;
  }
>(({ id, store, doc, undoManagers }, ref) => {
  const user = useUser();

  const { pageOrder, items, choiceSets, fieldLabels } = useSyncedStore(store);
  const transact = useSyncedTransact(store);

  const handleSetValue = (valueString: string) => {
    const newValue = JSON.parse(valueString);
    const {
      items: itemsData,
      pageOrder: pageOrderData,
      choiceSets: choiceSetsData,
      fieldLabels: fieldLabelsData,
    } = snakeCaseToCamelCase(newValue);

    transact(() => {
      const itemsMap = doc.getMap('items');
      const pageOrderArray = doc.getArray('pageOrder');
      const choiceSetsMap = doc.getMap('choiceSets');
      const fieldLabelsMap = doc.getMap('fieldLabels');

      pageOrderArray.delete(0, pageOrderArray.length);
      pageOrderArray.push(pageOrderData);

      itemsMap.clear();
      for (const [key, itemValue] of Object.entries(itemsData)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        items[key] = itemValue;
      }

      choiceSetsMap.clear();
      for (const [key, choiceSetValue] of Object.entries(choiceSetsData)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        choiceSets[key] = choiceSetValue;
      }

      fieldLabelsMap.clear();
      for (const [key, fieldLabelValue] of Object.entries(fieldLabelsData)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fieldLabels[key] = fieldLabelValue;
      }
    });
  };

  const {
    focus,
    logic,
    collapsed,
    handleOnBlur,
    handleOnFocus,
    handleOnSelectLogic,
    handleOnCollapse,
    getShouldParentIncreaseDepth,
    getLogicFor,
    getShouldItemRender,
    getShouldItemNest,
    getShouldIncludeSelf,
    handleOnChange,
    handleAddPageOnClick,
    transformParentItem,
    choiceSetEditorProps,
  } = useFormTemplateEditor(store);

  useImperativeHandle(ref, () => ({
    undo: () => {
      undoManagers.main.undo();
      return null;
    },
    redo: () => {
      undoManagers.main.redo();
      return null;
    },
  }));

  return (
    <ErrorBoundary
      fallbackRender={() => (
        <div>
          <p>Error loading form</p>
          <p className="text-xs">Try reloading the page.</p>
        </div>
      )}
    >
      <FormTemplateContextProvider
        value={{
          store,
          focus,
          logic,
          collapsed,
          onBlur: handleOnBlur,
          onFocus: handleOnFocus,
          onSelectLogic: handleOnSelectLogic,
          onCollapse: handleOnCollapse,
          getShouldParentIncreaseDepth,
          getLogicFor,
        }}
      >
        <AccordionPrimitive.Root
          type="single"
          collapsible
          value={focus ?? ''}
          onValueChange={(value) => {
            handleOnFocus(items[value]);
          }}
        >
          <div className="relative pr-[102px]">
            {user?.email === 'jamie.williams@mchughltd.co.uk' && (
              <div className="absolute right-0 top-0">
                <Button
                  size="sm"
                  variant="outline"
                  onClick={async () => {
                    const { data } = await platformClient.getFormTemplate(id)();
                    handleSetValue(data);
                  }}
                >
                  Sync
                </Button>
              </div>
            )}
            <Tree
              pageOrder={pageOrder as string[]}
              items={items as Items}
              collapsed={collapsed}
              getShouldItemNest={getShouldItemNest}
              getShouldItemRender={getShouldItemRender}
              getShouldIncludeSelf={getShouldIncludeSelf}
              getShouldParentIncreaseDepth={getShouldParentIncreaseDepth}
              transformParentItem={transformParentItem}
              renderPageHeader={PageHeader}
              renderPageFooter={PageFooter}
              renderPagePlaceholder={PagePlaceholder}
              renderItem={Block}
              renderItemPlaceholder={BlockPlaceholder}
              onChange={handleOnChange}
            />
            <Button variant="outline" size="sm" onClick={handleAddPageOnClick}>
              <PlusIcon className="mr-1 h-4 w-4" />
              Add Page
            </Button>
          </div>
        </AccordionPrimitive.Root>
        <ChoiceSetEditor
          {...choiceSetEditorProps}
          undoManager={undoManagers.choiceSets}
        />
      </FormTemplateContextProvider>
    </ErrorBoundary>
  );
});

export * from './types';

export default FormTemplateEditor;
