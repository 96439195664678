import React from 'react';
import { cn } from '@/lib/utils';
import { useSchedulerContext } from '../../../../hooks';

const ControlsLastAction = () => {
  const { actions } = useSchedulerContext();
  return (
    <p
      className={cn('text-xs transition-opacity', {
        'opacity-100': actions.show,
        'opacity-50': actions.pulse,
        'opacity-0': !actions.show,
      })}
    >
      {actions.value}
    </p>
  );
};

export default ControlsLastAction;
