import { createContext, useContext } from 'react';
import { useJobTask } from './hooks';

export const JobTaskContext = createContext(
  {} as ReturnType<typeof useJobTask>['context'],
);

export const useJobTaskContext = () => {
  return useContext(JobTaskContext);
};
