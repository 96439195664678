import React from 'react';
import { flexRender } from '@tanstack/react-table';
import { Loader } from '@/components/ui/loading';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { cn } from '@/lib/utils';
import { useJobTaskContext } from '../../context';
import AssignmentRowStaffMember from './components/AssignmentRowStaffMember';
import AssignmentsEmpty from './components/AssignmentsEmpty';
import { useTable } from './table';

const TaskAssignments = () => {
  const { task } = useJobTaskContext();

  const { table, columns } = useTable();

  if (!task) {
    return (
      <div className="space-4 flex items-center p-4">
        <div className="space-y-2">
          <Loader>Loading task...</Loader>
        </div>
      </div>
    );
  }

  const assignPath = `tasks/${task.number}/assign`;

  if (!task.timesheetActivity.duties.length) {
    return (
      <AssignmentsEmpty
        table={table}
        columns={columns}
        assignPath={assignPath}
      />
    );
  }

  return (
    <div className="min-h-0 grow border-t">
      <ScrollArea className="h-full">
        <Table wrapperClassname="h-full" className="h-full">
          <TableHeader className="sticky top-0 z-[5] border-l-4 bg-background">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} removeHoverStyles>
                <TableHead className="w-1" />
                {headerGroup.headers.map((header, index) => {
                  return (
                    <TableHead
                      key={header.id}
                      className={cn('w-1 whitespace-nowrap ', {
                        'pl-4': index === 0,
                      })}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="[&_tr:last-child]:border-b">
            {table.getRowModel().rows.map((row) => (
              <AssignmentRowStaffMember key={row.id} table={table} row={row} />
            ))}
          </TableBody>
        </Table>
      </ScrollArea>
    </div>
  );
};

export default TaskAssignments;
