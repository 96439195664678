import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { PoundSterling, ShieldCheck } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { PageLoader } from 'lib/shared';
import { CommercialContractRate } from 'lib/types';
import RateQualifications from './components/RateQualifications';
import RateUnitValue from './components/RateUnitValue';
import RateUnitValueTransition from './components/RateUnitValueTransition';
import { ContractRateContext } from './context';
import { useContractRate } from './hooks';

const ContractRate = ({
  row,
}: CellContext<CommercialContractRate, unknown>) => {
  const { context } = useContractRate(row.original.id);
  const { rate, sheet } = context;
  return (
    <ContractRateContext.Provider value={context}>
      <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
        <SheetTrigger asChild>
          <Button variant="table" size="table">
            {row.original.name}
          </Button>
        </SheetTrigger>
        <SheetContent className="flex w-[500px] max-w-[500px] flex-col gap-y-0 p-0 sm:max-w-[500px]">
          <SheetHeader className="p-4">
            <SheetTitle>{row.original.name}</SheetTitle>
            <SheetDescription>{row.original.description}</SheetDescription>
          </SheetHeader>
          {rate ? (
            <ScrollArea className="min-h-0 flex-grow">
              <div className="mx-4 pb-4">
                <div className="mb-8">
                  <div className="sticky top-0 flex items-center bg-background pb-2">
                    <PoundSterling className="mr-1 size-4" />
                    <p className="text-sm font-semibold">Values</p>
                  </div>
                  <RateUnitValue />
                  <RateUnitValueTransition />
                </div>
                <div>
                  <div className="sticky top-0 flex items-center bg-background pb-2">
                    <ShieldCheck className="mr-1 h-4 w-4" />
                    <p className="text-sm font-semibold">Qualifications</p>
                  </div>
                  <RateQualifications />
                </div>
              </div>
            </ScrollArea>
          ) : (
            <div className="min-h-0 flex-grow p-4">
              <PageLoader />
            </div>
          )}
        </SheetContent>
      </Sheet>
    </ContractRateContext.Provider>
  );
};

export default ContractRate;
