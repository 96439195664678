import React from 'react';
import { Sheet } from '@/components/ui/sheet';
import EditorDynamic from './components/EditorDynamic';
import EditorStatic from './components/EditorStatic';
import { ChoiceSetEditorContext } from './context';
import { useChoiceSetEditor } from './hooks';
import { ChoiceSetEditorProps } from './types';

const ChoiceSetEditor = ({
  choiceSet,
  isOpen,
  undoManager,
  onClose,
  onSave,
}: ChoiceSetEditorProps) => {
  const { context } = useChoiceSetEditor(choiceSet, onSave);

  if (!choiceSet) {
    return null;
  }

  if (choiceSet.dynamic) {
    return (
      <ChoiceSetEditorContext.Provider value={context}>
        <Sheet
          open={isOpen}
          onOpenChange={(open) => {
            if (!open) {
              undoManager.clear();
              onClose?.();
            }
          }}
        >
          <EditorDynamic />
        </Sheet>
      </ChoiceSetEditorContext.Provider>
    );
  }

  return (
    <ChoiceSetEditorContext.Provider value={context}>
      <Sheet
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) {
            undoManager.clear();
            onClose?.();
          }
        }}
      >
        <EditorStatic />
      </Sheet>
    </ChoiceSetEditorContext.Provider>
  );
};

export default ChoiceSetEditor;
