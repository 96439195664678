import { createContext, useContext } from 'react';
import { useValueLine } from './hooks';

export const ValueLineContext = createContext(
  {} as ReturnType<typeof useValueLine>['context'],
);

export const useValueLineContext = () => {
  return useContext(ValueLineContext);
};
