import { ChangeEvent, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

export const useDebouncedNumberInput = (
  initialValue: number | null,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
) => {
  const [inner, setInner] = useState(initialValue);
  const [event, setEvent] = useState<ChangeEvent<HTMLInputElement> | null>(
    null,
  );

  useEffect(() => {
    setInner(initialValue);
  }, [initialValue]);

  useDebounce(
    async () => {
      if (event) {
        onChange(event);
      }
    },
    500,
    [event, onChange],
  );

  const handleOnChange = (defaultEvent: ChangeEvent<HTMLInputElement>) => {
    setInner(parseFloat(defaultEvent.target.value));
    setEvent(defaultEvent);
  };

  return {
    input: {
      value: inner,
      onChange: handleOnChange,
    },
  };
};
