import React, { forwardRef } from 'react';
import { LinkProps, Link as RouterLink } from 'react-router-dom';
import { useWindowManager } from '../WindowManager/hooks';

const Link = forwardRef<any, LinkProps>(({ children, to, ...props }, ref) => {
  const { windowed, setPath } = useWindowManager();

  if (windowed) {
    return (
      <RouterLink
        {...props}
        to={`?window=${to}`}
        onClick={(event) => {
          setPath(to as string);
        }}
      >
        {children}
      </RouterLink>
    );
  }

  return (
    <RouterLink ref={ref} to={to} {...props}>
      {children}
    </RouterLink>
  );
});

export default Link;
