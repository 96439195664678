import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { gql, useQuery } from '@apollo/client';
import {
  CommercialCostLine,
  CommercialType,
  CommercialValueLine,
} from 'lib/types';
import { useCommercialEstimateContext } from '../../context';
import { useEstimateTableContext } from './context';
import { EstimateTableQueryData, EstimateTableQueryVariables } from './types';

export const useEstimateTable = (type: CommercialType) => {
  const { activity, estimate, store } = useCommercialEstimateContext();
  const { order } = useSyncedStore(store);

  const { data, refetch } = useQuery<
    EstimateTableQueryData,
    EstimateTableQueryVariables
  >(
    gql`
      query GetCommercialEstimate($id: ID!, $type: String) {
        commercialEstimate(commercialEstimateId: $id) {
          valueLines(type: $type) {
            id
            unit
            unitDisplay
            rate {
              id
              name
              description
              unit
              unitValue
            }
            description
            descriptionEstimate
            descriptionInternal
            estimatedQuantity
            estimatedUnitValue
            estimatedUnitValueUplift
            estimatedUnitValueWithUplift
            estimatedTotalValue
          }
          costLines(type: $type) {
            id
            unit
            unitDisplay
            rate {
              id
              unit
              unitCost
              description
              user {
                id
                name
              }
            }
            description
            descriptionInternal
            estimatedQuantity
            estimatedUnitCost
            estimatedTotalCost
            estimatedTotalCostWithPrelim
          }
          totalEstimatedValue(type: $type)
          totalEstimatedCost(type: $type)
          totalEstimatedCostWithPrelims(type: $type)
          totalEstimatedMargin(type: $type)
          totalEstimatedMarginWithPrelims(type: $type)
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      skip: !estimate,
      variables: {
        id: estimate?.id!,
        type,
      },
    },
  );

  useEffect(() => {
    refetch().then();
  }, [estimate?.contractPrelimFactor]);

  const valueLines = useMemo(() => {
    if (!data?.commercialEstimate) return [];
    return [...data.commercialEstimate.valueLines].sort(
      (a, b) => order.indexOf(a.id) - order.indexOf(b.id),
    );
  }, [data?.commercialEstimate, JSON.stringify(order)]);

  const costLines = useMemo(() => {
    if (!data?.commercialEstimate) return [];
    return [...data.commercialEstimate.costLines].sort(
      (a, b) => order.indexOf(a.id) - order.indexOf(b.id),
    );
  }, [data?.commercialEstimate, JSON.stringify(order)]);

  const updateRefetch = useCallback(async () => {
    await refetch();
    await activity.refetch({});
    await estimate?.refetch();
  }, [activity, estimate, refetch]);

  if (!data?.commercialEstimate) {
    return {
      ready: false,
      context: {
        type,
        valueLines: [] as CommercialValueLine[],
        costLines: [] as CommercialCostLine[],
        totalEstimatedValue: 0,
        totalEstimatedCost: 0,
        totalEstimatedCostWithPrelims: 0,
        totalEstimatedMargin: 0,
        totalEstimatedMarginWithPrelims: 0,
        updateRefetch: async () => {},
      },
    };
  }

  const inner = data.commercialEstimate;
  return {
    ready: !!data?.commercialEstimate,
    context: {
      type,
      valueLines,
      costLines,
      totalEstimatedValue: inner.totalEstimatedValue,
      totalEstimatedCost: inner.totalEstimatedCost,
      totalEstimatedCostWithPrelims: inner.totalEstimatedCostWithPrelims,
      totalEstimatedMargin: inner.totalEstimatedMargin,
      totalEstimatedMarginWithPrelims: inner.totalEstimatedMarginWithPrelims,
      updateRefetch,
    },
  };
};

export const useEstimateTableRenderer = () => {
  const {
    type,
    valueLines,
    costLines,
    totalEstimatedValue,
    totalEstimatedCost,
  } = useEstimateTableContext();

  const empty = valueLines.length + costLines.length === 0;
  const [open, setOpen] = useState(!empty);

  const handleOnToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return {
    type,
    state: {
      empty,
      open,
      onToggle: handleOnToggle,
    },
    totalEstimatedValue,
    totalEstimatedCost,
  };
};
