import TimesheetControls from './components/TimesheetControls';
import TimesheetTable from './components/TimesheetTable';
import { TimesheetContext } from './context';
import { useTimesheet } from './hooks';

const HumanResourcesTimesheet = () => {
  const { context } = useTimesheet();
  return (
    <TimesheetContext.Provider value={context}>
      <div className="container mt-8">
        <TimesheetControls />
        <TimesheetTable />
      </div>
    </TimesheetContext.Provider>
  );
};

export default HumanResourcesTimesheet;
