import React from 'react';
import { cn } from '@/lib/utils';
import MessageGroup from '../../../../../../external/MessageGroup';
import { useJobContext } from '../../../../context';
import { useJobIssueContext } from '../../context';
import { IssueFeedProps } from './types';

const IssueFeed = ({ children }: IssueFeedProps) => {
  const { state } = useJobContext();
  const { issue } = useJobIssueContext();

  if (!issue) return null;

  return (
    <div
      className={cn('mt-4', {
        'col-span-6 col-start-4': !state.small,
        'col-span-12 px-4': state.small,
      })}
    >
      <MessageGroup scope="jobs::job-issue" id={issue.id}>
        {children}
      </MessageGroup>
    </div>
  );
};

export default IssueFeed;
