import { Input } from '@/components/ui/input';
import { EditableAutoSaveProvider } from 'lib/shared';
import { useCommercialActivityContext } from '../../../../../CommercialActivity/context';
import { useCommercialEstimateContext } from '../../../../context';

const HeaderName = () => {
  const { activity } = useCommercialActivityContext();
  const { estimate, handlers } = useCommercialEstimateContext();
  return (
    <EditableAutoSaveProvider
      className="grow"
      value={estimate?.name}
      onChange={async (value) => {
        await handlers.onUpdate('name', value);
        await activity?.refetch();
      }}
    >
      {({ readOnly, value, onValueChange }) => (
        <Input
          className="h-8 border-none py-0 pl-1.5 text-sm font-bold outline-0 focus:ring-0 focus-visible:ring-0"
          readOnly={readOnly}
          value={value}
          onChange={(event) => {
            onValueChange(event.target.value);
          }}
        />
      )}
    </EditableAutoSaveProvider>
  );
};

export default HeaderName;
