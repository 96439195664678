import React from 'react';
import { Hexagon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Slider } from '@/components/ui/slider';
import { cn } from '@/lib/utils';
import { gql, useQuery } from '@apollo/client';
import { Duration } from 'luxon';
import { CommercialContract, CommercialContractRate } from 'lib/types';
import { useJobContext } from '../../../../../../sections/ClientJob/context';
import { usePendingInteractions } from './hooks';
import { PendingInteractionsProps } from './types';

const PendingInteractions = ({
  className,
  id,
  value,
}: PendingInteractionsProps) => {
  const { handlers } = usePendingInteractions(id);
  const interactions = value ?? [];
  return (
    <div className={cn(className)}>
      <div className="mb-2 flex flex-col gap-y-2">
        {interactions.map((interaction) => {
          const duration = interaction.duration
            ? Duration.fromMillis(interaction.duration).shiftTo(
                'hours',
                'minutes',
              )
            : Duration.fromObject({ hours: 0, minutes: 0 });
          return (
            <div
              key={interaction.id}
              className="flex flex-col gap-y-3 rounded border bg-secondary pb-4 pt-2"
            >
              <div>
                <div className="mb-1 flex items-center justify-between">
                  <p className="px-4 text-xs font-semibold">Skill</p>
                  <Button
                    variant="link"
                    size="xs"
                    confirm="Are you sure you want to remove this interaction?"
                    onClick={handlers.onDelete(interaction.id)}
                  >
                    Remove
                  </Button>
                </div>
                <div className="px-2">
                  <CommercialContractRateSelector
                    className="px-2"
                    value={interaction.rate}
                    onChange={(rate) => {
                      handlers.onUpdate(interaction.id, 'rate', rate.id);
                    }}
                  />
                </div>
              </div>
              <div className="px-4">
                <p className="mb-2 text-xs font-semibold">Duration</p>
                <p className="mb-3 text-xs font-semibold">
                  {duration.toHuman()}
                </p>
                <Slider
                  value={[duration.shiftTo('minutes').as('minutes')]}
                  max={300}
                  step={5}
                  onValueChange={(value) => {
                    handlers.onUpdate(
                      interaction.id,
                      'duration',
                      Duration.fromObject({ minutes: value[0] }).toMillis(),
                    );
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      {interactions.length > 0 ? (
        <div>
          <Button
            className="w-full"
            size="sm"
            variant="secondary"
            onClick={handlers.onCreate}
          >
            Add interaction
          </Button>
        </div>
      ) : (
        <div className="flex items-center rounded-lg border bg-secondary px-3 py-2">
          <div className="flex flex-grow items-center gap-x-2">
            <Hexagon className="dark:text-white-400 h-4 w-4 text-gray-400" />
            <p className="dark:text-white-400 text-xs text-gray-400">
              No interactions
            </p>
          </div>
          <Button size="sm" variant="outline" onClick={handlers.onCreate}>
            Add interaction
          </Button>
        </div>
      )}
    </div>
  );
};

type CommercialContractRateSelectorProps = {
  className?: string;
  value: CommercialContractRate['id'] | undefined;
  onChange: (value: CommercialContractRate) => void;
};

const CommercialContractRateSelector = ({
  className,
  value,
  onChange,
}: CommercialContractRateSelectorProps) => {
  const { job } = useJobContext();

  const { data } = useQuery<{ contract: CommercialContract }>(
    gql`
      query GetCommercialContractRates($id: ID!) {
        contract: commercialContract(commercialContractId: $id) {
          rates {
            id
            name
          }
        }
      }
    `,
    {
      skip: job.partial || !job.commercialActivity.contract,
      fetchPolicy: 'cache-and-network',
      variables:
        job.partial || !job.commercialActivity.contract
          ? undefined
          : { id: job.commercialActivity.contract.id },
    },
  );

  const handleOnValueChange = (value: string) => {
    const rate = data?.contract.rates.find((rate) => rate.id === value);
    if (rate) {
      onChange(rate);
    }
  };

  if (!data?.contract) {
    return null;
  }

  return (
    <Select value={value} onValueChange={handleOnValueChange}>
      <SelectTrigger className={cn('h-[34px] w-full', className)}>
        <div className="flex h-[34px] w-full items-center gap-x-1 text-xs">
          <SelectValue placeholder="Select skill" />
        </div>
      </SelectTrigger>
      <SelectContent>
        {data.contract.rates.map((rate) => (
          <SelectItem key={rate.id} value={rate.id}>
            {rate.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default PendingInteractions;
