import { Route, Routes } from 'react-router-dom';
import Subcontractor from './components/Subcontractor';
import SubcontractorRequirements from './components/SubcontractorRequirements';
import Subcontractors from './components/Subcontractors';
import { SupplyChainSubcontractorsContext } from './context';
import { useSupplyChainSubcontractors } from './hooks';

const SupplyChainSubcontractors = () => {
  const { context } = useSupplyChainSubcontractors();
  return (
    <SupplyChainSubcontractorsContext.Provider value={context}>
      <Routes>
        <Route index element={<Subcontractors />} />
        <Route path="requirements" element={<SubcontractorRequirements />} />
        <Route path=":id" element={<Subcontractor />} />
      </Routes>
    </SupplyChainSubcontractorsContext.Provider>
  );
};

export default SupplyChainSubcontractors;
