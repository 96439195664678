import { PlatformTodo } from 'lib/types';
import { useTodos } from './hooks';

export enum TodosVariant {
  CardList,
  Table,
}

export type TodosProps = {
  variant: TodosVariant;
  todoOnClick?: (todo: PlatformTodo) => void;
  todoOnClose?: (todo: PlatformTodo) => void;
  status?: string;
  queryFn?: any;
  groupKey?: string;
} & (
  | {
      scope: string;
      todos?: never;
      openTodos?: never;
      refetchTodos?: never;
    }
  | ({
      scope?: never;
    } & ReturnType<typeof useTodos>)
);

export type TodosQueryVariables = {
  scope: string;
  sort: any;
};

export type TodosQueryData = {
  todos: PlatformTodo[];
};
