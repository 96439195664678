import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'sonner';
import { useMatch } from 'lib/hooks';
import { Subcontractor } from 'lib/types';
import {
  SubcontractorQueryData,
  UpdateSubcontractorMutationData,
  UpdateSubcontractorMutationVariables,
} from './types';

export const useSubcontractor = () => {
  const match = useMatch('/supply-chain/subcontractors/:id');

  if (!match?.params.id) {
    throw new Error('No match found');
  }

  const { data, refetch } = useQuery<SubcontractorQueryData>(
    gql`
      query GetSubcontractor($id: ID!) {
        requirements: subcontractorRequirements {
          id
          name
          description
        }
        subcontractor(subcontractorId: $id) {
          id
          name
          contacts {
            id
            name
            email
            phone
            mobile
            notes
          }
          locations {
            id
            name
            contacts {
              id
            }
          }
          policies {
            id
            documents {
              id
              name
            }
            dateValid
            dateExpiry
            requirement {
              id
            }
          }
          duties {
            id
            dateTimeStart
            dateTimeEnd
            timesheetActivity {
              id
              jobTask {
                id
                name
                number
                description
                job {
                  id
                  reference
                  site {
                    location {
                      id
                      address {
                        id
                      }
                      precise {
                        id
                        geometry {
                          id
                          point {
                            latitude
                            longitude
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    { variables: { id: match.params.id } },
  );

  const [update] = useMutation<
    UpdateSubcontractorMutationData,
    UpdateSubcontractorMutationVariables
  >(
    gql`
      mutation UpdateSubcontractor(
        $id: ID!
        $field: String!
        $value: JSONScalarInput!
      ) {
        updateSubcontractor(
          subcontractorId: $id
          field: $field
          value: $value
        ) {
          subcontractor {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnUpdate = async (field: string, value: any) => {
    const { data, errors } = await update({
      variables: {
        id: match.params.id!,
        field,
        value,
      },
    });

    if (data) {
      await refetch();
      toast.success('Subcontractor updated');
    }

    if (errors) {
      console.error(errors);
      toast.error('Failed to update subcontractor');
    }
  };

  return {
    context: {
      requirements: data?.requirements || [],
      subcontractor: data?.subcontractor
        ? ({
            ...data?.subcontractor,
            refetch,
          } as Subcontractor)
        : undefined,
      handlers: {
        onUpdate: handleOnUpdate,
      },
    },
  };
};
