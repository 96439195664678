import React from 'react';
import { useToolbar } from '../../../../../../../../shared/PdfViewer/hooks';
import ReportContents from '../../../ReportContents';
import ReportToolbar from '../../../ReportToolbar';
import { useJobReportVersionContext } from '../../context';
import { JobReportVersionMode } from '../../types';
import VersionLive from '../VersionLive';
import VersionPdf from '../VersionPdf';
import { VersionRendererContext } from './context';

const VersionRenderer = () => {
  const { mode } = useJobReportVersionContext();
  const toolbar = useToolbar();
  return (
    <toolbar.Toolbar>
      {(toolbarSlot) => (
        <VersionRendererContext.Provider
          value={{
            Toolbar: toolbarSlot,
            toolbar,
          }}
        >
          <ReportToolbar />
          {mode.value === JobReportVersionMode.Live && (
            <div className="relative flex min-h-0 grow bg-secondary bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-background">
              <div className="pointer-events-none absolute inset-y-0 left-0 z-[8] w-[350px]">
                <ReportContents />
              </div>
              <div className="h-full grow">
                <VersionLive />
              </div>
            </div>
          )}
          {mode.value === JobReportVersionMode.PDF && (
            <div className="relative flex min-h-0 grow bg-secondary bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-background">
              <VersionPdf />
            </div>
          )}
        </VersionRendererContext.Provider>
      )}
    </toolbar.Toolbar>
  );
};

export default VersionRenderer;
