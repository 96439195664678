import { gql } from '@apollo/client';
import { Client } from 'lib/types';
import { createSectionState, createUse } from 'lib/utils';

export const useStore = createSectionState({
  clients: [] as Client[],
});

export const useClients = createUse(useStore)(
  'clients',
  gql`
    query GetClients {
      clients {
        id
        name
        slug
        accentColor
        accentColorDark
      }
    }
  `,
);
