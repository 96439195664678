import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useTimesheetContext } from '../../context';

export const useTimesheetTable = () => {
  const { duties } = useTimesheetContext();

  const table = useReactTable({
    data: duties,
    columns: [
      {
        id: 'move',
        header: '',
      },
      {
        id: 'description',
        header: 'Description',
      },
      {
        id: 'unitValue',
        header: 'Unit Value',
      },
      {
        id: 'quantity',
        header: 'Quantity',
      },
      {
        id: 'total',
        header: 'Total',
      },
      {
        id: 'actions',
        header: '',
      },
    ],
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
  };
};
