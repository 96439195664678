import React from 'react';
import { Image } from '@/components/ui/image';
import { Separator } from '@/components/ui/separator';
import { usePlatformObject } from 'lib/hooks';
import { useTrainingQualificationContext } from '../../context';
import { QualificationCardProps } from './types';

const QualificationCard = ({ extra }: QualificationCardProps) => {
  const { qualification } = useTrainingQualificationContext();
  const { object } = usePlatformObject(qualification?.logo?.id);

  if (!qualification) return null;

  return (
    <div
      className="relative rounded-lg border-2 bg-background px-4 pb-3 pt-4"
      style={{ borderColor: qualification.color }}
    >
      <div className="flex h-16 gap-x-4">
        <Image className="h-full rounded" src={object?.getUrl} />
        <div>
          <p className="font-semibold">{qualification.name}</p>
          <p className="line-clamp-2 text-sm dark:text-gray-400">
            {qualification.description}
          </p>
        </div>
      </div>
      <Separator className="my-2" />
      <div className="flex flex-col gap-y-2">
        {qualification.fields.map((field) => (
          <div
            key={field.label}
            className="flex items-center justify-between gap-x-2 whitespace-nowrap"
          >
            <p className="w-32 whitespace-nowrap text-sm font-semibold">
              {field.label}:
            </p>
            <p className="font-mono text-sm">{field.value}</p>
          </div>
        ))}
      </div>
      {extra}
    </div>
  );
};

export default QualificationCard;
