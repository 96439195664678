import React from 'react';
import {
  DraggableProvidedDragHandleProps,
  DraggableProvidedDraggableProps,
  DraggableStateSnapshot,
  OnDragEndResponder,
  OnDragStartResponder,
  OnDragUpdateResponder,
} from '@hello-pangea/dnd';

export type GetShouldItemRender<T = any> = (
  item: Item<T>,
  parentItem: Item<T>,
) => boolean;

export type GetShouldParentIncreaseDepth<T = any> = (
  parentItem: Item<T>,
) => boolean;

export type GetShouldItemNest<T = any> = (aboveItem: Item<T>) => boolean;

export type GetShouldIncludeSelf<T = any> = (aboveItem: Item<T>) => boolean;

export type GetIsItemCollapsed<T = any> = (
  aboveItemId: Item<T>['id'],
) => boolean;

export type TransformParentItem<T = any> = (item: Item<T>) => Item<T>;

export enum ItemType {
  PAGE = 'PAGE',
  CONTAINER = 'CONTAINER',
  BLOCK = 'BLOCK',
  LOGIC = 'LOGIC',
  COMMENT = 'COMMENT',
}

export type Page<T = any> = {
  id: string;
  type: ItemType.PAGE;
  parentId: string;
  children: string[];
  items: Item<T>[];
  data: {
    name: string;
  };
};

export type Item<T = any> = {
  id: string;
  type: ItemType;
  parentId: Item['id'];
  children: Item['id'][];
  data: T;
};

export type Pages<T = any> = Record<Page['id'], Page<T>>;
export type Items<T = any> = Record<Item['id'], Item<T>>;

export type TreeState = {
  pageOrder: Page['id'][];
  items: Record<string, Page | Item>;
};

export type PlaceholderPosition = Pick<
  React.CSSProperties,
  'top' | 'left' | 'height' | 'width'
>;

export type RenderItemProps<T> = {
  item: Item<T>;
  index: number;
  isLast: boolean;
  draggableProps: DraggableProvidedDraggableProps;
  dragHandleProps: DraggableProvidedDragHandleProps | null;
  draggableStateSnapshot: DraggableStateSnapshot;
};

export type RenderPageHeaderProps<T = any> = {
  page: Page<T>;
  isCollapsed: boolean;
};

export type RenderPageFooterProps<T = any> = {
  page: Page<T>;
};

export type RenderPagePlaceholderProps<T = any> = {
  page: Page<T>;
};

export type TreeProps<T = any> = {
  pageOrder: Page['id'][];
  items: Items<T>;
  collapsed?: string[];
  getShouldItemRender: GetShouldItemRender<T>;
  getShouldItemNest: GetShouldItemNest<T>;
  getShouldIncludeSelf?: GetShouldIncludeSelf<T>;
  getShouldParentIncreaseDepth: GetShouldParentIncreaseDepth<T>;
  transformParentItem?: TransformParentItem<T>;
  renderItem: React.FC<RenderItemProps<T>>;
  renderItemPlaceholder?: React.FC;
  renderPageHeader?: React.FC<RenderPageHeaderProps<T>>;
  renderPageFooter?: React.FC<RenderPageFooterProps<T>>;
  renderPagePlaceholder?: React.FC<RenderPagePlaceholderProps<T>>;
  onChange: TreeOnChange<T>;

  // Optional drag event hooks.
  onDragStart?: OnDragStartResponder;
  onDragUpdate?: OnDragUpdateResponder;
  onDragEnd?: OnDragEndResponder;
};

export type TreeContextType = {
  pages: Pages;
  items: Items;
  placeholderPosition: PlaceholderPosition;
  currentItemDepth: number | undefined;
  getShouldParentIncreaseDepth: GetShouldParentIncreaseDepth;
};

export type TreeChangeEvent<T = any> = {
  oldParentItem: Page | Item<T>;
  newParentItem: Page | Item<T>;
  children: Item['id'][];
  item: Item<T>;
};

export type TreeOnChange<T = any> = (params: TreeChangeEvent<T>) => void;
