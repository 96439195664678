import { useCallback, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { DateTime, Interval } from 'luxon';
import { TimesheetQueryData, TimesheetQueryVariables } from './types';

export const useTimesheet = () => {
  const [interval, setInterval] = useState(
    Interval.fromDateTimes(DateTime.now(), DateTime.now().plus({ days: 7 })),
  );

  const handleSetIntervalStart = useCallback((start: DateTime) => {
    setInterval((prevInterval) => prevInterval.set({ start }));
  }, []);

  const handleSetIntervalEnd = useCallback((end: DateTime) => {
    setInterval((prevInterval) => prevInterval.set({ end }));
  }, []);

  const { data } = useQuery<TimesheetQueryData, TimesheetQueryVariables>(
    gql`
      query GetTimesheetDuties($start: DateTime!, $end: DateTime!) {
        timesheetDuties(dateTimeStart: $start, dateTimeEnd: $end) {
          id
          events {
            id
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        start: interval.start,
        end: interval.end,
      },
    },
  );

  const duties = useMemo(() => {
    return data?.timesheetDuties || [];
  }, [data]);

  return {
    context: {
      duties,
      interval: {
        value: interval,
        setStart: handleSetIntervalStart,
        setEnd: handleSetIntervalEnd,
      },
    },
  };
};
