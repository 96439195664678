import { gql, useQuery } from '@apollo/client';
import { UsersQueryData } from './types';

export const useTrainingStaff = () => {
  const { data } = useQuery<UsersQueryData>(
    gql`
      query GetUsers {
        users {
          id
          name
          email
          externalId
          training {
            id
            qualification {
              id
              name
            }
          }
        }
      }
    `,
    { fetchPolicy: 'cache-and-network' },
  );

  return {
    users: data?.users || [],
  };
};
