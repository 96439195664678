import { TableRenderer } from '@/components/ui/table/table/Table';
import { useSubcontractorsRequirementsContext } from '../../context';

const RequirementsTable = () => {
  const { requirements } = useSubcontractorsRequirementsContext();
  return (
    <TableRenderer
      columns={[
        {
          id: 'name',
          accessorKey: 'name',
          header: 'Name',
        },
      ]}
      initialSorting={[{ id: 'name', desc: false }]}
      data={requirements}
    />
  );
};

export default RequirementsTable;
