import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useCommercialActivityContext } from '../../../CommercialActivity/context';
import { useCommercialEstimatesContext } from '../../context';

export const useEstimatesDelete = () => {
  const { activity } = useCommercialActivityContext();
  const { handlers, state } = useCommercialEstimatesContext();

  const [open, setOpen] = useState(false);

  const [delete_] = useMutation(
    gql`
      mutation DeleteCommercialEstimate($id: ID!) {
        deleteCommercialEstimate(commercialEstimateId: $id) {
          deleted
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnDelete = async () => {
    if (!state.selected) return;

    const { data, errors } = await delete_({
      variables: {
        id: state.selected,
      },
    });

    if (data) {
      await activity.refetch();
      setOpen(false);
      handlers.onSelect(undefined);
      toast.success('Estimate deleted');
    }

    if (errors) {
      console.error(errors);
      toast.error('Error deleting estimate');
    }
  };

  return {
    dialog: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onCancel: () => setOpen(false),
      onDelete: handleOnDelete,
    },
  };
};
