import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import { Loader } from '@/components/ui/loading';
import { useReportContext } from '../../context';
import ReportComponent from '../ReportComponent';
import { ReportDocumentProps } from './types';

const ReportDocument = (props: ReportDocumentProps) => {
  return (
    <ReportComponent
      editComponent={EditComponent}
      viewComponent={ViewComponent}
      props={props}
    />
  );
};

const EditComponent = ({
  ignoreOnMount = false,
  object,
  src,
  srcThumbnail,
  onError,
  ...props
}: ReportDocumentProps) => {
  const { theme, registerDocument } = useReportContext();

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const timeoutRef = useRef<NodeJS.Timeout>();
  const [retryToken, setRetryToken] = useState(0);

  const handleOnError = useCallback(() => {
    timeoutRef.current = setTimeout(
      () => {
        setRetryToken((prevRetryToken) => {
          if (prevRetryToken > 5) {
            setError(true);
            return prevRetryToken;
          }

          return prevRetryToken + 1;
        });
      },
      Math.max(1000, 1000 + Math.random() * 1000),
    );
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (ignoreOnMount) return;
    registerDocument({
      id: object.id,
      src,
      srcThumbnail,
    });
  }, [object?.id, src, registerDocument]);

  if (error) {
    return (
      <div
        style={{
          ...props.style,
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'start',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme.colors.border,
          borderRadius: 4,
          paddingLeft: 8,
          paddingBottom: 4,
        }}
      >
        <p>PDF</p>
      </div>
    );
  }

  return (
    <div className="relative">
      {!loaded && (
        <div
          className="absolute inset-0 bg-background"
          style={{
            ...props.style,
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'start',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.colors.border,
            borderRadius: 4,
            paddingLeft: 8,
            paddingBottom: 4,
          }}
        >
          <div className="flex items-center gap-x-1">
            <p>PDF</p>
            <div className="h-4">
              <Loader className="mx-auto h-4 w-4 animate-spin" />
            </div>
          </div>
        </div>
      )}

      <img
        key={retryToken}
        {...props}
        alt={`Front page of document ${object.name}`}
        src={srcThumbnail}
        onLoad={() => {
          setLoaded(true);
        }}
        onError={handleOnError}
      />
    </div>
  );
};

const ViewComponent = ({ style, srcThumbnail }: ReportDocumentProps) => {
  const { theme } = useReportContext();
  return (
    <View style={style}>
      <View
        style={{
          position: 'absolute',
          zIndex: -1,
          width: '100%',
          height: '100%',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme.colors.border,
          borderRadius: 4,
          padding: 4,
        }}
      >
        <Text
          style={{
            position: 'absolute',
            bottom: 4,
            left: 4,
            fontSize: 13,
          }}
        >
          PDF
        </Text>
      </View>
      <Image
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'relative',
          zIndex: 0,
          borderRadius: 4,
        }}
        cache={false}
        source={{
          uri: srcThumbnail,
          body: undefined,
          method: 'GET',
          headers: {
            Pragma: 'no-cache',
            'Cache-Control': 'no-cache',
            'Access-Control-Allow-Origin': '*',
          },
        }}
      />
    </View>
  );
};

export default ReportDocument;
