import React from 'react';
import { useUser } from 'lib/hooks';
import { Search } from 'lib/shared';
import { useTime } from './hooks';
import { getGreeting } from './utils';

const Landing = () => {
  const user = useUser();
  const time = useTime();
  return (
    <div className="flex h-full w-full flex-col bg-background bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-secondary">
      <div className="container mt-[180px] max-w-4xl">
        <div className="mb-8 text-center">
          <h1 className="mb-2 text-2xl font-bold">
            {getGreeting(time)}, {user?.name.split(' ').at(0)} 👋
          </h1>
          <p className="text-sm text-gray-400">
            {time.toFormat('cccc, d LLLL yyyy')}
          </p>
        </div>
        <Search />
      </div>
    </div>
  );
};

export default Landing;
