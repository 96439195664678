import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { cn } from '@/lib/utils';
import { UserPhotoProps } from './types';

const UserPhoto = ({
  children,
  className,
  avatarClassName,
  avatarFallbackClassName,
  user,
}: UserPhotoProps) => {
  const { instance } = useMsal();

  const [photo, setPhoto] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      setPhoto(null);

      if (!user) {
        return;
      }

      const { accessToken } = await instance.acquireTokenSilent({
        scopes: ['User.Read', 'User.Read.All'],
      });

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };

      const photoResponse = await fetch(
        `https://graph.microsoft.com/v1.0/users/${user.externalId ?? user.id}/photo/$value`,
        { headers },
      );

      if (photoResponse.ok) {
        const blob = await photoResponse.blob();
        setPhoto(URL.createObjectURL(blob));
      } else {
        setPhoto(null);
      }
    })();
  }, [user]);

  if (!photo) {
    if (children) {
      return <>{children}</>;
    }

    return (
      <Avatar key={user?.id} className={cn(avatarClassName)}>
        <AvatarFallback
          className={cn(
            'bg-background-secondary text-[10px] font-semibold group-hover:bg-secondary dark:bg-muted',
            avatarFallbackClassName,
          )}
        >
          {user?.name?.[0]}
        </AvatarFallback>
      </Avatar>
    );
  }

  return (
    <Avatar key={user?.id} className={cn('block', className)}>
      <AvatarImage
        className="block h-auto w-full rounded-full object-cover align-middle"
        src={photo}
        alt={`Profile picture for ${user?.name}`}
      />
    </Avatar>
  );
};

export default UserPhoto;
