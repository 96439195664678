import React, { useState } from 'react';
import { ArrowRight, Search } from 'lucide-react';
import { useNavigate } from 'react-router';
import { Outlet } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { input } from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { Input } from '@/components/ui/input';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable';
import { ScrollArea } from '@/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { z } from 'zod';
import { Link } from 'lib/shared';
import { useInitialQuery } from './hooks';
import { useStore } from './state';

const CreateTeam = ({ onCreate }: any) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [create, { loading }] = useMutation(gql`
    mutation CreateRosterTeam($name: String!) {
      createRosterTeam(rosterTeamName: $name) {
        rosterTeam {
          id
        }
      }
    }
  `);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm" className="h-6">
          Create team
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create team</DialogTitle>
          <DialogDescription>
            Create a new team to manage your roster.
          </DialogDescription>
        </DialogHeader>
        <FormRenderer
          fields={[
            input({
              name: 'name',
              label: 'Name',
              placeholder: 'e.g. Engineering',
              description: 'The name of the team',
              schema: z.string().min(3),
            }),
          ]}
          onSubmit={async (values) => {
            const { data } = await create({ variables: values });
            if (data) {
              onCreate();
              setOpen(false);
              navigate(
                `/hr/rosters/teams/${data.createRosterTeam.rosterTeam.id}`,
              );
              toast.success('Team created successfully');
            }
          }}
        >
          {({ onSubmit }) => (
            <div className="flex gap-x-2">
              <Button variant="outline" size="sm">
                Cancel
              </Button>
              <Button isLoading={loading} size="sm" onClick={onSubmit}>
                Create
              </Button>
            </div>
          )}
        </FormRenderer>
      </DialogContent>
    </Dialog>
  );
};

const HumanResourcesTeams = () => {
  const { rosterTeams, rosterTeam } = useStore((state) => ({
    rosterTeams: state.rosterTeams,
    rosterTeam: state.rosterTeam,
  }));
  const { refetch } = useInitialQuery();
  return (
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel className="h-full min-h-0" defaultSize={20} minSize={20}>
        <div className="flex h-full min-h-0 flex-col">
          <div className="flex h-[52px] flex-shrink-0 items-center gap-x-2 border-b px-4 py-2">
            <h1
              className={cn('text-xl font-bold', {
                'font-normal': !!rosterTeam,
              })}
            >
              Teams
            </h1>
            <CreateTeam onCreate={() => refetch()} />
            {rosterTeam && (
              <ArrowRight className="ml-auto h-4 w-4 text-muted-foreground" />
            )}
          </div>
          <div className="bg-background/95 px-4 pt-4 backdrop-blur supports-[backdrop-filter]:bg-background/60">
            <form>
              <div className="relative">
                <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input placeholder="Search" className="pl-8" />
              </div>
            </form>
          </div>
          <div className="min-h-0 grow">
            <ScrollArea className="h-full min-h-0 p-4">
              <div className="flex flex-col gap-y-4">
                {rosterTeams.map((team) => (
                  <Card key={team.id}>
                    <CardHeader className="p-4">
                      <CardTitle>{team.name}</CardTitle>
                    </CardHeader>
                    <CardContent className="p-4 pt-0">
                      <Link to={`/hr/rosters/teams/${team.id}`}>
                        <Button variant="link" className="p-0">
                          Manage
                        </Button>
                      </Link>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </ScrollArea>
          </div>
        </div>
      </ResizablePanel>
      <ResizableHandle />
      <ResizablePanel>
        <Outlet />
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};

export default HumanResourcesTeams;
