import React from 'react';
import {
  ArrowRight,
  CogIcon,
  FormInputIcon,
  Settings2,
  Wrench,
} from 'lucide-react';
import { Outlet } from 'react-router-dom';
import { Nav } from '@/components/ui/nav';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable';
import { useRouteMatch } from 'lib/hooks';
import { useClient } from '../../hooks';

const ClientSettings = () => {
  const client = useClient();
  const routeMatch = useRouteMatch([
    '/clients/:clientSlug/settings',
    '/clients/:clientSlug/settings/forms/*',
    '/clients/:clientSlug/settings/delivery/*',
  ]);
  return (
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel defaultSize={12} minSize={12}>
        <div className="flex h-[52px] flex-shrink-0 items-center border-b px-4 py-2">
          <h1 className="text-xl font-bold">Settings</h1>
          {routeMatch && (
            <ArrowRight className="ml-auto h-4 w-4 text-muted-foreground" />
          )}
        </div>
        <Nav
          links={[
            {
              to: client.paths.settings.root,
              title: 'General',
              icon: CogIcon,
              variant:
                routeMatch?.pattern.path === '/clients/:clientSlug/settings'
                  ? 'selected'
                  : 'unselected',
            },
            {
              to: client.paths.settings.forms,
              title: 'Forms',
              icon: FormInputIcon,
              variant: routeMatch?.pattern.path.startsWith(
                '/clients/:clientSlug/settings/forms',
              )
                ? 'selected'
                : 'unselected',
            },
            {
              to: client.paths.settings.delivery,
              title: 'Delivery',
              icon: Wrench,
              variant: routeMatch?.pattern.path.startsWith(
                '/clients/:clientSlug/settings/delivery',
              )
                ? 'selected'
                : 'unselected',
            },
            {
              to: client.paths.settings.supplyChain,
              title: 'Supply Chain',
              icon: Settings2,
              variant: routeMatch?.pattern.path.startsWith(
                '/clients/:clientSlug/settings/supply-chain',
              )
                ? 'selected'
                : 'unselected',
            },
          ]}
        />
      </ResizablePanel>
      <ResizableHandle />
      <ResizablePanel>
        <Outlet />
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};

export default ClientSettings;
