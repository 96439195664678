import { evaluateExpression } from '@enterprise-platform-ui/expressions';
import { CONDITIONS } from 'external/FormTemplateEditor';
import { BlockData } from 'external/types';

export const shouldRenderBase = (item: any, collapsed: string[]) => {
  return !collapsed.includes(item.id);
};

const evaluateCondition = (item: any, items: any, context: any) => {
  const parent = items[item.parentId];
  const condition =
    item.data.condition ??
    CONDITIONS[(parent.data as BlockData).dataType].find(
      ({ id }) => id === item.data.conditionId,
    );

  if (!condition) {
    return true;
  }

  try {
    const expressionResult = evaluateExpression(condition.expression || '', {
      value: context[parent.id],
      ...context,
      ...item.data.constants,
    });

    return expressionResult?.value;
  } catch (error) {
    console.error(error);
    return true;
  }
};

const parse = (
  item: any,
  items: any,
  values: any,
  collapsed: string[],
  ret: any[] = [],
  collected: any = {},
  shouldRender: (item: any, collapsed: string[]) => boolean,
) => {
  // if (!shouldRender(item, collapsed)) {
  //   return ret;
  // }

  const stack = (item: any, nextId: string, depthDelta = 0) => {
    return {
      ...items[nextId],
      path: `${item.path}::${nextId}`,
      depth: item.depth + depthDelta,
    };
  };

  if (item.type === 'PAGE') {
    for (const inner of item.children) {
      parse(
        stack(item, inner, 1),
        items,
        values,
        collapsed,
        ret,
        collected,
        shouldRender,
      );
    }
  }

  if (item.type === 'CONTAINER' && item.data.isRepeated) {
    const branches = values[item.path]?.value || [];

    for (const [index, branch] of branches.entries()) {
      const branchPath = `${item.path}::${branch}`;
      const branchChild = {
        id: branch,
        type: 'CONTAINER_REPEATING_BRANCH',
        path: branchPath,
        parentId: item.id,
        depth: item.depth + 1,
        children: item.children,
        data: { ...item.data, branchId: branch, branchIndex: index },
      };

      parse(
        branchChild,
        items,
        values,
        collapsed,
        ret,
        // We must create a copy of the collected values
        // for each branch, otherwise we will overwrite
        // the values for each branch.
        { ...collected },
        shouldRender,
      );
    }

    ret.push({
      id: `${item.id}::stub`,
      path: item.path,
      depth: item.depth + 1,
      parentId: item.id,
      type: 'CONTAINER_REPEATING_BRANCH_STUB',
      data: item.data,
      children: [],
    });

    if (branches.length === 0) {
      ret.push({
        id: `${item.id}::empty`,
        path: `${item.path}::empty`,
        depth: item.depth + 1,
        parentId: item.id,
        type: 'CONTAINER_REPEATING_EMPTY',
        data: item.data,
        children: [],
      });
    }
  }

  if (item.type === 'CONTAINER_REPEATING_BRANCH') {
    ret.push({
      id: item.id,
      type: 'CONTAINER_REPEATING_BRANCH',
      path: item.path,
      parentId: item.id,
      depth: item.depth,
      children: item.children,
      data: item.data,
    });

    if (!collapsed.includes(item.id)) {
      for (const child of item.children) {
        parse(
          stack(item, child),
          items,
          values,
          collapsed,
          ret,
          collected,
          shouldRender,
        );
      }
    }
  }

  if (item.type === 'CONTAINER' && !item.data.isRepeated) {
    ret.push({
      id: item.id,
      type: 'CONTAINER',
      path: item.path,
      parentId: item.id,
      depth: item.depth,
      children: item.children,
      data: item.data,
    });

    if (!collapsed.includes(item.id)) {
      for (const child of item.children) {
        parse(
          stack(item, child, 1),
          items,
          values,
          collapsed,
          ret,
          collected,
          shouldRender,
        );
      }
    }
  }

  if (item.type === 'BLOCK') {
    ret.push({
      id: item.id,
      type: 'BLOCK',
      path: item.path,
      parentId: item.id,
      depth: item.depth + 1,
      children: item.children,
      data: item.data,
    });

    collected[item.id] = values[item.path];

    for (const child of item.children) {
      parse(
        stack(item, child),
        items,
        values,
        collapsed,
        ret,
        collected,
        shouldRender,
      );
    }
  }

  if (item.type === 'LOGIC') {
    if (evaluateCondition(item, items, collected)) {
      for (const child of item.children) {
        parse(
          stack(item, child),
          items,
          values,
          collapsed,
          ret,
          collected,
          shouldRender,
        );
      }
    }
  }

  return ret;
};

export const getPages = (
  template: { pageOrder: string[]; items: Record<string, any> },
  values: any,
  collapsed: string[],
  shouldRender: (item: any, collapsed: string[]) => boolean,
) => {
  const items = template.items;
  const pageMap = template.pageOrder
    .map((pageId) => items[pageId])
    .reduce(
      (ret, page) => ({
        ...ret,
        [page.id]: {
          ...page,
          items: parse(
            {
              ...page,
              path: `root::${page.id}`,
              depth: -1,
              parentId: 'root',
            },
            items,
            values,
            collapsed,
            [],
            {},
            shouldRender,
          ),
        },
      }),
      {},
    );
  return {
    pageMap,
    pages: Object.values(pageMap) as any[],
  };
};

export const usePages = (
  template: { pageOrder: string[]; items: Record<string, any> },
  values: any,
  collapsed: string[],
  shouldRender: (item: any, collapsed: string[]) => boolean,
) => {
  return getPages(template, values, collapsed, shouldRender);
};
