import React from 'react';
import { TableRenderer } from '@/components/ui/table/table/Table';
import { cn } from '@/lib/utils';
import { DateTime } from 'luxon';
import { useCommercialContractContext } from '../../context';
import PrelimsCreate from './components/PrelimsCreate';

const ContractPrelims = () => {
  const { contract } = useCommercialContractContext();
  if (!contract) return null;
  return (
    <div>
      <div className="flex justify-between">
        <div>
          <p className="mb-1 text-sm font-bold">Prelim factors</p>
          <p className="text-xs text-gray-400">
            View the active prelim and past prelims for this contract.
          </p>
        </div>
      </div>
      <TableRenderer
        filter="factor"
        columns={[
          {
            id: 'factor',
            accessorKey: 'factor',
            header: 'Factor',
            cell: ({ row }) => {
              const active = contract.activePrelim?.id === row.original.id;
              return (
                <div
                  className={cn({
                    'font-semibold': active,
                    'opacity-75': !active,
                  })}
                >
                  {((row.original.factor - 1) * 100).toFixed(2)}%{' '}
                  {active && '(Active)'}
                </div>
              );
            },
          },
          {
            id: 'dateTimeCreated',
            accessorKey: 'dateTimeCreated',
            header: 'Valid from',
            cell: ({ row }) => {
              return (
                <div>
                  {DateTime.fromISO(
                    row.original.dateTimeCreated,
                  ).toLocaleString(DateTime.DATETIME_MED)}
                </div>
              );
            },
          },
        ]}
        data={contract.prelims}
        topRight={({ columnsSelector }) => (
          <>
            {columnsSelector}
            <PrelimsCreate className="ml-2" />
          </>
        )}
      />
    </div>
  );
};

export default ContractPrelims;
