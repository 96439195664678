import { gql, useQuery } from '@apollo/client';
import { useUser } from 'lib/hooks';
import { User } from 'lib/types';
import { UserQueryData, UserQueryVariables } from './types';

export const useUserProfile = () => {
  const user = useUser();

  const { data, refetch } = useQuery<UserQueryData, UserQueryVariables>(
    gql`
      query GetUser($id: ID!) {
        user(userId: $id) {
          id
          initials
          name
          email
          externalId
        }
      }
    `,
    {
      skip: !user?.id,
      variables: {
        id: user?.id,
      },
    },
  );

  if (!data) {
    return {
      context: {
        user: null,
      },
    };
  }

  return {
    context: {
      user: {
        ...data.user,
        refetch,
      } as User,
    },
  };
};
