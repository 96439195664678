import { Report } from 'lib/shared';
import { CommercialType } from 'lib/types';
import { useQuoteRendererContext } from '../../../../context';
import { BodyLineProps } from './types';

const BodyLine = ({ line }: BodyLineProps) => {
  const { theme } = useQuoteRendererContext();
  return (
    <Report.View style={{ display: 'flex', flexDirection: 'row' }}>
      <Report.View style={{ width: '40%' }}>
        {(line.type === CommercialType.Prelim ||
          line.type === CommercialType.Labor ||
          line.type === CommercialType.Subcontractor) && (
          <Report.Text
            style={{
              fontSize: 12,
              color: theme.colors.title,
            }}
          >
            {line.rate?.description}
          </Report.Text>
        )}
        {(line.type === CommercialType.Material ||
          line.type === CommercialType.Equipment) && (
          <Report.Text
            style={{
              fontSize: 12,
              color: theme.colors.title,
            }}
          >
            {line.description}
          </Report.Text>
        )}
        {line.descriptionEstimate && (
          <Report.Text
            style={{
              fontSize: 11,
              color: theme.colors.subtitle,
              marginTop: 4,
              paddingRight: 8,
            }}
          >
            {line.descriptionEstimate}
          </Report.Text>
        )}
      </Report.View>
      <Report.View style={{ width: '15%' }}>
        <Report.Text
          style={{
            fontSize: 12,
            color: theme.colors.title,
          }}
        >
          {line.unitDisplay}
        </Report.Text>
      </Report.View>
      <Report.View style={{ width: '15%' }}>
        <Report.Text
          style={{
            fontSize: 12,
            color: theme.colors.title,
          }}
        >
          {line.estimatedQuantity?.toFixed(2)}
        </Report.Text>
      </Report.View>
      <Report.View style={{ width: '15%' }}>
        <Report.Text
          style={{
            fontSize: 12,
            color: theme.colors.title,
          }}
        >
          {line.estimatedUnitValueWithUplift?.toFixed(2)}
        </Report.Text>
      </Report.View>
      <Report.View style={{ width: '15%' }}>
        <Report.Text
          style={{
            fontSize: 12,
            color: theme.colors.title,
            textAlign: 'right',
          }}
        >
          {line.estimatedTotalValue?.toFixed(2)}
        </Report.Text>
      </Report.View>
    </Report.View>
  );
};

export default BodyLine;
