import React from 'react';
import { ChevronRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { EditableAutoSave } from 'lib/shared';
import { CommercialType } from 'lib/types';
import { useCommercialEstimateContext } from '../../context';
import EstimateTable from '../EstimateTable';
import PrelimsContractFactor from './components/PrelimsContractFactor';
import PrelimsConvertToContract from './components/PrelimsConvertToContract';
import PrelimsConvertToExplicit from './components/PrelimsConvertToExplicit';
import { useEstimatePrelims } from './hooks';

const EstimatePrelims = () => {
  const { estimate } = useCommercialEstimateContext();
  const { state } = useEstimatePrelims();

  if (estimate?.contractPrelimFactor === null) {
    return (
      <EstimateTable type={CommercialType.Prelim} color="#ffa600">
        <div className="flex grow items-center justify-between pr-10">
          <p className="pl-2 font-bold">Prelims estimated value</p>
          <PrelimsConvertToContract />
        </div>
      </EstimateTable>
    );
  }

  return (
    <div className="overflow-hidden rounded-md border bg-background">
      <div className="grid h-full grid-cols-7 items-center justify-between">
        <div className="col-span-3 flex h-full items-center border-r">
          <Button
            className="size-8 flex-shrink-0 rounded-none p-0"
            variant="ghost"
            onClick={state.onToggle}
          >
            <ChevronRight
              className={cn('size-4', state.open && 'rotate-90 transform')}
            />
          </Button>
          <p className="pl-2 text-sm font-bold">Contract prelim factor</p>
        </div>
        <div className="col-span-1 flex h-full items-center gap-x-2 whitespace-nowrap border-r pl-2">
          <p className="text-xs font-semibold">Value (% of cost):</p>
        </div>
        <div className="col-span-1 h-full border-r">
          <PrelimsContractFactor />
        </div>
        <div className="col-span-2 flex justify-end pr-2">
          <PrelimsConvertToExplicit />
        </div>
      </div>
      {state.open && (
        <div className="border-t bg-background-secondary">
          <ContractPrelimDescription />
        </div>
      )}
    </div>
  );
};

const ContractPrelimDescription = () => {
  const { estimate, handlers } = useCommercialEstimateContext();
  return (
    <div className="grid grid-cols-12 p-4">
      <div className="col-span-2">
        <p className="text-sm font-medium">Notes</p>
        <p className="text-xs dark:text-gray-400">Internal only</p>
      </div>
      <div className="col-span-10">
        <EditableAutoSave
          value={estimate?.contractPrelimFactorDescription}
          onChange={async (value) => {
            await handlers.onUpdate(
              'contract_prelim_factor_description',
              value,
            );
          }}
        />
      </div>
    </div>
  );
};

export default EstimatePrelims;
