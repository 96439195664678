import { createContext, useContext } from 'react';
import { StoreApi, create } from 'zustand';

const StoreContext = createContext(undefined as any);
export const StoreProvider = StoreContext.Provider;

const useParentStore = <T = any>() =>
  useContext<ReturnType<typeof create<StoreApi<T>>>>(StoreContext);

export default useParentStore;
