import { PlatformObject } from './platform';
import { User } from './users';

export type Training = {
  id: string;
  user: User;
  qualification: TrainingQualification;
  certificates: TrainingCertificate[];
  event?: TrainingEvent;
  dateValid?: string;
  dateExpiry?: string;
  data: any;
};

export type TrainingCertificate = {
  id: string;
  sourceObject: PlatformObject;
};

export type TrainingEvent = {
  id: string;
};

export type TrainingQualification = {
  id: string;
  logo?: PlatformObject;
  color: string;
  name: string;
  description: string;
  fields: any[];
  data: any;
  children: TrainingQualification[];
  refetch: () => Promise<any>;
};
