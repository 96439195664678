import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  custom,
  input,
} from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { z } from 'zod';
import { LocationPicker } from 'lib/shared';
import { Location } from 'lib/types';
import { useLocationsCreate } from './hooks';

const LocationsCreate = () => {
  const { sheet, handlers } = useLocationsCreate();
  return (
    <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
      <SheetTrigger asChild>
        <Button className="pl-2" size="xs" variant="link">
          <Plus className="mr-1 size-4" />
          Create location
        </Button>
      </SheetTrigger>
      <SheetContent className="flex h-full min-h-0 flex-col p-0">
        <SheetHeader className="p-4">
          <SheetTitle>Create Location</SheetTitle>
          <SheetDescription>Create a new location</SheetDescription>
        </SheetHeader>
        <ScrollArea className="min-h-0 flex-grow">
          <FormRenderer
            className="p-4 pt-0"
            fields={[
              input({
                name: 'name',
                label: 'Name',
                schema: z.string().min(1),
              }),
              custom({
                name: 'location',
                schema: z.any(),
                defaultValue: undefined,
                render: ({ formField }) => {
                  return (
                    <LocationEditor
                      value={formField.value}
                      onChange={formField.onChange}
                    />
                  );
                },
              }),
            ]}
            onSubmit={handlers.onSubmit}
          >
            {({ onSubmit }) => (
              <div className="col-span-2 pt-2">
                <Button className="w-full" size="sm" onClick={onSubmit}>
                  Create
                </Button>
              </div>
            )}
          </FormRenderer>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

const LocationEditor = ({
  value,
  onChange,
}: {
  value?: Location;
  onChange: (value: Location | undefined) => void;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <LocationPicker
      value={value}
      onChange={(value) => {
        onChange(value);
        setOpen(false);
      }}
    >
      <div>{JSON.stringify(value)}</div>
      <Button onClick={() => setOpen(true)}>
        <Button>Select Location</Button>
      </Button>
      {open && <LocationEditorDialog onClose={() => setOpen(false)} />}
    </LocationPicker>
  );
};

const LocationEditorDialog = ({ onClose }: { onClose: () => void }) => {
  const { handleReset } = LocationPicker.useContext();
  return (
    <div className="fixed inset-4 right-[400px] grid grid-cols-12">
      <div className="col-span-5">
        <LocationPicker.Search />
      </div>
      <div className="col-span-7">
        <LocationPicker.Map />
      </div>
      <LocationPicker.Confirm />
      <div className="absolute right-3 top-3">
        <LocationPicker.Close
          onClose={() => {
            onClose();
            handleReset();
          }}
        />
      </div>
    </div>
  );
};

export default LocationsCreate;
