import { useMemo, useState } from 'react';
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useClientTodosContext } from '../context';
import { columns } from './table';

export const useTodosTable = () => {
  const { todos } = useClientTodosContext();

  const data = useMemo(() => todos, [todos]);

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'urgency',
      desc: true,
    },
  ]);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row.id,
    enableSorting: true,
    enableMultiSort: false,
    state: { sorting },
    onSortingChange: setSorting,
  });

  return {
    table,
  };
};
