import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { CompanyMembershipsQueryData } from './types';

export const useCompanyMemberships = () => {
  const { data, refetch } = useQuery<CompanyMembershipsQueryData>(gql`
    query GetCompanyMemberships {
      companyMemberships {
        id
        logo {
          id
          type
          name
          size
          data
        }
        name
        description
        dateTimeExpiry
        document {
          id
          name
          size
          type
          data
        }
      }
    }
  `);

  const memberships = useMemo(() => data?.companyMemberships || [], [data]);

  return {
    context: {
      memberships,
      refetch,
    },
  };
};
