import { cn } from '@/lib/utils';
import PolicyCreate from '../PolicyCreate';
import PolicyUpdate from '../PolicyUpdate';
import { usePolicyRequirement } from './hooks';
import { PolicyRequirementProps } from './types';

const PolicyRequirement = ({ requirement }: PolicyRequirementProps) => {
  const { policy } = usePolicyRequirement(requirement);
  return (
    <div
      className={cn('border-b border-l-4 p-4', {
        'border-l-destructive': !policy,
      })}
    >
      <div>{requirement.name}</div>
      {policy ? (
        <div>
          {policy?.id}
          <PolicyUpdate requirement={requirement} policy={policy} />
        </div>
      ) : (
        <div>Not met</div>
      )}
      <PolicyCreate requirement={requirement} />
    </div>
  );
};

export default PolicyRequirement;
