import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { PlatformTodo } from 'lib/types';
import TodoUrgency from '../../../../../external/Todos/components/TodoUrgency';

export const columns: ColumnDef<PlatformTodo>[] = [
  {
    id: 'urgency',
    header: 'Urgency',
    accessorKey: 'urgency',
    sortingFn: 'basic',
    meta: { span: 1, button: true },
    cell: ({ row }) => {
      return <TodoUrgency urgency={row.original.urgency} />;
    },
  },
  {
    id: 'description',
    header: 'Description',
    accessorKey: 'description',
    meta: { span: 4 },
    cell: ({ row }) => {
      return (
        <p className="line-clamp-1 text-xs dark:text-gray-300">
          {row.original.title}
        </p>
      );
    },
  },
  {
    id: 'rest',
    header: '',
    meta: { span: 7 },
  },
];
