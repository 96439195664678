import React from 'react';
import { MultipleSelector } from '@/components/ui/multi-select';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { ChoiceSelectorProps } from './types';

const ChoiceSelector = ({
  value,
  isMulti = false,
  options,
  onChange,
}: ChoiceSelectorProps) => {
  const selected = value ?? [];
  const selectedOptions = options.filter((option) =>
    selected.includes(option.value),
  );

  if (isMulti) {
    return (
      <MultipleSelector
        options={options}
        value={selectedOptions}
        onChange={(options) => {
          onChange(options.map((option) => option.value));
        }}
      />
    );
  }

  return (
    <Select value={value?.[0]} onValueChange={onChange}>
      <SelectTrigger className="w-full">
        <SelectValue placeholder="Select" />
      </SelectTrigger>
      <SelectContent>
        {options.map((option) => (
          <SelectItem
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default ChoiceSelector;
