import React from 'react';
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import { ListTodoIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  NavigationMenuContent,
  NavigationMenuItem,
} from '@/components/ui/navigation-menu';
import { ScrollArea } from '@/components/ui/scroll-area';
import Todos from '../../../../../../external/Todos';
import { useTodos } from '../../../../../../external/Todos/hooks';
import { TodosVariant } from '../../../../../../external/Todos/types';

const MenuTodos = () => {
  const { openTodos } = useTodos();
  return (
    <NavigationMenuItem>
      <NavigationMenuPrimitive.Trigger
        asChild
        onPointerMove={(event) => event.preventDefault()}
        onPointerLeave={(event) => event.preventDefault()}
      >
        <Button className="relative" variant="outline" size="icon" disabled>
          <ListTodoIcon className="w-5 text-gray-700 dark:text-primary" />
          {openTodos.length > 0 && (
            <div className="absolute bottom-[-2px] bg-background px-[4px]">
              <div
                className="h-[2px] max-w-4 rounded-lg bg-destructive"
                style={{ width: openTodos.length * 16 }}
              />
            </div>
          )}
        </Button>
      </NavigationMenuPrimitive.Trigger>
      <NavigationMenuContent>
        <div className="w-[500px]">
          <div className="flex p-4 font-normal">
            <div className="flex grow flex-col space-y-2">
              <p className="text-sm font-medium leading-none">Todos</p>
              <p className="text-xs leading-none text-muted-foreground">
                {openTodos.length} open todos
              </p>
            </div>
            <div>
              <Button variant="outline" size="sm">
                Mark all as read
              </Button>
            </div>
          </div>
          <ScrollArea className="h-[400px]">
            <Todos
              variant={TodosVariant.CardList}
              scope="clients::client::bexley-council"
              status="OPEN"
            />
          </ScrollArea>
          <div className="p-4">
            <Button className="w-full" size="sm" variant="outline">
              View all todos
            </Button>
          </div>
        </div>
      </NavigationMenuContent>
    </NavigationMenuItem>
  );
};

export default MenuTodos;
