import React from 'react';
import { Button } from '@/components/ui/button';
import {
  color,
  image,
  input,
  textarea,
} from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { z } from 'zod';
import { qualificationFields } from '../../fields';
import { QualificationEditorProps } from './types';

const QualificationEditor = ({
  qualification,
  onClose,
  onSubmit,
  onDelete,
}: QualificationEditorProps) => {
  return (
    <div className="h-full min-h-0 bg-secondary bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-background">
      <ScrollArea className="h-full min-h-0">
        <div className="grid-area-[test] container flex w-[900px] flex-col gap-y-4 py-8">
          <div className="relative rounded-lg border-2 border-dashed bg-background p-4">
            <FormRenderer
              className="gap-y-4 space-y-0"
              style={{
                display: 'grid',
                gridAutoColumns: '1fr',
                gridTemplateRows: '1fr 1fr',
                gridTemplateAreas: `
                  "logo        color"
                  "name        name" 
                  "description description"
                  "fields      fields"
                  "controls    controls"
                `,
              }}
              fields={[
                image({
                  style: { gridArea: 'logo' },
                  name: 'logo',
                  label: 'Logo',
                  schema: z.any(),
                  defaultValue: qualification?.logo as any,
                }),
                color({
                  style: { gridArea: 'color' },
                  name: 'color',
                  label: 'Color',
                  defaultValue: qualification?.color,
                  schema: z.string(),
                }),
                input({
                  style: { gridArea: 'name' },
                  name: 'name',
                  label: 'Name',
                  schema: z.string().min(1),
                  defaultValue: qualification?.name,
                }),
                textarea({
                  style: { gridArea: 'description' },
                  name: 'description',
                  label: 'Description',
                  schema: z.string().min(1),
                  defaultValue: qualification?.description,
                }),
                qualificationFields(qualification),
              ]}
              onSubmit={(values) => onSubmit(values as any)}
            >
              {({ form, onSubmit }) => (
                <div style={{ gridArea: 'controls' }}>
                  <Separator className="my-4" />
                  <div className="flex justify-between">
                    <Button
                      size="sm"
                      variant="outline"
                      confirm={
                        form.formState.isDirty ? 'Are you sure?' : undefined
                      }
                      onClick={() => {
                        onClose();
                        form.reset();
                      }}
                    >
                      Cancel
                    </Button>
                    <div className="flex gap-x-2">
                      {qualification && onDelete && (
                        <Button
                          size="sm"
                          variant="destructive"
                          confirm="Are you sure?"
                          onClick={onDelete}
                        >
                          Delete qualification
                        </Button>
                      )}
                      <Button
                        size="sm"
                        disabled={!form.formState.isValid}
                        onClick={onSubmit}
                      >
                        Save qualification
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </FormRenderer>
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};

export default QualificationEditor;
