import { useActionsContext } from '../../context';
import Action from '../Action';

const ActionsList = () => {
  const { actionSet } = useActionsContext();
  if (!actionSet) return null;
  return (
    <>
      {actionSet.actions.map((action) => (
        <Action key={action.id} id={action.id} />
      ))}
    </>
  );
};

export default ActionsList;
