import React from 'react';
import ReportSvg from '../ReportSvg';

const ReportLogo = (props: any) => {
  return (
    <ReportSvg viewBox="0 0 150.04 200" style={props.data?.style}>
      <ReportSvg.G transform="matrix(.30284 0 0 -.30284 -76.56 251.19)">
        <ReportSvg.G transform="translate(500.52 785.07)">
          <ReportSvg.Path
            fill="#106c12"
            d="m0 0c-71.42 0-125.4-27.637-160.42-82.142-37.347-58.11-42.924-129.16-42.924-166.33 0-71.816 23.492-153.43 62.841-218.32 40.42-66.653 91.633-104.88 140.51-104.88s100.09 38.227 140.51 104.88c39.35 64.89 62.843 146.5 62.843 218.32 0 74.71-19.815 248.48-203.35 248.48m0-616.04c-65.705 0-129.08 44.833-178.44 126.24-43.377 71.528-69.272 161.74-69.272 241.32 0 42.044 6.491 122.68 49.967 190.32 43.106 67.071 111.49 102.52 197.75 102.52 88.222 0 157.27-35.505 199.67-102.68 41.805-66.219 48.046-147.57 48.046-190.17 0-79.58-25.897-169.8-69.272-241.32-49.368-81.41-112.74-126.24-178.44-126.24"
          />
        </ReportSvg.G>
        <ReportSvg.G transform="translate(581.54 421.79)">
          <ReportSvg.Path
            fill="#106c12"
            d="m0 0c-3.052 7.373-5.807 14.923-8.241 22.568 2.434-7.645 5.189-15.195 8.241-22.568m39.413-64.972c11.047-12.436 23.233-22.492 36.228-29.168 3.713-1.907 7.491-3.539 11.328-4.871v-1e-3c-3.837 1.333-7.615 2.965-11.328 4.872-12.995 6.676-25.181 16.732-36.228 29.168-6.313 7.107-12.254 14.991-17.762 23.466 5.508-8.475 11.449-16.359 17.762-23.466m-278.46-29.168c9.282 4.769 18.152 11.262 26.488 19.115-8.336-7.854-17.206-14.346-26.488-19.115-3.712-1.907-7.491-3.539-11.328-4.872v1e-3c3.837 1.332 7.616 2.964 11.328 4.871m53.99 52.634c-5.508-8.475-11.448-16.359-17.761-23.466 6.313 7.107 12.253 14.991 17.761 23.466m306.8 301.34c-14.372 33.948-34.251 61.791-59.084 82.755l-5.832 4.924-1.729-7.434c-9.686-41.629-40.722-111.47-139.74-111.47-92.562 0-126.51 57.299-138.69 105.37l-1.936 7.644-5.773-5.372c-21.622-20.122-39.346-45.8-52.682-76.321l-2.955-6.765 7.379 0.227c1.47 0.045 2.964 0.067 4.48 0.067 46.023 0 80.402-17.49 102.18-51.987 22.151-35.085 25.459-79.035 25.459-102.11 0-38.384-11.073-81.656-30.381-118.72-20.27-38.912-46.672-65.632-74.343-75.243l-5.21-1.808 2.643-4.841c18.3-33.513 39.337-61.688 62.53-83.742l7.781-7.4 0.107 10.738c0.434 43.561 12.174 92.676 31.402 131.38 20.235 40.733 46.095 64.094 70.949 64.094 24.863 0 50.726-23.361 70.957-64.094 19.224-38.702 30.96-87.817 31.394-131.38l0.107-10.738 7.782 7.4c23.192 22.054 44.23 50.229 62.529 83.742l2.643 4.841-5.21 1.808c-27.671 9.611-54.073 36.331-74.343 75.243-19.307 37.063-30.381 80.335-30.381 118.72 0 22.721 3.444 66.197 26.508 102.08 22.186 34.517 56.212 52.019 101.13 52.019 2.376 0 4.167-0.034 5.806-0.111l7.414-0.349z"
          />
        </ReportSvg.G>
        <ReportSvg.G transform="translate(546.02 707.42)">
          <ReportSvg.Path
            fill="#106c12"
            d="m0 0c0-23.89-20.368-43.257-45.493-43.257-25.126 0-45.493 19.367-45.493 43.257 0 23.891 20.367 43.257 45.493 43.257 25.125 0 45.493-19.366 45.493-43.257"
          />
        </ReportSvg.G>
      </ReportSvg.G>
    </ReportSvg>
  );
};

export default ReportLogo;
