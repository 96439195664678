import { gql, useQuery } from '@apollo/client';
import { useClient } from '../../../../../hooks';
import { JobCategoriesQueryData, JobCategoriesQueryVariables } from './types';

export const useDeliveryCategories = () => {
  const client = useClient();

  const { data, refetch } = useQuery<
    JobCategoriesQueryData,
    JobCategoriesQueryVariables
  >(
    gql`
      query GetPriorities($clientId: ID!) {
        jobCategories(clientId: $clientId) {
          id
          name
          code
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      variables: { clientId: client.id },
    },
  );

  return {
    categories: data?.jobCategories || [],
    refetch,
  };
};
