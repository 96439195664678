import { createContext, useContext } from 'react';
import * as Y from 'yjs';
import { useCommercialEstimate } from './hooks';

export const CommercialEstimateContext = createContext(
  {} as ReturnType<typeof useCommercialEstimate>['context'] & {
    store: any;
    doc: Y.Doc;
  },
);

export const useCommercialEstimateContext = () => {
  return useContext(CommercialEstimateContext);
};
