import produce from 'immer';
import { DateTime } from 'luxon';
import { StateCreator, create } from 'zustand';
import { RosterDuty, RosterReview, RosterTeam, User } from 'lib/types';
import { RosterValidationResults } from './components/RosterDutyRow/types';
import {
  RosterDutyTableState,
  createRosterDutyTableSlice,
} from './components/RosterDutyTable/state';
import { SubmitRosterMutationData } from './components/RosterSubmit/types';
import {
  InitialQueryData,
  RosterDutiesQueryData,
  UpdateRosterTeamMutationData,
} from './types';

export type RosterValues = {
  hasLoaded: boolean;

  loading: Record<string, boolean>;

  dateTimeStart: DateTime;
  dateTimeEnd: DateTime;

  user: User | null;

  rosterTeams: RosterTeam[];
  rosterTeam: RosterTeam | null;

  rosterDuties: RosterDuty[];
  rosterDutiesInMonth: RosterDuty[];
  rosterValidationResults: RosterValidationResults;

  rosterReview: RosterReview | null;

  stage: number;
};

export type RosterActions = {
  reset: () => void;

  startLoading: (name: string) => void;
  stopLoading: (name: string) => void;

  initialQueryOnCompleted: (
    dateTimeStart: DateTime,
    dateTimeEnd: DateTime,
    data: InitialQueryData,
  ) => void;

  userOnChange: (user: User, data: RosterDutiesQueryData) => void;

  timeIntervalOnChange: (
    dateTimeStart: DateTime,
    dateTimeEnd: DateTime,
    data: RosterDutiesQueryData,
  ) => void;

  submitRosterOnCompleted: (data: SubmitRosterMutationData) => void;

  updateRosterTeamOnCompleted: (data: UpdateRosterTeamMutationData) => void;
};

export type RosterState = RosterValues & RosterActions;

const initialValues: RosterValues = {
  hasLoaded: false,

  loading: {
    table: false,
  },

  dateTimeStart: DateTime.local(),
  dateTimeEnd: DateTime.local(),

  user: null,

  rosterTeams: [],
  rosterTeam: null,

  rosterDuties: [],
  rosterDutiesInMonth: [],
  rosterValidationResults: {
    fatigueIndices: {},
    riskIndices: {},
    exceedances: [],
  },

  rosterReview: null,

  stage: 0,
};

export const createRosterSlice: StateCreator<RosterState> = (set) => ({
  ...initialValues,

  startLoading: (name) =>
    set(
      produce<RosterState>((state) => {
        state.loading[name] = true;
      }),
    ),

  stopLoading: (name) =>
    set(
      produce<RosterState>((state) => {
        state.loading[name] = false;
      }),
    ),

  reset: () => set(() => initialValues),

  initialQueryOnCompleted: (
    dateTimeStart,
    dateTimeEnd,
    { rosterTeams, rosterTeam, rosterReview, rosterDutiesInMonth },
  ) =>
    set(
      produce<RosterState>((state) => {
        state.rosterTeams = rosterTeams;
        state.rosterTeam = rosterTeam;
        state.rosterDutiesInMonth = rosterDutiesInMonth;
        state.dateTimeStart = dateTimeStart;
        state.dateTimeEnd = dateTimeEnd;
        state.hasLoaded = true;
        state.loading.table = false;
        state.rosterReview = rosterReview ?? null;
      }),
    ),

  userOnChange: (user, { rosterDuties, rosterValidationResults }) =>
    set(
      produce<RosterState>((state) => {
        state.user = user;
        state.loading.table = false;
        state.rosterDuties = rosterDuties;
        state.rosterValidationResults = rosterValidationResults;
      }),
    ),

  timeIntervalOnChange: (
    dateTimeStart,
    dateTimeEnd,
    { rosterDuties, rosterValidationResults },
  ) =>
    set(
      produce<RosterState>((state) => {
        state.dateTimeStart = dateTimeStart;
        state.dateTimeEnd = dateTimeEnd;
        state.loading.table = false;
        state.rosterDuties = rosterDuties;
        state.rosterValidationResults = rosterValidationResults;
      }),
    ),

  submitRosterOnCompleted: ({ submitRoster: { rosterDuties } }) =>
    set(
      produce<RosterState>((state) => {
        state.rosterDuties = state.rosterDuties.map((rosterDuty) => ({
          ...rosterDuty,
          ...rosterDuties.find(({ id }) => id === rosterDuty.id),
        }));
      }),
    ),

  updateRosterTeamOnCompleted: ({ updateRosterTeam }) =>
    set(
      produce<RosterState>((state) => {
        state.rosterValidationResults = updateRosterTeam.validationResults;
        state.rosterTeam = {
          ...state.rosterTeam,
          ...updateRosterTeam.rosterTeam,
        };

        state.rosterDuties = state.rosterDuties.map((rosterDuty) => {
          const updatedRosterDuty = updateRosterTeam.rosterDuties.find(
            ({ id }) => id === rosterDuty.id,
          );
          if (!updatedRosterDuty) return rosterDuty;
          return { ...rosterDuty, settings: rosterDuty.settings };
        });
      }),
    ),
});

export const useStore = create<RosterState & RosterDutyTableState>()(
  (...args) => ({
    ...createRosterSlice(...args),
    ...createRosterDutyTableSlice(...args),
    ...{ stage: 0 },
  }),
);
