import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import HeaderDescription from './components/HeaderDescription';
import HeaderName from './components/HeaderName';
import HeaderNumber from './components/HeaderNumber';

const EstimateHeader = () => {
  return (
    <Card className={cn('relative overflow-hidden p-0', {})}>
      <div className="flex h-8 items-center border-b">
        <HeaderNumber />
        <HeaderName />
      </div>
      <HeaderDescription />
    </Card>
  );
};

export default EstimateHeader;
