import React from 'react';
import { DragDropContext } from '@hello-pangea/dnd';
import TreePage from './components/TreePage';
import { TreeContext } from './context';
import { useListeners } from './listeners';
import { TreeProps } from './types';
import { getPageMap } from './utils';

const Tree = ({
  pageOrder,
  items,
  collapsed = [],
  renderItem,
  renderItemPlaceholder,
  getShouldItemRender,
  getShouldItemNest,
  getShouldParentIncreaseDepth,
  getShouldIncludeSelf,
  transformParentItem,
  renderPageHeader,
  renderPageFooter,
  renderPagePlaceholder,
  onDragStart: onDragStartHook,
  onDragUpdate: onDragUpdateHook,
  onDragEnd: onDragEndHook,
  onChange,
}: TreeProps) => {
  const pages = getPageMap(
    pageOrder,
    items,
    getShouldItemRender,
    getShouldIncludeSelf,
  );

  const {
    placeholderPosition,
    currentItemDepth,
    onDragStart,
    onDragUpdate,
    onDragEnd,
  } = useListeners({
    pages,
    items,
    getShouldItemNest,
    getShouldParentIncreaseDepth,
    transformParentItem,
    onDragStartHook,
    onDragUpdateHook,
    onDragEndHook,
    onChange,
  });

  return (
    <TreeContext.Provider
      value={{
        pages,
        items,
        placeholderPosition,
        currentItemDepth,
        getShouldParentIncreaseDepth,
      }}
    >
      <DragDropContext
        onDragStart={onDragStart}
        onDragUpdate={onDragUpdate}
        onDragEnd={onDragEnd}
      >
        {Object.values(pages).map((page, index) => (
          <TreePage
            key={page.id}
            page={page}
            index={index}
            isCollapsed={collapsed.includes(page.id)}
            renderHeader={renderPageHeader}
            renderFooter={renderPageFooter}
            renderPlaceholder={renderPagePlaceholder}
            renderItem={renderItem}
            renderItemPlaceholder={renderItemPlaceholder}
          />
        ))}
      </DragDropContext>
    </TreeContext.Provider>
  );
};

export * from './context';
export * from './tracking';
export * from './types';
export * from './utils';
export default Tree;
