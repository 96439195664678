import {
  CSSProperties,
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { DefaultValues, UseFormReturn, useForm } from 'react-hook-form';
import { cn } from '@/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { ZodTypeAny, z } from 'zod';
import { FormControls } from '../form-controls/FormControls';
import {
  FormFieldRenderer,
  RendererField,
  RendererFieldsFormValues,
} from '../form-field-renderer/FormFieldRenderer';
import { Form } from '../form/Form';

const FormRendererContext = createContext({
  disabled: false,
  disable: () => {},
  enable: () => {},
});

export const useFormRendererContext = () => useContext(FormRendererContext);

export type FormRendererSubmitHandler<TFields extends RendererField[]> = (
  values: RendererFieldsFormValues<TFields>,
  form: UseFormReturn<RendererFieldsFormValues<TFields>, unknown, undefined>,
) => void | Promise<void>;

export interface FormRendererProps<TFields extends RendererField[]> {
  /**
   * Available fields: `input`, `select`, `multiSelect`, `custom`.
   */
  fields: TFields;
  className?: string;
  style?: CSSProperties;
  onSubmit?: FormRendererSubmitHandler<TFields>;
}

/**
 * Renders form based on provided fields. Available fields: `input`, `select`, `multiSelect`, `custom`.
 *
 * @example
 * import { useMemo } from "react";
 * import {
 *  input,
 *  FormRendererSubmitHandler
 * } from "@/ui/components/form/form-field-renderer/FormFieldRenderer"
 * import { FormRenderer } from "@/ui/components/form/form-renderer/FormRenderer"
 *
 * const MyForm = () => {
 *  const formFields = useMemo(() => [
 *   input({
 *    label: "Email",
 *    name: "email",
 *    schema: z.string().email()
 *   }),
 *   input({
 *    label: "Password",
 *    name: "password",
 *    schema: z.string().min(6)
 *   })
 *  ], [])
 *
 *  const handleSubmit: FormRendererSubmitHandler<typeof formFields> = (values) => {
 *    console.log(values); // { email: string, password: string }
 *  }
 *
 *  return <FormRenderer fields={formFields} onSubmit={handleSubmit} />
 * }
 */
export const FormRenderer = <TFields extends RendererField[]>({
  children,
  style,
  className,
  fields,
  onSubmit,
}: FormRendererProps<TFields> & {
  children?: FC<{
    form: UseFormReturn<RendererFieldsFormValues<TFields>>;
    rendererContext: {
      disabled: boolean;
      disable: () => void;
      enable: () => void;
    };
    onSubmit: () => void | Promise<void>;
  }>;
}) => {
  const formSchema = useMemo(() => {
    const zodObject: Record<string, ZodTypeAny> = {};

    fields.forEach((field) => {
      zodObject[field.name] = field.schema;
    });

    return z.object(zodObject);
  }, [fields]);

  const defaultValues = useMemo(() => {
    const values: Record<string, unknown> = {};

    fields.forEach((field) => {
      switch (field.type) {
        case 'input':
          values[field.name] = field.defaultValue || '';
          break;
        case 'number':
          values[field.name] = field.defaultValue || 0;
          break;
        case 'boolean':
          values[field.name] = field.defaultValue || false;
          break;
        case 'textarea':
          values[field.name] = field.defaultValue || '';
          break;
        case 'color':
          values[field.name] = field.defaultValue || '';
          break;
        case 'select':
          values[field.name] = field.defaultValue;
          break;
        case 'duration':
          values[field.name] = field.defaultValue;
          break;
        case 'time':
          values[field.name] = field.defaultValue;
          break;
        case 'date':
          values[field.name] = field.defaultValue;
          break;
        case 'datetime':
          values[field.name] = field.defaultValue;
          break;
        case 'file':
          values[field.name] = field.defaultValue;
          break;
        case 'files':
          values[field.name] = field.defaultValue ?? [];
          break;
        case 'image':
          values[field.name] = field.defaultValue;
          break;
        case 'custom':
          values[field.name] = field.defaultValue;
      }
    });

    return values;
  }, [fields]);

  const form = useForm({
    mode: 'onSubmit',
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues as DefaultValues<
      RendererFieldsFormValues<typeof fields>
    >,
  });

  const handleSubmit = (values: RendererFieldsFormValues<typeof fields>) => {
    return onSubmit?.(values, form);
  };

  const [disabled, setDisabled] = useState(false);
  const disable = useCallback(() => setDisabled(true), []);
  const enable = useCallback(() => setDisabled(false), []);
  const rendererContext = useMemo(
    () => ({ disabled, disable, enable }),
    [disabled, disable, enable],
  );

  return (
    <Form {...form}>
      <FormRendererContext.Provider value={rendererContext}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className={cn('space-y-4', className)}
          style={style}
        >
          {fields.map((field) => (
            <FormFieldRenderer key={field.name} field={field} form={form} />
          ))}
          {children ? (
            children({
              form,
              rendererContext,
              onSubmit: form.handleSubmit(handleSubmit),
            })
          ) : (
            <FormControls
              isDisabled={disabled || form.formState.isSubmitting}
            />
          )}
        </form>
      </FormRendererContext.Provider>
    </Form>
  );
};
