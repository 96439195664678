import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useCommercialActivityContext } from '../../../../context';
import {
  ConvertCommercialActivityToExplicitMutationData,
  ConvertCommercialActivityToExplicitMutationVariables,
} from './types';

export const usePrelimsConvertToExplicit = () => {
  const { activity } = useCommercialActivityContext();

  const [open, setOpen] = useState(false);

  const [convert] = useMutation<
    ConvertCommercialActivityToExplicitMutationData,
    ConvertCommercialActivityToExplicitMutationVariables
  >(
    gql`
      mutation ConvertToExplicitPrelim($id: ID!) {
        convertCommercialActivityToExplicitPrelim(commercialActivityId: $id) {
          commercialActivity {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnConvert = async () => {
    if (!activity) {
      console.error('No activity found');
      return;
    }

    const { data, errors } = await convert({
      variables: {
        id: activity.id,
      },
    });

    if (data) {
      await activity.refetch();
      setOpen(false);
      toast.success('Converted to explicit prelim');
    }

    if (errors) {
      toast.error('Failed to convert to explicit prelim');
    }
  };

  return {
    dialog: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onConvert: handleOnConvert,
    },
  };
};
