import React from 'react';
import ButtonTabs from '@/components/ui/button-tabs';
import SchedulerPending from '../SchedulerPending';
import ControlsDate from './components/ControlsDate';
import ControlsLastAction from './components/ControlsLastAction';
import ControlsResourceType from './components/ControlsResourceType';
import { SchedulerControlsProps } from './types';

const SchedulerControls = ({ disabled = false }: SchedulerControlsProps) => {
  return (
    <div className="relative flex items-center justify-between border-b bg-background p-2">
      <ControlsDate disabled={disabled} />
      <div className="flex items-center">
        <ControlsResourceType />
      </div>
      <div className="flex items-center gap-x-4">
        <ControlsLastAction />
        <SchedulerPending />
      </div>
    </div>
  );
};

export default SchedulerControls;
