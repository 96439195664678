import React from 'react';
import { TableRenderer } from '@/components/ui/table/table/Table';

const SeriesOverview = () => {
  return (
    <div className="flex h-full min-h-0 flex-col">
      <div className="flex h-[52px] flex-shrink-0 items-center justify-between border-b px-4 py-2">
        <h1 className="text-xl font-bold">Overview</h1>
      </div>
      <div className="min-h-0 grow">
        <div className="p-4">
          <TableRenderer
            columns={[
              {
                header: 'Reference',
                accessorKey: 'reference',
              },
            ]}
            data={[]}
          />
        </div>
      </div>
    </div>
  );
};

export default SeriesOverview;
