import React from 'react';
import { Loader } from '@/components/ui/loading';
import { cn } from '@/lib/utils';

const PickerLoader = () => {
  return (
    <div
      className={cn('absolute inset-0 z-10 opacity-100 transition-all', {
        'pointer-events-none opacity-0': context.mapReady,
      })}
    >
      <div className="flex h-full w-full items-center justify-center rounded-md border border-dashed bg-background">
        <div>
          <Loader className="dark:text-white-400 mx-auto mb-2 h-4 w-4 text-gray-400" />
          <p className="dark:text-white-400 text-xs text-gray-400">
            Loading map
          </p>
        </div>
      </div>
    </div>
  );
};

export default PickerLoader;
