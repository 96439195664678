import React from 'react';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  date,
  files,
  input,
  textarea,
} from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { z } from 'zod';
import { Empty } from 'lib/shared';
import { usePolicyCreate } from './hooks';
import { PolicyCreateProps } from './types';

const PolicyCreate = ({ requirement }: PolicyCreateProps) => {
  const { sheet, handlers } = usePolicyCreate();
  return (
    <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
      <SheetTrigger asChild>
        <Button className="pl-2" size="xs" variant="link">
          <Plus className="mr-1 size-4" />
          {requirement ? `Add ${requirement.name} policy` : `Add Policy`}
        </Button>
      </SheetTrigger>
      <SheetContent className="flex h-full min-h-0 w-[500px] max-w-[500px] flex-col p-0 sm:max-w-[500px]">
        <SheetHeader className="p-4 pb-0">
          <SheetTitle>Create Policy</SheetTitle>
          <SheetDescription>
            Create a new policy for the requirement
          </SheetDescription>
        </SheetHeader>
        {requirement ? (
          <div className="h-32 px-4">
            <div className="flex h-full items-center rounded-lg border p-4">
              <div>{requirement.name}</div>
            </div>
          </div>
        ) : (
          <div className="h-32 px-4">
            <Empty className="text-center" classNameInner="bg-secondary">
              <p className="mb-1">Other</p>
              <p>
                <span className="font-semibold">
                  No specific requirement selected
                </span>
              </p>
            </Empty>
          </div>
        )}
        <ScrollArea className="min-h-0 flex-grow border-t">
          <FormRenderer
            className="p-4"
            style={{
              display: 'grid',
              gridAutoColumns: '1fr',
              gridTemplateAreas: `
                "documents documents"
                "valid     expiry" 
                "notes     notes"
              `,
            }}
            fields={[
              files({
                style: { gridArea: 'documents' },
                name: 'documents',
                label: 'Documents',
                schema: z.array(z.any()) as any,
                description: 'Attach supporting documents',
                right: 500,
              }),
              date({
                style: { gridArea: 'valid' },
                name: 'dateValid',
                label: 'Valid from',
                schema: z.any(),
                timescape: true,
                description: 'Date the policy is valid from',
              }),
              date({
                className: 'text-end justify-self-end',
                style: { gridArea: 'expiry' },
                name: 'dateExpiry',
                label: 'Expires on',
                schema: z.any(),
                timescape: true,
                description: 'Date the policy expires',
              }),
              textarea({
                style: { gridArea: 'notes' },
                name: 'notes',
                label: 'Notes',
                schema: z.string().optional(),
                description: 'Additional notes',
              }),
            ]}
            onSubmit={handlers.onSubmit}
          >
            {({ onSubmit }) => (
              <div className="col-span-2 pt-2">
                <Button className="w-full" size="sm" onClick={onSubmit}>
                  Create
                </Button>
              </div>
            )}
          </FormRenderer>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

export default PolicyCreate;
