import { flexRender } from '@tanstack/react-table';
import { Card, CardContent } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import TimesheetLine from '../TimesheetLine';
import { useTimesheetTable } from './hooks';

const TimesheetTable = () => {
  const { table } = useTimesheetTable();
  return (
    <Card className="overflow-hidden">
      <CardContent className="px-0 pb-0">
        <div className="sticky top-0 border-b p-3">
          <p className="text-xs font-medium">Timesheet</p>
        </div>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id} className="h-8 text-[11px]">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                ))}
              </tr>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TimesheetLine key={row.id} row={row} />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow className="bg-muted/50">
              <TableCell colSpan={3} />
              <TableCell>
                <span className="text-xs font-semibold">Total</span>
              </TableCell>
              <TableCell className="pl-[12px] font-medium">0</TableCell>
              <TableCell />
            </TableRow>
          </TableFooter>
        </Table>
      </CardContent>
    </Card>
  );
};

export default TimesheetTable;
