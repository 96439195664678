import React from 'react';
import { ShieldCheck, X } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import { Button } from '@/components/ui/button';
import { FormControl, FormItem } from '@/components/ui/form';
import { CustomFieldRenderData } from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormLabel } from '@/components/ui/form/form-label/FormLabel';
import { useUploadFile } from 'lib/hooks';
import { Empty, PdfSheet } from 'lib/shared';
import { TrainingCertificate } from 'lib/types';
import { generateId } from 'lib/utils';

const FieldCertificates = ({ formField }: CustomFieldRenderData<any>) => {
  const uploadFile = useUploadFile();

  const handleFile = async ([file]: File[]) => {
    const platformObject = await uploadFile(file);
    formField.onChange([
      ...(formField.value ?? []),
      {
        id: generateId(),
        isNew: true,
        sourceObject: {
          id: platformObject.id,
          type: platformObject.type,
          size: platformObject.size,
          name: platformObject.name,
          data: platformObject.data,
        },
      },
    ]);
  };

  const dropzone = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop: handleFile,
  });

  const certificates = (formField.value ?? []) as TrainingCertificate[];

  return (
    <FormItem className="mt-4">
      <FormLabel>Certificates</FormLabel>
      <FormControl>
        <div>
          {certificates.length > 0 ? (
            (formField.value ?? []).map((certificate: TrainingCertificate) => (
              <div
                key={certificate.id}
                className="flex items-center gap-x-2 rounded-md border bg-background-secondary"
              >
                <ShieldCheck className="ml-2 size-4" />
                <PdfSheet
                  objectId={certificate.sourceObject.id}
                  extension
                  right={384}
                >
                  <Button variant="link" className="p-0">
                    <p className="line-clamp-1 flex-grow text-xs">
                      {certificate.sourceObject.name}
                    </p>
                  </Button>
                </PdfSheet>
                <Button
                  size="icon-sm"
                  variant="ghost"
                  onClick={(event) => {
                    event.preventDefault();
                    formField.onChange(
                      certificates.filter(
                        (inner) => inner.id !== certificate.id,
                      ),
                    );
                  }}
                >
                  <X className="size-4" />
                </Button>
              </div>
            ))
          ) : (
            <Empty className="mb-2 h-24 text-center">
              No certificates
              <Button
                size="xs"
                className="mt-2 w-full"
                variant="secondary"
                onClick={(event) => {
                  event.preventDefault();
                  dropzone.open();
                }}
              >
                Upload
              </Button>
            </Empty>
          )}
        </div>
      </FormControl>
    </FormItem>
  );
};

export default FieldCertificates;
