import { createContext, useContext } from 'react';
import { useSubcontractorReport } from './hooks';

export const SubcontractorReportContext = createContext(
  {} as ReturnType<typeof useSubcontractorReport>['context'],
);

export const useSubcontractorReportContext = () => {
  return useContext(SubcontractorReportContext);
};
