import * as React from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { CommercialUnit } from 'lib/types';
import { CommercialUnitFieldProps } from './types';

const CommercialUnitField = ({
  readOnly = false,
  value,
  valueDisplay,
  onValueChange,
}: CommercialUnitFieldProps) => {
  if (readOnly) {
    return (
      <div className="flex items-center px-2">
        <p className="text-sm">{valueDisplay}</p>
      </div>
    );
  }

  return (
    <Select value={value ? value : undefined} onValueChange={onValueChange}>
      <SelectTrigger className="h-8 rounded-none border-none py-0 pl-2 pr-1 text-sm hover:bg-accent">
        <SelectValue placeholder="Select" />
      </SelectTrigger>
      <SelectContent position="popper">
        <SelectItem value={CommercialUnit.Hour}>Hour</SelectItem>
        <SelectItem value={CommercialUnit.Day}>Day</SelectItem>
        <SelectItem value={CommercialUnit.Week}>Week</SelectItem>
        <SelectItem value={CommercialUnit.Month}>Month</SelectItem>
        <SelectItem value={CommercialUnit.Year}>Year</SelectItem>
        <SelectItem value={CommercialUnit.Unit}>Unit</SelectItem>
      </SelectContent>
    </Select>
  );
};

export default CommercialUnitField;
