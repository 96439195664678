import axios from 'axios';
import { PlatformObject } from 'lib/types';
import { generateId } from 'lib/utils';
import useCreatePlatformObjects from './useCreatePlatformObject';

const getObjectType = (name: string) => {
  const extension = name.split('.').reverse()?.[0];
  if (['jpg', 'jpeg', 'png', 'webp'].includes(extension.toLowerCase()))
    return 'IMAGE';
  return 'DOCUMENT';
};

const getObject = (
  object: PlatformObject,
  objects: (Omit<PlatformObject, 'sources' | 'data'> & { file: File })[],
) => {
  return objects.find(({ id }) => id === object.id) as PlatformObject & {
    file: File;
  };
};

const prepareFormData = (postData: any, file: File) => {
  const formData = new FormData();
  for (const key in postData.fields) {
    formData.append(key, postData.fields[key]);
  }
  formData.append('file', file);
  return formData;
};

const useUploadFiles = ({
  onCreated,
  onUploadProgress,
  onUploaded,
  onCompleted,
}: {
  onCreated?: (objects: PlatformObject[]) => void;
  onUploadProgress?: (id: string, progress: number) => void;
  onUploaded?: (id: string) => void;
  onCompleted?: (objects: PlatformObject[]) => void;
}) => {
  const createPlatformObjects = useCreatePlatformObjects();

  return async (files: File[]) => {
    const objects = files.map(
      (file) =>
        ({
          id: generateId(),
          type: getObjectType(file.name),
          name: file.name,
          size: file.size,
          file,
        }) as PlatformObject & { file: File },
    );

    const platformObjects = await createPlatformObjects(
      objects.map((object) => ({
        id: object.id,
        type: object.type,
        name: object.name,
        size: object.size,
        content_type: object.file.type,
      })),
    );

    onCreated?.(platformObjects);

    for (const platformObject of platformObjects) {
      if (!platformObject.postData) continue;
      const acceptedObject = getObject(platformObject, objects);
      await axios.post(
        platformObject.postData.url,
        prepareFormData(platformObject.postData, acceptedObject.file),
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total ?? 100),
            );
            onUploadProgress?.(platformObject.id, progress);
          },
        },
      );
      onUploaded?.(platformObject.id);
    }

    onCompleted?.(platformObjects);

    return platformObjects;
  };
};

export default useUploadFiles;
