import {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react-thin';
import { ProjectModel } from '@bryntum/schedulerpro-thin';
import { GraphQLError } from 'graphql';
import { useDeepCompareMemoize } from 'use-deep-compare-effect';
import { pipe, subscribe } from 'wonka';
import { SchedulerContext } from './context';
import { schedulerActionSubject } from './stream';
import {
  PreparedTimesheetDuty,
  SchedulerActionRequest,
  SchedulerProps,
  SchedulerResourceType,
  Update,
  UpdateStatus,
  UpdateType,
  Updates,
} from './types';
import { compareDateTimes } from './utils';

export const useScheduler = ({
  ref,
  project,
  onUpdateSuccess,
  onUpdateError,
}: Pick<SchedulerProps, 'project'> & {
  ref: RefObject<BryntumSchedulerPro>;
  onUpdateSuccess?: (update: Update) => void;
  onUpdateError?: (update: Update, errors: readonly GraphQLError[]) => void;
}) => {
  const resourceType = useScheduleResourceType(project);
  const actions = useSchedulerActions();
  const updates = useSchedulerUpdates({
    onUpdateSuccess,
    onUpdateError,
  });
  return useMemo(
    () => ({
      context: {
        ref,
        project,
        actions,
        updates,
        resourceType,
      },
    }),
    useDeepCompareMemoize([ref, project, actions, updates, resourceType]),
  );
};

const useScheduleResourceType = (project: ProjectModel) => {
  const [type, setType] = useState<SchedulerResourceType>(
    SchedulerResourceType.User,
  );

  useEffect(() => {
    (async () => {
      await project.resourceStore.clearFilters();
      await project.resourceStore.filter((resource: any) => {
        return resource.type === type;
      });

      console.debug('Default resource type filter applied');
    })();
  }, []);

  const handleOnChange = useCallback(
    async (next: SchedulerResourceType) => {
      setType(next);

      await project.resourceStore.clearFilters();
      await project.resourceStore.filter((resource: any) => {
        return resource.type === next;
      });

      console.debug(`Resource type ${next} filter applied`);
    },
    [project.resourceStore],
  );

  return {
    value: type,
    set: handleOnChange,
  };
};

export const useSchedulerContext = () => {
  return useContext(SchedulerContext);
};

export const useSchedulerActions = () => {
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const timeoutPulse = useRef<NodeJS.Timeout | null>(null);

  const [lastAction, setLastAction] = useState<string>('');
  const [lastActionKey, setLastActionKey] = useState<number>(0);

  const [showLastAction, setShowLastAction] = useState<boolean>(false);
  const [pulseLastAction, setPulseLastAction] = useState<boolean>(false);

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    if (timeoutPulse.current) clearTimeout(timeoutPulse.current);

    setShowLastAction(true);
    setPulseLastAction(false);

    timeoutPulse.current = setTimeout(() => {
      setPulseLastAction(true);
    }, 250);

    timeout.current = setTimeout(() => {
      setShowLastAction(false);
    }, 1000);

    return () => {
      if (timeout.current) clearTimeout(timeout.current);
      if (timeoutPulse.current) clearTimeout(timeoutPulse.current);
    };
  }, [lastAction, lastActionKey]);

  const handleSetLastAction = useCallback((action: string) => {
    setLastAction(action);
    setLastActionKey((key) => key + 1);
  }, []);

  const handleRequest = useCallback((request: SchedulerActionRequest) => {
    console.debug('Scheduler action requested', request);
    schedulerActionSubject.next(request);
    handleSetLastAction(request.description);
  }, []);

  const handleRequestFactory = useCallback(
    (request: SchedulerActionRequest) => {
      return () => {
        handleRequest(request);
      };
    },
    [handleRequest],
  );

  return {
    value: lastAction,
    key: lastActionKey,
    show: showLastAction,
    pulse: pulseLastAction,
    set: handleSetLastAction,
    request: handleRequest,
    factory: handleRequestFactory,
  };
};

export const useSchedulerActionObserver = (
  callback: (request: SchedulerActionRequest) => void | Promise<void>,
  deps: any[] = [],
) => {
  useEffect(() => {
    const subscription = pipe(
      schedulerActionSubject.source,
      subscribe(callback),
    );
    console.debug('Scheduler action observer subscribed');
    return () => {
      subscription.unsubscribe();
      console.debug('Scheduler action observer unsubscribed');
    };
  }, deps);
};

export const useSchedulerUpdates = ({
  onUpdateSuccess,
  onUpdateError,
}: {
  onUpdateSuccess?: (update: Update) => void;
  onUpdateError?: (update: Update, errors: readonly GraphQLError[]) => void;
}) => {
  const [updates, setUpdates] = useState<Updates>({});

  const handleGetUpdate = useCallback(
    (id: string | number) => {
      if (typeof id === 'number') {
        console.warn(`Update ID ${id} is a number`);
      }
      return updates[String(id)];
    },
    [updates],
  );

  const handleSetUpdate = useCallback(
    (id: string, updater: (update: Update) => Update) => {
      setUpdates((prevUpdates) => ({
        ...prevUpdates,
        [id]: updater(prevUpdates[id]),
      }));
    },
    [],
  );

  const handleUnsetUpdate = useCallback((id: string) => {
    setUpdates((prevUpdates) => {
      const { [id]: _, ...rest } = prevUpdates;
      return rest;
    });
  }, []);

  const handleUpdate = useCallback(
    (duty: PreparedTimesheetDuty, update: Update) => {
      if (duty.id !== update.diff.id || duty.id !== update.duty.id) {
        throw new Error('Update mismatch');
      }

      if (
        !duty.isNew &&
        compareDateTimes(duty.dateTimeStart, update.diff.dateTimeStart) &&
        compareDateTimes(duty.dateTimeEnd, update.diff.dateTimeEnd) &&
        duty.user?.id === update.diff.user?.id
      ) {
        handleUnsetUpdate(duty.id);
        console.debug('Update removed');
        return;
      }

      if (
        duty.isNew &&
        (update.type === UpdateType.Update ||
          update.type === UpdateType.UpdateTransfer)
      ) {
        update.type = UpdateType.Create;
        console.debug('Create updated');
      }

      if (duty.isNew && update.type === UpdateType.Delete) {
        handleUnsetUpdate(duty.id);
        console.debug('Create removed');
        return;
      }

      handleSetUpdate(duty.id, (prev) => ({
        ...prev,
        ...update,
        diff: { ...prev?.diff, ...update.diff },
      }));
      console.debug('Update applied');
    },
    [handleSetUpdate, handleUnsetUpdate],
  );

  const handleMarkUpdate = useCallback(
    (
      id: string,
      status: UpdateStatus,
      errors: GraphQLError[] | undefined = undefined,
    ) => {
      setUpdates((prevUpdates) => {
        const update = prevUpdates[id];
        if (!update) {
          throw new Error('Update not found');
        }
        return {
          ...prevUpdates,
          [id]: {
            ...update,
            status,
            errors,
          },
        };
      });
    },
    [],
  );

  const all = useMemo(() => Object.values(updates), [updates]);
  const pending = useMemo(() => all.filter((update) => !update.status), [all]);

  return {
    get: handleGetUpdate,
    set: handleSetUpdate,
    all,
    pending,
    handle: handleUpdate,
    remove: handleUnsetUpdate,
    mark: handleMarkUpdate,
    callbacks: {
      onSuccess: onUpdateSuccess,
      onError: onUpdateError,
    },
  };
};
