import React, { PropsWithChildren } from 'react';
import { cn } from '@/lib/utils';
import { ActionCardProps } from './types';

const ActionsCard = ({
  children,
  className,
  hideHeader = false,
}: PropsWithChildren<ActionCardProps>) => {
  return (
    <div
      className={cn(
        'flex h-full w-full flex-col overflow-hidden rounded-lg border bg-background',
        className,
      )}
    >
      {!hideHeader && (
        <div className="border-b p-4">
          <h3 className="font-semibold">Actions</h3>
        </div>
      )}
      {children}
    </div>
  );
};

export default ActionsCard;
