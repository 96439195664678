import ActionBody from './components/ActionBody';
import ActionDialog from './components/ActionDialog';
import ActionPreview from './components/ActionPreview';
import { ActionContext } from './context';
import { useAction } from './hooks';
import { ActionProps } from './types';

const Action = (props: ActionProps) => {
  const { context } = useAction(props);

  if (props.preview) {
    return (
      <ActionContext.Provider value={context}>
        <ActionPreview>
          <ActionBody />
        </ActionPreview>
      </ActionContext.Provider>
    );
  }

  return (
    <ActionContext.Provider value={context}>
      <ActionDialog>
        <ActionBody />
      </ActionDialog>
    </ActionContext.Provider>
  );
};

export default Action;
