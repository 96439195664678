export type CallbackRef<T = any> = (node: T) => void;

export type FileObject = {
  id: string;
  type: string;
  name: string;
};

export type FileObjectPlaceholder = Omit<FileObject, 'id'>;

export interface FileObjectUpload extends FileObject {
  postData: any;
}

export type Nullable<T> = T | null;

export type RenderSnakeCaseToCamelCase<Key extends string | number | symbol> =
  Key extends `${infer FirstPart}_${infer FirstLetter}${infer LastPart}`
    ? `${FirstPart}${Uppercase<FirstLetter>}${RenderSnakeCaseToCamelCase<LastPart>}`
    : Key;

export type SnakeCaseToCamelCase<T extends Record<string, any>> = {
  [Property in keyof T as RenderSnakeCaseToCamelCase<Property>]: T[Property];
};

export type Thread = {
  threadId: string;
  threadState: string;
};
