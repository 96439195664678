import React, { Fragment, useRef } from 'react';
import { MapRef } from 'react-map-gl';
import ButtonTabs from '@/components/ui/button-tabs';
import { MultipleSelector } from '@/components/ui/multi-select';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Empty } from 'lib/shared';
import { useClient } from '../../hooks';
import ActivityMap from './components/ActivityMap';
import ActivitySchedule from './components/ActivitySchedule';
import { ClientActivityContext } from './context';
import { useClientActivity } from './hooks';

enum ClientActivityMode {
  Map,
  Schedule,
  Split,
}

const ClientActivity = () => {
  const [mode, setMode] = useLocalStorage(
    'clientActivityMode',
    ClientActivityMode.Map,
  );

  const client = useClient();
  const mapRef = useRef<MapRef>(null);

  const {
    timesheetDuties,
    subcontractors,
    interval,
    focused,
    handleOnFocus,
    handleOnBlur,
    handleTimeIntervalOnChange,
  } = useClientActivity();

  return (
    <ClientActivityContext.Provider
      value={{
        interval,
        focused,
        onFocus: handleOnFocus,
        onBlur: handleOnBlur,
        onIntervalChange: handleTimeIntervalOnChange,
      }}
    >
      <div className="flex h-full w-full flex-col bg-background bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-secondary">
        <div className="flex h-[52px] w-full flex-shrink-0 items-center border-b bg-background px-4 py-2">
          <div className="flex w-full grow items-center gap-x-4">
            <h1 className="grow text-xl font-bold">Activity</h1>
            <div>
              <MultipleSelector
                emptyIndicator={
                  <div className="h-16 px-4">
                    <Empty className="h-full">All teams selected</Empty>
                  </div>
                }
                hidePlaceholderWhenSelected
                placeholder="Select groups..."
                options={client.userGroups.results.map((group) => ({
                  label: group.name,
                  value: group.id,
                }))}
                value={client.userGroups.results
                  .filter((group) =>
                    client.userGroups.selected.includes(group.id),
                  )
                  .map((group) => ({
                    label: group.name,
                    value: group.id,
                  }))}
                onChange={(groups) => {
                  client.userGroups.setSelected(
                    groups.map((group) => group.value),
                  );
                }}
              />
            </div>
            <ButtonTabs
              tabs={[
                {
                  label: 'Map',
                  value: ClientActivityMode.Map,
                },
                {
                  label: 'Schedule',
                  value: ClientActivityMode.Schedule,
                },
                {
                  label: 'Split',
                  value: ClientActivityMode.Split,
                },
              ]}
              value={mode}
              onValueChange={setMode}
            />
          </div>
        </div>
        {mode === ClientActivityMode.Map && (
          <div className="grow">
            <ActivityMap mapRef={mapRef} jobs={client.jobs.all} />
          </div>
        )}
        {mode === ClientActivityMode.Schedule && (
          <div className="grow">
            <Fragment key={focused?.id}>
              <ActivitySchedule
                timesheetDuties={timesheetDuties}
                subcontractors={subcontractors}
              />
            </Fragment>
          </div>
        )}
        {mode === ClientActivityMode.Split && (
          <ResizablePanelGroup
            className="grow"
            direction="horizontal"
            autoSaveId="clients::client::activity"
          >
            <ResizablePanel>
              <Fragment key={focused?.id}>
                <ActivitySchedule
                  timesheetDuties={timesheetDuties}
                  subcontractors={subcontractors}
                />
              </Fragment>
            </ResizablePanel>
            <ResizableHandle withHandle />
            <ResizablePanel
              onResize={() => {
                mapRef.current?.resize();
              }}
            >
              <ActivityMap mapRef={mapRef} jobs={client.jobs.all} />
            </ResizablePanel>
          </ResizablePanelGroup>
        )}
      </div>
    </ClientActivityContext.Provider>
  );
};

export default ClientActivity;
