import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useReportQuery } from 'lib/shared';
import { User } from 'lib/types';
import ReportSection from '../../../../../../../ReportSection';
import ReportText from '../../../../../../../ReportText';
import { FormFieldComponentProps } from '../../types';

const FieldUser = ({ item, value, media }: FormFieldComponentProps) => {
  const { data } = useReportQuery<{ user: User }>(
    item.id,
    gql`
      query GetUser($id: ID!) {
        user(userId: $id) {
          id
          name
          email
          externalId
        }
      }
    `,
    {
      skip: !value?.value,
      variables: { id: value?.value.id },
    },
  );

  return (
    <ReportSection
      title={item.data.value}
      subtitle={item.data.helpText?.value}
      media={media}
    >
      <ReportText style={{ fontSize: 12, padding: 12 }}>
        {value?.value?.name}
      </ReportText>
    </ReportSection>
  );
};

export default FieldUser;
