import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { input } from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { z } from 'zod';
import { useRequirementsCreate } from './hooks';

const RequirementsCreate = () => {
  const { dialog, handlers } = useRequirementsCreate();
  return (
    <Dialog open={dialog.open} onOpenChange={dialog.onOpenChange}>
      <DialogTrigger asChild>
        <Button>Create Requirement</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Requirement</DialogTitle>
          <DialogDescription>Create a new requirement</DialogDescription>
        </DialogHeader>
        <FormRenderer
          fields={[
            input({
              name: 'name',
              label: 'Name',
              schema: z.string().min(1),
            }),
          ]}
          onSubmit={handlers.onSubmit}
        >
          {({ onSubmit }) => (
            <DialogFooter>
              <DialogClose asChild>
                <Button>Cancel</Button>
              </DialogClose>
              <Button onClick={onSubmit}>Create</Button>
            </DialogFooter>
          )}
        </FormRenderer>
      </DialogContent>
    </Dialog>
  );
};

export default RequirementsCreate;
