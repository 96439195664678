import ProfileHeader from './components/ProfileHeader';
import { UserProfileContext } from './context';
import { useUserProfile } from './hooks';
import { UserProfileProps } from './types';

const UserProfile = ({ root = false }: UserProfileProps) => {
  const { context } = useUserProfile();
  return (
    <UserProfileContext.Provider value={context}>
      <div className="grid grid-cols-12 py-8">
        <div className="col-span-8 col-start-3 flex gap-x-4">
          <ProfileHeader />
        </div>
      </div>
    </UserProfileContext.Provider>
  );
};

export default UserProfile;
