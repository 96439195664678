import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import ButtonTabs from '@/components/ui/button-tabs';
import { TableRenderer } from '@/components/ui/table/table/Table';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'sonner';
import { Job } from 'types/jobs';
import { useClient } from '../../../../../hooks';

const DeliveryJobs = () => {
  const { id } = useClient();
  const [mode, setMode] = useState('tracked');

  const { data: { jobs } = { jobs: [] }, refetch } = useQuery<{ jobs: Job[] }>(
    gql`
      query GetJobs($clientId: ID!, $archived: Boolean) {
        jobs(clientId: $clientId, archived: $archived) {
          id
          reference
          deletedAt
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      variables: { clientId: id, archived: mode === 'archived' },
    },
  );

  return (
    <div className="px-4 pt-4">
      <div>
        <h3 className="mb-1">Jobs</h3>
        <p className="text-xs dark:text-white/75">
          Work tracked by the system.
        </p>
      </div>
      <TableRenderer
        filter="reference"
        columns={[
          {
            id: 'reference',
            header: 'Reference',
            accessorKey: 'reference',
          },
          {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
              return (
                <DeliveryJobActions job={row.original} refetch={refetch} />
              );
            },
          },
        ]}
        data={jobs}
        topRight={({ columnsSelector }) => (
          <div className="ml-auto flex gap-x-2">
            <ButtonTabs
              className="h-8 bg-background"
              buttonClassName="h-8"
              tabs={[
                {
                  label: 'Tracked',
                  value: 'tracked',
                },
                {
                  label: 'Archived',
                  value: 'archived',
                },
              ]}
              value={mode}
              onValueChange={setMode}
            />
            {columnsSelector}
          </div>
        )}
      />
    </div>
  );
};

const DeliveryJobActions = ({
  job,
  refetch,
}: {
  job: Job;
  refetch: () => Promise<any>;
}) => {
  const [archive] = useMutation(
    gql`
      mutation ArchiveJob($id: ID!) {
        deleteJob(jobId: $id) {
          job {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnArchive = async () => {
    if (job.deletedAt) {
      console.warn('Job already archived');
      return;
    }

    const { data, errors } = await archive({ variables: { id: job.id } });

    if (data) {
      toast.success('Job archived');
      await refetch();
    }

    if (errors) {
      toast.error('Failed to archive job');
    }
  };

  const [restore] = useMutation(
    gql`
      mutation RestoreJob($id: ID!) {
        restoreJob(jobId: $id) {
          job {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnRestore = async () => {
    const { data, errors } = await restore({ variables: { id: job.id } });

    if (data) {
      await refetch();
      toast.success('Job restored');
    }

    if (errors) {
      toast.error('Failed to restore job');
    }
  };

  return (
    <div className="flex justify-end">
      {job.deletedAt ? (
        <Button size="sm" variant="outline" onClick={handleOnRestore}>
          Restore
        </Button>
      ) : (
        <Button size="sm" variant="outline" onClick={handleOnArchive}>
          Archive
        </Button>
      )}
    </div>
  );
};

export default DeliveryJobs;
