import { Button } from '@/components/ui/button';
import { gql, useQuery } from '@apollo/client';
import { useUser } from 'lib/hooks';
import { User } from 'lib/types';
import { DataType } from '../../../../../../external/FormTemplateEditor';
import UserPhoto from '../../../../../UserPhoto';

const FormFieldUser = ({ readOnly, item, value, onChange }: any) => {
  const user = useUser();

  const { data } = useQuery<{ user: User }>(
    gql`
      query GetUser($id: ID!) {
        user(userId: $id) {
          id
          name
          email
          externalId
        }
      }
    `,
    {
      skip: !value?.value,
      variables: { id: value?.value.id },
    },
  );

  const handleMeOnClick = () => {
    if (!user) return;

    onChange(item, {
      dataType: DataType.USER,
      value: {
        id: user.id,
        name: user.name,
      },
    });
  };

  if (value?.value) {
    return (
      <div className="flex items-center gap-x-3 rounded-lg border bg-secondary p-2.5">
        <UserPhoto user={value.value} />
        <div className="flex-grow">
          <p className="text-sm font-medium">{value.value.name}</p>
          <p className="text-xs dark:text-gray-400">{data?.user.email}</p>
        </div>
        {!readOnly && (
          <div>
            <Button size="sm" variant="secondary">
              Change
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <p>No user set</p>
      {!readOnly && <Button onClick={handleMeOnClick}>Me</Button>}
    </div>
  );
};

export default FormFieldUser;
