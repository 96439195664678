import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useCompanyInsurancesContext } from '../../context';
import {
  CreateCompanyInsuranceMutationData,
  CreateCompanyInsuranceMutationVariables,
} from './types';

export const useInsurancesCreate = () => {
  const { refetch } = useCompanyInsurancesContext();

  const [open, setOpen] = useState(false);

  const [create] = useMutation<
    CreateCompanyInsuranceMutationData,
    CreateCompanyInsuranceMutationVariables
  >(
    gql`
      mutation CreateCompanyInsurance($data: CompanyInsuranceData!) {
        createCompanyInsurance(companyInsuranceData: $data) {
          companyInsurance {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (values: any) => {
    const { data, errors } = await create({
      variables: {
        data: {
          coverName: values.coverName,
          issuer: values.issuer,
          policyNumber: values.policyNumber,
          dateTimeCoverStart: values.dateTimeCoverStart,
          dateTimeCoverEnd: values.dateTimeCoverEnd,
          indemnityLimit: values.indemnityLimit,
          notes: values.notes,
          document: values.document
            ? {
                id: values.document.id,
                type: values.document.type,
                name: values.document.name,
                size: values.document.size,
                data: values.document.data,
              }
            : undefined,
        },
      },
    });

    if (data) {
      await refetch();
      setOpen(false);
      toast.success('Company insurance created');
    }

    if (errors) {
      toast.error('Failed to create company insurance');
    }
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onCreate: handleOnCreate,
    },
  };
};
