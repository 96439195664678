import { createContext, useContext } from 'react';
import { ReportBlockFormContextType } from './types';

export const ReportBlockFormContext = createContext<ReportBlockFormContextType>(
  {
    template: { pageOrder: [], items: {}, choiceSets: {}, fieldLabels: {} },
    submission: { values: {}, objects: {} },
    shouldRender: () => false,
    getBlockComponent: (block: string) => () => null,
  },
);

export const useReportBlockFormContext = () =>
  useContext(ReportBlockFormContext);
