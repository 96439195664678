import React from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { ScrollArea } from '@/components/ui/scroll-area';
import * as Y from 'yjs';
import { Empty } from 'lib/shared';
import { JobTask } from 'lib/types';
import { useNotes } from '../../../../../../external/Notes/hooks';
import { NotesStore } from '../../../../../../external/Notes/types';
import { useJobTaskContext } from '../../context';
import TaskNote from './components/TaskNote';

const TaskNotes = () => {
  const { task } = useJobTaskContext();

  const { store, doc } = useNotes(
    'jobs::job',
    task?.job ? `${task.job.id}::notes` : undefined,
  );

  if (!task || !store) return null;

  return <TaskNotesRenderer task={task} store={store} doc={doc} />;
};

const TaskNotesRenderer = ({
  task,
  store,
  doc,
}: {
  task: JobTask;
  store: NotesStore;
  doc: Y.Doc;
}) => {
  const state = useSyncedStore(store);
  const scope = `jobs::job-task::${task.id}`;
  const notes = Object.values(state?.notes || {}).filter(
    (note) => note.scope === scope,
  );

  if (notes.length === 0) {
    return (
      <div className="h-full border-t p-4">
        <Empty>No notes</Empty>
      </div>
    );
  }

  const notesToRender = notes.filter((note) => !note.deleted);
  return (
    <div className="h-full min-h-0 border-t p-4">
      <div className="h-full min-h-0 items-center rounded-md border bg-secondary bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-background">
        <ScrollArea className="h-full min-h-0">
          <div className="flex flex-col gap-y-4 p-4">
            {notesToRender.length > 0 ? (
              notesToRender.map((note) => (
                <TaskNote key={note.id} note={note} store={store} doc={doc} />
              ))
            ) : (
              <div />
            )}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
};

export default TaskNotes;
