import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { CompanyAccreditationsQueryData } from './types';

export const useCompanyAccreditations = () => {
  const { data, refetch } = useQuery<CompanyAccreditationsQueryData>(gql`
    query GetCompanyAccreditations {
      companyAccreditations {
        id
        logo {
          id
          name
          size
          type
          data
        }
        name
        reference
        dateTimeExpiry
        description
        document {
          id
          name
          size
          type
          data
        }
      }
    }
  `);

  const accreditations = useMemo(
    () => data?.companyAccreditations || [],
    [data],
  );

  return {
    context: {
      accreditations,
      refetch,
    },
  };
};
