import { PlatformObject } from './platform';

export type CompanyAccreditation = {
  id: string;
  logo?: Omit<PlatformObject, 'sources'>;
  name: string;
  reference: string;
  dateTimeExpiry: string;
  description: string;
  document?: Omit<PlatformObject, 'sources'>;
};

export type CompanyInsurance = {
  id: string;
  coverName: string;
  issuer: string;
  policyNumber: string;
  dateTimeCoverStart: string;
  dateTimeCoverEnd: string;
  indemnityLimit: number;
  notes: string;
  document?: Omit<PlatformObject, 'sources'>;
};

export type CompanyMembership = {
  id: string;
  logo?: Omit<PlatformObject, 'sources'>;
  name: string;
  description: string;
  dateTimeExpiry?: string;
  document?: Omit<PlatformObject, 'sources'>;
};
