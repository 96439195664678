import React from 'react';
import { GripVerticalIcon, TrashIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ColorPicker } from '@/components/ui/color-picker';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
} from '@hello-pangea/dnd';
import { Empty } from 'lib/shared';
import { Choice } from '../../../../types';
import { useChoiceSetEditorContext } from '../../context';

const EditorStatic = () => {
  const { choiceSet, handlers } = useChoiceSetEditorContext();
  return (
    <SheetContent className="flex min-h-0 flex-col gap-y-0 p-0">
      <SheetHeader className="p-4">
        <SheetTitle>Edit choice set</SheetTitle>
        <SheetDescription>Add, edit or remove choices</SheetDescription>
      </SheetHeader>
      <div className="min-h-0 grow">
        <ScrollArea className="h-full min-h-0">
          <div className="px-4 pb-4 pt-[1px]">
            <DragDropContext onDragEnd={handlers.onDragEnd}>
              <Droppable droppableId="main">
                {({
                  innerRef: droppableInnerRef,
                  droppableProps,
                  placeholder,
                }) =>
                  !choiceSet ? null : (
                    <div
                      ref={droppableInnerRef}
                      className="flex flex-col"
                      {...droppableProps}
                    >
                      {choiceSet.choices.length > 0 ? (
                        choiceSet.choices.map((choice, index) => {
                          return (
                            <Draggable
                              key={choice.id}
                              draggableId={choice.id}
                              index={index}
                            >
                              {(provided) => (
                                <EditorStaticChoice
                                  choice={choice}
                                  provided={provided}
                                />
                              )}
                            </Draggable>
                          );
                        })
                      ) : (
                        <Empty className="h-32">No choices</Empty>
                      )}
                      {placeholder}
                    </div>
                  )
                }
              </Droppable>
            </DragDropContext>
          </div>
        </ScrollArea>
      </div>
      <div className="flex justify-between border-t p-4">
        <Button size="sm" variant="outline" onClick={handlers.onAdd}>
          Add new choice
        </Button>
        {choiceSet && (
          <Button
            disabled={choiceSet.choices.length > 0}
            size="sm"
            variant="outline"
            onClick={handlers.onMakeDynamic}
          >
            Make dynamic
          </Button>
        )}
        <Button size="sm" variant="outline" onClick={handlers.onSave}>
          Done
        </Button>
      </div>
    </SheetContent>
  );
};

const EditorStaticChoice = ({
  choice,
  provided: { innerRef: draggableInnerRef, draggableProps, dragHandleProps },
}: {
  choice: Choice;
  provided: DraggableProvided;
}) => {
  const { handlers } = useChoiceSetEditorContext();
  return (
    <div
      key={choice.id}
      ref={draggableInnerRef}
      className="flex gap-x-2"
      {...draggableProps}
    >
      <span {...dragHandleProps}>
        <Button size="icon" variant="outline">
          <GripVerticalIcon className="h-4 w-4" />
        </Button>
      </span>
      <div>
        <ColorPicker
          small
          value={choice.color}
          onValueChange={(value) => handlers.onUpdate(choice, 'color', value)}
        />
      </div>
      <Input value={choice.value} onChange={handlers.onValueChange(choice)} />
      <div>
        <Button
          size="icon"
          variant="outline"
          onClick={handlers.onDelete(choice)}
        >
          <TrashIcon className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

export default EditorStatic;
