import { useEffect, useMemo, useRef, useState } from 'react';
import { useEdgesState, useNodesState, useReactFlow } from '@xyflow/react';
import { useAutoLayout } from '../../../../../ClientJob/pages/JobOverview/components/OverviewGraph';
import { useCompanyStaffContext } from '../../context';

export const useStaffChart = () => {
  const { users, refetch } = useCompanyStaffContext();

  const { fitView } = useReactFlow();

  const root = useMemo(
    () =>
      users.find(
        (user) => user.email.toLowerCase() === 'james.mchugh@mchughltd.co.uk',
      ),
    [users],
  );

  if (!root) {
    throw new Error('Root user not found');
  }

  const { layouted } = useAutoLayout({
    direction: 'TB' as const,
    spacing: [50, 100],
  });

  const [initialNodes] = useState(() => [
    {
      id: root.id,
      type: 'user',
      data: { user: root },
      position: { x: 0, y: 0 },
    },
    ...users
      .filter((user) => !!user.lineManager)
      .map((user) => ({
        id: user.id,
        type: 'user',
        data: { user },
        position: { x: 0, y: 0 },
      })),
  ]);

  const [initialEdges] = useState(() =>
    users
      .filter((user) => !!user.lineManager)
      .map((user) => ({
        id: user.id,
        source: user.lineManager!.id,
        target: user.id,
      })),
  );

  const [nodes, _setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, _setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const set = useRef(false);

  useEffect(() => {
    (async () => {
      if (!layouted) return;
      if (!set.current) {
        set.current = true;
        await fitView({ padding: 0.01 });
      }
    })();
  }, [nodes]);

  return {
    users,
    refetch,
    graph: {
      initialNodes,
      initialEdges,
      nodes,
      edges,
      onNodesChange,
      onEdgesChange,
    },
  };
};
