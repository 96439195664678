import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useSupplyChainSubcontractorsContext } from '../../context';
import {
  CreateSubcontractorMutationData,
  CreateSubcontractorMutationVariables,
} from './types';

export const useSubcontractorsCreate = () => {
  const { refetch } = useSupplyChainSubcontractorsContext();

  const [open, setOpen] = useState(false);

  const [create] = useMutation<
    CreateSubcontractorMutationData,
    CreateSubcontractorMutationVariables
  >(
    gql`
      mutation CreateSubcontractor($name: String!) {
        createSubcontractor(name: $name) {
          subcontractor {
            id
            name
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (values: any) => {
    const { data, errors } = await create({ variables: { name: values.name } });

    if (data) {
      await refetch();
      setOpen(false);
      toast.success('Subcontractor created');
    }

    if (errors) {
      toast.error('Failed to create subcontractor');
    }
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    onCreate: handleOnCreate,
  };
};
