import {
  DeleteUserMutationData,
  DeleteUserMutationVariables,
  RestoreUserMutationData,
  RestoreUserMutationVariables,
} from '@/components/ui/user/components/UserDangerZone/types';
import { useUserSheetContext } from '@/components/ui/user/context';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';

export const useUserDangerZone = () => {
  const { sheet, user } = useUserSheetContext();

  const [delete_] = useMutation<
    DeleteUserMutationData,
    DeleteUserMutationVariables
  >(
    gql`
      mutation DeleteUser($id: ID!) {
        deleteUser(userId: $id) {
          user {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnDelete = async () => {
    const { data, errors } = await delete_({
      variables: {
        id: user.id,
      },
    });

    if (data) {
      await user.refetch();
      sheet.onClose();
      toast.success('User archived successfully');
    }

    if (errors) {
      toast.error('Failed to archive user');
    }
  };

  const [restore] = useMutation<
    RestoreUserMutationData,
    RestoreUserMutationVariables
  >(
    gql`
      mutation RestoreUser($id: ID!) {
        restoreUser(userId: $id) {
          user {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnRestore = async () => {
    const { data, errors } = await restore({
      variables: {
        id: user.id,
      },
    });

    if (data) {
      await user.refetch();
      toast.success('User restored successfully');
    }

    if (errors) {
      toast.error('Failed to restore user');
    }
  };

  return {
    handlers: {
      onDelete: handleOnDelete,
      onRestore: handleOnRestore,
    },
  };
};
