import React from 'react';
import { Button } from '@/components/ui/button';
import { Command, CommandInput } from '@/components/ui/command';
import { Loader } from '@/components/ui/loading';
import * as CommandPrimitive from 'cmdk';
import { platformClient } from '../../app/platform';
import SearchResults from './components/SearchResults';
import SearchStatic from './components/SearchStatic';
import { useSearch } from './hooks';

const Search = () => {
  const {
    search,
    searching,
    results,
    loading,
    value,
    setSearch,
    setSearching,
    setResults,
    setLoading,
    setValue,
  } = useSearch();
  return (
    <div>
      {!searching && (
        <Command className="rounded-lg border shadow-md" shouldFilter loop>
          <CommandInput
            placeholder="Type a command or search..."
            autoFocus
            value={search}
            onValueChange={async (searchTerm) => {
              setSearch(searchTerm);
            }}
            onKeyDown={async (event) => {
              if (event.key === 'Enter') {
                setSearching(true);
                setLoading(true);
                const response =
                  await platformClient.searchEverything(search)();
                setResults(
                  response.results.map((result: any) => ({
                    ...result,
                    scope: result.scope?.toLowerCase()?.replace(/\./g, '::'),
                  })),
                );
                setLoading(false);
              }
            }}
          />
          <SearchStatic />
        </Command>
      )}
      {searching && (
        <Command
          className="rounded-lg border shadow-md"
          shouldFilter={false}
          loop
          value={value}
          onValueChange={setValue}
        >
          <div className="relative">
            <CommandInput
              placeholder="Type a command or search..."
              autoFocus
              value={search}
              onValueChange={async (searchTerm) => {
                setSearch(searchTerm);
              }}
              onKeyDown={async (event) => {
                if (event.key === 'Enter') {
                  if (!search) {
                    setSearching(false);
                    setSearch('');
                    setResults([]);
                  } else {
                    setSearching(true);
                    setLoading(true);
                    setResults([]);
                    const response =
                      await platformClient.searchEverything(search)();
                    setResults(
                      response.results.map((result: any) => ({
                        ...result,
                        scope: result.scope
                          ?.toLowerCase()
                          ?.replace(/\./g, '::'),
                      })),
                    );
                    setLoading(false);
                  }
                }
              }}
            />
            <div className="absolute bottom-0 right-2 top-0 flex h-[40px] items-center">
              <Button
                size="xs"
                variant="ghost"
                onClick={() => {
                  setSearching(false);
                  setSearch('');
                  setResults([]);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    setSearching(false);
                    setSearch('');
                    setResults([]);
                  }
                }}
              >
                Clear search
              </Button>
            </div>
          </div>
          <CommandPrimitive.CommandList>
            {loading ? (
              <CommandPrimitive.CommandLoading>
                <div className="flex h-[56px] items-center pl-4">
                  <Loader>Searching...</Loader>
                </div>
              </CommandPrimitive.CommandLoading>
            ) : (
              <SearchResults results={results} onClose={() => {}} />
            )}
          </CommandPrimitive.CommandList>
        </Command>
      )}
    </div>
  );
};

export { SearchDialog } from './components/SearchDialog';

export default Search;
