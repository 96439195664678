import React from 'react';
import BlockButton from '../../../BlockButton';
import { ControlsDeleteProps } from './types';

const ControlsDelete = ({ item, isHovered, onClick }: ControlsDeleteProps) => {
  const getButtonText = () => {
    if (item.type === 'CONTAINER') return 'section';
    if (item.data.dataType === 'COMMENT') return 'comment';
    return 'question';
  };

  return (
    <div>
      {isHovered && (
        <BlockButton confirm="Are you sure?" onClick={onClick}>
          Delete {getButtonText()}
        </BlockButton>
      )}
    </div>
  );
};

export default ControlsDelete;
