import React from 'react';
import { Container } from 'lucide-react';
import { Marker } from 'react-map-gl';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';

const LocationYard = ({
  name,
  drivers,
  longitude,
  latitude,
}: {
  name: string;
  drivers: any[];
  longitude: number;
  latitude: number;
}) => {
  return (
    <>
      <Marker longitude={longitude} latitude={latitude}>
        <div className="relative size-6">
          <Container className="absolute inset-0 size-6" />
        </div>
      </Marker>
      <Marker longitude={longitude} latitude={latitude}>
        <div className="size-10 rounded-full border-2 border-accent-foreground bg-accent-foreground/25" />
      </Marker>
      <Marker offset={[0, 40]} longitude={longitude} latitude={latitude}>
        <Sheet>
          <SheetTrigger asChild>
            <Button className="p-0" variant="link">
              <p
                className="font-semibold"
                style={{
                  textShadow:
                    '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
                }}
              >
                {name}
                <p className="text-[11px]">({drivers.length} present)</p>
              </p>
            </Button>
          </SheetTrigger>
          <SheetContent className="p-0" overlay={false}>
            <ScrollArea className="h-full">
              <div className="flex flex-col gap-y-4 p-4">
                {drivers.map((driverUpdate) => {
                  return (
                    <div key={driverUpdate.vehicle.vin}>
                      {JSON.stringify(driverUpdate)}
                    </div>
                  );
                })}
              </div>
            </ScrollArea>
          </SheetContent>
        </Sheet>
      </Marker>
    </>
  );
};

export default LocationYard;
