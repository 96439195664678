import { useState } from 'react';
import { DateTime } from 'luxon';
import { useSchedulerActionObserver } from '../../../../hooks';
import { SchedulerAction } from '../../../../types';

export const useControlsDate = () => {
  const [value, setValue] = useState(DateTime.now());

  useSchedulerActionObserver((action) => {
    if (
      action.type === SchedulerAction.ScrollToDateTime ||
      action.type === SchedulerAction.SetControlDateTime
    ) {
      setValue(action.dateTime);
    }
  });

  return {
    value,
  };
};
