import { Edit } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { number } from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import {
  Sheet,
  SheetContentExtension,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { z } from 'zod';
import { useContractRateContext } from '../../context';
import { useRateUnitValue } from './hooks';

const RateUnitValue = () => {
  const { rate } = useContractRateContext();
  const { sheet, handlers } = useRateUnitValue();

  if (!rate) {
    return null;
  }

  return (
    <div className="mb-4 flex items-center rounded-md border p-4 pr-2">
      <div className="flex-grow">
        <div className="mb-0.5 text-xs dark:text-gray-400">Unit Value</div>
        <div className="font-semibold">£{rate.unitValue}</div>
      </div>
      <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
        <SheetTrigger asChild>
          <Button variant="link" size="sm">
            <Edit className="mr-1 size-3" />
            Update
          </Button>
        </SheetTrigger>
        <SheetContentExtension right={500}>
          <SheetHeader className="p-4">
            <SheetTitle>Update value</SheetTitle>
            <SheetDescription>
              Update the value for this contract rate.
            </SheetDescription>
          </SheetHeader>
          <FormRenderer
            className="mx-4"
            fields={[
              number({
                name: 'unitValue',
                label: 'Unit Value',
                schema: z.number().min(0),
                defaultValue: rate.unitValue,
              }),
            ]}
            onSubmit={handlers.onSubmit}
          >
            {({ onSubmit }) => (
              <div className="mt-4 flex justify-end">
                <Button
                  className="w-full"
                  variant="outline"
                  size="sm"
                  type="submit"
                  onClick={onSubmit}
                >
                  Update
                </Button>
              </div>
            )}
          </FormRenderer>
        </SheetContentExtension>
      </Sheet>
    </div>
  );
};

export default RateUnitValue;
