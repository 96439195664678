import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useCommercialActivityContext } from '../../../CommercialActivity/context';
import { useCommercialEstimatesContext } from '../../context';
import {
  CreateCommercialEstimateMutationData,
  CreateCommercialEstimateMutationVariables,
} from './types';

export const useEstimatesCreate = () => {
  const { activity } = useCommercialActivityContext();
  const { handlers } = useCommercialEstimatesContext();

  const [open, setOpen] = useState(false);

  const [create] = useMutation<
    CreateCommercialEstimateMutationData,
    CreateCommercialEstimateMutationVariables
  >(
    gql`
      mutation CreateCommercialEstimate($activityId: ID!, $name: String!) {
        createCommercialEstimate(
          commercialActivityId: $activityId
          commercialEstimateName: $name
          commercialEstimateDescription: ""
        ) {
          commercialEstimate {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (values: any) => {
    const { data, errors } = await create({
      variables: {
        activityId: activity.id,
        name: values.name,
      },
    });

    if (data) {
      await activity.refetch();
      const { commercialEstimate } = data.createCommercialEstimate;
      handlers.onSelect(commercialEstimate.id);
      setOpen(false);
      toast.success('Estimate created successfully');
    }

    if (errors) {
      toast.error('Failed to create estimate');
    }
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onCreate: handleOnCreate,
    },
  };
};
