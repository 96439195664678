import React from 'react';
import { ReportBlockComponentProps } from '../../../../types';
import { evaluateProperty } from '../../../../utils';
import ReportText from '../../../ReportText';
import ReportView from '../../../ReportView';
import { BlockTextProps } from './types';

const BlockText = ({
  data,
  context,
  after = null,
  custom = null,
}: ReportBlockComponentProps<BlockTextProps> & {
  after?: React.ReactNode;
  custom?: React.ReactNode;
}) => {
  if (data?.mode === 'row') {
    const label = evaluateProperty('label', data, context);
    const text = evaluateProperty('text', data, context);
    return (
      <ReportView style={data?.style}>
        <ReportView
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          {(data.label || data['$.label']) && (
            <ReportText
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 1.2,
                whiteSpace: 'nowrap',
                ...data?.labelStyle,
              }}
            >
              {label}
            </ReportText>
          )}
          {text && (
            <ReportText
              style={{
                fontSize: 14,
                paddingLeft: 16,
                ...data?.textStyle,
              }}
            >
              {evaluateProperty('text', data, context)}
            </ReportText>
          )}
          {custom}
        </ReportView>
        {after}
      </ReportView>
    );
  }

  return (
    <ReportView style={data?.style}>
      {(data.label || data['$.label']) && (
        <ReportText
          style={{
            textTransform: 'uppercase',
            fontSize: 12,
            fontWeight: 'bold',
            opacity: 0.5,
            lineHeight: 1.2,
            ...data?.labelStyle,
          }}
        >
          {evaluateProperty('label', data, context)}
        </ReportText>
      )}
      <ReportText
        style={{
          fontSize: 14,
          ...data?.textStyle,
        }}
      >
        {evaluateProperty('text', data, context)}
      </ReportText>
      {(data.subtext || data['$.subtext']) && (
        <ReportText
          style={{
            fontSize: 14,
            opacity: 0.75,
            ...data?.subtextStyle,
          }}
        >
          {evaluateProperty('subtext', data, context)}
        </ReportText>
      )}
      {after}
    </ReportView>
  );
};

export default BlockText;
