import { useQuery } from '@tanstack/react-query';
import { platformClient } from '../../app/platform';
import { EventProps } from './types';

const Event = ({ children, scope, name }: EventProps) => {
  const {
    data: { events } = { events: [] },
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['events', scope],
    queryFn: platformClient.getEvents(scope),
    enabled: !!scope,
  });

  const event = events.find((event) => event.name === name);

  if (!event) {
    return null;
  }

  if (children) {
    return <>{children({ event })}</>;
  }

  return <div>{event.name}</div>;
};

export default Event;
