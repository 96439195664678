import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { Training } from 'lib/types';
import { useTrainingStaffMemberContext } from '../../context';
import {
  DeleteTrainingMutationData,
  DeleteTrainingMutationVariables,
  UpdateTrainingMutationData,
  UpdateTrainingMutationVariables,
} from './types';

export const useMemberTrainingEditor = (training: Training) => {
  const { user } = useTrainingStaffMemberContext();

  const [open, setOpen] = useState(false);

  const [update] = useMutation<
    UpdateTrainingMutationData,
    UpdateTrainingMutationVariables
  >(
    gql`
      mutation UpdateTraining(
        $id: ID!
        $dateValid: DateTime
        $dateExpiry: DateTime
        $data: JSONScalarInput!
        $certificates: [TrainingCertificateInput!]!
      ) {
        updateTraining(
          trainingId: $id
          dateValid: $dateValid
          dateExpiry: $dateExpiry
          data: $data
          certificates: $certificates
        ) {
          training {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnUpdate = async (values: any) => {
    if (!user) {
      return;
    }

    const { data, errors } = await update({
      variables: {
        id: training.id,
        dateValid: values.dateValid,
        dateExpiry: values.dateExpiry,
        data: values.data,
        certificates: values.certificates,
      },
    });

    if (data) {
      await user.refetch();
      setOpen(false);
      toast.success('Training record updated');
    }

    if (errors) {
      toast.error('Failed to update training record');
    }
  };

  const [delete_] = useMutation<
    DeleteTrainingMutationData,
    DeleteTrainingMutationVariables
  >(
    gql`
      mutation DeleteTraining($id: ID!) {
        deleteTraining(trainingId: $id) {
          deleted
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnDelete = async () => {
    if (!user) {
      return;
    }

    const { data, errors } = await delete_({
      variables: {
        id: training.id,
      },
    });

    if (data) {
      await user.refetch();
      setOpen(false);
      toast.success('Training record deleted');
    }

    if (errors) {
      toast.error('Failed to delete training record');
    }
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onUpdate: handleOnUpdate,
      onDelete: handleOnDelete,
    },
  };
};
