import React from 'react';
import { Badge } from '@/components/ui/badge';
import chroma, { Color } from 'chroma-js';
import { DataType } from 'external/FormTemplateEditor';
import { useFormContext } from '../../../../context';
import { FormFieldComponentProps } from '../../types';
import ChoiceButtons from './components/ChoiceButtons';
import ChoiceRadioGroup from './components/ChoiceRadioGroup';
import ChoiceSelector from './components/ChoiceSelector';

const FormFieldChoice = ({
  readOnly,
  item,
  value,
  onChange,
}: FormFieldComponentProps) => {
  const { template } = useFormContext();

  const selected = value?.value as string[];
  const selectedOrDefault = selected ?? [];

  const choiceSet = template.choiceSets[item.data.choiceSetId as string];
  const options =
    choiceSet.choices.map((choice) => ({
      label: choice.value,
      value: choice.id,
      color: choice.color,
      // fixme: Add disabled to Choice
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      disabled: choice.disabled,
    })) || [];

  const isMulti = item.data.isMultipleSelection;
  const isModalSelection = options.length > 5;

  const handleOnChange = (selected: any | any[]) => {
    const inner = Array.isArray(selected) ? selected : [selected];

    if (isMulti) {
      onChange(item, {
        dataType: DataType.CHOICE_SELECTION,
        value: inner,
      });
      return;
    }

    onChange(item, {
      dataType: DataType.CHOICE_SELECTION,
      value: inner?.[0] ? [inner[0]] : [],
    });
  };

  if (readOnly) {
    return (
      <div className="mt-2 flex flex-col gap-2">
        {options
          .filter((option) => selectedOrDefault.includes(option.value))
          .map((choice) => {
            let color: Color;
            try {
              color = chroma(choice.color);
            } catch (error) {
              console.warn(
                `Invalid color for choice \`${choice.label}\`: ${choice.color}`,
              );
              color = chroma('gray');
            }
            return (
              <Badge
                key={choice.value}
                className="h-8 rounded-md font-semibold capitalize"
                style={{
                  color: color.css(),
                  border: `1px solid ${color.alpha(0.5).css()}`,
                  backgroundColor: color.alpha(0.1).css(),
                }}
              >
                {choice.label}
              </Badge>
            );
          })}
      </div>
    );
  }

  if (isModalSelection) {
    return (
      <ChoiceSelector
        value={selectedOrDefault}
        isMulti={isMulti}
        options={options}
        onChange={handleOnChange}
      />
    );
  }

  if (!isMulti) {
    return (
      <ChoiceRadioGroup
        options={options}
        value={selectedOrDefault}
        onChange={(selected: any) => {
          onChange(item, {
            dataType: DataType.CHOICE_SELECTION,
            value: [selected],
          });
        }}
      />
    );
  }

  return (
    <ChoiceButtons
      value={selectedOrDefault}
      options={options}
      onChange={handleOnChange}
    />
  );
};

export default FormFieldChoice;
