import { createContext } from 'react';
import { Asset, Client, Job, Site } from 'lib/types';
import { useClientJobs, useClientUserGroups } from './hooks';

export const ClientContext = createContext<
  Omit<Client, 'userGroups'> & {
    refetch: (variables?: any) => Promise<any>;
    update: (field: string, value: any) => Promise<any>;
    userGroups: ReturnType<typeof useClientUserGroups>;
    jobs: ReturnType<typeof useClientJobs>;
    scope: string;
    paths: {
      root: string;
      activity: string;
      todos: string;
      maintenance: {
        root: string;
        series: (series: any) => string;
      };
      jobs: {
        root: string;
        job: (job: Job) => {
          root: string;
          location: string;
          settings: {
            general: string;
            danger: string;
          };
          commercial: {
            root: string;
            estimates: string;
          };
        };
      };
      sites: {
        root: string;
        site: (site: Site) => string;
      };
      assets: {
        root: string;
        asset: (asset: Asset) => string;
      };
      commercial: {
        root: string;
        contract: (slug: string, dateStart: string, dateEnd: string) => string;
      };
      projects: {
        root: string;
      };
      settings: {
        root: string;
        forms: string;
        delivery: string;
        supplyChain: string;
      };
    };
  }
>({} as any);
