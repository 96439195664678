import { ArrowDown, ArrowUp } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useCommercialActivityContext } from '../../context';

const ActivityFooter = () => {
  const { activity } = useCommercialActivityContext();
  return (
    <div className="flex border-t bg-background px-4">
      <div className="grid w-7/12 grid-cols-8">
        <div className={cn(cell, 'col-span-5')} />
        <div className={cn(cell, 'col-span-2 text-xs font-semibold')}>
          Total estimated margin
        </div>
        <div
          className={cn(cell, 'text-sm font-bold', {
            'bg-red-500/25 text-red-500':
              activity.totalEstimatedMarginWithPrelims < 0,
            'bg-green-700/25 text-green-700':
              activity.totalEstimatedMarginWithPrelims > 0,
          })}
        >
          {activity.totalEstimatedMarginWithPrelims.toFixed(2)}
        </div>
      </div>
      <div className="grid w-5/12 grid-cols-6">
        <div className={cn(cell, 'col-span-2')} />
        <div className={cn(cell, 'col-span-1 text-xs font-semibold')}>
          Total margin
        </div>
        <div
          className={cn(
            cell,
            'gap-x-1 overflow-hidden whitespace-nowrap border-r-0 text-sm font-bold',
            {
              'bg-red-500/25 text-red-500': activity.totalActualMargin < 0,
              'bg-green-700/25 text-green-700': activity.totalActualMargin > 0,
            },
          )}
        >
          {activity.totalActualMarginWithPrelims.toFixed(2)}
          {activity.totalMarginDifferenceWithPrelims !== 0 && (
            <span
              className={cn('text-xs', {
                'text-red-500': activity.totalMarginDifferenceWithPrelims < 0,
                'text-green-700': activity.totalMarginDifferenceWithPrelims > 0,
              })}
            >
              {activity.totalMarginDifferenceWithPrelims > 0 && (
                <ArrowUp className="inline-block size-4" />
              )}
              {activity.totalMarginDifferenceWithPrelims < 0 && (
                <ArrowDown className="inline-block size-4" />
              )}
              {activity.totalMarginDifferenceWithPrelims.toFixed(2)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const cell = 'flex items-center col-span-1 border-r p-2 text-sm';

export default ActivityFooter;
