import React, { useMemo, useState } from 'react';
import {
  useMutation as useReactMutation,
  useQuery as useReactQuery,
} from '@tanstack/react-query';
import { RowSelectionState } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import {
  Sheet,
  SheetContent,
  SheetContentExtension,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { TableRenderer, selectColumn } from '@/components/ui/table/table/Table';
import { z } from 'zod';
import { UserGroup } from 'lib/types';
import { platformClient } from '../../../../../../app/platform';
import { useUserGroupContext } from '../../context';
import { rankMulti, sortScope } from './utils';

const GroupConfigurator = () => {
  const [selected, setSelected] = useState<RowSelectionState>({});
  const selectedIds = Object.entries(selected)
    .filter(([_, isSelected]) => isSelected)
    .map(([id]) => id);

  const { group } = useUserGroupContext() as { group: UserGroup };
  const { data, refetch } = useReactQuery({
    queryKey: ['identity', 'group', group.platformId, 'permissions'],
    queryFn: platformClient.getIdentityGroup({
      id: group.platformId,
      includeAnnotatedScopes: true,
    }),
  });

  const { data: scopes = [] } = useReactQuery({
    queryKey: ['identity', 'scopes'],
    queryFn: platformClient.getIdentityScopes(),
  });

  const createGroupPermission = useReactMutation({
    mutationFn: platformClient.createIdentityGroupPermission(),
  });

  const deletePermission = useReactMutation({
    mutationFn: platformClient.deleteIdentityPermission(),
  });

  const tableData = useMemo(
    () =>
      (data?.annotatedScopes ?? []).map((scope) => ({
        id: scope.permission.id,
        action: scope.permission.action,
        scope: scope.value,
      })),
    [data?.annotatedScopes],
  );

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" size="sm">
          Configure
        </Button>
      </SheetTrigger>
      <SheetContent className="w-[700px] min-w-[700px] p-0">
        <SheetHeader className="p-4">
          <SheetTitle>Configure</SheetTitle>
          <SheetDescription>
            Manage the scopes and permissions for this group
          </SheetDescription>
        </SheetHeader>
        <div className="px-4">
          <TableRenderer
            filter="action"
            globalFilterFn={rankMulti}
            selected={selected}
            onSelectedChange={setSelected}
            columns={[
              selectColumn(),
              {
                header: 'Scope',
                accessorKey: 'scope',
                sortingFn: sortScope('scope'),
              },
              {
                header: 'Action',
                accessorKey: 'action',
                sortingFn: sortScope('action'),
              },
            ]}
            data={tableData}
            initialSorting={[
              { id: 'scope', desc: false },
              { id: 'action', desc: false },
            ]}
            topRight={() => (
              <div className="ml-auto">
                {selectedIds.length > 0 ? (
                  <Button
                    variant="destructive"
                    size="sm"
                    onClick={async () => {
                      await Promise.all(
                        selectedIds.map((id) =>
                          deletePermission.mutateAsync({
                            id,
                          }),
                        ),
                      );
                      await refetch();
                      setSelected({});
                    }}
                  >
                    Remove permission{selectedIds.length > 1 ? 's' : ''}
                  </Button>
                ) : (
                  <Sheet>
                    <SheetTrigger asChild>
                      <Button variant="outline" size="sm">
                        Add permission
                      </Button>
                    </SheetTrigger>
                    <SheetContentExtension>
                      <SheetHeader className="p-4">
                        <SheetTitle>Add permission</SheetTitle>
                        <SheetDescription>
                          Add a permission to this group
                        </SheetDescription>
                      </SheetHeader>
                      <div className="px-4">
                        <FormRenderer
                          fields={[
                            {
                              label: 'Scope',
                              type: 'select',
                              name: 'scope',
                              combobox: true,
                              schema: z.string({
                                required_error: 'Scope is required',
                              }),
                              description:
                                'Select the scope for this permission',
                              options: scopes.map((scope) => ({
                                value: scope.id,
                                label: scope.value,
                                keywords: scope.value.split('::'),
                              })),
                            },
                            {
                              label: 'Action',
                              type: 'input',
                              name: 'action',
                              description:
                                'Enter the action for this permission',
                              schema: z
                                .string()
                                .min(1, { message: 'Action is required' }),
                            },
                          ]}
                          onSubmit={async (values, form) => {
                            await createGroupPermission.mutateAsync({
                              target: group.platformId,
                              scope: values.scope,
                              action: values.action,
                            });
                            form.reset();
                            await refetch();
                          }}
                        >
                          {({ onSubmit }) => (
                            <div>
                              <Button
                                variant="outline"
                                size="sm"
                                onClick={onSubmit}
                              >
                                Add permission
                              </Button>
                            </div>
                          )}
                        </FormRenderer>
                      </div>
                    </SheetContentExtension>
                  </Sheet>
                )}
              </div>
            )}
          />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default GroupConfigurator;
