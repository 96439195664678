import React, { PropsWithChildren } from 'react';
import { PlatformObject, PlatformObjectSources } from 'lib/types';
import { MediaContext } from './context';
import {
  useForceUpdateOnMediaChange,
  useGetObjectSource,
  useMediaBoundaryContext,
  useMediaContext,
  useMediaProvider,
  useObjectSource,
  useRegisterObjectsOnMount,
} from './hooks';

const MediaProvider = ({ children }: PropsWithChildren) => {
  const {
    registeredObjects,
    registeredObjectSources,
    registerObjects,
    registerObjectSources,
    deregisterObjects,
  } = useMediaProvider();
  return (
    <MediaContext.Provider
      value={{
        registeredObjects,
        registeredObjectSources,
        registerObjects,
        registerObjectSources,
        deregisterObjects,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};

export const MediaBoundary = ({
  children,
  registeredObjects,
  registeredObjectSources,
}: PropsWithChildren<{
  registeredObjects: Omit<PlatformObject, 'sources'>[];
  registeredObjectSources: {
    id: PlatformObject['id'];
    sources: PlatformObjectSources;
  }[];
}>) => {
  /*
   * Due to an issue with @react-pdf/renderer, we need to
   * place any context providers inside the root <Document>.
   * A MediaBoundary component is a workaround for this issue:
   * (https://github.com/diegomura/react-pdf/issues/1371).
   */
  return (
    <MediaContext.Provider
      value={{
        registeredObjects,
        registeredObjectSources,
        // The below methods are noops because we don't want to register
        // objects inside a <Document> component as we will very likely
        // have already registered them outside the <Document> component.
        registerObjects: () => {},
        registerObjectSources: () => {},
        deregisterObjects: () => {},
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};

export {
  useRegisterObjectsOnMount,
  useGetObjectSource,
  useObjectSource,
  useForceUpdateOnMediaChange,
  useMediaContext,
  useMediaBoundaryContext,
};

export default MediaProvider;
