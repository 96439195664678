import {
  CommercialEstimateStatus,
  JobIssueItemStatus,
  JobIssueStatus,
  JobQuoteStatus,
  JobReportStatus,
  JobTaskStatus,
} from 'lib/types';

export const jobTaskStatusColors = {
  REQUIRES_ASSIGNMENT: '#F87171',
  [JobTaskStatus.Pending]: '#00c4ff',
  [JobTaskStatus.Started]: '#FBBF24',
  [JobTaskStatus.Completed]: '#34D399',
};

export const jobIssueStatusColors = {
  [JobIssueStatus.Open]: '#F87171',
  [JobIssueStatus.Pending]: '#FBBF24',
  [JobIssueStatus.Closed]: '#34D399',
};

export const jobIssueItemStatusColors = {
  [JobIssueItemStatus.Blocking]: '#F87171',
  [JobIssueItemStatus.NotBlocking]: '#FBBF24',
  [JobIssueItemStatus.Resolved]: '#34D399',
};

export const jobReportStatusColors = {
  [JobReportStatus.Open]: '#F87171',
  [JobReportStatus.Pending]: '#FBBF24',
  [JobReportStatus.Submitted]: '#ab9d79',
  [JobReportStatus.Closed]: '#34D399',
};

export const jobQuoteStatusColors = {
  [JobQuoteStatus.Open]: '#F87171',
  [JobQuoteStatus.Submitted]: '#ab9d79',
  [JobQuoteStatus.Closed]: '#34D399',
};

export const commercialEstimateStatusColors = {
  [CommercialEstimateStatus.Estimating]: '#00c4ff',
  [CommercialEstimateStatus.Submitted]: '#FBBF24',
  [CommercialEstimateStatus.Approved]: '#34D399',
  [CommercialEstimateStatus.Rejected]: '#F87171',
};
