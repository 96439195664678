import React from 'react';
import { Button } from '@/components/ui/button';
import { TableRenderer } from '@/components/ui/table/table/Table';
import { UserSheet } from '@/components/ui/user';
import { useUserGroupContext } from '../../context';
import GroupConfigurator from '../GroupConfigurator';
import GroupUserSelector from '../GroupUserSelector';
import TableActions from './components/TableActions';

const GroupTable = () => {
  const { group, users } = useUserGroupContext();
  return (
    <TableRenderer
      filter="name"
      filterPlaceholder="Search..."
      columns={[
        {
          header: 'Name',
          cell: ({ row }) => (
            <UserSheet id={row.original.id}>
              <Button variant="table" size="table">
                {row.original.name}
              </Button>
            </UserSheet>
          ),
        },
        {
          id: 'actions',
          enableHiding: false,
          cell: ({ row }) => {
            return (
              <div className="flex justify-end">
                <TableActions user={row.original} />
              </div>
            );
          },
        },
      ]}
      data={users}
      topRight={({ columnsSelector }) => (
        <div className="ml-auto space-x-2">
          {group && (
            <>
              <GroupConfigurator />
              <GroupUserSelector />
            </>
          )}
          {columnsSelector}
        </div>
      )}
    />
  );
};

export default GroupTable;
