import React from 'react';
import OverviewDelivery from './components/OverviewDelivery';

const ClientOverview = () => {
  return (
    <div className="flex h-full flex-col">
      <div className="flex h-[52px] flex-shrink-0 items-center border-b px-4 py-2">
        <h1 className="text-xl font-bold">Overview</h1>
      </div>
      <OverviewDelivery />
    </div>
  );
};

export default ClientOverview;
