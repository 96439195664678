import { createContext, useContext } from 'react';
import { useChoiceSetEditor } from './hooks';

export const ChoiceSetEditorContext = createContext(
  {} as ReturnType<typeof useChoiceSetEditor>['context'],
);

export const useChoiceSetEditorContext = () => {
  return useContext(ChoiceSetEditorContext);
};
