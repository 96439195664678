import SubcontractorsTable from '../SubcontractorsTable';

const Subcontractors = () => {
  return (
    <div className="p-4">
      <SubcontractorsTable />
    </div>
  );
};

export default Subcontractors;
