import React from 'react';
import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import { Report, ReportBlockComponentProps, ReportMode } from 'lib/shared';
import { JobTask, User } from 'lib/types';
import { parseForClient } from 'lib/utils';
import { usePages } from '../../../../../../../../../../shared/FormRenderer/parse';

const BlockJobTask = ({
  context,
  getBlockComponent,
}: ReportBlockComponentProps) => {
  const { mode, theme } = Report.useContext();

  const {
    task,
    selected,
    eachIndex = 0,
  } = parseForClient(context) as {
    task: JobTask;
    selected: string[];
    eachIndex: number;
  };

  const { data } = Report.useQuery<{ jobTask: JobTask }>(
    `jobTaskTimings-${task.id}`,
    gql`
      query GetJobTaskTimings($jobTaskId: ID!) {
        jobTask(jobTaskId: $jobTaskId) {
          timesheetActivity {
            duration
            durationActive
            durationChargeable
            firstEvent {
              dateTimeStart
            }
            lastEvent {
              dateTimeEnd
            }
            duties {
              id
              user {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      jobTaskId: task.id,
    },
  );

  if (!data?.jobTask) return null;
  return (
    <>
      <Report.View
        bookmark={{ title: task.name }}
        break={eachIndex > 0}
        style={
          mode === ReportMode.Edit && eachIndex === 0
            ? { marginTop: 16 }
            : undefined
        }
      >
        <Report.View
          style={{ paddingLeft: 32, paddingRight: 32, marginBottom: 16 }}
        >
          <Report.Text
            style={{ fontSize: 14, fontWeight: 600, lineHeight: '2' }}
          >
            Task {task.number} - {task.name}
          </Report.Text>
          <Report.Text
            style={{
              fontSize: 12,
              color: theme.colors.subtitle,
              lineHeight: '2',
            }}
          >
            A job task is a specific piece of work that needs to be done as part
            of a job.
          </Report.Text>
        </Report.View>
        <Report.Stack>
          <Report.Section title="Task Details">
            <Report.Text
              style={{ fontSize: 12, padding: 12, lineHeight: '1.5' }}
            >
              {task.description}
            </Report.Text>
          </Report.Section>
          <BlockJobTaskDates task={data.jobTask} />
          <BlockJobTaskAttendees task={data.jobTask} />
        </Report.Stack>
        {task.forms
          .filter((form) => selected.includes(form.id))
          .map((form, index) => (
            <BlockJobTaskForm
              key={form.id}
              form={form}
              index={index}
              getBlockComponent={getBlockComponent}
            />
          ))}
      </Report.View>
    </>
  );
};

const BlockJobTaskDates = ({ task }: { task: JobTask }) => {
  const { theme } = Report.useContext();
  const { firstEvent, lastEvent } = task.timesheetActivity;
  if (!firstEvent?.dateTimeStart || !lastEvent?.dateTimeEnd) return null;
  return (
    <Report.Section title="Task Dates">
      <Report.View style={{ padding: 16 }}>
        <Report.View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 8,
          }}
        >
          <Report.Text
            style={{
              width: 64,
              fontSize: 12,
              fontWeight: 600,
              color: theme.colors.subtitle,
            }}
          >
            Start
          </Report.Text>
          <Report.Text style={{ fontSize: 14, fontWeight: 600 }}>
            {DateTime.fromISO(firstEvent.dateTimeStart).toFormat(
              'HH:mm, EEEE, MMMM dd, yyyy',
            )}
          </Report.Text>
        </Report.View>
        <Report.View
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Report.Text
            style={{
              width: 64,
              fontSize: 12,
              fontWeight: 600,
              color: theme.colors.subtitle,
            }}
          >
            End
          </Report.Text>
          <Report.Text style={{ fontSize: 14, fontWeight: 600 }}>
            {DateTime.fromISO(lastEvent.dateTimeEnd).toFormat(
              'HH:mm, EEEE, MMMM dd, yyyy',
            )}
          </Report.Text>
        </Report.View>
      </Report.View>
    </Report.Section>
  );
};

const BlockJobTaskAttendees = ({ task }: { task: JobTask }) => {
  const { theme } = Report.useContext();
  const { duties = [] } = task.timesheetActivity;
  const users = duties
    .filter((duty) => duty.user)
    .filter(
      (duty, index, self) =>
        self.findIndex((inner) => inner.user?.id === duty.user?.id) === index,
    )
    .map((duty) => duty.user) as User[];
  return (
    <Report.Section title="Task Attendees">
      <Report.View style={{ padding: 16 }}>
        {users.map((user) => (
          <Report.View
            key={user.id}
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Report.Text
              style={{
                width: 64,
                fontSize: 12,
                fontWeight: 600,
                color: theme.colors.subtitle,
              }}
            >
              Staff
            </Report.Text>
            <Report.Text style={{ fontSize: 14, fontWeight: 600 }}>
              {user.name}
            </Report.Text>
          </Report.View>
        ))}
      </Report.View>
    </Report.Section>
  );
};

const BlockJobTaskForm = ({
  form,
  index,
  getBlockComponent,
}: {
  form: any;
  index: number;
  getBlockComponent: any;
}) => {
  const { theme } = Report.useContext();
  const { name, description, pages, template, submission, shouldRender } =
    useBlockForm(form);

  return (
    <Report.FormContext.Provider
      value={{
        template,
        submission,
        shouldRender,
        getBlockComponent,
      }}
    >
      <Report.View
        break
        bookmark={{ title: name, expanded: false, parent: true }}
      >
        <Report.View
          style={{ paddingLeft: 32, paddingRight: 32, marginBottom: 16 }}
        >
          <Report.Text
            style={{ fontSize: 14, fontWeight: 600, lineHeight: '2' }}
          >
            Form {index + 1} - {name}
          </Report.Text>
          <Report.Text
            style={{
              fontSize: 12,
              lineHeight: '2',
              color: theme.colors.subtitle,
            }}
          >
            {description ?? 'No description'}
          </Report.Text>
        </Report.View>
        {pages.map((page) => (
          <Report.FormPage key={page.id} page={page} />
        ))}
      </Report.View>
    </Report.FormContext.Provider>
  );
};

export const useBlockForm = (form: any) => {
  const submissionTemplate = JSON.parse(form.latestSubmission.template);
  const submission = JSON.parse(form.latestSubmission.data);
  const template = JSON.parse(submissionTemplate.data);

  const { pages } = usePages(template, submission.values, [], (item) => {
    return !item.data.isPrivate;
  });

  const paths = pages.flatMap((page) =>
    page.items.map((inner: any) => inner.path),
  );

  const shouldRender = (path: string) => {
    return paths.includes(path);
  };

  return {
    name: submissionTemplate.name,
    description: submissionTemplate.description,
    template,
    submission,
    pages,
    paths,
    shouldRender,
  };
};

export default BlockJobTask;
