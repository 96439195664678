import React, { PropsWithChildren } from 'react';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { cn } from '@/lib/utils';
import { useActionContext } from '../../context';
import { ActionStatus } from '../../types';
import ActionButton from '../ActionButton';
import ActionTooltip from '../ActionTooltip';

const ActionDialog = ({ children }: PropsWithChildren) => {
  const { action, state, dialog } = useActionContext();
  if (!action) return null;
  return (
    <Dialog open={dialog.open}>
      <ActionTooltip>
        <span>
          <DialogTrigger asChild>
            <ActionButton />
          </DialogTrigger>
        </span>
      </ActionTooltip>
      <DialogContent
        overlay
        className={cn('px-4 pb-4 pt-3.5', {
          'ring-2 ring-success':
            state.status === ActionStatus.PerformCleanup ||
            state.status === ActionStatus.PerformSucceeded,
        })}
        hideClose
        onCloseAutoFocus={(event) => event.preventDefault()}
      >
        <DialogHeader className="flex flex-row">
          <div className="grow">
            <DialogTitle className="text-md mb-1">{action.label}</DialogTitle>
            <DialogDescription className="text-xs">
              {action.description}
            </DialogDescription>
          </div>
          <Button
            style={{ marginTop: 0 }}
            className="flex-shrink-0 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
            variant="ghost"
            size="icon-xs"
            onClick={dialog.onClose}
          >
            <Cross2Icon className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </Button>
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default ActionDialog;
