import { createContext, useContext } from 'react';
import { useTimesheet } from './hooks';

export const TimesheetContext = createContext(
  {} as ReturnType<typeof useTimesheet>['context'],
);

export const useTimesheetContext = () => {
  return useContext(TimesheetContext);
};
