import { useSyncedStore } from '@syncedstore/react';
import { BlockData, LogicData } from 'external/types';
import { Item } from '../../../Tree';
import { useFormTemplateContext } from '../../context';
import { useSyncedTransact } from '../../hooks';
import { DATA_TYPE_MAP, DataType } from '../../types';

export const useBlockConfigurator = (item: Item<BlockData>) => {
  const { store } = useFormTemplateContext();
  const { items, choiceSets } = useSyncedStore(store);
  const transact = useSyncedTransact(store);

  const info = DATA_TYPE_MAP[item.data.dataType as keyof typeof DATA_TYPE_MAP];
  const choiceSet = choiceSets?.[item.data.choiceSetId as string] ?? null;

  const handleDataTypeOnClick = (type: DataType) => {
    transact(() => {
      (items[item.id].data as BlockData).dataType = type;
      if (items[item.id].data.choiceSetId) {
        (items[item.id].data as BlockData).choiceSetId = undefined;
        console.debug('cleared choice set for', item.id);
      }
      for (const childId of items[item.id].children) {
        const child = items[childId];
        if (child.type === 'LOGIC') {
          (items[child.id].data as LogicData).constants = undefined;
          console.debug('cleared constants for', child.id);
        }
      }
    });
  };

  return {
    info,
    choiceSet,
    handleDataTypeOnClick,
  };
};
