import React, { PropsWithChildren } from 'react';
import { PageError, PageLoader } from 'lib/shared';
import CommercialActivity from './components/CommercialActivity';
import CommercialActivityCreate from './components/CommercialActivityCreate';
import { JobCommercialContext, useJobCommercialContext } from './context';
import { useJobCommercial } from './hooks';

const JobCommercial = () => {
  return (
    <JobCommercialProvider>
      <JobCommercialRenderer />
    </JobCommercialProvider>
  );
};

export const JobCommercialProvider = ({ children }: PropsWithChildren) => {
  const { context } = useJobCommercial();
  return (
    <JobCommercialContext.Provider value={context}>
      {children}
    </JobCommercialContext.Provider>
  );
};

const JobCommercialRenderer = () => {
  const { state } = useJobCommercialContext();

  if (state.loading) {
    return (
      <div className="h-full p-4">
        <PageLoader>Loading commercial activity...</PageLoader>
      </div>
    );
  }

  if (state.error) {
    return (
      <div className="h-full p-4">
        <PageError>Error loading commercial activity.</PageError>
      </div>
    );
  }

  if (!state.exists) {
    return <CommercialActivityCreate />;
  }

  return (
    <div className="flex min-h-0 grow flex-col bg-secondary">
      <CommercialActivity />
    </div>
  );
};

export default JobCommercial;
