import React from 'react';
import JobGantt from '../../components/JobGantt';

const JobTimeline = () => {
  return (
    <div className="grow">
      <JobGantt />
    </div>
  );
};

export default JobTimeline;
