import React from 'react';
import { Badge } from '@/components/ui/badge';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { DateTime, Interval } from 'luxon';
import { useStore } from '../../state';
import RosterExceedanceDescription from '../RosterExceedanceDescription';

const RosterReviewExceedances = () => {
  const { exceedances, interval } = useStore((state) => ({
    exceedances: state.rosterValidationResults?.exceedances,
    interval: Interval.fromDateTimes(state.dateTimeStart, state.dateTimeEnd),
  }));
  const exceedancesInView =
    exceedances?.filter((exceedance) => {
      const exceedanceInterval = Interval.fromDateTimes(
        DateTime.fromISO(exceedance.duty.dateTimeStart),
        DateTime.fromISO(exceedance.duty.dateTimeEnd),
      );
      return interval.overlaps(exceedanceInterval);
    }) ?? [];
  return (
    <Table>
      <TableHeader className="border-b">
        <TableRow>
          <TableHead className="pl-3">Status</TableHead>
          <TableHead>Exceedance</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {exceedancesInView.length > 0 ? (
          exceedancesInView.map((exceedance) => {
            return (
              <TableRow
                key={`${exceedance.duty.id}-${exceedance.offence}`}
                className="h-[52px]"
              >
                <TableCell>
                  {exceedance.review ? (
                    <Badge variant="outline">Reviewed</Badge>
                  ) : (
                    <Badge variant="outline">Outstanding</Badge>
                  )}
                </TableCell>
                <TableCell>
                  <RosterExceedanceDescription exceedance={exceedance} />
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={3} className="p-4 pl-3">
              <p className="mb-1 font-medium">No exceedances</p>
              <p className="text-xs text-gray-400">
                All validation checks passed
              </p>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default RosterReviewExceedances;
