import React from 'react';
import { Construction } from 'lucide-react';
import { cn } from '@/lib/utils';
import { UnderConstructionProps } from './types';

const UnderConstruction = ({ className }: UnderConstructionProps) => {
  return (
    <div className={cn('h-full w-full p-4 pb-12', className)}>
      <div className="flex h-full w-full items-center justify-center rounded-md border border-dashed">
        <div>
          <Construction className="dark:text-white-400 mx-auto mb-2 h-4 w-4 text-gray-400" />
          <p className="dark:text-white-400 text-xs text-gray-400">
            Under Construction
          </p>
        </div>
      </div>
    </div>
  );
};

export default UnderConstruction;
