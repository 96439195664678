import React, { HTMLAttributes } from 'react';
import ReactPDF, { View as ViewBase } from '@react-pdf/renderer';
import ReportComponent from '../ReportComponent';

import ViewProps = ReactPDF.ViewProps;

const EditView = ({
  children,
  minPresenceAhead: minPresenceAhead_ = 0,
  break: break_ = false,
  bookmark,
  wrap,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  minPresenceAhead?: number;
  wrap?: boolean;
  break?: boolean;
  bookmark?: any;
}) => {
  return (
    // We need to use @ts-ignore because the Box component does not have the
    // break or wrap props but the ReportView must support it as the ReactPDF.View
    // component does have these props.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <div
      {...props}
      id={bookmark?.title}
      style={{
        scrollMarginTop: 16,
        ...props.style,
        ...(break_ ? { marginTop: 16 } : {}),
      }}
    >
      {children}
    </div>
  );
};

const View = ({
  children,
  break: break_ = false,
  ...props
}: React.PropsWithChildren<ViewProps>) => {
  return (
    <ViewBase
      {...props}
      break={break_}
      style={{
        ...props.style,
        ...(break_ ? {} : {}),
      }}
    >
      {children}
    </ViewBase>
  );
};

const ReportView = (
  props:
    | ReactPDF.ViewProps
    | (HTMLAttributes<HTMLDivElement> & { bookmark?: any }),
) => {
  return (
    <ReportComponent
      editComponent={EditView}
      viewComponent={View}
      props={props}
    />
  );
};

export default ReportView;
