import React, { useEffect, useMemo, useState } from 'react';
import { FileIcon } from 'lucide-react';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { MediaInputImage } from '@/components/ui/form/form-media/FormMedia';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { formatDistanceToNow } from 'date-fns';
import { filesize } from 'filesize';
import { DateTime } from 'luxon';
import * as Y from 'yjs';
import { Empty, PdfSheet } from 'lib/shared';
import { PlatformObject, User } from 'lib/types';
import { NoteUpdate } from '../../../../../../../../external/Notes';
import { Note, NotesStore } from '../../../../../../../../external/Notes/types';
import { useRegisterObjectsOnMount } from '../../../../../../../../shared/MediaProvider';

const TaskNote = ({
  note,
  store,
  doc,
}: {
  note: Note;
  store: NotesStore;
  doc: Y.Doc;
}) => {
  const collaborators = useMemo(() => {
    const map = note.history.reduce(
      (collaborators, update) => {
        if (!collaborators[update.user.id]) {
          collaborators[update.user.id] = {
            user: update.user,
            updates: 0,
          };
        }
        collaborators[update.user.id].updates++;
        return collaborators;
      },
      {} as Record<
        string,
        { user: Pick<User, 'id' | 'name' | 'email'>; updates: number }
      >,
    );
    return Object.values(map);
  }, [note.history]);

  const [lastUpdate, setLastUpdate] = useState(() => {
    const latest = note.history.at(-1);
    if (latest) {
      return {
        user: latest.user,
        dateTime: DateTime.fromISO(latest.dateTime),
      };
    }
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      const latest = note.history.at(-1);
      if (latest) {
        setLastUpdate({
          user: latest.user,
          dateTime: DateTime.fromISO(latest.dateTime),
        });
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [note.history]);

  return (
    <div key={note.id} className="flex flex-col rounded-lg bg-background p-4">
      <div className="mb-3 flex items-center justify-between">
        <div className="flex gap-x-2">
          <p className="text-xs font-semibold">Note</p>
          {lastUpdate && (
            <p className="text-xs">
              <span className="dark:text-gray-400">(Latest update: </span>
              <span className="font-medium">{lastUpdate.user.name} </span>
              <span>
                {formatDistanceToNow(lastUpdate.dateTime.toJSDate())} ago
              </span>
              <span className="dark:text-gray-400">)</span>
            </p>
          )}
        </div>
        <div className="flex gap-x-4">
          <NoteUpdate note={note} store={store} doc={doc} />
        </div>
      </div>
      <div className="mb-3">
        <p className="text-sm">{note.content}</p>
      </div>
      <div className="flex items-center justify-between whitespace-nowrap">
        <div>
          <div className="flex gap-x-2">
            <div className="border-r pr-2">
              <p className="mb-[2px] text-xs font-medium dark:text-gray-400">
                Collaborators
              </p>
              <p className="text-xs dark:text-gray-400">
                {collaborators.length} user{collaborators.length !== 1 && 's'}
              </p>
            </div>
            <div className="flex gap-x-2">
              {collaborators.map(({ user, updates }) => (
                <div key={user.id}>
                  <p className="mb-[2px] text-xs font-semibold">{user.name}</p>
                  <p className="text-xs dark:text-gray-400">
                    {updates} update{updates !== 1 && 's'}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex gap-x-2">
          <div className="flex items-center border-r pr-2">
            <div>
              <p className="mb-[2px] text-xs font-medium dark:text-gray-400">
                Media
              </p>
              <p
                className={cn('text-xs', {
                  'font-semibold': note.objects.length > 0,
                  'dark:text-gray-400': note.objects.length === 0,
                })}
              >
                {note.objects.length} object{note.objects.length !== 1 && 's'}
              </p>
            </div>
          </div>
          {note.objects.length > 0 ? (
            <MediaRow objects={note.objects} />
          ) : (
            <div>
              <Empty horizontal>No media</Empty>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const MediaRow = ({ objects }: { objects: PlatformObject[] }) => {
  useRegisterObjectsOnMount(objects);

  const images = objects.filter((object) => object.type === 'IMAGE');
  const documents = objects.filter((object) => object.type !== 'IMAGE');

  return (
    <div className="flex gap-x-2">
      {images.map((image) => (
        <MediaRowImage key={image.id} object={image} />
      ))}
      {documents.map((document) => (
        <MediaRowDocument key={document.id} object={document} />
      ))}
    </div>
  );
};

const MediaRowImage = ({ object }: { object: PlatformObject }) => {
  return <MediaInputImage nested={false} upload={object} />;
};

const MediaRowDocument = ({ object }: { object: PlatformObject }) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div className="flex w-[180px] gap-x-1 rounded-lg border">
          <PdfSheet objectId={object.id}>
            <button>
              <Avatar className="m-1 h-10 w-10 rounded-lg">
                <AvatarFallback className="rounded-lg text-gray-400">
                  <FileIcon />
                </AvatarFallback>
              </Avatar>
            </button>
          </PdfSheet>
          <div className="flex w-full flex-col justify-between overflow-hidden whitespace-nowrap py-1.5 pr-1">
            <p className="truncate text-xs">{object.name}</p>
            <div className="flex gap-x-2">
              <p className="text-xs font-medium uppercase text-gray-600">
                {object.name.split('.').at(-1)}
              </p>
              {object.size && (
                <p className="text-xs text-gray-600">
                  {filesize(object.size ?? 0)}
                </p>
              )}
            </div>
          </div>
        </div>
      </TooltipTrigger>
      <TooltipContent>
        <div className="flex items-center gap-x-2">
          <p className="text-sm">{object.name}</p>
        </div>
      </TooltipContent>
    </Tooltip>
  );
};

export default TaskNote;
