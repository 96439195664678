import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { z } from 'zod';
import { useSubcontractorsCreate } from './hooks';

const SubcontractorsCreate = () => {
  const { sheet, onCreate } = useSubcontractorsCreate();
  return (
    <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
      <SheetTrigger asChild>
        <Button className="pl-2" variant="outline" size="sm">
          <Plus className="mr-1 size-4" />
          Create subcontractor
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader className="mb-4">
          <SheetTitle>Create Subcontractor</SheetTitle>
          <SheetDescription>
            Fill in the form below to create a new subcontractor
          </SheetDescription>
        </SheetHeader>
        <FormRenderer
          fields={[
            {
              type: 'input',
              name: 'name',
              label: 'Name',
              schema: z.string().min(1).max(255),
            },
          ]}
          onSubmit={onCreate}
        />
      </SheetContent>
    </Sheet>
  );
};

export default SubcontractorsCreate;
