import {
  ChangeEvent,
  KeyboardEvent,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MapRef } from 'react-map-gl';
import { gql, useMutation } from '@apollo/client';
import { debounce } from 'lodash';
import { useMapStyle } from 'lib/hooks';
import { Location } from 'lib/types';
import { generateId } from 'lib/utils';
import { LocationPickerContext } from './context';
import {
  EstimateLocationData,
  EstimateLocationVariables,
  Result,
  ResultType,
  Viewport,
} from './types';
import {
  getBounds,
  mapPlaceToResult,
  mapPointToResult,
  resultToAddress,
} from './utils';

export const useLocationPickerContext = () => {
  return useContext(LocationPickerContext);
};

export const useLocationPicker = (
  value: Location | undefined,
  onChange: (value: Location) => void,
) => {
  const mapRef = useRef<MapRef>(null);
  const mapStyle = useMapStyle();
  const [mapReady, setMapReady] = useState(false);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const [hovered, setHovered] = useState<Result | null>(null);
  const [focused, setFocused] = useState<Result | null>(null);
  const [selected, setSelected] = useState<Result | null>(null);
  const [hidden, setHidden] = useState<Result[]>([]);

  const [placingMarker, setPlacingMarker] = useState(false);
  const [markerPosition, setMarkerPosition] = useState<[number, number]>([
    0, 0,
  ]);

  const [marked, setMarked] = useState<Result | null>(null);

  const [internalValue, setInternalValue] = useState<Location | undefined>(
    value,
  );

  const fitBounds = (bounds: Viewport, padding: number) => {
    if (mapRef.current) {
      mapRef.current.fitBounds(
        [
          [bounds.sw.lng, bounds.sw.lat],
          [bounds.ne.lng, bounds.ne.lat],
        ],
        {
          padding: {
            top: padding + 100,
            bottom: padding,
            right: padding,
            left: padding,
          },
        },
      );
    }
  };

  const [centered, setCentered] = useState(false);

  const handleOnContinue = async () => {
    if (markerPosition) {
      await estimate({
        point: {
          latitude: markerPosition[1],
          longitude: markerPosition[0],
        },
      });

      setPlacingMarker(false);
      setMarkerPosition([0, 0]);

      const location = {
        lat: markerPosition[1],
        lng: markerPosition[0],
      };

      const viewPortPadding = 0.01;
      const viewport = {
        ne: {
          lng: location.lng + viewPortPadding,
          lat: location.lat + viewPortPadding,
        },
        sw: {
          lng: location.lng - viewPortPadding,
          lat: location.lat - viewPortPadding,
        },
      };

      const result = {
        id: generateId(),
        type: ResultType.Point,
        location,
        viewport,
        data: {},
      };

      setMarked(result);
      setFocused(result);
      setSelected(result);
    }
  };

  const handleRecenter = () => {
    if (!centered && mapRef.current && selected) {
      mapRef.current.easeTo({
        center: [selected.location.lng, selected.location.lat],
        zoom: 18,
      });
      setCentered(true);
    }
  };

  const {
    estimating,
    results,
    reset: resetEstimate,
    estimate,
    estimateDebounced,
  } = useEstimateLocation();

  const nonHiddenResults = useMemo(() => {
    return results.filter((r) => !hidden.includes(r));
  }, [results, hidden]);

  const handleSearchOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleOnSearch = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      try {
        const { bounds, padding } = await estimate({ query: search });
        fitBounds(bounds, padding);
      } catch (error) {
        console.error('Error estimating location', error);
      }
    }
  };

  const handleOnMouseEnter = (result: Result) => {
    return () => {
      setHovered(result);
    };
  };

  const handleOnMouseLeave = () => {
    setHovered(null);
  };

  const handleOnSelect = (result: Result) => {
    setSelected(result);
    setCentered(true);
    if (mapRef.current && result.location) {
      mapRef.current.easeTo({
        center: [result.location.lng, result.location.lat],
        zoom: 18,
      });
    }
  };

  const handleOnClear = async (reset = false) => {
    setSelected(null);
    setFocused(null);
    setPlacingMarker(false);

    if (marked) {
      setMarked(null);
      setSearch('');
      resetEstimate();
      return;
    }

    if (results?.length) {
      fitBounds(getBounds(results), results.length > 1 ? 64 : 0);
    }

    if (reset) {
      await handleOpen(value);
    }
  };

  const handleToggleHideOnClick = (result: Result) => {
    return () => {
      setHidden((hidden) => {
        const ret = hidden.includes(result)
          ? hidden.filter((r) => r.id !== result.id)
          : [...hidden, result];
        const nonHidden = results.filter((r) => !ret.includes(r));
        const nonHiddenBounds = getBounds(nonHidden);
        fitBounds(nonHiddenBounds, nonHidden.length > 1 ? 64 : 0);
        return ret;
      });
    };
  };

  const handleOnSave = async () => {
    if (
      selected?.type === ResultType.Place &&
      selected?.data?.addressComponents
    ) {
      const nextValue = {
        type: ResultType.Place,
        externalId: selected.id,
        address: resultToAddress(selected),
        precise: {
          geometry: {
            point: {
              latitude: selected.location.lat,
              longitude: selected.location.lng,
            },
          },
        },
      };
      await onChange(nextValue as any);
      setOpen(false);
      setMapReady(false);
    }

    if (selected?.type === ResultType.Point) {
      const nextValue = {
        type: ResultType.Point,
        precise: {
          geometry: {
            point: {
              latitude: selected.location.lat,
              longitude: selected.location.lng,
            },
          },
        },
      };
      await onChange(nextValue as any);
      setOpen(false);
      setMapReady(false);
    }
  };

  const handleOnCancel = () => {
    setSelected(null);
    fitBounds(
      getBounds(nonHiddenResults),
      nonHiddenResults.length > 1 ? 64 : 0,
    );
  };

  const handleReset = () => {
    setInternalValue(value);
    setSelected(null);
    setFocused(null);
    resetEstimate();
    setHidden([]);
    setMapReady(false);
    setMarked(null);
  };

  const handleOpen = async (value: Location | undefined) => {
    if (!value?.address?.formatted) return null;
    setSearch(value.address.formatted);
    setMarked(null);
    const result = await estimate({ query: value.address.formatted });
    if (result && mapRef.current && result.bounds) {
      const { bounds, padding } = result;
      fitBounds(bounds, padding);
      setHidden([]);
    }
  };

  return {
    context: {
      estimating,
      estimation: {
        estimating,
        results,
        estimate,
        estimateDebounced,
      },
      open,
      search,
      hovered,
      selected,
      hidden,
      internalValue,
      mapRef,
      mapReady,
      mapStyle,
      nonHiddenResults,
      focused,
      centered,
      placingMarker,
      markerPosition,
      marked,
      setMarked,
      handleOpen,
      setSearch,
      setMarkerPosition,
      setPlacingMarker,
      setCentered,
      handleRecenter,
      setFocused,
      fitBounds,
      handleSearchOnChange,
      handleOnSearch,
      handleOnMouseEnter,
      handleOnMouseLeave,
      handleOnSelect,
      handleOnContinue,
      handleToggleHideOnClick,
      handleOnSave,
      handleOnCancel,
      handleReset,
      setOpen,
      setMapReady,
      handleOnClear,
    },
  };
};

export const useEstimateLocation = (
  onEstimate?: () => void,
  onSuccess?: (response: {
    results: Result[];
    bounds: Viewport;
    padding: number;
  }) => void,
) => {
  const [results, setResults] = useState<Result[]>([]);
  const [bounds, setBounds] = useState<Viewport>();

  const [estimate, { loading: estimating }] = useMutation<
    EstimateLocationData,
    EstimateLocationVariables
  >(
    gql`
      mutation EstimateLocation(
        $query: String
        $point: LocationEstimatePointInput
      ) {
        estimateLocation(query: $query, point: $point) {
          places
          points
        }
      }
    `,
    { fetchPolicy: 'no-cache' },
  );

  const handleEstimateLocation = async (
    variables: EstimateLocationVariables,
  ) => {
    onEstimate?.();
    const { data } = await estimate({ variables });

    if (!data) {
      throw new Error('No data returned from estimateLocation');
    }

    const places = data.estimateLocation.places;
    const points = data.estimateLocation.points;

    if (places.length + points.length === 0) {
      throw new Error('No results returned from estimateLocation');
    }

    const results = [
      ...places.map(mapPlaceToResult),
      ...points.map(mapPointToResult),
    ];

    const bounds = getBounds(results);

    setResults(results);
    setBounds(bounds);

    const padding = results.length > 1 ? 64 : 0;
    const ret = { places, points, results, bounds, padding };
    onSuccess?.(ret);
    return ret;
  };

  const handleEstimateLocationDebounced = debounce(handleEstimateLocation, 500);

  const handleResetEstimation = () => {
    setResults([]);
    setBounds(undefined);
  };

  return {
    estimating,
    results,
    bounds,
    estimate: handleEstimateLocation,
    estimateDebounced: handleEstimateLocationDebounced,
    reset: handleResetEstimation,
  };
};
