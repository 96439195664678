import { FC } from 'react';
import { Training } from 'lib/types';

export enum MemberTrainingFormMode {
  Create = 'create',
  Update = 'update',
}

export type MemberTrainingFormProps = {
  children: FC<{ form: any; onSubmit: () => void }>;
  training?: Training;
  onSubmit: (values: any) => Promise<void>;
};
