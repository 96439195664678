import React from 'react';
import { useNavigate } from 'react-router';
import { Action, Actions } from 'external';
import { useUser } from 'lib/hooks';
import { useTodoContext } from '../../../../../../external/TodosProvider/hooks';
import { useClient } from '../../../../../Client/hooks';
import { useJobContext } from '../../../../context';
import { useConfirmed, useIssues, useSerializedState } from '../../hooks';

const InstantiationActions = () => {
  const navigate = useNavigate();
  const user = useUser();
  const client = useClient();
  const { job } = useJobContext();
  const issues = useIssues();
  const confirmed = useConfirmed(4);
  const serializedState = useSerializedState();
  const { refetchTodos } = useTodoContext();

  if (job.partial) return null;

  return (
    <Actions
      actionSetId={job.actionSetId as string}
      scope={`jobs::job::${job.id}`}
      queryKey={[job.id, 'instantiation']}
      context={{
        event: {
          data: {
            user,
            delete: true,
            instantiate: true,
            is_site_verified: confirmed,
            job: {
              id: job.id,
              reference: job.reference,
              site: { reference: job.site.reference },
              instantiation: serializedState,
            },
            issue_label: `Open issue with ${issues.length} item${issues.length > 1 ? 's' : ''}`,
            issues: issues.map((issue) => ({
              type: issue.type,
              details: issue.note,
            })),
          },
        },
      }}
    >
      <div className="flex justify-between">
        <div className="flex gap-x-2">
          <Action
            id="delete"
            small
            onSuccess={async () => {
              await client.jobs.refetch();
              await refetchTodos();
              navigate(`/clients/${job.client.slug}/jobs`);
            }}
          />
          <Action
            id="reject"
            small
            onSuccess={async () => {
              await Promise.all([
                client.jobs.refetch(),
                job.refetch(),
                refetchTodos(),
              ]);
            }}
          />
        </div>
        {issues.length > 0 ? (
          <Action
            id="instantiate-with-issue"
            small
            onSuccess={async () => {
              await Promise.all([
                client.jobs.refetch(),
                job.refetch(),
                refetchTodos(),
              ]);
            }}
          />
        ) : (
          <Action
            id="instantiate"
            small
            onSuccess={async () => {
              await Promise.all([
                client.jobs.refetch(),
                job.refetch(),
                refetchTodos(),
              ]);
            }}
          />
        )}
      </div>
    </Actions>
  );
};

export default InstantiationActions;
