import React from 'react';
import { Button } from '@/components/ui/button';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import {
  Sheet,
  SheetContentExtension,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { TableRenderer } from '@/components/ui/table/table/Table';
import { useUserResourceRates } from '@/components/ui/user/components/UserResourceRates/hooks';
import {
  UpdateCommercialResourceRateMutationData,
  UpdateCommercialResourceRateMutationVariables,
} from '@/components/ui/user/components/UserResourceRates/types';
import { useUserSheetContext } from '@/components/ui/user/context';
import { gql, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { toast } from 'sonner';
import { z } from 'zod';
import { CommercialResourceRate } from 'lib/types';

const UserResourceRates = () => {
  const { sheet, rates, handlers } = useUserResourceRates();
  return (
    <div>
      <div>
        <p className="mb-1 text-sm font-medium">Resource rates</p>
        <p className="text-sm text-gray-400 dark:text-gray-400">
          Used to calculate the cost of a user's time. You can create new
          resource rates for this user.
        </p>
      </div>
      <TableRenderer
        simple
        filter="description"
        topRight={({ columnsSelector }) => (
          <>
            <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
              <SheetTrigger asChild>
                <Button className="ml-2 flex-grow" variant="outline" size="sm">
                  Create
                </Button>
              </SheetTrigger>
              <SheetContentExtension right={500} className="p-4">
                <SheetHeader className="mb-4">
                  <SheetTitle>Add resource rate</SheetTitle>
                  <SheetDescription>
                    Create a new resource rate for this user.
                  </SheetDescription>
                </SheetHeader>
                <FormRenderer
                  fields={[
                    {
                      name: 'description',
                      label: 'Description',
                      type: 'input',
                      schema: z.string().min(1),
                    },
                    {
                      name: 'unit',
                      label: 'Unit',
                      type: 'select',
                      schema: z.string(),
                      options: [
                        {
                          label: 'Hour',
                          value: 'HOUR',
                        },
                        {
                          label: 'Day',
                          value: 'DAY',
                        },
                        {
                          label: 'Week',
                          value: 'WEEK',
                        },
                        {
                          label: 'Month',
                          value: 'MONTH',
                        },
                        {
                          label: 'Year',
                          value: 'YEAR',
                        },
                        {
                          label: 'Unit',
                          value: 'UNIT',
                        },
                      ],
                    },
                    {
                      name: 'unitCost',
                      label: 'Unit Cost',
                      type: 'number',
                      schema: z.number(),
                    },
                    {
                      name: 'base',
                      label: 'Base',
                      description: 'Is this a base rate?',
                      type: 'boolean',
                      schema: z.boolean(),
                    },
                    {
                      name: 'dateStart',
                      label: 'Date Start',
                      type: 'date',
                      schema: z.any(),
                    },
                    {
                      name: 'dateEnd',
                      label: 'Date End',
                      type: 'date',
                      schema: z.any(),
                    },
                  ]}
                  onSubmit={handlers.onCreate}
                >
                  {({ form, onSubmit }) => (
                    <div>
                      <Button
                        size="sm"
                        className="mt-2 w-full"
                        variant="secondary"
                        disabled={!form.formState.isValid}
                        onClick={onSubmit}
                      >
                        Create resource rate
                      </Button>
                    </div>
                  )}
                </FormRenderer>
              </SheetContentExtension>
            </Sheet>
            {columnsSelector}
          </>
        )}
        columns={[
          {
            header: 'Description',
            cell: ({ row }) => <Rate rate={row.original} />,
          },
          {
            accessorKey: 'unitDisplay',
            header: 'Unit',
            cell: ({ row }) => row.original.unitDisplay ?? <em>Not set</em>,
          },
          {
            accessorKey: 'unitCost',
            header: 'Unit Cost',
            cell: ({ row }) => row.original.unitCost.toFixed(2),
          },
        ]}
        data={rates}
      />
    </div>
  );
};

const Rate = ({ rate }: { rate: CommercialResourceRate }) => {
  const { user } = useUserSheetContext();

  const [open, setOpen] = React.useState(false);

  const [update] = useMutation<
    UpdateCommercialResourceRateMutationData,
    UpdateCommercialResourceRateMutationVariables
  >(
    gql`
      mutation UpdateCommercialResourceRate(
        $id: ID!
        $description: String!
        $base: Boolean!
        $unit: String!
        $unitCost: Float!
        $dateStart: DateTime!
        $dateEnd: DateTime!
      ) {
        updateCommercialResourceRate(
          commercialResourceRateId: $id
          description: $description
          base: $base
          unit: $unit
          unitCost: $unitCost
          dateStart: $dateStart
          dateEnd: $dateEnd
        ) {
          commercialResourceRate {
            id
            description
            base
            unit
            unitDisplay
            unitCost
            dateStart
            dateEnd
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnUpdate = async (values: any) => {
    const { data, errors } = await update({
      variables: {
        id: rate.id,
        description: values.description,
        base: values.base,
        unit: values.unit,
        unitCost: values.unitCost,
        dateStart: values.dateStart,
        dateEnd: values.dateEnd,
      },
    });

    if (data) {
      await user.refetch();
      setOpen(false);
      toast.success('Resource rate updated');
    }

    if (errors) {
      console.error(errors);
      toast.error('Resource rate update failed');
    }
  };

  const [delete_] = useMutation(
    gql`
      mutation DeleteCommercialResourceRate($id: ID!) {
        deleteCommercialResourceRate(commercialResourceRateId: $id) {
          deleted
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnDelete = async () => {
    const { data, errors } = await delete_({
      variables: {
        id: rate.id,
      },
    });

    if (data) {
      await user.refetch();
      toast.success('Resource rate deleted');
    }

    if (errors) {
      console.error(errors);
      toast.error('Resource rate deletion failed');
    }
  };

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button size="table" variant="table">
          {rate.description}
        </Button>
      </SheetTrigger>
      <SheetContentExtension right={500} className="p-4">
        <SheetHeader className="mb-4">
          <SheetTitle>Update resource rate</SheetTitle>
          <SheetDescription>
            Update the resource rate for this user.
          </SheetDescription>
        </SheetHeader>
        <FormRenderer
          fields={[
            {
              name: 'description',
              label: 'Description',
              type: 'input',
              schema: z.string(),
              defaultValue: rate.description,
            },
            {
              name: 'unit',
              label: 'Unit',
              type: 'select',
              schema: z.string(),
              options: [
                {
                  label: 'Hour',
                  value: 'HOUR',
                },
                {
                  label: 'Day',
                  value: 'DAY',
                },
                {
                  label: 'Week',
                  value: 'WEEK',
                },
                {
                  label: 'Month',
                  value: 'MONTH',
                },
                {
                  label: 'Year',
                  value: 'YEAR',
                },
                {
                  label: 'Unit',
                  value: 'UNIT',
                },
              ],
              defaultValue: rate.unit,
            },
            {
              name: 'unitCost',
              label: 'Unit Cost',
              type: 'number',
              schema: z.number(),
              defaultValue: rate.unitCost,
            },
            {
              name: 'base',
              label: 'Base',
              description: 'Is this a base rate?',
              type: 'boolean',
              schema: z.boolean(),
              defaultValue: rate.base,
            },
            {
              name: 'dateStart',
              label: 'Date Start',
              type: 'date',
              schema: z.any(),
              defaultValue: DateTime.fromISO(
                rate.dateStart,
              ) as unknown as string,
            },
            {
              name: 'dateEnd',
              label: 'Date End',
              type: 'date',
              schema: z.any(),
              defaultValue: DateTime.fromISO(rate.dateEnd) as unknown as string,
            },
          ]}
          onSubmit={handleOnUpdate}
        >
          {({ onSubmit }) => (
            <div>
              <Button
                className="mt-2 w-full"
                size="sm"
                variant="outline"
                onClick={onSubmit}
              >
                Update
              </Button>
              <Button confirm="Are you sure?" onClick={handleOnDelete}>
                Delete
              </Button>
            </div>
          )}
        </FormRenderer>
      </SheetContentExtension>
    </Sheet>
  );
};

export default UserResourceRates;
