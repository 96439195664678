import React, { CSSProperties, PropsWithChildren } from 'react';
import { Loader } from '@/components/ui/loading';
import { cn } from '@/lib/utils';

const PageLoader = ({
  children,
  className,
  style,
}: PropsWithChildren<{ className?: string; style?: CSSProperties }>) => {
  return (
    <div className={cn('h-full w-full ', className)} style={style}>
      <div className="flex h-full w-full items-center justify-center rounded-md border border-dashed">
        <div>
          <Loader className="dark:text-white-400 mx-auto mb-2 h-4 w-4 text-gray-400" />
          <p className="dark:text-white-400 text-xs text-gray-400">
            {children}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageLoader;
