import React, { useRef, useState } from 'react';
import { MapRef } from 'react-map-gl';
import LocationAddress from './components/LocationAddress';
import LocationEstimates from './components/LocationEstimates';
import LocationMap from './components/LocationMap';
import { LocationMapMarker } from './components/LocationMap/types';
import LocationPointsOfInterest from './components/LocationPointsOfInterest';
import LocationPrecise from './components/LocationPrecise';
import { Provider } from './context';
import { StoreProvider } from './store';
import { LocationProps } from './types';

const LocationContext = ({
  children,
  location,
  supplementaryLocation,
  locationOnCreate,
  locationOnUpdate,
  defaultViewState,
}: LocationProps) => {
  const mapRef = useRef<MapRef>(null);
  const [mapMarkers, setMapMarkers] = useState<LocationMapMarker[]>([]);
  return (
    <Provider
      value={{
        mapRef,
        mapMarkers,
        setMapMarkers,
        location,
        supplementaryLocation,
        handlers: {
          locationOnCreate,
          locationOnUpdate,
        },
      }}
    >
      <StoreProvider defaultViewState={defaultViewState}>
        {children}
      </StoreProvider>
    </Provider>
  );
};

export {
  LocationAddress,
  LocationContext,
  LocationEstimates,
  LocationPointsOfInterest,
  LocationPrecise,
  LocationMap,
};
