import { cn } from '@/lib/utils';
import { useCommercialEstimateContext } from '../../context';
import EstimateActions from '../EstimateActions';
import EstimateApprove from '../EstimateApprove';

const EstimateFooter = () => {
  const { estimate } = useCommercialEstimateContext();
  if (!estimate) return null;
  return (
    <div className="grid grid-cols-8 border-t bg-background/75 pl-4 pr-[17px] backdrop-blur-sm">
      <div className="col-span-5 border-r" />
      <div className="col-span-1 flex flex-col border-r bg-secondary px-2 py-1">
        <span className="text-sm font-bold">
          {estimate.totalEstimatedValue.toFixed(2)}
        </span>
        <div className="col-span-1 flex items-center text-xs font-medium opacity-75">
          Total value
        </div>
      </div>
      <div className="col-span-1 flex flex-col border-r bg-red-500/25 px-2 py-1">
        <span className="flex items-center">
          <span className="text-sm font-bold">
            {estimate.totalEstimatedCostWithPrelims.toFixed(2)}
          </span>
        </span>
        <div className="col-span-1 flex items-center text-xs font-medium opacity-75">
          Total cost
        </div>
      </div>
      <div
        className={cn(
          'col-span-1 flex flex-col whitespace-nowrap border-r px-2 py-1',
          {
            'bg-red-500/25 text-red-500':
              estimate.totalEstimatedMarginWithPrelims < 0,
            'bg-green-700/25 text-green-700':
              estimate.totalEstimatedMarginWithPrelims > 0,
          },
        )}
      >
        <span className="flex items-center">
          <span className="text-sm font-bold">
            {estimate.totalEstimatedMarginWithPrelims.toFixed(2)}
          </span>
          <span className="ml-2 text-xs font-bold">
            {(estimate.totalEstimatedMarginRatioWithPrelims * 100).toFixed(1)}%
          </span>
        </span>
        <div className="col-span-1 flex items-center text-xs font-semibold">
          Gross margin
        </div>
      </div>
    </div>
  );
};

export default EstimateFooter;
