import React from 'react';
import { User } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import ContactsDelete from '../ContactsDelete';
import { ContactsDetailProps } from './types';

const ContactsDetail = ({ contact }: ContactsDetailProps) => {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button size="table" variant="table">
          <User className="mr-2 size-4" />
          <span className="font-semibold">{contact.name}</span>
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>{contact.name}</SheetTitle>
          <SheetDescription> {contact.email}</SheetDescription>
        </SheetHeader>
        <div>
          <div></div>
          <ContactsDelete contact={contact} />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default ContactsDetail;
