import React from 'react';
import { toast } from 'react-toastify';
import { Button } from '@/components/ui/button';
import {
  color,
  duration,
  input,
  select,
} from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { Loader } from '@/components/ui/loading';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { z } from 'zod';
import { useClient } from '../../../../../../../hooks';
import { usePriorityEditor } from './hooks';
import { PriorityEditorProps } from './types';

const PriorityEditor = ({ id, onSuccess }: PriorityEditorProps) => {
  const client = useClient();
  const { priority, sheet, state, handlers } = usePriorityEditor(id, onSuccess);
  return (
    <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
      <SheetTrigger asChild>
        <Button variant="outline" size="sm">
          {id ? 'Edit' : 'Create priority'}
        </Button>
      </SheetTrigger>
      <SheetContent className=" min-h-0 p-0">
        <div className="relative h-full min-h-0">
          {state.loading && (
            <div className="absolute inset-0 p-4 backdrop-blur-sm">
              <Loader>Loading...</Loader>
            </div>
          )}
          <ScrollArea className="h-full">
            <div className="p-4">
              <FormRenderer
                key={id}
                fields={[
                  input({
                    label: 'Reference',
                    name: 'reference',
                    schema: z.string(),
                    defaultValue: priority?.reference,
                  }),
                  color({
                    label: 'Color',
                    name: 'color',
                    schema: z.string(),
                    defaultValue: priority?.color,
                  }),
                  input({
                    label: 'Response name',
                    name: 'responseName',
                    schema: z.string(),
                    defaultValue: priority?.responseName,
                  }),
                  input({
                    label: 'Response name short',
                    name: 'responseNameShort',
                    description: 'Abbreviated response name',
                    schema: z.string(),
                    defaultValue: priority?.responseNameShort,
                  }),
                  duration({
                    label: 'Response duration',
                    name: 'responseDuration',
                    schema: z.coerce.number(),
                    defaultValue: priority?.responseDuration,
                  }),
                  input({
                    label: 'Completion name',
                    name: 'completionName',
                    schema: z.string(),
                    defaultValue: priority?.completionName,
                  }),
                  input({
                    label: 'Completion name short',
                    name: 'completionNameShort',
                    description: 'Abbreviated completion name',
                    schema: z.string(),
                    defaultValue: priority?.completionNameShort,
                  }),
                  duration({
                    label: 'Completion duration',
                    name: 'completionDuration',
                    schema: z.coerce.number(),
                    defaultValue: priority?.completionDuration,
                  }),
                  select({
                    label: 'Duration space',
                    name: 'durationSpace',
                    options: [
                      {
                        label: 'Working days',
                        value: 'WORKING_DAYS',
                      },
                      {
                        label: 'All days',
                        value: 'ALL_DAYS',
                      },
                    ],
                    schema: z.string(),
                    defaultValue: priority?.durationSpace,
                  }),
                ]}
                onSubmit={async (values) => {
                  if (id) {
                    await handlers.update({
                      variables: {
                        id,
                        data: values,
                      },
                    });
                    toast.success('Priority updated');
                    sheet.onOpenChange(false);
                    onSuccess();
                  } else {
                    await handlers.create({
                      variables: {
                        clientId: client.id,
                        data: values,
                      },
                    });
                    toast.success('Priority created');
                    sheet.onOpenChange(false);
                    onSuccess();
                  }
                }}
              >
                {({ onSubmit }) => (
                  <div>
                    {id ? (
                      <Button
                        size="sm"
                        variant="outline"
                        className="w-full"
                        isLoading={state.updating}
                        onClick={onSubmit}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        variant="outline"
                        className="w-full"
                        isLoading={state.creating}
                        onClick={onSubmit}
                      >
                        Create
                      </Button>
                    )}
                  </div>
                )}
              </FormRenderer>
            </div>
          </ScrollArea>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default PriorityEditor;
