import React from 'react';
import { Draggable, DraggableStateSnapshot } from '@hello-pangea/dnd';
import { useTreeContext } from '../../context';
import { getItemDepth } from '../../tracking';
import { TreeItemProps } from './types';

const TreeItem = ({ item, index, isLast, renderItem }: TreeItemProps) => {
  const { currentItemDepth, items, getShouldParentIncreaseDepth } =
    useTreeContext();
  const depth = getItemDepth(item, items, getShouldParentIncreaseDepth);

  const getDraggableStyle = (
    snapshot: DraggableStateSnapshot,
    style: React.CSSProperties = {},
  ) => {
    if (!snapshot.isDropAnimating || !snapshot.dropAnimation) {
      return style;
    }

    const previousDepth = getItemDepth(
      item,
      items,
      getShouldParentIncreaseDepth,
    );
    const diffX = ((currentItemDepth ?? 0) - previousDepth) * 40;

    const { moveTo } = snapshot.dropAnimation;
    const translate = `translate(${moveTo.x + diffX}px, ${moveTo.y}px)`;

    const width = `calc(${
      style?.width ? `${style.width}px` : '100%'
    } - ${diffX}px)`;

    return {
      ...style,
      transform: translate,
      width,
    };
  };

  return (
    <Draggable draggableId={item.id} index={index}>
      {(
        { innerRef, draggableProps, dragHandleProps },
        draggableStateSnapshot,
      ) => {
        const itemStyle = getDraggableStyle(
          draggableStateSnapshot,
          draggableProps.style,
        );

        return (
          <div ref={innerRef} {...draggableProps} style={itemStyle}>
            <div
              style={{ position: 'relative', marginLeft: `${40 * depth}px` }}
            >
              {renderItem({
                item,
                index,
                isLast,
                draggableProps,
                dragHandleProps,
                draggableStateSnapshot,
              })}
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default TreeItem;
