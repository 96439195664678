import React, { useState } from 'react';
import { Building } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { input } from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import {
  Sheet,
  SheetContent,
  SheetContentExtension,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { z } from 'zod';
import { LocationPicker } from 'lib/shared';
import { Location } from 'lib/types';
import { useSubcontractorContext } from '../../../../context';
import { LocationsDetailProps } from './types';

const LocationsDetail = ({ location }: LocationsDetailProps) => {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button size="table" variant="table">
          <Building className="mr-2 size-4" />
          <span className="font-semibold">{location.name}</span>
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Location</SheetTitle>
          <SheetDescription>Configure the location details</SheetDescription>
        </SheetHeader>
        <div>
          <NameEditor id={location.id} value={location.name} />
          <LocationEditor value={location?.location} />
        </div>
      </SheetContent>
    </Sheet>
  );
};

const NameEditor = ({ id, value }: { id: string; value: string }) => {
  const { subcontractor } = useSubcontractorContext();

  const [open, setOpen] = useState(false);

  const [update] = useMutation(
    gql`
      mutation UpdateSubcontractor(
        $id: ID!
        $field: String!
        $value: JSONScalarInput!
      ) {
        updateSubcontractorLocation(
          subcontractorLocationId: $id
          field: $field
          value: $value
        ) {
          subcontractorLocation {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnChange = async (field: string, value: string) => {
    if (!subcontractor) return;

    const { data, errors } = await update({
      variables: {
        id,
        field,
        value,
      },
    });

    if (data) {
      await subcontractor.refetch();
      setOpen(false);
      toast.success('Location updated');
    }

    if (errors) {
      console.error(errors);
      toast.error('Failed to update location');
    }
  };

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button size="table" variant="table">
          <Building className="mr-2 size-4" />
          {value}
        </Button>
      </SheetTrigger>
      <SheetContentExtension right={384}>
        <SheetHeader>
          <SheetTitle>Location Name</SheetTitle>
          <SheetDescription>Configure the location name</SheetDescription>
        </SheetHeader>
        <FormRenderer
          fields={[
            input({
              name: 'name',
              label: 'Name',
              schema: z.string().min(1),
              defaultValue: value,
            }),
          ]}
          onSubmit={async (values) => {
            await handleOnChange('name', values.name);
          }}
        >
          {({ onSubmit }) => (
            <div>
              <Button onClick={onSubmit}>Update</Button>
            </div>
          )}
        </FormRenderer>
      </SheetContentExtension>
    </Sheet>
  );
};

const LocationEditor = ({ value }: { value?: Location }) => {
  const { subcontractor } = useSubcontractorContext();

  const [open, setOpen] = useState(false);

  const [update] = useMutation(
    gql`
      mutation UpdateLocation($id: ID, $data: LocationEstimateInput!) {
        updateLocation(locationId: $id, locationEstimate: $data) {
          location {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnChange = async (value: Location) => {
    if (!subcontractor) return;

    const { data, errors } = await update({
      variables: {
        id: value.id,
        data: value,
      },
    });

    if (data) {
      await subcontractor.refetch();
      setOpen(false);
      toast.success('Location updated');
    }

    if (errors) {
      console.error(errors);
      toast.error('Failed to update location');
    }
  };

  return (
    <LocationPicker
      value={value}
      onChange={async (value) => {
        if (value) {
          await handleOnChange(value);
        }
      }}
    >
      <div>{JSON.stringify(value)}</div>
      <Button onClick={() => setOpen(true)}>
        <Button>Select Location</Button>
      </Button>
      {open && <LocationEditorDialog onClose={() => setOpen(false)} />}
    </LocationPicker>
  );
};

const LocationEditorDialog = ({ onClose }: { onClose: () => void }) => {
  const { handleReset } = LocationPicker.useContext();
  return (
    <div className="fixed inset-4 right-[400px] grid grid-cols-12">
      <div className="col-span-5">
        <LocationPicker.Search />
      </div>
      <div className="col-span-7">
        <LocationPicker.Map />
      </div>
      <LocationPicker.Confirm />
      <div className="absolute right-3 top-3">
        <LocationPicker.Close
          onClose={() => {
            onClose();
            handleReset();
          }}
        />
      </div>
    </div>
  );
};

export default LocationsDetail;
