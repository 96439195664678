import React from 'react';
import { Loader } from '@/components/ui/loading';
import { cn } from '@/lib/utils';
import { useYjs } from 'lib/hooks';
import { useJobContext } from '../../context';
import InstantiationActions from './components/InstantiationActions';
import InstantiationBody from './components/InstantiationBody';
import InstantiationCommercial from './components/InstantiationCommercial';
import InstantiationDescription from './components/InstantiationDescription';
import InstantiationHeader from './components/InstantiationHeader';
import InstantiationLocation from './components/InstantiationLocation';
import InstantiationTimeline from './components/InstantiationTimeline';
import { InstantiationContext } from './context';
import { useInstantiation } from './hooks';
import { InstantiationRendererProps, InstantiationStore } from './types';

const JobInstantiation = () => {
  const { job } = useJobContext();

  const { isReady, doc, store } = useYjs(
    'jobs::job',
    job.partial ? undefined : `${job.id}::instantiation`,
    { stages: {}, history: [] } as InstantiationStore,
    { debugName: `${job.reference} instantiation` },
  );

  if (!store || !doc?.current) return null;
  if (!isReady) {
    // todo: maybe this should fade in to prevent flickering
    return (
      <div className="h-full p-4">
        <div className={cn('h-full w-full')}>
          <div className="flex h-full w-full items-center justify-center rounded-md border border-dashed">
            <div>
              <Loader className="dark:text-white-400 mx-auto h-4 w-4 text-gray-400">
                Loading job...
              </Loader>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <InstantiationRenderer store={store} doc={doc.current} />;
};

const InstantiationRenderer = (props: InstantiationRendererProps) => {
  const { context } = useInstantiation(props);
  return (
    <InstantiationContext.Provider value={context}>
      <div className="relative flex h-full min-h-0 w-full flex-col bg-background py-10 bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-secondary">
        <div className="container flex h-full min-h-0 max-w-[900px] flex-col rounded-lg border bg-background p-0">
          <InstantiationHeader />
          <InstantiationBody>
            <InstantiationTimeline />
            <InstantiationDescription />
            <InstantiationCommercial />
            <InstantiationLocation />
          </InstantiationBody>
        </div>
        <div className="absolute inset-x-0 bottom-0 z-10 border-t bg-background p-4 shadow-2xl">
          <div className="container max-w-[900px] p-0">
            <InstantiationActions />
          </div>
        </div>
      </div>
    </InstantiationContext.Provider>
  );
};

export default JobInstantiation;
