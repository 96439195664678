import {
  ResourceTimeRangeModelConfig,
  TimeSpanConfig,
} from '@bryntum/scheduler-thin';
import {
  AssignmentModelConfig,
  ResourceModelConfig,
} from '@bryntum/schedulerpro-thin';
import chroma from 'chroma-js';
import { DateTime } from 'luxon';
import {
  Job,
  Subcontractor,
  SubcontractorDuty,
  TimesheetDuty,
  UserGroup,
} from 'lib/types';
import {
  PreparedSubcontractorDuty,
  PreparedTimesheetDuty,
  PreparedTimesheetEvent,
  SchedulerDuty,
  SchedulerResourceType,
} from './types';

export const compareDateTimes = (
  a: DateTime | undefined | null,
  b: DateTime | undefined | null,
) => {
  if (!a || !b) return false;
  return a.equals(b);
};

export const prepareTimesheetDuty = (duty: TimesheetDuty, job?: Job) => {
  return {
    ...duty,
    job: duty.activity.jobTask?.job,
    jobTask: duty.activity.jobTask,
    // Check if the duty is active by comparing the job id
    // of the activity with the job id of the current job.
    // Note, the new timesheet module's generality means that
    // we don't have to render exclusively jobs on the scheduler.
    isActive: duty.activity.jobTask?.job?.id === job?.id,
    dateTimeCreated: DateTime.fromISO(duty.dateTimeCreated),
    dateTimeStart: duty.dateTimeStart
      ? DateTime.fromISO(duty.dateTimeStart)
      : null,
    dateTimeEnd: duty.dateTimeEnd ? DateTime.fromISO(duty.dateTimeEnd) : null,
    events: duty.events.map(
      (event) =>
        ({
          ...event,
          dateTimeStart: DateTime.fromISO(event.dateTimeStart),
          dateTimeEnd: event.dateTimeEnd
            ? DateTime.fromISO(event.dateTimeEnd)
            : null,
        }) as PreparedTimesheetEvent,
    ),
  } as PreparedTimesheetDuty;
};

export const prepareSubcontractorDuty = (
  duty: SubcontractorDuty,
  job?: Job,
) => {
  return {
    ...duty,
    job,
    dateTimeStart: DateTime.fromISO(duty.dateTimeStart),
    dateTimeEnd: DateTime.fromISO(duty.dateTimeEnd),
  } as PreparedSubcontractorDuty;
};

export const prepareResources = (
  scopes: string[],
  userGroups: UserGroup[],
  subcontractors: Subcontractor[],
) => {
  return [
    ...userGroups.flatMap((group) =>
      group.users.map(
        (user) =>
          ({
            id: user.id,
            name: user.name,
            email: user.email,
            initials: user.initials,
            type: SchedulerResourceType.User,
            user,
          }) as Partial<ResourceModelConfig>,
      ),
    ),
  ].filter(
    (resource, index, self) =>
      index === self.findIndex((inner) => inner.id === resource.id),
  );
};

export const prepareResourceTimeRanges = (
  preparedDuties: PreparedTimesheetDuty[],
) => {
  return preparedDuties.flatMap((duty) =>
    duty.events.map(
      (event) =>
        ({
          id: event.id,
          startDate: event.dateTimeStart
            ? event.dateTimeStart.startOf('minute').toJSDate()
            : null,
          startDateType: event.dateTimeStartType,
          endDate: event.dateTimeEnd
            ? event.dateTimeEnd.startOf('minute').toJSDate()
            : null,
          endDateType: event.dateTimeEndType,
          timesheetDuty: duty,
          timesheetEvent: event,
          resourceId: duty.user?.id ?? duty.subcontractor?.id,
          name: 'Timesheet Event',
          type: duty.user
            ? SchedulerResourceType.User
            : SchedulerResourceType.Subcontractor,
          timeRangeColor: chroma.random().hex(),
        }) as Partial<ResourceTimeRangeModelConfig>,
    ),
  );
};

export const prepareTimeRanges = (job: Job) => {
  const ret: Partial<TimeSpanConfig>[] = [
    {
      id: 'job-timeline',
      name: 'Issued',
      startDate: job.timeline.dateTimeStart,
    },
    {
      id: 'job-timeline-range',
      name: '',
      startDate: DateTime.fromISO(job.timeline.dateTimeStart)
        .minus({ year: 1 })
        .toJSDate(),
      endDate: job.timeline.dateTimeStart,
    },
  ];
  for (const target of job.timeline.targets) {
    ret.push({
      id: `job-timeline-${target.id}`,
      cls: 'capitalize',
      name: target.type.replace(/_/g, ' ').toLowerCase(),
      startDate: target.dateTime,
    });
  }
  return ret;
};

export const prepareEvents = (preparedDuties: PreparedTimesheetDuty[]) => {
  return preparedDuties.map(
    (duty) =>
      ({
        id: duty.id,
        type: duty.user
          ? SchedulerResourceType.User
          : SchedulerResourceType.Subcontractor,
        name: !duty.isActive
          ? `#${duty.jobTask?.number}`
          : `${duty.job?.reference} #${duty.jobTask?.number}`,
        startDate: duty.dateTimeStart ? duty.dateTimeStart.toJSDate() : null,
        endDate: duty.dateTimeEnd ? duty.dateTimeEnd.toJSDate() : null,
        timesheetDuty: duty,
        showInTimeline: duty.isActive,
        readOnly: !duty.isActive,
        isActive: duty.isActive,
      }) as Partial<SchedulerDuty>,
  );
};

export const prepareAssignments = (preparedDuties: PreparedTimesheetDuty[]) => {
  return preparedDuties.flatMap(
    (duty) =>
      ({
        id: duty.id,
        type: duty.user
          ? SchedulerResourceType.User
          : SchedulerResourceType.Subcontractor,
        eventId: duty.id,
        resourceId: duty.user?.id ?? duty.subcontractor?.id,
      }) as Partial<AssignmentModelConfig>,
  );
};
