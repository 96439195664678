import { FilterFn, Row } from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';

export const rankScope = (value: string, filterValue: string) => {
  const valueSplit = value.split('::');
  const valueCompare = [...valueSplit, value];
  const score =
    valueCompare.reduce((acc, segment) => {
      const segmentScore = rankItem(segment, filterValue).rank;
      return acc + segmentScore;
    }, 0) / valueSplit.length;
  return {
    rankedValue: value,
    rank: score,
    accessorIndex: 0,
    accessorThreshold: undefined,
    passed: score > 0,
  };
};

export const rank = <Data>(
  row: Row<Data>,
  columnId: string,
  filterValue: string,
) => {
  const value = row.getValue(columnId);

  if (columnId === 'action' || columnId === 'scope') {
    return rankScope(value as string, filterValue);
  }

  return rankItem(value, filterValue);
};

export const rankMulti: FilterFn<any> = (
  row,
  columnId,
  filterValue,
  addMeta,
) => {
  let score = 0;
  let passed = true;

  for (const term of filterValue.split(' ')) {
    const itemRank = rank(row, columnId, term);
    score += itemRank.rank;
    passed = passed && itemRank.passed;
  }

  const itemRank = {
    rankedValue: row.getValue(columnId),
    rank: score,
    accessorIndex: 0,
    accessorThreshold: undefined,
    passed,
  };

  addMeta({ itemRank });

  return passed;
};

export const sortScope = <Data>(columnId: string) => {
  return (a: Row<Data>, b: Row<Data>) => {
    const segmentsA = a.getValue<string>(columnId).split('::');
    const segmentsB = b.getValue<string>(columnId).split('::');
    for (let i = 0; i < Math.max(segmentsA.length, segmentsB.length); i++) {
      if (segmentsA[i] && segmentsB[i]) {
        if (segmentsA[i] < segmentsB[i]) return -1;
        if (segmentsA[i] > segmentsB[i]) return 1;
      } else if (segmentsA[i]) {
        return 1;
      } else if (segmentsB[i]) {
        return -1;
      }
    }
    return 0;
  };
};
