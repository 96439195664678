import React from 'react';
import { flexRender } from '@tanstack/react-table';
import { Hexagon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { cn } from '@/lib/utils';
import JobLink from '../../../../../../components/JobLink';
import { AssignmentsEmptyProps } from './types';

const AssignmentsEmpty = ({
  table,
  columns,
  assignPath,
}: AssignmentsEmptyProps) => {
  return (
    <div className="grow border-t">
      <Table wrapperClassname="h-full" className="h-full">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                return (
                  <TableHead
                    key={header.id}
                    className={cn('w-1 whitespace-nowrap', {
                      'pl-2': index === 0,
                    })}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell
              colSpan={columns.length}
              className="h-full p-4 text-center"
            >
              <div className="dark:text-white-400 flex h-full flex-col items-center justify-center gap-4 rounded-lg border border-dashed text-gray-400">
                <Hexagon className="dark:text-white-400 mx-auto h-8 w-8 text-gray-400" />
                <p className="dark:text-white-400 text-xs text-gray-400">
                  Task not yet assigned.
                </p>
                <JobLink to={assignPath} relative="path">
                  <Button
                    variant="outline"
                    size="sm"
                    className="text-black dark:text-white"
                  >
                    Assign task
                  </Button>
                </JobLink>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default AssignmentsEmpty;
