import React from 'react';
import { useNavigate } from 'react-router';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { cn } from '@/lib/utils';
import { useMatch } from 'lib/hooks';
import { useWindowManager } from '../../../../shared/WindowManager/hooks';
import { useClient } from '../../../Client/hooks';
import { useJobContext } from '../../context';

const JobSelector = () => {
  const navigate = useNavigate();

  const { windowed, setPath } = useWindowManager();

  const client = useClient();

  const { state, job } = useJobContext();

  const matchVariation = useMatch(
    '/clients/:clientSlug/jobs/:jobReference/:variation/*',
  );

  const small = false;

  return (
    <div className="flex flex-nowrap items-center">
      <h4
        className={cn('whitespace-nowrap font-semibold', {
          'text-lg': small,
          'text-2xl': !small,
        })}
        style={{ fontFamily: 'Roboto Mono' }}
      >
        {job.reference}
      </h4>
      <h4
        className={cn('ml-2 mr-1', {
          'text-md': small,
          'text-xl': !small,
        })}
      >
        /
      </h4>
      <Select
        value={
          state.variation ? matchVariation?.params.variation || 'ROOT' : 'ROOT'
        }
        onValueChange={(nextValue) => {
          if (job.partial) {
            return;
          }

          let path: string;
          if (nextValue === 'ROOT') {
            path = client.paths.jobs.job(job).root;
          } else {
            path = `${client.paths.jobs.job(job).root}/${nextValue}`;
          }
          if (windowed) {
            setPath(path);
            navigate(`?window=${path}`);
          } else {
            navigate(path);
          }
        }}
      >
        <SelectTrigger
          className={cn(
            'h-8 min-w-0 border-none px-1 font-semibold shadow-none hover:bg-accent focus:ring-0',
            {
              'text-lg': small,
              'text-2xl': !small,
            },
          )}
          style={{ fontFamily: 'Roboto Mono' }}
          hideIcon
        >
          <SelectValue className="mr-2" />
        </SelectTrigger>
        {!job.partial && (
          <SelectContent>
            <SelectGroup>
              <SelectItem value="ROOT">Root</SelectItem>
              {job.variations.map((variation) => (
                <SelectItem
                  key={variation.id}
                  value={variation.reference || 'Unnamed'}
                >
                  {variation.reference || 'Unnamed'}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        )}
      </Select>
    </div>
  );
};

export default JobSelector;
