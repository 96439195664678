import {
  Sheet,
  SheetContent,
  SheetContentExtension,
  SheetTrigger,
} from '@/components/ui/sheet';
import { PdfViewer } from 'lib/shared';
import { PdfSheetProps } from './types';

const PdfSheet = ({
  children,
  objectId,
  extension = false,
  right = 700,
}: PdfSheetProps) => {
  if (extension) {
    return (
      <Sheet>
        <SheetTrigger asChild>{children}</SheetTrigger>
        <SheetContentExtension
          className="w-[700px] max-w-[700px] sm:max-w-[700px]"
          right={right}
        >
          <PdfViewer objectId={objectId} />
        </SheetContentExtension>
      </Sheet>
    );
  }
  return (
    <Sheet>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent className="w-[700px] max-w-[700px] sm:max-w-[700px]">
        <PdfViewer objectId={objectId} />
      </SheetContent>
    </Sheet>
  );
};

export default PdfSheet;
