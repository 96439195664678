import { ChangeEvent, useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { CommercialUnit, CommercialValueLine } from 'lib/types';
import { useEstimateTableContext } from '../../../../context';

export const useValueLine = (line: CommercialValueLine) => {
  const { updateRefetch } = useEstimateTableContext();

  const [update] = useMutation(
    gql`
      mutation UpdateCommercialValueLine(
        $id: ID!
        $field: String!
        $value: String!
      ) {
        updateCommercialValueLine(line: $id, field: $field, value: $value) {
          commercialValueLine {
            id
            rate {
              id
            }
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnChange = useCallback(
    async (field: string, value: string) => {
      const { data, errors } = await update({
        variables: {
          id: line.id,
          field,
          value,
        },
      });

      if (data) {
        await updateRefetch();
      }

      if (errors) {
      }
    },
    [line, update, updateRefetch],
  );

  const handleRateOnChange = useCallback(
    async (value: string) => {
      await handleOnChange('rate', value);
    },
    [handleOnChange],
  );

  const handleDescriptionOnChange = useCallback(
    async (value: string, mode: string) => {
      if (mode === 'create') {
        await handleOnChange('description', value);
      }
    },
    [handleOnChange],
  );

  const handleUnitOnChange = useCallback(
    async (value: CommercialUnit) => {
      await handleOnChange('unit', value);
    },
    [handleOnChange],
  );

  const handleEstimatedQuantityOnChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      await handleOnChange('estimated_quantity', event.target.value);
    },
    [handleOnChange],
  );

  const handleEstimatedUnitValueOnChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      await handleOnChange('estimated_unit_value', event.target.value);
    },
    [handleOnChange],
  );

  const handleEstimatedUnitValueUpliftOnChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const percentage = parseFloat(event.target.value);
      const uplift = percentage / 100 + 1;
      await handleOnChange('estimated_unit_value_uplift', uplift.toString());
    },
    [handleOnChange],
  );

  const handleDescriptionEstimateOnChange = useCallback(
    async (value: string) => {
      await handleOnChange('description_estimate', value);
    },
    [handleOnChange],
  );

  const handleDescriptionInternalOnChange = useCallback(
    async (value: string) => {
      await handleOnChange('description_internal', value);
    },
    [handleOnChange],
  );

  return {
    context: {
      line,
      handlers: {
        rateOnChange: handleRateOnChange,
        descriptionOnChange: handleDescriptionOnChange,
        unitOnChange: handleUnitOnChange,
        estimatedQuantityOnChange: handleEstimatedQuantityOnChange,
        estimatedValueOnChange: handleEstimatedUnitValueOnChange,
        estimatedValueUpliftOnChange: handleEstimatedUnitValueUpliftOnChange,
        descriptionEstimateOnChange: handleDescriptionEstimateOnChange,
        descriptionInternalOnChange: handleDescriptionInternalOnChange,
      },
    },
  };
};
