import { TableRenderer } from '@/components/ui/table/table/Table';
import { useSubcontractorContext } from '../../../../context';
import ContactsDetail from '../ContactsDetail';

const ContactsTable = () => {
  const { subcontractor } = useSubcontractorContext();

  if (!subcontractor?.contacts) return null;

  return (
    <TableRenderer
      header={false}
      headerClassName="bg-background-secondary"
      wrapperClassName="border-none"
      simple
      initialSorting={[{ id: 'name', desc: false }]}
      columns={[
        {
          id: 'name',
          accessorKey: 'name',
          header: 'Name',
          className: 'pl-4',
          cell: ({ row }) => <ContactsDetail contact={row.original} />,
        },
        {
          id: 'email',
          accessorKey: 'email',
          header: 'Email',
          cell: ({ row }) => {
            if (!row.original.email) {
              return <em>Not set</em>;
            }
            return (
              <a href={`mailto:${row.original.email}`}>{row.original.email}</a>
            );
          },
        },
        {
          id: 'phone',
          accessorKey: 'phone',
          header: 'Phone',
          cell: ({ row }) => {
            if (!row.original.phone) {
              return <em>Not set</em>;
            }
            return (
              <a href={`tel:${row.original.phone}`}>{row.original.phone}</a>
            );
          },
        },
        {
          id: 'mobile',
          accessorKey: 'mobile',
          header: 'Mobile',
          cell: ({ row }) => {
            if (!row.original.mobile) {
              return <em>Not set</em>;
            }
            return (
              <a href={`tel:${row.original.mobile}`}>{row.original.mobile}</a>
            );
          },
        },
      ]}
      data={subcontractor.contacts}
    />
  );
};

export default ContactsTable;
