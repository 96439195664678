import React from 'react';
import {
  ChevronLeftIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from '@radix-ui/react-icons';
import { ChevronRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { DatePicker } from '@/components/ui/date-picker';
import { useSchedulerContext } from '../../../../hooks';
import { SchedulerAction } from '../../../../types';
import { useControlsDate } from './hooks';
import { ControlsDateProps } from './types';

const ControlsDate = ({ disabled = false }: ControlsDateProps) => {
  console.warn('ControlsDate', disabled);

  const { actions } = useSchedulerContext();
  const { value } = useControlsDate();
  return (
    <div>
      <div className="flex grow items-center justify-center gap-x-2">
        <Button
          disabled={disabled}
          size="icon-sm"
          variant="outline"
          onClick={actions.factory({
            description: 'Jumped back 7 days',
            type: SchedulerAction.ShiftTimeAxis,
            unit: 'day',
            delta: -7,
          })}
        >
          <DoubleArrowLeftIcon className="h-4 w-4" />
        </Button>
        <Button
          disabled={disabled}
          size="icon-sm"
          variant="outline"
          onClick={actions.factory({
            description: 'Jumped back 1 day',
            type: SchedulerAction.ShiftTimeAxis,
            unit: 'day',
            delta: -1,
          })}
        >
          <ChevronLeftIcon className="h-4 w-4" />
        </Button>
        <DatePicker
          value={value}
          onValueChange={(dateTime) => {
            actions.request({
              description: 'Set control date',
              type: SchedulerAction.SetControlDateTime,
              dateTime: dateTime.startOf('day'),
            });
          }}
        />
        <Button
          disabled={disabled}
          size="icon-sm"
          variant="outline"
          onClick={actions.factory({
            description: 'Jumped forward 1 day',
            type: SchedulerAction.ShiftTimeAxis,
            unit: 'day',
            delta: 1,
          })}
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
        <Button
          disabled={disabled}
          size="icon-sm"
          variant="outline"
          onClick={actions.factory({
            description: 'Jumped forward 7 days',
            type: SchedulerAction.ShiftTimeAxis,
            unit: 'day',
            delta: 7,
          })}
        >
          <DoubleArrowRightIcon className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

export default ControlsDate;
