import { gql, useMutation } from '@apollo/client';
import { useStore } from '../../state';
import {
  SubmitRosterMutationData,
  SubmitRosterMutationVariables,
} from './types';

export const useSubmitRoster = () => {
  const { user, rosterTeam, submitRosterOnCompleted } = useStore((state) => ({
    user: state.user,
    rosterTeam: state.rosterTeam,
    rosterDuties: state.rosterDuties,
    submitRosterOnCompleted: state.submitRosterOnCompleted,
  }));

  const [submitRoster, { loading: isSubmitting }] = useMutation<
    SubmitRosterMutationData,
    SubmitRosterMutationVariables
  >(gql`
    mutation ($rosterDutyIds: [ID!]!, $rosterTeamId: ID!) {
      submitRoster(rosterDutyIds: $rosterDutyIds, rosterTeamId: $rosterTeamId) {
        rosterDuties {
          id
          isPristine
          status
          statusDisplay
        }
      }
    }
  `);

  const handleOnSubmit = async (duties: string[]) => {
    if (!user) return;
    if (!rosterTeam) return;

    const { data } = await submitRoster({
      variables: {
        rosterTeamId: rosterTeam.id,
        rosterDutyIds: duties,
      },
    });

    if (data) submitRosterOnCompleted(data);
  };

  return { onSubmit: handleOnSubmit, isSubmitting };
};
