import React from 'react';
import { Button } from '@/components/ui/button';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { AsyncContext } from 'lib/shared';
import { useSitesCreate } from './hooks';

const SitesCreate = () => {
  const { fields, onSubmit } = useSitesCreate();
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" size="sm">
          Create site
        </Button>
      </SheetTrigger>
      <SheetContent className="flex w-[500px] max-w-[500px] flex-col gap-y-0 p-0 sm:max-w-[500px]">
        <SheetHeader className="border-b p-4">
          <SheetTitle>Create new site</SheetTitle>
          <SheetDescription>Create a new site for the client</SheetDescription>
        </SheetHeader>
        <div className="min-h-0 grow">
          <ScrollArea className="h-full">
            <div className="p-4">
              <FormRenderer fields={fields} onSubmit={onSubmit}>
                {({ onSubmit }) => (
                  <AsyncContext handle={async () => onSubmit()}>
                    {({ loading, handle }) => (
                      <div>
                        <Button isLoading={loading} onClick={handle}>
                          Create
                        </Button>
                      </div>
                    )}
                  </AsyncContext>
                )}
              </FormRenderer>
            </div>
          </ScrollArea>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default SitesCreate;
