import React, { Fragment } from 'react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Loader } from '@/components/ui/loading';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { cn } from '@/lib/utils';
import chroma from 'chroma-js';
import { Empty, Link } from 'lib/shared';
import { Job, JobTag } from 'lib/types';
import { useClient } from '../../../../hooks';

export const JobsBoard = ({
  className,
  onClick,
}: {
  className?: string;
  filter?: string;
  onClick?: (job: Job) => void;
}) => {
  const client = useClient();
  const {
    loading,
    active,
    requiresAction,
    requiresReview,
    incoming,
    rejected,
    progress,
    delivered,
  } = client.jobs;

  const renderJob = ({
    job,
    extraTags = [],
  }: {
    job: Job;
    extraTags?: JobTag[];
  }) => {
    const tags = [...job.tags, ...extraTags];

    const tagColors = tags.map((tag) => {
      try {
        return chroma(tag.color);
      } catch {
        return chroma('#000');
      }
    });
    const tagColorScale = chroma.scale(tagColors).mode('lab');

    return (
      <div className="group flex w-full justify-start gap-x-3 rounded-lg border bg-background px-3 py-2 text-left transition-colors hover:border-primary/50 hover:shadow">
        <div>
          <div
            className="h-full w-[3px] rounded-full"
            style={{
              backgroundImage:
                tagColors.length > 1
                  ? `linear-gradient(to bottom, ${tagColorScale(0).css()}, ${tagColorScale(1).css()})`
                  : undefined,
              backgroundColor:
                tagColors.length === 1 ? tagColorScale(0).css() : undefined,
            }}
          />
        </div>
        <div>
          <div className="mb-2">
            <div className="mb-1 flex items-center gap-x-1">
              <Link to={client.paths.jobs.job(job).root} className="block">
                <span className="text-sm font-bold">{job.reference}</span>
              </Link>
              <span className="text-xs dark:text-white/50">[Reactive]</span>
            </div>
            <p className="line-clamp-2 text-[11px] leading-4 text-gray-400 dark:text-white/75">
              {job.description}
            </p>
          </div>
          <div className="flex items-center gap-x-2">
            {tags.map((tag) => (
              <Badge
                key={tag.id}
                className="h-6 rounded-md border-none px-2 py-0 text-[11px] font-semibold"
                variant="outline"
                style={{
                  color: chroma(tag.color).css(),
                  backgroundColor: chroma(tag.color).alpha(0.1).css(),
                }}
              >
                {tag.label}
              </Badge>
            ))}
            {job.category?.code && (
              <Badge
                className="font-semibold　bg-secondary h-6 rounded-md border-none px-2 py-0 font-mono text-[10px]"
                variant="outline"
              >
                {job.category.code.replace(/_/g, ' ')}
              </Badge>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={cn('relative min-h-0 grow bg-secondary py-4', className)}>
      <div
        className={cn(
          'pointer-events-none absolute inset-0 z-10 opacity-0 backdrop-blur-[2px] transition-opacity',
          {
            'opacity-100': loading,
          },
        )}
      >
        <div className="flex h-full w-full items-center justify-center rounded-md border border-dashed">
          <div>
            <Loader className="dark:text-white-400 mx-auto mb-2 h-4 w-4 text-gray-400" />
            <p className="dark:text-white-400 text-xs text-gray-400">
              Loading...
            </p>
          </div>
        </div>
      </div>
      <div className="flex h-full divide-x">
        <div className="flex h-full basis-1/5 flex-col pl-4">
          <Tabs defaultValue="incoming">
            <TabsList className="mb-[9px] mt-0 h-8">
              <TabsTrigger value="incoming">
                <div className="flex items-center gap-x-2">
                  <span style={{ lineHeight: '24px' }}>Incoming</span>
                  <Badge
                    variant="outline"
                    className="h-4 w-4 justify-center rounded p-0 text-center group-data-[state=inactive]:border-white/10"
                  >
                    <p className="text-[10px]">{incoming.length}</p>
                  </Badge>
                </div>
              </TabsTrigger>
              <TabsTrigger value="rejected">
                <div className="flex items-center gap-x-2">
                  <span style={{ lineHeight: '24px' }}>Rejected</span>
                  <Badge
                    variant="outline"
                    className="h-4 w-4 justify-center rounded p-0 text-center group-data-[state=inactive]:border-white/10"
                  >
                    <p className="text-[10px]">{rejected.length}</p>
                  </Badge>
                </div>
              </TabsTrigger>
            </TabsList>
          </Tabs>
          <div className="flex items-center gap-x-2 py-4">
            <p className="text-xs leading-[24px]">Click to review</p>
          </div>
          <div className="min-h-0 grow">
            {incoming.length > 0 ? (
              <ScrollArea className="h-full pr-4">
                <div className="flex flex-col gap-y-2">
                  {incoming.map((job) => (
                    <Fragment key={job.id}>{renderJob({ job })}</Fragment>
                  ))}
                </div>
              </ScrollArea>
            ) : (
              <Empty className="pr-4">No incoming jobs</Empty>
            )}
          </div>
        </div>
        <div className="flex basis-3/5 flex-col px-4">
          <div className="flex items-center gap-x-2 pb-4">
            <p className="text-sm font-semibold">In progress</p>
            <Badge variant="number">{progress.length}</Badge>
          </div>
          <Separator />
          <div className="flex min-h-0 grow divide-x pt-4">
            <div className="flex h-full basis-1/3 flex-col">
              <div className="mb-4 flex items-center gap-x-2">
                <p className="text-sm font-semibold">Requires action</p>
                <Badge variant="number">{requiresAction.length}</Badge>
              </div>
              <div className="min-h-0 grow">
                {requiresAction.length > 0 ? (
                  <ScrollArea className="h-full pr-4">
                    <div className="flex flex-col gap-y-2">
                      {requiresAction.map((job) => (
                        <Fragment key={job.id}>{renderJob({ job })}</Fragment>
                      ))}
                    </div>
                  </ScrollArea>
                ) : (
                  <Empty className="pr-4">No jobs requiring action</Empty>
                )}
              </div>
            </div>
            <div className="flex h-full basis-1/3 flex-col px-4">
              <div className="mb-4 flex items-center gap-x-2">
                <p className="text-sm font-semibold">Active</p>
                <Badge variant="number">{active.length}</Badge>
              </div>
              <div className="min-h-0 grow">
                {active.length > 0 ? (
                  <ScrollArea className="h-full pr-4">
                    <div className="flex flex-col gap-y-2">
                      {active.map((job) => (
                        <Fragment key={job.id}>
                          {renderJob({
                            job,
                            extraTags: [
                              {
                                id: 'active',
                                label: 'Active',
                                color: '#FFC107',
                              },
                            ],
                          })}
                        </Fragment>
                      ))}
                    </div>
                  </ScrollArea>
                ) : (
                  <Empty>No active jobs</Empty>
                )}
              </div>
            </div>
            <div className="flex h-full basis-1/3 flex-col pl-4">
              <div className="mb-4 flex items-center gap-x-2">
                <p className="text-sm font-semibold">Requires review</p>
                <Badge variant="number">{requiresReview.length}</Badge>
              </div>
              <div className="min-h-0 grow">
                {requiresReview.length > 0 ? (
                  <ScrollArea className="h-full pr-4">
                    <div className="flex flex-col gap-y-2">
                      {requiresReview.map((job) => (
                        <Fragment key={job.id}>{renderJob({ job })}</Fragment>
                      ))}
                    </div>
                  </ScrollArea>
                ) : (
                  <Empty>No jobs requiring review</Empty>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex h-full basis-1/5 flex-col px-4">
          <div className="mb-4 flex items-center gap-x-2">
            <p className="text-sm font-semibold">Delivery completed</p>
            <Badge variant="number">{delivered.length}</Badge>
          </div>
          <Separator />
          <div className="flex  items-center gap-x-2 py-4">
            <p className="text-xs leading-[24px]">Click to review</p>
          </div>
          <div className="min-h-0 grow">
            {delivered.length > 0 ? (
              <ScrollArea className="h-full pr-4">
                <div className="flex flex-col gap-y-2">
                  {delivered.map((job) => (
                    <Fragment key={job.id}>{renderJob({ job })}</Fragment>
                  ))}
                </div>
              </ScrollArea>
            ) : (
              <Empty>No delivered jobs awaiting review</Empty>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const JobBoardCard = ({
  job,
  extraTags = [],
}: {
  job: Job;
  extraTags?: JobTag[];
}) => {
  const client = useClient();
  const tags = [...job.tags, ...extraTags];
  return (
    <div className="group block w-full justify-start rounded-lg border bg-background text-left transition-colors hover:border-primary/50 hover:shadow">
      <div className="p-4">
        <div className="mb-1 flex items-center gap-x-1">
          <Link to={client.paths.jobs.job(job).root} className="block">
            <span className="text-base font-semibold ">{job.reference}</span>
          </Link>
          <span className="text-xs dark:text-white/50">[Reactive]</span>
        </div>
        <p className="line-clamp-2 text-xs text-gray-400 dark:text-white/75">
          {job.description}
        </p>
      </div>
      {job.variations.length > 0 && (
        <div>
          {job.variations.map((variation) => (
            <Button
              key={variation.id}
              size="sm"
              variant="outline"
              className="flex h-auto w-full items-center justify-between rounded-none border-x-0 border-b border-t-0 px-4 py-2"
            >
              <span className="mt-0.5">Variation {variation.reference} </span>
            </Button>
          ))}
        </div>
      )}
      <div className="flex items-center gap-x-2 px-4 py-3">
        {tags.length > 0 ? (
          tags.map((tag) => (
            <Badge
              key={tag.id}
              variant="outline"
              style={{
                color: chroma(tag.color).css(),
                backgroundColor: chroma(tag.color).alpha(0.1).css(),
                borderColor: chroma(tag.color).css(),
              }}
            >
              {tag.label}
            </Badge>
          ))
        ) : (
          <p className="text-xs dark:text-gray-400">No tags</p>
        )}
      </div>
    </div>
  );
};

export default JobsBoard;
