import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Report } from 'lib/shared';
import { Training } from 'lib/types';
import { evaluate, renderForPlatform } from 'lib/utils';
import { platformClient } from '../../../../../../../../../../../../app/platform';
import { useReportContext } from '../../../../../../../../../../../../shared/Report/context';

const JobTraining = ({ training }: { training: Training }) => {
  const { theme } = useReportContext();
  const { data: object } = Report.useQueryFactory(
    training.qualification.logo?.id,
    platformClient.getObject(training.qualification.logo?.id as string),
  );

  const { instance } = useMsal();
  const { data: profilePictureSrc } = Report.useQueryFactory(
    training.user?.externalId ?? training.user?.id,
    async () => {
      if (!(training.user?.externalId ?? training.user?.id)) {
        return;
      }

      const { accessToken } = await instance.acquireTokenSilent({
        scopes: ['User.Read', 'User.Read.All'],
      });

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };

      const photoResponse = await fetch(
        `https://graph.microsoft.com/v1.0/users/${training.user.externalId ?? training.user.id}/photo/$value`,
        { headers },
      );

      return URL.createObjectURL(await photoResponse.blob());
    },
  );

  return (
    <Report.View
      key={training.id}
      style={{
        padding: 12,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: training.qualification.color,
        borderRadius: 16,
      }}
    >
      <Report.View
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 16,
        }}
      >
        <Report.View style={{ position: 'relative', height: 40 }}>
          <Report.Image
            style={{
              width: 40,
              borderRadius: 0,
              objectFit: 'contain',
              objectPosition: 'top',
            }}
            ignoreOnMount
            object={object}
            src={object?.getUrl}
          />
          <Report.Image
            style={{
              top: '100%',
              left: 0,
              position: 'absolute',
              width: 40,
              marginTop: 16,
              borderRadius: 999,
              objectFit: 'cover',
              objectPosition: 'top',
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: training.qualification.color,
            }}
            ignoreOnMount
            src={profilePictureSrc}
          />
        </Report.View>
        <Report.View style={{ flex: 1 }}>
          <Report.Text
            style={{
              fontSize: 13,
              fontWeight: 600,
              marginBottom: 8,
            }}
          >
            {training.qualification.name}
          </Report.Text>
          <Report.Text
            style={{
              fontSize: 11,
              color: theme.colors.subtitle,
              lineHeight: '1.5',
            }}
          >
            {training.qualification.description}
          </Report.Text>
        </Report.View>
      </Report.View>
      <Report.View
        style={{
          paddingLeft: 56,
          paddingTop: 12,
          paddingRight: 12,
          display: 'flex',
          flexDirection: 'column',
          rowGap: 8,
        }}
      >
        {training.qualification.fields.map((field) => (
          <Report.View
            key={field.id}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Report.Text style={{ fontSize: 12, lineHeight: '1.5' }}>
              {field.label}:
            </Report.Text>
            <Report.Text
              style={{ fontSize: 12, fontWeight: 600, lineHeight: '1.5' }}
            >
              {evaluate(field.value, { training: renderForPlatform(training) })}
            </Report.Text>
          </Report.View>
        ))}
      </Report.View>
    </Report.View>
  );
};

export default JobTraining;
