import React, { Fragment } from 'react';
import { cn } from '@/lib/utils';
import { DateTime } from 'luxon';
import EventHtml from '../EventHtml';
import { EventBodyProps } from './types';

export const EventBody = ({ event, last = false }: EventBodyProps) => {
  return (
    <Fragment key={event.id}>
      <div className="grid h-8 grid-cols-12 p-0">
        <div
          className={cn(
            'col-span-2 flex h-8 items-center whitespace-nowrap p-0',
            { 'border-b': !last },
          )}
        >
          <p className="pl-4 text-xs text-gray-500 dark:text-gray-400">
            {DateTime.fromSeconds(event.timestamp).toLocaleString(
              DateTime.TIME_SIMPLE,
            )}
          </p>
        </div>
        <div
          className={cn(
            'col-span-10 flex h-8 items-center gap-x-1 whitespace-nowrap p-0 text-xs text-gray-500 dark:text-gray-400',
            { 'border-b': !last },
          )}
        >
          <EventHtml event={event} />
        </div>
      </div>
      {(event.children ?? []).map((childEvent, index, children) => {
        const isLastChild = index === children.length - 1;
        return (
          <div key={childEvent.id} className=" h-8 p-0">
            <div
              className={cn('w-0', {
                'border-t': last && isLastChild,
                'border-b': !last && isLastChild,
              })}
            />
            <div
              className={cn('p-0', {
                'border-t': last && isLastChild,
                'border-b': !last && isLastChild,
              })}
            >
              <div className="relative flex h-auto items-center">
                <div className="h-8 pl-24 pr-[1px]">
                  <div className="h-[50%] w-[40px] rounded-bl-lg border-b-2 border-l-2 border-gray-300 dark:border-gray-600" />
                </div>
                {!isLastChild && (
                  <div className="absolute left-[7px] top-0 h-full border-l-2 border-gray-200" />
                )}
                <div className="ml-2 text-xs">
                  <EventHtml event={childEvent} />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};

export default EventBody;
