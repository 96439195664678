import { gql } from '@apollo/client';

export const INLINE_DATA_QUERY = gql`
  query GetProjectInlineData($clientId: ID!, $jobId: ID!, $jobTaskId: ID!) {
    client(clientId: $clientId) {
      userGroups {
        id
        name
        reference
        users {
          id
          name
          initials
          externalId
        }
      }
    }
    job(jobId: $jobId) {
      tasks {
        id
        name
        number
        status
        job {
          id
        }
        timesheetActivity {
          id
          duties {
            id
          }
        }
      }
    }
    jobTask(jobTaskId: $jobTaskId) {
      id
    }
    subcontractors {
      id
      name
    }
    timesheetDuties {
      id
      dateTimeCreated
      dateTimeStart
      dateTimeStartCondition
      dateTimeEnd
      dateTimeEndCondition
      events {
        id
        dateTimeStart
        dateTimeStartType
        dateTimeEnd
        dateTimeEndType
      }
      user {
        id
        name
      }
      subcontractor {
        id
      }
      activity {
        id
        jobTask {
          id
          name
          job {
            id
            client {
              id
              name
            }
            reference
          }
          number
        }
      }
      jobInteractions {
        id
        isPlanned
        duration
        commercialContractRate {
          id
          name
          description
        }
      }
    }
  }
`;
