import { createContext, useContext } from 'react';
import { useJob } from './hooks';

export const JobContext = createContext(
  {} as ReturnType<typeof useJob>['context'],
);

export const useJobContext = () => {
  return useContext(JobContext);
};
