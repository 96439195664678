import { evaluateExpression } from '@enterprise-platform-ui/expressions';
import { ChoiceSet } from 'external/FormTemplateEditor';
import { PlatformActionForm, PlatformActionFormHookItem } from 'lib/types';
import generateId from './generateId';
import snakeCaseToCamelCase from './snakeCaseToCamelCase';

const prepareTemplate = (form: PlatformActionForm, defaults: any = {}) => {
  const items = snakeCaseToCamelCase<
    Record<string, PlatformActionFormHookItem>
  >(form.items);

  const choiceSets = snakeCaseToCamelCase<Record<ChoiceSet['id'], ChoiceSet>>(
    form.choiceSets,
  );

  const page = {
    id: 'page',
    type: 'PAGE',
    children: Object.keys(items),
  };

  const getDefault = (item: PlatformActionFormHookItem) => {
    return item['$.default']
      ? evaluateExpression(item['$.default'], defaults)?.value
      : item.default;
  };

  const pageChildren: string[] = [];
  const preparedItems = Object.entries(items).reduce((ret, [itemId, item]) => {
    if (item?.if) {
      const logicId = generateId();
      pageChildren.push(logicId);

      return {
        ...ret,
        [logicId]: {
          id: logicId,
          type: 'LOGIC',
          parentId: page.id,
          children: [itemId],
          data: {
            ...item.data,
            condition: { expression: item.if.condition },
            constants: item.if.constants,
          },
        },
        [itemId]: {
          id: itemId,
          type: 'BLOCK',
          parentId: logicId,
          children: [],
          data: {
            ...item.data,
            isRequired:
              typeof item.isRequired === 'undefined' ||
              item.isRequired === 'true',
            value: item.data.label,
            media: item.media,
            multiline: item.data.isMultiline,
            isMultiline: item.data.isMultiline,
            default: getDefault(item),
          },
        },
      };
    }

    pageChildren.push(itemId);
    return {
      ...ret,
      [itemId]: {
        id: itemId,
        type: 'BLOCK',
        parentId: page.id,
        children: [],
        data: {
          ...item.data,
          choiceSetId: item.choiceSet,
          isRequired:
            typeof item.isRequired === 'undefined' ||
            item.isRequired === 'true',
          value: item.data.label,
          media: item.media,
          multiline: item.data.isMultiline,
          isMultiline: item.data.isMultiline,
          default: getDefault(item),
        },
      },
    };
  }, {});

  return {
    pageOrder: [page.id],
    choiceSets,
    items: {
      [page.id]: {
        ...page,
        children: pageChildren,
      },
      ...preparedItems,
    },
  };
};

export default prepareTemplate;
