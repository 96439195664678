import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useCommercialContractContext } from '../../context';
import {
  CommercialContractQueryData,
  CommercialContractQueryVariables,
} from './types';

export const useContractRates = () => {
  const { contract } = useCommercialContractContext();

  const [create] = useMutation<
    CommercialContractQueryData,
    CommercialContractQueryVariables
  >(
    gql`
      mutation CreateContractRate(
        $contractId: ID!
        $name: String!
        $description: String!
        $unit: String!
        $unitValue: Float!
      ) {
        createCommercialContractRate(
          commercialContractId: $contractId
          name: $name
          description: $description
          unit: $unit
          unitValue: $unitValue
        ) {
          commercialContractRate {
            id
            name
            description
            unitValue
            unit
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (values: any) => {
    if (!contract) {
      console.warn('Cannot create rate without contract');
      return;
    }

    const { data, errors } = await create({
      variables: {
        contractId: contract.id,
        name: values.name,
        description: values.description,
        unit: values.unit,
        unitValue: values.unitValue,
      },
    });

    if (data) {
      await contract.refetch();
      toast.success('Rate created');
    }

    if (errors) {
      toast.error('Failed to create rate');
    }
  };

  return {
    rates: contract?.rates ?? [],
    onCreate: handleOnCreate,
  };
};
