import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useCommercialContractContext } from '../../../../context';

export const useAccountingUpdate = () => {
  const { contract } = useCommercialContractContext();

  const [open, setOpen] = useState(false);

  const [update] = useMutation(
    gql`
      mutation UpdateCommercialContractAccounting(
        $id: ID!
        $data: CommercialContractAccountingInput!
      ) {
        updateCommercialContractAccounting(
          commercialContractId: $id
          commercialContractAccountData: $data
        ) {
          commercialContract {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnSubmit = async (values: any) => {
    if (!contract) {
      console.warn('Cannot update non-existent contract');
      return;
    }

    const { data, errors } = await update({
      variables: {
        id: contract.id,
        data: {
          customer: values.customer,
          searchCode: values.searchCode,
          nominalCostCentre: values.nominalCostCentre,
          authPo: values.authPo,
          approvedValue: values.approvedValue,
          contractManager: values.contractManager,
        },
      },
    });

    if (data) {
      await contract.refetch();
      setOpen(false);
      toast.success('Contract updated');
    }

    if (errors) {
      toast.error('Failed to update contract');
    }
  };

  return {
    contract,
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onSubmit: handleOnSubmit,
    },
  };
};
