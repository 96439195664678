import React, { useMemo, useState } from 'react';
import { RowSelectionState } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { TableRenderer, selectColumn } from '@/components/ui/table/table/Table';
import { pluralize } from '@/lib/utils';
import { gql, useQuery } from '@apollo/client';
import { toast } from 'sonner';
import { User } from 'lib/types';
import { useUserGroupContext } from '../../context';

const UserSelector = () => {
  const { group, users, handlers } = useUserGroupContext();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<RowSelectionState>({});
  const selectedIds = Object.entries(selected)
    .filter(([_, isSelected]) => isSelected)
    .map(([id]) => id);

  const { data } = useQuery<{ users: User[] }>(
    gql`
      query GetUsers {
        users {
          id
          name
          email
          externalId
        }
      }
    `,
    { fetchPolicy: 'no-cache' },
  );

  const dataMemo = useMemo(
    () =>
      data
        ? data.users.filter(({ id }) => !users.map(({ id }) => id).includes(id))
        : [],
    [data, users],
  );

  if (!group) {
    return null;
  }

  return (
    <Sheet
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (open) {
          setSelected({});
        }
      }}
    >
      <SheetTrigger asChild>
        <Button variant="outline" size="sm">
          Add members
        </Button>
      </SheetTrigger>
      <SheetContent className="flex w-[500px] max-w-[500px] flex-col gap-y-0 p-4 sm:max-w-[500px]">
        <SheetHeader>
          <SheetTitle>Add members</SheetTitle>
          <SheetDescription>Select users to add to this group</SheetDescription>
        </SheetHeader>
        <div className="min-h-0 grow">
          <TableRenderer
            small
            filter="name"
            globalFilterFn="auto"
            columns={[
              selectColumn(),
              {
                id: 'name',
                accessorKey: 'name',
                header: 'Name',
              },
            ]}
            data={dataMemo}
            topRight={() => null}
            selected={selected}
            onSelectedChange={setSelected}
          />
        </div>
        <Button
          className="w-full"
          variant="outline"
          disabled={selectedIds.length === 0}
          onClick={async () => {
            await handlers.onAdd(
              data?.users.filter(({ id }) => selectedIds.includes(id)) ?? [],
            );
            setOpen(false);
            toast.success('Users added to group');
          }}
        >
          {selectedIds.length === 0 ? (
            'Select user(s)'
          ) : (
            <>
              Add {selectedIds.length} {pluralize(selectedIds.length, 'user')}{' '}
              to group
            </>
          )}
        </Button>
      </SheetContent>
    </Sheet>
  );
};

export default UserSelector;
