import { createContext, useContext } from 'react';

export const ScopeContext = createContext({
  isLoading: false,
  scopeData: {} as Record<string, any>,
  registerScopes: (() => {}) as (scopes: string[]) => void,
});

export const useScopeContext = () => {
  return useContext(ScopeContext);
};
