import { createContext } from 'react';
import { PlatformTodo, PlatformTodoGroup } from 'lib/types';

export const TodosContext = createContext({
  todos: [] as PlatformTodo[],
  groups: [] as PlatformTodoGroup[],
  selected: null as PlatformTodo | null,
  refetchTodos: async (): Promise<any> => {},
  selectTodo: (todo: PlatformTodo | null) => {},
  clearSelectedTodo: () => {},
});
