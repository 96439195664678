import React, { useState } from 'react';
import { Circle } from 'lucide-react';
import Map, { Marker, ViewState } from 'react-map-gl';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { circle } from '@turf/turf';
import { useMapStyle } from 'lib/hooks';
import { LocationContext } from 'lib/shared';
import { useJobContext } from '../../../../context';

const OverviewMap = () => {
  const { job } = useJobContext();

  const mapStyle = useMapStyle();

  const [ready, setReady] = useState(false);

  const getDefaultViewState = (): Partial<ViewState> => {
    if (job.partial) return {};

    if (job.location?.precise) {
      return {
        latitude: job.location.precise.geometry.point.latitude,
        longitude: job.location.precise.geometry.point.longitude,
      };
    }
    if (job.site.location?.precise) {
      return {
        latitude: job.site.location.precise.geometry.point.latitude,
        longitude: job.site.location.precise.geometry.point.longitude,
      };
    }
    return {};
  };

  if (job.partial) return null;

  const { site } = job;
  const address = site.location?.address;
  const point = site.location?.precise?.geometry?.point;

  return (
    <LocationContext
      location={job.location}
      supplementaryLocation={job?.site?.location}
      defaultViewState={getDefaultViewState()}
    >
      <div className="relative h-full">
        <div className="pointer-events-none absolute left-0 right-4 top-0 z-[5] h-full">
          <div className="flex justify-between">
            <div className="pointer-events-auto">
              <Badge
                className="absolute right-0 top-2 rounded bg-background text-xs font-bold"
                variant="outline"
              >
                {site.reference}
              </Badge>
              {address?.formatted && (
                <p className="absolute bottom-2 right-2 text-xs font-semibold text-gray-500 dark:text-gray-400">
                  {address.formatted}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="absolute inset-y-0 left-[-100%] right-0">
          <div
            className={cn(
              'pointer-events-none absolute inset-0 z-[8] bg-background transition-opacity',
              {
                'opacity-100': !ready,
                'opacity-0': ready,
              },
            )}
          />
          <div className="pointer-events-none absolute inset-0 z-[7] from-background from-15% to-75% bg-gradient-[45deg]" />
          <Map
            key={job.id}
            style={{
              width: '100%',
              height: '100%',
            }}
            initialViewState={{
              zoom: 16,
              latitude: 51.4574,
              longitude: 0.1278,
              pitch: 0,
              bearing: 0,
              ...(point
                ? {
                    latitude: point.latitude,
                    longitude: point.longitude - 0.002,
                  }
                : {}),
            }}
            attributionControl={false}
            mapboxAccessToken="pk.eyJ1IjoiZW5lcmd5ZW5hYmxlcyIsImEiOiJjbGpia2NqZ2IxcTcxM2pxeGxvNG5ud2lpIn0.gMXRc_xES-6AlQx1w8MuXw"
            mapStyle={mapStyle}
            onLoad={(event) => {
              if (point) addCircleOnLoad(event, point);
              setReady(true);
            }}
          >
            {point && (
              <Marker {...point}>
                <Circle className="size-3 fill-success stroke-success" />
              </Marker>
            )}
          </Map>
        </div>
      </div>
    </LocationContext>
  );
};

export const addCircleOnLoad = (event: any, point: any) => {
  event.target.addLayer({
    id: 'polygon',
    type: 'fill',
    source: {
      type: 'geojson',
      data: circle([point.longitude, point.latitude], 0.05, {
        steps: 200,
      }),
    },
    layout: {},
    paint: {
      'fill-color': '#106c12',
      'fill-opacity': 0.2,
    },
  });
};

export default OverviewMap;
