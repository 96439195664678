import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '@/components/ui/input';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import BlockButton from '../../../BlockButton';
import { ControlsLabelProps } from './types';

const ControlsLabel = ({
  item,
  fieldLabel,
  onClick,
  onChange,
  onDelete,
}: ControlsLabelProps) => {
  if (item.data.dataType === 'COMMENT') return null;

  return (
    <div>
      {fieldLabel ? (
        <div className="flex">
          <Input
            className="border-0"
            value={fieldLabel.label}
            onChange={onChange}
          />
          <button style={{ width: 30, height: 30 }} onClick={onDelete}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      ) : (
        <BlockButton onClick={onClick}>Add label</BlockButton>
      )}
    </div>
  );
};

export default ControlsLabel;
