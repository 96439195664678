import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { MessageGroup } from 'lib/types';
import { platformClient } from '../app/platform';

export type UseMessageGroupOptions = {
  scope: string;
  id: string;
  defaultMeta?: any;
  skip?: boolean;
  pageSize?: number;
};

const useMessageGroup = ({
  scope,
  id,
  defaultMeta,
  skip = false,
  pageSize = 50,
}: UseMessageGroupOptions) => {
  const messageGroupId = `${scope}::${id}`;
  const queryClient = useQueryClient();
  const queryKey = ['messageGroup', messageGroupId];

  const { data: messageGroup } = useQuery<MessageGroup>({
    queryKey,
    queryFn: platformClient.getMessageGroup({
      id: messageGroupId,
      includeMessages: true,
    }),
  });

  const send = useMutation({
    mutationFn: platformClient.sendMessage(),
    onMutate: async (message) => {
      await queryClient.cancelQueries({ queryKey });
      const previousMessageGroup = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (old: any) => ({
        ...old,
        messages: [
          ...(old.messages ?? []),
          {
            ...message,
            timestamp: Math.round(DateTime.now().toMillis() / 1000),
          },
        ],
      }));
      return { previousMessageGroup };
    },
    onError: (_, __, context) => {
      if (!context?.previousMessageGroup) return;
      queryClient.setQueryData(queryKey, context.previousMessageGroup);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const handleOnSend = async (
    value: any,
    valueHtml: string,
    valueString: string,
    meta?: any,
  ) => {
    if (!messageGroup) return;

    await send.mutateAsync({
      message_group_id: messageGroup.id,
      body_string: valueString,
      body_html: valueHtml,
      body_json: value,
      meta: {
        ...(defaultMeta ?? {}),
        ...(meta ?? {}),
      },
    });
  };

  return {
    messageGroup,
    messages: messageGroup?.messages ?? [],
    handleOnSend,
    onSend: handleOnSend,
  };
};

export default useMessageGroup;
