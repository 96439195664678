import React from 'react';
import Timeline from './components/Timeline';
import TimelineGrid from './components/TimelineGrid';
import { TimelineRendererContext, useTimelineRendererContext } from './context';
import { useTimelineRenderer } from './hooks';
import { TimelineRendererProps } from './types';

const TimelineRenderer = (props: TimelineRendererProps) => {
  const { context } = useTimelineRenderer(props);

  const inner = (
    <div className="relative flex h-full min-h-0 w-full overflow-hidden">
      <TimelineGrid />
      <Timeline />
    </div>
  );

  if (props.asChild) {
    return inner;
  }

  return (
    <TimelineRendererContext.Provider value={context}>
      {inner}
    </TimelineRendererContext.Provider>
  );
};

TimelineRenderer.use = useTimelineRenderer;
TimelineRenderer.useContext = useTimelineRendererContext;
TimelineRenderer.Provider = TimelineRendererContext.Provider;

export default TimelineRenderer;
