import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useEstimateTableContext } from '../../../../../../context';
import { useValueLineContext } from '../../context';

export const useLineMenu = () => {
  const { updateRefetch } = useEstimateTableContext();
  const { line } = useValueLineContext();

  const [open, setOpen] = useState(false);

  const [delete_] = useMutation(
    gql`
      mutation DeleteCommercialValueLine($id: ID!) {
        deleteCommercialValueLine(line: $id) {
          deleted
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnDelete = async () => {
    const { data, errors } = await delete_({
      variables: {
        id: line.id,
      },
    });

    if (data) {
      await updateRefetch();
      setOpen(false);
      toast.success('Line deleted successfully');
    }

    if (errors) {
      toast.error('Failed to delete line');
    }
  };

  return {
    dialog: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onDelete: handleOnDelete,
    },
  };
};
