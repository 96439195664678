import { useEffect, useState } from 'react';
import { OperationVariables } from '@apollo/client';
import { DocumentNode } from 'graphql/index';
import { ReportMode } from 'lib/shared';
import { httpClient } from '../../app/hooks';
import { useReportContext } from './context';

export const useReportQuery = <
  TData = any,
  TVariables extends OperationVariables = any,
>(
  key: string,
  query: DocumentNode,
  variables: TVariables,
) => {
  const { mode, cache } = useReportContext();
  const [asyncData, setAsyncData] = useState<TData | null>(null);

  useEffect(() => {
    if (mode !== ReportMode.Edit) return;
    (async () => {
      const { data } = await httpClient.query<TData, TVariables>({
        query,
        variables,
      });
      setAsyncData(data);
      cache.set(key, data);
    })();
  }, [mode, query, variables]);

  if (mode === ReportMode.Edit) {
    return { data: asyncData };
  }

  return { data: cache.get(key) as TData };
};

export const useReportQueryFactory = <TData = any>(
  key: string | undefined,
  queryFn: () => Promise<TData>,
) => {
  const { mode, cache } = useReportContext();
  const [asyncData, setAsyncData] = useState<TData | null>(null);
  const [queryFnMemo] = useState(() => queryFn);

  useEffect(() => {
    if (!key) return;
    if (mode !== ReportMode.Edit) return;
    (async () => {
      const data = await queryFnMemo();
      setAsyncData(data);
      cache.set(key, data);
    })();
  }, [mode, queryFnMemo]);

  if (mode === ReportMode.Edit) {
    return { data: asyncData };
  }

  return { data: key ? (cache.get(key) as TData) : undefined };
};
