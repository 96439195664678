import { FC } from 'react';
import { Button } from '@/components/ui/button';

export interface FormControlsProps {
  isDisabled: boolean;
}

/**
 * Renders form controls.
 */
export const FormControls: FC<FormControlsProps> = ({ isDisabled }) => {
  return <Button disabled={isDisabled}>Submit!</Button>;
};
