import { useCallback, useMemo, useState } from 'react';
import { PlatformObjectAnnotation, PlatformObjectImageData } from 'lib/types';

export const useImage = (
  providedAnnotations: PlatformObjectAnnotation[] | undefined,
  highlighted: string | undefined,
  dataString: string | undefined,
) => {
  const [open, setOpen] = useState(false);

  const getOpacity = useCallback(
    (id: string) => {
      if (!highlighted) return 1;
      if (highlighted === id) return 1;
      return 0.5;
    },
    [highlighted],
  );

  const data = useMemo<PlatformObjectImageData>(() => {
    if (!dataString) return { annotations: [] };
    try {
      return JSON.parse(dataString);
    } catch (error) {
      return { annotations: [] };
    }
  }, [dataString]);

  const annotations = providedAnnotations || data.annotations || [];

  return {
    data,
    annotations,
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      getOpacity,
    },
  };
};
