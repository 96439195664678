import React from 'react';
import {
  FeedItem,
  FeedItemType,
} from '../../../../../../external/MessageGroup/types';
import ActivityEvent from './components/ActivityEvent';
import ActivityMessage from './components/ActivityMessage';

const IssueActivity = ({ feed }: { feed: FeedItem[] }) => {
  return (
    <div className="relative ml-[13px]">
      {feed.map((item) => {
        if (item.type === FeedItemType.Message) {
          return <ActivityMessage key={item.id} feed={feed} message={item} />;
        }

        if (item.type === FeedItemType.Event) {
          return <ActivityEvent key={item.id} event={item} />;
        }

        return null;
      })}

      <div className="z-1 absolute inset-y-0 left-[15px] w-0.5 bg-background-secondary dark:bg-accent" />
    </div>
  );
};

export default IssueActivity;
