import React from 'react';
import chroma, { Color } from 'chroma-js';
import { Choice } from 'external/FormTemplateEditor';
import ReportSection from '../../../../../../../ReportSection';
import ReportText from '../../../../../../../ReportText';
import ReportView from '../../../../../../../ReportView';
import { useReportBlockFormContext } from '../../../../context';
import { FormFieldComponentProps } from '../../types';

const FieldChoiceSet = ({ item, value, media }: FormFieldComponentProps) => {
  const { template } = useReportBlockFormContext();
  const { choiceSets } = template;

  if (!item.data.choiceSetId) return null;

  const choiceSet = choiceSets[item.data.choiceSetId];
  const choiceIds = (value?.value as string[]) || [];
  const choices = choiceSet.choices.filter(({ id }) => choiceIds.includes(id));

  return (
    <ReportSection title={item.data.value} media={media}>
      <ReportView style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 8 }}>
        {choices.map(FieldChoice)}
      </ReportView>
    </ReportSection>
  );
};

const FieldChoice = ({ id, value, color: colorString }: Choice) => {
  let color: Color;
  try {
    color = chroma(colorString);
  } catch (error) {
    console.warn(`Invalid color for choice \`${value}\`: ${colorString}`);
    color = chroma('gray');
  }
  const backgroundColor = color.alpha(0.2).css();
  return (
    <ReportView key={id}>
      <ReportView
        style={{
          width: '100%',
          borderRadius: 4,
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 4,
          paddingBottom: 4,
          marginBottom: 8,
          color: colorString,
          backgroundColor,
          textAlign: 'center',
        }}
      >
        <ReportText style={{ fontSize: 12, fontWeight: 'bold' }}>
          {value}
        </ReportText>
      </ReportView>
    </ReportView>
  );
};

export default FieldChoiceSet;
