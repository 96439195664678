import chroma from 'chroma-js';
import { TimesheetDuty, TimesheetDutyStatus } from 'lib/types';

export const getTimesheetDutyColor = (duty: TimesheetDuty) => {
  switch (duty.status) {
    case TimesheetDutyStatus.Pending:
      return chroma('#00c4ff');
    case TimesheetDutyStatus.Started:
      return chroma('#ffa600');
    case TimesheetDutyStatus.Completed:
      return chroma('#106c12');
    default:
      return chroma('gray');
  }
};
