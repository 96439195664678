import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useSubcontractorsRequirementsContext } from '../../context';
import {
  CreateSubcontractorRequirementMutationData,
  CreateSubcontractorRequirementMutationVariables,
} from './types';

export const useRequirementsCreate = () => {
  const { refetch } = useSubcontractorsRequirementsContext();

  const [open, setOpen] = useState(false);

  const [create] = useMutation<
    CreateSubcontractorRequirementMutationData,
    CreateSubcontractorRequirementMutationVariables
  >(
    gql`
      mutation CreateSubcontractorRequirement($name: String!) {
        createSubcontractorRequirement(
          subcontractorRequirementData: { name: $name }
        ) {
          subcontractorRequirement {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnSubmit = async (values: any) => {
    const { data, errors } = await create({
      variables: {
        name: values.name,
      },
    });

    if (data) {
      await refetch();
      setOpen(false);
      toast.success('Requirement created');
    }

    if (errors) {
      console.error(errors);
      toast.error('Failed to create requirement');
    }
  };

  return {
    dialog: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onSubmit: handleOnSubmit,
    },
  };
};
