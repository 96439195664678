import React, { useMemo, useState } from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { Button } from '@/components/ui/button';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { useTheme } from '@/components/ui/theme';
import { DateTime } from 'luxon';
import { useUser } from 'lib/hooks';
import { User } from 'lib/types';
import { generateId } from 'lib/utils';
import { NoteHistoryItemAction } from '../../types';
import Note from '../Note';
import { NoteUpdateProps } from './types';

const NoteUpdate = ({ note, store: storeProxy, doc }: NoteUpdateProps) => {
  const theme = useTheme();
  const user = useUser() as User;
  const [open, setOpen] = useState(false);

  const store = useSyncedStore(storeProxy);

  const collaborators = useMemo(
    () =>
      note.history
        .map(({ user }) => user)
        .filter(
          (user, index, users) =>
            users.findIndex((inner) => inner.id === user.id) === index,
        ),
    [note.history],
  );

  const lastUpdate = useMemo(() => {
    const latest = note.history.at(-1);
    if (latest) {
      return {
        user: latest.user,
        dateTime: DateTime.fromISO(latest.dateTime),
      };
    }
  }, [note.history]);

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant="link" className="ml-2 h-4 p-0 text-xs">
          Edit note
        </Button>
      </SheetTrigger>
      <SheetContent className="w-[500px] max-w-[500px] sm:max-w-[500px]">
        <SheetHeader className="mb-8">
          <SheetTitle>Update note</SheetTitle>
          <SheetDescription>
            Update a new note to keep track of important information
          </SheetDescription>
        </SheetHeader>
        <Note
          note={note}
          onDelete={(note) => {
            doc.transact(() => {
              store.notes[note.id].deleted = true;
              store.notes[note.id].history.push({
                id: generateId(),
                action: NoteHistoryItemAction.Delete,
                dateTime: DateTime.now().toISO() as string,
                snapshot: JSON.stringify({
                  id: note.id,
                  scope: note.scope,
                  content: note.content,
                  deleted: note.deleted,
                  objects: note.objects,
                }),
                user: {
                  id: user.id,
                  name: user.name,
                  email: user.email,
                },
              });
            });
            setOpen(false);
          }}
          onSave={(note) => {
            doc.transact(() => {
              store.notes[note.id].content = note.content;
              store.notes[note.id].objects = note.objects;
              store.notes[note.id].history.push({
                id: generateId(),
                action: NoteHistoryItemAction.Update,
                dateTime: DateTime.now().toISO() as string,
                snapshot: JSON.stringify({
                  id: note.id,
                  scope: note.scope,
                  content: note.content,
                  deleted: note.deleted,
                  objects: note.objects,
                }),
                user: {
                  id: user.id,
                  name: user.name,
                  email: user.email,
                },
              });
            });
            setOpen(false);
          }}
        />
      </SheetContent>
    </Sheet>
  );
};

export default NoteUpdate;
