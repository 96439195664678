import React from 'react';
import { PlatformObject } from 'lib/types';

export type MediaProps =
  | {
      readOnly?: false;
      renderOnReadOnly?: never;
      label?: React.ReactNode;
      objects: PlatformObject[];
      standalone?: boolean;
      onCreated: (objects: PlatformObject[]) => void;
      onDeleted: (object: PlatformObject) => void;
      onUploadProgress: (
        objectId: PlatformObject['id'],
        progress: number,
      ) => void;
      onUploaded: (objectId: PlatformObject['id']) => void;
    }
  | {
      readOnly?: true;
      renderOnReadOnly?: boolean;
      label?: React.ReactNode;
      objects: PlatformObject[];
      standalone?: boolean;
      onCreated?: any;
      onDeleted?: any;
      onUploadProgress?: any;
      onUploaded?: any;
    };

export type MediaObjectProps = {
  className?: string;
  object: PlatformObject;
  color: string;
  onDeleted: (object: PlatformObject) => void;
};
