export type Location = {
  id: string;
  address?: LocationAddress;
  precise?: LocationPrecise;
  pointsOfInterest: LocationPointOfInterest[];
  estimates: LocationEstimate[];
};

export type LocationAddress = {
  id: string;
  lineOne: string;
  lineTwo: string;
  city: string;
  region: string;
  postcode: string;
  country: string;
  formatted: string;
};

export type LocationGeometry = {
  id: string;
  point: LocationGeometryPoint;
};

export type LocationGeometryPoint = {
  latitude: number;
  longitude: number;
};

export type LocationPrecise = {
  id: string;
  description?: string;
  geometry: LocationGeometry;
};

export type LocationPointOfInterest = {
  id: string;
  name: string;
  description?: string;
  geometry: LocationGeometry;
};

export type LocationEstimate = {
  id: string;
  location: Location;
  address?: LocationAddress;
  precise?: LocationPrecise;
  data: any;
};
