import React from 'react';
import { TableRenderer } from '@/components/ui/table/table/Table';
import { useCommercialContractContext } from '../../context';
import ContractRate from '../ContractRate';
import RatesCreate from './components/RatesCreate';
import { useContractRates } from './hooks';

const ContractRates = () => {
  const { contract } = useCommercialContractContext();
  const { rates, onCreate } = useContractRates();
  if (!contract) return null;
  return (
    <div>
      <div className="flex justify-between">
        <div>
          <p className="mb-1 text-sm font-bold">Rates</p>
          <p className="text-xs text-gray-400">
            Manage your contracts here. You can create, edit, and delete
            contracts.
          </p>
        </div>
      </div>
      <TableRenderer
        filter="name"
        columns={[
          {
            id: 'name',
            accessorKey: 'name',
            header: 'Name',
            cell: ContractRate,
          },
          {
            id: 'description',
            accessorKey: 'description',
            header: 'Description',
          },
          {
            id: 'unitValue',
            accessorKey: 'unitValue',
            header: 'Unit Value (£)',
            cell: ({ row }) => row.original.unitValue.toFixed(2),
          },
          {
            id: 'unitValueTransition',
            accessorKey: 'unitValueTransition',
            header: 'Unit Value Overtime (£)',
            cell: ({ row }) =>
              row.original.unitValueTransition?.toFixed(2) ?? (
                <em className="dark:text-gray-400">Not set</em>
              ),
          },
          { id: 'unit', accessorKey: 'unitDisplay', header: 'Unit' },
        ]}
        initialSorting={[{ id: 'name', desc: false }]}
        data={rates}
        topRight={({ columnsSelector }) => (
          <>
            {columnsSelector}
            <RatesCreate className="ml-2" onCreate={onCreate} />
          </>
        )}
      />
    </div>
  );
};

export default ContractRates;
