import { useQuery } from '@apollo/client';
import { FetchPolicy } from '@apollo/client/core';
import { useMatch } from 'lib/hooks';
import { Job } from 'lib/types';
import { useTitle } from '../../utils/useTitle';
import { useClient } from '../Client/hooks';
import { GET_JOB_QUERY } from './query';
import {
  JobQueryData,
  JobQueryVariables,
  PartialJob,
  UseJobReturn,
} from './types';

export const useJob = ({
  id = undefined as string | undefined,
  readOnly = false,
  small = false,
  variation = false,
  fetchPolicy = 'cache-and-network' as FetchPolicy,
}): UseJobReturn => {
  const client = useClient();

  const match = useMatch('/clients/:client/jobs/:reference/*');
  const matchVariation = useMatch(
    '/clients/:client/jobs/:reference/:variation/*',
  );

  useTitle(match?.params.reference || 'Job');

  const { data, refetch } = useQuery<JobQueryData, JobQueryVariables>(
    GET_JOB_QUERY,
    {
      fetchPolicy,
      variables: {
        id,
        client: client.slug,
        reference: match?.params.reference,
        variation: variation ? matchVariation?.params.variation : undefined,
      },
    },
  );

  if (!data?.job) {
    return {
      context: {
        state: {
          readOnly,
          small,
          variation,
        },
        job: {
          reference: match?.params.reference,
          variationReference: matchVariation?.params.variation,
          partial: true,
          refetch,
        } as PartialJob,
      },
    };
  }

  return {
    context: {
      state: {
        readOnly,
        small,
        variation,
      },
      job: {
        ...data.job,
        variationReference: matchVariation?.params.variation,
        partial: false,
        refetch,
      } as Job,
    },
  };
};
