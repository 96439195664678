import React from 'react';
import { useActivityMapContext } from '../../context';
import LocationOffice from './components/LocationOffice';
import LocationYard from './components/LocationYard';

const MapLocations = () => {
  const { getDriversAtCoords } = useActivityMapContext();
  const driversAtHeadOffice = getDriversAtCoords(
    51.43675904815511,
    0.07505702683621203,
    0.005,
  );
  const driversAtBasingstokeOffice = getDriversAtCoords(
    51.27167748819066,
    -1.0890495885635525,
    0.005,
  );
  const driversAtSwanleyYard = getDriversAtCoords(
    51.39023030725496,
    0.1626716644235529,
    0.005,
  );
  return (
    <>
      <LocationOffice
        name="Head Office"
        drivers={driversAtHeadOffice}
        longitude={0.07505702683621203}
        latitude={51.43675904815511}
      />
      <LocationOffice
        name="Basingstoke Office"
        drivers={driversAtBasingstokeOffice}
        longitude={-1.0890495885635525}
        latitude={51.27167748819066}
      />
      <LocationYard
        name="Swanley Yard"
        drivers={driversAtSwanleyYard}
        longitude={0.1626716644235529}
        latitude={51.39023030725496}
      />
    </>
  );
};

export default MapLocations;
