import React from 'react';
import CommercialContracts from './components/CommercialContracts';

const ClientCommercial = () => {
  return (
    <div>
      <div className="flex h-[52px] flex-shrink-0 items-center border-b px-4 py-2">
        <h1 className="text-xl font-bold">Commercial</h1>
      </div>
      <CommercialContracts />
    </div>
  );
};

export default ClientCommercial;
