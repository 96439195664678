import React, { CSSProperties, PropsWithChildren } from 'react';
import ReportView from '../ReportView';

const ReportStack = ({
  children,
  spacing = 16,
  style,
}: PropsWithChildren<{ spacing?: number; style?: CSSProperties }>) => {
  return (
    <ReportView
      style={{
        ...style,
        display: 'flex',
        flexDirection: 'column',
        rowGap: spacing,
      }}
    >
      {children}
    </ReportView>
  );
};

export default ReportStack;
