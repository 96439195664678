import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  input,
  textarea,
} from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { z } from 'zod';
import { useContactsCreate } from './hooks';

const ContactsCreate = () => {
  const { sheet, handlers } = useContactsCreate();
  return (
    <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
      <SheetTrigger asChild>
        <Button className="pl-2" size="xs" variant="link">
          <Plus className="mr-1 size-4" />
          Create Contact
        </Button>
      </SheetTrigger>
      <SheetContent className="flex h-full min-h-0 flex-col p-0">
        <SheetHeader className="p-4">
          <SheetTitle>Create Contact</SheetTitle>
          <SheetDescription>Create a new contact</SheetDescription>
        </SheetHeader>
        <ScrollArea className="min-h-0 flex-grow">
          <FormRenderer
            className="p-4 pt-0"
            fields={[
              input({
                name: 'name',
                label: 'Name',
                schema: z.string().min(1),
              }),
              input({
                name: 'email',
                label: 'Email',
                schema: z.string().email().optional(),
              }),
              input({
                name: 'phone',
                label: 'Phone',
                schema: z.string().optional(),
              }),
              input({
                name: 'mobile',
                label: 'Mobile',
                schema: z.string().optional(),
              }),
              textarea({
                name: 'notes',
                label: 'Notes',
                schema: z.string().optional(),
              }),
            ]}
            onSubmit={handlers.onSubmit}
          >
            {({ onSubmit }) => (
              <div className="col-span-2 pt-2">
                <Button className="w-full" size="sm" onClick={onSubmit}>
                  Create
                </Button>
              </div>
            )}
          </FormRenderer>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

export default ContactsCreate;
