import React from 'react';
import regexifyString from 'regexify-string';

export const renderNodeDescription = (
  description: string | { pre: string | string[]; post: string | string[] },
  mode: 'pre' | 'post' = 'pre',
) => {
  if (typeof description === 'string') {
    return (
      <p className="text-xs text-gray-600 dark:text-gray-400">
        {parse(description)}
      </p>
    );
  }
  const value = description[mode];
  if (Array.isArray(value)) {
    return (
      <div>
        {value.map((inner) => (
          <p>{parse(inner)}</p>
        ))}
      </div>
    );
  }
  return parse(value);
};

export const parse = (text: string) => {
  return regexifyString({
    input: text,
    pattern: /\*\*([^*]+)\*\*/g,
    decorator: (_, index, result) => {
      return <strong key={index}>{result?.[1]}</strong>;
    },
  });
};
