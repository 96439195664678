import React, { useEffect, useRef, useState } from 'react';
import { ChevronRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ReportMode } from 'lib/shared';
import { useReportContext } from '../../context';
import { ReportComponentProps } from './types';

const ReportComponent = ({
  editComponent: EditComponent,
  viewComponent: ViewComponent,
  props,
}: ReportComponentProps) => {
  const { mode } = useReportContext();
  switch (mode) {
    case ReportMode.Contents:
      return <ReportComponentBookmark {...props} />;
    case ReportMode.Edit:
    case ReportMode.View:
      return <EditComponent {...props} />;
    case ReportMode.PDF:
      return <ViewComponent {...props} />;
    default:
      throw Error(`Invalid report mode ${mode}.`);
  }
};

const ReportComponentBookmark = ({
  bookmark,
  children,
}: ReportComponentProps['props']) => {
  const [open, setOpen] = useState(
    typeof bookmark?.expanded === 'undefined' ? true : bookmark.expanded,
  );
  const [parent, setParent] = useState(
    typeof bookmark?.parent === 'undefined' ? false : bookmark.parent,
  );

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      // If the div has children, this is a parent bookmark.
      setParent(ref.current.children.length > 0);
    }
  }, []);

  if (!bookmark) {
    return <>{children}</>;
  }

  return (
    <>
      {bookmark && (
        <>
          {parent ? (
            <div className="mb-1 flex items-center">
              <Button
                size="icon-xs"
                variant="ghost"
                onClick={() => {
                  setOpen((prevOpen) => !prevOpen);
                }}
              >
                <ChevronRight className="h-4 w-4" />
              </Button>
              <a href={`#${bookmark.title}`}>
                <p className="whitespace-nowrap text-sm font-medium">
                  {bookmark.title}
                </p>
              </a>
            </div>
          ) : (
            <a href={`#${bookmark.title}`}>
              <p className="mb-1 ml-2 whitespace-nowrap text-sm dark:text-gray-300">
                {bookmark.title}
              </p>
            </a>
          )}
          {open && (
            <div ref={ref} className="ml-3 border-l-2 pl-3">
              {children}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ReportComponent;
