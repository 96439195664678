import React from 'react';
import {
  AlertTriangleIcon,
  CheckSquareIcon,
  ClockIcon,
  ImageIcon,
  InfoIcon,
  PenToolIcon,
  SigmaIcon,
  TimerIcon,
  TypeIcon,
  UserIcon,
} from 'lucide-react';
import {
  BlockData,
  ContainerData,
  Item,
  LogicData,
  Page,
} from 'external/types';
import type { UndoManager } from 'yjs';

export enum DataType {
  ARRAY = 'ARRAY',
  CHOICE = 'CHOICE',
  CHOICE_SELECTION = 'CHOICE_SELECTION',
  CHOICE_SET = 'CHOICE_SET',
  CHAR = 'CHAR',
  COMMENT = 'COMMENT',
  NUMBER = 'NUMBER',
  DATETIME = 'DATETIME',
  DURATION = 'DURATION',
  BOOLEAN = 'BOOLEAN',
  JSON = 'JSON',
  FILE = 'FILE',
  MAPPING = 'MAPPING',
  MEDIA = 'MEDIA',
  OBJECT = 'OBJECT',
  SIGNATURE = 'SIGNATURE',
  UNDEFINED = 'UNDEFINED',
  USER = 'USER',
  WARNING = 'WARNING',
}

export type Choice = {
  id: string;
  value: string;
  color: string;
};

export type ChoiceSet = {
  id: string;
  choices: Choice[];
  dynamic?: boolean;
  source?: string;
  sourceConfig?: any;
};

export type Nullable<T> = T | null;

export type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> &
  Partial<Pick<Type, Key>>;

export type State = {
  pageOrder: string[];
  items: Record<
    string,
    Page<any> | Item<BlockData | ContainerData | LogicData>
  >;
  choiceSets: Record<string, ChoiceSet>;
  fieldLabels: Record<string, { label: string }>;
};

export type FormTemplateEditorRef = {
  undo: UndoManager['undo'];
  redo: UndoManager['redo'];
};

const iconClassName = 'h-4 w-4';

export const DATA_TYPE_MAP = {
  [DataType.BOOLEAN]: {
    label: 'Checkbox',
    icon: <CheckSquareIcon className={iconClassName} />,
  },
  [DataType.CHAR]: {
    label: 'Text',
    icon: <TypeIcon className={iconClassName} />,
  },
  [DataType.NUMBER]: {
    label: 'Number',
    icon: <SigmaIcon className={iconClassName} />,
  },
  [DataType.DATETIME]: {
    label: 'Date & Time',
    icon: <ClockIcon className={iconClassName} />,
  },
  [DataType.DURATION]: {
    label: 'Duration',
    icon: <TimerIcon className={iconClassName} />,
  },
  [DataType.MEDIA]: {
    label: 'Media',
    icon: <ImageIcon className={iconClassName} />,
  },
  SIGNATURE: {
    label: 'Signature',
    icon: <PenToolIcon className={iconClassName} />,
  },
  WARNING: {
    label: 'Warning',
    icon: <AlertTriangleIcon className={iconClassName} />,
  },
  COMMENT: {
    label: 'Comment',
    icon: <InfoIcon className={iconClassName} />,
  },
  USER: {
    label: 'User',
    icon: <UserIcon className={iconClassName} />,
  },
} as Record<string, { label: string; icon: React.ReactNode }>;

export type Condition = {
  id: string;
  title: string;
  collector: string;
  summary: string;
  expression: string;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const CONDITIONS: Record<DataType, Condition[]> = {
  [DataType.CHAR]: [
    {
      id: 'CHAR_IS_BLANK',
      title: 'If value is blank',
      collector: 'If value is $blank',
      summary: 'Is blank',
      // eslint-disable-next-line quotes
      expression: "$.value == ''",
    },
    {
      id: 'CHAR_IS_NOT_BLANK',
      title: 'If value is not blank',
      collector: 'If value is $not_blank',
      summary: 'Is not blank',
      // eslint-disable-next-line quotes
      expression: "$.value != ''",
    },
    {
      id: 'CHAR_IS',
      title: 'If value is',
      collector: 'If value $is a:CHAR',
      summary: '$eq a:CHAR',
      expression: '$.value == $.a',
    },
    {
      id: 'CHAR_IS_NOT',
      title: 'If value is not',
      collector: 'If value $is_not a:CHAR',
      summary: '$neq a:CHAR',
      expression: '$.value != $.a',
    },
  ],
  [DataType.NUMBER]: [
    {
      id: 'NUMBER_IS_EQUAL_TO',
      title: 'If value is equal to',
      collector: 'If value is $equal_to a:NUMBER',
      summary: '$eq a:NUMBER',
      expression: '$.value == $.a',
    },
    {
      id: 'NUMBER_IS_NOT_EQUAL_TO',
      title: 'If value is not equal to',
      collector: 'If value is $not_equal_to a:NUMBER',
      summary: '$neq a:NUMBER',
      expression: '$.value != $.a',
    },
    {
      id: 'NUMBER_IS_GREATER_THAN',
      title: 'If value is greater than',
      collector: 'If value is $greater_than a:NUMBER',
      summary: '$gt a:NUMBER',
      expression: '$.value > $.a',
    },
    {
      id: 'NUMBER_IS_GREATER_THAN_OR_EQUAL_TO',
      title: 'If value is greater than or equal to',
      collector: 'If value is $greater_than_or_equal_to a:NUMBER',
      summary: '$gte a:NUMBER',
      expression: '$.value >= $.a',
    },
    {
      id: 'NUMBER_IS_LESS_THAN',
      title: 'If value is less than',
      collector: 'If value is $less_than a:NUMBER',
      summary: '$lt a:NUMBER',
      expression: '$.value < $.a',
    },
    {
      id: 'NUMBER_IS_LESS_THAN_OR_EQUAL_TO',
      title: 'If value is less than or equal to',
      collector: 'If value is $less_than_or_equal_to a:NUMBER',
      summary: '$lte a:NUMBER',
      expression: '$.value <= $.a',
    },
    {
      id: 'NUMBER_IS_BETWEEN',
      title: 'If value is between',
      collector: 'If value $is_between a:NUMBER and b:NUMBER',
      summary: 'a:NUMBER $lt $x $lt b:NUMBER',
      expression: '$.a < $.value and $.value < $.b',
    },
  ],
  [DataType.BOOLEAN]: [
    {
      id: 'BOOLEAN_IS',
      title: 'If value is',
      collector: 'If value is a:BOOLEAN',
      summary: '$eq a:BOOLEAN',
      expression: '$.value == $.a',
    },
  ],
  [DataType.CHOICE_SET]: [
    {
      id: 'CHOICE_SELECTION_IS',
      title: 'If selection is',
      collector: 'If selection $is a:CHOICE',
      summary: '$eq a:CHOICE',
      expression: '$.value == $.a',
    },
    {
      id: 'CHOICE_SELECTION_IS_NOT',
      title: 'If selection is not',
      collector: 'If selection $is_not a:CHOICE',
      summary: '$neq a:CHOICE',
      expression: '$.value != $.a',
    },
    {
      id: 'CHOICE_SELECTION_IS_ONE_OF',
      title: 'If selection is one of',
      collector: 'If selection $is_one_of a:CHOICE',
      summary: 'Is one of a:CHOICE',
      expression: '$.value in $.a',
    },
    {
      id: 'CHOICE_SELECTION_IS_NOT_ONE_OF',
      title: 'If selection is not one of',
      collector: 'If selection $is_not_one_of a:CHOICE',
      summary: 'Is not one of a:CHOICE',
      expression: '$.value not in $.a',
    },
  ],
};
