import { Button } from '@/components/ui/button';
import {
  input,
  textarea,
} from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { z } from 'zod';
import { qualificationFields } from '../../../../fields';
import { useChildrenEditor } from './hooks';
import { ChildrenEditorProps } from './types';

const ChildrenEditor = ({ child }: ChildrenEditorProps) => {
  const { state, handlers } = useChildrenEditor(child);
  return (
    <Sheet open={state.open} onOpenChange={state.onOpenChange}>
      <SheetTrigger asChild>
        <Button size="table" variant="table">
          {child.name}
        </Button>
      </SheetTrigger>
      <SheetContent className="flex w-[700px] max-w-[700px] flex-col gap-0 gap-y-4 sm:max-w-[700px]">
        <SheetHeader className="mb-4">
          <SheetTitle>Update nested</SheetTitle>
          <SheetDescription>Update nested qualification</SheetDescription>
        </SheetHeader>
        <FormRenderer
          fields={[
            input({
              label: 'Name',
              name: 'name',
              schema: z.string().min(1),
              defaultValue: child.name,
            }),
            textarea({
              label: 'Description',
              name: 'description',
              schema: z.string().min(1),
              defaultValue: child.description,
            }),
            qualificationFields(child),
          ]}
          onSubmit={handlers.onUpdate}
        >
          {({ onSubmit }) => (
            <div className="flex items-center justify-between">
              <Button
                size="sm"
                variant="destructive"
                confirm="Are you sure?"
                onClick={handlers.onDelete}
              >
                Delete
              </Button>
              <Button size="sm" onClick={onSubmit}>
                Save
              </Button>
            </div>
          )}
        </FormRenderer>
      </SheetContent>
    </Sheet>
  );
};

export default ChildrenEditor;
