import React from 'react';
import JobGantt from '../../components/JobGantt';
import { useJobContext } from '../../context';
import OverviewActions from './components/OverviewActions';
import OverviewEvents from './components/OverviewEvents';
import OverviewGraph from './components/OverviewGraph';

const JobOverview = () => {
  const { state } = useJobContext();

  if (state.small) {
    return (
      <div
        className="h-full min-h-0 w-full gap-4 bg-secondary p-4"
        style={{
          display: 'grid',
          gridAutoColumns: '1fr',
          gridAutoRows: '1fr',
          gridTemplateAreas: `
            "graph graph"
            "graph graph"
            "graph graph"
            "gantt gantt"
            "gantt gantt"
          `,
        }}
      >
        <div style={{ gridArea: 'graph' }}>
          <div className="h-full rounded-lg border-[3px] border-background">
            <OverviewGraph />
          </div>
        </div>
        <div style={{ gridArea: 'gantt' }}>
          <div className="h-full overflow-hidden rounded-lg border">
            <JobGantt />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="h-full min-h-0 w-full gap-4 bg-secondary p-4"
      style={{
        display: 'grid',
        gridAutoColumns: '1fr',
        gridAutoRows: '1fr',
        gridTemplateAreas: `
          "graph graph events"
          "graph graph events"
          "graph graph events"
          "graph graph events"
          "gantt gantt actions"
          "gantt gantt actions"
          "gantt gantt actions"
        `,
      }}
    >
      <div style={{ gridArea: 'graph' }}>
        <div className="h-full rounded-lg border-[3px] border-background">
          <OverviewGraph />
        </div>
      </div>
      <div style={{ gridArea: 'gantt' }}>
        <div className="h-full overflow-hidden rounded-lg border">
          <JobGantt />
        </div>
      </div>
      <div style={{ gridArea: 'events' }}>
        <OverviewEvents />
      </div>
      <div style={{ gridArea: 'actions' }}>
        <OverviewActions />
      </div>
    </div>
  );
};

export default JobOverview;
