import { Button } from '@/components/ui/button';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import MemberTrainingForm from '../MemberTrainingForm';
import { useMemberTrainingEditor } from './hooks';
import { MemberTrainingEditorProps } from './types';

const MemberTrainingEditor = ({ training }: MemberTrainingEditorProps) => {
  const { sheet, handlers } = useMemberTrainingEditor(training);
  return (
    <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
      <SheetTrigger asChild>
        <Button
          className="block w-[300px] text-ellipsis text-start"
          size="table"
          variant="table"
        >
          <span className="block w-[300px] min-w-0 overflow-hidden text-ellipsis text-start">
            {training.qualification.name}
          </span>
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader className="mb-4">
          <SheetTitle>Update Training</SheetTitle>
          <SheetDescription>
            Update training for {training.user.name}
          </SheetDescription>
        </SheetHeader>
        <MemberTrainingForm training={training} onSubmit={handlers.onUpdate}>
          {({ onSubmit }) => (
            <div className="flex justify-between pt-16">
              <Button
                size="sm"
                variant="destructive"
                confirm="Are you sure?"
                onClick={handlers.onDelete}
              >
                Delete record
              </Button>
              <Button size="sm" onClick={onSubmit}>
                Update record
              </Button>
            </div>
          )}
        </MemberTrainingForm>
      </SheetContent>
    </Sheet>
  );
};

export default MemberTrainingEditor;
