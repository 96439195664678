import { HocuspocusProvider } from '@hocuspocus/provider';
import * as Y from 'yjs';
import { PlatformObject, User } from 'lib/types';

export type NotesStore = {
  notes: Record<string, Note>;
};

export type Note = {
  id: string;
  content: string;
  deleted: boolean;
  objects: PlatformObject[];
  history: NoteHistoryItem[];
  scope: string;
};

export type NoteHistoryItem = {
  id: string;
  action: NoteHistoryItemAction;
  dateTime: string;
  snapshot: string;
  user: Pick<User, 'id' | 'name' | 'email'>;
};

export enum NoteHistoryItemAction {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE',
}

export type NotesProps = {
  scope: string;
  namespace: string;
  id: string;
};

export type NotesRendererProps = {
  scope: string;
  store: NotesStore;
  provider: HocuspocusProvider;
};

export type NoteProps = {
  note?: Note;
  onSave: (note: Note) => void;
  onDelete?: (note: Note) => void;
} & (
  | {
      note: Note;
      scope?: never;
    }
  | {
      note?: never;
      scope: string;
    }
);

export type UseNotesResult =
  | {
      isReady: false;
      store: undefined;
      doc: undefined;
    }
  | {
      isReady: true;
      store: NotesStore;
      doc: Y.Doc;
    };
