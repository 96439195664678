import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';

export const useStaffMemberCreate = (onCreated: any) => {
  const [open, setOpen] = useState(false);

  const [create] = useMutation(gql`
    mutation CreateUser(
      $name: String!
      $email: String!
      $jobTitle: String
      $driverKeyFobId: String
    ) {
      createUser(
        name: $name
        email: $email
        jobTitle: $jobTitle
        driverKeyFobId: $driverKeyFobId
      ) {
        user {
          id
          name
          email
          initials
          platformId
          jobTitle
          driverKeyFobId
        }
      }
    }
  `);

  const handleOnCreate = async (variables: { name: string; email: string }) => {
    const { data } = await create({ variables });
    await onCreated(data.createUser.user);
    setOpen(false);
    toast(`User ${variables.email} created successfully.`);
  };

  return {
    state: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onCreate: handleOnCreate,
    },
  };
};
