import { ChevronRight, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { toast } from 'sonner';
import { CommercialType, commercialTypeDisplayMap } from 'lib/types';
import { useCommercialEstimateContext } from '../../context';
import TableCost from './components/TableCost';
import TableValue from './components/TableValue';
import { EstimateTableContext } from './context';
import { useEstimateTable, useEstimateTableRenderer } from './hooks';
import { EstimateTableProps, EstimateTableRendererProps } from './types';

const EstimateTable = ({
  children,
  type,
  headerSummary = true,
}: EstimateTableProps) => {
  const { ready, context } = useEstimateTable(type);
  if (!ready) return null;
  return (
    <EstimateTableContext.Provider value={context}>
      <EstimateTableRenderer headerSummary={headerSummary}>
        {children}
      </EstimateTableRenderer>
    </EstimateTableContext.Provider>
  );
};

const EstimateTableRenderer = ({
  children,
  headerSummary,
}: EstimateTableRendererProps) => {
  const { sections } = useCommercialEstimateContext();
  const { type, state, totalEstimatedValue, totalEstimatedCost } =
    useEstimateTableRenderer();

  if (state.empty && !sections.isOpen(type)) {
    return null;
  }

  return (
    <Card
      className={cn('relative overflow-hidden p-0', {
        'border-labor/50': type === CommercialType.Labor,
        'border-material/50': type === CommercialType.Material,
        'border-equipment/50': type === CommercialType.Equipment,
        'border-subcontractor/50': type === CommercialType.Subcontractor,
      })}
    >
      <div className="relative z-[3]">
        <div
          className={cn(
            'grid h-8 grid-cols-7 items-center bg-gradient-to-r to-background to-20% text-sm',
            {
              'bg-background': type === CommercialType.Prelim,
              'from-labor/50': type === CommercialType.Labor,
              'from-material/50': type === CommercialType.Material,
              'from-equipment/50': type === CommercialType.Equipment,
              'from-subcontractor/50': type === CommercialType.Subcontractor,
            },
          )}
        >
          <div className="col-span-7 flex items-center">
            <Button
              className="size-8 flex-shrink-0 rounded-none p-0"
              variant="ghost"
              onClick={state.onToggle}
            >
              <ChevronRight
                className={cn('size-4', state.open && 'rotate-90 transform')}
              />
            </Button>
            {children || (
              <p className="pl-2 font-bold">
                <span className="capitalize">
                  {commercialTypeDisplayMap[type]}
                </span>{' '}
                value estimate
              </p>
            )}
          </div>
          {headerSummary && !state.open && (
            <>
              <div className="col-span-1 flex items-center">Estimated cost</div>
              <div className="col-span-1 flex items-center">
                £{totalEstimatedCost.toFixed(2)}
              </div>
              <div className="col-span-1 flex items-center">
                Estimated value
              </div>
              <div className="col-span-1 flex items-center">
                £{totalEstimatedValue.toFixed(2)}
              </div>
            </>
          )}
          {state.empty && (
            <div className="absolute inset-y-0 right-0">
              <Button
                className="size-8 rounded-none p-0"
                variant="ghost"
                onClick={() => {
                  sections.remove(type);
                  toast.success(`Removed ${commercialTypeDisplayMap[type]}`);
                }}
              >
                <X className="size-4" />
              </Button>
            </div>
          )}
        </div>
        {state.open && (
          <>
            <TableValue />
            <div className="grid grid-cols-7 text-sm">
              <div className="col-span-3 flex h-8 items-center pl-8">
                <p className="pl-2 font-bold">
                  <span className="capitalize">
                    {commercialTypeDisplayMap[type]}
                  </span>{' '}
                  estimated cost
                </p>
              </div>
            </div>
            <TableCost />
          </>
        )}
      </div>
      <div className="absolute inset-0 z-[2] flex">
        <div className="-ml-[1px] grid h-full w-full grid-cols-8">
          <div className="col-span-7" />
          <div className="col-span-1 ml-[1px] bg-secondary ring-1 ring-border" />
        </div>
      </div>
    </Card>
  );
};

export default EstimateTable;
