import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Outlet } from 'react-router-dom';
import { Badge } from '@/components/ui/badge';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable';
import { cn } from '@/lib/utils';
import { useRouteMatch } from 'lib/hooks';
import ClientNav from './components/ClientNav';
import { ClientContext } from './context';
import { useClient, useSetupClient } from './hooks';

const Client = () => {
  const client = useSetupClient();
  return client ? (
    <ClientContext.Provider value={client}>
      <ClientRenderer />
    </ClientContext.Provider>
  ) : null;
};

const ClientRenderer = () => {
  const client = useClient();
  const routeMatch = useRouteMatch([
    '/clients/:clientSlug',
    '/clients/:clientSlug/todos',
    '/clients/:clientSlug/activity',
    '/clients/:clientSlug/commercial/*',
    '/clients/:clientSlug/projects/*',
    '/clients/:clientSlug/assets/*',
    '/clients/:clientSlug/sites/*',
    '/clients/:clientSlug/maintenance/*',
    '/clients/:clientSlug/jobs/:jobReference/*',
    '/clients/:clientSlug/jobs/*',
    '/clients/:clientSlug/settings/*',
  ]);
  return (
    <ResizablePanelGroup
      autoSaveId="client"
      direction="horizontal"
      className="grow items-stretch bg-background"
    >
      <ResizablePanel
        className="relative flex flex-col bg-background-secondary"
        collapsible={false}
        minSize={12}
        maxSize={15}
      >
        <div className="flex h-[52px] flex-shrink-0 items-center gap-x-3 border-b px-4">
          <Badge
            className="h-8 w-8 justify-center rounded-md text-white"
            style={{ backgroundColor: client.accentColor }}
          >
            <p className="text-sm">{client.name.at(0)}</p>
          </Badge>
          <h1
            className={cn('text-xl font-semibold', {
              'text-gray-600 dark:text-gray-400':
                routeMatch?.pattern.path !== '/clients/:clientSlug',
            })}
          >
            {client.name}
          </h1>
          {routeMatch?.pattern.path !== '/clients/:clientSlug' && (
            <ArrowRight className="ml-auto h-4 w-4 text-muted-foreground" />
          )}
        </div>
        <ClientNav routeMatch={routeMatch} />
      </ResizablePanel>
      <ResizableHandle withHandle />
      <ResizablePanel minSize={30} className="flex flex-col">
        <Outlet />
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};

export default Client;
