import { createContext, useContext } from 'react';
import { ToolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';

export const VersionRendererContext = createContext({
  Toolbar: null as unknown as ToolbarSlot,
  toolbar: null as unknown as ToolbarPlugin,
});

export const useVersionRendererContext = () => {
  return useContext(VersionRendererContext);
};
