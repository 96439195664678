import { createContext, useContext } from 'react';
import { useSupplyChainSubcontractors } from './hooks';

export const SupplyChainSubcontractorsContext = createContext(
  {} as ReturnType<typeof useSupplyChainSubcontractors>['context'],
);

export const useSupplyChainSubcontractorsContext = () => {
  return useContext(SupplyChainSubcontractorsContext);
};
