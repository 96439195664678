import React from 'react';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/lib/utils';
import { ChoiceButtonsProps } from './types';

const ChoiceButtons = ({ value, options, onChange }: ChoiceButtonsProps) => {
  return (
    <div className="flex flex-col gap-2">
      {options.map((option) => {
        const isSelected = value.includes(option.value);
        return (
          <Button
            key={option.value}
            className={cn('justify-start pl-2', {
              'font-semibold': isSelected,
            })}
            variant="outline"
            onClick={() =>
              onChange(
                isSelected
                  ? value.filter((inner) => inner !== option.value)
                  : [...value, option.value],
              )
            }
          >
            <Checkbox
              asChild
              checked={isSelected}
              style={{
                borderColor: option.color,
                backgroundColor: isSelected ? option.color : undefined,
              }}
            />
            <p className="ml-2">{option.label}</p>
          </Button>
        );
      })}
    </div>
  );
};

export default ChoiceButtons;
