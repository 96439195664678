import React from 'react';
import { cn } from '@/lib/utils';
import { DataType } from 'external/FormTemplateEditor';
import { useFormContext } from '../../../../context';
import FormFieldComment from '../FormFieldComment';
import { FormFieldLabelProps } from './types';

const FormFieldLabel = ({ item }: FormFieldLabelProps) => {
  const { template } = useFormContext();
  const { dataType, isRequired } = item.data;
  const [itemId] = item.id.split('::').slice(-1);

  if (dataType === DataType.COMMENT) {
    return (
      <div style={{ marginLeft: `${item.depth * 16}px` }}>
        <FormFieldComment value={item.data.value} />
      </div>
    );
  }

  return (
    <>
      <div className="mb-2 flex items-center">
        <div className="grow">
          <p className="text-sm font-semibold">
            <span>{isRequired ? '* ' : ' '}</span>
            {item.data.value}
          </p>
        </div>
        {itemId in (template.fieldLabels ?? {}) && (
          <div className="flex gap-x-0.5">
            <p className="text-xs opacity-75">Label:</p>
            <p className="text-xs font-medium opacity-75">
              "{template.fieldLabels[itemId].label}"
            </p>
          </div>
        )}
      </div>
      {item.data.helpText && (
        <p
          className={cn('light:text-muted mb-2.5 text-xs', {
            'ml-1.5': isRequired,
          })}
        >
          {item.data.helpText.value}
        </p>
      )}
    </>
  );
};

export default FormFieldLabel;
