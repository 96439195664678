import React, { useMemo, useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { RowSelectionState } from '@tanstack/table-core/src/features/RowSelection';
import { toast } from 'sonner';
import { useStore } from '../../state';
import RosterDutyField from '../RosterDutyField';
import { useSubmitRoster } from './hooks';

const RosterSubmit = () => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<RowSelectionState>({});
  const selectedIds = Object.entries(selected)
    .filter(([_, isSelected]) => isSelected)
    .map(([id]) => id);

  const { rosterTeam, rosterDuties, user } = useStore((state) => ({
    rosterTeam: state.rosterTeam,
    rosterDuties: state.rosterDuties,
    user: state.user,
  }));

  const canSubmit =
    rosterDuties.length > 0 &&
    rosterDuties.filter((rosterDuty) => !rosterDuty.isPristine).length > 0;

  const dutiesToSubmit = useMemo(
    () =>
      rosterTeam
        ? rosterDuties
            .filter(({ isPristine }) => !isPristine)
            .filter(({ team }) => team.id === rosterTeam.id)
        : [],
    [rosterDuties, rosterTeam],
  );

  const { onSubmit, isSubmitting } = useSubmitRoster();

  if (!rosterTeam || !user) return null;

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (open) {
          setSelected(
            dutiesToSubmit.reduce((acc, { id }) => {
              acc[id] = true;
              return acc;
            }, {} as RowSelectionState),
          );
        }
      }}
    >
      <DialogTrigger asChild>
        <Button
          isLoading={isSubmitting}
          variant="outline"
          size="sm"
          disabled={!canSubmit}
        >
          Submit for approval
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Submit for approval</DialogTitle>
          <DialogDescription>
            Select the duties you want to submit for approval
          </DialogDescription>
        </DialogHeader>
        <RosterDutyField
          rosterDuties={dutiesToSubmit}
          value={selected}
          onChange={setSelected}
        />
        <DialogFooter>
          <DialogClose asChild>
            <Button size="sm" variant="outline" onClick={() => {}}>
              Cancel
            </Button>
          </DialogClose>
          <Button
            size="sm"
            disabled={selectedIds.length === 0}
            onClick={async () => {
              setOpen(false);
              await onSubmit(selectedIds);
              toast.success('Roster duties submitted');
            }}
          >
            Submit
            {selectedIds.length > 0
              ? ` ${selectedIds.length} dut${
                  selectedIds.length === 1 ? 'y' : 'ies'
                }`
              : ''}{' '}
            for approval
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default RosterSubmit;
