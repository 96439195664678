import React from 'react';
import UserPhoto from '../../../../../UserPhoto';
import { SchedulerResourceType } from '../../../../types';
import { ContentColumnProps } from './types';

const ContentColumn = ({ record }: ContentColumnProps) => {
  if (record.type === SchedulerResourceType.User) {
    return (
      <div className="flex items-center gap-x-2 pl-4">
        <UserPhoto
          className="size-8"
          avatarClassName="size-8 text-xs"
          user={record.user}
        />
        <p className="text-xs font-medium">{record.name}</p>
      </div>
    );
  }

  if (record.type === SchedulerResourceType.Subcontractor) {
    return (
      <div className="flex items-center gap-x-2 pl-4">
        <p className="text-xs font-medium">{record.name}</p>
      </div>
    );
  }

  console.warn(`Unknown resource type: ${record.type}`);
  return null;
};

export default ContentColumn;
