import * as React from 'react';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { toast } from 'sonner';
import { CommercialType, commercialTypeDisplayMap } from 'lib/types';
import { CommercialSelectorProps } from './types';

type Status = {
  value: CommercialType;
  label: string;
};

const statuses: Status[] = [
  {
    value: CommercialType.Prelim,
    label: 'Prelims',
  },
  {
    value: CommercialType.Labor,
    label: 'Labour',
  },
  {
    value: CommercialType.Material,
    label: 'Materials',
  },
  {
    value: CommercialType.Equipment,
    label: 'Equipment',
  },
  {
    value: CommercialType.Subcontractor,
    label: 'Subcontractors',
  },
];

const CommercialSelector = ({ sections }: CommercialSelectorProps) => {
  const [open, setOpen] = React.useState(false);

  const handleOnSelect = (status: Status) => {
    setOpen(false);
    sections.add(status.value);
    toast.success(`Added ${commercialTypeDisplayMap[status.value]}`);
  };

  if (sections.value.length === 5) {
    return null;
  }

  return (
    <div className="flex items-center space-x-4">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            size="sm"
            variant="outline"
            className="w-[150px] justify-start"
          >
            <Plus className="mr-1 size-3" /> Add section
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0" side="right" align="start">
          <Command>
            <CommandInput placeholder="Search..." />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {statuses.map((status) =>
                  sections.isOpen(status.value) ? null : (
                    <CommandItem
                      key={status.value}
                      value={status.value}
                      onSelect={() => handleOnSelect(status)}
                    >
                      {status.label}
                    </CommandItem>
                  ),
                )}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default CommercialSelector;
