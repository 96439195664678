import { DataType } from 'external/FormTemplateEditor';
import { DateTime, Duration } from 'luxon';


const getPlatformType = (value: any) => {
  if (typeof value === 'undefined') return DataType.UNDEFINED;
  if (value === null) return DataType.UNDEFINED;
  if (Array.isArray(value)) return DataType.ARRAY;

  if (DateTime.isDateTime(value)) return DataType.DATETIME;
  if (Duration.isDuration(value)) return DataType.DURATION;

  switch (typeof value) {
    case 'boolean':
      return DataType.BOOLEAN;
    case 'number':
      return DataType.NUMBER;
    case 'object':
      return DataType.OBJECT;
    case 'string':
      return DataType.CHAR;
    default:
      return DataType.UNDEFINED;
  }
};

const renderForPlatform = (obj: any): any => {
  const type = getPlatformType(obj);

  if (type === DataType.UNDEFINED) return null;

  if (type === DataType.OBJECT) {
    return {
      dataType: DataType.OBJECT,
      value: Object.entries(obj).reduce(
        (ret, [key, value]) => ({
          ...ret,
          [key]: renderForPlatform(value),
        }),
        {},
      ),
    };
  }

  if (type === DataType.ARRAY) {
    return {
      dataType: DataType.ARRAY,
      value: obj.map(renderForPlatform),
    };
  }

  return { dataType: type, value: obj };
};

export default renderForPlatform;
