import { useMatch } from 'react-router';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'sonner';
import {
  DeleteTrainingEventData,
  DeleteTrainingEventVariables,
  TrainingEventQueryData,
  TrainingEventQueryVariables,
  UpdateTrainingEventData,
  UpdateTrainingEventVariables,
} from './types';

export const useTrainingEvent = () => {
  const match = useMatch('/human-resources/training/events/:id');

  const { data, refetch } = useQuery<
    TrainingEventQueryData,
    TrainingEventQueryVariables
  >(
    gql`
      query GetTrainingEvent($id: ID!) {
        trainingEvent(trainingEventId: $id) {
          id
        }
      }
    `,
    {
      skip: !match?.params.id,
      variables: { id: match?.params.id as string },
    },
  );

  const [update] = useMutation<
    UpdateTrainingEventData,
    UpdateTrainingEventVariables
  >(gql`
    mutation UpdateTrainingEvent($id: ID!) {
      updateTrainingEvent(trainingEventId: $id) {
        trainingEvent {
          id
        }
      }
    }
  `);

  const handleOnUpdate = async () => {
    const { data, errors } = await update({});

    if (data) {
      await refetch();
      toast.success('Event updated successfully');
    }

    if (errors) {
      toast.error('Failed to update event');
    }
  };

  const [delete_] = useMutation<
    DeleteTrainingEventData,
    DeleteTrainingEventVariables
  >(gql`
    mutation DeleteTrainingEvent($id: ID!) {
      deleteTrainingEvent(trainingEventId: $id) {
        deleted
      }
    }
  `);

  const handleOnDelete = async () => {
    const { data, errors } = await delete_({});

    if (data) {
      await refetch();
      toast.success('Event deleted successfully');
    }

    if (errors) {
      toast.error('Failed to delete event');
    }
  };

  if (!data) {
    return {
      event: null,
    };
  }

  return {
    event: {
      ...data.trainingEvent,
      refetch,
    },
    handlers: {
      onUpdate: handleOnUpdate,
      onDelete: handleOnDelete,
    },
  };
};
