import { createContext, useContext } from 'react';
import { useActivityMap } from './hooks';

export const ActivityMapContext = createContext(
  {} as ReturnType<typeof useActivityMap>['context'],
);

export const useActivityMapContext = () => {
  return useContext(ActivityMapContext);
};
