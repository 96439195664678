import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { CommercialValueLine } from 'lib/types';
import { useCommercialActivityContext } from '../../../../../../context';
import { useActivityTableContext } from '../../../../context';

export const useValueMenu = (line: CommercialValueLine) => {
  const { store } = useCommercialActivityContext();
  const { updateRefetch } = useActivityTableContext();

  const [copy] = useMutation(
    gql`
      mutation CopyCommercialValueLineToCost($line: ID!) {
        copyCommercialLineToCost(line: $line) {
          commercialCostLine {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCopyToCost = async () => {
    const { data, errors } = await copy({ variables: { line: line.id } });

    if (data) {
      const { id } = data.copyCommercialLineToCost.commercialCostLine;
      store.order.push(id);
      await updateRefetch();
      toast.success('Line copied to cost');
    }

    if (errors) {
      toast.error('Error copying line to cost');
    }
  };

  const [delete_] = useMutation(
    gql`
      mutation DeleteCommercialValueLine($line: ID!) {
        deleteCommercialValueLine(line: $line) {
          deleted
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnDelete = async () => {
    const { data, errors } = await delete_({ variables: { line: line.id } });

    if (data) {
      try {
        store.order.splice(store.order.indexOf(line.id), 1);
      } catch (error) {
        console.error('Error removing line from store', error);
      }
      await updateRefetch();
    }

    if (errors) {
    }
  };

  return {
    handlers: {
      onCopy: handleOnCopyToCost,
      onDelete: handleOnDelete,
    },
  };
};
