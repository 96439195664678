import React, { useMemo, useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { RowSelectionState } from '@tanstack/table-core/src/features/RowSelection';
import { toast } from 'sonner';
import RosterDutyField from '../../../HumanResourcesRoster/components/RosterDutyField';
import { useStore } from '../../state';
import { useApproveRoster } from './hooks';

const RosterApprove = () => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<RowSelectionState>({});
  const selectedIds = Object.entries(selected)
    .filter(([_, isSelected]) => isSelected)
    .map(([id]) => id);

  const { rosterTeam, rosterDuties, rosterValidationResults, user } = useStore(
    (state) => ({
      rosterTeam: state.rosterTeam,
      rosterDuties: state.rosterDuties,
      rosterValidationResults: state.rosterValidationResults,
      user: state.user,
    }),
  );

  const canApprove =
    rosterDuties.length > 0 &&
    rosterValidationResults.exceedances.filter(({ review }) => !!review) &&
    rosterDuties
      .filter((rosterDuty) => rosterDuty.team.id === rosterTeam?.id)
      .filter((rosterDuty) => rosterDuty.status !== '2').length > 0;

  const dutiesToApprove = useMemo(
    () =>
      rosterTeam
        ? rosterDuties
            .filter(({ status }) => status === '1')
            .filter(({ team }) => team.id === rosterTeam.id)
        : [],
    [rosterDuties, rosterTeam],
  );

  const { onSubmit, isApproving } = useApproveRoster();

  if (!user) return null;

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (open) {
          setSelected(
            dutiesToApprove.reduce((acc, { id }) => {
              acc[id] = true;
              return acc;
            }, {} as RowSelectionState),
          );
        }
      }}
    >
      <DialogTrigger asChild>
        <Button
          isLoading={isApproving}
          variant="outline"
          size="sm"
          disabled={!canApprove}
        >
          Approve duties
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Approve</DialogTitle>
          <DialogDescription>
            Select the duties you want to approve
          </DialogDescription>
        </DialogHeader>
        <RosterDutyField
          rosterDuties={dutiesToApprove}
          value={selected}
          onChange={setSelected}
        />
        <DialogFooter>
          <DialogClose asChild>
            <Button size="sm" variant="outline" onClick={() => {}}>
              Cancel
            </Button>
          </DialogClose>
          <Button
            size="sm"
            disabled={selectedIds.length === 0}
            onClick={async () => {
              setOpen(false);
              await onSubmit(selectedIds);
              toast.success('Roster duties approved');
            }}
          >
            Approve
            {selectedIds.length > 0
              ? ` ${selectedIds.length} dut${
                  selectedIds.length === 1 ? 'y' : 'ies'
                }`
              : ''}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default RosterApprove;
