import { gql, useQuery } from '@apollo/client';
import { useClient } from '../../../../../hooks';
import { JobPrioritiesQueryData, JobPrioritiesQueryVariables } from './types';

export const useDeliveryPriorities = () => {
  const client = useClient();

  const { data, refetch } = useQuery<
    JobPrioritiesQueryData,
    JobPrioritiesQueryVariables
  >(
    gql`
      query GetPriorities($clientId: ID!) {
        jobPriorities(clientId: $clientId) {
          id
          color
          reference
          responseName
          responseNameShort
          responseDuration
          completionName
          completionNameShort
          completionDuration
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      variables: { clientId: client.id },
    },
  );

  return {
    priorities: data?.jobPriorities || [],
    refetch,
  };
};
