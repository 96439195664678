import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { User } from 'lib/types';
import { useCompanyStaffContext } from '../../../../context';
import { useStaffMemberContext } from '../../context';

export const useMemberLineManager = () => {
  const { refetch: refetchList } = useCompanyStaffContext();
  const { user } = useStaffMemberContext();

  const [open, setOpen] = useState(false);

  const [update] = useMutation(
    gql`
      mutation UpdateUser($id: ID!, $field: String!, $value: JSONScalarInput!) {
        updateUser(userId: $id, field: $field, value: $value) {
          user {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnSelect = async ([selected]: User[]) => {
    if (!user) {
      return;
    }

    const { data, errors } = await update({
      variables: {
        id: user.id,
        field: 'line_manager_id',
        value: selected.id,
      },
    });

    if (data) {
      await user.refetch();
      await refetchList();
      setOpen(false);
      toast.success('Line manager updated successfully');
    }

    if (errors) {
      toast.error('Failed to update line manager');
    }
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onSelect: handleOnSelect,
    },
  };
};
