import React from 'react';
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverAnchor,
  PopoverContent,
} from '@/components/ui/popover';
import { ScrollArea } from '@/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { useTableSelector } from './hooks';
import { TableSelectorProps, ValueBase } from './types';

export const TableSelector = <Value extends ValueBase>({
  children,
  disabled = false,
  className,
  popoverClassName,
  placeholder,
  value,
  valueDisplay,
  valueTag,
  searchDefault,
  searchPlaceholder,
  onChange,
  onOpen,
}: TableSelectorProps<Value>) => {
  const { state, popover, command, search } = useTableSelector(
    onChange,
    onOpen,
    searchDefault ? searchDefault : undefined,
  );
  return (
    <Popover open={popover.open} onOpenChange={popover.onOpenChange}>
      <PopoverAnchor asChild>
        <button
          className={cn(
            'flex h-8 w-full cursor-text items-center px-2 text-left outline-none hover:bg-accent',
            className,
          )}
          onClick={popover.onOpen}
        >
          {valueDisplay ? (
            <>
              <span className="grow">{valueDisplay}</span>
            </>
          ) : (
            <span className="grow text-xs opacity-30">{placeholder}</span>
          )}
          {valueTag}
        </button>
      </PopoverAnchor>
      <PopoverContent
        variant="no-animation"
        className={cn(
          '-ml-0.5 rounded-sm border-2 border-success p-0 shadow-2xl',
          popoverClassName,
        )}
        style={{ width: 'calc(var(--radix-popover-trigger-width) + 4px)' }}
        align="start"
        sideOffset={-34}
      >
        <Command
          disablePointerSelection={state.loading}
          className={cn('h-[300px] rounded-t-[3px] p-0', {
            'ring-2': state.loading,
          })}
          vimBindings={false}
          defaultValue={value ? value : undefined}
          onKeyDown={command.onKeyDown}
        >
          <CommandInput
            wrapperClassname="px-0"
            className="h-[31px] rounded-none bg-accent pl-[8px] pt-[11px] font-normal placeholder:text-xs placeholder:font-medium placeholder:text-inherit placeholder:opacity-30"
            placeholder={searchPlaceholder}
            icon={false}
            value={search.value}
            disabled={state.loading}
            onValueChange={search.onValueChange}
          />
          <ScrollArea className="h-full min-h-0">
            <CommandList className="max-h-none">
              <CommandEmpty>No results found.</CommandEmpty>
              {children({
                disabled: disabled || state.loading,
                onSelect: command.onSelect,
              })}
            </CommandList>
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default TableSelector;
