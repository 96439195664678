import React from 'react';
import { DateTime } from 'luxon';
import { Report } from 'lib/shared';
import { useQuoteRendererContext } from '../../context';

const RendererHeader = () => {
  const { theme, job, estimate } = useQuoteRendererContext();

  if (job.partial) return null;

  const quote = job.quotes.find(
    (quote) => quote.commercialEstimate.id === estimate.id,
  );

  return (
    <Report.View
      style={{
        paddingHorizontal: 32,
        paddingTop: 12,
        marginBottom: 24,
        borderTop: '4px solid #106c12',
      }}
    >
      <Report.View
        style={{
          marginBottom: 24,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Report.View style={{ width: '50%' }}>
          <Report.View style={{ width: 32, marginBottom: 12 }}>
            <Report.Logo style={{ width: 16 }} />
          </Report.View>
          <Report.Text
            style={{
              fontSize: 13,
              fontWeight: 500,
              lineHeight: '1.5',
              color: theme.colors.subtitle,
            }}
          >
            429a Footscray Road, New Eltham,
          </Report.Text>
          <Report.Text
            style={{
              fontSize: 13,
              fontWeight: 500,
              lineHeight: '1.5',
              color: theme.colors.subtitle,
            }}
          >
            London, SE9 3UL
          </Report.Text>
        </Report.View>
        <Report.View
          style={{ width: '50%', paddingTop: 8, textAlign: 'right' }}
        >
          <Report.Text
            style={{
              fontSize: 13,
              fontWeight: 700,
              marginBottom: 8,
            }}
          >
            Quote
          </Report.Text>
          <Report.Text
            style={{
              fontSize: 13,
              fontWeight: 700,
              whiteSpace: 'nowrap',
              color: theme.colors.subtitle,
            }}
          >
            {quote ? quote.reference : 'Draft'}
          </Report.Text>
        </Report.View>
      </Report.View>
      <Report.View>
        <Report.View
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: 12,
            marginBottom: 8,
          }}
        >
          <Report.Text style={{ fontWeight: 700 }}>Date: </Report.Text>
          <Report.Text>
            {DateTime.now().toLocaleString(DateTime.DATE_FULL)}
          </Report.Text>
        </Report.View>
        <Report.View
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: 12,
            marginBottom: 8,
          }}
        >
          <Report.Text style={{ fontWeight: 700 }}>Site: </Report.Text>
          <Report.Text>{job.site.reference}</Report.Text>
        </Report.View>
        <Report.View
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: 12,

            marginBottom: 8,
          }}
        >
          <Report.Text style={{ fontWeight: 700 }}>Lead time: </Report.Text>
          <Report.Text>18 days</Report.Text>
        </Report.View>
        <Report.View>
          <Report.Text
            style={{
              fontSize: 12,
              fontWeight: 700,
              marginBottom: 4,
            }}
          >
            Description:
          </Report.Text>
          <Report.Text style={{ fontSize: 11 }}>
            {estimate.description}
          </Report.Text>
        </Report.View>
      </Report.View>
    </Report.View>
  );
};

export default RendererHeader;
