import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useCommercialActivityContext } from '../../../../../CommercialActivity/context';
import { useCommercialEstimateContext } from '../../../../context';
import {
  ConvertCommercialEstimateToContractMutationData,
  ConvertCommercialEstimateToContractMutationVariables,
} from './types';

export const usePrelimsConvertToContract = () => {
  const { activity } = useCommercialActivityContext();
  const { estimate } = useCommercialEstimateContext();

  const [open, setOpen] = useState(false);

  const [convert] = useMutation<
    ConvertCommercialEstimateToContractMutationData,
    ConvertCommercialEstimateToContractMutationVariables
  >(
    gql`
      mutation ConvertToContractPrelim($id: ID!) {
        convertCommercialEstimateToContractPrelim(commercialEstimateId: $id) {
          commercialEstimate {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnConvert = async () => {
    if (!estimate) {
      console.error('No estimate found');
      return;
    }

    const { data, errors } = await convert({
      variables: {
        id: estimate.id,
      },
    });

    if (data) {
      await activity.refetch();
      await estimate.refetch();
      setOpen(false);
      toast.success('Converted to contract prelim');
    }

    if (errors) {
      toast.error('Failed to convert to contract prelim');
    }
  };

  return {
    dialog: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onConvert: handleOnConvert,
    },
  };
};
