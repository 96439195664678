import { gql } from '@apollo/client';
import usePlatformSubscription from '../../usePlatformSubscription';

const useRealtime = (scope: string, callback: (realtimeData: any) => void) => {
  return usePlatformSubscription<
    RealtimeSubscriptionData,
    RealtimeSubscriptionVariables
  >(
    gql(`
      subscription ListenForTodoUpdates($scope: String!) {
        subscribe(scope: $scope) {
          scope
          data
        }
      }
    `),
    {
      variables: { scope },
      onData: ({ data: { data } }) => {
        if (!data?.subscribe) return;

        console.debug('Realtime data received', data.subscribe);

        let realtimeData: any;
        try {
          realtimeData = JSON.parse(data.subscribe.data);
        } catch (error) {
          console.warn('Failed to parse realtime data', error);
          return;
        }
        try {
          callback(realtimeData);
        } catch (error) {
          console.warn('Failed to handle realtime data', error);
        }
      },
    },
  );
};

type RealtimeSubscriptionVariables = {
  scope: string;
};

type RealtimeSubscriptionData = {
  subscribe: {
    scope: string;
    data: string;
  };
};

export default useRealtime;
