import React from 'react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { ChoiceRadioGroupProps } from './types';

const ChoiceRadioGroup = ({
  options,
  value,
  onChange,
}: ChoiceRadioGroupProps) => {
  return (
    <div className="flex flex-col gap-y-4">
      {options.map((option) => {
        const selected = value?.[0] === option.value;
        return (
          <Button
            key={option.value}
            size="sm"
            variant="outline"
            className={cn({ 'font-semibold': selected })}
            style={{ backgroundColor: selected ? option.color : undefined }}
            onClick={() => {
              if (selected) {
                onChange(undefined);
                return;
              }

              onChange(option.value);
            }}
          >
            {option.label}
          </Button>
        );
      })}
    </div>
  );
};

export default ChoiceRadioGroup;
