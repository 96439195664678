import React from 'react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { UpdateStatus } from '../../../../types';
import { usePendingStyle } from '../../hooks';
import PendingDateTime from '../PendingDateTime';
import PendingDuration from '../PendingDuration';
import PendingStaffMember from '../PendingStaffMember';
import PendingSubcontractor from '../PendingSubcontractor';
import { PendingDeleteProps } from './types';

const PendingDelete = ({
  update,
  onHighlight,
  onRevert,
  onConfirm,
}: PendingDeleteProps) => {
  const style = usePendingStyle(update, {
    backgroundColor: 'rgba(148, 34, 34, 0.1)',
    borderColor: 'rgba(148, 34, 34, 0.75)',
  });
  return (
    <div className="rounded-md border p-4" style={style}>
      <div className="mb-4 flex items-center justify-between">
        <p className="text-sm font-semibold">
          {update.status ?? 'Pending deletion'}
        </p>
        <div className="flex gap-x-2">
          <Badge className="h-5" variant="destructive">
            Delete timesheet duty
          </Badge>
        </div>
      </div>
      <div>
        {update.duty.user && (
          <PendingStaffMember className="mb-4" value={update.duty.user} />
        )}
        {update.duty.subcontractor && (
          <PendingSubcontractor
            className="mb-4"
            value={update.duty.subcontractor}
          />
        )}
        {update.duty.dateTimeStart && (
          <PendingDateTime
            className="mb-4"
            title="Start"
            value={update.duty.dateTimeStart}
          />
        )}
        {update.duty.dateTimeEnd && (
          <PendingDateTime
            className="mb-4"
            title="End"
            value={update.duty.dateTimeEnd}
          />
        )}
        {update.duty.dateTimeStart && update.duty.dateTimeEnd && (
          <PendingDuration
            className="mb-4"
            title="Duration"
            value={update.duty.dateTimeEnd.diff(update.duty.dateTimeStart)}
          />
        )}
      </div>
      <div className="flex justify-between">
        <div className="flex items-center gap-x-2">
          {update.status !== UpdateStatus.Applied && (
            <Button size="sm" variant="outline" onClick={onHighlight}>
              Highlight
            </Button>
          )}
          {update.status === UpdateStatus.Error && (
            <Button size="sm" variant="outline" onClick={onConfirm}>
              Retry
            </Button>
          )}
          {!update.status && (
            <Button size="sm" variant="outline" onClick={onRevert}>
              Reinstate
            </Button>
          )}
        </div>
        <Button
          size="sm"
          variant="destructive"
          disabled={!!update.status}
          isLoading={update.status === UpdateStatus.Applying}
          onClick={onConfirm}
        >
          {!update.status && 'Delete'}
          {update.status === UpdateStatus.Applied && 'Deleted timesheet duty'}
          {update.status === UpdateStatus.Error && 'Failed'}
        </Button>
      </div>
    </div>
  );
};

export default PendingDelete;
