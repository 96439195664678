import { useQuery } from '@apollo/client';
import { useJobContext } from '../../context';
import { GET_COMMERCIAL_ACTIVITY_QUERY } from './query';
import {
  CommercialActivityQueryData,
  CommercialActivityQueryVariables,
} from './types';

export const useJobCommercial = () => {
  const { job } = useJobContext();

  const { data, error, loading, refetch } = useQuery<
    CommercialActivityQueryData,
    CommercialActivityQueryVariables
  >(GET_COMMERCIAL_ACTIVITY_QUERY, {
    skip: job.partial,
    variables: job.partial
      ? undefined
      : {
          id: job.variation
            ? job.variation.commercialActivity?.id
            : job.commercialActivity?.id,
        },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  if (!data?.commercialActivity) {
    return {
      context: {
        state: {
          loading,
          error,
          exists: !loading,
        },
        activity: undefined,
      },
    };
  }

  return {
    context: {
      state: {
        loading: false,
        error,
        exists: true,
      },
      activity: {
        ...data.commercialActivity,
        refetch,
      },
    },
  };
};
