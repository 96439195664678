import { create } from 'zustand';
import { User } from 'lib/types';

export type GlobalValues = {
  user: User | null;
  theme: 'light' | 'dark';
};

export type GlobalActions = {
  apply: (getNewState: (state: GlobalState) => GlobalState) => void;
};

export type GlobalState = GlobalValues & GlobalActions;

export const initialValues: GlobalValues = {
  user: null,
  theme: (localStorage.getItem('theme') as 'light' | 'dark') ?? 'light',
};

export const useGlobalStore = create<GlobalState>()((set) => ({
  ...initialValues,
  apply: (getNewState) => set(getNewState),
}));
