import React, { useCallback } from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { Item, ItemType } from 'external/Tree';
import { BlockData, LogicData } from 'external/types';
import { generateId } from 'lib/utils';
import { useFormTemplateContext } from '../../context';
import { useDeleteItem, useSyncedTransact } from '../../hooks';
import { CONDITIONS, DataType } from '../../types';

export const useBlockControls = (item: Item<BlockData>) => {
  const { store, focus, onCollapse, onSelectLogic } = useFormTemplateContext();
  const { fieldLabels, items } = useSyncedStore(store);
  const transact = useSyncedTransact(store);

  const isFocused = focus === item.id;
  const handleDeleteOnClick = useDeleteItem(item);

  const handleAddLogicOnClick = useCallback(() => {
    const condition = CONDITIONS[item.data.dataType][0];
    const newLogicId = generateId();
    const newLogicItem = {
      id: newLogicId,
      type: ItemType.LOGIC,
      parentId: item.id,
      data: {
        conditionId: condition.id,
        expression: condition.expression,
      } as LogicData,
      children: [],
    };

    const newBlockId = generateId();
    const newBlockItem = {
      id: newBlockId,
      type: ItemType.BLOCK,
      parentId: newLogicItem.id,
      data: { dataType: DataType.CHAR } as BlockData,
      children: [],
    };

    transact(() => {
      items[item.id].children.push(newLogicItem.id);
      items[newBlockItem.id] = newBlockItem;
      items[newLogicItem.id] = newLogicItem;
      items[newLogicItem.id].children = [newBlockItem.id];
    });

    setTimeout(() => {
      onCollapse(item, false)();
      onSelectLogic(newLogicItem)();
    }, 100);
  }, [item, items, onCollapse, onSelectLogic, transact]);

  const handleAddLabelOnClick = useCallback(() => {
    const label = 'new-label';
    fieldLabels[item.id] = { label };
  }, [fieldLabels, item.id]);

  const handleLabelOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      fieldLabels[item.id].label = event.target.value
        .toLowerCase()
        .replace(/ /g, '-');
    },
    [fieldLabels, item.id],
  );

  const handleLabelOnRemove = useCallback(() => {
    delete fieldLabels[item.id];
  }, [fieldLabels, item.id]);

  const fieldLabel = fieldLabels?.[item.id];
  return {
    isFocused,
    handleDeleteOnClick,
    handleAddLogicOnClick,
    handleAddLabelOnClick,
    handleLabelOnChange,
    handleLabelOnRemove,
    fieldLabel,
  };
};
