import useUploadFiles from './useUploadFiles';

const useUploadFile = () => {
  const uploadFiles = useUploadFiles({});
  return async (file: File) => {
    const [object] = await uploadFiles([file]);
    return object;
  };
};

export default useUploadFile;
