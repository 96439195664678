import { useTheme } from '@/components/ui/theme';

const useMapStyle = () => {
  const { theme } = useTheme();
  return theme === 'dark'
    ? 'mapbox://styles/energyenables/clrcv8giv009s01pedl6ecmy5'
    : 'mapbox://styles/energyenables/clqzso8ec009y01pd8b4tatmd';
};

export default useMapStyle;
