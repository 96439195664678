import React from 'react';
import { Search } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { useJobContext } from '../../../../../../context';

const ActivityToolbar = () => {
  const { job } = useJobContext();

  if (job.partial) return null;

  return (
    <div className="relative flex h-12 flex-shrink-0 items-center justify-center border-b bg-background">
      <div className="absolute inset-y-0 left-4 flex items-center">
        <Badge className="h-7 rounded-sm" variant="secondary">
          {job.variation ? (
            <>{job.variation.reference}</>
          ) : (
            <>Commercial activity: Root</>
          )}
        </Badge>
      </div>
      <div
        className="relative flex overflow-hidden rounded-md bg-secondary"
        style={{ width: 'calc(var(--scale-factor)* 595.280029px)' }}
      >
        <Popover>
          <PopoverTrigger asChild>
            <Button
              size="sm"
              variant="secondary"
              className="h-7 w-full gap-x-2 rounded-none dark:text-gray-400"
            >
              <Search className="h-4 w-4" />
              Search...
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="popover-content-width-same-as-its-trigger p-0"
            side="top"
            align="start"
          >
            <Command>
              <CommandInput placeholder="Type a command or search..." />
              <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup heading="Suggestions">
                  <CommandItem>Calendar</CommandItem>
                  <CommandItem>Search Emoji</CommandItem>
                  <CommandItem>Calculator</CommandItem>
                </CommandGroup>
                <CommandSeparator />
                <CommandGroup heading="Settings">
                  <CommandItem>Profile</CommandItem>
                  <CommandItem>Billing</CommandItem>
                  <CommandItem>Settings</CommandItem>
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
      <div className="absolute inset-y-0 right-4 flex items-center">
        <Button className="h-7" size="sm" variant="outline" disabled>
          View estimates
        </Button>
      </div>
    </div>
  );
};

export default ActivityToolbar;
