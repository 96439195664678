import { gql, useQuery } from '@apollo/client';
import { usePlatformObject } from 'lib/hooks';
import { useClient } from '../../../../../../../Client/hooks';
import { useJobContext } from '../../../../../../context';
import { useCommercialEstimateContext } from '../../context';
import { JobQuoteQueryData, JobQuoteQueryVariables } from './types';

export const useEstimateQuote = () => {
  const { activity, estimate } = useCommercialEstimateContext();

  const { data } = useQuery<JobQuoteQueryData, JobQuoteQueryVariables>(
    gql`
      query GetJobQuote($estimateId: ID) {
        jobQuote(commercialEstimateId: $estimateId) {
          id
          actionSetId
          status
        }
      }
    `,
    {
      skip: !estimate,
      variables: { estimateId: estimate!.id },
    },
  );

  return {
    context: {
      activity,
      estimate: estimate!,
      jobQuote: data?.jobQuote,
    },
  };
};

export const useQuoteRenderer = () => {
  const client = useClient();

  const { job } = useJobContext();
  const { activity, estimate } = useCommercialEstimateContext();

  const { object } = usePlatformObject(client.logo?.id);

  const theme = {
    colors: {
      border: '#e0e0e0',
      signature: '#000',
      signatureBackground: '#fff',
      title: '#000',
      subtitle: '#444444',
    },
  };

  return {
    context: {
      activity,
      job,
      estimate: estimate!,
      order: [] as string[],
      theme,
      logo: object,
    },
  };
};
