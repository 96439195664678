import { PropsWithChildren, memo } from 'react';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card';
import { cn } from '@/lib/utils';
import { gql, useQuery } from '@apollo/client';
import { User } from 'lib/types';
import UserPhoto from '../UserPhoto';

const UserLabel = memo(
  ({
    children,
    small = false,
    user,
  }: PropsWithChildren<{ small?: boolean; user: User }>) => {
    const { data } = useQuery(
      gql`
        query GetUser($id: ID!) {
          user(userId: $id) {
            id
            name
            email
            initials
            externalId
          }
        }
      `,
      {
        skip: !!user?.email,
        variables: { id: user?.id },
      },
    );
    return (
      <HoverCard>
        <HoverCardTrigger asChild>
          {children ||
            (small ? (
              <Button variant="link" className="group h-6 gap-x-1 p-0">
                <UserPhoto
                  className="size-5 text-xs"
                  user={{
                    ...user,
                    ...data?.user,
                  }}
                />
                <p className={cn('text-xs font-semibold')}>
                  {user?.name ?? data?.user?.name}
                </p>
              </Button>
            ) : (
              <Button
                variant="ghost"
                className="group -ml-2 h-6 justify-start gap-x-2 p-2"
              >
                <UserPhoto
                  className="size-6 text-xs"
                  user={{
                    ...user,
                    ...data?.user,
                  }}
                />
                <p className="text-sm font-semibold">
                  {user?.name ?? data?.user?.name}
                </p>
              </Button>
            ))}
        </HoverCardTrigger>
        <HoverCardContent align="start" className="w-[300px]">
          <UserPhoto user={{ ...user, ...data?.user }} className="mb-2 size-10">
            <Avatar className="mb-2 size-10 text-xs">
              <AvatarFallback className="bg-background-secondary font-semibold dark:bg-muted">
                {user?.initials?.[0] ?? data?.user?.initials?.[0]}
              </AvatarFallback>
            </Avatar>
          </UserPhoto>
          <p className="mb-2 text-sm font-semibold">
            {user?.name ?? data?.user?.name}
          </p>
          <p className="text-sm dark:text-gray-400">
            {user?.email ?? data?.user?.email}
          </p>
        </HoverCardContent>
      </HoverCard>
    );
  },
);

export default UserLabel;
