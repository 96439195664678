import { DataType } from 'external/FormTemplateEditor';

export type MessageGroup = {
  id: string;
  messages: Message[];
  lastEvaluatedKey: string;
};

export type Message = {
  id: string;
  timestamp: number;
  bodyString: string;
  bodyHtml: string;
  bodyJson: string;
  meta: any;
};

export type PlatformMessage = Message;

export type PlatformObjectSource = {
  uri: string;
  width?: number;
  height?: number;
};

export type PlatformObjectSources = {
  full: PlatformObjectSource;
  thumbnail: PlatformObjectSource;
  compressed: PlatformObjectSource;
};

export type PlatformObjectData = {
  id: string;
  type: string;
  name: string;
  getUrlThumbnail: string;
  getUrlFull: string;
  getUrl: string;
};

export type PlatformObject<T = any> = {
  id: string;
  index?: number;
  isNew?: boolean;
  isPending?: boolean;
  progressPercentage?: number;
  type: string;
  name: string;
  status?: string;
  sources: PlatformObjectSources;
  getUrl?: string;
  getUrlThumbnail?: string;
  getUrlCompressed?: string;
  postData?: {
    url: string;
    fields: any;
  };
  size?: number;
  data: T;
  contentType?: string;
};

export type PlatformObjectWithoutSources = Omit<PlatformObject, 'sources'>;

export type PlatformObjectImageData = {
  annotations: PlatformObjectAnnotation[];
  blurhash: string;
  size: number;
};

export type PlatformObjectAnnotation = {
  id: string;
  height: number;
  width: number;
  svg: string;
};

export type PlatformAction<ActionId extends string = string> = {
  id: ActionId;
  label: string;
  description?: string;
  isValid: boolean;
  validatorDescription?: string;
  form?: PlatformActionForm;
};

export type PlatformActionForm = {
  label: string;
  choiceSets: any;
  items: any;
  hooks: PlatformActionFormHook[];
};

export type PlatformActionFormHook = {
  id: string;
  label: string;
};

export type PlatformActionFormHookItem = {
  label: string;
  ask?: DataType;
  default?: any;
  '$.default'?: string;
  if?: {
    condition: string;
    constants: Record<string, string>;
  };
  type?: DataType;
  choiceSet?: string;
  required?: boolean | string;
  isRequired?: boolean | string;
  media?: boolean;
  multiline?: boolean;
  data: any;
};

export type PlatformActionSet = {
  id: string;
  description?: string;
  actions: PlatformAction[];
};

export type PlatformActionSetQueryVariables = {
  actionSetId: string;
  context?: any;
};

export type PlatformActionSetQueryData = {
  actionSet: PlatformActionSet;
};

export type PlatformPerformActionMutationVariables = {
  actionSetId: string;
  actionId: string;
  scope: string;
  context?: any;
};

export type PlatformPerformActionMutationData = {
  performAction: string;
};

export type PlatformActionRepresentation = {
  nodes: PlatformActionRepresentationNode[];
};

export type PlatformActionRepresentationNode = {
  id: string;
  type: string;
  label: string;
  description: string | null;
  children: PlatformActionRepresentationNode[];
  humanId: string;
};

export type PlatformTodo<Data = any> = {
  id: string;
  status: string;
  title: string;
  data: Data;
  target: string;
  scopes: string[];
  timestamp: number;
  deadline: number;
  listeners: PlatformTodoListener[];
  urgency: number;
};

export type PlatformTodoGroup<Data = any> = {
  scope: string;
  todos: PlatformTodo<Data>[];
};

export type PlatformTodoListener = {
  method: string;
  filter: string;
};

export type PlatformTodoListenerFilter = {
  name: string;
  data: any;
};

export type PlatformAlert = {
  priority: number;
  schedule: {
    target: string;
    offset: number;
  };
};

export type PlatformIdentityGroup = {
  id: string;
  reference: string;
  annotatedScopes: {
    id: string;
    value: string;
    permission: {
      id: string;
      action: string;
    };
  }[];
  scopes: PlatformIdentityScope[];
  permissions: PlatformIdentityPermission[];
};

export type PlatformIdentityScope = {
  id: string;
  value: string;
};

export type PlatformIdentityPermission = {
  id: string;
  action: string;
};
