import React from 'react';

export const AsyncContext = ({
  children,
  handle,
}: {
  children: React.FC<{ loading: boolean; handle: () => Promise<any> }>;
  handle: () => Promise<any>;
}) => {
  const [loading, setLoading] = React.useState(false);
  return (
    <>
      {children({
        loading,
        handle: async () => {
          setLoading(true);
          try {
            await handle();
          } finally {
            setLoading(false);
          }
        },
      })}
    </>
  );
};

export default AsyncContext;
