import { useCallback } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { JobTaskForm } from 'lib/types';
import { useJobTaskContext } from '../../pages/JobTask/context';
import jobTaskForm from './index';
import {
  ApproveJobTaskFormMutationData,
  ApproveJobTaskFormMutationVariables,
  EditJobTaskFormMutationData,
  EditJobTaskFormMutationVariables,
  JobTaskFormQueryData,
  JobTaskFormQueryVariables,
} from './types';

export const useJobTaskForm = (jobTaskFormId: string) => {
  const { task } = useJobTaskContext();

  const { data, refetch } = useQuery<
    JobTaskFormQueryData,
    JobTaskFormQueryVariables
  >(
    gql`
      query GetJobTaskForm($jobTaskFormId: ID!) {
        jobTaskForm(jobTaskFormId: $jobTaskFormId) {
          id
          isRequired
          isCompleted
          isApproved
          isLocked
          template {
            id
            name
          }
          submissions {
            id
            number
            dateTimeCreated
            data
          }
          latestSubmission {
            id
            number
            approvedBy {
              id
              name
              email
              initials
            }
          }
        }
      }
    `,
    {
      skip: !jobTaskFormId,
      variables: { jobTaskFormId },
    },
  );

  const [approveJobTaskForm] = useMutation<
    ApproveJobTaskFormMutationData,
    ApproveJobTaskFormMutationVariables
  >(gql`
    mutation ApproveJobTaskForm(
      $jobTaskFormId: ID!
      $jobTaskFormTemplate: JSONScalarInput!
      $jobTaskFormSubmission: JSONScalarInput!
    ) {
      approveJobTaskForm(
        jobTaskFormId: $jobTaskFormId
        jobTaskFormTemplate: $jobTaskFormTemplate
        jobTaskFormSubmission: $jobTaskFormSubmission
      ) {
        jobTaskForm {
          id
          isApproved
          isLocked
          submissions {
            id
            number
            dateTimeCreated
            approvedBy {
              id
              name
              email
              initials
            }
          }
        }
      }
    }
  `);

  const handleApproveJobTaskForm = useCallback(
    async (formTemplate: any, getSubmission: () => any) => {
      if (!jobTaskFormId) return;

      const { data } = await approveJobTaskForm({
        variables: {
          jobTaskFormId,
          jobTaskFormTemplate: formTemplate,
          jobTaskFormSubmission: getSubmission().submission,
        },
      });

      if (data) {
        if (task) {
          await refetch();
          await task.refetch();
        }
      }
    },
    [task, approveJobTaskForm, jobTaskForm],
  );

  const [editJobTaskForm] = useMutation<
    EditJobTaskFormMutationData,
    EditJobTaskFormMutationVariables
  >(gql`
    mutation EditJobTaskForm($jobTaskFormId: ID!) {
      editJobTaskForm(jobTaskFormId: $jobTaskFormId) {
        jobTaskForm {
          id
          isApproved
          isLocked
          submissions {
            id
            number
            dateTimeCreated
            approvedBy {
              id
              name
              email
              initials
            }
          }
        }
      }
    }
  `);

  const handleEditJobTaskForm = useCallback(async () => {
    if (!jobTaskFormId) return;

    const { data } = await editJobTaskForm({
      variables: {
        jobTaskFormId,
      },
    });

    if (data) {
      if (task) {
        await refetch();
        await task.refetch();
      }
    }
  }, [task, editJobTaskForm, jobTaskForm]);

  if (!data?.jobTaskForm) {
    return {
      context: {
        form: null as JobTaskForm | null,
        handlers: {
          edit: handleEditJobTaskForm,
          approve: handleApproveJobTaskForm,
        },
      },
    };
  }

  return {
    context: {
      form: {
        ...data.jobTaskForm,
        refetch,
      } as JobTaskForm | null,
      handlers: {
        edit: handleEditJobTaskForm,
        approve: handleApproveJobTaskForm,
      },
    },
  };
};
