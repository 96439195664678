import { useGlobalStore } from '../state';

const useUser = () => {
  return useGlobalStore((state) => {
    if (!state.user) {
      return null;
    }
    return {
      ...state.user,
      username: state.user.email.replace('@mchughltd.co.uk', '').toLowerCase(),
    };
  });
};

export default useUser;
