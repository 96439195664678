import { useEffect } from 'react';
import { BlockData, ContainerRepeatingBranchData, Item } from 'external/types';
import { PlatformObject } from 'lib/types';
import { createSectionState, generateId } from 'lib/utils';
import { Template } from './types';

const useStore = createSectionState({
  values: {} as Record<string, any>,
  objects: {} as Record<string, Omit<PlatformObject, 'sources'>[]>,
  localObjects: [] as PlatformObject[],
  meta: {} as Record<string, any>,
});

export const useFormLocal = (
  template: Template,
  onSubmit?: (values: any) => void,
) => {
  const { values, objects, localObjects, meta, apply, reset } = useStore(
    (state) => ({
      values: state.values,
      objects: state.objects,
      localObjects: state.localObjects,
      meta: state.meta,
      apply: state.apply,
      reset: state.reset,
    }),
  );

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const handleFieldValueOnChange = (item: Item<BlockData>, value: any) => {
    apply((state) => {
      state.values[item.path] = value;
    });
  };

  const handleFieldMetaOnChange = (item: Item<BlockData>, value: any) => {
    apply((state) => {
      state.meta[item.path] = value;
    });
  };

  const handleRepeatingBranchOnCreate = (
    item: Item<ContainerRepeatingBranchData>,
  ) => {
    apply((state) => {
      if (!(item.parentId in values)) {
        state.values[item.parentId] = [generateId()];
        return;
      }

      state.values[item.parentId].push(generateId());
    });
  };

  const handleRepeatingBranchOnDelete = (
    item: Item<ContainerRepeatingBranchData>,
  ) => {
    apply((state) => {
      const index = state.values[item.parentId].indexOf(item.data.branchId);
      state.values[item.parentId].splice(index, 1);
    });
  };

  const handleObjectsOnCreate = (
    item: Item<BlockData>,
    newObjects: PlatformObject[],
  ) => {
    const serializedObjects = newObjects.map((object) => ({
      id: object.id,
      type: object.type,
      name: object.name,
      status: 'UPLOADING' as const,
      data: object.data,
    }));

    apply((state) => {
      state.localObjects.push(...newObjects);

      if (!(item.path in state.objects)) {
        state.objects[item.path] = serializedObjects;
        return;
      }

      state.objects[item.path].push(...serializedObjects);
    });
  };

  const handleObjectOnUploadProgress = (
    item: Item<BlockData>,
    objectId: PlatformObject['id'],
    progressPercentage: number,
  ) => {
    apply((state) => {
      const index = state.localObjects.map(({ id }) => id).indexOf(objectId);
      state.localObjects[index].progressPercentage = progressPercentage;
    });
  };

  const handleObjectOnUpload = (
    item: Item<BlockData>,
    newObjectId: PlatformObject['id'],
  ) => {
    apply((state) => {
      const index = state.objects[item.path]
        .map(({ id: objectId }) => objectId)
        .indexOf(newObjectId);
      state.objects[item.path][index].status = 'UPLOADED';
    });
  };

  const handleObjectOnDelete = (
    item: Item<BlockData>,
    object: PlatformObject,
  ) => {
    if (object.isNew || object.isPending) return;
    apply((state) => {
      const index = state.objects[item.path]
        .map(({ id: objectId }) => objectId)
        .indexOf(object.id);
      state.objects[item.path].splice(index, 1);
    });
  };

  const handleGetSubmission = () => {
    return JSON.parse(JSON.stringify({ values, objects }));
  };

  const handleOnSubmit = () => {
    onSubmit?.(values);
  };

  return {
    values,
    objects,
    localObjects,
    meta,
    handleFieldValueOnChange,
    handleFieldMetaOnChange,
    handleRepeatingBranchOnCreate,
    handleRepeatingBranchOnDelete,
    handleObjectsOnCreate,
    handleObjectOnUploadProgress,
    handleObjectOnUpload,
    handleObjectOnDelete,
    handleGetSubmission,
    handleOnSubmit,
    isCompleted: true,
    completionPercentage: 100,
  };
};
