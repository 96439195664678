import React from 'react';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { z } from 'zod';
import { useEstimatesCreate } from './hooks';

const EstimatesCreate = () => {
  const { sheet, handlers } = useEstimatesCreate();
  return (
    <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
      <SheetTrigger asChild>
        <Button className="h-7" size="sm" variant="ghost">
          <Plus className="mr-1 size-4" />
          Add estimate
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Create estimate</SheetTitle>
          <SheetDescription>
            Add a new estimate to this activity
          </SheetDescription>
        </SheetHeader>
        <div className="mt-4">
          <FormRenderer
            fields={[
              {
                name: 'name',
                label: 'Name',
                type: 'input',
                schema: z.string().min(2),
              },
            ]}
            onSubmit={handlers.onCreate}
          >
            {({ onSubmit }) => (
              <Button
                className="w-full"
                variant="outline"
                size="sm"
                onClick={onSubmit}
              >
                Create new estimate
              </Button>
            )}
          </FormRenderer>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default EstimatesCreate;
