import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { TrainingQualification } from 'lib/types';
import { useTrainingQualificationContext } from '../../../../context';
import {
  DeleteTrainingQualificationData,
  DeleteTrainingQualificationVariables,
} from '../../../../types';

export const useChildrenEditor = (child: TrainingQualification) => {
  const { qualification } = useTrainingQualificationContext();

  const [open, setOpen] = useState(false);

  const [update] = useMutation(
    gql`
      mutation UpdateTrainingQualificationChild(
        $id: ID!
        $data: TrainingQualificationInput!
      ) {
        updateTrainingQualification(
          trainingQualificationId: $id
          trainingQualificationData: $data
        ) {
          trainingQualification {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnUpdate = async (values: any) => {
    if (!qualification) return;

    const { data, errors } = await update({
      variables: {
        id: child.id,
        data: {
          name: values.name,
          description: values.description,
          color: qualification.color,
          logo: qualification.logo
            ? {
                id: qualification.logo?.id,
                type: qualification.logo?.type,
                name: qualification.logo?.name,
                size: qualification.logo?.size,
              }
            : null,
          data: child.data || {},
          fields: values.fields || [],
        },
      },
    });

    if (data) {
      await qualification.refetch();
      setOpen(false);
      toast.success('Qualification updated successfully');
    }

    if (errors) {
      toast.error('Failed to update qualification');
    }
  };

  const [delete_] = useMutation<
    DeleteTrainingQualificationData,
    DeleteTrainingQualificationVariables
  >(gql`
    mutation DeleteTrainingQualification($id: ID!) {
      deleteTrainingQualification(trainingQualificationId: $id) {
        deleted
      }
    }
  `);

  const handleOnDelete = async () => {
    if (!qualification || !child) return;

    const { data, errors } = await delete_({
      variables: {
        id: child.id,
      },
    });

    if (data) {
      await qualification.refetch();
      setOpen(false);
      toast.success('Qualification deleted successfully');
    }

    if (errors) {
      toast.error('Failed to delete qualification');
    }
  };

  return {
    state: {
      open,
      onOpenChange: setOpen,
      onOpen: () => setOpen(true),
      onClose: () => setOpen(false),
    },
    handlers: {
      onUpdate: handleOnUpdate,
      onDelete: handleOnDelete,
    },
  };
};
