import React from 'react';
import {
  CheckIcon,
  LockClosedIcon,
  LockOpen1Icon,
} from '@radix-ui/react-icons';
import { Clipboard, Hexagon, Loader2, XIcon } from 'lucide-react';
import { ErrorBoundary } from 'react-error-boundary';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { FormState } from 'lib/shared';
import { JobTaskForm } from 'lib/types';
import { useJobTaskContext } from '../../context';
import TaskForm from './components/TaskForm';
import TaskFormCompletion from './components/TaskFormCompletion';
import { TaskFormProps } from './types';

const TaskForms = ({ scope, onSelect }: TaskFormProps) => {
  const { task } = useJobTaskContext();

  if (!task) return null;
  if (!task.forms) return null;

  const forms = task.forms.filter((form) =>
    scope ? form.scope === scope : true,
  );

  if (!forms.length) {
    return (
      <Table className="h-full" wrapperClassname="h-full">
        <TableHeader className="border-t">
          <TableRow>
            <TableHead className="pl-4">Name</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Mandatory</TableHead>
            <TableHead>Approved</TableHead>
            <TableHead>Locked</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell className="h-full p-4" colSpan={5}>
              <div className="flex h-full w-full items-center justify-center rounded-md border border-dashed">
                <div>
                  <Hexagon className="dark:text-white-400 mx-auto mb-2 h-4 w-4 text-gray-400" />
                  <p className="dark:text-white-400 text-xs text-gray-400">
                    No forms
                  </p>
                </div>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
  return (
    <ScrollArea className="h-full">
      <Table>
        <TableHeader className="sticky top-0 z-[5] border-t bg-background">
          <TableRow>
            <TableHead className="pl-4">Name</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Mandatory</TableHead>
            <TableHead>Approved</TableHead>
            <TableHead>Locked</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {forms.map((form: JobTaskForm) => {
            return (
              <ErrorBoundary
                key={form.id}
                fallback={
                  <TableRow key={form.id}>
                    <TableCell colSpan={6}>
                      Error loading form ({form.template.name})
                    </TableCell>
                  </TableRow>
                }
              >
                <FormState
                  scope="jobs::job-task-form"
                  id={form.id}
                  loader={
                    <TableRow key={form.id} className="h-16">
                      <TableCell width={1} className="pl-4">
                        <Button size="sm" variant="outline" disabled>
                          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                          {form.template.name}
                        </Button>
                      </TableCell>
                    </TableRow>
                  }
                >
                  {({ values, meta }) => (
                    <TableRow key={form.id} className="h-16">
                      <TableCell width={1} className="pl-4">
                        {onSelect ? (
                          <Button
                            size="sm"
                            variant="outline"
                            onClick={() => onSelect(form)}
                          >
                            <Clipboard className="mr-2 h-4 w-4" />
                            {form.template.name}
                          </Button>
                        ) : (
                          <TaskForm form={form} />
                        )}
                      </TableCell>
                      <TableCell>
                        {meta.isCompleted ? (
                          <Badge
                            variant="outline"
                            className="pl-1 text-primary"
                          >
                            <CheckIcon className="mr-1" />
                            Completed
                          </Badge>
                        ) : (
                          <TaskFormCompletion
                            values={values}
                            form={form}
                            small
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center gap-1 whitespace-nowrap text-xs font-semibold">
                          {!meta.isLocked ? (
                            <LockClosedIcon />
                          ) : (
                            <LockOpen1Icon />
                          )}
                          <p>{!meta.isLocked ? 'Yes' : 'No'}</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        {meta.isApproved ? (
                          <Badge className="bg:primary">Approved</Badge>
                        ) : (
                          <div className="flex items-center gap-1 whitespace-nowrap text-xs font-semibold">
                            <XIcon size={16} />
                            <p>No</p>
                          </div>
                        )}
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center gap-1 whitespace-nowrap text-xs font-semibold">
                          {meta.isLocked ? (
                            <LockClosedIcon />
                          ) : (
                            <LockOpen1Icon />
                          )}
                          <p>{meta.isLocked ? 'Locked' : 'Not locked'}</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </FormState>
              </ErrorBoundary>
            );
          })}
        </TableBody>
      </Table>
    </ScrollArea>
  );
};

export { TaskFormCompletion };

export default TaskForms;
