import React from 'react';
import ReportSection from '../../../../../../../ReportSection';
import ReportText from '../../../../../../../ReportText';
import { FormFieldComponentProps } from '../../types';

const FieldChar = ({ item, value, media }: FormFieldComponentProps) => {
  return (
    <ReportSection
      title={item.data.value}
      subtitle={item.data.helpText?.value}
      media={media}
    >
      <ReportText style={{ fontSize: 12, padding: 12 }}>
        {value?.value}
      </ReportText>
    </ReportSection>
  );
};

export default FieldChar;
