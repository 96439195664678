import React from 'react';
import { cn } from '@/lib/utils';
import { ScopeLabel, StatusBadge } from 'lib/shared';
import { jobIssueStatusColors } from 'lib/theme';
import { JobIssue, JobIssueStatus } from 'lib/types';
import { NodeProps, NodeType } from '../../types';
import NodeBase from '../NodeBase';

const NodeJobIssue = ({ id, data }: NodeProps<JobIssue>) => {
  return (
    <NodeBase
      type={NodeType.Issue}
      id={id}
      to={`issues/${data.number}`}
      borderColor={data.status == JobIssueStatus.Open ? 'red' : 'transparent'}
    >
      <div className="rounded-md border-2 bg-background p-3">
        <div className="mb-2 flex h-6 justify-between">
          <p className="text-sm font-semibold text-gray-400">
            Issue{' '}
            <span className="text-xs text-gray-400">[#{data.number}]</span>
          </p>
          <StatusBadge status={data.status} colors={jobIssueStatusColors} />
        </div>
        <div>
          <p>
            <span
              className={cn({
                'font-semibold': data.status === JobIssueStatus.Open,
              })}
            >
              <ScopeLabel
                className="text-base lowercase"
                label={data.name}
                link={false}
              />
            </span>
          </p>
        </div>
      </div>
    </NodeBase>
  );
};

export default NodeJobIssue;
