import React from 'react';
import { ReportText, ReportView } from 'lib/shared';
import { FormFieldComponentProps } from '../../types';

const FieldNumber = ({ item, value, media }: FormFieldComponentProps) => {
  return (
    <ReportView>
      <ReportText>{item.data.value}</ReportText>
      <ReportText>{value?.value}</ReportText>
      {media}
    </ReportView>
  );
};

export default FieldNumber;
