import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useCompanyStaffContext } from '../../../../context';
import { useStaffMemberContext } from '../../context';

export const useMemberDetails = () => {
  const { refetch: refetchList } = useCompanyStaffContext();
  const { user } = useStaffMemberContext();

  const [open, setOpen] = useState(false);

  const [update] = useMutation(
    gql`
      mutation UpdateUser($id: ID!, $field: String!, $value: JSONScalarInput!) {
        updateUser(userId: $id, field: $field, value: $value) {
          user {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnSubmit = async (values: any) => {
    if (!user) {
      return;
    }

    const promises = [];

    if (values.name !== user.name) {
      promises.push(
        update({
          variables: {
            id: user.id,
            field: 'name',
            value: values.name,
          },
        }),
      );
    }

    if (values.jobTitle !== user.jobTitle) {
      promises.push(
        update({
          variables: {
            id: user.id,
            field: 'job_title',
            value: values.jobTitle,
          },
        }),
      );
    }

    await Promise.all(promises);
    await user.refetch();
    await refetchList();
    setOpen(false);
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onSubmit: handleOnSubmit,
    },
  };
};
