import React from 'react';
import { Text } from '@react-pdf/renderer';
import { DateTime } from 'luxon';
import VersionRenderer from './components/VersionRenderer';
import { JobReportVersionContext } from './context';
import { useJobReportVersion } from './hooks';

const ReportVersion = () => {
  const { context } = useJobReportVersion();
  if (!context.state.ready) return null;
  return (
    <JobReportVersionContext.Provider value={context}>
      <VersionRenderer />
    </JobReportVersionContext.Provider>
  );
};

export const footer = (
  <Text
    style={{
      position: 'absolute',
      bottom: 12,
      left: 36,
      fontSize: 10,
      fontWeight: 600,
      color: '#A19898',
    }}
    fixed
    render={() => <> {DateTime.now().toFormat('dd LLLL yyyy')}</>}
  />
);

export default ReportVersion;
