import React from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  files,
  textarea,
} from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { z } from 'zod';
import { generateId } from 'lib/utils';
import { NoteProps } from '../../types';

const Note = ({ scope, note, onSave, onDelete }: NoteProps) => {
  const defaultValues = note ? JSON.parse(JSON.stringify(note)) : undefined;
  return (
    <FormRenderer
      fields={[
        textarea({
          name: 'content',
          label: 'Content',
          description: 'The content of the note',
          defaultValue: defaultValues?.content,
          schema: z.string(),
        }),
        files({
          name: 'objects',
          label: 'Media',
          description: 'The media objects attached to the note',
          defaultValue: defaultValues?.objects ?? [],
          schema: z.any(),
          right: 500,
        }),
      ]}
      onSubmit={(values) => {
        onSave({
          id: note?.id ?? generateId(),
          scope: (note?.scope ?? scope) as string,
          content: values.content,
          objects: values.objects,
          deleted: false,
          history: note?.history ?? [],
        });
      }}
    >
      {({ onSubmit }) => (
        <div className="flex justify-between">
          <Button size="sm" onClick={onSubmit}>
            {note ? 'Save note' : 'Create note'}
          </Button>
          {note && onDelete && (
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="destructive" size="sm">
                  Delete note
                </Button>
              </DialogTrigger>
              <DialogContent overlay>
                <DialogHeader>
                  <DialogTitle>Delete Quote Line</DialogTitle>
                  <DialogDescription>
                    Are you sure you want to delete this cost line?
                  </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                  <DialogClose asChild>
                    <Button size="sm" variant="outline">
                      Cancel
                    </Button>
                  </DialogClose>
                  <Button
                    size="sm"
                    variant="destructive"
                    onClick={() => onDelete(note)}
                  >
                    Delete
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          )}
        </div>
      )}
    </FormRenderer>
  );
};

export default Note;
