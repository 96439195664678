import React from 'react';
import { ClockIcon, PackageCheckIcon } from 'lucide-react';
import { DateTime } from 'luxon';
import {
  JobTask,
  JobTimelineTargetAim,
  JobTimelineTargetAimCondition,
} from 'lib/types';

const TaskStartBy = ({ aim }: { aim: JobTimelineTargetAim }) => {
  return (
    <div className="flex items-center gap-x-1">
      <ClockIcon className="mr-0.5 h-4 w-4" />
      <p className="text-sm">
        Start by{' '}
        <strong>
          {DateTime.fromISO(aim.target.dateTime).toLocaleString(
            DateTime.DATETIME_MED,
          )}
        </strong>
      </p>
    </div>
  );
};

const TaskCompleteBy = ({ aim }: { aim: JobTimelineTargetAim }) => {
  return (
    <div className="flex items-center gap-x-1">
      <PackageCheckIcon className="mr-0.5 h-4 w-4" />
      <p className="text-sm">
        Complete by{' '}
        <strong>
          {DateTime.fromISO(aim.target.dateTime).toLocaleString(
            DateTime.DATETIME_MED,
          )}
        </strong>
      </p>
    </div>
  );
};

const TaskAims = ({ task }: { task: JobTask }) => {
  return (
    <div className="flex gap-x-4">
      {task.aims.map((aim) => {
        if (aim.condition === JobTimelineTargetAimCondition.StartBy) {
          return <TaskStartBy key={aim.id} aim={aim} />;
        }
        if (aim.condition === JobTimelineTargetAimCondition.CompleteBy) {
          return <TaskCompleteBy key={aim.id} aim={aim} />;
        }
        return null;
      })}
    </div>
  );
};

export default TaskAims;
