import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { Loader2 } from 'lucide-react';
import { cn } from '@/lib/utils';
import { HTMLMotionProps, motion } from 'framer-motion';

export const Image = ({
  opacity = 1,
  ...props
}: HTMLMotionProps<'img'> & { opacity?: number }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const timeoutRef = useRef<NodeJS.Timeout>();
  const [retryToken, setRetryToken] = useState(0);

  const handleOnError = useCallback(() => {
    timeoutRef.current = setTimeout(
      () => {
        setRetryToken((prevRetryToken) => {
          if (prevRetryToken > 10) {
            setError(true);
            return prevRetryToken;
          }

          return prevRetryToken + 1;
        });
      },
      Math.max(1000, 1000 + Math.random() * 1000),
    );
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  if (loading) {
    return (
      <div className={cn(props.className, 'flex items-center justify-center')}>
        <div className="h-4">
          <Loader2 className="mx-auto h-4 w-4 animate-spin" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div
        className={cn(
          props.className,
          'flex items-center justify-center rounded-md border',
        )}
      >
        <div className="h-4">
          <ExclamationTriangleIcon className="mx-auto h-4 w-4" />
        </div>
      </div>
    );
  }

  return (
    <motion.img
      key={retryToken}
      initial={{ opacity: 0 }}
      animate={{ opacity }}
      alt=""
      {...props}
      onLoadStart={() => setLoading(true)}
      onLoad={() => setLoading(false)}
      onError={handleOnError}
    />
  );
};
