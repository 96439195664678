import { createContext, useContext } from 'react';
import { useTimelineRenderer } from './hooks';

export const TimelineRendererContext = createContext(
  {} as ReturnType<typeof useTimelineRenderer>['context'],
);

export const useTimelineRendererContext = () => {
  return useContext(TimelineRendererContext);
};
