import { PlatformObject } from 'lib/types';

const platformObjectInput = (
  platformObject: PlatformObject | Omit<PlatformObject, 'sources'> | undefined,
): Omit<PlatformObject, 'sources'> | undefined =>
  platformObject
    ? {
        id: platformObject.id,
        name: platformObject.name,
        size: platformObject.size,
        type: platformObject.type,
        data: platformObject.data,
      }
    : undefined;

export default platformObjectInput;
