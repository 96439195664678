import React from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import { Check, CornerDownLeft, Paperclip } from 'lucide-react';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Placeholder } from '@tiptap/extension-placeholder';
import { StarterKit } from '@tiptap/starter-kit';
import { useUser } from 'lib/hooks';
import { JobIssueStatus } from 'lib/types';
import { Action, Actions } from '../../../../../../external';
import { useActionsContext } from '../../../../../../external/Actions/context';
import { useClient } from '../../../../../Client/hooks';
import { useJobContext } from '../../../../context';
import { useJobIssueContext } from '../../context';

const IssueInput = ({ onSend }: { onSend: any }) => {
  const user = useUser();
  const client = useClient();

  const { job } = useJobContext();
  const { issue } = useJobIssueContext();

  const editor = useEditor({
    extensions: [StarterKit, Placeholder.configure({ placeholder: 'Aa' })],
  });

  if (job.partial || !issue) return null;

  return (
    <>
      <div className="relative">
        <Actions
          actionSetId={issue.actionSetId}
          scope=""
          context={{
            event: {
              data: {
                user,
                client: {
                  id: client.id,
                  slug: client.slug,
                },
                job: {
                  id: job.id,
                  reference: job.reference,
                },
                job_issue: {
                  id: issue.id,
                  number: issue.number,
                  status: issue.status,
                },
                job_variation: {
                  id: job.variation?.id,
                },
              },
            },
          }}
          queryKey={['issue', 'actions', issue.id]}
          onSuccess={async () => {
            await Promise.all([
              client.refetch(),
              job.refetch(),
              issue.refetch(),
            ]);
          }}
        >
          <ActionsList />
        </Actions>
        {issue.closeOutData && (
          <div className="flex-start relative z-[2] ml-[13px] flex items-center gap-x-2 pt-8">
            <Avatar className="-ml-0.5 size-9 border-4 border-secondary text-xs">
              <AvatarFallback className="bg-background-secondary dark:bg-muted">
                <Check className="size-4" />
              </AvatarFallback>
            </Avatar>
            <p className="text-xs font-semibold dark:text-gray-400">
              Closed out
            </p>
          </div>
        )}
        <div className="z-1 absolute inset-y-0 left-7 w-0.5 bg-background-secondary dark:bg-accent" />
      </div>
      {issue.status === JobIssueStatus.Open && (
        <div className="flex min-h-[122px] bg-secondary">
          <div className="flex w-full flex-col rounded-lg border bg-background/50 p-4 focus-within:ring-1">
            <div className="flex grow">
              <Avatar className="size-6 text-xs">
                <AvatarFallback className="bg-background-secondary font-semibold dark:bg-muted">
                  {user?.initials?.[0]}
                </AvatarFallback>
              </Avatar>
              <div className="ml-3 mt-0.5 grow text-sm">
                <EditorContent editor={editor} />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex gap-x-2">
                <Button size="icon-sm" variant="secondary">
                  <Paperclip className="size-4" />
                </Button>
              </div>
              <div>
                <Button
                  size="sm"
                  variant={editor?.isEmpty ? 'secondary' : undefined}
                  onClick={() => {
                    if (!editor) return;
                    onSend(
                      editor.getJSON(),
                      editor.getHTML(),
                      editor.getText(),
                    );
                    editor.commands.clearContent();
                  }}
                >
                  Send Message
                  <CornerDownLeft className="ml-2 size-5" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ActionsList = () => {
  const { actionSet } = useActionsContext();
  if (!actionSet?.actions?.length) return null;
  return (
    <div className="relative py-8">
      <div className="mb-2 ml-11">
        <p className="text-xs font-semibold dark:text-gray-400">
          Close out actions
        </p>
      </div>
      <div className=" ml-[7px] flex flex-col gap-y-4">
        <Action
          id="create-task"
          small
          button={{
            className:
              'relative z-[2] rounded-full border-4 h-10 border-secondary',
          }}
        />
        <Action
          id="create-variation"
          small
          button={{
            className:
              'relative z-[2] rounded-full border-4 h-10 border-secondary',
          }}
        />
      </div>
    </div>
  );
};

export default IssueInput;
