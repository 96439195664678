import React from 'react';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Clipboard } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import JobTaskForm from '../../../../../../../../components/JobTaskForm';
import { useJobTaskContext } from '../../../../../../context';
import { useTaskFormContext } from '../../context';
import SubcontractorReport from './components/SubcontractorReport';
import SubcontractorReportList from './components/SubcontractorReportList';
import { useFormSubcontractor } from './hooks';

const FormSubcontractor = () => {
  const { task } = useJobTaskContext();
  const { form, state, handlers } = useTaskFormContext();

  const { active, onSelect, onClear } = useFormSubcontractor();

  if (!task) {
    return null;
  }

  return (
    <Dialog open={state.open} onOpenChange={state.onOpenChange}>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm" className="mr-4 font-bold">
          <Clipboard className="mr-2 h-4 w-4" />
          {form.template.name}
        </Button>
      </DialogTrigger>
      <DialogContent
        overlay
        hideClose
        className="flex h-full w-full max-w-none flex-col px-4 pb-4 pt-3.5"
        onCloseAutoFocus={(event) => event.preventDefault()}
      >
        <Button
          className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
          onClick={state.onClose}
        >
          <Cross2Icon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
        <DialogHeader>
          <DialogTitle className="text-md">{form.template.name}</DialogTitle>
          <DialogDescription className="text-xs"></DialogDescription>
        </DialogHeader>
        <div className="grid min-h-0 w-full grow grid-cols-12 gap-x-4">
          <div className="col-span-6 h-full min-h-0">
            <div className="flex h-full min-h-0 flex-col rounded-lg border bg-background">
              {active.duty && active.report ? (
                <SubcontractorReport
                  active={active}
                  onSelect={onSelect}
                  onClear={onClear}
                />
              ) : (
                <>
                  <div className="justify-content-between flex h-[61px] flex-shrink-0 items-center justify-between border-b px-4">
                    <h1 className="text-xl font-bold">Subcontractor Duties</h1>
                  </div>
                  <SubcontractorReportList onSelect={onSelect} />
                </>
              )}
            </div>
          </div>
          <div className="col-span-6 h-full min-h-0">
            <div className="h-full min-h-0 rounded-lg border bg-background">
              <JobTaskForm
                form={form}
                onApprove={handlers.onApprove}
                onEdit={handlers.onEdit}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FormSubcontractor;
