import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { DragHelper } from '@bryntum/core-thin';
import { DateTime, Duration } from 'luxon';
import {
  JobTask,
  Nullable,
  TimesheetActivity,
  TimesheetDutyCondition,
  TimesheetDutyStatus,
  User,
} from 'lib/types';
import { generateId } from 'lib/utils';
import {
  PreparedTimesheetDuty,
  SchedulerDuty,
  SchedulerResource,
  SchedulerResourceType,
  Update,
  UpdateType,
} from '../../types';
import {
  DragEndEvent,
  DragEvent,
  DragMe,
  DragProps,
  DragStartEvent,
} from './types';

export class Drag extends DragHelper {
  getDefaultDuration: () => Duration;

  getJobTaskById: (id: string) => JobTask;

  setIsDragging: (isDragging: boolean) => void;

  setActive: (active: Nullable<JobTask>) => void;

  onSuccess: (duty: PreparedTimesheetDuty, update: Update) => void;

  constructor({
    getDefaultDuration,
    getJobTaskById,
    setIsDragging,
    setActive,
    onSuccess,
    ...rest
  }: DragProps) {
    super(rest);
    this.getDefaultDuration = getDefaultDuration;
    this.getJobTaskById = getJobTaskById;
    this.setIsDragging = setIsDragging;
    this.setActive = setActive;
    this.onSuccess = onSuccess;
  }

  static get configurable() {
    return {
      cloneTarget: true,
      mode: 'translateXY',
      targetSelector: '.draggable',
      autoSizeClonedTarget: false,
      listeners: {
        dragstart: 'onTaskDragStart',
        drag: 'onTaskDrag',
        drop: 'onTaskDragEnd',
        abort: 'onTaskDragAbort',
      },
    };
  }

  createProxy(element: HTMLElement): HTMLElement {
    const me = this as unknown as DragMe;

    const id = element.dataset.id as string;
    const task = this.getJobTaskById(id);

    const proxy = document.createElement('div');
    const margin = me.schedule.resourceMargin as number;
    const height = me.schedule.rowHeight - 2 * margin;
    const width = me.schedule.timeAxisViewModel.getDistanceForDuration(
      this.getDefaultDuration().toMillis(),
    );

    proxy.style.cssText = '';
    proxy.classList.remove('b-grid-row');
    proxy.innerHTML = renderToStaticMarkup(
      <div
        style={{
          borderRadius: 4,
          paddingLeft: 8,
          backgroundColor: 'rgba(4, 204, 255, 0.5)',
          border: '1px solid rgba(4, 204, 255, 0.75)',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontSize: 12,
          height,
          width,
        }}
      >
        <div className="flex h-full flex-col justify-center">
          <p className="text-xs font-medium">{task.job.reference}</p>
          <p className="text-xs font-medium">
            {task.name} {task.number}
          </p>
        </div>
      </div>,
    );

    return proxy;
  }

  onTaskDragStart({ context }: DragStartEvent) {
    const me = this as unknown as DragMe;
    const id = context.grabbed.dataset.id as string;
    context.task = this.getJobTaskById(id);

    me.schedule.enableScrollingCloseToEdges(me.schedule.timeAxisSubGrid);
    this.setIsDragging(true);
    this.setActive(context.task);
  }

  onTaskDrag({ context }: DragEvent) {
    const me = this as unknown as DragMe;

    if (context.target) {
      context.resource = me.schedule.resolveResourceRecord(
        context.target,
      ) as SchedulerResource;
    }
  }

  onTaskDragAbort() {
    const me = this as unknown as DragMe;
    me.schedule.disableScrollingCloseToEdges(me.schedule.timeAxisSubGrid);

    this.setIsDragging(false);
    this.setActive(null);
  }

  onTaskDragEnd({ context }: DragEndEvent) {
    const me = this as unknown as DragMe;
    me.schedule.disableScrollingCloseToEdges(me.schedule.timeAxisSubGrid);

    this.setIsDragging(false);
    this.setActive(null);

    // To protect against users dropping the task outside
    // the scheduler or onto the grid, we check if the
    // context has a resource and if it is valid.
    if (!context.resource || !context.resource.type) return;

    if (context.valid && context.target) {
      const id = generateId();
      const dateTimeStartJS = me.schedule.getDateFromCoordinate(
        context.newX,
        'round',
        false,
      );

      const dateTimeStart = DateTime.fromJSDate(dateTimeStartJS);
      const dateTimeEnd = dateTimeStart.plus(this.getDefaultDuration());
      const duration = dateTimeEnd.diff(dateTimeStart);

      const user =
        context.resource.type === SchedulerResourceType.User
          ? (context.resource.getData('user') as User)
          : undefined;

      const subcontractor =
        context.resource.type === SchedulerResourceType.Subcontractor
          ? context.resource.getData('subcontractor')
          : undefined;

      const timesheetDuty = {
        status: TimesheetDutyStatus.Draft,
        id,
        isNew: true,
        number: 0,
        duration: 0,
        events: [],
        createdBy: null,
        activity: { jobTask: context.task } as TimesheetActivity,
        dateTimeCreated: '',
        dateTimeStart,
        dateTimeStartCondition: TimesheetDutyCondition.At,
        dateTimeEnd,
        dateTimeEndCondition: TimesheetDutyCondition.At,
        user: user ?? null,
        subcontractor: subcontractor ?? null,
      } as unknown as PreparedTimesheetDuty;

      me.schedule.suspendRefresh();
      me.schedule.eventStore.add({
        id,
        startDate: dateTimeStart.toJSDate(),
        endDate: dateTimeEnd.toJSDate(),
        resourceId: context.resource.id,
        timesheetDuty,
        isActive: true,
      } as Partial<SchedulerDuty>);

      me.schedule.resumeRefresh();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      me.schedule.refresh();

      const update: Update = {
        type: UpdateType.Create,
        duty: timesheetDuty,
        diff: {
          id: timesheetDuty.id,
          dateTimeStart,
          dateTimeEnd,
          user,
          subcontractor,
          interactions: [
            {
              id: generateId(),
              duration: duration.toMillis(),
            },
          ],
        },
      };

      this.onSuccess(timesheetDuty, update);
    }
  }
}
