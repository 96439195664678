import React, { useCallback } from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { OnDragEndResponder } from '@hello-pangea/dnd';
import chroma from 'chroma-js';
import { Choice, ChoiceSet } from 'external/FormTemplateEditor';
import { Nullable } from 'lib/types';
import { generateId } from 'lib/utils';
import { useFormTemplateContext } from '../../context';
import { useSyncedTransact } from '../../hooks';

export const useChoiceSetEditor = (
  choiceSet: Nullable<ChoiceSet>,
  onSave?: () => void,
) => {
  const { store } = useFormTemplateContext();
  const { choiceSets } = useSyncedStore(store);
  const transact = useSyncedTransact(store);

  const handleAddOnClick = useCallback(() => {
    if (!choiceSet) return;

    transact(() => {
      choiceSets[choiceSet.id].choices.push({
        id: generateId(),
        value: 'New choice',
        color: chroma.random().hex(),
      });
    });
  }, [choiceSet, choiceSets]);

  const handleDeleteOnClick = useCallback(
    (choice: Choice) => {
      return () => {
        if (!choiceSet) return;

        const index = choiceSets[choiceSet.id].choices
          .map(({ id }) => id)
          .indexOf(choice.id);
        choiceSets[choiceSet.id].choices.splice(index, 1);
      };
    },
    [choiceSet, choiceSets],
  );

  const handleOnDragEnd: OnDragEndResponder = useCallback(
    (result) => {
      if (!choiceSet) return;
      if (!result.destination) return;

      const moved = choiceSets[choiceSet.id].choices[result.source.index];
      const movedCopy = {
        id: moved.id,
        color: moved.color,
        value: moved.value,
      };

      transact(() => {
        choiceSets[choiceSet.id].choices.splice(result.source.index, 1);
        choiceSets[choiceSet.id].choices.splice(
          result.destination!.index,
          0,
          movedCopy,
        );
      });
    },
    [choiceSet, choiceSets],
  );

  const handleUpdate = useCallback(
    (choice: Choice, property: keyof Choice, value: string) => {
      if (!choiceSet) return;

      const index = choiceSets[choiceSet.id].choices
        .map(({ id }) => id)
        .indexOf(choice.id);
      choiceSets[choiceSet.id].choices[index][property] = value;
    },
    [choiceSet, choiceSets],
  );

  const handleValueOnChange = useCallback(
    (choice: Choice) => {
      return (event: React.ChangeEvent<HTMLInputElement>) => {
        handleUpdate(choice, 'value', event.target.value);
      };
    },
    [handleUpdate],
  );

  const handleMakeDynamicOnClick = useCallback(() => {
    if (!choiceSet) return;
    transact(() => {
      choiceSets[choiceSet.id].choices = [];
      choiceSets[choiceSet.id].dynamic = true;
      choiceSets[choiceSet.id].source = undefined;
      choiceSets[choiceSet.id].sourceConfig = undefined;
    });
  }, [choiceSet]);

  const handleOnSelectDynamicSource = useCallback(
    (source: string, defaultConfig = {}) => {
      return () => {
        if (!choiceSet) return;
        transact(() => {
          choiceSets[choiceSet.id].source = source;
          choiceSets[choiceSet.id].sourceConfig = defaultConfig;
        });
      };
    },
    [choiceSet],
  );

  const handleOnClearDynamicSource = useCallback(() => {
    if (!choiceSet) return;
    transact(() => {
      choiceSets[choiceSet.id].source = undefined;
      choiceSets[choiceSet.id].sourceConfig = undefined;
    });
  }, [choiceSet]);

  return {
    context: {
      store,
      choiceSet,
      handlers: {
        onAdd: handleAddOnClick,
        onDelete: handleDeleteOnClick,
        onDragEnd: handleOnDragEnd,
        onUpdate: handleUpdate,
        onValueChange: handleValueOnChange,
        onMakeDynamic: handleMakeDynamicOnClick,
        onSelectDynamicSource: handleOnSelectDynamicSource,
        onClearDynamicSource: handleOnClearDynamicSource,
        onSave,
      },
    },
  };
};
