import React, {
  FC,
  ReactHTMLElement,
  ReactNode,
  cloneElement,
  useState,
} from 'react';
import { ImageIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { input } from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import {
  FormRenderer,
  FormRendererProps,
} from '@/components/ui/form/form-renderer/FormRenderer';
import { Loader } from '@/components/ui/loading';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { cn } from '@/lib/utils';
import { z } from 'zod';
import { usePlatformObject } from 'lib/hooks';
import { PlatformObjectWithoutSources } from 'lib/types';
import { useSubcontractorContext } from '../../context';

const SubcontractorDetails = () => {
  const { subcontractor, handlers } = useSubcontractorContext();

  if (!subcontractor) {
    return null;
  }

  return (
    <div className="flex items-center gap-x-4 border-b bg-background-secondary px-4 py-8">
      <LogoSheet
        value={subcontractor.logo}
        onChange={(value) => handlers.onUpdate('logo', value)}
      />
      <div>
        <EditSheet
          fields={[
            input({
              name: 'name',
              label: 'Name',
              schema: z.string(),
              defaultValue: subcontractor.name,
            }),
          ]}
          onSubmit={(values) => handlers.onUpdate('name', values.name)}
        >
          {({ trigger }) => (
            <h1 className="text-lg font-semibold">
              {subcontractor.name}
              {trigger}
            </h1>
          )}
        </EditSheet>
        <EditSheet
          fields={[
            input({
              name: 'description',
              label: 'Description',
              schema: z.string(),
            }),
          ]}
          onSubmit={(values) =>
            handlers.onUpdate('description', values.description)
          }
        >
          {({ trigger }) => (
            <p className="text-sm dark:text-gray-400">
              {subcontractor.name}
              {trigger}
            </p>
          )}
        </EditSheet>
      </div>
    </div>
  );
};

const LogoSheet = ({
  value,
  onChange,
}: {
  value: PlatformObjectWithoutSources | undefined;
  onChange: (value: PlatformObjectWithoutSources | undefined) => Promise<any>;
}) => {
  const { isLoading, object } = usePlatformObject(value?.id);
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button
          variant={value?.id ? 'ghost' : 'outline'}
          className={cn('h-[56px]', {
            'p-2': value?.id,
          })}
        >
          {value?.id ? (
            <div className="w-[128px]">
              {isLoading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                <img
                  className="h-[40px]"
                  src={object?.getUrl}
                  alt="Client logo"
                />
              )}
            </div>
          ) : (
            <div>
              <ImageIcon className="h-6" />
            </div>
          )}
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader className="mb-4">
          <SheetTitle>{value?.id ? 'Change logo' : 'Add logo'}</SheetTitle>
          <SheetDescription>
            {value?.id
              ? 'Change the logo of the subcontractor'
              : 'Add a logo to the subcontractor'}
          </SheetDescription>
        </SheetHeader>
        <FormRenderer
          fields={[
            {
              type: 'file',
              name: 'file',
              label: 'File',
              schema: z.any(),
              description: 'Upload a new logo',
              right: 384,
            },
          ]}
          onSubmit={async (values) => {
            await onChange({
              id: values.file.id,
              type: values.file.type,
              name: values.file.name,
              size: values.file.size,
              data: values.file.data,
            });
          }}
        >
          {({ onSubmit }) => (
            <div>
              <Button className="w-full" size="sm" onClick={onSubmit}>
                {value?.id ? 'Change' : 'Set'}
              </Button>
            </div>
          )}
        </FormRenderer>
      </SheetContent>
    </Sheet>
  );
};

const EditSheet = ({
  children,
  fields,
  onSubmit,
}: FormRendererProps<any> & { children: FC<{ trigger: ReactNode }> }) => {
  const [open, setOpen] = useState(false);

  const handleOnSubmit = async (values: any, form: any) => {
    if (!onSubmit) return;
    await onSubmit(values, form);
    setOpen(false);
  };

  const parent = children({
    trigger: (
      <SheetTrigger asChild>
        <Button
          size="xs"
          className="absolute right-0 top-0 opacity-0 transition-all group-hover:opacity-100"
        >
          Edit
        </Button>
      </SheetTrigger>
    ),
  }) as ReactHTMLElement<any>;

  const parentCloned = cloneElement(parent, {
    className: cn(parent.props.className, 'group relative'),
  });

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      {parentCloned}
      <SheetContent>
        <FormRenderer fields={fields} onSubmit={handleOnSubmit} />
      </SheetContent>
    </Sheet>
  );
};

export default SubcontractorDetails;
