import React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import ViewerSearch from '../ViewerSearch';
import { ViewerToolbarProps } from './types';

const ViewerToolbar = ({ toolbar }: ViewerToolbarProps) => {
  const { Toolbar } = toolbar;
  return (
    <Toolbar>
      {({ GoToPreviousPage, GoToNextPage, Search }) => (
        <div className="flex items-center gap-x-4 border-b px-4 py-2">
          <div className="flex grow">
            <GoToPreviousPage>
              {({ isDisabled, onClick }) => (
                <Button
                  size="icon-sm"
                  variant="ghost"
                  disabled={isDisabled}
                  onClick={onClick}
                >
                  <ChevronUpIcon className="h-4 w-4" />
                </Button>
              )}
            </GoToPreviousPage>
            <GoToNextPage>
              {({ isDisabled, onClick }) => (
                <Button
                  size="icon-sm"
                  variant="ghost"
                  disabled={isDisabled}
                  onClick={onClick}
                >
                  <ChevronDownIcon className="h-4 w-4" />
                </Button>
              )}
            </GoToNextPage>
            <Search>{(props) => <ViewerSearch {...props} />}</Search>
          </div>
        </div>
      )}
    </Toolbar>
  );
};

export default ViewerToolbar;
