import React from 'react';
import { Actions } from 'external';
import { DateTime } from 'luxon';
import { useUser } from 'lib/hooks';
import { useClient } from '../../../../../Client/hooks';
import { useJobContext } from '../../../../context';
import { useJobTaskContext } from '../../context';

const TaskActions = () => {
  const user = useUser();
  const client = useClient();

  const { job } = useJobContext();
  const { task } = useJobTaskContext();

  const context =
    !job.partial && task
      ? {
          event: {
            data: {
              job: {
                id: job.id,
                reference: job.reference,
              },
              job_task: {
                id: task.id,
                name: task.name,
                can_delete: Boolean(task.canDelete),
                requires_completion_confirmation: Boolean(
                  task.requiresCompletionConfirmation,
                ),
              },
              user,
              client,
              issue_deadline: DateTime.now()
                .startOf('day')
                .plus({ day: 3 })
                .toUnixInteger(),
              progress_report_deadline: DateTime.now()
                .startOf('day')
                .plus({ day: 3 })
                .toUnixInteger(),
              final_report_deadline: DateTime.now()
                .startOf('day')
                .plus({ day: 3 })
                .toUnixInteger(),
              all_forms_approved: task.forms.every((form) => form.isApproved),
              job_report_selected: [
                task.id,
                ...(task.forms.map((form) => form.id) ?? []),
                ...(task.subcontractorReports.map((report) => report.id) ?? []),
              ],
            },
          },
        }
      : {};

  return (
    <Actions
      queryKey={[task?.id as string, 'overview']}
      actionSetId={task?.actionSetId as string}
      scope={`jobs::job-task::${task?.id}`}
      context={context}
      onSuccess={async () => {
        await Promise.all([client.refetch(), job.refetch(), task?.refetch()]);
      }}
    />
  );
};

export default TaskActions;
