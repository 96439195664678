import React from 'react';
import { flexRender } from '@tanstack/react-table';
import { ArrowUpIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { useJobsTable } from './hooks';

const JobsTable = () => {
  const { table } = useJobsTable();
  return (
    <div className="flex h-full min-h-0 w-full flex-col">
      <div className="border-b">
        {table.getHeaderGroups().map((headerGroup) => (
          <div key={headerGroup.id} className="grid grid-cols-12">
            {headerGroup.headers.map((header) => {
              const { span } = (header.column.columnDef.meta as any) || {
                meta: 1,
              };
              if (header.column.getCanSort()) {
                return (
                  <Button
                    key={header.id}
                    variant="ghost"
                    className={cn(
                      'flex justify-start gap-x-2 rounded-none bg-background-secondary px-4 py-2 text-sm font-semibold ring-1 ring-border',
                      `col-span-${span}`,
                    )}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    {header.column.getIsSorted() && (
                      <ArrowUpIcon
                        className={cn('h-4 w-4 transition-all', {
                          'rotate-180': header.column.getIsSorted() === 'desc',
                        })}
                      />
                    )}
                  </Button>
                );
              }
              return (
                <div
                  key={header.id}
                  className={cn(
                    'relative bg-background-secondary px-4 py-2 text-sm font-semibold ring-1 ring-border',
                    `col-span-${span}`,
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <ScrollArea className="min-h-0 grow">
        <div>
          {table.getRowModel().rows?.length
            ? table.getRowModel().rows.map((row) => (
                <div
                  key={row.id}
                  className="group relative grid h-[42px] grid-cols-12"
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => {
                    const { span, button } = (cell.column.columnDef
                      .meta as any) || {
                      meta: 1,
                      button: false,
                    };
                    return (
                      <div
                        key={cell.id}
                        className={cn(
                          'flex items-center bg-background ring-1 ring-border group-hover:bg-accent/25',
                          `col-span-${span}`,
                          { 'px-2 py-0': button, 'px-4 py-2': !button },
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </div>
                    );
                  })}
                </div>
              ))
            : null}
        </div>
      </ScrollArea>
      <div className="h-9 flex-shrink-0 border-t bg-background-secondary px-4"></div>
    </div>
  );
};

export default JobsTable;
