import { createContext, useContext } from 'react';
import { useTrainingQualifications } from './hooks';

export const TrainingQualificationsContext = createContext(
  {} as ReturnType<typeof useTrainingQualifications>['context'],
);

export const useTrainingQualificationsContext = () => {
  return useContext(TrainingQualificationsContext);
};
