import React from 'react';
import parse, { Element, domToReact } from 'html-react-parser';
import DOMPurify from 'dompurify';
import { DateTime } from 'luxon';
import { z } from 'zod';
import { Empty, useValue } from 'lib/shared';
import { FormFieldComponentProps } from '../../types';

const FormFieldSignature = ({ value }: FormFieldComponentProps) => {
  const [{ name, dateTime: dateTimeString, svg }, isValid] = useValue(
    value,
    z.object({
      name: z.string(),
      dateTime: z.string().datetime({ offset: true }),
      svg: z.string(),
    }),
  );

  if (!value) {
    return <Empty className="h-24">No signature</Empty>;
  }

  if (!isValid) {
    return <Empty className="h-24">Invalid signature</Empty>;
  }

  return <Signature signature={{ name, dateTime: dateTimeString, svg }} />;
};

const Signature = ({ signature }: any) => {
  const dateTime = signature.dateTime
    ? DateTime.fromISO(signature.dateTime)
    : null;

  const safe = DOMPurify.sanitize(signature.svg);
  const html = parse(safe, {
    replace: (node) => {
      if (!(node instanceof Element)) return;
      if (node.name === 'svg') {
        return (
          <svg
            {...node.attribs}
            width={undefined}
            height={undefined}
            fill={undefined}
            style={{ width: 200, height: 100 }}
          >
            {domToReact(node.children)}
          </svg>
        );
      }
      if (node.name === 'path') {
        return <path {...node.attribs} fill="black" />;
      }
      return node;
    },
  });

  return (
    <div className="rounded-lg border p-4">
      <div className="mb-2 fill-foreground stroke-1">{html}</div>
      <div>
        <p className="text-sm font-medium">{signature.name}</p>
        <p className="text-xs">
          {dateTime?.toLocaleString(DateTime.DATETIME_FULL)}
        </p>
      </div>
    </div>
  );
};

export default FormFieldSignature;
