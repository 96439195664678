import React, { CSSProperties } from 'react';
import { PageProps } from '@react-pdf/renderer';
import { PlatformObject, PlatformObjectSources } from 'lib/types';
import { ReportCache } from './context';

export type Size = number | 'auto';

export type Positioned = {
  position?: 'relative' | 'absolute';
  x?: Size;
  y?: Size;
};

export type Sized = {
  w?: Size;
  h?: Size;
};

export type PositionedAndSized<T> = Positioned & Sized & T;

export type ReportElement<D = any> = PositionedAndSized<{
  // Maps the components definition a React components
  // provided in the ReportLayoutRenderer's props.
  type: string;
  data: D & {
    break?: boolean;
    style?: CSSProperties;
  };
}>;

export type ReportBlockComponentProps<D = any> = ReportElement<D> & {
  context: any;
  getBlockComponent: (type: string) => ReportBlockComponent<D>;
};

export type ReportBlockComponent<D = any> = React.FC<
  ReportBlockComponentProps<D>
>;

export type ReportComponent = {
  style?: CSSProperties;
  break?: boolean;
  fragment?: boolean;
  elements: ReportElement[];
};

export type ReportBlock = PositionedAndSized<{
  id: string;
  break?: boolean;
  component: string;
}>;

export type ReportLayout = {
  pages: {
    id: string;
    style?: PageProps['style'];
    structure: ReportBlock[];
  }[];
  components: Record<string, ReportComponent>;
};

export enum ReportMode {
  Edit = 'EDIT',
  View = 'VIEW',
  Meta = 'META',
  PDF = 'PDF',
  Contents = 'CONTENTS',
}

export type ReportProps = {
  cache: ReportCache;
  template: ReportLayout;
  context: any;
  blocks?: Record<string, ReportBlockComponent>;
  images?: any[];
  documents?: any[];
  footer?: React.ReactNode;
} & (
  | {
      mode: ReportMode.Edit | ReportMode.View;
      registerDocument: (document: any) => void;
      registerImage: (image: any) => void;
      registeredObjects?: never;
      registeredObjectSources?: never;
    }
  | {
      mode: ReportMode.Contents;
      registerDocument?: never;
      registerImage?: never;
      registeredObjects?: never;
      registeredObjectSources?: never;
    }
  | {
      mode: ReportMode.PDF;
      registerDocument?: never;
      registerImage?: never;
      registeredObjects: Omit<PlatformObject, 'sources'>[];
      registeredObjectSources: {
        id: PlatformObject['id'];
        sources: PlatformObjectSources;
      }[];
    }
);
