import { createContext, useContext } from 'react';
import { useCompanyAccreditations } from './hooks';

export const CompanyAccreditationsContext = createContext(
  {} as ReturnType<typeof useCompanyAccreditations>['context'],
);

export const useCompanyAccreditationsContext = () => {
  return useContext(CompanyAccreditationsContext);
};
