import { PropsWithChildren } from 'react';
import { Location, SnakeCaseToCamelCase } from 'lib/types';

export type EstimateLocationVariables = {
  query?: string;
  point?: {
    latitude: number;
    longitude: number;
  };
};

export type EstimateLocationData = {
  estimateLocation: {
    places: any[];
    points: any[];
  };
};

export type Result = {
  id: string;
  location: {
    lng: number;
    lat: number;
  };
  viewport: Viewport;
  type: ResultType;
  data: any;
};

export enum ResultType {
  Place = 'PLACE',
  Point = 'POINT',
}

export type Viewport = {
  ne: {
    lng: number;
    lat: number;
  };
  sw: {
    lng: number;
    lat: number;
  };
};

export type LocationPickerProps = PropsWithChildren<{
  value: Location | undefined;
  onChange: (value: Location | undefined) => void;
}>;
