import React from 'react';
import { Report, ReportBlockComponentProps } from 'lib/shared';
import JobCommercial from './components/JobCommercial';
import JobDetails from './components/JobDetails';
import JobMap from './components/JobMap';

const BlockJob = ({ context }: ReportBlockComponentProps) => {
  return (
    <Report.View bookmark={{ title: 'Job' }}>
      <Report.Stack>
        <JobDetails context={context} />
        <JobMap context={context} />
      </Report.Stack>
      <JobCommercial context={context} />
    </Report.View>
  );
};

export default BlockJob;
