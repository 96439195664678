import { useNavigate } from 'react-router';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useClient } from '../../../../../../hooks';
import {
  CreateCommercialContractMutationData,
  CreateCommercialContractMutationVariables,
} from './types';

export const useContractsCreate = () => {
  const navigate = useNavigate();
  const client = useClient();

  const [create] = useMutation<
    CreateCommercialContractMutationData,
    CreateCommercialContractMutationVariables
  >(
    gql`
      mutation CreateCommercialContract(
        $clientId: ID!
        $name: String!
        $description: String!
        $dateStart: DateTime!
        $dateEnd: DateTime!
      ) {
        createCommercialContract(
          clientId: $clientId
          name: $name
          description: $description
          dateStart: $dateStart
          dateEnd: $dateEnd
        ) {
          commercialContract {
            id
            slug
            dateStart
            dateEnd
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (values: any) => {
    const { data, errors } = await create({
      variables: {
        clientId: client.id,
        name: values.name,
        description: values.description,
        dateStart: values.dateStart,
        dateEnd: values.dateEnd,
      },
    });

    if (data) {
      const { slug, dateStart, dateEnd } =
        data.createCommercialContract.commercialContract;
      navigate(client.paths.commercial.contract(slug, dateStart, dateEnd));
      toast.success('Contract created');
    }

    if (errors) {
      const message = 'Error creating contract';
      console.error(message, errors);
      toast.error(message);
    }
  };

  return {
    onCreate: handleOnCreate,
  };
};
