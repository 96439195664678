import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { useTrainingStaffMemberContext } from '../../../../context';
import MemberTrainingForm from '../../../MemberTrainingForm';

const TrainingCreate = () => {
  const { handlers } = useTrainingStaffMemberContext();
  const [open, setOpen] = useState(false);
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant="outline" size="sm">
          Add training record
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Create training</SheetTitle>
          <SheetDescription>
            Create a new training record for this user.
          </SheetDescription>
        </SheetHeader>
        <div className="mt-4">
          <MemberTrainingForm
            onSubmit={async (values) => {
              await handlers.onCreate(values);
              setOpen(false);
            }}
          >
            {({ form, onSubmit }) => (
              <div>
                <Button
                  disabled={!form.formState.isValid}
                  className="w-full"
                  size="sm"
                  variant="secondary"
                  onClick={onSubmit}
                >
                  Create training
                </Button>
              </div>
            )}
          </MemberTrainingForm>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default TrainingCreate;
