import React from 'react';

const SeriesCommercial = () => {
  return (
    <div className="flex h-[52px] flex-shrink-0 items-center justify-between border-b px-4 py-2">
      <h1 className="text-xl font-bold">Commercial</h1>
    </div>
  );
};

export default SeriesCommercial;
