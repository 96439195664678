import { useMatch } from 'react-router';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { DateTime, Interval } from 'luxon';
import { User } from 'lib/types';
import { useStore } from './state';
import {
  InitialQueryData,
  InitialQueryVariables,
  RosterDutiesQueryData,
  RosterDutiesQueryVariables,
} from './types';

export const useTimeInterval = () => {
  const match = useMatch('hr/rosters/:team/:range');

  const [dateStartMillis, dateEndMillis] = match
    ? (match.params.range as string).split(encodeURIComponent('::'))
    : ['', ''];

  const dateTimeStart = DateTime.fromMillis(parseInt(dateStartMillis));
  const dateTimeEnd = DateTime.fromMillis(parseInt(dateEndMillis));

  return {
    dateTimeStart,
    dateTimeEnd,
    interval: Interval.fromDateTimes(dateTimeStart, dateTimeEnd),
  };
};

export const useInitialQuery = () => {
  const { dateTimeStart, dateTimeEnd } = useTimeInterval();
  const initialQueryOnCompleted = useStore(
    (state) => state.initialQueryOnCompleted,
  );

  const match = useMatch('hr/rosters/:team/:range');

  return useQuery<InitialQueryData, InitialQueryVariables>(
    gql`
      query (
        $rosterTeamId: ID!
        $dateTimeStartMonth: DateTime!
        $dateTimeEndMonth: DateTime!
      ) {
        rosterTeam(rosterTeamId: $rosterTeamId) {
          id
          name
          defaults
          membersGroup {
            id
            users {
              id
              name
              email
              initials
            }
          }
        }

        rosterDutiesInMonth: rosterDuties(
          stage: 0
          dateTimeStart: $dateTimeStartMonth
          dateTimeEnd: $dateTimeEndMonth
        ) {
          id
          dateTimeStart
          dateTimeEnd
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      variables: {
        rosterTeamId: match?.params?.team as string,
        dateTimeStartMonth: dateTimeStart.startOf('month'),
        dateTimeEndMonth:
          dateTimeStart.endOf('month') >
          dateTimeStart.endOf('month').endOf('week')
            ? dateTimeStart.endOf('month')
            : dateTimeStart.endOf('month').endOf('week'),
      },
      onCompleted: (data) => {
        initialQueryOnCompleted(dateTimeStart, dateTimeEnd, data);
      },
    },
  );
};

export const useGetRosterDuties = () => {
  const [getRosterDuties, { loading: isLoading, variables }] = useLazyQuery<
    RosterDutiesQueryData,
    RosterDutiesQueryVariables
  >(
    gql`
      query (
        $stage: Int!
        $dateTimeStart: DateTime!
        $dateTimeEnd: DateTime!
        $userId: ID!
      ) {
        rosterDuties(
          stage: $stage
          dateTimeStart: $dateTimeStart
          dateTimeEnd: $dateTimeEnd
          userId: $userId
        ) {
          id
          commuteTime
          dateTimeStart
          dateTimeEnd
          shiftDuration
          shiftType {
            label
            value
          }
          isPristine
          isDeleted
          status
          statusDisplay
          fatigueIndex
          riskIndex
          settings
          isSettingsPristine
          user {
            id
            name
            initials
          }
          team {
            id
            name
          }
        }

        rosterDutiesInWeek: rosterDuties(
          stage: 1
          dateTimeStart: $dateTimeStart
          dateTimeEnd: $dateTimeEnd
        ) {
          id
          dateTimeStart
          dateTimeEnd
          status
          team {
            id
          }
          user {
            id
          }
        }

        rosterDutiesInMonth: rosterDuties(
          stage: 1
          dateTimeStart: $dateTimeStart
          dateTimeEnd: $dateTimeEnd
        ) {
          id
          dateTimeStart
          dateTimeEnd
        }

        rosterValidationResults(
          rangeDateTimeStart: $dateTimeStart
          rangeDateTimeEnd: $dateTimeEnd
          stage: $stage
          userId: $userId
        ) {
          fatigueIndices
          riskIndices
          exceedances {
            duty {
              id
              dateTimeStart
              dateTimeEnd
              user {
                id
              }
              shiftType {
                value
              }
            }
            offence
            offenceValueType
            offenceValue
            review {
              id
              type
              note
              files {
                id
                name
                type
                size
                data
              }
            }
          }
        }
      }
    `,
    { fetchPolicy: 'no-cache' },
  );

  return { getRosterDuties, isLoading, variables };
};

export const useUserOnChange = () => {
  const { stage, dateTimeStart, dateTimeEnd, startLoading, userOnChange } =
    useStore((state) => ({
      stage: state.stage,
      dateTimeStart: state.dateTimeStart,
      dateTimeEnd: state.dateTimeEnd,
      startLoading: state.startLoading,
      userOnChange: state.userOnChange,
    }));

  const { getRosterDuties, isLoading, variables } = useGetRosterDuties();

  return (selectedUser: User) => {
    const handleOnClick = async () => {
      startLoading('table');
      const { data } = await getRosterDuties({
        variables: {
          stage,
          dateTimeStart: dateTimeStart,
          dateTimeEnd: dateTimeEnd,
          userId: selectedUser.id,
          getRosterReview: false,
        },
      });
      if (data) {
        userOnChange(selectedUser, data);
      }
    };

    return {
      onClick: handleOnClick,
      isLoading: isLoading && variables?.userId === selectedUser.id,
    };
  };
};

export const useTimeIntervalOnChange = () => {
  const { stage, user, startLoading, timeIntervalOnChange } = useStore(
    (state) => ({
      stage: state.stage,
      user: state.user,
      startLoading: state.startLoading,
      timeIntervalOnChange: state.timeIntervalOnChange,
    }),
  );

  const { getRosterDuties } = useGetRosterDuties();

  return async (dateTimeStart: DateTime, dateTimeEnd: DateTime) => {
    if (!user) return;

    startLoading('table');
    const { data } = await getRosterDuties({
      variables: {
        stage,
        dateTimeStart: dateTimeStart,
        dateTimeEnd: dateTimeEnd,
        userId: user.id,
        getRosterReview: true,
      },
    });

    if (data) timeIntervalOnChange(dateTimeStart, dateTimeEnd, data);
  };
};
