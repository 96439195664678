import { useMemo } from 'react';
import { SubcontractorRequirement } from 'lib/types';
import { useSubcontractorContext } from '../../../context';

export const usePolicyRequirement = (requirement: SubcontractorRequirement) => {
  const { subcontractor } = useSubcontractorContext();

  const policy = useMemo(() => {
    if (!subcontractor.policies) {
      return undefined;
    }
    return subcontractor.policies.find(
      (policy) => policy.requirement?.id === requirement.id,
    );
  }, [requirement, subcontractor.policies]);

  return {
    policy,
  };
};
