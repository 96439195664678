import React from 'react';
import { Loader } from '@/components/ui/loading';
import { ScrollArea } from '@/components/ui/scroll-area';
import ContractAccounting from './components/ContractAccounting';
import ContractCopy from './components/ContractCopy';
import ContractDelete from './components/ContractDelete';
import ContractDetails from './components/ContractDetails';
import ContractPrelims from './components/ContractPrelims';
import ContractRates from './components/ContractRates';
import { CommercialContractContext } from './context';
import { useCommercialContract } from './hooks';

const CommercialContract = () => {
  const { contract } = useCommercialContract();
  return (
    <CommercialContractContext.Provider value={{ contract }}>
      <div className="flex h-[52px] flex-shrink-0 items-center justify-between border-b px-4 py-2">
        {contract ? (
          <h1 className="text-xl font-bold">{contract?.name}</h1>
        ) : (
          <Loader />
        )}
        <div className="flex gap-x-2">
          <ContractCopy />
          <ContractDelete />
        </div>
      </div>
      <ScrollArea>
        <div className="px-4 pt-4">
          <div className="mb-4 grid grid-cols-12 gap-x-4">
            <div className="col-span-4">
              <ContractDetails />
            </div>
            <div className="col-span-8">
              <ContractAccounting />
            </div>
          </div>
          <ContractPrelims />
          <ContractRates />
        </div>
      </ScrollArea>
    </CommercialContractContext.Provider>
  );
};

export default CommercialContract;
