import React from 'react';
import { Button } from '@/components/ui/button';
import { useWindowManager } from '../../hooks';

const WindowManagerClose = () => {
  const { close } = useWindowManager();
  return <Button onClick={close}>Close</Button>;
};

export default WindowManagerClose;
