import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { CompanyInsurance } from 'lib/types';
import { useCompanyInsurancesContext } from '../../context';
import {
  DeleteCompanyInsuranceMutationData,
  DeleteCompanyInsuranceMutationVariables,
  UpdateCompanyInsuranceMutationData,
  UpdateCompanyInsuranceMutationVariables,
} from './types';

export const useInsurancesEditor = (insurance: CompanyInsurance) => {
  const { refetch } = useCompanyInsurancesContext();

  const [open, setOpen] = useState(false);

  const [update] = useMutation<
    UpdateCompanyInsuranceMutationData,
    UpdateCompanyInsuranceMutationVariables
  >(
    gql`
      mutation UpdateCompanyInsurance($id: ID!, $data: CompanyInsuranceData!) {
        updateCompanyInsurance(
          companyInsuranceId: $id
          companyInsuranceData: $data
        ) {
          companyInsurance {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnUpdate = async (values: any) => {
    const { data, errors } = await update({
      variables: {
        id: insurance.id,
        data: {
          coverName: values.coverName,
          issuer: values.issuer,
          policyNumber: values.policyNumber,
          dateTimeCoverStart: values.dateTimeCoverStart,
          dateTimeCoverEnd: values.dateTimeCoverEnd,
          indemnityLimit: values.indemnityLimit,
          notes: values.notes,
          document: values.document
            ? {
                id: values.document.id,
                type: values.document.type,
                name: values.document.name,
                size: values.document.size,
                data: values.document.data,
              }
            : undefined,
        },
      },
    });

    if (data) {
      await refetch();
      setOpen(false);
      toast.success('Company insurance created');
    }

    if (errors) {
      toast.error('Failed to create company insurance');
    }
  };

  const [delete_] = useMutation<
    DeleteCompanyInsuranceMutationData,
    DeleteCompanyInsuranceMutationVariables
  >(
    gql`
      mutation DeleteCompanyInsurance($id: ID!) {
        deleteCompanyInsurance(companyInsuranceId: $id) {
          deleted
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnDelete = async () => {
    const { data, errors } = await delete_({
      variables: {
        id: insurance.id,
      },
    });

    if (data) {
      await refetch();
      setOpen(false);
      toast.success('Company insurance deleted');
    }

    if (errors) {
      toast.error('Failed to delete company insurance');
    }
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onUpdate: handleOnUpdate,
      onDelete: handleOnDelete,
    },
  };
};
