import { createContext, useContext } from 'react';
import { useTrainingQualification } from './hooks';

export const TrainingQualificationContext = createContext(
  {} as ReturnType<typeof useTrainingQualification>['context'],
);

export const useTrainingQualificationContext = () => {
  return useContext(TrainingQualificationContext);
};
