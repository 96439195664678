import { gql } from '@apollo/client';

export const GET_JOB_TASK_QUERY = gql`
  query GetJob($jobId: ID!, $jobTaskNumber: Int!) {
    job(jobId: $jobId) {
      id
      task(jobTaskNumber: $jobTaskNumber) {
        job {
          id
          reference
          client {
            id
            slug
          }
        }
        timesheetActivity {
          id
          duties {
            id
            dateTimeCreated
            dateTimeStart
            dateTimeStartCondition
            dateTimeEnd
            dateTimeEndCondition
            duration
            createdBy {
              id
              name
            }
            events {
              id
              dateTimeStart
              dateTimeStartType
              dateTimeEnd
              dateTimeEndType
              duration
            }
            status
            user {
              id
              name
            }
            subcontractor {
              id
              name
            }
          }
          subcontractorDuties {
            id
            dateTimeStart
            dateTimeEnd
            subcontractor {
              id
              name
            }
            reports {
              id
              status
              statusDisplay
              sourceObject {
                id
                name
              }
            }
          }
        }
        id
        actionSetId
        name
        number
        description
        requiresCompletionConfirmation
        canDelete
        deleted
        status
        viewDateTimeStart
        viewDateTimeEnd
        aims {
          id
          status
          condition
          target {
            id
            type
            typeDisplay
            dateTime
          }
        }
        forms {
          id
          isRequired
          isCompleted
          isApproved
          isLocked
          scope
          template {
            id
            name
          }
          submissions {
            id
            number
            dateTimeCreated
            data
          }
        }
        subcontractorReports {
          id
          status
          statusDisplay
          sourceObject {
            id
            name
          }
        }
        notes {
          id
          dateTime
          data
          user {
            id
            name
            initials
            email
          }
        }
        plannedDateTimeOn
        plannedDateTimeOff
        dateTimeOn
        dateTimeOff
      }
    }
  }
`;
