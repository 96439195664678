import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import WindowManagerRouter from '../../shared/WindowManager/components/WindowManagerRouter';
import JobHeader from './components/JobHeader';
import { JobContext } from './context';
import { useJob } from './hooks';
import JobCommercial from './pages/JobCommercial';
import JobInstantiation from './pages/JobInstantiation';
import JobIssue from './pages/JobIssue';
import JobLocation from './pages/JobLocation';
import JobOverview from './pages/JobOverview';
import JobReport from './pages/JobReport';
import JobSettings from './pages/JobSettings';
import JobTask from './pages/JobTask';
import JobTaskAssignment from './pages/JobTaskAssignment';
import JobTimeline from './pages/JobTimeline';
import JobVariationInstantiation from './pages/JobVariationInstantiation';
import {
  ClientJobElementProps,
  ClientJobProps,
  ClientJobWindowedRendererProps,
} from './types';

export const ClientJob = ({ id, small = false }: ClientJobProps) => {
  if (id) {
    return <ClientJobWindowed id={id} small={small} />;
  }

  const inner = (
    <>
      <Route index element={<JobOverview />} />
      <Route path="location/*" element={<JobLocation />} />
      <Route path="settings/*" element={<JobSettings />} />
      <Route path="commercial/*" element={<JobCommercial />} />
      <Route path="tasks/:number" element={<JobTask />} />
      <Route path="tasks/:number/assign" element={<JobTaskAssignment />} />
      <Route path="issues/:number/*" element={<JobIssue />} />
      <Route path="reports/:number/*" element={<JobReport />} />
    </>
  );

  return (
    <Routes>
      <Route path="/*" element={<ClientJobElement small={small} />}>
        {inner}
      </Route>
      <Route
        path="/:variation/*"
        element={<ClientJobElement small={small} variation />}
      >
        {inner}
      </Route>
    </Routes>
  );
};

export const ClientJobElement = ({
  small = false,
  variation = false,
}: ClientJobElementProps) => {
  const { context } = useJob({ small, variation });

  if (context.job.partial) {
    return null;
  }

  if (!context.job.isInstantiated) {
    return (
      <JobContext.Provider value={context}>
        <JobInstantiation />
      </JobContext.Provider>
    );
  }

  if (context.job.variation && !context.job.variation.isInstantiated) {
    return (
      <JobContext.Provider value={context}>
        <JobHeader />
        <JobVariationInstantiation />
      </JobContext.Provider>
    );
  }

  return (
    <JobContext.Provider value={context}>
      <JobHeader />
      <Outlet />
    </JobContext.Provider>
  );
};

const ClientJobWindowed = ({
  id,
  small,
}: ClientJobElementProps & { id: string }) => {
  return (
    <WindowManagerRouter routes={routes}>
      {({ match, route }) => (
        <ClientJobWindowedRenderer
          id={id}
          small={small}
          route={route}
          variation={match.pattern.path.includes(':variation')}
        />
      )}
    </WindowManagerRouter>
  );
};

const ClientJobWindowedRenderer = ({
  id,
  small = false,
  variation = false,
  route,
}: ClientJobWindowedRendererProps) => {
  const { context } = useJob({ id, small, variation });

  if (context.job.partial) {
    return null;
  }

  if (!context.job.isInstantiated) {
    return (
      <JobContext.Provider value={context}>
        <JobInstantiation />
      </JobContext.Provider>
    );
  }

  if (context.job.variation && !context.job.variation.isInstantiated) {
    return (
      <JobContext.Provider value={context}>
        <JobHeader />
        <JobVariationInstantiation />
      </JobContext.Provider>
    );
  }

  return (
    <JobContext.Provider value={context}>
      <JobHeader />
      {route}
    </JobContext.Provider>
  );
};

const routes = [
  {
    path: '/clients/:clientSlug/jobs/:jobReference',
    component: <JobOverview />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/tasks/:jobTaskNumber',
    component: <JobTask />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/tasks/:jobTaskNumber/assign',
    component: <JobTaskAssignment />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/issues/:jobIssueNumber/*',
    component: <JobIssue />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/reports/:jobReportNumber',
    component: <JobReport />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/commercial',
    component: <JobCommercial />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/timeline',
    component: <JobTimeline />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/location',
    component: <JobLocation />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/settings/*',
    component: <JobSettings />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/:variation',
    component: <JobOverview />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/:variation/tasks/:jobTaskNumber',
    component: <JobTask />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/:variation/tasks/:jobTaskNumber/assign/',
    component: <JobTaskAssignment />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/:variation/issues/:jobIssueNumber/*',
    component: <JobIssue />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/:variation/reports/:jobReportNumber',
    component: <JobReport />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/:variation/commercial',
    component: <JobCommercial />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/:variation/timeline',
    component: <JobTimeline />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/:variation/location',
    component: <JobLocation />,
  },
  {
    path: '/clients/:clientSlug/jobs/:jobReference/:variation/settings/*',
    component: <JobSettings />,
  },
];

export default ClientJob;
