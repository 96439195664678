import React from 'react';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import chroma from 'chroma-js';
import { jobReportStatusColors } from 'lib/theme';
import { JobReport, JobReportStatus } from 'lib/types';
import { NodeProps, NodeType } from '../../types';
import NodeBase from '../NodeBase';

const NodeJobReport = ({ id, data }: NodeProps<JobReport>) => {
  const color = chroma(jobReportStatusColors[data.status]);
  return (
    <NodeBase
      type={NodeType.Report}
      id={id}
      to={`reports/${data.number}`}
      borderColor={data.status == JobReportStatus.Open ? 'red' : 'transparent'}
    >
      <div className="rounded-md border-2 bg-background p-3">
        <div className="mb-2 flex h-6 justify-between">
          <p className="text-sm font-semibold text-gray-400">Report</p>
          <Badge
            className="rounded-md font-semibold capitalize"
            style={{
              color: color.css(),
              border: `1px solid ${color.alpha(0.5).css()}`,
              backgroundColor: color.alpha(0.1).css(),
            }}
          >
            {data.status.toLowerCase()}
          </Badge>
        </div>
        <div>
          <p>
            <span
              className={cn({
                'font-semibold': data.status === JobReportStatus.Open,
              })}
            >
              {data.name}{' '}
            </span>
            <span className="text-xs text-gray-400">[#{data.number}]</span>
          </p>
        </div>
      </div>
    </NodeBase>
  );
};

export default NodeJobReport;
