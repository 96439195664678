import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from '@/components/ui/button';
import { DateTime } from 'luxon';
import { useSchedulerContext } from '../../../../hooks';
import { useSchedulerContentContext } from '../../hooks';
import { ContentEventTooltipProps } from './types';

const ContentEventTooltip = ({ eventRecord }: ContentEventTooltipProps) => {
  const update = useSchedulerContext().updates.get(eventRecord.id);
  const { onEventDelete, onEventRevert } = useSchedulerContentContext();

  if (!eventRecord.timesheetDuty) return null;

  const { job, jobTask, isNew } = eventRecord.timesheetDuty;

  const dateTimeStart = DateTime.fromJSDate(eventRecord.startDate as Date);
  const dateTimeEnd = DateTime.fromJSDate(eventRecord.endDate as Date);

  if (isNew) {
    if (!dateTimeStart || !dateTimeEnd) return null;
    return (
      <div className="w-[400px] rounded-lg border bg-background">
        <div className="p-4">
          <p className="text-sm font-medium">New timesheet duty</p>
        </div>
        <div className="flex flex-col gap-y-1 p-4">
          <div className="flex justify-between">
            <p className="text-xs font-semibold uppercase tracking-wide text-gray-400 dark:text-gray-500">
              Start
            </p>
            <p className="text-end text-xs font-semibold uppercase tracking-wide text-gray-400 dark:text-gray-500">
              End
            </p>
          </div>
          <div className="flex justify-between">
            <p className="font-medium">
              {dateTimeStart?.toLocaleString(DateTime.TIME_24_SIMPLE)}
            </p>
            {dateTimeStart && dateTimeEnd && (
              <div className="relative grow">
                <div className="absolute inset-x-2 top-1/2 h-[1px] -translate-y-1/2 rounded-full bg-gray-400" />
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-background px-2">
                  <p className="text-sm">
                    {dateTimeEnd.diff(dateTimeStart, 'minutes').minutes} mins
                  </p>
                </div>
              </div>
            )}
            <p className="font-medium">
              {dateTimeEnd?.toLocaleString(DateTime.TIME_24_SIMPLE)}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm">
              {dateTimeStart?.toLocaleString(DateTime.DATE_MED)}
            </p>
            <p className="text-sm">
              {dateTimeEnd?.toLocaleString(DateTime.DATE_MED)}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between border-t p-4">
          <div>
            <p className="text-sm">Actions:</p>
          </div>
          <div className="flex gap-x-2">
            <Button
              size="sm"
              variant="outline"
              onClick={() => onEventDelete({ eventRecord })}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (!job || !jobTask || !dateTimeStart || !dateTimeEnd) return null;

  return (
    <ErrorBoundary fallback={<div>Failed to render event tooltip</div>}>
      <div className="w-[400px] rounded-lg border bg-background">
        <div className="border-b p-4">
          <p className="font-semibold" style={{ fontFamily: 'Roboto Mono' }}>
            {job.reference}
          </p>
          <p>{jobTask.name}</p>
        </div>
        <div className="flex flex-col gap-y-1 p-4">
          <div className="flex justify-between">
            <p className="text-xs font-semibold uppercase tracking-wide text-gray-400 dark:text-gray-500">
              Start
            </p>
            <p className="text-end text-xs font-semibold uppercase tracking-wide text-gray-400 dark:text-gray-500">
              End
            </p>
          </div>
          <div className="flex justify-between">
            <p className="font-medium">
              {dateTimeStart?.toLocaleString(DateTime.TIME_24_SIMPLE)}
            </p>
            {dateTimeStart && dateTimeEnd && (
              <div className="relative grow">
                <div className="absolute inset-x-2 top-1/2 h-[1px] -translate-y-1/2 rounded-full bg-gray-400" />
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-background px-2">
                  <p className="text-sm">
                    {dateTimeEnd.diff(dateTimeStart, 'minutes').minutes} mins
                  </p>
                </div>
              </div>
            )}
            <p className="font-medium">
              {dateTimeEnd?.toLocaleString(DateTime.TIME_24_SIMPLE)}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm">
              {dateTimeStart?.toLocaleString(DateTime.DATE_MED)}
            </p>
            <p className="text-sm">
              {dateTimeEnd?.toLocaleString(DateTime.DATE_MED)}
            </p>
          </div>
        </div>
        {(eventRecord.timesheetDuty?.jobInteractions?.length ?? 0) > 0 && (
          <div>
            {eventRecord.timesheetDuty.jobInteractions.map((interaction) => (
              <div key={interaction.id}>
                {interaction.id}

                <p>{interaction.commercialContractRate.name}</p>
              </div>
            ))}
          </div>
        )}
        {!eventRecord.readOnly && (
          <div className="flex items-center justify-between border-t p-4">
            <div>
              <p className="text-sm">Actions:</p>
            </div>
            <div className="flex gap-x-2">
              {update && (
                <Button
                  size="sm"
                  variant="outline"
                  onClick={() => onEventRevert({ eventRecord })}
                >
                  Revert
                </Button>
              )}
              <Button
                size="sm"
                variant="outline"
                onClick={() => onEventDelete({ eventRecord })}
              >
                Delete
              </Button>
            </div>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default ContentEventTooltip;
