import React, { Fragment } from 'react';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { ArrowDown } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Loader } from '@/components/ui/loading';
import { useActionContext } from '../../context';
import { ActionStatus } from '../../types';
import { flatten } from '../../utils';
import { parse, renderNodeDescription } from './utils';

const ActionDryrunPending = () => {
  return (
    <div>
      <div className="flex h-[200px] w-full items-center justify-center rounded-md border border-dashed">
        <div>
          <Loader className="mx-auto mb-2 h-4 w-4 text-gray-400" />
          <p className="dark:text-white-400 text-xs text-gray-400">
            Preparing action...
          </p>
        </div>
      </div>
    </div>
  );
};

const ActionDryrunSuccess = () => {
  const { state, handlers } = useActionContext();
  if (!state.representation) return null;
  return (
    <>
      <div className="flex flex-col items-center gap-y-2 rounded-md border bg-secondary p-4 bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-background">
        <Badge variant="warning">Start</Badge>
        <ArrowDown className="h-4 w-4 text-gray-500 dark:text-gray-400" />
        {state.representation.nodes
          .flatMap(flatten)
          .filter((node) => !!node.label || !!node.description)
          .map((node, index, nodes) => {
            return (
              <Fragment key={node.id}>
                <div className="rounded border border-background bg-background/75 p-4 text-center">
                  <p className="dark-text-gray-400 mb-2 text-sm font-semibold">
                    {parse(node.label)}
                  </p>
                  {node.description && renderNodeDescription(node.description)}
                </div>
                {index !== nodes.length - 1 && (
                  <ArrowDown className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                )}
              </Fragment>
            );
          })}
        <ArrowDown className="h-4 w-4 text-gray-500 dark:text-gray-400" />
        <Badge variant="success">Complete</Badge>
      </div>
      <Button
        isLoading={state.status === ActionStatus.DryrunPending}
        disabled={state.status === ActionStatus.DryrunPending}
        size="sm"
        variant="outline"
        className="w-full"
        onClick={handlers.performOnClick}
      >
        {(() => {
          switch (state.status) {
            case ActionStatus.DryrunPending:
              return 'Preparing...';
            case ActionStatus.DryrunSucceeded:
              return 'Perform';
            default:
              return 'Go';
          }
        })()}
      </Button>
    </>
  );
};

const ActionDryrunError = () => {
  return (
    <div className="absolute inset-0 px-4 backdrop-blur-[2px]">
      <div className="flex h-full w-full items-center justify-center rounded-md border border-dashed">
        <div className="rounded-lg bg-background p-4">
          <ExclamationTriangleIcon className="mx-auto mb-2 h-4 w-4 text-gray-800" />
          <p className="dark:text-white-700 text-xs text-gray-800">
            An error occurred
          </p>
        </div>
      </div>
      <Button size="sm" variant="outline" className="w-full">
        Retry
      </Button>
    </div>
  );
};

const Dryrun = {
  Pending: ActionDryrunPending,
  Success: ActionDryrunSuccess,
  Error: ActionDryrunError,
};

export default Dryrun;
