import React from 'react';
import { ButtonProps } from '@/components/ui/button';
import { RenderFormField } from 'lib/shared';
import { PlatformObject } from 'lib/types';

export type ActionProps = {
  id: string;
  small?: boolean;
  disabled?: boolean;
  endIcon?: React.ReactNode;
  preview?: PlatformObject;
  button?: ButtonProps;
  context?: any;
  onSuccess?: () => Promise<any> | void;
  fieldExtensions?: Record<string, RenderFormField>;
};

export enum ActionStatus {
  Idle = 'IDLE',
  Form = 'FORM',
  DryrunPending = 'DRYRUN_PENDING',
  DryrunSucceeded = 'DRYRUN_SUCCEEDED',
  DryrunFailed = 'DRYRUN_FAILED',
  PerformPending = 'PERFORM_PENDING',
  PerformCleanup = 'PERFORM_CLEANUP',
  PerformSucceeded = 'PERFORM_SUCCEEDED',
  PerformFailed = 'PERFORM_FAILED',
}
