import React from 'react';
import {
  NavigationMenu,
  NavigationMenuList,
} from '@/components/ui/navigation-menu';
import NavigationLeftClients from './components/NavigationLeftClients';
import NavigationLeftCompany from './components/NavigationLeftCompany';
import NavigationLeftHumanResources from './components/NavigationLeftHumanResources';
import NavigationLeftSupplyChain from './components/NavigationLeftSupplyChain';

const TopbarNav = () => {
  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationLeftClients />
        <NavigationLeftHumanResources />
        <NavigationLeftSupplyChain />
        <NavigationLeftCompany />
      </NavigationMenuList>
    </NavigationMenu>
  );
};

export default TopbarNav;
