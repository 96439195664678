import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { toast } from 'sonner';
import { useCommercialContractContext } from '../../../../context';
import { useContractRateContext } from '../../context';
import {
  UpdateCommercialContractRateMutationData,
  UpdateCommercialContractRateMutationVariables,
} from './types';

export const useRateUnitValueTransition = () => {
  const { contract } = useCommercialContractContext();
  const { rate } = useContractRateContext();

  const [open, setOpen] = useState(false);

  const [update] = useMutation<
    UpdateCommercialContractRateMutationData,
    UpdateCommercialContractRateMutationVariables
  >(
    gql`
      mutation UpdateContractRate(
        $id: ID!
        $field: String!
        $value: JSONScalarInput!
      ) {
        updateCommercialContractRate(
          commercialContractRateId: $id
          field: $field
          value: $value
        ) {
          commercialContractRate {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnSubmit = async (values: any) => {
    if (!rate || !contract) return;

    try {
      await handleUpdateField(
        'unit_value_transition',
        values.unitValueTransition,
      );
    } catch (error) {
      console.error(error);
      toast.error('Failed to update transition unit value');
    }

    try {
      await handleUpdateField(
        'unit_value_transition_time',
        DateTime.fromISO(values.unitValueTransitionTime).toISOTime(),
      );
    } catch (error) {
      console.error(error);
      toast.error('Failed to update transition unit value time');
    }

    await rate.refetch();
    await contract.refetch();
    setOpen(false);
    toast.success('Transition unit value updated');
  };

  const handleUpdateField = async (
    field: 'unit_value_transition' | 'unit_value_transition_time',
    value: any,
  ) => {
    if (!rate) return;

    const { errors } = await update({
      variables: {
        id: rate.id,
        field,
        value,
      },
    });

    if (errors) {
      throw Error(errors[0].message);
    }
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onSubmit: handleOnSubmit,
    },
  };
};
