import { gql, useQuery } from '@apollo/client';
import { Client } from 'lib/types';
import { ClientsQueryData } from './types';

export const useNavigationLeftClients = () => {
  const { data, loading, error } = useQuery<ClientsQueryData>(
    gql`
      query GetClients {
        clients {
          id
          slug
          name
          accentColor
          accentColorDark
          openJobs {
            id
          }
          openIssues {
            id
          }
          openReports {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all', fetchPolicy: 'cache-and-network' },
  );

  return {
    clients: data?.clients || [],
    loading,
    error,
  };
};
