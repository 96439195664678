import React from 'react';
import { UserQueryData, UserQueryVariables } from '@/components/ui/user/types';
import { gql, useQuery } from '@apollo/client';

export const useUserSheet = (id: string | undefined) => {
  const [open, setOpen] = React.useState(false);

  const { data, loading, refetch } = useQuery<
    UserQueryData,
    UserQueryVariables
  >(
    gql`
      query GetUser($id: ID!) {
        user(userId: $id) {
          id
          name
          email
          rates {
            id
            description
            base
            unit
            unitDisplay
            unitCost
            dateStart
            dateEnd
          }
          isConfigured
          externalId
        }
      }
    `,
    {
      skip: !open,
      variables: { id: id as string },
      fetchPolicy: 'no-cache',
    },
  );

  if (!data) {
    return {
      sheet: {
        open,
        onOpenChange: setOpen,
        onClose: () => setOpen(false),
      },
      loading,
      user: null,
    };
  }

  data.user.refetch = refetch;
  return {
    sheet: {
      open,
      onOpenChange: setOpen,
      onClose: () => setOpen(false),
    },
    loading,
    user: data.user,
  };
};
