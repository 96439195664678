import { useCommercialEstimateContext } from '../../../../context';

const HeaderNumber = () => {
  const { estimate } = useCommercialEstimateContext();
  return (
    <p className="ml-0.5 flex size-8 items-center justify-center text-sm">
      #{estimate?.number}
    </p>
  );
};

export default HeaderNumber;
