import React from 'react';
import { Badge } from '@/components/ui/badge';
import chroma from 'chroma-js';
import { Job } from 'lib/types';
import { NodeProps, NodeType } from '../../types';
import NodeBase from '../NodeBase';

const NodeJob = ({ id, data }: NodeProps<Job>) => {
  return (
    <NodeBase type={NodeType.Job} id={id} target={false} to="_base">
      <div className="rounded-md border-2 bg-background p-3">
        <div className="mb-2 flex h-6 items-center justify-between">
          <p className="text-sm font-semibold text-gray-400">Job</p>
          <Badge
            className="rounded-md font-semibold capitalize"
            style={{
              color: '#00c4ff',
              border: `1px solid ${chroma('#00c4ff').alpha(0.5).hex()}`,
              backgroundColor: chroma('#00c4ff').alpha(0.1).hex(),
            }}
          >
            {data.scope.split('::').at(-1)?.replace(/-/g, ' ')}
          </Badge>
        </div>
        <p className="font-semibold">{data.reference}</p>
      </div>
    </NodeBase>
  );
};

export default NodeJob;
