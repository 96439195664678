import { Nullable } from './core';
import { JobInteraction, JobTask } from './jobs';
import {
  Subcontractor,
  SubcontractorDuty,
  SubcontractorReport,
} from './subcontractors';
import { User } from './users';

export type TimesheetActivity = {
  id: string;
  duration: number;
  durationActive: number;
  durationChargeable: number;
  duties: TimesheetDuty[];
  subcontractorDuties: SubcontractorDuty[];
  jobTask: JobTask;
  users: User[];
  firstEvent: Nullable<TimesheetEvent>;
  lastEvent: Nullable<TimesheetEvent>;
};

export enum TimesheetDutyCondition {
  By = 'BY',
  At = 'AT',
  After = 'AFTER',
}

export enum TimesheetDutyStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Started = 'STARTED',
  Completed = 'COMPLETED',
}

export type TimesheetDuty = {
  id: string;
  number: number;
  activity: TimesheetActivity;
  dateTimeCreated: string;
  createdBy: Nullable<User>;
  dateTimeStart: string;
  dateTimeStartCondition: TimesheetDutyCondition;
  dateTimeEnd: string;
  dateTimeEndCondition: TimesheetDutyCondition;
  user: Nullable<User>;
  subcontractor: Nullable<Subcontractor>;
  subcontractorReports: SubcontractorReport[];
  duration: Nullable<number>;
  events: TimesheetEvent[];
  status: TimesheetDutyStatus;
  isNew?: boolean;
  isAssigning?: boolean;
  jobInteractions: JobInteraction[];
};

export enum TimesheetEventStartType {
  Started = 'STARTED',
  Continued = 'CONTINUED',
}

export enum TimesheetEventEndType {
  Completed = 'COMPLETED',
  Paused = 'PAUSED',
  Left = 'LEFT',
}

export type TimesheetEvent = {
  id: string;
  dateTimeStart: string;
  dateTimeStartType: TimesheetEventStartType;
  dateTimeEnd: string | null;
  dateTimeEndType: TimesheetEventEndType | null;
  duration: number | null;
  resolvedBy: TimesheetEvent | null;
};
