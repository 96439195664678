import React from 'react';
import { LayoutDashboard } from 'lucide-react';
import { Outlet } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Nav } from '@/components/ui/nav';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable';
import { ScrollArea } from '@/components/ui/scroll-area';
import { TableRenderer } from '@/components/ui/table/table/Table';
import { useRouteMatch } from 'lib/hooks';
import { Link } from 'lib/shared';
import { useTrainingStaff } from './hooks';

const TrainingStaff = () => {
  const { users } = useTrainingStaff();
  const routeMatch = useRouteMatch(['/hr/training/staff']);
  return (
    <ResizablePanelGroup
      direction="horizontal"
      className="h-full min-h-0 items-stretch bg-background"
    >
      <ResizablePanel
        className="flex flex-col"
        defaultSize={20}
        minSize={20}
        maxSize={20}
      >
        <div className="flex h-[52px] flex-shrink-0 items-center justify-between border-b px-4 py-2">
          <h1 className="text-xl font-bold">Staff</h1>
        </div>
        <div className="border-b">
          <Nav
            collapsed={!open}
            links={[
              {
                title: 'Overview',
                icon: LayoutDashboard,
                variant:
                  routeMatch?.pattern.path === '/hr/training/staff'
                    ? 'selected'
                    : 'unselected',
                to: `/hr/training/staff`,
              },
            ]}
          />
        </div>
        <ScrollArea className="min-h-0 grow">
          <div className="px-4">
            <TableRenderer
              small
              topRight={() => null}
              filter="name"
              initialSorting={[{ id: 'name', desc: false }]}
              columns={[
                {
                  id: 'name',
                  accessorKey: 'name',
                  header: 'Name',
                  cell: ({ row }) => {
                    const username = row.original.email
                      .replace('@mchughltd.co.uk', '')
                      .toLowerCase();
                    return (
                      <Link to={`/hr/training/staff/${username}`}>
                        <Button variant="table" size="table">
                          {row.original.name || <em>No name</em>}
                        </Button>
                      </Link>
                    );
                  },
                },
              ]}
              data={users}
            />
          </div>
        </ScrollArea>
      </ResizablePanel>
      <ResizableHandle withHandle />
      <ResizablePanel>
        <Outlet />
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};

export default TrainingStaff;
