import React from 'react';
import { Check } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { useJobContext } from '../../../../../../context';

const LocationHeader = () => {
  const { job } = useJobContext();

  if (job.partial) return null;

  return (
    <div className="flex gap-x-2">
      <div className="flex rounded-lg border bg-secondary">
        <p className="border-r px-2 py-1.5 text-xs font-semibold uppercase text-gray-500 dark:text-gray-400">
          Site
        </p>
        <p className="px-2 py-1">{job.site.reference}</p>
      </div>
      <Badge className="rounded-lg" variant="outline">
        <Check className="mr-2 h-4 w-4" />
        Location verified
      </Badge>
    </div>
  );
};

export default LocationHeader;
