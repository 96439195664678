import { Button } from '@/components/ui/button';
import { TableRenderer } from '@/components/ui/table/table/Table';
import { Link } from 'lib/shared';
import { useSupplyChainSubcontractorsContext } from '../../context';
import SubcontractorsCreate from '../SubcontractorsCreate';

const SubcontractorsTable = () => {
  const { subcontractors } = useSupplyChainSubcontractorsContext();
  return (
    <TableRenderer
      filter="name"
      columns={[
        {
          header: 'Name',
          accessorKey: 'name',
          cell: ({ row }) => (
            <Link to={`/supply-chain/subcontractors/${row.original.id}`}>
              <Button size="table" variant="link">
                {row.original.name}
              </Button>
            </Link>
          ),
        },
      ]}
      data={subcontractors}
      topRight={({ columnsSelector }) => (
        <div className="ml-auto flex gap-x-2">
          <SubcontractorsCreate />
          {columnsSelector}
        </div>
      )}
    />
  );
};

export default SubcontractorsTable;
