import { gql, useQuery } from '@apollo/client';

export const useSubcontractorRequirements = () => {
  const { data, refetch } = useQuery(gql`
    query GetSubcontractorRequirements {
      subcontractorRequirements {
        id
        name
        description
      }
    }
  `);

  return {
    context: {
      requirements: data?.subcontractorRequirements,
      refetch,
    },
  };
};
