import { createContext, useContext } from 'react';
import { useEstimateTable } from './hooks';

export const EstimateTableContext = createContext(
  {} as ReturnType<typeof useEstimateTable>['context'],
);

export const useEstimateTableContext = () => {
  return useContext(EstimateTableContext);
};
