import React from 'react';
import Events from 'external/Events';
import { useJobContext } from '../../../../context';

const OverviewEvents = () => {
  const { job } = useJobContext();

  if (job.partial) return null;

  return (
    <Events
      scope={
        job.variation
          ? `jobs::job-variation::${job.variation.id}`
          : `jobs::job::${job.id}`
      }
    />
  );
};

export default OverviewEvents;
