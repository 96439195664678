import produce from 'immer';
import { DateTime } from 'luxon';
import { create } from 'zustand';
import { RosterTeam } from 'lib/types';
import { RosterDutiesQueryData } from './hooks';
import { RosterDutyCountPerDay } from './types';

export type RosterCalendarValues = {
  rosterTeams: RosterTeam[];
  selectedRosterTeamIds: string[];
  dateTimeStart: DateTime;
  rosterDutyCountPerDay: RosterDutyCountPerDay;
  rosterDutySubmittedPerDay: RosterDutyCountPerDay;
  rosterDutyApprovedPerDay: RosterDutyCountPerDay;
};

export type RosterCalendarActions = {
  initialQueryOnCompleted: (data: RosterDutiesQueryData) => void;
  setRosterTeams: (rosterTeams: RosterTeam[]) => void;
  selectRosterTeams: (rosterTeamIds: string[]) => void;
  setStartTimeStart: (dateTimeStart: DateTime) => void;
  decrementStartTimeStart: () => void;
  incrementStartTimeStart: () => void;
};

export type RosterCalendarState = RosterCalendarValues & RosterCalendarActions;

export const initialValues: RosterCalendarValues = {
  rosterTeams: [],
  selectedRosterTeamIds: [],
  dateTimeStart: DateTime.local().startOf('year').set({ month: 4, day: 1 }),
  rosterDutyCountPerDay: {},
  rosterDutySubmittedPerDay: {},
  rosterDutyApprovedPerDay: {},
};

export const useStore = create<RosterCalendarState>()((set) => ({
  ...initialValues,

  setRosterTeams: (rosterTeams: RosterTeam[]) =>
    set(
      produce<RosterCalendarState>((state) => {
        state.rosterTeams = rosterTeams;
      }),
    ),

  selectRosterTeams: (rosterTeamIds: string[]) =>
    set(
      produce<RosterCalendarState>((state) => {
        state.selectedRosterTeamIds = rosterTeamIds;
      }),
    ),

  setStartTimeStart: (dateTimeStart: DateTime) =>
    set(
      produce<RosterCalendarState>((state) => {
        state.dateTimeStart = dateTimeStart;
      }),
    ),

  decrementStartTimeStart: () => {
    set(
      produce<RosterCalendarState>((state) => {
        state.dateTimeStart = state.dateTimeStart.minus({ year: 1 });
      }),
    );
  },

  incrementStartTimeStart: () => {
    set(
      produce<RosterCalendarState>((state) => {
        state.dateTimeStart = state.dateTimeStart.plus({ year: 1 });
      }),
    );
  },

  initialQueryOnCompleted: ({
    rosterDutyCountPerDay,
    rosterDutySubmittedPerDay,
    rosterDutyApprovedPerDay,
  }) =>
    set(
      produce<RosterCalendarState>((state) => {
        state.rosterDutyCountPerDay = rosterDutyCountPerDay;
        state.rosterDutySubmittedPerDay = rosterDutySubmittedPerDay;
        state.rosterDutyApprovedPerDay = rosterDutyApprovedPerDay;
      }),
    ),
}));
