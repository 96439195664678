import { Report } from 'lib/shared';
import {
  CommercialEstimate,
  CommercialType,
  commercialTypeDisplayMap,
} from 'lib/types';
import { useQuoteRendererContext } from '../../../../context';
import BodyLine from '../BodyLine';
import { useBodyLines } from './hooks';
import { BodyLinesProps } from './types';

const getTotal = (estimate: CommercialEstimate, type: CommercialType) => {
  switch (type) {
    case CommercialType.Prelim:
      return estimate.totalQuoteValuePrelim;
    case CommercialType.Labor:
      return estimate.totalQuoteValueLabor;
    case CommercialType.Material:
      return estimate.totalQuoteValueMaterial;
    case CommercialType.Equipment:
      return estimate.totalQuoteValueEquipment;
    case CommercialType.Subcontractor:
      return estimate.totalQuoteValueSubcontractor;
  }
};

const BodyLines = ({ type }: BodyLinesProps) => {
  const { estimate, theme } = useQuoteRendererContext();
  const { lines } = useBodyLines(type);

  if (lines.length === 0) {
    return null;
  }

  return (
    <Report.View
      style={{
        borderTopWidth: 1,
        borderTopColor: theme.colors.border,
        paddingTop: 8,
        marginBottom: 8,
      }}
    >
      <Report.View>
        <Report.Text
          style={{
            fontSize: 11,
            fontWeight: 700,
            color: theme.colors.title,
            marginBottom: 8,
          }}
        >
          {commercialTypeDisplayMap[type]}
        </Report.Text>
      </Report.View>
      {lines.length > 0 && (
        <Report.View style={{ marginBottom: 12, display: 'flex', rowGap: 8 }}>
          {lines.map((line) => (
            <BodyLine key={line.id} line={line} />
          ))}
        </Report.View>
      )}
      <Report.View style={{ display: 'flex', flexDirection: 'row' }}>
        <Report.View style={{ width: '40%' }} />
        <Report.View style={{ width: '15%' }} />
        <Report.View style={{ width: '30%' }}>
          <Report.Text
            style={{
              fontSize: 12,
              fontWeight: 700,
              color: theme.colors.subtitle,
            }}
          >
            Total ({commercialTypeDisplayMap[type]})
          </Report.Text>
        </Report.View>
        <Report.View style={{ width: '15%' }}>
          <Report.Text
            style={{
              fontSize: 12,
              fontWeight: 700,
              color: theme.colors.title,
              textAlign: 'right',
            }}
          >
            {getTotal(estimate, type).toFixed(2)}
          </Report.Text>
        </Report.View>
      </Report.View>
    </Report.View>
  );
};

export default BodyLines;
