import { z } from 'zod';

export const getHorizontalShift = (depth: number) => {
  return depth * 8 + 16;
};

export const getHorizontalPadding = (depth: number) => {
  return `${getHorizontalShift(depth)}px`;
};

export function getDefaults<Schema extends z.ZodType>(schema: Schema) {
  return Object.fromEntries(
    Object.entries(schema).map(([key, value]) => {
      if (value instanceof z.ZodDefault)
        return [key, value._def.defaultValue()];
      return [key, undefined];
    }),
  );
}
