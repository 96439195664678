import React from 'react';
import { MoreHorizontal } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { AsyncContext } from 'lib/shared';
import { useLineMenu } from './hooks';

const LineMenu = () => {
  const { dialog, handlers } = useLineMenu();
  return (
    <div className="w-8 flex-shrink-0">
      <Dialog open={dialog.open} onOpenChange={dialog.onOpenChange}>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="size-8 rounded-none p-0">
              <MoreHorizontal className="size-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-[200px]">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuGroup>
              <DialogTrigger asChild>
                <DropdownMenuItem>Delete</DropdownMenuItem>
              </DialogTrigger>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <DialogContent overlay>
          <DialogHeader>
            <DialogTitle>Delete Quote Line</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this cost line?
            </DialogDescription>
          </DialogHeader>
          <AsyncContext handle={handlers.onDelete}>
            {({ loading, handle }) => (
              <DialogFooter>
                {!loading && (
                  <DialogClose asChild>
                    <Button size="sm" variant="outline">
                      Cancel
                    </Button>
                  </DialogClose>
                )}
                <Button size="sm" variant="destructive" onClick={handle}>
                  Delete value line
                </Button>
              </DialogFooter>
            )}
          </AsyncContext>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LineMenu;
