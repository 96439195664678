import React from 'react';
import PickerClose from './components/PickerClose';
import PickerConfirm from './components/PickerConfirm';
import PickerEmpty from './components/PickerEmpty';
import PickerLoader from './components/PickerLoader';
import PickerMap from './components/PickerMap';
import PickerSearch from './components/PickerSearch';
import { LocationPickerContext } from './context';
import { useLocationPicker, useLocationPickerContext } from './hooks';
import { LocationPickerProps } from './types';

const LocationPicker = ({ children, value, onChange }: LocationPickerProps) => {
  const { context } = useLocationPicker(value, onChange);
  return (
    <LocationPickerContext.Provider value={context}>
      {children}
    </LocationPickerContext.Provider>
  );
};

LocationPicker.Close = PickerClose;
LocationPicker.Confirm = PickerConfirm;
LocationPicker.Empty = PickerEmpty;
LocationPicker.Loader = PickerLoader;
LocationPicker.Map = PickerMap;
LocationPicker.Search = PickerSearch;
LocationPicker.useContext = useLocationPickerContext;

export default LocationPicker;
