import React from 'react';
import { Badge } from '@/components/ui/badge';
import chroma from 'chroma-js';
import { useMatch } from 'lib/hooks';
import { commercialEstimateStatusColors } from 'lib/theme';
import { CommercialEstimateStatus, JobVariation } from 'lib/types';
import { useJobContext } from '../../../../../../context';
import { NodeProps, NodeType } from '../../types';
import NodeBase from '../NodeBase';

const NodeJobVariation = ({ id, data }: NodeProps<JobVariation>) => {
  const { state } = useJobContext();
  const color =
    commercialEstimateStatusColors[CommercialEstimateStatus.Estimating];
  return (
    <NodeBase
      type={NodeType.Variation}
      id={id}
      to={state.variation ? '/' : data.reference}
    >
      <div className="rounded-md border-2 bg-background p-3">
        <div className="mb-2 flex h-6 items-center justify-between">
          <p className="text-sm font-semibold text-gray-400">Variation</p>
          <Badge
            className="rounded-md font-semibold capitalize"
            style={{
              color,
              border: `1px solid ${chroma(color).alpha(0.5).hex()}`,
              backgroundColor: chroma(color).alpha(0.1).hex(),
            }}
          >
            Variation
          </Badge>
        </div>
        <p className="font-semibold">{data.reference}</p>
      </div>
    </NodeBase>
  );
};

export default NodeJobVariation;
