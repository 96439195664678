import React from 'react';
import RosterShiftChip from '../RosterShiftChip';
import { RosterExceedanceDescriptionProps } from './types';

const RosterExceedanceDescription = ({
  exceedance,
}: RosterExceedanceDescriptionProps) => {
  const elements = {
    A: (
      <div>
        <RosterShiftChip exceedance={exceedance} /> leads to over 60 hours
        worked in 7 days ({(exceedance.offenceValue as number) / 3600} hours
        total)
      </div>
    ),
    B: (
      <div>
        <RosterShiftChip exceedance={exceedance} /> begins without a 12 hour
        break after <RosterShiftChip exceedance={exceedance} offset={-1} />
      </div>
    ),
    C: (
      <div>
        <RosterShiftChip exceedance={exceedance} /> is longer than 12 hours
      </div>
    ),
    D: (
      <div>
        <RosterShiftChip exceedance={exceedance} /> leads to more than 13
        consecutive shifts
      </div>
    ),
    E: (
      <div>
        <RosterShiftChip exceedance={exceedance} /> has an estimated risk index
        greater than 1.5
      </div>
    ),
    F: (
      <div>
        <RosterShiftChip exceedance={exceedance} /> has an estimated fatigue
        index greater than{' '}
        {exceedance.duty?.shiftType?.value === 'DAY' ? '35' : '45'}
      </div>
    ),
    G: (
      <div>
        <RosterShiftChip exceedance={exceedance} /> is longer then 14 hours
        door-to-door
      </div>
    ),
  };

  return elements[exceedance.offence];
};

export default RosterExceedanceDescription;
