import { DatePicker } from '@/components/ui/date-picker';
import { useTimesheetContext } from '../../context';

const TimesheetControls = () => {
  const { interval } = useTimesheetContext();
  return (
    <div className="mb-8 flex justify-between">
      <DatePicker
        value={interval.value.start}
        onValueChange={interval.setStart}
      />
      <DatePicker value={interval.value.end} onValueChange={interval.setEnd} />
    </div>
  );
};

export default TimesheetControls;
