import React from 'react';

const PagePlaceholder = () => {
  return (
    <div className="border-l border-r bg-background-secondary px-2 py-4">
      <p className="text-sm">
        Add a question or section to this page to get started.
      </p>
    </div>
  );
};

export default PagePlaceholder;
