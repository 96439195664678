import React, { PropsWithChildren } from 'react';
import { Portal } from 'lib/shared';
import { useWindowManager } from '../../hooks';

const WindowManagerHeader = ({ children }: PropsWithChildren) => {
  const { headerRef } = useWindowManager();
  if (!headerRef?.current) return null;
  return <Portal element={headerRef?.current}>{children}</Portal>;
};

export default WindowManagerHeader;
