import { gql, useQuery } from '@apollo/client';
import { TrainingQualificationsQueryData } from './types';

export const useQualificationsAdd = () => {
  const { data } = useQuery<TrainingQualificationsQueryData>(
    gql`
      query GetTrainingQualifications {
        trainingQualifications {
          id
          name
          description
          color
          logo {
            id
          }
        }
      }
    `,
    { fetchPolicy: 'cache-and-network' },
  );

  return {
    qualifications: data?.trainingQualifications ?? [],
  };
};
