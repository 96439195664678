import { useMutation } from '@tanstack/react-query';
import { PlatformObject } from 'lib/types';
import { platformClient } from '../app/platform';

const useCreatePlatformObjects = () => {
  const create = useMutation({
    mutationFn: platformClient.createObjects(),
  });
  return async (
    objects: Pick<PlatformObject, 'id' | 'name' | 'type' | 'size'>[],
  ) => {
    return (await create.mutateAsync(objects)) || [];
  };
};

export default useCreatePlatformObjects;
