import React, { useEffect, useMemo } from 'react';
import { FileCheck2Icon, FileX2Icon } from 'lucide-react';
import { ErrorBoundary } from 'react-error-boundary';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import { useReadOnlyFormTemplate, useYjs } from 'lib/hooks';
import {
  AsyncContext,
  RenderFormField,
  getHorizontalPadding,
} from 'lib/shared';
import { FormComponent } from '../../../../shared/Form';
import { FormChildrenProps } from '../../../../shared/Form/types';
import UserPhoto from '../../../../shared/UserPhoto';
import { TaskFormCompletionDisplay } from '../../pages/JobTask/components/TaskFormCompletion';
import { JobTaskFormContext, useJobTaskFormContext } from './context';
import { useJobTaskForm } from './hooks';
import { JobTaskFormProps, JobTaskFormRendererProps } from './types';

const JobTaskForm = ({ form, onApprove, onEdit, debug }: JobTaskFormProps) => {
  const { context } = useJobTaskForm(form.id);
  const { formTemplate } = useReadOnlyFormTemplate(form.template.id);

  useEffect(() => {
    console.debug(
      `[${(form.template.name ?? '')
        .slice(0, 29)
        .padEnd(29)}]: JobTaskForm mount`,
    );
    return () => {
      console.debug(
        `[${(form.template.name ?? '')
          .slice(0, 29)
          .padEnd(29)}]: JobTaskForm unmount`,
      );
    };
  }, [form.template.name]);

  useEffect(() => {
    if (formTemplate) {
      console.debug(
        `[${(formTemplate.name ?? '')
          .slice(0, 29)
          .padEnd(29)}]: Loaded template`,
      );
    }
  }, [formTemplate]);

  const { isReady, store, doc } = useYjs(
    'jobs::job-task-form',
    form.id,
    { values: {}, objects: {}, meta: {}, choiceSets: {}, fieldLabels: {} },
    { debugName: form.template.name },
  );

  if (!context.form || !formTemplate || !isReady || !store || !doc.current) {
    return null;
  }

  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <JobTaskFormContext.Provider value={context}>
        <JobTaskFormRenderer
          id={form.id}
          store={store}
          doc={doc.current}
          formTemplate={formTemplate}
          onApprove={onApprove}
          onEdit={onEdit}
          debug={debug}
        />
      </JobTaskFormContext.Provider>
    </ErrorBoundary>
  );
};

const JobTaskFormRenderer = ({
  id,
  store,
  doc,
  formTemplate,
  onApprove,
  onEdit: onEditCallback,
  debug,
}: JobTaskFormRendererProps) => {
  const { form, handlers } = useJobTaskFormContext();

  const template = useMemo(
    () =>
      formTemplate
        ? JSON.parse(formTemplate.data)
        : { pageOrder: [], items: {} },
    [formTemplate],
  );

  if (!form) return null;

  const renderForm = ({
    form: formComponent,
    meta,
    isCompleted,
    isApproved,
    isLocked,
    completionPercentage,
    getSubmission,
    onComplete,
    onEdit,
  }: FormChildrenProps) => {
    return (
      <div className="flex h-full flex-col">
        <div className="justify-content-between flex items-center justify-between border-b p-4">
          <h1 className="text-xl font-bold">{form.template.name}</h1>
          <div className="mr-8">
            <TaskFormCompletionDisplay
              isCompleted={isCompleted}
              completionPercentage={completionPercentage}
            />
          </div>
        </div>
        <div className="flex border-b">
          <div className="basis-1/2 p-4">
            <p className="text-sm font-medium">Collaborators</p>
            <div>
              {meta.collaborators?.[0] ? (
                <div className="flex items-center gap-2 pt-4">
                  <UserPhoto user={meta.collaborators?.[0]} className="size-9">
                    <Avatar className="h-9 w-9">
                      <AvatarFallback className="bg-accent text-sm font-bold">
                        {meta.collaborators?.[0].name?.[0]}
                      </AvatarFallback>
                    </Avatar>
                  </UserPhoto>
                  <div>
                    <p className="text-sm font-semibold">
                      {meta.collaborators?.[0].name}
                    </p>
                  </div>
                </div>
              ) : (
                <div>No collaborators</div>
              )}
            </div>
          </div>
          <Separator orientation="vertical" />
          <div className="basis-1/2 p-4">
            <p className="text-sm font-medium">Versions</p>
            <div className="pt-4">
              {form.latestSubmission ? (
                <div className="flex items-center gap-2">
                  <Avatar className="h-9 w-9">
                    <AvatarFallback className="bg-accent text-sm font-bold">
                      V{form.latestSubmission?.number}
                    </AvatarFallback>
                  </Avatar>
                  <p className="text-sm font-semibold">
                    Version {form.latestSubmission?.number}
                  </p>
                </div>
              ) : (
                <p className="light:text-muted text-xs">No versions</p>
              )}
            </div>
          </div>
        </div>
        <ScrollArea className="min-h-0 grow overflow-hidden border-b">
          {formComponent}
        </ScrollArea>
        <AsyncContext
          handle={async () => {
            if (!formTemplate) return;
            await handlers.approve(formTemplate, getSubmission);
            meta.isApproved = true;
            onApprove?.();
          }}
        >
          {({ loading, handle }) => (
            <div className="flex items-center justify-between p-4">
              <Dialog>
                <DialogTrigger asChild>
                  {isCompleted ? (
                    <Button
                      disabled={loading || isLocked}
                      size="sm"
                      variant="outline"
                    >
                      Edit
                    </Button>
                  ) : (
                    <div />
                  )}
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Are you sure?</DialogTitle>
                  </DialogHeader>
                  <DialogDescription>
                    If you edit the form you will need to approve it again
                    before the latest version can be used.
                  </DialogDescription>
                  <DialogFooter>
                    <DialogClose className="flex gap-x-2">
                      <Button size="sm" variant="outline">
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        onClick={async () => {
                          await handlers.edit();
                          onEditCallback?.();
                          onEdit?.();
                        }}
                      >
                        Continue
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
              {debug?.({ formTemplate, getSubmission })}
              {isCompleted ? (
                <Button
                  size="sm"
                  disabled={isLocked || isApproved}
                  variant="outline"
                  isLoading={loading}
                  onClick={handle}
                >
                  Approve{isApproved ? 'd' : ''}
                </Button>
              ) : (
                <Button size="sm" variant="outline" onClick={onComplete}>
                  Complete
                </Button>
              )}
            </div>
          )}
        </AsyncContext>
      </div>
    );
  };

  const renderField: RenderFormField = ({
    item,
    isLast,
    label,
    field,
    media,
    dropzone,
  }) => (
    <div
      className={cn('relative p-4', { 'border-b': !isLast })}
      style={{ paddingLeft: getHorizontalPadding(item.depth) }}
      {...dropzone.getRootProps()}
    >
      <div
        className={cn('pointer-events-none absolute inset-0 z-[10]', {
          'opacity-0': !dropzone.isDragActive,
        })}
      >
        {dropzone.isDragAccept && (
          <div className="flex h-full w-full items-center justify-center bg-green-200/10 backdrop-blur-[2px]">
            <div className="flex flex-col items-center gap-y-2">
              <div>
                <FileCheck2Icon className="h-6 w-6 text-gray-400" />
              </div>
              <p className="text-sm font-semibold">Drop to upload</p>
              <p className="text-grey-400 text-xs">Selected files supported</p>
            </div>
          </div>
        )}
        {dropzone.isDragReject && (
          <div className="flex h-full w-full items-center justify-center bg-red-200/10 backdrop-blur-sm">
            <div className="flex flex-col items-center gap-y-2">
              <div>
                <FileX2Icon className="h-6 w-6 text-gray-400" />
              </div>
              <p className="text-sm font-semibold">Cannot drop</p>
              <p className="text-grey-400 text-xs">
                At least one selected file is not supported
              </p>
            </div>
          </div>
        )}
      </div>
      {label}
      {item.data.dataType !== 'COMMENT' && (
        <div>
          {field}
          {item.data.dataType !== 'MEDIA' && media}
        </div>
      )}
    </div>
  );

  return (
    <FormComponent
      id={id}
      store={store}
      doc={doc}
      disableHeader
      template={template}
      render={renderForm}
      renderField={renderField}
    />
  );
};

export default JobTaskForm;
