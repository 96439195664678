import React from 'react';
import { ArrowRight, Circle, Edit3, X } from 'lucide-react';
import Map, { Marker } from 'react-map-gl';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { cn } from '@/lib/utils';
import { useMapStyle } from 'lib/hooks';
import { Empty } from 'lib/shared';
import PickerClose from '../../../../../../../../shared/LocationPicker/components/PickerClose';
import PickerConfirm from '../../../../../../../../shared/LocationPicker/components/PickerConfirm';
import PickerMap from '../../../../../../../../shared/LocationPicker/components/PickerMap';
import PickerSearch from '../../../../../../../../shared/LocationPicker/components/PickerSearch';
import { useLocationPickerContext } from '../../../../../../../../shared/LocationPicker/hooks';
import { useJobContext } from '../../../../../../context';
import { addCircleOnLoad } from '../../../../../JobOverview/components/OverviewMap';
import LocationPreview from '../LocationPreview';
import { LocationSheetProps } from './types';

const LocationSheet = ({
  open,
  edit,
  onOpenChange,
  onEditChange,
}: LocationSheetProps) => {
  const { job } = useJobContext();
  const { selected, handleOpen, handleReset } = useLocationPickerContext();

  const mapStyle = useMapStyle();

  if (job.partial) return null;

  const point = job.site.location?.precise?.geometry?.point;

  return (
    <>
      <Sheet
        open={open}
        onOpenChange={(open) => {
          if (!open) {
            onEditChange(false);
          }
          onOpenChange(open);
        }}
      >
        <SheetTrigger asChild>
          <Button size="xs" variant="outline">
            <Edit3 className="mr-2 h-4 w-4" />
            Edit site
          </Button>
        </SheetTrigger>
        <SheetContent
          onInteractOutside={(event) => {
            if (edit) {
              event.preventDefault();
            }
          }}
          overlay={!edit}
          className={cn(
            'flex w-[500px] max-w-[500px] flex-col gap-y-0 overflow-visible p-0 sm:max-w-[500px]',
          )}
          side="left"
        >
          <div className="absolute inset-0 top-0 z-[9] min-h-0">
            <Map
              style={{ height: '100%', width: '100%' }}
              initialViewState={{
                zoom: 15,
                ...(job.location?.precise?.geometry?.point ||
                  job.site.location?.precise?.geometry?.point),
              }}
              mapboxAccessToken="pk.eyJ1IjoiZW5lcmd5ZW5hYmxlcyIsImEiOiJjbGpia2NqZ2IxcTcxM2pxeGxvNG5ud2lpIn0.gMXRc_xES-6AlQx1w8MuXw"
              attributionControl={false}
              mapStyle={mapStyle}
              onLoad={(event) => {
                const center = event.target.getCenter();
                event.target.jumpTo({
                  center: [center.lng, center.lat - 0.0055],
                  zoom: 15,
                });
                if (point) addCircleOnLoad(event, point);
              }}
            >
              {point && (
                <Marker {...point}>
                  <Circle className="size-3 fill-success stroke-success" />
                </Marker>
              )}
            </Map>
          </div>
          <div
            className={cn(
              'relative z-10 h-full w-full from-background from-70% to-90% text-sm transition-all bg-gradient-[0deg]',
            )}
          >
            <div
              className={cn(
                'flex h-full min-h-0 flex-col bg-transparent transition-all',
                {
                  'bg-background': edit,
                },
              )}
            >
              <div
                className={cn(
                  'mb-40 flex justify-between px-4 pt-4 transition-all',
                  {
                    'mb-4': edit,
                  },
                )}
              >
                <div className="flex rounded-lg border bg-secondary">
                  <p className="border-r px-2 py-1 text-xs font-semibold uppercase text-gray-500 dark:text-gray-400">
                    Site
                  </p>
                  <p className="px-2 py-0.5 text-sm">{job.site.reference}</p>
                </div>
                {edit ? null : (
                  <Button
                    size="xs"
                    variant="secondary"
                    onClick={async () => {
                      onEditChange(true);
                      await handleOpen(job.site.location);
                    }}
                  >
                    <Edit3 className="mr-2 size-4" />
                    Edit location
                  </Button>
                )}
              </div>
              <div className="mb-4 px-4">
                <div className="mb-2 flex justify-between">
                  <p>Current address</p>
                  <ArrowRight
                    className={cn(
                      'h-4 w-4 rotate-90 opacity-0 transition-all',
                      { 'rotate-0 opacity-100': edit },
                    )}
                  />
                </div>
                <div>
                  <table className="pointer-events-auto">
                    <tbody>
                      <tr>
                        <td className="pr-2 text-gray-500 dark:text-gray-400">
                          Line one
                        </td>
                        <td>{job.site.location?.address?.lineOne}</td>
                      </tr>
                      <tr>
                        <td className="pr-2 text-gray-500 dark:text-gray-400">
                          Line two
                        </td>
                        <td>{job.site.location?.address?.lineTwo}</td>
                      </tr>
                      <tr>
                        <td className="pr-2 text-gray-500 dark:text-gray-400">
                          City/Town
                        </td>
                        <td>{job.site.location?.address?.city}</td>
                      </tr>
                      <tr>
                        <td className="pr-2 text-gray-500 dark:text-gray-400">
                          Postcode
                        </td>
                        <td>{job.site.location?.address?.postcode}</td>
                      </tr>
                      <tr>
                        <td className="pr-2 text-gray-500 dark:text-gray-400">
                          Country
                        </td>
                        <td>{job.site.location?.address?.country}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {edit ? (
                <div className="min-h-0 grow border-t">
                  <PickerSearch />
                </div>
              ) : (
                <div className="px-4 pt-4">
                  <div className="mb-8">
                    <div className="mb-2">
                      <p>Contacts</p>
                    </div>
                    <div className="h-32">
                      <Empty>No contacts</Empty>
                    </div>
                  </div>
                  <div className="mb-8">
                    <div className="mb-2">
                      <p>Buildings</p>
                    </div>
                    <div className="h-32">
                      <Empty>No buildings</Empty>
                    </div>
                  </div>
                  <div>
                    <div className="mb-2">
                      <p>Assets</p>
                    </div>
                    <div className="h-32">
                      <Empty>No assets</Empty>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {edit && (
            <div className="absolute inset-y-0 left-full z-[100] w-[calc(100vw-500px)] bg-secondary p-1">
              {selected && <LocationPreview />}
              <PickerMap />
              <PickerConfirm />
              <div className="absolute right-3 top-3">
                <PickerClose
                  onClose={() => {
                    onEditChange(false);
                    handleReset();
                  }}
                />
              </div>
            </div>
          )}
        </SheetContent>
      </Sheet>
    </>
  );
};

export default LocationSheet;
