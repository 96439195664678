import React from 'react';
import { DataType } from 'external/FormTemplateEditor';
import { ReportSection, ReportText, ReportView } from 'lib/shared';
import { useReportContext } from '../../../../../../context';
import { useReportBlockFormContext } from '../../context';
import { SubmissionValue } from '../../types';
import FormFieldMedia from '../FormFieldMedia';
import FieldBoolean from './components/FieldBoolean';
import FieldChar from './components/FieldChar';
import FieldChoiceSet from './components/FieldChoiceSet';
import FieldComment from './components/FieldComment';
import FieldDuration from './components/FieldDuration';
import FieldNumber from './components/FieldNumber';
import FieldSignature from './components/FieldSignature';
import FieldUser from './components/FieldUser';
import { FormFieldComponentProps, FormFieldProps } from './types';

const FormField = ({ item, path }: FormFieldProps) => {
  if (item.data.isPrivate) {
    return null;
  }

  const { submission } = useReportBlockFormContext();

  const value = submission.values[path] as SubmissionValue;
  const media = <FormFieldMedia item={item} path={path} />;

  const fields = {
    [DataType.BOOLEAN]: (props) => <FieldBoolean {...props} />,
    [DataType.CHAR]: (props) => <FieldChar {...props} />,
    [DataType.CHOICE_SET]: (props) => <FieldChoiceSet {...props} />,
    [DataType.COMMENT]: (props) => <FieldComment {...props} />,
    [DataType.DURATION]: (props) => <FieldDuration {...props} />,
    [DataType.NUMBER]: (props) => <FieldNumber {...props} />,
    [DataType.MEDIA]: (props) => (
      <FormFieldMediaComponent {...props} path={path} />
    ),
    [DataType.SIGNATURE]: (props: any) => <FieldSignature {...props} />,
    [DataType.USER]: (props) => <FieldUser {...props} />,
  } as Record<DataType, React.FC<FormFieldComponentProps>>;

  if (!(item.data.dataType in fields)) {
    return null;
  }

  return (
    <ReportView wrap={false}>
      {fields[item.data.dataType]({ item, value, media })}
    </ReportView>
  );
};

const FormFieldMediaComponent = ({
  item,
  path,
}: FormFieldComponentProps & { path: string }) => {
  const { theme } = useReportContext();
  return (
    <ReportSection title={item.data.value}>
      <ReportView>
        <FormFieldMedia
          item={item}
          path={path}
          size={100}
          borderTop={false}
          placeholder={
            <ReportView style={{ padding: 12 }}>
              <ReportText
                style={{ fontSize: 11, color: theme.colors.subtitle }}
              >
                No media uploaded
              </ReportText>
            </ReportView>
          }
        />
      </ReportView>
    </ReportSection>
  );
};

export default FormField;
