import { Circle, CircleCheck } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { SubcontractorReportStatus } from 'lib/types';
import { useSubcontractorReportContext } from '../SubcontractorReport/context';

const SubcontractorReportActions = () => {
  const { report, handlers } = useSubcontractorReportContext();

  if (!report) {
    return null;
  }

  if (report.status === SubcontractorReportStatus.ReviewPending) {
    return (
      <div>
        <Button
          className="gap-x-2 pl-2.5"
          size="sm"
          onClick={handlers.onReviewed}
        >
          <Circle className="size-4" />
          Approve
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Button
        className="gap-x-2 pl-2.5 font-semibold"
        size="sm"
        variant="secondary"
        onClick={handlers.onReset}
      >
        <CircleCheck className="size-4" />
        Approved
      </Button>
    </div>
  );
};

export default SubcontractorReportActions;
