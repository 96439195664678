import { FC, ReactNode } from 'react';
import { EventModel } from '@bryntum/scheduler-thin';
import { ProjectModel, ResourceModel } from '@bryntum/schedulerpro-thin';
import { GraphQLError } from 'graphql';
import { DateTime } from 'luxon';
import {
  Job,
  JobTask,
  Subcontractor,
  SubcontractorDuty,
  TimesheetDuty,
  TimesheetEvent,
  User,
} from 'lib/types';
import { useScheduler, useSchedulerActions } from './hooks';

export type SchedulerProps = {
  children: ReactNode | FC<SchedulerContext>;
  project: ProjectModel;
  onUpdateSuccess?: (update: Update) => void;
  onUpdateError?: (update: Update, errors: readonly GraphQLError[]) => void;
};

export type SchedulerContext = ReturnType<typeof useScheduler>['context'];

export type SchedulerActionsState = ReturnType<typeof useSchedulerActions>;

export enum SchedulerResourceType {
  User = 'USER',
  Subcontractor = 'SUBCONTRACTOR',
}

export class SchedulerResource extends ResourceModel {
  declare type: SchedulerResourceType;
  user: User | Subcontractor | undefined;
  subcontractor: any | undefined;
}

export class SchedulerDuty extends EventModel {
  declare resource: SchedulerResource;
  declare resourceType: SchedulerResourceType;
  isDeleted: boolean = false;
  isHighlighted: boolean = false;
  timesheetDuty: PreparedTimesheetDuty | undefined;
  subcontractorDuty: PreparedSubcontractorDuty | undefined;
}

export type PreparedTimesheetDuty = Omit<
  TimesheetDuty,
  'dateTimeCreated' | 'dateTimeStart' | 'dateTimeEnd' | 'events'
> & {
  job: Job;
  jobTask: JobTask;
  isActive: boolean;
  dateTimeCreated: DateTime;
  dateTimeStart: DateTime | null;
  dateTimeEnd: DateTime | null;
  events: PreparedTimesheetEvent[];
};

export type PreparedSubcontractorDuty = Omit<
  SubcontractorDuty,
  'dateTimeStart' | 'dateTimeEnd'
> & {
  job: Job;
  dateTimeStart: DateTime;
  dateTimeEnd: DateTime;
};

export type PreparedTimesheetEvent = Omit<
  TimesheetEvent,
  'dateTimeStart' | 'dateTimeEnd'
> & {
  dateTimeStart: DateTime;
  dateTimeEnd: DateTime | null;
};

export type Updates = Record<string, Update>;

export type Update = {
  type: UpdateType;
  duty: PreparedTimesheetDuty;
  diff: UpdateDiff;
  status?: UpdateStatus;
  errors?: any[];
};

export enum UpdateType {
  Create = 'CREATE',
  Update = 'UPDATE',
  UpdateTransfer = 'UPDATE_TRANSFER',
  Delete = 'DELETE',
}

export enum UpdateStatus {
  Applying = 'APPLYING',
  Applied = 'APPLIED',
  Error = 'ERROR',
}

export type UpdateDiff = {
  id: string;
  dateTimeStart?: DateTime;
  dateTimeEnd?: DateTime | null;
  user?: User | null;
  userPrevious?: User | null;
  subcontractor?: Subcontractor | null;
  subcontractorPrevious?: Subcontractor | null;
  interactions?: UpdateDiffInteraction[];
};

export type UpdateDiffInteraction = {
  id: string;
  rate?: string;
  duration?: number;
};

export type HighlightRequest = {
  id: string;
};

export enum SchedulerAction {
  ShiftTimeAxis = 'SHIFT_TIME_AXIS',
  SetControlDateTime = 'SET_CONTROL_DATE_TIME',
  ScrollToDateTime = 'SCROLL_TO_DATE_TIME',
  HighlightEvent = 'HIGHLIGHT_EVENT',
}

export type ScrollToOptions = {
  block?: 'start' | 'center' | 'end';
};

export type SchedulerActionRequest = {
  type: SchedulerAction;
  description: string;
  origin?: string;
} & (
  | {
      type: SchedulerAction.ShiftTimeAxis;
      unit: string;
      delta: number;
    }
  | {
      type: SchedulerAction.SetControlDateTime;
      dateTime: DateTime;
    }
  | {
      type: SchedulerAction.HighlightEvent;
      id: string;
    }
  | {
      type: SchedulerAction.ScrollToDateTime;
      dateTime: DateTime;
      options?: ScrollToOptions;
    }
);
