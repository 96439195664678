import { cn } from '@/lib/utils';
import { CommercialType } from 'lib/types';
import { useActivityTableContext } from '../../../../context';

const ValueHeader = ({ estimate = false }) => {
  const { type } = useActivityTableContext();
  return (
    <div className="flex bg-background-secondary text-xs font-semibold">
      <div
        className={cn('grid grid-cols-8', {
          'w-7/12 border-y': !estimate,
          'w-full border-b': estimate,
        })}
      >
        <div className="col-span-3 flex items-center border-r">
          {!estimate && <div className="w-8 flex-shrink-0" />}
          {(type === CommercialType.Prelim ||
            type === CommercialType.Labor ||
            type === CommercialType.Subcontractor) && (
            <span className="px-2">Contract rate</span>
          )}
          {type === CommercialType.Material && (
            <span className="px-2">Item / Description</span>
          )}
          {type === CommercialType.Equipment && (
            <span className="px-2">Asset / Description</span>
          )}
        </div>
        <div className="col-span-1 border-r p-2">Unit</div>
        <div className="col-span-1 border-r p-2">Quantity</div>
        <div className="col-span-1 border-r p-2">Unit value (£)</div>
        <div className="col-span-1 border-r p-2">Uplift (%)</div>
        <div
          className={cn('col-span-1 p-2', {
            'border-r': !estimate,
          })}
        >
          Total value (£)
        </div>
      </div>
      {!estimate && (
        <div className="grid w-5/12 grid-cols-6 border-y">
          <div className="p-2-sm col-span-1 border-r p-2">Quantity</div>
          <div className="col-span-1 border-r p-2">Unit value (£)</div>
          <div className="col-span-1 border-r p-2">Uplift (%)</div>
          <div className="col-span-1 border-r p-2">Total value (£)</div>
          <div className="border- col-span-2 p-2">Status</div>
        </div>
      )}
    </div>
  );
};

export default ValueHeader;
