import { useMemo, useRef, useState } from 'react';
import { MapEvent, MapRef } from 'react-map-gl';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useMapStyle } from 'lib/hooks';
import { Location } from 'lib/types';
import { useJobContext } from '../../../../context';
import { addCircleOnLoad } from '../../../JobOverview/components/OverviewMap';
import {
  UpdateSiteLocationMutationData,
  UpdateSiteLocationMutationVariables,
} from './types';

export const useInstantiationLocation = () => {
  const { job } = useJobContext();

  const mapRef = useRef<MapRef>(null);
  const mapStyle = useMapStyle();

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const [updateSiteLocation] = useMutation<
    UpdateSiteLocationMutationData,
    UpdateSiteLocationMutationVariables
  >(
    gql`
      mutation UpdateSiteLocation(
        $siteId: ID!
        $siteLocationEstimate: LocationEstimateInput!
      ) {
        updateSiteLocation(
          siteId: $siteId
          siteLocationEstimate: $siteLocationEstimate
        ) {
          site {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnChange = async (value: Location | undefined) => {
    if (job.partial) return;

    if (!value) {
      console.warn('Location is undefined');
      return;
    }

    const { data, errors } = await updateSiteLocation({
      variables: {
        siteId: job.site.id,
        siteLocationEstimate: value,
      },
    });

    if (data) {
      setEdit(false);
      await job.refetch();
      setOpen(false);
      toast.success('Site location updated');
    }

    if (errors) {
      toast.error('Failed to update site location');
    }
  };

  const point = job.partial
    ? undefined
    : job.site.location?.precise?.geometry?.point;

  const handleMapOnLoad = (event: MapEvent) => {
    const center = event.target.getCenter();
    const bounds = event.target.getBounds();
    const max = bounds.getEast();
    const offset = max - center.lng / 2;
    event.target.jumpTo({
      center: [center.lng - offset / 25, center.lat],
      zoom: 15,
    });
    if (point) addCircleOnLoad(event, point);
  };

  const mapInitialViewState = useMemo(
    () => ({
      zoom: 15,
      ...(point
        ? {
            latitude: point.latitude,
            longitude: point.longitude - 0.002,
          }
        : {
            latitude: 51.4574,
            longitude: 0.1278,
          }),
    }),
    [point],
  );

  return {
    sheet: {
      open,
      edit,
      onOpenChange: setOpen,
      onEditChange: setEdit,
    },
    map: {
      ref: mapRef,
      style: mapStyle,
      initialViewState: mapInitialViewState,
      point,
      onLoad: handleMapOnLoad,
    },
    handlers: {
      onChange: handleOnChange,
    },
  };
};
