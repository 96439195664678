import { gql, useMutation } from '@apollo/client';
import { useCommercialEstimateContext } from '../../../../../../context';
import { useEstimateTableContext } from '../../../../context';
import {
  CreateValueLineMutationData,
  CreateValueLineMutationVariables,
} from './types';

export const useValueCreate = () => {
  const { activity, estimate, contracts } = useCommercialEstimateContext();
  const { type, updateRefetch } = useEstimateTableContext();

  const [create] = useMutation<
    CreateValueLineMutationData,
    CreateValueLineMutationVariables
  >(
    gql`
      mutation CreateCommercialValueLine(
        $estimateId: ID
        $type: String!
        $rate: ID
        $description: String
      ) {
        createCommercialValueLine(
          estimateId: $estimateId
          type: $type
          rate: $rate
          description: $description
        ) {
          commercialValueLine {
            id
            rate {
              id
            }
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (values: any) => {
    if (!estimate) return;

    const { data, errors } = await create({
      variables: {
        estimateId: estimate.id,
        type,
        ...values,
      },
    });

    if (data) {
      await updateRefetch();
    }

    if (errors) {
    }
  };

  const handleOnCreateLabor = async (rate: string) => {
    await handleOnCreate({ rate });
  };

  const handleOnCreateMaterial = async (item: string, mode: string) => {
    if (mode === 'create') {
      await handleOnCreate({ description: item });
      return;
    }

    await handleOnCreate({ item });
  };

  return {
    contracts,
    rates: activity.contract.rates,
    handlers: {
      onCreateLabor: handleOnCreateLabor,
      onCreateMaterial: handleOnCreateMaterial,
    },
  };
};
