import React, { useEffect, useState } from 'react';
import { ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router';
import { Button } from '@/components/ui/button';
import { Loader } from '@/components/ui/loading';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import { Interval } from 'luxon';
import { StoreProvider } from 'lib/hooks';
import {
  HorizontalCalendar,
  VerticalCalendarSelector,
} from '../../components/VerticalCalendar';
import RosterDutyTable from './components/RosterDutyTable';
import RosterExceedances from './components/RosterExceedances';
import RosterSubmit from './components/RosterSubmit';
import {
  useInitialQuery,
  useTimeIntervalOnChange,
  useUserOnChange,
} from './hooks';
import { useStore } from './state';

const HumanResourcesRoster = () => {
  const navigate = useNavigate();

  const { loading: isLoading } = useInitialQuery();

  const { interval, rosterTeam, user, rosterDuties, hasLoaded, reset } =
    useStore((state) => ({
      interval: Interval.fromDateTimes(state.dateTimeStart, state.dateTimeEnd),
      rosterTeams: state.rosterTeams,
      rosterTeam: state.rosterTeam,
      rosterDuties: state.rosterDuties,
      rosterDutiesInMonth: state.rosterDutiesInMonth,
      user: state.user,
      hasLoaded: state.hasLoaded,
      reset: state.reset,
    }));

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const userOnChange = useUserOnChange();
  const timeIntervalOnChange = useTimeIntervalOnChange();

  if (!hasLoaded) {
    return null;
  }

  const handleRangeOnClick = (range: Interval) => {
    if (!rosterTeam || !range?.start || !range?.end) return;
    navigate(
      `/hr/rosters/${rosterTeam.id}/${encodeURIComponent(
        `${range.start.toMillis()}::${range.end.toMillis()}`,
      )}`,
    );
    timeIntervalOnChange(range.start, range.end);
  };

  if (!rosterTeam) return null;

  return (
    <StoreProvider value={useStore}>
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel
          className="bg-background-secondary"
          defaultSize={20}
          minSize={20}
        >
          <div className="flex h-full flex-col">
            <div className="flex h-[52px] flex-shrink-0 items-center gap-x-2 border-b px-4 py-2">
              <h1
                className={cn('text-xl font-bold', {
                  'font-normal': !!user,
                })}
              >
                {rosterTeam.name}
              </h1>
              {user && (
                <ArrowRight className="ml-auto h-4 w-4 text-muted-foreground" />
              )}
            </div>
            <div className="p-4">
              {interval.start && interval.end && (
                <VerticalCalendarSelector
                  isLoading={isLoading}
                  interval={interval}
                  intervalOnChange={handleRangeOnClick}
                  variables={{
                    team: rosterTeam.id,
                    user: user?.id,
                  }}
                  deps={[rosterDuties]}
                />
              )}
            </div>
            <Separator />
            <div className="grow">
              <ScrollArea>
                <div>
                  {rosterTeam.membersGroup.users.map((inner) => {
                    const { isLoading, onClick } = userOnChange(inner);
                    return (
                      <Button
                        key={inner.id}
                        className={cn(
                          'group/week relative block h-auto w-full border-b p-4 text-left',
                          'rounded-none fill-background last:border-b-0 hover:fill-muted',
                          {
                            'bg-muted': user?.id === inner.id,
                            'group/week-selected': user?.id === inner.id,
                            'fill-muted': user?.id === inner.id,
                          },
                        )}
                        variant="ghost"
                        onClick={onClick}
                      >
                        {isLoading && (
                          <div className="absolute right-4 top-4">
                            <Loader />
                          </div>
                        )}
                        <p
                          className={cn('mb-2', {
                            'font-medium': user?.id !== inner.id,
                            'font-bold': user?.id === inner.id,
                          })}
                        >
                          {inner.name}
                        </p>
                        <HorizontalCalendar
                          interval={interval}
                          variables={{
                            team: rosterTeam.id,
                            user: inner.id,
                          }}
                          deps={[rosterDuties]}
                        />
                      </Button>
                    );
                  })}
                </div>
              </ScrollArea>
            </div>
          </div>
        </ResizablePanel>
        <ResizableHandle />
        <ResizablePanel>
          {user ? (
            <div className="flex h-full flex-col">
              <div className="flex h-[52px] flex-shrink-0 items-center gap-x-2 border-b px-4">
                <div className="flex grow items-center gap-4">
                  <h1 className="text-xl font-bold">{user.name}</h1>
                </div>
                <div>{user && <RosterSubmit />}</div>
              </div>
              <div className="grow">
                <ScrollArea>
                  <div>
                    <RosterDutyTable />
                    <Separator />
                  </div>
                </ScrollArea>
              </div>
              <div className="pb-4">
                <Separator />
                <RosterExceedances />
              </div>
            </div>
          ) : (
            <div className="h-full w-full p-4">
              <div className="flex h-full w-full items-center rounded border border-dashed text-center">
                <div className="grow">
                  <h3 className="mt-2 text-sm font-semibold text-gray-600 dark:text-secondary">
                    No user selected
                  </h3>
                  <p className="mt-1 text-sm text-gray-400 dark:text-secondary/75">
                    Select a user from the list to begin.
                  </p>
                </div>
              </div>
            </div>
          )}
        </ResizablePanel>
      </ResizablePanelGroup>
    </StoreProvider>
  );
};

export default HumanResourcesRoster;
