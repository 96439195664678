import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useYjs } from 'lib/hooks';
import { CommercialActivityStatus, CommercialType } from 'lib/types';
import { useJobContext } from '../../../../context';
import CommercialEstimates from '../CommercialEstimates';
import CommercialSelector from '../CommercialSelector';
import ActivityFooter from './components/ActivityFooter';
import ActivityPrelims from './components/ActivityPrelims';
import ActivityTable from './components/ActivityTable';
import ActivityToolbar from './components/ActivityToolbar';
import {
  CommercialActivityContext,
  useCommercialActivityContext,
} from './context';
import { useCommercialActivity } from './hooks';

const CommercialActivity = () => {
  return (
    <CommercialActivityProvider>
      <Routes>
        <Route index element={<CommercialActivityRenderer />} />
        <Route path="estimates" element={<CommercialEstimates />} />
      </Routes>
    </CommercialActivityProvider>
  );
};

export const CommercialActivityProvider = ({
  children,
  loader = null,
}: PropsWithChildren<{ loader?: ReactNode }>) => {
  const { job } = useJobContext();
  const { context } = useCommercialActivity();

  const { isReady, doc, store } = useYjs(
    'commercial::commercial-activity',
    context.activity ? `${context.activity.id}::edit` : undefined,
    { order: [] } as { order: string[] },
    { debugName: `${job.reference} commercial activity` },
  );

  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (!isReady || !store || !doc?.current || checked || !context.activity)
      return;

    for (const line of [
      ...context.activity.valueLines,
      ...context.activity.costLines,
    ]) {
      if (!store.order.includes(line.id)) {
        store.order.push(line.id);
        console.debug('Added line to store', line.id);
      }
    }

    setChecked(true);
    console.debug('Checked store', JSON.stringify(store.order));
  }, [isReady, store, checked, context.activity]);

  if (!store || !doc?.current) return <>{loader}</>;

  return (
    <CommercialActivityContext.Provider
      value={{
        ...context,
        store,
        doc: doc.current,
      }}
    >
      {children}
    </CommercialActivityContext.Provider>
  );
};

const CommercialActivityRenderer = () => {
  const { activity, sections } = useCommercialActivityContext();

  if (activity.status === CommercialActivityStatus.Estimating) {
    return <CommercialEstimates />;
  }

  return (
    <>
      <ActivityToolbar />
      <ScrollArea className="min-h-0 grow">
        <div className="flex flex-col gap-y-4 p-4">
          <ActivityPrelims />
          <ActivityTable type={CommercialType.Labor} color="#ffa600" />
          <ActivityTable type={CommercialType.Material} color="#ffa600" />
          <ActivityTable type={CommercialType.Equipment} color="#ffa600" />
          <ActivityTable type={CommercialType.Subcontractor} color="#ffa600" />
          <CommercialSelector sections={sections} />
        </div>
      </ScrollArea>
      <ActivityFooter />
    </>
  );
};

export default CommercialActivity;
