import React from 'react';
import { Crosshair } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { ScrollArea } from '@/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { Empty } from 'lib/shared';
import { useLocationPickerContext } from '../../hooks';

const PickerSearch = () => {
  const {
    search,
    placingMarker,
    marked,
    setSearch,
    setPlacingMarker,
    estimation: { results, estimating, estimate },
    setFocused,
    fitBounds,
    handleOnSelect,
  } = useLocationPickerContext();
  return (
    <Command
      className="flex min-h-0 grow flex-col rounded-none"
      shouldFilter={false}
    >
      <CommandInput
        className="text-gray-400 focus:text-primary"
        value={search}
        loading={estimating}
        onValueChange={setSearch}
        onKeyDown={async (event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            const { bounds, padding } = await estimate({ query: search });
            fitBounds(bounds, padding);
          }
        }}
      />
      <ScrollArea className="grow">
        <CommandList
          className={cn('max-h-none min-h-0 grow transition-all', {
            'opacity-0': placingMarker || estimating,
          })}
        >
          {results.length === 0 && !estimating ? (
            <div className="h-32 p-4">
              <Empty>
                <p>No results found.</p>
              </Empty>
            </div>
          ) : (
            <CommandGroup heading="Results">
              {results
                .filter((result) => result.type === 'PLACE')
                .map((result, index) => {
                  return (
                    <CommandItem
                      key={result.id}
                      className="gap-x-2"
                      value={result.id}
                      onSelect={() => {
                        handleOnSelect(result);
                        setFocused(result);
                      }}
                    >
                      <div className="rounded-full bg-incoming-foreground/25 p-2">
                        <div className="relative h-4 w-4 rounded-full bg-incoming-foreground">
                          <span
                            className={cn(
                              'absolute left-1/2 top-1/2 -translate-y-1/2 text-xs',
                              {
                                '-translate-x-[55%]': index === 1,
                                '-translate-x-[45%]': index !== 1,
                              },
                            )}
                          >
                            {index + 1}
                          </span>
                        </div>
                      </div>
                      <div className="min-w-0 grow overflow-hidden">
                        <p className="truncate text-sm font-medium">
                          {result.data.displayName?.text}
                        </p>
                        <p className="truncate text-xs leading-5 text-gray-500 dark:text-gray-400">
                          {result.data.formattedAddress}
                        </p>
                      </div>
                    </CommandItem>
                  );
                })}
            </CommandGroup>
          )}
        </CommandList>
      </ScrollArea>
      <div
        className={cn(
          'h-[99px] flex-shrink-0 translate-y-0 overflow-hidden border-t p-4 transition-all',
          { 'h-0 py-0': placingMarker },
        )}
      >
        {marked ? (
          <div className="mb-0.5 flex h-[64px] w-full justify-start rounded-md border px-4 text-start">
            <div className="flex grow items-center gap-x-2">
              <Crosshair className="h-8 w-8" />
              <div className="flex grow items-center justify-between">
                <div>
                  <p>Marked location</p>
                  <p>
                    {marked.location.lat.toFixed(6)},{' '}
                    {marked.location.lng.toFixed(6)}
                  </p>
                </div>
                <Button variant="outline" size="sm">
                  Reselect
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <Button
            className="mb-0.5 h-[64px] w-full justify-start border text-start"
            disabled={placingMarker}
            variant="outline"
            onClick={() => {
              setPlacingMarker((prevPlacingMarker) => {
                if (prevPlacingMarker) {
                  setFocused(null);
                  return false;
                }
                return true;
              });
            }}
          >
            <div className="flex grow items-center gap-x-2">
              <Crosshair className="h-8 w-8" />
              {placingMarker ? (
                <div>
                  <p>Placing marker...</p>
                  <p className="text-xs dark:text-gray-400">
                    Move the map to place the marker.
                  </p>
                </div>
              ) : (
                <div>
                  <p>Place marker</p>
                  <p className="text-xs dark:text-gray-400">
                    If you can't find the location, you can place a marker on
                    the map.
                  </p>
                </div>
              )}
            </div>
          </Button>
        )}
      </div>
    </Command>
  );
};

export default PickerSearch;
