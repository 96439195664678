import { useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { TableMode } from './components/StaffTable/types';
import { Mode, UsersQueryData, UsersQueryVariables } from './types';

export const useCompanyStaff = () => {
  const [mode, setMode] = useState<Mode>(Mode.Table);
  const [tableMode, setTableMode] = useState<TableMode>(TableMode.Active);

  const { data, refetch } = useQuery<UsersQueryData, UsersQueryVariables>(
    gql`
      query GetUsers($isActive: Boolean!) {
        users(isActive: $isActive) {
          id
          name
          email
          initials
          platformId
          externalId
          driverKeyFobId
          jobTitle
          lineManager {
            id
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        isActive: mode === Mode.Chart || tableMode === TableMode.Active,
      },
    },
  );

  const users = useMemo(
    () => data?.users?.filter((user) => user.email !== 'AnonymousUser') ?? [],
    [data],
  );

  return {
    mode: {
      value: mode,
      set: setMode,
    },
    context: {
      users,
      refetch,
      tableMode: {
        value: tableMode,
        set: setTableMode,
      },
    },
  };
};
