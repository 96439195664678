import { Client } from './clients';
import { Location } from './locations';
import { PlatformObject } from './platform';
import { TimesheetActivity } from './timesheets';

export type Subcontractor = {
  id: string;
  logo?: PlatformObject;
  name: string;
  slug: string;
  clients: Client[];
  duties: SubcontractorDuty[];
  policies: SubcontractorPolicy[];
  locations: SubcontractorLocation[];
  contacts: SubcontractorContact[];
  refetch: () => Promise<any>;
};

export type SubcontractorDuty = {
  id: string;
  timesheetActivity: TimesheetActivity;
  subcontractor: Subcontractor;
  dateTimeStart: string;
  dateTimeEnd: string;
  reports: SubcontractorReport[];
};

export enum SubcontractorReportStatus {
  ReviewPending = 'REVIEW_PENDING',
  Reviewed = 'REVIEWED',
}

export type SubcontractorReport = {
  id: string;
  sourceObject: PlatformObject;
  status: SubcontractorReportStatus;
  statusDisplay: string;
  subcontractor: Subcontractor;
};

export type SubcontractorRequirement = {
  id: string;
  name: string;
  description?: string;
  dateTimeCreated: string;
  dateApplies?: string;
};

export type SubcontractorPolicy = {
  id: string;
  requirement?: SubcontractorRequirement;
  documents: PlatformObject[];
  notes?: string;
  dateValid?: string;
  dateExpiry?: string;
};

export type SubcontractorLocation = {
  id: string;
  name: string;
  contacts: SubcontractorContact[];
  location?: Location;
};

export type SubcontractorContact = {
  id: string;
  name: string;
  email?: string;
  phone?: string;
  mobile?: string;
  notes?: string;
  locations: SubcontractorLocation[];
};
