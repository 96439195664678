import { Edit } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { z } from 'zod';
import { useAccountingUpdate } from './hooks';

const AccountingUpdate = () => {
  const { contract, sheet, handlers } = useAccountingUpdate();

  if (!contract) return null;

  return (
    <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
      <SheetTrigger asChild>
        <Button variant="ghost" size="icon-sm">
          <Edit className="size-4" />
        </Button>
      </SheetTrigger>
      <SheetContent>
        <FormRenderer
          fields={[
            {
              type: 'input',
              name: 'customer',
              label: 'Customer',
              schema: z.string().min(2),
              defaultValue: contract.accounting?.customer,
            },
            {
              type: 'input',
              name: 'searchCode',
              label: 'Search Code',
              schema: z.string().min(2),
              defaultValue: contract.accounting?.searchCode,
            },
            {
              type: 'input',
              name: 'nominalCostCentre',
              label: 'Nominal Cost Centre',
              schema: z.string().min(2),
              defaultValue: contract.accounting?.nominalCostCentre,
            },
            {
              type: 'input',
              name: 'authPo',
              label: 'Auth PO',
              schema: z.string().min(2),
              defaultValue: contract.accounting?.authPo,
            },
            {
              type: 'input',
              name: 'approvedValue',
              label: 'Approved Value',
              schema: z.string().min(2),
              defaultValue: contract.accounting?.approvedValue,
            },
            {
              type: 'input',
              name: 'contractManager',
              label: 'Contract Manager',
              schema: z.string().min(2),
              defaultValue: contract.accounting?.contractManager,
            },
          ]}
          onSubmit={handlers.onSubmit}
        >
          {({ form, onSubmit }) => (
            <Button
              size="sm"
              variant="secondary"
              className="w-full"
              disabled={!form.formState.isValid}
              onClick={onSubmit}
            >
              Update
            </Button>
          )}
        </FormRenderer>
      </SheetContent>
    </Sheet>
  );
};

export default AccountingUpdate;
