import React, { useCallback } from 'react';
import { Duration } from 'luxon';
import { AssignmentCrosshairProps } from './types';

export const useAssignmentCrosshair = ({
  min,
  max,
  onValueChange,
}: Pick<AssignmentCrosshairProps, 'min' | 'max' | 'onValueChange'>) => {
  const handleOnWheel = useCallback(
    (event: React.WheelEvent) => {
      onValueChange((value) => {
        const nextValue = value.minus({ minutes: (event.deltaY / 100) * 5 });
        if (nextValue.as('minutes') < min.as('minutes')) {
          return min;
        }
        if (nextValue.as('minutes') > max.as('minutes')) {
          return max;
        }
        return nextValue;
      });
    },
    [min, max, onValueChange],
  );

  const calculateWidth = useCallback(
    (duration: Duration) => {
      return (duration.as('minutes') / max.as('minutes')) * 100;
    },
    [max],
  );

  return {
    handleOnWheel,
    calculateWidth,
  };
};
