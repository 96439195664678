import { createContext } from 'react';
import { PlatformObject } from 'lib/types';
import { MediaContextType } from './types';

export const MediaContext = createContext<MediaContextType>({
  registeredObjects: [] as Omit<PlatformObject, 'sources'>[],
  registeredObjectSources: [],
  registerObjects: () => {},
  registerObjectSources: () => {},
  deregisterObjects: () => {},
});

export const MediaBoundaryContext = createContext<
  Pick<MediaContextType, 'registeredObjects' | 'registeredObjectSources'>
>({
  registeredObjects: [] as Omit<PlatformObject, 'sources'>[],
  registeredObjectSources: [],
});
