import React, { useMemo } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { CheckCheck, Search } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Link } from 'lib/shared';
import { PlatformTodo } from 'lib/types';
import { useTodoContext } from '../TodosProvider/hooks';
import TodoCardList from './components/TodoCardList';
import { useTodos } from './hooks';
import { TodosProps, TodosVariant } from './types';

type TableTodo = Pick<PlatformTodo, 'id' | 'title' | 'urgency'> & {
  description: string;
  to: string;
};

const Todos = ({
  variant,
  scope,
  status,
  todoOnClick,
  todoOnClose,
  queryFn,
  groupKey,
}: TodosProps) => {
  const { selected, selectTodo, clearSelectedTodo } = useTodoContext();

  const {
    todos: allTodos = [],
    groups = [],
    searchTerm,
    searchTermOnChange,
  } = useTodos({
    scope,
    status,
    sort: { urgency: 'desc' },
    pagination: { page_size: 10 },
    queryFn,
    groupKey,
  });

  const todos = allTodos.filter((todo) => !!todo.data.card);

  const columns = useMemo<ColumnDef<TableTodo>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Title',
        cell: ({ renderValue }) => (
          <p className="font-semibold">{renderValue() as string}</p>
        ),
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'urgency',
        header: 'Urgency',
        sortDescFirst: true,
      },
      {
        accessorKey: 'id',
        header: undefined,
        cell: ({ row: { original } }) => (
          <div className="flex items-center justify-end">
            <Link to={original.to}>
              <Button variant="secondary" size="xs">
                View
              </Button>
            </Link>
          </div>
        ),
      },
    ],
    [],
  );

  const data = useMemo(
    () =>
      todos.map((todo) => ({
        id: todo.id,
        title: todo.title,
        urgency: todo.urgency,
        description: todo.data.card.description,
        to: todo.data.window.initialPath,
      })),
    [todos],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (variant === TodosVariant.CardList) {
    return (
      <div>
        <div className="border-b bg-background/95 px-4 pb-4 backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <form>
            <div className="relative">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search"
                className="pl-8"
                value={searchTerm}
                onChange={(event) => {
                  searchTermOnChange(event.target.value);
                }}
              />
            </div>
          </form>
        </div>
        <div>
          {groups.length > 0 ? (
            <TodoCardList
              selected={selected}
              groups={groups}
              todos={todos}
              todoOnClick={todoOnClick ?? selectTodo}
              todoOnClose={todoOnClose ?? clearSelectedTodo}
            />
          ) : (
            <div className="pt-4 text-center">
              <CheckCheck className="mx-auto" />
              <h3 className="mt-2 text-sm font-semibold text-gray-900 dark:text-white/50">
                No todos
              </h3>
              <p className="mt-1 text-sm text-gray-500">All done.</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (variant === TodosVariant.Table) {
    return (
      <Card className="h-full">
        <CardHeader>
          <CardTitle>Todos</CardTitle>
          <CardDescription>
            Invite your team members to collaborate.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }

  return null;
};

export default Todos;
