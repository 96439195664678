import React from 'react';
import { Item } from 'external/types';
import ReportStack from '../../../../../ReportStack';
import { useReportBlockFormContext } from '../../context';
import FormBlock from '../FormBlock';
import { FormPageProps } from './types';

const FormPage = ({ page }: FormPageProps) => {
  const { template } = useReportBlockFormContext();
  return (
    <ReportStack>
      {page.children
        .map((itemId) => template.items[itemId])
        .map((item) => (
          <FormBlock
            key={item.id}
            item={item as Item}
            path={`root::${page.id}::${item.id}`}
          />
        ))}
    </ReportStack>
  );
};

export default FormPage;
