import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { z } from 'zod';
import { useSubcontractorReportCreate } from './hooks';
import { SubcontractorReportCreateProps } from './types';

const SubcontractorReportCreate = ({
  duty,
}: SubcontractorReportCreateProps) => {
  const { state, handlers } = useSubcontractorReportCreate(duty);
  return (
    <Dialog open={state.open} onOpenChange={state.onOpenChange}>
      <DialogTrigger asChild>
        <Button variant="outline" size="xs" className="pl-2 font-bold">
          <Plus className="mr-1 size-4" />
          Provide report
        </Button>
      </DialogTrigger>
      <DialogContent overlay>
        <FormRenderer
          fields={[
            {
              name: 'sourceObject',
              label: 'Document',
              type: 'file',
              description: 'Upload the provided subcontractor report',
              schema: z.any(),
            },
          ]}
          onSubmit={handlers.onCreate}
        >
          {({ rendererContext, onSubmit }) => (
            <div className="flex justify-end">
              <Button
                className="w-full"
                size="sm"
                type="submit"
                disabled={rendererContext.disabled}
                onClick={onSubmit}
              >
                Submit
              </Button>
            </div>
          )}
        </FormRenderer>
      </DialogContent>
    </Dialog>
  );
};

export default SubcontractorReportCreate;
