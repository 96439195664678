import React from 'react';
import ButtonTabs from '@/components/ui/button-tabs';
import TodosTable from './TodosTable';
import TodosWorkspace from './TodosWorkspace';
import { ClientTodosContext } from './context';
import { useClientTodos } from './hooks';
import { ViewMode } from './types';

const ClientTodos = () => {
  const { context, handlers } = useClientTodos();
  return (
    <ClientTodosContext.Provider value={context}>
      <div className="grid h-[52px] flex-shrink-0 grid-cols-12 items-center border-b px-4 py-2">
        <h1 className="col-span-1 text-xl font-bold">Todos</h1>
        <div className="col-span-4"></div>
        <div className="col-span-7 flex justify-end">
          <ButtonTabs
            tabs={[
              {
                label: 'Workspace',
                value: ViewMode.Workspace,
              },
              {
                label: 'Table',
                value: ViewMode.Table,
              },
            ]}
            value={context.mode.value}
            onValueChange={handlers.onValueChange}
          />
        </div>
      </div>
      {context.mode.value === ViewMode.Workspace && <TodosWorkspace />}
      {context.mode.value === ViewMode.Table && <TodosTable />}
    </ClientTodosContext.Provider>
  );
};

export default ClientTodos;
