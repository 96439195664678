import React from 'react';
import { PageLoader } from 'lib/shared';
import { useJobContext } from '../../context';
import IssueActivity from './components/IssueActivity';
import IssueBody from './components/IssueBody';
import IssueFeed from './components/IssueFeed';
import IssueHeader from './components/IssueHeader';
import IssueInput from './components/IssueInput';
import IssueManagement from './components/IssueManagement';
import IssueSource from './components/IssueSource';
import IssueToolbar from './components/IssueToolbar';
import { JobIssueContext } from './context';
import { useJobIssue } from './hooks';

const JobIssue = () => {
  const { state } = useJobContext();
  const { context } = useJobIssue();

  if (!context.issue) {
    return <PageLoader className="p-4">Loading issue...</PageLoader>;
  }

  if (state.small) {
    return (
      <JobIssueContext.Provider value={context}>
        <IssueToolbar />
        <IssueBody>
          <IssueFeed>
            {({ feed, onSend }) => (
              <>
                <IssueHeader />
                <IssueActivity feed={feed} />
                <IssueInput onSend={onSend} />
              </>
            )}
          </IssueFeed>
        </IssueBody>
      </JobIssueContext.Provider>
    );
  }

  return (
    <JobIssueContext.Provider value={context}>
      <IssueToolbar />
      <IssueBody>
        <IssueSource />
        <IssueFeed>
          {({ feed, onSend }) => (
            <>
              <IssueHeader />
              <IssueActivity feed={feed} />
              <IssueInput onSend={onSend} />
            </>
          )}
        </IssueFeed>
        <IssueManagement />
      </IssueBody>
    </JobIssueContext.Provider>
  );
};

export default JobIssue;
