import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { Empty } from 'lib/shared';
import { useSchedulerContext } from '../../hooks';
import { SchedulerAction, UpdateType } from '../../types';
import PendingCreate from './components/PendingCreate';
import PendingDelete from './components/PendingDelete';
import PendingUpdate from './components/PendingUpdate';
import { useControlsPending } from './hooks';

const ControlsPending = () => {
  const { actions, updates } = useSchedulerContext();
  const {
    open,
    confirmAllDisabled,
    onRevert,
    onConfirm,
    onConfirmAll,
    onOpenChange,
  } = useControlsPending();
  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetTrigger asChild>
        <Button
          size="sm"
          disabled={updates.all.length === 0}
          variant={updates.all.length > 0 ? 'default' : 'outline'}
        >
          {updates.pending.length > 0 ? (
            <>
              Confirm {updates.pending.length} update
              {updates.pending.length !== 1 && 's'}
              <ArrowRight className="ml-1 h-4 w-4" />
            </>
          ) : (
            <>No updates to confirm</>
          )}
        </Button>
      </SheetTrigger>
      <SheetContent
        overlay={false}
        className="flex w-[500px] max-w-[500px] flex-col gap-0 p-0 sm:max-w-[500px]"
      >
        <SheetHeader className="p-4">
          <SheetTitle>Assignment updates</SheetTitle>
          <SheetDescription>Confirm or revert pending updates</SheetDescription>
        </SheetHeader>
        <div className="min-h-0 grow">
          <ScrollArea className="h-full">
            <div className="flex flex-col gap-y-4 px-4">
              {updates.all.length > 0 ? (
                updates.all.map((update) => {
                  const props = {
                    update,
                    onHighlight: actions.factory({
                      description: `Highlighted event`,
                      type: SchedulerAction.HighlightEvent,
                      id: update.diff.id,
                    }),
                    onRevert: onRevert(update),
                    onConfirm: onConfirm(update),
                  };
                  switch (update.type) {
                    case UpdateType.Create:
                      return <PendingCreate {...props} key={update.diff.id} />;
                    case UpdateType.Update:
                    case UpdateType.UpdateTransfer:
                      return <PendingUpdate {...props} key={update.diff.id} />;
                    case UpdateType.Delete:
                      return <PendingDelete {...props} key={update.diff.id} />;
                  }
                })
              ) : (
                <div className="h-32">
                  <Empty>No pending updates</Empty>
                </div>
              )}
            </div>
          </ScrollArea>
        </div>
        <div className="p-4">
          <Button
            size="sm"
            className="w-full"
            variant={confirmAllDisabled ? 'outline' : 'default'}
            disabled={confirmAllDisabled}
            onClick={async () => {
              await onConfirmAll();
            }}
          >
            {updates.pending.length === 0
              ? 'No updates'
              : updates.pending.length > 1
                ? `Confirm ${updates.pending.length} updates`
                : 'Confirm update'}
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default ControlsPending;
