import React from 'react';
import { Report, ReportBlockComponentProps, ReportMode } from 'lib/shared';
import { Job } from 'lib/types';
import { parseForClient } from 'lib/utils';
import { platformClient } from '../../../../../../../../../../app/platform';

const BlockHeader = ({ context }: ReportBlockComponentProps) => {
  const { mode, theme } = Report.useContext();
  const { job, title } = parseForClient(context) as { job: Job; title: string };
  const { data } = Report.useQueryFactory(
    job.client.logo?.id,
    platformClient.getObject(job.client.logo?.id as string),
  );
  return (
    <Report.View
      style={{
        paddingTop: mode === ReportMode.Edit ? 28 : 0,
        paddingLeft: 32,
        paddingRight: 32,
        paddingBottom: 24,
        marginBottom: 28,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.colors.border,
      }}
    >
      <Report.View
        style={{
          marginBottom: 24,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Report.View style={{ width: '50%' }}>
          <Report.View style={{ width: 32, marginBottom: 12 }}>
            <Report.Logo style={{ width: 32 }} />
          </Report.View>
          <Report.Text
            style={{
              fontSize: 13,
              fontWeight: 500,
              lineHeight: '1.75',
              color: theme.colors.subtitle,
            }}
          >
            429a Footscray Road, New Eltham,
          </Report.Text>
          <Report.Text
            style={{
              fontSize: 13,
              fontWeight: 500,
              lineHeight: '1.75',
              color: theme.colors.subtitle,
            }}
          >
            London, SE9 3UL
          </Report.Text>
        </Report.View>
        <Report.View style={{ width: '50%' }}>
          <Report.View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Report.View style={{ marginBottom: 12 }}>
              <Report.Image
                ignoreOnMount
                src={data?.getUrl}
                style={{ height: 43 }}
              />
            </Report.View>
          </Report.View>
          <Report.Text
            style={{
              textAlign: 'right',
              fontSize: 13,
              fontWeight: 500,
              lineHeight: '1.75',
              whiteSpace: 'nowrap',
              color: theme.colors.subtitle,
            }}
          >
            Civic Offices, 2 Watling Street, Bexleyheath
          </Report.Text>
          <Report.Text
            style={{
              textAlign: 'right',
              fontSize: 13,
              fontWeight: 500,
              lineHeight: '1.75',
              color: theme.colors.subtitle,
            }}
          >
            London, DA6 7AT
          </Report.Text>
        </Report.View>
      </Report.View>
      <Report.Text
        style={{
          color: '#106c12',
          fontSize: 14,
          fontWeight: 600,
          marginBottom: 4,
          textTransform: 'uppercase',
        }}
      >
        {job.scope.split('::').at(-1)?.replace(/-/g, ' ')}
      </Report.Text>
      <Report.Text style={{ fontSize: 18, fontWeight: 600, marginBottom: 4 }}>
        {job.reference}
      </Report.Text>
      <Report.Text
        style={{ fontSize: 14, fontWeight: 500, color: theme.colors.subtitle }}
      >
        {title}
      </Report.Text>
    </Report.View>
  );
};

export default BlockHeader;
