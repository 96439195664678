import React from 'react';
import { Button } from '@/components/ui/button';
import { TableRenderer } from '@/components/ui/table/table/Table';
import { DateTime } from 'luxon';
import { Link } from 'lib/shared';
import { useClient } from '../../../../hooks';
import ContractsCreate from './components/ContractsCreate';
import { useCommercialContracts } from './hooks';

const CommercialContracts = () => {
  const client = useClient();
  const { contracts } = useCommercialContracts();
  return (
    <div className="p-4">
      <div>
        <p className="mb-1 text-sm font-bold">Contracts</p>
        <p className="text-xs text-gray-400">
          Manage your contracts here. You can create, edit, and delete
          contracts.
        </p>
      </div>
      <TableRenderer
        filter="name"
        columns={[
          {
            id: 'name',
            accessorKey: 'name',
            header: 'Name',
            cell: ({ row }) => (
              <Link
                to={client.paths.commercial.contract(
                  row.original.slug,
                  row.original.dateStart,
                  row.original.dateEnd,
                )}
              >
                <Button variant="table" size="table">
                  {row.original.name}
                </Button>
              </Link>
            ),
          },
          {
            header: 'Start date',
            accessorFn: ({ dateStart }) =>
              DateTime.fromISO(dateStart).toLocaleString(DateTime.DATE_SHORT),
          },
          {
            header: 'End date',
            accessorFn: ({ dateEnd }) =>
              DateTime.fromISO(dateEnd).toLocaleString(DateTime.DATE_SHORT),
          },
          {
            header: 'Rates',
            cell: ({ row }) => {
              return <p>{row.original.rates.length}</p>;
            },
          },
        ]}
        data={contracts}
        topRight={({ columnsSelector }) => (
          <div className="ml-auto space-x-2">
            <ContractsCreate />
            {columnsSelector}
          </div>
        )}
      />
    </div>
  );
};

export default CommercialContracts;
