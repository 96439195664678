import { gql } from '@apollo/client';

export const APPLY_JOB_TASK_ASSIGNMENT_UPDATE = gql`
  mutation ApplyJobTaskAssignmentUpdate(
    $jobTaskId: ID!
    $update: JobTaskAssignmentUpdate!
  ) {
    applyJobTaskAssignmentUpdate(jobTaskId: $jobTaskId, update: $update) {
      jobTask {
        id
      }
      timesheetDuty {
        id
        dateTimeCreated
        dateTimeStart
        dateTimeStartCondition
        dateTimeEnd
        dateTimeEndCondition
        events {
          id
          dateTimeStart
          dateTimeStartType
          dateTimeEnd
          dateTimeEndType
        }
        user {
          id
          name
        }
        subcontractor {
          id
        }
        activity {
          id
          jobTask {
            id
            name
            job {
              id
              client {
                id
                name
              }
              reference
            }
            number
          }
        }
      }
      subcontractorDuty {
        id
        dateTimeStart
        dateTimeEnd
      }
    }
  }
`;
