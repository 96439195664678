import { createContext, useContext } from 'react';
import { User } from 'lib/types';

export const UserSheetContext = createContext(
  {} as {
    sheet: {
      open: boolean;
      onOpenChange: (open: boolean) => void;
      onClose: () => void;
    };
    user: User;
  },
);

export const useUserSheetContext = () => {
  return useContext(UserSheetContext);
};
