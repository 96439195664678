import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from '@uidotdev/usehooks';
import { ViewMode } from '../CommercialEstimate/types';

export const useCommercialEstimates = () => {
  const [params, setParams] = useSearchParams();

  const [selected, setSelected] = useState<string | undefined>(
    params.get('estimate') || undefined,
  );

  useEffect(() => {
    const next = params.get('estimate') || '';
    if (next !== selected) {
      setSelected(next);
    }
  }, [params]);

  const handleOnSelect = (next: string | undefined) => {
    if (next) {
      setParams((prevParams) => {
        const params = new URLSearchParams(prevParams);
        params.set('estimate', next);
        return params.toString();
      });
      setSelected(next);
    } else {
      setParams((prevParams) => {
        const params = new URLSearchParams(prevParams);
        params.delete('estimate');
        return params.toString();
      });
      setSelected(undefined);
    }
  };

  const [mode, setMode] = useLocalStorage(
    'commercial-estimate-view-mode',
    ViewMode.Split,
  );

  return {
    context: {
      state: {
        selected,
      },
      mode: {
        value: mode,
        set: setMode,
      },
      handlers: {
        onSelect: handleOnSelect,
      },
    },
  };
};
