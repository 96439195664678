import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { TableRenderer } from '@/components/ui/table/table/Table';
import { useTrainingStaffMemberContext } from '../../context';
import MemberTrainingEditor from '../MemberTrainingEditor';
import MemberTrainingForm from '../MemberTrainingForm';
import TrainingCreate from './components/TrainingCreate';

const MemberTraining = () => {
  const { user } = useTrainingStaffMemberContext();

  if (!user) {
    return null;
  }

  return (
    <div className="rounded-lg border bg-background px-4 pt-4">
      <div>
        <h3 className="mb-1">Qualifications</h3>
        <p className="text-xs dark:text-white/75">
          Training records for this user.
        </p>
      </div>
      <div className="flex flex-col gap-y-4">
        <TableRenderer
          // @ts-ignore
          filter="qualification.name"
          columns={[
            {
              id: 'name',
              accessorKey: 'qualification.name',
              header: 'Name',
              cell: ({ row }) => {
                return <MemberTrainingEditor training={row.original} />;
              },
            },
            {
              id: 'dateValid',
              accessorKey: 'dateValid',
              header: 'Date Valid',
              className: 'whitespace-nowrap',
            },
            {
              id: 'dateExpiry',
              accessorKey: 'dateExpiry',
              header: 'Date Expiry',
              className: 'whitespace-nowrap',
            },
          ]}
          data={user.training}
          topRight={({ columnsSelector }) => (
            <div className="ml-auto flex gap-x-2">
              {columnsSelector}
              <TrainingCreate />
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default MemberTraining;
