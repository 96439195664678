import { Cpu } from 'lucide-react';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { DateTime } from 'luxon';
import { ScopeLabel } from 'lib/shared';
import { ActivityEventProps } from './types';

const ActivityEvent = ({ event }: ActivityEventProps) => {
  return (
    <div className="flex-start relative z-[2] flex items-center gap-x-2 pt-8">
      <Avatar className="-ml-0.5 size-9 border-4 border-secondary text-xs">
        <AvatarFallback className=" bg-background-secondary dark:bg-muted">
          <Cpu className="size-4" />
        </AvatarFallback>
      </Avatar>
      <div className="rounded-full bg-background px-4 py-1 text-sm">
        <ScopeLabel label={event.data.label} />
      </div>
      <p className="text-xs font-semibold dark:text-gray-400">
        {DateTime.fromSeconds(event.timestamp).toLocaleString(
          DateTime.DATETIME_MED,
        )}
      </p>
    </div>
  );
};

export default ActivityEvent;
