import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import {
  Notification,
  PlatformActionSet,
  PlatformIdentityGroup,
  PlatformIdentityScope,
  PlatformObject,
  PlatformTodo,
} from 'lib/types';
import { createTokenString } from 'lib/utils';
import { PlatformEvent } from '../external/Events';
import snakeCaseToCamelCase from '../utils/snakeCaseToCamelCase';
import { msalApplication } from './hooks';

type Trace = {
  id: string;
  parts: TracePart[];
};

type TracePart = {
  id: string;
  service: string;
  logs: TracePartLog[];
};

type TracePartLog = {
  level: string;
  message: string;
};

export const platformCache = new InMemoryCache();

const appsyncConfig = {
  url: `https://${import.meta.env.PUBLIC_PLATFORM_REALTIME_API_HOST}/graphql`,
  region: import.meta.env.PUBLIC_PLATFORM_API_REGION,
  auth: {
    type: AUTH_TYPE.AWS_LAMBDA,
    token: async () => {
      const request = { scopes: [import.meta.env.PUBLIC_AZURE_SCOPE] };
      const { accessToken } = await msalApplication.acquireTokenSilent(request);
      return `Bearer ${createTokenString('azure', accessToken)}`;
    },
  } as AuthOptions,
};

export const platformSubscriptionClient = new ApolloClient({
  cache: platformCache,
  link: ApolloLink.from([
    createAuthLink(appsyncConfig),
    createSubscriptionHandshakeLink(appsyncConfig),
  ]),
});

export type PerformActionData = any;

export type PerformActionRequest = {
  action_set_id: string;
  action_id: string;
  action_form_hook_id?: string;
  action_form_submission?: any;
  scope: string;
  context?: any;
  camelcase_to_snakecase?: boolean;
  disable_atomic_requests?: boolean;
  dryrun?: boolean;
};

export type PlatformError = {
  message: string;
  traceId: string;
  tracePartId: string;
};

export class PlatformClient {
  host: string;

  constructor(host?: string) {
    this.host = host ?? import.meta.env.PUBLIC_PLATFORM_API_HOST;
  }

  async getHeaders() {
    const { accessToken } = await msalApplication.acquireTokenSilent({
      scopes: [import.meta.env.PUBLIC_AZURE_SCOPE],
    });
    return {
      authorization: `Bearer ${createTokenString('azure', accessToken)}`,
    };
  }

  makeQueryFn<Response = any, Request = any>(
    service: string,
    path: string,
    body?: Request,
  ) {
    return async () => {
      const response = await fetch(`${this.host}/v1/${service}/${path}`, {
        method: 'POST',
        headers: await this.getHeaders(),
        body: JSON.stringify(body ?? {}, (_, value) =>
          value === undefined ? null : value,
        ),
      });
      return snakeCaseToCamelCase(await response.json()) as Response;
    };
  }

  makeMutationFn<
    Response = any,
    Request = any,
    DefaultRequest = Partial<Request>,
  >(service: string, path: string, body?: DefaultRequest) {
    return async (request: Omit<Request, keyof DefaultRequest>) => {
      const response = await fetch(`${this.host}/v1/${service}/${path}`, {
        method: 'POST',
        headers: await this.getHeaders(),
        body: JSON.stringify(
          Array.isArray(request) ? request : { ...(body ?? {}), ...request },
          (_, value) => (value === undefined ? null : value),
        ),
      });
      return snakeCaseToCamelCase(await response.json()) as Response;
    };
  }

  getIndexes() {
    return this.makeQueryFn('datagraph', 'get_indexes');
  }

  getIndex(index: string) {
    return this.makeQueryFn('datagraph', 'get_index', { index });
  }

  searchIndex(
    index: string,
    {
      query,
      sort,
      pagination,
      includeData = false,
    }: {
      query: any;
      sort?: any;
      pagination?: any;
      includeData?: boolean;
    },
  ) {
    return this.makeQueryFn('datagraph', 'search_index', {
      index,
      query,
      sort,
      pagination,
      include_data: includeData,
    });
  }

  createIndex({
    numberOfShards,
    numberOfReplicas,
  }: {
    numberOfShards: number;
    numberOfReplicas: number;
  }) {
    return this.makeMutationFn<
      any,
      {
        name: string;
        settings: {
          number_of_shards: number;
          number_of_replicas: number;
        };
      }
    >('datagraph', 'create_index', {
      settings: {
        number_of_shards: numberOfShards,
        number_of_replicas: numberOfReplicas,
      },
    });
  }

  createNodes() {
    return this.makeMutationFn('datagraph', 'create_nodes');
  }

  deleteNodes() {
    return this.makeMutationFn('datagraph', 'delete_nodes');
  }

  getActionSet(actionSetId: string | undefined, context?: any, scope?: string) {
    return this.makeQueryFn<PlatformActionSet>('actions', 'get_action_set', {
      id: actionSetId,
      context,
      scope,
    });
  }

  performAction<P = Partial<PerformActionRequest>>(body?: P) {
    return this.makeMutationFn<
      PerformActionData,
      PerformActionRequest,
      Partial<P>
    >('actions', 'perform_action', body);
  }

  getIdentityGroup({
    id,
    includeAnnotatedScopes,
  }: {
    id: string;
    includeAnnotatedScopes?: boolean;
  }) {
    return this.makeQueryFn<PlatformIdentityGroup>(
      'identity',
      'get_identity_group',
      {
        id,
        include_annotated_scopes: includeAnnotatedScopes,
      },
    );
  }

  getIdentityScopes(prefix?: string) {
    return this.makeQueryFn<PlatformIdentityScope[], { prefix?: string }>(
      'identity',
      'get_identity_scopes',
      prefix ? { prefix } : undefined,
    );
  }

  createIdentityPermission() {
    return this.makeMutationFn('identity', 'create_identity_permission');
  }

  createIdentityGroupPermission() {
    return this.makeMutationFn('identity', 'create_identity_group_permission');
  }

  updateIdentityPermission() {
    return this.makeMutationFn('identity', 'update_identity_permission');
  }

  deleteIdentityPermission() {
    return this.makeMutationFn('identity', 'delete_identity_permission');
  }

  getEvents(scope: string | undefined, excludeNames?: string[]) {
    return this.makeQueryFn<{ events: PlatformEvent[] }>(
      'events',
      'get_events',
      {
        scope,
        exclude_names: excludeNames,
      },
    );
  }

  getFormTemplates(scope?: string) {
    return this.makeQueryFn('forms', 'get_form_templates', { scope });
  }

  getFormTemplate(formTemplateId: string) {
    return this.makeQueryFn('forms', 'get_form_template', {
      id: formTemplateId,
    });
  }

  createFormTemplate() {
    return this.makeMutationFn('forms', 'create_form_template');
  }

  updateFormTemplate() {
    return this.makeMutationFn('forms', 'update_form_template');
  }

  publishFormTemplate() {
    return this.makeMutationFn('forms', 'publish_form_template');
  }

  getTodos({
    scope,
    status,
    pagination,
    sort,
    searchTerm,
  }: {
    scope?: string;
    status?: string;
    sort?: any;
    pagination?: any;
    searchTerm?: string;
  }) {
    return this.makeQueryFn<{ results: PlatformTodo[] }>('todos', 'get_todos', {
      scope,
      status,
      pagination,
      sort,
      search_term: searchTerm,
      annotations: ['urgency'],
    });
  }

  getTodosForIdentity({
    status,
    pagination,
    sort,
    searchTerm,
  }: {
    status?: string;
    sort?: any;
    pagination?: any;
    searchTerm?: string;
  }) {
    return this.makeQueryFn<{ results: PlatformTodo[] }>(
      'todos',
      'get_todos_for_identity',
      {
        status,
        pagination,
        sort,
        search_term: searchTerm,
        annotations: ['urgency'],
      },
    );
  }

  getTrace(traceId: string, includeParts = true) {
    return this.makeQueryFn<{ trace: Trace }>('tracing', 'get_trace', {
      id: traceId,
      include_parts: includeParts,
    });
  }

  getObjects(ids: string[]) {
    return this.makeQueryFn<PlatformObject[]>('objects', 'get_objects', {
      ids,
    });
  }

  getObject(id: string) {
    return this.makeQueryFn<PlatformObject>('objects', 'get_object', {
      id,
    });
  }

  prepareObjectUpload(object: PlatformObject) {
    return {
      ...object,
      postData: object.postData
        ? JSON.parse(object.postData as unknown as string)
        : undefined,
    };
  }

  createObjects() {
    return async (
      request: Pick<PlatformObject, 'id' | 'name' | 'type' | 'size'>[],
    ) => {
      const objects = await this.makeMutationFn<PlatformObject[]>(
        'objects',
        'create_objects',
      )(request);
      return objects.map(this.prepareObjectUpload);
    };
  }

  createObject() {
    return async (request: Pick<PlatformObject, 'id' | 'name' | 'type'>) => {
      const object = await this.makeMutationFn<PlatformObject>(
        'objects',
        'create_object',
      )(request);
      return this.prepareObjectUpload(object);
    };
  }

  getNotifications() {
    return this.makeQueryFn<Notification[]>(
      'notifications',
      'get_notifications',
    );
  }

  readNotifications() {
    return this.makeMutationFn<any, { ids: string[] }>(
      'notifications',
      'read_notifications',
    );
  }

  readAllNotifications() {
    return this.makeMutationFn('notifications', 'read_all_notifications');
  }

  getMessageGroup({
    id,
    includeMessages,
  }: {
    id: string;
    includeMessages: boolean;
  }) {
    return this.makeQueryFn('messaging', 'get_message_group', {
      id,
      include_messages: includeMessages,
    });
  }

  sendMessage() {
    return this.makeMutationFn('messaging', 'send_message');
  }

  explainAnnotation(request: any) {
    return this.makeQueryFn('datagraph', 'explain_annotation', request);
  }

  getDoc(reference: string) {
    return this.makeQueryFn('docs', 'get_doc', { reference });
  }

  searchEverything(query: string) {
    return this.makeQueryFn('datagraph', 'search_everything', {
      query,
      index_pattern: '',
    });
  }

  searchEvents(index: string, query: any) {
    return this.makeQueryFn('datagraph', 'search_events', { index, query });
  }

  // Higher level examples.
  getDriverUpdatesByVehicleEsn() {
    return this.searchEvents('tenant__mchugh__verizon_driver_updates_event', {
      persisted_query_reference: 'verizon-driver-updates-by-vehicle-esn',
    });
  }
}

export const platformClient = new PlatformClient();
