import { Box } from '@syncedstore/core';
import type { MappedTypeDescription } from '@syncedstore/core/types/doc';
import * as Y from 'yjs';
import { User } from 'lib/types';

export type InstantiationPresence = Pick<User, 'id' | 'name' | 'email'>;

export type InstantiationRendererProps = {
  store: MappedTypeDescription<InstantiationStore>;
  doc: Y.Doc;
};

export type InstantiationStore = {
  stages: Record<InstantiationStage['id'], InstantiationStage>;
  history: Box<InstantiationHistoryItem>[];
};

export type InstantiationStage = {
  id: string;
  isConfirmed: boolean;
  issues: InstantiationStageIssue[];
};

export type InstantiationStageIssue = {
  id: string;
  type: string | undefined;
  note: string;
  editor?: InstantiationPresence;
  draft?: Pick<InstantiationStageIssue, 'type' | 'note'>;
};

export type InstantiationStageIssueType = {
  label: string;
  value: string;
  color: string;
};

export type InstantiationHistoryItem = {
  id: string;
  action: InstantiationHistoryItemAction;
  dateTime: string;
  snapshot: string;
  user: InstantiationPresence;
};

export enum InstantiationHistoryItemAction {
  ConfirmStage = 'CONFIRM_STAGE',
  ResetStage = 'RESET_STAGE',
  CreateIssue = 'CREATE_ISSUE',
  UpdateIssue = 'UPDATE_ISSUE',
  DeleteIssue = 'DELETE_ISSUE',
}
