import { matchPath } from 'react-router-dom';

export const getRouteMatch = (path: string, patterns: readonly string[]) => {
  for (const pattern of [...patterns].sort((a, b) => b.length - a.length)) {
    const possibleMatch = matchPath(pattern, path);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
};
