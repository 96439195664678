import { useContext, useState } from 'react';
import { generateId } from 'lib/utils';
import { BaseActions } from '../../utils/createSectionState';
import { StoreContext } from './store';
import { LocationStore } from './types';


export const useStore = <T>(
  selector: (state: LocationStore & BaseActions<LocationStore>) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }
  return store(selector, equalityFn);
};

export const useWorkspaceHandle = () => {
  const { workspaceHandle, apply } = useStore((state) => ({
    workspaceHandle: state.workspaceHandle,
    apply: state.apply,
  }));

  const [internalWorkspaceHandle, setInternalWorkspaceHandle] = useState<
    string | undefined
  >(undefined);

  return {
    isActiveWorkspaceHandle:
      workspaceHandle && workspaceHandle === internalWorkspaceHandle,
    workspaceHandle,
    internalWorkspaceHandle,
    generateWorkspaceHandle: () => {
      const newWorkspaceHandle = generateId();
      setInternalWorkspaceHandle(newWorkspaceHandle);
      apply((state) => {
        state.workspaceHandle = newWorkspaceHandle;
      });
    },
  };
};
