import { Edit } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  number,
  time,
} from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import {
  Sheet,
  SheetContentExtension,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { DateTime } from 'luxon';
import { z } from 'zod';
import { useContractRateContext } from '../../context';
import { useRateUnitValueTransition } from './hooks';

const RateUnitValueTransition = () => {
  const { rate } = useContractRateContext();
  const { sheet, handlers } = useRateUnitValueTransition();

  if (!rate) {
    return null;
  }

  return (
    <div className="flex items-center rounded-md border p-4 pr-2">
      <div className="flex-grow">
        <div className="mb-0.5 text-xs dark:text-gray-400">
          Unit Value Transition
        </div>
        <div className="mb-0.5 font-semibold">£{rate.unitValueTransition}</div>
        <div className="text-xs">Time: {rate.unitValueTransitionTime}</div>
      </div>
      <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
        <SheetTrigger asChild>
          <Button variant="link" size="sm">
            <Edit className="mr-1 size-3" />
            Update
          </Button>
        </SheetTrigger>
        <SheetContentExtension right={500}>
          <SheetHeader className="p-4">
            <SheetTitle>Update transition value</SheetTitle>
            <SheetDescription>
              Update the transition value for this contract rate.
            </SheetDescription>
          </SheetHeader>
          <FormRenderer
            className="mx-4"
            fields={[
              number({
                name: 'unitValueTransition',
                label: 'Unit Value Transition',
                schema: z.number().min(0),
                defaultValue: rate.unitValueTransition,
              }),
              time({
                name: 'unitValueTransitionTime',
                label: 'Unit Value Transition Time',
                schema: z.any(),
                defaultValue: rate.unitValueTransitionTime
                  ? (DateTime.fromISO(
                      rate.unitValueTransitionTime,
                    ) as unknown as any)
                  : undefined,
              }),
            ]}
            onSubmit={handlers.onSubmit}
          >
            {({ onSubmit }) => (
              <div className="mt-4 flex justify-end">
                <Button
                  className="w-full"
                  variant="outline"
                  size="sm"
                  type="submit"
                  onClick={onSubmit}
                >
                  Update
                </Button>
              </div>
            )}
          </FormRenderer>
        </SheetContentExtension>
      </Sheet>
    </div>
  );
};

export default RateUnitValueTransition;
