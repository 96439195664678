import React from 'react';
import { GripVertical } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { CommandItem } from '@/components/ui/command';
import { cn } from '@/lib/utils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DebouncedNumberInput, TableSelector } from 'lib/shared';
import { CommercialType } from 'lib/types';
import CommercialUnitField from '../../../../../../../CommercialUnitField';
import { useActivityTableContext } from '../../../../context';
import CostMenu from '../CostMenu';
import { useTableCost } from './hooks';
import { CostLineProps } from './types';

const CostLine = ({ line }: CostLineProps) => {
  const { type } = useActivityTableContext();
  const { resources, handlers } = useTableCost(line);

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: line.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className={cn('relative z-0 flex ring-1', {
        'z-10': isDragging,
        'ring-border': !isDragging,
      })}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div className="grid w-7/12 grid-cols-8">
        <div className="col-span-3 flex border-r bg-background">
          <div className="w-8 flex-shrink-0 border-r">
            <Button
              ref={setActivatorNodeRef}
              variant="ghost"
              className="size-8 cursor-grab rounded-none p-0"
              {...listeners}
            >
              <GripVertical className="size-4" />
            </Button>
          </div>
          {(type === CommercialType.Prelim ||
            type === CommercialType.Labor ||
            type === CommercialType.Subcontractor) && (
            <TableSelector
              className="text-sm"
              placeholder="Select rate"
              searchPlaceholder="Search resources"
              value={line.rate?.id}
              valueDisplay={line.rate?.user.name}
              onChange={async () => {}}
            >
              {({ disabled, onSelect }) => (
                <>
                  {resources.map((resource) => (
                    <CommandItem
                      key={resource.id}
                      value={resource.id}
                      keywords={[resource.user.name]}
                      className="cursor-pointer"
                      active={resource.id === line.rate?.id}
                      disabled={disabled}
                      onSelect={onSelect}
                    >
                      <div className="flex w-full items-center justify-between">
                        <p className="text-[13px]">{resource.user.name}</p>
                      </div>
                    </CommandItem>
                  ))}
                </>
              )}
            </TableSelector>
          )}
          {type === CommercialType.Material && (
            <TableSelector
              className="text-sm"
              popoverClassName="border-material/75"
              placeholder="Select material"
              searchPlaceholder="Search material"
              value={line.description}
              valueDisplay={line.description}
              searchDefault={line.description}
              onChange={async () => {}}
            >
              {({ onSelect }) => null}
            </TableSelector>
          )}
        </div>
        <div className="col-span-1 flex items-center border-r">
          <CommercialUnitField
            readOnly={!!line.rate}
            value={line.unit}
            valueDisplay={line.unitDisplay}
            onValueChange={handlers.unitOnChange}
          />
        </div>
        <div className="col-span-1 border-r bg-background">
          <DebouncedNumberInput
            className="h-full rounded-none border-none px-2 hover:bg-secondary focus:bg-secondary"
            min={0}
            value={line.estimatedQuantity?.toFixed(2)}
            onChange={handlers.estimatedQuantityOnChange}
          />
        </div>
        <div className="col-span-1 border-r bg-background">
          <DebouncedNumberInput
            className="h-full rounded-none border-none px-2 hover:bg-secondary focus:bg-secondary"
            min={0}
            value={line.estimatedUnitCost?.toFixed(2)}
            onChange={handlers.estimatedCostOnChange}
          />
        </div>
        <div className="col-span-1 flex items-center border-r bg-background px-2 text-sm">
          {line.estimatedTotalCost?.toFixed(2)}
        </div>
        <div className="col-span-1" />
      </div>
      {line.actualQuantity ? (
        <div className="grid w-5/12 grid-cols-6">
          <div className="col-span-1 flex items-center border-r bg-background px-2 text-sm">
            {line.actualQuantity?.toFixed(2)}
          </div>
          <div className="col-span-1 flex items-center border-r bg-background px-2 text-sm">
            {line.actualUnitCost?.toFixed(2)}
          </div>
          <div className="col-span-1 flex items-center border-r bg-background px-2 text-sm">
            {line.actualTotalCost?.toFixed(2)}
          </div>
          <div className="col-span-1" />
          <div className="col-span-2 flex">
            {(type === CommercialType.Labor ||
              type === CommercialType.Subcontractor) && (
              <div className="flex grow items-center border-r px-2 text-sm">
                Completed
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="grid w-5/12 grid-cols-6">
          <div className="col-span-1 flex items-center gap-x-2 border-r bg-background px-2 text-sm italic">
            {line.plannedQuantity === null ? null : (
              <>
                {line.plannedQuantity.toFixed(2)}
                <span className="text-[11px] dark:text-gray-400">
                  (Planned)
                </span>
              </>
            )}
          </div>
          <div className="col-span-1 flex items-center gap-x-2 border-r bg-background px-2 text-sm italic">
            {line.plannedUnitCost === null ? null : (
              <>
                {line.plannedUnitCost.toFixed(2)}
                <span className="text-[11px] dark:text-gray-400">
                  (Planned)
                </span>
              </>
            )}
          </div>
          <div className="col-span-1 flex items-center gap-x-2 border-r bg-background px-2 text-sm italic">
            {line.plannedTotalCost === null ? null : (
              <>
                {line.plannedTotalCost.toFixed(2)}
                <span className="text-[11px] dark:text-gray-400">
                  (Planned)
                </span>
              </>
            )}
          </div>
          <div className="col-span-1" />
          <div className="col-span-2 flex bg-background">
            {(type === CommercialType.Labor ||
              type === CommercialType.Subcontractor) && (
              <div className="flex grow items-center border-r px-2 text-sm">
                {line.plannedQuantity === null ? 'Unassigned' : 'Assigned'}
              </div>
            )}
            {type === CommercialType.Material && (
              <div className="flex grow items-center border-r px-2 text-sm">
                Ordered
              </div>
            )}
            <CostMenu line={line} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CostLine;
