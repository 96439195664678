import React from 'react';
import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';
import { usePlatformObject } from 'lib/hooks';
import Conditional from '../Conditional';
import FlashbangWarning from '../FlashbangWarning';
import ViewerToolbar from './components/ViewerToolbar';
import { useToolbar } from './hooks';
import { PdfViewerProps } from './types';

const PdfViewer = ({ objectId, flashbangWarning = true }: PdfViewerProps) => {
  const toolbar = useToolbar();
  const { object } = usePlatformObject(objectId);
  return (
    <div className="mx-auto h-full min-h-0 overflow-hidden bg-background">
      {object?.getUrl && (
        <div className="flex h-full flex-col stroke-white">
          <ViewerToolbar toolbar={toolbar} />
          <Conditional wrapper={<FlashbangWarning />} wrap={flashbangWarning}>
            <div className="min-h-0 grow">
              <Viewer
                fileUrl={object.getUrl}
                plugins={[toolbar]}
                defaultScale={SpecialZoomLevel.PageWidth}
              />
            </div>
          </Conditional>
        </div>
      )}
    </div>
  );
};

export default PdfViewer;
