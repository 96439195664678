import React from 'react';
import { Button } from '@/components/ui/button';
import {
  date,
  file,
  image,
  input,
  textarea,
} from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { DateTime } from 'luxon';
import { z } from 'zod';
import { PdfSheet } from 'lib/shared';
import { useMembershipsEditor } from './hooks';
import { MembershipsEditorProps } from './types';

const MembershipsEditor = ({ membership }: MembershipsEditorProps) => {
  const { sheet, handlers } = useMembershipsEditor(membership);
  return (
    <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
      <SheetTrigger asChild>
        <Button size="table" variant="table">
          {membership.name}
        </Button>
      </SheetTrigger>
      <SheetContent className="flex h-full min-h-0 flex-col p-0">
        <SheetHeader className="p-4">
          <SheetTitle>Update Company Membership</SheetTitle>
          <SheetDescription>Update company membership</SheetDescription>
        </SheetHeader>
        <ScrollArea className="min-h-0 flex-grow pb-4">
          <FormRenderer
            className="mx-4"
            fields={[
              image({
                name: 'logo',
                label: 'Logo',
                schema: z.any(),
                description: 'The logo of the accreditation',
                defaultValue: membership.logo as any,
              }),
              input({
                name: 'name',
                label: 'Name',
                schema: z.string().min(1),
                defaultValue: membership.name,
              }),
              textarea({
                name: 'description',
                label: 'Description',
                schema: z.string().min(1),
                defaultValue: membership.description,
              }),
              date({
                name: 'dateTimeExpiry',
                label: 'Expiry Date',
                schema: z.any(),
                timescape: true,
                description: 'The date the accreditation expires',
                defaultValue: membership.dateTimeExpiry
                  ? (DateTime.fromISO(membership.dateTimeExpiry) as any)
                  : undefined,
              }),
              file({
                name: 'document',
                label: 'Document',
                schema: z.any(),
                description: 'The document of the accreditation',
                defaultValue: membership.document as any,
                right: 384,
              }),
            ]}
            onSubmit={handlers.onUpdate}
          >
            {({ onSubmit }) => (
              <div className="flex gap-x-2 pt-2">
                <Button size="sm" className="w-1/2" onClick={onSubmit}>
                  Update
                </Button>
                <Button
                  size="sm"
                  variant="destructive"
                  confirm="Are you sure?"
                  className="w-1/2"
                  onClick={handlers.onDelete}
                >
                  Delete
                </Button>
              </div>
            )}
          </FormRenderer>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

export default MembershipsEditor;
