import { gql, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { toast } from 'sonner';
import { useDebounceCallback, useParentStore } from 'lib/hooks';
import { RosterDuty } from 'lib/types';
import { RosterDutyTableState } from '../RosterDutyTable/state';
import {
  CreateRosterDutyMutationData,
  CreateRosterDutyMutationVariables,
  DeleteRosterDutyMutationData,
  DeleteRosterDutyMutationVariables,
  UpdateRosterDutyMutationData,
  UpdateRosterDutyMutationVariables,
} from './types';

export const useCreateRosterDuty = (
  day: DateTime,
  shiftType: 'DAY' | 'NIGHT',
) => {
  const useStore = useParentStore<RosterDutyTableState>();

  const {
    stage,
    user,
    rosterTeam,
    dateTimeStart,
    dateTimeEnd,
    onCreateRosterDuty,
  } = useStore((state) => ({
    stage: state.stage,
    user: state.user,
    rosterTeam: state.rosterTeam,
    dateTimeStart: state.dateTimeStart,
    dateTimeEnd: state.dateTimeEnd,
    onCreateRosterDuty: state.onCreateRosterDuty,
  }));

  const [createRosterDuty, { loading: isCreating }] = useMutation<
    CreateRosterDutyMutationData,
    CreateRosterDutyMutationVariables
  >(gql`
    mutation (
      $dateTimeStart: DateTime!
      $shiftType: String!
      $stage: Int!
      $teamId: ID!
      $userId: ID!
      $rangeDateTimeStart: DateTime!
      $rangeDateTimeEnd: DateTime!
    ) {
      createRosterDuty(
        dateStart: $dateTimeStart
        shiftType: $shiftType
        stage: $stage
        teamId: $teamId
        userId: $userId
        rangeDateTimeStart: $rangeDateTimeStart
        rangeDateTimeEnd: $rangeDateTimeEnd
      ) {
        rosterDuty {
          id
          commuteTime
          dateTimeStart
          dateTimeEnd
          shiftType {
            label
            value
          }
          user {
            id
            name
            initials
          }
          team {
            id
            name
          }
          settings
          isPristine
          isSettingsPristine
        }
        validationResults {
          fatigueIndices
          riskIndices
          exceedances {
            offence
            offenceValueType
            offenceValue
            duty {
              id
              dateTimeStart
              dateTimeEnd
              shiftType {
                label
                value
              }
            }
          }
        }
      }
    }
  `);

  const handleOnCreate = async () => {
    if (!user) return;
    if (!rosterTeam) return;

    const { data } = await createRosterDuty({
      variables: {
        dateTimeStart: day.toISO() as string,
        shiftType,
        stage,
        teamId: rosterTeam.id,
        userId: user.id,
        rangeDateTimeStart: dateTimeStart,
        rangeDateTimeEnd: dateTimeEnd,
      },
    });

    if (data) onCreateRosterDuty(data);
  };

  return { createRosterDuty: handleOnCreate, isCreating };
};

export const useUpdateRosterDuty = (rosterDuty: RosterDuty) => {
  const useStore = useParentStore<RosterDutyTableState>();

  const {
    stage,
    dateTimeStart,
    dateTimeEnd,
    onUpdateRosterDuty,
    onUpdateRosterCompleted,
  } = useStore((state) => ({
    stage: state.stage,
    dateTimeStart: state.dateTimeStart,
    dateTimeEnd: state.dateTimeEnd,
    onUpdateRosterDuty: state.onUpdateRosterDuty,
    onUpdateRosterCompleted: state.onUpdateRosterCompleted,
  }));

  const [updateRosterDuty, { loading: isUpdating }] = useMutation<
    UpdateRosterDutyMutationData,
    UpdateRosterDutyMutationVariables
  >(gql`
    mutation (
      $stage: Int!
      $rosterDutyId: ID!
      $field: String!
      $fieldValue: JSONScalarInput!
      $rangeDateTimeStart: DateTime!
      $rangeDateTimeEnd: DateTime!
    ) {
      updateRosterDuty(
        stage: $stage
        rosterDutyId: $rosterDutyId
        field: $field
        fieldValue: $fieldValue
        rangeDateTimeStart: $rangeDateTimeStart
        rangeDateTimeEnd: $rangeDateTimeEnd
      ) {
        rosterDuty {
          id
          isPristine
          isDeleted
          isSettingsPristine
          settings
          status
          shiftType {
            label
            value
          }
        }
        validationResults {
          fatigueIndices
          riskIndices
          exceedances {
            offence
            offenceValueType
            offenceValue
            duty {
              id
              dateTimeStart
              dateTimeEnd
              shiftType {
                label
                value
              }
            }
            review {
              id
              duty {
                id
              }
            }
          }
        }
      }
    }
  `);

  const updateRosterDutyDebounced = useDebounceCallback(updateRosterDuty);

  const handleOnUpdate = async <F extends keyof RosterDuty>(
    field: F,
    fieldValue: RosterDuty[F],
    debounce = true,
  ) => {
    onUpdateRosterDuty(rosterDuty, field, fieldValue);
    const { data } = await (debounce
      ? updateRosterDutyDebounced
      : updateRosterDuty)({
      variables: {
        rosterDutyId: rosterDuty.id,
        field,
        fieldValue,
        stage,
        rangeDateTimeStart: dateTimeStart,
        rangeDateTimeEnd: dateTimeEnd,
      },
    });

    if (data) {
      onUpdateRosterCompleted(data);
      toast.success('Roster duty updated successfully');
    }
  };

  return { updateRosterDuty: handleOnUpdate, isUpdating };
};

export const useDeleteRosterDuty = (rosterDuty: RosterDuty) => {
  const useStore = useParentStore<RosterDutyTableState>();

  const { stage, dateTimeStart, dateTimeEnd, onDeleteRosterDuty } = useStore(
    (state) => ({
      stage: state.stage,
      dateTimeStart: state.dateTimeStart,
      dateTimeEnd: state.dateTimeEnd,
      onDeleteRosterDuty: state.onDeleteRosterDuty,
    }),
  );

  const [deleteRosterDuty, { loading: isDeleting }] = useMutation<
    DeleteRosterDutyMutationData,
    DeleteRosterDutyMutationVariables
  >(gql`
    mutation (
      $stage: Int!
      $rosterDutyId: ID!
      $rangeDateTimeStart: DateTime!
      $rangeDateTimeEnd: DateTime!
    ) {
      deleteRosterDuty(
        stage: $stage
        rosterDutyId: $rosterDutyId
        rangeDateTimeStart: $rangeDateTimeStart
        rangeDateTimeEnd: $rangeDateTimeEnd
      ) {
        isDeleted
        deletedId
        rosterDuty {
          id
          isPristine
          isDeleted
        }
        validationResults {
          fatigueIndices
          riskIndices
          exceedances {
            offence
            offenceValueType
            offenceValue
            duty {
              id
              dateTimeStart
              dateTimeEnd
            }
          }
        }
      }
    }
  `);

  const handleOnDelete = async () => {
    const { data } = await deleteRosterDuty({
      variables: {
        stage,
        rosterDutyId: rosterDuty.id,
        rangeDateTimeStart: dateTimeStart,
        rangeDateTimeEnd: dateTimeEnd,
      },
    });

    if (data) onDeleteRosterDuty(data);
  };

  return { deleteRosterDuty: handleOnDelete, isDeleting };
};
