import React from 'react';
import {
  PendingSubcontractorDiffProps,
  PendingSubcontractorProps,
} from './types';

const PendingSubcontractor = ({
  className,
  value,
}: PendingSubcontractorProps) => {
  return (
    <div className={className}>
      <div className="mb-1">
        <p className="text-xs font-semibold uppercase tracking-wide text-gray-400 dark:text-gray-300">
          Subcontractor
        </p>
      </div>
      <div className="flex items-center gap-x-2">
        <p className="font-medium">{value.name}</p>
      </div>
    </div>
  );
};

const PendingSubcontractorDiff = ({
  className,
  duty,
  diff,
}: PendingSubcontractorDiffProps) => {
  const isSame = duty.id === diff.id;

  return (
    <div className={className}>
      <div className="mb-1 flex items-center justify-between">
        <p className="text-xs font-semibold uppercase tracking-wide text-gray-400 dark:text-gray-300">
          Subcontractor
        </p>
        {isSame ? (
          <p className="text-sm dark:text-gray-400">(unchanged)</p>
        ) : (
          <p className="text-sm dark:text-gray-400">(transferred)</p>
        )}
      </div>
      <div className="flex justify-between">
        {isSame ? (
          '-'
        ) : (
          <div className="flex items-center gap-x-2 line-through dark:text-gray-400">
            <p className="font-medium">{duty.name}</p>
          </div>
        )}
        <div className="flex items-center gap-x-2">
          <p className="font-medium">{diff.name}</p>
        </div>
      </div>
    </div>
  );
};

PendingSubcontractor.Diff = PendingSubcontractorDiff;

export default PendingSubcontractor;
