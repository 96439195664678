import { useEffect } from 'react';
import { useWindowManager } from '../shared/WindowManager/hooks';

export const useTitle = (title: string) => {
  const { windowed } = useWindowManager();

  useEffect(() => {
    if (windowed) {
      return;
    }

    const prevTitle = window.document.title;
    window.document.title = title;

    return () => {
      window.document.title = prevTitle;
    };
  }, [title, windowed]);
};
