import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useSubcontractorContext } from '../../../../context';
import {
  CreateSubcontractorLocationMutationData,
  CreateSubcontractorLocationMutationVariables,
} from './types';

export const useLocationsCreate = () => {
  const { subcontractor } = useSubcontractorContext();

  const [open, setOpen] = useState(false);

  const [create] = useMutation<
    CreateSubcontractorLocationMutationData,
    CreateSubcontractorLocationMutationVariables
  >(
    gql`
      mutation CreateSubcontractorLocation(
        $id: ID!
        $data: CreateSubcontractorLocationInput!
      ) {
        createSubcontractorLocation(
          subcontractorId: $id
          subcontractorLocationData: $data
        ) {
          subcontractorLocation {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnSubmit = async (values: any) => {
    if (!subcontractor) return;

    const { data, errors } = await create({
      variables: {
        id: subcontractor.id,
        data: {
          name: values.name,
          location: values.location,
        },
      },
    });

    if (data) {
      await subcontractor.refetch();
      setOpen(false);
      toast.success('Location created');
    }

    if (errors) {
      console.error(errors);
      toast.error('Failed to create location');
    }
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onSubmit: handleOnSubmit,
    },
  };
};
