import React from 'react';
import {
  CreateCommercialResourceRateMutationData,
  CreateCommercialResourceRateMutationVariables,
} from '@/components/ui/user/components/UserResourceRates/types';
import { useUserSheetContext } from '@/components/ui/user/context';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';

export const useUserResourceRates = () => {
  const { user } = useUserSheetContext();

  const [open, setOpen] = React.useState(false);

  const [create] = useMutation<
    CreateCommercialResourceRateMutationData,
    CreateCommercialResourceRateMutationVariables
  >(
    gql`
      mutation CreateCommercialResourceRate(
        $userId: ID!
        $description: String!
        $base: Boolean!
        $unit: String!
        $unitCost: Float!
        $dateStart: DateTime!
        $dateEnd: DateTime!
      ) {
        createCommercialResourceRate(
          userId: $userId
          description: $description
          base: $base
          unit: $unit
          unitCost: $unitCost
          dateStart: $dateStart
          dateEnd: $dateEnd
        ) {
          commercialResourceRate {
            id
            description
            base
            unit
            unitDisplay
            unitCost
            dateStart
            dateEnd
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (values: any) => {
    const { data, errors } = await create({
      variables: {
        userId: user.id,
        description: values.description,
        base: values.base,
        unit: values.unit,
        unitCost: values.unitCost,
        dateStart: values.dateStart,
        dateEnd: values.dateEnd,
      },
    });

    if (data) {
      await user.refetch();
      setOpen(false);
      toast.success('Resource rate created');
    }

    if (errors) {
      console.error(errors);
      toast.error('Resource rate creation failed');
    }
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    rates: user.rates ?? [],
    handlers: {
      onCreate: handleOnCreate,
    },
  };
};
