import React, { useMemo } from 'react';
import { cn } from '@/lib/utils';
import { formatDistanceToNow } from 'date-fns';
import { DateTime } from 'luxon';
import { StatusBadge } from 'lib/shared';
import { jobTaskStatusColors } from 'lib/theme';
import { JobTask } from 'lib/types';
import { useOverviewFlow } from '../../index';
import { NodeProps, NodeType } from '../../types';
import NodeBase from '../NodeBase';

const NodeJobTask = ({ id, data }: NodeProps<JobTask>) => {
  const { showMeta } = useOverviewFlow();

  const status = useMemo(() => {
    if (data.requiresAssignment) return 'REQUIRES_ASSIGNMENT';
    return data.status;
  }, [data.requiresAssignment, data.status]);

  const nextAim = data.aims.filter((aim) => aim.status === 'PENDING')[0];

  return (
    <NodeBase
      type={NodeType.Task}
      id={id}
      to={`tasks/${data.number}`}
      borderColor={data.requiresAssignment ? 'red' : 'transparent'}
    >
      <div className="rounded-md border-2 bg-background p-3">
        <div className="mb-2 flex h-6 justify-between">
          <p className="text-sm font-semibold text-gray-400">Task</p>
          <StatusBadge status={status} colors={jobTaskStatusColors} />
        </div>
        <div>
          <p>
            <span className={cn({ 'font-semibold': data.requiresAssignment })}>
              {data.name}{' '}
            </span>
            <span className="text-xs text-gray-400">[#{data.number}]</span>
          </p>
        </div>
      </div>
      {showMeta && (
        <>
          {nextAim && (
            <div className="absolute left-2 top-[100%]">
              <p className="whitespace-nowrap text-[10px] font-bold">
                Must {nextAim.condition.replace(/_/g, ' ').toLowerCase()}{' '}
                {DateTime.fromISO(nextAim.target.dateTime).toFormat(
                  'mm:HH dd LLL',
                )}{' '}
                [in{' '}
                {formatDistanceToNow(
                  DateTime.fromISO(nextAim.target.dateTime).toJSDate(),
                )}
                ]
              </p>
            </div>
          )}
        </>
      )}
    </NodeBase>
  );
};

export default NodeJobTask;
