import { useQuery } from '@tanstack/react-query';
import { FormTemplate } from 'lib/types';
import { platformClient } from '../../../../app/platform';

const useFormTemplates = (formTemplateScope: string | undefined) => {
  const { data: formTemplates = [], isLoading } = useQuery<FormTemplate[]>({
    queryFn: platformClient.getFormTemplates(formTemplateScope),
    queryKey: ['formTemplates', formTemplateScope],
    enabled: !!formTemplateScope,
  });

  return {
    formTemplates,
    isLoading,
  };
};

export default useFormTemplates;
