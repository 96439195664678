import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { platformObjectInput } from 'lib/utils';
import { useSubcontractorContext } from '../../../context';
import {
  UpdateSubcontractorPolicyMutationData,
  UpdateSubcontractorPolicyMutationVariables,
} from './types';

export const usePolicyUpdate = () => {
  const { subcontractor } = useSubcontractorContext();

  const [open, setOpen] = useState(false);

  const [update] = useMutation<
    UpdateSubcontractorPolicyMutationData,
    UpdateSubcontractorPolicyMutationVariables
  >(
    gql`
      mutation UpdateSubcontractorPolicy(
        $id: ID!
        $data: UpdateSubcontractorPolicyInput!
      ) {
        updateSubcontractorPolicy(
          subcontractorPolicyId: $id
          subcontractorPolicyData: $data
        ) {
          subcontractorPolicy {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnSubmit = async (values: any) => {
    if (!subcontractor) return;

    const { data, errors } = await update({
      variables: {
        id: subcontractor.id,
        data: {
          dateValid: values.dateValid,
          dateExpiry: values.dateExpiry,
          documents: values.documents.map(platformObjectInput),
          notes: values.notes,
        },
      },
    });

    if (data) {
      await subcontractor.refetch();
      setOpen(false);
      toast.success('Subcontractor policy updated');
    }

    if (errors) {
      console.error(errors);
      toast.error('Failed to update subcontractor policy');
    }
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onSubmit: handleOnSubmit,
    },
  };
};
