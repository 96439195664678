import React from 'react';
import { Edit } from 'lucide-react';
import { ChoiceSet as ChoiceSetType } from '../../types';
import BlockButton from '../BlockButton';
import ChoiceSet from '../ChoiceSet';
import { useChoiceSets } from './hooks';
import { ChoiceSetsProps } from './types';

const ChoiceSets = ({
  item,
  addChoiceSetOnClick,
  editChoiceSetOnClick,
}: ChoiceSetsProps) => {
  const {
    choiceSets,
    handleChoiceSetOnClick,
    handleCreateChoiceSetOnClick,
    handleEditChoiceSetOnClick,
  } = useChoiceSets(item);
  return (
    <div className="flex min-w-[300px] flex-col">
      <div className="flex h-[37px] items-center justify-between border-b pl-4">
        <p className="text-sm font-medium">Choice sets</p>
        <BlockButton
          className="h-full"
          onClick={() => {
            handleCreateChoiceSetOnClick();
            addChoiceSetOnClick?.();
          }}
        >
          Create new
        </BlockButton>
      </div>
      {choiceSets.map((choiceSet) => (
        <ChoiceSetButton
          key={choiceSet.id}
          choiceSet={choiceSet}
          choiceSetOnClick={handleChoiceSetOnClick}
          editChoiceSetOnClick={() => {
            handleEditChoiceSetOnClick(choiceSet)();
            editChoiceSetOnClick?.(choiceSet);
          }}
        />
      ))}
    </div>
  );
};

const ChoiceSetButton = ({
  choiceSet,
  choiceSetOnClick,
  editChoiceSetOnClick,
}: {
  choiceSet: ChoiceSetType;
  choiceSetOnClick: (choiceSet: ChoiceSetType) => () => void;
  editChoiceSetOnClick: () => void;
}) => {
  return (
    <div key={choiceSet.id} className="group relative">
      <BlockButton
        className="h-8 w-full justify-start gap-x-2 text-left"
        onClick={choiceSetOnClick(choiceSet)}
      >
        <ChoiceSet choiceSet={choiceSet} />
      </BlockButton>
      <div className="pointer-events-none absolute inset-0 bg-gradient-to-l from-background-secondary from-[8%] to-20%" />
      <BlockButton
        className="absolute right-0 top-0 bg-none px-2 opacity-25 hover:bg-transparent group-hover:opacity-100"
        onClick={editChoiceSetOnClick}
      >
        <Edit className="size-4" />
      </BlockButton>
    </div>
  );
};

export default ChoiceSets;
