import { createContext, useContext } from 'react';
import { useCompanyInsurances } from './hooks';

export const CompanyInsurancesContext = createContext(
  {} as ReturnType<typeof useCompanyInsurances>['context'],
);

export const useCompanyInsurancesContext = () => {
  return useContext(CompanyInsurancesContext);
};
