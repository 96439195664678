import React, { useCallback, useMemo } from 'react';
import { Dispatch, SetStateAction, useState } from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { BoxIcon, GitBranchIcon, InfoIcon, PlusIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Item, ItemType } from 'external/Tree';
import { BlockData, ContainerData } from 'external/types';
import deepEqual from 'fast-deep-equal';
import { useFormTemplateContext } from '../../context';
import { useCreateItem } from '../../hooks';
import { CONDITIONS, DataType } from '../../types';
import BlockButton from '../BlockButton';
import BlockToggle from '../BlockToggle';
import ControlsDelete from './components/ControlsDelete';
import ControlsLabel from './components/ControlsLabel';
import { useBlockControls } from './hooks';
import { BlockControlsProps } from './types';

function useHovered(): [
  boolean,
  { onMouseEnter: () => void; onMouseLeave: () => void },
  Dispatch<SetStateAction<boolean>>,
];

function useHovered() {
  const [hovered, setHovered] = useState(false);
  const onMouseEnter = useCallback(() => setHovered(true), []);
  const onMouseLeave = useCallback(() => setHovered(false), []);
  return useMemo(
    () => [hovered, { onMouseEnter, onMouseLeave }, setHovered],
    [hovered, onMouseEnter, onMouseLeave],
  );
}

const BlockControls = ({
  item,
  style,
  getBorderBottom,
}: BlockControlsProps) => {
  const { collapsed, store } = useFormTemplateContext();
  const { items } = useSyncedStore(store);
  const {
    isFocused,
    fieldLabel,
    handleAddLogicOnClick,
    handleDeleteOnClick,
    handleLabelOnChange,
    handleLabelOnRemove,
    handleAddLabelOnClick,
  } = useBlockControls(item as Item<BlockData>);

  const isSection = item.type === ItemType.CONTAINER;
  const isCollapsed = collapsed.includes(item.id);
  const itemHasLogic = item.children.some(
    (childId) => items[childId].type === ItemType.LOGIC,
  );

  const createItem = useCreateItem({
    item: items[item.children[item.children.length - 1]],
    parentId: item.id,
  });

  const borderBottom = getBorderBottom('controls');

  const borderBottomLeftRadius = useMemo(() => {
    if (!borderBottom) return 0;

    if (isSection && isFocused) {
      return 4;
    }

    return itemHasLogic || isCollapsed;
  }, [borderBottom, isCollapsed, isFocused, itemHasLogic]);

  const [isHovered, hoveredCallbacks] = useHovered();

  const showIsRequired =
    (item.data.dataType !== 'COMMENT' && item.type !== ItemType.CONTAINER) ||
    (item.type === ItemType.CONTAINER &&
      (item.data as ContainerData).isRepeated);

  const handleHelpTextOnAdd = useCallback(() => {
    items[item.id].data.helpText = { value: '' };
  }, [item.id, items]);

  const handleHelpTextOnRemove = useCallback(() => {
    delete items[item.id].data.helpText;
  }, [item.id, items]);

  return (
    <div
      className={cn('h-[34px] border-x bg-background', {
        'rounded-bl-md': borderBottomLeftRadius,
        'border-b': !!borderBottom,
      })}
      {...hoveredCallbacks}
    >
      <div className="flex h-full">
        <div className="flex flex-1">
          {item.type === ItemType.BLOCK &&
            CONDITIONS?.[item.data.dataType as DataType]?.[0] && (
              <BlockButton className="pl-3" onClick={handleAddLogicOnClick}>
                <GitBranchIcon className="mr-1 size-4" />
                Add logic
              </BlockButton>
            )}
          {item.type === ItemType.CONTAINER && (
            <>
              <BlockButton
                onClick={createItem(ItemType.BLOCK, {
                  dataType: DataType.CHAR,
                  isRequired: true,
                })}
              >
                <PlusIcon className="mr-1 size-4" />
                Add question
              </BlockButton>
              <BlockButton
                onClick={createItem(ItemType.CONTAINER, {
                  isRepeated: false,
                })}
              >
                <BoxIcon className="mr-1 size-4" />
                Add section
              </BlockButton>
            </>
          )}
          {showIsRequired && (
            <BlockToggle item={item} toggle="isRequired">
              Required
            </BlockToggle>
          )}
          <BlockToggle item={item} toggle="isPrivate">
            Private
          </BlockToggle>
          {item.data.dataType === DataType.CHAR && (
            <BlockToggle item={item} toggle="isMultiline">
              Multiline
            </BlockToggle>
          )}
          {item.data.dataType === DataType.CHOICE_SET && (
            <BlockToggle item={item} toggle="isMultipleSelection">
              Multiple selection
            </BlockToggle>
          )}
          {(item.data as BlockData).helpText ? (
            <BlockButton onClick={handleHelpTextOnRemove}>
              <InfoIcon className="mr-1 size-4" />
              Remove help text
            </BlockButton>
          ) : (
            <BlockButton onClick={handleHelpTextOnAdd}>
              <InfoIcon className="mr-1 size-4" />
              Add help text
            </BlockButton>
          )}
        </div>
        <div className="flex">
          <ControlsDelete
            item={item}
            isHovered={isHovered}
            onClick={handleDeleteOnClick}
          />
          <ControlsLabel
            item={item}
            fieldLabel={fieldLabel}
            onClick={handleAddLabelOnClick}
            onChange={handleLabelOnChange}
            onDelete={handleLabelOnRemove}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(BlockControls, deepEqual);
