import { useEffect } from 'react';
import { MapLayerMouseEvent, ViewStateChangeEvent } from 'react-map-gl';
import { useStore } from '../../hooks';
import { GeometryType } from '../../types';

export const useLocationMap = () => {
  const { viewState, apply } = useStore((state) => ({
    viewState: state.map.viewState,
    apply: state.apply,
  }));

  useEffect(() => {
    apply((state) => {
      state.mapEnabled = true;
    });
    return () => {
      apply((state) => {
        state.mapEnabled = false;
      });
    };
  }, [apply]);

  const handleOnMove = (event: ViewStateChangeEvent) => {
    apply((state) => {
      state.map.viewState = event.viewState;
    });
  };

  const handleOnClick = (event: MapLayerMouseEvent) => {
    apply((state) => {
      if (state.workspaceHandle) {
        state.workspace = {
          geometry: {
            type: GeometryType.Point,
            point: {
              latitude: event.lngLat.lat,
              longitude: event.lngLat.lng,
            },
          },
        };
      }
    });
  };

  return {
    viewState,
    handleOnMove,
    handleOnClick,
  };
};
