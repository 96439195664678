import React, { useMemo } from 'react';
import { BryntumSchedulerProProps } from '@bryntum/schedulerpro-react-thin';
import { Scheduler } from 'lib/shared';
import { useSchedulerContext } from '../../../../../../shared/Scheduler/hooks';
import { SchedulerResourceType } from '../../../../../../shared/Scheduler/types';
import { defaultConfig } from './config';
import { AssignmentSubcontractorProps } from './types';

const AssignmentSubcontractor = ({
  getDefaultDuration,
}: AssignmentSubcontractorProps) => {
  const { resourceType } = useSchedulerContext();

  const config = useMemo<Partial<BryntumSchedulerProProps>>(
    () => ({
      ...defaultConfig,
    }),
    [],
  );

  if (resourceType.value !== SchedulerResourceType.Subcontractor) {
    return null;
  }

  return (
    <Scheduler.Content
      resourceType={SchedulerResourceType.Subcontractor}
      getDefaultDuration={getDefaultDuration}
      config={config}
    />
  );
};

export default AssignmentSubcontractor;
