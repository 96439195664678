import React from 'react';
import { Report, ReportBlockComponentProps } from 'lib/shared';
import { Job } from 'lib/types';
import { parseForClient } from 'lib/utils';

const JobDetails = ({
  context,
}: Pick<ReportBlockComponentProps, 'context'>) => {
  const { theme } = Report.useContext();
  const { job } = parseForClient(context) as {
    job: Job;
    selected: string[];
  };
  return (
    <Report.Section
      title="Job Details"
      subtitle="Client provided job description"
    >
      <Report.Text
        style={{
          fontSize: 12,
          padding: 12,
          lineHeight: '1.5',
          whiteSpace: 'pre-line',
        }}
      >
        {job.description.replace(/\r/g, ' ')}
      </Report.Text>
      <Report.View
        style={{
          display: 'flex',
          flexDirection: 'row',
          borderTopWidth: 1,
          borderTopStyle: 'solid',
          borderTopColor: theme.colors.border,
        }}
      >
        <Report.Text
          style={{
            fontSize: 13,
            fontWeight: 600,
            lineHeight: '1.25',
            color: theme.colors.title,
            textAlign: 'center',
            padding: 8,
            flexBasis: `${100 / 3}%`,
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: theme.colors.border,
          }}
        >
          Priority {job.priority.reference}
        </Report.Text>
        <Report.Text
          style={{
            fontSize: 13,
            fontWeight: 600,
            padding: 8,
            lineHeight: '1.25',
            flexBasis: `${100 / 3}%`,
            color: theme.colors.title,
            textAlign: 'center',
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: theme.colors.border,
          }}
        >
          {job.priority.responseName}
        </Report.Text>
        <Report.Text
          style={{
            padding: 8,
            textAlign: 'center',
            flexBasis: `${100 / 3}%`,
            fontSize: 13,
            fontWeight: 600,
            lineHeight: '1.25',
            color: theme.colors.title,
          }}
        >
          {job.priority.completionName}
        </Report.Text>
      </Report.View>
      <Report.View
        style={{
          display: 'flex',
          flexDirection: 'row',
          borderTopWidth: 1,
          borderTopStyle: 'solid',
          borderTopColor: theme.colors.border,
        }}
      >
        <Report.Text
          style={{
            fontSize: 13,
            fontWeight: 600,
            lineHeight: '1.25',
            color: theme.colors.title,
            textAlign: 'center',
            padding: 8,
            flexBasis: `${100 / 3}%`,
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: theme.colors.border,
          }}
        >
          Issued
        </Report.Text>
        <Report.Text
          style={{
            fontSize: 13,
            fontWeight: 600,
            padding: 8,
            lineHeight: '1.25',
            flexBasis: `${100 / 3}%`,
            color: theme.colors.title,
            textAlign: 'center',
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: theme.colors.border,
          }}
        >
          Started
        </Report.Text>
        <Report.Text
          style={{
            padding: 8,
            textAlign: 'center',
            flexBasis: `${100 / 3}%`,
            fontSize: 13,
            fontWeight: 600,
            lineHeight: '1.25',
            color: theme.colors.title,
          }}
        >
          Completed
        </Report.Text>
      </Report.View>
    </Report.Section>
  );
};

export default JobDetails;
