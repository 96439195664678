import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { z } from 'zod';
import { usePrelimsCreate } from './hooks';
import { PrelimsCreateProps } from './types';

const PrelimsCreate = ({ className }: PrelimsCreateProps) => {
  const { sheet, handlers } = usePrelimsCreate();
  return (
    <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
      <SheetTrigger asChild>
        <Button className={className} variant="outline" size="sm">
          Update default prelim
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader className="mb-4">
          <SheetTitle>Update default prelim</SheetTitle>
          <SheetDescription>
            Update the default prelim for this contract
          </SheetDescription>
        </SheetHeader>
        <FormRenderer
          fields={[
            {
              name: 'factor',
              label: 'Factor (% of cost)',
              type: 'number',
              schema: z.number(),
            },
          ]}
          onSubmit={handlers.onCreate}
        >
          {({ onSubmit }) => (
            <div className="pt-4">
              <Button
                className="w-full"
                size="sm"
                variant="outline"
                onClick={onSubmit}
              >
                Update
              </Button>
            </div>
          )}
        </FormRenderer>
      </SheetContent>
    </Sheet>
  );
};

export default PrelimsCreate;
