import React, { useContext } from 'react';
import { QueryClient } from '@tanstack/react-query';
import { gql, useMutation } from '@apollo/client';
import produce from 'immer';
import { useEffectAsync } from 'lib/hooks';
import { useGlobalStore } from '../../state';
import { LoginMutationData } from '../AppLogin/components/AppLoginRedirect/types';
import { useApollo } from '../hooks';

type AppInnerContext = {
  token: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const AppInnerContext_ = React.createContext<AppInnerContext>(
  {} as AppInnerContext,
);
export const AppInnerContextProvider = AppInnerContext_.Provider;

export const useAppContext = () => useContext(AppInnerContext_);

const queryClient = new QueryClient();

export const useSetup = () => {
  const { httpClient } = useApollo();
  const apply = useGlobalStore((state) => state.apply);

  const [login] = useMutation<LoginMutationData, null>(
    gql`
      mutation Login {
        login {
          isValid
          user {
            id
            name
            initials
            email
          }
        }
      }
    `,
    { fetchPolicy: 'no-cache', client: httpClient },
  );

  useEffectAsync(async () => {
    const { data } = await login();

    if (data) {
      apply(
        produce((state) => {
          state.user = data.login.user;
        }),
      );
    }
  }, []);

  return { httpClient, queryClient };
};
