import { createContext, useContext } from 'react';
import { useContractRate } from './hooks';

export const ContractRateContext = createContext(
  {} as ReturnType<typeof useContractRate>['context'],
);

export const useContractRateContext = () => {
  return useContext(ContractRateContext);
};
