import React from 'react';
import { pluralize } from '@/lib/utils';
import { useUserGroupContext } from '../../context';

const GroupHeader = () => {
  const { group, users } = useUserGroupContext();
  return (
    <div>
      <p className="mb-1 text-sm font-bold">{group?.name}</p>
      <p className="text-xs text-gray-400">
        This group has <span className="font-bold">{users.length}</span>{' '}
        {pluralize(users.length, 'member')}
      </p>
    </div>
  );
};

export default GroupHeader;
