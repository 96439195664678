import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import { Link } from 'lib/shared';
import { Job } from 'lib/types';
import { useClient } from '../../../../../../hooks';

const TableReference = ({ row }: CellContext<Job, any>) => {
  const client = useClient();
  return (
    <Link to={client.paths.jobs.job(row.original).root}>
      <Button
        variant="ghost"
        className="h-7 w-full px-2 text-sm font-semibold"
        style={{ fontFamily: 'Roboto Mono' }}
      >
        {row.original.reference}
      </Button>
    </Link>
  );
};

export default TableReference;
