import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faEquals } from '@fortawesome/pro-light-svg-icons/faEquals';
import { faGreaterThan } from '@fortawesome/pro-light-svg-icons/faGreaterThan';
import { faGreaterThanEqual } from '@fortawesome/pro-light-svg-icons/faGreaterThanEqual';
import { faLambda } from '@fortawesome/pro-light-svg-icons/faLambda';
import { faLessThan } from '@fortawesome/pro-light-svg-icons/faLessThan';
import { faLessThanEqual } from '@fortawesome/pro-light-svg-icons/faLessThanEqual';
import { faNotEqual } from '@fortawesome/pro-light-svg-icons/faNotEqual';
import { Choice, DataType } from '../../../../types';
import ChoiceSet from '../../../ChoiceSet';
import { useBlockLogicCondition } from './hooks';
import { BlockLogicConditionProps } from './types';

const iconMap: Record<string, IconDefinition> = {
  $x: faLambda,
  $eq: faEquals,
  $neq: faNotEqual,
  $lt: faLessThan,
  $lte: faLessThanEqual,
  $gt: faGreaterThan,
  $gte: faGreaterThanEqual,
};

const BlockLogicCondition = ({ parent, itemId }: BlockLogicConditionProps) => {
  const { item, body, condition, isSelected, choiceSets, onSelectLogic } =
    useBlockLogicCondition(parent, itemId);

  if (!condition) return null;

  return (
    <Button
      variant="ghost"
      className={cn(
        'h-auto min-w-0 rounded-none border-b-2 border-b-transparent text-sm',
        { 'border-b-success': isSelected },
      )}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        onSelectLogic(item)();
      }}
    >
      <div className="flex h-6 gap-x-1">
        {body.map((element, index) => {
          if (element.type === 'Identifier') {
            if (element.name in iconMap) {
              return (
                <div
                  key={index}
                  className="flex h-full flex-col justify-center"
                >
                  {iconMap[element.name] && (
                    <FontAwesomeIcon icon={iconMap[element.name]} />
                  )}
                </div>
              );
            }

            return (
              <div key={index} className="flex h-full flex-col justify-center">
                {element.name}
              </div>
            );
          }

          if (element.type === 'BinaryExpression') {
            const name = element.left.name as string;
            const dataType = element.right.name as string;

            if (dataType === DataType.CHOICE) {
              const choiceSet = choiceSets[parent.data.choiceSetId as string];
              let choices: Choice[];
              try {
                choices = choiceSet.choices.filter(({ id }) =>
                  (item.data.constants?.[name]?.value ?? []).includes(id),
                );
              } catch (error) {
                console.error('Failed to check isSelected', error);
                choices = [];
              }
              return (
                <div key={index} className="pt-0.5">
                  {choiceSet && (
                    <ChoiceSet
                      choiceSet={{
                        id: choiceSet.id,
                        choices,
                      }}
                    />
                  )}
                </div>
              );
            }

            return (
              <div key={index} className="flex h-full flex-col justify-center">
                {String(item.data.constants?.[name]?.value) || ''}
              </div>
            );
          }
        })}
      </div>
    </Button>
  );
};

export default BlockLogicCondition;
