import { useMemo } from 'react';
import { gql } from '@apollo/client';
import { useReportQuery } from 'lib/shared';
import {
  CommercialActivity,
  CommercialType,
  CommercialValueLine,
  Job,
} from 'lib/types';
import { parseForClient } from 'lib/utils';

export const useJobCommercial = (context: any) => {
  const { job } = parseForClient(context) as { job: Job };

  const { data } = useReportQuery<{
    job: {
      id: string;
      commercialActivity: CommercialActivity & {
        PRELIM: number;
        LABOR: number;
        MATERIAL: number;
        EQUIPMENT: number;
        SUBCONTRACTOR: number;
      };
    };
  }>(
    'jobCommercial',
    gql`
      query GetJobTimings($jobId: ID!) {
        job(jobId: $jobId) {
          id
          commercialActivity {
            id
            order
            valueLines {
              id
              type
              isCallout
              description
              descriptionEstimate
              rate {
                id
                name
                description
                unitDisplay
                unitValue
              }
              unitDisplay
              estimatedQuantity
              estimatedUnitValue
              estimatedTotalValue
            }
            totalEstimatedValue
            PRELIM: totalEstimatedValue(type: "PRELIM")
            LABOR: totalEstimatedValue(type: "LABOR")
            MATERIAL: totalEstimatedValue(type: "MATERIAL")
            EQUIPMENT: totalEstimatedValue(type: "EQUIPMENT")
            SUBCONTRACTOR: totalEstimatedValue(type: "SUBCONTRACTOR")
            staffTraining {
              id
              user {
                id
                name
                email
                initials
                externalId
              }
              qualification {
                id
                logo {
                  id
                }
                color
                name
                description
                fields
                data
              }
              dateValid
              dateExpiry
              data
            }
          }
        }
      }
    `,
    { jobId: job.id },
  );

  const valueLinesSorted = useMemo(() => {
    if (!data) return [];
    return [...data.job.commercialActivity.valueLines].sort((a, b) => {
      const { order } = data.job.commercialActivity;
      return order.indexOf(a.id) - order.indexOf(b.id);
    });
  }, [data]);

  const valueLinesByType = useMemo(() => {
    if (!data) return [];
    return Object.entries(
      valueLinesSorted.reduce(
        (acc, line) => {
          const type = line.type;
          if (!acc[type]) acc[type] = [];
          acc[type].push(line);
          return acc;
        },
        {} as Record<CommercialType, CommercialValueLine[]>,
      ),
    );
  }, [valueLinesSorted]);

  return {
    ready: !!data,
    linesByType: valueLinesByType,
    totalsByType: {
      [CommercialType.Prelim]: data?.job.commercialActivity.PRELIM || 0,
      [CommercialType.Labor]: data?.job.commercialActivity.LABOR || 0,
      [CommercialType.Material]: data?.job.commercialActivity.MATERIAL || 0,
      [CommercialType.Equipment]: data?.job.commercialActivity.EQUIPMENT || 0,
      [CommercialType.Subcontractor]:
        data?.job.commercialActivity.SUBCONTRACTOR || 0,
    } as const,
    total: data?.job.commercialActivity.totalEstimatedValue || 0,
    training: data?.job.commercialActivity.staffTraining || [],
  };
};
