import React, { useCallback } from 'react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { FormLocal, RenderFormField } from 'lib/shared';
import { prepareTemplate } from 'lib/utils';
import { useActionContext } from '../../context';

const ActionForm = () => {
  const { action, form, handlers } = useActionContext();

  const renderField = useCallback<RenderFormField>(
    (props) => {
      if (
        form.fieldExtensions &&
        props.item.data.dataType in form.fieldExtensions
      ) {
        return form.fieldExtensions[props.item.data.dataType](props);
      }

      const { item, isLast, label, field } = props;

      return (
        <div
          className={cn({ 'border-b': !isLast })}
          style={{ paddingLeft: (item.depth - 1) * 16 }}
        >
          {label}
          {item.data.dataType !== 'COMMENT' && <div>{field}</div>}
        </div>
      );
    },
    [form.fieldExtensions],
  );

  if (!action?.form) return null;

  return (
    <FormLocal
      disableHeader
      template={prepareTemplate(action.form)}
      renderField={renderField}
    >
      {({ isCompleted, getSubmission }) => (
        <div className="flex gap-x-2">
          {action.form!.hooks.map((hook) => (
            <Button
              key={hook.id}
              disabled={!isCompleted}
              size="sm"
              variant="outline"
              className="w-full"
              onClick={() =>
                handlers.performHookOnClick(hook.id, getSubmission())
              }
            >
              {hook.label}
            </Button>
          ))}
        </div>
      )}
    </FormLocal>
  );
};

export default ActionForm;
