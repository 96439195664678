import { useSyncedStore } from '@syncedstore/react';
import { PageData } from 'external/types';
import { Page, flatten } from '../../../Tree';
import { useFormTemplateContext } from '../../context';

export const usePageHeader = (page: Page) => {
  const { store, onCollapse } = useFormTemplateContext();
  const { items, pageOrder } = useSyncedStore(store);

  const handlePageNameOnChange = (nextValue: string) => {
    (items[page.id].data as PageData).name = nextValue;
  };

  const handleDeletePageOnClick = () => {
    const itemsToRemove = flatten(
      page,
      items,
      () => true,
      () => true,
    );

    for (const itemToRemove of itemsToRemove) {
      delete items[itemToRemove.id];
      pageOrder.splice(pageOrder.indexOf(itemToRemove.id), 1);
    }
  };

  const handleCollapsePageOnClick = () => {
    onCollapse(page)();
  };

  return {
    pageOrder,
    handlePageNameOnChange,
    handleCollapsePageOnClick,
    handleDeletePageOnClick,
  };
};
