import React, { useMemo } from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { ItemType, getItemDepth, useTreeContext } from 'external/Tree';
import { BlockData, Item } from '../../../types';
import { useFormTemplateContext } from '../../context';

export const useBlock = (item: Item<BlockData>) => {
  const { collapsed, store, focus, onFocus, getShouldParentIncreaseDepth } =
    useFormTemplateContext();

  const { items } = useSyncedStore(store);

  const { pages } = useTreeContext();
  const depth = getItemDepth(item, items, getShouldParentIncreaseDepth);

  const itemsArray = Object.values(pages).flatMap((page) =>
    Object.values(page.items),
  );
  const itemIndex = itemsArray.map(({ id }) => id).indexOf(item.id);
  const aboveItem = itemsArray[itemIndex - 1];
  const belowItem = itemsArray[itemIndex + 1];

  const aboveItemDepth =
    aboveItem && getItemDepth(aboveItem, items, getShouldParentIncreaseDepth);

  const belowItemDepth =
    belowItem && getItemDepth(belowItem, items, getShouldParentIncreaseDepth);

  const style = useMemo((): Pick<
    React.CSSProperties,
    'borderTopLeftRadius' | 'borderBottomLeftRadius'
  > => {
    const itemHasLogic = item.children
      ? item.children.some((childId) => items[childId].type === ItemType.LOGIC)
      : false;

    const itemIsFocused = focus === item.id;
    const itemIsCollapsed = collapsed.includes(item.id);
    const aboveItemIsCollapsed = aboveItem && collapsed.includes(aboveItem.id);

    let borderTopLeftRadius = '0';
    let borderBottomLeftRadius = '0';

    if (
      !aboveItemIsCollapsed &&
      (aboveItemDepth > depth ||
        (aboveItemDepth === depth && (aboveItem?.children?.length ?? 0) > 0))
    ) {
      borderTopLeftRadius = '4px';
    }

    if (
      !itemHasLogic &&
      !itemIsFocused &&
      !itemIsCollapsed &&
      (belowItemDepth > depth || item.children.length > 0)
    ) {
      borderBottomLeftRadius = '4px';
    }

    return {
      borderTopLeftRadius,
      borderBottomLeftRadius,
    };
  }, [
    aboveItem,
    aboveItemDepth,
    belowItemDepth,
    collapsed,
    depth,
    focus,
    item.children,
    item.id,
    items,
  ]);

  return {
    visibleItems: itemsArray,
    itemIndex,
    aboveItem,
    aboveItemDepth,
    belowItem,
    belowItemDepth,
    depth,
    style,
    collapsed,
    onFocus,
    getShouldParentIncreaseDepth,
  };
};
