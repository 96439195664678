import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Clients from '../../sections/Clients';
import Company from '../../sections/Company';
import HumanResources from '../../sections/HumanResources';
import Landing from '../../sections/Landing';
import SupplyChain from '../../sections/SupplyChain';
import UserProfile from '../../sections/User/pages/UserProfile';
import Layout from '../Layout';

const LayoutRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<Layout />}>
        <Route index element={<Landing />} />
        <Route path="clients/*" element={<Clients />} />
        <Route path="hr/*" element={<HumanResources />} />
        <Route path="supply-chain/*" element={<SupplyChain />} />
        <Route path="company/*" element={<Company />} />
        <Route path="profile" element={<UserProfile root />} />
        <Route path="users/:username" element={<UserProfile />} />
      </Route>
    </Routes>
  );
};

export default LayoutRouter;
