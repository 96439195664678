import { useState } from 'react';

export const useSearch = () => {
  const [searching, setSearching] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [results, setResults] = useState<any[]>([]);
  const [value, setValue] = useState<string>('');
  return {
    searching,
    setSearching,
    loading,
    setLoading,
    search,
    setSearch,
    results,
    setResults,
    value,
    setValue,
  };
};
