import React from 'react';
import { Placeholder } from './styled';
import { PlaceholderPosition, RenderPlaceholder } from './types';

type Props = {
  placeholderPosition: PlaceholderPosition;
  currentItemDepth: number | undefined;
  depthMargin: number;
  renderPlaceholder?: RenderPlaceholder;
};

const DroppablePlaceholder = ({
  placeholderPosition,
  currentItemDepth,
  depthMargin = 40,
  renderPlaceholder,
}: Props) => {
  return (
    <div
      style={{
        position: 'absolute',
        ...placeholderPosition,
        left: (currentItemDepth ?? 0) * depthMargin,
        width:
          (placeholderPosition.width as number) -
            (currentItemDepth ?? 0) * depthMargin || 0,
      }}
    >
      {renderPlaceholder ? renderPlaceholder({}) : <Placeholder />}
    </div>
  );
};

export default DroppablePlaceholder;
