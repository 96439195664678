import React, { PropsWithChildren } from 'react';
import { DotIcon } from 'lucide-react';
import { Badge, badgeVariants } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useMatch } from 'lib/hooks';
import { useJobContext } from '../../context';
import OverviewMap from '../../pages/JobOverview/components/OverviewMap';
import JobLink from '../JobLink';
import JobNavigationMenu from '../JobNavigationMenu';
import JobSelector from '../JobSelector';
import { JobTimelineBadge } from '../JobTimeline';

const JobHeader = ({ children }: PropsWithChildren) => {
  const match = useMatch('/clients/:clientSlug/jobs/:jobReference/location');
  const { job } = useJobContext();

  const small = false;
  const readOnly = false;

  return (
    <div
      className={cn('border-b bg-background', {
        'grid grid-cols-3 pl-4': !small,
        'px-4': small,
      })}
    >
      <div className="z-[10] col-span-2 flex flex-col">
        <div className="flex h-[52px] shrink-0 items-center">
          <div className="flex items-center gap-x-2">
            <JobSelector />
            {!job.partial && (
              <>
                <Badge
                  variant="outline"
                  className="h-6 whitespace-nowrap bg-background shadow-sm"
                  style={{ textTransform: 'capitalize' }}
                >
                  {job.scope.split('::').at(-1)?.replace(/-/g, ' ')}
                </Badge>
                <Badge
                  className="flex h-6 items-center gap-x-2 whitespace-nowrap bg-background shadow-sm"
                  variant="outline"
                >
                  {job.priority.responseNameShort}
                  <DotIcon className="h-2 w-2" />
                  {job.priority.completionNameShort}
                </Badge>
                {job.isInstantiated && (
                  <>
                    <JobTimelineBadge />
                    <JobLink to="location">
                      <Button
                        className={cn(
                          badgeVariants({ variant: 'outline' }),
                          'h-6',
                        )}
                        variant="outline"
                        size="sm"
                      >
                        Location
                      </Button>
                    </JobLink>
                  </>
                )}
              </>
            )}
            {!readOnly && (
              <JobLink to="settings">
                <Button
                  className={cn(badgeVariants({ variant: 'outline' }), 'h-6')}
                  variant="outline"
                  size="sm"
                >
                  Settings
                </Button>
              </JobLink>
            )}
          </div>
        </div>
        {!job.partial && (
          <div className={cn('mb-2 mt-1 grow', { 'w-2/3': !small })}>
            <p className="line-clamp-2 h-[40px] text-xs leading-5 text-gray-400">
              {job.description}
            </p>
          </div>
        )}
        <div className="py-2">
          <JobNavigationMenu />
        </div>
      </div>
      <div className="col-span-1">
        {children ? children : <>{!match && <OverviewMap />}</>}
      </div>
    </div>
  );
};

export default JobHeader;
