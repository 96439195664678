import React, { createContext, useContext, useEffect, useState } from 'react';
import { CheckIcon } from '@radix-ui/react-icons';
import { Moon, Sun } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

type Theme = 'dark' | 'light';

type ThemeProviderProps = {
  children: React.ReactNode;
  defaultTheme?: Theme;
  storageKey?: string;
};

type ThemeProviderState = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

const initialState: ThemeProviderState = {
  theme: 'light',
  setTheme: () => null,
};

const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export function ThemeProvider({
  children,
  defaultTheme = 'light',
  storageKey = 'ui-theme',
  ...props
}: ThemeProviderProps) {
  const [theme, setTheme] = useState<Theme>(
    () => (localStorage.getItem(storageKey) as Theme) || defaultTheme,
  );

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove('light', 'dark');
    root.classList.add(theme);
    setTimeout(() => {
      const bryntumTheme = document.getElementById(
        'bryntum-theme',
      ) as HTMLLinkElement | null;
      if (bryntumTheme) {
        bryntumTheme.href = `/themes/${theme}.css`;
      }
    }, 250);
  }, [theme]);

  const value = {
    theme,
    setTheme: (theme: Theme) => {
      localStorage.setItem(storageKey, theme);
      setTheme(theme);
      setTimeout(() => {
        const bryntumTheme = document.getElementById(
          'bryntum-theme',
        ) as HTMLLinkElement | null;
        if (bryntumTheme) {
          bryntumTheme.href = `/themes/${theme}.css`;
        }
      }, 250);
    },
  };

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  );
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);

  if (context === undefined)
    throw new Error('useTheme must be used within a ThemeProvider');

  return context;
};

export function ThemeToggle() {
  const { setTheme } = useTheme();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon">
          <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
          <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem
          className="cursor-pointer bg-accent dark:bg-background"
          onClick={() => setTheme('light')}
        >
          Light
          <CheckIcon className="ml-auto h-4 w-4 opacity-100 dark:opacity-0 " />
        </DropdownMenuItem>
        <DropdownMenuItem
          className="cursor-pointer dark:bg-accent"
          onClick={() => setTheme('dark')}
        >
          Dark
          <CheckIcon className="ml-auto h-4 w-4 opacity-0 dark:opacity-100" />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
