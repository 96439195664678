import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFileExcel } from '@fortawesome/pro-solid-svg-icons/faFileExcel';
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons/faFilePdf';
import { DataType } from 'external/FormTemplateEditor';
import { PlatformObject } from 'lib/types';
import { useMedia } from '../../../../../Media/hooks';
import { useFormContext } from '../../../../context';
import { FormFieldComponentProps } from '../../types';

const ICON_MAP: Record<string, IconProp> = {
  '.xls': faFileExcel,
  '.pdf': faFilePdf,
};

const FormFieldFile = ({ item, onChange }: FormFieldComponentProps) => {
  // prettier-ignore
  const {
    objects,
    objectsOnCreate,
    objectOnUploadProgress,
    objectOnUpload,
  } = useFormContext();

  const accept = item.data.accept ?? {};
  const acceptedExtensions = Object.values(accept).flat();

  const icon = acceptedExtensions.map(
    (extension: string) => ICON_MAP[extension],
  )?.[0];
  const object = objects?.[item.path]?.[0];

  const { dropzone } = useMedia({
    accept: item.data.accept,
    onCreated: ([object]: PlatformObject[]) => {
      onChange(item, {
        dataType: DataType.OBJECT,
        value: {
          id: object.id,
          name: object.name,
          status: object.status,
          type: object.type,
        },
      });
      objectsOnCreate(item, [object]);
    },
    onUploadProgress: (
      objectId: PlatformObject['id'],
      progressPercentage: number,
    ) => objectOnUploadProgress(item, objectId, progressPercentage),
    onUploaded: (objectId: PlatformObject['id']) =>
      objectOnUpload(item, objectId),
  });

  return null;
};

export default FormFieldFile;
