import React from 'react';
import { SheetContent } from '@/components/ui/sheet';
import Image from '../../index';
import { useImageSheet } from './hooks';
import { ImageSheetProps } from './types';

const ImageSheet = ({
  name,
  sources,
  nested = false,
  right = 384,
  annotations = [],
}: ImageSheetProps) => {
  const { highlighted, setHighlighted } = useImageSheet();
  return (
    <SheetContent
      className="p-0"
      overlay={!nested}
      style={nested ? { right, zIndex: 49 } : undefined}
    >
      <div className="justify-content-between flex items-center justify-between border-b p-4">
        <p className="text-sm font-bold">{name}</p>
        <div className="mr-8"></div>
      </div>
      <div className="relative min-h-0 p-4">
        <Image
          className="w-full rounded-lg"
          sources={sources}
          annotations={annotations}
          highlighted={highlighted}
        />
      </div>
      {(annotations?.length ?? 0) > 0 && (
        <div className="flex flex-col gap-y-2 px-4">
          {annotations.map((annotation, index) => {
            return (
              <div
                key={annotation.id}
                className="group flex items-center gap-x-2"
                onMouseEnter={() => setHighlighted(annotation.id)}
                onMouseLeave={() => setHighlighted(undefined)}
              >
                <div className="overflow-hidden rounded-lg group-hover:ring-2">
                  <Image
                    className="h-12 w-12 cursor-pointer rounded-lg opacity-10 "
                    annotations={[annotation]}
                    sources={sources}
                    opacity={0.5}
                  />
                </div>
                <p className="text-sm">Annotation {index + 1}</p>
              </div>
            );
          })}
        </div>
      )}
    </SheetContent>
  );
};

export default ImageSheet;
