import React from 'react';
import { Circle } from 'lucide-react';
import Map, { Marker } from 'react-map-gl';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import LocationPicker from '../../../../../../shared/LocationPicker';
import { useJobContext } from '../../../../context';
import { useStage } from '../../hooks';
import { STAGE_LOCATION } from '../../stages';
import { InstantiationStageIssues } from '../InstantiationIssues';
import LocationAddress from './components/LocationAddress';
import LocationHeader from './components/LocationHeader';
import LocationSheet from './components/LocationSheet';
import { useInstantiationLocation } from './hooks';

const InstantiationLocation = () => {
  const { job } = useJobContext();
  const stage = useStage(STAGE_LOCATION);
  const { sheet, map, handlers } = useInstantiationLocation();

  if (job.partial) return null;

  return (
    <LocationPicker value={job.site.location} onChange={handlers.onChange}>
      <AccordionItem value={STAGE_LOCATION} asChild>
        <div className="p-4">
          <div className="relative mb-4">
            <AccordionTrigger className="grow p-0">
              <LocationHeader />
            </AccordionTrigger>
            <div className="absolute inset-y-0 right-8 flex items-center">
              <LocationSheet
                open={sheet.open}
                edit={sheet.edit}
                onOpenChange={sheet.onOpenChange}
                onEditChange={sheet.onEditChange}
              />
            </div>
          </div>
          <AccordionContent>
            <div className="relative flex w-full overflow-hidden rounded-lg border">
              <div className="pointer-events-none z-[7] w-full from-secondary from-25% to-75% p-3 bg-gradient-[90deg]">
                <LocationAddress />
              </div>
              <div className="absolute inset-0 min-h-0">
                <Map
                  ref={map.ref}
                  key={map.point ? JSON.stringify(map.point) : 'default'}
                  style={{ height: '100%', width: '100%' }}
                  initialViewState={map.initialViewState}
                  mapboxAccessToken="pk.eyJ1IjoiZW5lcmd5ZW5hYmxlcyIsImEiOiJjbGpia2NqZ2IxcTcxM2pxeGxvNG5ud2lpIn0.gMXRc_xES-6AlQx1w8MuXw"
                  attributionControl={false}
                  mapStyle={map.style}
                  scrollZoom={false}
                  onLoad={map.onLoad}
                >
                  {map.point && (
                    <Marker {...map.point}>
                      <Circle className="size-3 fill-success stroke-success" />
                    </Marker>
                  )}
                </Map>
              </div>
            </div>
          </AccordionContent>
          <InstantiationStageIssues
            stage={stage}
            types={[
              {
                value: 'information-missing',
                label: 'Missing information',
                color: 'red',
              },
            ]}
          />
        </div>
      </AccordionItem>
    </LocationPicker>
  );
};

export default InstantiationLocation;
