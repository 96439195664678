import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { toast } from 'sonner';
import { CompanyAccreditation } from 'lib/types';
import { platformObjectInput } from 'lib/utils';
import { useCompanyAccreditationsContext } from '../../context';
import {
  DeleteCompanyAccreditationMutationData,
  DeleteCompanyAccreditationMutationVariables,
  UpdateCompanyAccreditationMutationData,
  UpdateCompanyAccreditationMutationVariables,
} from './types';

export const useAccreditationsEditor = (
  accreditation: CompanyAccreditation,
) => {
  const { refetch } = useCompanyAccreditationsContext();

  const [open, setOpen] = useState(false);

  const [update] = useMutation<
    UpdateCompanyAccreditationMutationData,
    UpdateCompanyAccreditationMutationVariables
  >(
    gql`
      mutation CreateCompanyAccreditation(
        $id: ID!
        $data: CompanyAccreditationInput!
      ) {
        updateCompanyAccreditation(
          companyAccreditationId: $id
          companyAccreditationData: $data
        ) {
          companyAccreditation {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnUpdate = async (values: any) => {
    const { data, errors } = await update({
      variables: {
        id: accreditation.id,
        data: {
          logo: platformObjectInput(values.logo),
          name: values.name,
          reference: values.reference,
          dateTimeExpiry: values.dateTimeExpiry,
          description: values.description,
          document: platformObjectInput(values.document),
        },
      },
    });

    if (data) {
      await refetch();
      setOpen(false);
      toast.success('Company accreditation updated');
    }

    if (errors) {
      toast.error('Failed to update company accreditation');
    }
  };

  const [delete_] = useMutation<
    DeleteCompanyAccreditationMutationData,
    DeleteCompanyAccreditationMutationVariables
  >(
    gql`
      mutation DeleteCompanyAccreditation($id: ID!) {
        deleteCompanyAccreditation(companyAccreditationId: $id) {
          deleted
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnDelete = async () => {
    const { data, errors } = await delete_({
      variables: {
        id: accreditation.id,
      },
    });

    if (data) {
      await refetch();
      setOpen(false);
      toast.success('Company accreditation deleted');
    }

    if (errors) {
      toast.error('Failed to delete company accreditation');
    }
  };

  return {
    sheet: {
      open,
      onOpenChange: setOpen,
    },
    handlers: {
      onUpdate: handleOnUpdate,
      onDelete: handleOnDelete,
    },
  };
};
