import React, { useCallback } from 'react';
import { CheckIcon } from '@radix-ui/react-icons';
import * as SelectPrimitive from '@radix-ui/react-select';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Select, SelectContent, SelectItem } from '@/components/ui/select';
import { cn } from '@/lib/utils';
import parse, { BinaryExpression, Identifier } from 'jsep';
import { CONDITIONS, Choice, DataType } from '../../../../types';
import BlockButton from '../../../BlockButton';
import ChoiceSet from '../../../ChoiceSet';
import { useBlockLogic } from '../../hooks';
import { BlockConditionRendererProps } from './types';

parse.addBinaryOp(':', 1);

const BlockConditionRenderer = ({ item }: BlockConditionRendererProps) => {
  const {
    choiceSets,
    constants,
    condition,
    handleConditionOnChange,
    handleConstantOnChange,
    handleInputOnChange,
    handleChoiceOnClick,
    handleDeleteOnClick,
  } = useBlockLogic(item);

  const body = condition?.collector
    ? (parse(condition.collector).body as (Identifier | BinaryExpression)[])
    : null;

  const renderChoice = useCallback(
    (element: BinaryExpression) => {
      if (!item.data.choiceSetId) return null;
      if (!choiceSets[item.data.choiceSetId]) return null;
      const choices = choiceSets[item.data.choiceSetId].choices;

      let choicesDisplayed: Choice[];
      try {
        choicesDisplayed = choices.filter(({ id }) =>
          (constants?.[element.left.name as string]?.value ?? []).includes(id),
        );
      } catch (error) {
        console.error('Failed to check isSelected', error);
        choicesDisplayed = [];
      }
      return (
        <Popover>
          <PopoverTrigger asChild>
            <BlockButton className="min-w-0 px-0">
              <ChoiceSet
                placeholder="Select"
                choiceSet={{
                  id: item.data.choiceSetId,
                  choices: choicesDisplayed,
                }}
              />
            </BlockButton>
          </PopoverTrigger>
          <PopoverContent
            className={cn('min-h-0 p-0', {
              'h-[300px]': choices.length > 10,
            })}
            align="start"
          >
            <div className="h-full min-h-0">
              <ScrollArea className="h-full">
                <div className="flex flex-col gap-y-1 p-1">
                  {choices.map((choice) => {
                    let isSelected: boolean;
                    try {
                      isSelected = (
                        constants?.[element.left.name as string]?.value ?? []
                      ).includes(choice.id);
                    } catch (error) {
                      console.error('Failed to check isSelected', error);
                      isSelected = false;
                    }
                    return (
                      <BlockButton
                        key={choice.id}
                        className={cn(
                          'h-9 w-full justify-between gap-x-2 rounded-sm px-2 text-left',
                          { 'bg-accent/75': isSelected },
                        )}
                        variant="ghost"
                        onClick={handleChoiceOnClick(
                          element.left.name as string,
                          choice,
                        )}
                      >
                        <ChoiceSet.Choice choice={choice} />
                        <CheckIcon
                          className={cn('h-4 w-4 opacity-0 transition-all', {
                            'opacity-100': isSelected,
                          })}
                        />
                      </BlockButton>
                    );
                  })}
                </div>
              </ScrollArea>
            </div>
          </PopoverContent>
        </Popover>
      );
    },
    [choiceSets, constants, item.data.choiceSetId, handleChoiceOnClick],
  );

  const renderBoolean = useCallback(
    (element: BinaryExpression) => {
      const value = constants?.[element.left.name as string]?.value;
      return (
        <Popover>
          <PopoverTrigger asChild>
            <BlockButton className="ml-1 mr-2 min-w-0 p-0">
              {Boolean(value) ? 'true' : 'false'}
            </BlockButton>
          </PopoverTrigger>
          <PopoverContent className="max-h-[500px] min-h-0 p-0" align="start">
            <BlockButton
              className="w-full justify-start gap-x-2 text-left"
              onClick={handleConstantOnChange(
                element.left.name as string,
                true,
              )}
            >
              <Checkbox checked={value === true} />
              true
            </BlockButton>
            <BlockButton
              className="w-full justify-start gap-x-2 text-left"
              onClick={handleConstantOnChange(
                element.left.name as string,
                false,
              )}
            >
              <Checkbox checked={value === false} />
              false
            </BlockButton>
          </PopoverContent>
        </Popover>
      );
    },
    [constants, handleConstantOnChange],
  );

  if (!condition) return null;
  if (!body) return null;

  return (
    <div className="group flex pl-2 text-sm">
      <div className="flex h-full grow items-center gap-x-1">
        {body.map((element) => {
          const key = String(element.name);

          if (element.type === 'BinaryExpression') {
            if (element.right.name === DataType.CHOICE) {
              return (
                <React.Fragment key={key}>
                  {renderChoice(element)}
                </React.Fragment>
              );
            }

            if (element.right.name === DataType.BOOLEAN) {
              return (
                <React.Fragment key={key}>
                  {renderBoolean(element)}
                </React.Fragment>
              );
            }

            return (
              <div key={key}>
                <input
                  value={constants?.[element.left.name as string]?.value || ''}
                  onChange={(event) =>
                    handleInputOnChange(element.left.name as string)(
                      event.target.value,
                    )
                  }
                />
              </div>
            );
          }

          if (element.name.startsWith('$')) {
            return (
              <Select
                key={key}
                onValueChange={(value) => {
                  if (value) {
                    try {
                      handleConditionOnChange(JSON.parse(value));
                    } catch (error) {
                      console.error('Failed to parse condition', error);
                    }
                  }
                }}
              >
                <SelectPrimitive.Trigger asChild>
                  <BlockButton className="px-0">
                    {element.name.replace('$', '').replace(/_/g, ' ')}
                  </BlockButton>
                </SelectPrimitive.Trigger>
                <SelectContent>
                  {CONDITIONS[item.data.dataType].map((condition) => (
                    <SelectItem
                      key={condition.id}
                      value={JSON.stringify(condition)}
                    >
                      {condition.title}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            );
          }

          return (
            <span key={key} style={{ lineHeight: '34px' }}>
              {element.name}{' '}
            </span>
          );
        })}
        <span style={{ lineHeight: '34px' }}>then ask questions</span>
      </div>
      <div className="opacity-0 transition-opacity group-hover:opacity-100">
        <BlockButton onClick={handleDeleteOnClick}>Delete branch</BlockButton>
      </div>
    </div>
  );
};

export default BlockConditionRenderer;
