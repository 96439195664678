import { camelCase, isArray, isObject, transform } from 'lodash';
import { SnakeCaseToCamelCase } from 'lib/types';

const snakeCaseToCamelCase = <T extends Record<string, any>>(
  obj: T,
): SnakeCaseToCamelCase<T> => {
  const isUuid = (obj: string) => {
    // Regex to check if uuid.
    // https://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
    const uuidRegex = new RegExp(
      '^[0-9a-f]{8}-?[0-9a-f]{4}-?4[0-9a-f]{3}-?[89ab][0-9a-f]{3}-?[0-9a-f]{12}$',
      'i',
    );

    return uuidRegex.test(obj);
  };

  const pass = (inner: any) =>
    transform(inner, (ret: any, value: any, key: any, target: any) => {
      const camelKey =
        isUuid(key as string) || isArray(target)
          ? key
          : camelCase(key as string);
      ret[camelKey] = isObject(value) ? pass(value) : value;
    });

  return pass(obj);
};

export default snakeCaseToCamelCase;
