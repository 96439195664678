import { useCallback, useMemo, useState } from 'react';
import { useSyncedStore } from '@syncedstore/react';
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useCommercialActivityContext } from '../../../../../../context';
import { useActivityTableContext } from '../../../../context';
import ValueCreate from '../ValueCreate';
import ValueLine from '../ValueLine';

const ValueBody = () => {
  const { store } = useCommercialActivityContext();
  const { valueLines } = useActivityTableContext();

  const { order } = useSyncedStore(store);

  const valueLinesSorted = useMemo(() => {
    return [...valueLines].sort((a, b) => {
      return order.indexOf(a) - order.indexOf(b);
    });
  }, [JSON.stringify(order), valueLines]);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  const [active, setActive] = useState<string | null>(null);

  const handleOnDragStart = useCallback(({ active }: DragStartEvent) => {
    if (active) {
      setActive(active.id as string);
    }
  }, []);

  const handleOnDragCancel = useCallback(() => {
    setActive(null);
  }, []);

  const handleOnDragEnd = useCallback(
    ({ over }: DragEndEvent) => {
      setActive(null);
      if (over) {
        const overIndex = store.order.indexOf(over.id as string);
        const activeIndex = store.order.indexOf(active as string);
        if (activeIndex !== overIndex) {
          store.order.splice(
            overIndex,
            0,
            store.order.splice(activeIndex, 1)[0],
          );
        }
      }
    },
    [active, JSON.stringify(store.order)],
  );

  return (
    <>
      <div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          onDragStart={handleOnDragStart}
          onDragCancel={handleOnDragCancel}
          onDragEnd={handleOnDragEnd}
        >
          <SortableContext
            items={valueLinesSorted}
            strategy={verticalListSortingStrategy}
          >
            {valueLinesSorted.map((line) => (
              <ValueLine key={line.id} line={line} />
            ))}
          </SortableContext>
        </DndContext>
      </div>
      <ValueCreate />
    </>
  );
};

export default ValueBody;
