import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'sonner';
import { SubcontractorReport, SubcontractorReportStatus } from 'lib/types';
import { useJobTaskContext } from '../../../../../../../../context';
import {
  SubcontractorReportQueryData,
  SubcontractorReportQueryVariables,
} from './types';

export const useSubcontractorReport = (
  report: SubcontractorReport | undefined,
) => {
  const { task } = useJobTaskContext();

  const { data, refetch } = useQuery<
    SubcontractorReportQueryData,
    SubcontractorReportQueryVariables
  >(
    gql`
      query GetSubcontractorReport($id: ID!) {
        subcontractorReport(subcontractorReportId: $id) {
          id
          status
          statusDisplay
          duty {
            id
            dateTimeStart
            dateTimeEnd
            subcontractor {
              id
              name
            }
          }
          sourceObject {
            id
            name
          }
        }
      }
    `,
    { variables: { id: report!.id } },
  );

  const [update] = useMutation(
    gql`
      mutation UpdateSubcontractorReport(
        $id: ID!
        $field: String!
        $value: JSONScalarInput!
      ) {
        updateSubcontractorReport(
          subcontractorReportId: $id
          field: $field
          value: $value
        ) {
          subcontractorReport {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnUpdate = (field: string, value: string) => {
    return async () => {
      if (!task || !report) return;

      const { data, errors } = await update({
        variables: {
          id: report.id,
          field,
          value,
        },
      });

      if (data) {
        await refetch();
        await task.refetch();
        toast.success('Subcontractor report status updated');
      }

      if (errors) {
        toast.error('Failed to update subcontractor report status');
        console.error(errors);
      }
    };
  };

  return {
    context: {
      report: {
        ...data?.subcontractorReport,
        refetch,
      },
      handlers: {
        onReviewed: handleOnUpdate(
          'status',
          SubcontractorReportStatus.Reviewed,
        ),
        onReset: handleOnUpdate(
          'status',
          SubcontractorReportStatus.ReviewPending,
        ),
      },
    },
  };
};
