import React from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { Edit3 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { gql, useMutation } from '@apollo/client';
import { Action, Actions } from 'external';
import ShortUniqueId from 'short-unique-id';
import { toast } from 'sonner';
import { useUploadFile } from 'lib/hooks';
import { AsyncContext, Report, ReportMode, ReportView } from 'lib/shared';
import { JobReportStatus, JobTask } from 'lib/types';
import DownloadButton from '../../../../../../shared/DownloadButton';
import { useMediaContext } from '../../../../../../shared/MediaProvider';
import { useJobContext } from '../../../../context';
import { useJobReportContext } from '../../context';
import template from '../../template.json';
import { render } from '../../utils';
import { footer } from '../ReportVersion';
import blocks from '../ReportVersion/components/VersionRenderer/blocks';
import { useJobReportVersionContext } from '../ReportVersion/context';

const ReportActions = () => {
  const uploadFile = useUploadFile();
  const { registeredObjects, registeredObjectSources } = useMediaContext();

  const { job } = useJobContext();
  const { report } = useJobReportContext();
  const { state, synced, version, cache } = useJobReportVersionContext();

  const { meta } = useSyncedStore(synced.store);

  const [approve] = useMutation(gql`
    mutation ApproveJobReportVersion(
      $jobReportVersionId: ID!
      $jobReportVersionSourceContext: JSONScalarInput!
      $jobReportVersionSourceObject: PlatformObjectInput!
    ) {
      approveJobReportVersion(
        jobReportVersionId: $jobReportVersionId
        jobReportVersionSourceContext: $jobReportVersionSourceContext
        jobReportVersionSourceObject: $jobReportVersionSourceObject
      ) {
        jobReportVersion {
          id
        }
      }
    }
  `);

  const handleOnApprove = async () => {
    if (job.partial) return;

    if (!report) {
      console.warn('Report not found');
      return;
    }

    if (!version) {
      console.warn('Version not found');
      return;
    }

    synced.store.meta.isCompleted = true;
    console.debug('Report completed');

    const context = {
      selected: report.selected,
      job: {
        ...job,
        status: job.status.map(({ label }) => label).join(', '),
      },
      jobReport: report,
      title: report.name,
      tasks: (job.tasks ?? []) as JobTask[],
    };

    const documents = state.documents
      .map((id) => registeredObjectSources.find((source) => source.id === id))
      .filter(Boolean)
      .map((source) => {
        return {
          id: source!.id,
          source: source!,
        };
      });

    const blob = await render(
      <Report.Root
        mode={ReportMode.PDF}
        cache={cache}
        template={template}
        blocks={blocks}
        footer={footer}
        context={context}
        images={state.images}
        documents={state.documents}
        registeredObjects={registeredObjects}
        registeredObjectSources={registeredObjectSources}
      />,
    );

    if (!blob) {
      console.warn('Failed to create PDF');
      toast.error('Failed to create PDF');
      return;
    }

    // todo: look into this
    // @ts-ignore
    const { randomUUID } = new ShortUniqueId({ length: 10 });
    const platformObject = await uploadFile(
      new File([blob], `${report.slug}-${randomUUID()}.pdf`, {
        type: 'application/pdf',
      }),
    );

    if (!platformObject) {
      console.warn('Failed to upload PDF');
      toast.error('Failed to upload PDF');
      return;
    }

    const { data } = await approve({
      variables: {
        jobReportVersionId: version.id,
        jobReportVersionSourceContext: { ...context, template },
        jobReportVersionSourceObject: {
          id: platformObject.id,
          type: platformObject.type,
          size: platformObject.size,
          name: platformObject.name,
          data: platformObject.data,
        },
      },
    });

    if (!data) {
      console.warn('Failed to approve report');
      toast.error('Failed to approve report');
      return;
    }

    synced.store.meta.isApproved = true;
    console.debug('Report approved');

    await version.refetch();
    console.debug('Report refetched');

    toast.success('Report approved');
  };

  if (job.partial || !report || !state.ready) {
    return null;
  }

  return (
    <div className="pr-4">
      {meta.isApproved ? (
        <div className="flex gap-x-2">
          <DownloadButton
            id={version?.sourceObject?.id}
            variant="outline"
            size="sm"
          />
          {report.status === JobReportStatus.Open && (
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="outline" size="sm">
                  <Edit3 className="mr-2 size-4" />
                  Edit
                </Button>
              </DialogTrigger>
              <DialogContent overlay>
                <DialogHeader>
                  <DialogTitle>
                    Are you sure you want to edit the report?
                  </DialogTitle>
                  <DialogDescription>
                    To edit the report press the button below.
                  </DialogDescription>
                </DialogHeader>
                <div>
                  <DialogClose asChild>
                    <Button
                      className="mb-4 w-full"
                      onClick={() => {
                        meta.isApproved = false;
                      }}
                    >
                      Edit report
                    </Button>
                  </DialogClose>
                  <p className="text-xs text-gray-400 dark:text-gray-400">
                    If you edit this report it will need re-approval before any
                    further actions can be taken. Please ensure it is necessary
                    before proceeding.
                  </p>
                </div>
              </DialogContent>
            </Dialog>
          )}
          <Actions
            actionSetId={report.actionSetId as string}
            scope={`jobs::job-report::${report.id}`}
            queryKey={[report.id, 'actions']}
            context={{
              event: {
                data: {
                  job: {
                    id: job.id,
                    reference: job.reference,
                    external_id: job.externalId
                      ? parseInt(job.externalId, 10)
                      : undefined,
                  },
                  job_report: {
                    id: report.id,
                    status: report.status,
                    number: report.number,
                  },
                  job_report_version: {
                    id: version?.id,
                    status: version?.statusExternal,
                    external_id: version?.externalId,
                    source_object: version?.sourceObject
                      ? {
                          id: version.sourceObject.id,
                          name: version.sourceObject.name
                            .split('.')
                            .slice(0, -1)
                            .join('.'),
                        }
                      : undefined,
                  },
                },
              },
            }}
          >
            <>
              <Action
                id="send-to-client"
                small
                preview={version?.sourceObject}
              />
              <Action id="revoke-from-client" small />
            </>
          </Actions>
        </div>
      ) : (
        <AsyncContext handle={handleOnApprove}>
          {({ handle, loading }) => (
            <Dialog>
              <DialogTrigger asChild>
                <Button
                  variant="outline"
                  size="sm"
                  className="w-full"
                  loading={loading}
                >
                  Approve report
                </Button>
              </DialogTrigger>
              <DialogContent overlay>
                <div>
                  <p className="mb-2 text-sm font-semibold">
                    Are you sure you want to approve the report?
                  </p>
                  <p className="mb-4 text-xs">
                    To approve the report press the button below.
                  </p>
                  <DialogClose asChild>
                    <Button className="mb-4 w-full" onClick={handle}>
                      Approve report
                    </Button>
                  </DialogClose>
                  <p className="text-xs text-gray-400 dark:text-gray-400">
                    Upon approval the report will be rendered as a PDF and
                    stored ready to be sent to the client or other parties.
                    Please ensure all information is correct before proceeding.
                  </p>
                </div>
              </DialogContent>
            </Dialog>
          )}
        </AsyncContext>
      )}
    </div>
  );
};

export default ReportActions;
