import { useCallback, useContext, useMemo, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { PlatformObject, PlatformObjectSources } from 'lib/types';
import { platformClient } from '../../app/platform';
import { MediaBoundaryContext, MediaContext } from './context';
import { createSourceObject } from './utils';

export const useMediaProvider = () => {
  const getObjects = useCallback(async (objectIds: PlatformObject['id'][]) => {
    if (objectIds.length === 0) return [];
    return platformClient.getObjects(objectIds)();
  }, []);

  const [registeredObjects, setRegisteredObjects] = useState<
    Omit<PlatformObject, 'sources'>[]
  >([]);

  const registerObjects = useCallback(
    (objects: Omit<PlatformObject, 'sources'>[]) => {
      setRegisteredObjects((prevRegisteredObjects) => {
        const ret = [...prevRegisteredObjects];
        for (const object of objects) {
          const prevObject = ret.find((inner) => inner.id === object.id);
          if (prevObject) {
            ret.push({
              ...prevObject,
              ...object,
            });
          } else {
            ret.push(object);
          }
        }
        return ret;
      });
    },
    [],
  );

  const registerObjectSources = useCallback(
    (
      objectSources: {
        id: PlatformObject['id'];
        sources: PlatformObjectSources;
      }[],
    ) => {
      setRegisteredObjectSources((prevRegisteredObjectSources) => [
        ...prevRegisteredObjectSources,
        ...objectSources.filter(
          (objectSource) =>
            !prevRegisteredObjectSources.find(
              (prevObjectSource) => prevObjectSource.id === objectSource.id,
            ),
        ),
      ]);
    },
    [],
  );

  const deregisterObjects = useCallback((objectIds: PlatformObject['id'][]) => {
    setRegisteredObjects((prevRegisteredObjects) =>
      prevRegisteredObjects.filter(
        (prevObject) => !objectIds.includes(prevObject.id),
      ),
    );
  }, []);

  const [registeredObjectSources, setRegisteredObjectSources] = useState<
    { id: PlatformObject['id']; sources: PlatformObjectSources }[]
  >([]);

  useDeepCompareEffect(() => {
    (async () => {
      const objectIds = registeredObjects
        .map((object) => object.id)
        .filter(Boolean);
      const objects = await getObjects?.(objectIds);
      setRegisteredObjectSources(
        (objects ?? [])
          .filter((object) => !!object.getUrl || !!object.getUrlCompressed)
          .map((object) => ({
            id: object.id,
            sources: {
              full: createSourceObject(object.getUrl!, 800, 800),
              thumbnail: createSourceObject(
                object.getUrlThumbnail ?? object.getUrl!,
                200,
                200,
              ),
              compressed: createSourceObject(
                object.getUrlCompressed || object.getUrl!,
                200,
                200,
              ),
            },
          })),
      );
    })();
  }, [getObjects, registeredObjects]);

  return {
    registeredObjects,
    registeredObjectSources,
    registerObjects,
    registerObjectSources,
    deregisterObjects,
  };
};

export const useGetObjectSource = () => {
  const { registeredObjectSources } = useContext(MediaContext);
  return useCallback(
    (objectId: PlatformObject['id']) =>
      registeredObjectSources.find((source) => source.id === objectId)?.sources,
    [registeredObjectSources],
  );
};

export const useObjectSource = (
  object: PlatformObject | Omit<PlatformObject, 'sources'>,
) => {
  const { registeredObjectSources } = useContext(MediaContext);
  return useMemo(() => {
    const registeredObjectSource = registeredObjectSources.find(
      (source) => source.id === object.id,
    );
    return registeredObjectSource?.sources;
  }, [object, registeredObjectSources]);
};

export const useRegisterObjectsOnMount = (
  objectsWithoutSources: Omit<PlatformObject, 'sources'>[] | undefined,
) => {
  const { registerObjects } = useContext(MediaContext);

  useDeepCompareEffect(() => {
    if (!objectsWithoutSources || !registerObjects) return;
    registerObjects(objectsWithoutSources);
  }, [objectsWithoutSources, registerObjects]);

  const getObjectSource = useGetObjectSource();

  return {
    getObjectSource,
  };
};

export const useForceUpdateOnMediaChange = () => {
  const { registeredObjectSources } = useContext(MediaContext);
  const [, forceUpdate] = useState(0);

  useDeepCompareEffect(() => {
    forceUpdate((prev) => prev + 1);
  }, [registeredObjectSources]);

  return {
    forceUpdate,
  };
};

export const useMediaContext = () => {
  return useContext(MediaContext);
};

export const useMediaBoundaryContext = () => {
  return useContext(MediaBoundaryContext);
};
