import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { DateTime, Interval } from 'luxon';
import { RosterTeam } from 'lib/types';
import { useStore } from './state';

export type RosterDutiesQueryVariables = {
  dateTimeStart: DateTime;
  dateTimeEnd: DateTime;
  teams?: string[];
};

export type RosterDutiesQueryData = {
  rosterTeams: RosterTeam[];
  rosterDutyCountPerDay: Record<string, number>;
  rosterDutySubmittedPerDay: Record<string, number>;
  rosterDutyApprovedPerDay: Record<string, number>;
};

export const useInterval = () => {
  const dateTimeStart = useStore((state) => state.dateTimeStart);
  return useMemo(
    () =>
      Interval.fromDateTimes(dateTimeStart, dateTimeStart.plus({ years: 1 })),
    [dateTimeStart],
  );
};

export const useInitialQuery = () => {
  const interval = useInterval();

  const { set, select, teams } = useStore((state) => ({
    set: state.setRosterTeams,
    select: state.selectRosterTeams,
    teams: state.selectedRosterTeamIds,
  }));

  const { networkStatus } = useQuery<{ rosterTeams: RosterTeam[] }>(
    gql`
      query GetRosterTeams {
        rosterTeams {
          id
          name
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        set(data.rosterTeams);
        select(data.rosterTeams.map((team) => team.id));
      },
    },
  );

  return useQuery<RosterDutiesQueryData, RosterDutiesQueryVariables>(
    gql`
      query GetCalendarData(
        $dateTimeStart: DateTime!
        $dateTimeEnd: DateTime!
        $teams: [ID!]
      ) {
        rosterDutyCountPerDay(
          rangeDateTimeStart: $dateTimeStart
          rangeDateTimeEnd: $dateTimeEnd
          teamIds: $teams
          status: 0
        )

        rosterDutySubmittedPerDay: rosterDutyCountPerDay(
          rangeDateTimeStart: $dateTimeStart
          rangeDateTimeEnd: $dateTimeEnd
          teamIds: $teams
          status: 1
        )

        rosterDutyApprovedPerDay: rosterDutyCountPerDay(
          rangeDateTimeStart: $dateTimeStart
          rangeDateTimeEnd: $dateTimeEnd
          teamIds: $teams
          status: 2
        )
      }
    `,
    {
      skip: networkStatus !== 7,
      fetchPolicy: 'no-cache',
      variables: {
        dateTimeStart: interval.start as DateTime,
        dateTimeEnd: interval.end as DateTime,
        teams,
      },
      onCompleted: useStore((state) => state.initialQueryOnCompleted),
    },
  );
};
