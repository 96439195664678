import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from '@/components/ui/sonner';
import { TooltipProvider } from '@/components/ui/tooltip';
import { ApolloProvider } from '@apollo/client';
import { ScopeProvider } from 'lib/shared';
import CollaborationProvider from '../../external/CollaborationProvider';
import EventsProvider from '../../external/EventsProvider';
import TodosProvider from '../../external/TodosProvider';
import LayoutRouter from '../../layout/LayoutRouter';
import MediaProvider from '../../shared/MediaProvider';
import { useSetup } from './hooks';

const AppInner = () => {
  const { httpClient, queryClient } = useSetup();
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={httpClient}>
          <CollaborationProvider>
            <MediaProvider>
              <ScopeProvider>
                <EventsProvider>
                  <TodosProvider>
                    <TooltipProvider delayDuration={500}>
                      <LayoutRouter />
                    </TooltipProvider>
                  </TodosProvider>
                </EventsProvider>
              </ScopeProvider>
            </MediaProvider>
          </CollaborationProvider>
        </ApolloProvider>
      </QueryClientProvider>
      <Toaster />
    </>
  );
};

export default AppInner;
