import { useSyncedStore } from '@syncedstore/react';
import chroma from 'chroma-js';
import { ChoiceSet, DataType } from 'external/FormTemplateEditor';
import { Item } from 'external/Tree';
import { BlockData, LogicData } from 'external/types';
import { generateId } from 'lib/utils';
import { useFormTemplateContext } from '../../context';
import { useStore, useSyncedTransact } from '../../hooks';

export const useChoiceSets = (item: Item<BlockData>) => {
  const apply = useStore((state) => state.apply);

  const { store } = useFormTemplateContext();
  const { choiceSets, items } = useSyncedStore(store);
  const transact = useSyncedTransact(store);

  const handleChoiceSetOnClick = (choiceSet: ChoiceSet) => {
    return () => {
      transact(() => {
        (items[item.id].data as BlockData).choiceSetId = choiceSet.id;
        (items[item.id].data as BlockData).dataType = DataType.CHOICE_SET;
        for (const childId of items[item.id].children) {
          const child = items[childId];
          if (child.type === 'LOGIC') {
            (items[child.id].data as LogicData).constants = undefined;
            console.debug('cleared constants for', child.id);
          }
        }
      });
    };
  };

  const handleCreateChoiceSetOnClick = () => {
    const id = generateId();
    choiceSets[id] = {
      id,
      choices: [
        {
          id: generateId(),
          value: 'New choice',
          color: chroma.random().hex(),
        },
      ],
    };
    setTimeout(() => {
      apply((state) => {
        state.choiceSetId = id;
        state.choiceSetEditorIsOpen = true;
      });
    }, 100);
  };

  const handleEditChoiceSetOnClick = (choiceSet: ChoiceSet) => {
    return () => {
      apply((state) => {
        state.choiceSetEditorIsOpen = true;
        state.choiceSetId = choiceSet.id;
      });
    };
  };

  return {
    choiceSets: Object.values(choiceSets),
    handleChoiceSetOnClick,
    handleCreateChoiceSetOnClick,
    handleEditChoiceSetOnClick,
  };
};
