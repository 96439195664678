import React from 'react';
import { PendingStaffMemberDiffProps, PendingStaffMemberProps } from './types';

const PendingStaffMember = ({ className, value }: PendingStaffMemberProps) => {
  return (
    <div className={className}>
      <div className="mb-1">
        <p className="text-xs font-semibold uppercase tracking-wide text-gray-400 dark:text-gray-300">
          Staff member
        </p>
      </div>
      <div className="flex items-center gap-x-2">
        <p className="font-medium">{value.name}</p>
      </div>
    </div>
  );
};

const PendingStaffMemberDiff = ({
  className,
  duty,
  diff,
}: PendingStaffMemberDiffProps) => {
  const isSame = duty.id === diff.id;
  return (
    <div className={className}>
      <div className="mb-1 flex items-center justify-between">
        <p className="text-xs font-semibold uppercase tracking-wide text-gray-400 dark:text-gray-300">
          Staff member
        </p>
        {isSame ? (
          <p className="text-sm dark:text-gray-400">(unchanged)</p>
        ) : (
          <p className="text-sm dark:text-gray-400">(transferred)</p>
        )}
      </div>
      <div className="flex justify-between">
        {isSame ? (
          '-'
        ) : (
          <div className="flex items-center gap-x-2 line-through dark:text-gray-400">
            <p className="font-medium">{duty.name}</p>
          </div>
        )}
        <div className="flex items-center gap-x-2">
          <p className="font-medium">{diff.name}</p>
        </div>
      </div>
    </div>
  );
};

PendingStaffMember.Diff = PendingStaffMemberDiff;

export default PendingStaffMember;
