import { useSubcontractorContext } from '../../context';
import ContactsCreate from './components/ContactsCreate';
import ContactsTable from './components/ContactsTable';

const SubcontractorContacts = () => {
  const { subcontractor } = useSubcontractorContext();

  if (!subcontractor?.contacts) return null;

  return (
    <div
      className="overflow-hidden rounded-lg border"
      style={{ gridArea: 'contacts' }}
    >
      <div className="flex border-b bg-background-secondary p-4">
        <h3 className="flex-grow font-semibold">Contacts</h3>
        <ContactsCreate />
      </div>
      <ContactsTable />
    </div>
  );
};

export default SubcontractorContacts;
