import React, { Fragment } from 'react';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { Row, Table as TableType, flexRender } from '@tanstack/react-table';
import { DotIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { TableCell, TableRow } from '@/components/ui/table';
import { cn } from '@/lib/utils';
import { formatDistanceToNow } from 'date-fns';
import { DateTime } from 'luxon';
import { TimesheetDuty } from 'lib/types';
import { getTimesheetDutyColor } from '../../utils';

const AssignmentRowStaffMember = ({
  table,
  row,
}: {
  table: TableType<TimesheetDuty>;
  row: Row<TimesheetDuty>;
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <Fragment key={row.id}>
      <TableRow
        removeHoverStyles
        data-state={row.getIsSelected() && 'selected'}
        className={cn('h-16', {
          'border-b-0': row.original.events.length > 0,
        })}
        style={{
          borderLeft: `4px solid ${getTimesheetDutyColor(row.original)
            .alpha(0.5)
            .hex()}`,
        }}
      >
        <TableCell className="pl-4 pr-0">
          <Button
            variant="outline"
            size="icon"
            disabled={row.original.events.length === 0}
            onClick={() => setOpen((prevOpen) => !prevOpen)}
          >
            <ChevronRightIcon
              className={cn('h-4 w-4 transition-all', {
                'rotate-90': open,
              })}
            />
          </Button>
        </TableCell>
        {row.getVisibleCells().map((cell) => (
          <TableCell key={cell.id} className="w-0">
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        ))}
      </TableRow>
      {open && (
        <>
          {row.original.events.length > 0 ? (
            <TableRow
              removeHoverStyles
              style={{
                borderLeft: `4px solid ${getTimesheetDutyColor(row.original)
                  .alpha(0.5)
                  .hex()}`,
              }}
            >
              <TableCell
                className="p-0 pb-3 pl-[14px]"
                colSpan={table.getAllColumns().length + 1}
              >
                <ul className="space-y-6">
                  {row.original.events.map((event, index, events) => (
                    <Fragment key={event.id}>
                      <li className="relative flex items-center gap-x-4 px-2">
                        {event.dateTimeEnd &&
                          event.dateTimeEndType &&
                          event.duration && (
                            <div className="absolute -bottom-6 left-2 top-0 flex w-6 justify-center">
                              <div className="w-px bg-gray-300" />
                            </div>
                          )}
                        <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-background">
                          <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-400" />
                        </div>
                        <p className="py-0.5 text-xs leading-5 text-gray-500">
                          <span className="font-medium text-gray-900 dark:text-white">
                            <span className="capitalize">
                              {event.dateTimeStartType.toLowerCase()}
                            </span>{' '}
                            at{' '}
                            {DateTime.fromISO(
                              event.dateTimeStart,
                            ).toLocaleString(DateTime.DATETIME_FULL)}
                          </span>
                        </p>
                        <DotIcon className="h-2 w-2" />
                        <time
                          dateTime={event.dateTimeStart}
                          className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                        >
                          {formatDistanceToNow(event.dateTimeStart, {
                            addSuffix: true,
                          })}
                        </time>
                      </li>
                      {event.dateTimeEnd &&
                        event.dateTimeEndType &&
                        event.duration && (
                          <li className="relative flex items-center gap-x-4 px-2">
                            <div className="relative flex h-6 w-6 flex-none items-center justify-center rounded-md bg-background">
                              <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-400" />
                            </div>
                            <div className="py-0.5 text-xs leading-5 text-gray-500">
                              <p>
                                <span className="font-medium text-gray-900 dark:text-white">
                                  <span className="capitalize">
                                    {event.dateTimeEndType.toLowerCase()}
                                  </span>{' '}
                                  at{' '}
                                  {DateTime.fromISO(
                                    event.dateTimeEnd,
                                  ).toLocaleString(DateTime.DATETIME_FULL)}
                                </span>
                              </p>
                            </div>
                            <DotIcon className="h-2 w-2" />
                            <time
                              dateTime={event.dateTimeEnd}
                              className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                            >
                              {formatDistanceToNow(event.dateTimeEnd, {
                                addSuffix: true,
                              })}
                            </time>
                          </li>
                        )}
                      {events.length > 1 && index < events.length - 1 && (
                        <div>
                          <Separator />
                        </div>
                      )}
                    </Fragment>
                  ))}
                </ul>
              </TableCell>
            </TableRow>
          ) : null}
        </>
      )}
    </Fragment>
  );
};

export default AssignmentRowStaffMember;
