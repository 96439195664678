import { ArrowDown, ArrowUp } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useCommercialActivityContext } from '../../../../../../context';
import { useActivityTableContext } from '../../../../context';

const CostFooter = () => {
  const { activity } = useCommercialActivityContext();
  const {
    totalEstimatedCost,
    totalEstimatedCostWithPrelims,
    totalEstimatedMargin,
    totalEstimatedMarginWithPrelims,
    totalActualCost,
    totalActualCostWithPrelims,
    totalActualMargin,
    totalActualMarginWithPrelims,
    totalMarginDifference,
    totalMarginDifferenceWithPrelims,
  } = useActivityTableContext();
  return (
    <>
      {activity?.contractPrelimFactor !== null && (
        <div className="flex">
          <div className="grid w-7/12 grid-cols-8 border-b">
            <div className="col-span-5 border-r" />
            <div className="col-span-1 flex items-center border-r px-2 text-xs font-semibold">
              Subtotal
            </div>
            <div className="col-span-1 flex items-center px-2 text-sm">
              {totalEstimatedCost.toFixed(2)}
            </div>
            <div
              className={cn(
                'col-span-1 flex h-8 items-center px-2 text-sm font-bold',
                {
                  'bg-red-500/25 text-red-500': totalEstimatedMargin < 0,
                  'bg-green-700/25 text-green-700': totalEstimatedMargin > 0,
                },
              )}
            >
              {totalEstimatedMargin.toFixed(2)}
            </div>
          </div>

          <div className="grid w-5/12 grid-cols-6 border-b">
            <div className="col-span-1 border-r" />
            <div className="col-span-1 flex items-center border-r px-2 text-xs font-semibold">
              Subtotal
            </div>
            <div className="col-span-1 flex items-center px-2 text-sm">
              {totalActualCost.toFixed(2)}
            </div>
            <div
              className={cn(
                'col-span-1 flex h-8 items-center px-2 text-sm font-bold',
                {
                  'bg-red-500/25 text-red-500': totalActualMargin < 0,
                  'bg-green-700/25 text-green-700': totalActualMargin > 0,
                },
              )}
            >
              {totalActualMargin.toFixed(2)}
            </div>
          </div>
        </div>
      )}
      <div className="flex">
        <div className="grid w-7/12 grid-cols-8">
          <div className="col-span-5 border-r" />
          <div className="col-span-1 flex items-center border-r px-2 text-xs font-semibold">
            Total{activity?.contractPrelimFactor !== null && ' w/prelims'}
          </div>
          <div className="col-span-1 flex items-center border-r bg-secondary px-2 text-sm">
            {totalEstimatedCostWithPrelims.toFixed(2)}
          </div>
          <div
            className={cn(
              'col-span-1 flex h-8 items-center px-2 text-sm font-bold',
              {
                'bg-red-500/25 text-red-500':
                  totalEstimatedMarginWithPrelims < 0,
                'bg-green-700/25 text-green-700':
                  totalEstimatedMarginWithPrelims > 0,
              },
            )}
          >
            {totalEstimatedMarginWithPrelims.toFixed(2)}
          </div>
        </div>
        <div className="grid w-5/12 grid-cols-6">
          <div className="col-span-1 border-r" />
          <div className="col-span-1 flex items-center whitespace-nowrap border-r px-2 text-xs font-semibold">
            Total{activity?.contractPrelimFactor !== null && ' w/prelims'}
          </div>
          <div className="col-span-1 flex items-center border-r bg-secondary px-2 text-sm">
            {totalActualCostWithPrelims.toFixed(2)}
          </div>
          <div
            className={cn(
              'items col-span-1 flex h-8 items-center gap-x-0.5 overflow-hidden whitespace-nowrap border-r px-2 text-sm font-bold',
              {
                'bg-red-500/25 text-red-500': totalActualMarginWithPrelims < 0,
                'bg-green-700/25 text-green-700':
                  totalActualMarginWithPrelims > 0,
              },
            )}
          >
            {totalActualMarginWithPrelims.toFixed(2)}
            {totalMarginDifferenceWithPrelims !== 0 && (
              <span
                className={cn('text-xs', {
                  'text-red-500': totalMarginDifferenceWithPrelims < 0,
                  'text-green-700': totalMarginDifferenceWithPrelims > 0,
                })}
              >
                {totalMarginDifferenceWithPrelims > 0 && (
                  <ArrowUp className="inline-block size-4" />
                )}
                {totalMarginDifferenceWithPrelims < 0 && (
                  <ArrowDown className="inline-block size-4" />
                )}
                {totalMarginDifferenceWithPrelims.toFixed(2)}
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CostFooter;
