import { Action } from '../../../../../../../../external';
import Actions from '../../../../../../../../external/Actions';
import { useEstimateQuoteContext } from '../EstimateQuote/context';

const EstimateActions = () => {
  const { jobQuote } = useEstimateQuoteContext();

  if (!jobQuote || !jobQuote.actionSetId) {
    return null;
  }

  return (
    <Actions
      actionSetId={jobQuote.actionSetId}
      scope=""
      context={{
        event: {
          data: {
            job_quote: {
              id: jobQuote.id,
              status: 'OPEN',
            },
          },
        },
      }}
      queryKey={[]}
    >
      <Action id="send-to-client" small />
    </Actions>
  );
};

export default EstimateActions;
