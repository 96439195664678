import { useUserProfileContext } from '../../context';

const ProfileHeader = () => {
  const { user } = useUserProfileContext();

  if (!user) return null;

  return (
    <>
      <div className="flex size-48 items-center justify-center rounded-lg border bg-secondary">
        <p className="text-xl">{user.initials[0]}</p>
      </div>
      <div className="grow rounded-lg border bg-secondary p-8">
        <p className="text-xl">{user.name}</p>
        <p className="text-sm dark:text-gray-400">{user.email}</p>
      </div>
    </>
  );
};

export default ProfileHeader;
