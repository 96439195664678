import React from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { PageLoader, UserPhoto } from 'lib/shared';
import MemberDangerZone from './components/MemberDangerZone';
import MemberDetails from './components/MemberDetails';
import MemberDriverInfo from './components/MemberDriverInfo';
import MemberLineManager from './components/MemberLineManager';
import MemberResourceRates from './components/MemberResourceRates';
import { StaffMemberContext } from './context';
import { useStaffMember } from './hooks';
import { StaffMemberProps } from './types';

const StaffMember = ({ children, id }: StaffMemberProps) => {
  const { context } = useStaffMember(id);
  return (
    <StaffMemberContext.Provider value={context}>
      <Sheet
        open={context.sheet.open}
        onOpenChange={context.sheet.onOpenChange}
      >
        <SheetTrigger asChild>{children}</SheetTrigger>
        <SheetContent className="w-[500px] min-w-[500px] p-0">
          {context.loading ? (
            <PageLoader>Loading user...</PageLoader>
          ) : (
            context.user && (
              <ScrollArea className="h-full min-h-0">
                <MemberDetails />
                <div className="mb-4 border-b px-4 pb-4">
                  <MemberResourceRates />
                </div>
                <div className="mb-4 border-b px-4 pb-4">
                  <MemberLineManager />
                </div>
                <div className="mb-4 border-b px-4 pb-4">
                  <MemberDriverInfo />
                </div>
                <div className="px-4 pb-4">
                  <MemberDangerZone />
                </div>
              </ScrollArea>
            )
          )}
        </SheetContent>
      </Sheet>
    </StaffMemberContext.Provider>
  );
};

export default StaffMember;
