import React, { forwardRef, useMemo } from 'react';
import { LinkProps } from 'react-router-dom';
import { useMatch } from 'lib/hooks';
import { Link } from 'lib/shared';
import { useJobContext } from '../../context';

const JobLink = forwardRef(
  ({ children, to: base, ...rest }: LinkProps, ref) => {
    const { state } = useJobContext();

    const match = useMatch('/clients/:clientSlug/jobs/:jobReference/*');
    const matchVariation = useMatch(
      '/clients/:clientSlug/jobs/:jobReference/:variation/*',
    );

    const to = useMemo(() => {
      if (!match) return '';

      const client = match.params.clientSlug;
      const reference = match.params.jobReference;

      if (state.variation && matchVariation) {
        const variation = matchVariation.params.variation;
        return `/clients/${client}/jobs/${reference}/${variation}/${base}`;
      }

      return base === '_base'
        ? `/clients/${client}/jobs/${reference}`
        : `/clients/${client}/jobs/${reference}/${base}`;
    }, [base, state.variation, match]);

    return (
      <Link ref={ref} to={to} {...rest} relative="path">
        {children}
      </Link>
    );
  },
);

export default JobLink;
