import { useMatch } from 'react-router';
import { gql, useQuery } from '@apollo/client';
import { DateTime, Interval } from 'luxon';
import { User } from 'lib/types';
import { useGetRosterDuties } from '../HumanResourcesRoster/hooks';
import { useStore } from './state';
import { RosterReviewQueryData, RosterReviewQueryVariables } from './types';

export const useTimeInterval = () => {
  const match = useMatch('hr/rosters/reviews/:team/:range');

  const [dateStartMillis, dateEndMillis] = match
    ? (match.params.range as string).split(encodeURIComponent('::'))
    : ['', ''];

  const dateTimeStart = DateTime.fromMillis(parseInt(dateStartMillis));
  const dateTimeEnd = DateTime.fromMillis(parseInt(dateEndMillis));

  return {
    dateTimeStart,
    dateTimeEnd,
    interval: Interval.fromDateTimes(dateTimeStart, dateTimeEnd),
  };
};

export const useTeamId = () => {
  const match = useMatch('hr/rosters/reviews/:team/:range');
  return match?.params?.team as string;
};

export const useInitialQuery = () => {
  const rosterTeamId = useTeamId();
  const { dateTimeStart, dateTimeEnd } = useTimeInterval();
  const { rosterReviewQueryOnCompleted } = useStore((state) => ({
    rosterReviewQueryOnCompleted: state.rosterReviewQueryOnCompleted,
  }));
  return useQuery<RosterReviewQueryData, RosterReviewQueryVariables>(
    gql`
      query (
        $rosterTeamId: ID!
        $dateTimeStartMonth: DateTime!
        $dateTimeEndMonth: DateTime!
      ) {
        rosterTeam(rosterTeamId: $rosterTeamId) {
          id
          name
          defaults
          membersGroup {
            id
            users {
              id
              name
              email
              initials
            }
          }
        }

        rosterDutiesInMonth: rosterDuties(
          stage: 1
          dateTimeStart: $dateTimeStartMonth
          dateTimeEnd: $dateTimeEndMonth
        ) {
          id
          dateTimeStart
          dateTimeEnd
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      variables: {
        rosterTeamId,
        dateTimeStartMonth: dateTimeStart.startOf('month'),
        dateTimeEndMonth:
          dateTimeStart.endOf('month') >
          dateTimeStart.endOf('month').endOf('week')
            ? dateTimeStart.endOf('month')
            : dateTimeStart.endOf('month').endOf('week'),
      },
      onCompleted: (data) =>
        rosterReviewQueryOnCompleted(dateTimeStart, dateTimeEnd, data),
    },
  );
};

export const useUserOnChange = () => {
  const { stage, dateTimeStart, dateTimeEnd, userOnChange } = useStore(
    (state) => ({
      stage: state.stage,
      dateTimeStart: state.dateTimeStart,
      dateTimeEnd: state.dateTimeEnd,
      userOnChange: state.userOnChange,
    }),
  );

  const { getRosterDuties, isLoading, variables } = useGetRosterDuties();

  return (selectedUser: User) => {
    const handleOnClick = async () => {
      const { data } = await getRosterDuties({
        variables: {
          stage,
          dateTimeStart: dateTimeStart,
          dateTimeEnd: dateTimeEnd,
          userId: selectedUser.id,
          getRosterReview: false,
        },
      });
      if (data) {
        userOnChange(selectedUser, data);
      }
    };

    return {
      onClick: handleOnClick,
      isLoading: isLoading && variables?.userId === selectedUser.id,
    };
  };
};

export const useTimeIntervalOnChange = () => {
  const { stage, user, timeIntervalOnChange } = useStore((state) => ({
    stage: state.stage,
    user: state.user,
    timeIntervalOnChange: state.timeIntervalOnChange,
  }));

  const { getRosterDuties } = useGetRosterDuties();

  return async (dateTimeStart: DateTime, dateTimeEnd: DateTime) => {
    if (!user) return;

    const { data } = await getRosterDuties({
      variables: {
        stage,
        dateTimeStart: dateTimeStart,
        dateTimeEnd: dateTimeEnd,
        userId: user.id,
        getRosterReview: true,
      },
    });

    if (data) timeIntervalOnChange(dateTimeStart, dateTimeEnd, data);
  };
};
