import { useState } from 'react';
import { useMatch } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'sonner';
import { useTrainingQualificationsContext } from '../TrainingQualifications/context';
import {
  DeleteTrainingQualificationData,
  DeleteTrainingQualificationVariables,
  TrainingQualificationQueryData,
  TrainingQualificationQueryVariables,
  UpdateTrainingQualificationData,
  UpdateTrainingQualificationVariables,
} from './types';

export const useTrainingQualification = () => {
  const navigate = useNavigate();
  const { refetch: refetchList } = useTrainingQualificationsContext();

  const match = useMatch('/hr/training/qualifications/:id');

  const [open, setOpen] = useState(false);

  const { data, refetch } = useQuery<
    TrainingQualificationQueryData,
    TrainingQualificationQueryVariables
  >(
    gql`
      query GetTrainingQualification($id: ID!) {
        trainingQualification(trainingQualificationId: $id) {
          id
          name
          description
          color
          logo {
            id
            type
            name
            size
            data
          }
          fields
          data
          children {
            id
            name
            description
            fields
            data
          }
        }
      }
    `,
    {
      skip: !match?.params.id,
      variables: { id: match?.params.id as string },
      fetchPolicy: 'cache-and-network',
    },
  );

  const [update] = useMutation<
    UpdateTrainingQualificationData,
    UpdateTrainingQualificationVariables
  >(gql`
    mutation UpdateTrainingQualification(
      $id: ID!
      $data: TrainingQualificationInput!
    ) {
      updateTrainingQualification(
        trainingQualificationId: $id
        trainingQualificationData: $data
      ) {
        trainingQualification {
          id
        }
      }
    }
  `);

  const handleOnUpdate = async (values: any) => {
    if (!match?.params.id) return;

    const { data, errors } = await update({
      variables: {
        id: match?.params.id as string,
        data: {
          ...values,
          data: {},
          logo: values.logo
            ? {
                id: values.logo.id,
                type: values.logo.type,
                name: values.logo.name,
                size: values.logo.size,
                data: values.logo.data,
              }
            : undefined,
        },
      },
    });

    if (data) {
      await refetch();
      await refetchList();
      setOpen(false);
      toast.success('Qualification updated successfully');
    }

    if (errors) {
      toast.error('Failed to update qualification');
    }
  };

  const [delete_] = useMutation<
    DeleteTrainingQualificationData,
    DeleteTrainingQualificationVariables
  >(gql`
    mutation DeleteTrainingQualification($id: ID!) {
      deleteTrainingQualification(trainingQualificationId: $id) {
        deleted
      }
    }
  `);

  const handleOnDelete = async () => {
    if (!match?.params.id) return;

    const { data, errors } = await delete_({
      variables: {
        id: match?.params.id as string,
      },
    });

    if (data) {
      await refetch();
      await refetchList();
      navigate('/hr/training/qualifications');
      toast.success('Qualification deleted successfully');
    }

    if (errors) {
      toast.error('Failed to delete qualification');
    }
  };

  if (!data) {
    return {
      context: {
        state: {
          open,
          onOpen: () => setOpen(true),
          onClose: () => setOpen(false),
        },
        qualification: null,
        handlers: {
          onUpdate: handleOnUpdate,
          onDelete: handleOnDelete,
        },
      },
    };
  }

  return {
    context: {
      state: {
        open,
        onOpen: () => setOpen(true),
        onClose: () => setOpen(false),
      },
      qualification: {
        ...data.trainingQualification,
        refetch,
      },
      handlers: {
        onUpdate: handleOnUpdate,
        onDelete: handleOnDelete,
      },
    },
  };
};
