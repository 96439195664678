import React, { useState } from 'react';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { CheckSquare, Square } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { TextareaAutosize } from '@/components/ui/textarea';
import { toast } from 'sonner';
import { useInstantiationContext } from '../../hooks';
import {
  InstantiationStageConfirmProps,
  InstantiationStageIssueEditorProps,
  InstantiationStageIssueProps,
  InstantiationStageIssuesProps,
} from './types';

const InstantiationIssues = () => {
  return null;
};

export const InstantiationStageIssues = ({
  stage,
  types,
  disabled = false,
}: InstantiationStageIssuesProps) => {
  if (!stage) return null;
  return (
    <>
      {stage.issues.length > 0 && (
        <div className="mb-4 flex flex-col gap-y-4">
          {stage.issues.map((issue) => (
            <InstantiationStageIssue
              key={issue.id}
              stage={stage}
              issue={issue}
              types={types}
            />
          ))}
        </div>
      )}
      <InstantiationStageConfirm
        stage={stage}
        issues={stage.issues}
        disabled={disabled}
      />
    </>
  );
};

export const InstantiationStageIssue = ({
  stage,
  issue,
  types,
}: InstantiationStageIssueProps) => {
  const { deleteIssue } = useInstantiationContext();
  const type = types.find((type) => type.value === issue.type);
  return (
    <Card key={issue.id}>
      <CardContent className="p-0">
        <div className="flex items-center">
          <div className="px-4">
            <ExclamationTriangleIcon className="h-6 w-6 text-warning" />
          </div>
          <div className="grow border-x p-4">
            <p className="mb-1 text-sm font-semibold">
              {type?.label ?? 'Issue reported'}
            </p>
            {issue.note.length > 0 ? (
              <p className="text-xs text-gray-600 dark:text-gray-400">
                {issue.note}
              </p>
            ) : (
              <p className="text-xs italic text-gray-600 dark:text-gray-400">
                No further issue information
              </p>
            )}
          </div>
          <div className="flex gap-x-2 px-4">
            <InstantiationStageIssueEditor
              stage={stage}
              issue={issue}
              types={types}
            />
            <Button
              size="xs"
              variant="outline"
              onClick={() => {
                deleteIssue(stage.id, issue.id);
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export const InstantiationStageIssueEditor = ({
  stage,
  issue,
  types,
}: InstantiationStageIssueEditorProps) => {
  const [open, setOpen] = useState(false);
  const { updateIssue, requestIssueUpdate, cancelIssueUpdate } =
    useInstantiationContext();
  return (
    <Sheet
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (!open) {
          cancelIssueUpdate(stage.id, issue.id);
        }
      }}
    >
      <SheetTrigger asChild>
        <Button
          size="xs"
          variant="outline"
          onClick={() => {
            const granted = requestIssueUpdate(stage.id, issue.id);
            if (!granted) {
              toast.error('Another user is already editing this issue');
            }
          }}
        >
          Edit
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader className="mb-4">
          <SheetTitle>
            {issue.draft ? 'Edit issue' : 'Issue details'}
          </SheetTitle>
          <SheetDescription>
            {issue.draft ? 'Edit the issue details' : 'View the issue details'}
          </SheetDescription>
        </SheetHeader>
        {issue.draft && (
          <div className="flex flex-col gap-y-4">
            <Select
              value={issue.draft.type}
              onValueChange={(value) => {
                if (!issue.draft) return;
                issue.draft.type = value;
              }}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select mode" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {types.map((type) => (
                    <SelectItem key={type.value} value={type.value}>
                      {type.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <TextareaAutosize
              className="min-h-32"
              value={issue.draft.note}
              onChange={(event) => {
                if (!issue.draft) return;
                issue.draft.note = event.target.value;
              }}
            />
            <div className="flex justify-between gap-x-2">
              <Button
                size="sm"
                variant="outline"
                onClick={() => {
                  setOpen(false);
                  cancelIssueUpdate(stage.id, issue.id);
                }}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                onClick={() => {
                  try {
                    updateIssue(stage.id, issue.id, (issueProxy) => {
                      if (!issueProxy.draft) return;
                      issueProxy.type = issueProxy.draft.type;
                      issueProxy.note = issueProxy.draft.note;
                    });
                    setOpen(false);
                  } catch (error) {
                    console.error('Failed to update issue', error);
                    toast.error('Failed to update issue');
                  }
                }}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
};

export const InstantiationStageConfirm = ({
  stage,
  issues,
  disabled = false,
}: InstantiationStageConfirmProps) => {
  const { confirmStage, resetStage, createIssue } = useInstantiationContext();
  return (
    <div className="flex overflow-hidden rounded-lg border">
      <Button
        className="grow justify-start gap-x-1 rounded-none border-r text-start"
        size="sm"
        variant="ghost"
        disabled={disabled || issues.length > 0}
        onClick={() => confirmStage(stage.id)}
      >
        {stage.isConfirmed ? (
          <>
            <CheckSquare className="h-4 w-4" />
            Confirmed
          </>
        ) : (
          <>
            <Square className="h-4 w-4" />
            {issues.length > 0 ? 'Issues present' : 'Confirm stage'}
          </>
        )}
      </Button>
      {stage.isConfirmed ? (
        <Button
          className="rounded-none border-r"
          size="sm"
          variant="ghost"
          onClick={() => resetStage(stage.id)}
        >
          Reset
        </Button>
      ) : (
        <Button
          className="rounded-none"
          size="sm"
          variant="ghost"
          onClick={() => {
            createIssue(stage.id);
          }}
        >
          Add issue
        </Button>
      )}
    </div>
  );
};

export default InstantiationIssues;
