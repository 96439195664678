import React from 'react';
import { DotIcon } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { useJobContext } from '../../../../../../context';
import { useCommercialActivityContext } from '../../../CommercialActivity/context';
import { useCommercialEstimatesContext } from '../../context';
import EstimatesCopy from '../EstimatesCopy';
import EstimatesCreate from '../EstimatesCreate';
import EstimatesDelete from '../EstimatesDelete';
import EstimatesMode from '../EstimatesMode';

const EstimatesToolbar = () => {
  const { job } = useJobContext();
  const { activity } = useCommercialActivityContext();
  const { state, handlers } = useCommercialEstimatesContext();
  return (
    <div className="relative flex h-12 flex-shrink-0 items-center gap-x-2 border-b bg-background px-4">
      <p className="ml-4 text-sm font-medium">Estimates: </p>
      <div className="flex grow gap-x-2">
        {activity.estimates.map((estimate) => {
          const quote =
            !job.partial &&
            job.quotes.find(
              (quote) => quote.commercialEstimate.id === estimate.id,
            );
          return (
            <Button
              key={estimate.id}
              size="sm"
              variant={state.selected === estimate.id ? 'default' : 'secondary'}
              className="h-7"
              onClick={() => handlers.onSelect(estimate.id)}
            >
              [#{estimate.number}] {estimate.name}{' '}
              <DotIcon className="mx-1 h-2 w-2" />{' '}
              {quote ? (
                <span className="font-semibold text-green-600">
                  {quote.reference}
                </span>
              ) : (
                <span className="font-semibold">{estimate.statusDisplay}</span>
              )}
            </Button>
          );
        })}
        <EstimatesCreate />
      </div>
      {state.selected && (
        <>
          <EstimatesCopy />
          <EstimatesDelete />
        </>
      )}
      <EstimatesMode />
    </div>
  );
};

export default EstimatesToolbar;
