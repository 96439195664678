import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useWindowManager } from '../../../../../../../../shared/WindowManager/hooks';

const SeriesNavigation = () => {
  const { path, setPath } = useWindowManager();
  return (
    <div>
      <div className="flex h-[52px] flex-shrink-0 items-center border-b px-4 py-2">
        <h1
          style={{ fontFamily: 'Roboto Mono' }}
          className={cn(
            'text-2xl font-semibold text-gray-600 dark:text-gray-400',
          )}
        >
          INS0001234
        </h1>
        <ArrowRight className="ml-auto h-4 w-4 text-muted-foreground" />
      </div>
      <div className="mt-4 flex flex-col gap-y-2 px-4">
        <Button
          className="block w-full justify-center text-left"
          variant={path === '/' ? 'selected' : 'outline'}
          onClick={() => {
            setPath('/');
          }}
        >
          Overview
        </Button>
        <Button
          className="block w-full justify-center text-left"
          variant={path === '/commercial' ? 'selected' : 'outline'}
          onClick={() => {
            setPath('/commercial');
          }}
        >
          Commercial
        </Button>
      </div>
      <div>
        <div className="p-4">
          <p>Jobs</p>
        </div>
        <div className="px-4">
          <Button
            className="block w-full justify-center text-left"
            variant={
              path === '/clients/bexley-council/jobs/MTC001852'
                ? 'selected'
                : 'outline'
            }
            onClick={() => {
              setPath('/clients/bexley-council/jobs/MTC001852');
            }}
          >
            MTC001852
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SeriesNavigation;
