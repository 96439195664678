import React from 'react';
import Events from 'external/Events';
import { useJobTaskContext } from '../../context';

const TaskEvents = () => {
  const { task } = useJobTaskContext();
  return (
    <Events
      scope={`jobs::job-task::${task?.id}`}
      excludedNames={['USER_VIEWED_CONTENT']}
    />
  );
};

export default TaskEvents;
