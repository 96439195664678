import React, { useEffect, useState } from 'react';
import { CogIcon, MoonIcon, SunIcon, TrashIcon } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Loader } from '@/components/ui/loading';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { TableCell, TableRow } from '@/components/ui/table';
import { TimePicker } from '@/components/ui/time-picker';
import { cn } from '@/lib/utils';
import { DateTime } from 'luxon';
import { useDebounceCallback, useParentStore } from 'lib/hooks';
import { RosterDuty } from 'lib/types';
import RosterDutySettings from '../RosterDutySettings';
import { RosterDutyTableState } from '../RosterDutyTable/state';
import {
  useCreateRosterDuty,
  useDeleteRosterDuty,
  useUpdateRosterDuty,
} from './hooks';
import { RosterDutyRowProps } from './types';

export const RosterDutyEmptyRow = ({
  index,
  day,
}: Omit<RosterDutyRowProps, 'rosterDuty'>) => {
  const useStore = useParentStore<RosterDutyTableState>();

  const { user } = useStore((state) => ({
    stage: state.stage,
    user: state.user,
  }));

  const { createRosterDuty: createRosterDutyDay, isCreating: isCreatingDay } =
    useCreateRosterDuty(day, 'DAY');

  const {
    createRosterDuty: createRosterDutyNight,
    isCreating: isCreatingNight,
  } = useCreateRosterDuty(day, 'NIGHT');

  if (!user) {
    return <TableRow></TableRow>;
  }

  return (
    <TableRow className="h-[72px] opacity-50 hover:opacity-100">
      <TableCell className="pl-4">
        <Badge
          variant="outline"
          className="h-[36px] w-[100px] justify-center rounded-md text-center"
        >
          Shift {index + 1}
        </Badge>
      </TableCell>
      <TableCell>{day.toFormat('dd/MM')}</TableCell>
      <TableCell>{day.toFormat('EEEE')}</TableCell>
      <TableCell />
      <TableCell className="relative">
        <div className="absolute left-2 top-1/2 flex -translate-y-[50%] gap-x-2">
          <Button
            isLoading={isCreatingDay}
            disabled={isCreatingNight}
            className="flex gap-x-1"
            variant="outline"
            size="sm"
            onClick={createRosterDutyDay}
          >
            <SunIcon className="h-4 w-4" />
            Add day shift
          </Button>
          <Button
            isLoading={isCreatingNight}
            disabled={isCreatingDay}
            className="flex gap-x-1"
            variant="outline"
            size="sm"
            onClick={createRosterDutyNight}
          >
            <MoonIcon className="h-4 w-4" />
            Add night shift
          </Button>
        </div>
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell className="pr-4" />
    </TableRow>
  );
};

const useDebouncedField = <Value = any,>(
  value: Value,
  onChange: (event: any) => void,
) => {
  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);
  const debouncedOnChange = useDebounceCallback(onChange, {
    debounceDuration: 1000,
  });
  return {
    value: internalValue,
    onChange: (value: Value) => {
      setInternalValue(value);
      debouncedOnChange(value);
    },
  };
};

const DebouncedNumberInput = ({
  disabled = false,
  value,
  onChange,
}: {
  disabled?: boolean;
  value: number;
  onChange: (event: any) => void;
}) => {
  const debounced = useDebouncedField(value, onChange);
  return (
    <Input
      {...debounced}
      disabled={disabled}
      type="number"
      onChange={(event) => {
        const { value } = event.target;
        debounced.onChange(parseInt(value, 10));
      }}
    />
  );
};

const DebouncedTimeInput = ({
  index,
  disabled = false,
  value,
  onChange,
}: {
  index: number;
  disabled?: boolean;
  value: DateTime;
  onChange: (event: any) => void;
}) => {
  const debounced = useDebouncedField(value, onChange);
  return (
    <TimePicker
      table
      index={index}
      seconds={false}
      disabled={disabled}
      date={debounced.value.toJSDate()}
      setDate={(date: Date | undefined) => {
        if (!date) return;
        debounced.onChange(DateTime.fromJSDate(date));
      }}
    />
  );
};

const ConfigureRosterDuty = ({ rosterDuty }: { rosterDuty: RosterDuty }) => {
  const [open, setOpen] = useState(false);
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant="outline" size="icon">
          <CogIcon className="h-4 w-4" />
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Delete roster duty</SheetTitle>
          <SheetDescription>
            <p className="mb-4">Are you sure you want to delete this duty?</p>
          </SheetDescription>
        </SheetHeader>
        <SheetFooter>
          <SheetClose asChild>
            <Button variant="outline" size="sm">
              Close
            </Button>
          </SheetClose>
          <Button
            size="sm"
            onClick={async (event) => {
              event.preventDefault();
              setOpen(false);
            }}
          >
            Update
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

const DeleteRosterDuty = ({ rosterDuty }: { rosterDuty: RosterDuty }) => {
  const [open, setOpen] = useState(false);
  const { deleteRosterDuty, isDeleting } = useDeleteRosterDuty(rosterDuty);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" size="icon">
          <TrashIcon className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete roster duty</DialogTitle>
          <DialogDescription>
            <p className="mb-4">Are you sure you want to delete this duty?</p>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" size="sm">
              Close
            </Button>
          </DialogClose>
          <Button
            isLoading={isDeleting}
            size="sm"
            onClick={async (event) => {
              event.preventDefault();
              await deleteRosterDuty();
              setOpen(false);
            }}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const RosterDutyRow = ({ index, rosterDuty }: RosterDutyRowProps) => {
  const useStore = useParentStore<RosterDutyTableState>();

  const { rosterTeam, rosterValidationResults } = useStore((state) => ({
    rosterTeam: state.rosterTeam,
    rosterValidationResults: state.rosterValidationResults,
  }));

  const { updateRosterDuty, isUpdating } = useUpdateRosterDuty(rosterDuty);

  const handleCommuteTimeOnChange = (value: number) => {
    updateRosterDuty('commuteTime', value);
  };

  const handleTimeStartOnChange = (value: DateTime | null) => {
    if (!value) return;
    updateRosterDuty('dateTimeStart', value.toISO() as string);
  };

  const handleTimeEndOnChange = (value: DateTime | null) => {
    if (!value) return;
    updateRosterDuty('dateTimeEnd', value.toISO() as string);
  };

  const handleSettingsOnSubmit = (values: any) => {
    if (!values) return;
    updateRosterDuty('settings', values, false);
  };

  const handleResetSettingsToDefaults = () => {
    if (!rosterTeam?.defaults) return;
    updateRosterDuty('settings', rosterTeam.defaults, false);
  };

  const handleRestoreOnClick = () => {
    updateRosterDuty('isDeleted', false, false);
  };

  const dateTimeStart = DateTime.fromISO(rosterDuty.dateTimeStart);
  const dateTimeEnd = DateTime.fromISO(rosterDuty.dateTimeEnd);

  const fatigueIndex = rosterValidationResults.fatigueIndices[rosterDuty.id];
  const riskIndex = rosterValidationResults.riskIndices[rosterDuty.id];

  if (!rosterTeam) return null;

  return (
    <TableRow className="relative h-[72px]">
      <TableCell
        className={cn('border-l-[2px] border-l-transparent pl-4', {
          'border-l-warning': !rosterDuty.isPristine,
        })}
      >
        {isUpdating && (
          <div className="absolute inset-[1px] z-[10] backdrop-blur-[2px]">
            <div className="flex h-full w-full items-center">
              <Loader wrapperClassname="p-4 rounded-lg">Updating duty</Loader>
            </div>
          </div>
        )}
        <Badge
          className={cn(
            'h-[36px] w-[100px] justify-center rounded-md text-center',
            {
              'bg-warning/25': rosterDuty.status === '1',
              'text-success-foreground bg-primary/25':
                rosterDuty.status === '2',
              'bg-error/25': rosterDuty.status === 'REJECTED',
            },
          )}
          variant="outline"
        >
          Shift {index + 1}
        </Badge>
      </TableCell>
      <TableCell>{dateTimeStart.toFormat('dd/MM')}</TableCell>
      <TableCell>{dateTimeStart.toFormat('EEEE')}</TableCell>
      <TableCell>
        <Badge
          variant="outline"
          className="h-[36px] w-[100px] whitespace-nowrap rounded-md"
        >
          <div className="flex gap-x-1">
            {rosterDuty.shiftType.value === 'DAY' ? (
              <SunIcon className="h-4 w-4" />
            ) : (
              <MoonIcon className="h-4 w-4" />
            )}
            <p>{rosterDuty.shiftType.label}</p>
          </div>
        </Badge>
      </TableCell>
      <TableCell>
        <DebouncedNumberInput
          disabled={rosterDuty.isDeleted}
          value={rosterDuty.commuteTime}
          onChange={handleCommuteTimeOnChange}
        />
      </TableCell>
      <TableCell>
        <DebouncedTimeInput
          index={index}
          disabled={rosterDuty.isDeleted}
          value={dateTimeStart}
          onChange={handleTimeStartOnChange}
        />
      </TableCell>
      <TableCell>
        <DebouncedTimeInput
          index={index}
          disabled={rosterDuty.isDeleted}
          value={dateTimeEnd}
          onChange={handleTimeEndOnChange}
        />
      </TableCell>
      <TableCell>
        <div className="flex items-center gap-x-2">
          <p className="text-sm font-semibold">
            {dateTimeEnd.diff(dateTimeStart).toFormat('hh:mm')}
          </p>
          <p className="text-sm text-gray-600">
            [
            {dateTimeEnd
              .diff(dateTimeStart)
              .plus({ minutes: rosterDuty.commuteTime })
              .toFormat('hh:mm')}
            ]
          </p>
        </div>
      </TableCell>
      {rosterDuty.isDeleted ? (
        <>
          <TableCell colSpan={2}>
            <Badge
              className="h-[32px] w-full justify-center rounded"
              variant="outline"
            >
              {rosterDuty.status === '1'
                ? 'Deletion submitted'
                : 'Marked for deletion'}
            </Badge>
          </TableCell>
          <TableCell className="pr-4">
            <Button
              size="sm"
              variant="outline"
              className="w-full"
              onClick={handleRestoreOnClick}
            >
              Restore
            </Button>
          </TableCell>
        </>
      ) : (
        <>
          <TableCell>
            <Badge
              variant="outline"
              className="h-[36px] w-[100px] justify-center rounded-md text-center"
            >
              {fatigueIndex ? Math.floor(fatigueIndex) : '-'}
            </Badge>
          </TableCell>
          <TableCell>
            <Badge
              variant="outline"
              className="h-[36px] w-[100px] justify-center rounded-md text-center"
            >
              {riskIndex ? Math.floor(10 * riskIndex) / 10 : '-'}
            </Badge>
          </TableCell>
          <TableCell className="pr-4">
            <div className="flex gap-x-2">
              <RosterDutySettings
                team={rosterTeam}
                duty={rosterDuty}
                onReset={handleResetSettingsToDefaults}
                onSubmit={handleSettingsOnSubmit}
              />
              <DeleteRosterDuty rosterDuty={rosterDuty} />
            </div>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default RosterDutyRow;
