import { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { gql } from '@apollo/client';
import { PlatformActionRepresentation } from 'lib/types';
import {
  platformClient,
  platformSubscriptionClient,
} from '../../../../app/platform';
import { Submission } from '../../../../shared/Report/components/ReportBlock/components/BlockForm/types';
import { useTodoContext } from '../../../TodosProvider/hooks';
import { useActionsContext } from '../../context';
import { ActionProps, ActionStatus } from './types';

type Extra = {
  action_form_hook_id: string;
  action_form_submission: Submission;
};

export const useAction = ({
  id,
  small = false,
  preview,
  button,
  context: innerContext,
  disabled: disabledLocal = false,
  onSuccess: customOnSuccess,
  fieldExtensions,
}: ActionProps) => {
  const { selectTodo } = useTodoContext();
  const {
    actionSetId,
    actionSet,
    scope,
    context: outerContext,
    disabled: disabledGlobal,
    onSuccess: defaultOnSuccess,
  } = useActionsContext();

  const context = useMemo(
    () => ({ ...outerContext, ...innerContext }),
    [outerContext, innerContext],
  );

  const disabled = disabledLocal || disabledGlobal;

  const action = useMemo(
    () => actionSet?.actions.find((inner) => inner.id === id),
    [actionSet, id],
  );

  const [open, setOpen] = useState(false);

  const [representation, setRepresentation] = useState<
    PlatformActionRepresentation | undefined
  >(undefined);

  const [extra, setExtra] = useState<Extra>();

  const [nodeStatus, setNodeStatus] = useState<Record<string, any>>({});

  const [status, setStatus] = useState<ActionStatus>(ActionStatus.Idle);

  const performAction = useMutation({
    mutationFn: platformClient.performAction({
      action_set_id: actionSetId,
      scope,
      context,
      camelcase_to_snakecase: true,
    }),
  });

  const handleDryrun = useCallback(
    async (nextExtra?: Extra) => {
      if (!action) {
        console.error('Action not found');
        return;
      }

      setStatus(ActionStatus.DryrunPending);
      setOpen(true);

      const { result } = await performAction.mutateAsync({
        ...(nextExtra ?? {}),
        dryrun: true,
        action_id: action.id,
      });

      setExtra(nextExtra);

      if (result.status === 'SUCCEEDED') {
        setRepresentation(result.representation);
        setStatus(ActionStatus.DryrunSucceeded);
      }

      if (result.status === 'FAILED') {
        setStatus(ActionStatus.DryrunFailed);
      }
    },
    [action, performAction],
  );

  const handleDialogOnOpen = useCallback(async () => {
    setExtra(undefined);
    setNodeStatus({});
    setOpen(true);

    if (!action) {
      console.error('Action not found');
      return;
    }

    if (action.form) {
      setStatus(ActionStatus.Form);
      return;
    }

    await handleDryrun();
  }, [action]);

  const handleDialogOnClose = useCallback(() => {
    if (status === ActionStatus.PerformPending) {
      return;
    }

    setOpen(false);
  }, [status]);

  const handlePerformHookOnClick = async (hook: string, submission: any) => {
    await handleDryrun({
      action_form_hook_id: hook,
      action_form_submission: submission,
    });
  };

  const handlePerformActionOnClick = async () => {
    await handlePerform();
  };

  const handlePerform = async () => {
    if (!action) {
      console.error('Action not found');
      return;
    }

    setStatus(ActionStatus.PerformPending);

    const observable = platformSubscriptionClient.subscribe({
      query: gql(`
        subscription Listen($scope: String!) {
          subscribe(scope: $scope) {
            scope
            data
          }
        }  
      `),
      variables: { scope: `workflows::execution::${scope}` },
    });

    const { unsubscribe } = observable.subscribe(({ data }) => {
      if (!data.subscribe) {
        console.warn('no data in subscribe event');
        return;
      }

      const { data: realtimeData } = JSON.parse(data.subscribe.data);
      const { node } = realtimeData;
      console.debug('data received', realtimeData);

      setNodeStatus((prevNodeStatus) => ({
        ...prevNodeStatus,
        [node]: realtimeData,
      }));
    });

    try {
      const { result } = await performAction.mutateAsync({
        ...(extra ?? {}),
        dryrun: false,
        action_id: action.id,
      });

      if (!result) {
        setStatus(ActionStatus.PerformFailed);
        return;
      }

      if (result.status === 'SUCCEEDED') {
        if (result.extensions.length > 0) {
          const lastTodo = result.extensions[result.extensions.length - 1];
          selectTodo(lastTodo || null);
        }

        const onSuccess = customOnSuccess || defaultOnSuccess;
        if (onSuccess) {
          setStatus(ActionStatus.PerformCleanup);
          await onSuccess();
        }

        setStatus(ActionStatus.PerformSucceeded);
        setTimeout(() => {
          setOpen(false);
        }, 500);
      }

      if (result.status === 'FAILED') {
        setStatus(ActionStatus.PerformFailed);
      }
    } finally {
      try {
        unsubscribe();
      } catch (error) {
        console.warn('Failed to unsubscribe', error);
      }
    }
  };

  return {
    context: {
      action,
      extra,
      small,
      button,
      preview,
      state: {
        status,
        disabled,
        representation,
        nodeStatus,
      },
      dialog: {
        open,
        onOpen: handleDialogOnOpen,
        onClose: handleDialogOnClose,
      },
      form: {
        fieldExtensions,
      },
      handlers: {
        performOnClick: handlePerformActionOnClick,
        performHookOnClick: handlePerformHookOnClick,
      },
    },
  };
};
