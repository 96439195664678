import React from 'react';
import parse, { DOMNode, Element, domToReact } from 'html-react-parser';
import DOMPurify from 'dompurify';
import { DateTime } from 'luxon';
import { SignatureProps } from './types';

const Signature = ({ signature }: SignatureProps) => {
  const dateTime = signature.dateTime
    ? DateTime.fromISO(signature.dateTime)
    : null;

  const replace = (node: DOMNode) => {
    if (!(node instanceof Element)) return;
    if (node.name === 'svg') {
      return (
        <svg
          {...node.attribs}
          width={undefined}
          height={undefined}
          fill={undefined}
          style={{ width: 200, height: 100 }}
        >
          {domToReact(node.children, { replace })}
        </svg>
      );
    }
    if (node.name === 'path') {
      return (
        <path
          {...node.attribs}
          className="fill-accent-foreground stroke-accent-foreground"
        />
      );
    }
    if (node.name === 'rect') {
      return <rect {...node.attribs} className="fill-background" />;
    }
    return node;
  };

  const safe = DOMPurify.sanitize(signature.svg);
  const html = parse(safe, { replace });

  return (
    <div>
      <div className="mb-2 fill-foreground stroke-1">{html}</div>
      <div>
        <p className="mb-0.5 text-sm font-medium">{signature.name}</p>
        <p className="text-xs dark:text-gray-400">
          {dateTime?.toLocaleString(DateTime.DATETIME_FULL)}
        </p>
      </div>
    </div>
  );
};

export default Signature;
