import { gql, useQuery } from '@apollo/client';
import { useStore } from './state';
import { InitialQueryData } from './types';

export const useInitialQuery = () => {
  useQuery<InitialQueryData>(
    gql`
      query {
        user {
          canAccessRosterTeams: testRule(rule: "can_access_roster_teams")
          canAccessRosterReviews: testRule(rule: "can_access_roster_reviews")
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      onCompleted: useStore((state) => state.initialQueryOnCompleted),
    },
  );
};
