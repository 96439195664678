import { useCallback, useState } from 'react';

type Options = {
  debounceDuration?: number;
};

type Callback = (...args: any) => void;

const defaultOptions = {
  debounceDuration: 1000,
};

function useDebounceCallback<T extends Callback = Callback>(
  callback: T,
  options?: {
    debounceDuration?: number;
  },
): T;

function useDebounceCallback<T extends Callback = Callback>(
  callback: T,
  options: Options = defaultOptions,
) {
  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout>();

  return useCallback(
    (...args: any) => {
      return new Promise((resolve) => {
        if (saveTimeout) {
          clearTimeout(saveTimeout);
        }
        setSaveTimeout(
          setTimeout(() => {
            resolve(callback(...args));
          }, options.debounceDuration),
        );
      });
    },
    [callback, options.debounceDuration, saveTimeout],
  );
}

export default useDebounceCallback;
