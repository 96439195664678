import QualificationEditor from '../QualificationEditor';
import { useQualificationCreator } from './hooks';

const QualificationCreator = () => {
  const { handlers } = useQualificationCreator();
  return (
    <QualificationEditor
      onClose={handlers.onClose}
      onSubmit={handlers.onCreate}
    />
  );
};

export default QualificationCreator;
