import ValueBody from './components/ValueBody';
import ValueFooter from './components/ValueFooter';
import ValueHeader from './components/ValueHeader';

const TableValue = () => {
  return (
    <>
      <ValueHeader />
      <ValueBody />
      <ValueFooter />
    </>
  );
};

export default TableValue;
