import React from 'react';
import { X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { PickerCloseProps } from './types';

const PickerClose = ({ onClose }: PickerCloseProps) => {
  return (
    <Button
      className="overflow-hidden border-none p-0 text-start"
      variant="background"
      onClick={onClose}
    >
      <div className="flex h-full items-center gap-x-2 bg-destructive px-2 pl-3">
        <p className="font-bold">Close</p>
        <X className="h-4 w-4" />
      </div>
    </Button>
  );
};

export default PickerClose;
