import { useCallback } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useLocalStorage } from '@uidotdev/usehooks';
import { toast } from 'sonner';
import { CommercialType } from 'lib/types';
import { useClient } from '../../../../../Client/hooks';
import { useJobCommercialContext } from '../../context';
import {
  CommercialActivityQueryQueryData,
  CommercialActivityQueryVariables,
} from './types';

export const useCommercialActivity = () => {
  const client = useClient();
  const { activity } = useJobCommercialContext();

  const [sections, setSections] = useLocalStorage(
    `commercial::commercial-activity::${activity?.id}::sections`,
    [] as CommercialType[],
  );

  const handleAddSection = useCallback(
    (section: CommercialType) => {
      setSections((prev) => [...new Set([...(prev ?? []), section])]);
    },
    [setSections],
  );

  const handleRemoveSection = useCallback(
    (section: CommercialType) => {
      setSections((prev) => prev.filter((inner) => inner !== section));
    },
    [setSections],
  );

  const handleIsSectionOpen = useCallback(
    (section: CommercialType) => {
      return sections.includes(section);
    },
    [sections],
  );

  const [update] = useMutation(
    gql`
      mutation UpdateCommercialEstimate(
        $id: ID!
        $field: String!
        $value: JSONScalarInput!
      ) {
        updateCommercialActivity(
          commercialActivityId: $id
          field: $field
          value: $value
        ) {
          commercialActivity {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnUpdate = useCallback(
    async (field: string, value: string) => {
      if (!activity) return;

      const { data, errors } = await update({
        variables: {
          id: activity.id,
          field,
          value,
        },
      });

      if (data) {
        await activity.refetch();
        toast.success('Estimate updated successfully');
      }

      if (errors) {
        toast.error('Failed to update estimate');
      }
    },
    [activity, update],
  );

  const { data } = useQuery<
    CommercialActivityQueryQueryData,
    CommercialActivityQueryVariables
  >(
    gql`
      query GetCommercialContracts($clientId: ID!) {
        commercialContracts(clientId: $clientId) {
          id
          name
          rates {
            id
            name
            description
            unit
            unitDisplay
            unitValue
          }
        }
        commercialResourceRates {
          id
          unitCost
          dateStart
          dateEnd
          user {
            id
            name
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      variables: { clientId: client.id },
    },
  );

  return {
    context: {
      activity: activity!,
      contracts: data?.commercialContracts || [],
      resources: data?.commercialResourceRates || [],
      sections: {
        value: sections,
        isOpen: handleIsSectionOpen,
        add: handleAddSection,
        remove: handleRemoveSection,
      },
      handlers: {
        onUpdate: handleOnUpdate,
      },
    },
  };
};
