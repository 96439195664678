import { Empty } from 'lib/shared';
import CommercialEstimate from '../CommercialEstimate';
import EstimatesToolbar from './components/EstimatesToolbar';
import { CommercialEstimatesContext } from './context';
import { useCommercialEstimates } from './hooks';

const CommercialEstimates = () => {
  const { context } = useCommercialEstimates();
  return (
    <CommercialEstimatesContext.Provider value={context}>
      <EstimatesToolbar />
      {context.state.selected ? (
        <CommercialEstimate id={context.state.selected} />
      ) : (
        <div className="h-full p-4">
          <Empty>Select an estimate to view details</Empty>
        </div>
      )}
    </CommercialEstimatesContext.Provider>
  );
};

export default CommercialEstimates;
