import { Report } from 'lib/shared';
import { useQuoteRendererContext } from '../../../../context';

const BodyHeader = () => {
  const { theme } = useQuoteRendererContext();
  return (
    <Report.View
      style={{
        display: 'flex',
        flexDirection: 'row',
        borderTopWidth: 1,
        borderTopColor: theme.colors.border,
        paddingTop: 8,
        marginBottom: 8,
      }}
    >
      <Report.View style={{ width: '40%' }}>
        <Report.Text
          style={{
            fontSize: 11,
            fontWeight: 700,
            textTransform: 'uppercase',
            color: theme.colors.subtitle,
          }}
        >
          Item
        </Report.Text>
      </Report.View>
      <Report.View style={{ width: '15%' }}>
        <Report.Text
          style={{
            fontSize: 11,
            fontWeight: 700,
            textTransform: 'uppercase',
            color: theme.colors.subtitle,
          }}
        >
          Unit
        </Report.Text>
      </Report.View>
      <Report.View style={{ width: '15%' }}>
        <Report.Text
          style={{
            fontSize: 11,
            fontWeight: 700,
            textTransform: 'uppercase',
            color: theme.colors.subtitle,
          }}
        >
          Rate
        </Report.Text>
      </Report.View>
      <Report.View style={{ width: '15%' }}>
        <Report.Text
          style={{
            fontSize: 11,
            fontWeight: 700,
            textTransform: 'uppercase',
            color: theme.colors.subtitle,
          }}
        >
          Quantity
        </Report.Text>
      </Report.View>
      <Report.View style={{ width: '15%' }}>
        <Report.Text
          style={{
            fontSize: 11,
            fontWeight: 700,
            textTransform: 'uppercase',
            color: theme.colors.subtitle,
            textAlign: 'right',
          }}
        >
          Amount
        </Report.Text>
      </Report.View>
    </Report.View>
  );
};

export default BodyHeader;
