import React from 'react';
import { Hexagon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { EmptyProps } from './types';

const Empty = ({
  children,
  className,
  classNameInner,
  style,
  horizontal = false,
  asChild = false,
}: EmptyProps) => {
  if (horizontal) {
    return (
      <div className={cn('h-full w-full', className)} style={style}>
        <div
          className={cn(
            'flex h-full w-full items-center justify-center gap-x-2 rounded-md border border-dashed px-2',
            classNameInner,
          )}
        >
          <Hexagon className="dark:text-white-400 h-4 w-4 text-gray-400" />
          {asChild ? (
            children
          ) : (
            <p className="dark:text-white-400 text-xs text-gray-400">
              {children}
            </p>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className={cn('h-full w-full', className)} style={style}>
      <div
        className={cn(
          'flex h-full w-full items-center justify-center rounded-md border border-dashed',
          classNameInner,
        )}
      >
        <div>
          <Hexagon className="dark:text-white-400 mx-auto mb-2 h-4 w-4 text-gray-400" />
          {asChild ? (
            children
          ) : (
            <p className="dark:text-white-400 text-xs text-gray-400">
              {children}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Empty;
