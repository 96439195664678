import { useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { DateTime, Interval } from 'luxon';
import { User } from 'lib/types';
import { useClient } from '../../hooks';
import { ClientActivityContext } from './context';

export const useClientActivityContext = () => {
  return useContext(ClientActivityContext);
};

export const useClientActivity = () => {
  const client = useClient();

  const { data } = useQuery(
    gql`
      query GetClientActivity {
        timesheetDuties {
          id
          dateTimeStart
          dateTimeEnd
          events {
            id
            dateTimeStart
            dateTimeStartType
            dateTimeEnd
            dateTimeEndType
          }
          user {
            id
          }
          activity {
            id
            jobTask {
              id
              name
              job {
                id
                client {
                  id
                  name
                }
                reference
              }
              number
            }
          }
        }
        users {
          id
          initials
          name
          email
        }
        subcontractors {
          id
          name
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      skip: !client?.id,
      variables: { clientId: client?.id as string },
    },
  );

  const [focused, setFocused] = useState<User | null>(null);

  const handleOnFocus = (user: User | null) => {
    setFocused(user);
  };

  const handleOnBlur = () => {
    setFocused(null);
  };

  const [interval, setInterval] = useState(
    Interval.fromDateTimes(
      DateTime.local().startOf('day'),
      DateTime.local().startOf('day').plus({ days: 1 }),
    ),
  );

  const handleTimeIntervalOnChange = (nextInterval: Interval) => {
    setInterval(nextInterval);
  };

  return {
    timesheetDuties: data?.timesheetDuties ?? [],
    users: data?.users ?? [],
    subcontractors: data?.subcontractors ?? [],
    interval,
    focused,
    handleOnFocus,
    handleOnBlur,
    handleTimeIntervalOnChange,
  };
};
