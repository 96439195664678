import React from 'react';
import { useNavigate } from 'react-router';
import { Badge } from '@/components/ui/badge';
import { CommandItem } from '@/components/ui/command';
import { Loader } from '@/components/ui/loading';
import { gql, useQuery } from '@apollo/client';
import chroma from 'chroma-js';
import DOMPurify from 'dompurify';
import { useTodos } from '../../../../../../external/Todos/hooks';
import { ResultJobProps } from './types';

const ResultJob = ({ result, onClose }: ResultJobProps) => {
  const navigate = useNavigate();
  const { data } = useQuery(
    gql`
      query GetJob($id: ID!) {
        job(jobId: $id) {
          client {
            id
            slug
          }
          id
          tags {
            label
            color
          }
          reference
          description
          scope
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      variables: { id: result.id },
    },
  );
  const todos = useTodos({
    scope: `jobs::job::${result.id}`,
  });
  return data ? (
    <CommandItem className="cursor-pointer" value={result.id}>
      <button
        className="block text-start"
        onClick={(event) => {
          event.preventDefault();
          navigate(
            `/clients/${data.job.client.slug}/jobs/${data.job.reference}`,
          );
          onClose();
        }}
      >
        <div className="mb-1 flex items-center gap-x-2">
          <h1 className="text-lg font-semibold">{data.job.reference}</h1>
          <div className="flex grow items-center gap-x-2">
            {data.job.tags.map((tag: any) => (
              <Badge
                key={`tag-${tag.label}`}
                className="h-5"
                variant="outline"
                style={{
                  textTransform: 'capitalize',
                  backgroundColor: chroma(tag.color).alpha(0.2).css(),
                  borderColor: tag.color,
                  color: tag.color,
                }}
              >
                {tag.label}
              </Badge>
            ))}
          </div>
          {todos.openTodos.length > 0 && <div></div>}
        </div>
        <p className="dark:text-white-400 text-xs text-gray-400">
          {data.job.description}
        </p>
        {result.highlight?.description && (
          <div className="mt-2 flex gap-x-2">
            <p className="dark:text-white-400 text-xs font-semibold text-gray-400">
              Matched:{' '}
            </p>
            <div className="flex flex-col gap-y-1">
              {result.highlight?.description?.map((highlight: string) => {
                const __html = DOMPurify.sanitize(highlight);
                return (
                  <p
                    key={`highlight-${highlight}`}
                    className="dark:text-white-400 text-xs text-gray-400 [&_em]:font-bold"
                    dangerouslySetInnerHTML={{ __html }}
                  />
                );
              })}
            </div>
          </div>
        )}
      </button>
    </CommandItem>
  ) : (
    <CommandItem value={result.id}>
      <Loader wrapperClassname="bg-transparent">Loading job info</Loader>
    </CommandItem>
  );
};

export default ResultJob;
