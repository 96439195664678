import React from 'react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

const ButtonTabs = <Value,>({
  className,
  buttonClassName,
  tabs = [],
  value,
  onValueChange,
}: {
  className?: string;
  buttonClassName?: string;
  tabs: {
    label: string;
    value: Value;
    disabled?: boolean;
  }[];
  value: Value;
  onValueChange: (value: Value) => void;
}) => {
  return (
    <div
      className={cn(
        'flex h-7 divide-x overflow-hidden rounded-md border',
        className,
      )}
    >
      {tabs.map((tab) => (
        <Button
          key={String(tab.value)}
          className={cn('relative h-7 rounded-none border-0', buttonClassName)}
          size="sm"
          disabled={tab.disabled}
          variant={value === tab.value ? 'selected' : 'ghost'}
          onClick={() => onValueChange(tab.value)}
        >
          <span className="font-bold opacity-0">{tab.label}</span>
          <span className="absolute inset-x-0">{tab.label}</span>
        </Button>
      ))}
    </div>
  );
};

export default ButtonTabs;
