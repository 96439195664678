import { gql, useMutation } from '@apollo/client';
import { useCommercialActivityContext } from '../../../../../../context';
import { useActivityTableContext } from '../../../../context';

export const useCostCreate = () => {
  const { activity, resources, store } = useCommercialActivityContext();
  const { type, updateRefetch } = useActivityTableContext();

  const [create] = useMutation(
    gql`
      mutation CreateCommercialValueLine(
        $activityId: ID!
        $type: String!
        $rate: ID!
      ) {
        createCommercialCostLine(
          activityId: $activityId
          type: $type
          rate: $rate
        ) {
          commercialCostLine {
            id
            rate {
              id
            }
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (rate: string) => {
    const { data, errors } = await create({
      variables: {
        activityId: activity.id,
        type,
        rate,
      },
    });

    if (data) {
      const { id } = data.createCommercialCostLine.commercialCostLine;
      store.order.push(id);
      await updateRefetch();
    }

    if (errors) {
    }
  };

  return {
    resources,
    onCreate: handleOnCreate,
  };
};
