import { createContext, useContext } from 'react';
import { useJobTaskForm } from './hooks';

export const JobTaskFormContext = createContext(
  {} as ReturnType<typeof useJobTaskForm>['context'],
);

export const useJobTaskFormContext = () => {
  return useContext(JobTaskFormContext);
};
