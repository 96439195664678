import React from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { AsyncContext } from 'lib/shared';
import { useCommercialActivityContext } from '../../../../context';
import { useActivityTableContext } from '../../../ActivityTable/context';
import { usePrelimsConvertToContract } from './hooks';

const PrelimsConvertToContract = () => {
  const { activity } = useCommercialActivityContext();
  const { valueLines, costLines } = useActivityTableContext();
  const { dialog, handlers } = usePrelimsConvertToContract();

  if (valueLines.length + costLines.length > 0) {
    return null;
  }

  return (
    <Dialog open={dialog.open} onOpenChange={dialog.onOpenChange}>
      <DialogTrigger asChild>
        <Button className="h-8 p-0 text-xs" variant="link">
          Convert to contract prelim
        </Button>
      </DialogTrigger>
      <DialogContent overlay>
        <DialogHeader>
          <DialogTitle>Convert to contract prelim</DialogTitle>
          <DialogDescription>
            This will convert the preliminary activity to a contract prelim.
          </DialogDescription>
        </DialogHeader>
        {activity.contract.activePrelim?.factor && (
          <div className="my-4 rounded-md border bg-background-secondary p-4">
            <p className="text-sm">
              The contract prelim factor is currently set to{' '}
              <span className="font-bold">
                {((activity.contract.activePrelim.factor - 1) * 100).toFixed(2)}
                %
              </span>
            </p>
          </div>
        )}
        <AsyncContext handle={handlers.onConvert}>
          {({ loading, handle }) => (
            <div className="flex justify-between">
              {loading ? (
                <div />
              ) : (
                <DialogClose asChild>
                  <Button size="sm" variant="outline">
                    Cancel
                  </Button>
                </DialogClose>
              )}
              <Button size="sm" variant="default" onClick={handle}>
                Convert to contract prelim
              </Button>
            </div>
          )}
        </AsyncContext>
      </DialogContent>
    </Dialog>
  );
};

export default PrelimsConvertToContract;
