import { createContext, useContext } from 'react';
import { useUserGroup } from './hooks';

export const UserGroupContext = createContext(
  {} as ReturnType<typeof useUserGroup>['context'],
);

export const useUserGroupContext = () => {
  return useContext(UserGroupContext);
};
