import { createContext, useContext } from 'react';
import { useCompanyMemberships } from './hooks';

export const CompanyMembershipsContext = createContext(
  {} as ReturnType<typeof useCompanyMemberships>['context'],
);

export const useCompanyMembershipsContext = () => {
  return useContext(CompanyMembershipsContext);
};
