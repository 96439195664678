import { evaluateProperty } from 'lib/utils';
import { usePages } from '../../../../../FormRenderer/parse';
import { Submission, Template } from './types';

export const useBlockForm = (data: any, context: any) => {
  const templateString = evaluateProperty('template', data, context).data.value;
  const template: Template = templateString
    ? JSON.parse(templateString)
    : { pageOrder: [], items: {}, choiceSets: {}, fieldLabels: {} };

  const submissionString = evaluateProperty('submission', data, context);
  const submission: Submission = submissionString
    ? JSON.parse(submissionString)
    : { values: {}, objects: {} };

  const { pages } = usePages(
    template,
    submission.values,
    [],
    (item, collapsed) => {
      return !item.data.isPrivate && !collapsed.includes(item.id);
    },
  );

  const paths = pages.flatMap((page) =>
    page.items.map((inner: any) => inner.id),
  );

  const shouldRender = (path: string) => {
    return paths.includes(path);
  };

  return {
    template,
    submission,
    pages,
    paths,
    shouldRender,
  };
};
