import React from 'react';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { DataType } from 'external/FormTemplateEditor';
import { FormFieldComponentProps } from '../../types';

const FormFieldBoolean = ({
  readOnly,
  item,
  value,
  onChange,
}: FormFieldComponentProps) => {
  const getValue = () => {
    if (!value) return '';
    if (value?.value) return 'yes';
    if (!value?.value) return 'no';
  };

  if (readOnly) {
    return (
      <div>
        <p className="text-semibold">{value?.value ? 'Yes' : 'No'}</p>
      </div>
    );
  }

  return (
    <RadioGroup
      className="p-2"
      value={getValue()}
      onValueChange={(nextValue) => {
        onChange(item, {
          dataType: DataType.BOOLEAN,
          value: nextValue === 'yes',
        });
      }}
    >
      <div className="flex items-center space-x-2">
        <RadioGroupItem value="yes" id={`${item.id}-option-yes`} />
        <Label htmlFor={`${item.id}-option-yes`}>Yes</Label>
      </div>
      <div className="flex items-center space-x-2">
        <RadioGroupItem value="no" id={`${item.id}-option-no`} />
        <Label htmlFor={`${item.id}-option-no`}>No</Label>
      </div>
    </RadioGroup>
  );
};

export default FormFieldBoolean;
