import React from 'react';
import { PendingDurationDiffProps, PendingDurationProps } from './types';

const PendingDuration = ({ className, title, value }: PendingDurationProps) => {
  return (
    <div className={className}>
      <div className="mb-1">
        <p className="text-xs font-semibold uppercase tracking-wide text-gray-400 dark:text-gray-300">
          {title}
        </p>
      </div>
      <div className="flex items-center gap-x-2">
        <p className="font-mono font-medium">{value.toFormat('hh:mm:ss')}</p>
      </div>
    </div>
  );
};

const PendingDurationDiff = ({
  className,
  duty,
  diff,
}: PendingDurationDiffProps) => {
  const isSame = duty.equals(diff);
  return (
    <div className={className}>
      <div className="mb-1 flex items-center justify-between">
        <p className="text-xs font-semibold uppercase tracking-wide text-gray-400 dark:text-gray-300">
          Duration
        </p>
        {isSame ? (
          <p className="text-sm dark:text-gray-400">(unchanged)</p>
        ) : (
          <p className="text-sm dark:text-gray-400">
            (
            {diff > duty ? (
              <>{diff.minus(duty).rescale().toHuman()} longer</>
            ) : (
              <>{duty.minus(diff).rescale().toHuman()} shorter</>
            )}
            )
          </p>
        )}
      </div>
      <div className="flex justify-between">
        {isSame ? (
          '-'
        ) : (
          <div className="flex items-center gap-x-2 line-through dark:text-gray-400">
            <p className="font-mono font-medium">{duty.toFormat('hh:mm:ss')}</p>
          </div>
        )}
        <div className="flex items-center gap-x-2">
          <p className="font-mono font-medium">{diff.toFormat('hh:mm:ss')}</p>
        </div>
      </div>
    </div>
  );
};

PendingDuration.Diff = PendingDurationDiff;

export default PendingDuration;
