import React from 'react';
import { Loader2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { TableRenderer } from '@/components/ui/table/table/Table';
import UserGroups from '@/components/ui/user-groups';
import { DateTime } from 'luxon';
import { RosterTeam } from 'lib/types';
import { useStore } from '../HumanResourcesRosterTeams/state';
import RosterTeamSettings, {
  attentionMapping,
  workloadMapping,
} from './components/RosterTeamSettings';
import { useRosterTeam } from './hooks';

const HumanResourcesRosterTeam = () => {
  const { rosterTeam } = useStore((state) => ({
    rosterTeam: state.rosterTeam,
    setRosterTeamUsers: state.setRosterTeamUsers,
  }));

  const { isLoading, isUpdating, onSubmit } = useRosterTeam();

  if (!rosterTeam) return null;
  if (isLoading) {
    return (
      <div className="p-4">
        <div className="flex items-center text-gray-600">
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          <p className="text-sm">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col">
      <div className="flex h-[52px] flex-shrink-0 items-center justify-between border-b px-4 py-2">
        <h1 className="text-xl font-bold">{rosterTeam.name}</h1>
        <DeleteTeam />
      </div>
      <ScrollArea className="min-w-0">
        <div className="min-w-0 p-4">
          <UserGroups ids={rosterTeam.userGroupIds} />
          <Settings
            rosterTeam={rosterTeam}
            isUpdating={isUpdating}
            onSubmit={onSubmit}
          />
        </div>
      </ScrollArea>
    </div>
  );
};

const Settings = ({
  rosterTeam,
  isUpdating,
  onSubmit,
}: {
  rosterTeam: RosterTeam;
  isUpdating: boolean;
  onSubmit: (settings: RosterTeam) => void;
}) => {
  return (
    <div>
      <div className="flex items-center justify-between">
        <div>
          <p className="mb-1 text-sm font-bold">Default settings</p>
          <p className="text-xs text-gray-400">
            Configure the default settings for this team
          </p>
        </div>
      </div>
      <TableRenderer
        filter="name"
        filterPlaceholder="Filter settings..."
        columns={[
          {
            accessorKey: 'name',
            header: 'Name',
          },
          {
            accessorKey: 'value',
            header: 'Value',
          },
        ]}
        data={[
          {
            name: 'Workload',
            value:
              workloadMapping[rosterTeam.defaults.jobWorkload]?.label ??
              'Not set',
          },
          {
            name: 'Attention',
            value:
              attentionMapping[rosterTeam.defaults.jobAttention]?.label ??
              'Not set',
          },
          {
            name: 'Break frequency',
            value: rosterTeam.defaults.breakFrequency
              ? `Every ${rosterTeam.defaults.breakFrequency} minutes`
              : 'Not set',
          },
          {
            name: 'Longest continuous work before break',
            value: rosterTeam.defaults.longestContinuousWorkBeforeBreak
              ? `${rosterTeam.defaults.longestContinuousWorkBeforeBreak} minutes`
              : 'Not set',
          },
          {
            name: 'Average break length',
            value: rosterTeam.defaults.breakAverageLength
              ? `${rosterTeam.defaults.breakAverageLength} minutes`
              : 'Not set',
          },
          {
            name: 'Typical break length after continuous work',
            value: rosterTeam.defaults.breakLengthAfterContinuousWork
              ? `${rosterTeam.defaults.breakLengthAfterContinuousWork} minutes`
              : 'Not set',
          },
          {
            name: 'Normal day start time',
            value: rosterTeam.defaults.timeStartDay
              ? DateTime.fromISO(
                  rosterTeam.defaults.timeStartDay,
                ).toLocaleString(DateTime.TIME_SIMPLE)
              : 'Not set',
          },
          {
            name: 'Normal night start time',
            value: rosterTeam.defaults.timeStartNight
              ? DateTime.fromISO(
                  rosterTeam.defaults.timeStartNight,
                ).toLocaleString(DateTime.TIME_SIMPLE)
              : 'Not set',
          },
          {
            name: 'Normal shift duration',
            value: rosterTeam.defaults.shiftDuration
              ? `${rosterTeam.defaults.shiftDuration} minutes`
              : 'Not set',
          },
          {
            name: 'Typical commute time',
            value: rosterTeam.defaults.commuteTime
              ? `${rosterTeam.defaults.commuteTime} minutes`
              : 'Not set',
          },
        ]}
        topRight={({ columnsSelector }) => (
          <div className="ml-auto space-x-2">
            <RosterTeamSettings
              isLoading={isUpdating}
              rosterTeam={rosterTeam}
              onSubmit={onSubmit}
            />
            {columnsSelector}
          </div>
        )}
      />
    </div>
  );
};

const DeleteTeam = () => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="sm" variant="outline">
          Delete team
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete team</DialogTitle>
          <DialogDescription>
            <p className="mb-4">
              Currently, roster teams cannot be deleted via mchugh.day as it is
              not yet clear how team deletion should handled if pending roster
              duties exist for the team.
            </p>
            <p>If you have any suggestions please contact us.</p>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" size="sm" className="w-full">
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default HumanResourcesRosterTeam;
