import { useMatch as useRouterMatch } from 'react-router';
import { useWindowMatch } from '../shared/WindowManager/components/WindowManagerRouter/hooks';
import { useWindowManager } from '../shared/WindowManager/hooks';

const useMatch: typeof useRouterMatch = (pattern) => {
  const { windowed } = useWindowManager();

  const routerMatch = useRouterMatch(pattern);
  const windowMatch = useWindowMatch();

  return windowed ? windowMatch : routerMatch;
};

export default useMatch;
