import React, { ReactNode } from 'react';
import { ReportImage, ReportText, ReportView } from 'lib/shared';
import { PlatformObject } from 'lib/types';
import {
  useObjectSource,
  useRegisterObjectsOnMount,
} from '../../../../../../../MediaProvider';
import { useReportContext } from '../../../../../../context';
import ReportDocument from '../../../../../ReportDocument';
import { useReportBlockFormContext } from '../../context';
import { FormBlockProps } from '../FormBlock/types';

const FormFieldMedia = ({
  item,
  path,
  size = 80,
  placeholder = null,
  borderTop = true,
}: FormBlockProps & {
  size?: number;
  placeholder?: ReactNode | null;
  borderTop?: boolean;
}) => {
  const { theme } = useReportContext();
  const { submission } = useReportBlockFormContext();
  const objects = submission.objects[path];

  useRegisterObjectsOnMount(objects);

  if (!objects || objects.length === 0) return placeholder;

  return (
    <ReportView
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        rowGap: 8,
        marginBottom: 4,
        paddingTop: 12,
        paddingLeft: 12,
        paddingRight: 12,
        ...(borderTop
          ? {
              borderTopWidth: 1,
              borderStyle: 'solid',
              borderColor: theme.colors.border,
            }
          : {}),
      }}
    >
      {objects.map((object, index) => {
        const last = index === objects.length - 1;
        return (
          <FormFieldObject
            key={object.id}
            label={item.data.value}
            object={object}
            last={last}
            size={size}
          />
        );
      })}
    </ReportView>
  );
};

const FormFieldObject = ({
  object,
  size,
  label,
  last = false,
}: {
  object: Omit<PlatformObject, 'sources'>;
  size: number;
  last: boolean;
  label: string;
}) => {
  if (object.type === 'IMAGE') {
    return (
      <FormFieldImage object={object} size={size} label={label} last={last} />
    );
  }

  if (object.type === 'DOCUMENT') {
    return (
      <FormFieldDocument
        object={object}
        size={size}
        label={label}
        last={last}
      />
    );
  }

  return null;
};

const FormFieldImage = ({
  object,
  size,
  label,
  last = false,
}: {
  object: Omit<PlatformObject, 'sources'>;
  size: number;
  last: boolean;
  label: string;
}) => {
  const { images } = useReportContext();
  const sources = useObjectSource(object);
  return (
    <ReportView key={object.id} style={{ marginRight: last ? 0 : 16 }}>
      {sources ? (
        <ReportImage
          object={object}
          alt={`Uploaded image for field ${label}`}
          src={sources.full.uri}
          srcCompressed={sources.compressed.uri}
          style={{
            width: size,
            height: size,
            borderRadius: 4,
            objectFit: 'cover',
          }}
        />
      ) : (
        <ReportText>Loading...</ReportText>
      )}
      <ReportText style={{ fontSize: 10, opacity: 0.5, marginTop: 8 }}>
        Photo {images.findIndex((inner) => inner.id === object.id) + 1}
      </ReportText>
    </ReportView>
  );
};

const FormFieldDocument = ({
  object,
  size,
  last = false,
}: {
  object: Omit<PlatformObject, 'sources'>;
  size: number;
  last: boolean;
  label: string;
}) => {
  const { documents } = useReportContext();
  const sources = useObjectSource(object);
  return (
    <ReportView key={object.id} style={{ marginRight: last ? 0 : 16 }}>
      {sources ? (
        <ReportDocument
          object={object}
          src={sources.full.uri}
          srcThumbnail={sources.thumbnail.uri}
          style={{
            width: size,
            height: size,
            borderRadius: 4,
            objectFit: 'cover',
          }}
        />
      ) : (
        <ReportText>Loading...</ReportText>
      )}
      <ReportText style={{ fontSize: 10, opacity: 0.5, marginTop: 4 }}>
        Document {documents.findIndex((inner) => inner.id === object.id) + 1}
      </ReportText>
    </ReportView>
  );
};

export default FormFieldMedia;
