import React, { Fragment, useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import chroma from 'chroma-js';

export const ColorPicker = ({
  small = false,
  value,
  onValueChange,
}: {
  small?: boolean;
  value: string;
  onValueChange: (value: string) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [inner, setInner] = useState<string>();

  useEffect(() => {
    if (open) {
      setInner(value);
    }
  }, [open]);

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        {small ? (
          <Button
            size="icon"
            variant="outline"
            style={{ backgroundColor: value }}
          />
        ) : (
          <Button
            variant="outline"
            className="w-full justify-between px-2 text-left"
          >
            {value ? (
              <div
                className="w-32 rounded px-2 text-center text-xs"
                style={{ backgroundColor: value }}
              >
                {value}
              </div>
            ) : (
              <div className="text-xs">No color selected</div>
            )}
            <p className="text-xs text-gray-400">Click to edit</p>
          </Button>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>
          <Tabs defaultValue="select" className="w-[400px]">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="select">Select</TabsTrigger>
              <TabsTrigger value="manual">Manual</TabsTrigger>
            </TabsList>
            <TabsContent value="select">
              {['Reds', 'Oranges', 'Greens', 'Blues'].map(
                (scale, index, colors) => (
                  <Fragment key={scale}>
                    <DropdownMenuGroup>
                      <div className="flex justify-around gap-x-1 py-2">
                        {chroma
                          .scale(scale)
                          .classes(6)
                          .colors(6)
                          .map((color) => (
                            <Button
                              key={color}
                              size="icon"
                              variant={value === color ? 'outline' : 'ghost'}
                              onClick={() => {
                                onValueChange(color);
                                setOpen(false);
                              }}
                            >
                              <div
                                className="h-5 w-5 rounded-full"
                                style={{ backgroundColor: color }}
                              />
                            </Button>
                          ))}
                      </div>
                    </DropdownMenuGroup>
                    {index < colors.length - 1 && <DropdownMenuSeparator />}
                  </Fragment>
                ),
              )}
            </TabsContent>
            <TabsContent value="manual" className="mt-4">
              <div className="flex h-full gap-x-2">
                <div
                  className="h-9 w-16 rounded text-center text-xs"
                  style={{ backgroundColor: inner }}
                />
                <Input
                  value={inner}
                  style={{ border: '1px solid', borderColor: inner }}
                  onChange={(event) => {
                    setInner(event.target.value);
                  }}
                />
              </div>
              <Button
                size="sm"
                variant="outline"
                className="mt-4 w-full"
                onClick={() => {
                  if (inner && chroma.valid(inner)) {
                    onValueChange(inner);
                    setOpen(false);
                  }
                }}
              >
                Save
              </Button>
            </TabsContent>
          </Tabs>
        </DropdownMenuLabel>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
