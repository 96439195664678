import { useContext } from 'react';
import { useMatch } from 'react-router';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useLocalStorage } from '@uidotdev/usehooks';
import { matchSorter } from 'match-sorter';
import { Asset, Client, Job, Site, UserGroup } from 'lib/types';
import { ClientContext } from './context';
import { ClientQueryData, ClientQueryVariables } from './types';
import { hasTag } from './utils';

export const useSetupClient = () => {
  const match = useMatch('/clients/:clientSlug/*');
  const clientSlug = match?.params?.clientSlug as string;
  const { data, refetch } = useQuery<ClientQueryData, ClientQueryVariables>(
    gql`
      query GetClient($clientSlug: String!) {
        client(clientSlug: $clientSlug) {
          id
          name
          description
          slug
          userGroupIds
          logo {
            id
          }
          accentColor
          accentColorDark
        }
      }
    `,
    {
      skip: !clientSlug,
      fetchPolicy: 'no-cache',
      variables: { clientSlug },
    },
  );

  const [update] = useMutation(gql`
    mutation UpdateClient(
      $clientId: ID!
      $field: String!
      $value: JSONScalarInput
    ) {
      updateClient(clientId: $clientId, field: $field, value: $value) {
        client {
          id
        }
      }
    }
  `);

  const handleUpdate = async (field: string, value: any) => {
    if (!data?.client) return;
    await update({
      variables: {
        clientId: data.client.id,
        field,
        value,
      },
    });
    await refetch();
  };

  const client = data?.client;

  const userGroups = useClientUserGroups(data?.client);
  const jobs = useClientJobs(data?.client, undefined);

  return client
    ? {
        ...client,
        refetch,
        update: handleUpdate,
        userGroups,
        jobs,
        scope: `clients::client::${client.slug}`,
        paths: {
          root: `/clients/${client.slug}`,
          activity: `/clients/${client.slug}/activity`,
          todos: `/clients/${client.slug}/todos`,
          maintenance: {
            root: `/clients/${client.slug}/maintenance`,
            series: (series: any) =>
              `/clients/${client.slug}/maintenance/series/${series.reference}`,
          },
          jobs: {
            root: `/clients/${client.slug}/jobs`,
            job: (job: Job) => {
              const root = `/clients/${client.slug}/jobs/${job.reference}`;
              return {
                root,
                location: `${root}/location`,
                settings: {
                  general: `${root}/settings`,
                  danger: `${root}/settings/danger`,
                },
                commercial: {
                  root: `${root}/commercial`,
                  estimates: `${root}/commercial/estimates`,
                },
              };
            },
          },
          sites: {
            root: `/clients/${client.slug}/sites`,
            site: (site: Site) =>
              `/clients/${client.slug}/sites/${site.reference}`,
          },
          assets: {
            root: `/clients/${client.slug}/assets`,
            asset: (asset: Asset) =>
              `/clients/${client.slug}/assets/${asset.reference}`,
          },
          commercial: {
            root: `/clients/${client.slug}/commercial`,
            contract: (
              contractId: string,
              dateStart: string,
              dateEnd: string,
            ) =>
              `/clients/${client.slug}/commercial/contracts/${contractId}/${dateStart}/${dateEnd}`,
          },
          projects: {
            root: `/clients/${client.slug}/projects`,
          },
          settings: {
            root: `/clients/${client.slug}/settings`,
            forms: `/clients/${client.slug}/settings/forms`,
            delivery: `/clients/${client.slug}/settings/delivery`,
            supplyChain: `/clients/${client.slug}/settings/supply-chain`,
          },
        },
      }
    : null;
};

export const useClientUserGroups = (client: Client | undefined) => {
  const [selected, setSelected] = useLocalStorage<string[]>(
    'activityScheduleUserGroupSelector',
    [],
  );

  const { data } = useQuery<{ userGroups: UserGroup[] }>(
    gql`
      query GetUserGroups($scope: String!) {
        userGroups(scope: $scope) {
          id
          reference
          name
          users {
            id
            name
            email
            initials
            driverKeyFobId
            externalId
          }
        }
      }
    `,
    {
      skip: !client,
      fetchPolicy: 'no-cache',
      variables: { scope: `clients::client::${client?.slug}` },
    },
  );

  return {
    selected,
    setSelected,
    results: data?.userGroups ?? [],
  };
};

export const useClientJobs = (
  client: Client | undefined,
  filter: string | undefined,
) => {
  const { data, loading, refetch } = useQuery<{
    incoming: Job[];
    rejected: Job[];
    progress: Job[];
    delivered: Job[];
    approved: Job[];
  }>(
    gql`
      fragment NavJobFragment on Job {
        id
        reference
        description
        category {
          id
          name
          code
        }
        priority {
          id
          reference
          responseNameShort
        }
        timeline {
          id
          dateTimeStart
          targets {
            id
            type
            dateTime
          }
        }
        dateTimeCreated
        tags {
          id
          label
          color
        }
        variations {
          id
          reference
        }
        site {
          id
          location {
            id
            precise {
              id
              geometry {
                id
                point {
                  latitude
                  longitude
                }
              }
            }
          }
        }
      }
      query GetJobs($clientId: ID!) {
        incoming: incomingJobs(clientId: $clientId) {
          ...NavJobFragment
        }
        rejected: rejectedJobs(clientId: $clientId) {
          ...NavJobFragment
        }
        progress: inProgressJobs(clientId: $clientId) {
          ...NavJobFragment
          tasks {
            id
            name
            description
            status
            tags {
              id
              label
              color
            }
            aims {
              id
              condition
              target {
                id
              }
            }
          }
        }
        delivered: deliveredJobs(clientId: $clientId) {
          ...NavJobFragment
        }
        approved: approvedJobs(clientId: $clientId) {
          ...NavJobFragment
        }
      }
    `,
    {
      skip: !client,
      fetchPolicy: 'cache-and-network',
      variables: { clientId: client?.id as string },
    },
  );

  const {
    incoming = [],
    rejected = [],
    progress = [],
    delivered = [],
    approved = [],
  } = data ?? {};

  const filterJobs = (jobs: Job[], by: string | undefined) => {
    if (!by) return jobs;
    return matchSorter(jobs, by, {
      keys: ['reference', 'description'],
    });
  };

  const requiresAction = progress.filter(
    (job) => hasTag(job, 'open-issue') || hasTag(job, 'requires-assignment'),
  );

  const requiresReview = progress
    .filter((job) => !requiresAction.find(({ id }) => id === job.id))
    .filter((job) => hasTag(job, 'open-report'));

  const active = progress.filter(
    (job) =>
      ![...requiresAction, ...requiresReview].find(({ id }) => id === job.id),
  );

  return {
    refetch,
    loading,
    all: [
      ...filterJobs(incoming, filter),
      ...filterJobs(rejected, filter),
      ...filterJobs(progress, filter),
      ...filterJobs(delivered, filter),
      ...filterJobs(approved, filter),
    ],
    incoming: filterJobs(incoming, filter),
    rejected: filterJobs(rejected, filter),
    progress: filterJobs(progress, filter),
    delivered: filterJobs(delivered, filter),
    approved: filterJobs(approved, filter),
    requiresAction: filterJobs(requiresAction, filter),
    requiresReview: filterJobs(requiresReview, filter),
    active: filterJobs(active, filter),
  };
};

export const useClient = () => {
  return useContext(ClientContext);
};
