import React, { useState } from 'react';
import { Circle, ExternalLink } from 'lucide-react';
import Map, { Marker } from 'react-map-gl';
import { Button } from '@/components/ui/button';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { useTheme } from '@/components/ui/theme';
import { z } from 'zod';
import { Empty, Link, PageLoader, UnderConstruction } from 'lib/shared';
import { useClient } from '../../../Client/hooks';
import { useJobContext } from '../../context';
import { useJob } from '../../hooks';
import { addCircleOnLoad } from '../JobOverview/components/OverviewMap';
import LocationSheet from './components/LocationSheet';

const JobLocation = () => {
  const client = useClient();

  const { job } = useJobContext();

  if (job.partial) return null;

  return (
    <div className="grid h-full w-full grid-cols-12">
      <div className="col-span-4">
        <div className="border-b p-8">
          <div className="mb-4 flex">
            <div className="grow">
              <p className="mb-1 text-sm font-bold">Site information</p>
              <p className="text-xs text-gray-400">
                Manage your contracts here. You can create, edit, and delete
                contracts.
              </p>
            </div>
            <Link to={client.paths.sites.site(job.site)}>
              <Button className="h-6 p-0" size="xs" variant="link">
                <ExternalLink className="mr-1 size-4" />
                View
              </Button>
            </Link>
          </div>
          <FormRenderer
            fields={[
              {
                name: 'reference',
                label: 'Reference',
                type: 'input',
                schema: z.string(),
                defaultValue: job.site.reference,
                description: 'Client provided site reference (or site code)',
              },
              {
                name: 'name',
                label: 'Name',
                type: 'input',
                schema: z.string(),
                defaultValue: job.site.name,
                description: 'Optionally set, human-readable site name',
              },
            ]}
          >
            {() => null}
          </FormRenderer>
        </div>
        <div className="border-b p-4">
          <div className="flex p-4">
            <div className="grow">
              <p className="mb-1 text-sm font-bold">Job location</p>
              <p className="text-xs text-gray-400">
                Manage your contracts here. You can create, edit, and delete
                contracts.
              </p>
            </div>
          </div>
          <UnderConstruction className="h-32 pb-4" />
        </div>
      </div>
      <div className="col-span-8">
        <LocationMap />
        <LocationSite />
        <LocationSpot />
        <LocationPoint />
      </div>
    </div>
  );
};

const LocationSite = () => {
  return null;
};

const LocationSpot = () => {
  return null;
};

const LocationPoint = () => {
  return null;
};

const LocationMap = () => {
  const { theme } = useTheme();

  const { job } = useJobContext();

  const [ready, setReady] = useState(false);

  if (job.partial) return null;

  const point = job.site.location?.precise?.geometry?.point;
  const address = job.site.location?.address;

  if (!point) {
    return (
      <div className="h-full p-4">
        <Empty asChild>
          <div className="flex flex-col items-center gap-y-2">
            <p className="text-sm">No precise location set</p>
            <p className="dark:text-white-400 text-xs text-gray-400">
              Locate this site on the map
            </p>
            <LocationSheet />
          </div>
        </Empty>
      </div>
    );
  }

  return (
    <div className="relative h-full w-full">
      {!ready && (
        <div className="absolute inset-0 z-10 bg-secondary p-4">
          <PageLoader>Loading map</PageLoader>
        </div>
      )}
      <div className="absolute left-4 top-4 z-[9] min-w-[400px] rounded-md border p-4 text-sm shadow-lg backdrop-blur-xl">
        <div className="mb-2 flex justify-between gap-x-4">
          <div>
            <p className="mb-1 font-bold">{job.site.reference}</p>
            <p>Address on file</p>
          </div>
        </div>
        <table className="pointer-events-auto">
          <tbody>
            <tr>
              <td className="pr-2 text-gray-500 dark:text-gray-400">
                Line one
              </td>
              <td>{address?.lineOne}</td>
            </tr>
            <tr>
              <td className="pr-2 text-gray-500 dark:text-gray-400">
                Line two
              </td>
              <td>{address?.lineTwo}</td>
            </tr>
            <tr>
              <td className="pr-2 text-gray-500 dark:text-gray-400">
                City/Town
              </td>
              <td>{address?.city}</td>
            </tr>
            <tr>
              <td className="pr-2 text-gray-500 dark:text-gray-400">
                Postcode
              </td>
              <td>{address?.postcode}</td>
            </tr>
            <tr>
              <td className="pr-2 text-gray-500 dark:text-gray-400">Country</td>
              <td>{address?.country}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Map
        key={theme}
        style={{ height: '100%', width: '100%' }}
        initialViewState={{
          zoom: 17,
          latitude: 51.4574,
          longitude: 0.1278,
          pitch: 45,
          bearing: 45,
          ...(point ? point : {}),
        }}
        mapboxAccessToken="pk.eyJ1IjoiZW5lcmd5ZW5hYmxlcyIsImEiOiJjbGpia2NqZ2IxcTcxM2pxeGxvNG5ud2lpIn0.gMXRc_xES-6AlQx1w8MuXw"
        attributionControl={false}
        mapStyle="mapbox://styles/mapbox/standard"
        onLoad={(event) => {
          if (point) addCircleOnLoad(event, point);
          if (theme === 'dark') {
            event.target.setConfigProperty('basemap', 'lightPreset', 'night');
            setTimeout(() => {
              setReady(true);
            }, 1000);
          } else {
            setReady(true);
          }
        }}
      >
        {point && (
          <Marker {...point}>
            <Circle className="size-3 fill-success stroke-success" />
          </Marker>
        )}
      </Map>
    </div>
  );
};

export default JobLocation;
