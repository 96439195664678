import React, { useMemo, useState } from 'react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  RendererField,
  custom,
  textarea,
} from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { MediaInput } from '@/components/ui/form/form-media/FormMedia';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { DateTime, Interval } from 'luxon';
import { toast } from 'sonner';
import { z } from 'zod';
import { PlatformObject, RosterExceedance } from 'lib/types';
import RosterExceedanceDescription from '../../../HumanResourcesRoster/components/RosterExceedanceDescription';
import { useStore } from '../../state';
import { useApproveExceedance } from './hooks';

const ExceedanceReview = ({ exceedance }: { exceedance: RosterExceedance }) => {
  const [open, setOpen] = useState(false);
  const {
    isApproving,
    isUpdating,
    isDeleting,
    approveExceedance,
    updateExceedance,
    deleteExceedance,
  } = useApproveExceedance();
  const fields = useMemo<RendererField[]>(
    () => [
      textarea({
        label: 'Note',
        name: 'note',
        placeholder: 'Aa',
        defaultValue: exceedance.review?.note ?? '',
        schema: z.string().min(2),
      }),
      custom({
        name: 'files',
        schema: z.array(z.any()).min(1),
        defaultValue: exceedance.review?.files ?? [],
        render: ({ formField }) => (
          <MediaInput value={formField.value} onChange={formField.onChange} />
        ),
      }),
    ],
    [exceedance.review?.note, exceedance.review?.files],
  );
  if (exceedance.review) {
    return (
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetTrigger asChild>
          <Button variant="link" className="p-0">
            Update review
          </Button>
        </SheetTrigger>
        <SheetContent className="flex h-full w-[700px] max-w-[700px] flex-col p-0 sm:max-w-[700px]">
          <SheetHeader className="p-4">
            <SheetTitle>Review exceedance</SheetTitle>
            <SheetDescription>
              Make a note about this exceedance.
            </SheetDescription>
          </SheetHeader>
          <div className="min-h-0 grow">
            <ScrollArea className="h-full ">
              <FormRenderer
                className="flex h-full flex-col p-4"
                fields={fields}
                onSubmit={async (values) => {
                  await updateExceedance({
                    id: exceedance.review.id,
                    note: values.note,
                    files: values.files.map((object: PlatformObject) => ({
                      id: object.id,
                      type: object.type,
                      size: object.size,
                      name: object.name,
                      data: object.data,
                    })),
                  });
                  setOpen(false);
                  toast.success('Note updated successfully');
                }}
              >
                {({ onSubmit }) => (
                  <div>
                    <SheetFooter>
                      <Button
                        isLoading={isDeleting}
                        onClick={async () => {
                          await deleteExceedance(exceedance.review.id);
                          setOpen(false);
                          toast.success(
                            'Exceedance review updated successfully',
                          );
                        }}
                      >
                        Delete
                      </Button>
                      <Button isLoading={isUpdating} onClick={onSubmit}>
                        Update
                      </Button>
                      <SheetClose>
                        <Button>Close</Button>
                      </SheetClose>
                    </SheetFooter>
                  </div>
                )}
              </FormRenderer>
            </ScrollArea>
          </div>
        </SheetContent>
      </Sheet>
    );
  }
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant="link" className="p-0">
          Review
        </Button>
      </SheetTrigger>
      <SheetContent className="flex h-full w-[700px] max-w-[700px] flex-col p-0 sm:max-w-[700px]">
        <SheetHeader className="p-4">
          <SheetTitle>Update exceedance review</SheetTitle>
          <SheetDescription>
            Make a note about this exceedance.
          </SheetDescription>
        </SheetHeader>
        <div className="min-h-0 grow">
          <ScrollArea className="h-full ">
            <FormRenderer
              className="flex h-full flex-col p-4"
              fields={fields}
              onSubmit={async (values) => {
                await approveExceedance(exceedance, {
                  note: values.note,
                  files: values.files.map((object: PlatformObject) => ({
                    id: object.id,
                    type: object.type,
                    size: object.size,
                    name: object.name,
                    data: object.data,
                  })),
                });
                setOpen(false);
                toast.success('Exceedance reviewed successfully');
              }}
            >
              {({ onSubmit }) => (
                <div>
                  <SheetFooter>
                    <Button isLoading={isApproving} onClick={onSubmit}>
                      Submit
                    </Button>
                    <SheetClose>
                      <Button>Close</Button>
                    </SheetClose>
                  </SheetFooter>
                </div>
              )}
            </FormRenderer>
          </ScrollArea>
        </div>
      </SheetContent>
    </Sheet>
  );
};

const RosterReviewExceedances = () => {
  const { exceedances, interval } = useStore((state) => ({
    exceedances: state.rosterValidationResults.exceedances,
    interval: Interval.fromDateTimes(state.dateTimeStart, state.dateTimeEnd),
  }));
  const exceedancesInView =
    exceedances?.filter((exceedance) => {
      const exceedanceInterval = Interval.fromDateTimes(
        DateTime.fromISO(exceedance.duty.dateTimeStart),
        DateTime.fromISO(exceedance.duty.dateTimeEnd),
      );
      return interval.overlaps(exceedanceInterval);
    }) ?? [];
  return (
    <Table>
      <TableHeader className="border-b">
        <TableHead className="pl-3">Status</TableHead>
        <TableHead>Exceedance</TableHead>
        <TableHead />
      </TableHeader>
      <TableBody>
        {exceedancesInView.length > 0 ? (
          exceedancesInView.map((exceedance) => {
            return (
              <TableRow
                key={`${exceedance.duty.id}-${exceedance.offence}`}
                className="h-[52px]"
              >
                <TableCell>
                  {exceedance.review ? (
                    <Badge variant="outline">Reviewed</Badge>
                  ) : (
                    <Badge variant="outline">Outstanding</Badge>
                  )}
                </TableCell>
                <TableCell>
                  <RosterExceedanceDescription exceedance={exceedance} />
                </TableCell>
                <TableCell>
                  <ExceedanceReview exceedance={exceedance} />
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={3} className="p-4 pl-3">
              <p className="mb-1 font-medium">No exceedances</p>
              <p className="text-xs text-gray-400">
                All validation checks passed
              </p>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default RosterReviewExceedances;
