import React from 'react';
import parse, { Element, domToReact } from 'html-react-parser';
import { cn } from '@/lib/utils';
import DOMPurify from 'dompurify';
import { ImageAnnotationProps } from './types';

const ImageAnnotation = ({
  className,
  style,
  width,
  height,
  annotation,
}: ImageAnnotationProps) => {
  const safe = DOMPurify.sanitize(annotation.svg);
  const html = parse(safe, {
    replace: (node) => {
      if (!(node instanceof Element)) return;
      if (node.name === 'svg') {
        const scale = annotation.width / width;
        const scaledWidth = annotation.width * scale;
        const scaledHeight = annotation.height * scale;
        const top = -((1 / scale) * annotation.height - height) / 2;

        return (
          <svg
            {...node.attribs}
            width={undefined}
            height={undefined}
            fill="red"
            viewBox={`0 0 ${scaledWidth} ${scaledHeight}`}
            style={{
              pointerEvents: 'none',
              position: 'absolute',
              left: 0,
              top,
              width: annotation.width,
              height: annotation.height,
            }}
          >
            {domToReact(node.children)}
          </svg>
        );
      }
      if (node.name === 'path') {
        return <path {...node.attribs} />;
      }
      return node;
    },
  });

  return (
    <div
      className={cn(
        'pointer-events-none absolute inset-0 overflow-hidden',
        className,
      )}
      style={style}
    >
      {html}
    </div>
  );
};

export default ImageAnnotation;
