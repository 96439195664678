import React from 'react';
import { useLocationContext } from '../../context';

const LocationEstimates = ({ border = false }) => {
  const { location, supplementaryLocation } = useLocationContext();
  const estimates = (location ?? supplementaryLocation)?.estimates;

  if (!estimates) return null;

  return (
    <div>
      {estimates.map((estimate) => (
        <div key={estimate.id}>{estimate.id}</div>
      ))}
    </div>
  );
};

export default LocationEstimates;
