import { Edge, Node, Position } from '@xyflow/react';
import { Job } from 'lib/types';

export type Elements = {
  nodeMap: Map<string, Node>;
  edgeMap: Map<string, Edge>;
};

export type Direction = 'TB' | 'LR' | 'RL' | 'BT';

export type LayoutAlgorithmOptions = {
  direction: Direction;
  spacing: [number, number];
};

export type LayoutAlgorithm = (
  nodes: Node[],
  edges: Edge[],
  options: LayoutAlgorithmOptions,
) => Promise<{ nodes: Node[]; edges: Edge[] }>;

export type Options = {
  direction: Direction;
};

export const positionMap: Record<string, Position> = {
  T: Position.Top,
  L: Position.Left,
  R: Position.Right,
  B: Position.Bottom,
};

export type NodeProps<NodeData> = {
  id: string;
  data: NodeData;
};

export type OverviewFlowProps = {
  job: Job;
  defaultZoom?: number;
  highlight?: string[];
  showControls?: boolean;
  showMeta?: boolean;
} & (
  | {
      selectableTypes?: undefined;
      selected?: undefined;
      onSelect?: undefined;
    }
  | {
      selectableTypes: NodeType[];
      selected: string[];
      onSelect: (id: string) => void;
    }
);

export enum NodeType {
  Job = 'JOB',
  Task = 'TASK',
  Issue = 'ISSUE',
  Quote = 'QUOTE',
  Report = 'REPORT',
  Variation = 'VARIATION',
}
