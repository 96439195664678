import React, { Fragment } from 'react';
import { Hexagon } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '@/components/ui/navigation-menu';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import { useRouteMatch } from 'lib/hooks';
import { ScopeLabel } from 'lib/shared';
import { getJobTaskStatusColor } from 'lib/utils';
import { useJobContext } from '../../context';
import OverviewGraph from '../../pages/JobOverview/components/OverviewGraph';
import JobLink from '../JobLink';

const JobNavigationMenu = () => {
  const { job } = useJobContext();

  const routeMatch = useRouteMatch([
    '/clients/:clientSlug/jobs/:jobReference',
    '/clients/:clientSlug/jobs/:jobReference/location/*',
    '/clients/:clientSlug/jobs/:jobReference/settings/*',
    '/clients/:clientSlug/jobs/:jobReference/commercial',
    '/clients/:clientSlug/jobs/:jobReference/tasks/*',
    '/clients/:clientSlug/jobs/:jobReference/issues/*',
    '/clients/:clientSlug/jobs/:jobReference/reports/*',
    '/clients/:clientSlug/jobs/:jobReference/:variation',
    '/clients/:clientSlug/jobs/:jobReference/:variation/commercial',
    '/clients/:clientSlug/jobs/:jobReference/:variation/tasks/*',
    '/clients/:clientSlug/jobs/:jobReference/:variation/issues/*',
    '/clients/:clientSlug/jobs/:jobReference/:variation/reports/*',
  ]);

  if (job.partial) return null;

  const isMatch = (base: string) => {
    const path = routeMatch?.pattern.path;

    if (!path) {
      return false;
    }

    return path === base || path === `${base}/:variation`;
  };

  const navigationLinkStyle = (path: string, extra?: string) => {
    const matched = isMatch(path);
    return cn(navigationMenuTriggerStyle(), extra, {
      'bg-muted': matched,
      '[&>.nav-badge]:border-white/25': matched,
    });
  };

  const badgeStyle =
    'nav-badge ml-2 flex size-[20px] justify-center rounded dark:border-gray-700 border-gray-300 p-0 text-[11px]';

  const disabled =
    !job.isInstantiated || (!!job.variation && !job.variation.isInstantiated);

  const variationEstimating =
    job.variation && job.variation.commercialActivity.status === 'ESTIMATING';

  return (
    <NavigationMenu className="z-[9] justify-start">
      <NavigationMenuList className="h-8">
        <NavigationMenuItem className="h-8">
          <NavigationMenuLink
            asChild
            className={cn(
              navigationLinkStyle(
                '/clients/:clientSlug/jobs/:jobReference',
                '',
              ),
            )}
          >
            <JobLink className="h-8" to="">
              Overview
            </JobLink>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem className={cn('h-8')}>
          <NavigationMenuLink
            asChild
            className={cn(
              navigationLinkStyle(
                '/clients/:clientSlug/jobs/:jobReference/commercial',
                '',
              ),
              {
                'ml-2 border pl-3 pr-2': variationEstimating,
              },
            )}
          >
            <JobLink className="h-8" to="commercial">
              Commercial
              {variationEstimating && (
                <Badge
                  className="ml-4 h-4 rounded text-[11px]"
                  variant="incoming"
                >
                  Estimating
                </Badge>
              )}
            </JobLink>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger
            disabled={disabled}
            className={navigationLinkStyle(
              '/clients/:clientSlug/jobs/:jobReference/tasks/*',
              'px-2',
            )}
          >
            Tasks
            <Badge className={badgeStyle} variant="outline">
              {job.tasks.length}
            </Badge>
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <div className="flex w-[500px] flex-col">
              <div className="border-b">
                <p className="px-4 py-2 text-xs dark:text-gray-400">Tasks</p>
              </div>
              {job.tasks.length > 0 ? (
                job.tasks.map((task) => (
                  <Fragment key={task.id}>
                    <NavigationMenuLink
                      asChild
                      className="cursor-pointer p-4 hover:bg-muted"
                    >
                      <JobLink to={`tasks/${task.number}`}>
                        <div className="mb-2 flex items-center gap-x-2">
                          <p className="text-sm font-semibold">
                            #{task.number} {task.name}
                          </p>
                          <Badge
                            className="h-5 capitalize"
                            variant="outline"
                            style={{
                              borderColor: getJobTaskStatusColor(task),
                              color: getJobTaskStatusColor(task),
                            }}
                          >
                            {task.status.toLowerCase()}
                          </Badge>
                        </div>
                        <p className="line-clamp-3 max-h-[100px] text-xs text-gray-400">
                          {task.description}
                        </p>
                      </JobLink>
                    </NavigationMenuLink>
                    <Separator className="last-of-type:hidden" />
                  </Fragment>
                ))
              ) : (
                <div className="p-4 py-8 text-center">
                  <Hexagon className="dark:text-white-400 mx-auto mb-2 h-4 w-4 text-gray-400" />
                  <p className="dark:text-white-400 text-xs text-gray-400">
                    No tasks
                  </p>
                </div>
              )}
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger
            disabled={disabled}
            className={navigationLinkStyle(
              '/clients/:clientSlug/jobs/:jobReference/issues/*',
              'px-2',
            )}
          >
            Issues
            <Badge className={badgeStyle} variant="outline">
              {job.issues.length}
            </Badge>
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <div className="flex w-[500px] flex-col">
              <div className="border-b">
                <p className="px-4 py-2 text-xs dark:text-gray-400">Issues</p>
              </div>
              {job.issues.length > 0 ? (
                job.issues.map((issue) => (
                  <Fragment key={issue.id}>
                    <NavigationMenuLink
                      asChild
                      className="cursor-pointer p-4 hover:bg-muted"
                    >
                      <JobLink to={`issues/${issue.number}`}>
                        <div className="flex items-center gap-x-2 text-sm">
                          <ScopeLabel
                            className="lowercase"
                            label={issue.name}
                          />
                          <Badge className="h-5 capitalize" variant="outline">
                            {issue.status.toLowerCase()}
                          </Badge>
                        </div>
                        <p>{issue.description}</p>
                      </JobLink>
                    </NavigationMenuLink>
                    <Separator className="last-of-type:hidden" />
                  </Fragment>
                ))
              ) : (
                <div className="p-4 py-8 text-center">
                  <Hexagon className="dark:text-white-400 mx-auto mb-2 h-4 w-4 text-gray-400" />
                  <p className="dark:text-white-400 text-xs text-gray-400">
                    No issues
                  </p>
                </div>
              )}
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger
            disabled={disabled}
            className={navigationLinkStyle(
              '/clients/:clientSlug/jobs/:jobReference/reports/*',
              'px-2',
            )}
          >
            Reports
            <Badge className={badgeStyle} variant="outline">
              {job.reports.length}
            </Badge>
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <div className="flex w-[500px] flex-col">
              <div className="border-b">
                <p className="px-4 py-2 text-xs dark:text-gray-400">Reports</p>
              </div>
              {job.reports.length > 0 ? (
                job.reports.map((report) => (
                  <Fragment key={report.id}>
                    <NavigationMenuLink
                      asChild
                      className="cursor-pointer p-4 hover:bg-muted"
                    >
                      <JobLink to={`reports/${report.number}`}>
                        <div className="flex items-center gap-x-2">
                          <p className="text-sm font-semibold">
                            #{report.number} {report.name}
                          </p>
                          <Badge className="h-5 capitalize" variant="outline">
                            {report.status.toLowerCase()}
                          </Badge>
                        </div>
                      </JobLink>
                    </NavigationMenuLink>
                    <Separator className="last-of-type:hidden" />
                  </Fragment>
                ))
              ) : (
                <div className="px-4 py-8 text-center">
                  <Hexagon className="dark:text-white-400 mx-auto mb-2 h-4 w-4 text-gray-400" />
                  <p className="dark:text-white-400 text-xs text-gray-400">
                    No reports
                  </p>
                </div>
              )}
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger
            disabled={disabled}
            className={navigationLinkStyle('never', 'px-2')}
          >
            Graph
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <div className="grid h-[400px] gap-3 p-4 md:w-[400px] lg:w-[800px] lg:grid-cols-[.25fr_1fr]">
              <span className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md">
                <div className="mb-2 mt-4 text-lg font-medium">Graph</div>
              </span>
              <div className="flex flex-col gap-2">
                <OverviewGraph />
              </div>
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
};

export default JobNavigationMenu;
