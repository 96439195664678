import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@/components/ui/button';
import { faBrowser } from '@fortawesome/pro-regular-svg-icons/faBrowser';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { ItemType } from 'external/Tree';
import { useFormTemplateContext } from '../../context';
import { useCreateItem } from '../../hooks';
import { DataType } from '../../types';
import { BlockSidecarProps } from './types';

const BlockSidecar = ({ item }: BlockSidecarProps) => {
  const createItem = useCreateItem({ item });
  const { focus } = useFormTemplateContext();
  const isFocused = focus === item.id;

  if (!isFocused) return null;

  return (
    <div className="absolute left-[calc(100%+16px)] top-1/2 z-10 w-20 -translate-y-1/2 transform rounded-md bg-background p-1">
      <svg
        className="absolute right-full top-0 h-full w-4 fill-background"
        viewBox="0 0 1 11"
        preserveAspectRatio="none"
      >
        <path d="M0,5.5 l1,-1 v2 z" stroke="transparent" />
      </svg>
      <Button
        variant="ghost"
        className="aspect-square h-auto w-full rounded-t-md bg-background"
        onClick={createItem(ItemType.BLOCK, {
          dataType: DataType.CHAR,
          isRequired: true,
        })}
      >
        <div className="flex flex-col gap-y-2">
          <FontAwesomeIcon icon={faPlus} style={{ height: 20 }} />
          <p className="text-xs">Question</p>
        </div>
      </Button>
      <Button
        variant="ghost"
        className="aspect-square h-auto w-full rounded-b-md bg-background"
        onClick={createItem(ItemType.CONTAINER, { isRepeated: false })}
      >
        <div className="flex flex-col gap-y-2">
          <FontAwesomeIcon icon={faBrowser} style={{ height: 20 }} />
          <p className="text-xs">Section</p>
        </div>
      </Button>
    </div>
  );
};

export default BlockSidecar;
