import React from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import DeliveryCategories from './DeliveryCategories';
import DeliveryJobs from './DeliveryJobs';
import DeliveryPriorities from './DeliveryPriorities';

const SettingsDelivery = () => {
  return (
    <div className="flex h-full min-h-0 flex-col">
      <div className="flex h-[52px] flex-shrink-0 items-center justify-between border-b px-4 py-2">
        <h1 className="text-xl font-bold">Delivery</h1>
      </div>
      <ScrollArea className="min-h-0 grow">
        <DeliveryCategories />
        <Separator />
        <DeliveryPriorities />
        <Separator />
        <DeliveryJobs />
      </ScrollArea>
    </div>
  );
};

export default SettingsDelivery;
