import React from 'react';
import { DotIcon } from 'lucide-react';
import { useTimelineRendererContext } from '../../context';

const TimelineGrid = () => {
  const { jobs, refs } = useTimelineRendererContext();
  return (
    <div
      ref={refs.grid}
      className="pointer-events-none absolute inset-0 z-[10] grid min-h-0 grid-cols-12 gap-2 overflow-hidden"
    >
      <div className="pointer-events-auto col-span-3">
        <div className="border-r bg-background/75 backdrop-blur-md">
          <div className="sticky top-0 h-8 border-b bg-background/75 p-2 pl-[18px] backdrop-blur-md">
            <p className="text-xs font-semibold dark:text-gray-400">
              Most urgent first
            </p>
          </div>
          {jobs.map((job) => {
            return (
              <button
                key={job.id}
                className="flex h-12 w-full justify-start gap-x-2 p-2 text-start hover:bg-accent"
                style={{ alignItems: 'unset' }}
              >
                <div>
                  <div className="h-full w-[3px] rounded-full bg-incoming-foreground" />
                </div>
                <div>
                  <div className="flex items-center gap-x-1">
                    <p className="text-xs font-semibold">{job.reference}</p>
                    {job.category?.code && (
                      <>
                        <DotIcon className="h-2 w-2" />
                        <p className="font-mono text-[11px] leading-[12px]">
                          {job.category.code.replace(/_/g, ' ')}
                        </p>
                      </>
                    )}
                  </div>
                  <p className="line-clamp-1 text-[10px] text-xs dark:text-gray-400">
                    {job.description}
                  </p>
                </div>
              </button>
            );
          })}
        </div>
      </div>
      <div />
    </div>
  );
};

export default TimelineGrid;
