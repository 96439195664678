import React, { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { CommandDialog, CommandInput } from '@/components/ui/command';
import { Loader } from '@/components/ui/loading';
import { ScrollArea } from '@/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import * as CommandPrimitive from 'cmdk';
import { platformClient } from '../../../../app/platform';
import SearchResults from '../SearchResults';
import SearchStatic from '../SearchStatic';

export const SearchDialog = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [results, setResults] = useState<any[]>([]);
  useEffect(() => {
    const down = (event: KeyboardEvent) => {
      if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        setOpen((open) => !open);
      }
    };
    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);
  return (
    <div>
      <Button
        disabled={open}
        variant="outline"
        className={cn(
          'relative h-9 w-full justify-start rounded-[0.5rem] bg-background text-sm font-normal text-muted-foreground shadow-none sm:pr-12 md:w-40 lg:w-64',
        )}
        onClick={() => setOpen(true)}
      >
        <span className="hidden lg:inline-flex">Search everything...</span>
        <kbd className="pointer-events-none absolute right-[0.3rem] top-[0.3rem] hidden h-6 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
          <span className="text-xs">⌘</span>K
        </kbd>
      </Button>
      <CommandDialog
        open={open}
        onOpenChange={setOpen}
        shouldFilter={!searching}
        loop
      >
        {!searching && (
          <>
            <CommandInput
              placeholder="Type a command or search..."
              autoFocus
              value={search}
              onValueChange={async (searchTerm) => {
                setSearch(searchTerm);
              }}
              onKeyDown={async (event) => {
                if (event.key === 'Enter') {
                  setSearching(true);
                  setLoading(true);
                  const response =
                    await platformClient.searchEverything(search)();
                  setResults(
                    response.results.map((result: any) => ({
                      ...result,
                      scope: result.scope?.toLowerCase()?.replace(/\./g, '::'),
                    })),
                  );
                  setLoading(false);
                }
              }}
            />
            <ScrollArea>
              <SearchStatic
                onClose={() => {
                  setSearching(false);
                  setSearch('');
                  setResults([]);
                  setOpen(false);
                }}
              />
            </ScrollArea>
          </>
        )}
        {searching && (
          <>
            <div className="relative">
              <CommandInput
                placeholder="Type a command or search..."
                autoFocus
                value={search}
                onValueChange={async (searchTerm) => {
                  setSearch(searchTerm);
                }}
                onKeyDown={async (event) => {
                  if (event.key === 'Enter') {
                    if (!search) {
                      setSearching(false);
                      setSearch('');
                      setResults([]);
                    } else {
                      setSearching(true);
                      setLoading(true);
                      setResults([]);
                      const response =
                        await platformClient.searchEverything(search)();
                      setResults(
                        response.results.map((result: any) => ({
                          ...result,
                          scope: result.scope
                            ?.toLowerCase()
                            ?.replace(/\./g, '::'),
                        })),
                      );
                      setLoading(false);
                    }
                  }
                }}
              />
              <div className="absolute bottom-0 right-10 top-0 flex h-[48px] items-center">
                <Button
                  size="xs"
                  variant="ghost"
                  onClick={() => {
                    setSearching(false);
                    setSearch('');
                    setResults([]);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setSearching(false);
                      setSearch('');
                      setResults([]);
                    }
                  }}
                >
                  Clear search
                </Button>
              </div>
            </div>
            <ScrollArea>
              <CommandPrimitive.CommandList>
                {loading ? (
                  <CommandPrimitive.CommandLoading>
                    <div className="flex h-[56px] items-center pl-4">
                      <Loader>Searching...</Loader>
                    </div>
                  </CommandPrimitive.CommandLoading>
                ) : (
                  <SearchResults
                    results={results}
                    onClose={() => {
                      setOpen(false);
                      setSearching(false);
                      setSearch('');
                      setResults([]);
                    }}
                  />
                )}
              </CommandPrimitive.CommandList>
            </ScrollArea>
          </>
        )}
      </CommandDialog>
    </div>
  );
};

export default SearchDialog;
