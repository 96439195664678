import React, { useCallback, useMemo } from 'react';
import { Document, Font, Page, Text } from '@react-pdf/renderer';
import { ErrorBoundary } from 'react-error-boundary';
import { useTheme } from '@/components/ui/theme';
import renderForPlatform from '../../utils/renderForPlatform';
import { MediaBoundary } from '../MediaProvider';
import ReportBlock from './components/ReportBlock';
import BlockAppendix from './components/ReportBlock/components/BlockAppendix';
import BlockContainer from './components/ReportBlock/components/BlockContainer';
import BlockDateTime from './components/ReportBlock/components/BlockDateTime';
import BlockDuration from './components/ReportBlock/components/BlockDuration';
import FormPage from './components/ReportBlock/components/BlockForm/components/FormPage';
import { ReportBlockFormContext } from './components/ReportBlock/components/BlockForm/context';
import { useBlockForm } from './components/ReportBlock/components/BlockForm/hooks';
import BlockMap from './components/ReportBlock/components/BlockMap';
import BlockText from './components/ReportBlock/components/BlockText';
import BlockTitle from './components/ReportBlock/components/BlockTitle';
import ReportDocument from './components/ReportDocument';
import ReportImage from './components/ReportImage';
import ReportLogo from './components/ReportLogo';
import ReportSection from './components/ReportSection';
import ReportStack from './components/ReportStack';
import ReportSvg from './components/ReportSvg';
import ReportText from './components/ReportText';
import ReportView from './components/ReportView';
import { ReportContext, ReportTheme, useReportContext } from './context';
// @ts-ignore
import InterBold from './fonts/Inter/Inter-Bold.ttf';
// @ts-ignore
import InterRegular from './fonts/Inter/Inter-Regular.ttf';
// @ts-ignore
import InterSemiBold from './fonts/Inter/Inter-SemiBold.ttf';
import { useReportQuery, useReportQueryFactory } from './hooks';
import ReportHexagon from './icons/ReportHexagon';
import { ReportBlockComponent, ReportMode, ReportProps } from './types';

export const registerFonts = () => {
  Font.register({
    family: 'Inter',
    fonts: [
      {
        src: InterRegular,
      },
      {
        src: InterSemiBold,
        fontWeight: 'bold',
      },
      {
        src: InterBold,
        fontWeight: 'ultrabold',
      },
    ],
  });

  Font.registerHyphenationCallback((word) => [word]);
};

registerFonts();

const defaultBlocks: Record<string, ReportBlockComponent> = {
  container: BlockContainer,
  map: BlockMap,
  text: BlockText,
  datetime: BlockDateTime,
  duration: BlockDuration,
  title: BlockTitle,
  logo: ReportLogo,
  appendix: BlockAppendix,
};

const Report = ({
  mode,
  cache,
  template,
  context,
  blocks = {},
  footer,
  images = [],
  documents = [],
  registerDocument = () => {},
  registerImage = () => {},
  registeredObjects,
  registeredObjectSources,
}: ReportProps) => {
  const theme = useMemo(
    () => ({
      base: {
        colors: {
          border: '#e0e0e0',
          signature: '#000',
          signatureBackground: '#fff',
          annotation: '#f00',
          title: '#000',
          subtitle: 'rgb(120, 125, 134)',
        },
      },
      dark: {
        colors: {
          border: '--border',
          signature: '#fff',
          signatureBackground: 'transparent',
          annotation: '#f00',
          title: '#fff',
          subtitle: 'rgb(255 255 255 / 0.5)',
        },
      },
    }),
    [],
  ) as { base: ReportTheme; dark: ReportTheme };

  const { theme: themeMode } = useTheme();

  const theme_ = useMemo(() => {
    if (mode === ReportMode.PDF || themeMode === 'light') {
      return theme.base;
    }
    return theme.dark;
  }, [themeMode, mode, theme.base, theme.dark]) as ReportTheme;

  const getBlockComponent = useCallback(
    (block: string) => {
      const ret = block in blocks ? blocks[block] : defaultBlocks[block];
      if (!ret) {
        throw Error(`Block component not found for ${block}`);
      }
      return ret;
    },
    [blocks],
  );

  const renderedContext = renderForPlatform(context);
  const reportContext = {
    mode,
    theme: theme_,
    cache,
    images,
    documents,
    registerImage,
    registerDocument,
  };

  if (
    mode === ReportMode.Edit ||
    mode === ReportMode.View ||
    mode === ReportMode.Contents
  ) {
    return (
      <ReportContext.Provider value={reportContext}>
        <ReportView>
          <ErrorBoundary
            fallback={
              <ReportView style={{ padding: 16 }}>
                <ReportText>Error rendering report</ReportText>
              </ReportView>
            }
          >
            {template.pages
              .flatMap((page) => page.structure)
              .map((block) => (
                <ReportBlock
                  key={block.id}
                  block={block}
                  component={template.components[block.component]}
                  context={renderedContext}
                  getBlockComponent={getBlockComponent}
                />
              ))}
          </ErrorBoundary>
        </ReportView>
      </ReportContext.Provider>
    );
  }

  if (mode === ReportMode.PDF) {
    return (
      <Document>
        <ReportContext.Provider value={reportContext}>
          <MediaBoundary
            registeredObjects={registeredObjects}
            registeredObjectSources={registeredObjectSources}
          >
            {template.pages.map((page) => {
              return (
                <Page
                  key={page.id}
                  wrap
                  size="A4"
                  bookmark={page.id}
                  style={{ fontFamily: 'Inter', ...page.style }}
                >
                  {page.structure.map((block) => (
                    <ReportBlock
                      key={block.id}
                      block={block}
                      component={template.components[block.component]}
                      context={renderedContext}
                      getBlockComponent={getBlockComponent}
                    />
                  ))}
                  {footer}
                  <Text
                    style={{
                      position: 'absolute',
                      bottom: 12,
                      right: 36,
                      fontSize: 10,
                      fontWeight: 600,
                      color: theme_.colors.subtitle,
                    }}
                    fixed
                    render={({ pageNumber, totalPages }) => (
                      <>
                        Page {pageNumber} of {totalPages}
                      </>
                    )}
                  />
                </Page>
              );
            })}
          </MediaBoundary>
        </ReportContext.Provider>
      </Document>
    );
  }

  throw Error(`Invalid view mode ${mode}.`);
};

export {
  ReportText,
  ReportView,
  ReportBlock,
  ReportSvg,
  ReportHexagon,
  ReportSection,
  ReportImage,
  ReportDocument,
  useReportQuery,
};

export default {
  Root: Report,
  Logo: ReportLogo,
  Text: ReportText,
  View: ReportView,
  Stack: ReportStack,
  Block: ReportBlock,
  Svg: ReportSvg,
  Hexagon: ReportHexagon,
  Section: ReportSection,
  Image: ReportImage,
  Document: ReportDocument,
  useContext: useReportContext,
  FormPage,
  FormContext: ReportBlockFormContext,
  useQuery: useReportQuery,
  useQueryFactory: useReportQueryFactory,
  useBlockForm,
} as const;
