import React, { useState } from 'react';
import { RenderSearchProps } from '@react-pdf-viewer/search';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  SearchIcon,
  XIcon,
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

const ViewerSearch = ({
  keyword,
  setKeyword,
  clearKeyword,
  search,
  jumpToNextMatch,
  jumpToPreviousMatch,
  currentMatch,
  numberOfMatches,
}: RenderSearchProps) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="flex gap-x-2">
      {open && (
        <>
          <div className="relative ml-2">
            <Input
              className="h-8"
              autoFocus
              value={keyword}
              onChange={(event) => {
                setKeyword(event.target.value);
              }}
              placeholder="Search"
              onKeyDown={async (event) => {
                if (event.key === 'Enter') {
                  await search();
                }
                if (event.key === 'Escape') {
                  setOpen(false);
                  clearKeyword();
                }
              }}
            />
            {numberOfMatches > 0 && (
              <div className="absolute inset-y-0 right-2 flex items-center">
                <small className="text-muted-foreground">
                  {currentMatch}/{numberOfMatches}
                </small>
              </div>
            )}
          </div>
          <div>
            <Button
              size="icon-sm"
              variant="ghost"
              disabled={currentMatch === 1 || numberOfMatches === 0}
              onClick={jumpToPreviousMatch}
            >
              <ChevronUpIcon className="h-4 w-4 opacity-50" />
            </Button>
          </div>
          <div>
            <Button
              size="icon-sm"
              variant="ghost"
              disabled={
                currentMatch === numberOfMatches || numberOfMatches === 0
              }
              onClick={jumpToNextMatch}
            >
              <ChevronDownIcon className="h-4 w-4 opacity-50" />
            </Button>
          </div>
        </>
      )}
      <div>
        <Button
          size="icon-sm"
          variant="ghost"
          onClick={() => {
            setOpen((prevOpen) => !prevOpen);
            clearKeyword();
          }}
        >
          {open ? (
            <XIcon className="h-4 w-4 opacity-50" />
          ) : (
            <SearchIcon className="h-4 w-4 opacity-50" />
          )}
        </Button>
      </div>
    </div>
  );
};

export default ViewerSearch;
