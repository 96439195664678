import { ScrollArea } from '@/components/ui/scroll-area';
import { IssueBodyProps } from './types';

const IssueBody = ({ children }: IssueBodyProps) => {
  return (
    <div className="flex min-h-0 grow flex-col bg-secondary bg-dotted-spacing-4 bg-dotted-gray-200 dark:bg-dotted-background">
      <ScrollArea>
        <div className="grid h-full min-h-0 w-full grid-cols-12 pb-4">
          {children}
        </div>
      </ScrollArea>
    </div>
  );
};

export default IssueBody;
