import React, { PropsWithChildren } from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useActionContext } from '../../context';

const ActionTooltip = ({ children }: PropsWithChildren) => {
  const { action, small } = useActionContext();
  if (!action) return null;
  if (small) return <>{children}</>;
  return (
    <Tooltip delayDuration={1000}>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent>
        {action.isValid ? (
          <p className="text-xs text-gray-400">{action.description}</p>
        ) : (
          <p className="text-xs text-gray-400">{action.validatorDescription}</p>
        )}
      </TooltipContent>
    </Tooltip>
  );
};

export default ActionTooltip;
