import { createContext } from 'react';
import { DateTime, Interval } from 'luxon';
import { User } from 'lib/types';

export const ClientActivityContext = createContext({
  interval: Interval.fromDateTimes(
    DateTime.local().startOf('day'),
    DateTime.local().startOf('day').plus({ days: 1 }),
  ),
  focused: null as User | null,
  onFocus: (_: User | null) => {},
  onBlur: () => {},
  onIntervalChange: (_: Interval) => {},
});
