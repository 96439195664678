import { DateTime } from 'luxon';
import { Client } from './clients';
import {
  CommercialActivity,
  CommercialContractRate,
  CommercialEstimate,
  CommercialType,
  CommercialUnit,
} from './commercial';
import { Nullable } from './core';
import { FormTemplate } from './forms';
import { Location } from './locations';
import { PlatformAction, PlatformObject } from './platform';
import { Site } from './sites';
import { Subcontractor, SubcontractorReport } from './subcontractors';
import { TimesheetActivity, TimesheetDuty, TimesheetEvent } from './timesheets';
import { User } from './users';

export type JobGroup = {
  id: string;
  clientId: Client['id'];
  reference: string;
};

export type Job = {
  id: string;
  client: Client;
  status: any[];
  site: Site;
  spot?: Site;
  location?: Location;
  timeline: JobTimeline;
  locationId?: string;
  group: JobGroup;
  reference: string;
  category: JobCategory;
  priority: JobPriority;
  scope: string;
  description: string;
  isSiteConfirmed: boolean;
  isInfoConfirmed: boolean;
  isInstantiated: boolean;
  isRejected: boolean;
  rejectionReason?: string;
  dateTimeCreated: string;
  dateTimeStart: string;
  dateTimeEnd: string;
  targetDateTimeStart: string;
  targetDateTimeEnd: string;
  actualDateTimeStart?: string;
  actualDateTimeEnd?: string;
  tasks: JobTask[];
  issues: JobIssue[];
  quotes: JobQuote[];
  reports: JobReport[];
  variations: JobVariation[];
  variation: JobVariation | null;
  actionSetId?: string;
  externalId?: string;
  graph: {
    nodes: any[];
    edges: any[];
  };
  canCreateTask: boolean;
  notes: JobNote[];
  tags: JobTag[];
  attendances: JobTaskAttendance[];
  firstSiteTimesheetEvent?: TimesheetEvent;
  lastSiteTimesheetEvent?: TimesheetEvent;
  activeDurationOnSite: number;
  chargeableDurationOnSite: number;
  totalDurationOnSite: number;
  commercialActivity: CommercialActivity;
  refetch: (variables?: { jobVariationReference?: string }) => Promise<any>;
  variationOnChange: (jobVariationReference: string) => Promise<any>;
  variationReference?: string;
  partial?: boolean;
  deletedAt?: string;
};

export type JobTimeline = {
  id: string;
  dateTimeStart: string;
  targets: JobTimelineTarget[];
};

export type JobTimelineTarget = {
  id: string;
  type: string;
  typeDisplay: string;
  dateTime: string;
};

export enum JobTimelineTargetAimCondition {
  StartBy = 'START_BY',
  CompleteBy = 'COMPLETE_BY',
}

export enum JobTimelineTargetAimStatus {
  Pending = 'PENDING',
}

export type JobTimelineTargetAim = {
  id: string;
  condition: JobTimelineTargetAimCondition;
  status: JobTimelineTargetAimStatus;
  target: JobTimelineTarget;
  task: JobTask;
  issues: JobIssue[];
};

export type JobInteraction = {
  id: string;
  commercialContractRate: CommercialContractRate;
  duration: number;
};

export type JobNote = {
  id: string;
  dateTime: DateTime;
  content: string;
  files: PlatformObject[];
  user: User;
};

export type JobTag = {
  id: string;
  label: string;
  color: string;
};

export type JobCategory = {
  id: string;
  name: string;
  code: string;
  refetch: () => Promise<any>;
};

export type JobPriority = {
  id: string;
  reference: string;
  color: string;
  responseName: string;
  responseNameShort?: string;
  responseDuration: number;
  completionName: string;
  completionNameShort?: string;
  completionDuration: number;
  durationSpace: string;
};

export enum JobTaskStatus {
  Pending = 'PENDING',
  Started = 'STARTED',
  Completed = 'COMPLETED',
}

export enum JobTaskAttendanceStatus {
  Planned = 'PLANNED',
  Started = 'STARTED',
  Joined = 'JOINED',
  OnHold = 'ON_HOLD',
  Left = 'LEFT',
  Completed = 'COMPLETED',
}

export type JobTaskAttendance = {
  id: string;
  status: JobTaskAttendanceStatus;
  duty: TimesheetDuty;
  task: JobTask;
  dateTimeOn: DateTime | null;
  dateTimeOff: DateTime | null;
  duration: number | null;
  plannedDuration: number | null;
  liveDuration: number | null;
  user?: User;
  subcontractor?: Subcontractor;
  subcontractorReports?: SubcontractorReport[];
  actionSetId?: string;
};

export interface JobTask {
  id: string;
  name: string;
  slug: string;
  number: number;
  status: JobTaskStatus;
  description: string;
  viewDateTimeStart: string | null;
  viewDateTimeEnd: string | null;
  plannedDateTimeOn: DateTime | null;
  plannedDateTimeOff: DateTime | null;
  dateTimeOn: DateTime | null;
  dateTimeOff: DateTime | null;
  totalDuration: number | null;
  job: Job;
  aims: JobTimelineTargetAim[];
  notes: JobNote[];
  forms: JobTaskForm[];
  users: User[];
  attendances: JobTaskAttendance[];
  subcontractors: Subcontractor[];
  subcontractorReports: SubcontractorReport[];
  actionSetId?: string;
  requiresAssignment: boolean;
  deleted: boolean;
  requiresCompletionConfirmation: boolean;
  canDelete: boolean;
  timesheetActivity: TimesheetActivity;
  tags: JobTag[];
  refetch: () => Promise<any>;
}

export enum JobTaskFormStatus {
  Assigned = 'ASSIGNED',
  Started = 'STARTED',
  Completed = 'COMPLETED',
}

export enum JobTaskFormScope {
  Staff = 'STAFF',
  Subcontractor = 'SUBCONTRACTOR',
  General = 'GENERAL',
}

export type JobTaskForm = {
  id: string;
  isRequired: boolean;
  isCompleted: boolean;
  isApproved: boolean;
  isLocked: boolean;
  status: JobTaskFormStatus;
  scope: JobTaskFormScope;
  submissions: JobTaskFormSubmission[];
  template: FormTemplate;
  latestSubmission: JobTaskFormSubmission | null;
};

export type JobTaskFormSubmission = {
  id: string;
  form: JobTaskForm;
  number: number;
  data: string;
  template: string;
  dateTimeCreated: DateTime;
  approvedBy: Nullable<User>;
};

export type JobVariation = {
  id: string;
  reference: string;
  dateTimeCreated: DateTime;
  description: string;
  isInstantiated: boolean;
  tasks: JobTask[];
  issue?: JobIssue;
  externalId: string;
  actionSetId?: string;
  commercialActivity: CommercialActivity;
  quoteResponses: JobQuoteResponse[];
};

export type JobQuoteResponse = {
  id: string;
  description: string;
  jobReference: string;
  jobVariationReference: string;
  approves: JobQuote[];
  rejects: JobQuote[];
};

export enum JobReportStatus {
  Open = 'OPEN',
  Pending = 'PENDING',
  Submitted = 'SUBMITTED',
  Closed = 'CLOSED',
}

export type JobReport = {
  id: string;
  name: string;
  status: JobReportStatus;
  statusDisplay: string;
  slug: string;
  number: number;
  tasks: JobReportTaskAttachment[];
  links: JobLink[];
  selected?: string[];
  actionSetId?: string;
  dateTimeCreated: DateTime;
  dateTimePrepared?: DateTime;
  dateTimeSubmitted?: DateTime;
  job: Job;
  versions: JobReportVersion[];
  latestVersion?: JobReportVersion;
};

export type JobReportVersion = {
  id: string;
  report: JobReport;
  number: number;
  dateTimeCreated: DateTime;
  sourceObject?: PlatformObject;
  externalId?: string;
  statusInternal: string;
  statusExternal: string;
  refetch: () => Promise<any>;
};

export type JobLinkNode =
  | Job
  | JobTask
  | JobIssue
  | JobQuote
  | JobVariation
  | JobReport;

export type JobLink = {
  id: string;
  type: string;
  source: JobLinkNode;
  target: JobLinkNode;
  data: any;
};

export type JobReportTaskAttachment = {
  id: string;
  report: JobReport;
  task: JobTask;
  forms: JobTaskForm[];
};

export enum JobIssueStatus {
  Open = 'OPEN',
  Pending = 'PENDING',
  Closed = 'CLOSED',
}

export type JobIssue = {
  id: string;
  number: number;
  name: string;
  slug: string;
  description: string;
  status: JobIssueStatus;
  dateTimeCreated: DateTime;
  actionSetId: string;
  links: JobLink[];
  items: JobIssueItem[];
  users: User[];
  closeOutData?: Pick<PlatformAction, 'id' | 'label' | 'description'>;
  job: Job;
  jobTask?: JobTask;
  refetch: () => Promise<any>;
};

export enum JobIssueItemType {
  Staff = 'STAFF',
  Subcontractor = 'SUBCONTRACTOR',
}

export enum JobIssueItemStatus {
  Blocking = 'BLOCKING',
  NotBlocking = 'NOT_BLOCKING',
  Resolved = 'RESOLVED',
}

export type JobIssueItem = {
  id: string;
  name: string;
  label: string;
  type: string;
  status: JobIssueItemStatus;
  description?: string;
  jobTaskForm?: JobTaskForm;
  jobTaskFormFieldLabel?: string;
  resolutions: JobIssueResolution[];
};

export type JobIssueResolution = {
  id: string;
  description: string;
};

export enum JobQuoteStatus {
  Awaited = 'AWAITED',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export type JobQuote = {
  id: string;
  number: number;
  source: number;
  reference: string;
  name: string;
  slug: string;
  status: JobQuoteStatus;
  lines: JobQuoteLine[];
  versions: JobQuoteVersion[];
  actionSetId?: string;
  total: number;
  totalLabor: number;
  totalMaterial: number;
  totalEquipment: number;
  totalSubcontractor: number;
  commercialEstimate: CommercialEstimate;
  refetch: () => Promise<any>;
};

export type JobQuoteLine = {
  id: string;
  type: CommercialType;
  description: string;
  unit?: CommercialUnit;
  unitValue?: number;
  commercialContractRate?: CommercialContractRate;
  quantity: number;
  total: number;
};

export type JobQuoteVersion = {
  id: string;
  dateTimeCreated: DateTime;
  number: number;
  file: PlatformObject;
};
