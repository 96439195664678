import { Report } from 'lib/shared';
import { useQuoteRendererContext } from '../../../../context';

const BodySummary = () => {
  const { estimate, theme } = useQuoteRendererContext();
  return (
    <Report.View>
      <Report.View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 8,
          marginBottom: 8,
          paddingTop: 8,
          borderTopWidth: 1,
          borderTopColor: theme.colors.border,
        }}
      >
        <Report.View style={{ width: '50%' }} />
        <Report.View style={{ width: '15%' }} />
        <Report.View style={{ width: '15%' }}>
          <Report.Text style={{ fontSize: 12, color: theme.colors.title }}>
            Sub Total
          </Report.Text>
        </Report.View>
        <Report.View style={{ width: '20%', textAlign: 'right' }}>
          <Report.Text style={{ fontSize: 12, color: theme.colors.title }}>
            £{estimate.totalEstimatedValue.toFixed(2)}
          </Report.Text>
        </Report.View>
      </Report.View>
      <Report.View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 8,
        }}
      >
        <Report.View style={{ width: '40%' }} />
        <Report.View style={{ width: '15%' }} />
        <Report.View style={{ width: '15%' }} />
        <Report.View style={{ width: '15%' }}>
          <Report.Text style={{ fontSize: 12, color: theme.colors.title }}>
            Tax
          </Report.Text>
        </Report.View>
        <Report.View style={{ width: '15%', textAlign: 'right' }}>
          <Report.Text style={{ fontSize: 12, color: theme.colors.title }}>
            £{(estimate.totalEstimatedValue * 0.2).toFixed(2)}
          </Report.Text>
        </Report.View>
      </Report.View>
      <Report.View style={{ display: 'flex', flexDirection: 'row' }}>
        <Report.View style={{ width: '40%' }} />
        <Report.View style={{ width: '15%' }} />
        <Report.View style={{ width: '15%' }} />
        <Report.View style={{ width: '15%' }}>
          <Report.Text
            style={{
              fontSize: 12,
              fontWeight: 700,
              color: theme.colors.title,
              paddingTop: 8,
              borderTopWidth: 1,
              borderTopColor: theme.colors.border,
            }}
          >
            Total
          </Report.Text>
        </Report.View>
        <Report.View style={{ width: '15%', textAlign: 'right' }}>
          <Report.Text
            style={{
              fontSize: 12,
              fontWeight: 700,
              color: theme.colors.title,
              paddingTop: 8,
              borderTopWidth: 1,
              borderTopColor: theme.colors.border,
            }}
          >
            £{(estimate.totalEstimatedValue * 1.2).toFixed(2)}
          </Report.Text>
        </Report.View>
      </Report.View>
    </Report.View>
  );
};

export default BodySummary;
