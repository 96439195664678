import { useCallback } from 'react';
import { useSchedulerContext } from '../../hooks';
import { SchedulerAction } from '../../types';
import { OnEventClick } from './types';

export const useSchedulerTimeline = () => {
  const { actions } = useSchedulerContext();

  const handleOnEventClick = useCallback<OnEventClick>(
    async ({ eventRecord }) => {
      actions.request({
        description: 'Highlight event',
        type: SchedulerAction.HighlightEvent,
        id: eventRecord.id as string,
      });
    },
    [],
  );

  return {
    onEventClick: handleOnEventClick,
  };
};
