import React from 'react';
import { CommandGroup, CommandItem } from '@/components/ui/command';
import { Result } from '../../types';
import ResultEvent from './components/ResultEvent';
import ResultJob from './components/ResultJob';

const SearchResults = ({
  results,
  onClose,
}: {
  results: Result[];
  onClose: () => void;
}) => {
  return (
    <CommandGroup heading={`Found ${results.length} results`}>
      {results.map((result) => {
        if (result.scope === 'events::event') {
          return <ResultEvent result={result} />;
        }
        if (result.scope === 'jobs::job') {
          return <ResultJob result={result} onClose={onClose} />;
        }
        return (
          <CommandItem value={result.id} className="p-4">
            <span>{result.id}</span>
          </CommandItem>
        );
      })}
    </CommandGroup>
  );
};

export default SearchResults;
