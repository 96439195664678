import { createContext, useContext } from 'react';
import { useTaskForm } from './hooks';

export const TaskFormContext = createContext(
  {} as ReturnType<typeof useTaskForm>['context'],
);

export const useTaskFormContext = () => {
  return useContext(TaskFormContext);
};
