import { gql } from '@apollo/client';

export const GET_JOB_QUERY = gql`
  query GetJob(
    $id: ID
    $client: String!
    $reference: String
    $variation: String
  ) {
    job(jobId: $id, clientSlug: $client, jobReference: $reference) {
      id
      scope
      status
      reference
      description
      dateTimeCreated
      isInstantiated
      actionSetId
      graph(jobVariationReference: $variation)
      client {
        id
        slug
        name
        logo {
          id
        }
      }
      category {
        id
        name
        code
        externalId
      }
      priority {
        id
        color
        reference
        responseName
        responseNameShort
        responseDuration
        completionName
        completionNameShort
        completionDuration
      }
      timeline {
        id
        dateTimeStart
        targets {
          id
          type
          typeDisplay
          dateTime
        }
      }
      site {
        id
        name
        reference
        isVerified
        location {
          id
          address {
            id
            lineOne
            lineTwo
            city
            region
            postcode
            country
            formatted
          }
          precise {
            id
            description
            geometry {
              id
              point {
                latitude
                longitude
              }
            }
          }
          pointsOfInterest {
            id
            name
            description
            geometry {
              id
              point {
                latitude
                longitude
              }
            }
          }
        }
      }
      commercialActivity {
        id
        status
        contract {
          id
          name
        }
      }
      tasks(jobVariationReference: $variation) {
        id
        status
        number
        name
        description
        forms {
          id
          # todo: look into this, I don't like having to fetch the latest submission here.
          latestSubmission {
            id
            template
            data
          }
        }
      }
      issues(jobVariationReference: $variation) {
        id
        status
        number
        name
        description
      }
      reports(jobVariationReference: $variation) {
        id
        status
        number
        name
      }
      variations {
        id
        reference
      }
      quotes {
        id
        status
        reference
        commercialEstimate {
          id
          name
        }
      }
      variation(jobVariationReference: $variation) {
        id
        reference
        isInstantiated
        actionSetId
        dateTimeCreated
        commercialActivity {
          id
          status
          estimates {
            id
            status
            statusDisplay
            number
            name
            description
            totalEstimatedValue
          }
        }
        quoteResponses {
          id
          description
          jobReference
          jobVariationReference
        }
      }
    }
  }
`;
