import React from 'react';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import { CardTitle } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { useFormStore } from '../../hooks';
import { getHorizontalPadding } from '../../utils';
import { FormContainerProps } from './types';

const FormContainer = ({ item }: FormContainerProps) => {
  const collapsed = useFormStore((state) => state.collapsed);
  const onCollapse = useFormStore((state) => state.onCollapse);
  const isCollapsed = collapsed.includes(item.id);
  return item.data.isRepeated ? null : (
    <div
      className="sticky top-0 z-10 border-b bg-background-secondary py-3"
      style={{ paddingLeft: getHorizontalPadding(item.depth) }}
    >
      <div className="flex items-center gap-x-3">
        <Button
          variant="outline"
          size="icon-xs"
          onClick={() => onCollapse(item.id)}
        >
          <ChevronRightIcon
            className={cn('h-4 w-4 transition-all', {
              'rotate-90': !isCollapsed,
            })}
          />
        </Button>
        <CardTitle>{item.data.value}</CardTitle>
      </div>
    </div>
  );
};

export default FormContainer;
