import React, { RefObject, forwardRef } from 'react';
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react-thin';
import SchedulerContent from './components/SchedulerContent';
import SchedulerControls from './components/SchedulerControls';
import ControlsDate from './components/SchedulerControls/components/ControlsDate';
import SchedulerPending from './components/SchedulerPending';
import SchedulerTimeline from './components/SchedulerTimeline';
import { SchedulerContext } from './context';
import { useScheduler } from './hooks';
import { SchedulerProps } from './types';

const SchedulerRoot = forwardRef<BryntumSchedulerPro, SchedulerProps>(
  ({ children, project, onUpdateSuccess, onUpdateError }, ref) => {
    const { context } = useScheduler({
      ref: ref as RefObject<BryntumSchedulerPro>,
      project,
      onUpdateSuccess,
      onUpdateError,
    });
    return (
      <SchedulerContext.Provider value={context}>
        {typeof children === 'function' ? children(context) : children}
      </SchedulerContext.Provider>
    );
  },
);

const Scheduler = {
  Root: SchedulerRoot,
  Content: SchedulerContent,
  Controls: SchedulerControls,
  ControlsDate,
  Pending: SchedulerPending,
  Timeline: SchedulerTimeline,
};

export default Scheduler;
