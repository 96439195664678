import React from 'react';
import { ArrowRight } from 'lucide-react';
import { TableRenderer } from '@/components/ui/table/table/Table';
import { cn } from '@/lib/utils';
import * as path from 'path';
import { Link, UnderConstruction } from 'lib/shared';
import { useClient } from '../../hooks';

const ClientMaintenance = () => {
  const client = useClient();

  return <UnderConstruction className="pb-4" />;

  return (
    <div>
      <div className="flex h-[52px] flex-shrink-0 items-center border-b px-4 py-2">
        <h1 className="text-xl font-bold">Maintenance</h1>
        <ArrowRight className="ml-auto h-4 w-4 text-muted-foreground" />
      </div>
      <Link to={client.paths.maintenance.series({ reference: 'INS001234' })}>
        Series
      </Link>
      <div className="p-4">
        <TableRenderer columns={[]} data={[]} />
      </div>
    </div>
  );
};

export default ClientMaintenance;
