import React from 'react';
import { MouseIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Duration } from 'luxon';
import { useAssignmentCrosshair } from './hooks';
import { AssignmentCrosshairProps } from './types';

const AssignmentCrosshair = ({
  active,
  value,
  min,
  max,
  onValueChange,
  ...props
}: AssignmentCrosshairProps) => {
  const { handleOnWheel, calculateWidth } = useAssignmentCrosshair({
    min,
    max,
    onValueChange,
  });
  return (
    <div
      className="group relative flex h-32 items-center justify-center rounded-md border-2 border-dashed"
      onWheel={handleOnWheel}
    >
      <div
        {...props}
        className={cn(
          'draggable z-[1] -mt-4 flex h-14 cursor-grab items-center justify-center rounded-md',
          {
            'border-2 border-dashed bg-secondary': active,
          },
        )}
        style={{
          width: `${calculateWidth(value)}%`,
          backgroundColor: active ? undefined : 'rgba(4, 204, 255, 0.5)',
          border: active ? undefined : '1px solid rgba(4, 204, 255, 0.75)',
        }}
      >
        <div className="select-none text-center">
          {active ? (
            <>
              <p className="mb-1 whitespace-nowrap text-xs">Drop to assign</p>
              <p className="whitespace-nowrap text-xs dark:text-white/75">
                {value.toHuman()}
              </p>
            </>
          ) : (
            <>
              <p className="mb-1 whitespace-nowrap text-xs">Drag to assign</p>
              <p className="whitespace-nowrap text-xs dark:text-white/75">
                {value.toHuman()}
              </p>
            </>
          )}
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-2 flex items-center justify-center gap-x-1 text-xs text-gray-400 transition-all group-hover:text-gray-600 dark:text-white/50 group-hover:dark:text-white/75">
        <MouseIcon className="h-4 w-4" />
        <p>Scroll to adjust duration</p>
      </div>
      <div className="absolute inset-x-0 top-1/2 z-[0] -mt-1.5 h-[1px] -translate-y-0.5 bg-border" />
      <div
        className="absolute z-[0] -mt-3.5 h-4 w-[1px] bg-border"
        style={{
          left: `${calculateWidth(Duration.fromObject({ minutes: 30 }))}%`,
        }}
      />
      <div
        className="absolute z-[0] -mt-3.5 h-4 w-[1px] bg-border"
        style={{
          left: `${calculateWidth(Duration.fromObject({ minutes: 60 }))}%`,
        }}
      />
      <div
        className="absolute z-[0] -mt-3.5 h-4 w-[1px] bg-border"
        style={{
          right: `${calculateWidth(Duration.fromObject({ minutes: 30 }))}%`,
        }}
      />
      <div
        className="absolute z-[0] -mt-3.5 h-4 w-[1px] bg-border"
        style={{
          right: `${calculateWidth(Duration.fromObject({ minutes: 60 }))}%`,
        }}
      />
    </div>
  );
};

export default AssignmentCrosshair;
