import { useEffect, useState } from 'react';
import {
  useEdgesState,
  useNodesInitialized,
  useNodesState,
  useReactFlow,
  useStore,
} from '@xyflow/react';
import { Job } from 'lib/types';
import { hierarchyLayout } from './algorithms';
import {
  compareElements,
  getSourceHandlePosition,
  getTargetHandlePosition,
} from './utils';

export const useGraph = (job: Job, highlight?: string[]) => {
  const { fitView } = useReactFlow();

  useAutoLayout();

  const [initialNodes] = useState(() =>
    job?.graph
      ? job.graph.nodes.map((node) => ({
          ...node,
          position: { x: 0, y: 0 },
        }))
      : [],
  );

  const [initialEdges] = useState(() => (job?.graph ? job.graph.edges : []));

  const [nodes, _setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, _setEdges, onEdgesChange] = useEdgesState(initialEdges);

  useEffect(() => {
    fitView({ nodes: highlight ? highlight.map((id) => ({ id })) : undefined });
  }, [nodes]);

  return {
    initialNodes,
    initialEdges,
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
  };
};

const defaultOptions = {
  direction: 'LR' as 'LR' | 'TB',
  spacing: [100, 100] as [number, number],
};

export const useAutoLayout = (options = defaultOptions) => {
  const [done, setDone] = useState(false);

  const { setNodes, setEdges } = useReactFlow();
  const nodesInitialized = useNodesInitialized();

  const elements = useStore(
    (state) => ({
      nodeMap: state.nodeLookup,
      edgeMap: state.edgeLookup,
    }),
    compareElements,
  );

  useEffect(() => {
    if (!nodesInitialized || elements.nodeMap.size === 0) {
      return;
    }

    (async () => {
      const nodes = [...elements.nodeMap.values()];
      const edges = [...elements.edgeMap.values()];

      const { nodes: nextNodes, edges: nextEdges } = await hierarchyLayout(
        nodes,
        edges,
        options,
      );

      for (const node of nextNodes) {
        node.style = { ...node.style, opacity: 1 };
        node.sourcePosition = getSourceHandlePosition(options.direction);
        node.targetPosition = getTargetHandlePosition(options.direction);
      }

      setNodes(nextNodes);
      setEdges(nextEdges);

      setDone(true);
    })();
  }, [nodesInitialized, elements, options, setNodes, setEdges]);

  return { layouted: done, ready: true };
};
