import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router';
import { gql, useMutation } from '@apollo/client';
import produce from 'immer';
import { AuthErrors, userNotConfigured } from 'lib/errors';
import { useEffectAsync } from '../../../../hooks';
import { useGlobalStore } from '../../../../state';
import { useApollo } from '../../../hooks';
import { LoginMutationData } from './types';

export const useOnMount = () => {
  const { httpClient } = useApollo();
  const navigate = useNavigate();

  const { instance } = useMsal();

  const apply = useGlobalStore((state) => state.apply);

  const [login, { loading: isLoading }] = useMutation<LoginMutationData, null>(
    gql`
      mutation LoginMutation {
        login {
          isValid
          user {
            id
            name
            initials
            email
          }
        }
      }
    `,
    { fetchPolicy: 'no-cache', errorPolicy: 'all', client: httpClient },
  );

  const [progress, setProgress] = useState(0);
  const [done, setDone] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) =>
        Math.min(prevProgress + Math.random() * 10, 100),
      );
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [retry, setRetry] = useState(0);

  useEffectAsync(async () => {
    const account = instance.getActiveAccount();

    if (!account) {
      if (retry > 10) {
        throw new Error('No account');
      }

      console.debug('No account, retrying...', retry);
      setTimeout(() => {
        setRetry((prev) => prev + 1);
      }, 500);

      return;
    }

    console.debug('Logging in...');
    const { data, errors } = await login();

    if (userNotConfigured(errors)) {
      setTimeout(() => {
        navigate('/login/error', {
          state: { error: AuthErrors.AUTH_USER_NOT_CONFIGURED },
        });
      }, 500);
    }

    if (data) {
      setProgress(100);
      setTimeout(() => {
        setDone(true);
      }, 250);

      apply(
        produce((state) => {
          state.user = data.login.user;
        }),
      );

      if (errors) {
        setTimeout(() => {
          navigate(data.login.isValid ? '/' : '/login/error');
        }, 500);
      } else {
        setTimeout(() => {
          navigate(data.login.isValid ? '/' : '/login/request');
        }, 500);
      }
    }
  }, [instance, retry]);

  return { isLoading, done, progress };
};
