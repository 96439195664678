import { createContext, useContext } from 'react';
import { ReportMode } from './types';

export class ReportCache {
  private cache: Record<string, any> = {};

  set(key: string, value: any) {
    this.cache[key] = value;
  }

  unset(key: string) {
    delete this.cache[key];
  }

  get(key: string) {
    return this.cache[key];
  }
}

export type ReportTheme = {
  colors: {
    border: string;
    signature: string;
    signatureBackground: string;
    annotation: string;
    title: string;
    subtitle: string;
  };
};

export const ReportContext = createContext({
  mode: undefined as unknown as ReportMode,
  cache: undefined as unknown as ReportCache,
  theme: {} as ReportTheme,
  images: [] as any[],
  documents: [] as any[],
  registerImage: (_: any) => {},
  registerDocument: (_: any) => {},
});

export const useReportContext = () => {
  return useContext(ReportContext);
};
