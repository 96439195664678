import React from 'react';
import { DateTime } from 'luxon';
import { Duty } from '../../../../../../../ClientJob/pages/JobTaskAssignment/config';

const EventTooltipRenderer = ({ eventRecord }: { eventRecord: Duty }) => {
  if (!eventRecord.timesheetDuty) return null;
  const { job, jobTask, dateTimeStart, dateTimeEnd } =
    eventRecord.timesheetDuty;
  return (
    <div className="w-[400px] rounded-lg border bg-background">
      <div className="border-b p-4">
        <p className="font-semibold" style={{ fontFamily: 'Roboto Mono' }}>
          {job.reference}
        </p>
        <p>{jobTask.name}</p>
      </div>
      <div className="flex flex-col gap-y-1 p-4">
        <div className="flex justify-between">
          <p className="text-xs font-semibold uppercase tracking-wide text-gray-400 dark:text-gray-500">
            Start
          </p>
          <p className="text-end text-xs font-semibold uppercase tracking-wide text-gray-400 dark:text-gray-500">
            End
          </p>
        </div>
        <div className="flex justify-between">
          <p className="font-medium">
            {dateTimeStart?.toLocaleString(DateTime.TIME_24_SIMPLE)}
          </p>
          {dateTimeStart && dateTimeEnd && (
            <div className="relative grow">
              <div className="absolute inset-x-2 top-1/2 h-[1px] -translate-y-1/2 rounded-full bg-gray-400" />
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-background px-2">
                <p className="text-sm">
                  {dateTimeEnd.diff(dateTimeStart, 'minutes').minutes} mins
                </p>
              </div>
            </div>
          )}
          <p className="font-medium">
            {dateTimeEnd?.toLocaleString(DateTime.TIME_24_SIMPLE)}
          </p>
        </div>
        <div className="flex justify-between">
          <p className="text-sm">
            {dateTimeStart?.toLocaleString(DateTime.DATE_MED)}
          </p>
          <p className="text-sm">
            {dateTimeEnd?.toLocaleString(DateTime.DATE_MED)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EventTooltipRenderer;
