import React from 'react';
import {
  NavigationMenu,
  NavigationMenuIndicator,
  NavigationMenuList,
} from '@/components/ui/navigation-menu';
import { ThemeToggle } from '@/components/ui/theme';
import { SearchDialog } from 'lib/shared';
import MenuNotifications from './components/MenuNotifications';
import MenuProfile from './components/MenuProfile';
import MenuTodos from './components/MenuTodos';

const TopbarMenu = () => {
  return (
    <div className="flex items-center gap-2">
      <SearchDialog />
      <ThemeToggle />
      <NavigationMenu viewportWrapperClassname="right-0">
        <NavigationMenuList className="gap-x-1">
          <MenuNotifications />
          <MenuTodos />
          <MenuProfile />
          <NavigationMenuIndicator />
        </NavigationMenuList>
      </NavigationMenu>
    </div>
  );
};

export default TopbarMenu;
