import React, { useState } from 'react';
import ButtonTabs from '@/components/ui/button-tabs';
import { useLocalStorage } from '@uidotdev/usehooks';
import JobsBoard from './components/JobsBoard';
import JobsTable from './components/JobsTable';
import JobsTimeline from './components/JobsTimeline';

enum ViewMode {
  Timeline = 'timeline',
  Table = 'table',
  Board = 'board',
}

const ClientJobs = () => {
  const [filter, setFilter] = useState('');
  const [mode, setMode] = useLocalStorage<ViewMode>(
    'client-jobs-view-mode',
    ViewMode.Board,
  );
  return (
    <>
      <div className="grid h-[52px] flex-shrink-0 grid-cols-12 items-center border-b px-4 py-2">
        <h1 className="col-span-1 text-xl font-bold">Jobs</h1>
        <div className="col-span-4"></div>
        <div className="col-span-7 flex justify-end">
          <ButtonTabs
            tabs={[
              {
                label: 'Timeline',
                value: ViewMode.Timeline,
              },
              {
                label: 'Board',
                value: ViewMode.Board,
              },
              {
                label: 'Table',
                value: ViewMode.Table,
              },
            ]}
            value={mode}
            onValueChange={setMode}
          />
        </div>
      </div>
      {mode === ViewMode.Timeline && <JobsTimeline />}
      {mode === ViewMode.Board && <JobsBoard filter={filter} />}
      {mode === ViewMode.Table && <JobsTable />}
    </>
  );
};

export default ClientJobs;
