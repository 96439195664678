import React from 'react';
import { Slider } from '@/components/ui/slider';
import { Duration } from 'luxon';
import { DataType } from '../../../../../../external/FormTemplateEditor';
import { FormFieldComponentProps } from '../../types';

const FormFieldDuration = ({
  readOnly,
  item,
  value,
  onChange,
}: FormFieldComponentProps) => {
  const duration = value?.value
    ? Duration.fromMillis(value.value).shiftTo('hours', 'minutes')
    : Duration.fromObject({ hours: 0, minutes: 0 });

  if (readOnly) {
    return (
      <div className="flex h-8 items-center rounded-md border bg-secondary px-[10px]">
        <p className="text-xs font-semibold">{duration.toHuman()}</p>
      </div>
    );
  }

  const minutes = value?.value ? duration.shiftTo('minutes').as('minutes') : 0;

  return (
    <div className="flex flex-col gap-y-4 rounded border p-4">
      <Slider
        value={[minutes]}
        max={300}
        step={5}
        onValueChange={(value) => {
          onChange(item, {
            dataType: DataType.DURATION,
            value: Duration.fromObject({ minutes: value[0] }).toMillis(),
          });
        }}
      />
      <p className="font-mono text-sm">{duration.toHuman()}</p>
    </div>
  );
};

export default FormFieldDuration;
