import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { UserQueryData, UserQueryVariables } from './types';

export const useStaffMember = (id: string | undefined) => {
  const [open, setOpen] = useState(false);

  const { data, loading, refetch } = useQuery<
    UserQueryData,
    UserQueryVariables
  >(
    gql`
      query GetUser($id: ID!) {
        user(userId: $id) {
          id
          name
          email
          rates {
            id
            description
            base
            unit
            unitDisplay
            unitCost
            dateStart
            dateEnd
          }
          isConfigured
          driverKeyFobId
          externalId
          lineManager {
            id
            name
            email
          }
          jobTitle
        }
      }
    `,
    {
      skip: !open,
      variables: { id: id as string },
      fetchPolicy: 'no-cache',
    },
  );

  if (!data) {
    return {
      context: {
        sheet: {
          open,
          onOpenChange: setOpen,
          onClose: () => setOpen(false),
        },
        loading,
        user: null,
      },
    };
  }

  data.user.refetch = refetch;
  return {
    context: {
      sheet: {
        open,
        onOpenChange: setOpen,
        onClose: () => setOpen(false),
      },
      loading,
      user: data.user,
    },
  };
};
