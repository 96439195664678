import React from 'react';
import { TableRenderer } from '@/components/ui/table/table/Table';
import CategoryEditor from './components/CategoryEditor';
import { useDeliveryCategories } from './hooks';

const DeliveryCategories = () => {
  const { categories, refetch } = useDeliveryCategories();
  return (
    <div className="px-4 pt-4">
      <div>
        <h3 className="mb-1">Categories</h3>
        <p className="text-xs dark:text-white/75">
          Categories used to group related work types.
        </p>
      </div>
      <TableRenderer
        filter="code"
        filterPlaceholder="Search categories..."
        columns={[
          {
            accessorKey: 'name',
            header: 'Name',
          },
          {
            accessorKey: 'code',
            header: 'Code',
          },
          {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
              return (
                <div className="flex justify-end">
                  <CategoryEditor
                    category={row.original}
                    onSuccess={async () => {
                      await refetch();
                    }}
                  />
                </div>
              );
            },
          },
        ]}
        data={categories}
        topRight={({ columnsSelector }) => (
          <div className="ml-auto space-x-2">
            <CategoryEditor
              category={undefined}
              onSuccess={async () => {
                await refetch();
              }}
            />
            {columnsSelector}
          </div>
        )}
      />
    </div>
  );
};

export default DeliveryCategories;
