import React from 'react';
import ButtonTabs from '@/components/ui/button-tabs';
import { ViewMode } from '../../../CommercialEstimate/types';
import { useCommercialEstimatesContext } from '../../context';

const EstimatesMode = () => {
  const { mode, state } = useCommercialEstimatesContext();
  return (
    <ButtonTabs
      className="h-7 bg-background"
      buttonClassName="h-7"
      tabs={[
        {
          disabled: !state.selected,
          label: 'Quote preview',
          value: ViewMode.Split,
        },
        {
          disabled: !state.selected,
          label: 'Job view',
          value: ViewMode.TwoColumn,
        },
      ]}
      value={mode.value}
      onValueChange={mode.set}
    />
  );
};

export default EstimatesMode;
