import { Button } from '@/components/ui/button';
import {
  input,
  textarea,
} from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { z } from 'zod';
import { qualificationFields } from '../../../../fields';
import { useChildrenCreator } from './hooks';

const ChildrenCreator = () => {
  const { state, handlers } = useChildrenCreator();
  return (
    <Sheet open={state.open} onOpenChange={state.onOpenChange}>
      <SheetTrigger asChild>
        <Button size="sm" variant="outline">
          Create nested
        </Button>
      </SheetTrigger>
      <SheetContent className="flex w-[700px] max-w-[700px] flex-col gap-0 gap-y-4 sm:max-w-[700px]">
        <SheetHeader className="mb-4">
          <SheetTitle>Create nested</SheetTitle>
          <SheetDescription>Create a new nested qualification</SheetDescription>
        </SheetHeader>
        <FormRenderer
          fields={[
            input({
              label: 'Name',
              name: 'name',
              schema: z.string().min(1),
            }),
            textarea({
              label: 'Description',
              name: 'description',
              schema: z.string().min(1),
            }),
            qualificationFields(undefined),
          ]}
          onSubmit={handlers.onCreate}
        >
          {({ onSubmit }) => (
            <div>
              <Button size="sm" onClick={onSubmit}>
                Create
              </Button>
            </div>
          )}
        </FormRenderer>
      </SheetContent>
    </Sheet>
  );
};

export default ChildrenCreator;
