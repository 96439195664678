import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import '@azure/msal-browser';
import * as msal from '@azure/msal-browser';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import produce from 'immer';
import { createTokenString } from 'lib/utils';
import { useGlobalStore } from '../state';

export const msalApplication = new msal.PublicClientApplication({
  auth: {
    clientId: import.meta.env.PUBLIC_AZURE_CLIENT_ID,
    redirectUri: import.meta.env.PUBLIC_AZURE_REDIRECT_URL,
    authority: import.meta.env.PUBLIC_AZURE_AZURE_AUTHORITY,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            // case msal.LogLevel.Verbose:
            // case msal.LogLevel.Trace:
            console.debug(message);
            return;
          case msal.LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.log(message);
            return;
        }
      },
    },
  },
});

const cache = new InMemoryCache();

await persistCache({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
});

const httpLink = createHttpLink({ uri: import.meta.env.PUBLIC_API_HOST });

const authenticationLink = setContext(async (_, context) => {
  const account = msalApplication.getActiveAccount();

  if (!account) {
    window.location.href = '/login';
    return;
  }

  const request = {
    account,
    scopes: [import.meta.env.PUBLIC_AZURE_SCOPE, 'User.Read'],
  };

  try {
    const { accessToken } = await msalApplication.acquireTokenSilent(request);
    return {
      ...context,
      headers: {
        ...context?.headers,
        authorization: `Bearer ${createTokenString('azure', accessToken)}}`,
      },
    };
  } catch (error) {
    console.error(error);
    window.location.href = '/login';
  }
});

export const httpClient = new ApolloClient({
  cache,
  link: ApolloLink.from([authenticationLink, httpLink]),
  defaultOptions: { watchQuery: { fetchPolicy: 'cache-and-network' } },
});

export const useApollo = () => {
  return { httpClient };
};

export const useLogout = () => {
  const apply = useGlobalStore((state) => state.apply);
  return async () => {
    await msalApplication.logoutRedirect();
    apply(
      produce((state) => {
        state.user = null;
      }),
    );
  };
};
