import {
  GetShouldIncludeSelf,
  GetShouldItemRender,
  Item,
  ItemType,
  Items,
  Pages,
} from './types';

export const flatten = (
  item: Item,
  items: Items,
  getShouldItemRender: GetShouldItemRender,
  getShouldIncludeSelf: GetShouldIncludeSelf,
): Item[] => {
  /*
   * Recursively flatten the tree structure into an array of items.
   * This is used to generate the page map. The page map is used to
   * render the page tree.
   */

  if (getShouldIncludeSelf(item)) {
    if (!item.children) {
      return [item];
    }

    return [
      item,
      ...item.children
        .filter((childId) => getShouldItemRender(items[childId], item))
        .flatMap((childId) =>
          flatten(
            items[childId],
            items,
            getShouldItemRender,
            getShouldIncludeSelf,
          ),
        ),
    ];
  }

  return item.children
    ? item.children
        .filter((childId) => getShouldItemRender(items[childId], item))
        .flatMap((childId) =>
          flatten(
            items[childId],
            items,
            getShouldItemRender,
            getShouldIncludeSelf,
          ),
        )
    : [];
};

export const getPageMap = (
  pageOrder: string[],
  items: Items,
  getShouldItemRender: GetShouldItemRender,
  getShouldIncludeSelf: GetShouldIncludeSelf = () => true,
) => {
  return pageOrder.reduce((ret, pageId) => {
    const page = items[pageId];
    return {
      ...ret,
      [pageId]: {
        ...page,
        items: flatten(
          page,
          items,
          getShouldItemRender,
          // Override this method to always return
          // false as the page should never be
          // included in the items array.
          (item: Item) => {
            return item.type === ItemType.PAGE
              ? false
              : getShouldIncludeSelf(item);
          },
        ),
      },
    };
  }, {}) as Pages;
};
