import { useState } from 'react';
import { PlaceholderPosition } from './types';

export const getPlaceholderPosition = (
  draggedDOM: Element,
  items: any[],
  newIndex: number,
) => {
  const { clientWidth, clientHeight } = draggedDOM;
  const clientX = parseFloat(
    window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft,
  );
  const clientY =
    parseFloat(
      window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop,
    ) +
    items.slice(0, newIndex).reduce((total, current) => {
      const style = window.getComputedStyle(current);
      const marginBottom = parseFloat(style.marginBottom);
      return total + current.clientHeight + marginBottom;
    }, 0);

  return {
    width: clientWidth,
    height: clientHeight,
    top: clientY,
    left: clientX,
  };
};

export const usePlaceholder = () => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [placeholderPosition, _setPlaceholderPosition] =
    useState<PlaceholderPosition>({});

  const setPlaceholderPosition = (
    draggedDOM: Element,
    items: any[],
    newIndex: number,
  ) => {
    return _setPlaceholderPosition(
      getPlaceholderPosition(draggedDOM, items, newIndex),
    );
  };

  return { placeholderPosition, setPlaceholderPosition };
};
