import React, { forwardRef } from 'react';
import { Button, ButtonProps } from '@/components/ui/button';
import { cn } from '@/lib/utils';

const BlockButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, ...props }: ButtonProps, ref) => {
    return (
      <Button
        ref={ref}
        variant="ghost"
        size="sm"
        className={cn(
          'relative h-full min-w-0 rounded-none px-3 transition-none',
          className,
        )}
        {...props}
      >
        {children}
      </Button>
    );
  },
);

export default BlockButton;
