import React from 'react';
import { useSyncedStore } from '@syncedstore/react';
import { Badge } from '@/components/ui/badge';
import { JobTask } from 'types/jobs';
import { PageLoader, Tabs } from 'lib/shared';
import { NoteCreate } from '../../../../external/Notes';
import { useNotes } from '../../../../external/Notes/hooks';
import { NotesStore } from '../../../../external/Notes/types';
import { useJobContext } from '../../context';
import TaskActions from './components/TaskActions';
import TaskAssignments from './components/TaskAssignments';
import TaskEvents from './components/TaskEvents';
import TaskFormEditor from './components/TaskFormEditor';
import TaskForms from './components/TaskForms';
import TaskInfo from './components/TaskInfo';
import TaskNotes from './components/TaskNotes';
import { JobTaskContext, useJobTaskContext } from './context';
import { useJobTask } from './hooks';

const JobTaskOverview = () => {
  const { context } = useJobTask();

  if (context.jobTask?.deleted) {
    return (
      <JobTaskContext.Provider value={context}>
        <div className="p-4">
          <h5>Job Task Deleted</h5>
          <p>This job task has been deleted and is no longer available.</p>
        </div>
      </JobTaskContext.Provider>
    );
  }

  if (!context.jobTask) {
    return <PageLoader className="p-4">Loading task...</PageLoader>;
  }

  return (
    <JobTaskContext.Provider value={context}>
      <JobTaskOverviewRenderer />
    </JobTaskContext.Provider>
  );
};

const NotesCount = ({
  scope,
  store,
}: {
  scope?: string;
  store: NotesStore;
}) => {
  const state = useSyncedStore(store);
  const notes = Object.values(state.notes).filter(
    (note) => !note.deleted && (!scope || note.scope === scope),
  );
  return <>{notes.length}</>;
};

const JobTaskOverviewRenderer = () => {
  const { state } = useJobContext();

  if (state.small) {
    return (
      <div
        className="min-h-0 w-full grow gap-4 bg-secondary p-4"
        style={{
          display: 'grid',
          gridAutoColumns: '1fr',
          gridAutoRows: '1fr',
          gridTemplateAreas: `
          "activity activity"
          "activity activity"
          "activity activity"
          "forms    forms   "
          "forms    forms   "
        `,
        }}
      >
        <div style={{ gridArea: 'activity' }}>
          <div className="flex h-full flex-col gap-4 overflow-hidden rounded-lg border bg-background">
            <div className="flex h-full flex-col">
              <TaskInfo />
              <TaskAssignments />
            </div>
          </div>
        </div>
        <div style={{ gridArea: 'forms' }}>
          <JobTaskTabs />
        </div>
      </div>
    );
  }

  return (
    <div
      className="min-h-0 w-full grow gap-4 bg-secondary p-4"
      style={{
        display: 'grid',
        gridAutoColumns: '1fr',
        gridAutoRows: '1fr',
        gridTemplateAreas: `
          "activity activity events"
          "activity activity events"
          "activity activity events"
          "forms    forms    actions"
          "forms    forms    actions"
        `,
      }}
    >
      <div style={{ gridArea: 'activity' }}>
        <div className="flex h-full flex-col gap-4 overflow-hidden rounded-lg border bg-background">
          <div className="flex h-full flex-col">
            <TaskInfo />
            <TaskAssignments />
          </div>
        </div>
      </div>
      <div style={{ gridArea: 'forms' }}>
        <JobTaskTabs />
      </div>
      <div style={{ gridArea: 'events' }}>
        <TaskEvents />
      </div>
      <div style={{ gridArea: 'actions' }}>
        <TaskActions />
      </div>
    </div>
  );
};

const JobTaskTabs = () => {
  const { state } = useJobContext();
  const { task } = useJobTaskContext();

  const { store: notesStore } = useNotes(
    'jobs::job',
    task ? `${task.job.id}::notes` : undefined,
  );

  if (!task) {
    return null;
  }

  return (
    <Tabs
      tabs={[
        {
          name: (
            <div className="flex items-center gap-x-2">
              <span style={{ lineHeight: '24px' }}>Forms</span>
              <Badge
                variant="outline"
                className="h-4 w-4 justify-center rounded p-0 text-center group-data-[state=inactive]:border-white/10"
              >
                <p className="text-[10px]">{task.forms.length}</p>
              </Badge>
            </div>
          ),
          header: state.readOnly ? null : <TaskFormEditor />,
          render: () => <TaskForms />,
        },
        {
          name: (
            <div className="flex items-center gap-x-2">
              <span style={{ lineHeight: '24px' }}>Notes</span>
              <Badge
                variant="outline"
                className="h-4 w-4 justify-center rounded p-0 text-center group-data-[state=inactive]:border-white/10"
              >
                <p className="text-[10px]">
                  {notesStore && (
                    <NotesCount
                      scope={`jobs::job-task::${task.id}`}
                      store={notesStore}
                    />
                  )}
                </p>
              </Badge>
            </div>
          ),
          header:
            !state.readOnly && notesStore ? (
              <NoteCreate
                scope={`jobs::job-task::${task.id}`}
                store={notesStore}
              />
            ) : null,
          render: () => (notesStore ? <TaskNotes /> : null),
        },
      ]}
    />
  );
};

export default JobTaskOverview;
