import { useNavigate } from 'react-router';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'sonner';
import {
  CreateTrainingEventData,
  CreateTrainingEventVariables,
  TrainingEventsQueryData,
} from './types';

export const useTrainingEvents = () => {
  const navigate = useNavigate();

  const { data } = useQuery<TrainingEventsQueryData>(gql`
    query GetTrainingEvents {
      trainingEvents {
        id
      }
    }
  `);

  const [create] = useMutation<
    CreateTrainingEventData,
    CreateTrainingEventVariables
  >(
    gql`
      mutation CreateTrainingEvent($name: String!) {
        createTrainingEvent(name: $name) {
          trainingEvent {
            id
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async (values: any) => {
    const { data, errors } = await create({});

    if (data) {
      const { id } = data.createTrainingEvent.trainingEvent;
      navigate(`/human-resources/training/events/${id}`);
      toast.success('Event created successfully');
    }

    if (errors) {
      toast.error('Failed to create event');
    }
  };

  return {
    events: data?.trainingEvents || [],
    handlers: {
      onCreate: handleOnCreate,
    },
  };
};
