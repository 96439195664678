import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { useJobContext } from '../../../../context';
import {
  CreateJobCommercialActivityMutationData,
  CreateJobCommercialActivityMutationVariables,
} from './types';

export const useCommercialActivityCreate = () => {
  const { job } = useJobContext();

  const [open, setOpen] = useState(false);

  const [create] = useMutation<
    CreateJobCommercialActivityMutationData,
    CreateJobCommercialActivityMutationVariables
  >(
    gql`
      mutation CreateJobCommercialActivity($jobId: ID!) {
        createJobCommercialActivity(jobId: $jobId) {
          job {
            commercialActivity {
              id
            }
          }
        }
      }
    `,
    { errorPolicy: 'all' },
  );

  const handleOnCreate = async () => {
    if (job.partial) return;

    const { data, errors } = await create({ variables: { jobId: job.id } });

    if (data) {
      await job.refetch();
      setOpen(false);
      toast.success('Commercial activity created');
    }

    if (errors) {
      toast.error('Failed to create commercial activity');
    }
  };

  return {
    dialog: {
      open,
      onOpenChange: setOpen,
    },
    onCreate: handleOnCreate,
  };
};
