import React from 'react';
import { Button } from '@/components/ui/button';
import { useStaffMemberContext } from '../../context';
import { useMemberDangerZone } from './hooks';

const MemberDangerZone = () => {
  const { user } = useStaffMemberContext();
  const { handlers } = useMemberDangerZone();
  return (
    <div className="rounded-lg border p-4">
      <div className="mb-4">
        <p className="mb-1 text-sm font-medium">Danger Zone</p>
        <p className="text-sm text-gray-400 dark:text-gray-400">
          Used to calculate the cost of a user's time. You can create new
          resource rates for this user.
        </p>
      </div>
      <div>
        {user!.isConfigured ? (
          <Button
            className="w-full"
            size="sm"
            variant="destructive"
            confirm="Are you sure?"
            confirmHelp="This will not delete the user. It will make them inactive, restriciting access."
            onClick={handlers.onDelete}
          >
            Archive
          </Button>
        ) : (
          <Button
            className="w-full"
            size="sm"
            variant="destructive"
            confirm="Are you sure?"
            confirmHelp="This will restore the user and grant them access to the system."
            onClick={handlers.onRestore}
          >
            Restore
          </Button>
        )}
      </div>
    </div>
  );
};

export default MemberDangerZone;
