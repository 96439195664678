import { createContext, useContext } from 'react';
import { useUserProfile } from './hooks';

export const UserProfileContext = createContext(
  {} as ReturnType<typeof useUserProfile>['context'],
);

export const useUserProfileContext = () => {
  return useContext(UserProfileContext);
};
