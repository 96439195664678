import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PlatformActionSet } from 'lib/types';
import { PlatformError, platformClient } from '../../app/platform';
import { ActionsProps } from './types';

export const useActions = ({
  actionSetId,
  scope,
  context,
  queryKey,
  disabled,
  onSuccess,
}: ActionsProps) => {
  const { data, refetch } = useQuery({
    queryKey: ['actionSet', actionSetId, ...queryKey],
    queryFn: platformClient.getActionSet(actionSetId, context, scope),
    enabled: !!actionSetId,
  });

  useEffect(() => {
    refetch();
  }, [context]);

  return {
    context: {
      actionSetId,
      actionSet: data?.actions ? (data as PlatformActionSet) : undefined,
      // todo: look into this typing
      error: (data as any)?.message
        ? (data as unknown as PlatformError)
        : undefined,
      scope,
      context,
      disabled,
      onSuccess,
    },
  };
};
