import React, { useState } from 'react';
import { MapPin } from 'lucide-react';
import Map, { LngLat, Marker } from 'react-map-gl';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select';
import { useTheme } from '@/components/ui/theme';
import { cn } from '@/lib/utils';
import { useLocationPickerContext } from '../../hooks';
import { ResultType } from '../../types';

const MapStyle = ({
  id,
  name,
  src,
  description,
  option = false,
}: {
  id: string;
  name: string;
  src: string;
  description: string;
  option?: boolean;
}) => {
  const inner = (
    <div className="flex gap-x-4 text-sm">
      <img className="size-12 rounded-md object-cover" alt="" src={src} />
      <div className="mt-1">
        <p className="font-semibold">{name}</p>
        <p className="text-xs leading-5 text-gray-400">{description}</p>
      </div>
    </div>
  );

  if (!option) {
    return inner;
  }

  return <SelectItem value={id}>{inner}</SelectItem>;
};

const PickerMap = () => {
  const {
    placingMarker,
    marked,
    selected,
    focused,
    mapRef,
    setSearch,
    setFocused,
    setMapReady,
    setCentered,
    setMarkerPosition,
    handleOnSelect,
    estimation: { results },
  } = useLocationPickerContext();

  const [style, setStyle] = useState(
    'mapbox://styles/mapbox/satellite-streets-v12',
  );

  const satellite = (option = false) => (
    <MapStyle
      id="mapbox://styles/mapbox/satellite-streets-v12"
      src="/mapbox-satelite-streets.png"
      name="Satellite Streets"
      description="Imagery with street names"
      option={option}
    />
  );

  const navigation = (option = false) => (
    <MapStyle
      id="mapbox://styles/mapbox/navigation-night-v1"
      src="/mapbox-navigation-dark.png"
      name="Navigation"
      description="Focus on road and path details"
      option={option}
    />
  );

  return (
    <Map
      ref={mapRef}
      style={{ height: '100%', width: '100%' }}
      initialViewState={{
        zoom: 18,
        pitch: 45,
        latitude: 51.5074,
        longitude: 0.1278,
      }}
      scrollZoom={placingMarker ? { around: 'center' } : true}
      mapboxAccessToken="pk.eyJ1IjoiZW5lcmd5ZW5hYmxlcyIsImEiOiJjbGpia2NqZ2IxcTcxM2pxeGxvNG5ud2lpIn0.gMXRc_xES-6AlQx1w8MuXw"
      attributionControl={false}
      mapStyle={style}
      onLoad={(event) => {
        event.target.resize();
        setTimeout(async () => {
          setMapReady(true);
        }, 500);
      }}
      onMoveEnd={(event) => {
        if (placingMarker) {
          const center = event.target.getCenter();
          setMarkerPosition([center.lng, center.lat]);
          setSearch(`${center.lat}, ${center.lng}`);
        } else {
          if (mapRef.current && selected) {
            const selectedLngLat = {
              lng: selected.location.lng,
              lat: selected.location.lat,
            } as LngLat;
            const distance = event.target
              .getCenter()
              .distanceTo(selectedLngLat);
            setCentered(distance < 50);
          }
        }
      }}
    >
      <div className="absolute bottom-3 left-3">
        <Select
          value={style}
          onValueChange={(value) => {
            setStyle(value);
          }}
        >
          <SelectTrigger className="h-auto gap-x-4 bg-background text-start">
            {style === 'mapbox://styles/mapbox/satellite-streets-v12' &&
              satellite()}
            {style === 'mapbox://styles/mapbox/navigation-night-v1' &&
              navigation()}
          </SelectTrigger>
          <SelectContent side="bottom">
            <SelectGroup>
              {satellite(true)}
              {navigation(true)}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      {!placingMarker &&
        results
          .filter((result) => result.type === 'PLACE')
          .map((result, index) => {
            const isSelected = selected?.id === result.id;
            const isFocused = focused?.id === result.id;
            return (
              <Marker
                key={`${result.type}::${result.id}`}
                longitude={result.location.lng}
                latitude={result.location.lat}
                onClick={() => {
                  handleOnSelect(result);
                  setFocused(result);
                }}
              >
                <div
                  className={cn(
                    'relative cursor-pointer rounded-full border-2 border-incoming-foreground/50 bg-incoming-foreground/25 p-0 transition-[padding] hover:p-2 hover:opacity-100',
                    {
                      'opacity-25': focused && !isFocused && !isSelected,
                      'p-4': focused && isFocused,
                    },
                  )}
                >
                  <div className="rounded-full bg-incoming-foreground/25 p-2">
                    <div className="relative h-4 w-4 rounded-full bg-incoming-foreground">
                      <span
                        className={cn(
                          'absolute left-1/2 top-1/2 -translate-y-1/2',
                          {
                            '-translate-x-[55%]': index === 1,
                            '-translate-x-[45%]': index !== 1,
                          },
                        )}
                      >
                        {index + 1}
                      </span>
                    </div>
                  </div>
                  {result.type === ResultType.Place && (
                    <div className="pointer-events-none absolute left-full top-1/2 w-[300px] -translate-y-1/2 pl-3">
                      <p className="text-sm font-semibold">
                        {result.data.displayName?.text}
                      </p>
                      <p className="text-xs font-medium leading-5 text-gray-500 dark:text-gray-400">
                        {result.data.formattedAddress}
                      </p>
                    </div>
                  )}
                </div>
              </Marker>
            );
          })}
      {!placingMarker && marked && (
        <Marker
          longitude={marked.location.lng}
          latitude={marked.location.lat}
          onClick={() => {
            handleOnSelect(marked);
            setFocused(marked);
          }}
        >
          <div
            className={cn(
              'relative cursor-pointer rounded-full border-2 border-incoming-foreground/50 bg-incoming-foreground/25 p-8 transition-[padding]',
            )}
          >
            <div className="rounded-full bg-incoming-foreground/25 p-2">
              <div className="relative h-4 w-4 rounded-full bg-incoming-foreground">
                <span
                  className={cn('absolute left-1/2 top-1/2 -translate-y-1/2')}
                ></span>
              </div>
            </div>
            <div className="pointer-events-none absolute left-full top-1/2 w-[300px] -translate-y-1/2 pl-3">
              <p className="text-sm font-semibold">Marked location</p>
              <p className="text-xs font-medium leading-5 text-gray-500 dark:text-gray-400">
                {marked.location.lat.toFixed(6)},{' '}
                {marked.location.lng.toFixed(6)}
              </p>
            </div>
          </div>
        </Marker>
      )}
      {placingMarker && (
        <div className="pointer-events-none absolute inset-0 z-[50]">
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <MapPin className="h-8 w-8" />
          </div>
        </div>
      )}
    </Map>
  );
};

export default PickerMap;
