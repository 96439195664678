import { useQuery } from '@tanstack/react-query';
import { platformClient } from '../../../app/platform';

const usePlatformObject = (id: string | undefined) => {
  const { data, isLoading } = useQuery({
    queryKey: ['platform', 'object', id],
    queryFn: platformClient.getObject(id as string),
    enabled: !!id,
  });
  return {
    isLoading,
    object: data,
  };
};

export default usePlatformObject;
