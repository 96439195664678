import React from 'react';
import ReactPDF, { G, Path, Svg } from '@react-pdf/renderer';
import ReportComponent from '../ReportComponent';

const ReportSvg = (
  props: ReactPDF.SVGProps | React.HTMLAttributes<SVGElement>,
) => {
  return (
    <ReportComponent
      editComponent="svg"
      viewComponent={(props: ReactPDF.SVGProps) => <Svg {...props} />}
      props={props}
    />
  );
};

ReportSvg.G = (props: ReactPDF.GProps | React.HTMLAttributes<SVGGElement>) => {
  return (
    <ReportComponent
      editComponent="g"
      viewComponent={(props: ReactPDF.GProps) => <G {...props} />}
      props={props}
    />
  );
};
(ReportSvg.G as React.FC).displayName = 'ReportG';

ReportSvg.Path = (
  props: ReactPDF.PathProps | React.HTMLAttributes<SVGPathElement>,
) => {
  return (
    <ReportComponent
      editComponent="path"
      viewComponent={(props: ReactPDF.PathProps) => <Path {...props} />}
      props={props}
    />
  );
};
(ReportSvg.Path as React.FC).displayName = 'ReportPath';

ReportSvg.Rect = (
  props: ReactPDF.RectProps | React.HTMLAttributes<SVGRectElement>,
) => {
  return (
    <ReportComponent
      editComponent="rect"
      viewComponent={(props: ReactPDF.RectProps) => (
        <ReactPDF.Rect {...props} />
      )}
      props={props}
    />
  );
};
(ReportSvg.Rect as React.FC).displayName = 'ReportRect';

export default ReportSvg;
