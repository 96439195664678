import { JobReportVersion } from 'lib/types';

export enum JobReportVersionMode {
  Live = 'LIVE',
  PDF = 'PDF',
}

export type JobReportVersionQueryVariables = {
  jobReportVersionId: string;
};

export type JobReportVersionQueryData = {
  jobReportVersion: JobReportVersion;
};
