import { useSyncedStore } from '@syncedstore/react';
import {
  CONDITIONS,
  Choice,
  Condition,
  DataType,
} from 'external/FormTemplateEditor';
import { BlockData, Item, LogicData } from 'external/types';
import { useFormTemplateContext } from '../../context';
import { useDeleteItem } from '../../hooks';

export const useBlockLogic = (item: Item<BlockData>) => {
  const { store, getLogicFor } = useFormTemplateContext();
  const { choiceSets, items } = useSyncedStore(store);

  const logic = getLogicFor(item);
  const constants = (items[logic.id].data as LogicData).constants;
  const condition =
    CONDITIONS[item.data.dataType]?.find(
      ({ id }) => id === logic.data.conditionId,
    ) || CONDITIONS[item.data.dataType]?.[0];

  const handleConditionOnChange = (nextCondition: Condition) => {
    (items[logic.id].data as LogicData).conditionId = nextCondition.id;
    (items[logic.id].data as LogicData).expression = nextCondition.expression;
  };

  const handleConstantOnChange = (name: string, value: any) => {
    return () => {
      const data = items[logic.id].data as LogicData;

      if (!data.constants) {
        data.constants = {
          [name]: {
            dataType: item.data?.dataType,
            value,
          },
        };
        return;
      }

      data.constants[name] = {
        dataType: item.data?.dataType,
        value,
      };
    };
  };

  const handleInputOnChange = (name: string) => {
    return (nextValue: string) => {
      handleConstantOnChange(name, nextValue)();
    };
  };

  const handleChoiceOnClick = (name: string, choice: Choice) => {
    return () => {
      const data = items[logic.id].data as LogicData;

      console.debug(logic.id, JSON.stringify(data, null, 2));

      if (!data.constants) {
        data.constants = {
          [name]: {
            dataType: DataType.CHOICE_SELECTION,
            value: [choice.id],
          },
        };
        console.debug('initialized and added choice', choice.id);
        return;
      }

      if (data.constants[name].value.includes(choice.id)) {
        data.constants[name].value = data.constants[name].value.filter(
          (id: Choice['id']) => id !== choice.id,
        );
        console.debug('removed choice', choice.id);
        return;
      }

      data.constants[name].value.push(choice.id);
      console.debug('added choice', choice.id);
    };
  };

  const handleDeleteOnClick = useDeleteItem(logic);

  return {
    choiceSets,
    constants,
    condition,
    handleConditionOnChange,
    handleConstantOnChange,
    handleInputOnChange,
    handleChoiceOnClick,
    handleDeleteOnClick,
  };
};
