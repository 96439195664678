import React from 'react';
import { toast } from 'react-toastify';
import { Button } from '@/components/ui/button';
import { input } from '@/components/ui/form/form-field-renderer/FormFieldRenderer';
import { FormRenderer } from '@/components/ui/form/form-renderer/FormRenderer';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { z } from 'zod';
import { useClient } from '../../../../../../../hooks';
import { useCategoryEditor } from './hooks';
import { PriorityEditorProps } from './types';

const CategoryEditor = ({ category, onSuccess }: PriorityEditorProps) => {
  const client = useClient();
  const { sheet, state, handlers } = useCategoryEditor(category, onSuccess);
  return (
    <Sheet open={sheet.open} onOpenChange={sheet.onOpenChange}>
      <SheetTrigger asChild>
        <Button variant="outline" size="sm">
          {category ? 'Edit' : 'Create category'}
        </Button>
      </SheetTrigger>
      <SheetContent className=" min-h-0 p-0">
        <div className="relative h-full min-h-0">
          <ScrollArea className="h-full">
            <div className="p-4">
              <FormRenderer
                key={category?.id}
                fields={[
                  input({
                    label: 'Name',
                    name: 'name',
                    schema: z.string(),
                    defaultValue: category?.name,
                  }),
                  input({
                    label: 'Code',
                    name: 'code',
                    schema: z.string(),
                    defaultValue: category?.code,
                  }),
                ]}
                onSubmit={async (values) => {
                  if (category?.id) {
                    await handlers.update({
                      variables: {
                        id: category.id,
                        name: values.name,
                        code: values.code,
                      },
                    });
                    toast.success('Category updated');
                    sheet.onOpenChange(false);
                    onSuccess();
                  } else {
                    await handlers.create({
                      variables: {
                        clientId: client.id,
                        name: values.name,
                        code: values.code,
                      },
                    });
                    toast.success('Category created');
                    sheet.onOpenChange(false);
                    onSuccess();
                  }
                }}
              >
                {({ onSubmit }) => (
                  <div>
                    {category ? (
                      <Button
                        size="sm"
                        variant="outline"
                        className="w-full"
                        isLoading={state.updating}
                        onClick={onSubmit}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        variant="outline"
                        className="w-full"
                        isLoading={state.creating}
                        onClick={onSubmit}
                      >
                        Create
                      </Button>
                    )}
                  </div>
                )}
              </FormRenderer>
            </div>
          </ScrollArea>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default CategoryEditor;
