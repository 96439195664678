import React from 'react';
import { Scheduler } from 'lib/shared';
import { useSchedulerContext } from '../../../../../../shared/Scheduler/hooks';
import { SchedulerResourceType } from '../../../../../../shared/Scheduler/types';
import { AssignmentStaffProps } from './types';

const AssignmentStaff = ({ getDefaultDuration }: AssignmentStaffProps) => {
  const { resourceType } = useSchedulerContext();

  if (resourceType.value !== SchedulerResourceType.User) {
    return null;
  }

  return (
    <Scheduler.Content
      resourceType={SchedulerResourceType.User}
      getDefaultDuration={getDefaultDuration}
    />
  );
};

export default AssignmentStaff;
